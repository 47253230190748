<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.situacaoMovimentacaoQuantidade }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r">{{ bibDialogo.produto }}</th>
    <th class="r-1">{{ bibDialogo.quantidade }}</th>
    <th class="r-1">{{ bibDialogo.quantidadeTotalAbreviado }}</th>
    <th class="r-0-5">{{ bibDialogo.esperado }}</th>
    <th class="r-0-5">{{ bibDialogo.encontradoAbreviacao }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let situacaoMovimentacaoQuaMovimentacao of situacaoMovimentacaoQuaMovimentacoes">
      <tr class="conteudo">
        <td>{{ situacaoMovimentacaoQuaMovimentacao.id }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.data | data }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.numero }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.idLoja + ' - ' + situacaoMovimentacaoQuaMovimentacao.loja }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.idEmpresa + ' - ' + situacaoMovimentacaoQuaMovimentacao.empresa }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.idOperacao + ' - ' + situacaoMovimentacaoQuaMovimentacao.operacao }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.nome }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.quantidadeEsperada | monetario }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.quantidadeEncontrada | monetario }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.situacaoEsperada }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.situacaoEncontrada }}</td>
        <td>{{ situacaoMovimentacaoQuaMovimentacao.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
