<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro *ngIf="!this.utilSessao.telaInterna" [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10 clique" (click)="listar(add('numeracao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('id'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nReduzido }}</th>
        <th class="l-50 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.descricao }}</th>
        <th class="l-20">
          <!-- <botao *ngIf="!contemContaContabil && !ehMostraContaContabilPadrao && utilizaPlanoContaPadrao" [compacto]="compacto" [legenda]="bibDialogo.visualizarPlanoContaPadrao" (botaoEmt)="mostrarContaContabilPadrao()"></botao>
          <botao *ngIf="ehMostraContaContabilPadrao && utilizaPlanoContaPadrao" [compacto]="compacto" [legenda]="bibDialogo.importarPlanoContaPadrao" (botaoEmt)="importarContaContabilPadrao()" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true"></botao> -->
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let contaContabil of contaContabeis; let i = index">
        <tr *ngIf="contaContabil.correntista >= 0" [ngClass]="{ tachado: contaContabil.ativo != 'S' }">
          <td>{{ contaContabil.numeracao }}</td>
          <td class="text-right">{{ contaContabil.pai == 'N' && contaContabil.correntista > 0 && contaContabil.idPaiCorrentista == null ? contaContabil.idIntegracao != null ? contaContabil.idIntegracao : contaContabil.id : '' }}</td>
          <td class="limitar">
            <a *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && contaContabil.pai == 'N' && contaContabil.idPaiCorrentista == null" href="#" (click)="ir(contaContabil.id, i, contaContabeis)"> {{ contaContabil.nome.split(' ').join('&nbsp;') }}</a>
            <a *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && contaContabil.pai == 'S' && contaContabil.idPaiCorrentista == null" href="#" (click)="ir(contaContabil.id, i, contaContabeis)"><strong>{{ contaContabil.nome.split(' ').join('&nbsp;') }}</strong></a
            >            
            <span *ngIf="!contaContabil.idEmpresa || contaContabil.correntista == 0 || contaContabil.idPaiCorrentista != null">{{ contaContabil.nome.split(' ').join('&nbsp;') }}</span>  
            <atencao *ngIf="contaContabil.correntista > 1" [atencao]="bibDialogo.correntista"></atencao>          
          </td>
          <td class="row justify-content-end">
            <btnAdicional [id]="'adicionar' + i" class="mr-1" *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && contaContabil.correntista == 1  && contaContabil.idPaiCorrentista == null" [icone]="bibIcone.mais" (btnAdicional)="adicionarContaContabil(contaContabil)" [ajuda]="bibDialogo.criar + ' ' + this.bibDialogo.sub.toLowerCase() + '-' + this.menuPlanoConta.apelido.toLowerCase()"></btnAdicional>
            <btnAdicional [id]="'expandir' + i" class="mr-1" *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && contaContabil.correntista != 1  && contaContabil.idPaiCorrentista == null" [icone]="bibIcone.setaBaixo" (btnAdicional)="expandirContaContabil(contaContabil)" [ajuda]="bibDialogo.expandir + ' ' + this.bibDialogo.correntista.toLowerCase() + '-' + this.menuPlanoConta.apelido.toLowerCase()"></btnAdicional>
            <btnAdicional [id]="'editarConta' + i" class="mr-1" *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && contaContabil.idPaiCorrentista == null" [icone]="bibIcone.editar" (btnAdicional)="ir(contaContabil.id, i, contaContabeis)"></btnAdicional>            
            <btnAdicional [id]="'excluirConta' + i" class="mr-1" *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && contaContabil.idPaiCorrentista == null" [icone]="bibIcone.lixo" (btnAdicional)="excluirContaContabil(contaContabil.id, contaContabil.nome)"></btnAdicional>
            <!-- <btnAdicional [id]="'mostrar' + i" *ngIf="contaContabil.correntista > 0 && contaContabil.idEmpresa && !this.utilSessao.telaInterna && contaContabil.idPaiCorrentista == null" [icone]="bibIcone.olho" (btnAdicional)="mostrarHierarquiaDaConta(contaContabil)" [ajuda]="bibDialogo.mostrarHierarquiaDaConta"></btnAdicional> -->
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<modal [componente]="componente" (modalEmt)="fecharModalPlanoConta()"></modal>
