export class RelatorioGrupoUsuario {
  public acessar: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public favorito: string = 'N';
  public id: number;
  public idEmpresa: number;
  public idGrupoUsuario: number;
  public idRelatorio: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public principal: string;
  public relatorio: string;
  public relatorioModulo: string;
  public tipoRelatorioModulo: string;
}
