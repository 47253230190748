import { Type } from 'class-transformer';
import { EmpresaFilial } from './empresaFilial';
import { EmpresaOperacaoBloqueio } from './empresaOperacaoBloqueio';
import { EmpresaOperacaoContabil } from './empresaOperacaoContabil';
import { EmpresaOperacaoContabilFinanceiro } from './empresaOperacaoContabilFinanceiro';

export class Empresa {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public abreviacao: string;
  public alertaDesconto: number;
  public alertaPrazo: number;
  public alertarPesoAcima: number = 0;
  public alertarQuantidadeAcima: number = 0;
  public alertarValorAcima: number = 0;
  public ativo: string;
  public compacto: string;
  public consumidor: string;
  public contrato: string;
  public correntista: string = 'N';
  public empresaFiliais: EmpresaFilial[] = [];
  public empresaOperacaoBloqueios: EmpresaOperacaoBloqueio[] = [];
  public empresaOperacaoContabeis: EmpresaOperacaoContabil[] = [];
  public empresaOperacaoContabeisFinanceiro: EmpresaOperacaoContabilFinanceiro[] = [];
  public foto: string;
  public fotoEmpresa: string;
  public historicoFoto: string = 'N';
  public id: number;
  public idConsumidorFinal: number;
  public idGrupoEmpresa: number;
  public idProdutoPersonalizado: number;
  public idRelatorioEntrega: number;
  public idRelatorioVenda: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idContaContabilCaixa: number;
  public idContaContabilCliente: number;
  public idContaContabilEspecifica: number;
  public idContaContabilFornecedor: number;
  public idContaContabilDescCredito: number;
  public idContaContabilDescDebito: number;
  public idContaContabilJuroCredito: number;
  public idContaContabilJuroDebito: number;
  public idContaContabilMultaCredito: number;
  public idContaContabilMultaDebito: number;
  public idContaContabilDespesa: number;
  public idContaContabilReceita: number;
  public idContaContabilTaxaCartao: number;
  public idsOperacaoBloqueioExcluir: number[] = [];
  public idsOperacaoContabilExcluir: number[] = [];
  public idsOperacaoContabilFinanceiroExcluir: number[] = [];
  public limitarEstoque: number;
  public limitarNegociacao: number;
  public limitarParceiro: number;
  public limitarProduto: number;
  public limiteCredito: number;
  public movimentacaoEstilo: number = 1;
  public movimentacaoSalvarFinalizar: number = 1;
  public nome: string;
  public nomeContaContabilCaixa: string;
  public nomeContaContabilCliente: string;
  public nomeContaContabilEspecifica: string;
  public nomeContaContabilFornecedor: string;
  public nomeRelatorioEntrega: string;
  public nomeRelatorioMovimentacao: string;
  public ordemPagamento: number = 1;
  public produtoPersonalizado: string;
  public regraAbrirMovimentacao: string = 'N';
  public regraCasasDecimais: number = 2;
  public regraCasasDecimaisEntrada: number = 2;
  public regraCnpjCpf: number;
  public regraCnpjCpfDuplicado: number = 1;
  public regraComissao: number;
  public regraCustoReposicao: number;
  public regraEmitirNfeContingencia: number = 1;
  public regraEstoqueNegativo: string = 'N';
  public regraLojaLogin: string = 'N';
  public regraMascaraContaContabil: string = '00.00.00.00';
  public regraPlanoContaPadrao: string = 'N';
  public regraProdutoNomeAutomatico: string = 'S';
  public regraValidarInadimplencia: number = 1;
  public regraValorLancamentoContabil: number = 1;
  public regraVariacaoValorTotal: number;
  public regraEspecieTipoTitulo: string = 'S';
  public separarEntregar: string = 'S';
  public separarEntregarOpcional: string = 'N';
  public contabilidade: string = 'N';
  public receituario: string = 'N';
  public custoMaisIpi: string = 'N';
  public custoMaisIcmsSt: string = 'N';
  public custoMaisFrete: string = 'N';
  public custoMaisOutro: string = 'N';
  public custoMenosDesconto: string = 'N';
  public custoMenosIcms: string = 'N';
  public custoMenosIcmsDesonerado: string = 'N';
  public custoMenosCofins: string = 'N';
  public custoMenosPis: string = 'N';
  public custoMaisSeguro: string = 'N';
  public exigeAprovacaoInterna: string = 'N';
  public historico: string;
  public nomeContaContabilDescCredito: string;
  public nomeContaContabilDescDebito: string;
  public nomeContaContabilJuroCredito: string;
  public nomeContaContabilJuroDebito: string;
  public nomeContaContabilMultaCredito: string;
  public nomeContaContabilMultaDebito: string;
  public nomeContaContabilReceita: string;
  public nomeContaContabilDespesa: string;
  public nomeContaContabilTaxaCartao: string;
  public subtrairIcmsPisCofins: string;
  public integracaoNeogrid: string;
  public integracaoNs: string = 'S';
  public mobilidade: string = 'N';
  public mostraMargemLucro: string;
}
