import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import bibServico from '../biblioteca/bibServico';
import { Criterio } from '../modelo/criterio';
import { Loja } from '../modelo/loja';
import { Transporte } from '../modelo/transporte';
import { ComunicacaoService } from '../servico/comunicacao.service';
import { UtilSessao } from './util.sessao';

@Injectable()
export class UtilBusca {
  public bibServico = bibServico;

  constructor(private comunicacaoService: ComunicacaoService, private utilSessao: UtilSessao) {}

  listarLojas(): Observable<any> {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));

    const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.loja);
    resposta.subscribe((res) => {
      this.utilSessao.setLojas(plainToClass(Loja, res) as any);
    });
    return resposta;
  }
}
