import { Component } from '@angular/core';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { Justificativa } from 'src/app/integracao/tecnospeed/nfe/justificativa';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoCancelamento } from 'src/app/modelo/movimentacaoCancelamento';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './cancelamentoFrm.component.html',
})
export class CancelamentoFrmComponent extends PaginaComponent {
  public apresentaDescricaoTexto: boolean = false;
  public bibServicoTecnoSpeed: bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public bloqueado: boolean = false;
  public justificativa: Justificativa = new Justificativa();
  public movimentacao: Movimentacao = new Movimentacao();
  public movimentacaoCancelamento: MovimentacaoCancelamento = new MovimentacaoCancelamento();
  public motivos: any[] = [
    { id: 'ERRO NA EMISSÃO DA NOTA FISCAL ELETRÔNICA.', nome: 'ERRO NA EMISSÃO DA NOTA FISCAL ELETRÔNICA.' },
    { id: 'DESISTÊNCIA DO PARCEIRO', nome: 'DESISTÊNCIA DO PARCEIRO' },
  ];

  ngOnInit(): void {
    this.movimentacao = this.utilSessao.getIdentificacao('movimentacao')?.conteudo;
    const protocoloCancelamento: boolean = this.util.coalesce(this.utilSessao.getIdentificacao('protocoloCancelamento')?.conteudo, false);
    this.bloqueado = this.movimentacao.ativo != 'S' && protocoloCancelamento;
  }

  persistirCancelamento(idMovimentacao: number): void {
    this.movimentacaoCancelamento.idMovimentacao = idMovimentacao;
    if (this.ehValido()) {
      super.persistir(new Transporte(this.movimentacaoCancelamento), this.bibServico.movimentacaoCancelamento, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.bloqueado = true;
          this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.movimentacaoCancelamento, this.movimentacaoCancelamento));
        }
      });
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    return ehValidoObrigatorio;
  }

  ehValidoObrigatorio(): boolean {
    if (this.movimentacaoCancelamento.motivo) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setMotivo(motivo: string): void {
    this.movimentacaoCancelamento.motivo = motivo;
  }
}
