export class MovimentacaoChave {
  public chave: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idMovimentacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public modelo: string;
  public numeroFiscal: number;
  public serie: string;
}
