import { Component, Input } from '@angular/core';
import { UsuarioLoja } from 'src/app/modelo/usuarioLoja';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'usuarioLojasFrm',
  templateUrl: './usuarioLojasFrm.component.html',
})
export class UsuarioLojasFrmComponent extends PaginaComponent {
  @Input() usuarioLojas: UsuarioLoja[] = [];
}
