import { Component } from '@angular/core';
import { R2005Component } from '../r2005/r2005.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2007.component.html',
})
export class R2007Component extends R2005Component {
  public filtros = filtros;
}
