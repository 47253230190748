<layout [titulo]="classificacao.nome" [id]="classificacao.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="classificacao.nome" (alteracao)="classificacao.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="classificacao.ativo" (alteracao)="classificacao.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <titulo class="col-sm p-0" [titulo]="bibDialogo.validacao" [imagem]="bibImagem.cadeado"></titulo>
  <div class="row">
    <interruptor class="col-sm-3 pl-0" [id]="'bloquearFinanceiro'" [rotulo]="bibDialogo.bloqueadoFinanceiro" [campo]="classificacao.bloqueadoFinanceiro" (alteracao)="classificacao.bloqueadoFinanceiro = $event" [compacto]="false"></interruptor>
    <interruptor class="col-sm-3 pl-0" [id]="'bloquearContabilidade'" [rotulo]="bibDialogo.bloquearContabilidade" [campo]="classificacao.bloqueadoContabilidade" (alteracao)="classificacao.bloqueadoContabilidade = $event" [compacto]="false"></interruptor>
    <interruptor class="col-sm-3 pl-0" [id]="'vinculaOutroParceiro'" [rotulo]="bibDialogo.vinculaOutroParceiro" [campo]="classificacao.vinculoOutroParceiro" (alteracao)="classificacao.vinculoOutroParceiro = $event" [compacto]="false"></interruptor>
  </div>
  <div class="row">
    <interruptor class="col-sm-3 pl-0" [id]="'bloquearEstoque'" [rotulo]="bibDialogo.bloquear + ' ' + bibDialogo.estoqueDisponivel.toLowerCase()" [campo]="classificacao.bloqueadoEstoque" (alteracao)="classificacao.bloqueadoEstoque = $event" [compacto]="false"></interruptor>
    <interruptor class="col-sm-3 pl-0" [id]="'bloquearEstoqueNfe'" [rotulo]="bibDialogo.bloquear + ' ' + bibDialogo.estoque.toLowerCase() + ' ' + bibDialogo.nfe" [campo]="classificacao.bloqueadoEstoqueNfe" (alteracao)="classificacao.bloqueadoEstoqueNfe = $event" [compacto]="false"></interruptor>
    <interruptor class="col-sm-3 pl-0" [id]="'bloquearEstoqueNfe'" [rotulo]="bibDialogo.bloquearDocumentoEletronico" [campo]="classificacao.bloqueadoNfe" (alteracao)="classificacao.bloqueadoNfe = $event" [compacto]="false"></interruptor>
  </div>
  <classificacaoOperacao [classificacao]="classificacao" [idsClassificacaoOperacaoExcluir]="idsClassificacaoOperacaoExcluir"></classificacaoOperacao>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirClassificacao()" [desabilitado]="bloquearPermissao(classificacao.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirClassificacao(true)" [desabilitado]="bloquearPermissao(classificacao.id)"></botao>
<auditoria *ngIf="classificacao.id" [idUsuarioInclusao]="classificacao.idUsuarioInclusao" [dataHoraInclusao]="classificacao.dataHoraInclusao" [idUsuarioAlteracao]="classificacao.idUsuarioAlteracao" [dataHoraAlteracao]="classificacao.dataHoraAlteracao"></auditoria>
