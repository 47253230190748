<titulo [titulo]="bibDialogo.taxas" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.taxas.toLowerCase()" [desabilitado]="bloquearPermissao(tipoTitulo.id)" [obrigatorio]="this.tipoTitulo.tipoTituloCaixas.length == 0"> </titulo>
<div class="row" *ngIf="tipoTitulo.tipoTituloCaixas.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.taxa.toLowerCase() + ', ' }} <strong class="clique" [id]="'adicionarTaxas'" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="this.tipoTitulo.tipoTituloCaixas.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th *ngIf="especieCartaoDebito == false" class="l-10">{{ bibDialogo.parcelaDe }}</th>
      <th *ngIf="especieCartaoDebito == false" class="l-10">{{ bibDialogo.parcelaAte }}</th>
      <th class="l-10 text-right">{{ bibDialogo.taxa }}</th>
      <th class="l"></th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let tipoTituloCaixa of this.tipoTitulo.tipoTituloCaixas; let i = index">
      <tr>
        <td *ngIf="especieCartaoDebito == false">
          <texto *ngIf="!tipoTituloCaixa.id || edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id)" [campo]="tipoTituloCaixa.parcelaDe" [id]="'parcelaDe' + i" (alteracao)="tipoTituloCaixa.parcelaDe = $event" [tipo]="this.bibPropriedade.texto.numero" [obrigatorio]="true"></texto>
          {{ tipoTituloCaixa.id && edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id) == false ? tipoTituloCaixa.parcelaDe : '' }}
        </td>
        <td *ngIf="especieCartaoDebito == false">
          <texto *ngIf="!tipoTituloCaixa.id || edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id)" [campo]="tipoTituloCaixa.parcelaAte" [id]="'parcelaAte' + i" (alteracao)="tipoTituloCaixa.parcelaAte = $event" [tipo]="this.bibPropriedade.texto.numero" [obrigatorio]="true"></texto>
          {{ tipoTituloCaixa.id && edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id) == false ? tipoTituloCaixa.parcelaAte : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="!tipoTituloCaixa.id || edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id)" [campo]="tipoTituloCaixa.taxa" [id]="'taxa' + i" (alteracao)="tipoTituloCaixa.taxa = $event" [obrigatorio]="true" [sufixo]="'%'"></decimal>
          {{ tipoTituloCaixa.id && edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id) == false ? (tipoTituloCaixa.taxa | monetario) + '%' : '' }}
        </td>
        <td></td>
        <td>
          <btnAdicional [id]="'edicao' + i" [icone]="bibIcone.editar" (btnAdicional)="editar(tipoTituloCaixa)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirTipoTituloCaixa(i)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>