import { Component } from '@angular/core';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Grupo } from 'src/app/modelo/grupo';
import { GrupoValor } from 'src/app/modelo/grupoValor';
import { Loja } from 'src/app/modelo/loja';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';
import { Filtros } from './filtro';

@Component({
  templateUrl: './grupoValor.component.html',
})
export class GrupoValorComponent extends PaginaComponent {
  public atualizarMargem: string = 'N';
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public componente: any;
  public filtros: Filtro[] = new Filtros().filtros;
  public formaEscolhida: string = 'A';
  public grupoValores: GrupoValor[] = [];
  public indice: string;
  public loja: Loja;
  public margemEscolhida: string = 'G';
  public percentual: number = 0;
  public rotuloGrupo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 11).apelido;
  public util: Util = new Util();
  public atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['os ' + this.menuGrupo.apelido.toLowerCase() + 's']);
  public mostraSufixoPercentual: boolean = true;

  public margens: any = [
    { id: 'G', nome: this.bibNomenclatura.escrever(this.bibNomenclatura.margemGerencial) },
    { id: 'L', nome: this.bibDialogo.margemLucro },
  ];

  public formaEscolhidas: any = [
    { id: 'A', nome: this.bibDialogo.acrescentar },
    { id: 'M', nome: this.bibDialogo.diminuir },
    { id: 'D', nome: this.bibDialogo.definir },
  ];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.grupoValor).subscribe((res) => {
      this.grupoValores = this.plainToClass(GrupoValor, res) as any;
      this.popularGrupoValores(criterios, posicao, quantidade);
    });
    this.percentual = 0;
    this.atualizarMargem = 'N';
    this.loja = this.utilSessao.getLojas().find((loja) => loja.id == criterios.find((criterio) => criterio.nome == 'ID_LOJA').valor);
    this.mostraSufixoPercentual = this.loja.margemLucroDiferenciada != 'S';
  }

  popularGrupoValores(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    if (criterios.filter((criterio) => criterio.nome != 'GRUPO' && criterio.nome != 'ID_LOJA').length == 0) {
      criterios.forEach((criterio) => {
        if (criterio.nome == 'GRUPO') {
          criterio.nome = 'NOME';
        }
      });
      criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
      this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.grupo).subscribe((res) => {
        const grupos: Grupo[] = this.plainToClass(Grupo, res) as any;
        grupos.forEach((grupo) => {
          if (this.grupoValores.find((grupoValor) => grupoValor.idGrupo == grupo.id) == undefined) {
            const grupoValor: GrupoValor = new GrupoValor();
            grupoValor.idGrupo = grupo.id;
            grupoValor.grupo = grupo.nome;
            grupoValor.idLoja = criterios.find((criterio) => criterio.nome == 'ID_LOJA').valor;
            grupoValor.margemLucro = 0;
            grupoValor.margemGerencial = 0;
            if (this.grupoValores.filter((grupoValor) => grupoValor.idGrupo == grupo.id).length == 0) {
              this.grupoValores.push(grupoValor);
            }
          }
        });
      });
    }
  }

  setMargemGerencial(grupoValor: GrupoValor, margemGerencial: number): void {
    grupoValor.margemGerencial = margemGerencial;
    this.setAlterado(grupoValor);
  }

  setMargemLucro(grupoValor: GrupoValor, margemLucro: number): void {
    grupoValor.margemLucro = this.util.arredondar(margemLucro);
    this.setAlterado(grupoValor);
  }

  setAlterado(grupoValor: GrupoValor): void {
    grupoValor.alterado = 'S';
  }

  excluirGrupoValor(id: number, nome: string): void {
    super.excluir<GrupoValor>(id, this.grupoValores, nome, this.bibServico.grupoValor);
  }

  persistirGrupoValor(novo: boolean = false): void {
    const grupoValoresModificados: GrupoValor[] = this.grupoValores.filter((grupoValor) => grupoValor.alterado == 'S');
    if (this.ehValido(grupoValoresModificados)) {
      const transporte: Transporte = new Transporte(grupoValoresModificados);
      this.persistir(transporte, this.bibServico.grupoValor, novo ? new GrupoValor() : null).subscribe((res) => {
        this.definirId(grupoValoresModificados, this.bibClasse.grupoValor, true);
      });
    }
  }

  ehValido(grupoValores: GrupoValor[]): boolean {
    if (grupoValores.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.alterePeloMenusUmValorParaEfetuarAlteracao));
    return false;
  }

  editarCampo(indice: string): void {
    this.indice = indice;
  }

  atualizarMargemGrupoValor(): void {
    this.grupoValores.forEach((grupoValor) => {
      let valor: number = this.margemEscolhida == 'G' ? grupoValor.margemGerencial : grupoValor.margemLucro;
      switch (this.formaEscolhida) {
        case 'A':
          valor += this.percentual;
          break;
        case 'M':
          valor -= this.percentual;
          break;
        case 'D':
          valor = this.percentual;
          break;
      }
      if (this.margemEscolhida == 'G') {
        this.setMargemGerencial(grupoValor, valor);
      } else {
        this.setMargemLucro(grupoValor, valor);
      }
    });
  }
}
