import { Component, Input, Output, EventEmitter } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'checkIcone',
  templateUrl: './checkIcone.component.html',
  styleUrls: ['./checkIcone.component.css'],
})
export class CheckIcone {
  @Input() campo: any;
  @Input() rotulo: string;
  @Input() compacto: boolean = this.utilSessao.getEmpresa().compacto == 'S';
  @Input() obrigatorio: boolean;
  @Input() desabilitado: boolean = false;
  @Input() lista: any[];
  @Input() nomeCampoRegra: string;
  @Input() id: string;
  @Output() alteracao = new EventEmitter<any>();

  constructor(private utilSessao: UtilSessao) {}

  alterar(objeto: any): void {
    if (!this.desabilitado) {
      if (this.nomeCampoRegra == 'permitido') {
        this.alteracao.emit(objeto);
      } else {
        let clone = plainToClass(Object(objeto).class, objeto) as any;
        if (this.campo == objeto[this.nomeCampoRegra]) {
          clone.id = null;
        }
        this.alteracao.emit(clone);
      }
    }
  }

  alterarLista(campo: any): void {
    this.alteracao.emit(campo);
  }
}
