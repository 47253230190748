import { Component } from '@angular/core';
import { Formulario } from '../formulario';

@Component({
  selector: 'apresentacao',
  templateUrl: './apresentacao.component.html',
  styleUrls: ['./apresentacao.component.css'],
})
export class ApresentacaoComponent extends Formulario {
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
}
