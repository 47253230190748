import { Classificacao } from 'src/app/modelo/classificacao';
import { Component, Input } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { ClassificacaoOperacao } from 'src/app/modelo/classificacaoOperacao';
import { Criterio } from 'src/app/modelo/criterio';

@Component({
  selector: 'classificacaoOperacao',
  templateUrl: './classificacaoOperacao.component.html',
})
export class ClassificacaoOperacaoComponent extends PaginaComponent {
  @Input() classificacao: Classificacao;
  @Input() idsClassificacaoOperacaoExcluir: number[] = [];
  public componente: any = null;
  public ehMinhaModal: boolean = false;
  public lista: string[] = ['1', '2'];

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const operacoes: any[] = identificacao.conteudo;
        operacoes.forEach((operacao) => {
          if (this.classificacao.classificacaoOperacoes.filter((classificacaoOperacao) => classificacaoOperacao.idOperacao == operacao.id || classificacaoOperacao.idOperacao == operacao.idOperacao).length == 0) {
            let classificacaoOperacao: ClassificacaoOperacao = new ClassificacaoOperacao();
            classificacaoOperacao.idOperacao = operacao.id;
            classificacaoOperacao.operacao = operacao.nome;
            this.classificacao.classificacaoOperacoes.push(classificacaoOperacao);
          }
        });
      }
    });
  }

  adicionarClassificacaoOperacao(): void {
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.operacao));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.classificacao.classificacaoOperacoes));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.operacao));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }
}
