import { Component } from '@angular/core';
import { subDays } from 'date-fns';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Permissao } from 'src/app/modelo/permissao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { FinanceiroFrmComponent } from 'src/app/pagina/financeiro/frm/financeiroFrm.component';
import { ParceiroFrmComponent } from 'src/app/pagina/parceiro/frm/parceiroFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import { Filtros } from './acessorio/filtro';
import totais from './acessorio/total';

@Component({
  templateUrl: './r2014.component.html',
  styleUrls: ['./r2014.component.css'],
})
export class R2014Component extends RelatorioComponent {
  public componente: any;
  public filtros: Filtro[] = new Filtros().filtros;
  public financeiros: any[] = [];
  public modal: boolean;
  public usaExpandido: boolean = false;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public telaCliente: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 5);
  public telaReceita: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 28);

  public tipoNegociacoes: any[] = [
    { id: 'L', nome: this.bibDialogo.livre.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.semanal.toUpperCase() },
    { id: 'Q', nome: this.bibDialogo.quinzenal.toUpperCase() },
    { id: 'M', nome: this.bibDialogo.mensal.toUpperCase() },
    { id: 'D', nome: this.bibDialogo.diaFixo.toUpperCase() },
    { id: 'P', nome: this.bibDialogo.prazoFixo.toUpperCase() },
    { id: 'A', nome: this.bibDialogo.aVista.toUpperCase() },
  ];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'FinanceiroAgrupado').lista;
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.completarFinanceiro();
      this.ordenarAgrupar(this.financeiros);
      this.ehImprimirRelatorio();
      this.expandirRelatorio();
    });
  }

  completarFinanceiro(): void {
    let dataAtual: Date = new Date();
    this.financeiros.forEach((financeiro) => {
      financeiro.negociacao = this.tipoNegociacoes.find((tipoNegociacao) => tipoNegociacao.id == financeiro.negociacao).nome;
      financeiro.valorLiquido = financeiro.valorTotal - financeiro.valorCredito;
      financeiro.vencimento = subDays(dataAtual, financeiro.diaAtraso);
    });
  }

  expandir(financeiro: any) {
    if (financeiro.expandido && (financeiro.financeiros == null || financeiro.financeiros.length == 0)) {
      this.listarEspecifico(new Criterio('ID_PARCEIRO', financeiro.id), 127).subscribe((res) => {
        let financeiroDetalhes: any[] = this.plainToClass(RelatorioResultado, res)[0].lista;
        financeiroDetalhes.forEach((financeiroDetalhe) => {
          financeiroDetalhe.valorLiquido = financeiroDetalhe.valorTotal - financeiroDetalhe.valorCredito;
        });
        financeiro.financeiros = financeiroDetalhes;
      });
    }
  }

  emitirModal(id: number, tipo: string): void {
    const MENU_RECEITA = 28;
    const MENU_DESPESA = 41;
    const idMenu: number = tipo == 'D' ? MENU_DESPESA : MENU_RECEITA;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('parametro', tipo), new Identificacao('objeto', id)];
    this.componente = this.util.abrirModal(FinanceiroFrmComponent, identificacoes, idMenu);
  }

  irParceiro(idParceiro: number, cobranca = false): void {
    const MENU_CLIENTE = 5;
    this.componente = this.abrirModalRelatorio(ParceiroFrmComponent, [new Identificacao('idParceiro', idParceiro), new Identificacao('pesquisaCliente', false), new Identificacao('parametro', 'CLIENTE'), new Identificacao('cobranca', cobranca)], MENU_CLIENTE);
  }

  expandirRelatorio() {
    this.financeiros.forEach((financeiro) => {
      if (this.usaExpandido) {
        this.expandir(financeiro);
      } else {
        financeiro.expandido = !financeiro.expandido;
        financeiro.financeiros = null;
      }
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaExpandido = this.definirRegra(relatorioAdicional, 233, this.usaExpandido);
    this.expandirRelatorio();
  }
}
