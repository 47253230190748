<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.situacaoMovimentacaoProduto }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r">{{ bibDialogo.produto }}</th>
    <th class="r-1">{{ bibDialogo.quantidade }}</th>
    <th class="r-1">{{ bibDialogo.quantidadeTotalAbreviado }}</th>
    <th class="r-0-5">{{ bibDialogo.esperado }}</th>
    <th class="r-0-5">{{ bibDialogo.encontradoAbreviacao }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let situacaoMovimentacaoProMovimentacao of situacaoMovimentacaoProMovimentacoes">
      <tr class="conteudo">
        <td>{{ situacaoMovimentacaoProMovimentacao.id }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.data | data }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.numero }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.idLoja + ' - ' + situacaoMovimentacaoProMovimentacao.loja }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.idEmpresa + ' - ' + situacaoMovimentacaoProMovimentacao.empresa }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.idOperacao + ' - ' + situacaoMovimentacaoProMovimentacao.operacao }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.nome }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.quantidadeEsperada | monetario }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.quantidadeEncontrada | monetario }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.situacaoEsperada }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.situacaoEncontrada }}</td>
        <td>{{ situacaoMovimentacaoProMovimentacao.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
