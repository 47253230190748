import { Type } from 'class-transformer';
import { EntregaProduto } from './entregaProduto';

export class Entrega {
  @Type(() => Date)
  public dataHoraEntrega: Date;
  @Type(() => Date)
  public dataHoraSeparacao: Date;

  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public entregaProdutos: EntregaProduto[] = [];
  public id: number;
  public idColaboradorSep: number;
  public idEmpresa: number;
  public idLoja: number;
  public idMovimentacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public observacao: string;
}
