<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-20 clique" (click)="listar(add('id'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.lote }}</th>
        <th class="l-5 clique" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
        <th class="l-25 clique" (click)="listar(add('contaContabil'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.conta }}</th>
        <th class="l-5 clique" (click)="listar(add('debitoCredito'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.tipo }}</th>
        <th class="l-10 clique" (click)="listar(add('usuario'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.usuario }}</th>
        <th class="l-15">{{ bibDialogo.origem }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let lancamentoContabil of lancamentoContabeis; let i = index">
        <tr [ngClass]="{ tachado: lancamentoContabil.ativo != 'S' }">     
          <td class="fonte-menor">{{ lancamentoContabil.chave }}</td>
          <td>{{ lancamentoContabil.loja }}</td>
          <td>{{ lancamentoContabil.data | data }}</td>
          <td>{{ lancamentoContabil.valor | monetario }}</td>
          <td class="limitar fonte-menor">{{ (lancamentoContabil.idParceiro ||  lancamentoContabil.idContaBancaria) ? lancamentoContabil.numeracaoCorrentista + ' - ' + lancamentoContabil.nomeCorrentista : lancamentoContabil.contaContabil }}</td>
          <td><atencao [atencao]="lancamentoContabil.debitoCredito == 1 ? 'D' : 'C'" [tipo]="lancamentoContabil.debitoCredito == 1 ? '' : bibPropriedade.atencao.cuidado" [ajuda]="lancamentoContabil.debitoCredito == 1 ? bibDialogo.debito : bibDialogo.credito"></atencao></td>
          <td class="limitar fonte-menor">{{ lancamentoContabil.nomeUsuarioAlteracao }}</td>
          <td>{{ lancamentoContabil.origem == 1 ? this.bibDialogo.manual : lancamentoContabil.origem == 2 ? bibDialogo.financeiro : lancamentoContabil.origem == 3 ? bibDialogo.movimentacao : lancamentoContabil.origem == 4 ? menuRoteiroContabil.apelido : lancamentoContabil.origem == 5 ? bibDialogo.estornoFinanceiro : bibDialogo.manual }}</td>
          <td>
            <btnAdicional *ngIf="lancamentoContabil.origem != 3 && lancamentoContabil.origem != 2" [icone]="bibIcone.olho" (btnAdicional)="ir(lancamentoContabil.id, i, lancamentoContabeis)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [id]="'excluir' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirLancamentoContabil(lancamentoContabil.id, bibDialogo.esteRegistro)" [desabilitado]="lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>