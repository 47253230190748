import { Component } from '@angular/core';
import { Aprovacao } from 'src/app/modelo/aprovacao';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Permissao } from 'src/app/modelo/permissao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { FinanceiroFrmComponent } from '../financeiro/frm/financeiroFrm.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import { ParceiroFrmComponent } from '../parceiro/frm/parceiroFrm.component';

@Component({
  templateUrl: './aprovacaoFrm.component.html',
  styleUrls: ['./aprovacaoFrm.component.css'],
})
export class AprovacaoFrmComponent extends PaginaComponent {
  public aprovacao: Aprovacao = new Aprovacao();
  public compacto: boolean = this.utilSessao.getEmpresa().compacto == 'S';
  public componente: any = null;
  public movimentacao: Movimentacao = new Movimentacao();
  public permissaoAbrirPesquisaCliente: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 5);

  ngOnInit(): void {
    const id: number = this.utilSessao.getIdentificacao('id')?.conteudo;
    this.listarAprovacao(id);
  }

  listarAprovacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.aprovacao).subscribe((res) => {
      if (res.length > 0) {
        this.aprovacao = this.plainToClass(Aprovacao, res[0]) as any;
        this.definirFinalizadoOrigem();
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.registroNaoDisponivel));
        this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.aprovacao, null));
      }
    });
  }

  definirFinalizadoOrigem(): void {
    this.aprovacao.finalizado = this.aprovacao.finalizadoOrigem.split('_')[0];
    this.aprovacao.origem = Number(this.aprovacao.finalizadoOrigem.split('_')[1]);
  }

  persistirAprovacao(situacao: string): void {
    this.aprovacao.situacao = situacao;
    if (!this.aprovacao.justificativa && this.aprovacao.situacao == 'R') {
      this.aprovacao.justificativa = this.bibDialogo.movimentacaoRecusada;
    } else {
      if (this.aprovacao.justificativa == this.bibDialogo.movimentacaoRecusada) this.aprovacao.justificativa = '';
    }
    this.aprovacao.idUsuarioAprovacao = this.utilSessao.getUsuario().id;
    this.persistir(new Transporte(this.aprovacao), this.bibServico.aprovacao, null).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.aprovacao, this.aprovacao));
      }
    });
  }

  irCliente(idParceiro: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', idParceiro));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', 'CLIENTE'));
    this.utilSessao.setPermissaoEspecifica(this.permissaoAbrirPesquisaCliente);
    this.componente = ParceiroFrmComponent;
  }

  abrirModalOrigem(aprovacao: Aprovacao): void {
    if (aprovacao.categoria == 'G') {
      this.utilSessao.setIdentificacao(new Identificacao('parametro', 'D'));
      this.utilSessao.setIdentificacao(new Identificacao('objeto', aprovacao.origem));
      this.utilSessao.setIdentificacao(new Identificacao('modalBloqueada', 'S'));
      this.componente = FinanceiroFrmComponent;
    } else {
      this.utilSessao.setIdentificacao(new Identificacao('objeto', aprovacao.origem));
      this.utilSessao.setIdentificacao(new Identificacao('bloqueado', true));
      this.componente = MovimentacaoFrmComponent;
    }
  }
}
