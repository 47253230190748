import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.numero, campo: 'numero' },
  { rotulo: bibDialogo.produto, campo: 'nome' },
  { rotulo: bibDialogo.grupo, campo: 'grupo' },
  { rotulo: bibDialogo.deposito, campo: 'categoria' },
];
export default ordens;
