<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.resumo + ' ' + menuNegociacaoApelido }}<ajuda [ajuda]="bibDialogo.movimentacoesGeraramFinanceiro" [posicao]="bibDialogo.esquerda"></ajuda></h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r">{{ menuNegociacaoApelido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroMes }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoResumoNegociacao of movimentacaoResumoNegociacoes">
      <tr>
        <td>{{ movimentacaoResumoNegociacao.loja }}</td>
        <td class="limitar">{{ movimentacaoResumoNegociacao.negociacao }}</td>
        <td class="text-right">{{ (movimentacaoResumoNegociacao.percentualDia | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoResumoNegociacao.valorTotalDia | monetario }}</td>
        <td class="text-right">{{ movimentacaoResumoNegociacao.valorLucroDia | monetario }}</td>
        <td class="text-right">{{ (movimentacaoResumoNegociacao.percentualLucroDia | monetario) + '%' }}</td>
        <td class="text-right">{{ (movimentacaoResumoNegociacao.percentualMes | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoResumoNegociacao.valorTotalMes | monetario }}</td>
        <td class="text-right">{{ movimentacaoResumoNegociacao.valorLucroMes | monetario }}</td>
        <td class="text-right">{{ (movimentacaoResumoNegociacao.percentualLucroMes | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoResumoNegociacoes.length > 0">
    <tr>
      <td colspan="3"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoResumoNegociacoes[0].totalValorDia | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoResumoNegociacoes[0].totalLucroDia | monetario }}</strong>
      </td>
      <td colspan="2"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoResumoNegociacoes[0].totalValorMes | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoResumoNegociacoes[0].totalLucroMes | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
