import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataConciliacaoDe, obrigatorio: 'S', nome: 'DATA_CONCILIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataConciliacaoAte, obrigatorio: 'S', nome: 'DATA_CONCILIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.conta, nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.contaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
]);
export default filtros;
