<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let local of locais; let i = index">
        <tr [ngClass]="{ tachado: local.ativo != 'S' }">
          <td>
            <span *ngIf="local.id == 0">{{ local.nome.split(' ').join('&nbsp;') }}</span>
            <a *ngIf="local.id != 0 && local.pai == 'N'" href="#" (click)="ir(local.id, i, locais)"> {{ local.nome.split(' ').join('&nbsp;') }}</a>
            <a *ngIf="local.id != 0 && local.pai == 'S'" href="#" (click)="ir(local.id, i, locais)"
              ><strong> {{ local.nome.split(' ').join('&nbsp;') }}</strong></a
            >
          </td>
          <td>
            <btnAdicional *ngIf="local.id != 0 && local.nome != bibDialogo.grupo.toUpperCase() && local.nome != bibDialogo.categoria.toUpperCase()" [icone]="bibIcone.editar" (btnAdicional)="ir(local.id, i, locais)"></btnAdicional>
          </td>
          <td>
            <btnAdicional *ngIf="local.id != 0 && local.nome != bibDialogo.grupo.toUpperCase() && local.nome != bibDialogo.categoria.toUpperCase()" [icone]="bibIcone.lixo" (btnAdicional)="excluirLocal(local.id, local.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
