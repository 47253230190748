import { Component, Input } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  selector: 'r1001',
  templateUrl: './r1001.component.html',
})
export class R1001Component extends RelatorioComponent {
  @Input() filtros = filtros;
  public grupos = grupos;
  public criteriosFiltro: Criterio[] = [];
  public movimentacoes: any[] = [];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public situacoes: any[] = [{ nome: this.bibDialogo.aberta }, { nome: this.bibDialogo.aguardandoFinanceiro }, { nome: this.bibDialogo.aguardandoEntrega }, { nome: this.bibDialogo.entregaParcial }, { nome: this.bibDialogo.concluida }, { nome: this.bibDialogo.cancelada }];
  public usaItem: boolean = false;
  public usaNegociacao: boolean = false;
  public usaSituacao: boolean = false;
  public usaExpandido: boolean = false;
  @Input() pedidoCarteira: boolean = false;
  @Input() comissao: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    this.atualizarTotalizador();
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.ordenarAgrupar(this.movimentacoes);
      this.atualizarTotalizador();
      this.atualizarSituacao();
      this.ehImprimirRelatorio();
    });
  }

  expandirRelatorio(): void {
    this.movimentacoes.forEach((movimentacao) => {
      movimentacao.expandido = false;
      if (this.usaExpandido) {
        this.expandir(movimentacao);
      }
    })
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaNegociacao = this.definirRegra(relatorioAdicional, 107, this.usaNegociacao);
    this.usaSituacao = this.definirRegra(relatorioAdicional, 162, this.usaSituacao);
    this.usaExpandido = this.definirRegra(relatorioAdicional, 265, this.usaExpandido);
    this.atualizarTotalizador();
    this.expandirRelatorio();
    this.ehModoPaisagem();
  }

  ehModoPaisagem(): void {
    let contagemAdicionais: number = 0;
    this.usaNegociacao ? contagemAdicionais++ : contagemAdicionais;
    if (contagemAdicionais > 0) {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', ''));
    } else {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', '', 'S'));
    }
  }

  atualizarTotalizador(): void {
    if (this.pedidoCarteira) {
      this.posicionarTotalizador('valorTotalFaturado', true);
      this.posicionarTotalizador('valorTotalDesfeitoEstorno', true);
      this.posicionarTotalizador('valorTotalPendente', true);
    } else {
      this.posicionarTotalizador('valorTotal', true, 2);
    }
  }

  atualizarSituacao(): void {
    this.movimentacoes.forEach((movimentacao) => {
      movimentacao.situacaoDescricao = this.situacoes[movimentacao.situacao - 1].nome;
    });
  }

  totalizarProduto(movimentacao: any): void {
    movimentacao.totalDesconto = 0;
    movimentacao.totalValorTotal = 0;
    movimentacao.totalDescontoRateado = 0;
    movimentacao.totalValorFinal = 0;
    movimentacao.totalValorFaturado = 0;
    movimentacao.totalValorDesfeitoEstorno = 0;
    movimentacao.totalValorPendente = 0;
    movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      movimentacao.totalDesconto += movimentacaoProduto.descontoValor;
      movimentacao.totalValorTotal += movimentacaoProduto.valorTotal;
      movimentacao.totalDescontoRateado += movimentacaoProduto.descontoRateado;
      movimentacao.totalValorFinal += movimentacaoProduto.valorFinal;
      movimentacao.totalValorFaturado += movimentacaoProduto.valorFaturado;
      movimentacao.totalValorDesfeitoEstorno += movimentacaoProduto.valorDesfeitoEstorno;
      movimentacao.totalValorPendente += movimentacaoProduto.valorPendente;
    });
  }

  expandir(movimentacao: any) {
    this.criteriosFiltro = this.criterios;
    movimentacao.expandido = !movimentacao.expandido;
    this.pedidoCarteira ? this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', '')) : '';
    if (movimentacao.expandido && (movimentacao.movimentacaoProdutos == null || movimentacao.movimentacaoProdutos.length == 0)) {
      this.listarEspecifico(new Criterio('ID_MOVIMENTACAO', movimentacao.id), 15).subscribe((res) => {
        movimentacao.movimentacaoProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
        for (const movimentacaoProduto of movimentacao.movimentacaoProdutos) {
          movimentacaoProduto.expandido = false;
          movimentacaoProduto.valorFaturado = (movimentacaoProduto.quantidadeFaturada * movimentacaoProduto.valorFinal) / movimentacaoProduto.quantidade;
          movimentacaoProduto.valorDesfeitoEstorno = ((movimentacaoProduto.quantidadeDesfeita + movimentacaoProduto.quantidadeDevolvidaEst) * movimentacaoProduto.valorFinal) / movimentacaoProduto.quantidade;
          movimentacaoProduto.valorPendente = (movimentacaoProduto.quantidadePendenteFaturamento * movimentacaoProduto.valorFinal) / movimentacaoProduto.quantidade;
        }
        this.totalizarProduto(movimentacao);
      });
    }
  }
}
