import { Component } from '@angular/core';
import { BloqueioPeriodo } from 'src/app/modelo/bloqueioPeriodo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Patrimonial } from 'src/app/modelo/patrimonial';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './patrimonial.component.html',
})
export class PatrimonialComponent extends PaginaComponent {
  public patrimoniais: Patrimonial[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.patrimonial).subscribe((res) => {
      this.patrimoniais = this.plainToClass(Patrimonial, res) as any;
    });
  }

  excluirPatrimonio(id: number, nome: string): void {
    super.excluir<Patrimonial>(id, this.patrimoniais, nome);
  }
}
