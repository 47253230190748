<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.resumo + ' ' + menuGrupoApelido }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r">{{ menuGrupoApelido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroMes }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoGrupoProduto of movimentacaoGrupoProdutos">
      <tr>
        <td>{{ movimentacaoGrupoProduto.loja }}</td>
        <td class="limitar">{{ movimentacaoGrupoProduto.grupo }}</td>
        <td class="text-right">{{ (movimentacaoGrupoProduto.percentualDia | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoGrupoProduto.valorTotalDia | monetario }}</td>
        <td class="text-right">{{ movimentacaoGrupoProduto.valorLucroDia | monetario }}</td>
        <td class="text-right">{{ (movimentacaoGrupoProduto.percentualLucroDia | monetario) + '%' }}</td>
        <td class="text-right">{{ (movimentacaoGrupoProduto.percentualMes | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoGrupoProduto.valorTotalMes | monetario }}</td>
        <td class="text-right">{{ movimentacaoGrupoProduto.valorLucroMes | monetario }}</td>
        <td class="text-right">{{ (movimentacaoGrupoProduto.percentualLucroMes | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoGrupoProdutos.length > 0">
    <tr>
      <td colspan="3"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoGrupoProdutos[0].totalValorDia | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoGrupoProdutos[0].totalLucroDia | monetario }}</strong>
      </td>
      <td colspan="2"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoGrupoProdutos[0].totalValorMes | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoGrupoProdutos[0].totalLucroMes | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
