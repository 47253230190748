<ng-container>
  <td class="p-0" colSpan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-2">{{ bibDialogo.cofins + ' - ' + bibDialogo.cstEntrada }}</th>
          <th class="r-2">{{ bibDialogo.cofins + ' - ' + bibDialogo.aliquotaEntrada }}</th>
          <th class="r-2">{{ bibDialogo.cofins + ' - ' + bibDialogo.cstSaida }}</th>
          <th class="r-2">{{ bibDialogo.cofins + ' - ' + bibDialogo.aliquotaSaida }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ produto.cofinsEntrada }}</td>
          <td>{{ produto.cofinsAliquotaEntrada }}</td>
          <td>{{ produto.cofinsSaida }}</td>
          <td>{{ produto.cofinsAliquotaSaida }}</td>
        </tr>
      </tbody>
    </table>
  </td>
</ng-container>
