<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.entregaProdutoMovimentacaoProduto }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r">{{ bibDialogo.produto }}</th>
    <th class="r-1-5">{{ bibDialogo.quantidadeEntregueAbreviacao }}</th>
    <th class="r-1-5">{{ bibDialogo.entrega }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let entregaProdutoMovimentacaoProduto of entregaProdutoMovimentacaoProdutos">
      <tr class="conteudo">
        <td>{{ entregaProdutoMovimentacaoProduto.id }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.data | data }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.numero }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.idLoja + ' - ' + entregaProdutoMovimentacaoProduto.loja }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.idEmpresa + ' - ' + entregaProdutoMovimentacaoProduto.empresa }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.idOperacao + ' - ' + entregaProdutoMovimentacaoProduto.operacao }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.produto }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.quantidadeEntregue | monetario }}</td>
        <td>{{ entregaProdutoMovimentacaoProduto.entregue | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
