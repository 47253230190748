import { Util } from '../utilitario/util';

export class BaixaMovimentacaoProduto {
  private valorAPagarProporcional: number = 0;
  public descontoRateado: number = 0;
  public id: number;
  public produto: string;
  public produtoNumero: number;
  public quantidade: number;
  public selecionado: string;
  public valorBaixa: number = 0;
  public valorFinal: number = 0;
  public valorRestante: number = 0;
  public valorTotal: number = 0;

  setValorAPagarProporcional(valorAPagarProporcional: number) {
    this.valorAPagarProporcional = new Util().arredondar(valorAPagarProporcional);
  }
  getValorAPagarProporcional(): number {
    return this.valorAPagarProporcional;
  }
  private _valorAPagarTotal: number = 0;
  setValorAPagarTotal(valorAPagarTotal: number) {
    this._valorAPagarTotal = new Util().arredondar(valorAPagarTotal);
  }
  getValorAPagarTotal(): number {
    return this._valorAPagarTotal;
  }
}
