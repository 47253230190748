import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1003.component.html',
})
export class R1003Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public movimentacaoProdutos: any[];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public usaDesconto: boolean = false;
  public usaParceiro: boolean = false;
  public usaTotalQuantidade: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    this.atualizarTotalizador();
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.verificarListagemPorProduto(criterios);
      this.posicionarTotalizador('quantidade', this.usaTotalQuantidade, this.usaParceiro ? 1 : 0);
      this.ordenarAgrupar(this.movimentacaoProdutos);
      this.ehImprimirRelatorio();
    });
  }

  verificarListagemPorProduto(criterios: Criterio[]): void {
    let contagemCriterioProduto: number = 0;
    criterios.forEach((criterio) => {
      if (criterio.nome == 'ID_PRODUTO' && criterio.valor != null) {
        contagemCriterioProduto++;
      }
    });
    this.usaTotalQuantidade = contagemCriterioProduto > 0;
  }

  atualizarTotalizador(): void {
    let valor: number = 0;
    valor += this.usaDesconto ? 0 : -1;
    valor += this.usaParceiro ? 0 : -1;
    this.posicionarTotalizador('valorFinal', true, valor);
    this.posicionarTotalizador('quantidade', this.usaTotalQuantidade, this.usaParceiro ? 1 : 0);
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaParceiro = this.definirRegra(relatorioAdicional, 164, this.usaParceiro);
    this.usaDesconto = this.definirRegra(relatorioAdicional, 165, this.usaDesconto);
    this.atualizarTotalizador();
    this.ehModoPaisagem();
  }

  ehModoPaisagem(): void {
    let contagemAdicionais: number = 0;
    this.usaParceiro ? contagemAdicionais++ : contagemAdicionais;
    if (contagemAdicionais > 0) {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', ''));
    } else {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', '', 'S'));
    }
  }

  agrupar(lista: any[], agrupa: any): void {
    this.usaTotalQuantidade = false;
    if (agrupa.rotulo == 'Produto') {
      this.usaTotalQuantidade = true;
    }
    this.atualizarTotalizador();
    super.agrupar(lista, agrupa);
  }
}
