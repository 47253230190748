<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.ocorrenciaMovimentacoes }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-0-5">{{ bibDialogo.vend }}</th>
    <th class="r-3-5">{{ bibDialogo.cliente }}</th>
    <th class="r">{{ bibDialogo.ocorrencia }}</th>
    <th class="r-1">{{ bibDialogo.responsavel }}</th>
    <th class="r-1">{{ bibDialogo.aprovado }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoOcorrencia of movimentacaoOcorrencias">
      <tr>
        <td>{{ movimentacaoOcorrencia.loja }}</td>
        <td>{{ movimentacaoOcorrencia.vendedor }}</td>
        <td class="limitar">{{ movimentacaoOcorrencia.clienteNumero + ' - ' + movimentacaoOcorrencia.cliente }}</td>
        <td class="limitar fonte-7pt">{{ movimentacaoOcorrencia.ocorrencia }}</td>
        <td class="limitar">{{ movimentacaoOcorrencia.responsavel }}</td>
        <td class="limitar">{{ movimentacaoOcorrencia.aprovado }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
