import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r2004itens]</tr>',
  templateUrl: './r2004itens.component.html',
})
export class R2004ItensComponent {
  @Input() roteiroContabeis: any[];
  public bibDialogo = bibDialogo;
}
