<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-30 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-20 clique" (click)="listar(add('bloqueadoEstoque'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.bloquear + ' ' + bibDialogo.estoque.toLowerCase() }}</th>
        <th class="l-20 clique" (click)="listar(add('bloqueadoFinanceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.bloqueadoFinanceiro }}</th>
        <th class="l-20 clique" (click)="listar(add('bloqueadoContabilidade'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.bloquearContabilidade }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let classificacao of classificacoes; let i = index">
        <tr [ngClass]="{ tachado: classificacao.ativo != 'S' }">
          <td>
            <a href="#" (click)="ir(classificacao.id, i, classificacoes)">{{ classificacao.nome }}</a>
          </td>
          <td>{{ classificacao.bloqueadoEstoque == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
          <td>{{ classificacao.bloqueadoFinanceiro == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
          <td>{{ classificacao.bloqueadoContabilidade == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(classificacao.id, i, classificacoes)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirClassificacao(classificacao.id, classificacao.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
