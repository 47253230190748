<div class="responsiva">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-70">{{ bibDialogo.nomeFantasia }}</th>
        <th class="l-20">{{ bibDialogo.abreviacao }}</th>
        <th class="l-10 text-center">{{ bibDialogo.permitir }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usuarioLoja of usuarioLojas; let i = index">
        <td class="limitar">{{ usuarioLoja.nome }}</td>
        <td>{{ usuarioLoja.abreviacao }}</td>
        <td class="text-center">
          <icone [icone]="usuarioLoja.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="usuarioLoja.acessar = usuarioLoja.acessar == 'S' ? 'N' : 'S'" [cor]="usuarioLoja.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza"></icone>
        </td>
      </tr>
    </tbody>
  </table>
</div>
