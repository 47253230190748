import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Utilitario } from 'src/app/modelo/utilitario';

@Component({
  selector: 'utilitario',
  templateUrl: './utilitario.component.html',
  styleUrls: ['./utilitario.component.css'],
})
export class UtilitarioComponent {
  @Input() cor: string;
  @Input() utilitario: Utilitario;
  @Input() atencao: string;
  @Output() utilitarioEmt = new EventEmitter<string>();

  colorir(): string {
    if (this.cor === 'amarelo') return 'fa-amarelo';
  }

  clicar(): void {
    this.utilitarioEmt.emit(this.utilitario.legenda);
  }
}
