<td class="p-0" colspan="4">
  <table class="tabela-complementar">
    <thead>
      <th class="r-1">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-5">{{ bibDialogo.parceiro }}</th>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.qtd }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.valorFinal }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos">
        <tr>
          <td>{{ movimentacaoProduto.loja }}</td>
          <td>{{ movimentacaoProduto.movimentacaoData | data }}</td>
          <td class="text-right">{{ movimentacaoProduto.movimentacaoNumero }}</td>
          <td>{{ movimentacaoProduto.parceiroNumero + ' - ' + movimentacaoProduto.parceiro }}</td>
          <td>{{ movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto }}</td>
          <td>{{ movimentacaoProduto.unidade }}</td>
          <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</td>
