<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-10 text-right">{{ bibDialogo.numero }}</th>
        <th class="l-10 text-right">{{ bibDialogo.data }}</th>
        <th class="l-20">{{ bibDialogo.loja }}</th>
        <th class="l-20">{{ menuTransportadora.apelido }}</th>
        <th class="l-20">{{ bibDialogo.motorista }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let romaneioEntrega of romaneioEntregas; let i = index">
        <tr>
          <td>
            <btnAdicional *ngIf="romaneioEntrega.status == 'S'" [icone]="bibIcone.cadeadoFechado" (btnAdicional)="abrirRomaneioEntrega(romaneioEntrega)" [ajuda]="bibDialogo.abrirRomaneioEntrega"></btnAdicional>
          </td>          
          <td class="l-10 text-right">{{ romaneioEntrega.numero }}</td>
          <td class="text-right">{{ romaneioEntrega.data | data }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(romaneioEntrega.id, i, romaneioEntregas)">{{ romaneioEntrega.loja }}</a>
          </td>    
          <td class="limitar"> {{ romaneioEntrega.parceiroTransportadora }} </td>
          <td class="limitar"> {{ romaneioEntrega.motoristaNome }} </td>     
          <td><btnAdicional [icone]="romaneioEntrega.status == 'S' ? bibIcone.olho : bibIcone.editar" (btnAdicional)="ir(romaneioEntrega.id, i, romaneioEntregas)"></btnAdicional></td>
          <td><btnAdicional *ngIf="romaneioEntrega.status != 'S'" [icone]="bibIcone.lixo" (btnAdicional)="excluirRomaneioEntrega(romaneioEntrega.id, romaneioEntrega.numero)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>