<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.vendedor }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.vendas }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.comissao }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorLiberado }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorNaoLiberado }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorPago }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorAguardando }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorTotalAPagar }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiro of parceiros; let i = index">
        <tr>
          <td>{{ parceiro.colaborador}}</td>
          <td class="text-right">{{ parceiro.vendas | monetario }}</td>
          <td class="text-right">{{ parceiro.valorComissao | monetario }}</td>
          <td class="text-right">{{ parceiro.valorLiberado | monetario }}</td>
          <td class="text-right">{{ parceiro.valorNaoLiberado | monetario }}</td>
          <td class="text-right">{{ parceiro.valorPago | monetario }}</td>
          <td class="text-right">{{ parceiro.valorAguardando | monetario }}</td>
          <td class="text-right">{{ parceiro.valorTotalPagar | monetario }}</td>
        </tr>
        <tr *ngIf="usaDetalheComissoes" r1268Comissao [parceiro]="parceiro"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="parceiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
