import { ProdutoFormuladoDose } from './produtoFormuladoDose';
import { ProdutoFormuladoRestricao } from './ProdutoFormuladoRestricao';

export class ProdutoFormulado {
  public ativo: string = 'S';
  public classeAgronomica: string;
  public classeAmbiental: string;
  public classeToxicologica: string;
  public corIcone: string;
  public corrosividade: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public desabilitado: boolean = false;
  public descricaoClasse: string;
  public ehIconeClasseToxicologica: boolean = false;
  public empresa: string;
  public formulacao: string;
  public grauToxicologica: string;
  public grupoEmbalagem: string;
  public grupoQuimico: string;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idsProdutoFormuladoDoseExcluir: number[] = [];
  public idsProdutoFormuladoRestricaoExcluir: number[] = [];
  public inflamabilidade: string;
  public ingredienteAtivo: string;
  public modoAcao: string;
  public nome: string;
  public nomeEmbarque: string;
  public nomeTecnico: string;
  public numeroOnu: string;
  public numeroRisco: string;
  public produtoFormuladoDoses: ProdutoFormuladoDose[] = [];
  public produtoFormuladoRestricoes: ProdutoFormuladoRestricao[] = [];
  public registro: string;
  public bula: string;
  public fichaEmergencia: string;
}
