import { Component, Input } from '@angular/core';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroNegociacao } from 'src/app/modelo/parceiroNegociacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'parceiroNegociacao',
  templateUrl: './parceiroNegociacao.component.html',
})
export class ParceiroNegociacaoComponent extends PaginaComponent {
  @Input() excluirNegociacoes: Number[] = [];
  @Input() negociacoes: Negociacao[];
  @Input() parceiro: Parceiro;
  public ehMinhaModal: boolean = false;
  public componente: any = null;

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const negociacoes: Negociacao[] = identificacao.conteudo;
        negociacoes.forEach((negociacao) => {
          if (this.parceiro.parceiroNegociacoes.filter((parceiroNegociacao) => parceiroNegociacao.idNegociacao == negociacao.id).length == 0) {
            let parceiro: ParceiroNegociacao = new ParceiroNegociacao(negociacao.id);
            parceiro.nome = negociacao.nome;
            this.parceiro.parceiroNegociacoes.push(parceiro);
            this.negociacoes.push(negociacao);
          }
        });
      }
    });
  }

  listarNegociacao(): void {
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.negociacao).subscribe((res) => {
      this.negociacoes = this.plainToClass(Negociacao, res) as any; 
      let todasNegociacoes = this.negociacoes;   
      this.negociacoes = this.negociacoes.filter((negociacao) => negociacao.restricao != 1);
      this.parceiro.parceiroNegociacoes.forEach((parceiroNegociacao) => {
        todasNegociacoes.forEach((negociacao) =>{
          if(parceiroNegociacao.idNegociacao == negociacao.id){
            this.negociacoes.push(negociacao);
          }
        })
      })
    });
  }

  excluirNegociacaoRestrita(i: number, id: number ,idNegociacao: number): void{
    this.ehExcluir(i, this.parceiro.parceiroNegociacoes, this.excluirNegociacoes, id);
    this.negociacoes = this.negociacoes.filter((negociacao) => negociacao.id != idNegociacao);
  }

  abrirPesquisa() {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('RESTRICAO', 1));
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.negociacao));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.parceiro.parceiroNegociacoes));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.negociacao));
    this.utilSessao.setIdentificacao(new Identificacao('criterios', criterios));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }
}
