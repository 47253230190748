<titulo class="col-sm p-0" [titulo]="bibDialogo.operacoes" [imagem]="bibImagem.nfe" [icone]="bibIcone.mais" (click)="adicionarClassificacaoOperacao()" [desabilitado]="bloquearPermissao(classificacao.id)"></titulo>
<div class="row">
  <p class="col-sm-12 pl-3" *ngIf="classificacao.classificacaoOperacoes.length == 0">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.operacao.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarClassificacaoOperacao()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="classificacao.classificacaoOperacoes.length > 0">
  <thead class="table tabela-formulario"></thead>
  <tbody>
    <tr *ngFor="let classificacaoOperacao of classificacao.classificacaoOperacoes; let i = index">
      <td>{{ classificacaoOperacao.operacao }}</td>
      <td></td>
      <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, classificacao.classificacaoOperacoes, idsClassificacaoOperacaoExcluir, classificacaoOperacao.id)"></btnAdicional></td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
