import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r2026itens]</tr>',
  templateUrl: './r2026itens.component.html',
})
export class R2026ItensComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() romaneioEntregaProdutoMov: any;
  public bibDialogo = bibDialogo;
}
