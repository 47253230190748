import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.sim, id: 'S' },
  { nome: bibDialogo.nao, id: 'N' },
]);

const filtroComboHistorico: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.comMovimento, id: 'S' },
  { nome: bibDialogo.semMovimento, id: 'N' },
]);

const filtroComboFormacaoAgricola: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.agronomo, id: 'A' },
  { nome: bibDialogo.tecnicoAgricola, id: 'T' },
]);

const filtros = {
  cliente: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numeroClienteAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'nomeFiltro', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
    { id: 'cpf', rotulo: bibDialogo.cpf, nome: 'CNPJ_CPF', coluna: 12, servicoWeb: bibServico.cpf, tipo: bibPropriedade.filtro.mascara },
    { id: 'lojaFiltro', rotulo: bibDialogo.lojaCadastro, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'regiao', rotulo: bibDialogo.regiao, nome: 'ID_REGIAO', coluna: 6, servicoWeb: bibServico.regiao, tipo: bibPropriedade.filtro.busca },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
    { id: 'historico', rotulo: bibDialogo.historico, nome: 'HISTORICO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboHistorico },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
  ]),
  colaborador: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numeroColaboradorAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
    { id: 'cpf', rotulo: bibDialogo.cpf, nome: 'CNPJ_CPF', coluna: 12, servicoWeb: bibServico.cpf, tipo: bibPropriedade.filtro.mascara },
    { id: 'regiao', rotulo: bibDialogo.regiao, nome: 'ID_REGIAO', coluna: 6, servicoWeb: bibServico.regiao, tipo: bibPropriedade.filtro.busca },
    { id: 'formacaoAgricola', rotulo: bibDialogo.formacao + ' ' + bibDialogo.agricola.toLowerCase(), nome: 'AGRONOMO_TECNICO', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '', lista: filtroComboFormacaoAgricola },
    { id: 'lojaFiltro', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  ]),
  fornecedor: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numeroFornecedorAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
    { id: 'cpfCnpj', rotulo: bibDialogo.cpf, nome: 'CNPJ_CPF', coluna: 12, servicoWeb: bibServico.cpf, tipo: bibPropriedade.filtro.mascara },
    { id: 'lojaFiltro', rotulo: bibDialogo.lojaCadastro, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'regiao', rotulo: bibDialogo.regiao, nome: 'ID_REGIAO', coluna: 6, servicoWeb: bibServico.regiao, tipo: bibPropriedade.filtro.busca },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
    { id: 'historico', rotulo: bibDialogo.historico, nome: 'HISTORICO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboHistorico },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
  ]),
  transportadora: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numeroTransportadoraAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
    { id: 'cpf', rotulo: bibDialogo.cpf, nome: 'CNPJ_CPF', coluna: 12, servicoWeb: bibServico.cpf, tipo: bibPropriedade.filtro.mascara },
    { id: 'loja', rotulo: bibDialogo.lojaCadastro, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'regiao', rotulo: bibDialogo.regiao, nome: 'ID_REGIAO', coluna: 6, servicoWeb: bibServico.regiao, tipo: bibPropriedade.filtro.busca },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
    { id: 'historico', rotulo: bibDialogo.historico, nome: 'HISTORICO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboHistorico },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
  ]),
};

export default filtros;
