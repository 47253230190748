<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-30 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.descricao }}</th>
        <th class="l-45 clique" (click)="listar(add('cstIcms'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cst }}</th>
        <th class="l-15 clique" (click)="listar(add('base'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.baseCalculo }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let classificacaoFiscal of classificacaoFiscais; let i = index">
        <tr [ngClass]="{ tachado: classificacaoFiscal.ativo != 'S' }">
          <td class="limitar">
            <a href="#" (click)="ir(classificacaoFiscal.id, i, classificacaoFiscais)">{{ classificacaoFiscal.nome }}</a>
          </td>
          <td class="limitar">{{ classificacaoFiscal.cstIcms }}</td>
          <td>{{ classificacaoFiscal.base + '%' }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(classificacaoFiscal.id, i, classificacaoFiscais)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirCidade(classificacaoFiscal.id, classificacaoFiscal.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
