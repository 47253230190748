<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.valorLiquidoMovimentacaoParFinanceiro }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.valorEsperado }}</th>
    <th class="r-1">{{ bibDialogo.valorEncontrado }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let valorLiquidoMovimentacaoParFinanceiro of valorLiquidoMovimentacaoParFinanceiros">
      <tr class="conteudo">
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.id }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.data | data }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.numero }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.idLoja + ' - ' + valorLiquidoMovimentacaoParFinanceiro.loja }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.idEmpresa + ' - ' + valorLiquidoMovimentacaoParFinanceiro.empresa }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.valorEsperado | monetario }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.valorEncontrado | monetario }}</td>
        <td>{{ valorLiquidoMovimentacaoParFinanceiro.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
