import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015EstoqueHistorico',
  templateUrl: './r2015EstoqueHistorico.component.html',
})
export class R2015EstoqueHistorico {
  @Input() estoqueHistoricos: any[] = [];
  public bibDialogo = bibDialogo;
}
