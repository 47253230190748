import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const criterioPedido: Criterio[] = [new Criterio('IDS', '2,7,18')];

const filtroSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.faturamentoNaoFinalizado, id: 1 },
  { nome: '02 - ' + bibDialogo.faturamentoFinalizado, id: 2 },
  { nome: '03 - ' + bibDialogo.aguardandoEntrega, id: '3' },
  { nome: '04 - ' + bibDialogo.todos, id: '-' },

]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.clienteFornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'PRODUTO_NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.produto, nome: 'PRODUTO_NOME', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO_CARTEIRA', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 1, lista: filtroSituacao },
  { rotulo: bibDialogo.operacao, nome: 'IDS_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.checklista, obrigatorio: 'S', criterios: criterioPedido },
  { nome: 'PERMITE_FATURAMENTO', valor: '1', tipo: bibPropriedade.filtro.valorPadrao },
]);
export default filtros;
