<div [ngClass]="{ 'form-inline': compacto }">
  <div class="col-sm" *ngIf="rotulo" [ngClass]="compacto ? 'rotulo-compacto' : ''" [ngClass]="compacto && vertical ? filtro ? 'rotulo-vertical-filtro' : 'rotulo-vertical' : ''">
    <rotulo [ajudaDireita]="ajudaDireita" [ajuda]="ajuda" [rotulo]="rotulo" [idMenu]="idMenu" [campoFor]="campoFor" [obrigatorio]="obrigatorio" [preenchido]="preenchido" [atencao]="atencaoMensagem" [compacto]="compacto"> </rotulo>
  </div>
  <div class="col-sm" [ngClass]="[ehModoClaro ? '' : 'modo-escuro', borda ? 'input-group p-0' : '', desabilitado && ehModoClaro ? 'desabilitado' : desabilitado && !ehModoClaro ? 'desabilitado-modo-escuro' : '']">
    <div class="input-group-prepend" *ngIf="prefixo">
      <span class="input-group-text prefixo-sufixo" id="basic-addon2">{{ prefixo }}</span>
    </div>
    <ng-content></ng-content>
    <obrigatorio class="align-self-center mr-md-1" *ngIf="(!rotulo || compacto) && obrigatorio" [obrigatorio]="obrigatorio" [preenchido]="preenchido" [compacto]="true"> </obrigatorio>
    <atencao class="align-self-center mr-md-1" *ngIf="(!rotulo || compacto) && atencaoMensagem" [atencao]="atencaoMensagem" [tipo]="bibPropriedade.atencao.alerta" [compacto]="true"> </atencao>
    <div class="input-group-append" *ngIf="sufixo">
      <span class="input-group-text prefixo-sufixo" id="basic-addon2">{{ sufixo }}</span>
    </div>
    <btnAdicional [compacto]="compacto" [compactoBotao]="compactoBotao" [icone]="icone" [ajuda]="ajuda" [desabilitado]="desabilitado && iconeDesabilitado" (btnAdicional)="clicarBtnAdicional()"></btnAdicional>
  </div>
</div>
