import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ParceiroVeiculo } from 'src/app/modelo/parceiroVeiculo';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './parceiroVeiculoFrm.component.html',
})
export class ParceiroVeiculoFrmComponent extends PaginaComponent {
  public componente: any;
  public parceiroVeiculo: ParceiroVeiculo = new ParceiroVeiculo();
  public estados: any[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarEstado();
  }

  ehAlteracao(): void {
    if (this.utilSessao.getIdentificacao('objetoVeiculo')?.conteudo != null) {
      this.parceiroVeiculo = this.utilSessao.getIdentificacao('objetoVeiculo')?.conteudo;
    }
    this.utilSessao.excluirIdentificacao('objetoVeiculo');
  }

  listarEstado(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  persistirParceiroVeiculo(): void {
    if (this.ehValidoObrigatorio() && this.ehPlacaValida() && this.ehValidoCpf()) {
      this.parceiroVeiculo.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.parceiroVeiculo, this.parceiroVeiculo));
    }
  }

  ehPlacaValida(): boolean {
    if (this.parceiroVeiculo.placa.length > 0) {
      for (let index = 0; index < this.parceiroVeiculo.placa.length; index++) {
        if (!this.parceiroVeiculo.placa[index].match('[A-Za-z0-9]')) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.placa + ' ' + this.bibDialogo.somenteLetraNumero.toLowerCase()));
          return false;
        }
      }
    }
    return true;
  }

  ehValidoObrigatorio(): boolean {
    if (this.parceiroVeiculo.nome && this.parceiroVeiculo.idEstado && this.parceiroVeiculo.placa && this.parceiroVeiculo.renavan) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEstado(estado: Estado): void {
    this.parceiroVeiculo.idEstado = estado.id;
    this.parceiroVeiculo.estado = estado.nome;
  }

  setCpf(parceiroVeiculo: ParceiroVeiculo, valor: string): void {
    parceiroVeiculo.motoristaCpf = valor == '' ? null : valor;
    parceiroVeiculo.ehValidoCpf = this.util.validarCnpjCpf(parceiroVeiculo.motoristaCpf);
  }

  ehValidoCpf(): boolean {
    if (this.util.validarCnpjCpf(this.parceiroVeiculo.motoristaCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cpfIncorreto));
    return false;
  }
}
