import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2017.component.html',
})
export class R2017Component extends RelatorioComponent {
  public filtros = filtros;
  public produtos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.produtos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Produto').lista;
      this.produtos.forEach((produto) => (produto.produtosMovimentacoes = []));
    });
  }

  expandir(produto: any) {
    produto.expandido = !produto.expandido;
    if (produto.expandido && produto.produtosMovimentacoes.length == 0) {
      this.listarEspecifico(new Criterio('ID_PRODUTO', produto.idProduto), 159).subscribe((res) => {
        produto.produtosMovimentacoes = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }
}
