import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Receituario } from '../../modelo/receituario';
import filtros from './filtro';

@Component({
  templateUrl: './receituario.component.html',
})
export class ReceituarioComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public idLojas: string;
  public movimentacoes: Movimentacao[] = [];

  ngOnInit(): void {
    this.ehListarAtalhoMovimentacao();
  }

  ehListarAtalhoMovimentacao(): void {
    const idMovimentacao: number = this.utilSessao.getIdentificacao('idMovimentacao')?.conteudo;
    if (idMovimentacao) {
      this.listar([], 0, 0, idMovimentacao);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, idMovimentacao: number = null): void {
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('FINALIZADA_CANCELADA', 'S'));
    criterios.push(new Criterio('PRODUTO_FORMULADO', 'S'));
    criterios.push(new Criterio('ENTRADA_SAIDA_INTERNA', -1));
    criterios.push(new Criterio('ID', idMovimentacao));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
    });
  }

  abrirReceituario(movimentacao: Movimentacao) {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_MOVIMENTACAO', movimentacao.id)]), this.bibServico.receituario).subscribe((res) => {
      const receituarios: Receituario[] = this.plainToClass(Receituario, res) as any;
      for (const receituario of receituarios) {
        receituario.status = 'A';
      }
      this.persistirReceituario(movimentacao, receituarios);
    });
  }

  persistirReceituario(movimentacao: Movimentacao, receituarios: Receituario[]): void {
    super.persistir(new Transporte(receituarios), this.bibServico.receituario, null).subscribe((res) => {
      if (!this.utilSessao.falha) {
        movimentacao.receituarioPendente = null;
      }
    });
  }
}
