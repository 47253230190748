<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(receituarios, $event)" (campoGrupoEmt)="agrupar(receituarios, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-0-5 text-right">{{ bibDialogo.nfe }}</th>
      <th class="r">{{ bibDialogo.cliente }}</th>
      <th class="r-3">{{ bibDialogo.agronomo }}</th>
      <th class="r-1-5">{{ bibDialogo.art }}</th>
      <th class="r-3">{{ bibDialogo.produto }}</th>
      <th class="r-1 text-right">{{ bibDialogo.receita }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let receituario of receituarios; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>        
        <tr class="conteudo" [ngClass]="{ tachado: receituario.ativo != 'S' }">
          <td>{{ receituario.abreviacao }}</td>
          <td>{{ receituario.data | data }}</td>
          <td class="text-right">{{ receituario.documentoFiscal }}</td>
          <td>{{ receituario.numeroParceiro + ' - ' + receituario.parceiro }}</td>
          <td>{{ receituario.numeroColaborador + ' - ' + receituario.colaborador }}</td>
          <td>{{ receituario.art }}</td>
          <td>{{ receituario.numeroProduto + ' - ' + receituario.produto }}</td>
          <td class="text-right">{{ receituario.numero }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="receituarios.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
