import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BibServicoMdfeTecnoSpeed } from '../biblioteca/bibServicoMdfeTecnoSpeed';

@Injectable()
export class ComunicacaoTecnoSpeedMDFeService {
  private token: string = 'Basic YWRtaW46QzM5RTJEMTRCREU4NkMxQTg1Qjg2NDQzMTQ1NEVBNEU='; //produção
  private cnpjSh: string = '09647414000181';
  private grupo: string = 'PLUG_09647414000181';

  constructor(private http: HttpClient) {}

  listar(url: string, cnpjEmitente: string): Observable<any> {
    return this.consumir(url, null, cnpjEmitente, 'GET');
  }

  consultar(url: string, parametros: any, cnpjEmitente: string): Observable<any> {
    return this.consumir(url, parametros, cnpjEmitente, 'GET');
  }

  persistirLoja(url: string, secao: string, campo: string, parametros: any, cnpjEmitente: string): Observable<any> {
    url += '?Grupo=' + this.grupo;
    url += '&CNPJ=' + cnpjEmitente;
    url += '&Secao=' + secao;
    url += '&' + campo + '=' + parametros;

    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', this.token).set('Accept', '*/*');
    return this.http.get(url, { responseType: 'text', headers: headers });
  }

  persistir(url: string, parametros: any, cnpjEmitente: string): Observable<any> {
    return this.consumir(url, parametros, cnpjEmitente, 'POST');
  }

  persistirEncerramento(url: string, parametros: any, cnpjEmitente: string, cidadeEncerramento: string, dataEncerramento: string): Observable<any> {
    let body = new URLSearchParams();
    body.set('Grupo', this.grupo);
    body.set('CNPJ', cnpjEmitente);
    body.set('ChaveNota', parametros);
    body.set('SequenciaEvento', '1');
    body.set('DataEncerramento', dataEncerramento);
    body.set('CodUfEncerramento', cidadeEncerramento.substr(0, 2));
    body.set('CodMunicipioEncerramento', cidadeEncerramento);
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', this.token).set('Accept', '*/*');
    return this.http.post(url, body.toString(), { headers });
  }

  enviarEmail(url: string, parametros: any, cnpjEmitente: string, chave: string, numero: number): Observable<any> {
    let body = new URLSearchParams();
    body.set('Grupo', this.grupo);
    body.set('CNPJ', cnpjEmitente);
    body.set('EmailDestinatario', parametros);
    body.set('ChaveNota', chave);
    body.set('Assunto', 'MDF-e ' + numero);
    body.set('Texto', 'Segue em anexo o XML e PDF');
    body.set('AnexaPDF', '1');
    body.set('ConteudoHTML', '0');
    body.set('EmailCCo', '');
    body.set('EmailCC', '');
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', this.token).set('Accept', '*/*');
    return this.http.post(url, body.toString(), { headers });
  }

  deletar(url: string, parametros: any, cnpjEmitente: string): Observable<any> {
    return this.consumir(url, parametros, cnpjEmitente, 'DELETE');
  }

  retornaBody(url: string, cnpjEmitente: string, parametros: string): URLSearchParams {
    let bibServicoMdfeTecnoSpeed: BibServicoMdfeTecnoSpeed = new BibServicoMdfeTecnoSpeed();
    let servico: string = url.replace(bibServicoMdfeTecnoSpeed.url + '/mdfe/', '');
    let body = new URLSearchParams();

    if (servico == 'envia') {
      body.set('Grupo', this.grupo);
      body.set('CNPJ', cnpjEmitente);
      body.set('Arquivo', parametros);
    }
    if (servico == 'cancela') {
      body.set('Grupo', this.grupo);
      body.set('CNPJ', cnpjEmitente);
      body.set('ChaveNota', parametros);
      body.set('Justificativa', 'Justificativa do cancelamento do MDFe');
      body.set('Fuso', '-03:00');
    }

    if (servico == 'descarta') {
      body.set('Grupo', this.grupo);
      body.set('CNPJ', cnpjEmitente);
      body.set('ChaveNota', parametros);
    }
    return body;
  }

  retornaQuerystring(url: string, cnpjEmitente: string, parametros: string): string {
    let bibServicoMdfeTecnoSpeed: BibServicoMdfeTecnoSpeed = new BibServicoMdfeTecnoSpeed();
    let servico: string = url.replace(bibServicoMdfeTecnoSpeed.url + '/mdfe/', '');

    if (servico == 'imprime') {
      url += '?Grupo=' + this.grupo;
      url += '&CNPJ=' + cnpjEmitente;
      url += '&ChaveNota=' + parametros;
      url += '&Url=0';
    }
    if (servico == 'consulta') {
      url += '?Grupo=' + this.grupo;
      url += '&CNPJ=' + cnpjEmitente;
      url += '&Filtro=codmdf=' + parametros;
      url += '&Campos=situacao, chave, nprotenvio, numero, cstat, motivo, nprotcanc';
      url += '&Limite=100';
      url += '&Ordem=nrecibo desc';
      url += '&Visao=TspdMDFeVWConsulta';
      url += '&encode=true';
    }

    return url;
  }

  private consumir(url: string, parametros: any, cnpjEmitente: string, metodo: string): Observable<any> {
    let body = this.retornaBody(url, cnpjEmitente, parametros);
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', this.token).set('Accept', '*/*');
    if (metodo == 'POST') {
      return this.http.post(url, body.toString(), { headers });
    } else if (metodo == 'GET') {
      url = this.retornaQuerystring(url, cnpjEmitente, parametros);
      return this.http.get(url, { responseType: 'text', headers: headers });
    }
  }

  consumirPdf(url: string, cnpjEmitente: string, parametros: string): Observable<Blob> {
    url = this.retornaQuerystring(url, cnpjEmitente, parametros);
    let headers: HttpHeaders = new HttpHeaders().set('Authorization', this.token);
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

  consumirXml(url: string, cnpjEmitente: string, parametros: string, xmlTipo: string): Observable<any> {
    url += '?Grupo=' + this.grupo;
    url += '&CNPJ=' + cnpjEmitente;
    url += '&ChaveNota=' + parametros;
    xmlTipo != '' ? (url += '&Documento=' + xmlTipo) : '';
    let headers: HttpHeaders = new HttpHeaders().set('Authorization', this.token).set('Accept', 'application/octet-stream');
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

  persistirLogotipo(url: string, parametros: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Accept', 'multipart/form-data');
    return this.http.post(url, parametros, { headers: headers });
  }

  deletarLogotipo(url: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Content-Type', 'application/json');
    return this.http.delete(url, { headers: headers });
  }
}
