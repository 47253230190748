import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1125.component.html',
})
export class R1125Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public produtoIcmsEntradaSaidas: any[] = [];
  public produtoIcmsSts: any[] = [];
  public produtos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.produtos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Produto').lista;
      this.produtoIcmsSts = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ProdutoIcmsSt').lista;
      this.produtoIcmsEntradaSaidas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ProdutoIcmsEntradaSaida').lista;
      this.ordenarAgrupar(this.produtos);
      this.vincular();
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.produtos.forEach((produto) => {
      produto.produtoIcmsEntradas = this.produtoIcmsEntradaSaidas.filter((produtoIcmsEntrada) => produtoIcmsEntrada.idProduto == produto.id && produtoIcmsEntrada.tipo == 1);
      produto.produtoIcmsSaidas = this.produtoIcmsEntradaSaidas.filter((produtoIcmsSaida) => produtoIcmsSaida.idProduto == produto.id );
      produto.produtoIcmsSaidas.forEach(produtoIcmsSaida => {
        if(['030', '040', '041', '050', '060'].includes(produtoIcmsSaida.cst)){
          produtoIcmsSaida.aliquotaFisica = 0;
          produtoIcmsSaida.aliquotaJuridica = 0;
        }              
      });
      produto.produtoIcmsSts = this.produtoIcmsSts.filter((produtoIcmsSt) => produtoIcmsSt.idProduto == produto.id);
    });
  }
}
