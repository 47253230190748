import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.clientePagouNaoRepassadoAoVendedor, id: 'P' },
  { nome: bibDialogo.valorFaltantePagarVendedor, id: 'F' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataMoviDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.dataMovimentacao },
  { rotulo: bibDialogo.dataMoviAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.dataMovimentacao },
  { rotulo: bibDialogo.dataLiberacaoDe, nome: 'DATA_LIBERACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.ajudaComissaoLiberadaConciliacao },
  { rotulo: bibDialogo.dataLiberacaoAte, nome: 'DATA_LIBERACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.ajudaComissaoLiberadaConciliacao },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'P', lista: filtroComboSituacao },
]);
export default filtros;
