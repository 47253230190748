export class Cfop {
  private operacao: string;
  public ativo: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricaoNfe: string;
  public entradaSaida: string;
  public id: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;

  constructor(id: number, nome: string) {
    this.id = id;
    this.nome = nome;
  }
}
