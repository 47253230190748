import { Type } from 'class-transformer';

export class Cheque {
  @Type(() => Date)
  public data: Date = new Date();
  @Type(() => Date)
  public dataFinalizado: Date;
  @Type(() => Date)
  public dataRepasse: Date;
  @Type(() => Date)
  public dataPreDatada: Date;

  public abreviacao: string;
  public agenciaBanco: string;
  public contaAtual: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public emitente: string;
  public finalizado: string = 'N';
  public id: number;
  public idConciliacao: number;
  public idConciliacaoRepasse: number;
  public idContaAtual: number;
  public idContaInicial: number;
  public idEmpresa: number;
  public idLoja: number;
  public idParceiro: number;
  public idParceiroDestino: number;
  public idTransferenciaCheque: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public loja: string;
  public nominal: string;
  public numero: string;
  public numeroBanco: string;
  public numeroConta: string;
  public observacao: string;
  public parceiro: string;
  public parceiroDestino: string;
  public selecionado: string = 'N';
  public tipo: string = 'R';
  public valor: number;
}
