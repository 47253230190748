<layout [titulo]="clienteEspecial.nome" [id]="clienteEspecial.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="clienteEspecial.nome" (alteracao)="clienteEspecial.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="clienteEspecial.ativo" (alteracao)="clienteEspecial.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm-4" [id]="'precificacao'" [rotulo]="bibDialogo.precificacao" [campo]="clienteEspecial.precificacao" [lista]="listaPrecificacao" (alteracao)="setPrecificacao($event.id)" [obrigatorio]="true"></lista>
    <decimal class="col-sm" [id]="'percentual'" [rotulo]="bibDialogo.percentual" [campo]="clienteEspecial.percentual" (alteracao)="clienteEspecial.percentual = $event" [prefixo]="clienteEspecial.precificacao == 4 ? '-' : '+'" [numeroCasasDecimais]="2" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div>
</layout>
<botao [id]="'salvar'" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirClienteEspecial()" [desabilitado]="bloquearPermissao(clienteEspecial.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirClienteEspecial(true)" [desabilitado]="bloquearPermissao(clienteEspecial.id)"></botao>
<auditoria *ngIf="clienteEspecial.id" [idUsuarioInclusao]="clienteEspecial.idUsuarioInclusao" [dataHoraInclusao]="clienteEspecial.dataHoraInclusao" [idUsuarioAlteracao]="clienteEspecial.idUsuarioAlteracao" [dataHoraAlteracao]="clienteEspecial.dataHoraAlteracao"></auditoria>
