<layout [titulo]="generico.nome" [id]="generico.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto [id]="'nome'" class="col-sm" [rotulo]="bibDialogo.nome" [campo]="generico.nome" (alteracao)="generico.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="generico.ativo" (alteracao)="generico.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <lista *ngIf="usaLoja" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="generico.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="generico.idLoja = $event.id"> </lista>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGenerico()" [desabilitado]="bloquearPermissao(generico.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirGenerico(true)" [desabilitado]="bloquearPermissao(generico.id)"></botao>
<auditoria *ngIf="generico.id" [idUsuarioInclusao]="generico.idUsuarioInclusao" [dataHoraInclusao]="generico.dataHoraInclusao" [idUsuarioAlteracao]="generico.idUsuarioAlteracao" [dataHoraAlteracao]="generico.dataHoraAlteracao"></auditoria>
