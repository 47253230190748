<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (filtroEmt)="listarRelatorio($event)">
  <table> 
    <thead>
      <th class="r-1-5">{{ bibDialogo.numeracao }}</th>
      <th class="r">{{ bibDialogo.conta }}</th>
      <th *ngIf="ehRelatorio2005 || ocultarSaldoAnterior" class="r-1-5 text-right">{{ bibDialogo.saldoAnterior }}</th>
      <th *ngIf="ehRelatorio2005" class="r-1-5 text-right">{{ bibDialogo.debito }}</th>
      <th *ngIf="ehRelatorio2005" class="r-1-5 text-right">{{ bibDialogo.credito }}</th>
      <th *ngIf="ehRelatorio2010" class="r-1-5 text-right">{{ bibDialogo.total }}</th> 
      <th *ngIf="ehRelatorio2005 || ehRelatorio2007" class="r-1-5 text-right">{{ bibDialogo.saldoAtual }}</th>
      <th *ngIf="ehRelatorio2005" class="r-0-5"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let contaContabil of contaContabeis; let i = index">
        <cabecalhoGrupo [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr>
          <td>{{ contaContabil.numeracao }}</td>
          <td [ngClass]="contaContabil.pai == 'S' || (contaContabil.correntistas && contaContabil.correntistas.length > 0) || (contaContabil.correntistasParceiros && contaContabil.correntistasParceiros.length > 0) ? 'negrito' : ''">
            {{ contaContabil.nome.split(' ').join('&nbsp;') }}
          </td>
          <td *ngIf="ehRelatorio2005 || ocultarSaldoAnterior" class="text-right">{{ contaContabil.saldoAnterior | monetario }}</td>
          <td *ngIf="ehRelatorio2005" class="text-right">{{ contaContabil.debito | monetario }}</td>
          <td *ngIf="ehRelatorio2005" class="text-right">{{ contaContabil.credito | monetario }}</td>
          <td *ngIf="ehRelatorio2010" class="text-right">{{ contaContabil.debito - contaContabil.credito | monetario }}</td>         
          <td *ngIf="ehRelatorio2005 || ehRelatorio2007" class="text-right">{{ contaContabil.saldoAtual | monetario }}</td>
          <td class="naoImprimir" *ngIf="contaContabil.correntista && contaContabil.correntistasParceiros && contaContabil.correntista > 1 && contaContabil.correntistasParceiros.length > 0"><icone class="justify-content-end" [icone]="contaContabil.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (iconeEmt)="this.expandir(contaContabil)" [clique]="true" [mensagem]="(contaContabil.expandido ?  bibDialogo.ocultar : bibDialogo.expandir ) + ' ' +  bibDialogo.correntista "></icone></td>
        </tr>
        <tr *ngIf="contaContabil.correntista > 1 && contaContabil.expandido && contaContabil.correntistas.length > 0 || (contaContabil.correntista > 1 && contaContabil.expandido && contaContabil.correntistasParceiros.length > 0)" r2005correntistas class="sem-borda" [usaCorrentista]="true" [correntistas]="contaContabil.correntista == 2 ? contaContabil.correntistasParceiros : contaContabil.correntistas"></tr>
      </ng-container>
    </tbody>
  </table>

  <table class="tabela-totalizador" *ngIf="ehRelatorio2010 && contaContabilPatrimonialDRe.length > 0">
    <ng-container *ngFor="let contaContabilDRe of contaContabilPatrimonialDRe; let i = index">
      <div class="row pt-2">
        <th class="r-2-5 text-right">{{ contaContabilDRe.nome }} </th>
        <th class="r-1-5 text-right">{{ contaContabilDRe.valor | monetario }}</th>
      </div>   
    </ng-container>
    <div class="row pt-2">
    <th class="r-2-5 text-right">{{ bibDialogo.valorLucro }} </th>
    <th class="r-1-5 text-right">{{ totalGeral | monetario }}</th>  
    </div>    
  </table>

  <div *ngIf="ehRelatorio2007 && responsavelContador.length > 0" class="quebra-pagina">
  </div> 

  <div  *ngIf="ehRelatorio2007 && responsavelContador.length > 0">
    <div> 
      <div class="row justify-content-center text-center pt-5">    
        <div class="r-5">
          <span class="text-center r">{{ this.util.substituir(bibDialogo.patrimonialDRe, [relatorio.nome, contaContabilNomes, totalGeral | monetario, this.util.escreverValor(totalGeral)]) }} </span>   
        </div>  
      </div> 
      <div class="row justify-content-center pt-5">
        <div class="r-5">
          <div class="border-top border-dark r-5 relatorio-assinatura text-center">{{ responsavelContador[0].responsavel }}</div>
          <p class="r-5 text-center">{{ bibDialogo.administrador }}</p>
        </div>             
        <div class="pl-5 r-6">
          <div class="border-top border-dark r-5 relatorio-assinatura text-center">{{ responsavelContador[0].contador }}</div>
          <p class="r-5 text-center mb-0">{{ bibDialogo.contadorMaiscula }} </p>
          <p class="r-5 text-center mb-0">{{ bibDialogo.cpf + ': ' + responsavelContador[0].contadorCpf + ' ' + bibDialogo.crc + ' ' + responsavelContador[0].contadorCrc }}</p>
        </div>
      </div>     
      <div class="row pt-3">
        <span class="r text-center">{{ dataRodape }} </span>
      </div>   
    </div>  
  </div>
</relatorioLayout>
