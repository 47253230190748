import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

export class Filtros4 {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.contaBancaria, nome: 'IDS_CONTA_CONTABIL', obrigatorio: 'N', coluna: 6, tipo: bibPropriedade.filtro.checklista, servicoWeb: bibServico.contaBancariaCorrentista, visualizacaoInicial: true, ajuda: bibDialogo.ajudaFiltroBloqueadoContaBancaria },
    { rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, valor: '2', tipo: bibPropriedade.filtro.lista, visualizacaoInicial: false, criterios: criterioTipoLancamento },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  ]);
}
