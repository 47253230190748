export class NsSatEmitente {
    public registro: string = 'C';
    public reservado1: string;
    public reservado2: string;
    public IE: string;
    public reservado3: string;
    public IM: string;
    public separador: string;
    public cRegTribISSQN: string;
    public indRatISSQN: string;
    public cNPJ: string;
}