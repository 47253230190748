import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './negociacaoFrm.component.html',
})
export class NegociacaoFrmComponent extends PaginaComponent {
  public bloqueiaFormaFechamento: boolean = false;
  public negociacao: Negociacao = new Negociacao();
  public negociacoes: Negociacao[] = [];
  public tipoTitulos: TipoTitulo[] = [];

  public restricoes: any[] = [
    { id: '2', nome: this.bibDialogo.nenhuma.toUpperCase() },
    { id: '1', nome: this.bibDialogo.porCliente.toUpperCase() },
  ];

  public listaSimNao: any[] = [
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
  ];

  public modalidades: any[] = [
    { id: '1', nome: this.bibDialogo.aVista.toUpperCase() },
    { id: '2', nome: this.bibDialogo.aPrazo.toUpperCase() },
  ];

  public formaFechamento: any[] = [
    { id: 'N', nome: this.bibDialogo.nenhum.toUpperCase() },
    { id: 'H', nome: this.bibDialogo.hoje.toUpperCase() },
    { id: 'U', nome: this.bibDialogo.ultimoDiaMes.toUpperCase() },
    { id: 'C', nome: this.bibDialogo.regraDoCliente.toUpperCase() },
  ];

  public diaGeracao: any[] = [
    { id: 8, nome: '00 - ' + this.bibDialogo.nenhum.toUpperCase() },
    { id: 2, nome: '02 - ' + this.bibDialogo.segundaFeira.toUpperCase() },
    { id: 3, nome: '03 - ' + this.bibDialogo.tercaFeira.toUpperCase() },
    { id: 4, nome: '04 - ' + this.bibDialogo.quartaFeira.toUpperCase() },
    { id: 5, nome: '05 - ' + this.bibDialogo.quintaFeira.toUpperCase() },
    { id: 6, nome: '06 - ' + this.bibDialogo.sextaFeira.toUpperCase() },
    { id: 7, nome: '07 - ' + this.bibDialogo.sabado.toUpperCase() },
  ];

  public permiteEntregaDireta: any[] = [
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
  ];

  public especies: any[] = [
    // { id: 'C', nome: this.bibDialogo.credito.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.substituicao.toUpperCase() },
    { id: 'V', nome: this.bibDialogo.valor.toUpperCase() },
  ];

  public entradaSaida: any[] = [
    { id: -1, nome: this.bibDialogo.venda.toUpperCase() },
    { id: 3, nome: this.bibDialogo.devolucao.toUpperCase() },
    { id: 4, nome: this.bibDialogo.vendaDevolucao.toUpperCase() },
  ];

  ngOnInit(): void {
    this.listarTipoTitulo();
    this.ehAlteracao();
  }

  listarTipoTitulo(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoTitulo).subscribe((res) => {
      this.tipoTitulos = this.plainToClass(TipoTitulo, res) as any;
    });
  }

  ehAlteracao(): void {
    this.negociacao = this.plainToClass(Negociacao, this.ehAtualizacao(this.negociacao));
    if (this.negociacao.id) {
      const criterios: Criterio[] = [];
      criterios.push(new Criterio('ID', this.negociacao.id));
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.negociacao).subscribe((res) => {
        this.negociacao = this.plainToClass(Negociacao, res[0]) as any;
        this.negociacoes.push(this.negociacao);
        this.calcularTaxaMensal();
      });
    } else {
      this.negociacoes.push(this.negociacao);
    }
  }

  calcularTaxaMensal(): void {
    this.negociacao.penalidadeTaxaMensal = this.negociacao.penalidadeTaxaDiaria * 30;
  }

  setDiaFechamentoPadrao(valor: number): void {
    this.negociacao.diaFechamentoPadrao = valor;
    if (valor) {
      this.negociacao.formaFechamento = 'N';
      this.bloqueiaFormaFechamento = true;
    } else {
      this.bloqueiaFormaFechamento = false;
    }
  }

  setDiasEntre(valor: number): void {
    this.negociacao.diasEntre = valor;
    if (valor) {
      this.negociacao.formaFechamento = 'N';
      this.bloqueiaFormaFechamento = true;
    } else {
      this.bloqueiaFormaFechamento = false;
    }
  }

  setRestricao(restricao: number): void {
    this.negociacao.restricao = restricao;
    this.negociacao.permitido = this.negociacao.restricao == 2 ? 'S' : 'N';
  }

  setParcelaMaximo(parcelaMaximo: number): void {
    this.negociacao.parcelaMaximo = parcelaMaximo;
    if (this.negociacao.parcelaMaximo == 0) {
      this.negociacao.parcelaMinimoValor = 0;
    }
  }

  setParcelaFixo(valor: number): void {
    this.negociacao.parcelaFixo = valor;
    this.negociacao.parcelaMaximo = 0;
  }

  setTipoTitulo(tipoTitulo: TipoTitulo): void {
    this.negociacao.idTipoTitulo = tipoTitulo.id;
    this.negociacao.tipoTituloIdEspecie = tipoTitulo.idEspecie;
  }

  setModalidade(aVista: string): void {
    this.negociacao.aVista = aVista;
    if (this.negociacao.aVista == '1') {
      this.setParcelaFixo(1);
      this.setDiaFechamentoPadrao(0);
    }
  }

  persistirNegociacao(novo: boolean = false): void {
    if (this.ehValido() && this.ehValidoNumeroParcelaFormaFechamento()) {
      super.persistir(new Transporte(this.negociacao), this.bibServico.negociacao, novo ? new Negociacao() : null);
    }
  }

  ehValido(): boolean {
    if (this.negociacao.entradaSaida && this.negociacao.nome && this.negociacao.especie && this.negociacao.abreviacao && this.negociacao.restricao && this.negociacao.permitirEntregaDireta && this.negociacao.idLoja && this.negociacao.descontoMaximo == 0 && this.negociacao.especie == 'V' && this.negociacao.formaFechamento != 'P' && this.negociacao.formaFechamento != 'F') {
      if (confirm(this.bibDialogo.valorNegociacaoZerado)) {
        return true;
      }
      return false;
    }
    if (this.negociacao.entradaSaida && this.negociacao.nome && this.negociacao.especie && this.negociacao.abreviacao && this.negociacao.restricao && this.negociacao.permitirEntregaDireta && this.negociacao.idLoja && (this.negociacao.penalidadeDiaFixo || this.negociacao.penalidadeDiaFixo == 0) && (this.negociacao.penalidadeDiaMinimo || this.negociacao.penalidadeDiaMinimo == 0) && (this.negociacao.penalidadeTaxaDiaria || this.negociacao.penalidadeTaxaDiaria == 0) && (this.negociacao.penalidadeTaxaFixa || this.negociacao.penalidadeTaxaFixa == 0) && (this.negociacao.descontoMaximo || this.negociacao.descontoMaximo == 0) && (this.negociacao.formaFechamento == 'P' ? this.negociacao.diaFechamentoPadrao != null : true) && (this.negociacao.formaFechamento == 'F' ? this.negociacao.prazoFixo != null : true)) {
      return true;
    }
    if (this.negociacao.entradaSaida && this.negociacao.especie && this.negociacao.especie != 'V' && this.negociacao.nome && this.negociacao.abreviacao && this.negociacao.restricao && this.negociacao.permitirEntregaDireta && this.negociacao.idLoja) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoNumeroParcelaFormaFechamento(): boolean {
    if (this.negociacao.parcelaFixo > 1 && this.negociacao.formaFechamento == 'H') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.fechamentoHojeParcelaUnica));
      return false;
    }
    return true;
  }

  setTaxaDiaria(taxaMensal: number): void {
    this.negociacao.penalidadeTaxaDiaria = taxaMensal / 30;
  }
}
