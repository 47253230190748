<layout [titulo]="''">
  <header class="card-header modal-etapas">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a class="nav-link active show" data-toggle="tab" (click)="abaSelecionado = false" href="#pesquisa">{{ bibDialogo.pesquisa }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" (click)="abaSelecionado = true" href="#selecionados">
          {{ bibDialogo.selecionados }}
          <span *ngIf="selecionados.length > 0" class="badge badge-primary">{{ selecionados.length }}</span>
        </a>
      </li>
    </ul>
  </header>
  <div class="card-body">
    <div id="myTabContent" class="tab-content">
      <div class="tab-pane fade active show" id="pesquisa">
        <div *ngIf="!abaSelecionado" class="row m0 form-group" [ngClass]="compacto ? 'tamanho-minimo-compacto' : 'tamanho-minimo'">
          <select *ngIf="abaSelecionado == false" [ngClass]="compacto ? 'compacto' : ''" [(ngModel)]="filtro.nome" class="col-sm-2 custom-select">
            <option *ngFor="let nome of ['Nº MOVIMENTAÇÃO', 'Nº PRODUTO', 'DESCRIÇÃO PRODUTO', 'Nº CLIENTE']" [value]="nome">
              {{ nome }}
            </option>
          </select>
          <busca *ngIf="abaSelecionado == false" [focoId]="focoId" [id]="'busca'" class="col-sm" [campo]="filtro.valor" (alteracao)="alterarValorFiltro($event)" [listaExterna]="true" [compacto]="compacto" (btnAdicional)="listarMovimentacao()" [focoId]="focoBusca"></busca>
          <titulo *ngIf="abaSelecionado == true" [titulo]="bibDialogo.selecionados.toLowerCase()" [compacto]="true"></titulo>
        </div>
        <table *ngIf="!abaSelecionado" class="table table-responsive-sm tabela-fixa">
          <thead>
            <tr>
              <th class="l-5"></th>
              <th class="l-5 clique" (click)="util.ordenarLista(movimentacoes, 'abreviacao')">{{ bibDialogo.loja }}</th>
              <th class="l-10 text-right clique" (click)="util.ordenarLista(movimentacoes, 'numero')">{{ bibDialogo.numeroAbreviacao }}</th>
              <th class="l-10 clique" (click)="util.ordenarLista(movimentacoes, 'data')">{{ bibDialogo.data }}</th>
              <th class="l-20 clique" (click)="util.ordenarLista(movimentacoes, 'colaborador')">{{ bibDialogo.colaborador }}</th>
              <th class="l-25 clique" (click)="util.ordenarLista(movimentacoes, 'nomeParceiro')">{{ saida ? bibDialogo.cliente : bibDialogo.fornecedor }}</th>
              <th class="l-10 text-right clique" (click)="util.ordenarLista(movimentacoes, 'valorTotal')">{{ bibDialogo.valor }}</th>
              <th class="l-15 clique" (click)="util.ordenarLista(movimentacoes, 'operacao')">{{ bibDialogo.tipo }}</th>
            </tr>
          </thead>
          <tbody #corpoTabelaMovimentacao class="tamanho" tabindex="-1" (keydown)="$event.preventDefault()">
            <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
              <tr (click)="montarMovimentacaoProduto(movimentacao, i)" [ngClass]="i == posicaoMovimentacao ? 'selecionado' : ''" class="clique">
                <td class="l-5"><check [id]="'selecionaItem' + i" *ngIf="movimentacao.id != 0" [campo]="movimentacao.selecionado" (alteracao)="setSelecionadoMovimentacao(i, $event)" [indeterminado]="movimentacao.indeterminado"></check></td>
                <td class="l-5">{{ movimentacao.abreviacao }}</td>
                <th class="l-10 text-right">{{ movimentacao.numero }}</th>
                <td class="l-10">{{ movimentacao.data | data }}</td>
                <td class="l-20 limitar">{{ movimentacao.colaborador }}</td>
                <td class="l-25 limitar">{{ movimentacao.nomeParceiro }}</td>
                <td class="l-10 text-right">{{ movimentacao.valorTotal | monetario }}</td>
                <td class="l-15 limitar">{{ movimentacao.operacao }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="!abaSelecionado" class="row align-items-center">
          <titulo class="col-sm p-0" [titulo]="bibDialogo.produtos" [compacto]="true"></titulo>
        </div>
        <ng-container *ngIf="movimentacoes.length > 0">
          <table class="table table-responsive-sm tabela-fixa">
            <thead>
              <tr>
                <th class="l-5" *ngIf="!abaSelecionado"></th>
                <th class="l-40 limitar">{{ bibDialogo.produto }}</th>
                <th class="l-10">{{ bibDialogo.unidadeAbreviacao }}</th>
                <th class="l-30 text-right">{{ ehDevolucao == 'S' ? bibDialogo.quantidadeEntregueAbreviacao : ehTransferenciaEntrada == 'S' ? bibDialogo.quantidadePendenteAbreviada : nota == 'S' || notaTerceiro == 'S' || pedidoCarteira ? bibDialogo.quantidadePendenteFaturada : bibDialogo.quantidade }}</th>
                <th class="l-15 text-right">{{ bibDialogo.valor }}</th>
                <th class="l-5" *ngIf="abaSelecionado"></th>
              </tr>
            </thead>
            <tbody class="tamanho">
              <tr *ngFor="let movimentacaoProduto of abaSelecionado ? selecionados : movimentacaoProdutos; let i = index">
                <td class="l-5" *ngIf="!abaSelecionado"><check *ngIf="movimentacaoProduto.id != 0 && ehTransferenciaEntrada != 'S'" [campo]="movimentacaoProduto.selecionado" (alteracao)="setSelecionadoMovimentacaoProduto(movimentacaoProduto, $event)"></check></td>
                <td class="l-40 limitar">{{ movimentacaoProduto.produtoNumero }} - {{ movimentacaoProduto.produto }}</td>
                <td class="l-10">{{ movimentacaoProduto.abreviacao }}</td>
                <td class="l-30 text-right">{{ ehDevolucao == 'S' ? movimentacaoProduto.quantidadeEntregue - movimentacaoProduto.quantidadeDevolvida : ehTransferenciaEntrada == 'S' ? movimentacaoProduto.quantidadePendente : nota == 'S' || notaTerceiro == 'S' || pedidoCarteira ? movimentacaoProduto.quantidadePendenteFaturamento : movimentacaoProduto.quantidade }}</td>
                <td class="l-15 text-right">{{ movimentacaoProduto.valorTotal | monetario }}</td>
                <th class="l-5 text-right" *ngIf="abaSelecionado"><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirSelecionado(movimentacaoProduto)"></btnAdicional></th>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
</layout>
<div class="row align-items-center">
  <botao class="col" [compacto]="compacto" [legenda]="modalVinculandoXml ? bibDialogo.vincular : bibDialogo.adicionar" [fundo]="true" (botaoEmt)="vincularMovimentacaoProduto()" (saindo)="focoId = $event"></botao>
  <atencao [atencao]="bibDialogo.teclaEspacoSelecionar"></atencao>
  <atencao [atencao]="bibDialogo.teclaEnterAdicionar" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
</div>
