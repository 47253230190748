import { Util } from '../utilitario/util';

export class FinanceiroBaixaPro {
  private valor: number = 0;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idFinanceiro: number;
  public idFinanceiroBaixa: number;
  public idMovimentacaoPro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public posicao: number = 0;

  setValor(valor: number) {
    this.valor = new Util().arredondar(valor);
  }
  getValor(): number {
    return this.valor;
  }

  getCampoReferencia(): string {
    return 'idFinanceiroBaixa';
  }
}
