import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1125rcofins]</tr>',
  templateUrl: './r1125cofins.component.html',
})
export class R1125cofinsComponent {
  @Input() produto: any;
  public bibDialogo = bibDialogo;
}
