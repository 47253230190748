<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.telefone }}</th>
        <th class="r-1">{{ bibDialogo.ramoAtividade }}</th>
        <th class="r-1 text-right">{{ bibDialogo.limiteMaximo }}</th>
        <th class="r-1 text-right">{{ bibDialogo.limiteDisponivel }}</th>
        <th class="r-1 text-right">{{ bibDialogo.atrasoMaximo }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorVencido }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
        <th class="r-1 text-right">{{ bibDialogo.credito }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorLiquido }}<ajuda [ajuda]="bibDialogo.valorTotalMenosCredito"></ajuda></th>
        <th class="r-1 text-right">{{ bibDialogo.juro }}</th>
        <th class="r-1">{{ bibDialogo.negociacao }}</th>
        <th *ngIf="financeiros.length > 0" class="r-1"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo sem-borda">
          <td>{{ financeiro.clienteNumero + ' - ' + financeiro.cliente }} <ajuda [ajuda]="financeiro.clienteFantasia"></ajuda></td>
        </tr>
        <tr class="conteudo" [ngClass]="financeiro.expandido ? 'sem-borda' : ''">
          <td>{{ financeiro.telefone }}</td>
          <td><ajuda [ajuda]="financeiro.ramoAtividade"></ajuda>{{ financeiro.ramoAtividade }}</td>
          <td class="text-right">{{ financeiro.limiteMaximo | monetario }}</td>
          <td class="text-right">{{ financeiro.limiteDisponivel | monetario }}</td>
          <td class="text-right">{{ financeiro.diaAtraso > 0 ? (financeiro.vencimento | data: 'dd/MM/yy') + ' - ' + financeiro.diaAtraso : financeiro.diaAtraso }}</td>
          <td class="text-right">{{ financeiro.valorVencido | monetario }}</td>
          <td class="text-right">{{ financeiro.valorTotal | monetario }}</td>
          <td class="text-right">{{ financeiro.valorCredito | monetario }}</td>
          <td class="text-right">{{ financeiro.valorLiquido | monetario }}</td>
          <td class="text-right">{{ financeiro.juros | monetario }}</td>
          <td>{{ financeiro.negociacao }}</td>
          <td *ngIf="telaCliente" class="naoImprimir"><btnAdicional [icone]="bibIcone.sifrao" [ajuda]="bibDialogo.registrar + ' ' + bibDialogo.cobranca.toLowerCase()" (btnAdicional)="this.irParceiro(financeiro.id, true)"></btnAdicional></td>
        </tr>
        <tr *ngIf="usaExpandido" r2014itens [financeiros]="financeiro.financeiros"></tr>
        <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" class="sem-borda"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="financeiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>