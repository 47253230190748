import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'saldoInicial', posicao: 2 },
  { campo: 'receita', posicao: 3 },
  { campo: 'despesa', posicao: 4 },
  { campo: 'saldo', posicao: 5 },
  { campo: 'receitaAConciliar', posicao: 6 },
  { campo: 'despesaAConciliar', posicao: 7 },
  { campo: 'saldo+receitaAConciliar-despesaAConciliar', posicao: 8, tipo: 'expressao' },
  { campo: 'saldoInicialValorLiquido', posicao: 2, mostrar: false },
  { campo: 'receitaValorLiquido', posicao: 3, mostrar: false },
  { campo: 'despesaValorLiquido', posicao: 4, mostrar: false },
  { campo: 'saldoValorLiquido', posicao: 5, mostrar: false },
  { campo: 'receitaAConciliarValorLiquido', posicao: 6, mostrar: false },
  { campo: 'despesaAConciliarValorLiquido', posicao: 7, mostrar: false },
  { campo: 'saldoValorLiquido+receitaAConciliarValorLiquido-despesaAConciliarValorLiquido', posicao: 8, tipo: 'expressao', mostrar: false },
];
export default totais;
