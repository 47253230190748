<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="4">
    <a *ngIf="produtosMovimentacoes.length == 0">{{ bibDialogo.produtoSemMovimentacoes }}</a>
    <table *ngIf="produtosMovimentacoes.length > 0" class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-0-5">{{ bibDialogo.loja }}</th>
          <th class="r-1">{{ bibDialogo.data }}</th>
          <th class="r-1">{{ bibDialogo.dataAlteracao }}</th>
          <th class="r-1">{{ bibDialogo.numero }}</th>
          <th class="r-2">{{ bibDialogo.usuario }}</th>
          <th class="r">{{ bibDialogo.clienteFornecedor }}</th>
          <th class="r-1 text-right">{{ bibDialogo.entrada }}</th>
          <th class="r-1 text-right">{{ bibDialogo.saida }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let produtoMovimentacao of produtosMovimentacoes">
          <tr class="conteudo">
            <td>{{ produtoMovimentacao.abreviacao }}</td>
            <td>{{ produtoMovimentacao.data | data }}</td>
            <td>{{ produtoMovimentacao.dataAlteracao | data }}</td>            
            <td>{{ produtoMovimentacao.operacaoAbreviacao + '-' + produtoMovimentacao.numero }}</td>
            <td>{{ produtoMovimentacao.usuarioNome }}</td>
            <td>{{ produtoMovimentacao.parceiroNumero + ' - ' + produtoMovimentacao.parceiroNome }}</td>
            <td class="text-right">{{ produtoMovimentacao.quantidadeEntrada > 0 ? (produtoMovimentacao.quantidadeEntrada | monetario) : '' }}</td>
            <td class="text-right">{{ produtoMovimentacao.quantidadeSaida > 0 ? (produtoMovimentacao.quantidadeSaida | monetario) : '' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
