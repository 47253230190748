export class ProdutoEstoqueMinimoMaximo {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public lojaAbreviacao: string;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public quantidadeMaxima: number;
  public quantidadeMinima: number;
  public percentual: number;

  public quantidadeDisponivel: number = 0;
  public quantidadeReservada: number = 0;
  public quantidadeCarteira: number = 0;
  public quantidadeNfe: number = 0;
  public quantidadeTransferida: number = 0;
  public quantidadeComprada: number = 0;
  public editando: boolean = false;
}
