import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Baixa } from 'src/app/modelo/baixa';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  templateUrl: './baixa.component.html',
})
export class BaixaComponent {
  private parametroAnterior: string = '';
  private params: any;
  public apresentarLoja: boolean = false;
  public baixa: Baixa = new Baixa();
  public bibDialogo = bibDialogo;
  public bibPropriedade = bibPropriedade;
  public bibServico = bibServico;
  public criterios: Criterio[] = [];
  public parametro: string = '';
  public progresso: any;

  constructor(private activatedRoute: ActivatedRoute, private utilSessao: UtilSessao) {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.baixa.parametro = this.parametro;
      this.progresso = [this.bibDialogo.pesquisa, this.bibDialogo.emAberto, this.parametro == 'DESPESA' ? this.bibDialogo.dadosPagamento : this.bibDialogo.dadosRecebimento, this.bibDialogo.finalizar];
      if (this.parametro == 'DESPESA') {
        this.baixa.telaDespesa = true;
      }
      if (this.parametro != this.parametroAnterior) {
        this.ir(1);
        this.parametroAnterior = this.parametro;
      }
    });
  }

  ngOnDestroy(): void {
    this.params.unsubscribe();
  }

  ir(posicao: number): void {
    if (!(this.baixa.getEtapa() == 4 && posicao != 1)) {
      if (posicao < this.baixa.getEtapa()) {
        this.baixa.setEtapa(posicao);
      } else {
        this.baixa.baixaNotificacao.baixaEmt.emit(posicao);
      }
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.financeiroFinalizado));
    }
  }
}
