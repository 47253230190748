<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(conciliacoes, $event)" (campoGrupoEmt)="agrupar(conciliacoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <tr>
        <th class="r-0-5">{{ bibDialogo.loja }}</th>
        <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
        <th class="r-1">{{ bibDialogo.dataBaixa }}</th>
        <th class="r">{{ bibDialogo.destino }}</th>
        <th class="r-2">{{ bibDialogo.conta }}</th>
        <th class="r-1-5">{{ bibDialogo.numeroDocumento }}</th>
        <th class="r-2">{{ bibDialogo.historico }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let conciliacao of conciliacoes; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ conciliacao.abreviacao }}</td>
          <td class="text-right">{{ conciliacao.numero }}</td>
          <td>{{ conciliacao.dataBaixa | data }}</td>
          <td>{{ conciliacao.parceiro }}</td>
          <td>{{ conciliacao.conta }}</td>
          <td>{{ conciliacao.numeroDocumento }}</td>
          <td>{{ conciliacao.observacao }}</td>
          <td class="text-right">{{ conciliacao.valor | monetario }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="conciliacoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
