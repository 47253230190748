export class RoteiroContabilRateio {
  public alterado: boolean = false;
  public contaContabil: string;
  public contaContabilContrapartida: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public expandido: boolean = false;
  public historico: string;
  public id: number;
  public idContaContabil: number;
  public idContaContabilContrapartida: number;
  public idEmpresa: number;
  public idTipoLancamentoContabil: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public percentual: number = 100;
  public tipoLancamentoContabil: string;
  public idContaBancaria: number;
  public idContaBancariaContrapartida: number;
  public idParceiro: number;
  public idParceiroContrapartida: number;  
  public contaBancaria: string;
  public contaBancariaContrapartida: string;
  public parceiro: string;
  public parceiroContrapartida: string;  
  public contaContabilCorrentista: number;  
  public contaContabilCorrentistaC: number;  
}