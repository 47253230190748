import { TabelaPrecoCliente } from './tabelaPrecoCliente';
import { TabelaPrecoColaborador } from './tabelaPrecoColaborador';
import { TabelaPrecoConfiguracao } from './tabelaPrecoConfiguracao';
import { TabelaPrecoGrupo } from './tabelaPrecoGrupo';
import { TabelaPrecoProduto } from './tabelaPrecoProduto';

export class TabelaPreco {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public tabelaPrecoClientes: TabelaPrecoCliente[] = [];
  public tabelaPrecoColaboradores: TabelaPrecoColaborador[] = [];
  public tabelaPrecoConfiguracoes: TabelaPrecoConfiguracao[] = [];
  public tabelaPrecoGrupos: TabelaPrecoGrupo[] = [];
  public tabelaPrecoProdutos: TabelaPrecoProduto[] = [];
  public taxa: number;
  public tipo: number;
}
