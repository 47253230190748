import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { VisitaComponent } from './visita.component';
import { VisitaFrmComponent } from './visitaFrm/visitaFrm.component';

const routes: Routes = [
  {
    path: '',
    component: VisitaComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'Frm',
    component: VisitaFrmComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  declarations: [VisitaComponent, VisitaFrmComponent],
  imports: [CommonModule, FormsModule, RouterModule, RouterModule.forChild(routes), CompartilhadoModule],
})
export class VisitaModule {}
