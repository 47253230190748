import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboCampo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'PRODUTO', id: '1' },
  { nome: 'GRUPO', id: '2' },
  { nome: 'CLIENTE', id: '3' },
  { nome: 'VENDEDOR', id: '4' },
]);

const filtroComboValor: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'VALOR', id: '4' },
  { nome: '% LUCRO', id: '5' },
  { nome: 'VALOR LUCRO', id: '6' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: 'Curva A', nome: 'CURVAA', obrigatorio: 'S', coluna: 4, tipo: bibPropriedade.filtro.numero, valor: 70 },
  { rotulo: 'Curva B', nome: 'CURVAB', obrigatorio: 'S', coluna: 4, tipo: bibPropriedade.filtro.numero, valor: 20 },
  { rotulo: 'Curva C', nome: 'CURVAC', obrigatorio: 'S', coluna: 4, tipo: bibPropriedade.filtro.numero, valor: 10 },
  { rotulo: bibDialogo.campo + ' ' + bibDialogo.referencia, nome: 'CAMPO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '1', lista: filtroComboCampo },
  { rotulo: bibDialogo.valor + ' ' + bibDialogo.referencia, nome: 'VALOR', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '4', lista: filtroComboValor },
  { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 12, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
