import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css'],
})
export class WhatsappComponent {

  @Input() nomeMenu: string;
  @Input() id: string;
  private apiWhatsapp: string = 'https://api.whatsapp.com/send?phone=5534988507668&text=';
  public mensagem: string;
  private util: Util = new Util();
  public bibDialogo = bibDialogo;

  constructor(private utilSessao: UtilSessao) { }

  retornarSaudacao(): string {
    const horaAtual = new Date().getHours();
    if (horaAtual > 0 && horaAtual < 13) {
      return 'Bom dia';
    } else if (horaAtual > 12 && horaAtual < 19) {
      return 'Boa tarde';
    }
    return 'Boa noite';
  }

  irWhatsapp(): void {
    let textoPadrao: string = this.util.substituir(this.bibDialogo.mensagemPadraoParaSuporteCliente, [this.retornarSaudacao(), this.util.definirPrimeiraMaiscula(this.utilSessao.getUsuario().nome.split(' ')[0].toLowerCase()), this.utilSessao.getEmpresa().nome, this.bibDialogo.versaoSistema]);
    let textoDescricaoDaTela: string = ' e ' + this.util.substituir(this.bibDialogo.informacaoDaTela, [this.nomeMenu]) + (this.id ? ' o ' + this.bibDialogo.registro.toLowerCase() + ' de ' + this.bibDialogo.id.toUpperCase() + ' ' + this.id + ', ' : ',') + this.bibDialogo.apresentaSeguinteSituacao;
    this.mensagem = this.nomeMenu == null ? textoPadrao : textoPadrao + textoDescricaoDaTela;
    this.montarDescricaoDoErro();
    const url = this.apiWhatsapp + this.mensagem;
    window.open(url);
  }

  montarDescricaoDoErro(): void {
    this.utilSessao.getResultados().forEach((resultado, i) => {
      if (resultado != null && resultado != undefined && resultado.mensagem && resultado.mensagem != undefined) {
        this.mensagem += resultado.mensagem + (resultado.descricaoNerd?.length > 0 ? ' [' + resultado.descricaoNerd + '] ' : '') + (i + 1 < this.utilSessao.getResultados().length ? ' | ' : '');
      }
    });
  }
}


