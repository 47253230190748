import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const bibNomenclatura: BibNomenclatura = new BibNomenclatura();
export class Filtros {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, criterios: [new Criterio('LOJA_USUARIO', 'S')], tipo: bibPropriedade.filtro.lista },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia, nome: 'REFERENCIA', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto, nome: 'EXCETO', coluna: 12, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
    { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
    { id: 'numeroMovimentacao', rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO_MOVIMENTACAO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataUltCompraDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataAte', rotulo: bibDialogo.dataUltCompraAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
    { id: 'categoria', rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
    { id: 'margemGerencialDe', rotulo: bibNomenclatura.escrever(bibNomenclatura.margemGerencial) + ' (De)', nome: 'MARGEM_GERENCIAL_DE', coluna: 6, tipo: bibPropriedade.filtro.numero, sufixo: bibDialogo.percentualSimbolo },
    { id: 'margemGerencialAte', rotulo: bibNomenclatura.escrever(bibNomenclatura.margemGerencial) + ' (Até)', nome: 'MARGEM_GERENCIAL_ATE', coluna: 6, tipo: bibPropriedade.filtro.numero, sufixo: bibDialogo.percentualSimbolo },
    { id: 'margemLucroDe', rotulo: bibDialogo.margemLucroDe, nome: 'MARGEM_LUCRO_DE', coluna: 6, tipo: bibPropriedade.filtro.numero, sufixo: bibDialogo.percentualSimbolo },
    { id: 'margemLucroAte', rotulo: bibDialogo.margemLucroAte, nome: 'MARGEM_LUCRO_ATE', coluna: 6, tipo: bibPropriedade.filtro.numero, sufixo: bibDialogo.percentualSimbolo },
    { id: 'margemRealDe', rotulo: bibDialogo.margemRealDe, nome: 'MARGEM_REAL_DE', coluna: 6, tipo: bibPropriedade.filtro.numero, sufixo: bibDialogo.percentualSimbolo },
    { id: 'margemRealAte', rotulo: bibDialogo.margemRealAte, nome: 'MARGEM_REAL_ATE', coluna: 6, tipo: bibPropriedade.filtro.numero, sufixo: bibDialogo.percentualSimbolo },
    { id: 'custoReposicao', rotulo: bibNomenclatura.escrever(bibNomenclatura.custoReposicao) + ' ' + bibDialogo.custoReposicaoMaiorVenda, nome: 'CUSTO_MAIOR_VENDA', coluna: 6, tipo: bibPropriedade.filtro.interruptor },
    { id: 'lucroDiferenteReal', rotulo: bibDialogo.margemLucroDifReal, nome: 'LUCRO_DIFERENTE_REAL', coluna: 6, tipo: bibPropriedade.filtro.interruptor },
  ]);
}
