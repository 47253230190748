import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015ValorLiquidoMovimentacaoParFinanceiro',
  templateUrl: './r2015ValorLiquidoMovimentacaoParFinanceiro.component.html',
})
export class R2015ValorLiquidoMovimentacaoParFinanceiros {
  @Input() valorLiquidoMovimentacaoParFinanceiros: any[] = [];
  public bibDialogo = bibDialogo;
}
