  
  <td class="text-right">{{ financeiro.numero }}</td>
  <td>{{ financeiro.dataVencimento | data }}</td>
  <td>{{ financeiro.parceiro }}</td>
  <td>{{ financeiro.natureza }}</td>
  <td>{{ financeiro.tipoTitulo }}</td>
  <td class="text-right">
    <span *ngIf="financeiro.tipo == 'R'">{{ financeiro.receita | monetario }}</span>
  </td>
  <td class="text-right">
    <span *ngIf="financeiro.tipo == 'D'">{{ financeiro.despesa | monetario }}</span>
  </td>
  <td class="text-right" [ngClass]="financeiro.saldo < 0 ? tachado ? 'tachado bg-vermelho text-white' : 'bg-vermelho text-white' : tachado ? 'tachado' : ''">{{ financeiro.saldo | monetario }}</td>