import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1126contato]</tr>',
  templateUrl: './r1126contato.component.html',
})
export class R1126ContatoComponent {
  @Input() parceiro: any;
  public bibDialogo = bibDialogo;
}
