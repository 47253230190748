import { Type } from 'class-transformer';
import { Util } from '../utilitario/util';

export class MovimentacaoParcela {
  @Type(() => Date)
  public dataVencimento: Date = new Date();

  private valorBloqueado: number = 0;
  private valorParcela: number;
  public entrada: string = 'N';
  public id: number;
  public idMovimentacao: number;
  public idTipoTitulo: number;
  public status: string = 'N';
  public incrementouDomingo: boolean = false;
  public idEspecie: number;

  setValorParcela(valorParcela: number) {
    this.valorParcela = new Util().arredondar(valorParcela);
  }

  setValorBloqueado(valorBloqueado: number) {
    this.valorBloqueado = new Util().arredondar(valorBloqueado);
  }

  getValorParcela(): number {
    return this.valorParcela;
  }

  getValorBloqueado(): number {
    return this.valorBloqueado;
  }
}
