export class Icmssn900 {
    public orig: string;
    public CSOSN: string = '900';
    public modBC: string;
    public vBC: string;
    public pRedBC: string;
    public pICMS: string;
    public vICMS: string;
    public modBCST: string;
    public pMVAST: string;
    public pRedBCST: string;
    public vBCST: string;
    public pICMSST: string;
    public vICMSST: string;
    public pCredSN: string;
    public vCredICMSSN: string;
    public vBCFCPST: string;
    public pFCPST: string;
    public vFCPST: string;
}