<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-70 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-10 clique" (click)="listar(add('estado'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.estado }}</th>
        <th class="l-10 clique" (click)="listar(add('ibge'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.ibge }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let cidade of cidades; let i = index">
        <tr [ngClass]="{ tachado: cidade.ativo != 'S' }">
          <td class="limitar">
            <a href="#" (click)="ir(cidade.id, i, cidades)">{{ cidade.nome }}</a>
          </td>
          <td>{{ cidade.estado }}</td>
          <td>{{ cidade.ibge }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(cidade.id, i, cidades)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirCidade(cidade.id, cidade.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
