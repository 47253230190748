export class TransporteItem {
  public campoReferencia: string = null;
  public lista: any[] = [];

  constructor(listaOuObjeto: any) {
    if (listaOuObjeto.length >= 0) {
      this.lista = listaOuObjeto;
    } else {
      this.lista.push(listaOuObjeto);
    }
  }
}
