import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { Transporte } from 'src/app/modelo/transporte';
import { MovimentacaoFrmComponent } from '../estrutura/movimentacaoFrm.component';
import { Parceiro } from 'src/app/modelo/parceiro';

@Component({
  selector: 'movimentacaoTransporteFrm',
  templateUrl: './movimentacaoTransporteFrm.component.html',
})
export class MovimentacaoTransporteFrmComponent extends MovimentacaoFrmComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;
  public estados: Estado[];

  public listaTipoFrete: any[] = [
    { id: '100', nome: this.bibDialogo.transporteTipoContaEmitente },
    { id: '1', nome: this.bibDialogo.transporteTipoContaDestinatario },
    { id: '2', nome: this.bibDialogo.transporteTipoContaTerceiros },
    { id: '9', nome: this.bibDialogo.transporteTipoSemFrete },
  ];

  ngOnInit(): void {
    this.listarEstado();
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      res.forEach((estado) => {
        estado.nome = estado.abreviacao;
      });
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  definirTransportadora(parceiro: Parceiro) {
    this.movimentacaoControlador.movimentacao.idTransportadora = parceiro.id;
    if (parceiro.placa) {
      this.movimentacaoControlador.movimentacao.transportadoraPlaca = parceiro.placa;
    }
    if (parceiro.placaIdEstado) {
      this.movimentacaoControlador.movimentacao.idEstadoTransportadora = parceiro.placaIdEstado;
    }
  }
}
