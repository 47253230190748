export class InfContrato {
  public NroContrato_219: number;
  public vContratoGlobal_220: string;
  public cInt_rodo_5: string = '001';
  public placa_rodo_6: string;
  public RENAVAM_10: string;
  public tara_rodo_7: number = 0;
  public tpRod_rodo_34: string = '06';
  public tpCar_rodo_35: string = '01';
  public UF_rodo_36: string;
}
