import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  { id: 'estoqueBloqueado', rotulo: bibDialogo.bloquear + ' ' + bibDialogo.estoque.toLowerCase(), nome: 'BLOQUEIA_ESTOQUE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboAtivo },
]);
export default filtros;
