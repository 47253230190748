import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Criterio } from 'src/app/modelo/criterio';
import { Cte } from 'src/app/modelo/cte';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './cte.component.html',
})
export class CteComponent extends PaginaComponent {
  public ativo: boolean = false;
  public ctes: Cte[] = [];
  public filtros: Filtro[] = filtros;
  public name: string;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.cte, true).subscribe((res) => {
      this.ctes = this.plainToClass(Cte, res) as any;
    });
  }

  excluirCte(id: number, nome: string): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id ? id : 0)), this.bibServico.cte).subscribe((res) => {
      const cteBusca = this.plainToClass(Cte, res[0]) as any;
      if (cteBusca && cteBusca.status == 'S') {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cteFinalizado));
      } else if (cteBusca && cteBusca.ativo == 'N') {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cteCancelado));
      } else {
        super.excluir<Cte>(id, this.ctes, nome, this.bibServico.cte);
      }
    });
  }

  clonarCte(id: number, posicao: number, cte: Cte[]): void {
    this.ir(id, posicao, cte);
    this.utilSessao.setIdentificacao(new Identificacao('clone', true));
  }

  listarCte(idCte: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idCte)), this.bibServico.cte);
  }

  abrirCte(cte: Cte): void {
    if (cte.situacao != 3 && cte.situacao != 4 && cte.situacao != 6) {
      this.listarCte(cte.id).subscribe((res) => {
        let ctePersistir: Cte = this.plainToClass(Cte, res[0]) as any;
        if (confirm(this.bibDialogo.desejaAbrirCteNumero + ' ' + (cte.numero != undefined ? cte.numero : '0'))) {
          ctePersistir.status = 'N';
          const transporte: Transporte = new Transporte(ctePersistir);
          super.persistir(transporte, this.bibServico.cte, null).subscribe(() => {
            if (!this.utilSessao.falha) {
              cte.status = 'N';
            } else {
              cte.status = 'S';
            }
          });
        }
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.validarAbrirCteJaEnviado));
    }
  }
}
