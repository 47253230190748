export class EstoqueProdutoMinimoMaximo {
  public abreviacao: string;
  public idLoja: number;
  public mesFinal: number;
  public mesInicial: number;
  public periodo: string;
  public quantidadeComprada: number = 0;
  public quantidadeDisponivel: number = 0;
  public quantidadeFisica: number = 0;
  public quantidadeMaxima: number = 0;
  public quantidadeMinima: number = 0;
  public quantidadeNfe: number = 0;
  public quantidadeReservada: number = 0;
  public quantidadeCarteira: number = 0;
}
