import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2030.component.html',
})
export class R2030Component extends RelatorioComponent {
  public componente: any;
  public filtros = filtros;
  public auditorias: any[] = [];
  public usaExpandido: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.auditorias = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Auditoria').lista;
      this.ehImprimirRelatorio();
      this.expandirRelatorio();
    });
  }

  expandir(auditoria: any) {
    auditoria.expandido = !auditoria.expandido;
    if (auditoria.expandido && auditoria.auditoriaDetalhes == null) {
      this.listarEspecifico(new Criterio('ID_LOG', auditoria.id), 195).subscribe((res) => {
        let auditoriaDetalhes: any[] = this.plainToClass(RelatorioResultado, res)[0].lista;
        auditoria.auditoriaDetalhes = auditoriaDetalhes;
      });
    }
  }

  expandirRelatorio() {
    this.auditorias.forEach((auditoria) => {
      if (auditoria.operacao == 1) {
        auditoria.expandido = false;
        if (this.usaExpandido) {
          this.expandir(auditoria);
        }
      }
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaExpandido = this.definirRegra(relatorioAdicional, 238, this.usaExpandido);
    this.expandirRelatorio();
  }
}
