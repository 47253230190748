import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Identificacao } from 'src/app/modelo/identificacao';

@Component({
  templateUrl: './parceiroEnderecoFrm.component.html',
})
export class ParceiroEnderecoFrmComponent extends PaginaComponent {
  public componente: any;
  public parceiroEndereco: ParceiroEndereco = new ParceiroEndereco();
  public tipoVinculo: string;
  ngOnInit(): void {
    this.parceiroEndereco = this.plainToClass(ParceiroEndereco, this.ehAtualizacao(this.parceiroEndereco));
    if (this.parceiroEndereco.id && !this.parceiroEndereco.alterado) {
      this.listarParceiroEndereco();
    }
    this.tipoVinculo = this.utilSessao.getIdentificacao('tipoVinculo')?.conteudo;
  }

  listarParceiroEndereco(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.parceiroEndereco.id)), this.bibServico.parceiroEndereco).subscribe((res) => {
      this.parceiroEndereco = this.plainToClass(ParceiroEndereco, res[0]) as any;
    });
  }

  persistirParceiroEndereco(): void {
    if (this.ehValidoObrigatorio() && this.ehValidoNumero()) {
      this.parceiroEndereco.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.parceiroEndereco, this.parceiroEndereco));
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.parceiroEndereco.endereco && this.parceiroEndereco.numero && this.parceiroEndereco.bairro && this.parceiroEndereco.cidade && this.parceiroEndereco.idEstado && this.parceiroEndereco.idVinculo && this.parceiroEndereco.tipo) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoNumero(): boolean {
    const contemLetras: any = /(?=(?:.*?[A-Z]))/;
    if (this.parceiroEndereco.numero != null) {
      if (contemLetras.exec(this.parceiroEndereco.numero) && this.parceiroEndereco.numero != 'S/N') {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enderecoNumero));
        return false;
      }
    }
    return true;
  }

  abrirMapa(): void {
    let url: string = `https://www.google.com/maps/search/?api=1&query=${this.parceiroEndereco.latitude},${this.parceiroEndereco.longitude}`;
    window.open(url, '_blank');
  }
}
