<layout (filtroEmt)="listar($event)" [filtros]="filtros">
  <registro [posicao]="posicao" [paginacao]="paginacao" [numeroRegistro]="grupoContabeis.length"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90 clique" (click)="util.ordenarLista(grupoContabeis, 'nome')">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let grupoContabil of grupoContabeis; let i = index">
        <tr *ngIf="i >= posicao && i < posicao + paginacao" [ngClass]="{ tachado: grupoContabil.ativo != 'S' }">
          <td class="limitar">
            <a href="#" (click)="ir(grupoContabil.id, i, grupoContabeis)">{{ grupoContabil.nome }}</a>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(grupoContabil.id, i, grupoContabeis)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoContabil(grupoContabil.id, grupoContabil.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
