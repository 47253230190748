import { Component } from '@angular/core';
import { R1001Component } from '../r1001/r1001.component';
import filtros from './acessorio/filtro';


@Component({
  selector: 'r2024',
  templateUrl: './r2024.component.html',
})
export class R2024Component extends R1001Component {
  public filtros = filtros;
}