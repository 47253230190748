import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './parceiro.component.html',
})
export class ParceiroComponent extends PaginaComponent {
  private params: any;
  public filtros: Filtro[];
  public parametro: string;
  public parceiros: Parceiro[] = [];

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.parceiros = [];
      this.selecionarFiltro();
    });
  }

  ngOnDestroy() {
    this.params.unsubscribe();
  }

  selecionarFiltro(): void {
    switch (this.parametro) {
      case 'CLIENTE':
        this.filtros = filtros.cliente;
        break;
      case 'FORNECEDOR':
        this.filtros = filtros.fornecedor;
        break;
      case 'TRANSPORTADORA':
        this.filtros = filtros.transportadora;
        break;
      case 'COLABORADOR':
        this.filtros = filtros.colaborador;
        break;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, nomeCampoOrdem: string = ''): void {
    criterios.push(new Criterio(this.parametro, 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.parceiro, false, true, true, true, 120000, false, '', nomeCampoOrdem).subscribe((res) => {
      this.parceiros = this.plainToClass(Parceiro, res) as any;
    });
  }

  excluirParceiro(id: number, nome: string): void {
    super.excluir<Parceiro>(id, this.parceiros, nome);
  }
}
