import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { RoteiroContabil } from 'src/app/modelo/roteiroContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './roteiroContabil.component.html',
})
export class RoteiroContabilComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public roteiroContabeis: RoteiroContabil[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.roteiroContabil).subscribe((res) => {
      this.roteiroContabeis = this.plainToClass(RoteiroContabil, res) as any;
    });
  }
}
