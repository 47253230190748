import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros = {
  filtroCliente: plainToClass(Filtro, [
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'parceiro', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  ]),
  filtroEmpresa: plainToClass(Filtro, [
    {id:'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    {id:'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    {id:'parceiro', rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  ]),
};

export default filtros;
