import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroHistorico } from 'src/app/modelo/parceiroHistorico';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UtilLimiteCredito } from 'src/app/utilitario/utilLimiteCredito';
import { ParceiroFrmComponent } from '../parceiro/frm/parceiroFrm.component';
import filtros from './filtro';
import { LimiteCreditoHistoricoComponent } from './limiteCreditoHistorico/limiteCreditoHistorico.component';

@Component({
  selector: 'limiteCredito',
  templateUrl: './limiteCredito.component.html',
  styleUrls: ['./limiteCredito.component.css'],
})
export class LimiteCreditoComponent extends PaginaComponent {
  public componente: any;
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public empresaLimiteCreditoAutomatico: boolean = this.utilSessao.getEmpresa().limiteCredito == 1 ? true : false;
  public filtros: Filtro[] = filtros;
  public parceiroHistorico: ParceiroHistorico = new ParceiroHistorico();
  public parceiroHistoricos: ParceiroHistorico[] = [];
  public parceiros: Parceiro[] = [];
  public utilLimiteCredito: UtilLimiteCredito = new UtilLimiteCredito();
  public visualizaHistorico: boolean = false;
  public parceiroLimites: any[] = [];

  ngOnInit(): void {
    let idParceiro: Identificacao = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
    if (idParceiro != null) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID', idParceiro));
      this.listar(criterios);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('CLIENTE', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.parceiro).subscribe((res) => {
      this.parceiros = this.plainToClass(Parceiro, res) as any;
      this.criarListaParceiroLimites();
      this.listarParceiroHistorico();
    });
  }

  criarListaParceiroLimites(): void {
    this.parceiros.map((parceiro) => {
      this.parceiroLimites.push({ id: parceiro.id, limiteMaximo: parceiro.limiteMaximo, limiteDisponivel: parceiro.limiteDisponivel, limiteCredito: parceiro.limiteCredito });
    });
  }

  private listarParceiroHistorico(): void {
    let criterios: Criterio[] = [new Criterio('IDS_PARCEIRO', this.parceiros.map((parceiro) => parceiro.id).toString())];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroHistorico).subscribe((res) => {
      this.parceiroHistoricos = this.plainToClass(ParceiroHistorico, res) as any;
    });
  }

  public vincularParceiroHistorico(idParceiro: number): void {
    this.visualizaHistorico = this.visualizaHistorico == false ? true : false;
    if (this.visualizaHistorico) {
      this.parceiroHistorico = this.parceiroHistoricos.find((financeiroParceiro) => financeiroParceiro.idParceiro == idParceiro);
    }
  }

  persistirParceiros(): void {
    if (this.utilLimiteCredito.sehEhValidoLimiteCredito(this.parceiros, this.empresaLimiteCreditoAutomatico)) {
      const parceirosPersistir: Parceiro[] = this.parceiros.filter((parceiro) => parceiro.alterado);
      const transporte: Transporte = new Transporte(parceirosPersistir);
      super.persistir(transporte, this.bibServico.parceiro, null).subscribe((res) => {
        this.parceiros.forEach((parceiro) => {
          parceiro.alterado = false;
          parceiro.parceiroHistoricoLimites = [];
        });
      });
    }
  }

  abrirModalHistorico(parceiro: Parceiro): void {
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', parceiro.id));
    this.utilSessao.setIdentificacao(new Identificacao('parceiro', parceiro.razaoSocial ? parceiro.razaoSocial : parceiro.nomeFantasia));
    this.componente = LimiteCreditoHistoricoComponent;
  }

  abrirModalCliente(idParceiro: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', idParceiro));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', 'CLIENTE'));
    this.componente = ParceiroFrmComponent;
  }
}
