<layout [chave]="baixa.chaveGeral" [mostraRelatorio]="baixa.mostraRelatorio" [subTitulo]="baixa.financeiros.length > 0 && baixa.apresentaSubTitulo && baixa.apresentaNomeParceiro ? this.baixa.financeiros[0].parceiro + (baixa.apresentaLoja ? ' - ' + this.baixa.financeiros[0].loja : '') : baixa.financeiros.length > 0 && baixa.financeiros[0].idParceiro == baixa.idConsumidorFinal && baixa.apresentaSubTitulo ? baixa.nomeConsumidorFinal + (baixa.apresentaLoja ? ' - ' + this.baixa.financeiros[0].loja : '') : ''">
  <progresso [progresso]="progresso" [etapa]="baixa.getEtapa()" [clique]="true" (progressoEmt)="ir($event)"></progresso>
  <baixaBusca [hidden]="baixa.getEtapa() != 1" [baixa]="baixa" [criterios]="criterios"></baixaBusca>
  <baixaLista *ngIf="baixa.etapaAtingida >= 2" [hidden]="baixa.getEtapa() != 2 || this.baixa.utilizaConferencia || this.baixa.utilizaIdFinanceiro" [baixa]="baixa" [financeiros]="baixa.financeiros"></baixaLista>
  <baixaBaixa *ngIf="baixa.getEtapa() >= 3" [hidden]="baixa.getEtapa() != 3" [baixa]="baixa"></baixaBaixa>
  <baixaComprovante *ngIf="baixa.etapaAtingida >= 4" [hidden]="baixa.getEtapa() != 4" [baixa]="baixa"></baixaComprovante>
</layout>
<totalizador *ngIf="(baixa.getEtapa() == 2 || baixa.getEtapa() == 3) && (baixa.valorReceita > 0 && baixa.valorDespesa > 0)" [valorTotalizador]="baixa.valorReceita | monetario" [tipoTotalizador]="'bg-azul'" [alinhamento]="bibPropriedade.totalizador.esquerda" [atencaoTotalizador]="bibDialogo.receita" [tipoAtencaoTotalizador]="bibPropriedade.atencao.discreto"></totalizador>
<totalizador *ngIf="baixa.getEtapa() == 2 || baixa.getEtapa() == 3" [valorTotalizador]="(baixa.getEtapa() == 2 ? baixa.valorRestanteLiquido : baixa.valorRestanteLiquido - baixa.valorDesconto + baixa.valorJuro) | monetario" [tipoTotalizador]="baixa.tipo == 'D' ? 'bg-vermelho': 'bg-azul'" [atencaoTotalizador]="baixa.parametro == 'RECEITA' ? bibDialogo.valorTotalAberto + ': ' + (baixa.valorTotalAbertoReceita | monetario) : baixa.parametro == 'DESPESA' ?  bibDialogo.valorTotalAberto + ': ' + (baixa.valorTotalAbertoDespesa | monetario) : baixa.parametro == 'CONFERENCIA' && baixa.valorTotalAberto > 0 && baixa.getEtapa() == 3 ? (baixa.tipo == 'D' ? bibDialogo.aPagar: bibDialogo.aReceber) : null" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta"></totalizador>
<totalizador *ngIf="(baixa.getEtapa() == 2 || baixa.getEtapa() == 3) && (baixa.valorReceita > 0 && baixa.valorDespesa > 0)" [valorTotalizador]="baixa.valorDespesa | monetario" [tipoTotalizador]="'bg-vermelho'" [alinhamento]="bibPropriedade.totalizador.direita"  [atencaoTotalizador]="bibDialogo.despesa" [tipoAtencaoTotalizador]="bibPropriedade.atencao.discreto"></totalizador>
