import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1126assinatura]</tr>',
  templateUrl: './r1126assinatura.component.html',
})
export class R1126AssinaturaComponent {
  @Input() parceiro: any;
  public bibDialogo = bibDialogo;
}
