import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibServico from 'src/app/biblioteca/bibServico';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'auditoria',
  templateUrl: './auditoria.component.html',
})
export class AuditoriaComponent {
  constructor(public comunicacaoService: ComunicacaoService) {}
  @Input() dataHoraAlteracao: Date;
  @Input() dataHoraInclusao: Date;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibServico = bibServico;
  public fotoUsuarioAlteracao: string;
  public fotoUsuarioInclusao: string;
  public fotoUsuarioPadrao: string = 'img/semfoto.jpg';
  public mostraDadosUsuario: boolean = false;
  public nomeUsuarioAlteracao: string;
  public nomeUsuarioInclusao: string;
  public utilSessao: UtilSessao = new UtilSessao();
  public conexao: Conexao = new Conexao(this.utilSessao);

  public _idUsuarioInclusao: number;
  @Input()
  set idUsuarioInclusao(idUsuarioInclusao: number) {
    if (idUsuarioInclusao) {
      this._idUsuarioInclusao = idUsuarioInclusao;
    }
  }

  public _idUsuarioAlteracao: number;
  @Input()
  set idUsuarioAlteracao(idUsuarioAlteracao: number) {
    if (idUsuarioAlteracao) {
      this._idUsuarioAlteracao = idUsuarioAlteracao;
    }
  }

  mostrarDadosUsuario(): void {
    this.mostraDadosUsuario = !this.mostraDadosUsuario;
    const usuario: Usuario = this.utilSessao.getUsuario();
    if (this.mostraDadosUsuario) {
      this.buscarUsuarioInsercao(usuario);
      this.buscarUsuarioAlteracao(usuario);
    }
  }

  buscarUsuarioInsercao(usuario: Usuario): void {
    if (this._idUsuarioInclusao && this._idUsuarioInclusao != usuario.id) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this._idUsuarioInclusao)), this.bibServico.usuario).subscribe((res) => {
        this.setNomeFotoUsuarioInclusao(plainToClass(Usuario, res[0]) as any);
      });
    } else {
      this.setNomeFotoUsuarioInclusao(usuario);
    }
  }

  setNomeFotoUsuarioInclusao(usuario: Usuario) {
    this.nomeUsuarioInclusao = usuario.nome;
    this.fotoUsuarioInclusao = usuario.foto == 'S' ? this.conexao.caminhoAwsS3 + '' + usuario.idEmpresa + '/usuario/' + usuario.id + '.jpg' : this.fotoUsuarioPadrao;
  }

  buscarUsuarioAlteracao(usuario: Usuario): void {
    if (this._idUsuarioAlteracao && this._idUsuarioAlteracao != usuario.id) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this._idUsuarioAlteracao)), this.bibServico.usuario).subscribe((res) => {
        this.setNomeFotoUsuarioAlteracao(plainToClass(Usuario, res[0]) as any);
      });
    } else {
      this.setNomeFotoUsuarioAlteracao(usuario);
    }
  }

  setNomeFotoUsuarioAlteracao(usuario: Usuario): void {
    this.nomeUsuarioAlteracao = usuario.nome;
    this.fotoUsuarioAlteracao = usuario.foto == 'S' ? this.conexao.caminhoAwsS3 + '' + usuario.idEmpresa + '/usuario/' + usuario.id + '.jpg' : this.fotoUsuarioPadrao;
  }
}
