import { CofinsAliq } from "./cofinsAliq";
import { CofinsNt } from "./cofinsNt";
import { CofinsOutr } from "./cofinsOutr";
import { CofinsQtde } from "./cofinsQtde";

export class Cofins {
    public COFINSNT: CofinsNt;
    public COFINSAliq: CofinsAliq;
    public COFINSQtde: CofinsQtde;
    public COFINSOutr: CofinsOutr;
}