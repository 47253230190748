export class Utilitario {
  public icone: string;
  public legenda: string;
  public mensagem: string;
  public visivel: boolean = true;
  public atencao: string;

  constructor(icone: string, legenda: string, mensagem: string = null, visivel: boolean = true, atencao: string = null) {
    this.icone = icone;
    this.legenda = legenda;
    this.mensagem = mensagem;
    this.visivel = visivel;
    this.atencao = atencao;
  }
}
