<ng-container class="border-bottom border-dark">
  <td class="p-0" colSpan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>     
          <th class="r-1-5 text-right">{{ bibDialogo.numero }}</th>
          <th class="r-1">{{ bibDialogo.data }}</th>
          <th class="r">{{ bibDialogo.naturezaOperacao }}</th>
          <th class="r-1 text-right">{{ bibDialogo.quantidade }}</th>
          <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let notaFiscal of movimentacaoProduto.notasfiscais">
          <tr>          
            <td class="text-right">
              <a href="#" (click)="abrirModalMovimentacao(notaFiscal.id)">{{ notaFiscal.numero }}</a>
            </td>
            <td>{{ notaFiscal.data | data }}</td>
            <td>{{ notaFiscal.naturezaOperacao }}</td>            
            <td class="text-right">{{ notaFiscal.quantidade | monetario }}</td>
            <td class="text-right">{{ notaFiscal.valorTotal | monetario }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <br/>
  </td>
</ng-container>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>