<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [grupos]="grupos" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (campoGrupoEmt)="agrupar(financeiros, $event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
        <th class="r-0-5" (click)="ordenar(financeiros, 'abreviacao')">{{ bibDialogo.loja }}</th>
        <th class="r" (click)="ordenar(financeiros, 'cliente')">{{ bibDialogo.cliente }}</th>        
        <th class="r-1" (click)="ordenar(financeiros, 'data')">{{ bibDialogo.data }}</th>
        <th class="r-1" (click)="ordenar(financeiros, 'dataVencimento')">{{ bibDialogo.vencimento }}</th>
        <th class="r-55-px" (click)="ordenar(financeiros, 'parcela')">{{ bibDialogo.parcela }}</th>
        <th class="r-0-5 text-right" (click)="ordenar(financeiros, 'numero')">{{ bibDialogo.numeroAbreviacao }}</th>
        <th class="r-1" (click)="ordenar(financeiros, 'colaborador')">{{ bibDialogo.vendedor }}</th>
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'valorOriginal')">{{ bibDialogo.valor }} <ajuda [ajuda]="bibDialogo.ajudaValorOriginal"></ajuda></th>
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'acrescimo')">{{ bibDialogo.acrescimo }} <ajuda [ajuda]="bibDialogo.ajudaJuros"></ajuda></th>        
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'desconto')">{{ bibDialogo.desconto }} <ajuda [ajuda]="bibDialogo.ajudaValorDescontoAnulado"></ajuda></th>    
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'valorCredito')">{{ bibDialogo.credito }} <ajuda [ajuda]="bibDialogo.ajudaCredito"></ajuda></th>            
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'valorPago')">{{ bibDialogo.valor }} <br> {{ bibDialogo.pago }} <ajuda [ajuda]="bibDialogo.ajudaValorPago"></ajuda></th>    
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'valorRestante')">{{ bibDialogo.valorRestante }} <ajuda [ajuda]="bibDialogo.ajudaValorRestante"></ajuda></th>  
        <th class="r-1 text-right" (click)="ordenar(financeiros, 'descontoAntecipacao')">{{ bibDialogo.descontoAntecipacao }}</th> 
        <th class="r-1 text-right">{{ bibDialogo.valorResTotal }} <ajuda [ajuda]="bibDialogo.ajudaValorRestanteTotal"></ajuda></th>    
      </thead>
      <tbody>
        <ng-container *ngFor="let financeiro of financeiros; let i = index">
          <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
          <tr class="conteudo">
            <td>{{ financeiro.abreviacao }}</td>
            <td><ajuda class="naoImprimir" [ajuda]="financeiro.numeroCliente + ' - ' + financeiro.cliente" [ajudaDireita]="true"></ajuda>{{ financeiro.numeroCliente + ' - ' + financeiro.cliente }}</td>
            <td>{{ financeiro.data | data }}</td>
            <td>{{ financeiro.dataVencimento | data }}</td>
            <td>{{ financeiro.parcela }}</td>
            <td class="text-right">
              <a href="#" (click)="abrirModalFinanceiro(financeiro.id, financeiro.tipo)">{{ financeiro.numero }}</a>
            </td>
            <td><ajuda class="naoImprimir" [ajuda]="financeiro.numeroColaborador + ' - ' + financeiro.colaborador"></ajuda>{{ financeiro.numeroColaborador && financeiro.colaborador ? financeiro.numeroColaborador + ' - ' + financeiro.colaborador : '' }}</td>
            <td class="text-right">{{ (financeiro.valorOriginal | monetario) }}</td>
            <td class="text-right">{{ financeiro.acrescimo > 0 ? (financeiro.acrescimo | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.desconto > 0 ? (financeiro.desconto | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.valorCredito > 0 ? (financeiro.valorCredito | monetario) : '' }}</td>            
            <td class="text-right">{{ financeiro.valorPago > 0 ? (financeiro.valorPago | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.valorRestante > 0 ? (financeiro.valorRestante | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.descontoAntecipacao > 0 ?  (financeiro.descontoAntecipacao | monetario) : '' }}</td>
            <td class="text-right">{{ (financeiro.valorRestante - financeiro.descontoAntecipacao) > 0 ? ((financeiro.valorRestante - financeiro.descontoAntecipacao) | monetario) : '' }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
        </ng-container>
      </tbody>
    <tfoot>
        <tr *ngIf="financeiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot> 
</table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>