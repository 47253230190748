import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Desfazimento } from 'src/app/modelo/desfazimento';
import { DesfazimentoProduto } from 'src/app/modelo/desfazimentoProduto';
import { MotivoDesfazimento } from 'src/app/modelo/motivoDesfazimento';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';

@Component({
  selector: 'desfazimentoFrm',
  templateUrl: './desfazimentoFrm.component.html',
  styleUrls: ['./desfazimentoFrm.component.css'],
})
export class DesfazimentoFrmComponent extends PaginaComponent {
  private produtos: Produto[] = [];
  public colaborador: string;
  public confirmada: boolean = false;
  public criteriosResponsavel: Criterio[] = [new Criterio('ID_MENU', this.utilSessao.getPermissao().idMenu)];
  public desfazimento: Desfazimento = new Desfazimento();
  public focoObservacao: number;
  public focoQuantidade: number;
  public idColaborador: number;
  public idLojas: string;
  public indiceFoco: string = 'q0';
  public motivoDesfazimentos: MotivoDesfazimento[] = [];
  public movimentacao: Movimentacao = new Movimentacao();
  public observacao: string;
  public posicaoProduto: number = 0;
  public posicaoQuantidade: number = 0;
  public quantidadeFocada: boolean = false;
  public util: Util = new Util();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.utilSessao.getLojas().forEach((loja) => (this.idLojas = this.util.concatenar(this.idLojas, loja.id, ',')));
    const id: number = this.utilSessao.getIdentificacao('id')?.conteudo;
    this.idColaborador = this.utilSessao.getUsuario().idColaborador;
    this.colaborador = this.utilSessao.getUsuario().colaborador;
    this.listarMotivoDesfazimento();
    this.listarMovimentacao(id);
  }

  private listarMotivoDesfazimento(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.motivoDesfazimento).subscribe((res) => {
      this.motivoDesfazimentos = this.plainToClass(MotivoDesfazimento, res) as any;
    });
  }

  listarMovimentacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
      this.listarMovimentacaoProduto(this.movimentacao.id);
    });
  }

  listarMovimentacaoProduto(idMovimentacao: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_MOVIMENTACAO', idMovimentacao), new Criterio('LOJAS', this.idLojas)]), this.bibServico.movimentacaoProduto).subscribe((res) => {
      this.movimentacao.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      this.listarProduto();      
    });
  }

  private listarProduto(): void {
    const idProdutos: number[] = this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
    this.comunicacaoService.listar(new Transporte([new Criterio('IDS', idProdutos.toString())]), this.bibServico.produto).subscribe((res) => {
      this.produtos = this.plainToClass(Produto, res) as any;
      this.produtos.forEach((produto) => {
        this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
          if (movimentacaoProduto.idProduto == produto.id) {
            movimentacaoProduto.grupo = produto.grupo;
            movimentacaoProduto.categoria = produto.categoria;
          }
        });
      });
      this.listarQuantidades();
    });
  }

  private listarQuantidades(): void {
    let idsMovimentacaoProduto: string = '';
    this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => (idsMovimentacaoProduto = this.util.concatenar(idsMovimentacaoProduto, movimentacaoProduto.id)));
    this.comunicacaoService.listar(new Transporte([new Criterio('IDS_MOVIMENTACAO_PRO', idsMovimentacaoProduto), new Criterio('LOJAS', this.idLojas)]), this.bibServico.movimentacaoQuantidade).subscribe((res) => {
      const movimentacaoQuantidades: MovimentacaoQuantidade[] = this.plainToClass(MovimentacaoQuantidade, res) as any;
      this.vincularMovimentacaoQuantidade(movimentacaoQuantidades);
    });
  }

  private vincularMovimentacaoQuantidade(movimentacaoQuantidades: MovimentacaoQuantidade[]): void {
    this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) =>
      movimentacaoQuantidades.map((movimentacaoQuantidade) => {
        if (movimentacaoProduto.id == movimentacaoQuantidade.idMovimentacaoProduto) {
          movimentacaoProduto.movimentacaoQuantidades.push(movimentacaoQuantidade);
        }
      })
    );
  }

  persistirDesfazimento(): void {
    if (this.ehValido()) {
      this.desfazimento.idLoja = this.movimentacao.idLoja;
      this.desfazimento.idMovimentacao = this.movimentacao.id;
      this.desfazimento.idColaborador = this.idColaborador;
      this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => {
        movimentacaoProduto.movimentacaoQuantidades.map((movimentacaoQuantidade) => {
          if (movimentacaoQuantidade.quantidadeDesfazimento > 0) {
            let desfazimentoProduto: DesfazimentoProduto = new DesfazimentoProduto();
            desfazimentoProduto.idEmpresa = movimentacaoProduto.idEmpresa;
            desfazimentoProduto.idProduto = movimentacaoProduto.idProduto;
            desfazimentoProduto.idMovimentacao = this.movimentacao.id;
            desfazimentoProduto.idMovimentacaoQuantidade = movimentacaoQuantidade.id;
            desfazimentoProduto.quantidade = movimentacaoQuantidade.quantidadeDesfazimento;
            desfazimentoProduto.idLoja = movimentacaoQuantidade.idLoja;
            desfazimentoProduto.idLocal = movimentacaoQuantidade.idLocal;
            desfazimentoProduto.idLote = movimentacaoQuantidade.idLote;
            this.desfazimento.desfazimentoProdutos.push(desfazimentoProduto);
          }
        });
      });
      super.persistir(new Transporte(this.desfazimento), this.bibServico.desfazimento, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.confirmada = true;
        }
      });
    }
  }

  ehValido(): boolean {
    const obrigatorio = this.ehValidoObrigatorio();
    const quantidade = this.ehValidoQuantidade();
    return obrigatorio && quantidade;
  }

  ehValidoObrigatorio(): boolean {
    if (!this.idColaborador || !this.desfazimento.idMotivoDesfazimento) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  ehValidoQuantidade(): boolean {
    let peloMenosUmProduto: boolean = false;
    let quantidadeSuperiorRestante: boolean = true;
    this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => {
      movimentacaoProduto.movimentacaoQuantidades.map((movimentacaoQuantidade) => {
        if (movimentacaoQuantidade.quantidadeDesfazimento > 0) {
          peloMenosUmProduto = true;
        }
        if (movimentacaoQuantidade.quantidadeDesfazimento > (movimentacaoQuantidade.quantidade * movimentacaoProduto.quantidadePendenteFaturamento) / movimentacaoProduto.quantidade) {
          quantidadeSuperiorRestante = false;
          this.utilSessao.setResultado(new Resultado(false, 'O produto ' + movimentacaoProduto.produto + ' está com quantidade (' + movimentacaoQuantidade.quantidadeDesfazimento + ') maior que a quantidade restante (' + (movimentacaoQuantidade.quantidade * movimentacaoProduto.quantidadePendenteFaturamento) / movimentacaoProduto.quantidade + ').'));
        }
      });
      if (peloMenosUmProduto == false) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeQuantidadeUmPro));
      }
    });

    return peloMenosUmProduto && quantidadeSuperiorRestante;
  }

  setQuantidadeEntregue(evento: any, indiceProduto: number, indiceQuantidade: number, quantidadePendenteFaturamento): void {
    this.posicaoProduto = indiceProduto;
    this.posicaoQuantidade = indiceQuantidade;
    switch (evento.keyCode) {
      case this.bibAtalho.espaco:
        if (this.quantidadeFocada) {
          const movimentacaoQuantidade: MovimentacaoQuantidade = this.movimentacao.movimentacaoProdutos[this.posicaoProduto].movimentacaoQuantidades[this.posicaoQuantidade];
          movimentacaoQuantidade.quantidadeDesfazimento = quantidadePendenteFaturamento;
          let elementoFocar: any = document.getElementById('p' + (this.posicaoProduto + 1));
          this.indiceFoco = 'p' + (this.posicaoProduto + 1);
          if (elementoFocar != null) {
            elementoFocar.focus();
          } else if (this.focoObservacao) {
            this.focoObservacao = Math.random();
          }
        }
        break;
    }
  }

  setPosicao(evento: boolean, j: number, i: number): void {
    this.quantidadeFocada = evento;
    if (evento) {
      this.posicaoProduto = j;
      this.posicaoQuantidade = i;
    }
  }

  limparNomeColaborador(): void {
    this.movimentacao.nomeParceiro = null;
    this.movimentacao.idParceiro = null;
    this.focoQuantidade = Math.random();
  }
}
