import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Conexao } from 'src/app/biblioteca/conexao';
import { NfeXmlPdf } from 'src/app/modelo/nfeXmlPdf';
import { Resultado } from 'src/app/modelo/resultado';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

export class UtilNfeXmlPdf {
  public bibDialogo = bibDialogo;

  constructor(public utilSessao: UtilSessao, public http: HttpClient) { }

  baixar(nfeXmlPdf: NfeXmlPdf) {

    const link: string = new Conexao(this.utilSessao).caminhoAwsS3 + nfeXmlPdf.idEmpresa + '/nfe/' + this.criarNomeSolicitacao(nfeXmlPdf) + '.zip';
    this.consumir(link).subscribe(
      (res) => {
        this.download(res);
      },
      () => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
      }
    );
  }

  criarNomeSolicitacao(nfeXmlPdf: NfeXmlPdf) {
    return nfeXmlPdf.idLoja.toString() + new Date(nfeXmlPdf.dataInicio).getFullYear().toString() + (new Date(nfeXmlPdf.dataInicio).getMonth() + 1).toString() + new Date(nfeXmlPdf.dataInicio).getDate().toString() + nfeXmlPdf.idUsuarioInclusao.toString() + new Date(nfeXmlPdf.dataFim).getFullYear().toString() + (new Date(nfeXmlPdf.dataFim).getMonth() + 1).toString() + new Date(nfeXmlPdf.dataFim).getDate().toString() + nfeXmlPdf.idEmpresa.toString() + nfeXmlPdf.id.toString() + (nfeXmlPdf.idParceiro ? nfeXmlPdf.idParceiro : 0);
  }

  consumir(url: string): Observable<Blob> {
    let headers: HttpHeaders = new HttpHeaders().set('Accept', 'application/zip');
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

  download(data: any): void {
    const blob = new Blob([data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
