import { Criterio } from './criterio';

export class Filtro {
  public ajuda: string;
  public apresentacao: string;
  public coluna: number;
  public criterios: Criterio[] = [];
  public id: string;
  public idIntegracao: number;
  public idMenu: number;
  public idNomenclatura: number;
  public idRelatorio: number;
  public lista: any[] = [];
  public manual: string = 'N';
  public nome: string;
  public obrigatorio: string = 'N';
  public ordem: number;
  public padrao: string;
  public rotulo: string;
  public rotulos: any[];
  public servicoWeb: string;
  public subordem: number;
  public sufixo: string;
  public tipo: number;
  public tipoPessoa: string = '';
  public valor: any = '';
  public valorVinculado: any;
  public vincularValor: string = 'N';
  public todos: string = 'N';

  public vinculoVisualizacao: string;
  public visualizacao: string[] = [];
  public visualizacaoInicial: boolean = true;

  public vinculaBloqueado: string;
  public bloqueados: string[] = [];
  public bloqueioInicial: boolean = false;

  public naoLimpa: boolean = false;
}
