export class BoletoEmail {
  IdIntegracao: string;
  SacadoCPFCNPJ: string;
  EmailNomeRemetente: string;
  EmailRemetente: string;
  EmailDestinatario: string;
  EmailAssunto: string;
  EmailMensagem: string;

  constructor(idIntegracao: string, sacadoCpfCnpj: string, nomeRementente: string, emailRementente: string, emailDestinatiario: string, emailAssunto: string, emailMensagem: string) {
    this.IdIntegracao = idIntegracao;
    this.SacadoCPFCNPJ = sacadoCpfCnpj;
    this.EmailNomeRemetente = nomeRementente;
    this.EmailRemetente = emailRementente;
    this.EmailDestinatario = emailDestinatiario;
    this.EmailAssunto = emailAssunto;
    this.EmailMensagem = emailMensagem;
  }
}
