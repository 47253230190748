import { Component, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Foto } from 'src/app/modelo/foto';
import { Usuario } from 'src/app/modelo/usuario';
import { Conexao } from 'src/app/biblioteca/conexao';

@Component({
  selector: 'carrossel ',
  templateUrl: './carrossel .component.html',
  styleUrls: ['./carrossel .component.css'],
})
export class CarrosselComponent extends PaginaComponent {
  @Input() fotos: any[] = [];
  @Input() somenteVisualizacao: boolean = false;
  @Output() imagem = new EventEmitter<string>();
  @Input() pasta: string = 'produto';
  @Input() idPai: number;
  @ViewChild('arquivoUpload') arquivoUpload: ElementRef;
  public compacto: boolean = this.utilSessao.getEmpresa().compacto == 'S';
  public usuario: Usuario = this.utilSessao.getUsuario();
  public conexao: Conexao = new Conexao(this.utilSessao);

  setarArquivo(arquivoInput: any): void {
    for (let arquivo of arquivoInput.target.files) {
      var foto = new Foto();
      var reader = new FileReader();
      reader.onload = function () {
        foto.arquivoRetorno = reader.result;
      };
      reader.readAsDataURL(arquivo);
      foto.arquivo = arquivo.name;
      foto.idEmpresa = this.usuario.idEmpresa;
      foto.pendente = 'S';
      foto.excluido = 'N';
      foto.arquivoFile = <File>arquivo;
      this.fotos.push(foto);
    }
  }

  deletar(foto: any): void {
    if (confirm(this.bibDialogo.desejaExcluirFoto)) {
      foto.excluido = 'S';
      this.fotos = this.fotos.filter((foto) => foto.excluido != 'S');
    }
  }

  abrirImagem(imagemBase64: string): void {
    this.imagem.emit(imagemBase64);
  }
}
