<titulo [titulo]="bibDialogo.endereco" [imagem]="bibImagem.endereco" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.endereco.toLowerCase()" [desabilitado]="temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
<div class="row" *ngIf="_parceiroEnderecos.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.endereco.toLowerCase() + ', ' }} <strong class="clique" [id]="'adicionarEndereco'" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="_parceiroEnderecos.length > 0">
  <thead>
    <tr>
      <th class="l-40">{{ bibDialogo.logradouro }}</th>
      <th class="l-10">{{ bibDialogo.numero }}</th>
      <th class="l-15">{{ rotuloBairro }}</th>
      <th class="l-15">{{ rotuloCidade }}</th>
      <th class="l-10">{{ bibDialogo.cep }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{ tachado: parceiroEndereco.ativo != 'S' }" *ngFor="let parceiroEndereco of _parceiroEnderecos; let i = index">
      <td *ngIf="parceiroEndereco.endereco == null" class="limitar"><atencao [atencao]="bibDialogo.logradouroObrigatorio" [tipo]="bibPropriedade.atencao.cuidado"></atencao></td>
      <td *ngIf="parceiroEndereco.endereco != null" class="limitar">{{ parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco }}</td>
      <td>{{ parceiroEndereco.numero }}</td>
      <td class="limitar">{{ parceiroEndereco.bairro }}</td>
      <td class="limitar">{{ parceiroEndereco.cidade }}</td>
      <td>{{ parceiroEndereco.cep }}</td>
      <td>
        <btnAdicional [id]="'editarEndereco' + i" [icone]="bibIcone.editar" (btnAdicional)="abrirModal(parceiroEndereco)"></btnAdicional>
      </td>
      <td>
        <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this._parceiroEnderecos, this.excluirEnderecos, parceiroEndereco.id)"></btnAdicional>
      </td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
