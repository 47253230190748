import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboEstoque: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'DISPONÍVEL', id: 'EST.QUANTIDADE_DISPONIVEL' },
  { nome: 'FÍSICO', id: 'EST.QUANTIDADE_FISICA' },
  { nome: 'NFE', id: 'EST.QUANTIDADE_NFE' },
]);

const filtroComboDiferenca: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: '1ª LOJA SALDO MAIOR', id: ' > 0' },
  { nome: '2ª LOJA SALDO MAIOR', id: ' < 0' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.primeiraLoja, nome: 'ID_LOJA_UM', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { rotulo: bibDialogo.segundaLoja, nome: 'ID_LOJA_DOIS', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { rotulo: bibDialogo.produtoDescricao, nome: 'NOME_PRODUTO', coluna: 6, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.estoque, nome: 'ESTOQUE', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'EST.QUANTIDADE_DISPONIVEL', lista: filtroComboEstoque },
  { rotulo: bibDialogo.diferenca, nome: 'DIFERENCA', obrigatorio: 'S', coluna: 6, tipo: 5, valor: '-', lista: filtroComboDiferenca },
]);
export default filtros;
