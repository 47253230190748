import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Cfop } from 'src/app/modelo/cfop';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Estado } from 'src/app/modelo/estados';
import { GrupoIcms } from 'src/app/modelo/grupoIcms';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoIcms } from 'src/app/modelo/produtoIcms';
import { ProdutoIcmsEspecifico } from 'src/app/modelo/produtoIcmsEspecifico';
import { ProdutoIcmsSt } from 'src/app/modelo/produtoIcmsSt';
import { ProdutoPisCofins } from 'src/app/modelo/produtoPisCofins';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { IcmsFrmComponent } from 'src/app/pagina/imposto/icms/icmsFrm.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { PisCofinsFrmComponent } from '../pisCofins/pisCofinsFrm.component';

@Component({
  templateUrl: './produtoImpostoFrm.component.html',
  styleUrls: ['./produtoImpostoFrm.component.css'],
})
export class ProdutoImpostoFrmComponent extends PaginaComponent {
  private cstIpis: Cst[] = [];
  private idNcm: number;
  private modal: boolean = false;
  private ncmDescricao: string;
  public cfopsEmpresaEntrada: CfopEmpresa[] = [];
  public cfopsEmpresaSaida: CfopEmpresa[] = [];
  public cfopsEmpresaTransferencia: CfopEmpresa[] = [];
  public compacto: boolean;
  public componente: any;
  public cstIpiEntradas: Cst[] = [];
  public cstIpiSaidas: Cst[] = [];
  public cstsPisCofinsEntrada: Cst[] = [];
  public cstsPisCofinsSaida: Cst[] = [];
  public excluirProdutoIcmsSts: number[] = [];
  public excluirProdutoImpostosIcms: number[] = [];
  public produto: Produto = new Produto();
  public produtoPisCofins: ProdutoPisCofins = new ProdutoPisCofins();
  public ehICMSDiferido: boolean = false;
  public estados: Estado[] = [];
  public estadosOrigem: Estado[] = [];
  public excluirProdutoImpostoIcmsEspecificos: number[] = [];
  public edicaoProdutoImpostoIcmsEspecificos: number[] = [];
  public excluirProdutoImpostoPisCofins: number[] = [];
  public focoEstado: number;
  public gruposIcms: GrupoIcms[] = [];

  public tipoItem: any[] = [
    { nome: '00 - Mercadoria para Revenda', id: '00' },
    { nome: '01 - Matéria-Prima', id: '01' },
    { nome: '02 - Embalagem', id: '02' },
    { nome: '03 - Produto em Processo', id: '03' },
    { nome: '04 - Produto Acabado', id: '04' },
    { nome: '05 - Subproduto', id: '05' },
    { nome: '06 - Produto Intermediário', id: '06' },
    { nome: '07 - Material de Uso e Consumo', id: '07' },
    { nome: '08 - Ativo Imobilizado', id: '08' },
    { nome: '09 - Serviços', id: '09' },
    { nome: '10 - Outros insumos', id: '10' },
    { nome: '99 - Outras', id: '99' },
  ];

  public produtoGeneros: any[] = [
    { nome: '00 - Serviço', id: '00' },
    { nome: '01 - Animais vivos', id: '01' },
    { nome: '02 - Carnes e miudezas, comestíveis', id: '02' },
    { nome: '03 - Peixes e crustáceos, moluscos e os outros invertebrados aquáticos', id: '03' },
    { nome: '04 - Leite e laticínios, ovos de aves, mel natural, produtos comestíveis de origem animal, não especificados', id: '04' },
    { nome: '05 - Outros produtos de origem animal, não especificados nem compreendidos em outros Capítulos da TIPI', id: '05' },
    { nome: '06 - Plantas vivas e produtos de floricultura', id: '06' },
    { nome: '07 - Produtos hortícolas, plantas, raízes e tubérculos, comestíveis', id: '07' },
    { nome: '08 - Frutas, cascas de cítricos e de melões', id: '08' },
    { nome: '09 - Café, chá, mate e especiarias', id: '09' },
    { nome: '10 - Cereais', id: '10' },
    { nome: '11 - Produtos da indústria de moagem, malte, amidos e féculas, inulina, glúten de trigo', id: '11' },
    { nome: '12 - Sementes e frutos oleaginosos, grãos, sementes e frutos diversos, plantas industriais ou medicinais, palha e fo', id: '12' },
    { nome: '13 - Gomas, resinas e outros sucos e extratos vegetais', id: '13' },
    { nome: '14 - Matérias para entrançar e outros produtos de origem vegeta', id: '14' },
    { nome: '15 - Gorduras e óleos animais ou vegetais, produtos da sua dissociação, gorduras alimentares elaboradas, ceras de or', id: '15' },
    { nome: '16 - Preparações de carne, de peixes ou de crustáceos, de moluscos ou de outros invertebrados aquáticos', id: '16' },
    { nome: '17 - Açúcares e produtos de confeitaria', id: '17' },
    { nome: '18 - Cacau e suas preparações', id: '18' },
    { nome: '19 - Preparações à base de cereais, farinhas, amidos, féculas ou de leite, produtos de pastelaria', id: '19' },
    { nome: '20 - Preparações de produtos hortícolas, de frutas ou de outras partes de plantas', id: '20' },
    { nome: '21 - Preparações alimentícias diversas', id: '21' },
    { nome: '22 - Bebidas, líquidos alcoólicos e vinagres', id: '22' },
    { nome: '23 - Resíduos e desperdícios das indústrias alimentares, alimentos preparados para animais', id: '23' },
    { nome: '24 - Fumo (tabaco) e seus sucedâneos, manufaturados', id: '24' },
    { nome: '25 - Sal, enxofre, terras e pedras, gesso, cal e cimento', id: '25' },
    { nome: '26 - Minérios, escórias e cinzas', id: '26' },
    { nome: '27 - Combustíveis minerais, óleos minerais e produtos de sua destilação, matérias betuminosas, ceras minerais', id: '27' },
    { nome: '28 - Produtos químicos inorgânicos, compostos inorgânicos ou orgânicos de metais preciosos, de elementos radioativos', id: '28' },
    { nome: '29 - Produtos químicos orgânicos', id: '29' },
    { nome: '30 - Produtos farmacêuticos', id: '30' },
    { nome: '31 - Adubos ou fertilizantes', id: '31' },
    { nome: '32 - Extratos tanantes e tintoriais, taninos e seus derivados, pigmentos e outras matérias corantes, tintas e verniz', id: '32' },
    { nome: '33 - Óleos essenciais e resinóides, produtos de perfumaria ou de toucador preparados e preparações cosméticas', id: '33' },
    { nome: '34 - Sabões, agentes orgânicos de superfície, preparações para lavagem, preparações lubrificantes, ceras artificiais', id: '34' },
    { nome: '35 - Matérias albuminóides, produtos à base de amidos ou de féculas modificados, colas, enzimas', id: '35' },
    { nome: '36 - Pólvoras e explosivos, artigos de pirotecnia, fósforos, ligas pirofóricas, matérias inflamáveis', id: '36' },
    { nome: '37 - Produtos para fotografia e cinematografia', id: '37' },
    { nome: '38 - Produtos diversos das indústrias químicas', id: '38' },
    { nome: '39 - Plásticos e suas obras', id: '39' },
    { nome: '40 - Borracha e suas obras', id: '40' },
    { nome: '41 - Peles, exceto a peleteria (peles com pêlo*), e couros', id: '41' },
    { nome: '42 - Obras de couro, artigos de correeiro ou de seleiro, artigos de viagem, bolsas e artefatos semelhantes, obras de', id: '42' },
    { nome: '43 - Peleteria (peles com pelo*) e suas obras, peleteria (peles com pelo*) artificial', id: '43' },
    { nome: '44 - Madeira, carvão vegetal e obras de madeira', id: '44' },
    { nome: '45 - Cortiça e suas obras', id: '45' },
    { nome: '46 - Obras de espartaria ou de cestaria', id: '46' },
    { nome: '47 - Pastas de madeira ou de outras matérias fibrosas celulósicas, papel ou cartão de reciclar (desperdícios e apara', id: '47' },
    { nome: '48 - Papel e cartão, obras de pasta de celulose, de papel ou de cartão', id: '48' },
    { nome: '49 - Livros, jornais, gravuras e outros produtos das indústrias gráficas, textos manuscritos ou datilografados, plan', id: '49' },
    { nome: '50 - Seda', id: '50' },
    { nome: '51 - Lã e pelos finos ou grosseiros, fios e tecidos de crina', id: '51' },
    { nome: '52 - Algodão', id: '52' },
    { nome: '53 - Outras fibras têxteis vegetais, fios de papel e tecido de fios de papel', id: '53' },
    { nome: '54 - Filamentos sintéticos ou artificiais', id: '54' },
    { nome: '55 - Fibras sintéticas ou artificiais, descontínuas', id: '55' },
    { nome: '56 - Pastas ("ouates"), feltros e falsos tecidos, fios especiais, cordéis, cordas e cabos, artigos de cordoaria', id: '56' },
    { nome: '57 - Tapetes e outros revestimentos para pavimentos, de matérias têxteis', id: '57' },
    { nome: '58 - Tecidos especiais, tecidos tufados, rendas, tapeçarias, passamanarias, bordados', id: '58' },
    { nome: '59 - Tecidos impregnados, revestidos, recobertos ou estratificados, artigos para usos técnicos de matérias têxteis', id: '59' },
    { nome: '60 - Tecidos de malha', id: '60' },
    { nome: '61 - Vestuário e seus acessórios, de malha', id: '61' },
    { nome: '62 - Vestuário e seus acessórios, exceto de malha', id: '62' },
    { nome: '63 - Outros artefatos têxteis confeccionados, sortidos, artefatos de matérias têxteis, calçados, chapéus e artefatos', id: '63' },
    { nome: '64 - Calçados, polainas e artefatos semelhantes, e suas partes', id: '64' },
    { nome: '65 - Chapéus e artefatos de uso semelhante, e suas partes', id: '65' },
    { nome: '66 - Guarda-chuvas, sombrinhas, guarda-sóis, bengalas, bengalas-assentos, chicotes, e suas partes', id: '66' },
    { nome: '67 - Penas e penugem preparadas, e suas obras, flores artificiais, obras de cabelo', id: '67' },
    { nome: '68 - Obras de pedra, gesso, cimento, amianto, mica ou de matérias semelhantes', id: '68' },
    { nome: '69 - Produtos cerâmicos', id: '69' },
    { nome: '70 - Vidro e suas obras', id: '70' },
    { nome: '71 - Pérolas naturais ou cultivadas, pedras preciosas ou semipreciosas e semelhantes, metais preciosos, metais folhe', id: '71' },
    { nome: '72 - Ferro fundido, ferro e aço', id: '72' },
    { nome: '73 - Obras de ferro fundido, ferro ou aço', id: '73' },
    { nome: '74 - Cobre e suas obras', id: '74' },
    { nome: '75 - Níquel e suas obras', id: '75' },
    { nome: '76 - Alumínio e suas obras', id: '76' },
    { nome: '77 - (Reservado para uma eventual utilização futura no SH)', id: '77' },
    { nome: '78 - Chumbo e suas obras', id: '78' },
    { nome: '79 - Zinco e suas obras', id: '79' },
    { nome: '80 - Estanho e suas obras', id: '80' },
    { nome: '81 - Outros metais comuns, ceramais ("cermets"), obras dessas matérias', id: '81' },
    { nome: '82 - Ferramentas, artefatos de cutelaria e talheres, e suas partes, de metais comuns', id: '82' },
    { nome: '83 - Obras diversas de metais comuns', id: '83' },
    { nome: '84 - Reatores nucleares, caldeiras, máquinas, aparelhos e instrumentos mecânicos, e suas partes', id: '84' },
    { nome: '85 - Máquinas, aparelhos e materiais elétricos, e suas partes, aparelhos de gravação ou de reprodução de som, aparel', id: '85' },
    { nome: '86 - Veículos e material para vias férreas ou semelhantes, e suas partes, aparelhos mecânicos (incluídos os eletrome', id: '86' },
    { nome: '88 - Aeronaves e aparelhos espaciais, e suas partes', id: '88' },
    { nome: '89 - Embarcações e estruturas flutuantes', id: '89' },
    { nome: '90 - Instrumentos e aparelhos de óptica, fotografia ou cinematografia, medida, controle ou de precisão, instrumentos', id: '90' },
    { nome: '91 - Aparelhos de relojoaria e suas partes', id: '91' },
    { nome: '92 - Instrumentos musicais, suas partes e acessórios', id: '92' },
    { nome: '93 - Armas e munições, suas partes e acessórios', id: '93' },
    { nome: '94 - Móveis, mobiliário médico-cirúrgico, colchões, iluminação e construção pré-fabricadas', id: '94' },
    { nome: '95 - Brinquedos, jogos, artigos para divertimento ou para esporte, suas partes e acessórios', id: '95' },
    { nome: '96 - Obras diversas', id: '96' },
    { nome: '97 - Objetos de arte, de coleção e antiguidades', id: '97' },
    { nome: '98 - (Reservado para usos especiais pelas Partes Contratantes)', id: '98' },
    { nome: '99 - Operações especiais (utilizado exclusivamente pelo Brasil para classificar operações especiais na exportação)', id: '99' },
  ];

  public listaEnquadramento: any = [
    { id: '2', nome: this.bibDialogo.lucroPresumido.toUpperCase() },
    { id: '3', nome: this.bibDialogo.lucroReal.toUpperCase() },
    { id: '1', nome: this.bibDialogo.simplesNacional.toUpperCase() },
    { id: '4', nome: this.bibDialogo.simplesNacionalExcesso.toUpperCase() },
  ];

  ngOnInit(): void {
    this.compacto = this.utilSessao.getEmpresa().compacto == 'S';
    if (this.utilSessao.getIdentificacao('produto') != null) {
      this.produto = this.utilSessao.getIdentificacao('produto')?.conteudo;
    }
    this.modal = this.util.coalesce(this.utilSessao.getIdentificacao('modal')?.conteudo, false);
    this.ehAlteracao();
    this.listarCfopEmpresa();
    this.listarCstPisCofins();
    this.listarCstIpi();
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.produtoPisCofins) {
        this.adicionarTemporario(this.plainToClass(ProdutoPisCofins, identificacao.conteudo), this.produto.produtoPisCofins);
      }
      if (identificacao.nome == this.bibClasse.produtoIcms) {
        this.adicionarTemporario(this.plainToClass(ProdutoIcms, identificacao.conteudo), this.produto.produtoImpostosIcms);
        this.produto.produtoImpostosIcms.sort((a, b) => (a['estado'] == b['estado'] ? 0 : a['estado'] > b['estado'] ? 1 : -1));
        this.produto.produtoImpostosIcms.forEach((produtoImpostosIcms) => (produtoImpostosIcms.cstIcms.substring(1, produtoImpostosIcms.cstIcms.length).endsWith('51') ? (this.ehICMSDiferido = true) : ''));
        if (!this.ehICMSDiferido) {
          this.gruposIcms.forEach((grupoIcms) => (grupoIcms.cstIcms.substring(1, grupoIcms.cstIcms.length).endsWith('51') ? (this.ehICMSDiferido = true) : ''));
        }
      }
    });
  }

  ehAlteracao(): void {
    this.produto = this.plainToClass(Produto, this.ehAtualizacao(this.produto));
    if (this.produto.id) {
      let enquadramentosLojas: string[] = this.utilSessao.getLojas().map((loja) => loja.enquadramento?.toString());
      this.listaEnquadramento = this.listaEnquadramento.filter((enquadramento) => enquadramentosLojas.includes(enquadramento.id));
      this.listarProduto();
    }
  }

  listarProduto(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.produto.id)), this.bibServico.produto).subscribe((res) => {
      this.produto = this.plainToClass(Produto, res[0]) as any;
      this.idNcm = this.produto.idNcm;
      this.ncmDescricao = this.produto.ncm;
      this.listarProdutoIcms();
      this.listarProdutoPisCofins();
      this.listarProdutoGenero();
    });
  }

  listarGrupoIcms(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO', this.produto.idGrupo)), this.bibServico.grupoIcms).subscribe((res) => {
      this.gruposIcms = this.plainToClass(GrupoIcms, res) as any;
      for (const grupoIcms of this.gruposIcms) {
        if (grupoIcms.cstIcms.substring(1, grupoIcms.cstIcms.length).endsWith('51')) {
          this.ehICMSDiferido = true;
        }
      }
    });
  }

  listarProdutoIcms(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoIcms).subscribe((res) => {
      this.produto.produtoImpostosIcms = this.plainToClass(ProdutoIcms, res) as any;
      for (const produtoIcms of this.produto.produtoImpostosIcms) {
        if (produtoIcms.cstIcms.substring(1, produtoIcms.cstIcms.length).endsWith('51')) {
          this.ehICMSDiferido = true;
        }
      }
      if (!this.ehICMSDiferido) {
        this.listarGrupoIcms();
      }
      this.listarProdutoIcmsSt();
    });
  }

  listarProdutoPisCofins(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoPisCofins).subscribe((res) => {
      this.produto.produtoPisCofins = this.plainToClass(ProdutoPisCofins, res) as any;
    })
  }

  listarProdutoGenero(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoIcms).subscribe((res) => {
      this.produto.produtoImpostosIcms = this.plainToClass(ProdutoIcms, res) as any;
    });
  }

  listarProdutoIcmsEspecifico(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoIcmsEspecifico).subscribe((res) => {
      this.produto.produtoImpostosIcmsEspecifico = this.plainToClass(ProdutoIcmsEspecifico, res) as any;
    });
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res);
      let estadosLojas: string[] = this.utilSessao.getLojas().map((loja) => loja.idEstado?.toString());
      this.estadosOrigem = this.estados.filter((estado) => estadosLojas.includes(estado.id.toString()));
      this.listarProdutoIcmsEspecifico();
    });
  }

  listarProdutoIcmsSt(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoIcmsSt).subscribe((res) => {
      this.produto.produtoIcmsSts = this.plainToClass(ProdutoIcmsSt, res) as any;
    });
    this.listarEstado();
  }

  listarCfopEmpresa(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cfopEmpresa).subscribe((res) => {
      const cfopsEmpresa: CfopEmpresa[] = this.plainToClass(Cfop, res) as any;
      this.cfopsEmpresaEntrada = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopEntradaSaida == 'E');
      this.cfopsEmpresaSaida = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopEntradaSaida == 'S');
      this.cfopsEmpresaTransferencia = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopTransferencia == 'S');
    });
  }

  listarCstPisCofins(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstPisCofins).subscribe((res) => {
      const cstsPisCofins: Cst[] = this.plainToClass(Cst, res) as any;
      this.cstsPisCofinsEntrada = cstsPisCofins.filter((cstPisCofins) => cstPisCofins.entradaSaida == 'E');
      this.cstsPisCofinsSaida = cstsPisCofins.filter((cstPisCofins) => cstPisCofins.entradaSaida == 'S');
    });
  }

  listarCstIpi(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstIpi).subscribe((res) => {
      this.cstIpis = this.plainToClass(Cst, res) as any;
      this.cstIpiEntradas = this.cstIpis.filter((cstIpi) => cstIpi.entradaSaida == 'E');
      this.cstIpiSaidas = this.cstIpis.filter((cstIpi) => cstIpi.entradaSaida == 'S');
    });
  }

  abrirModalIcms(tipo: number, icms: any = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('icms', icms));
    this.utilSessao.setIdentificacao(new Identificacao('tipo', tipo));
    this.utilSessao.setIdentificacao(new Identificacao('classe', this.bibClasse.produtoIcms));
    this.componente = IcmsFrmComponent;
  }


  abrirModalPisCofins(tipo: number, pisCofins: any = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('pisCofins', pisCofins));
    this.utilSessao.setIdentificacao(new Identificacao('tipo', tipo));
    this.utilSessao.setIdentificacao(new Identificacao('classe', this.bibClasse.produtoPisCofins));
    this.componente = PisCofinsFrmComponent;
  }

  excluirProdutoIcms(posicao: number, tipo: number, id: number): void {
    const produtoIcms: ProdutoIcms[] = this.produto.produtoImpostosIcms.filter((produtoImpostoIcms) => produtoImpostoIcms.tipo == tipo);
    super.ehExcluir(posicao, produtoIcms, this.excluirProdutoImpostosIcms, id);
    this.produto.produtoImpostosIcms = this.produto.produtoImpostosIcms.filter((produtoImpostoIcms) => produtoImpostoIcms.tipo != tipo).concat(produtoIcms);
    this.ehICMSDiferido = false;
    this.produto.produtoImpostosIcms.forEach((produtoImpostosIcms) => (produtoImpostosIcms.cstIcms.substring(1, produtoImpostosIcms.cstIcms.length).endsWith('51') ? (this.ehICMSDiferido = true) : ''));
    if (!this.ehICMSDiferido) {
      this.gruposIcms.forEach((grupoIcms) => (grupoIcms.cstIcms.substring(1, grupoIcms.cstIcms.length).endsWith('51') ? (this.ehICMSDiferido = true) : ''));
    }
  }

  excluirProdutoPisCofins(posicao: number, id: number): void {
    super.ehExcluir(posicao, this.produto.produtoPisCofins, this.excluirProdutoImpostoPisCofins, id);
  }

  persistirProduto(): void {
    this.listarProdutoEdicao(this.produto.id).subscribe((res) => {
      if (this.ehValido() && this.ehValidoProdutoNcm(this.plainToClass(Produto, res[0]))) {
        const produtoIcmsComDesonerado = this.produto.produtoImpostosIcms.filter((produtoIcms) => produtoIcms.usaIcmsDesonerado != 'S');
        if (produtoIcmsComDesonerado.length > 0) {
          this.produto.motivoDesonerado = null;
        }
        let persistenciaProduto: Transporte = new Transporte(this.produto);
        persistenciaProduto.adicionar([]);
        persistenciaProduto.adicionar([]);
        persistenciaProduto.adicionar(this.excluirProdutoImpostosIcms);
        persistenciaProduto.adicionar(this.excluirProdutoIcmsSts);
        persistenciaProduto.adicionar(this.excluirProdutoImpostoIcmsEspecificos);
        persistenciaProduto.adicionar(this.excluirProdutoImpostoPisCofins);
        super.persistir(persistenciaProduto, this.bibServico.produto, null).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.definirId([this.produto], this.bibClasse.produto);
            this.definirId(this.produto.produtoImpostosIcms, this.bibClasse.produtoIcms);
            this.definirId(this.produto.produtoIcmsSts, this.bibClasse.produtoIcmsSt);
            this.definirId(this.produto.produtoPisCofins, this.bibClasse.produtoPisCofins);
            this.excluirProdutoIcmsSts = [];
            this.excluirProdutoImpostosIcms = [];
            this.excluirProdutoImpostoIcmsEspecificos = [];
            this.excluirProdutoImpostoPisCofins = [];
          }
          if (this.modal) {
            this.modal = false;
            this.modalNotificacao.modalEmt.emit(new Identificacao());
          }
          this.modalNotificacao.modalEmt.emit(new Identificacao('produto', this.produto));
        });
      }
    });
  }

  ehValidoAliquotaInterna(): boolean {
    let contagemAliquotaInterna: number = 0;
    let contagemCamposObrigatorios: number = 0;
    let mensagem: string = '';
    if (this.produto.produtoImpostosIcmsEspecifico.length > 0) {
      this.produto.produtoImpostosIcmsEspecifico.forEach((produtoImpostoIcmsEspecifico) => {
        if (produtoImpostoIcmsEspecifico.estadoOrigem == null || produtoImpostoIcmsEspecifico.estado == null || produtoImpostoIcmsEspecifico.enquadramento == null || produtoImpostoIcmsEspecifico.aliquotaFisica == null || produtoImpostoIcmsEspecifico.aliquotaJuridica == null) {
          contagemCamposObrigatorios++;
        }
        if (produtoImpostoIcmsEspecifico.aliquotaInterna != null) {
          if (produtoImpostoIcmsEspecifico.aliquotaInterna != 4 && produtoImpostoIcmsEspecifico.aliquotaInterna != 7 && produtoImpostoIcmsEspecifico.aliquotaInterna != 12) {
            mensagem += mensagem == '' ? '' : ', ';
            mensagem += produtoImpostoIcmsEspecifico.estado;
            contagemAliquotaInterna++;
          }
        }
      });
    }
    if (contagemAliquotaInterna > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.estadoLojaAliquotaInterna + ' ' + this.bibDialogo.aliquotasPermitidas + ' ' + this.bibDialogo.ajudaAliquotaInterna, [mensagem])));
    }
    if (contagemCamposObrigatorios > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    }
    if (contagemAliquotaInterna > 0 || contagemCamposObrigatorios > 0) {
      return false;
    }

    return true;
  }

  ehValido(): boolean {
    const ehValidoAliquotaInterna: boolean = this.ehValidoAliquotaInterna();
    if (ehValidoAliquotaInterna && (this.produto.idIpiEntrada ? this.produto.ipiAliquotaEntrada || this.produto.ipiAliquotaEntrada == 0 : true) && (this.produto.idIpiSaida ? this.produto.ipiAliquotaSaida || this.produto.ipiAliquotaSaida == 0 : true) && this.produto.idNcm != null) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEstadoOrigem(produtoImpostosIcmsEspecifico: ProdutoIcmsEspecifico, estadoOrigem: Estado) {
    produtoImpostosIcmsEspecifico.idEstadoOrigem = estadoOrigem.id;
    produtoImpostosIcmsEspecifico.estadoOrigem = estadoOrigem.abreviacao;
  }

  setEstadoDestino(produtoImpostosIcmsEspecifico: ProdutoIcmsEspecifico, estadoDestino: Estado) {
    produtoImpostosIcmsEspecifico.idEstado = estadoDestino.id;
    produtoImpostosIcmsEspecifico.estado = estadoDestino.nome;
  }

  setEnquadramento(produtoImpostosIcmsEspecifico: ProdutoIcmsEspecifico, enquadramento: any) {
    produtoImpostosIcmsEspecifico.enquadramento = enquadramento.id;
  }

  listarProdutoEdicao(idProduto: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idProduto)), this.bibServico.produto);
  }

  ehValidoProdutoNcm(produto: Produto): boolean {
    if (produto && produto.idNcm) {
      if (this.idNcm != produto.idNcm) {
        if (confirm(this.util.substituir(this.bibDialogo.edicaoParceiroNcms, [this.menuProduto.apelido.toLowerCase(), this.produto.nomeFiscal, this.ncmDescricao.substring(this.ncmDescricao.indexOf('-'), 0).trim(), produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim(), this.produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim(), produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim()]))) {
          this.produto.idNcm = produto.idNcm;
          this.produto.ncm = produto.ncm;
          return true;
        }
        return false;
      }
    }
    return true;
  }

  abrirSiteConfaz(): void {
    window.open('https://www.confaz.fazenda.gov.br/legislacao/convenios/2018/CV142_18');
  }

  excluirIcmsEspecifico(posicao: number, idIcmsEspecifico: number): void {
    super.ehExcluir(posicao, this.produto.produtoImpostosIcmsEspecifico, this.excluirProdutoImpostoIcmsEspecificos, idIcmsEspecifico);
  }

  adicionarIcmsEspecifico(): void {
    let produtoIcmsEspecifico = new ProdutoIcmsEspecifico();
    this.produto.produtoImpostosIcmsEspecifico.push(produtoIcmsEspecifico);
    this.focoEstado = Math.random();
  }

  editarIcmsEspecifico(produtoIcmsEspecifico: ProdutoIcmsEspecifico): void {
    if (this.edicaoProdutoImpostoIcmsEspecificos.includes(produtoIcmsEspecifico.id)) {
      const posicao: number = this.edicaoProdutoImpostoIcmsEspecificos.indexOf(produtoIcmsEspecifico.id, 0);
      this.edicaoProdutoImpostoIcmsEspecificos.splice(posicao, 1);
    } else {
      this.edicaoProdutoImpostoIcmsEspecificos.push(produtoIcmsEspecifico.id);
    }
  }
}
