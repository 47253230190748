<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.movimentacoesUltrapassaramLimiteMaximo }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.vend }}</th>
    <th class="r">{{ bibDialogo.cliente }}</th>
    <th class="r-2">{{ rotuloRamoAtividade }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorDebito }}</th>
    <th class="r-1 text-right">{{ bibDialogo.limiteMaximo }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorVencido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.diasAtraso }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let debitoMaiorLimite of debitoMaiorLimites">
      <tr>
        <td>{{ debitoMaiorLimite.vendedor }}</td>
        <td class="limitar">{{ debitoMaiorLimite.parceiroNumero + ' - ' + debitoMaiorLimite.parceiro }}</td>
        <td class="limitar">{{ debitoMaiorLimite.ramoAtividade }}</td>
        <td class="text-right">{{ debitoMaiorLimite.valorTotal | monetario }}</td>
        <td class="text-right">{{ debitoMaiorLimite.valorDebito | monetario }}</td>
        <td class="text-right">{{ debitoMaiorLimite.limiteMaximo | monetario }}</td>
        <td class="text-right">{{ debitoMaiorLimite.vencido | monetario }}</td>
        <td class="text-right">{{ debitoMaiorLimite.diasAtraso }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="debitoMaiorLimites.length > 0">
    <tr>
      <td colspan="3"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ debitoMaiorLimites[0].valorTotalTotal | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
