import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Baixa } from 'src/app/modelo/baixa';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'baixaComprovante',
  templateUrl: './baixaComprovante.component.html',
  styleUrls: ['./baixaComprovante.component.css'],
})
export class BaixaComprovanteComponent {
  @Input() baixa: Baixa;
  public bibDialogo = bibDialogo;
  public compacto: boolean;
  public data: Date = new Date();
  public rotuloBaixaParametro: string;

  constructor(private utilSessao: UtilSessao) {
    this.compacto = utilSessao.getEmpresa().compacto == 'S';
  }

  ngOnInit(): void {
    if (this.baixa.parametro == 'RECEITA') {
      this.rotuloBaixaParametro = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 93).apelido;
    }
    if (this.baixa.parametro == 'DESPESA') {
      this.rotuloBaixaParametro = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 104).apelido;
    }
    if (this.baixa.parametro == 'CAIXA') {
      this.rotuloBaixaParametro = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 166).apelido;
    }
    if (this.baixa.parametro == 'CONFERENCIA') {
      this.rotuloBaixaParametro = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 110).apelido;
    }
    if (this.baixa.parametro == 'RAPIDA') {
      this.rotuloBaixaParametro = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 191).apelido;
    }
    if (this.baixa.parametro == 'DUPLICATA') {
      this.rotuloBaixaParametro = this.bibDialogo.duplicataMercantil;
    }
    this.baixa.mostraRelatorio = true;
  }

  ir(): void {
    this.baixa.baixaNotificacao.reiniciaFiltroEmt.emit(true);
    this.baixa.setEtapa(1);
  }
}
