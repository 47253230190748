<nav *ngIf="baixa.getEtapa() == 2" class="bg-cinza-claro" [ngClass]="ehModoClaro ? '' : 'modo-escuro'">
  <div class="row mt-2 pt-2 pb-2">
    <div *ngIf="this.baixa.parametro == 'RAPIDA'" class="col-sm-4">
      <check [campo]="selecionaVencimento" (alteracao)="setSelecionadoVencimento()" [compacto]="true" [id]="'SelecionaVencimento'"></check>
      {{ bibDialogo.somenteEm + ' ' + (baixa.dataVencimentoAte | data) }}
    </div>    
    <div class="col-sm-4">
      <check [focoId]="focoIdInicial" [campo]="baixa.selecionadoAtrasados" (alteracao)="setSelecionadoAtrasados()" [compacto]="true" [id]="'SelecionaVencimento'"></check>
      {{ bibDialogo.selecionarVencimentoAte + ' ' + (dataAtual | data) }}
    </div>
    <div class="col-sm-4">
      <check [campo]="selecionaTodos" (alteracao)="setSelecionadoTodos()" [compacto]="true" [id]="'Selecionatodos'"></check>
      {{ bibDialogo.selecionarTodos }}
    </div>
  </div>
</nav>
<table class="table table-responsive-sm mb-0 naoImprimir">
  <thead>
    <tr>
      <th class="coluna-check-icone"></th>
      <th class="coluna-check-icone"></th>
      <th class="coluna-loja clique" (click)="util.ordenarLista(financeiros, 'abreviacao')">{{ baixa.bibDialogo.loja }}</th>
      <th class="l-5 text-right clique" (click)="util.ordenarLista(financeiros, 'numero')">{{ baixa.bibDialogo.numeroAbreviacao }}</th>
      <th class="coluna-parcela text-right clique" (click)="util.ordenarLista(financeiros, 'parcela')">{{ baixa.bibDialogo.parcela }}</th>
      <th *ngIf="this.baixa.parametro == 'RAPIDA'" (click)="util.ordenarLista(financeiros, 'parceiro')">{{ bibDialogo.cliente }}</th>
      <th *ngIf="this.baixa.parametro == 'RAPIDA'" class="l-5"></th>
      <th [ngClass]="this.baixa.parametro == 'RAPIDA' ? 'l-15' : this.baixa.parametro != 'CAIXA' || baixa.getEtapa() == 3 ? 'coluna-tipo-titulo' : 'coluna-tipo-titulo-g'" (click)="util.ordenarLista(financeiros, 'tipoTitulo')">{{ menuTipoDeTitulo.apelido }}</th>
      <th *ngIf="baixa.getEtapa() != 3 && this.baixa.parametro != 'CAIXA' && this.baixa.parametro != 'RAPIDA'" class="l-10 clique" (click)="util.ordenarLista(financeiros, 'dataNegociacao')">{{ baixa.bibDialogo.data + ' ' + baixa.bibDialogo.negociacao }}</th>
      <th class="l-10 clique" (click)="util.ordenarLista(financeiros, 'dataVencimento')">{{ baixa.bibDialogo.data + ' ' + baixa.bibDialogo.vencimento }}</th>
      <th class="l-10 text-right clique" (click)="util.ordenarLista(financeiros, 'valor')">{{ baixa.bibDialogo.valorOriginal }}</th>
      <th class="l-10 text-right clique" *ngIf="this.baixa.parametro != 'CAIXA' && this.baixa.parametro != 'RAPIDA'" (click)="util.ordenarLista(financeiros, 'juroAutomatico')">{{ baixa.bibDialogo.juroAutomatico }}</th>
      <th class="l-10 text-right clique" (click)="util.ordenarLista(financeiros, 'valorRestante')">{{ baixa.bibDialogo.valorRestante }}</th>
      <th *ngIf="baixa.getEtapa() == 3" class="l-10 text-right clique" (click)="util.ordenarLista(financeiros, 'valorAPagar')">{{ baixa.bibDialogo.valorAPagar }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container class="responsiva" *ngFor="let financeiro of financeiros; let i = index">
      <tr [ngClass]="ehModoClaro ? 'bg-cinza-claro' : 'badge-light'">
        <td class="coluna-check-icone">
          <check *ngIf="baixa.getEtapa() == 2" [campo]="financeiro.selecionado" (alteracao)="baixa.setSelecionado(financeiro, $event)" [indeterminado]="financeiro.selecionado == null" [id]="'selecionaItem' + i"></check>
        </td>
        <td class="coluna-check-icone">
          <icone [icone]="baixa.bibIcone.circulo" [cor]="financeiro.tipo == 'D' ? 'vermelho' : 'azul'" [mensagem]="financeiro.tipo == 'D' ? 'Despesa' : 'Receita'"></icone>
        </td>
        <td class="coluna-loja">{{ financeiro.abreviacao }}</td>
        <td class="l-5 text-right">
          <sup class="pr-1" *ngIf="financeiro.valorFiscal > 0" [ngbTooltip]="bibDialogo.registroPossuiVinculoNotaFiscal">{{ 'NF' }}</sup><a (click)="abrirFinanceiro(financeiro.id, financeiro.tipo)" href="#">{{ this.baixa.parametro == 'DUPLICATA' ? financeiro.numeroFiscal + ' ': financeiro.numero + ' ' }}</a> 
          <ajuda *ngIf="financeiro.numeroFiscal != undefined" [ajuda]="bibDialogo.numeroFiscal + ': ' + financeiro.numeroFiscal"></ajuda>
        </td>
        <td class="coluna-parcela text-right">{{ financeiro.parcela }}</td>
        <td *ngIf="this.baixa.parametro == 'RAPIDA'" class="limitar">{{ financeiro.parceiro }}</td>
        <td *ngIf="this.baixa.parametro == 'RAPIDA'"><ajuda [ajuda]="financeiro.clienteNomeFantasia"></ajuda></td>
        <td [ngClass]="this.baixa.parametro == 'RAPIDA' ? 'l-15' : this.baixa.parametro != 'CAIXA' || baixa.getEtapa() == 3 ? 'coluna-tipo-titulo' : 'coluna-tipo-titulo-g'" class="limitar">{{ financeiro.tipoTitulo }}<ajuda *ngIf="this.baixa.parametro != 'RAPIDA' && financeiro.tipoTitulo != ' '" class="naoImprimir" [ajuda]="financeiro.tipoTitulo"></ajuda></td>
        <td *ngIf="baixa.getEtapa() != 3 && this.baixa.parametro != 'CAIXA' && this.baixa.parametro != 'RAPIDA'" class="l-10">{{ financeiro.dataNegociacao | data: 'dd/MM/yy' }}</td>
        <td class="l-10">{{ financeiro.dataVencimento | data: 'dd/MM/yy' }} <atencao *ngIf="financeiro.diasAtrasado > 0" [atencao]="financeiro.diasAtrasado" [tipo]="baixa.bibPropriedade.atencao.alerta"></atencao></td>
        <td class="l-10 text-right">{{ financeiro.valor | monetario }}</td>
        <td *ngIf="this.baixa.parametro != 'CAIXA' && this.baixa.parametro != 'RAPIDA'" class="l-10 text-right">{{ financeiro.juroAutomatico | monetario }}</td>
        <td class="l-10 text-right">
          <ng-container *ngIf="baixa.ehTachado(financeiro) && baixa.getEtapa() == 3">
            (<span class="tachado">{{ financeiro.valorRestante | monetario }}</span
            >)
            {{ financeiro.valorRestante - financeiro.getValorAPagarTotal() | monetario }}
          </ng-container>
          <ng-container *ngIf="!baixa.ehTachado(financeiro) || baixa.getEtapa() == 2">{{ financeiro.valorRestante | monetario }}</ng-container>
        </td>
        <td *ngIf="baixa.getEtapa() == 3" class="l-10 text-right">{{ financeiro.getValorAPagarTotal() + financeiro.juroAPagar | monetario }}<atencao *ngIf="financeiro.getValorAPagarTotal() - financeiro.valorRestante > 0.04" [atencao]="'!'" [tipo]="baixa.bibPropriedade.atencao.cuidado" [ajuda]="bibDialogo.valorAPagarMaiorQueValorRestante"></atencao></td>
      </tr>
      <atencao *ngIf="(!this.baixa.apresentaNomeParceiro || financeiro.idParceiro == this.baixa.idConsumidorFinal) && this.baixa.parametro != 'RAPIDA'" [atencao]="financeiro.parceiro" [tipo]="baixa.bibPropriedade.atencao.sucesso"></atencao>
    </ng-container>
  </tbody>
  <tfoot *ngIf="baixa.getEtapa() == 3 && baixa.parametro != 'CAIXA' && baixa.parametro != 'RAPIDA'">
    <tr>
      <td [colSpan]="7"></td>
      <td class="text-right negrito">
        <p>{{ baixa.valorTotalOriginal | monetario }}</p>
      </td>
      <td class="text-right negrito">
        <p>{{ baixa.valorTotalJuroAutomatico | monetario }}</p>
      </td>
      <td class="text-right negrito">
        <p>{{ baixa.valorTotalRestante | monetario }}</p>
      </td>
    </tr>
  </tfoot>
</table>
<div *ngIf="baixa.getEtapa() == 2">
  <div class="row">
    <div class="mt-2 mb-5 col">
      <botao [compacto]="compacto" [legenda]="baixa.bibDialogo.voltarParaPesquisa" (botaoEmt)="baixa.setEtapa(1)"></botao>
      <botao [id]="'avancar'" [compacto]="compacto" [legenda]="baixa.bibDialogo.avancarPara + ' ' + (baixa.parametro == 'DESPESA' ? this.bibDialogo.dadosPagamento.toLowerCase() : this.bibDialogo.dadosRecebimento.toLowerCase())" [tipo]="baixa.bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="irBaixa()"></botao>
    </div>
    <div>
      <div class="row m-0 p-0">
        <atencao [atencao]="bibDialogo.diasAtrasados" [tipo]="baixa.bibPropriedade.atencao.alerta"></atencao>
      </div>
    </div>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="this.componente = null"></modal>
