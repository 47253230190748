import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r1198Itens]</tr>',
  templateUrl: './r1198Itens.component.html',
})
export class R1198ItensComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacaoProdutos: any[];
  public bibDialogo = bibDialogo;
}
