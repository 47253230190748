import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroArt } from 'src/app/modelo/parceiroArt';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './parceiroArtFrm.component.html',
})
export class ParceiroArtFrmComponent extends PaginaComponent {
  public componente: any;
  public estados: Estado[] = [];
  public parceiroArt: ParceiroArt = new ParceiroArt();
  public parceiro: Parceiro;
  public criterioAgronomo: Criterio[] = [new Criterio('CREA', 'S')];
  public integracaoEidaf: boolean = false;

  ngOnInit(): void {
    this.integracaoEidaf = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
    this.listarEstados();
    this.parceiroArt = this.plainToClass(ParceiroArt, this.ehAtualizacao(this.parceiroArt));
    this.parceiro = this.utilSessao.getIdentificacao('parceiro')?.conteudo;
    if (this.parceiroArt.id && !this.parceiroArt.alterado) {
      this.listarParceiroArt();
    }
  }

  listarEstados(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  listarParceiroArt(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.parceiroArt.id)), this.bibServico.parceiroArt).subscribe((res) => {
      this.parceiroArt = this.plainToClass(ParceiroArt, res[0]) as any;
    });
  }

  setIdEstado(estado: Estado) {
    this.parceiroArt.idEstado = estado.id;
    this.parceiroArt.estado = estado.nome;
  }

  persistirParceiroArt(): void {
    if (this.ehValido()) {
      this.parceiroArt.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.parceiroArt, this.parceiroArt));
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoNumero: boolean = this.ehValidoNumero();
    const ehValidoArtMesmoEstado: boolean = this.ehValidoArtMesmoEstado();
    return ehValidoObrigatorio && ehValidoNumero && ehValidoArtMesmoEstado;
  }

  ehValidoObrigatorio(): boolean {
    if (this.parceiroArt.art && this.parceiroArt.de && this.parceiroArt.ate && this.parceiroArt.idEstado) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoNumero(): boolean {
    if (Number(this.parceiroArt.de) > Number(this.parceiroArt.ate)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.numeroReceitaDeMaiorReceitaAte));
      return false;
    } else {
      if (Number(this.parceiroArt.atual) < Number(this.parceiroArt.de) || Number(this.parceiroArt.atual) >= Number(this.parceiroArt.ate)) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.numeroReceitaForaFaixa));
        return false;
      }
    }
    return true;
  }

  ehValidoArtMesmoEstado(): boolean {
    const parceiroArtMesmoEstadoEncontrado = this.parceiro.parceiroArts.find((parceiroArt) => parceiroArt.idEstado == this.parceiroArt.idEstado && this.util.coalesce(parceiroArt.id, 0) != this.util.coalesce(this.parceiroArt.id, 0));
    if (parceiroArtMesmoEstadoEncontrado) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.artMesmoEstado));
      return false;
    }
    return true;
  }

}
