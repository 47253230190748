<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(desfazimentoProdutos, $event)" (campoGrupoEmt)="agrupar(desfazimentoProdutos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-3">{{ bibDialogo.cliente }}</th>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
      <th class="r-1-5 text-right quebra-linha">{{ bibDialogo.qtdDesfazimento }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.valorUnitario }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.valorFinal }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let desfazimentoProduto of desfazimentoProdutos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ desfazimentoProduto.abreviacao }}</td>
          <td>{{ desfazimentoProduto.dataDesfazimento | data }}</td>
          <td class="text-right">{{ desfazimentoProduto.numeroMovimentacao }}</td>
          <td>{{ desfazimentoProduto.numeroParceiro + ' - ' + desfazimentoProduto.parceiro }}</td>
          <td>{{ desfazimentoProduto.numeroProduto + ' - ' + desfazimentoProduto.produto }}</td>
          <td>{{ desfazimentoProduto.unidade }}</td>
          <td class="text-right">{{ desfazimentoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ desfazimentoProduto.quantidadeDesfazimento | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ desfazimentoProduto.valorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ desfazimentoProduto.valorFinal | monetario }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="desfazimentoProdutos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
