<layout [titulo]="bibDialogo.cheques + ' - ' + conta">
  <div class="row">
    <div class="col-lg-6 sortable-tile">
      <div class="card card-fluid">
        <div class="card-header">{{ bibDialogo.disponiveis }}</div>
        <ul class="list-group list-group-flush list-group-bordered">
          <li *ngFor="let cheque of cheques | filtrarChequeSelecionado: { filtro: 'N' }; let i = index" class="list-group-item align-items-center">
            <div class="col-sm-11">
              <div class="row justify-content-between">
                <div>{{ cheque.abreviacao }} {{ cheque.valor | monetario }} {{ ' | ' + cheque.numero }}{{ cheque.dataPreDatada ? ' | ' : '' }} {{ cheque.dataPreDatada | data }}</div>
              </div>
              <div class="row">
                {{ cheque.parceiro }}
              </div>
            </div>
            <div class="col-sm-1 align-items-center">
              <div><check [campo]="cheque.selecionado" (alteracao)="setSelecionado(cheque, 'S')"></check></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-6 sortable-tile">
      <div class="card card-fluid">
        <div class="card-header">{{ bibDialogo.selecionados }}</div>
        <ul class="list-group list-group-flush list-group-bordered">
          <li *ngFor="let cheque of cheques | filtrarChequeSelecionado: { filtro: 'S' }; let i = index" class="list-group-item align-items-center">
            <div class="col-sm-11">
              <div class="row justify-content-between">
                <div>{{ cheque.abreviacao }} {{ cheque.valor | monetario }} {{ ' | ' + cheque.numero }}{{ cheque.dataPreDatada ? ' | ' : '' }} {{ cheque.dataPreDatada | data }}</div>
              </div>
              <div class="row">
                {{ cheque.parceiro }}
              </div>
            </div>
            <div class="col-sm-1 align-items-center">
              <div><check [campo]="cheque.selecionado" (alteracao)="setSelecionado(cheque, 'N')"></check></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionar()"></botao>
</layout>
<totalizador [atencaoTotalizador]="valorTotalCheques | monetario" [valorTotalizador]="valorTotalChequesSelecionados | monetario"></totalizador>
