import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'custoTotal', posicao: 7, descricao: bibDialogo.custo },
  { campo: 'valorTabelaTotal', posicao: 8, descricao: bibDialogo.valorTabela },
  { campo: 'valorTotal', posicao: 9, descricao: bibDialogo.valor },
  { campo: 'lucroValor', posicao: 10, descricao: bibDialogo.lucro },
  { campo: 'lucroValor*100/valorTotal', posicao: 11, tipo: 'expressao', descricao: bibDialogo.margem },
];
export default totais;
