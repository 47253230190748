export class DesfazimentoProduto {
  public abreviacao: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idDesfazimento: number;
  public idEmpresa: number;
  public idLocal: number;
  public idLoja: number;
  public idLote: number;
  public idMovimentacao: number;
  public idMovimentacaoQuantidade: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public movimentacaoNumero: number;
  public parceiro: string;
  public produto: string;
  public quantidade: number;
  public selecionado: string = 'N';
  public unidade: string;
}
