<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <ng-container *ngFor="let movimentacao of apresentaMovimentacoes; let index_movimentacao = index">
    <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let i = index; count as contagem">
      <table *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1">
        <tbody>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1" class="top">
            <td class="r-8-5 relatorio-titulo">
              <div class="row">
                <span class="r-3 col-sm text-left">{{ movimentacao.operacao }}</span> <span class="r-5 text-right mr-2">{{ mostraVia == true ? movimentacao.via : '' }}</span>
                <div *ngIf="movimentacao.status != 'S'" class="tarja-nao-finalizado">{{ bibDialogo.naoFinalizado }}</div>
              </div>
            </td>
            <td class="r-3-5 align-items-center">
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.numero + ': ' }} </span> <span class="r-2-5">{{ movimentacao.numero }} </span>
              </div>
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.emissao + ': ' }} </span> <span class="r-2-5">{{ movimentacao.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm:ss' }} </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.loja + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.loja }}</span>
              </div> 
              <div class="row">
                <ng-container *ngIf="movimentacao.identificacao == 1 || (movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == -1)"><span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.vendedor + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.responsavel }}</span></ng-container>
                <ng-container *ngIf="(movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == 1)"><span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.comprador + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.responsavel }}</span></ng-container>
                <ng-container *ngIf="movimentacao.identificacao == 6 || movimentacao.identificacao == 7 || movimentacao.identificacao == 5 || movimentacao.identificacao == 8"><span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.responsavel + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.responsavel }}</span></ng-container>
              </div>                           
            </td>
            <td>
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.telefone + ': ' }} </span><span class="r-2-5 limitar"> {{ movimentacao.lojaTelefone }}</span>
              </div> 
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.telefone + ': ' }} </span><span class="r-2-5 limitar"> {{ movimentacao.colaboradorTelefone }}</span>
              </div>                              
            </td>         
          </tr>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1">
            <td colspan="2">
              <div class="row">
                <span *ngIf="movimentacao.identificacao == 1 || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == -1)" class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.cliente + ': ' }} </span>
                <span *ngIf="(movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == 1)" class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.fornecedor + ': ' }} </span>
                <span *ngIf="movimentacao.identificacao == 5 || movimentacao.identificacao == 6 || movimentacao.identificacao == 7 || movimentacao.identificacao == 8" class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.colaborador + ': ' }} </span> <span class="r-10-5 limitar"> {{ movimentacao.parceiro }}</span> <span class="relatorio-rotulo" *ngIf="movimentacao.consumidorFinal == 'S' && movimentacao.movimentacaoCpfCnpj != null">{{ bibDialogo.cpf + ': ' }} </span> <span class="r-2" *ngIf="movimentacao.consumidorFinal == 'S' && movimentacao.movimentacaoCpfCnpj != null"> {{ movimentacao.movimentacaoCpfCnpj }}</span>
              </div>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.endResCom + ': ' }} </span>
                <div class="alinhar-vertical r-10-5 limitar">
                  <span class="fonte-endereco"> {{ movimentacao.parceiroEndereco }} </span>
                </div>
              </div>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.endCobranca + ': ' }} </span>
                <div class="alinhar-vertical r-10-5 limitar">
                  <span class="fonte-endereco"> {{ movimentacao.parceiroEnderecoCobranca }} </span>
                </div>
              </div>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.endEntrega + ': ' }} </span>
                <div class="alinhar-vertical r-10-5 limitar">
                  <span class="fonte-endereco"> {{ movimentacao.parceiroEnderecoEntrega }} </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.fpg + ': ' }} </span><span class="r-10-5 limitar"> {{ movimentacao.negociacao }}</span>
              </div> 
            </td>
          </tr>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1">
            <td colspan="2" class="bottom p-0 m-0">
              <div [ngClass]="ehMatricial ? 'matricial' : ''" class="tabela-produtos">
                <thead>
                  <tr>
                    <th class="r-0-5 text-right">{{ bibDialogo.numeroAbreviacao }}</th>
                    <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
                    <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
                    <th class="r">{{ bibDialogo.descricaoPodutos }}</th>
                    <th *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.valor }}<br/>{{ bibDialogo.unitario.toLowerCase() }}</th>
                    <th *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.desconto }}
                    <th *ngIf="usaValorUnitario && !usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.total }}</th>
                    <th *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.total }}</th>
                    <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.valorTabela }}</th>
                    <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.desconto }}</th>
                    <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.valor }}<br/>{{ bibDialogo.unitario.toLowerCase() }}</th>
                    <th *ngIf="usaValorUnitarioComDesconto" class="r-2 text-right">{{ bibDialogo.valorUnitario }}</th>
                    <th *ngIf="usaValorUnitarioComDesconto || usaDesconto" class="r-1 text-right">{{ bibDialogo.total }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let movimentacaoProduto of movimentacaoProdutos | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let j = index">
                    <ng-container *ngIf="j + 1 >= i + 1 + (i == 0 ? 0 : 1) && j + 1 <= i + 17 + (i == 0 ? 0 : 1)">
                      <td class="r-0-5 text-right">{{ movimentacaoProduto.produtoNumero }}</td>
                      <td class="r-1 text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
                      <td class="r-0-5 fonte-endereco limitar">{{ movimentacaoProduto.unidadeAbreviacao }}</td>
                      <td class="r">{{ movimentacaoProduto.produto.length > 60 ? movimentacaoProduto.produto.substring(0, 60)+'...' : movimentacaoProduto.produto }}</td>
                      <td *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.valorUnitario + movimentacaoProduto.descontoValorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
                      <td *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.descontoValor + (movimentacaoProduto.quantidade * movimentacaoProduto.descontoValorUnitario) + movimentacaoProduto.descontoRateado | monetario: this.empresaRegraCasasDecimais }}</td>
                      <td *ngIf="usaValorUnitario && !usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.quantidade * (movimentacaoProduto.valorUnitario + movimentacaoProduto.descontoValorUnitario) | monetario }}</td>
                      <td *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
                      <td *ngIf="usaDesconto" class="r-1 text-right">{{ movimentacaoProduto.valorTabela | monetario }}</td>  
                      <td *ngIf="usaDesconto" class="r-1 text-right">{{ (movimentacaoProduto.descontoValor / movimentacaoProduto.quantidade) + (movimentacaoProduto.descontoValorUnitario) | monetario }}</td>   
                      <td *ngIf="usaDesconto" class="r-1 text-right">{{ (movimentacaoProduto.valorTotal / movimentacaoProduto.quantidade) | monetario }}</td>
                      <td *ngIf="usaValorUnitarioComDesconto" class="r-2 text-right">{{ (movimentacaoProduto.valorTotal / movimentacaoProduto.quantidade) | monetario }}</td>  
                      <td *ngIf="usaValorUnitarioComDesconto || usaDesconto" class="r-1 text-right">{{ movimentacaoProduto.valorTotal | monetario }}</td>                           
                    </ng-container>
                  </tr>
                </tbody>
              </div>
            </td>
          </tr>
          <tr>
            <td *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1" colspan="2" class="bottom p-0">
              <span class="r-1 relatorio-rotulo text-right">{{ bibDialogo.obs + ': ' }} </span> <span class="limitar ml-1">{{ movimentacao.observacao }}</span>
            </td>
          </tr>
          <tr class="altura-fixa-vencimento mt-0" *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1">
            <td>
              <div class="col r-8-5 row">
                <div class="r-1-5">
                  <span class="text-right relatorio-rotulo">{{ bibDialogo.vencimento + '(S): ' }}</span>
                </div>
                <ng-container *ngFor="let movimentacaoParcela of movimentacaoParcelas | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let i = index">
                  <div class="r-2">
                    <span>{{ i + 1 + 'º: ' }} {{ movimentacaoParcela.dataVencimento | data }} {{ ' - ' }} {{ movimentacaoParcela.valorParcela | monetario }} </span>
                  </div>
                </ng-container>
              </div>
              <div class="col r-8-5 row">
                <span class="text-right relatorio-rotulo">{{ bibDialogo.tipoPagamento + ': ' }}</span> {{ montarFormaPagamento(movimentacao.id) }}
              </div>
              <div class="col r-8-5 row">
                <span class="text-right relatorio-rotulo">{{ movimentacao.loja + ' - ' + bibDialogo.telefone + ': ' + movimentacao.lojaTelefone }}</span>
              </div>
            </td>
            <td>
              <div *ngIf="usaValorUnitario || usaDescontoValorFinal || usaDesconto || usaValorUnitarioComDesconto" class="r-3-5 row justify-content-end relatorio-rotulo">{{ bibDialogo.totais }}</div>
              <div *ngIf="movimentacao.descontoTotal > 0 && usaValorUnitarioComDesconto == false" class="row">
                <span class="r-1-5 relatorio-rotulo text-right">{{ bibDialogo.subTotal + ': ' }} </span> <span class="r-2 text-right"> {{ movimentacao.valorTotal + movimentacao.descontoTotal | monetario }}</span>
              </div>
              <div *ngIf="movimentacao.descontoTotal > 0 && usaValorUnitarioComDesconto == false"class="row">
                <span class="r-1-5 relatorio-rotulo text-right">{{ bibDialogo.desconto + ': ' }} </span> <span *ngIf="movimentacao.descontoTotal > 0" class="r-2 text-right"> {{ movimentacao.descontoTotal | monetario }}</span>
              </div>
              <div *ngIf="mostraTotal == true" class="row">
                <span class="r-1-5 relatorio-rotulo text-right">{{ bibDialogo.total + ': ' }} </span> <span class="r-2 text-right"> {{ movimentacao.valorTotal | monetario }}</span>
              </div>
            </td>
          </tr>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 17 == 0) && i + 1 != contagem) || contagem == 1">
            <td colspan="2">
              <div class="row p-0 m-0">
                <div class="border-right border-black r-2">{{ (movimentacao.lucroPercentual | monetario) + '|' + (movimentacao.lucroValor | monetario) }}</div>
                <div class="border-right border-black r-1"></div>
                <div class="border-right border-black r-1 text-right">{{ movimentacao.numero }}</div>
                <div class="border-right border-black r-1 text-center">{{ movimentacaoProdutoTotais[0].pesoTotalBruto ? (movimentacaoProdutoTotais[0].pesoTotalBruto | monetario) + 'kg' : '' }}</div>
                <div class="border-right border-black r-6">{{ movimentacao.identificacao == 1 ? bibDialogo.orcamentoValidoAte + ' ' + (movimentacao.data | data) : bibDialogo.somenteAceitamosDevolucaoEm30Dias }}</div>
                <div class="r-1 text-right"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
</relatorioLayout>
