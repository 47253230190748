import { Component } from '@angular/core';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Financeiro } from 'src/app/modelo/financeiro';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { FinanceiroNegociacao } from 'src/app/modelo/financeiroNegociacao';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { Permissao } from 'src/app/modelo/permissao';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { Utilitario } from 'src/app/modelo/utilitario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ComissaoFaturamentoComponent } from '../../comissaoFaturamento/comissaoFaturamento.component';
import { MovimentacaoFrmComponent } from '../../movimentacao/frm/estrutura/movimentacaoFrm.component';

@Component({
  selector: 'financeiroFrm',
  templateUrl: './financeiroFrm.component.html',
})
export class FinanceiroFrmComponent extends PaginaComponent {
  public componente: any = null;
  public criterios: Criterio[] = [new Criterio('SEM_ID_ZERO', 'S'), new Criterio('ATIVO', 'S')];
  public excluirFinanceiroBaixas: number[] = [];
  public financeiro: Financeiro = new Financeiro();
  public financeiroNegociacoes: FinanceiroNegociacao[] = [];
  public financeiros: Financeiro[] = [];
  public financeiroValorMulta: number;
  public parceiroDestinos: ParceiroDestino[] = [];
  public permissaoComissaoFaturamento: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 87);
  public permissaoRenegociacao: Permissao;
  public tipo: string;
  public tipoTitulos: TipoTitulo[] = [];
  public utilitarios: Utilitario[] = [];
  public utilizaOrdemPagamento: boolean = this.utilSessao.getEmpresa().ordemPagamento != 1 ? true : false;
  public bibDialogo = this.bibDialogo;
  public bibIcone = this.bibIcone;
  public bibPropriedade = this.bibPropriedade;
  public bibServico = this.bibServico;

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarTipoTitulo();
  }

  ehAlteracao(): void {
    this.tipo = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    this.financeiro = this.plainToClass(Financeiro, this.ehAtualizacao(this.financeiro));
    this.financeiro.tipo = this.tipo?.substring(0, 1);
    if (this.financeiro.id) {
      this.listarFinanceiro(this.financeiro.id);
    }
    this.verificarPermissaoRenegociacao();
  }

  verificarPermissaoRenegociacao() {
    const RECEITA: string = 'R';
    const ID_MENU_RENEGOCIACAO_RECEITA: number = 158;
    const ID_MENU_RENEGOCIACAO_DESPESA: number = 159;
    this.permissaoRenegociacao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == (this.financeiro.tipo == RECEITA ? ID_MENU_RENEGOCIACAO_RECEITA : ID_MENU_RENEGOCIACAO_DESPESA));
  }

  listarFinanceiro(id: number): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID', id));
    criterios.push(new Criterio('TIPO', this.tipo?.substring(0, 1)));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiro).subscribe((res) => {
      this.financeiro = this.plainToClass(Financeiro, res[0]) as any;
      this.financeiroValorMulta = this.financeiro.multa;
      this.utilitarios.push(new Utilitario(this.bibIcone.arquivo, this.bibDialogo.ordemPagamento, null, this.financeiro.tipo == 'D' && this.utilizaOrdemPagamento && this.financeiro.ordemPagamento != 1));
      this.utilitarios.push(new Utilitario(this.bibIcone.arquivo, this.bibDialogo.visualizarComissao, null, this.financeiro.comissao == 'S'));
      this.listarBaixas(id);
      this.listarTipoTitulo();
      this.financeiro.popular();
    });
  }

  listarTipoTitulo(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoTitulo).subscribe((res) => {
      this.tipoTitulos = this.plainToClass(TipoTitulo, res) as any;
      this.ehTipoTituloTipoAntecipacao();
    });
  }

  ehTipoTituloTipoAntecipacao(): void {
    if (this.financeiro && this.tipoTitulos && this.financeiro.idMovimentacao) {
      this.tipoTitulos = this.tipoTitulos.filter((tipoTitulo) => tipoTitulo.idEspecie != 9);
    }
  }

  listarBaixas(idFinanceiro: number): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_FINANCEIRO', idFinanceiro));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiroBaixa).subscribe((res) => {
      this.financeiro.financeiroBaixas = this.plainToClass(FinanceiroBaixa, res) as any;
      this.listarParceiroDestinos();
      this.ehUnificarBaixas();
    });
  }

  listarParceiroDestinos(): void {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIRO', this.financeiro.idParceiro), new Criterio('ATIVO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroDestino).subscribe((res) => {
      this.parceiroDestinos = this.plainToClass(ParceiroDestino, res) as any;
    });
  }

  ehUnificarBaixas(): void {
    let financeiroBaixaAgrupados: FinanceiroBaixa[] = [];
    this.financeiro.financeiroBaixas.forEach((financeiroBaixa, i) => {
      let posicao: number = 0;
      if (i > 0) {
        if (new Date(financeiroBaixaAgrupados[posicao].dataBaixa).getTime() == new Date(financeiroBaixa.dataBaixa).getTime() && financeiroBaixaAgrupados[posicao].idConta == financeiroBaixa.idConta && financeiroBaixaAgrupados[posicao].idTipoTitulo == financeiroBaixa.idTipoTitulo && financeiroBaixaAgrupados[posicao].numeroDocumento == financeiroBaixa.numeroDocumento) {
          financeiroBaixaAgrupados[posicao].valor += financeiroBaixa.valor * (this.financeiro.tipo != financeiroBaixa.tipo ? -1 : 1);
          financeiroBaixaAgrupados[posicao].juroValor += financeiroBaixa.juroValor * (this.financeiro.tipo != financeiroBaixa.tipo ? -1 : 1);
          financeiroBaixaAgrupados[posicao].juroManual += financeiroBaixa.juroManual * (this.financeiro.tipo != financeiroBaixa.tipo ? -1 : 1);
          financeiroBaixaAgrupados[posicao].descontoValor += financeiroBaixa.descontoValor * (this.financeiro.tipo != financeiroBaixa.tipo ? -1 : 1);
          financeiroBaixaAgrupados[posicao].valorCheio += financeiroBaixa.valorCheio * (this.financeiro.tipo != financeiroBaixa.tipo ? -1 : 1);
        } else {
          posicao++;
          financeiroBaixaAgrupados.push(JSON.parse(JSON.stringify(financeiroBaixa)));
        }
      } else {
        financeiroBaixaAgrupados.push(JSON.parse(JSON.stringify(financeiroBaixa)));
      }
    });
    this.financeiro.financeiroBaixas = financeiroBaixaAgrupados;
  }

  ehPreencherValorAVista(): void {
    if (!this.financeiro.aVista) {
      if (new Date(this.financeiro.dataNegociacao.toDateString()).getTime() == new Date(new Date().toDateString()).getTime() && new Date(this.financeiro.dataVencimento.toDateString()).getTime() == new Date(new Date().toDateString()).getTime()) {
        this.financeiro.aVista = '1';
      } else {
        this.financeiro.aVista = '2';
      }
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoNegociarRepetirRenegociar: boolean = this.ehValidoNegociarRepetirRenegociar();
    const validoRenegociar: boolean = this.ehValidoRenegociar();
    const ehValidoRepetirValores: boolean = this.ehValidoRepetirValores();
    const ehValidoDesativarFinanceiroBaixado: boolean = this.ehValidoDesativarFinanceiroBaixado();
    const ehValidoVencimentoMenorNegociacao: boolean = this.ehValidoVencimentoMenorNegociacao();
    const ehValidoValorMulta: boolean = this.ehValidarValorMulta();
    return ehValidoObrigatorio && ehValidoNegociarRepetirRenegociar && validoRenegociar && ehValidoRepetirValores && ehValidoDesativarFinanceiroBaixado && ehValidoVencimentoMenorNegociacao && ehValidoValorMulta;
  }

  ehValidoObrigatorio(): boolean {
    if (this.financeiro.dataNegociacao && this.financeiro.dataVencimento && this.financeiro.parcela && this.financeiro.idLoja && this.financeiro.idParceiro && this.ehValidoValoresObrigatorio()) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoNegociarRepetirRenegociar(): boolean {
    if (this.financeiro.negociar == 'RE' || this.financeiro.negociar == 'RN') {
      if (this.financeiro.repetidaTipo && this.financeiro.repetidaQuantidade && this.financeiro.repetidaQuantidade > 0 && this.financeiro.percentualJuroRenegociacao >= 0 && this.financeiros.length > 0 && this.financeiros.filter((financeiro) => financeiro.valor < 0 || financeiro.valorRestante <= 0).length == 0) {
        return true;
      }
    }
    if (this.financeiro.negociar == 'N') {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueRepetirRenegociar));
    return false;
  }

  ehValidoRenegociar(): boolean {
    const diferenca: number = this.util.arredondar(this.financeiro.totalizaValorRestante - this.financeiro.valorRestante);
    if ((this.financeiro.negociar == 'RN' && diferenca == 0) || this.financeiro.negociar != 'RN') {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.renegociacao + ' ' + this.bibDialogo.incorreta.toLowerCase() + '. ' + this.bibDialogo.diferenca + ' de ' + this.bibDialogo.valor.toLowerCase() + ': ' + this.util.moeda(diferenca)));
    return false;
  }

  ehValidoRepetirValores(): boolean {
    if ((this.financeiro.valor + this.financeiro.multa) > 0 && this.financeiro.valorRestante >= 0 && this.financeiro.desconto >= 0 && this.financeiro.juro >= 0 && this.financeiro.multa >= 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valoresIncorretos));
    return false;
  }

  ehValidoDesativarFinanceiroBaixado(): boolean {
    if (this.financeiro.ativo != 'S' && this.financeiro.valorBaixa) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.desativarFinanceiroBaixado));
      return false;
    }
    return true;
  }

  ehValidoVencimentoMenorNegociacao(): boolean {
    if (new Date(this.financeiro.dataVencimento.toDateString()) >= new Date(this.financeiro.dataNegociacao.toDateString())) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.vencimentoMenorNegociacao));
    return false;
  }

  ehValidoValoresObrigatorio(): boolean {
    this.tratarValorCasoTenhaMulta();
    if ((this.financeiro.multa || this.financeiro.multa == 0) && (this.financeiro.desconto || this.financeiro.desconto == 0) && (this.financeiro.juro || this.financeiro.juro == 0) && (this.financeiro.valor || this.financeiro.valor == 0)) {
      return true;
    }
    return false;
  }

  tratarValorCasoTenhaMulta(): void {
    this.financeiro.valor = this.financeiro.multa > 0 && this.financeiro.valor == undefined ? 0 : this.financeiro.valor;
  }

  ehValidarValorMulta(): boolean {
    if (this.financeiro.multa < this.financeiroValorMulta) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.acrescimoNaoPermiteValorMenorQueAtual));
      return false;
    }
    return true;
  }

  salvar(novo: boolean = false): void {
    this.ehPreencherValorAVista();
    if (this.ehValido()) {
      this.definirParametrosParcela();
      this.financeiro.tipo = this.tipo;
      this.financeiro.financeiroBaixas.sort((a, b) => a.ordemSalvar - b.ordemSalvar);
      this.definirRenegociacaoAntiga();
      this.persistirFinanceiro(novo);
    }
  }

  definirParametrosParcela(): void {
    if (this.financeiro.negociar == 'RE' || this.financeiro.negociar == 'RN') {
      if (this.financeiro.repetidaTipo != 3) {
        this.financeiro.repetidaDiaFixo = 0;
      }
      if (this.financeiro.percentualJuroRenegociacao == 0) {
        this.financeiro.tipoJuroRenegociacao = 'N';
      }
    }
  }

  definirRenegociacaoAntiga(): void {
    if (this.financeiros.length == 0 || this.financeiro.negociar == 'N') {
      this.financeiros = [];
      this.financeiro.renegociacao = this.financeiro.renegociacaoAntiga ? this.financeiro.renegociacaoAntiga : this.financeiro.renegociacao;
      this.financeiro.parcela = this.financeiro.parcelaAntiga ? this.financeiro.parcelaAntiga : this.financeiro.parcela;
      this.financeiros.push(this.financeiro);
    }
  }

  verificarUtilitario(legenda: string): void {
    switch (legenda) {
      case this.bibDialogo.ordemPagamento:
        this.financeiro.ordemPagamento = 3;
        this.salvar();
        this.alertarDescricao();
        break;
      case this.bibDialogo.visualizarComissao:
        this.utilSessao.setIdentificacao(new Identificacao('idFinanceiro', this.financeiro.id));
        this.utilSessao.setIdentificacao(new Identificacao('apresentaDadosComissao', false));
        this.utilSessao.setPermissaoEspecifica(this.permissaoComissaoFaturamento);
        this.componente = ComissaoFaturamentoComponent;
        break;
    }
  }

  alertarDescricao(): void {
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.ordemPagamentoGerada.toUpperCase()));
  }

  persistirFinanceiro(novo: boolean = false): void {
    this.financeiros[0].tipo = this.tipo.substring(0, 1);
    let transporte: Transporte = new Transporte(this.financeiros[0]);
    let financeiroNegociacoes = this.financeiro.negociar != 'N' && this.financeiros.length > 1 ? this.financeiros.slice(1, this.financeiro.repetidaQuantidade) : [];
    transporte.adicionar(financeiroNegociacoes);
    if (this.financeiro.id) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.financeiro.id)), this.bibServico.financeiro).subscribe((res) => {
        const financeiro: Financeiro = this.plainToClass(Financeiro, res[0]) as any;
        this.atualizarFinaneiro(financeiro);
        super.persistir(transporte, this.bibServico.financeiro, novo ? new Financeiro() : null).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.atualizarValores(this.financeiro.id);
            this.financeiro.negociar = 'N';
            this.financeiro.repetidaTipo = null;
            this.financeiro.repetidaQuantidade = null;
            this.financeiro.percentualJuroRenegociacao = 0;
            this.financeiro.tipoJuroRenegociacao = null;
            this.financeiro.repetidaDiaFixo = 0;
            this.financeiro.tipoJuroRenegociacao = 'N';
            this.financeiros = [];
          }
        });
      });
    } else {
      super.persistir(transporte, this.bibServico.financeiro, novo ? new Financeiro() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.financeiros = [];
          this.atualizarValores(this.financeiro.id);
        }
      });
    }
  }

  atualizarValores(id: number): void {
    if (id != null && id != 0) {
      this.utilitarios = [];
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.financeiro).subscribe((res) => {
        const financeiro: Financeiro = this.plainToClass(Financeiro, res[0]) as any;
        this.atualizarFinaneiro(financeiro);
        this.financeiro.negociar = 'N';
        this.financeiro.valor = financeiro.valor;
        this.financeiro.multa = financeiro.multa;
      });
    }
  }

  atualizarFinaneiro(financeiro: Financeiro) {
    this.financeiro.financeiroBaixas = financeiro.financeiroBaixas;
    this.financeiro.desconto = financeiro.desconto;
    this.financeiro.juro = financeiro.juro;
    this.financeiro.juroAutomatico = financeiro.juroAutomatico;
    this.financeiro.valorFinal = financeiro.valorFinal;
    this.financeiro.valorBaixa = financeiro.valorBaixa;
    this.financeiro.valorRestante = financeiro.valorRestante;
    this.financeiro.valorAnterior = financeiro.valorAnterior;
    this.utilitarios.push(new Utilitario(this.bibIcone.arquivo, this.bibDialogo.ordemPagamento, null, this.financeiro.tipo == 'D' && this.utilizaOrdemPagamento && this.financeiro.ordemPagamento != 1));
    this.financeiro.ehBaixado();
    this.utilSessao.setAlteracao(false);
  }

  setValor(valor: number, tipo: string): void {
    this.financeiro[tipo] = valor;
    this.totalizar();
  }

  setTipoTitulo(financeiro: Financeiro, tipoTitulo: TipoTitulo): void {
    financeiro.idTipoTitulo = tipoTitulo.id;
    financeiro.tipoTituloIdEspecie = tipoTitulo.idEspecie;
  }

  setidConta(financeiro: Financeiro, conta: Conta): void {
    financeiro.idConta = conta.id;
    financeiro.conta = conta.nome;
  }

  setFinanceiroParceiro(parceiro: Parceiro): void {
    this.financeiro.idParceiro = parceiro.id;
    this.financeiro.nomeParceiro = parceiro.numeroNomeParceiro;
  }

  totalizar(): void {
    this.financeiro.totalizar();
    if (this.financeiros.length > 0) {
      this.financeiro.recalcularNegociacao(this.financeiros);
    }
  }

  tratarBloqueioAtivo(): boolean {
    if (this.financeiro.valorBaixa > 0) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.utilSessao.excluirIdentificacao('apresentaDadosComissao');
  }

  abrirModalMovimentacao(id: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }
}
