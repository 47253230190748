import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoPisCofins } from 'src/app/modelo/produtoPisCofins';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './pisCofinsFrm.component.html',
})
export class PisCofinsFrmComponent extends PaginaComponent {
  private classe: string;
  public pisCofins: ProdutoPisCofins;
  public tipo: number;
  public produtoPisCofins: ProdutoPisCofins = new ProdutoPisCofins();
  public cstsPisCofinsEntrada: Cst[] = [];
  public cstsPisCofinsSaida: Cst[] = [];
  public listaEnquadramento: any = [
    { id: '2', nome: this.bibDialogo.lucroPresumido.toUpperCase() },
    { id: '3', nome: this.bibDialogo.lucroReal.toUpperCase() },
    { id: '1', nome: this.bibDialogo.simplesNacional.toUpperCase() },
    { id: '4', nome: this.bibDialogo.simplesNacionalExcesso.toUpperCase() },
  ];

  ngOnInit(): void {
    this.listarCstPisCofins();
    this.tipo = this.utilSessao.getIdentificacao('tipo')?.conteudo;
    this.classe = this.utilSessao.getIdentificacao('classe')?.conteudo;
    this.getPisCofins();
  }

  setEnquadramento(enquadramento: number): void {
    this.pisCofins.enquadramento = enquadramento;
  }

  setCstPisEntrada(pisCofins: ProdutoPisCofins, cst: Cst) {
    pisCofins.idCstPisEntrada = cst.id;
    pisCofins.cstPisEntrada = cst.nome;
  }

  setCstPisSaida(pisCofins: ProdutoPisCofins, cst: Cst) {
    pisCofins.idCstPisSaida = cst.id;
    pisCofins.cstPisSaida = cst.nome;
  }

  setCstCofinsEntrada(pisCofins: ProdutoPisCofins, cst: Cst) {
    pisCofins.idCstCofinsEntrada = cst.id;
    pisCofins.cstCofinsEntrada = cst.nome;
  }

  setCstCofinsSaida(pisCofins: ProdutoPisCofins, cst: Cst) {
    pisCofins.idCstCofinsSaida = cst.id;
    pisCofins.cstCofinsSaida = cst.nome;
  }

  getPisCofins(): void {
    this.pisCofins = this.plainToClass(ProdutoPisCofins, this.utilSessao.getIdentificacao('pisCofins')?.conteudo);
    if (this.pisCofins == null) {
      this.pisCofins = new ProdutoPisCofins();
    }
  }

  ehValidoObrigatorio(): boolean {
    let contagemCamposObrigatorios: number = 0;
    if (this.pisCofins.idCstPisEntrada ? this.pisCofins.aliquotaPisEntrada == null : true) {
      contagemCamposObrigatorios++;
    }
    if (this.pisCofins.idCstPisSaida ? this.pisCofins.aliquotaPisSaida == null : true) {
      contagemCamposObrigatorios++;
    }
    if (this.pisCofins.idCstCofinsEntrada ? this.pisCofins.aliquotaCofinsEntrada == null : true) {
      contagemCamposObrigatorios++;
    }
    if (this.pisCofins.idCstCofinsSaida ? this.pisCofins.aliquotaCofinsSaida == null : true) {
      contagemCamposObrigatorios++;
    }
    if (contagemCamposObrigatorios == 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  adicionarPisCofins(): void {
    if (this.ehValidoObrigatorio()) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.classe, this.pisCofins));
    }
  }

  listarCstPisCofins(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstPisCofins).subscribe((res) => {
      const cstsPisCofins: Cst[] = this.plainToClass(Cst, res) as any;
      this.cstsPisCofinsEntrada = cstsPisCofins.filter((cstPisCofins) => cstPisCofins.entradaSaida == 'E');
      this.cstsPisCofinsSaida = cstsPisCofins.filter((cstPisCofins) => cstPisCofins.entradaSaida == 'S');
    });
  }
}
