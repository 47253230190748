import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'diaAtraso', posicao: 5, tipo: 'valorMaximo', casasDecimais: 0 },
  { campo: 'valorOriginal', posicao: 9 },
  { campo: 'desconto', posicao: 10 },
  { campo: 'juro', posicao: 11 },
  { campo: 'valorRecebimento', posicao: 12 },
];
export default totais;
