<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ parametro == 'P' || parametro == 'DMP' ? bibDialogo.nome : menuGrupo.apelido }}</th>
        <th *ngIf="parametro == 'P'" class="l-25 clique" (click)="listar(add('grupo'), this.utilSessao.posicao, this.paginacao)">{{ menuGrupo.apelido }}</th>
        <th *ngIf="parametro == 'DMP' || parametro == 'DMG'" class="l-10">{{ bibDialogo.descontoMaximo }}</th>
        <th *ngIf="parametro == 'P' || parametro == 'G'" class="l-15 clique" (click)="listar(add('comissaoPercentual'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.comissao }}</th>
        <th *ngIf="utilSessao.sistema == 'aGrow-w' && (parametro == 'P' || parametro == 'G')" class="l-15 clique" (click)="listar(add('comissaoPercentualVd'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.comissao + ' VD' }}<ajuda class="pl-1" [ajuda]="bibDialogo.ajudaVendaDireta"></ajuda></th>
        <th *ngIf="parametro == 'P' || parametro == 'G'" class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let objeto of objetos; let i = index">
        <tr [ngClass]="{ tachado: objeto.ativo != 'S' }">
          <td class="limitar">{{ parametro == 'P' ? objeto.numero + ' - ' : '' }} {{ objeto.nome }}</td>
          <td *ngIf="parametro == 'P'" class="limitar">{{ objeto.grupo }}</td>
          <td *ngIf="parametro == 'DMP' || parametro == 'DMG'">
            <decimal [id]="'descontoMaximo' + i" [campo]="objeto.descontoMaximo" [sufixo]="'%'" (alteracao)="setDescontoMaximo(objeto, $event)" [foco]="i == 0" [valorMaximo]="100"></decimal>
          </td>
          <td *ngIf="parametro == 'P' || parametro == 'G'">
            <decimal [id]="'comissao' + i" [desabilitado]="regraComisssao == 1" [campo]="objeto.comissaoPercentual" [sufixo]="'%'" (alteracao)="setComissaoPercentual(objeto, $event)" [foco]="i == 0" [valorMaximo]="100"></decimal>
          </td>
          <td *ngIf="utilSessao.sistema == 'aGrow-w' && (parametro == 'P' || parametro == 'G')">
            <decimal [id]="'comissao' + i" [desabilitado]="regraComisssao == 1" [campo]="objeto.comissaoPercentualVd" [sufixo]="'%'" (alteracao)="setComissaoPercentualVd(objeto, $event)" [foco]="i == 0" [valorMaximo]="100"></decimal>
          </td>          
          <td *ngIf="parametro == 'P' || parametro == 'G'">
            <btnAdicional [id]="'usuario' + i" [icone]="bibIcone.usuario" (btnAdicional)="abrirModal(objeto, i)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
<div class="row m-0 justify-content-between">
  <botao *ngIf="objetos.length > 0" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirParceiroComissao()" [desabilitado]="!temPermissaoEdicao"></botao>
  <ng-container *ngIf="parametro == 'P' || parametro == 'G'">
    <atencao *ngIf="regraComisssao != 1" [atencao]="bibDialogo.regraComissao + ': ' + empresaRegraComissao"></atencao>
    <atencao *ngIf="regraComisssao == 1" [atencao]="bibDialogo.empresaNaoControlaComissao" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </ng-container>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="fecharModal()"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
