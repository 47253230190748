<td class="p-0" colspan="8">
  <table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r-1">{{ bibDialogo.numero }}</th>
        <th class="r">{{ bibDialogo.cliente }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorTotal }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.comissao }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorLiberado }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorNaoLiberado }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorPago }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorAguardando }}</th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorTotalAPagar }}</th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let comissao of parceiro.comissoes">
          <td><a href="#" (click)="abrirModalMovimentacao(comissao.idMovimentacao)">{{ comissao.numero }}</a></td>
          <td>{{ comissao.cliente }}</td>
          <td class="text-right">{{ comissao.valorTotal | monetario }}</td>
          <td class="text-right">{{ comissao.valor | monetario }}</td>
          <td class="text-right">{{ comissao.valorLiberado | monetario }}</td>
          <td class="text-right">{{ comissao.valorNaoLiberado | monetario }}</td>
          <td class="text-right">
            <icone *ngIf="comissao.valorPago > comissao.valorLiberado" [icone]="bibIcone.circulo" [cor]="bibPropriedade.icone.vermelho" [mensagem]="bibDialogo.valorPagoMaiorValorLiberado"></icone>
            {{ comissao.valorPago | monetario }}
          </td>
          <td class="text-right">{{ comissao.valorAguardando | monetario }}</td>
          <td class="text-right">{{ comissao.valorTotalPagar | monetario }}</td>
        </tr>
      </tbody>
  </table>
</td>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
