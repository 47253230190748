<layout [titulo]="bibDialogo.integracoes" [tag]="'int'">
  <registro [numeroRegistro]="totalIntegracoes.length"></registro>
  <div class="row form-group naoImprimir">
    <lista [campo]="campoPesquisa" [lista]="lista" (alteracao)="campoPesquisa = $event.id" [obrigatorio]="true"> </lista>
    <texto class="col-sm" [foco]="true" [campo]="filtro" (alteracao)="setFiltro($event); indice = 0" (navegacao)="focado = $event"></texto>
  </div>
  <div class="list-group list-group-flush list-group-divider border-top" data-toggle="radiolist">
    <ng-container *ngFor="let integracao of totalIntegracoes | filtrarIntegracao: { filtro: filtro, especifico: campoPesquisa }; let i = index">
      <div class="col-sm-12" *ngIf="i == 0 ? true : totalIntegracoes[i].integracaoCategoria != totalIntegracoes[i - 1].integracaoCategoria">
        <titulo [titulo]="integracao.integracaoCategoria"></titulo>
      </div>
      <div class="list-group-item" [ngClass]="indice == i ? 'active' : ''" (click)="indice = i" data-toggle="sidebar" data-sidebar="show" (click)="irIntegracao()">
        <a href="#" class="stretched-link"></a>
        <div class="list-group-item-figure">
          <div class="tile tile-circle" [ngClass]="'bg-' + integracao.tipoIntegracaoCategoria">{{ integracao.idIntegracao }}</div>
        </div>
        <div class="list-group-item-body">
          <h4 class="list-group-item-title">{{ integracao.integracao }}</h4>
        </div>
      </div>
    </ng-container>
  </div>
</layout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
