<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-30 clique" (click)="listar(add('colaborador'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.colaborador }}</th>
        <th class="l-15 text-right clique" (click)="listar(add('movimentacaoNumero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroMovimentacaoAbreviacao }}</th>
        <th class="l-35 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-15 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comissaoFinanceiro of comissaoFinanceiros; let i = index">
        <tr>
          <td>
            <check [campo]="comissaoFinanceiro.selecionado" [foco]="i == 0" (alteracao)="setSelecionado(comissaoFinanceiro, $event)"></check>
          </td>
          <td class="limitar">{{ comissaoFinanceiro.colaborador }}</td>
          <td class="text-right">{{ comissaoFinanceiro.movimentacaoNumero }}</td>
          <td class="limitar">{{ comissaoFinanceiro.parceiro }}</td>
          <td class="text-right">{{ comissaoFinanceiro.valor | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <totalizador [valorTotalizador]="valorTotalEstornar | monetario" [tipoTotalizador]="bibPropriedade.cor.vermelho"></totalizador>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.estornar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="estornar()" [desabilitado]="!temPermissaoEdicao"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
