import { Pipe, PipeTransform } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Pipe({
  name: 'filtrarRelatorio',
})
export class RelatorioListaPipe implements PipeTransform {
  transform(relatorios: any[], argumentos?: any): any {
    if (argumentos.filtro && argumentos.filtro != '' && argumentos.especifico != bibDialogo.pesquisarPor) {
      argumentos.filtro = argumentos.filtro.toLowerCase();
      switch (argumentos.especifico) {
        case bibDialogo.modulo:
          return relatorios.filter((relatorio) => relatorio.relatorioModulo != null && relatorio.relatorioModulo.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.nome:
          return relatorios.filter((relatorio) => relatorio.relatorio.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.numero:
          return relatorios.filter((relatorio) => relatorio.idRelatorio.toString().includes(argumentos.filtro));
        default:
          return relatorios.filter((relatorio) => relatorio.menu.toLowerCase().includes(argumentos.filtro) || relatorio.abreviacao.toLowerCase().includes(argumentos.filtro));
      }
    } else {
      return relatorios;
    }
  }
}
