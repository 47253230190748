<div class="row">
    <titulo class="col-sm-5 pl-3" [titulo]="bibDialogo.documentoFiscal" [imagem]="bibImagem.nfe" [icone]="bibIcone.mais" (iconeEmt)="ehAbrirModalDocumentoFiscal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.documentoFiscal.toLocaleLowerCase()" [obrigatorio]="romaneioEntrega.romaneioEntregaDocumentoFiscais && romaneioEntrega.romaneioEntregaDocumentoFiscais.length > 0 ? false : true" [desabilitado]="bloqueado"></titulo>
    <check *ngIf="!bloqueado" class="pt-2 pl-2" [campo]="romaneioEntrega.buscarTodosDocumentosFiscais" (alteracao)="romaneioEntrega.buscarTodosDocumentosFiscais = romaneioEntrega.buscarTodosDocumentosFiscais == 'S' ? 'N' : 'S'"></check>
    <rotulo *ngIf="!bloqueado" class="pt-2 pl-2" [id]="'permitirDocumentoFiscaisJaUtilizados'" [rotulo]="bibDialogo.permitirDocumentoFiscaisJaUtilizados"></rotulo>  
</div>
<div *ngIf="romaneioEntrega.romaneioEntregaDocumentoFiscais && romaneioEntrega.romaneioEntregaDocumentoFiscais.length == 0 && !bloqueado" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.documentoFiscal.toLowerCase() + ', ' }} <strong class="clique" (click)="ehAbrirModalDocumentoFiscal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
 <table *ngIf="romaneioEntrega.romaneioEntregaDocumentoFiscais && romaneioEntrega.romaneioEntregaDocumentoFiscais.length > 0" class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l-10 text-right">{{ bibDialogo.numero }}</th>
      <th class="l-10 text-right">{{ bibDialogo.data }}</th>
      <th class="l-40">{{ bibDialogo.parceiro }}</th> 
      <th class="l-10">{{ bibDialogo.responsavel }}</th>
      <th class="l-10 text-right">{{ bibDialogo.valor }}</th>
      <th class="l-10 text-right">{{ bibDialogo.pesoBruto }}</th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let romaneioEntregaDocumentoFiscal of romaneioEntrega.romaneioEntregaDocumentoFiscais; let i = index">
      <tr>
        <td class="text-right">
          <a href="#" (click)="abrirModalMovimentacao(romaneioEntregaDocumentoFiscal.idMovimentacao)"> {{ romaneioEntregaDocumentoFiscal.numeroMovimentacao }} </a>
        </td>    
        <td class="text-right"> {{ romaneioEntregaDocumentoFiscal.dataMovimentacao | data }} </td>         
        <td> {{ romaneioEntregaDocumentoFiscal.parceiroNomeFantasia }} </td>       
        <td> {{ romaneioEntregaDocumentoFiscal.colaborador }} </td>  
        <td class="text-right"> {{ romaneioEntregaDocumentoFiscal.valorTotalMovimentacao | monetario }} </td>              
        <td class="text-right"> {{ romaneioEntregaDocumentoFiscal.pesoBrutoMovimentacao | monetario }} </td> 
        <td>
          <btnAdicional *ngIf="!bloqueado" [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirDocumentoFiscal(i, romaneioEntregaDocumentoFiscal.id)"></btnAdicional>   
        </td>
      </tr>
    </ng-container>
  </tbody>
</table> 
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>