import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.estoqueDisponivelDiferenteNFe, id: 'SUM(EST.QUANTIDADE_DISPONIVEL) <> SUM(EST.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelIgualNfe, id: 'SUM(EST.QUANTIDADE_DISPONIVEL) = SUM(EST.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelMenorNfe, id: 'SUM(EST.QUANTIDADE_DISPONIVEL) < SUM(EST.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelMaiorNfe, id: 'SUM(EST.QUANTIDADE_DISPONIVEL) > SUM(EST.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelMenorPendente, id: 'SUM(EST.QUANTIDADE_DISPONIVEL) < SUM(EST.QUANTIDADE_RESERVADA)' },
  { nome: bibDialogo.estoqueDisponivelMenorMinimo, id: 'COALESCE((SELECT SUM(ESTDIS.QUANTIDADE_DISPONIVEL) FROM SGC_ESTOQUE ESTDIS WHERE ESTDIS.ID_PRODUTO = EST.ID_PRODUTO), 0) <= COALESCE((SELECT SUM(PROESTTOT.QUANTIDADE_MINIMA) FROM SGC_PRODUTO_ESTOQUE_MIN_MAX PROESTTOT WHERE PROESTTOT.ID_PRODUTO = EST.ID_PRODUTO), 0) and COALESCE((SELECT SUM(PROESTTOT.QUANTIDADE_MINIMA) FROM SGC_PRODUTO_ESTOQUE_MIN_MAX PROESTTOT WHERE PROESTTOT.ID_PRODUTO = EST.ID_PRODUTO), 0) > 0' },
  { nome: bibDialogo.estoqueDisponivelZerado, id: 'SUM(EST.QUANTIDADE_DISPONIVEL) = 0' },
]);

const filtroComboQuantidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.disponivel, id: 'SUM(EST.QUANTIDADE_DISPONIVEL)' },
  { nome: bibDialogo.nfe, id: 'SUM(EST.QUANTIDADE_NFE)' },
  { nome: bibDialogo.pendente, id: 'SUM(EST.QUANTIDADE_RESERVADA)' },
]);

const valores: string[] = ['SUM(EST.QUANTIDADE_DISPONIVEL)', 'SUM(EST.QUANTIDADE_NFE)', 'SUM(EST.QUANTIDADE_RESERVADA)'];

export class Filtros {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia, nome: 'REFERENCIA', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { rotulo: bibDialogo.produtoDescricao, nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto, nome: 'EXCETO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
    { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
    { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.lote, nome: 'ID_LOTE', coluna: 6, servicoWeb: bibServico.lote, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.local, nome: 'ID_LOCAL', coluna: 6, servicoWeb: bibServico.local, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, obrigatorio: 'S', tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.situacao, nome: 'DIFERENCA', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboSituacao },
    { rotulo: bibDialogo.quantidade, nome: 'QTD', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboQuantidade },
    { rotulo: bibDialogo.maiorQue, nome: 'QUANTIDADE_MAIOR', coluna: 6, tipo: bibPropriedade.filtro.numero, vinculaBloqueado: 'QTD', bloqueioInicial: true, bloqueados: valores },
    { rotulo: bibDialogo.menorQue, nome: 'QUANTIDADE_MENOR', coluna: 6, tipo: bibPropriedade.filtro.numero, vinculaBloqueado: 'QTD', bloqueioInicial: true, bloqueados: valores },
  ]);
}