import { GrupoUsuarioMenu } from './grupoUsuarioMenu';
import { RelatorioGrupoUsuario } from './relatorioGrupoUsuario';

export class GrupoUsuario {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public grupoUsuarioMenus: GrupoUsuarioMenu[] = [];
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public relatorioGrupoUsuarios: RelatorioGrupoUsuario[] = [];
  public tipo: string = 'N';
}
