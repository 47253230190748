import { Component } from '@angular/core';
import { ClienteEspecial } from 'src/app/modelo/clienteEspecial';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './clienteEspecial.component.html',
})
export class ClienteEspecialComponent extends PaginaComponent {
  public clienteEspeciais: ClienteEspecial[] = [];
  public clientes: Parceiro[] = [];
  public filtros: Filtro[] = filtros;

  ngOnInit(): void {
    this.listarConfiguracaoClienteEspecial();
  }

  listarConfiguracaoClienteEspecial(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.clienteEspecial).subscribe((res) => {
      this.clienteEspeciais = this.plainToClass(ClienteEspecial, res) as any;
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('CLIENTE', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.parceiro).subscribe((res) => {
      this.clientes = this.plainToClass(Parceiro, res) as any;
      this.clientes.sort((a, b) => {
        return a.razaoSocial === b.razaoSocial ? 0 : a.razaoSocial > b.razaoSocial ? 1 : -1;
      });
    });
  }

  setClienteEspecial(cliente: Parceiro, clienteEspecial: ClienteEspecial) {
    cliente.idClienteEspecial = clienteEspecial.id;
    cliente.alterado = true;
  }

  persistirCliente(): void {
    let clientes: Parceiro[] = this.clientes.filter((cliente) => cliente.alterado == true);
    const transporte: Transporte = new Transporte(clientes);
    if (this.ehValido(clientes)) {
      this.persistir(transporte, this.bibServico.parceiro, null);
    }
  }

  ehValido(clientes: Parceiro[]): boolean {
    if (clientes.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumRegistroAlterado));
    return false;
  }
}
