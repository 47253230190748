<relatorioLayout
  [relatorio]="relatorio"
  [relatorioResultados]="this.relatorioResultados"
  [excel]="excel"
  [empresa]="empresa"
  [grupos]="grupos"
  [filtros]="filtros"
  [relatorioAdicionais]="relatorioAdicionais"
  (filtroEmt)="listarRelatorio($event)"
  (campoGrupoEmt)="agrupar(resultadosCMV, $event)"
  (agrupaTotalizadorEmt)="agruparTotalizador()"
>
  <table>
    <thead>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-1 text-right">{{ bibDialogo.unidade }}</th>
      <th class="r-1 text-right">{{ bibDialogo.inicial }}</th>
      <th class="r-1 text-right">{{ bibDialogo.entrada }}</th>
      <th class="r-1 text-right">{{ bibDialogo.saida }}</th>
      <th class="r-1 text-right">{{ bibDialogo.final }}</th>
      <th class="r-1 text-right">{{ bibDialogo.cmv }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let resultadoCMV of resultadosCMV; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ resultadoCMV.numero + ' - ' + resultadoCMV.produto }}</td>
          <td>{{ resultadoCMV.unidade }}</td>
          <td class="text-right">{{ resultadoCMV.valorInicial | monetario }}</td>
          <td class="text-right">{{ resultadoCMV.valorEntrada | monetario }}</td>
          <td class="text-right">{{ resultadoCMV.valorSaida | monetario }}</td>
          <td class="text-right">{{ resultadoCMV.valorFinal | monetario }}</td>
          <td class="text-right">{{ resultadoCMV.valorCMV | monetario }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="resultadosCMV?.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
     </tfoot>    

  </table>
  <table *ngIf="resultadosCMV?.length > 0" class="mt-4  text-right">
    <tbody>
      <tr>
        <td class="r-2 negrito">{{ bibDialogo.custoReposicao }}</td>
        <td class="r-1">{{ valorCustoVenda | monetario }}</td>
        <td class="r"></td>
      </tr>
      <tr>
        <td class="r-2 negrito">{{ bibDialogo.valor + ' ' + bibDialogo.venda }}</td>
        <td class="r-1">{{ valorVenda | monetario }}</td>
        <td class="r"></td>
      </tr>
      <tr>
        <td class="r-2 negrito">{{ bibDialogo.valor + ' ' + bibDialogo.lucro }}</td>
        <td class="r-1">{{ (valorVenda - valorCustoVenda) | monetario }}</td>
        <td class="r"></td>
      </tr>
      <tr>
        <td class="r-2 negrito">{{ bibDialogo.margem  }}</td>
        <td class="r-1">{{ ((((valorVenda - valorCustoVenda)*100) / valorVenda) | monetario ) + bibDialogo.percentualSimbolo}}</td>
        <td class="r"></td>
      </tr>      
    </tbody>
  </table>

</relatorioLayout>


