<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-75 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let conta of contas; let i = index">
        <tr [ngClass]="{ tachado: conta.ativo != 'S' }">
          <td>
            <icone [icone]="bibIcone.circulo" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="conta.cedente == null ? bibPropriedade.icone.preto : conta.homologacao == 'S' ? bibPropriedade.icone.vermelho : conta.quantidadeBoletoRecebido > 0 ? bibPropriedade.icone.verde : bibPropriedade.icone.amarelo" [mensagem]="conta.cedente == null ? bibDialogo.naoUsaBoleto : conta.homologacao == 'S' ? bibDialogo.boletoHomologacao : conta.quantidadeBoletoRecebido > 0 ? util.substituir(bibDialogo.producaoBoletoRecebido, [conta.quantidadeBoletoRecebido]) : bibDialogo.producaoNenhumBoletoRecebido" [ajudaDireita]="true"></icone>
          </td>          
          <td>{{ conta.abreviacao }}</td>
          <td class="text-right">{{ conta.numero }}</td>
          <td>
            <a href="#" (click)="ir(conta.id, i, contas)">{{ conta.nome }}</a>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(conta.id, i, contas)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirConta(conta.id, conta.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
