export class EnderEmit {
  public xLgr_31: string;
  public nro_32: string;
  public xCpl_33: string;
  public xBairro_34: string;
  public cMun_35: string;
  public xMun_36: string;
  public CEP_37: string;
  public UF_38: string;
  public fone_39: string;
  public email_40: string;
}
