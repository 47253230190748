import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { GrupoParceiroComissao } from 'src/app/modelo/grupoParceiroComissao';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ProdutoParceiroComissao } from 'src/app/modelo/produtoParceiroComissao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './produtoParceiroComissao.component.html',
})
export class ProdutoParceiroComissaoComponent extends PaginaComponent {
  private classe: any;
  private criterio: string;
  private servico: string;
  public filtros: Filtro[] = filtros;
  public objeto: any;
  public parametro: string;
  public parceiroComissoes: any[];
  public regraComisssao: number;

  ngOnInit(): void {
    this.regraComisssao = this.utilSessao.getEmpresa().regraComissao;
    this.objeto = this.utilSessao.getIdentificacao('objeto')?.conteudo;
    this.parametro = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    if (this.parametro == 'P') {
      this.servico = this.bibServico.produtoParceiroComissao;
      this.classe = ProdutoParceiroComissao;
      this.criterio = 'ID_PRODUTO';
    } else {
      this.servico = this.bibServico.grupoParceiroComissao;
      this.classe = GrupoParceiroComissao;
      this.criterio = 'ID_GRUPO';
    }
    this.listarParceiroComissao();
  }

  listarParceiroComissao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio(this.criterio, this.objeto.id)), this.servico).subscribe((res) => {
      this.parceiroComissoes = this.plainToClass(this.classe, res) as any;
      this.listarColaborador();
    });
  }

  listarColaborador(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('COLABORADOR', 'S')), this.bibServico.parceiro).subscribe((res) => {
      const parceiros: Parceiro[] = this.plainToClass(Parceiro, res) as any;
      this.completarParceirosComissao(parceiros);
    });
  }

  completarParceirosComissao(parceiros: Parceiro[]): void {
    for (const parceiro of parceiros) {
      let parceiroComissao = this.parceiroComissoes.find((parceiroComissao) => parceiroComissao.idColaborador == parceiro.id);
      if (!parceiroComissao) {
        let parceiroComissao: any = {};
        parceiroComissao.colaborador = parceiro.razaoSocial;
        parceiroComissao.percentual = 0;
        parceiroComissao.percentualVd = 0;
        parceiroComissao.idColaborador = parceiro.id;
        parceiroComissao.comissionado = parceiro.comissionado;
        this.parametro == 'P' ? (parceiroComissao.idProduto = this.objeto.id) : (parceiroComissao.idGrupo = this.objeto.id);
        this.parceiroComissoes.push(parceiroComissao);
      } else {
        parceiroComissao.comissionado = parceiro.comissionado;
      }
    }
    this.parceiroComissoes.sort((a, b) => {
      return a.colaborador.trim() > b.colaborador.trim() ? 1 : b.colaborador.trim() > a.colaborador.trim() ? -1 : 0;
    });
  }

  adicionar(): void {
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.parametro == 'P' ? this.bibClasse.produtoParceiroComissao : this.bibClasse.grupoParceiroComissao, this.parceiroComissoes));
  }
}
