import { Component, Input } from '@angular/core';
import { GrupoIcmsSt } from 'src/app/modelo/grupoIcmsSt';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ProdutoIcmsSt } from 'src/app/modelo/produtoIcmsSt';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { IcmsStFrmComponent } from './icmsStFrm.component';

@Component({
  selector: 'icmsSt',
  templateUrl: './icmsSt.component.html',
})
export class IcmsStComponent extends PaginaComponent {
  @Input() classe: string;
  @Input() icmsSts: any;
  @Input() idsExcluir: number[];
  public componente: any;

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.produtoIcmsSt) {
        if (this.classe == this.bibClasse.produtoIcmsSt) {
          this.adicionarTemporario(this.plainToClass(ProdutoIcmsSt, identificacao.conteudo), this.icmsSts);
        } else {
          this.adicionarTemporario(this.plainToClass(GrupoIcmsSt, identificacao.conteudo), this.icmsSts);
        }
        this.icmsSts.sort((a, b) => (a['estado'] == b['estado'] ? 0 : a['estado'] > b['estado'] ? 1 : -1));
      }
    });
  }

  abrirModal(icmsSt: any = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('classe', this.bibClasse.produtoIcmsSt));
    this.utilSessao.setIdentificacao(new Identificacao('icmsSt', icmsSt));
    this.componente = IcmsStFrmComponent;
  }
}
