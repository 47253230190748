export class Ide {
  public cUF_5: number;
  public tpAmb_6: number;
  public tpEmit_7: number;
  //public tpTransp_20: number;
  public mod_8: number = 58;
  public serie_9: number;
  public nMDF_10: number;
  public cMDF_11: number;
  public cDV_12: string;
  public modal_13: number = 1;
  public dhEmi_14: string;
  public tpEmis_15: string;
  public procEmi_16: number = 0;
  public verProc_17: string = '1.0.0.0';
  public UFIni_18: string;
  public UFFim_19: string;
}
