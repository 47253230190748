import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  // { campo: 'valorParcela', posicao: 5 },
  { campo: 'valor', posicao: 3 },
  // { campo: 'valorPago', posicao: 7 },
  // { campo: 'valorDisponivel', posicao: 8 },
  // { campo: 'valorRestante', posicao: 9 },
];
export default totais;
