<layout [titulo]="bibDialogo.pesquisa + ' ' + nomePesquisa.toLowerCase()">
  <header class="card-header modal-etapas">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a class="nav-link active show" data-toggle="tab" id="pesquisa" (click)="setAbaSelecionada(false)" href="#pesquisa">{{ bibDialogo.pesquisa }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" id="abaSelecionado" (click)="setAbaSelecionada(true)" href="#selecionados">
          {{ bibDialogo.selecionados }}
          <span *ngIf="selecionados.length > 0" class="badge badge-primary">{{ selecionados.length }}</span>
        </a>
      </li>
    </ul>
  </header>
  <div class="card-body">
    <div id="myTabContent" class="tab-content">
      <div class="tab-pane fade active show" id="pesquisa">
        <div class="row m0 form-group" [ngClass]="compacto ? 'tamanho-minimo-compacto' : 'tamanho-minimo'">
          <select *ngIf="usaAbaSelecionada == false" [ngClass]="[compacto ? 'compacto' : '', !ehModoClaro ? 'modo-escuro' : '']" [(ngModel)]="filtro.nome" class="col-sm-2 custom-select">
            <option *ngFor="let nome of ['NOME']" [value]="nome">{{ nome }}</option>
          </select>
          <busca *ngIf="usaAbaSelecionada == false" [minimoCaracterBusca]="numeroCaracteres" class="col-sm" [campo]="filtro.valor" (alteracao)="alterarValorFiltro($event)" [listaExterna]="true" [compacto]="compacto" [foco]="true" [id]="'produto'" [focoId]="focoBusca" (btnAdicional)="listar()" [descricao]="bibDialogo.digiteNome + ' ' + nomePesquisa" (navegacao)="$event == true ? (posicaoLista = -1) : (pesquisando = false)"></busca>
          <titulo *ngIf="usaAbaSelecionada == true" [titulo]="bibDialogo.selecionados.toLowerCase()" [compacto]="true"></titulo>
        </div>
        <div class="col-sm">&nbsp;</div>
        <table class="table table-responsive-sm tabela-fixa">
          <thead>
            <tr>
              <th class="l-5"></th>
              <th class="l-95">{{ bibDialogo.nome }}</th>
            </tr>
          </thead>
          <tbody #corpoTabela class="tamanho" tabindex="-1" (keydown)="$event.keyCode == bibAtalho.espaco ? $event.preventDefault() : null">
            <ng-container *ngFor="let registro of usaAbaSelecionada ? selecionados : lista; let i = index">
              <tr [ngClass]="i == posicaoLista ? 'selecionado' : ''" class="clique" (click)="posicaoLista = i" (dblclick)="ehSetSelecionado(i)">
                <td class="l-5">
                  <check [id]="'selecionado' + i" [campo]="registro.selecionado" [id]="'selecionaItem' + i" (alteracao)="ehSetSelecionado(i)"></check>
                </td>
                <td class="limitar l-95">{{ (servico == bibServico.contaContabil ? registro.numeracao : '') + (registro.numero ? registro.numero + ' - ' : '') + (registro.nome ? registro.nome.split(' ').join('&nbsp;') : registro.razaoSocial ? registro.razaoSocial : registro.rotulo ? registro.rotulo : registro.parceiro) }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</layout>
<div class="row align-items-center">
  <botao class="col" [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionarRegistros()"></botao>
  <atencao [atencao]="bibDialogo.teclaEspacoSelecionar"></atencao>
  <atencao *ngIf="selecionados.length > 0" [atencao]="bibDialogo.teclaEnterAdicionar + ' [' + selecionados.length  + ']' " [tipo]="bibPropriedade.atencao.sucesso"></atencao>
</div>
