<div class="row ml-1 justify-content-between align-items-center">
  <titulo [titulo]="bibDialogo.icmsSt" [imagem]="bibImagem.icmsstsaida"></titulo>
  <botao [id]="'adicionarIcmsSt'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.icmsSt" (botaoEmt)="abrirModal()"></botao>
</div>
<table class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l-60">{{ bibDialogo.uf }}</th>
      <th class="l-15 text-right">{{ bibDialogo.aliquota }}</th>
      <th class="l-15 text-right">{{ bibDialogo.mva }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let icmsSt of icmsSts; let i = index">
      <tr>
        <td class="limitar">{{ icmsSt.estado }}</td>
        <td class="text-right">{{ icmsSt.aliquota + '%' }}</td>
        <td class="text-right">{{ icmsSt.mva + '%' }}</td>
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(icmsSt)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [icone]="bibIcone.lixo" [id]="'excluirSt' + i" (btnAdicional)="ehExcluir(i, this.icmsSts, this.idsExcluir, icmsSt.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
