<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.valorJuroFinanceiro }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.tipo }}</th>
    <th class="r-1">{{ bibDialogo.valorEsperado }}</th>
    <th class="r-1">{{ bibDialogo.valorEncontrado }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let valorJuroFinanceiroFinanceiroBaixa of valorJuroFinanceiroFinanceiroBaixas">
      <tr class="conteudo">
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.id }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.dataNegociacao | data }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.numero }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.idLoja + ' - ' + valorJuroFinanceiroFinanceiroBaixa.loja }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.idEmpresa + ' - ' + valorJuroFinanceiroFinanceiroBaixa.empresa }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.tipo }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.valorEsperado | monetario }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.valorEncontrado | monetario }}</td>
        <td>{{ valorJuroFinanceiroFinanceiroBaixa.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
