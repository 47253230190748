import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Loja } from 'src/app/modelo/loja';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { UtilBusca } from 'src/app/utilitario/util.busca';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import bibPropriedade from '../../biblioteca/bibPropriedade';
import { Formulario } from '../formulario';

@Component({
  selector: 'lista',
  templateUrl: './lista.component.html',
})
export class ListaComponent extends Formulario {
  private _tipo: string = '';
  private alterado: boolean = true;
  private recebeuCampoAtual: boolean = false;
  public _apresentaDescricaoTodos: boolean = false;
  public _lista: any[] = [];
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public plainToClass: Function = plainToClass;
  public utilBusca: UtilBusca;
  @Input() ehEdicao: boolean = true;
  @Input() textoVazio: string;

  @Input()
  set lista(lista: any[]) {
    this._lista = lista;
    if (lista) {
      this.ordernarLista(this._lista);
      this.tratarTipo();
    }
  }

  @Input()
  set campo(campo: any) {
    this.iniciar(campo);
    this.recebeuCampoAtual = true;
    this.tratarTipo(true);
  }

  @Input()
  set tipo(tipo: string) {
    if (tipo) {
      this._tipo = tipo;
      this.tratarTipo();
    }
  }

  @Input()
  set apresentaDescricaoTodos(apresentaDescricaoTodos: boolean) {
    if (apresentaDescricaoTodos) {
      this._apresentaDescricaoTodos = apresentaDescricaoTodos;
    }
  }

  @Input()
  set obrigatorioLoja(obrigatorio: boolean) {
    this.obrigatorio = obrigatorio;
    this.tratarTipo(true);
  }

  constructor(private comunicacaoService: ComunicacaoService, public utilNotificacao: UtilNotificacao, public utilSessao: UtilSessao) {
    super();
    this.utilBusca = new UtilBusca(comunicacaoService, utilSessao);
  }

  ngOnInit(): void {
    this.utilNotificacao.emtUtilizaModoClaro.subscribe((res) => {
      this.ehModoClaro = res;
    });
  }

  ordernarLista(lista: any[]): void {
    let ordenacao: string;
    if (this._tipo != bibPropriedade.lista.lote) {
      if (this._tipo == bibPropriedade.lista.lojaUsuario) {
        ordenacao = 'nomeFantasia';
      } else {
        ordenacao = lista[0] ? (lista[0].nome ? 'nome' : lista[0].nomeFantasia ? 'nomeFantasia' : lista[0].razaoSocial ? 'razaoSocial' : 'abreviacao') : '';
      }
      lista.sort((a, b) => (a[ordenacao] === b[ordenacao] ? 0 : a[ordenacao] > b[ordenacao] ? 1 : -1));
    }
  }

  tratarTipo(sugerirLoja: boolean = false): void {
    if (this._tipo == bibPropriedade.lista.lojaUsuario) {
      this._lista = this.utilSessao.getLojas();
      if (this.campoAtual) {
        if (this.ehEdicao && this._lista.find((lojaUsuario) => lojaUsuario.id == this.campoAtual) == undefined) {
          this.adicionarLojaTemporaria();
        }
      }
      if (this._lista.length > 1 && this.utilSessao.getIdLoja() && this.alterado && this.obrigatorio && this.campoAtual == null && sugerirLoja) {
        this.campoAtual = this.utilSessao.getIdLoja();
        setTimeout((_) =>
          this.emitirAlteracao(
            this._lista.find((loja) => loja.id == this.utilSessao.getIdLoja()),
            true
          )
        );
        this.alterado = false;
      }
    }
    if (this._tipo == bibPropriedade.lista.contaUsuario) {
      let contaUsuarios = this.utilSessao.getUsuarioContas();
      for (let contaUsuario of contaUsuarios) {
        contaUsuario.id = contaUsuario.idConta;
        contaUsuario.nome = contaUsuario.conta;
      }
      this._lista = contaUsuarios;
      if (this.ehEdicao && this._lista.length == 1 && this.recebeuCampoAtual && this.campoAtual == null) {
        this.campoAtual = this._lista[0].id;
        setTimeout((_) => this.emitirAlteracao(this._lista[0], true));
      }
    }
    if (this._tipo == bibPropriedade.lista.produtoUnidade) {
      let unidade: any = null;
      if (this._lista.find((unidade) => unidade.sugerirUnidade == 'S') != null) {
        unidade = this._lista.find((unidade) => unidade.sugerirUnidade == 'S');
      } else {
        if (this._lista.find((unidade) => unidade.unidadeAbreviacao == this.campoAtual) != null) {
          unidade = this._lista.find((unidade) => unidade.unidadeAbreviacao == this.campoAtual);
        }
      }
      if (unidade != null) {
        this.campoAtual = unidade.id;
        setTimeout((_) =>
          this.emitirAlteracao(
            unidade,
            true
          )
        );
      }
    }
    if (this.ehEdicao && this._lista && this._lista.length == 1 && this.obrigatorio && (this.campoAtual == null || this.campoAtual == '')) {
      this.campoAtual = this._lista[0].id;
      setTimeout((_) => this.emitirAlteracao(this._lista[0], true));
      this.alterado = false;
    }
  }

  adicionarLojaTemporaria(): void {
    const criterios: Criterio[] = [];
    criterios.push(new Criterio('ID', this.campoAtual));
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), bibServico.loja).subscribe((res) => {
      const loja: Loja = this.plainToClass(Loja, res[0]) as any;
      this._lista.push(loja);
    });
  }

  alterar(): void {
    if (this.campoAtual) {
      this.emitirAlteracao(this._lista.filter((objeto) => objeto.id == this.campoAtual)[0]);
    } else {
      this.emitirAlteracao({ id: null });
    }
  }

  clicarBtnAdicionalLista(): void {
    if (this._tipo == bibPropriedade.lista.lojaUsuario) {
      this.utilBusca.listarLojas().subscribe((res) => {
        this._lista = plainToClass(Loja, res) as any;
      });
    }
    this.clicarBtnAdicional();
  }
}
