import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { ModuloEmpresa } from 'src/app/modelo/moduloEmpresa';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './moduloEmpresa.component.html',
})
export class ModuloEmpresaComponent extends PaginaComponent {
  public moduloEmpresas: ModuloEmpresa[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.moduloEmpresa).subscribe((res) => {
      this.moduloEmpresas = this.plainToClass(ModuloEmpresa, res) as any;
    });
  }
}
