<div class="row">
    <titulo class="col-sm-5 pl-3" [titulo]="bibDialogo.colaborador" [imagem]="bibImagem.colaborador" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.colaborador.toLocaleLowerCase()"></titulo>
</div>
<div *ngIf="equipe.id && equipe.equipeParceiros.length == 0" class="row">
<p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.colaborador.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
 <table *ngIf="equipe.equipeParceiros && equipe.equipeParceiros.length > 0" class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l">{{ bibDialogo.nome }}</th>
      <th class="l-15 text-right">{{ bibDialogo.comissao + ' ' + bibDialogo.percentualSimbolo }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>      
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let equipeParceiro of equipe.equipeParceiros; let i = index">
      <tr>
        <td>
          <lista *ngIf="!equipeParceiro.id || edicaoEquipeParceiros.includes(equipeParceiro.id)" [campo]="equipeParceiro.idParceiro" [id]="'equipeParceiro' + i" (alteracao)="setParceiro(equipeParceiro, $event)" [lista]="colaboradores" [obrigatorio]="true" [foco]="true" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarColaboradores()"></lista>
          {{ equipeParceiro.id && edicaoEquipeParceiros.includes(equipeParceiro.id) == false ? equipeParceiro.parceiro : '' }}     
        </td>
        <td class="text-right">
          <decimal *ngIf="!equipeParceiro.id || edicaoEquipeParceiros.includes(equipeParceiro.id)" [campo]="equipeParceiro.comissao" (alteracao)="equipeParceiro.comissao = $event" [obrigatorio]="true"></decimal>
          {{ equipeParceiro.id && edicaoEquipeParceiros.includes(equipeParceiro.id) == false ? equipeParceiro.comissao  + ' ' + bibDialogo.percentualSimbolo : '' }}     
        </td>
        <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editar(equipeParceiro)"></btnAdicional></td>
        <td><btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirEquipeParceiro(i, equipeParceiro.id)"></btnAdicional></td>        
      </tr>
    </ng-container>
  </tbody>
</table> 