import { Component } from '@angular/core';
import { Credito } from 'src/app/modelo/credito';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { FinanceiroFrmComponent } from '../financeiro/frm/financeiroFrm.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import filtros from './filtro';

@Component({
  selector: 'credito',
  templateUrl: './credito.component.html',
})
export class CreditoComponent extends PaginaComponent {
  public componente: any;
  public creditoFuturo: number = 0;
  public creditos: Credito[] = [];
  public criterios: Criterio[] = [];
  public filtros: Filtro[];
  public idLoja: number = 0;
  public parametro: string;
  public atencao: string = "";
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.creditos = [];
      this.selecionarFiltro(params['parametro']);
    });
    const idParceiro = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
    this.idLoja = this.util.coalesce(this.utilSessao.getIdentificacao('idLoja')?.conteudo, 0);
    if (idParceiro) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID_PARCEIRO', idParceiro));
      criterios.push(new Criterio('PERTENCE', this.utilSessao.getIdentificacao('pertence')?.conteudo));
      this.listar(criterios);
    }
  }

  selecionarFiltro(parametro: string): void {
    switch (parametro) {
      case 'CLIENTE':
        this.filtros = filtros.filtroCliente;
        this.atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['os ' + this.menuCliente.apelido.toLowerCase() + 's']);
        break;
      case 'EMPRESA':
        this.filtros = filtros.filtroEmpresa;
        this.atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['os ' + this.menuFornecedor.apelido.toLowerCase() + 'es']);
        break;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.criterios = criterios.filter((criterio) => criterio.nome == 'DATA_DE' || criterio.nome == 'DATA_ATE' || criterio.nome == 'ID_PARCEIRO' || criterio.nome == 'PERTENCE');
    if (this.parametro == 'CLIENTE') {
      this.criterios.push(new Criterio('PARCEIRO', 'P'));
    } else {
      this.criterios.push(new Criterio('EMPRESA', 'E'));
    }
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.credito).subscribe((res) => {
      this.creditos = this.plainToClass(Credito, res) as any;
      this.creditoFuturo = 0;
      this.listarCreditoFuturo(criterios);
    });
  }

  private listarCreditoFuturo(criterios: Criterio[]): void {
    criterios = criterios.filter((criterio) => criterio.nome == 'ID_PARCEIRO' || criterio.nome == 'PERTENCE');
    criterios.push(new Criterio('CREDITO_FUTURO', 'S'));
    this.creditoFuturo = 0;
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.credito).subscribe((res) => {
      const creditos: Credito[] = this.plainToClass(Credito, res) as any;
      creditos.forEach((credito) => {
        this.creditoFuturo += credito.valor;
      });
    });
  }

  abrirModal(credito: Credito): void {
    if (credito.idFinanceiroBaixa) {
      this.utilSessao.setIdentificacao(new Identificacao('modalBloqueada', 'S'));
      this.utilSessao.setIdentificacao(new Identificacao('objeto', credito.idFinanceiro));
      this.utilSessao.setIdentificacao(new Identificacao('parametro', credito.pertencente == 'P' ? 'R' : 'D'));
      this.componente = FinanceiroFrmComponent;
    }
    if (credito.idMovimentacao) {
      this.utilSessao.setIdentificacao(new Identificacao('modalBloqueada', 'S'));
      this.utilSessao.setIdentificacao(new Identificacao('objeto', credito.idMovimentacao));
      this.componente = MovimentacaoFrmComponent;
    }
  }

  gerarAprovacao(credito: Credito): void {
    credito.chave = 'P';
    super.persistir(new Transporte(credito), this.bibServico.credito, credito).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.listar(this.criterios);
        this.alertarDescricao();
      }
    });
  }

  alertarDescricao(): void {
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.ordemPagamentoGerada.toUpperCase()));
  }

  persistirCredito(credito: Credito): void {
    credito.resgateFinanceiro = credito.id;
    credito.id = null;
    credito.origem = '4';
    credito.resgateCredito = 'S';
    credito.valor = credito.valor * -1;
    credito.idLoja = this.idLoja;
    super.persistir(new Transporte(credito), this.bibServico.credito, credito).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.listar(this.criterios);
      }
    });
  }
}
