<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <ng-container *ngFor="let produto of produtoEtiquetas">
    <table>
      <thead>
        <tr></tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ produto.referencia }}</td>
          <td>{{ produto.fabricante }}</td>
        </tr>
        <tr>
          <td colspan="2">{{ produto.complemento }}</td>
        </tr>
        <tr>
          <td>{{ produto.preco | monetario: this.empresaRegraCasasDecimais }}</td>
          <td>{{ produto.unidadeAbreviacao }}</td>
        </tr>
        <tr>
          <td colspan="2">{{ produto.nome }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</relatorioLayout>
