<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.nfeNaoTransmitida }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r-1">{{ bibDialogo.responsavel }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let notaNaoTransmitida of notasNaoTransmitidas">
      <tr>
        <td>{{ notaNaoTransmitida.loja }}</td>
        <td>{{ notaNaoTransmitida.operacao }}</td>
        <td>{{ notaNaoTransmitida.responsavel }}</td>
        <td>{{ notaNaoTransmitida.numero }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
