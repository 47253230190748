import { Type } from 'class-transformer';

export class RomaneioEntregaDocumentoFiscal {
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public id: number;
  public idRomaneioEntrega: number;
  public idMovimentacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;

  public parceiroNomeFantasia: string;
  public colaborador: string;
  public chaveFiscal: string;
  public dataMovimentacao: Date;
  public numeroMovimentacao: number;
  public valorTotalMovimentacao: number;
  public pesoBrutoMovimentacao: number;
  public entradaSaidaInterna: number = 0;
}
