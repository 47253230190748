import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Permissao } from 'src/app/modelo/permissao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { Util } from 'src/app/utilitario/util';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r2021.component.html',
  styleUrls: ['./r2021.component.css'],
})
export class R2021Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public estoques: any[] = [];
  public pedidoCarteiras: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public telaPedidoCarteira: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 196);
  public usaQuantidadeComprada: boolean = false;
  public usaQuantidadePendente: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.estoques = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Estoque').lista;
      this.pedidoCarteiras = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'PedidoCarteira').lista;
      this.ordenarAgrupar(this.estoques);
      this.atualizarQuantidadePendentePedidoCarteira();
    });
  }

  atualizarQuantidadePendentePedidoCarteira(): void {
    for (const pedidoCarteira of this.pedidoCarteiras) {
      const estoque: any = this.estoques.find((estoque) => estoque.idProduto == pedidoCarteira.idProduto);
      if (estoque) {
        estoque.quantidadePendenteFaturamento += pedidoCarteira.quantidadePendenteFaturamento;
        estoque.quantidadeDisponivelCarteira = estoque.quantidadeDisponivel - estoque.quantidadePendenteFaturamento;
        this.adicionarPedidoCarteira(estoque, pedidoCarteira);
      } else {
        pedidoCarteira.quantidadeDisponivel = 0;
        pedidoCarteira.quantidadeDisponivelCarteira = pedidoCarteira.quantidadeDisponivel - pedidoCarteira.quantidadePendenteFaturamento;
        pedidoCarteira.quantidadePendente = 0;
        pedidoCarteira.quantidadeComprada = 0;
        pedidoCarteira.quantidadeNfe = 0;
        this.estoques.push(pedidoCarteira);
        this.adicionarPedidoCarteira(this.estoques[this.estoques.length - 1], pedidoCarteira);
      }
    }
    new Util().ordenarLista(this.estoques, 'produto');
  }

  adicionarPedidoCarteira(estoque: any, pedidoCarteira: any) {
    let pedidoCarteiras: any[] = [];
    if (estoque.pedidoCarteiras) {
      pedidoCarteiras = estoque.pedidoCarteiras;
    }
    pedidoCarteiras.push(pedidoCarteira);
    estoque.pedidoCarteiras = pedidoCarteiras;
  }

  expandirPedidoCarteira(estoque) {
    event.preventDefault();
    estoque.pedidoCarteiraExpandido = estoque.pedidoCarteiraExpandido ? (estoque.pedidoCarteiraExpandido = !estoque.pedidoCarteiraExpandido) : true;
  }

  
  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaQuantidadeComprada = this.definirRegra(relatorioAdicional, 242, this.usaQuantidadeComprada);    
    this.usaQuantidadePendente = this.definirRegra(relatorioAdicional, 243, this.usaQuantidadePendente);
  }
}
