export class bibServicoTecnoSpeed {
  url: string = 'https://api.plugnotas.com.br'; //Produção
  //url: string = 'https://api.sandbox.plugnotas.com.br'; //Teste

  getUrlNfe(servico: string): string {
    return this.url + `/${servico}`;
  }

  getUrlEnviarEmail(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/email`;
  }

  getUrlCancelaNfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/cancelamento`;
  }

  getUrlConsultaCancelamentoNfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/cancelamento/status`;
  }

  getUrlConsultaResumoNfe(servico: string, idNota: string, cnpj: string = null, codigo: string = null): string {
    if (idNota) {
      return this.url + `/${servico}/${idNota}/resumo`;
    } else {
      return this.url + `/${servico}/${cnpj}/${codigo}/resumo`;
    }
  }

  getUrlBaixaPdfNfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/pdf`;
  }

  getUrlResumoNfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/resumo`;
  }

  getUrlBaixaXmlNfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/xml`;
  }

  getUrlBaixaXmlCancelamentoNfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/cancelamento/xml`;
  }

  getUrlBaixaPdfCartaCorrecao(idNota: string): string {
    return this.url + `/nfe/${idNota}/cce/pdf`;
  }

  getUrlCorrigiNfe(idNota: string): string {
    return this.url + `/nfe/${idNota}/cce`;
  }

  getUrlConsultaCorrecaoNfe(idNota: string): string {
    return this.url + `/nfe/${idNota}/cce/status`;
  }

  getUrlLogotipo(cnpj: string): string {
    return this.url + `/empresa/${cnpj}/logotipo`;
  }
}
