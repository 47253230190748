import { Type } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibServico from 'src/app/biblioteca/bibServico';
import { GrupoIcmsSt } from './grupoIcmsSt';
import { GrupoParceiroComissao } from './grupoParceiroComissao';
import { Hierarquia } from './hierarquia';

export class Grupo implements Hierarquia {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  private idGrupoPai: number;
  private pai: string = 'N';
  public alterado: boolean = false;
  public arvore: boolean = false;
  public ativo: string = 'S';
  public bloqueadoBalanco: string = 'N';
  public comissaoPercentual: number = 0;
  public descontoMaximo: number;
  public grupoIcmsSts: GrupoIcmsSt[] = [];
  public grupoPai: string;
  public grupoParceiroComissoes: GrupoParceiroComissao[] = [];
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idsParceiroComissaoGrupoExcluir: number[];
  public nome: string;
  public rotulo: string = bibDialogo.grupoPai;
  public servico: string = bibServico.grupo;
  public usaIcmsDesonerado: string = 'N';
  public cofinsAliquotaEntrada: number;
  public cofinsAliquotaSaida: number;
  public idCfopTransferencia: number;
  public idCfopEntrada: number;
  public idCfopSaida: number;
  public idCfopTransferenciaForaUf: number;
  public idCfopEntradaForaUf: number;
  public idCfopSaidaForaUf: number;
  public idCstCofinsEntrada: number;
  public idCstCofinsSaida: number;
  public idCstPisEntrada: number;
  public idCstPisSaida: number;
  public idIpiEntrada: string;
  public idIpiSaida: string;
  public pisAliquotaEntrada: number;
  public pisAliquotaSaida: number;
  public ipiAliquotaEntrada: number;
  public ipiAliquotaSaida: number;
  public ipiEnquadramentoEntrada: string;
  public ipiEnquadramentoSaida: string;
  public usoConsumo: string = 'N';
  public bloqueiaSemInscricao: string = 'N';

  setIdPai(idPai: number) {
    this.idGrupoPai = idPai;
  }

  getIdPai(): number {
    return this.idGrupoPai;
  }

  getPai(): string {
    return this.grupoPai;
  }
}
