<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-20 clique" (click)="listar(add('contaOrigem'), this.utilSessao.posicao, this.paginacao)">{{ menuConta.apelido + ' ' + bibDialogo.origem }}</th>
        <th class="l-20 clique" (click)="listar(add('contaDestino'), this.utilSessao.posicao, this.paginacao)">{{ menuConta.apelido + ' ' + bibDialogo.destino }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
        <th class="l-25 clique" (click)="listar(add('tipoTitulo'), this.utilSessao.posicao, this.paginacao)">{{ menuTipoDeTitulo.apelido }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let transferencia of transferencias; let i = index">
        <tr>
          <td>{{ transferencia.data | data }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(transferencia.id, i, transferencias)">{{ transferencia.contaOrigem }}</a>
          </td>
          <td class="limitar">{{ transferencia.contaDestino }}</td>
          <td class="text-right">{{ transferencia.valor | monetario }}</td>
          <td>{{ transferencia.tipoTitulo }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirTransferencia(transferencia.id, transferencia.contaOrigem + ' para ' + transferencia.contaDestino)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
