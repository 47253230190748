<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 naoImprimir" (click)="listar(add('selecionado'), this.utilSessao.posicao, this.paginacao)"></th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-10 clique" (click)="listar(add('dataEntrega'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataEntrega }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('movimentacaoNumero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-20 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-30 clique" (click)="listar(add('produto'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.produto }}</th>
        <th class="l-10 naoImprimir clique" (click)="listar(add('unidade'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.unidade }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('quantidade'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.quantidade }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let entregaProduto of entregaProdutos; let i = index">
        <tr>
          <td class="naoImprimir"><check [campo]="entregaProduto.selecionado" (alteracao)="entregaProduto.selecionado = $event" [id]="'check' + i"></check></td>
          <td>{{ entregaProduto.abreviacao }}</td>
          <td>{{ entregaProduto.dataEntrega | data }}</td>
          <td class="text-right">{{ entregaProduto.movimentacaoNumero }}</td>
          <td class="limitar">{{ entregaProduto.parceiro }}</td>
          <td class="limitar">{{ entregaProduto.produto }}</td>
          <td class="naoImprimir">{{ entregaProduto.unidade }}</td>
          <td class="text-right">{{ entregaProduto.quantidade }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.estornar" [tipo]="bibPropriedade.botao.sucesso" (botaoEmt)="estornar()" [fundo]="true" [desabilitado]="!temPermissaoEdicao"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
