<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(produtoValores, $event)" (campoGrupoEmt)="agrupar(produtoValores, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th *ngIf="usaReferencia" class="r-1-5">{{ bibDialogo.referencia }}</th>
      <th *ngIf="usaFabricante" class="r-2-5">{{ menuFabricante.apelido }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.estFisicoLocal + ' (' + lojaAbreviacao + ')' }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.estFisicoLojas }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.precoVenda }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoValor of produtoValores; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ produtoValor.produtoNumero + ' - ' + produtoValor.produto }}</td>
          <td *ngIf="usaReferencia">{{ produtoValor.produtoReferencia }}</td>
          <td *ngIf="usaFabricante" class="limitar">{{ produtoValor.fabricante }}</td>
          <td>{{ produtoValor.unidade }}</td>
          <td class="text-right">{{ produtoValor.estoqueFisicoLocal | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ produtoValor.estoqueFisicoTotal | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ produtoValor.precoVenda | monetario }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="produtoValores.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
