<div class="row justify-content-between align-items-center">
  <titulo class="col-sm-12 pl-3" [titulo]="bibDialogo.percurso" [imagem]="bibImagem.transporte" [icone]="bibIcone.mais" (iconeEmt)="ehAbrirModalPercurso()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.percurso.toLocaleLowerCase()" [desabilitado]="bloqueado" [ajuda]="bibDialogo.informacaoMdfeObrigatoriedadePercurso"></titulo>
</div>
<div *ngIf="mdfe.mdfePercursos && mdfe.mdfePercursos.length == 0 && !bloqueado" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.percurso.toLowerCase() + ', ' }} <strong class="clique" (click)="ehAbrirModalPercurso()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
  <atencao class="col-sm-12 pl-3" [atencao]="bibDialogo.informacaoMdfeObrigatoriedadePercurso"></atencao>
</div>
<table *ngIf="mdfe.mdfePercursos && mdfe.mdfePercursos.length > 0" class="table table-responsive-sm">
  <thead>
    <tr>
      <th>{{ bibDialogo.estado }}</th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let mdfePercurso of mdfe.mdfePercursos; let i = index">
      <tr>
        <td> {{ mdfePercurso.estado }} </td>   
        <td>
          <btnAdicional *ngIf="!bloqueado" [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirPercurso(i, mdfePercurso.id)"></btnAdicional>         
        </td>
      </tr>
    </ng-container>
  </tbody>
</table> 
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>