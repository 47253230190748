<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(produtos, $event)" (campoGrupoEmt)="agrupar(produtos, $event)" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.produto }}</th>
        <th class="r-2">{{ bibDialogo.grupo }}</th>
        <th class="r-1">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="r-1">{{ bibDialogo.ncm }}</th>
        <th class="r-1">{{ bibDialogo.cfopEntrada }}</th>
        <th class="r-1">{{ bibDialogo.cfopSaida }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo sem-borda">
          <td>{{ produto.numero + ' - ' + produto.nome }}</td>
          <td class="limitar">{{ produto.grupo }}</td>
          <td>{{ produto.unidade }}</td>
          <td>{{ produto.ncm }}</td>
          <td>{{ produto.cfopEntrada }}</td>
          <td>{{ produto.cfopSaida }}</td>
        </tr>
        <!-- <tr class="sem-borda" r1125icmsEntrada [produtoIcmsEntradas]="produto.produtoIcmsEntradas"></tr> -->
        <tr class="sem-borda" r1125icmsSaida [produtoIcmsSaidas]="produto.produtoIcmsSaidas"></tr>
        <tr class="sem-borda" r1125pis [produto]="produto"></tr>
        <tr class="sem-borda" r1125rcofins [produto]="produto"></tr>
        <tr class="sem-borda" r1125ipi [produto]="produto"></tr>
        <tr r1125icmsSt [produtoIcmsSts]="produto.produtoIcmsSts"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
