import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { RelatorioComponent } from '../../relatorio.component';

@Component({
  selector: ' <tr>[r1001itens]</tr>',
  templateUrl: './r1001itens.component.html',
})
export class R1001ItensComponent extends RelatorioComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacao: any;
  @Input() usaNegociacao: boolean = false;
  @Input() usaSituacao: boolean = false;
  @Input() pedidoCarteira: boolean = false;
  @Input() comissao: boolean = false;
  @Input() criteriosFiltro: Criterio[];
  public bibDialogo = bibDialogo;
  public menuProduto: MenuEmpresa = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 7);

  expandir(movimentacaoProduto: any) {
    movimentacaoProduto.expandido = !movimentacaoProduto.expandido;
    this.criterios = this.criteriosFiltro;
    if (movimentacaoProduto.expandido) {
      this.listarEspecifico(new Criterio('ID', movimentacaoProduto.id), 189).subscribe((res) => {
        movimentacaoProduto.notasfiscais = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

}
