<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (adicionalEmt)="definirRegraRelatorio($event)" (filtroEmt)="listarRelatorio($event)" [grupos]="grupos" [ordens]="ordens" (campoOrdemEmt)="ordenar(financeiros, $event)" (campoGrupoEmt)="agrupar(financeiros, $event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <tr>
        <th class="r-0-5 clique" (click)="ordenar(financeiros, 'loja')">{{ bibDialogo.loja }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'numero')">{{ bibDialogo.numero }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'numeroFiscal')">{{ bibDialogo.numeroFiscal }}</th>
        <th *ngIf="usaDataNegociacao" class="r-1 text-right clique" (click)="ordenar(financeiros, 'dataNegociacaoOrdenacao')">{{ bibDialogo.dataNegociacao }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'dataVencimentoOrdenacao')">{{ bibDialogo.dataVencimento }}</th>
        <th *ngIf="this.maisInformacoes == false" class="r-1 text-right clique" (click)="ordenar(financeiros, 'diaAtraso')">{{ bibDialogo.dia }} <br />{{ bibDialogo.atraso }}</th>
        <th class="r-1-5 text-right clique" (click)="ordenar(financeiros, 'parcela')">{{ bibDialogo.parcela }}</th>
        <th class="r clique" (click)="ordenar(financeiros, 'nomeParceiro')">{{ bibDialogo.fornecedor }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'valorRestante')">{{ bibDialogo.valorRestante }}</th>
        <th *ngIf="this.maisInformacoes" class="r-3">{{ bibDialogo.contaContabil }}</th>
        <th *ngIf="this.maisInformacoes" class="r-3">{{ rotuloContaCentroResultado }}</th>    
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ financeiro.loja }}</td>
          <td class="text-right">{{ financeiro.numero }}</td>
          <td class="text-right">{{ financeiro.numeroFiscal }}</td>
          <td *ngIf="usaDataNegociacao" class="text-right">{{ financeiro.dataNegociacao | data }}</td>
          <td class="text-right">{{ financeiro.dataVencimento | data }}</td>
          <td *ngIf="this.maisInformacoes == false" [ngClass]="financeiro.diaAtraso > 0 ? 'atraso' : ''" class="text-right">{{ financeiro.diaAtraso }}</td>
          <td class="text-right">{{ financeiro.parcela }}</td>
          <td>{{ financeiro.parceiro }}</td>
          <td class="text-right">{{ financeiro.valorRestante | monetario }}</td>
          <td *ngIf="this.maisInformacoes" class="limitar">{{ financeiro.contaContabil }}</td>
          <td *ngIf="this.maisInformacoes" class="limitar">{{ financeiro.centroResultado }}</td>          
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="financeiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
