import { MenuModuloEmpresa } from './menuModuloEmpresa';
import { RelatorioModuloEmpresa } from './relatorioModuloEmpresa';

export class ModuloEmpresa {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public icone: string;
  public id: number;
  public idEmpresa: number;
  public idModuloPai: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public menuModuloEmpresas: MenuModuloEmpresa[] = [];
  public relatorioModuloEmpresas: RelatorioModuloEmpresa[] = [];
  public nivel: number = 0;
  public nome: string;
  public ordem: number = 0;
  public titulo: string;
}
