<titulo  [id]="'adicionarDestino'" [titulo]="bibNomenclatura.escrever(bibNomenclatura.destino)" [imagem]="bibImagem.destino" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [ajuda]="bibDialogo.adicionar + ' ' + bibNomenclatura.escrever(bibNomenclatura.destino).toLowerCase()" [desabilitado]="temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
<div class="row" *ngIf="parceiroDestinos.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibNomenclatura.escrever(bibNomenclatura.destino).toLowerCase() + ', ' }} <strong class="clique" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="parceiroDestinos.length > 0">
  <thead>
    <tr>
      <th class="l-25">{{ bibDialogo.nome }}</th>
      <th class="l-30">{{ bibDialogo.logradouro }}</th>
      <th class="l-10 text-right">{{ bibDialogo.numero }}</th>
      <th class="l-10">{{ rotuloBairro }}</th>
      <th class="l-15">{{ rotuloCidade }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{ tachado: parceiroDestino.ativo != 'S' }" *ngFor="let parceiroDestino of parceiroDestinos; let i = index">
      <td class="limitar">{{ parceiroDestino.nome }}</td>
      <td class="limitar">{{ parceiroDestino.tipo + ' ' + parceiroDestino.endereco }}</td>
      <td class="text-right">{{ parceiroDestino.numero }}</td>
      <td class="limitar">{{ parceiroDestino.bairro }}</td>
      <td class="limitar">{{ parceiroDestino.cidade }}</td>
      <td>
        <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(parceiroDestino)"></btnAdicional>
      </td>
      <td>
        <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this.parceiroDestinos, this.excluirDestinos, parceiroDestino.id)"></btnAdicional>
      </td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
