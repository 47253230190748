<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <ng-container *ngFor="let movimentacao of this.movimentacoes; let i = index">
    <h5 *ngIf="movimentacao.documentoFiscal > 0">NF-e {{movimentacao.documentoFiscal }} JÁ TRANSMITIDA. FAÇA A IMPRESSÃO DIRETO NO DANFE</h5>
    <ng-container *ngIf="movimentacao.documentoFiscal == null || movimentacao.documentoFiscal == 0">
      <div class="tarja-nao-finalizado p-5">{{ movimentacao.status != 'S' ? bibDialogo.naoFinalizado: bibDialogo.naoFiscal }}</div>        
      <table>
        <tbody>
          <colgroup>
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
          </colgroup>        
          <tr class="border-top-0">
            <td colspan="7">RECEBEMOS DE {{movimentacao.loja}} OS PRODUTOS/SERVIÇOS CONSTANTES NA NOTA FISCAL INDICADA AO LADO</td>
            <td colspan="2">VALOR DA NOTA<br><b>R${{ movimentacao.valorTotal | monetario }}</b></td>
            <td colspan="1" rowspan="2" class="text-center align-items-center pt-3"><b>NF-e<br>Nº: {{ movimentacao.numeroFormatado }}<br>SÉRIE: {{movimentacao.serie}}</b></td>
          </tr>  
          <tr>
            <td colspan="2">DATA DE RECEBIMENTO<br>______/______/______</td>
            <td colspan="3">IDENTIFICAÇÃO E ASSINATURA DO RECEBEDOR</td>
            <td colspan="4">DESTINATÁRIO<br>{{ movimentacao.parceiroRazaoSocial }}</td>
          </tr>              
          <tr class="border-0">
            <td colspan="4" class="border-0"><h6>{{movimentacao.loja}}</h6></td>
            <td colspan="2"  rowspan="3" class="text-center"><h5>DANFE</h5><b>DOCUMENTO AUXILIAR<br>DA NOTA FISCAL<br>ELETRÔNICA<br>0 - ENTRADA<div class="entrada-saida"><b>{{movimentacao.naturezaOperacaoFinalidade == 3 || movimentacao.idOperacao == 17 ? '0': '1'}}</b></div><br>1 - SAÍDA<br>Nº: {{ movimentacao.numeroFormatado }}<br>SÉRIE: {{movimentacao.serie}}</b></td>
            <td colspan="4"><b>PRÉ-VISUALIZAÇÃO</b></td>
          </tr>   
          <tr>
            <td colspan="1" rowspan="2" class="border-top-0 border-right-0 text-center"><img [src]="foto ? foto : imagemEmpresa" [alt]="empresa.nome" height="80" width="80" /></td>
            <td colspan="3" rowspan="2" class="border-top-0 border-left-0 text-center">{{movimentacao.lojaEndereco}}<br>TELEFONE: {{movimentacao.lojaTelefone}}<br>E-MAIL: {{movimentacao.lojaEmail}}</td>
            <td colspan="4">CHAVE DE ACESSO<br>0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000</td>
          </tr>                          
          <tr>
            <td colspan="4"><b>DOCUMENTO NÃO VÁLIDO</b></td>
          </tr>    
          <tr>
            <td colspan="6">NATUREZA DA OPERAÇÃO<br>{{movimentacao.natureza}}</td>
            <td colspan="4">PROTOCOLO DE AUTORIZAÇÃO DE USO<br>000000000000000 - {{(dataAtual | data: 'dd/MM/yyyy HH:mm:ss')}}</td>
          </tr>        
          <tr>
            <td colspan="3">INSCRIÇÃO ESTADUAL<br>{{movimentacao.lojaInscricao}}</td>
            <td colspan="3">INSCRIÇÃO ESTADUAL SUB. TRIBUTARIA</td>
            <td colspan="4">CNPJ<br>{{movimentacao.lojaCnpj}}</td>
          </tr>   
          <tr>
            <td colspan="10"><b>DESTINATÁRIO/REMETENTE</b></td>
          </tr> 
          <tr>
            <td colspan="6">NOME/RAZÃO SOCIAL<br>{{movimentacao.parceiroRazaoSocial}}</td>
            <td colspan="2">CNPJ/CPF<br>{{movimentacao.parceiroCnpjCpf}}</td>
            <td colspan="2">DATA DA EMISSÃO<br>{{movimentacao.data | data}}</td>
          </tr>  
          <tr>
            <td colspan="4">ENDEREÇO<br>{{movimentacao.parceiroEnderecoResumo}}</td>
            <td colspan="3">BAIRRO/DISTRITO<br>{{movimentacao.parceiroBairro}}</td>
            <td colspan="1">CEP<br>{{movimentacao.parceiroCep}}</td>
            <td colspan="2">DATA DE SAÍDA/ENTRADA<br>{{movimentacao.dataSaida | data}}</td>
          </tr>    
          <tr>
            <td colspan="3">MUNICÍPIO<br>{{movimentacao.parceiroCidade}}</td>
            <td colspan="2">FONE/FAX<br>{{movimentacao.parceiroTelefone}}</td>
            <td colspan="1">UF<br>{{movimentacao.parceiroUf}}</td>
            <td colspan="2">INSCRIÇÃO ESTADUAL<br>{{movimentacao.parceiroInscricaoEstadual}}</td>
            <td colspan="2">HORA DE SAÍDA<br>{{movimentacao.horaSaida}}</td>
          </tr>                    
          <tr>
            <td colspan="10"><b>FATURA</b></td>
          </tr> 
          <tr>
            <td colspan="10"><b>CÁLCULO DO IMPOSTO</b></td>
          </tr>     
          <tr>
            <td colspan="2" class="text-center">BASE DE CÁLCULO DE ICMS<br>{{movimentacao.icmsBase | monetario}}</td>
            <td colspan="2" class="text-center">VALOR DO ICMS<br>{{movimentacao.icmsValor | monetario}}</td>
            <td colspan="2" class="text-center">BASE DE CÁLCULO ICMS ST<br>{{movimentacao.icmsStBase | monetario}}</td>
            <td colspan="2" class="text-center">VALOR DO ICMS SUBSTITUIÇÃO<br>{{movimentacao.icmsStValor | monetario}}</td>
            <td colspan="2" class="text-center">VALOR TOTAL DOS PRODUTOS<br>{{movimentacao.valorTotalProdutoBruto | monetario}}</td>
          </tr>  
          <tr>
            <td colspan="1" class="text-center">VALOR FRETE<br>{{movimentacao.freteValor | monetario}}</td>
            <td colspan="1" class="text-center">VALOR SEGURO<br>{{movimentacao.seguroValor | monetario}}</td>
            <td colspan="1" class="text-center">DESCONTO<br>{{movimentacao.descontoTotal | monetario}}</td>
            <td colspan="2" class="text-center">OUTRAS DESPESAS<br>{{movimentacao.despesaValor | monetario}}</td>
            <td colspan="1" class="text-center">VALOR IPI<br>{{movimentacao.ipiValor | monetario}}</td>
            <td colspan="2" class="text-center">VALOR APROX. DOS TRIBUTOS<br>{{movimentacao.ibptEstadualValor + movimentacao.ibptNacionalValor | monetario}}</td>
            <td colspan="2" class="text-center">VALOR TOTAL DA NOTA<br>{{movimentacao.valorTotal | monetario}}</td>
          </tr>                                                                  
          <tr>
            <td colspan="10"><b>TRANSPORTADOR/VOLUMES TRANSPORTADOS</b></td>
          </tr> 
          <tr>
            <td colspan="4">RAZÃO SOCIAL<br>{{movimentacao.transportadora}}</td>
            <td colspan="1">FRETE<br>{{movimentacao.freteTipo == '100' ?  '0 - REM.' : movimentacao.freteTipo == '1' ?  '1 - DEST.' : movimentacao.freteTipo == '2' ? '2 - TER.' : movimentacao.freteTipo == '9' ?  '9 - SEM.' : ''}}</td>
            <td colspan="1">CÓDIGO ANTT<br> </td>
            <td colspan="1">PLACA VEÍC.<br>{{movimentacao.transportadoraPlaca}}</td>
            <td colspan="1">UF<br>{{movimentacao.transportadoraPlacaUf}}</td>
            <td colspan="2">CNPJ/CPF<br>{{movimentacao.transportadoraCnpjCpf}}</td>          
          </tr> 
          <tr>
            <td colspan="5">ENDEREÇO<br>{{movimentacao.transportadoraEnderecoResumo}}</td>
            <td colspan="2">MUNICÍPIO<br>{{movimentacao.transportadoraCidade}}</td>
            <td colspan="1">UF<br>{{movimentacao.transportadoraUf}}</td>
            <td colspan="2">INSCRIÇÃO ESTADUAL<br>{{movimentacao.transportadoraInscricao}}</td>          
          </tr>    
          <tr>
            <td colspan="2">QUANTIDADE<br>{{movimentacao.transportadoraQuantidade}}</td>
            <td colspan="1">ESPÉCIE<br>{{movimentacao.transportadoraEspecie}}</td>
            <td colspan="2">MARCA<br>{{movimentacao.transportadoraMarca}}</td>
            <td colspan="1">NUMERAÇÃO<br>{{movimentacao.transportadoraNumeracao}}</td>
            <td colspan="2">PESO BRUTO<br>{{movimentacao.pesoBruto | monetario}}</td>          
            <td colspan="2">PESO LIQUIDO<br>{{movimentacao.pesoLiquido | monetario}}</td>          
          </tr>     
          <tr>
            <td colspan="10"><b>DADOS DO PRODUTO/SERVIÇO</b></td>
          </tr>    
      </table>             
      <table class="fonte-pequena border-top-0">
        <tbody>
          <colgroup>
            <col width="5%"><col width="5%"><col width="5%"><col width="5%">
            <col width="5%"><col width="5%"><col width="5%"><col width="5%">
            <col width="5%"><col width="5%"><col width="5%"><col width="5%">
            <col width="5%"><col width="5%"><col width="5%"><col width="5%">
            <col width="5%"><col width="5%"><col width="5%"><col width="5%">
          </colgroup>         
          <tr class="border-0">
            <td class="centraliza-vertical border-top-0" colspan="1" rowspan="2">COD. PROD.</td>
            <td class="centraliza-vertical border-top-0" colspan="6" rowspan="2">DESCRIÇÃO DO PRODUTO/SERVIÇO</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">NCM SH</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">CST</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">CFOP</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">UNID.</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">QUANT.</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">VALOR UNITARIO</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">VALOR TOTAL</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">B.CALC. ICMS</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">VALOR ICMS</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">VALOR IPI</td>
            <td class="centraliza-vertical text-center border-top-0" colspan="2">ALIQUOTAS</td>
            <td class="centraliza-vertical text-center border-top-0" rowspan="2">VALOR APROX. DOS TRIBUTOS</td>
          </tr>
          <tr>
            <td class="centraliza-vertical text-center">ICMS</td>
            <td class="centraliza-vertical text-center">IPI</td>
          </tr>      
          <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let i = index">
            <tr [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''">
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" colspan="1">{{ movimentacaoProduto.produtoNumero }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" colspan="6">{{ movimentacaoProduto.produtoNomeFiscal }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.ncm }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.cst }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.idCfop }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.unidadeAbreviacao }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.valorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.valorTotal | monetario }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.icmsBase | monetario }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.icmsValor | monetario }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.ipiValor | monetario }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.icmsAliquota | monetario }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.ipiAliquota | monetario }}</td>
              <td [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-bottom-0' : ''" class="text-center">{{ movimentacaoProduto.ibptEstadualValor + movimentacaoProduto.ibptNacionalValor | monetario }}</td>
            </tr>
            <tr *ngIf="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != ''" [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-top-0' : ''">
              <td colspan="20" [ngClass]="this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? 'border-top-0' : ''">{{(this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) != '' ? this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto) : '')}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>   
      <footer class="mt-5">
        <table>
          <tbody>
            <tr>
              <td colspan="2"><b>DADOS ADICIONAIS</b></td>
            </tr>      
            <tr>
              <td>INFORMAÇÕES COMPLEMENTARES<br>{{this.utilDocumentoEletronico.gerarInformacaoComplementar(movimentacao, movimentacao.parceiroUf)}}</td>
              <td>PRÉ VISUALIZAÇÃO - DOCUMENTO NÃO VÁLIDO<br></td>
            </tr>
          </tbody>
        </table> 
      </footer>   
    </ng-container>
  </ng-container>
</relatorioLayout>
