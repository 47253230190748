import { Component, Input } from '@angular/core';
import { UsuarioCategoriaRoteiro } from 'src/app/modelo/usuarioCategoriaRoteiro';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'usuarioCategoriaRoteirosFrm',
  templateUrl: './usuarioCategoriaRoteirosFrm.component.html',
})
export class UsuarioCategoriaRoteirosFrmComponent extends PaginaComponent {
  @Input() usuarioCategoriaRoteiros: UsuarioCategoriaRoteiro[] = [];
}
