<div class="row">
  <titulo class="col-sm" [titulo]="bibDialogo.pesquisaRapida" [imagem]="bibImagem.pesquisa"></titulo>
</div>
<div class="row form-group naoImprimir">
  <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="listaCampoPesquisa" (alteracao)="setCampoPesquisa($event.id)" [obrigatorio]="true"></lista>
  <texto [desabilitado]="campoPesquisa == bibDialogo.semAcesso || campoPesquisa == bibDialogo.comAcesso ? true : false" class="col-sm" [campo]="filtro" (alteracao)="filtro = $event" [descricao]="bibDialogo.digiteNomeTela"></texto>
</div>
<div class="responsiva">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-25">{{ bibDialogo.menu }}</th>
        <th class="l-10">{{ bibDialogo.acessar }}</th>
        <th class="l-10">{{ bibDialogo.inserir }}</th>
        <th class="l-10">{{ bibDialogo.editar }}</th>
        <th class="l-10">{{ bibDialogo.excluir }}</th>
        <th class="l-10">{{ bibDialogo.inativar }}</th>
        <th class="l-10">{{ bibDialogo.favorito }}</th>
        <th class="l-10"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
  </table>
  <ng-container class="responsiva" *ngFor="let grupoUsuarioMenu of grupoUsuarioMenus | filtrarUsuarioMenu: { filtro: filtro, especifico: campoPesquisa }; let i = index">
    <div class="col-sm-12" *ngIf="i == 0 ? true : grupoUsuarioMenus[i].modulo != grupoUsuarioMenus[i - 1].modulo">
      <titulo [titulo]="grupoUsuarioMenu.modulo" [compacto]="false"></titulo>
    </div>
    <table class="table table-responsive-sm mb-0">
      <tbody>
        <tr>
          <td class="l-25 limitar"><span *ngIf="grupoUsuarioMenu.idMenu == 80" class="menu-icon oi oi-dashboard"></span> {{ grupoUsuarioMenu.menu }} <span *ngIf="grupoUsuarioMenu.novo == 'S'" class="badge badge-warning">Novo</span></td>
          <td class="l-10">
            <icone [icone]="grupoUsuarioMenu.menuAcessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="grupoUsuarioMenu.acessar = grupoUsuarioMenu.acessar == 'S' ? alterarTodos(grupoUsuarioMenu, 'N') : 'S'" [cor]="grupoUsuarioMenu.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="grupoUsuarioMenu.acessar == 'S' ? bibDialogo.removerAcesso : bibDialogo.acessar"></icone>
          </td>
          <td class="l-10">
            <icone *ngIf="grupoUsuarioMenu.menuInserir == 'S'" [icone]="grupoUsuarioMenu.inserir == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="grupoUsuarioMenu.inserir = grupoUsuarioMenu.inserir == 'S' ? 'N' : 'S'" [cor]="grupoUsuarioMenu.inserir == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="grupoUsuarioMenu.inserir == 'S' ? bibDialogo.removerInsercao : bibDialogo.inserir"></icone>
          </td>
          <td class="l-10">
            <icone *ngIf="grupoUsuarioMenu.menuEditar == 'S'" [icone]="grupoUsuarioMenu.editar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="grupoUsuarioMenu.editar = grupoUsuarioMenu.editar == 'S' ? 'N' : 'S'" [cor]="grupoUsuarioMenu.editar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="grupoUsuarioMenu.editar == 'S' ? bibDialogo.removerEdicao : bibDialogo.editar"></icone>
          </td>
          <td class="l-10">
            <icone *ngIf="grupoUsuarioMenu.menuExcluir == 'S'" [icone]="grupoUsuarioMenu.excluir == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="grupoUsuarioMenu.excluir = grupoUsuarioMenu.excluir == 'S' ? 'N' : 'S'" [cor]="grupoUsuarioMenu.excluir == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="grupoUsuarioMenu.excluir == 'S' ? bibDialogo.removerExclusao : bibDialogo.excluir"></icone>
          </td>
          <td class="l-10">
            <icone *ngIf="grupoUsuarioMenu.menuInativar == 'S'" [icone]="grupoUsuarioMenu.inativar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="grupoUsuarioMenu.inativar = grupoUsuarioMenu.excluir == 'S' ? 'N' : 'S'" [cor]="grupoUsuarioMenu.inativar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="grupoUsuarioMenu.inativar == 'S' ? bibDialogo.removerInativar : bibDialogo.inativar"></icone>
          </td>
          <td class="l-10">
            <icone *ngIf="grupoUsuarioMenu.acessar == 'S'" [icone]="bibIcone.estrela" [clique]="true" (iconeEmt)="grupoUsuarioMenu.favorito = grupoUsuarioMenu.favorito == 'S' ? 'N' : 'S'" [cor]="grupoUsuarioMenu.favorito == 'S' ? bibPropriedade.icone.amarelo : bibPropriedade.icone.cinza" [mensagem]="grupoUsuarioMenu.favorito == 'S' ? bibDialogo.removerFavorito : bibDialogo.favoritar"></icone>
          </td>
          <td class="l-5" *ngIf="grupoUsuarioMenu.idMenu != 101">
            <btnAdicional [icone]="bibIcone.cadeadoAberto" (btnAdicional)="alterarTodos(grupoUsuarioMenu, 'S')" [ajuda]="bibDialogo.permitir"></btnAdicional>
          </td>
          <td class="l-5" *ngIf="grupoUsuarioMenu.idMenu != 101">
            <btnAdicional [icone]="bibIcone.cadeadoFechado" [iconeCor]="ehModoClaro ? bibPropriedade.icone.cinza : bibPropriedade.icone.cinzaEscuro" (btnAdicional)="alterarTodos(grupoUsuarioMenu, 'N')" [ajuda]="bibDialogo.bloquear"></btnAdicional>
          </td>
          <td class="l-5">
            <btnAdicional [icone]="grupoUsuarioMenu.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="grupoUsuarioMenu.expandido = !grupoUsuarioMenu.expandido"></btnAdicional>
          </td>
        </tr>
        <tr *ngIf="grupoUsuarioMenu.expandido">
          <td colspan="9"><apresentacao class="col-sm-12" [rotulo]="bibDialogo.descricao" [campo]="grupoUsuarioMenu.descricao"></apresentacao></td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
