export class Boleto {
  public CedenteContaCodigoBanco: string;
  public CedenteContaNumero: string;
  public CedenteContaNumeroDV: string;
  public CedenteConvenioNumero: string;
  public SacadoCPFCNPJ: string;
  public SacadoCelular: string;
  public SacadoEmail: string;
  public SacadoEnderecoBairro: string;
  public SacadoEnderecoCEP: string;
  public SacadoEnderecoCidade: string;
  public SacadoEnderecoComplemento: string;
  public SacadoEnderecoLogradouro: string;
  public SacadoEnderecoNumero: string;
  public SacadoEnderecoPais: string;
  public SacadoEnderecoUF: string;
  public SacadoNome: string;
  public SacadoTelefone: string;
  public TituloCodDesconto: number;
  public TituloCodProtesto: number;
  public TituloCodigoJuros: number;
  public TituloCodigoMulta: number;
  public TituloDataDesconto: string;
  public TituloDataEmissao: string;
  public TituloDataJuros: string;
  public TituloDataMulta: string;
  public TituloDataVencimento: string;
  public TituloDocEspecie: string;
  public TituloInstrucoes: string;
  public TituloLocalPagamento: string;
  public TituloMensagem01: string;
  public TituloMensagem02: string;
  public TituloMensagem03: string;
  public TituloNossoNumero: string;
  public TituloNumeroDocumento: string;
  public TituloPrazoProtesto: number;
  public TituloValor: string;
  public TituloValorDescontoTaxa: string;
  public TituloValorJuros: string;
  public TituloValorMultaTaxa: string;
  public motivo: string;
  public situacao: string;
  public TituloCodigoReferencia: string;
  public TituloVariacaoCarteira: string;
}
