import { Type } from 'class-transformer';

export class ReceituarioEletronico {
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idMovimentacao: number;
  public status: string;
  public statusDescricao: string;
  public enviando: string;
  public transmitido: string;
  public cancelado: string;
  public dataTransmissao: string;
  public protocoloTransmissao: string;
  public dataCancelamento: string;
  public protocoloCancelamento: string;
  public motivoCancelamento: string;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public receita: number;
  public codigoWebService: string;
  public arquivoPdf: string = '';

  public idAgronomo: number;
  public agronomo: string;
  public idParceiro: number;
  public parceiro: string;
  public idOperacao: number;
  public operacao: string;
  public dataMovimentacao: Date;
  public numeroMovimentacao: number;
  public statusMovimentacao: string;
  public empresa: string;
  public loja: string;
  public expandido: boolean = false;
  public selecionado: string = 'N';
  public tipoAssinatura: string = '';

  constructor(idMovimentacao: number, idEmpresa: number, idLoja: number, receita: number) {
    this.idMovimentacao = idMovimentacao;
    this.idEmpresa = idEmpresa;
    this.idLoja = idLoja;
    this.receita = receita;
    this.enviando = 'N';
    this.transmitido = 'N';
    this.cancelado = 'N';
    this.expandido = false;
    this.selecionado = 'N';
  }
}
