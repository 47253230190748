<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.atualizacaoLimiteCredito }}</h1>
<table>
  <thead>
    <th class="r-1-5">{{ bibDialogo.responsavel }}</th>
    <th class="r">{{ bibDialogo.cliente }}</th>
    <th class="r-2">{{ rotuloRamoAtividade }}</th>
    <th class="r-1 text-right">{{ bibDialogo.limiteAnterior }}</th>
    <th class="r-1 text-right">{{ bibDialogo.limiteNovo }}</th>
    <th class="r-1 text-right">{{ bibDialogo.limiteAtual }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorVencido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.diasAtraso }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let alteracaoLimiteMaximo of alteracaoLimiteMaximos">
      <tr>
        <td class="limitar">{{ alteracaoLimiteMaximo.vendedor }}</td>
        <td class="limitar">{{ alteracaoLimiteMaximo.numeroParceiro + ' - ' + alteracaoLimiteMaximo.parceiro }}</td>
        <td class="limitar">{{ alteracaoLimiteMaximo.ramoAtividade }}</td>
        <td class="text-right">{{ alteracaoLimiteMaximo.limiteAntes | monetario }}</td>
        <td class="text-right">{{ alteracaoLimiteMaximo.limiteDepois | monetario }}</td>
        <td class="text-right">{{ alteracaoLimiteMaximo.limiteAtual | monetario }}</td>
        <td class="text-right">{{ alteracaoLimiteMaximo.vencido | monetario }}</td>
        <td class="text-right">{{ alteracaoLimiteMaximo.diasAtraso }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
