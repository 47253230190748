import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.loja, campo: 'loja' },
  { rotulo: bibDialogo.data, campo: 'movimentacaoData' },
  { rotulo: bibDialogo.numero, campo: 'movimentacaoNumero' },
  { rotulo: bibDialogo.fornecedor, campo: 'parceiro' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.quantidade, campo: 'quantidade' },
];
export default ordens;
