import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioAdicionalEmpresa } from 'src/app/modelo/relatorioAdicionalEmpresa';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'relatorioPersonalizadoFrm',
  templateUrl: './relatorioPersonalizadoFrm.component.html',
})
export class RelatorioPersonalizadoFrmComponent extends PaginaComponent {
  public idRelatorio: number;
  public relatorio: string;
  public layoutEspecifico: string;
  public relatorioAdicionais: RelatorioAdicional[] = [];
  public relatorioAdicionalEmpresas: RelatorioAdicionalEmpresa[] = [];

  ngOnInit(): void {
    this.idRelatorio = this.utilSessao.getIdentificacao('idRelatorio')?.conteudo;
    this.relatorio = this.utilSessao.getIdentificacao('relatorioNome')?.conteudo;
    this.layoutEspecifico = this.utilSessao.getIdentificacao('layoutEspecifico')?.conteudo;
    this.relatorioAdicionalEmpresas = this.utilSessao.getIdentificacao('relatorioAdicionalEmpresas')?.conteudo;
    this.utilSessao.excluirIdentificacao('idRelatorio');
    this.listarRelatorioAdicionais(this.idRelatorio);
  }

  listarRelatorioAdicionais(idRelatorio: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_RELATORIO', idRelatorio)]), this.bibServico.relatorioAdicional).subscribe((res) => {
      this.relatorioAdicionais = this.plainToClass(RelatorioAdicional, res) as any;
      this.ocultarMatricial();
      if (this.layoutEspecifico == 'S') {
        this.ocultarLinhaDivisoriaEmRelatorioEspecifico();
        this.ocultarRetratoEmRelatorioEspecifico();
      }
      this.listarRelatorioAdicionalEmpresas(idRelatorio);
    });
  }

  ocultarMatricial(): void {
    const idAdicionalMatricial: number = 4;
    const index: number = this.relatorioAdicionais
      .map((relatorioAdicional) => {
        return relatorioAdicional.id;
      })
      .indexOf(idAdicionalMatricial);
    this.relatorioAdicionais.splice(index, 1);
  }

  ocultarLinhaDivisoriaEmRelatorioEspecifico(): void {
    const idAdicionalLinhaDivisoria: number = 2;
    const index: number = this.relatorioAdicionais
      .map((relatorioAdicional) => {
        return relatorioAdicional.id;
      })
      .indexOf(idAdicionalLinhaDivisoria);
    this.relatorioAdicionais.splice(index, 1);
  }

  ocultarRetratoEmRelatorioEspecifico(): void {
    const idAdicionalRetrato: number = 1;
    const index: number = this.relatorioAdicionais
      .map((relatorioAdicional) => {
        return relatorioAdicional.id;
      })
      .indexOf(idAdicionalRetrato);
    this.relatorioAdicionais.splice(index, 1);
  }

  listarRelatorioAdicionalEmpresas(idRelatorio: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_RELATORIO', idRelatorio)]), this.bibServico.relatorioAdicionalEmpresa).subscribe((res) => {
      const relatorioAdicionalEmpresa: RelatorioAdicionalEmpresa[] = this.plainToClass(RelatorioAdicionalEmpresa, res) as any;
      relatorioAdicionalEmpresa.forEach((relatorioAdicionalEmpresa) => {
        let ehRelatorioAdicionalNaoExistente: boolean = this.relatorioAdicionalEmpresas.find((relatorioAdicionalEmpresaBusca) => relatorioAdicionalEmpresaBusca.idRelatorioAdicional == relatorioAdicionalEmpresa.idRelatorioAdicional) == undefined;
        if (ehRelatorioAdicionalNaoExistente) {
          this.relatorioAdicionalEmpresas.push(relatorioAdicionalEmpresa);
        }
      });
      this.vincularRelatorioAdicionalEmpresa();
    });
  }

  vincularRelatorioAdicionalEmpresa(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      const relatorioAdicionalEmpresa: RelatorioAdicionalEmpresa = this.relatorioAdicionalEmpresas.find((relatorioAdicionalEmpresa) => relatorioAdicionalEmpresa.idRelatorioAdicional == relatorioAdicional.id);
      if (relatorioAdicionalEmpresa) {
        relatorioAdicional.marcado = relatorioAdicionalEmpresa.marcado;
        relatorioAdicional.oculto = relatorioAdicionalEmpresa.oculto;
      }
    });
  }

  setRelatorioAdicionalEmpresaOcultar(relatorioAdicional: RelatorioAdicional, oculto: string): void {
    let relatorioAdicionalEmpresa: RelatorioAdicionalEmpresa = this.relatorioAdicionalEmpresas.find((relatorioAdicionalEmpresa) => relatorioAdicionalEmpresa.idRelatorioAdicional == relatorioAdicional.id);
    if (relatorioAdicionalEmpresa) {
      relatorioAdicionalEmpresa.oculto = oculto;
      relatorioAdicionalEmpresa.alterado = true;
    } else {
      relatorioAdicionalEmpresa = new RelatorioAdicionalEmpresa();
      relatorioAdicionalEmpresa.idRelatorioAdicional = relatorioAdicional.id;
      relatorioAdicionalEmpresa.idRelatorio = this.idRelatorio;
      relatorioAdicionalEmpresa.idEmpresa = this.utilSessao.getUsuario().idEmpresa;
      relatorioAdicionalEmpresa.marcado = relatorioAdicional.marcado;
      relatorioAdicionalEmpresa.oculto = oculto;
      relatorioAdicionalEmpresa.alterado = true;
      this.relatorioAdicionalEmpresas.push(relatorioAdicionalEmpresa);
    }
  }

  setRelatorioAdicionalEmpresa(relatorioAdicional: RelatorioAdicional, marcado: string): void {
    let relatorioAdicionalEmpresa: RelatorioAdicionalEmpresa = this.relatorioAdicionalEmpresas.find((relatorioAdicionalEmpresa) => relatorioAdicionalEmpresa.idRelatorioAdicional == relatorioAdicional.id);
    if (relatorioAdicionalEmpresa) {
      relatorioAdicionalEmpresa.marcado = marcado;
      relatorioAdicionalEmpresa.alterado = true;
    } else {
      relatorioAdicionalEmpresa = new RelatorioAdicionalEmpresa();
      relatorioAdicionalEmpresa.idRelatorioAdicional = relatorioAdicional.id;
      relatorioAdicionalEmpresa.idRelatorio = this.idRelatorio;
      relatorioAdicionalEmpresa.idEmpresa = this.utilSessao.getUsuario().idEmpresa;
      relatorioAdicionalEmpresa.marcado = marcado;
      relatorioAdicionalEmpresa.oculto = 'N';
      relatorioAdicionalEmpresa.alterado = true;
      this.relatorioAdicionalEmpresas.push(relatorioAdicionalEmpresa);
    }
  }

  persistirRelatorioAdicionalEmpresas(): void {
    const relatorioAdicionalEmpresasPersistir = this.relatorioAdicionalEmpresas.filter((relatorioAdicionalEmpresa) => relatorioAdicionalEmpresa.alterado);
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.relatorioAdicionalEmpresa, relatorioAdicionalEmpresasPersistir));
  }
}
