import { Component } from '@angular/core';
import { addDays, compareAsc } from 'date-fns';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1151.component.html',
})
export class R1151Component extends RelatorioComponent {
  private dias: string[] = [bibDialogo.domingo, bibDialogo.segundaFeira, bibDialogo.tercaFeira, bibDialogo.quartaFeira, bibDialogo.quintaFeira, bibDialogo.sextaFeira, bibDialogo.sabado];
  public conciliacoes: any[] = [];
  public ehAgrupacaoDia: boolean = false;
  public ehAgrupacaoMesAno: boolean = false;
  public ehAgrupacaoSemanaAno: boolean = false;
  public filtros = filtros;
  public financeiros: any[] = [];
  public completoFinanceiros: any[] = [];
  public grupos = grupos;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public saldoTotalConciliacao: number = 0;
  public ignoraInadimplencia: boolean = false;
  public ultimoGrupo: Grupo;
  public inadimplenteFinanceiros: any[] = [];
  public indadimplenteReceita: number = 0;
  public indadimplenteDespesa: number = 0;
  public expandidoInadimplente: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    this.saldoTotalConciliacao = 0;
    super.listar(criterios).subscribe(() => {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N'));
      this.conciliacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Conciliacao').lista;
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.completoFinanceiros = this.financeiros;
      this.conciliacoes.forEach((conciliacao) => (this.saldoTotalConciliacao += conciliacao.saldoReal));
      this.calcularSaldo(this.financeiros, this.saldoTotalConciliacao);
      this.ordenarAgrupar(this.financeiros);
      this.ehImprimirRelatorio();
    });
  }

  calcularSaldo(financeiros: any[], saldoInicial: number): void {
    for (let i = 0; i < financeiros.length; i++) {
      const financeiro = financeiros[i];
      if (i == 0) {
        financeiro.saldo = saldoInicial + financeiro.receita - financeiro.despesa;
      } else {
        financeiro.saldo = financeiros[i - 1].saldo + financeiro.receita - financeiro.despesa;
      }
      const dia: number = new Date(financeiro.dataVencimento).getDate();
      financeiro.diaExtenso = new Date(financeiro.dataVencimento).getFullYear() + ' - ' + this.util.escreverMes(financeiro.mesAno, true) + ' - DIA ' + (dia <= 9 ? '0' : '') + dia + ' - ' + this.dias[financeiro.diaSemana];
      financeiro.semanaAnoExtenso = new Date(financeiro.dataVencimento).getFullYear() + ' - ' + financeiro.semanaAno + 'ª SEMANA';
      financeiro.mesAnoExtenso = new Date(financeiro.dataVencimento).getFullYear() + ' - ' + this.util.escreverMes(financeiro.mesAno, true);
    }
  }

  agruparCalcularSaldo(lista: any[], agrupa: any): void {
    this.ultimoGrupo = agrupa;
    this.agrupar(lista, agrupa, true);
    this.calcularSaldo(this.financeiros, this.saldoTotalConciliacao);
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.ignoraInadimplencia = this.definirRegra(relatorioAdicional, 223, this.ignoraInadimplencia);
    if (this.ignoraInadimplencia) {
      const dataSemHora = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      this.financeiros = this.completoFinanceiros.filter((financeiro) => compareAsc(addDays(new Date(financeiro.dataVencimento), 4), dataSemHora) != -1);
      this.inadimplenteFinanceiros = this.completoFinanceiros.filter((financeiro) => compareAsc(addDays(new Date(financeiro.dataVencimento), 4), dataSemHora) == -1);
      this.calcularInadimplente();
    } else {
      this.financeiros = this.completoFinanceiros;
      this.inadimplenteFinanceiros = [];
      this.indadimplenteReceita = 0;
      this.indadimplenteDespesa = 0;
      this.expandidoInadimplente = false;
    }
    // if (this.ultimoGrupo) {
    //   this.agruparCalcularSaldo(this.financeiros, this.ultimoGrupo);
    // } else {
    //   this.calcularSaldo(this.financeiros, this.saldoTotalConciliacao);
    // }
    this.agruparCalcularSaldo(this.financeiros, this.ultimoGrupo);
  }

  calcularInadimplente() {
    this.indadimplenteReceita = 0;
    this.indadimplenteDespesa = 0;
    for (const inadimplenteFinanceiro of this.inadimplenteFinanceiros) {
      this.indadimplenteReceita += inadimplenteFinanceiro.receita;
      this.indadimplenteDespesa += inadimplenteFinanceiro.despesa;
    }
    this.calcularSaldo(this.inadimplenteFinanceiros, 0);
  }
}
