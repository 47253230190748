<titulo [titulo]="bibDialogo.informacoesCarga" [imagem]="bibImagem.produto" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.informacoesCarga.toLocaleLowerCase()" [desabilitado]="bloqueado"></titulo>
<div *ngIf="cte.cteInformacaoCargas.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.informacoesCarga.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<div class="row">
  <decimal class="col-sm-4" [id]="'valorCarga'" [rotulo]="bibDialogo.valorCarga" [campo]="cte.valorCarga" (alteracao)="cte.valorCarga = $event" [desabilitado]="bloqueado"></decimal>
  <texto class="col-sm-8" [id]="'produtoPredominante'" [rotulo]="bibDialogo.produtoPredominante" [campo]="cte.produtoPredominante" (alteracao)="cte.produtoPredominante = $event" [desabilitado]="bloqueado" [obrigatorio]="true" [maximoCaracteres]="60"></texto>  
</div> 
<table *ngIf="cte.cteInformacaoCargas.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l-50 clique" (click)="util.ordenarLista(this.cte.cteInformacaoCargas, 'unidadeMedida')">{{ bibDialogo.unidadeMedida }}</th>
      <th class="l-60 clique" (click)="util.ordenarLista(this.cte.cteInformacaoCargas, 'tipoMedida')">{{ bibDialogo.tipoMedida }}</th>
      <th class="l-25 clique" (click)="util.ordenarLista(this.cte.cteInformacaoCargas, 'quantidadePeso')">{{ bibDialogo.quantidadePeso }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let cteInformacaoCarga of cte.cteInformacaoCargas; let i = index">
      <tr>
        <td>
          <lista *ngIf="!cteInformacaoCarga.id || edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id)" [campo]="cteInformacaoCarga.unidadeMedida" (alteracao)="cteInformacaoCarga.unidadeMedida = $event.id" [lista]="unidadesMedida" [obrigatorio]="true" [focoId]="focoUnidadeMedida"></lista>
          {{ cteInformacaoCarga.id && edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id) == false ? cteInformacaoCarga.unidadeMedida : '' }}
        </td>
        <td >
          <texto *ngIf="!cteInformacaoCarga.id || edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id)" [campo]="cteInformacaoCarga.tipoMedida" (alteracao)="cteInformacaoCarga.tipoMedida = $event" [obrigatorio]="true" ></texto>
          {{ cteInformacaoCarga.id && edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id) == false ? cteInformacaoCarga.tipoMedida : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="!cteInformacaoCarga.id || edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id)" [campo]="cteInformacaoCarga.quantidadePeso" (alteracao)="cteInformacaoCarga.quantidadePeso = $event" [obrigatorio]="true" [numeroCasasDecimais]="3"></decimal>
          {{ cteInformacaoCarga.id && edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id) == false ? cteInformacaoCarga.quantidadePeso : '' }}
        </td>
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editar(cteInformacaoCarga)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirCteInformacaoCarga(i, cteInformacaoCarga.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
