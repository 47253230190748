import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.clientePagouNaoRepassadoAoVendedor, id: 'COM.VALOR_DISPONIVEL <> 0' },
  { nome: bibDialogo.valorFaltantePagarVendedor, id: 'COM.VALOR_RESTANTE <> 0' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataMoviDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.dataMovimentacao },
  { rotulo: bibDialogo.dataMoviAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.dataMovimentacao },
  // { rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  // { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataLiberacaoDe, nome: 'DATA_LIBERACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.ajudaComissaoLiberadaConciliacao },
  { rotulo: bibDialogo.dataLiberacaoAte, nome: 'DATA_LIBERACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, ajuda: bibDialogo.ajudaComissaoLiberadaConciliacao },
  // { rotulo: bibDialogo.dataComissaoDe, nome: 'DATA_BAIXA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'SITUACAO_COMISSAO', visualizacao: ['COM.VALOR_PAGO <> 0'], visualizacaoInicial: false, ajuda: bibDialogo.ajudaDataPagamentoComissao },
  // { rotulo: bibDialogo.dataComissaoAte, nome: 'DATA_BAIXA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'SITUACAO_COMISSAO', visualizacao: ['COM.VALOR_PAGO <> 0'], visualizacaoInicial: false, ajuda: bibDialogo.ajudaDataPagamentoComissao },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  // { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboSituacao },
]);

export default filtros;
