import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { GrupoAcessoHorario } from 'src/app/modelo/grupoAcessoHorario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'grupoAcessoHorarioFrm',
  templateUrl: './grupoAcessoHorarioFrm.component.html',
})
export class GrupoAcessoHorarioFrmComponent extends PaginaComponent {
  @Input() grupoAcessoHorarios: GrupoAcessoHorario[] = [];
  @Input() excluirGrupoAcessoHorarios: number[] = [];
  @Input() edicaoGrupoAcessoHorarios: number[] = [];

  public diasSemana = [
    { id: 1, nome: '1 - Domingo' },
    { id: 2, nome: '2 - Segunda' },
    { id: 3, nome: '3 - Terça' },
    { id: 4, nome: '4 - Quarta' },
    { id: 5, nome: '5 - Quinta' },
    { id: 6, nome: '6 - Sexta' },
    { id: 7, nome: '7 - Sábado' },
  ];

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;

  adicionar(): void {
    let grupoAcessoHorario = new GrupoAcessoHorario();
    this.grupoAcessoHorarios.push(grupoAcessoHorario);
  }

  setDiaSemana(grupoAcessoHorarios: GrupoAcessoHorario, idDiaSemana: number): void {
    grupoAcessoHorarios.diaSemana = idDiaSemana;
  }

  editar(grupoAcessoHorario: GrupoAcessoHorario): void {
    if (this.edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id)) {
      const posicao: number = this.edicaoGrupoAcessoHorarios.indexOf(grupoAcessoHorario.id, 0);
      this.edicaoGrupoAcessoHorarios.splice(posicao, 1);
    } else {
      this.edicaoGrupoAcessoHorarios.push(grupoAcessoHorario.id);
    }
  }

  excluirGrupoAcessoHorario(posicao: number, idsGrupoAcessoHorario: number): void {
    super.ehExcluir(posicao, this.grupoAcessoHorarios, this.excluirGrupoAcessoHorarios, idsGrupoAcessoHorario);
  }
}
