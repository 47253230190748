import { Combustivel } from './combustivel';
import { Importacao } from './importacao';
import { Medicamento } from './medicamento';
import { Quantidade } from './quantidade';
import { Rastreavel } from './rastreavel';
import { Tributo } from './tributo';
import { Unidade } from './unidade';
import { ValorUnitario } from './valorUnitario';

export class Item {
  public cest: string;
  public cfop: string;
  public codigo: string;
  public codigoBarras: string;
  public codigoBarrasTributavel: string;
  public combustivel: Combustivel;
  public compoeTotal: boolean;
  public descricao: string;
  public ncm: string;
  public quantidade: Quantidade;
  public rastreavel: Rastreavel[];
  public tributos: Tributo;
  public unidade: Unidade;
  public valor: number;
  public valorDesconto: number;
  public valorFrete: number;
  public valorOutros: number;
  public valorSeguro: number;
  public valorUnitario: ValorUnitario;
  public codigoBeneficioFiscal: string;
  public informacoesComplementares: string;
  public medicamentos: Medicamento[];
  public importacao: Importacao[];
}
