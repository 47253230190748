<layout [titulo]="caixa.usuario" [id]="caixa.id" (voltarAvancarEmt)="ehAlteracao()">  
  <div class="row">
    <lista class="col-sm-6"  [id]="'conta'" [rotulo]="bibDialogo.conta" [campo]="caixa.idConta" (alteracao)="caixa.idConta = $event.id" [lista]="contas" [obrigatorio]="true" [foco]="true" [desabilitado]="caixa.id"></lista>    
  </div> 
  <table *ngIf="caixa.id" class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-20">{{ bibDialogo.usuario }}</th>
        <th class="l-20">{{ bibDialogo.dataHoraAbertura }}</th>
        <th class="l-20">{{ bibDialogo.dataHoraFechamento }}</th>
      </tr>
    </thead>
    <tbody>
      <tr >     
        <td class="limitar">         
          {{ caixa.usuario }}
        </td>
        <td class="limitar">          
          {{ caixa.dataHoraAbertura | data: 'dd/MM/yyyy HH:mm' }}
        </td>   
        <td class="limitar">          
          {{ caixa.dataHoraFechamento | data: 'dd/MM/yyyy HH:mm' }}
        </td>   
      </tr> 
    </tbody>  
  </table>  
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCaixa()" [desabilitado]="(caixa.id) || (!temPermissaoInserir && !caixa.id) ? true : false"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.fecharCaixa" [tipo]="bibPropriedade.botao.cuidado" [fundo]="true" (botaoEmt)="persistirCaixa(true)" [desabilitado]=" (caixa.dataHoraFechamento != null) || (!caixa.id)  || (!temPermissaoInserir) ? true : false"></botao>
<auditoria *ngIf="caixa.id" [idUsuarioInclusao]="caixa.idUsuarioInclusao" [dataHoraInclusao]="caixa.dataHoraInclusao" [idUsuarioAlteracao]="caixa.idUsuarioAlteracao" [dataHoraAlteracao]="caixa.dataHoraAlteracao"></auditoria>
