export class MovimentacaoCancelamento {
  private idUsuarioAlteracao: number;
  private idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idMovimentacao: number;
  public motivo: string;
}
