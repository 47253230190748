import { Component } from '@angular/core';
import { Equipamento } from 'src/app/agrow/modelo/equipamento';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';


@Component({
  templateUrl: './equipamento.component.html',
})
export class EquipamentoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public equipamentos: Equipamento[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.equipamento).subscribe((res) => {
      this.equipamentos = this.plainToClass(Equipamento, res) as any;
    });
  }

  excluirEquipamento(id: number, nome: string): void {
    super.excluir<Equipamento>(id, this.equipamentos, nome);
  }
}
