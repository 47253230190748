<layout [titulo]="grupoAcesso.nome" [id]="grupoAcesso.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="grupoAcesso.nome" (alteracao)="grupoAcesso.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="grupoAcesso.ativo" (alteracao)="grupoAcesso.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <section class="card card-fluid">
    <header class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <a class="nav-link active" [id]="'linkTabelaHorario'" data-toggle="tab" href="#tabelaHorario">{{ bibDialogo.horario }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [id]="'linkTabelaLocal'" data-toggle="tab" href="#tabelaLocal">{{ bibDialogo.local }}</a>
        </li>
      </ul>
    </header>
    <div class="card-body">
      <div id="myTabContent" class="tab-content">
        <grupoAcessoHorarioFrm class="tab-pane fade" id="tabelaHorario" [ngClass]="{ 'active show': true }" [grupoAcessoHorarios]="grupoAcesso.grupoAcessoHorarios" [excluirGrupoAcessoHorarios]="excluirGrupoAcessoHorarios" [edicaoGrupoAcessoHorarios]="edicaoGrupoAcessoHorarios"></grupoAcessoHorarioFrm>
        <grupoAcessoLocalFrm class="tab-pane fade" id="tabelaLocal" [grupoAcessoLocais]="grupoAcesso.grupoAcessoLocais" [excluirGrupoAcessoLocais]="excluirGrupoAcessoLocais" [edicaoGrupoAcessoLocais]="edicaoGrupoAcessoLocais"></grupoAcessoLocalFrm>
      </div>
    </div>
  </section>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGrupoAcesso()" [desabilitado]="bloquearPermissao(grupoAcesso.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirGrupoAcesso(true)" [desabilitado]="bloquearPermissao(grupoAcesso.id)"></botao>
<auditoria *ngIf="grupoAcesso.id" [idUsuarioInclusao]="grupoAcesso.idUsuarioInclusao" [dataHoraInclusao]="grupoAcesso.dataHoraInclusao" [idUsuarioAlteracao]="grupoAcesso.idUsuarioAlteracao" [dataHoraAlteracao]="grupoAcesso.dataHoraAlteracao"></auditoria>
