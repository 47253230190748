<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th [ngClass]="usaLoja ? 'l-10' : ''" *ngIf="usaLoja" class="l-10 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th [ngClass]="usaLoja ? 'l-80' : 'l-90'" class="col-sm clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let generico of genericos; let i = index">
        <tr [ngClass]="{ tachado: generico.ativo != 'S' }">
          <td *ngIf="usaLoja">{{ generico.abreviacao }}</td>
          <td>
            <span *ngIf="usuario.idEmpresa != generico.idEmpresa">{{ generico.nome }}</span>
            <a *ngIf="usuario.idEmpresa == generico.idEmpresa" href="#" (click)="ir(generico.id, i, genericos)">{{ generico.nome }}</a>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(generico.id, i, genericos)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirGenerico(generico.id, generico.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
