import { Component } from '@angular/core';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1012.component.html',
  styleUrls: ['./r1012.component.css'],
})
export class R1012Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public movimentacaoProdutoAgrupados: any[] = [];
  public movimentacaoProdutoAgrupadoNaturezaOperacoes: any[] = [];
  public totalizadorImpostos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public componente: any;
  public bibPropriedade = bibPropriedade;
  public totalAPagarICMS: number;
  public totalAPagarPIS: number;
  public totalAPagarCofins: number;
  public saldoICMS: number;
  public saldoPIS: number;
  public saldoCofins: number;
  public mostrarTotalizador: boolean = true;
  public mostrarEntradaSaidaDevolucao: boolean = false;
  public mostrarNaturezaOperacao: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacaoProdutoAgrupados = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProdutoAgrupado').lista;
      this.totalizadorImpostos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TotalizadorImpostos').lista;
      this.movimentacaoProdutoAgrupadoNaturezaOperacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProdutoAgrupadoNaturezaOperacao').lista;
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.ordenarAgrupar(this.movimentacaoProdutoAgrupados);
      this.ordenarAgrupar(this.movimentacaoProdutoAgrupadoNaturezaOperacoes);
      this.ehImprimirRelatorio();
      this.totalizarApuracao();
      this.agrupar(this.movimentacaoProdutoAgrupados, { rotulo: this.bibDialogo.entradaSaida, campo: 'tipo', id: '0' });
      this.utilNotificacao.setAgrupamento({ id: 1, rotulo: this.bibDialogo.entradaSaida, campo: 'tipo' });
    });
  }

  emitirModal(id: number): void {
    this.componente = this.abrirModalRelatorio(MovimentacaoFrmComponent, [new Identificacao('objeto', id)], null);
  }

  expandir(movimentacaoProdutoAgrupado: any) {
    movimentacaoProdutoAgrupado.expandido = !movimentacaoProdutoAgrupado.expandido;
    if (movimentacaoProdutoAgrupado.expandido && (movimentacaoProdutoAgrupado.movimentacaoProdutos == null || movimentacaoProdutoAgrupado.movimentacaoProdutos.length == 0)) {
      this.listarEspecifico(new Criterio('CFOP', movimentacaoProdutoAgrupado.id), 55).subscribe((res) => {
        movimentacaoProdutoAgrupado.movimentacaoProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

  totalizarApuracao(): void {
    this.totalAPagarICMS = 0;
    this.totalAPagarPIS = 0;
    this.totalAPagarCofins = 0;
    this.saldoICMS = 0;
    this.saldoPIS = 0;
    this.saldoCofins = 0;
    this.totalizadorImpostos.forEach((totalizadorImposto) => {
      this.totalAPagarICMS = totalizadorImposto.valorIcmsSaldoAnterior - totalizadorImposto.valorIcmsPeriodo * -1 > 0 ? 0.0 : totalizadorImposto.valorIcmsSaldoAnterior - totalizadorImposto.valorIcmsPeriodo * -1;
      this.totalAPagarPIS = totalizadorImposto.valorPisSaldoAnterior - totalizadorImposto.valorPisPeriodo * -1 > 0 ? 0.0 : totalizadorImposto.valorPisSaldoAnterior - totalizadorImposto.valorPisPeriodo * -1;
      this.totalAPagarCofins = totalizadorImposto.valorCofinsSaldoAnterior - totalizadorImposto.valorCofinsPeriodo * -1 > 0 ? 0.0 : totalizadorImposto.valorCofinsSaldoAnterior - totalizadorImposto.valorCofinsPeriodo * -1;
      this.saldoICMS = totalizadorImposto.valorIcmsSaldoAnterior - totalizadorImposto.valorIcmsPeriodo * -1 < 0 ? 0.0 : totalizadorImposto.valorIcmsSaldoAnterior - totalizadorImposto.valorIcmsPeriodo * -1;
      this.saldoPIS = totalizadorImposto.valorPisSaldoAnterior - totalizadorImposto.valorPisPeriodo * -1 < 0 ? 0.0 : totalizadorImposto.valorPisSaldoAnterior - totalizadorImposto.valorPisPeriodo * -1;
      this.saldoCofins = totalizadorImposto.valorCofinsSaldoAnterior - totalizadorImposto.valorCofinsPeriodo * -1 < 0 ? 0.0 : totalizadorImposto.valorCofinsSaldoAnterior - totalizadorImposto.valorCofinsPeriodo * -1;
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.mostrarTotalizador = this.definirRegra(relatorioAdicional, 218, this.mostrarTotalizador);
    this.mostrarEntradaSaidaDevolucao = this.definirRegra(relatorioAdicional, 219, this.mostrarEntradaSaidaDevolucao);
    this.mostrarNaturezaOperacao = this.definirRegra(relatorioAdicional, 220, this.mostrarNaturezaOperacao);

    if (this.mostrarEntradaSaidaDevolucao) {
      this.agrupar(this.movimentacaoProdutoAgrupados, { rotulo: this.bibDialogo.entradaSaidaDevolucao, campo: 'tipoDevolucao', id: '0' });
    } else {
      this.agrupar(this.movimentacaoProdutoAgrupados, { rotulo: this.bibDialogo.entradaSaida, campo: 'tipo', id: '0' });
    }
  }
}
