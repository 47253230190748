import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Transporte } from 'src/app/modelo/transporte';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Usuario } from 'src/app/modelo/usuario';
import filtros from './filtro';

@Component({
  templateUrl: './movimentacaoAbrir.component.html',
})
export class MovimentacaoAbrirComponent extends PaginaComponent {
  public componente = null;
  public filtros = filtros;
  public focoIdSenha: number;
  public idOperacao: number;
  public movimentacoes: Movimentacao[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    if (this.utilSessao.getUsuario().permitir != 'S') {
      criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().idColaborador));
    }
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    criterios.push(new Criterio('OPERACAO_USUARIO', 'S'));
    criterios.push(new Criterio('STATUS', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
    });
  }

  exigirSenha(): void {
    this.focoIdSenha = Math.random();
  }

  conferirSenha(chave: string): void {
    if (chave && chave.length == 6) {
      this.focoIdSenha = null;
      this.listarUsuarioChave(chave);
    }
  }

  listarUsuarioChave(chave: string): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('KEY', chave), new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().idColaborador)]), this.bibServico.usuario).subscribe((res) => {
      const usuario: Usuario = this.plainToClass(Usuario, res[0]) as any;
      if (usuario) {
        if (usuario.idColaborador == this.utilSessao.getUsuario().idColaborador) {
          this.persistirMovimentacao();
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.chaveNaoVinculadaAoResponsavel, [this.utilSessao.getUsuario().nome])));
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.chaveNaoVinculadaAoResponsavel, [this.utilSessao.getUsuario().nome])));
      }
    });
  }

  persistirMovimentacao(): void {
    const movimentacoes = this.movimentacoes.filter((movimentacao) => movimentacao.status == 'A');
    if (movimentacoes.length) {
      const transporte: Transporte = new Transporte(movimentacoes);
      super.persistir(transporte, this.bibServico.movimentacao, null).subscribe(() => {
        if (this.utilSessao.falha) {
          this.movimentacoes
            .filter((movimentacao) => movimentacao.status == 'A')
            .forEach((movimentacao) => {
              movimentacao.status = 'S';
            });
        }
        this.movimentacoes = this.movimentacoes.filter((movimentacao) => movimentacao.status == 'S');
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecioneUmItemLista));
    }
  }

  visualizar(id: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }
}
