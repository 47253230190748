import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { RomaneioEntrega } from 'src/app/modelo/romaneioEntrega';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './romaneioEntrega.component.html',
})
export class RomaneioEntregaComponent extends PaginaComponent {
  public romaneioEntregas: RomaneioEntrega[] = [];
  public filtros: Filtro[] = filtros;
  public rotuloGrupo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 193).apelido;
  public menuTransportadora: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 55);

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.romaneioEntrega).subscribe((res) => {
      this.romaneioEntregas = this.plainToClass(RomaneioEntrega, res) as any;
    });
  }

  excluirRomaneioEntrega(id: number, romaneioEntrega: string): void {
    super.excluir<RomaneioEntrega>(id, this.romaneioEntregas, romaneioEntrega);
  }

  abrirRomaneioEntrega(romaneioEntrega: RomaneioEntrega): void {
      this.listarRomaneioEntrega(romaneioEntrega.id).subscribe((res) => {
        let romaneioEntregaPersistir: RomaneioEntrega = this.plainToClass(RomaneioEntrega, res[0]) as any;
        if (confirm(this.bibDialogo.desejaAbrirRomaneioEntregaNumero + ' ' + romaneioEntrega.numero)) {
          romaneioEntregaPersistir.status = 'N';
          const transporte: Transporte = new Transporte(romaneioEntregaPersistir);
          super.persistir(transporte, this.bibServico.romaneioEntrega, null).subscribe(() => {
            if (!this.utilSessao.falha) {
              romaneioEntrega.status = 'N';
            } else {
              romaneioEntrega.status = 'S';
            }
          });
        }
      });
  }

  listarRomaneioEntrega(idRomaneioEntrega: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idRomaneioEntrega)), this.bibServico.romaneioEntrega);
  }
}
