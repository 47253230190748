import { Type } from 'class-transformer';

export class Safra {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public ativo: string = 'S';
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
}
