export class Resultado {
  public classe: string;
  public descricaoNerd: string;
  public id: number;
  public numero: number;
  public mensagem: string;
  public operacao: string;
  public sucesso: boolean;

  constructor(sucesso: boolean = null, mensagem: string = null, descricaoNerd: string = null) {
    this.sucesso = sucesso;
    this.mensagem = mensagem;
    this.descricaoNerd = descricaoNerd;
  }
}
