import { Component, Input } from '@angular/core';
import { PaginaComponent } from '../../utilitario/pagina.component';

@Component({
  selector: 'totalizador',
  templateUrl: './totalizador.component.html',
  styleUrls: ['./totalizador.component.css'],
})
export class TotalizadorComponent extends PaginaComponent {
  @Input() alinhamento: string = '';
  @Input() atencaoTotalizador: any;
  @Input() tipoAtencaoTotalizador: string;
  @Input() valorTotalizador: number;
  @Input() tipoTotalizador: string = this.bibPropriedade.cor.azul;
}
