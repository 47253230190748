<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10 clique" (click)="listar(add('dataDe'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataDe }}</th>
        <th [ngClass]="this.parametro == 3 ? 'l-80' : 'l-10'" class="clique" (click)="listar(add('dataDe'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataAte }}</th>
        <th *ngIf="this.parametro != 3" class="l-70 clique" (click)="listar(add('motivo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.motivo }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let bloqueioPeriodo of bloqueioPeriodos; let i = index">
        <tr [ngClass]="{ tachado: bloqueioPeriodo.ativo != 'S' }">
          <td>{{ bloqueioPeriodo.dataDe | data }}</td>
          <td>{{ bloqueioPeriodo.dataAte | data }}</td>
          <td *ngIf="this.parametro != 3" class="limitar">
            <a href="#" (click)="ir(bloqueioPeriodo.id, i, bloqueioPeriodos)">{{ bloqueioPeriodo.motivo }}</a>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(bloqueioPeriodo.id, i, bloqueioPeriodos)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirBloqueioPeriodo(bloqueioPeriodo.id, bloqueioPeriodo.motivo)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
