<td class="p-0" colspan="5">
  <table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.nome }}</th>
        <th class="r-4">{{ bibDialogo.telefone }}</th>
        <th class="r-1 vinculo">{{ bibDialogo.vinculo }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiroContato of parceiro.parceiroContatos">
        <tr>
          <td>{{ parceiroContato.nome }}</td>
          <td>{{ parceiroContato.telefone }}</td>
          <td>{{ parceiroContato.vinculo }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</td>
