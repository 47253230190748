import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r1009tiposTitulo',
  templateUrl: './r1009tiposTitulo.component.html',
})
export class R1009TiposTituloComponent {
  @Input() tiposTituloAgrupados: any;
  @Input() usaTipoTitulo: boolean;
  @Input() usaTaxaCartao: boolean;
  @Input() maisInformacoes: boolean;
  public bibDialogo = bibDialogo;
}
