import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Receituario } from 'src/app/agrow/modelo/receituario';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { bibServicoNs } from 'src/app/biblioteca/bibServicoNs';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { NfeNs } from 'src/app/integracao/ns/nfe/nfe';
import { NsSat } from 'src/app/integracao/ns/sat/nsSat';
import { BaseCalculo } from 'src/app/integracao/tecnospeed/nfe/baseCalculo';
import { CamposExtras } from 'src/app/integracao/tecnospeed/nfe/camposExtras';
import { Cobranca } from 'src/app/integracao/tecnospeed/nfe/cobranca';
import { Cofins } from 'src/app/integracao/tecnospeed/nfe/cofins';
import { Combustivel } from 'src/app/integracao/tecnospeed/nfe/combustivel';
import { CreditoSimplesNacional } from 'src/app/integracao/tecnospeed/nfe/creditoSimplesNacional';
import { Desoneracao } from 'src/app/integracao/tecnospeed/nfe/desoneracao';
import { Destinatario } from 'src/app/integracao/tecnospeed/nfe/destinatario';
import { Diferimento } from 'src/app/integracao/tecnospeed/nfe/diferimento';
import { Emitente } from 'src/app/integracao/tecnospeed/nfe/emitente';
import { Endereco } from 'src/app/integracao/tecnospeed/nfe/endereco';
import { Icms } from 'src/app/integracao/tecnospeed/nfe/icms';
import { Ipi } from 'src/app/integracao/tecnospeed/nfe/ipi';
import { Item } from 'src/app/integracao/tecnospeed/nfe/item';
import { Nfe } from 'src/app/integracao/tecnospeed/nfe/nfe';
import { NotaReferenciada } from 'src/app/integracao/tecnospeed/nfe/notaReferenciada';
import { NotaReferenciadaNfe } from 'src/app/integracao/tecnospeed/nfe/notaReferenciadaNfe';
import { Pagamento } from 'src/app/integracao/tecnospeed/nfe/pagamento';
import { Parcelas } from 'src/app/integracao/tecnospeed/nfe/parcelas';
import { Partilha } from 'src/app/integracao/tecnospeed/nfe/partilha';
import { Pis } from 'src/app/integracao/tecnospeed/nfe/pis';
import { Quantidade } from 'src/app/integracao/tecnospeed/nfe/quantidade';
import { ReenviarEmail } from 'src/app/integracao/tecnospeed/nfe/reenviarEmail';
import { ResponsavelAutorizado } from 'src/app/integracao/tecnospeed/nfe/responsavelAutorizado';
import { ResponsavelTecnico } from 'src/app/integracao/tecnospeed/nfe/responsavelTecnico';
import { SubstituicaoTributaria } from 'src/app/integracao/tecnospeed/nfe/substituicaoTributaria';
import { Telefone } from 'src/app/integracao/tecnospeed/nfe/telefone';
import { Total } from 'src/app/integracao/tecnospeed/nfe/total';
import { TransporteTecnoSpeed } from 'src/app/integracao/tecnospeed/nfe/transporteTecnoSpeed';
import { Tributo } from 'src/app/integracao/tecnospeed/nfe/tributo';
import { UfDestino } from 'src/app/integracao/tecnospeed/nfe/ufDestino';
import { Unidade } from 'src/app/integracao/tecnospeed/nfe/unidade';
import { ValorUnitario } from 'src/app/integracao/tecnospeed/nfe/valorUnitario';
import { Volume } from 'src/app/integracao/tecnospeed/nfe/volume';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Empresa } from 'src/app/modelo/empresa';
import { Estado } from 'src/app/modelo/estados';
import { GrupoIcms } from 'src/app/modelo/grupoIcms';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoCancelamento } from 'src/app/modelo/movimentacaoCancelamento';
import { MovimentacaoCartaCorrecao } from 'src/app/modelo/movimentacaoCartaCorrecao';
import { MovimentacaoChave } from 'src/app/modelo/movimentacaoChave';
import { MovimentacaoNfe } from 'src/app/modelo/movimentacaoNfe';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { NcmEmpresa } from 'src/app/modelo/ncmEmpresa';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroTelefone } from 'src/app/modelo/parceiroTelefone';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoIcms } from 'src/app/modelo/produtoIcms';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { CancelamentoFrmComponent } from '../cancelamento/cancelamentoFrm.component';
import { CartaCorrecaoComponent } from '../cartaCorrecao/cartaCorrecao.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import filtros from './filtro';
import { UtilDocumentoEletronico } from './utilDocumentoEletronico';
import { UtilDocumentoEletronicoNs } from './utilDocumentoEletronicoNs';
import { UtilDocumentoEletronicoNsSat } from './utilDocumentoEletronicoNsSat';

@Component({
  templateUrl: './documentoEletronico.component.html',
})
export class DocumentoEletronicoComponent extends PaginaComponent {
  public bibServicoNs: bibServicoNs = new bibServicoNs();
  private emiteEmContingencia: boolean = false;
  private idsMovimentacaoPersistir: number[];
  private modalEmt: any;
  private movimentacoesPersistir: Movimentacao[];
  private svcAn: string[] = ['AC', 'AL', 'AP', 'DF', 'ES', 'MG', 'PA', 'PB', 'PI', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];
  private svcRs: string[] = ['AM', 'BA', 'CE', 'GO', 'MA', 'MS', 'MT', 'PE', 'PR'];
  public bibServicoTecnoSpeed: bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public componente: any;
  public contagemMovimentacaoCancelar: number = 0;
  public dataAtualizacao: Date;
  public empresaRegraEmitirNfeContingencia: number = 1;
  public empresaReceituario: string = 'N';
  public filtros = filtros;
  public informacaoFiscal: any[] = [];
  public intervaloAtualizarNfe: any;
  public movimentacoes: Movimentacao[] = [];
  public negociacoes: Negociacao[] = [];
  public receituarios: Receituario[] = [];
  public operacao: string;
  public servico: string;
  public informacaoComplementarProduto: string;
  public usaNfe: boolean;
  public usaSat: boolean = false;
  public ehConsumidorFinalNfce: boolean = false;
  public valorMovimentacaoTotalizado: number = 0;
  public utilDocumentoEletronico = new UtilDocumentoEletronico(this.comunicacaoService, this.utilSessao);
  private informacoesComplementares: string;
  public enviarIbpt: boolean = true;
  public dataAtual: Date = new Date();
  public pesquisaTodasOperacoes: boolean = false;
  public lojaMovimentacao = new Loja();

  public finalidades: any[] = [];

  public finalidadesDevolucao: any[] = [{ id: '4', nome: this.bibDialogo.devolucao }];
  public criterios: Criterio[];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.movimentacoes = [];
      this.operacao = params['id'];
      switch (this.operacao) {
        case 'nf-e':
          this.usaNfe = true;
          this.servico = 'nfe';
          break;
        case 'sat':
          this.usaNfe = false;
          this.usaSat = true;
          this.servico = 'sat';
          break;
        case 'nfc-e':
          this.usaNfe = false;
          this.servico = 'nfce';
          break;
      }
      this.finalidade(this.servico);
    });

    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.movimentacaoCancelamento) {
        const movimentacaoCancelamento: MovimentacaoCancelamento = this.plainToClass(MovimentacaoCancelamento, identificacao.conteudo);
        const movimentacao: Movimentacao = this.movimentacoes.find((movimentacao) => movimentacao.id == movimentacaoCancelamento.idMovimentacao);
        movimentacao.ativo = 'N';
        movimentacao.movimentacaoCancelamento = movimentacaoCancelamento;
        this.persistirCancelamento(movimentacao);
      }
    });
    this.ehListarAtalhoMovimentacao();
    clearInterval(this.intervaloAtualizarNfe);
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
    clearInterval(this.intervaloAtualizarNfe);
  }

  ehListarAtalhoMovimentacao(): void {
    const idMovimentacao: number = this.utilSessao.getIdentificacao('idMovimentacao')?.conteudo;
    if (idMovimentacao) {
      this.listar([new Criterio('DESCONSIDERAR_NFE', 'S')], 0, 0, [idMovimentacao]);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, idMovimentacoes: number[] = []): void {
    this.ehCriterioEntrada(criterios);
    clearInterval(this.intervaloAtualizarNfe);
    this.contagemMovimentacaoCancelar = 0;
    criterios.push(new Criterio('CONFIRMADA_INATIVA', 'S'));
    criterios.push(new Criterio('PERMITE_TRANSMITIR_NFE', 'S'));
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    criterios.push(new Criterio('EMISSAO_PROPRIA', 'S'));
    if (idMovimentacoes.length > 0) {
      criterios.push(new Criterio('IDS', idMovimentacoes.toString()));
    }
    if (this.usaNfe) {
      criterios.push(new Criterio('NFE', 'S'));
    } else {
      if (this.usaSat) {
        criterios.push(new Criterio('SAT', 'S'));
      } else {
        criterios.push(new Criterio('NFCE', 'S'));
      }
    }
    this.criterios = criterios;
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      this.dataAtualizacao = new Date();
      if (this.movimentacoes.length > 0) {
        this.totalizarMovimentacoes();
        this.listarEmpresa(this.movimentacoes[0].idEmpresa).subscribe((res) => {
          const empresa: Empresa = this.plainToClass(Empresa, res[0]) as any;
          this.empresaRegraEmitirNfeContingencia = empresa.regraEmitirNfeContingencia;
          this.empresaReceituario = empresa.receituario;
          this.listarMovimentacaoNfe(this.movimentacoes.map((movimentacao) => movimentacao.id));
          this.listarNegociacoes(this.movimentacoes.filter((movimentacao) => movimentacao.idNegociacao != null).map((movimentacao) => movimentacao.idNegociacao));
        });
        this.verificarSePesquisouTodasOperacoes();
      }
      this.listarContagemMovimentacaoNfeCancelar(criterios);
    });

    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', () => {
      abaAtiva = abaAtiva == true ? false : true;
    });

    setInterval(() => {
      if (abaAtiva) {
        this.dataAtualizacao = new Date();
        this.atualizarNfe();
      }
    }, 30000);
  }


  verificarSePesquisouTodasOperacoes(): void {
    const idOperacaoPrimeiraMovimentacao: number = this.movimentacoes[0].idOperacao;
    this.pesquisaTodasOperacoes = this.movimentacoes.filter((movimentacao) => movimentacao.idOperacao != idOperacaoPrimeiraMovimentacao).length > 0;
  }

  totalizarMovimentacoes(): void {
    this.valorMovimentacaoTotalizado = 0;
    for (let movimentacao of this.movimentacoes) {
      this.valorMovimentacaoTotalizado += movimentacao.valorTotal;
    }
  }

  finalidade(tipo: String) {
    if (tipo == 'nfe') {
      this.finalidades = [
        { id: '1', nome: this.bibDialogo.normal },
        { id: '2', nome: this.bibDialogo.complementar },
        { id: '3', nome: this.bibDialogo.ajuste },
      ];
    }
    if (tipo == 'nfce' || tipo == 'sat') {
      this.finalidades = [{ id: '1', nome: this.bibDialogo.normal }];
    }
  }

  ehCriterioEntrada(criterios: Criterio[]): void {
    const criterioOperacao: Criterio = criterios.find((criterio) => criterio.nome == 'ID_OPERACAO');
    if (criterioOperacao && criterioOperacao.valor == 8) {
      criterios.push(new Criterio('TIPO_EMISSAO', 2));
    }
  }

  listarContagemMovimentacaoNfeCancelar(criterios: Criterio[]): void {
    criterios = criterios.filter((criterio) => criterio.nome == 'ID_LOJA' || criterio.nome == 'ID_OPERACAO');
    criterios.push(new Criterio('MOVIMENTACAO_NFE_CANCELAR', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacao).subscribe((res) => {
      const movimentacaoComNfeCanceladas: Movimentacao[] = this.plainToClass(Movimentacao, res) as any;
      this.contagemMovimentacaoCancelar = movimentacaoComNfeCanceladas.length;
    });
  }

  private listarEmpresa(idEmpresa: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idEmpresa)), this.bibServico.empresa);
  }

  private listarMovimentacaoNfe(idMovimentacoes: number[]): void {
    let criterios: Criterio = new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString());
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoNfe).subscribe((res) => {
      this.vincularMovimentacaoMovimentacaoNfe(this.plainToClass(MovimentacaoNfe, res) as any);
      this.listarMovimentacaoCancelamento();
      this.listarMovimentacaoCartaCorrecao(idMovimentacoes);
      this.listarMovimentacaoChaves(idMovimentacoes);
      this.movimentacoes.forEach((movimentacao) => {
        movimentacao.movimentacaoNfe.aCancelar = movimentacao.ativo != 'S' && movimentacao.movimentacaoNfe?.tecnospeedCancelamento != 'S' && movimentacao.movimentacaoNfe?.situacaoCancelado != 'S';
      })
    });
  }

  private listarNegociacoes(idNegociacoes: number[]): void {
    let criterio: Criterio = new Criterio('IDS', idNegociacoes && idNegociacoes.length > 0 ? idNegociacoes.toString() : 0);
    this.comunicacaoService.listar(new Transporte(criterio), this.bibServico.negociacao).subscribe((res) => {
      this.negociacoes = this.plainToClass(Negociacao, res) as any;
    });
  }

  private vincularMovimentacaoMovimentacaoNfe(movimentacoesNfes: MovimentacaoNfe[]): void {
    this.movimentacoes.forEach((movimentacao) => {
      const movimentacaoNfe: MovimentacaoNfe = movimentacoesNfes.find((movimentacoesNfe) => movimentacoesNfe.idMovimentacao == movimentacao.id);
      if (movimentacaoNfe) {
        movimentacao.movimentacaoNfe = movimentacaoNfe;
      } else {
        movimentacao.movimentacaoNfe = new MovimentacaoNfe(movimentacao.id, movimentacao.idLoja, 0);
      }
    });
  }

  private listarMovimentacaoCancelamento(): void {
    let idMovimentacoes: number[] = [];
    if (this.utilSessao.getEmpresa().integracaoNs == 'S') {
      this.movimentacoes.forEach((movimentacao) => {
        if (movimentacao.ativo != 'S') {
          idMovimentacoes.push(movimentacao.id);
        }
      });
    } else {
      this.movimentacoes.forEach((movimentacao) => {
        if (movimentacao.ativo != 'S' && movimentacao.movimentacaoNfe.status != -2 && movimentacao.movimentacaoNfe.situacaoCancelado != 'S') {
          idMovimentacoes.push(movimentacao.id);
        }
      });
    }
    if (idMovimentacoes.length > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString())), this.bibServico.movimentacaoCancelamento).subscribe((res) => {
        this.vincularMovimentacaoMovimentacaoCancelamento(this.plainToClass(MovimentacaoNfe, res) as any);
      });
    }
  }

  private vincularMovimentacaoMovimentacaoCancelamento(movimentacoesCancelamentos: MovimentacaoCancelamento[]): void {
    this.movimentacoes.forEach((movimentacao) => {
      const movimentacaoCancelamento: MovimentacaoCancelamento = movimentacoesCancelamentos.find((movimentacaoCancelamentoBusca) => movimentacaoCancelamentoBusca.idMovimentacao == movimentacao.id);
      if (movimentacaoCancelamento) {
        movimentacao.movimentacaoCancelamento = movimentacaoCancelamento;
      }
    });
  }

  private listarMovimentacaoCartaCorrecao(idMovimentacoes: number[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString())), this.bibServico.movimentacaoCartaCorrecao).subscribe((res) => {
      this.vincularMovimentacaoNfeMovimentacaoCartaCorrecao(this.plainToClass(MovimentacaoCartaCorrecao, res) as any);
    });
  }

  vincularMovimentacaoNfeMovimentacaoCartaCorrecao(movimentacaoCartaCorrecoes: MovimentacaoCartaCorrecao[]): void {
    movimentacaoCartaCorrecoes.forEach((movimentacaoCartaCorrecao) => {
      const movimentacao: Movimentacao = this.movimentacoes.find((movimentacaoBusca) => movimentacaoBusca.id == movimentacaoCartaCorrecao.idMovimentacao);
      movimentacao.movimentacaoNfe.movimentacaoCartaCorrecoes.push(movimentacaoCartaCorrecao);
    });
  }

  private listarMovimentacaoChaves(idMovimentacoes: number[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString())), this.bibServico.movimentacaoChave).subscribe((res) => {
      this.vincularMovimentacaoChaves(this.plainToClass(MovimentacaoChave, res) as any);
    });
  }

  vincularMovimentacaoChaves(MovimentacaoChaves: MovimentacaoChave[]): void {
    MovimentacaoChaves.forEach((movimentacaoChave) => {
      const movimentacao: Movimentacao = this.movimentacoes.find((movimentacaoBusca) => movimentacaoBusca.id == movimentacaoChave.idMovimentacao);
      if (movimentacao) {
        movimentacao.movimentacaoChaves.push(movimentacaoChave);
      }
    });
  }

  setMovimentacaoSelecionado(movimentacao: Movimentacao): void {
    movimentacao.selecionado = movimentacao.selecionado == 'S' ? 'N' : 'S';
    if (movimentacao.selecionado == 'S') {
      movimentacao.movimentacaoNfe.finalidade = movimentacao.naturezaOperacaoFinalidade == 1 || movimentacao.naturezaOperacaoFinalidade == 2 ? '1' : movimentacao.naturezaOperacaoFinalidade == 3 ? '4' : movimentacao.naturezaOperacaoFinalidade == 4 ? '2' : movimentacao.naturezaOperacaoFinalidade == 5 ? '3' : '1';
    } else {
      movimentacao.movimentacaoNfe.finalidade = null;
    }
  }

  atualizarNfe(movimentacaoNfes: MovimentacaoNfe[] = []): void {
    if (movimentacaoNfes.length == 0) {
      this.movimentacoes.forEach((movimentacao) => {
        if (movimentacao.movimentacaoNfe && movimentacao.movimentacaoNfe.situacaoEmAndamento == 'S') {
          movimentacaoNfes.push(movimentacao.movimentacaoNfe);
        }
      });
    }
    if (movimentacaoNfes.length > 0) {
      let idMovimentacoes: number[] = movimentacaoNfes.filter((movimentacaoNfe) => movimentacaoNfe.id != null).map((movimentacaoNfe) => movimentacaoNfe.idMovimentacao);
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.length > 0 ? idMovimentacoes.toString() : 0)), this.bibServico.movimentacaoNfe, true, true, false).subscribe((res) => {
        const movimentacaoNfeBuscas: MovimentacaoNfe[] = this.plainToClass(MovimentacaoNfe, res) as any;
        movimentacaoNfes.forEach((movimentacaoNfe) => {
          const movimentacaoNfeBusca: MovimentacaoNfe = movimentacaoNfeBuscas.find((movimentacaoNfeBusca) => movimentacaoNfeBusca.idMovimentacao == movimentacaoNfe.idMovimentacao);
          this.vincularMovimentacaoNfe(movimentacaoNfe, movimentacaoNfeBusca);
        });
        this.dataAtualizacao = new Date();
      });
    }
  }

  vincularMovimentacaoNfe(movimentacaoNfe: MovimentacaoNfe, movimentacaoNfeBusca: MovimentacaoNfe): void {
    movimentacaoNfe.status = movimentacaoNfeBusca.status;
    movimentacaoNfe.statusDescricao = this.verificarDescricao(movimentacaoNfeBusca.statusDescricao);
    movimentacaoNfe.chave = movimentacaoNfeBusca.chave;
    movimentacaoNfe.protocolo = movimentacaoNfeBusca.protocolo;
    movimentacaoNfe.dataHora = movimentacaoNfeBusca.dataHora;
    movimentacaoNfe.tecnospeedCancelamento = movimentacaoNfeBusca.tecnospeedCancelamento;
    movimentacaoNfe.situacaoEmAndamento = movimentacaoNfeBusca.situacaoEmAndamento;
    movimentacaoNfe.situacaoNaoAtendido = movimentacaoNfeBusca.situacaoNaoAtendido;
    movimentacaoNfe.situacaoAutorizado = movimentacaoNfeBusca.situacaoAutorizado;
    movimentacaoNfe.situacaoCancelado = movimentacaoNfeBusca.situacaoCancelado;
    movimentacaoNfe.consultarNfe = movimentacaoNfeBusca.consultarNfe;
  }

  consultarNfe(movimentacao: Movimentacao, desistindoCancelamento: boolean = false): void {
    if (this.utilSessao.getEmpresa().integracaoNs == 'S') {
      this.consultarNfeNs(movimentacao);
    } else {
      this.consultarNfeTecnospeed(movimentacao, desistindoCancelamento);
    }
  }

  consultarNfeTecnospeed(movimentacao: Movimentacao, desistindoCancelamento: boolean = false): void {
    this.utilSessao.iniciarProcesso();
    const cnpjEmitente: string = this.util.retirarCaracteresInvalidos(this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja).cnpj);
    let idTecnospeed: string;
    let idIntegracao: string;
    idIntegracao = movimentacao.id.toString() + 'aGrow';
    this.comunicacaoTecnoSpeedService.listar(this.bibServicoTecnoSpeed.getUrlConsultaResumoNfe(this.servico, idTecnospeed, cnpjEmitente, idIntegracao), null).subscribe(
      (res) => {
        let criterios: Criterio[] = [];
        criterios.push(new Criterio('ID_MOVIMENTACAO', idIntegracao ? idIntegracao.replace('aGrow', '') : null));
        criterios.push(new Criterio('ID_TECNOSPEED', idTecnospeed));
        this.listarMovimentacaoNfeConfirmacao(criterios).subscribe((resposta) => {
          movimentacao.movimentacaoNfe.situacaoNaoAtendido = 'N';
          movimentacao.movimentacaoNfe.situacaoEmAndamento = 'N';
          movimentacao.movimentacaoNfe.tecnospeedCancelamento = 'N';
          const movimentacaoNfe = this.plainToClass(MovimentacaoNfe, resposta[0]) as any;
          movimentacao.movimentacaoNfe.idTecnospeed = res[0].id;
          movimentacao.movimentacaoNfe.status = res[0].cStat ? res[0].cStat : 0;
          movimentacao.movimentacaoNfe.statusDescricao = this.verificarDescricao(res[0].mensagem);
          movimentacao.serie = res[0].serie;
          movimentacao.movimentacaoNfe.protocolo = res[0].protocolo ? res[0].protocolo : null;
          movimentacao.movimentacaoNfe.dataHora = res[0].dataAutorizacao ? res[0].dataAutorizacao : res[0].emissao;
          if (res[0].status == 'REJEITADO') {
            movimentacao.movimentacaoNfe.situacaoNaoAtendido = 'S';
          }
          if (res[0].status == 'CANCELADO') {
            movimentacao.movimentacaoNfe.tecnospeedCancelamento = 'S';
            movimentacao.movimentacaoNfe.protocoloCancelamento = res[0].protocoloCancelamento ? res[0].protocoloCancelamento : null;
          }
          if (movimentacao.movimentacaoNfe.protocolo != null) {
            movimentacao.numero = res[0].numero;
            movimentacao.documentoFiscal = res[0].numero;
            movimentacao.chaveFiscal = res[0].chave;
            movimentacao.modelo = movimentacao.chaveFiscal.substring(20, 22);
            movimentacao.movimentacaoNfe.numeroFiscal = res[0].numero;
          }
          this.persistirMovimentacaoNfe([movimentacao]).subscribe(() => {
            if (!this.utilSessao.falha) {
              if (movimentacao.chaveFiscal != null) {
                movimentacao.movimentacaoProdutos = [];
                movimentacao.movimentacaoParcelas = [];
                movimentacao.atualizandoConsultaNfe = 'S';
                if (desistindoCancelamento == false || (desistindoCancelamento && res[0].status == 'CANCELADO')) {
                  this.persistirMovimentacao([movimentacao]).subscribe(() => {
                    this.utilSessao.finalizarProcesso();
                  });
                } else {
                  if (desistindoCancelamento) {
                    if (res[0].status == 'CANCELADO') {
                      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelDesistirCancelamento));
                      this.listar(this.criterios, this.utilSessao.posicao, this.paginacao);
                    } else {
                      this.desistirCancelamento(movimentacao);
                    }
                  }
                }
              } else {
                this.utilSessao.finalizarProcesso();
              }
            }
          });
        });
      },
      (erro) => {
        this.utilSessao.finalizarProcesso();
        if (erro.status == 404 && movimentacao.movimentacaoNfe.idTecnospeed == null) {
          movimentacao.consultandoNfeNaoTransmitida = 'S';
          movimentacao.selecionado = 'S';
          this.iniciarProcessamentoNfe();
        }
      }
    );
  }

  listarMovimentacaoNfeConfirmacao(criterios: Criterio[]): Observable<MovimentacaoNfe> {
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoNfe);
  }

  persistirCancelamento(movimentacao: Movimentacao): void {
    movimentacao.movimentacaoNfe.status = -2;
    if (this.usaSat) {
      const utilDocumentoEletronicoNsSat = new UtilDocumentoEletronicoNsSat(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
      utilDocumentoEletronicoNsSat.gerarCancelamentoUltimaVenda(movimentacao.chaveFiscal);
      movimentacao.movimentacaoNfe.statusDescricao = "CANCELADO";
      movimentacao.movimentacaoNfe.situacaoCancelado = 'S';
      movimentacao.movimentacaoNfe.situacaoAutorizado = 'N';
      this.persistirMovimentacaoNfe([movimentacao]);
    } else {
      if (this.utilSessao.getEmpresa().integracaoNs == 'N') {
        this.persistirMovimentacaoNfe([movimentacao]).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.comunicacaoTecnoSpeedService.persistir(this.bibServicoTecnoSpeed.getUrlCancelaNfe(this.servico, movimentacao.movimentacaoNfe.idTecnospeed), movimentacao.movimentacaoCancelamento.motivo).subscribe((res) => {
              if (res.message == bibDialogo.cancelamentoEmProcessamento) {
                movimentacao.movimentacaoNfe.tecnospeedCancelamento = 'S';
                this.persistirMovimentacaoNfe([movimentacao]).subscribe();
              }
            });
          }
          this.contagemMovimentacaoCancelar = this.contagemMovimentacaoCancelar > 0 ? this.contagemMovimentacaoCancelar - 1 : this.contagemMovimentacaoCancelar;
        });
      } else {
        const utilDocumentoEletronicoNs = new UtilDocumentoEletronicoNs(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
        utilDocumentoEletronicoNs.cancelarNfe(movimentacao.movimentacaoNfe, movimentacao.chaveFiscal, movimentacao.movimentacaoNfe.protocolo, movimentacao.movimentacaoCancelamento.motivo, movimentacao.idLoja);
      }
    }
  }

  consultarCancelamento(movimentacao: Movimentacao): void {
    this.comunicacaoTecnoSpeedService.listar(this.bibServicoTecnoSpeed.getUrlConsultaCancelamentoNfe(this.servico, movimentacao.movimentacaoNfe.idTecnospeed), null).subscribe((res) => {
      movimentacao.movimentacaoNfe.status = res.data.cStat ? res.data.cStat : movimentacao.movimentacaoNfe.status;
      movimentacao.movimentacaoNfe.protocoloCancelamento = res.data.protocolo;
      movimentacao.movimentacaoNfe.statusDescricao = this.verificarDescricao(res.data.respostaSefaz);
      movimentacao.movimentacaoNfe.tecnospeedCancelamento = 'S';
      if (movimentacao.movimentacaoNfe.protocoloCancelamento != null || res.data.status == 'REJEITADO') {
        this.persistirMovimentacaoNfe([movimentacao]).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.atualizarNfe([movimentacao.movimentacaoNfe]);
          }
        });
      }
    });
  }

  desistirCancelamento(movimentacao: Movimentacao): void {
    if (confirm(this.bibDialogo.desejaDesistirCancelamento)) {
      movimentacao.status = 'S';
      this.listarMovimentacaoProduto(movimentacao);
    } else {
      this.utilSessao.finalizarProcesso();
    }
  }

  listarMovimentacaoProduto(movimentacao: Movimentacao): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', movimentacao.id)), this.bibServico.movimentacaoProduto).subscribe((res) => {
      movimentacao.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      this.listarQuantidades(movimentacao);
    });
  }

  listarQuantidades(movimentacao: Movimentacao): void {
    let idsMovimentacaoPro: number[] = movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO_PRO', idsMovimentacaoPro.toString())), this.bibServico.movimentacaoQuantidade).subscribe((res) => {
      let movimentacaoQuantidades: MovimentacaoQuantidade[] = this.plainToClass(MovimentacaoQuantidade, res) as any;
      this.vincularMovimentacaoQuantidade(movimentacao.movimentacaoProdutos, movimentacaoQuantidades);
      this.listarReceituario(movimentacao);
    });
  }

  vincularMovimentacaoQuantidade(movimentacaoProdutos: MovimentacaoProduto[], movimentacaoQuantidades: MovimentacaoQuantidade[]): void {
    for (let movimentacaoProduto of movimentacaoProdutos) {
      movimentacaoProduto.movimentacaoQuantidades = [];
      for (let movimentacaoQuantidade of movimentacaoQuantidades) {
        if (movimentacaoProduto.id == movimentacaoQuantidade.idMovimentacaoProduto) {
          movimentacaoProduto.movimentacaoQuantidades.push(movimentacaoQuantidade);
        }
      }
    }
  }

  listarReceituario(movimentacao: Movimentacao): void {
    let idsMovimentacaoPro: number[] = movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
    if (idsMovimentacaoPro.length > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO_PRO', idsMovimentacaoPro.toString())), this.bibServico.receituario).subscribe((res) => {
        let receituarios: Receituario[] = this.plainToClass(Receituario, res) as any;
        this.vincularReceituario(movimentacao.movimentacaoProdutos, receituarios);
        const transporte: Transporte = new Transporte(movimentacao);
        super.persistir(transporte, this.bibServico.movimentacao, null).subscribe(() => {
          this.listar(this.criterios, this.utilSessao.posicao, this.paginacao);
          this.utilSessao.finalizarProcesso();
        });
      });
    }
  }

  vincularReceituario(movimentacaoProdutos: MovimentacaoProduto[], receituarios: Receituario[]): void {
    for (let movimentacaoProduto of movimentacaoProdutos) {
      movimentacaoProduto.receituario = null;
      for (let receituario of receituarios) {
        if (movimentacaoProduto.id == receituario.idMovimentacaoPro) {
          movimentacaoProduto.receituario = receituario;
        }
      }
    }
  }

  verificarDescricao(descricao: string): string {
    if (descricao && descricao.length > 1000) {
      return descricao.substring(0, 997) + '...';
    }
    return descricao;
  }

  persistirMovimentacaoNfe(movimentacoes: Movimentacao[]): Observable<any> {
    let movimentacaoNfes: MovimentacaoNfe[] = movimentacoes.map((movimentacao) => movimentacao.movimentacaoNfe);
    const INTEGRACAO_NS = this.utilSessao.getEmpresa().integracaoNs == 'S';
    movimentacaoNfes.forEach((movimentacaoNfe) => {
      this.informacaoFiscal.forEach((informacaoFiscal) => {
        movimentacaoNfe.idMovimentacao == informacaoFiscal.id ? (movimentacaoNfe.informacaoComplementar = informacaoFiscal.informacaoFiscal) : '';
        if (INTEGRACAO_NS) {
          movimentacaoNfe.idTecnospeed = null;
        }
      });
    });
    const resposta: Observable<any> = this.persistir(new Transporte(movimentacaoNfes), this.bibServico.movimentacaoNfe, null);
    resposta.subscribe((res) => {
      if (!this.utilSessao.falha) {
        this.atualizarNfe(movimentacaoNfes);
      }
    });
    return resposta;
  }

  persistirMovimentacao(movimentacoes: Movimentacao[]): Observable<any> {
    return this.persistir(new Transporte(movimentacoes), this.bibServico.movimentacao, null);
  }

  iniciarProcessamentoNfe(emiteEmContingencia: boolean = false): void {
    if (this.utilSessao.getEmpresa().integracaoNs != 'S') {
      const ids = this.movimentacoes.filter((movimentacao) => movimentacao.selecionado == 'S' || movimentacao.consultandoNfeNaoTransmitida == 'S').map((movimentacao) => movimentacao.id);
      if (ids.length > 0) {
        this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO', ids.toString())), this.bibServico.movimentacaoNfe).subscribe((res) => {
          const movimentacoesNfePersistidas = this.plainToClass(MovimentacaoNfe, res) as any;
          const AGUARDANDO: number = -1;
          let nfeEnviada: boolean = false;
          let nomeParceiroNotaEnviada: string = '';
          movimentacoesNfePersistidas.forEach((movimentacaoNfePersistida) => {
            if (movimentacaoNfePersistida.status == AGUARDANDO && movimentacaoNfePersistida.idTecnospeed != null) {
              nfeEnviada = true;
              let nomeParceiro = this.movimentacoes.find((movimentacao) => movimentacao.id == movimentacaoNfePersistida.idMovimentacao).nomeParceiro;
              nomeParceiroNotaEnviada = nomeParceiroNotaEnviada + '\n' + nomeParceiro + '\n';
            }
          });
          if (nfeEnviada == false) {
            this.movimentacoesPersistir = this.movimentacoes.filter((movimentacao) => (movimentacao.selecionado == 'S' && movimentacao.movimentacaoNfe.situacaoEmAndamento != 'S') || movimentacao.consultandoNfeNaoTransmitida == 'S');
            if (this.movimentacoesPersistir.length > 0) {
              this.emiteEmContingencia = emiteEmContingencia;
              this.utilSessao.iniciarProcesso();
              this.idsMovimentacaoPersistir = this.movimentacoesPersistir.map((movimentacao) => movimentacao.id);
              this.utilDocumentoEletronico.iniciar(this.movimentacoesPersistir).subscribe(() => {
                this.criarMovimentacaoNfe();
              });
            } else {
              this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
            }
          } else {
            if (confirm(this.util.substituir(this.bibDialogo.notasJaEnviadas, [nomeParceiroNotaEnviada]))) {
              const idMovimentacoes: number[] = this.movimentacoes.map((movimentacao) => movimentacao.id);
              this.listar([], 0, 0, idMovimentacoes);
            }
          }
        });
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
      }
    } else {
      this.movimentacoesPersistir = this.movimentacoes.filter((movimentacao) => (movimentacao.selecionado == 'S' && movimentacao.movimentacaoNfe.situacaoEmAndamento != 'S') || movimentacao.consultandoNfeNaoTransmitida == 'S');
      if (this.movimentacoesPersistir.length > 0) {
        this.utilSessao.iniciarProcesso();
        this.utilDocumentoEletronico.iniciar(this.movimentacoes).subscribe(() => {
          this.criarMovimentacaoNfe();
          this.utilSessao.finalizarProcesso();
        });
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
      }
    }
  }

  ehEnviarEmail(idParceiro: number, idTecnospeed: string, chaveFiscal: string, idLoja: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiro)), this.bibServico.parceiro).subscribe((res) => {
      const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
      if (parceiro && parceiro.email) {
        if (confirm(this.util.substituir(this.bibDialogo.enviarNotaFiscalParaCliente, [parceiro.email]))) {
          if (idTecnospeed) {
            this.enviarEmail(parceiro.email.replace(' ', ''), idTecnospeed);
          } else {
            const utilDocumentoEletronicoNs = new UtilDocumentoEletronicoNs(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
            utilDocumentoEletronicoNs.enviarEmail(chaveFiscal, parceiro.email.replace(' ', ''), idLoja);
          }
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroNaoPossuiEmailCadastrado));
      }
    });
  }

  enviarEmail(emails: string, idTecnospeed: string): void {
    let destinatarios: string[] = this.adicionarDestinario(emails);
    destinatarios.forEach((destinatario) => {
      let reenviarEmail: ReenviarEmail = new ReenviarEmail();
      reenviarEmail.destinatarios.push(destinatario);
      this.comunicacaoTecnoSpeedService.persistir(this.bibServicoTecnoSpeed.getUrlEnviarEmail(this.servico, idTecnospeed), reenviarEmail).subscribe(
        (res) => {
          this.utilSessao.setResultado(new Resultado(true, res.mensagem));
        },
        (erro) => {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelReenviarEmail));
        }
      );
    });
  }

  private adicionarDestinario(emails: string): string[] {
    let destinatarios: string[] = [];
    if (emails && emails.length > 0) {
      if (emails.split(';').length > 0) {
        emails.split(';').forEach((email) => {
          destinatarios.push(email.replace(' ', ''));
        });
      } else {
        destinatarios.push(emails.replace(' ', ''));
      }
    }
    return destinatarios;
  }

  private criarMovimentacaoNfe(): void {
    if (this.ehValidoMovimentacaoProduto() && this.ehValidoConsumidorFinal()) {
      this.configurarMovimentacao();
      if (this.usaSat) {
        this.emitirSatNs();
      } else {
        if (this.utilSessao.getEmpresa().integracaoNs != 'S') {
          if (this.utilSessao.getUsuario()?.agrow != 'S') {
            let movimentacaoNfesPersistir: MovimentacaoNfe[] = this.movimentacoesPersistir.map((movimentacao) => movimentacao.movimentacaoNfe);
            movimentacaoNfesPersistir.forEach((movimentacaoNfe) => (movimentacaoNfe.modeloNotaFiscal = this.usaNfe ? 55 : 65));
            this.persistir(new Transporte(movimentacaoNfesPersistir), this.bibServico.movimentacaoNfe, null).subscribe((res) => {
              if (!this.utilSessao.falha) {
                movimentacaoNfesPersistir.forEach((movimentacaoNfe) => (movimentacaoNfe.id = null));
                this.utilSessao.getResultados().forEach((resultado) => (resultado.classe == this.bibClasse.movimentacaoNfe ? (resultado.operacao = 'I') : ''));
                this.definirId(movimentacaoNfesPersistir, this.bibClasse.movimentacaoNfe);
                this.emitirNfe();
              } else {
                this.utilSessao.finalizarProcesso();
                this.movimentacoesPersistir.forEach((movimentacao) => {
                  movimentacao.movimentacaoNfe.status = 0;
                  movimentacao.selecionado = 'N';
                  movimentacao.movimentacaoNfe.situacaoEmAndamento = 'N';
                });
                this.movimentacoesPersistir = [];
              }
            });
          } else {
            this.emitirNfe();
          }
        } else {
          this.emitirNfeNs();
        }
      }
    } else {
      this.utilSessao.finalizarProcesso();
    }
  }

  ehValidoMovimentacaoProduto(): boolean {
    const movimentacaoPersistirIds: number[] = this.movimentacoesPersistir.map((movimentacaoPersistir) => movimentacaoPersistir.id);
    const movimentacaoNfeItens: MovimentacaoProduto[] = this.utilDocumentoEletronico.movimentacaoProdutos.filter((movimentacaoProdutoBusca) => movimentacaoPersistirIds.includes(movimentacaoProdutoBusca.idMovimentacao));
    if (movimentacaoNfeItens.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.movimentacaoSemItens));
    return false;
  }

  ehConsumidorFinal(): boolean {
    return this.utilDocumentoEletronico.parceiros.filter((parceiro) => parceiro.contribuinte == 1).length == 0;
  }

  ehValidoConsumidorFinal(): boolean {
    const idConsumidorFinal: number = this.utilSessao.getEmpresa().idConsumidorFinal;
    let validoNfe: boolean = true;
    let validoNfce: boolean = true;
    if (this.servico != 'nfce') {
      for (let movimentacao of this.movimentacoesPersistir) {
        if (movimentacao.idParceiro == idConsumidorFinal) {
          validoNfe = false;
        }
      }
    }
    if (this.usaNfe == false) {
      if (this.ehConsumidorFinal() == false) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.consumidorFinalSomenteNFCe));
        validoNfce = false;
      }
    }

    if (validoNfe == false) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.consumidorFinalNaoPermitido));
      return false;
    }
    return true;
  }

  private configurarMovimentacao(): void {
    this.movimentacoesPersistir.forEach((movimentacao) => {
      movimentacao.movimentacaoProdutos = this.utilDocumentoEletronico.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == movimentacao.id);
      movimentacao.movimentacaoParcelas = this.utilDocumentoEletronico.movimentacaoParcelas.filter((movimentacaoParcela) => movimentacaoParcela.idMovimentacao == movimentacao.id);
      if (movimentacao.movimentacaoNfe.situacaoAutorizado != 'S') {
        movimentacao.movimentacaoNfe.status = -1;
        movimentacao.movimentacaoNfe.situacaoEmAndamento = 'S';
        movimentacao.movimentacaoNfe.idLoja = movimentacao.idLoja;
      }
    });
  }

  private emitirNfe(): void {
    const nfes: Nfe[] = this.transformarMovimentacaoEmNfe();
    nfes.forEach((nfe) => {
      this.emiteEmContingencia = false;
      if (this.utilSessao.getUsuario()?.agrow != 'S') {
        let nfeEnvio: Nfe[] = [];
        nfeEnvio.push(nfe);
        let movimentacaoEnviada: Movimentacao[] = [];
        movimentacaoEnviada.push(this.movimentacoesPersistir.find((movimentacao) => movimentacao.id.toString() + 'aGrow' == nfe.idIntegracao));
        this.comunicacaoTecnoSpeedService.persistir(this.bibServicoTecnoSpeed.getUrlNfe(this.servico), nfeEnvio).subscribe(
          (res) => {
            this.utilSessao.mensagemProcessandoNFe('');
            this.setMovimentacaoNfeIdTecnospeed(res);
            this.persistirMovimentacaoNfe(movimentacaoEnviada);
            this.validarMovimentacaoNfeSemIdTecnospeed(movimentacaoEnviada, res);
          },
          (erro) => {
            this.utilSessao.mensagemProcessandoNFe('');
            this.setMovimentacaoNfeErroTecnospeed(
              erro,
              nfeEnvio.map((nfe) => nfe.idIntegracao)
            );
            this.persistirMovimentacaoNfe(movimentacaoEnviada);
          }
        );
        this.utilSessao.mensagemProcessandoNFe('');
        this.utilSessao.finalizarProcesso();
      } else {
        this.utilSessao.mensagemProcessandoNFe('');
        this.utilSessao.finalizarProcesso();
        console.log(JSON.stringify(nfe));
      }
    });
    if (this.utilSessao.getUsuario()?.agrow == 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.documentoFiscalUsuarioaGrow));
    }
  }

  emitirNfeNs(): void {
    this.utilSessao.iniciarProcesso();
    const utilDocumentoEletronicoNs = new UtilDocumentoEletronicoNs(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
    this.idsMovimentacaoPersistir = this.movimentacoesPersistir.map((movimentacao) => movimentacao.id);
    this.movimentacoesPersistir.forEach((movimentacao) => {
      const infNFe: NfeNs = utilDocumentoEletronicoNs.transformarMovimentacaoEmNfe(movimentacao);
      const transporte = JSON.stringify({
        "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
        'NFe': { infNFe }
      });
      if (this.utilSessao.getUsuario()?.agrow != 'S') {
        this.comunicacaoNsService.persistir(this.bibServicoNs.getUrlEmissao(), transporte).subscribe(
          (res) => {
            movimentacao.movimentacaoNfe.idIntegracao = res.nsNRec;
            movimentacao.movimentacaoNfe.status = res.status;
            movimentacao.movimentacaoNfe.situacaoEmAndamento = 'S';
            movimentacao.movimentacaoNfe.situacaoNaoAtendido = 'N';
            movimentacao.movimentacaoNfe.expandido = false;
            movimentacao.movimentacaoNfe.modeloNotaFiscal = this.usaNfe ? 55 : 65;
            movimentacao.movimentacaoNfe.finalidade = movimentacao.naturezaOperacaoFinalidade == 1 || movimentacao.naturezaOperacaoFinalidade == 2 ? '1' : movimentacao.naturezaOperacaoFinalidade == 3 ? '4' : movimentacao.naturezaOperacaoFinalidade == 4 ? '2' : movimentacao.naturezaOperacaoFinalidade == 5 ? '3' : '1'; movimentacao.movimentacaoNfe.idTecnospeed = null;
            this.persistir(new Transporte(movimentacao.movimentacaoNfe), this.bibServico.movimentacaoNfe, null).subscribe(() => {
              this.definirId([movimentacao.movimentacaoNfe], this.bibClasse.movimentacaoNfe, true);
              if (!this.utilSessao.falha) {
                this.consultarNfeNs(movimentacao);
              }
              this.utilSessao.finalizarProcesso();
            });
          },
          (error) => {
            let erroEstrutura: string;
            if (error && error.error.erros) {
              for (let i = 0; i < error.error.erros.length; i++) {
                erroEstrutura += error.error.erros[i];
              }
            }
            movimentacao.movimentacaoNfe.status = -3;
            movimentacao.movimentacaoNfe.modeloNotaFiscal = this.usaNfe ? 55 : 65;
            movimentacao.movimentacaoNfe.statusDescricao = erroEstrutura ? erroEstrutura.substring(0, 999) : error.error.motivo.replace('[', '').replace(']', '').replace('{', '').replace('}', '').substring(0, 999);
            this.persistirMovimentacaoNfe([movimentacao]);
          }
        );
      } else {
        if (this.utilSessao.getUsuario()?.agrow == 'S') {
          console.log(JSON.stringify(transporte));
        }
      }
    });

    if (this.utilSessao.getUsuario()?.agrow == 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.documentoFiscalUsuarioaGrow));
    }
  }

  emitirSatNs(): void {
    const utilDocumentoEletronicoNsSat = new UtilDocumentoEletronicoNsSat(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
    this.movimentacoesPersistir.forEach((movimentacao) => {
      const nsSat: NsSat = utilDocumentoEletronicoNsSat.transformarMovimentacaoEmSat(movimentacao);
      utilDocumentoEletronicoNsSat.criarArquivoTxtSat(nsSat);
      if (this.utilSessao.getUsuario()?.agrow != 'S') {
        this.utilSessao.mensagemProcessandoNFe('');
        movimentacao.movimentacaoNfe.situacaoEmAndamento = 'S';
        movimentacao.movimentacaoNfe.status = -1;
        movimentacao.movimentacaoNfe.modeloNotaFiscal = 59
        this.persistirMovimentacaoNfe([movimentacao]);
        this.utilSessao.finalizarProcesso();
      } else {
        console.log('Verifique o arquivo que foi realizado o download');
        this.utilSessao.mensagemProcessandoNFe('');
        this.utilSessao.finalizarProcesso();
      }
    });

    if (this.utilSessao.getUsuario()?.agrow == 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.documentoFiscalUsuarioaGrow));
    }
  }

  consultarNfeNs(movimentacao: Movimentacao): void {
    const loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    this.utilSessao.iniciarProcesso();
    this.comunicacaoNsService.listar(this.bibServicoNs.getUrlConsultar(), this.util.retirarCaracteresInvalidos(loja.cnpj), movimentacao.movimentacaoNfe.idIntegracao).subscribe((res) => {
      if (res && res.xMotivo != null) {
        if ((res.cStat == 100 && res.nProt != null && res.chNFe != null)) {
          this.atualizarMovimentacaoNfeNsAutorizada(movimentacao, res);
          this.persistir(new Transporte([movimentacao.movimentacaoNfe]), this.bibServico.movimentacaoNfe, null).subscribe(() => {
            this.persistir(new Transporte([movimentacao]), this.bibServico.movimentacao, null).subscribe(() => {
              this.utilSessao.finalizarProcesso();
              if (this.utilSessao.falha) {
                this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelAtualizarMovimentacao));
              }
            });
          });
        } else {
          this.atualizarMovimentacaoNfeNsRejeicao(movimentacao, res);
          this.persistirMovimentacaoNfe([movimentacao]).subscribe();
          this.utilSessao.finalizarProcesso();
        }
      } else {
        this.utilSessao.finalizarProcesso();
      }

    });
  }

  atualizarMovimentacaoNfeNsAutorizada(movimentacao: Movimentacao, res: any): void {
    movimentacao.movimentacaoNfe.status = 100;
    movimentacao.movimentacaoNfe.statusDescricao = res.xMotivo.replace('[', '').replace(']', '').replace('{', '').replace('}', '').substring(0, 999);;
    movimentacao.movimentacaoNfe.protocolo = res.nProt;
    movimentacao.movimentacaoNfe.dataHora = res.dhRecbto;
    movimentacao.chaveFiscal = res.chNFe;
    movimentacao.selecionado = 'N';
    movimentacao.documentoFiscal = movimentacao.numero;
    movimentacao.movimentacaoNfe.numeroFiscal = movimentacao.numero;
    movimentacao.atualizandoConsultaNfe = 'S';
    movimentacao.movimentacaoNfe.situacaoEmAndamento = 'N';
    movimentacao.movimentacaoNfe.situacaoNaoAtendido = 'N';
    movimentacao.movimentacaoNfe.situacaoAutorizado = 'S';
  }

  atualizarMovimentacaoNfeNsRejeicao(movimentacao: Movimentacao, res: any): void {
    movimentacao.movimentacaoNfe.status = -3;
    movimentacao.movimentacaoNfe.statusDescricao = res.xMotivo;
    movimentacao.selecionado = 'N';
    movimentacao.movimentacaoNfe.situacaoEmAndamento = 'N';
    movimentacao.movimentacaoNfe.situacaoNaoAtendido = 'S';
  }

  setMovimentacaoNfeErroTecnospeed(erro: any, nfeIds: string[]) {
    console.log('erro', erro)
    var resultado = erro.error.error.message;
    for (var i in erro.error.error.data.fields) {
      if (erro.error.error.data.fields.hasOwnProperty(i)) {
        resultado += ': ' + i + ' = ' + erro.error.error.data.fields[i] + '\n';
      }
    }
    nfeIds.forEach((nfeId) => {
      let movimentacao: Movimentacao = this.movimentacoesPersistir.find((movimentacao) => movimentacao.id == Number(nfeId.replace('aGrow', '')));
      movimentacao.selecionado = 'N';
      movimentacao.movimentacaoNfe.idTecnospeed = nfeId;
      movimentacao.movimentacaoNfe.status = -3;
      movimentacao.movimentacaoNfe.statusDescricao = this.verificarDescricao(resultado);
    });
  }

  setMovimentacaoNfeIdTecnospeed(res: any) {
    res.documents.forEach((respostaNfe) => {
      let movimentacao: Movimentacao = this.movimentacoesPersistir.find((movimentacao) => movimentacao.id == respostaNfe.idIntegracao.replace('aGrow', ''));
      movimentacao.movimentacaoNfe.idTecnospeed = respostaNfe.id;
      if (movimentacao.movimentacaoNfe.idTecnospeed == null) {
        movimentacao.movimentacaoNfe.status = 0;
        movimentacao.movimentacaoNfe.situacaoEmAndamento = 'N';
        movimentacao.selecionado = 'N';
      }
    });
  }

  validarMovimentacaoNfeSemIdTecnospeed(movimentacaoNfePersistidas: Movimentacao[], respostaTecnospeed: any): void {
    let quantidadeMovimentacaoNfeSemIdTecnospeed = 0;
    respostaTecnospeed.documents.forEach((respostaNfe) => {
      let movimentacao: Movimentacao = movimentacaoNfePersistidas.find((movimentacao) => movimentacao.id == respostaNfe.idIntegracao.replace('aGrow', ''));
      if (movimentacao.movimentacaoNfe.idTecnospeed == null || movimentacao.movimentacaoNfe.status == 0) {
        quantidadeMovimentacaoNfeSemIdTecnospeed++;
      }
    });
    if (quantidadeMovimentacaoNfeSemIdTecnospeed > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.servidorReceitaIndisponivel));
    }
  }

  verificaEhConsumidorFinalNfce(parceiro: Parceiro, idParceiroDestino: number): void {
    let cnpjCpfDestinatario: string = '';
    if (idParceiroDestino) {
      cnpjCpfDestinatario = this.util.retirarCaracteresInvalidos(parceiro.parceiroDestinos[0].cnpjCpf ? parceiro.parceiroDestinos[0].cnpjCpf : parceiro.cnpjCpf);
    } else {
      cnpjCpfDestinatario = this.util.retirarCaracteresInvalidos(parceiro.cnpjCpf);
    }
    this.ehConsumidorFinalNfce = cnpjCpfDestinatario == '11111111111' ? true : false;
  }

  transformarMovimentacaoEmNfe(): Nfe[] {
    let nfes: Nfe[] = [];
    this.movimentacoesPersistir.forEach((movimentacao) => {
      this.informacaoComplementarProduto = '';
      this.informacoesComplementares = '';
      this.lojaMovimentacao = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja)
      let parceiro: Parceiro = this.utilDocumentoEletronico.getParceiro(movimentacao.idParceiro, movimentacao.idParceiroDestino);
      let transportadora: Parceiro = movimentacao.idTransportadora ? this.utilDocumentoEletronico.getParceiro(movimentacao.idTransportadora, null) : null;
      this.verificaEhConsumidorFinalNfce(parceiro, movimentacao.idParceiroDestino);
      let nfe: Nfe = new Nfe();
      const loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
      this.enviarIbpt = loja.enviarIbptNfe == 'S' ? true : loja.enviarIbptNfe == 'C' && parceiro.contribuinte != 1 ? true : false;
      nfe.enviarEmail = loja.nfeEmail == 'S';

      nfe.camposExtras = new CamposExtras((loja.nfeEmailCopia ? loja.nfeEmailCopia.replace(' ', '') + ';' : '') + (parceiro.email ? parceiro.email.replace(' ', '') : ''));
      nfe.idIntegracao = movimentacao.id.toString() + 'aGrow';
      nfe.codigo = movimentacao.id.toString();
      nfe.modelo = this.servico == 'nfce' ? '65' : '55';
      nfe.finalidade = movimentacao.movimentacaoNfe.finalidade;
      nfe.serie = movimentacao.serie;
      nfe.presencial = true;
      nfe.consumidorFinal = parceiro.contribuinte != 1;
      nfe.natureza = movimentacao.naturezaOperacao;
      nfe.dataEmissao = movimentacao.data.toString();
      nfe.dataSaidaEntrada = this.montarDataHoraEntradaSaida(movimentacao.dataEntradaSaida, movimentacao.horaSaida).toString();
      const NOTA_FISCAL: number = 3;
      const PEDIDO_COM_NOTA_FISCAL: number = 20;
      nfe.saida = movimentacao.idOperacao == NOTA_FISCAL || movimentacao.idOperacao == PEDIDO_COM_NOTA_FISCAL ? true : false;
      nfe.tipoEmissao = this.getTipoEmissao(movimentacao);
      nfe.emitente = this.gerarNfeEmitente(movimentacao);
      this.ehConsumidorFinalNfce == false ? nfe.destinatario = this.gerarNfeDestinatario(parceiro, movimentacao.idParceiroDestino) : null;
      nfe.pagamentos = this.gerarNfePagamentos(movimentacao);
      nfe.itens = this.gerarNfeItens(movimentacao, this.ehConsumidorFinalNfce == false ? nfe.destinatario.endereco.estado : nfe.emitente.endereco.estado, nfe);
      nfe.cobranca = nfe.modelo == '55' ? this.gerarNfeCobrancas(movimentacao) : null;
      let enviarPlacaTransportadora: boolean = this.ehConsumidorFinalNfce == false && nfe.emitente.endereco.estado == nfe.destinatario.endereco.estado && (nfe.emitente.endereco.codigoCidade == nfe.destinatario.endereco.codigoCidade || (nfe.emitente.endereco.codigoCidade != nfe.destinatario.endereco.codigoCidade && nfe.emitente.endereco.estado != 'MG'));
      nfe.transporte = nfe.modelo == '55' ? this.gerarNfeTransporte(movimentacao, transportadora, enviarPlacaTransportadora, loja.idEstado) : null;
      const totalDiferimentoIcms: number = this.totalizarDiferimentoIcms(nfe.itens);
      const totalCreditoIcmsBase: number = this.totalizarCreditoIcmsBase(nfe.itens);
      const totalCreditoIcmsValor: number = this.totalizarCreditoIcmsValor(nfe.itens);
      nfe.total = this.gerarNfeTotal(movimentacao, totalDiferimentoIcms, totalCreditoIcmsBase, totalCreditoIcmsValor, nfe);
      if (movimentacao.movimentacaoNfe.finalidade != '1') {
        nfe.notaReferenciada = this.gerarNotaReferenciada(movimentacao);
      }
      nfe.informacoesComplementares = this.informacoesComplementares + ' ';
      nfe.informacoesComplementares += this.utilDocumentoEletronico.gerarInformacaoComplementar(movimentacao, (this.ehConsumidorFinalNfce == false ? nfe.destinatario.endereco.estado : nfe.emitente.endereco.estado));
      nfe.informacoesComplementares = nfe.informacoesComplementares.replace(/[\r\n]/gm, '');
      nfe.informacoesComplementares = nfe.informacoesComplementares.replace('  ', ' ');
      this.informacaoFiscal.push({ id: movimentacao.id, informacaoFiscal: nfe.informacoesComplementares });
      this.ehConsumidorFinalNfce == false ? nfe.destinatario.endereco = nfe.destinatario.endereco : null;
      if (loja.contadorCpf && loja.contadorCpf != parceiro.cnpjCpf && this.ehConsumidorFinalNfce == false && nfe.modelo == '55') {
        nfe.responsavelAutorizado = this.gerarResponsavelAutorizado(loja.contadorCpf);
      }
      if (nfe.emitente.endereco.estado == 'PE') {
        nfe.responsavelTecnico = new ResponsavelTecnico();
      }
      nfes.push(nfe);
    });
    return nfes;
  }

  gerarResponsavelAutorizado(cnpjContador: string): ResponsavelAutorizado {
    this.utilSessao.mensagemProcessandoNFe('CPF Autorizado');
    let responsavelAutorizado = new ResponsavelAutorizado();
    responsavelAutorizado.cpfCnpj = this.util.retirarCaracteresInvalidos(cnpjContador);
    return responsavelAutorizado;
  }

  totalizarDiferimentoIcms(nfeItens: Item[]): number {
    this.utilSessao.mensagemProcessandoNFe('Diferimento ICMS');
    let totalDiferimentoIcms = 0;
    nfeItens.forEach((nfeItem) => (totalDiferimentoIcms += nfeItem.tributos.icms.diferimento?.valor != null ? nfeItem.tributos.icms.diferimento.valor : 0));
    return totalDiferimentoIcms;
  }

  totalizarCreditoIcmsBase(nfeItens: Item[]): number {
    this.utilSessao.mensagemProcessandoNFe('Crédito ICMS Base');
    let totalCreditoIcmsBase = 0;
    nfeItens.forEach((nfeItem) => {
      if (nfeItem.tributos.icms?.cst != null && nfeItem.tributos.icms?.cst.endsWith('101') && nfeItem.tributos.icms.creditoSimplesNacional?.valor && nfeItem.tributos.icms.creditoSimplesNacional?.percentual && nfeItem.tributos.icms.creditoSimplesNacional?.valor > 0 && nfeItem.tributos.icms.creditoSimplesNacional?.percentual > 0) {
        totalCreditoIcmsBase += nfeItem.tributos.icms?.creditoSimplesNacional?.valor / (nfeItem.tributos.icms.creditoSimplesNacional?.percentual / 100);
      }
    });
    return totalCreditoIcmsBase;
  }

  totalizarCreditoIcmsValor(nfeItens: Item[]): number {
    this.utilSessao.mensagemProcessandoNFe('Crédito ICMS Valor');
    let totalCreditoIcmsValor = 0;
    nfeItens.forEach((nfeItem) => (totalCreditoIcmsValor += nfeItem.tributos.icms?.creditoSimplesNacional?.valor != null ? nfeItem.tributos.icms.creditoSimplesNacional.valor : 0));
    return totalCreditoIcmsValor;
  }

  private montarDataHoraEntradaSaida(data: Date, hora: string): Date {
    this.utilSessao.mensagemProcessandoNFe('Data hora');
    const horas: string[] = hora.split(':');
    const ano: number = data.getFullYear();
    const mes: number = data.getMonth();
    const dia: number = data.getDate();
    return new Date(ano, mes, dia, Number(horas[0]), Number(horas[1]), Number(horas[2]), 0);
  }

  getTipoEmissao(movimentacao: Movimentacao): string {
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    if (this.emiteEmContingencia) {
      if (this.svcAn.includes(loja.estado)) {
        return '6';
      }
      if (this.svcRs.includes(loja.estado)) {
        return '7';
      }
    }
    return '1';
  }

  private gerarNfeEmitente(movimentacao: Movimentacao): Emitente {
    this.utilSessao.mensagemProcessandoNFe('Emitente');
    let emitente: Emitente = new Emitente();
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    emitente.cpfCnpj = loja.cnpj;
    emitente.cpfCnpj = this.util.retirarCaracteresInvalidos(emitente.cpfCnpj);
    emitente.razaoSocial = loja.razaoSocial;
    emitente.nomeFantasia = loja.nomeFantasia;
    emitente.inscricaoMunicipal = loja.inscricaoMunicipal;
    emitente.inscricaoEstadual = loja.inscricaoEstadual;
    emitente.simplesNacional = loja.enquadramento == 1;
    emitente.email = loja.email ? loja.email.replace(' ', '') : null;
    emitente.endereco = new Endereco();
    emitente.endereco.bairro = loja.bairro;
    emitente.endereco.estado = loja.estado;
    emitente.endereco.cep = loja.enderecoCep ? loja.enderecoCep.replace('-', '') : null;
    emitente.endereco.complemento = loja.enderecoComplemento;
    emitente.endereco.logradouro = loja.enderecoTipo + ' ' + loja.endereco;
    emitente.endereco.numero = loja.enderecoNumero.toString();
    emitente.endereco.codigoCidade = loja.cidadeIbge;
    const ddd: string = loja.telefone.replace('(', '').replace(')', '').substr(0, 2).trim();
    const numero: string = loja.telefone.replace('(', '').replace(')', '').replace('-', '').substr(2, loja.telefone.length).trim();
    emitente.telefone = new Telefone(ddd, numero);
    return emitente;
  }

  private gerarNfeDestinatario(parceiro: Parceiro, idParceiroDestino: number): Destinatario {
    this.utilSessao.mensagemProcessandoNFe('Destinatário');

    let destinatario = new Destinatario();
    let parceiroEndereco: any;
    if (idParceiroDestino) {
      destinatario.cpfCnpj = this.util.retirarCaracteresInvalidos(parceiro.parceiroDestinos[0].cnpjCpf ? parceiro.parceiroDestinos[0].cnpjCpf : parceiro.cnpjCpf);
    } else {
      destinatario.cpfCnpj = this.util.retirarCaracteresInvalidos(parceiro.cnpjCpf);
    }
    this.ehConsumidorFinalNfce = destinatario.cpfCnpj == '11111111111' ? true : false;
    destinatario.cpfCnpj = this.ehConsumidorFinalNfce ? '' : destinatario.cpfCnpj;
    if (idParceiroDestino) {
      parceiroEndereco = parceiro.parceiroDestinos.find((parceiroDestino) => parceiroDestino.idParceiro == parceiro.id && parceiroDestino.id == idParceiroDestino);
    } else {
      parceiroEndereco = parceiro.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == 3);
    }
    this.utilSessao.mensagemProcessandoNFe('Destinatário telefone');
    let parceiroTelefone: ParceiroTelefone = parceiro.parceiroTelefones.find((parceiroTelefoneBusca) => parceiroTelefoneBusca.idVinculo == 3);
    destinatario.razaoSocial = this.ehConsumidorFinalNfce ? '' : parceiro.razaoSocial;
    destinatario.nomeFantasia = this.ehConsumidorFinalNfce ? '' : parceiro.nomeFantasia;
    destinatario.email = this.ehConsumidorFinalNfce ? '' : (this.lojaMovimentacao.nfeEmail == 'S' && this.lojaMovimentacao.nfeEmailCopia ? this.lojaMovimentacao.nfeEmailCopia.replace(' ', '') + ';' : '') + (parceiro.email ? parceiro.email.replace(' ', '') : '');
    destinatario.indicadorInscricaoEstadual = this.ehConsumidorFinalNfce ? null : parceiro.contribuinte;

    if (parceiroEndereco) {
      this.utilSessao.mensagemProcessandoNFe('Destinatário endereço');
      let estado: Estado = this.utilDocumentoEletronico.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
      destinatario.endereco = new Endereco();
      destinatario.endereco.cep = parceiroEndereco.cep.replace('-', '');
      destinatario.endereco.tipoLogradouro = parceiroEndereco.tipo;
      destinatario.endereco.descricaoCidade = parceiroEndereco.cidade;
      destinatario.endereco.codigoCidade = parceiroEndereco.cidadeIbge;
      destinatario.endereco.logradouro = (idParceiroDestino ? parceiro.parceiroDestinos[0].nome + ' - ' : '') + parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco;
      destinatario.endereco.logradouro = destinatario.endereco.logradouro.substring(0, 60);
      destinatario.endereco.numero = parceiroEndereco.numero;
      destinatario.endereco.bairro = parceiroEndereco.bairro;
      destinatario.endereco.estado = estado.abreviacao;
    }
    if (idParceiroDestino && destinatario.endereco.estado != "EX") {
      destinatario.inscricaoEstadual = this.ehConsumidorFinalNfce ? '' : parceiro.parceiroDestinos[0].inscricaoEstadual;
      if (destinatario.inscricaoEstadual == 'ISENTO') {
        const ISENTO: number = 2;
        destinatario.indicadorInscricaoEstadual = ISENTO;
      }
    } else {
      destinatario.inscricaoEstadual = this.ehConsumidorFinalNfce || destinatario.endereco.estado == "EX" ? '' : parceiro.inscricaoEstadual;
    }

    if (parceiroTelefone && this.ehConsumidorFinalNfce == false) {
      const ddd: string = parceiroTelefone.telefone.replace('(', '').replace(')', '').substr(0, 2);
      const numero: string = parceiroTelefone.telefone.replace('(', '').replace(')', '').replace('-', '').substr(2, parceiroTelefone.telefone.length);
      destinatario.telefone = new Telefone(ddd, numero);
    }
    return destinatario;
  }

  private gerarNfeTransporte(movimentacao: Movimentacao, transportadora: Parceiro, enviarPlacaTransportadora: boolean, idEstadoLoja: number): TransporteTecnoSpeed {
    this.utilSessao.mensagemProcessandoNFe('Informações de transporte');
    let transporteTecnoSpeed: TransporteTecnoSpeed = new TransporteTecnoSpeed();
    transporteTecnoSpeed.modalidadeFrete = movimentacao.freteTipo == 100 ? '0' : movimentacao.freteTipo.toString();
    let volume = new Volume();
    volume.quantidade = movimentacao.transportadoraQuantidade;
    volume.especie = movimentacao.transportadoraEspecie;
    volume.marca = movimentacao.transportadoraMarca;
    volume.numeracao = movimentacao.transportadoraNumeracao ? Number(movimentacao.transportadoraNumeracao) : null;
    volume.pesoLiquido = Number(movimentacao.pesoLiquido.toFixed(3));
    volume.pesoBruto = Number(movimentacao.pesoBruto.toFixed(3));
    transporteTecnoSpeed.volumes.push(volume);
    if (transportadora) {
      this.gerarNfeTransportadora(transporteTecnoSpeed, transportadora, movimentacao, enviarPlacaTransportadora, idEstadoLoja);
      return transporteTecnoSpeed;
    } else {
      return transporteTecnoSpeed;
    }
  }

  private gerarNfeTransportadora(transporteTecnoSpeed: TransporteTecnoSpeed, transportadora: Parceiro, movimentacao: Movimentacao, enviarPlacaTransportadora: boolean, idEstadoLoja: number): void {
    this.utilSessao.mensagemProcessandoNFe('Transportadora');
    transporteTecnoSpeed.transportador.nome = transportadora.razaoSocial;
    transporteTecnoSpeed.transportador.cnpj = transportadora.tipoPessoa == 'J' && transportadora.cnpjCpf ? this.util.retirarCaracteresInvalidos(transportadora.cnpjCpf) : null;
    transporteTecnoSpeed.transportador.cpf = transportadora.tipoPessoa == 'F' && transportadora.cnpjCpf ? this.util.retirarCaracteresInvalidos(transportadora.cnpjCpf) : null;
    transporteTecnoSpeed.transportador.inscricaoEstadual = transportadora.inscricaoEstadual != null ? this.util.retirarCaracteresInvalidos(transportadora.inscricaoEstadual) : null;
    if (movimentacao.transportadoraPlaca != null && movimentacao.transportadoraUfPlaca != null) {
      if (enviarPlacaTransportadora) {
        transporteTecnoSpeed.veiculo.placa = movimentacao.transportadoraPlaca ? this.util.retirarCaracteresInvalidos(movimentacao.transportadoraPlaca) : null;
        transporteTecnoSpeed.veiculo.uf = movimentacao.transportadoraUfPlaca ? movimentacao.transportadoraUfPlaca : null;
      } else {
        this.informacoesComplementares += movimentacao.transportadoraPlaca ? ' PLACA: ' + movimentacao.transportadoraPlaca : '';
        this.informacoesComplementares += movimentacao.transportadoraUfPlaca ? ' UF PLACA: ' + movimentacao.transportadoraUfPlaca + ' ' : '';
      }
    } else {
      transporteTecnoSpeed.veiculo = null;
    }
    transporteTecnoSpeed.transportador.endereco.descricaoCidade = transportadora.cidade ? transportadora.cidade : null;
    transporteTecnoSpeed.transportador.endereco.uf = transportadora.uf ? transportadora.uf : null;
    transporteTecnoSpeed.transportador.endereco.logradouro = transportadora.enderecoCompleto ? transportadora.enderecoCompleto.substring(0, 60) : null;
  }

  private gerarNfeTotal(movimentacao: Movimentacao, totalDiferimentoIcms: number, totalCreditoIcmsBase: number, totalCreditoIcmsValor: number, nfe: Nfe): Total {
    this.utilSessao.mensagemProcessandoNFe('Totais')
    let total: Total = new Total();
    total.baseCalculoIcms = movimentacao.icmsBase - totalCreditoIcmsBase;
    total.baseCalculoIcmsSt = movimentacao.icmsStBase;
    total.valorCofins = movimentacao.cofinsValor;
    total.valorFrete = movimentacao.freteValor;
    total.valorIcms = movimentacao.icmsValor - this.util.arredondar(totalDiferimentoIcms) - this.util.arredondar(totalCreditoIcmsValor);
    total.valorIcmsSt = movimentacao.icmsStValor;
    total.valorIpi = movimentacao.ipiValor;
    total.valorOutros = movimentacao.outroValor + movimentacao.despesaValor;
    total.valorPis = movimentacao.pisValor;
    total.valorSeguro = movimentacao.seguroValor;
    total.valorProdutosServicos = 0;
    total.valorDesconto = 0;
    nfe.itens.forEach((item) => {
      total.valorProdutosServicos += Number(item.valor.toFixed(4));
      total.valorDesconto += Number(item.valorDesconto.toFixed(2));
    });
    total.valorProdutosServicos = Number(total.valorProdutosServicos.toFixed(2));
    total.valorNfe = movimentacao.valorTotal;
    total.valorIi = 0;
    total.valorAproximadoTributos = this.enviarIbpt ? this.util.arredondar(movimentacao.ibptEstadualValor + movimentacao.ibptNacionalValor) : 0.0;
    return total;
  }

  private gerarNfeItens(movimentacao: Movimentacao, estado: string, nfe: Nfe): Item[] {
    let itens: Item[] = [];
    let loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      this.informacaoComplementarProduto = '';
      this.utilSessao.mensagemProcessandoNFe('Produto');
      const produto: Produto = this.utilDocumentoEletronico.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
      this.utilSessao.mensagemProcessandoNFe('Produto ICMS');
      const produtoIcms: ProdutoIcms = this.utilDocumentoEletronico.produtoIcmss.find((produtoIcms) => produtoIcms.idProduto == produto.id && produtoIcms.estado == estado && produtoIcms.enquadramento == loja.enquadramento && (produtoIcms.tipo == movimentacao.entradaSaidaInterna || (movimentacao.identificacao == 6 ? -1 : movimentacao.identificacao == 7 ? 1 : 0)));
      this.utilSessao.mensagemProcessandoNFe('Grupo ICMS');
      const grupoIcms: GrupoIcms = this.utilDocumentoEletronico.grupoIcmss.find((grupoIcms) => produto.idGrupo == grupoIcms.idGrupo && grupoIcms.estado == estado && grupoIcms.enquadramento == loja.enquadramento && (grupoIcms.tipo == movimentacao.entradaSaidaInterna || (movimentacao.identificacao == 6 ? -1 : movimentacao.identificacao == 7 ? 1 : 0)));
      this.utilSessao.mensagemProcessandoNFe('CST ICMS');
      const cstIcms: Cst = this.utilDocumentoEletronico.cstIcmss.find((cstIcmsBusca) => cstIcmsBusca.id == movimentacaoProduto.idCstIcms);
      this.utilSessao.mensagemProcessandoNFe('CST Cofins');
      const cstCofins: Cst = this.utilDocumentoEletronico.cstPisCofinss.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstCofins);
      this.utilSessao.mensagemProcessandoNFe('CST PIS');
      const cstPis: Cst = this.utilDocumentoEletronico.cstPisCofinss.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstPis);
      this.utilSessao.mensagemProcessandoNFe('CFOP Empresa');
      const cfopEmpresa: CfopEmpresa = this.utilDocumentoEletronico.cfopEmpresas.find((cfopEmpresaBusca) => cfopEmpresaBusca.id == movimentacaoProduto.idCfopEmpresa);
      this.utilSessao.mensagemProcessandoNFe('CST IPI');
      const cstIpi: Cst = this.utilDocumentoEletronico.cstsIpis.find((cstIpi) => cstIpi.id == Number(movimentacaoProduto.idCstIpi));
      let item = new Item();
      item.descricao = produto.nomeFiscal;
      item.valorFrete = movimentacaoProduto.freteRateado;
      item.valorSeguro = movimentacaoProduto.seguroRateado;
      item.valorDesconto = movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado;
      if (movimentacaoProduto.descontoValorUnitario && movimentacaoProduto.descontoValorUnitario > 0) {
        item.valorDesconto = Number((movimentacaoProduto.descontoValorUnitario * movimentacaoProduto.quantidade).toFixed(2));
      }
      item.valorDesconto = Number(item.valorDesconto.toFixed(2))
      item.valorOutros = movimentacaoProduto.despesaRateado + movimentacaoProduto.outroRateado;
      item.codigo = movimentacaoProduto.produtoNumero.toString();
      item.cest = produto.cest;
      item.ncm = produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim();
      if (produto.cest == null) {
        const ncmEmpresa: NcmEmpresa = this.utilDocumentoEletronico.ncmEmpresas.find((ncmEmpresa) => Number(ncmEmpresa.idNcm) == Number(produto.idNcm));
        if (ncmEmpresa != null && ncmEmpresa.cest != null) {
          item.cest = ncmEmpresa.cest.toString();
        }
      }
      this.utilSessao.mensagemProcessandoNFe('Unidade');
      item.unidade = new Unidade();
      item.unidade.comercial = movimentacaoProduto.idUnidade && movimentacaoProduto.idUnidade != produto.idUnidade ? movimentacaoProduto.abreviacao : produto.abreviacao;
      item.unidade.tributavel = movimentacaoProduto.idUnidade && movimentacaoProduto.idUnidade != produto.idUnidade ? movimentacaoProduto.abreviacao : produto.abreviacao;
      item.cfop = cfopEmpresa.idCfop.toString();
      item.valor = Number((((movimentacaoProduto.valorTotal + movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado) + (movimentacaoProduto.getQuantidade() * movimentacaoProduto.descontoValorUnitario))).toFixed(2));
      item.quantidade = new Quantidade(movimentacaoProduto.getQuantidade(), movimentacaoProduto.getQuantidade());
      item.valorUnitario = new ValorUnitario(Number((item.valor > 0 ? item.valor / movimentacaoProduto.getQuantidade() : 0.00).toFixed(6)), Number((item.valor > 0 ? item.valor / movimentacaoProduto.getQuantidade() : 0.00).toFixed(6)));

      if (produto.idAnp) {
        this.utilSessao.mensagemProcessandoNFe('ANP');
        item.combustivel = new Combustivel();
        item.combustivel.codigoAnp = produto.anpNumero;
        item.combustivel.descricaoAnp = produto.anp;
        item.combustivel.estadoConsumo = estado;
      }
      this.utilSessao.mensagemProcessandoNFe('ICMS');
      item.tributos = new Tributo();
      item.tributos.icms = new Icms();
      item.tributos.icms.origem = cstIcms.cst[0];
      item.tributos.icms.cst = cstIcms.cst.substring(1, cstIcms.nome.length);

      const FINALIDADE_COMPLEMENTO: number = 4;
      const FINALIDADE_AJUSTE: number = 5;
      const SIMPLES_NACIONAL: number = 1;
      if (item.tributos.icms.cst.endsWith('40') == false && (movimentacaoProduto.getIcmsBase() > 0 || (item.tributos.icms.cst.endsWith('900')) || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_COMPLEMENTO || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_AJUSTE)) {
        item.tributos.icms.baseCalculo = new BaseCalculo();
        item.tributos.icms.baseCalculo.valor = movimentacaoProduto.getIcmsBase();
        item.tributos.icms.baseCalculo.modalidadeDeterminacao = 3;
        if (item.tributos.icms.cst.endsWith('00') == false && item.tributos.icms.cst.endsWith('10') == false && item.tributos.icms.baseCalculo.valor > 0) {
          const percentualReducao = this.util.arredondar(((item.valor - item.tributos.icms.baseCalculo.valor) / item.valor) * 100);
          if (percentualReducao > 0) {
            item.tributos.icms.baseCalculo.percentualReducao = percentualReducao;
          } else {
            if (item.valor == 0) {
              item.tributos.icms.baseCalculo.percentualReducao = 0;
            }
          }
        }
      }
      if (movimentacaoProduto.getCodigoBeneficioFiscal() != null) {
        item.codigoBeneficioFiscal = movimentacaoProduto.getCodigoBeneficioFiscal();
      }

      if ((movimentacaoProduto.getIcmsAliquota() > 0 && movimentacaoProduto.getIcmsBase() > 0 && movimentacaoProduto.getIcmsValor() > 0) || (item.tributos.icms.cst.endsWith('900')) || (item.tributos.icms.cst.endsWith('00') && loja.enquadramento != SIMPLES_NACIONAL) || (!item.tributos.icms.cst.endsWith('40') && (movimentacao.naturezaOperacaoFinalidade == FINALIDADE_COMPLEMENTO || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_AJUSTE))) {
        item.tributos.icms.aliquota = movimentacaoProduto.getIcmsAliquota();
      }

      if (movimentacaoProduto.getIcmsValor() > 0 || (!item.tributos.icms.cst.endsWith('40') && (movimentacao.naturezaOperacaoFinalidade == FINALIDADE_COMPLEMENTO || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_AJUSTE))) {
        item.tributos.icms.valor = movimentacaoProduto.getIcmsValor();
      }

      if (item.tributos.icms.cst.endsWith('51')) {
        item.tributos.icms.diferimento = new Diferimento();
        const aliquotaZero: boolean = produto.diferimentoAliquota == null || produto.diferimentoAliquota == 0;
        item.tributos.icms.diferimento.percentual = aliquotaZero ? 0 : produto.diferimentoAliquota;
        item.tributos.icms.diferimento.valor = aliquotaZero ? 0 : movimentacaoProduto.getIcmsValor() * (produto.diferimentoAliquota / 100);
        item.tributos.icms.diferimento.valorIcmsDevido = aliquotaZero ? 0 : movimentacaoProduto.getIcmsValor() - item.tributos.icms.diferimento.valor;
      }

      if (movimentacaoProduto.getIcmsStBase() > 0 || movimentacaoProduto.getIcmsStValor() > 0 || item.tributos.icms.cst.endsWith('60') || item.tributos.icms.cst.endsWith('10') || item.tributos.icms.cst.endsWith('500')) {
        item.tributos.icms.substituicaoTributaria = new SubstituicaoTributaria();
        item.tributos.icms.substituicaoTributaria.baseCalculo = new BaseCalculo();
        if (item.tributos.icms.cst.endsWith('10') || item.tributos.icms.cst.endsWith('70') || item.tributos.icms.cst.endsWith('900')) {
          item.tributos.icms.substituicaoTributaria.baseCalculo.modalidadeDeterminacao = 0;
        }
        item.tributos.icms.substituicaoTributaria.baseCalculo.valor = movimentacaoProduto.getIcmsStBase();
        item.tributos.icms.substituicaoTributaria.valor = movimentacaoProduto.getIcmsStValor();
        item.tributos.icms.substituicaoTributaria.aliquota = movimentacaoProduto.icmsStAliquota;
      }
      if (item.tributos.icms.cst.endsWith('101')) {
        item.tributos.icms.creditoSimplesNacional = new CreditoSimplesNacional();
        item.tributos.icms.creditoSimplesNacional.percentual = movimentacaoProduto.getIcmsAliquota();
        item.tributos.icms.creditoSimplesNacional.valor = movimentacaoProduto.getIcmsValor();
        item.tributos.icms.valor = undefined;
        item.tributos.icms.aliquota = undefined;
        item.tributos.icms.baseCalculo = undefined;
      }
      if (movimentacaoProduto.getIcmsDesonerado() > 0) {
        item.tributos.icms.desoneracao = new Desoneracao();
        item.tributos.icms.desoneracao.valor = movimentacaoProduto.getIcmsDesonerado();
        item.tributos.icms.desoneracao.motivo = 9;
      }
      this.utilSessao.mensagemProcessandoNFe('Cofins');
      item.tributos.cofins = new Cofins(cstCofins.cst);
      item.tributos.cofins.aliquota = movimentacaoProduto.getCofinsAliquota();
      item.tributos.cofins.valor = movimentacaoProduto.getCofinsValor();

      if (movimentacaoProduto.getCofinsAliquota() > 0) {
        item.tributos.cofins.baseCalculo = new BaseCalculo();
        item.tributos.cofins.baseCalculo.valor = movimentacaoProduto.getCofinsBase();
      }
      this.utilSessao.mensagemProcessandoNFe('PIS');
      item.tributos.pis = new Pis(cstPis.cst);
      item.tributos.pis.aliquota = movimentacaoProduto.getPisAliquota();
      item.tributos.pis.valor = movimentacaoProduto.getPisValor();

      if (movimentacaoProduto.getPisAliquota() > 0) {
        item.tributos.pis.baseCalculo = new BaseCalculo();
        item.tributos.pis.baseCalculo.valor = movimentacaoProduto.getPisBase();
        item.tributos.pis.baseCalculo.quantidade = movimentacaoProduto.getQuantidade();
      }

      // if (nfe.destinatario.endereco.estado == 'EX') {
      //   item.tributos.importacaoIi = new importacaoII();
      //   item.tributos.importacaoIi.baseCalculo = 0.0001;
      //   item.tributos.importacaoIi.valor = 0.00;
      //   item.tributos.importacaoIi.valorDespesasAduaneiras = 0.00;
      //   item.tributos.importacaoIi.valorOperacoesFinanceiras = 0.00;
      // }

      if (this.usaNfe) {
        this.utilSessao.mensagemProcessandoNFe('IPI');
        item.tributos.ipi = new Ipi();
        item.tributos.ipi.cst = cstIpi.cst;
        let enquadramento: string = movimentacao.entradaSaidaInterna == 1 ? produto.ipiEnquadramentoEntrada : produto.ipiEnquadramentoSaida;
        item.tributos.ipi.codigoEnquadramentoLegal = enquadramento != null ? enquadramento : '999';
        item.tributos.ipi.aliquota = movimentacaoProduto.getIpiAliquota();
        item.tributos.ipi.baseCalculo = movimentacaoProduto.getIpiBase();
      }

      if (movimentacaoProduto.getIpiValor() > 0) {
        item.tributos.ipi.valor = movimentacaoProduto.getIpiValor();
      }

      item.tributos.valorAproximadoTributos = this.enviarIbpt ? this.util.arredondar(movimentacaoProduto.ibptEstadualValor + movimentacaoProduto.ibptNacionalValor) : 0.0;
      if ((produtoIcms && produtoIcms.idClassificacaoFiscal) || (grupoIcms && grupoIcms.idClassificacaoFiscal)) {
        const classificacaoFiscal: ClassificacaoFiscal = this.utilDocumentoEletronico.classificacaoFiscais.find((classificacaoFiscalBusca) => classificacaoFiscalBusca.id == (produtoIcms && produtoIcms.idClassificacaoFiscal != undefined ? produtoIcms.idClassificacaoFiscal : grupoIcms && grupoIcms.idClassificacaoFiscal ? grupoIcms.idClassificacaoFiscal : ''));
        //this.informacoesComplementares += classificacaoFiscal && classificacaoFiscal.descricaoNfe ? ' ' + classificacaoFiscal.descricaoNfe : '';
      }

      if (this.ehConsumidorFinalNfce == false && nfe.emitente.endereco.codigoPais == nfe.destinatario.endereco.codigoPais && nfe.emitente.endereco.estado != nfe.destinatario.endereco.estado && nfe.destinatario.endereco.estado != "EX" && nfe.consumidorFinal && item.tributos.icms.cst.endsWith('40') == false) {
        this.utilSessao.mensagemProcessandoNFe('Partilha ICMS');
        item.tributos.partilha = new Partilha();
        item.tributos.partilha.ufDestino = new UfDestino();
        item.tributos.partilha.ufDestino = this.utilDocumentoEletronico.gerarPartilha(movimentacao, movimentacaoProduto, nfe, this.utilDocumentoEletronico.estados);
      }

      item.rastreavel = this.utilDocumentoEletronico.gerarRastreabilidade(produto, movimentacaoProduto);
      item.medicamentos = this.utilDocumentoEletronico.gerarMedicamentos(produto);
      this.utilDocumentoEletronico.gerarCalculoDesonerado(produto, movimentacaoProduto);
      this.utilDocumentoEletronico.gerarProdutoFormulado(produto);
      if (this.utilDocumentoEletronico.informacaoComplementarProduto && this.utilDocumentoEletronico.informacaoComplementarProduto.length > 500) {
        this.utilDocumentoEletronico.informacaoComplementarProduto.substring(0, 497) + '...';
      }

      // if (nfe.destinatario.endereco.estado == 'EX') {
      //   this.utilSessao.mensagemProcessandoNFe('Informações importação');
      //   item.importacao = this.utilDocumentoEletronico.gerarImportacao("fff", movimentacaoProduto.ordem.toString());
      // }

      item.informacoesComplementares = this.utilDocumentoEletronico.informacaoComplementarProduto;
      this.removerParametros(item);
      itens.push(item);
    });
    return itens;
  }

  removerParametros(item: Item): void {
    for (var atributo in item) {
      if (item[atributo] === null || item[atributo] === undefined || item[atributo].length == 0) {
        delete item[atributo];
      }
    }
  }

  formatarDataPadrao(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }

  private gerarNfePagamentos(movimentacao: Movimentacao): Pagamento[] {
    this.utilSessao.mensagemProcessandoNFe('Pagamentos');
    let pagamentos: Pagamento[] = [];
    const FINALIDADE_TRANSFERENCIA: number = 2;
    const FINALIDADE_DEVOLUCAO: number = 3;
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    let pagamento: Pagamento;
    if (movimentacao.movimentacaoParcelas.length == 0 || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_TRANSFERENCIA || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_COMPLEMENTO || movimentacao.naturezaOperacaoFinalidade == FINALIDADE_AJUSTE) {
      pagamento = new Pagamento();
      pagamento.valor = 0;
      pagamento.meio = '90';
      pagamentos.push(pagamento);
    } else {
      movimentacao.movimentacaoParcelas.forEach((movimentacaoParcela) => {
        pagamento = new Pagamento();
        pagamento.valor = movimentacaoParcela.getValorParcela();
        pagamento.meio = pagamento.valor == 0 ? '90' : '99';
        if (pagamento.meio == '99') {
          pagamento.descricaoMeio = this.bibDialogo.outroMeiodePagamento;
        }
        pagamentos.push(pagamento);
      });
    }
    return pagamentos;
  }

  completaEsquerda(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  private gerarNfeCobrancas(movimentacao: Movimentacao): Cobranca {
    let cobranca: Cobranca = new Cobranca();
    const FINALIDADE_TRANSFERENCIA: number = 2;
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (movimentacao.movimentacaoParcelas.length > 0 && movimentacao.naturezaOperacaoFinalidade != FINALIDADE_TRANSFERENCIA && movimentacao.naturezaOperacaoFinalidade != FINALIDADE_DEVOLUCAO) {
      this.utilSessao.mensagemProcessandoNFe('Cobrança');
      cobranca.numero = this.completaEsquerda(movimentacao.movimentacaoParcelas.length.toString(), '0', 3);
      cobranca.valorTotal = movimentacao.valorTotal;
      cobranca.valorDesconto = 0;
      cobranca.valorLiquido = movimentacao.valorTotal;
      let parcelas: Parcelas[] = [];
      let i: number = 1;
      for (let movimentacaoParcela of movimentacao.movimentacaoParcelas) {
        let parcela: Parcelas = new Parcelas();
        parcela.numero = this.completaEsquerda(i.toString(), '0', 3);
        parcela.dataVencimento = this.formatarData(movimentacaoParcela.dataVencimento);
        parcela.valor = movimentacaoParcela.getValorParcela();
        parcelas.push(parcela);
        i += 1;
      }
      cobranca.parcelas = parcelas;
    }
    return cobranca;
  }

  private gerarNotaReferenciada(movimentacao: Movimentacao): NotaReferenciada {
    this.utilSessao.mensagemProcessandoNFe('Nota Referência');
    if (movimentacao.movimentacaoChaves.length > 0 && movimentacao.movimentacaoNfe.finalidade != '1') {
      let notaReferenciada: NotaReferenciada = new NotaReferenciada();
      movimentacao.movimentacaoChaves.forEach((movimentacaoChave) => {
        if (['55', '65'].includes(movimentacaoChave.modelo)) {
          const notaReferenciadaNfe: NotaReferenciadaNfe = new NotaReferenciadaNfe(movimentacaoChave.chave);
          notaReferenciada.nfe.push(notaReferenciadaNfe);
        }
      });
      return notaReferenciada;
    }
    return null;
  }

  baixarXml(idNota: string, status: number, chaveFiscal: string, idLoja: number): void {
    if (idNota != null) {
      if (status == 135) {
        this.comunicacaoTecnoSpeedService.consumirXml(this.bibServicoTecnoSpeed.getUrlBaixaXmlCancelamentoNfe(this.servico, idNota)).subscribe(
          (res) => {
            this.downloadXml(res);
          },
          (erro) => {
            this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
          }
        );
      } else {
        this.comunicacaoTecnoSpeedService.consumirXml(this.bibServicoTecnoSpeed.getUrlBaixaXmlNfe(this.servico, idNota)).subscribe(
          (res) => {
            this.downloadXml(res);
          },
          (erro) => {
            this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
          }
        );
      }
    } else {
      if (chaveFiscal != null) {
        const utilDocumentoEletronicoNs = new UtilDocumentoEletronicoNs(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
        utilDocumentoEletronicoNs.baixarXml(chaveFiscal, idLoja);
      }
    }
  }

  baixarPdf(idNota: string, chaveFiscal: string, idLoja: number): void {
    if (idNota != null) {
      this.comunicacaoTecnoSpeedService.consumirPdf(this.bibServicoTecnoSpeed.getUrlBaixaPdfNfe(this.servico, idNota)).subscribe(
        (res) => {
          this.downloadPdf(res);
        },
        (error) => {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
        }
      );
    } else {
      if (this.usaSat) {
        const utilDocumentoEletronicoNsSat = new UtilDocumentoEletronicoNsSat(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
        utilDocumentoEletronicoNsSat.gerarReimpressao(chaveFiscal);
      } else {
        if (chaveFiscal != null) {
          const utilDocumentoEletronicoNs = new UtilDocumentoEletronicoNs(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
          utilDocumentoEletronicoNs.baixarPdf(chaveFiscal, idLoja);
        }
      }
    }
  }

  downloadPdf(data: any): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  downloadXml(data: any): void {
    const blob = new Blob([data], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    var link: any = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
  }

  abrirJsonResumoNfe(data: any): void {
    const blob = new Blob([data], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  abrirResumoNfe(idNota: string): void {
    this.comunicacaoTecnoSpeedService.consumirResumoNfe(this.bibServicoTecnoSpeed.getUrlResumoNfe(this.servico, idNota)).subscribe(
      (res) => {
        this.abrirJsonResumoNfe(res);
      },
      (error) => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.tenteNovamente));
      }
    );
  }

  buscarXml(idNota: string, formata: boolean = false): void {
    const url: string = 'https://api.plugnotas.com.br/nfe/' + idNota + '/xml';
    this.comunicacaoTecnoSpeedService.consumirXml(url).subscribe(
      (res) => {
        this.converterBlobParaString(res)
          .then((string) => this.copiarAbirXml(string, formata))
          .catch((error) => console.error(error));
      },
      (erro) => {
        console.error(erro);
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.tenteNovamente));
      }
    );
  }

  converterBlobParaString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result as string);
      };
      reader.onerror = function (event) {
        reject(event.target.error);
      };
      reader.readAsText(blob);
    });
  }

  copiarAbirXml(xml: string, formata): void {
    navigator.clipboard.writeText(xml)
      .then(() => {
        if (formata) {
          window.open('https://www.site24x7.com/pt/tools/xml-formatador.html', "_blank");
        } else {
          window.open('https://validador.nfe.tecnospeed.com.br/', "_blank");
        }
      })
      .catch((error) => {
        console.error('Falha ao copiar texto para a área de transferência:', error);
      });
  }

  abrirModalCartaCorrecao(movimentacao: Movimentacao): void {
    this.utilSessao.setIdentificacao(new Identificacao('movimentacaoNfe', movimentacao.movimentacaoNfe));
    this.utilSessao.setIdentificacao(new Identificacao('parceiro', movimentacao.parceiro));
    this.utilSessao.setIdentificacao(new Identificacao('data', movimentacao.data));
    this.utilSessao.setIdentificacao(new Identificacao('chaveFiscal', movimentacao.chaveFiscal));
    this.componente = CartaCorrecaoComponent;
  }

  abrirModalMovimentacao(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }

  ehAbrirModalCancelamento(movimentacao: Movimentacao): void {
    if (movimentacao.data.setHours(0, 0, 0, 0) != this.dataAtual.setHours(0, 0, 0, 0)) {
      if (confirm(this.bibDialogo.desejaCancelarForaPrazo)) {
        this.abrirModalCancelamento(movimentacao);
      }
    } else {
      this.abrirModalCancelamento(movimentacao);
    }
  }

  abrirModalCancelamento(movimentacao: Movimentacao): void {
    if (movimentacao.ativo == 'S') {
      this.utilSessao.setIdentificacao(new Identificacao('movimentacao', movimentacao));
      this.utilSessao.setIdentificacao(new Identificacao('protocoloCancelamento', movimentacao.movimentacaoNfe.protocoloCancelamento != null));
      this.componente = CancelamentoFrmComponent;
    } else {
      if (confirm(bibDialogo.enviarSolicitacaoCancelamento)) {
        this.persistirCancelamento(movimentacao);
      }
    }
  }

  preVisualizarDanfe(idMovimentacao: number): void {
    this.util.abrirRelatorio(idMovimentacao, 2020, false, true);
  }
}