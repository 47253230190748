import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { bibServicoNsCTe } from 'src/app/biblioteca/bibServicoNsCTe';
import { CteNs } from 'src/app/integracao/ns/cte/CTe';
import { InfModal } from 'src/app/integracao/ns/cte/infModal';
import { Rodo } from 'src/app/integracao/ns/cte/rodo';
import { Criterio } from 'src/app/modelo/criterio';
import { Cte } from 'src/app/modelo/cte';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { CteFrmComponent } from '../cte/cteFrm.component';
import filtros from './filtro';
import { UtilDocumentoEletronicoCte } from './utilDocumentoEletronicoCte';

@Component({
  templateUrl: './documentoEletronicoCTe.component.html',
})
export class DocumentoEletronicoCTeComponent extends PaginaComponent {
  private modalEmt: any;
  public emiteEmHomologacao: boolean = false;
  public emiteEmContingencia: boolean = false;
  public bibServicoNsCTe: bibServicoNsCTe = new bibServicoNsCTe();
  public componente: any;
  public dataAtualizacao: Date;
  public filtros = filtros;
  public ctes: Cte[] = [];
  public intervaloAtualizarCTe: any;
  // public servico: string;
  public loja: Loja;
  public lojaCnpj: string;
  public ctesPersistir: Cte[];
  public plainToClass: Function = plainToClass;
  public utilDocumentoEletronicoCte = new UtilDocumentoEletronicoCte(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsCTeService, this.bibServicoNsCTe, this.bibDialogo, this.bibServico, this.bibClasse);

  ngOnInit(): void {
    //this.servico = 'cte';
    this.ehListarAtalhoCte();
    clearInterval(this.intervaloAtualizarCTe);
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
    clearInterval(this.intervaloAtualizarCTe);
  }

  ehListarAtalhoCte(): void {
    const idCte: number = this.utilSessao.getIdentificacao('idCte')?.conteudo;
    if (idCte) {
      this.listar([], 0, 0, [idCte]);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, idCtes: number[] = []): void {
    clearInterval(this.intervaloAtualizarCTe);
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    if (idCtes.length > 0) {
      criterios.push(new Criterio('IDS', idCtes.toString()));
    }

    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.cte).subscribe((res) => {
      this.ctes = this.plainToClass(Cte, res) as any;
      if (this.ctes.length > 0) {
        this.lojaCnpj = this.util.retirarCaracteresInvalidos(this.utilSessao.getLojas().find((loja) => loja.id == this.ctes[0].idLoja).cnpj);

        this.loja = this.utilSessao.getLojas().find((loja) => loja.id == this.ctes[0].idLoja);
        this.emiteEmContingencia = this.loja.cteEnvio == 'NORMAL' ? false : true;
        this.emiteEmHomologacao = this.loja.cteAmbiente == 1 ? false : true;
      }
    });
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', () => {
      abaAtiva = abaAtiva == true ? false : true;
    });
  }

  setCteSelecionado(cte: Cte): void {
    cte.selecionado = cte.selecionado == 'S' ? 'N' : 'S';
  }

  abrirResumoCte(cte: Cte): void {
    this.utilDocumentoEletronicoCte.iniciar(cte).subscribe(() => {
      this.utilSessao.iniciarProcesso();
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == cte.idLoja);
      let CTe = new CteNs();
      let infModal = new InfModal();
      let rodo = new Rodo();
      infModal.rodo = rodo;
      infModal.rodo.RNTRC = cte.rntrc;
      CTe = this.utilDocumentoEletronicoCte.transformarCteEmCteNs(CTe, cte, loja);
      const transporte = JSON.stringify({
        "X-AUTH-TOKEN": this.bibServicoNsCTe.getToken(),
        CTe,
        infModal
      });
      this.comunicacaoNsCTeService.persistir(this.bibServicoNsCTe.getUrlPreviaCte(), transporte).subscribe(
        (res) => {
          var data = Uint8Array.from(atob(res.pdf), c => c.charCodeAt(0));
          var blob = new Blob([data], { type: 'application/pdf' });
          window.open(window.URL.createObjectURL(blob));
        },
        (erro) => {
          var reader = new FileReader();
          reader.readAsDataURL(erro.error.text);
          this.downloadPdf(erro.error.text);
        }
      );
      this.utilSessao.finalizarProcesso();
    });
  }

  consultarCte(cte: Cte): void {
    if (!this.utilSessao.falha) {
      this.utilSessao.iniciarProcesso();
      this.comunicacaoNsCTeService.consultar(this.bibServicoNsCTe.getUrlConsultar(), this.lojaCnpj, cte.chave, this.emiteEmHomologacao ? "2" : "1").subscribe((res) => {
        cte.situacao = res.retConsSitCTe.cStat;
        cte.situacaoDescricao = res.retConsSitCTe.cStat.toString() + ' - ' + res.retConsSitCTe.xMotivo;
        cte.protocolo = res.retConsSitCTe.protCTe.infProt.nProt.toString();
        cte.dataHora = res.retConsSitCTe.protCTe.infProt.dhRecbto.toString();
        if (cte.situacao.toString() == "101") {
          cte.ativo = 'N';
          cte.status = 'N';
          cte.motivoCancelamento = 'ERRO NA EMISSÃO DO CTE'
          cte.protocoloCancelamento = res.retConsSitCTe.procEventoCTe[0].retEventoCTe.infEvento.nProt.toString();
          cte.dataHoraCancelamento = res.retConsSitCTe.procEventoCTe[0].retEventoCTe.infEvento.dhRegEvento.toString();
        }
        cte.expandido = false;
        this.persistir(new Transporte(cte), this.bibServico.cte, null).subscribe(() => {
          this.listar([], 0, 0, [cte.id]);
          this.utilSessao.finalizarProcesso();
        });
      });
    }
  }

  consultaEmissaoCte(cte: Cte): void {
    if (!this.utilSessao.falha) {
      this.comunicacaoNsCTeService.listar(this.bibServicoNsCTe.getUrlConsultarEmissao(), this.lojaCnpj, cte.codigoIntegracao, this.emiteEmHomologacao ? "2" : "1").subscribe((res) => {
        if (res.cStat == '100') {
          cte.situacao = res.cStat;
          cte.situacaoDescricao = res.cStat.toString() + ' - ' + res.xMotivo;
          cte.protocolo = res.nProt.toString();
          cte.chave = res.chCTe.toString();
        } else {
          cte.situacao = -1;
          cte.situacaoDescricao = res.cStat.toString() + ' - ' + res.xMotivo;
        }
        cte.expandido = false;
        this.persistir(new Transporte(cte), this.bibServico.cte, null).subscribe(() => {
          this.listar([], 0, 0, [cte.id]);
          this.utilSessao.finalizarProcesso();
        });
      });
    }
  }

  listarCTeConfirmacao(criterios: Criterio[]): Observable<Cte> {
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cte);
  }

  persistirCancelamento(cte: Cte): void {
    this.utilSessao.iniciarProcesso();
    this.comunicacaoNsCTeService.consumirCancelamento(this.bibServicoNsCTe.getUrlCancelamento(), cte.chave, cte.protocolo, 'ERRO NA EMISSÃO DO CTE', this.emiteEmHomologacao ? "2" : "1").subscribe(
      (res) => {
        this.consultarCte(cte);
      },
      (erros) => {
      }
    );
    this.utilSessao.finalizarProcesso();
  }

  verificarDescricao(descricao: string): string {
    if (descricao && descricao.length > 1000) {
      return descricao.substring(0, 997) + '...';
    }
    return descricao;
  }

  persistirCte(ctes: Cte[]): Observable<any> {
    if (ctes[0].situacao != 2) {
      if (ctes[0].situacao != 5) {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.cteMensagemRetornoSucesso, [ctes[0].situacaoDescricao, ctes[0].numero, ctes[0].chave])));
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.cteMensagemRetornoErro, [ctes[0].situacaoDescricao])));
      }
    }
    return this.persistir(new Transporte(ctes), this.bibServico.cte, null);
  }

  iniciarProcessamentoCte(): void {
    const id = this.ctes.filter((cte) => cte.selecionado == 'S').map((cte) => cte.id);
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id.toString())), this.bibServico.cte).subscribe((res) => {
      const ctePersistir: Cte[] = this.plainToClass(Cte, res)
      if (ctePersistir.length == 1) {
        const cte: Cte = ctePersistir[0];
        if (cte.situacao != 3 && cte.situacao != 4 && cte.situacao != 6) {
          const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == cte.idLoja);
          this.utilDocumentoEletronicoCte.iniciar(cte).subscribe(() => {
            this.emitirCte(cte, loja);
          });
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarValidacaoCTe));
        }
      } else {
        if (this.ctesPersistir.length == 0) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarValidacaoCTeSomenteUm));
        }
      }
    });
  }

  ehEnviarEmail(idParceiro: number, cte: Cte): void {
    if (cte.situacao != 100 && cte.situacao != 101) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarEmailCTeSomenteEnviado));
    } else {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiro)), this.bibServico.parceiro).subscribe((res) => {
        const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
        if (parceiro && parceiro.email) {
          if (confirm(this.util.substituir(this.bibDialogo.enviarEmailCteParaDestinatario, [parceiro.email]))) {
            this.enviarEmail(parceiro.email, cte);
          }
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.destinatarioNaoPossuiEmailCadastrado));
        }
      });
    }
  }

  enviarEmail(email: string, cte: Cte): void {
    this.utilSessao.iniciarProcesso();
    this.comunicacaoNsCTeService.enviarEmail(this.bibServicoNsCTe.getUrlEmail(), email, cte.chave, this.emiteEmHomologacao ? "2" : "1").subscribe(
      (res) => {
        console.log('Enviar email')
      },
      (erros) => {
        console.log('erros ao enviar email');
      }
    );

    this.utilSessao.finalizarProcesso();
  }

  apenasNumeros(valor: string): string {
    let numsStr = valor.replace(/[^0-9]/g, '');
    return numsStr;
  }

  private emitirCte(cteEmitido: Cte, loja: Loja): void {
    this.utilSessao.iniciarProcesso();
    let CTe = new CteNs();
    let infModal = new InfModal();
    let rodo = new Rodo();
    infModal.rodo = rodo;
    infModal.rodo.RNTRC = cteEmitido.rntrc;
    CTe = this.utilDocumentoEletronicoCte.transformarCteEmCteNs(CTe, cteEmitido, loja);
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNsCTe.getToken(),
      CTe,
      infModal
    });
    this.comunicacaoNsCTeService.persistir(this.bibServicoNsCTe.getUrlEmissao(), transporte).subscribe(
      (res) => {
        cteEmitido.codigoIntegracao = res.nsNRec;
        cteEmitido.situacao = res.status;
        cteEmitido.situacaoDescricao = res.motivo;
        cteEmitido.chave = res.chCTe;
        cteEmitido.expandido = false;
        this.persistir(new Transporte(cteEmitido), this.bibServico.cte, null).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.consultaEmissaoCte(cteEmitido);
          }
          this.listar([], 0, 0, [cteEmitido.id]);
          this.utilSessao.finalizarProcesso();
        });
      },
      (error) => {
        let erroEstrutura: string = "";
        if (error && error.error.erros) {
          for (let i = 0; i < error.error.erros.length; i++) {
            erroEstrutura += error.error.erros[i];
          }
        }
        cteEmitido.situacao = error.error.status;
        cteEmitido.situacaoDescricao = erroEstrutura ? erroEstrutura.substring(0, 999) : error.error && error.error.motivo && error.error.motivo.replace('[', '').replace(']', '').replace('{', '').replace('}', '').substring(0, 999);
        this.persistirCte([cteEmitido]);
        this.listar([], 0, 0, [cteEmitido.id]);
        this.utilSessao.finalizarProcesso();
      }
    );
  }

  formatarDataPadrao(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }

  baixarXml(cte: Cte): void {
    if (cte.situacao != 100 && cte.situacao != 101) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.downloadValidacao, ['XML'])));
    } else {
      this.comunicacaoNsCTeService.consumirPdfXml(this.bibServicoNsCTe.getUrlPdf(), this.lojaCnpj, cte.chave, this.emiteEmHomologacao ? "2" : "1", "X").subscribe(
        (res) => {
          const blob = new Blob([res.xml], { type: 'application/xml' });
          const url = window.URL.createObjectURL(blob);
          var link: any = document.createElement('a');
          link.href = url;
          link.download = url.substring(url.lastIndexOf('/') + 1);
          link.click();
        },
        (erro) => {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
        }
      );
    }
  }

  baixarPdf(cte: Cte): void {
    if (cte.situacao != 100 && cte.situacao != 101) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.downloadValidacao, ['PDF'])));
    } else {
      this.comunicacaoNsCTeService.consumirPdfXml(this.bibServicoNsCTe.getUrlPdf(), this.lojaCnpj, cte.chave, this.emiteEmHomologacao ? "2" : "1", "P").subscribe(
        (res) => {
          var data = Uint8Array.from(atob(res.pdf), c => c.charCodeAt(0));
          var blob = new Blob([data], { type: 'application/pdf' });
          window.open(window.URL.createObjectURL(blob));
        },
        (erro) => {
          var reader = new FileReader();
          reader.readAsDataURL(erro.error.text);
          this.downloadPdf(erro.error.text);
        }
      );
    }
  }

  downloadPdf(data: any): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  downloadXml(data: any): void {
    const blob = new Blob([data], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    var link: any = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
  }

  abrirModalCte(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = CteFrmComponent;
  }

  enviarCancelamento(cte: Cte) {
    if (cte.situacao != 100) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarCancelamentoCTeSomenteEnviado));
    } else {
      if (confirm(this.bibDialogo.enviarSolicitacaoCancelamento)) {
        this.persistirCancelamento(cte);
      }
    }
  }
}
