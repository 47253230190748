import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Produto } from 'src/app/modelo/produto';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './produto.component.html',
})
export class ProdutoComponent extends PaginaComponent {
  public produtos: Produto[] = [];
  public filtros: Filtro[] = filtros;
  public rotuloGrupo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 11).apelido;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, nomeCampoOrdem: string = ''): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.produto, false, true, true, true, 120000, false, '', nomeCampoOrdem).subscribe((res) => {
      this.produtos = this.plainToClass(Produto, res) as any;
    });
  }

  excluirProduto(id: number, produto: string): void {
    super.excluir<Produto>(id, this.produtos, produto);
  }

  clonarProduto(id: number, posicao: number, produtos: Produto[]): void {
    this.ir(id, posicao, produtos);
    this.utilSessao.setIdentificacao(new Identificacao('clone', true));
  }
}
