import { Type } from 'class-transformer';

export class Generico {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public abreviacao: string;
  public ativo: string = 'S';
  public cor: string;
  public id: number;
  public idCor: number = 1;
  public idEmpresa: number;
  public idImagem: number = 1;
  public idLoja: number = 0;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public imagem: string;
  public loja: string;
  public menu: string;
  public nome: string;
  public tipo: string;
  public url: string;
  public usuarioAlteracao: string;
  public usuarioIncluscao: string;
}
