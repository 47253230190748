import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import filtros from './acessorio/filtro';

import { RelatorioComponent } from '../relatorio.component';

@Component({
  templateUrl: './r2027.component.html',
})
export class R2027Component extends RelatorioComponent {
  public filtros = filtros;
  public estoques: any[] = [];
  public totalCustoGerencial: number;
  public totalCustoMedio: number;
  public totalCustoReposicao: number;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.estoques = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Estoque').lista;
      this.totalizarEstoques();
      this.ehImprimirRelatorio();
    });
  }

  totalizarEstoques(): void {
    this.totalCustoReposicao = 0;
    this.totalCustoGerencial = 0;
    this.totalCustoMedio = 0;
    this.estoques.forEach((estoque) => {
      this.totalCustoReposicao += estoque.quantidadeDisponivel * estoque.custoReposicao;
      this.totalCustoGerencial += estoque.quantidadeDisponivel * estoque.custoGerencial;
      this.totalCustoMedio += estoque.quantidadeNfe * estoque.custoNfe;
    });
  }
}
