<layout [titulo]="bibDialogo.romaneioEntrega" [id]="romaneioEntrega.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm-3" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="romaneioEntrega.numero" (alteracao)="romaneioEntrega.numero = $event" [desabilitado]="true"></texto>
    <data class="col-sm-4" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="romaneioEntrega.data" (alteracao)="romaneioEntrega.data = $event" [obrigatorio]="true" [desabilitado]="bloqueado"></data>
  </div>
  <div *ngIf="visualizaLoja" class="row">
    <lista class="col-sm-12" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="romaneioEntrega.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="setLoja($event)" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()" [ajudaDireita]="true" [ajuda]="bibDialogo.lojaCadastro" [desabilitado]="bloqueado" [foco]="true"></lista>
  </div>
  <div class="row">
    <titulo class="col-sm-12" [titulo]="bibDialogo.transporte" [imagem]="bibImagem.transporte"></titulo>
    <busca class="col-sm-12" [id]="'transportadora'" [idMenu]="menuTransportadora.idMenu" [rotulo]="menuTransportadora.apelido" [campo]="romaneioEntrega.parceiroTransportadora" (alteracao)="setTransportadora($event)" [desabilitado]="bloqueado" [servico]="bibServico.transportadora" [obrigatorio]="true" [atencao]="'3'"></busca>
    <lista class="col-sm-4" [rotulo]="bibDialogo.veiculo" [campo]="romaneioEntrega.idVeiculo" (alteracao)="setVeiculo($event)" [lista]="parceiroVeiculos" [desabilitado]="bloqueado" [obrigatorio]="true"> </lista>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.uf + ' ' + bibDialogo.placa.toLowerCase()" [campo]="romaneioEntrega.placaUf"></apresentacao>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.placa" [campo]="romaneioEntrega.placa"></apresentacao>
    <texto class="col-sm-8" [id]="'motoristaNome'" [rotulo]="bibDialogo.motorista" [campo]="romaneioEntrega.motoristaNome" (alteracao)="romaneioEntrega.motoristaNome = $event" [desabilitado]="bloqueado" [obrigatorio]="true"></texto>
    <mascara class="col-sm-4" [id]="'motoristaCpf'" [rotulo]="bibDialogo.cpf + ' ' + bibDialogo.motorista.toLowerCase()" [campo]="romaneioEntrega.motoristaCpf" (alteracao)="setCpf(romaneioEntrega, $event)" [obrigatorio]="true" [tipo]="bibPropriedade.mascara.cpf" [atencao]="!romaneioEntrega.ehValidoCpf && romaneioEntrega.motoristaCpf ? bibDialogo.incorreto : null" [obrigatorio]="true" [desabilitado]="bloqueado"></mascara>
  </div>
  <div class="row">
    <romaneioEntregaDocumentoFiscal [romaneioEntrega]="romaneioEntrega" [excluirDocumentosFiscais]="excluirDocumentosFiscais" [bloqueado]="bloqueado"></romaneioEntregaDocumentoFiscal>
  </div>
  <titulo class="col-sm-12" [titulo]="bibDialogo.observacao" [imagem]="bibImagem.aspas"></titulo>
  <descricao class="col-sm-12" [campo]="romaneioEntrega.observacao" (alteracao)="romaneioEntrega.observacao = $event" [id]="'observação'" [desabilitado]="bloqueado" [maximoCaracteres]="5000"></descricao>
</layout>
<bloqueado [bloqueado]="romaneioEntrega.status == 'S'"></bloqueado>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirRomaneioEntrega()" [desabilitado]="bloqueado"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.finalizar" [fundo]="true" (botaoEmt)="finalizar()" [desabilitado]="bloqueado"></botao>
<auditoria *ngIf="romaneioEntrega.id" [idUsuarioInclusao]="romaneioEntrega.idUsuarioInclusao" [dataHoraInclusao]="romaneioEntrega.dataHoraInclusao" [idUsuarioAlteracao]="romaneioEntrega.idUsuarioAlteracao" [dataHoraAlteracao]="romaneioEntrega.dataHoraAlteracao"></auditoria>
