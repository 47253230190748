<layout [titulo]="nomeRoteiroContabil ? nomeRoteiroContabil : menuRoteiroContabil" [id]="roteiroContabilRateio.id">
  <div class="row">
    <busca class="col-sm-12" [id]="'contaDebito'" [rotulo]="bibDialogo.contaDebito" [campo]="roteiroContabilRateio.contaContabil" [buscarPorId]="true" (alteracao)="setConta($event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [obrigatorio]="true" [foco]="true"></busca>
    <busca *ngIf="roteiroContabilRateio.contaContabilCorrentista == 2" class="col-sm-12" [id]="'parceiro'" [rotulo]="bibDialogo.clienteFornecedor" [campo]="roteiroContabilRateio.parceiro" (alteracao)="setParceiro($event)" [servico]="bibServico.parceiro" [obrigatorio]="true"></busca>
    <busca *ngIf="roteiroContabilRateio.contaContabilCorrentista == 4" class="col-sm-12" [id]="'contaBancaria'" [rotulo]="menuConta.apelido" [campo]="roteiroContabilRateio.contaBancaria" (alteracao)="setContaBancaria($event)" [servico]="bibServico.conta" [obrigatorio]="true"></busca>
    <busca class="col-sm-12" [id]="'contaCredito'" [rotulo]="bibDialogo.contaCredito" [campo]="roteiroContabilRateio.contaContabilContrapartida" [buscarPorId]="true" (alteracao)="setContaContrapartida($event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [obrigatorio]="true"></busca>
    <busca *ngIf="roteiroContabilRateio.contaContabilCorrentistaC == 2" class="col-sm-12" [id]="'parceiroContrapartida'" [rotulo]="bibDialogo.clienteFornecedor" [campo]="roteiroContabilRateio.parceiroContrapartida" (alteracao)="setParceiroContrapartida($event)" [servico]="bibServico.parceiro" [obrigatorio]="true"></busca>
    <busca *ngIf="roteiroContabilRateio.contaContabilCorrentistaC == 4" class="col-sm-12" [id]="'contaBancariaContrapartida'" [rotulo]="menuConta.apelido" [campo]="roteiroContabilRateio.contaBancariaContrapartida" (alteracao)="setContaBancariaContrapartida($event)" [servico]="bibServico.conta" [obrigatorio]="true"></busca>
  </div>
  <div class="row">
    <pesquisaTextoBusca class="col-sm-12" [id]="'historico'" [idMenu]="permissaoHistorico && permissaoHistorico.inserir == 'S' ? menuHistorico.idMenu : null" [rotulo]="menuHistorico.apelido" [campo]="roteiroContabilRateio.historico" (alteracao)="roteiroContabilRateio.historico = $event" [obrigatorio]="true" [icone]="bibIcone.lupa" [criterios]="criterioRotulo" [servico]="bibServico.historico" [minimoCaracterBusca]="2"></pesquisaTextoBusca>
  </div>
  <div class="row">
    <lista class="col-sm" [id]="'tipoLancamentoContabil'" [rotulo]="menuTipoLancamentoContabil.apelido" [campo]="roteiroContabilRateio.idTipoLancamentoContabil" (alteracao)="setTipoLancamentoContabil($event)" [lista]="tipoLancamentoContabeis" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="listarTipoLancamentoContabil()" [idMenu]="permissaoTipoLancamentoContabil && permissaoTipoLancamentoContabil.inserir == 'S' ? menuTipoLancamentoContabil.idMenu : null"></lista>
    <decimal class="col-sm-4" [id]="'percentual'" [rotulo]="bibDialogo.percentual" [campo]="roteiroContabilRateio.percentual" (alteracao)="roteiroContabilRateio.percentual = $event" [obrigatorio]="true" [sufixo]="'%'"></decimal>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistiRoteiroContabilRateio()"></botao>
