<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-30 clique" (click)="listar(add('nomePopular'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.praga }}</th>
        <th class="l-30 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nomeCientifico }}</th>
        <th *ngIf="integracaoEidaf" class="l-10" (click)="listar(add('eidaf'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.integracao }}</th>
        <th *ngIf="integracaoAgroDefesa" class="l-10" (click)="listar(add('agroDefesa'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.integracao }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let praga of pragas; let i = index">
        <tr>
          <td>
            <a href="#" (click)="ir(praga.id, i, pragas)">{{ praga.nome }}</a>
          </td>
          <td>{{ praga.nomeCientifico }}</td>
          <td *ngIf="integracaoEidaf">{{ praga.eidaf }}</td>                   
          <td *ngIf="integracaoAgroDefesa">{{ praga.agroDefesa }}</td>    
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(praga.id, i, pragas)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirPraga(praga.id, praga.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
