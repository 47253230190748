import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { Vinculo } from 'src/app/modelo/vinculo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtros';

@Component({
  templateUrl: './vinculo.component.html',
})
export class VinculoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public vinculos: Vinculo[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.vinculo).subscribe((res) => {
      this.vinculos = this.plainToClass(Vinculo, res) as any;
    });
  }

  excluirVinculo(id: number, nome: string): void {
    super.excluir<Vinculo>(id, this.vinculos, nome);
  }
}
