import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivoCidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtroComboOrigem: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.manual, id: 1 },
  { nome: bibDialogo.financeiro, id: 2 },
  { nome: bibDialogo.movimentacao, id: 3 },
  { nome: bibDialogo.estornoFinanceiro, id: 5 },
]);

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

const filtros = {
  lancamentoContabil: plainToClass(Filtro, [
    { id: 'tipoLancamento', rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
    { id: 'id', rotulo: bibDialogo.lote, nome: 'CHAVE', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'valorDe', rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'valorAte', rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivoCidade },
    { id: 'origem', rotulo: bibDialogo.origem, nome: 'ORIGEM', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboOrigem },
    { id: 'usuario', rotulo: bibDialogo.usuario, nome: 'ID_USUARIO_ALTERACAO', coluna: 6, tipo: bibPropriedade.filtro.busca, servicoWeb: bibServico.usuario },
    { id: 'historico', rotulo: bibDialogo.historico, nome: 'HISTORICO', coluna: 12, tipo: bibPropriedade.filtro.texto},
  ]),
  lancamentoContabilRoteiro: plainToClass(Filtro, [
    { id: 'tipoLancamento', rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', coluna: 6, obrigatorio: 'S', servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
    { id: 'id', rotulo: bibDialogo.identificacao, nome: 'ID', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'valorDe', rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'valorAte', rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivoCidade },
  ]),
};
export default filtros;
