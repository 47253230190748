import { Component } from '@angular/core';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2025.component.html',
  styleUrls: ['./r2025.component.css'],
})
export class R2025Component extends RelatorioComponent {
  public filtros = filtros;
  public contaBancaria: any;
  public conciliacoes: any[] = [];  
  public mostrarTransferenciaAutomatica: boolean = false;
  public usaTipoTitulo: boolean = false;
  public bibPropriedade = bibPropriedade;
  public caixa: any;
  public conciliacoesAgrupadasTipoTitulos: any[] = [];
  public conciliacoesFiltradas: any[] = [];
  public conciliacoesCompletas: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_CAIXA', this.utilSessao.getIdCaixa()));
    if(criterios[0].nome == 'ID'){
      criterios.splice(0, 1);
    }
    super.listar(criterios).subscribe((res) => {      
      this.conciliacoes = [];
      this.conciliacoesFiltradas = [];
      this.conciliacoesCompletas = [];
      this.caixa = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Caixa').lista[0];      
      this.conciliacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Conciliacao').lista; 
      this.conciliacoesCompletas = this.conciliacoes;     
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N'));
      this.ehImprimirRelatorio();      
      this.totalizarDebitoCredito()
      this.agruparConciliacoesPorTipoTitulo();
      this.filtrarConciliacoes();
      this.conciliacoes = this.conciliacoesFiltradas;
    });
  }



  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.mostrarTransferenciaAutomatica = this.definirRegra(relatorioAdicional, 233, this.mostrarTransferenciaAutomatica);    
    if(this.mostrarTransferenciaAutomatica){
      this.conciliacoes = this.conciliacoesCompletas;
    }else{
      this.conciliacoes = this.conciliacoesFiltradas;
    }
    this.totalizarDebitoCredito();
    this.agruparConciliacoesPorTipoTitulo();
  }

  totalizarDebitoCredito(): void {
    let receita: number = 0;
    let despesa: number = 0;
    if (this.conciliacoes.length > 0) {
      let saldo: number = this.caixa.saldoAnterior;
      this.conciliacoes.forEach((conciliacao) => {
        saldo += conciliacao.receita ? conciliacao.receita : 0;
        saldo -= conciliacao.despesa ? conciliacao.despesa : 0;
        conciliacao.saldo = saldo;
        receita += conciliacao.receita ? conciliacao.receita : 0;
        despesa += conciliacao.despesa ? conciliacao.despesa : 0;
      });
      this.conciliacoes[0].receitaTotal = receita;
      this.conciliacoes[0].despesaTotal = despesa;
    }
  }

  agruparConciliacoesPorTipoTitulo(): void {  
    this.conciliacoesAgrupadasTipoTitulos = [];
    this.conciliacoes.forEach((conciliacao) => {
      const tipoTituloExistente = this.conciliacoesAgrupadasTipoTitulos.find(
        (conciliacaoTipoTitulo) => conciliacaoTipoTitulo.tipoTitulo === conciliacao.tipoTitulo
      );
      if (tipoTituloExistente) {
        tipoTituloExistente.valor += conciliacao.receita;
        tipoTituloExistente.valor -= conciliacao.despesa;
      } else {
        this.conciliacoesAgrupadasTipoTitulos.push({
          tipoTitulo: conciliacao.tipoTitulo,
          valor: conciliacao.receita - conciliacao.despesa,
        });
      }
    });
  }

  filtrarConciliacoes(): void {
    this.conciliacoes.forEach((conciliacao) => {
      if (conciliacao.chaveCartaoTransferencia == null || (conciliacao.chaveCartaoTransferencia != null && conciliacao.tipo == 'R' && conciliacao.idCaixa != null)) {
        this.conciliacoesFiltradas.push(conciliacao);
      }    
    })
  }
}
