import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.dataVencimento, campo: 'dataVencimento' },
  { rotulo: bibDialogo.fornecedor, campo: 'parceiro' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
  { rotulo: bibDialogo.vencidosAvencer, campo: 'atrasado' },
  { rotulo: bibDialogo.mes + '/' + bibDialogo.ano + ' ' + bibDialogo.vencimento, campo: 'mesAnoVencimento' },
  { rotulo: bibDialogo.contaContabil, campo: 'contaContabil' },
  { rotulo: new UtilFiltro().apelidoCentroResultado, campo: 'centroResultado' },
];

export default grupos;
