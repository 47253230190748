export class PatrimonialLancamentoContabil  {
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idPatrimonial: number;
  public data: Date;
  public valor: number = 0;
  public percentual: number = 0;
  public chave: string;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public alterado: boolean;
}
