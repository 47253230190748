import { TipoTituloCaixa } from './tipoTituloCaixa';

export class TipoTitulo {
  public ativo: string = 'S';
  public conciliacaoAutomatica: string = 'N';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dia: number;
  public diaConciliacaoPadrao: number;
  public especie: string;
  public especieNivel: number;
  public id: number;
  public idConta: number;
  public idEmpresa: number;
  public idEspecie: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public numeroTaxas: number;
  public padraoContaCorrente: string = 'N';
  public regraRecebimento: number;
  public sugestaoBaixa: string = 'N';
  public tipoFiscal: string;
  public tipoTituloCaixas: TipoTituloCaixa[] = [];
}
