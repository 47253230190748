import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1089.component.html',
})
export class R1089Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public resultadosABC: any[];
  public ABC = 0;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public usaDevolucoes: boolean = false;
  public apresentacao: string;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.resultadosABC = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'CurvaABC').lista;
      this.ordenarAgrupar(this.resultadosABC);
      this.somaABC(this.resultadosABC, this.totalizaSoma(this.resultadosABC));
      this.ehImprimirRelatorio();
      this.mudarRotulo(criterios);
    });
  }

  mudarRotulo(criterios: Criterio[]): void {
    this.apresentacao = criterios.find((criterio) => criterio.nome == 'CAMPO')?.apresentacao;
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaDevolucoes = this.definirRegra(relatorioAdicional, 257, this.usaDevolucoes);
  }

  totalizaSoma(lista: any[]): number {
    let i = 0;
    let total = 0;

    while (lista[i]) {
      total += lista[i].analiseABC;
      i++;
    }
    return total;
  }

  somaABC(lista: any[], total: number): void {
    let i: number = 0;
    let aux: number = 0;

    while (lista[i]) {
      if (i == 0) aux = lista[i].analiseABC;
      else aux = lista[i].analiseABC + lista[i - 1].somaABC;
      lista[i].somaABC = aux;

      if ((aux * 100) / total <= lista[i].curvaA) lista[i].curvaABC = 'A';
      else if ((aux * 100) / total <= lista[i].curvaA + lista[i].curvaB) lista[i].curvaABC = 'B';
      else lista[i].curvaABC = 'C';

      i++;
    }
  }
}
