<div class="row align-items-center">
  <titulo class="col-sm" [titulo]="bibDialogo.menu" [imagem]="bibImagem.regras"></titulo>
  <botao class="text-right" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.menu.toLowerCase()" (botaoEmt)="adicionarMenu()"></botao>
</div>
<div class="row" *ngIf="moduloEmpresa.menuModuloEmpresas.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.menu.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarMenu()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<div class="responsiva" *ngIf="moduloEmpresa.menuModuloEmpresas.length > 0">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10">{{ bibDialogo.ordem }}</th>
        <th class="l-85">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let menuModuloEmpresa of moduloEmpresa.menuModuloEmpresas; let i = index">
        <td><texto [campo]="menuModuloEmpresa.ordem" [id]="'menuModuloEmpresaOrdem' + i" [obrigatorio]="true" [tipo]="this.bibPropriedade.texto.inteiro" [compacto]="true" (alteracao)="setMenuModuloEmpresa(menuModuloEmpresa, $event)"></texto></td>
        <td>{{ menuModuloEmpresa.nome }}</td>
        <td><btnAdicional [icone]="bibIcone.lixo" [id]="'item' + i" (btnAdicional)="ehExcluir(i, moduloEmpresa.menuModuloEmpresas, idsMenuModuloEmpresaExcluir, menuModuloEmpresa.id)"></btnAdicional></td>
      </tr>
    </tbody>
  </table>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
