import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { R1002Component } from '../r1002.component';

@Component({
  selector: '<tr>[r1002itens]</tr>',
  templateUrl: './r1002itens.component.html',
})
export class R1002ItensComponent extends R1002Component {
  @Input() estoquePendencias: any;
  @Input() empresaRegraCasasDecimaisEntrada: number;

  public bibDialogo = bibDialogo;
}
