import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Criterio } from 'src/app/modelo/criterio';
import { Estoque } from 'src/app/modelo/estoque';
import { Loja } from 'src/app/modelo/loja';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoControlador } from 'src/app/modelo/produtoControlador';
import { ProdutoEstoqueMinimoMaximo } from 'src/app/modelo/produtoEstoqueMinimoMaximo';
import { ProdutoFoto } from 'src/app/modelo/produtoFoto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './produtoFrm.component.html',
})
export class ProdutoFrmComponent extends PaginaComponent {
  public produtoControlador = new ProdutoControlador(this.comunicacaoService);
  public edicaoLojaEstados: number[] = [];
  private ncmDescricao: string;
  private idNcm: number;
  private lojas: Loja[];

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == 'produto') {
        this.produtoControlador.produto = identificacao.conteudo;
      }
    });

    let idProduto: number = this.utilSessao.getIdentificacao('idProduto')?.conteudo;
    if (idProduto != null) {
      this.produtoControlador.produto.id = idProduto;
    }
    this.produtoControlador.ehClone = this.util.coalesce(this.utilSessao.getIdentificacao('clone')?.conteudo, false);
    this.produtoControlador.listarUnidade();
    this.produtoControlador.listarPolegadas();
    this.produtoControlador.listarCategorias();
    this.ehAlteracao();
    this.empresaRealizaIntegracaoFornecedores();
  }

  public empresaRealizaIntegracaoFornecedores(): void {
    if (this.utilSessao.getEmpresa().integracaoNeogrid == 'S') {
      this.produtoControlador.empresaRealizaIntegracaoFornecedores = 'S';
    }
  }


  ehAlteracao(): void {
    this.produtoControlador.visualizaHistorico = this.utilSessao.sistema == 'aGrow-w' ? false : true;
    this.produtoControlador.produto = this.plainToClass(Produto, this.ehAtualizacao(this.produtoControlador.produto));
    if (this.produtoControlador.produto.id) {
      this.listarProduto().subscribe((res) => {
        this.produtoControlador.produto = this.plainToClass(Produto, res[0]) as any;
        this.idNcm = this.produtoControlador.produto.idNcm;
        this.ncmDescricao = this.produtoControlador.produto.ncm;
        this.listarProdutoFotos();
        this.produtoControlador.apresentarHistorico(this.produtoControlador.visualizaHistorico);
        this.produtoControlador.ehTipoObrigatorio();
        this.listarProdutoEstoqueMinimoMaximo();
        this.produtoControlador.ehMedicamento(this.produtoControlador.produto.ncm);
        this.produtoControlador.ehMedicamentoIsento(this.produtoControlador.produto.anvisaCodigo);
      });
    } else {
      this.definirLoja();
      this.produtoControlador.produto.estoqueNegativo = this.utilSessao.sistema == 'aGrow-w' ? 'N' : 'S';
      this.listarProdutoEstoqueMinimoMaximo();
    }
  }

  listarProduto(): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.produtoControlador.produto.id)), this.bibServico.produto);
  }

  ehSetProdutoClone(): void {
    if (this.produtoControlador.ehClone) {
      this.produtoControlador.produto.id = null;
      this.produtoControlador.produto.numero = null;
      this.produtoControlador.produto.produtoFotos.forEach(produtoFoto => {
        produtoFoto.id = null;
        produtoFoto.idProduto = null;
      });
      this.utilSessao.excluirIdentificacao('id');
    }
  }

  listarProdutoEstoqueMinimoMaximo(): void {
    if (this.produtoControlador.ehClone == false) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produtoControlador.produto.id ? this.produtoControlador.produto.id : 0)), this.bibServico.produtoEstoqueMinimoMaximo).subscribe((res) => {
        this.produtoControlador.produto.produtoEstoqueMinimoMaximos = this.plainToClass(ProdutoEstoqueMinimoMaximo, res) as any;
        this.listarLojas();
      });
    } else {
      this.listarLojas();
    }
  }

  listarLojas(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.loja).subscribe((res) => {
      this.lojas = this.plainToClass(Loja, res) as any;
      this.popularEstoqueMinimoMaximoLoja();
      if (this.produtoControlador.produto.id) {
        this.listarEstoque();
      }
    });
  }


  popularEstoqueMinimoMaximoLoja(): void {
    this.lojas.forEach((loja) => {
      const possuiLoja: boolean = this.produtoControlador.produto.produtoEstoqueMinimoMaximos.find((produtoEstoqueMinimoMaximo) => produtoEstoqueMinimoMaximo.idLoja == loja.id) != undefined;
      if (!possuiLoja) {
        let produtoEstoqueMinimoMaximo = new ProdutoEstoqueMinimoMaximo();
        produtoEstoqueMinimoMaximo.lojaAbreviacao = loja.abreviacao;
        produtoEstoqueMinimoMaximo.idLoja = loja.id;
        this.produtoControlador.produto.produtoEstoqueMinimoMaximos.push(produtoEstoqueMinimoMaximo);
      }
    });
    this.produtoControlador.produto.produtoEstoqueMinimoMaximos.sort((a, b) => (a['lojaAbreviacao'] == b['lojaAbreviacao'] ? 0 : a['lojaAbreviacao'] > b['lojaAbreviacao'] ? 1 : -1));
  }

  listarEstoque(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produtoControlador.produto.id)), this.bibServico.estoque).subscribe((res) => {
      const estoques: Estoque[] = this.plainToClass(Estoque, res) as any;
      this.completarEstoqueMinimoMaximo(estoques);
    });
  }

  completarEstoqueMinimoMaximo(estoques: Estoque[]) {
    estoques.forEach((estoque) => {
      const produtoEstoqueMinimoMaximo: ProdutoEstoqueMinimoMaximo = this.produtoControlador.produto.produtoEstoqueMinimoMaximos.find((produtoEstoqueMinimoMaximo) => produtoEstoqueMinimoMaximo.idLoja == estoque.idLoja);
      if (produtoEstoqueMinimoMaximo) {
        produtoEstoqueMinimoMaximo.quantidadeDisponivel += estoque.quantidadeDisponivel;
        produtoEstoqueMinimoMaximo.quantidadeReservada += estoque.quantidadeReservada;
        produtoEstoqueMinimoMaximo.quantidadeCarteira += estoque.quantidadeCarteira;
        produtoEstoqueMinimoMaximo.quantidadeNfe += estoque.quantidadeNfe;
        produtoEstoqueMinimoMaximo.quantidadeTransferida += estoque.quantidadeTransferida;
        produtoEstoqueMinimoMaximo.quantidadeComprada += estoque.quantidadeComprada;
      }
    });
    this.produtoControlador.totalizarQuantidadeMinimoMaximoPercentual();
  }

  listarProdutoFotos(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produtoControlador.produto.id)), this.bibServico.produtoFoto).subscribe((res) => {
      this.produtoControlador.produto.produtoFotos = this.plainToClass(ProdutoFoto, res) as any;
      this.ehSetProdutoClone();
    });
  }

  definirLoja(): void {
    if (this.utilSessao.getLojas().length == 1) {
      this.produtoControlador.produto.idLoja = this.utilSessao.getLojas()[0].id;
    }
  }

  setAnvisa(): void {
    if (!this.produtoControlador.ehNcmAnvisa) {
      this.produtoControlador.produto.anvisaCodigo = null;
      this.produtoControlador.produto.anvisaMotivoIsencao = null;
      this.produtoControlador.produto.anvisaPrecoMaximo = null;
    }
  }

  persistirProduto(novo: boolean = false): void {
    this.ehAtualizarNCM().subscribe((res) => {
      if (this.setEhValido() && this.ehValidoProdutoNcm(this.plainToClass(Produto, res))) {
        this.setValorPadrao();
        this.setAnvisa();
        this.montarListaExclusaoEstoqueMinimoMaximo();
        const idProdutoFotosExcluir: number[] = this.produtoControlador.produto.produtoFotos.filter((produtoFoto) => produtoFoto.excluido == 'S' && produtoFoto.id).map((produtoFoto) => produtoFoto.id);
        this.produtoControlador.produto.produtoFotos = this.produtoControlador.produto.produtoFotos.filter((produtoFoto) => produtoFoto.excluido != 'S');
        this.produtoControlador.produto.produtoEstoqueMinimoMaximos = this.produtoControlador.produto.produtoEstoqueMinimoMaximos.filter((produtoEstoqueMinimoMaximo) => produtoEstoqueMinimoMaximo.quantidadeMinima || produtoEstoqueMinimoMaximo.quantidadeMaxima || produtoEstoqueMinimoMaximo.percentual);
        this.produtoControlador.produto.foto = this.produtoControlador.produto.produtoFotos.length > 0 ? 'S' : 'N';
        let persistenciaProduto: Transporte = new Transporte(this.produtoControlador.produto);
        persistenciaProduto.adicionar(this.produtoControlador.excluirEstoqueMinimoMaximo);
        persistenciaProduto.adicionar(idProdutoFotosExcluir);
        const ehInsercao: boolean = this.produtoControlador.produto.id > 0 ? false : true;
        super.persistir(persistenciaProduto, this.bibServico.produto, novo ? new Produto() : null).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.produtoControlador.excluirEstoqueMinimoMaximo = [];
            this.edicaoLojaEstados = [];
            if (ehInsercao && !novo) {
              this.atualizarNumero(this.produtoControlador.produto.id);
            }
            this.produtoControlador.produto.produtoFotos = this.produtoControlador.produto.produtoFotos.filter((produtoFoto) => produtoFoto.excluido != 'S');
            this.produtoControlador.produto.produtoFotos.forEach((produtoFoto) => (produtoFoto.pendente = 'N'));
            this.definirIds();
            this.popularEstoqueMinimoMaximoLoja();
            if (novo) {
              this.produtoControlador.produto.id = null;
            }
          }
        });
      }
    });
  }

  ehAtualizarNCM(): Observable<any> {
    if (this.produtoControlador.produto.id && this.produtoControlador.produto.faturamento == 'S') {
      const resposta: Observable<any> = this.listarProduto();
      resposta.subscribe((res) => {
        let produto: Produto = this.plainToClass(Produto, res[0]);
        this.produtoControlador.produto.idCfopEntrada = produto.idCfopEntrada;
        this.produtoControlador.produto.idCfopSaida = produto.idCfopSaida;
        this.produtoControlador.produto.idCfopTransferencia = produto.idCfopTransferencia;
        this.produtoControlador.produto.produtoImpostosIcms = produto.produtoImpostosIcms;
        this.produtoControlador.produto.usaIcmsDesonerado = produto.usaIcmsDesonerado;
        this.produtoControlador.produto.idCstPisEntrada = produto.idCstPisEntrada;
        this.produtoControlador.produto.pisAliquotaEntrada = produto.pisAliquotaEntrada;
        this.produtoControlador.produto.idCstPisSaida = produto.idCstPisSaida;
        this.produtoControlador.produto.pisAliquotaSaida = produto.pisAliquotaSaida;
        this.produtoControlador.produto.idCstCofinsEntrada = produto.idCstCofinsEntrada;
        this.produtoControlador.produto.cofinsAliquotaEntrada = produto.cofinsAliquotaEntrada;
        this.produtoControlador.produto.cofinsAliquotaSaida = produto.cofinsAliquotaSaida;
        this.produtoControlador.produto.idIpiEntrada = produto.idIpiEntrada;
        this.produtoControlador.produto.ipiAliquotaEntrada = produto.ipiAliquotaEntrada;
        this.produtoControlador.produto.ipiAliquotaSaida = produto.ipiAliquotaSaida;
        this.produtoControlador.produto.ipiEnquadramentoEntrada = produto.ipiEnquadramentoEntrada;
        this.produtoControlador.produto.ipiEnquadramentoSaida = produto.ipiEnquadramentoSaida;
        this.produtoControlador.produto.idIpiSaida = produto.idIpiSaida;
        this.produtoControlador.produto.produtoIcmsSts = produto.produtoIcmsSts;
      });
      return resposta;
    } else {
      return of(null);
    }
  }

  ehValidoProdutoNcm(produto: Produto): boolean {
    if (produto && produto.id && produto.idNcm) {
      if (this.idNcm != produto.idNcm) {
        if (confirm(this.util.substituir(this.bibDialogo.edicaoParceiroNcms, [this.menuProduto.apelido.toLowerCase(), this.produtoControlador.produto.nomeFiscal, this.ncmDescricao.substring(this.ncmDescricao.indexOf('-'), 0).trim(), produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim(), this.produtoControlador.produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim(), produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim()]))) {
          this.produtoControlador.produto.idNcm = produto.idNcm;
          this.produtoControlador.produto.ncm = produto.ncm;
          return true;
        }
        return false;
      }
    }
    return true;
  }

  setEhValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoNomeFiscal: boolean = this.setEhValidoNomeFiscal();
    const ehValidoPermiteFaturamento: boolean = this.setEhValidoPermiteFaturamento();
    const ehValidoEstoqueMinimoMaximo: boolean = this.setEhValidoEstoqueMinimoMaximo();
    const ehValidoInformacoesAnvisa: boolean = this.setEhInformacoesAnvisa();
    const ehValidoCest: boolean = this.setEhValidoCest();
    return ehValidoObrigatorio && ehValidoPermiteFaturamento && ehValidoEstoqueMinimoMaximo && ehValidoNomeFiscal && ehValidoInformacoesAnvisa && ehValidoCest;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.produtoControlador.produto.idGrupo && this.produtoControlador.produto.idUnidade && this.produtoControlador.produto.idLoja && (this.produtoControlador.produto.mostrarDescricao == 'S' ? this.produtoControlador.produto.observacao && this.produtoControlador.produto.observacao.length > 0 : true)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoNomeFiscal(): boolean {
    let contemCaracterEspecial: number = 0;
    if (this.produtoControlador.produto.nomeFiscal) {
      for (let letra of this.produtoControlador.produto.nomeFiscal) {
        if (['&', '+', '#', '!', '*', '$', "'", '"', "…"].includes(letra)) {
          contemCaracterEspecial++;
        }
      }
    }
    if (contemCaracterEspecial > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.caracteresInvalidosParaNomeFiscal));
      return false;
    }
    return true;
  }

  setEhValidoEstoqueMinimoMaximo(): boolean {
    if (this.produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual > 0 && this.produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual != 100) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueEstoqueMinimoMaximoPercentual));
      return false;
    }
    if (this.setEhValidoEstoqueMinimo()) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueEstoqueMinimoMaximo));
    return false;
  }

  setEhInformacoesAnvisa(): boolean {
    if (this.produtoControlador.ehNcmAnvisa && (!this.produtoControlador.produto.anvisaCodigo || this.produtoControlador.produto.anvisaPrecoMaximo == null)) {
      // this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueInformacoesAnvisa));
      //return false;
    }
    if (this.produtoControlador.ehNcmAnvisa && this.produtoControlador.ehAnvisaIsencao && !this.produtoControlador.produto.anvisaMotivoIsencao) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueInformacaoMotivoIsencaoAnvisa));
      return false;
    }
    if (this.produtoControlador.setEhValidoCodigoAnvisa() == false) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.anvisaCodigo + ': ' + this.util.definirPrimeiraMaiscula(this.bibDialogo.anvisaCodigoExplicacao.toLowerCase())));
      return false;
    }
    return true;
  }

  setEhValidoEstoqueMinimo(): boolean {
    for (let produtoEstoqueMinimoMaximo of this.produtoControlador.produto.produtoEstoqueMinimoMaximos.filter((produtoEstoqueMinimoMaximo) => produtoEstoqueMinimoMaximo.quantidadeMinima || produtoEstoqueMinimoMaximo.quantidadeMaxima || produtoEstoqueMinimoMaximo.percentual)) {
      if (produtoEstoqueMinimoMaximo.quantidadeMinima == null && produtoEstoqueMinimoMaximo.quantidadeMaxima == null) {
        return false;
      }
      if (produtoEstoqueMinimoMaximo.quantidadeMaxima) {
        if (produtoEstoqueMinimoMaximo.quantidadeMaxima < produtoEstoqueMinimoMaximo.quantidadeMinima) {
          return false;
        }
      }
    }
    return true;
  }

  setEhValidoPermiteFaturamento(): boolean {
    if (this.produtoControlador.produto.faturamento == 'S' && this.produtoControlador.produto.idNcm == null && this.produtoControlador.produto.id) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.permitirFaturamentoComNcm));
      return false;
    }
    return true;
  }


  setEhValidoCest(): boolean {
    if (this.produtoControlador.produto.cest != null && this.produtoControlador.produto.cest != '' && this.produtoControlador.produto.cest.length != 7) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cestErradoEspecifico));
      return false;
    }
    return true;
  }

  montarListaExclusaoEstoqueMinimoMaximo() {
    const produtoEstoqueMinimoMaximos: ProdutoEstoqueMinimoMaximo[] = this.produtoControlador.produto.produtoEstoqueMinimoMaximos.filter((produtoEstoqueMinimoMaximo) => produtoEstoqueMinimoMaximo.id && (produtoEstoqueMinimoMaximo.quantidadeMinima == 0 || produtoEstoqueMinimoMaximo.quantidadeMinima == null) && (produtoEstoqueMinimoMaximo.quantidadeMaxima == 0 || produtoEstoqueMinimoMaximo.quantidadeMaxima == null));
    this.produtoControlador.excluirEstoqueMinimoMaximo = [];
    produtoEstoqueMinimoMaximos.forEach((produtoEstoqueMinimoMaximo) => {
      this.produtoControlador.excluirEstoqueMinimoMaximo.push(produtoEstoqueMinimoMaximo.id);
    });
  }

  setValorPadrao(): void {
    if (this.produtoControlador.produto.controlarEstoque == 'N') {
      this.produtoControlador.produto.estoqueNegativo = 'N';
    }
    if (this.produtoControlador.produto.multiplicador == 0) {
      this.produtoControlador.produto.multiplicador = null;
    }
  }

  atualizarNumero(id: number): void {
    if (id != null && id != 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.produto).subscribe((res) => {
        this.produtoControlador.produto.numero = (this.plainToClass(Produto, res[0]) as any).numero;
      });
    }
  }

  definirIds(): void {
    const produtoEstoqueMinimoMaximos: ProdutoEstoqueMinimoMaximo[] = this.produtoControlador.produto.produtoEstoqueMinimoMaximos.filter((produtoEstoqueMinimoMaximo) => !produtoEstoqueMinimoMaximo.id);
    const produtoFotos: ProdutoFoto[] = this.produtoControlador.produto.produtoFotos.filter((produtoFoto) => produtoFoto.id == null);
    this.definirId([this.produtoControlador.produto], this.bibClasse.produto, true);
    this.definirId(produtoEstoqueMinimoMaximos, this.bibClasse.produtoEstoqueMinimoMaximo);
    this.definirId(produtoFotos, this.bibClasse.produtoFoto);
  }
}
