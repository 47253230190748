import { Component } from '@angular/core';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Especie } from 'src/app/modelo/especie';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { TipoTituloCaixa } from 'src/app/modelo/tipoTituloCaixa';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './tipoTituloFrm.component.html',
})
export class TipoTituloFrmComponent extends PaginaComponent {
  public contas: Conta[] = [];
  public especies: Especie[] = [];
  public idExclusaoTipoTituloCaixas: number[] = [];
  public tipoTitulo: TipoTitulo = new TipoTitulo();
  public tipo: string;
  public ocultarConciliacaoAutomatica: boolean = true;
  public especiesCartaoCreditoCartaoDebitoPix: number[] = [2, 3, 10, 11];
  public especieDesabilitado: boolean = false;

  public regrasRecebimento: any[] = [
    { id: 1, nome: this.bibDialogo.respeitaNegociacao },
    { id: 2, nome: this.bibDialogo.diasAntecipacao },
    { id: 3, nome: this.bibDialogo.diaFixo },
  ];

  public listaSimNao: any[] = [
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
  ];

  ngOnInit(): void {
    this.tipo = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    this.ehAlteracao();
    this.listarContas();
    this.listarEspecies();
  }

  ehAlteracao(): void {
    this.tipoTitulo = this.plainToClass(TipoTitulo, this.ehAtualizacao(this.tipoTitulo));
    this.especieDesabilitado = false;
    if (this.tipoTitulo.id) {
      this.listarTipoTitulo(this.tipoTitulo.id);
      this.especieDesabilitado = true;
    }
  }

  listarTipoTitulo(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.tipoTitulo).subscribe((res) => {
      this.tipoTitulo = this.plainToClass(TipoTitulo, res[0]) as any;
      this.ehListarTipoTituloCaixas();
      if (this.tipoTitulo.idEspecie) {
        this.setOcultarConciliacaoAutomatica(this.tipoTitulo.idEspecie);
      }
    });
  }

  ehListarTipoTituloCaixas(): void {
    if (this.tipoTitulo.idEspecie == 2 || this.tipoTitulo.idEspecie == 10) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_TIPO_TITULO', this.tipoTitulo.id)), this.bibServico.tipoTituloCaixa).subscribe((res) => {
        this.tipoTitulo.tipoTituloCaixas = [];
        this.tipoTitulo.tipoTituloCaixas = this.plainToClass(TipoTituloCaixa, res) as any;
      });
    }
  }

  listarContas(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.conta).subscribe((res) => {
      this.contas = this.plainToClass(Conta, res) as any;
    });
  }

  listarEspecies(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.especie).subscribe((res) => {
      this.especies = this.plainToClass(Especie, res) as any;
      if (this.tipo == 'C') {
        this.especies = this.especies.filter((especie) => especie.id == 2 || especie.id == 10);
      }
    });
  }

  setEspecie(especie: Especie): void {
    this.tipoTitulo.idEspecie = especie.id;
    if (this.tipoTitulo.idEspecie != 2 && this.tipoTitulo.idEspecie != 10) {
      this.tipoTitulo.tipoTituloCaixas = [];
      this.tipoTitulo.regraRecebimento = null;
    }
    const ESPECIE_SANGRIA: number = 12;
    if (this.tipoTitulo.idEspecie == ESPECIE_SANGRIA) {
      this.tipoTitulo.idConta = null;
    }
    this.sugerirConciliacaoAutomatica(especie.id);
    this.setOcultarConciliacaoAutomatica(especie.id);
  }

  sugerirConciliacaoAutomatica(idEspecie: number) {
    const dinheiroBoletoPix = [1, 3, 11];
    if (dinheiroBoletoPix.includes(idEspecie)) {
      this.tipoTitulo.conciliacaoAutomatica = 'S';
    } else {
      this.tipoTitulo.conciliacaoAutomatica = 'N';
    }
  }

  setOcultarConciliacaoAutomatica(idEspecie: number) {
    const duplicataValeOutrosCreditoAntecipacaoCreditoConsumoSangria = [5, 6, 8, 7, 9, 12];
    if (duplicataValeOutrosCreditoAntecipacaoCreditoConsumoSangria.includes(idEspecie)) {
      this.ocultarConciliacaoAutomatica = true;
    } else {
      this.ocultarConciliacaoAutomatica = false;
    }
  }

  setTipoTituloIdConta(tipoTitulo: any): void {
    if (tipoTitulo.id) {
      this.tipoTitulo.idConta = tipoTitulo.id;
      this.tipoTitulo.sugestaoBaixa = this.tipoTitulo.idConta ? 'S' : 'N';
    } else {
      this.tipoTitulo.idConta = null;
      this.tipoTitulo.sugestaoBaixa = 'N';
    }
  }

  setRegraRecebimento(regraRecebimento: number): void {
    this.tipoTitulo.regraRecebimento = regraRecebimento;
    this.tipoTitulo.dia = null;
  }

  ehPersistirTipoTitulo(novo: boolean = false): void {
    if (this.ehValido()) {
      this.persistirTipoTitulo(novo);
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoTipoCredito: boolean = this.ehValidoTipoCredito();
    const ehValidoObrigatorioTaxasSeEspecieDebitoOuCredito: boolean = this.ehValidoObrigatorioTaxasSeEspecieDebitoOuCredito();
    if (this.tipo == 'T') {
      return ehValidoObrigatorio && ehValidoTipoCredito && ehValidoObrigatorioTaxasSeEspecieDebitoOuCredito;
    }
    const ehValidoObrigatorioTipoTituloCaixa: boolean = this.ehValidoObrigatorioTipoTituloCaixa();
    const ehValidoOrdermParcelaTipoTituloCaixa: boolean = this.ehValidoOrdermParcelaTipoTituloCaixa();
    return ehValidoObrigatorio && ehValidoTipoCredito && ehValidoObrigatorioTipoTituloCaixa && ehValidoOrdermParcelaTipoTituloCaixa;
  }

  ehValidoObrigatorio(): boolean {
    if (!this.tipoTitulo.nome || (this.tipoTitulo.idEspecie == 2 || (this.tipoTitulo.idEspecie == 10 && this.tipo == 'C') ? this.tipoTitulo.regraRecebimento != 1 && !this.tipoTitulo.dia : false)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  ehValidoTipoCredito(): boolean {
    if ((this.tipoTitulo.idEspecie == 7 || this.tipoTitulo.idEspecie == 9) && this.tipoTitulo.idConta == null) {
      return false;
    }
    return true;
  }

  ehValidoObrigatorioTipoTituloCaixa(): boolean {
    if (this.tipoTitulo.tipoTituloCaixas && this.tipoTitulo.tipoTituloCaixas.length > 0 && (this.tipoTitulo.idEspecie == 2 || this.tipoTitulo.idEspecie == 10)) {
      let dadosNaoPreenchidos: number = 0;
      let parcelaDeMaiorParcelaAte: number = 0;
      this.tipoTitulo.tipoTituloCaixas.forEach((tipoTiuloCaixa) => {
        if (tipoTiuloCaixa.parcelaDe == null || tipoTiuloCaixa.parcelaAte == null || tipoTiuloCaixa.taxa == null) {
          dadosNaoPreenchidos++;
        } else if (Number(tipoTiuloCaixa.parcelaDe) > Number(tipoTiuloCaixa.parcelaAte)) {
          parcelaDeMaiorParcelaAte++;
        }
      });
      return this.mensagemObrigatorio(dadosNaoPreenchidos) && this.mensagemParcelaDeMaiorParcelaAte(parcelaDeMaiorParcelaAte);
    }
    return true;
  }

  ehValidoObrigatorioTaxasSeEspecieDebitoOuCredito(): boolean {
    const ESPECIE_DEBITO: number = 2;
    const ESPECIE_CREDITO: number = 10;
    if (this.tipoTitulo.tipoTituloCaixas.length == 0 && (this.tipoTitulo.idEspecie == ESPECIE_DEBITO || this.tipoTitulo.idEspecie == ESPECIE_CREDITO)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  mensagemObrigatorio(dadosNaoPreenchidos: number): boolean {
    if (dadosNaoPreenchidos > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  mensagemParcelaDeMaiorParcelaAte(parcelaDeMaiorParcelaAte: number): boolean {
    if (parcelaDeMaiorParcelaAte > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.quantidadeParcelaDeMaiorQquantidadeParcelaAte));
      return false;
    }
    return true;
  }

  ehValidoOrdermParcelaTipoTituloCaixa(): boolean {
    if (this.tipoTitulo.tipoTituloCaixas.length > 0 && (this.tipoTitulo.idEspecie == 2 || this.tipoTitulo.idEspecie == 10)) {
      return this.verificarParcelaInical() && this.verificarIncrementalDeParcelaAteDifere();
    }
    return true;
  }

  verificarParcelaInical(): boolean {
    if (Number(this.tipoTitulo.tipoTituloCaixas[0].parcelaDe) != 1) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parcelaInicalIncorreta));
      return false;
    }
    return true;
  }

  verificarIncrementalDeParcelaAteDifere(): boolean {
    let incrementalDeParcelaAteDifere: number = 0;
    this.tipoTitulo.tipoTituloCaixas.forEach((tipoTituloCaixas, i) => {
      if (i > 0 && this.tipoTitulo.tipoTituloCaixas.length - 1 >= i) {
        if (Number(this.tipoTitulo.tipoTituloCaixas[i - 1].parcelaAte) + 1 != Number(tipoTituloCaixas.parcelaDe)) {
          incrementalDeParcelaAteDifere++;
        }
      }
    });
    if (incrementalDeParcelaAteDifere > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.existeNumeroFaltantesParcelas));
      return false;
    }
    return true;
  }

  persistirTipoTitulo(novo: boolean): void {
    this.definirRegraTaxaCartaoDebito();
    let transporte: Transporte = new Transporte(this.tipoTitulo);
    transporte.adicionar(this.idExclusaoTipoTituloCaixas);
    super.persistir(transporte, this.bibServico.tipoTitulo, novo ? new TipoTitulo() : null).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.definirId([this.tipoTitulo], this.bibClasse.tipoTitulo, true);
        this.definirId(this.tipoTitulo.tipoTituloCaixas, this.bibClasse.tipoTituloCaixa);
        this.idExclusaoTipoTituloCaixas = [];
      }
    });
  }

  definirRegraTaxaCartaoDebito(): void {
    const ESPECIE_CARTAO_DEBITO = 2;
    if (this.tipoTitulo.idEspecie == ESPECIE_CARTAO_DEBITO && this.tipoTitulo.tipoTituloCaixas.length > 0) {
      this.tipoTitulo.tipoTituloCaixas[0].parcelaDe = 1;
      this.tipoTitulo.tipoTituloCaixas[0].parcelaAte = 1000;
    }
  }
}
