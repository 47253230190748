export class GrupoParceiroComissao {
  public colaborador: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public excluido: boolean = false;
  public grupo: string;
  public id: number;
  public idColaborador: number;
  public idEmpresa: number;
  public idGrupo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public percentual: number;
  public percentualVd: number;
}
