import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { LancamentoContabil } from 'src/app/modelo/lancamentoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './lancamentoContabil.component.html',
})
export class LancamentoContabilComponent extends PaginaComponent {
  private parametro: string;
  private params: any;
  public ativo: boolean = false;
  public filtros: Filtro[];
  public lancamentoContabeis: LancamentoContabil[] = [];

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.selecionarFiltro(this.parametro);
    });
  }

  selecionarFiltro(operacao: string): void {
    switch (operacao) {
      case 'completo':
        this.filtros = filtros.lancamentoContabil;
        break;
      case 'roteiro':
        this.filtros = filtros.lancamentoContabilRoteiro;
        break;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    if (this.parametro == 'roteiro') {
      criterios.push(new Criterio('ROTEIRO', 'S'));
    }
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.lancamentoContabil).subscribe((res) => {
      this.lancamentoContabeis = this.plainToClass(LancamentoContabil, res) as any;
    });
  }

  excluirLancamentoContabil(id: number, nome: string): void {
    super.excluir<LancamentoContabil>(id, this.lancamentoContabeis, nome.toLowerCase());
  }
}
