<layout [titulo]="produto.nome" [id]="produto.id" (voltarAvancarEmt)="ehAlteracao()">
  <titulo [titulo]="bibDialogo.ncm_cest" [imagem]="bibImagem.ncm"></titulo>
  <div class="row">
    <busca class="col-sm-9" [id]="'ncm'" [rotulo]="bibDialogo.ncm" [campo]="produto.ncm" [minimoCaracterBusca]="5" (alteracao)="produto.idNcm = $event.id" [servico]="bibServico.ncm" [obrigatorio]="true" [foco]="true" [ajuda]="descricaoUtilizacaoNcm"></busca>
    <texto *ngIf="produto.tipo != 'S'" class="col-sm-3" [id]="'cest'" [rotulo]="bibDialogo.cest" [campo]="produto.cest" (alteracao)="produto.cest = $event" [ajuda]="bibDialogo.acesseSiteConfaz" [tipo]="bibPropriedade.texto.inteiro" (btnAdicional)="abrirSiteConfaz()" [maximoCaracteres]="7" [icone]="bibIcone.globo"></texto>
  </div>
  <titulo [titulo]="bibDialogo.cfop + ' ' + bibDialogo.dentro.toLowerCase() + ' '  + bibDialogo.estado" [imagem]="bibImagem.cstcfop"></titulo>
  <div class="row">
    <lista class="col-sm-12" [id]="'cfopEntrada'" [rotulo]="bibDialogo.entrada" [campo]="produto.idCfopEntrada" (alteracao)="produto.idCfopEntrada = $event.id" [lista]="cfopsEmpresaEntrada" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
    <lista class="col-sm-12" [id]="'cfopSaida'" [rotulo]="bibDialogo.saida" [campo]="produto.idCfopSaida" (alteracao)="produto.idCfopSaida = $event.id" [lista]="cfopsEmpresaSaida" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
    <lista class="col-sm-12" [id]="'cfopTransferencia'" [rotulo]="bibDialogo.transferencia" [campo]="produto.idCfopTransferencia" (alteracao)="produto.idCfopTransferencia = $event.id" [lista]="cfopsEmpresaTransferencia" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
  </div>
  <titulo [titulo]="bibDialogo.cfop + ' ' + bibDialogo.fora.toLowerCase() + ' '  + bibDialogo.estado" [imagem]="bibImagem.cstcfop"></titulo>
  <div class="row">
    <lista class="col-sm-12" [id]="'cfopEntradaForaUf'" [rotulo]="bibDialogo.entrada" [campo]="produto.idCfopEntradaForaUf" (alteracao)="produto.idCfopEntradaForaUf = $event.id" [lista]="cfopsEmpresaEntrada" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
    <lista class="col-sm-12" [id]="'cfopSaidaForaUf'" [rotulo]="bibDialogo.saida" [campo]="produto.idCfopSaidaForaUf" (alteracao)="produto.idCfopSaidaForaUf = $event.id" [lista]="cfopsEmpresaSaida" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
    <lista class="col-sm-12" [id]="'cfopTransferenciaForaUf'" [rotulo]="bibDialogo.transferencia" [campo]="produto.idCfopTransferenciaForaUf" (alteracao)="produto.idCfopTransferenciaForaUf = $event.id" [lista]="cfopsEmpresaTransferencia" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
  </div>  

  <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.aliquota + ' ' + bibDialogo.icmsEspecifica" [imagem]="bibImagem.csticmssaida"></titulo>
    <botao [id]="'adicionarIcmsEspecifico'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.aliquota + ' ' + bibDialogo.icmsEspecifica" (botaoEmt)="adicionarIcmsEspecifico()"></botao>
  </div>
  <table *ngIf="produto.produtoImpostosIcmsEspecifico.length > 0" class="table table-responsive-sm tabela-formulario">
    <thead>
      <tr>
        <th class="l-15">{{ bibDialogo.ufOrigem }}</th>
        <th class="l-15">{{ bibDialogo.ufDestino }}</th>
        <th class="l-20">{{ bibDialogo.enquadramento }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquotaJuridica }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquotaFisica }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquota + ' ' + bibDialogo.interna }}<ajuda [ajuda]="bibDialogo.ajudaAliquotaInterna"></ajuda></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoImpostoIcmsEspecifico of produto.produtoImpostosIcmsEspecifico; let i = index">
        <tr>
          <td>
            <lista *ngIf="!produtoImpostoIcmsEspecifico.id || edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id)" [campo]="produtoImpostoIcmsEspecifico.idEstadoOrigem" (alteracao)="setEstadoOrigem(produtoImpostoIcmsEspecifico, $event)" [lista]="estadosOrigem" [obrigatorio]="true" [focoId]="focoEstado"></lista>
            {{ produtoImpostoIcmsEspecifico.id && edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id) == false ? produtoImpostoIcmsEspecifico.estadoOrigem : '' }}
          </td>
          <td>
            <lista *ngIf="!produtoImpostoIcmsEspecifico.id || edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id)" [campo]="produtoImpostoIcmsEspecifico.idEstado" (alteracao)="setEstadoDestino(produtoImpostoIcmsEspecifico, $event)" [lista]="estados" [obrigatorio]="true"></lista>
            {{ produtoImpostoIcmsEspecifico.id && edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id) == false ? produtoImpostoIcmsEspecifico.estado : '' }}
          </td>       
          <td>
            <lista *ngIf="!produtoImpostoIcmsEspecifico.id || edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id)" [campo]="produtoImpostoIcmsEspecifico.enquadramento" (alteracao)="setEnquadramento(produtoImpostoIcmsEspecifico, $event)" [lista]="listaEnquadramento" [obrigatorio]="true"></lista>
            {{ produtoImpostoIcmsEspecifico.id && edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id) == false ? produtoImpostoIcmsEspecifico.enquadramento == 1 ? this.bibDialogo.simplesNacional : produtoImpostoIcmsEspecifico.enquadramento == 2 ? this.bibDialogo.lucroPresumido : produtoImpostoIcmsEspecifico.enquadramento == 3 ? this.bibDialogo.lucroReal : this.bibDialogo.simplesNacionalExcesso : '' }}
          </td>             
          <td class="text-right">
            <decimal *ngIf="!produtoImpostoIcmsEspecifico.id || edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id)" [campo]="produtoImpostoIcmsEspecifico.aliquotaJuridica" (alteracao)="produtoImpostoIcmsEspecifico.aliquotaJuridica = $event" [sufixo]="bibDialogo.percentualSimbolo" [obrigatorio]="true"></decimal>
            {{ produtoImpostoIcmsEspecifico.id && edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id) == false ? produtoImpostoIcmsEspecifico.aliquotaJuridica + ' ' + bibDialogo.percentualSimbolo : '' }}
          </td>
          <td class="text-right">
            <decimal *ngIf="!produtoImpostoIcmsEspecifico.id || edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id)" [campo]="produtoImpostoIcmsEspecifico.aliquotaFisica" (alteracao)="produtoImpostoIcmsEspecifico.aliquotaFisica = $event" [sufixo]="bibDialogo.percentualSimbolo" [obrigatorio]="true"></decimal>
            {{ produtoImpostoIcmsEspecifico.id && edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id) == false ? produtoImpostoIcmsEspecifico.aliquotaFisica + ' ' + bibDialogo.percentualSimbolo : '' }}
          </td>
          <td class="text-right">
            <decimal *ngIf="!produtoImpostoIcmsEspecifico.id || edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id)" [campo]="produtoImpostoIcmsEspecifico.aliquotaInterna" (alteracao)="produtoImpostoIcmsEspecifico.aliquotaInterna = $event" [sufixo]="bibDialogo.percentualSimbolo"></decimal>
            {{ produtoImpostoIcmsEspecifico.id && edicaoProdutoImpostoIcmsEspecificos.includes(produtoImpostoIcmsEspecifico.id) == false && produtoImpostoIcmsEspecifico.aliquotaInterna ? produtoImpostoIcmsEspecifico.aliquotaInterna + ' ' + bibDialogo.percentualSimbolo : '' }}
          </td>   
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editarIcmsEspecifico(produtoImpostoIcmsEspecifico)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirIcmsEspecifico(i, produtoImpostoIcmsEspecifico.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.icms" [imagem]="bibImagem.csticmssaida"></titulo>
    <botao [id]="'adicionarIcms'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.icms" (botaoEmt)="abrirModalIcms(-1)"></botao>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10">{{ bibDialogo.ufOrigem }}</th>
        <th class="l-10">{{ bibDialogo.ufDestino }}</th>
        <th class="l-30">{{ bibDialogo.enquadramento }}</th>
        <th class="l-30">{{ menuClassificacaoFiscal.apelido }}</th>
        <th class="l-10">{{ bibDialogo.desonerado }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoIcms of produto.produtoImpostosIcms | filtrarIcmsEntradaSaida: { filtro: -1 }; let i = index">
        <tr>
          <td class="limitar">{{ produtoIcms.estadoOrigem }}</td>
          <td class="limitar">{{ produtoIcms.estado }}</td>
          <td class="limitar">{{ produtoIcms.enquadramento == 1 ? this.bibDialogo.simplesNacional : produtoIcms.enquadramento == 2 ? this.bibDialogo.lucroPresumido : produtoIcms.enquadramento == 3 ? this.bibDialogo.lucroReal : this.bibDialogo.simplesNacionalExcesso }}</td>
          <td class="limitar">{{ produtoIcms.classificacaoFiscal }}</td>
          <td class="limitar">{{ produtoIcms.usaIcmsDesonerado == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModalIcms(-1, produtoIcms)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" [id]="'produtoIcmsSaida' + i" (btnAdicional)="excluirProdutoIcms(i, produtoIcms.tipo, produtoIcms.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>


  <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.pisCofins" [imagem]="bibImagem.cstpis"></titulo>
    <botao [id]="'adicionarPis'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.pisCofins" (botaoEmt)="abrirModalPisCofins(-1)"></botao>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-15 text-left">{{ bibDialogo.enquadramento }}</th>
        <th class="l-10 text-right">{{ bibDialogo.cstPisEntrada }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquotaPisEntrada }}</th>
        <th class="l-10 text-right">{{ bibDialogo.cstPisSaida }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquotaPisSaida }}</th>
        <th class="l-10 text-right">{{ bibDialogo.cstCofinsEntrada }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquotaCofinsEntrada }}</th>
        <th class="l-10 text-right">{{ bibDialogo.cstCofinsSaida }}</th>
        <th class="l-10 text-right">{{ bibDialogo.aliquotaCofinsSaida }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoPisCofins of produto.produtoPisCofins; let i = index">
        <tr>
          <td class="limitar text-left">{{ produtoPisCofins.enquadramento == 1 ? this.bibDialogo.simplesNacional : produtoPisCofins.enquadramento == 2 ? this.bibDialogo.lucroPresumido : produtoPisCofins.enquadramento == 3 ? this.bibDialogo.lucroReal : this.bibDialogo.simplesNacionalExcesso }}</td>
          <td class="limitar text-right">{{ produtoPisCofins.cstPisEntrada }}</td>
          <td class="limitar text-right">{{ (produtoPisCofins.aliquotaPisEntrada | monetario) + '%' }}</td>
          <td class="limitar text-right">{{ produtoPisCofins.cstPisSaida }}</td>
          <td class="limitar text-right">{{ (produtoPisCofins.aliquotaPisSaida | monetario) + '%' }}</td>
          <td class="limitar text-right">{{ produtoPisCofins.cstCofinsEntrada }}</td>
          <td class="limitar text-right">{{ (produtoPisCofins.aliquotaCofinsEntrada | monetario) + '%' }}</td>
          <td class="limitar text-right">{{ produtoPisCofins.cstCofinsSaida }}</td>
          <td class="limitar text-right">{{ (produtoPisCofins.aliquotaCofinsSaida | monetario) + '%' }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModalPisCofins(-1, produtoPisCofins)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" [id]="'produtoIcmsSaida' + i" (btnAdicional)="excluirProdutoPisCofins(i, produtoPisCofins.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div *ngIf="(produto.produtoImpostosIcms | filtrarIcmsDesonerado).length > 0">
    <titulo [titulo]="bibDialogo.icmsDesonerado" [imagem]="bibImagem.icmsdesonerado"></titulo>
    <div class="row m-0 p-0">
      <texto class="col-sm-9"  [rotulo]="bibDialogo.motivo + ' ' + bibDialogo.desonerado.toLowerCase()" [campo]="produto.motivoDesonerado" (alteracao)="produto.motivoDesonerado = $event" [maximoCaracteres]="100"></texto>
    </div>
  </div>
  <titulo *ngIf="ehICMSDiferido"  [titulo]="bibDialogo.icmsDiferimento" [imagem]="bibImagem.icmsdiferimento"></titulo>  
  <div class="row m-0 p-0">
    <decimal *ngIf="ehICMSDiferido" [rotulo]="bibDialogo.diferimentoAliquota" [id]="'diferimentoAliquota'" [campo]="produto.diferimentoAliquota" (alteracao)="produto.diferimentoAliquota = $event"></decimal>
  </div>
  <titulo [titulo]="bibDialogo.ipi" [imagem]="bibImagem.cstipi"></titulo>
  <div class="row">
    <lista class="col-sm-5" [rotulo]="bibDialogo.entrada" [id]="'ipiEntrada'" [campo]="produto.idIpiEntrada" (alteracao)="produto.idIpiEntrada = $event.id" [lista]="cstIpiEntradas"></lista>
    <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'ipiEntradaAliquota'" [campo]="produto.ipiAliquotaEntrada" (alteracao)="produto.ipiAliquotaEntrada = $event" [obrigatorio]="produto.idIpiEntrada != null" [sufixo]="'%'"></decimal>
    <texto class="col-sm" [rotulo]="bibDialogo.enquadramento" [id]="'ipiEnquadramentoEntrada'" [campo]="produto.ipiEnquadramentoEntrada" (alteracao)="produto.ipiEnquadramentoEntrada = $event" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="3" [ajuda]="bibDialogo.maximo3Caracteres"></texto>
  </div>
  <div class="row">
    <lista class="col-sm-5" [rotulo]="bibDialogo.saida" [id]="'ipiSaida'" [campo]="produto.idIpiSaida" (alteracao)="produto.idIpiSaida = $event.id" [lista]="cstIpiSaidas"></lista>
    <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'ipiSaidaAliquota'" [campo]="produto.ipiAliquotaSaida" (alteracao)="produto.ipiAliquotaSaida = $event" [obrigatorio]="produto.idIpiSaida != null" [sufixo]="'%'"></decimal>
    <texto class="col-sm" [rotulo]="bibDialogo.enquadramento" [id]="'ipiEnquadramentoSaida'" [campo]="produto.ipiEnquadramentoSaida" (alteracao)="produto.ipiEnquadramentoSaida = $event" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="3" [ajuda]="bibDialogo.maximo3Caracteres"></texto>
  </div>
  <icmsSt [icmsSts]="produto.produtoIcmsSts" [idsExcluir]="excluirProdutoIcmsSts" [classe]="bibClasse.produtoIcmsSt"></icmsSt>
  <titulo [titulo]="bibDialogo.spedContribuicoes" [imagem]="bibImagem.ncm"></titulo>
  <div class="row">
    <texto class="col-sm-6" [rotulo]="bibDialogo.naturezaReceita" [id]="'naturezaReceita'" [campo]="produto.naturezaReceita" (alteracao)="produto.naturezaReceita = $event" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="3" [ajuda]="bibDialogo.naturezaReceitaM410M810"></texto>
    <lista class="col-sm-6" [id]="'tipoItem'" [rotulo]="bibDialogo.tipoItem" [campo]="produto.tipoItem" [lista]="tipoItem" (alteracao)="produto.tipoItem = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm-12" [rotulo]="bibDialogo.generoProduto" [id]="'generoProduto'" [campo]="produto.codigoGenero" (alteracao)="produto.codigoGenero = $event.id" [lista]="produtoGeneros" [ajuda]="bibDialogo.ajudaGeneroProdutoSped" [ajudaDireita]="true"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirProduto()" [desabilitado]="bloquearPermissao(1)"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>