<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(aprovacoes, $event)" (campoGrupoEmt)="agrupar(aprovacoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r">{{ bibDialogo.motivo }}</th>
      <th class="r-5">{{ bibDialogo.justificativa }}</th>
      <th class="r-1">{{ bibDialogo.situacao }}</th>
      <th class="r-1-5">{{ bibDialogo.usuario }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let aprovacao of aprovacoes; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="usaDescricao ? 'sem-borda' : ''">
          <td>{{ aprovacao.loja }}</td>
          <td>{{ aprovacao.data | data }}</td>
          <td class="text-right">{{ aprovacao.numero }}</td>
          <td>{{ aprovacao.aprovacaoMotivo }}</td>
          <td>{{ aprovacao.justificativa }}</td>
          <td>{{ aprovacao.situacao }}</td>
          <td>{{ aprovacao.usuario }}</td>
        </tr>
        <tr *ngIf="usaDescricao" r1152itens [descricao]="aprovacao.descricao"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="aprovacoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
