<layout [id]="bloqueiaPeriodo.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <data class="col-sm" [id]="'dataDe'" [rotulo]="bibDialogo.dataDe" [campo]="bloqueiaPeriodo.dataDe" (alteracao)="bloqueiaPeriodo.dataDe = $event" [obrigatorio]="true" [foco]="true"></data>
    <data class="col-sm" [id]="'dataAte'" [rotulo]="bibDialogo.dataAte" [campo]="bloqueiaPeriodo.dataAte" (alteracao)="bloqueiaPeriodo.dataAte = $event" [obrigatorio]="true"></data>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="bloqueiaPeriodo.ativo" (alteracao)="bloqueiaPeriodo.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="bloqueiaPeriodo.idLoja" (alteracao)="bloqueiaPeriodo.idLoja = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [icone]="bibIcone.atualizar"></lista>
  </div>
  <div *ngIf="this.parametro != 3" class="row">
    <texto class="col-sm" [id]="'motivo'" [rotulo]="bibDialogo.motivo" [campo]="bloqueiaPeriodo.motivo" (alteracao)="bloqueiaPeriodo.motivo = $event" [obrigatorio]="parametro != 3 ? true : false"></texto>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ehPersistirBloqueiaPeriodo()" [desabilitado]="bloquearPermissao(bloqueiaPeriodo.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="ehPersistirBloqueiaPeriodo(true)" [desabilitado]="bloquearPermissao(bloqueiaPeriodo.id)"></botao>
<auditoria *ngIf="bloqueiaPeriodo.id" [idUsuarioInclusao]="bloqueiaPeriodo.idUsuarioInclusao" [dataHoraInclusao]="bloqueiaPeriodo.dataHoraInclusao" [idUsuarioAlteracao]="bloqueiaPeriodo.idUsuarioAlteracao" [dataHoraAlteracao]="bloqueiaPeriodo.dataHoraAlteracao"></auditoria>
