<div class="row">
  <titulo class="col-sm" [titulo]="bibDialogo.pesquisaRapida" [imagem]="bibImagem.pesquisa"></titulo>
</div>
<div class="row form-group naoImprimir">
  <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="listaCampoPesquisa" (alteracao)="setCampoPesquisa($event.id)" [obrigatorio]="true"></lista>
  <texto [desabilitado]="campoPesquisa == bibDialogo.semAcesso || campoPesquisa == bibDialogo.comAcesso ? true : false" class="col-sm" [campo]="filtro" (alteracao)="filtro = $event" [descricao]="bibDialogo.digiteNomeIntegracao"></texto>
</div>
<div class="responsiva">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-50">{{ bibDialogo.integracao }}</th>
        <th class="l-30">{{ bibDialogo.categoria }}</th>
        <th class="l-10 text-center">{{ bibDialogo.permitir }}</th>
        <th class="l-10 text-center">{{ bibDialogo.favorito }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let integracaoUsuario of integracaoUsuarios | filtrarUsuarioIntegracao: { filtro: filtro, especifico: campoPesquisa }; let i = index">
        <td class="limitar">{{ integracaoUsuario.idIntegracao + ' - ' + integracaoUsuario.integracao }}</td>
        <td class="limitar">{{ integracaoUsuario.integracaoCategoria }}</td>
        <td class="text-center">
          <icone [icone]="integracaoUsuario.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="integracaoUsuario.acessar = integracaoUsuario.acessar == 'S' ? 'N' : 'S'" [cor]="integracaoUsuario.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="integracaoUsuario.acessar == 'S' ? bibDialogo.removerPermissao : bibDialogo.permitir"></icone>
        </td>
        <td class=" text-center">
          <icone [icone]="bibIcone.estrela" [clique]="true" (iconeEmt)="integracaoUsuario.favorito = integracaoUsuario.favorito == 'S' ? 'N' : 'S'" [cor]="integracaoUsuario.favorito == 'S' ? bibPropriedade.icone.amarelo : bibPropriedade.icone.cinza" [mensagem]="integracaoUsuario.favorito == 'S' ? bibDialogo.removerFavorito : bibDialogo.favoritar"></icone>
        </td>
      </tr>
    </tbody>
  </table>
</div>
