export class ConfiguracaoTransferenciaContabil {
  public configuracaoTransferenciaTotalContabeis: ConfiguracaoTransferenciaContabil[] = [];
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;

  setObjetoTotais(lista: any[]): void {
    this.configuracaoTransferenciaTotalContabeis = lista;
  }

  getObjetoTotais(): any[] {
    return this.configuracaoTransferenciaTotalContabeis;
  }
}
