<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" (campoOrdemEmt)="ordenar(estoques, $event)" (campoGrupoEmt)="agrupar(estoques, $event)" (filtroEmt)="listarRelatorio($event)" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <th class="r clique" (click)="ordenar(estoques, 'produto')">{{ bibDialogo.produto }}</th>
      <th class="clique r-1" (click)="ordenar(estoques, 'unidade')">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeDisponivel')">{{ bibDialogo.quantidadeDisponivel }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeDisponivelCarteira')">{{ bibDialogo.quantidadeDisponivel + ' ' +  bibDialogo.para.toLowerCase() + ' ' +  bibDialogo.venda.toLowerCase()}}<ajuda [ajuda]="bibDialogo.quantidadeDisponivel + ' - ' + bibDialogo.quantidade.toLowerCase() + ' de ' + bibDialogo.pedido.toLowerCase() + ' em ' +  bibDialogo.carteira.toLowerCase() + ' ' + bibDialogo.pendente.toLowerCase()"></ajuda></th>
      <th class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadePendenteFaturamento')">{{ bibDialogo.quantidade + ' ' + bibDialogo.carteira.toLowerCase() }}<ajuda [ajuda]="bibDialogo.quantidade + ' de ' + bibDialogo.pedido.toLowerCase() + ' em ' +  bibDialogo.carteira.toLowerCase() + ' ' + bibDialogo.pendente.toLowerCase() + ' de ' + bibDialogo.faturamento.toLowerCase()"></ajuda></th>
      <th *ngIf="usaQuantidadePendente" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadePendente')">{{ bibDialogo.quantidadePendente }}</th>
      <th *ngIf="usaQuantidadeComprada" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeComprada')">{{ bibDialogo.quantidadeComprada }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeNfe')">{{ bibDialogo.quantidadeNfe }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let estoque of estoques; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="estoque.pedidoCarteiraExpandido ? 'sem-borda' : ''">
          <td class="fonte-7pt">{{ estoque.produtoNumero + ' - ' + estoque.produto }}</td>
          <td class="fonte-7pt">{{ estoque.unidade }}</td>
          <td class="text-right">{{ estoque.quantidadeDisponivel | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right" [ngClass]="estoque.quantidadeDisponivelCarteira < 0 ? 'atraso fonte-negrito fonte-preta' : ''" >{{ estoque.quantidadeDisponivelCarteira | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>          
          <td class="text-right fonte-preta">
            <a class="fonte-negrito" href="#" *ngIf="estoque.quantidadePendenteFaturamento > 0" (click)="expandirPedidoCarteira(estoque)">
              {{ estoque.quantidadePendenteFaturamento | monetario: this.empresaRegraCasasDecimaisEntrada }}
            </a>
            <ng-container *ngIf="estoque.quantidadePendenteFaturamento == 0">{{ estoque.quantidadePendenteFaturamento | monetario: this.empresaRegraCasasDecimaisEntrada }}</ng-container>
          </td>
          <td *ngIf="usaQuantidadePendente" class="text-right">{{ estoque.quantidadePendente | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaQuantidadeComprada" class="text-right">{{ estoque.quantidadeComprada | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right">{{ estoque.quantidadeNfe | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
        </tr>
        <tr *ngIf="estoque.pedidoCarteiraExpandido" r2021pedidoCarteira [empresaRegraCasasDecimaisEntrada]="this.empresaRegraCasasDecimaisEntrada" [pedidoCarteiras]="estoque.pedidoCarteiras" [utilSessao]="utilSessao" [telaPedidoCarteira]="telaPedidoCarteira"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="estoques.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>