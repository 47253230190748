import { Adicoes } from './adicoes';
import { Desembaraco } from './desembaraco';

export class Importacao {
  public numero: string;
  public dataEmissao: string;
  public viaTransporte: number;
  public valorAfrmm: number;
  public formaImportacao: number;
  public codigoExportador: string;
  public desembaraco: Desembaraco;
  public adicoes: Adicoes[];
}
