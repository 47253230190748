import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { GrupoAcesso } from 'src/app/modelo/grupoAcesso';
import { GrupoAcessoHorario } from 'src/app/modelo/grupoAcessoHorario';
import { GrupoAcessoLocal } from 'src/app/modelo/grupoAcessoLocal';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './grupoAcessoFrm.component.html',
})
export class GrupoAcessoFrmComponent extends PaginaComponent {
  public grupoAcessoHorario: GrupoAcessoHorario = new GrupoAcessoHorario();
  public grupoAcessoLocal: GrupoAcessoLocal = new GrupoAcessoLocal();
  public grupoAcesso: GrupoAcesso = new GrupoAcesso();
  public edicaoGrupoAcessoHorarios: number[] = [];
  public excluirGrupoAcessoHorarios: number[] = [];
  public edicaoGrupoAcessoLocais: number[] = [];
  public excluirGrupoAcessoLocais: number[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.grupoAcesso = this.plainToClass(GrupoAcesso, this.ehAtualizacao(this.grupoAcesso));
    if (this.grupoAcesso.id) {
      this.listarGrupoAcesso(this.grupoAcesso.id);
    }
  }

  listarGrupoAcesso(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.grupoAcesso).subscribe((res) => {
      this.grupoAcesso = this.plainToClass(GrupoAcesso, res[0]) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_ACESSO', this.grupoAcesso.id)), this.bibServico.grupoAcessoHorario).subscribe((res) => {
        this.grupoAcesso.grupoAcessoHorarios = this.plainToClass(GrupoAcessoHorario, res);
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_ACESSO', this.grupoAcesso.id)), this.bibServico.grupoAcessoLocal).subscribe((res) => {
          this.grupoAcesso.grupoAcessoLocais = this.plainToClass(GrupoAcessoLocal, res);
        });
      });
    });
  }

  persistirGrupoAcesso(novo: boolean = false): void {
    if (this.ehValido()) {
      const transporte: Transporte = new Transporte(this.grupoAcesso);
      transporte.adicionar(this.excluirGrupoAcessoHorarios);
      transporte.adicionar(this.excluirGrupoAcessoLocais);
      super.persistir(transporte, this.bibServico.grupoAcesso, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.grupoAcesso], this.bibClasse.grupoAcesso, true);
          this.definirId(this.grupoAcesso.grupoAcessoHorarios, this.bibClasse.grupoAcessoHorario);
          this.definirId(this.grupoAcesso.grupoAcessoLocais, this.bibClasse.grupoAcessoLocal);
          this.excluirGrupoAcessoHorarios = [];
          this.excluirGrupoAcessoLocais = [];
          this.edicaoGrupoAcessoHorarios = [];
        }
      });
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoNumerosHorario: boolean = this.ehValidoNumerosHorario();
    const ehValidoLocalAcesso: boolean = this.ehValidoLocalAcesso();
    return ehValidoObrigatorio && ehValidoNumerosHorario && ehValidoLocalAcesso;
  }

  ehValidoObrigatorio(): boolean {
    if (this.grupoAcesso.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoNumerosHorario(): boolean {
    let quantidadeHorarioInvalidos: number = 0;
    this.grupoAcesso.grupoAcessoHorarios.forEach((grupoHorario) => {
      let horarioInicial: string[] = grupoHorario.horaInicial.split(':');
      let horarioFinal: string[] = grupoHorario.horaFinal.split(':');
      if ((Number(horarioInicial[0]) == Number(horarioFinal[0]) && Number(horarioInicial[1]) >= Number(horarioFinal[1])) || Number(horarioInicial[0]) > Number(horarioFinal[0]) || Number(horarioInicial[0]) > 24 || Number(horarioInicial[1]) > 60 || Number(horarioFinal[0]) > 60 || Number(horarioFinal[1]) > 60) {
        quantidadeHorarioInvalidos++;
      }
    });
    if (quantidadeHorarioInvalidos > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.horaInvalida));
      return false;
    }
    return true;
  }

  ehValidoLocalAcesso(): boolean {
    let quantidadeGrupoAcesso: number = 0;
    if (this.grupoAcesso.grupoAcessoLocais.length > 0) {
      this.grupoAcesso.grupoAcessoLocais.forEach((grupoAcessoLocal) => {
        if (!grupoAcessoLocal.nome || !grupoAcessoLocal.ip) {
          quantidadeGrupoAcesso++;
        }
      });
    }
    if (quantidadeGrupoAcesso > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }
}
