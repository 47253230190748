import { Pipe, PipeTransform } from '@angular/core';
import { TransferenciaCheque } from 'src/app/modelo/transferenciaCheque';

@Pipe({
  name: 'filtrarTransferenciaChequeNaoExcluido',
  pure: false,
})
export class TransferenciaChequePipe implements PipeTransform {
  transform(transferenciaCheques: TransferenciaCheque[]): any {
    return transferenciaCheques.filter((transferenciaCheque) => transferenciaCheque.excluido == false);
  }
}
