import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtroComboTipo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'CLIENTE', id: "AND PAR.TIPO_CLIENTE = 'S'" },
  { nome: 'FORNECEDOR', id: "AND PAR.TIPO_FORNECEDOR = 'S'" },
  { nome: 'TRANSPORTADORA', id: "AND PAR.TIPO_TRANSPORTADORA = 'S'" },
  { nome: 'COLABORADOR', id: "AND PAR.TIPO_COLABORADOR = 'S'" },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroParceiroAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataAniversarioDe, nome: 'NASCIMENTO_INICIO', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAniversarioAte, nome: 'NASCIMENTO_FIM', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.cpf, nome: 'CNPJ_CPF', coluna: 12, servicoWeb: bibServico.cpf, tipo: bibPropriedade.filtro.mascara },
  { rotulo: bibDialogo.tipo, nome: 'TIPO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboTipo },
  { rotulo: utilFiltro.apelidoTelaRelacionamento, nome: 'IDS_RELACIONAMENTOS', coluna: 6, servicoWeb: bibServico.relacionamento, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.cidade, nome: 'ID_CIDADE', coluna: 6, servicoWeb: bibServico.cidade, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.regiao, nome: 'ID_REGIAO', coluna: 6, servicoWeb: bibServico.regiao, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_PARCEIRO_VENDEDOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
