import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1198.component.html',
})
export class R1198Component extends RelatorioComponent {
  public criterio: Criterio;
  public filtros = filtros;
  public movimentacaoProdutos: any[] = [];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    this.criterio = criterios.find((criterio) => criterio.nome == 'TOTALIZADOR');
    this.ordens[0].rotulo != this.criterio.apresentacao ? (this.ordens[0].rotulo = this.criterio.apresentacao) : '';
    criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().permitir != 'S' ? this.utilSessao.getUsuario().idColaborador : null));
    super.listar(criterios).subscribe(() => {
      this.movimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.calcularDiferenca(this.movimentacaoProdutos);
      this.ordenarAgrupar(this.movimentacaoProdutos);
      this.ehImprimirRelatorio();
    });
  }

  calcularDiferenca(movimentacaoProdutos: any[]): void {
    movimentacaoProdutos.forEach((movimentacaoProduto) => {
      movimentacaoProduto.diferenca = movimentacaoProduto.totalPeriodoInformado - movimentacaoProduto.totalAnoAnterior;
    });
  }

  expandir(movimentacaoProduto: any) {
    movimentacaoProduto.expandido = !movimentacaoProduto.expandido;
    if (movimentacaoProduto.expandido && (movimentacaoProduto.movimentacaoProdutos == null || movimentacaoProduto.movimentacaoProdutos.length == 0) ) {
      this.listarEspecifico(new Criterio("ID_VINCULO", movimentacaoProduto.id), 79).subscribe((res) => {
        movimentacaoProduto.movimentacaoProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });      
    }
    if (movimentacaoProduto.expandido) {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', ''));
    }
  }
}
