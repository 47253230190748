import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { Identificacao } from 'src/app/modelo/identificacao';
import { FinanceiroFrmComponent } from '../financeiro/frm/financeiroFrm.component';
import { Filtro } from 'src/app/modelo/filtro';
import filtros from './filtro';

@Component({
  selector: 'financeiroEstorno',
  templateUrl: './financeiroEstorno.component.html',
})
export class FinanceiroEstornoComponent extends PaginaComponent {
  private params: any;
  public componente = null;
  public filtros: Filtro[];
  public financeiroBaixas: FinanceiroBaixa[];
  public parametro: string;

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params.parametro;
      this.financeiroBaixas = [];
      this.selecionarFiltro();
    });
  }

  selecionarFiltro(): void {
    switch (this.parametro) {
      case 'D':
        this.filtros = filtros.filtros;
        break;
      case 'R':
        this.filtros = filtros.filtros;
        break;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('TIPO', this.parametro));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.financeiroBaixa).subscribe((res) => {
      this.financeiroBaixas = this.plainToClass(FinanceiroBaixa, res) as any;
      this.financeiroBaixas.sort((a, b) => (a['dataBaixa'] == b['dataBaixa'] ? 0 : a['dataBaixa'] < b['dataBaixa'] ? 1 : -1));
    });
  }

  visualizar(id: number, tipo: string): void {
    this.utilSessao.setIdentificacao(new Identificacao('modalBloqueada', 'S'));
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', tipo));
    this.componente = FinanceiroFrmComponent;
  }

  estornar(): void {
    const financeiroBaixasEstornar: number[] = [];
    this.financeiroBaixas.forEach((financeiroBaixa) => {
      if (financeiroBaixa.selecionado == 'S') {
        financeiroBaixasEstornar.push(financeiroBaixa.id);
      }
    });
    this.excluirLista(financeiroBaixasEstornar, this.bibServico.financeiroBaixa).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.financeiroBaixas = this.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.selecionado != 'S');
      }
    });
  }
}
