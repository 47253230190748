<layout [titulo]="equipamento?.nome" [id]="equipamento?.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="equipamento.nome" (alteracao)="equipamento.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="equipamento.ativo" (alteracao)="equipamento.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div *ngIf="integracaoEidaf" class="row">
    <texto class="col-sm-4" [id]="'integracao'" [rotulo]="bibDialogo.integracao" [campo]="equipamento.integracao" (alteracao)="equipamento.integracao = $event" [maximoCaracteres]="30"></texto>
  </div>    
</layout>
<botao [id]="'salvar'" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirEquipamento()" [desabilitado]="bloquearPermissao(equipamento.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirEquipamento(true)" [desabilitado]="bloquearPermissao(equipamento.id)"></botao>
<auditoria *ngIf="equipamento.id" [idUsuarioInclusao]="equipamento.idUsuarioInclusao" [dataHoraInclusao]="equipamento.dataHoraInclusao" [idUsuarioAlteracao]="equipamento.idUsuarioAlteracao" [dataHoraAlteracao]="equipamento.dataHoraAlteracao"></auditoria>
