import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Criterio } from 'src/app/modelo/criterio';
import { NcmEmpresa } from 'src/app/modelo/ncmEmpresa';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';

@Component({
  selector: 'cestFrm',
  templateUrl: './cestFrm.component.html',
})
export class CestFrmComponent extends PaginaComponent {
  public ncmEmpresa: NcmEmpresa = new NcmEmpresa();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.ncmEmpresa = this.plainToClass(NcmEmpresa, this.ehAtualizacao(this.ncmEmpresa));
    if (this.ncmEmpresa.id) {
      this.listarNcmEmpresa(this.ncmEmpresa.id);
    }
  }

  listarNcmEmpresa(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.ncmEmpresa).subscribe((res) => {
      this.ncmEmpresa = this.plainToClass(NcmEmpresa, res[0]) as any;
    });
  }

  persistirNcmEmpresa(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.ncmEmpresa), this.bibServico.ncmEmpresa, novo ? new NcmEmpresa() : null);
    }
  }

  ehValido(): boolean {
    if (this.ncmEmpresa.idNcm && this.ncmEmpresa.cest) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  abrirSiteConfaz(): void {
    window.open('https://www.confaz.fazenda.gov.br/legislacao/convenios/2018/CV142_18');
  }
}
