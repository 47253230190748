<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="ehRelatorio4 ? filtros4 : ehRelatorio2023 ? filtros2023 : filtros" [ordens]="!ehRelatorio2023 ? ordens : null" [grupos]="!ehRelatorio2023 ? grupos : null" (campoOrdemEmt)="ordenar(lancamentoContabeis, $event)" (campoGrupoEmt)="agrupar(lancamentoContabeis, $event)" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th *ngIf="mostrarLoja || ehRelatorio2023" class="r-0-5">{{ bibDialogo.loja }}</th>
      <th *ngIf="ehRelatorio4 || ehRelatorio2008" class="largura-data">{{ bibDialogo.dataEHora }}</th>
      <th *ngIf="ehRelatorio2022 || ehRelatorio2023" class="largura-data">{{ bibDialogo.data }}</th>      
      <th *ngIf="ehRelatorio4 || ehRelatorio2008 && menuRoteiroAtivo" class="largura-roteiro text-right">{{ bibDialogo.roteiro }}</th>
      <th *ngIf="ehRelatorio4 || ehRelatorio2008" class="r-2">{{ bibDialogo.usuario }}</th>
      <th *ngIf="ehRelatorio2008 || ehRelatorio2022 || ehRelatorio2023" class="r-1-5">{{ bibDialogo.contaContabil }}</th> 
      <th *ngIf="ehRelatorio2008" class="r-1-5">{{ bibDialogo.contrapartida }}</th>      
      <th class="r">{{ bibDialogo.historico }}</th>
      <th class="r-1 text-right">
        {{ bibDialogo.debito }} <br />
        <h6 class="margem-debito-credito">+</h6>
      </th>
      <th class="r-1 text-right">
        {{ bibDialogo.credito }} <br />
        <h6 class="margem-debito-credito">-</h6>
      </th>
      <th *ngIf="ehRelatorio4 || ehRelatorio2008 || ehRelatorio2023" class="r-1 text-right">{{ bibDialogo.saldo }}</th>
    </thead>
    <tbody>
      <ng-container *ngIf="lancamentoContabeis.length > 0 && ehRelatorio4 || ehRelatorio2008">
        <tr>
          <td [colSpan]="mostrarLoja ? 4 : 3"></td>
          <td [colSpan]="ehRelatorio2008 ? 2 : 1" class="text-right">{{ bibDialogo.saldoAnterior }}</td>
          <td colspan="2"></td>
          <td class="text-right">{{ saldoTotalAnterior | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tbody>
      <ng-container *ngFor="let lancamentoContabil of lancamentoContabeis; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td *ngIf="mostrarLoja || ehRelatorio2023">{{ lancamentoContabil.abreviacao }}</td>
          <td *ngIf="ehRelatorio4 || ehRelatorio2008">{{ (lancamentoContabil.data | data) + ' às ' + (lancamentoContabil.dataHora | data: 'HH:mm') }}</td>
          <td *ngIf="ehRelatorio2022 || ehRelatorio2023">{{ (lancamentoContabil.data | data) }}</td>          
          <td *ngIf="ehRelatorio4 || ehRelatorio2008 && menuRoteiroAtivo" class="text-right">{{ lancamentoContabil.roteiro }}</td>
          <td *ngIf="ehRelatorio4 || ehRelatorio2008"> {{ lancamentoContabil.usuario }}</td>
          <td *ngIf="ehRelatorio2008 || ehRelatorio2022 || ehRelatorio2023" class="fonte-10px"><ajuda [ajuda]="lancamentoContabil.numeracao + ' - ' + lancamentoContabil.contaContabil"></ajuda>{{ lancamentoContabil.numeracao }}</td> 
          <td *ngIf="ehRelatorio2008" class="fonte-10px"><ajuda [ajuda]="lancamentoContabil.numeracaoContrapartida + ' - ' + lancamentoContabil.contaContabilContraPartida"></ajuda>{{ lancamentoContabil.numeracaoContrapartida }}</td>                   
          <td class="fonte-10px">{{ lancamentoContabil.historico }}</td>
          <td class="text-right">{{ lancamentoContabil.debito | monetario }}</td>
          <td class="text-right">{{ lancamentoContabil.credito | monetario }}</td>
          <td *ngIf="ehRelatorio4 || ehRelatorio2008 || ehRelatorio2023" class="fonte-9px text-right">{{ lancamentoContabil.saldoAtual | monetario }}</td>
        </tr>
        <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" class="sem-borda"></tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="lancamentoContabeis.length > 0">
      <tr>
        <td *ngIf="ehRelatorio4 || ehRelatorio2008 || ehRelatorio2023" [colSpan]="mostrarLoja ? 5 : 4"></td>
        <td *ngIf="ehRelatorio2022 || ehRelatorio2023" [colSpan]="mostrarLoja  || ehRelatorio2023 ? 4 : 3"></td>        
        <td class="text-right font-weight-bold borda-cima" [colSpan]="ehRelatorio2008 ? 2 : 1">{{ lancamentoContabeis[0].debitoTotal | monetario }}</td>
        <td class="text-right font-weight-bold borda-cima">{{ lancamentoContabeis[0].creditoTotal | monetario }}</td>
        <td class="text-right font-weight-bold borda-cima">{{ saldoAtual | monetario }}</td>
      </tr>
    </tfoot>
  </table>
</relatorioLayout>
