import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

@Pipe({
  name: 'data',
})
export class DataPipe implements PipeTransform {
  transform(dataConverter: Date, formato: string = 'dd/MM/yyyy'): string {
    let dataFormatada: string = null;
    if (dataConverter != null && dataConverter.toString() != 'Invalid Date') {
      dataFormatada = dataConverter.toString();
      if (dataFormatada.length > 10) {
        dataFormatada = format(new Date(Date.parse(dataFormatada)), formato, { locale: pt });
      } else if (dataFormatada.length == 10) {
        dataFormatada = format(parseISO(dataFormatada), formato);
      }
    }
    return dataFormatada;
  }
}
