import { Component } from '@angular/core';
import { Cidade } from 'src/app/modelo/cidade';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './cidade.component.html',
})
export class CidadeComponent extends PaginaComponent {
  public ativo: boolean = false;
  public cidades: Cidade[] = [];
  public filtros: Filtro[] = filtros;
  public name: string;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.cidade, true).subscribe((res) => {
      this.cidades = this.plainToClass(Cidade, res) as any;
    });
  }

  excluirCidade(id: number, nome: string): void {
    super.excluir<Cidade>(id, this.cidades, nome, this.bibServico.cidade);
  }
}
