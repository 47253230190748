export class NomenclaturaEmpresa {
  public alterado: boolean = false;
  public apelido: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idNomenclatura: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nomenclatura: string;
}
