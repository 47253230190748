<layout [id]="cfopEmpresa.id" [titulo]="cfopEmpresa.idCfop ? cfopEmpresa.idCfop.toString() : null" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <busca class="col-sm" [id]="'cfop'" [rotulo]="bibDialogo.cfop" [campo]="cfopEmpresa.nome" (alteracao)="setIdCfop($event)" [servico]="bibServico.cfop" [obrigatorio]="true" [foco]="true"></busca>
    <interruptor [campo]="cfopEmpresa.ativo" [rotulo]="bibDialogo.ativo" (alteracao)="cfopEmpresa.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <descricao [id]="'descricaoFiscal'" [rotulo]="bibDialogo.descricaoFiscal" [campo]="cfopEmpresa.descricaoNfe" (alteracao)="cfopEmpresa.descricaoNfe = $event" [maximoCaracteres]="200"></descricao>
  <div class="row">
    <busca [id]="'cfopInverso'" class="col-sm-12" [rotulo]="bibDialogo.cfop + (entradaSaida ? ' ' + entradaSaida : '')" [campo]="cfopEmpresa.nomeContrapartida" (alteracao)="cfopEmpresa.idCfopContrapartida = $event.id" [servico]="bibServico.cfop" [criterios]="criteriosEntradaSaida" [desabilitado]="!cfopEmpresa.idCfop"></busca>
    <busca [id]="'cfopDevolucao'" class="col-sm-12" [rotulo]="bibDialogo.cfop + ' ' + bibDialogo.devolucao" [campo]="cfopEmpresa.cfopDevolucao" (alteracao)="cfopEmpresa.idCfopDevolucao = $event.id" [servico]="bibServico.cfop" [criterios]="criteriosDevolucaoEntradaSaida" [desabilitado]="!cfopEmpresa.idCfop"></busca>
  </div>
  <titulo [titulo]="bibDialogo.icms" [imagem]="bibImagem.csticmssaida"></titulo>
  <div class="row">
    <lista class="col-sm-12" [id]="'classificacaoFiscal'" [idMenu]="permissaoClassificacaoFiscal && permissaoClassificacaoFiscal.inserir == 'S' ? menuClassificacaoFiscal.idMenu : null" [rotulo]="menuClassificacaoFiscal.apelido" [campo]="cfopEmpresa.idClassificacaoFiscal" (alteracao)="cfopEmpresa.idClassificacaoFiscal = $event.id" [lista]="classificacaoFiscais" [icone]="bibIcone.atualizar" (btnAdicional)="listarClassificacaoFiscal()"></lista>
  </div>
  <titulo [titulo]="bibDialogo.cofins" [imagem]="bibImagem.cstcofins"></titulo>
  <div class="row">
    <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'" [rotulo]="bibDialogo.cst" [id]="'cofins'" [campo]="cfopEmpresa.idCstCofins" (alteracao)="cfopEmpresa.idCstCofins = $event.id" [lista]="cstsPisCofins"></lista>
    <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'cofinsAliquota'" [campo]="cfopEmpresa.cofinsAliquota" (alteracao)="cfopEmpresa.cofinsAliquota = $event" [obrigatorio]="cfopEmpresa.idCstCofins != null" [sufixo]="'%'"></decimal>
  </div>    
  <titulo [titulo]="bibDialogo.pis" [imagem]="bibImagem.cstpis"></titulo>
  <div class="row">
    <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'" [rotulo]="bibDialogo.cst" [id]="'pis'" [campo]="cfopEmpresa.idCstPis" (alteracao)="cfopEmpresa.idCstPis = $event.id" [lista]="cstsPisCofins"></lista>
    <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'pisAliquota'" [campo]="cfopEmpresa.pisAliquota" (alteracao)="cfopEmpresa.pisAliquota = $event" [obrigatorio]="cfopEmpresa.idCstPis != null" [sufixo]="'%'"></decimal>
  </div>
  <titulo [titulo]="bibDialogo.ipi" [imagem]="bibImagem.cstipi"></titulo>
  <div class="row">
    <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'"  [rotulo]="bibDialogo.cst" [id]="'ipi'" [campo]="cfopEmpresa.idCstIpi" (alteracao)="cfopEmpresa.idCstIpi = $event.id" [lista]="cstIpis"></lista>
    <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'ipiAliquota'" [campo]="cfopEmpresa.ipiAliquota" (alteracao)="cfopEmpresa.ipiAliquota = $event" [obrigatorio]="cfopEmpresa.idCstIpi != null" [sufixo]="'%'"></decimal>
  </div>  
  <div class="row">
    <texto class="col-sm-3" [rotulo]="bibDialogo.enquadramento" [id]="'ipiEnquadramento'" [campo]="cfopEmpresa.ipiEnquadramento" (alteracao)="cfopEmpresa.ipiEnquadramento = $event" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="3" [ajuda]="bibDialogo.maximo3Caracteres"></texto>
  </div>
  <titulo [titulo]="bibDialogo.bloqueio" [imagem]="bibImagem.cadeado"></titulo>
  <div class="row justify-content-start">
    <interruptor class="col-sm-3" [id]="'financeiro'" [rotulo]="bibDialogo.financeiro" [campo]="cfopEmpresa.bloqueadoFinanceiro" (alteracao)="cfopEmpresa.bloqueadoFinanceiro = $event"></interruptor>
    <interruptor class="col-sm-3" [id]="'estoque'" [rotulo]="bibDialogo.estoque" [campo]="cfopEmpresa.bloqueadoEstoque" (alteracao)="cfopEmpresa.bloqueadoEstoque = $event"></interruptor>
    <interruptor class="col-sm-3" [id]="'estoqueNfe'" [rotulo]="bibDialogo.estoque + ' ' + bibDialogo.nfe" [campo]="cfopEmpresa.bloqueadoEstoqueNfe" (alteracao)="cfopEmpresa.bloqueadoEstoqueNfe = $event"></interruptor>
    <interruptor class="col-sm-3" [id]="'custoReposicao'" [rotulo]="bibDialogo.custoReposicao" [campo]="cfopEmpresa.bloqueadoCustoReposicao" (alteracao)="cfopEmpresa.bloqueadoCustoReposicao = $event"></interruptor>
  </div>
  <titulo [titulo]="bibDialogo.spedContribuicoes" [imagem]="bibImagem.contabilidade"></titulo>  
  <div class="row">
    <texto class="col-sm-3" [id]="'naturezaCreditoPisCofins'" [rotulo]="bibDialogo.naturezaReceita" [campo]="cfopEmpresa.naturezaCreditoPisCofins" (alteracao)="cfopEmpresa.naturezaCreditoPisCofins = $event" [maximoCaracteres]="2" [ajuda]="bibDialogo.naturezaReceitaM105M505"></texto>
    <busca class="col-sm-12" [id]="'contaContabilSped'" [rotulo]="bibDialogo.planoConta" [campo]="cfopEmpresa.contaContabilSped" (alteracao)="setIdContaContabilSped($event)" [servico]="bibServico.contaContabil"></busca>
    <interruptor *ngIf="regimeCompetenciaF550" class="col-sm-4" [id]="'enviarSpedConRegCompetencia'" [rotulo]="bibDialogo.enviarCfopSpedContribuicao" [campo]="cfopEmpresa.enviarSpedConRegCompetencia" (alteracao)="cfopEmpresa.enviarSpedConRegCompetencia = $event" [ajuda]="bibDialogo.enviarCfopSpedContribuicaoAjuda" [ajudaDireita]="true"></interruptor>
  </div> 
  <div *ngIf="empresa.contabilidade == 'S'">
    <div class="row ml-1 justify-content-between align-items-center">
      <titulo [titulo]="bibDialogo.configuracaoContabil" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionar()"></titulo>
    </div>
    <div class="row" *ngIf="cfopEmpresa?.cfopEmpresaConfiguracaoMovimentacaoContabeis.length == 0">
      <p class="col-sm-12 pl-3">
        {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.configuracao.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
      </p>
    </div>
    <div class="responsiva" *ngIf="cfopEmpresa?.cfopEmpresaConfiguracaoMovimentacaoContabeis.length > 0">
      <table class="table table-responsive-sm tabela-formulario">
        <thead>
          <tr>
            <th class="l-30">{{ bibDialogo.loja }}</th>
            <th class="l-35">{{ bibDialogo.configuracao + ' ' + bibDialogo.contabil }} <ajuda [ajuda]="bibDialogo.efetueGeracaoDeLancamentoContabil"></ajuda></th>
            <th class="l-30" *ngIf="cfopEmpresa.cfopTransferencia == 'S'">{{ bibDialogo.lojaDestino }}</th>
            <th class="l-5"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cfopEmpresaConfiguracaoMovimentacaoContabil of cfopEmpresa?.cfopEmpresaConfiguracaoMovimentacaoContabeis; let i = index">
            <td><lista [campo]="cfopEmpresaConfiguracaoMovimentacaoContabil.idLoja" (alteracao)="cfopEmpresaConfiguracaoMovimentacaoContabil.idLoja = $event.id" [lista]="listaLojas" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()"></lista></td>
            <td><lista [campo]="cfopEmpresaConfiguracaoMovimentacaoContabil.idConfMovimentacaoContabil" (alteracao)="cfopEmpresaConfiguracaoMovimentacaoContabil.idConfMovimentacaoContabil = $event.id" [lista]="configuracaoMovimentacaoContabeis" [icone]="bibIcone.atualizar" (btnAdicional)="listarConfiguracaoMovimentacaoContabil()" [obrigatorio]="true"></lista></td>  
            <td *ngIf="cfopEmpresa.cfopTransferencia == 'S'"><lista [campo]="cfopEmpresaConfiguracaoMovimentacaoContabil.idLojaDestino" (alteracao)="cfopEmpresaConfiguracaoMovimentacaoContabil.idLojaDestino = $event.id" [lista]="this.utilSessao.getLojas()" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()"></lista></td>          
            <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, cfopEmpresa.cfopEmpresaConfiguracaoMovimentacaoContabeis, [], cfopEmpresaConfiguracaoMovimentacaoContabil.id)"></btnAdicional></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>   
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCfopEmpresa()" [desabilitado]="bloquearPermissao(cfopEmpresa.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirCfopEmpresa(true)" [desabilitado]="bloquearPermissao(cfopEmpresa.id)"></botao>
<auditoria *ngIf="cfopEmpresa.id" [idUsuarioInclusao]="cfopEmpresa.idUsuarioInclusao" [dataHoraInclusao]="cfopEmpresa.dataHoraInclusao" [idUsuarioAlteracao]="cfopEmpresa.idUsuarioAlteracao" [dataHoraAlteracao]="cfopEmpresa.dataHoraAlteracao"></auditoria>
