import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'CANCELADAS', id: 'C' },
  { nome: 'TRANSMITIDAS', id: 'T' },
  { nome: 'TODOS', id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('PERMITE_TRANSMITIR_NFE', 'S')] },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'T', lista: filtroComboSituacao },
  { rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.familia, nome: 'ID_FAMILIA', coluna: 6, servicoWeb: bibServico.familia, tipo: bibPropriedade.filtro.lista },
]);
export default filtros;
