<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(entregaProdutos, $event)" (campoGrupoEmt)="agrupar(entregaProdutos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r">{{ bibDialogo.cliente }}</th>
      <th class="r-4">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
      <th class="r-1 text-right quebra-linha">{{ bibDialogo.quantidadeEntregueAbreviacao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.quantidadePendenteAbreviada }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let entregaProduto of entregaProdutos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ entregaProduto.loja }}</td>
          <td>{{ entregaProduto.dataEntrega | data }}</td>
          <td class="text-right">{{ entregaProduto.movimentacaoNumero }}</td>
          <td>{{ entregaProduto.parceiroNumero + ' - ' + entregaProduto.parceiro }}</td>
          <td>{{ entregaProduto.produtoNumero + ' - ' + entregaProduto.produto }}</td>
          <td>{{ entregaProduto.unidade }}</td>
          <td class="text-right">{{ entregaProduto.quantidadeTotal | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ entregaProduto.quantidadeEntregue | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ entregaProduto.quantidadePendente | monetario: this.empresaRegraCasasDecimais }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="entregaProdutos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
