import { DesfazimentoProduto } from './desfazimentoProduto';

export class Desfazimento {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public desfazimentoProdutos: DesfazimentoProduto[] = [];
  public id: number;
  public idColaborador: number;
  public idEmpresa: number;
  public idLoja: number;
  public idMotivoDesfazimento: number;
  public idMovimentacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
}
