import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.data, campo: 'dataOrdenacao' },
  { rotulo: bibDialogo.motivo, campo: 'aprovacaoMotivo' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
  { rotulo: bibDialogo.situacao, campo: 'situacao' },
];
export default ordens;
