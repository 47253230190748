import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'N', coluna: 6, tipo: bibPropriedade.filtro.data},
  { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'N', coluna: 6, tipo: bibPropriedade.filtro.data},
  { id: 'conta', rotulo: bibDialogo.conta, nome: 'ID_CONTA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S'), new Criterio('EXIGE_ABERTURA_CAIXA', 'S')] },
]);
export default filtros;
