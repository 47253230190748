import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'r2001AlteracaoLimiteMaximo',
  templateUrl: './r2001AlteracaoLimiteMaximo.component.html',
})
export class R2001AlteracaoLimiteMaximo {
  @Input() alteracaoLimiteMaximos: any[];
  public rotuloRamoAtividade: string = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 21).apelido;
  public bibDialogo = bibDialogo;
}
