import { Component } from '@angular/core';
import filtros from 'src/app/pagina/bloquearEstoque/filtro';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Grupo } from 'src/app/modelo/grupo';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './bloqueiaEstoque.component.html',
})
export class BloqueiaEstoqueComponent extends PaginaComponent {
  public grupos: Grupo[] = [];
  public filtros: Filtro[] = filtros;
  public criteriosBusca: Criterio[] = [];
  public atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['os ' + this.menuGrupo.apelido.toLowerCase() + 's']);

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.criteriosBusca = criterios.filter((criterio) => criterio.nome == 'NOME' || criterio.nome == 'ATIVO');
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.grupo).subscribe((res) => {
      this.grupos = this.plainToClass(Grupo, res) as any;      
    });
  }

  setBloqueiaEstoque(grupo: Grupo, valor: string) {
    grupo.bloqueadoBalanco = valor;
    this.setAlterado(grupo);
  }

  setAlterado(grupo: Grupo): void {
    grupo.alterado = true;
  }

  persistirGrupo(): void {
    let gruposAlterados: Grupo[] = this.grupos.filter((grupo) => grupo.alterado == true);
    gruposAlterados.forEach((grupo) => (grupo.nome = grupo.nome.trim()));
    const transporte: Transporte = new Transporte(gruposAlterados);
    if (this.ehValido(gruposAlterados)) {
      this.persistir(transporte, this.bibServico.grupo, null).subscribe((res) => {
        if (!this.utilSessao.falha) {
          this.listar(this.criteriosBusca);
        }
      });
    }
  }

  ehValido(grupos: Grupo[]): boolean {
    if (grupos.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumRegistroAlterado));
    return false;
  }
}
