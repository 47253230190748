<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="7">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th>{{ bibDialogo.descricao }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="quebra-linha">{{ descricao }}</td>
        </tr>
      </tbody>
    </table>
  </td>
</ng-container>
