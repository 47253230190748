import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './negociacao.component.html',
})
export class NegociacaoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public negociacoes: Negociacao[] = [];
  public rotuloTipoDeTitulo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30).apelido;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.negociacao).subscribe((res) => {
      this.negociacoes = this.plainToClass(Negociacao, res) as any;
    });
  }

  excluirNegociacao(id: number, nome: string): void {
    super.excluir<Negociacao>(id, this.negociacoes, nome);
  }
}
