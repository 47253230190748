import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const grupos: Grupo[] = [
  { rotulo: bibDialogo.dataPagamento, campo: 'dataBaixa' },
  { rotulo: bibDialogo.fornecedor, campo: 'parceiro' },
  { rotulo: utilFiltro.apelidoTelaContaBancaria, campo: 'conta' },
  { rotulo: utilFiltro.apelidoTelaTipoTitulo, campo: 'tipoTitulo' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
  { rotulo: bibDialogo.mes + '/' + bibDialogo.ano + ' ' + bibDialogo.baixa, campo: 'mesAnoDataBaixa' },
  { rotulo: bibDialogo.contaContabil, campo: 'contaContabil' },
  { rotulo: utilFiltro.apelidoCentroResultado, campo: 'centroResultado' },
  { rotulo: bibDialogo.natureza, campo: 'natureza' },
];

export default grupos;
