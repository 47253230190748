import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1111.component.html',
})
export class R1111Component extends RelatorioComponent {
  public filtros = filtros;
  public financeiros: any[] = [];
  public grupos = grupos;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public totalContasPagar: boolean = true;
  public usaDataNegociacao: boolean = false;
  public maisInformacoes: boolean = false;
  public rotuloContaCentroResultado: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 32).apelido;

  listarRelatorio(criterios: Criterio[]): void {
    this.atualizarTotalizador();
    super.listar(criterios).subscribe(() => {
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.ordenarAgrupar(this.financeiros);
      this.atualizarTotalizador();
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaDataNegociacao = this.definirRegra(relatorioAdicional, 189, this.usaDataNegociacao);
    this.maisInformacoes = this.definirRegra(relatorioAdicional, 227, this.maisInformacoes);
    this.atualizarTotalizador();
    this.ehModoPaisagem();
  }

  ehModoPaisagem(): void {
    if (this.maisInformacoes) {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', ''));
    } else {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', '', 'S'));
    }
  }

  atualizarTotalizador(): void {
    let valor: number = 0;
    let usaDataNegociacao: number = 0;
    let valorDiaAtraso: number = 0;
    usaDataNegociacao = 0;
    valor += this.usaDataNegociacao ? 0 : -1;
    valor -= this.maisInformacoes ? 1 : 0;
    this.posicionarTotalizador('diaAtraso', true, valorDiaAtraso);
    this.posicionarTotalizador('valorRestante', true, valor);
  }
}
