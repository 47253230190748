import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015SituacaoMovimentacaoQuaMovimentacao',
  templateUrl: './r2015SituacaoMovimentacaoQuaMovimentacao.component.html',
})
export class R2015SituacaoMovimentacaoQuaMovimentacao {
  @Input() situacaoMovimentacaoQuaMovimentacoes: any[] = [];
  public bibDialogo = bibDialogo;
}
