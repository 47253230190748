import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1167.component.html',
})
export class R1167Component extends RelatorioComponent {
  public entregaProdutos: any[] = [];
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.entregaProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'EntregaProduto').lista;
      this.ordenarAgrupar(this.entregaProdutos);
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.ehImprimirRelatorio();
    });
  }
}
