<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)">
  <div class="row ml-3">
    <div class="forma-lengenda-verde mt-1 mb-1"></div>
    <p class="mt-1 ml-1">{{ legendaReceita ? bibDialogo.receitaMaiorMesAnterior : bibDialogo.despesaMenorMesAnterior }}</p>
    <div class="forma-lengenda-vermelho ml-2 mt-1 mb-1"></div>
    <p class="mt-1 ml-1">{{ legendaReceita ? bibDialogo.receitaMenorMesAnterior : bibDialogo.despesaMaiorMesAnterior }}</p>
  </div>
  <table class="fonte-10px">
    <thead>
      <tr>
        <th class="r-1-5">{{ bibDialogo.contaContabil }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.janeiroAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.fevereiroAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.marcoAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.abrilAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.maioAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.junhoAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.julhoAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.agostoAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.setembroAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.outubroAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.novembroAbreviacao }}</th>
        <th class="r-0-8 text-right">{{ bibDialogo.dezembroAbreviacao }}</th>
        <th class="r-1 text-right">{{ bibDialogo.total }}</th>
        <th *ngIf="financeiros.length > 0" class="r-0-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="financeiro.expandido ? 'sem-borda' : ''">
          <td>{{ financeiro.contaContabil }}</td>
          <td class="text-right">{{ financeiro.janeiro == 0 ? '' : (financeiro.janeiro | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.janeiro == financeiro.fevereiro || financeiro.janeiro == 0 || financeiro.fevereiro == 0 ? '' : financeiro.janeiro * -1 > financeiro.fevereiro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.fevereiro == 0 ? '' : (financeiro.fevereiro | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.fevereiro == financeiro.marco || financeiro.fevereiro == 0 || financeiro.marco == 0 ? '' : financeiro.fevereiro * -1 > financeiro.marco * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.marco == 0 ? '' : (financeiro.marco | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.marco == financeiro.abril || financeiro.marco == 0 || financeiro.abril == 0 ? '' : financeiro.marco * -1 > financeiro.abril * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.abril == 0 ? '' : (financeiro.abril | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.abril == financeiro.maio || financeiro.abril == 0 || financeiro.maio == 0 ? '' : financeiro.abril * -1 > financeiro.maio * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.maio == 0 ? '' : (financeiro.maio | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.maio == financeiro.junho || financeiro.maio == 0 || financeiro.junho == 0 ? '' : financeiro.maio * -1 > financeiro.junho * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.junho == 0 ? '' : (financeiro.junho | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.junho == financeiro.julho || financeiro.junho == 0 || financeiro.julho == 0 ? '' : financeiro.junho * -1 > financeiro.julho * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.julho == 0 ? '' : (financeiro.julho | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.julho == financeiro.agosto || financeiro.julho == 0 || financeiro.agosto == 0 ? '' : financeiro.julho * -1 > financeiro.agosto * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.agosto | monetario }}</td>
          <td class="text-right" [ngClass]="financeiro.agosto == financeiro.setembro || financeiro.agosto == 0 || financeiro.setembro == 0 ? '' : financeiro.agosto * -1 > financeiro.setembro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.setembro == 0 ? '' : (financeiro.setembro | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.setembro == financeiro.outubro || financeiro.setembro == 0 || financeiro.outubro == 0 ? '' : financeiro.setembro * -1 > financeiro.outubro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.outubro == 0 ? '' : (financeiro.outubro | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.outubro == financeiro.novembro || financeiro.outubro == 0 || financeiro.novembro == 0 ? '' : financeiro.outubro * -1 > financeiro.novembro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.novembro == 0 ? '' : (financeiro.novembro | monetario) }}</td>
          <td class="text-right" [ngClass]="financeiro.novembro == financeiro.dezembro || financeiro.novembro == 0 || financeiro.dezembro == 0 ? '' : financeiro.novembro * -1 > financeiro.dezembro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ financeiro.dezembro == 0 ? '' : (financeiro.dezembro | monetario) }}</td>
          <td class="text-right">{{ financeiro.total | monetario }}</td>
          <td *ngIf="financeiros.length > 0" class="naoImprimir fonte-14px text-right"><icone [icone]="financeiro.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (iconeEmt)="this.expandir(financeiro)" [clique]="true"></icone></td>
        </tr>
        <tr *ngIf="financeiro.expandido" r1168MovimentacaoFinanceira [movimentacaoFinanceiras]="financeiro.movimentacaoFinanceiras"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
