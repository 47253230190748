import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  selector: 'app-r3',
  templateUrl: './r3.component.html',
  styleUrls: ['./r3.component.css'],
})
export class R3Component extends RelatorioComponent {
  public entregaProdutos: any[];
  public entregas: any[];
  public filtros: Filtro[] = filtros;

  listarRelatorio(criterios: Criterio[]): void {
    if (criterios.filter((criterio) => criterio.nome == 'ID').length == 0) {
      criterios = this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios);
    }
    super.listar(criterios).subscribe((criterios) => {
      this.entregas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Entrega').lista;
      this.entregaProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'EntregaProduto').lista;
      this.vincular();
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.entregas.forEach((entrega) => {
      entrega.entregaProdutos = this.entregaProdutos.filter((entregaProduto) => entregaProduto.idEntrega == entrega.id);
    });
  }
}
