import { Component } from '@angular/core';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import { ProdutoFormuladoRestricao } from 'src/app/agrow/modelo/ProdutoFormuladoRestricao';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './compendioEletronicoFrm.component.html',
})
export class CompendioEletronicoFrmComponent extends PaginaComponent {
  public produtoFormulado: ProdutoFormulado = new ProdutoFormulado();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.produtoFormulado = this.plainToClass(ProdutoFormulado, this.ehAtualizacao(this.produtoFormulado));
    if (this.produtoFormulado.id) {
      this.listarProdutoFormulado(this.produtoFormulado.id);
    }
  }

  listarProdutoFormulado(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.produtoFormulado).subscribe((res) => {
      this.produtoFormulado = this.plainToClass(ProdutoFormulado, res[0]) as any;
      this.listarProdutoFormuladoDose();
      this.listarProdutoFormuladoRestricao();
    });
  }

  listarProdutoFormuladoDose(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO_FORMULADO', this.produtoFormulado.id)), this.bibServico.produtoFormuladoDose).subscribe((res) => {
      this.produtoFormulado.produtoFormuladoDoses = this.plainToClass(ProdutoFormuladoDose, res) as any;
      this.produtoFormulado.produtoFormuladoDoses.sort((a, b) => {
        return a.cultura === b.cultura ? 0 : a.cultura > b.cultura ? 1 : -1;
      });
    });
  }

  listarProdutoFormuladoRestricao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO_FORMULADO', this.produtoFormulado.id)), this.bibServico.produtoFormuladoRestricao).subscribe((res) => {
      this.produtoFormulado.produtoFormuladoRestricoes = this.plainToClass(ProdutoFormuladoRestricao, res) as any;
    });
  }

  persistirProdutoDose(): void {
    if (this.ehValido()) {
      this.persistir(new Transporte(this.produtoFormulado), this.bibServico.produtoFormulado, null).subscribe((res) => {
        if (!this.utilSessao.falha) {
          this.definirId([this.produtoFormulado], this.bibClasse.produtoFormulado, true);
          this.definirId(this.produtoFormulado.produtoFormuladoDoses, this.bibClasse.produtoFormuladoDose);
          this.definirId(this.produtoFormulado.produtoFormuladoRestricoes, this.bibClasse.produtoFormuladoRestricao);
          this.produtoFormulado.idsProdutoFormuladoDoseExcluir = [];
        }
      });
    }
  }

  ehValido(): boolean {
    const validObrigario: boolean = this.ehValidoObrigatorio();
    const validoRestricao: boolean = this.ehValidoRestricao();
    return validObrigario && validoRestricao;
  }

  ehValidoObrigatorio(): boolean {
    if (this.produtoFormulado.nome && this.produtoFormulado.classeToxicologica) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoRestricao(): boolean {
    if (this.produtoFormulado.produtoFormuladoRestricoes.length > 0) {
      for (const produtoFormuladoRestricao of this.produtoFormulado.produtoFormuladoRestricoes) {
        if ((produtoFormuladoRestricao.cultura == null || produtoFormuladoRestricao.cultura == '') && produtoFormuladoRestricao.idEstado == null && produtoFormuladoRestricao.idEquipamento == null) {
          this.utilSessao.setResultado(new Resultado(false, 'Informe pelo menos uma restrição'));
          return false;
        }
        if (this.produtoFormulado.produtoFormuladoRestricoes.filter((produtoFormuladoRestricaoRepetido) => produtoFormuladoRestricaoRepetido.cultura == produtoFormuladoRestricao.cultura && produtoFormuladoRestricaoRepetido.idEstado == produtoFormuladoRestricao.idEstado && produtoFormuladoRestricaoRepetido.idEquipamento == produtoFormuladoRestricao.idEquipamento).length > 1) {
          this.utilSessao.setResultado(new Resultado(false, 'Existe restrição repetida'));
          return false;
        }
      }
    }
    return true;
  }
}
