import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

export class Filtros {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', coluna: 12, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.contrapartida, nome: 'IDS_CONTA_CONTRA_PARTIDA', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista, ajuda: bibDialogo.contaContabilContrapartida },
    // { rotulo: bibDialogo.grupoContabil, nome: 'ID_GRUPO_CONTABIL', coluna: 6, servicoWeb: bibServico.grupoContabil, tipo: bibPropriedade.filtro.lista },
    { rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
    { rotulo: bibDialogo.roteiroContabil, nome: 'ID_ROTEIRO_CONTABIL', coluna: 6, servicoWeb: bibServico.roteiroContabil, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.historico, nome: 'HISTORICO', obrigatorio: 'N', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { rotulo: bibDialogo.usuario, nome: 'ID_USU_ALTERACAO', coluna: 6, tipo: bibPropriedade.filtro.busca, servicoWeb: bibServico.usuario },
  ]);
}
