<div class="row justify-content-end align-items-center">
  <div class="col-sm">
    <titulo [titulo]="bibDialogo.baixa" [imagem]="bibImagem.baixa"></titulo>
  </div>
  <div>
    <botao *ngIf="financeiro.id" [compacto]="compacto" [legenda]="bibDialogo.realizarBaixa" (botaoEmt)="irBaixa()" [desabilitado]="financeiro.baixado"></botao>
  </div>
</div>
<div class="row" *ngIf="financeiro.id && financeiro.financeiroBaixas.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.baixa.toLowerCase() + ', ' }} <strong class="clique" (click)="irBaixa()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="financeiro.financeiroBaixas.length > 0">
  <thead>
    <tr>
      <th class="l-10">{{ bibDialogo.dataPrevista }}</th>
      <th class="l-10">{{ bibDialogo.dataBaixa }}</th>
      <th class="l-10">{{ bibDialogo.tipoTitulo }}</th>
      <th class="l-10">{{ bibDialogo.conta }}</th>
      <th class="l-10 fonte-media">{{ bibDialogo.nDocumento }}</th>
      <th class="l-10 text-right">{{ bibDialogo.valor }}</th>
      <th class="l-10 text-right fonte-media">{{ bibDialogo.juroAutomatico }}</th>
      <th class="l-10 text-right">{{ bibDialogo.juro }}</th>
      <th class="l-10 text-right">{{ bibDialogo.desconto }}</th>
      <th class="l-10 text-right">{{ bibDialogo.valorFinal }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let financeiroBaixa of financeiro.financeiroBaixas; let i = index">
      <tr>
        <td>{{ financeiroBaixa.dataPrevista | data }}</td>
        <td>{{ financeiroBaixa.dataBaixa | data }}</td>
        <td class="limitar"><ajuda [ajuda]="financeiroBaixa.tipoTitulo"></ajuda>{{ ' ' + financeiroBaixa.tipoTitulo }}</td>
        <td class="limitar"><ajuda [ajuda]="financeiroBaixa.conta"></ajuda>{{ ' ' + financeiroBaixa.conta }}</td>        
        <td>{{ financeiroBaixa.numeroDocumento }}</td>
        <td class="text-right">{{ financeiroBaixa.valor | monetario }}</td>
        <td class="text-right">{{ financeiroBaixa.juroValor | monetario }}</td>
        <td class="text-right">{{ financeiroBaixa.juroManual | monetario }}</td>
        <td class="text-right">{{ financeiroBaixa.descontoValor | monetario }}</td>
        <td class="text-right">{{ financeiroBaixa.valorCheio | monetario }}</td>
        <td><btnAdicional [icone]="financeiroBaixa.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="financeiroBaixa.expandido = !financeiroBaixa.expandido"></btnAdicional></td>
        <td *ngIf="relatorio1144"><btnAdicional [icone]="bibIcone.impressora" (btnAdicional)="irRecibo(financeiroBaixa)" [ajuda]="bibDialogo.recibo"></btnAdicional></td>
      </tr>
      <tr *ngIf="financeiroBaixa.expandido">
        <td class="texto-normal" colspan="12">
          <descricao [rotulo]="bibDialogo.historico" [campo]="financeiroBaixa.observacao" (alteracao)="financeiroBaixa.observacao = $event" [desabilitado]="financeiroBaixa.id" [normal]="true" [maximoCaracteres]="250"></descricao>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot>
    <tr>
      <td class="valor-baixa text-right" colspan="10">{{ financeiro.valorBaixa | monetario }}</td>
    </tr>
    <div class="container"></div>
  </tfoot>
</table>
<atencao *ngIf="financeiro.valorRestante > 0" class="justify-content-end" [atencao]="bibDialogo.valorRestante + ': ' + (financeiro.valorRestante | monetario)" [tipo]="bibPropriedade.atencao.alerta"></atencao>
