import { Component, Input } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroVeiculo } from 'src/app/modelo/parceiroVeiculo';
import { ParceiroVeiculoFrmComponent } from './frm/parceiroVeiculoFrm.component';

@Component({
  selector: 'parceiroVeiculo',
  templateUrl: './parceiroVeiculo.component.html',
})
export class ParceiroVeiculoComponent extends PaginaComponent {
  @Input() excluirVeiculos: number[] = [];
  public componente: any;
  private modalEmt: any;
  public _parceiroVeiculos: ParceiroVeiculo[];
  public _idParceiro: number;

  @Input() set idParceiro(idParceiro: number) {
    this._idParceiro = idParceiro;
    this.sugerirParceiroVeiculo();
  }

  @Input() set parceiroVeiculos(parceiroVeiculos: ParceiroVeiculo[]) {
    this._parceiroVeiculos = parceiroVeiculos;
    this.sugerirParceiroVeiculo();
  }

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.parceiroVeiculo) {
        this.adicionarTemporario(this.plainToClass(ParceiroVeiculo, identificacao.conteudo), this._parceiroVeiculos);
        this.excluirParceiroVinculoPrincipal();
      }
    });
  }

  excluirParceiroVinculoPrincipal(): void {
    const posicao: number = this._parceiroVeiculos.findIndex((parceiroVeiculo) => parceiroVeiculo.nome == null);
    if (posicao >= 0) {
      this._parceiroVeiculos.splice(posicao, 1);
    }
  }

  ngOnDestroy() {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  abrirModal(parceiroVeiculo: ParceiroVeiculo = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objetoVeiculo', parceiroVeiculo));
    this.componente = ParceiroVeiculoFrmComponent;
  }

  sugerirParceiroVeiculo(): void {
    if ((this._idParceiro == null || this._idParceiro == undefined) && this._parceiroVeiculos && this._parceiroVeiculos.length == 0) {
      let parceiroVeiculo: ParceiroVeiculo = new ParceiroVeiculo();
      parceiroVeiculo.nome = null;
      this.adicionarTemporario(this.plainToClass(ParceiroVeiculo, parceiroVeiculo), this._parceiroVeiculos);
    }
  }
}
