<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.movimentacaoProdutoEstoqueHistorico }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <!-- <th class="r-1">{{ bibDialogo.data }}</th> -->
    <!-- <th class="r-1">{{ bibDialogo.numero }}</th> -->
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <!-- <th class="r-1">{{ bibDialogo.operacao }}</th> -->
    <!-- <th class="r">{{ bibDialogo.produto }}</th> -->
    <th class="r-1">{{ bibDialogo.fisico }}</th>
    <th class="r-1">{{ bibDialogo.fisico }}</th>
    <th class="r-1">{{ bibDialogo.reservado }}</th>
    <th class="r-1">{{ bibDialogo.reservado }}</th>
    <th class="r-1">{{ bibDialogo.comprado }}</th>
    <th class="r-1">{{ bibDialogo.comprado }}</th>
    <th class="r-1">{{ bibDialogo.emTransferencia }}</th>
    <th class="r-1">{{ bibDialogo.emTransferencia }}</th>
    <th class="r-0-5">{{ bibDialogo.nfe }}</th>
    <th class="r-0-5">{{ bibDialogo.nfe }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoProdutoEstoqueHistorico of movimentacaoProdutoEstoqueHistoricos">
      <tr class="conteudo">
        <td>{{ movimentacaoProdutoEstoqueHistorico.id }}</td>
        <!-- <td>{{ movimentacaoProdutoEstoqueHistorico.data | data }}</td> -->
        <!-- <td>{{ movimentacaoProdutoEstoqueHistorico.numero }}</td> -->
        <td>{{ movimentacaoProdutoEstoqueHistorico.idLoja + ' - ' + movimentacaoProdutoEstoqueHistorico.loja }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.idEmpresa + ' - ' + movimentacaoProdutoEstoqueHistorico.empresa }}</td>
        <!-- <td>{{ movimentacaoProdutoEstoqueHistorico.idOperacao + ' - ' + movimentacaoProdutoEstoqueHistorico.operacao }}</td> -->
        <!-- <td>{{ movimentacaoProdutoEstoqueHistorico.nome }}</td> -->
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeFisicaEncontrada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeFisicaEsperada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeReservadaEncontrada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeReservadaEsperada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeCompradaEncontrada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeCompradaEsperada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeTransferidaEncontrada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeTransferidaEsperada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeNfeEncontrada | monetario }}</td>
        <td>{{ movimentacaoProdutoEstoqueHistorico.quantidadeNfeEsperada | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
