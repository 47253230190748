import { Pipe, PipeTransform } from '@angular/core';
import { Cheque } from 'src/app/modelo/cheque';

@Pipe({
  name: 'filtrarChequeSelecionado',
  pure: false,
})
export class ChequePipe implements PipeTransform {
  transform(cheques: Cheque[], argumentos?: any): any {
    switch (argumentos.filtro) {
      case 'S':
        return cheques.filter((cheque) => cheque.selecionado == 'S');
      case 'N':
        return cheques.filter((cheque) => cheque.selecionado != 'S');
      default:
        return cheques;
    }
  }
}
