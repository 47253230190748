<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [grupos]="grupos" [ordens]="ordens" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(estoqueHistoricos, $event)" (campoGrupoEmt)="agrupar(estoqueHistoricos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <th class="r-0-5 clique" (click)="ordenar(estoqueHistoricos, 'loja')">{{ bibDialogo.loja }}</th>
      <th class="r clique" (click)="ordenar(estoqueHistoricos, 'produto')">{{ bibDialogo.produto }}</th>
      <th class="r-1 clique" (click)="ordenar(estoqueHistoricos, 'unidade')">{{ bibDialogo.unidade }}</th>
      <th *ngIf="usaLote" class="r-1-5 clique" (click)="ordenar(estoqueHistoricos, 'lote')">{{ bibDialogo.lote }}</th>
      <th *ngIf="usaLocal" class="r-1-5 clique" (click)="ordenar(estoqueHistoricos, 'local')">{{ bibDialogo.local }}</th>            
      <th class="r-1 text-right clique" (click)="ordenar(estoqueHistoricos, 'quantidadeAnterior')">{{ bibDialogo.saldoAnterior }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(estoqueHistoricos, 'quantidadeEntrada')">{{ bibDialogo.entrada }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(estoqueHistoricos, 'quantidadeSaida')">{{ bibDialogo.saida }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(estoqueHistoricos, 'saldoAtual')">{{ bibDialogo.saldo }}</th>
      <th *ngIf="estoqueHistoricos.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let estoqueHistorico of estoqueHistoricos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="estoqueHistorico.expandido ? 'sem-borda' : ''">
          <td>{{ estoqueHistorico.loja }}</td>
          <td>{{ estoqueHistorico.numero + ' - ' + estoqueHistorico.produto }}</td>
          <td>{{ estoqueHistorico.unidade }}</td>
          <td *ngIf="usaLote">{{ estoqueHistorico.lote }}</td>
          <td *ngIf="usaLocal">{{ estoqueHistorico.local }}</td>                    
          <td class="text-right">{{ estoqueHistorico.quantidadeAnterior | monetario: empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right">{{ estoqueHistorico.quantidadeEntrada | monetario: empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right">{{ estoqueHistorico.quantidadeSaida * -1 | monetario: empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right">{{ estoqueHistorico.saldoAtual | monetario: empresaRegraCasasDecimaisEntrada }}</td>
          <td class="naoImprimir" *ngIf="estoqueHistoricos.length > 0"><btnAdicional [icone]="estoqueHistorico.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(estoqueHistorico)"></btnAdicional></td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
        <tr *ngIf="estoqueHistorico.expandido" r1004itens [empresaRegraCasasDecimaisEntrada]="empresaRegraCasasDecimaisEntrada" [estoqueHistoricoItens]="estoqueHistorico.estoqueHistoricoItens" [usaLote]="usaLote" [usaLocal]="usaLocal"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="estoqueHistoricos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
