import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Loja } from 'src/app/modelo/loja';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2026.component.html',
  styleUrls: ['./r2026.component.css'],
})
export class R2026Component extends RelatorioComponent {
  public apresentaRomaneiosEntrega: any[] = [];
  public dataAtual: Date = new Date();
  public ehMatricial: boolean = false;
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public filtros = filtros;
  public foto: string;
  public imagemEmpresa: string = 'img/empresa-semfoto.jpg';
  public romaneiosEntregaProdutosItem: any[] = [];
  public romaneiosEntregaProdutosMov: any[] = [];
  public romaneiosEntrega: any[] = [];
  public romaneiosEntregaDuplicados: any[] = [];
  public mostraVia = true;
  public conexao: Conexao = new Conexao(this.utilSessao);
  public usaObservacao: boolean = true;
  public idAdicionalDuplicar: number = 234;
  public operacao: string = 'ROMANEIO DE ENTREGA';
  public empresaRegraCasasDecimais: number = this.utilSessao.getEmpresa().regraCasasDecimais;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.romaneiosEntrega = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RomaneioEntrega').lista;
      this.apresentaRomaneiosEntrega = this.romaneiosEntrega;
      this.romaneiosEntregaProdutosItem = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RomaneioEntregaProdutoItem').lista;
      this.romaneiosEntregaProdutosMov = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RomaneioEntregaProdutoMov').lista;
      this.vincularMovimentacaoProdutos(this.romaneiosEntrega, this.romaneiosEntregaProdutosMov);
      if (this.romaneiosEntrega.length > 0) {
        this.utilizarFotoLojaEmpresa(this.romaneiosEntrega[0].idLoja);
      }
      this.atualizarRelatorioAdicionais();
      this.ehImprimirRelatorio();
    });
  }

  vincularMovimentacaoProdutos(romaneiosEntrega: any[], romaneiosEntregaProdutosMov: any[]): void {
    romaneiosEntrega.forEach((romaneioEntrega) => {
      romaneioEntrega.romaneioEntregaProdutosMov = [];
      romaneiosEntregaProdutosMov.forEach((romaneioEntregaProdutoMov) => {
        if ((romaneioEntregaProdutoMov.idRomaneioEntrega == romaneioEntrega.id)) {
          romaneioEntrega.romaneioEntregaProdutosMov.push(romaneioEntregaProdutoMov);
        }
      });
    });
  }

  utilizarFotoLojaEmpresa(idLoja: number): void {
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == idLoja);
    this.foto = loja.foto == 'S' ? this.conexao.caminhoAwsS3 + '' + loja.idEmpresa + '/loja/' + loja.id + '.jpg' : this.conexao.caminhoAwsS3 + '' + this.empresa.id + '/empresa/' + this.empresa.id + '.jpg';
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaObservacao = this.definirRegra(relatorioAdicional, 235, this.usaObservacao);
    this.atualizarRelatorioAdicionais();
  }

  atualizarRelatorioAdicionais(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      this.tratarDuplicar(relatorioAdicional);
    });
  }

  tratarDuplicar(relatorioAdicional: RelatorioAdicional): void {
    if (relatorioAdicional.id == this.idAdicionalDuplicar) {
      if (relatorioAdicional.marcado == 'S') {
        this.clonar();
        this.apresentaRomaneiosEntrega = this.romaneiosEntregaDuplicados;
      } else {
        this.mostrarMovimentacaoSemDuplicar();
      }
    }
  }

  mostrarMovimentacaoSemDuplicar(): void {
    this.apresentaRomaneiosEntrega = this.romaneiosEntrega;
    this.romaneiosEntregaDuplicados = [];
  }

  clonar(): void {
    if (this.romaneiosEntregaDuplicados.length == 0) {
      this.romaneiosEntrega.forEach((romaneioEntrega) => {
        let romaneioEntregaViaCliente: any = JSON.parse(JSON.stringify(romaneioEntrega));
        romaneioEntregaViaCliente.via = bibDialogo.viaCliente;
        this.romaneiosEntregaDuplicados.push(romaneioEntregaViaCliente);
        let romaneioEntregaViaEmpresa: any = JSON.parse(JSON.stringify(romaneioEntrega));
        romaneioEntregaViaEmpresa.via = bibDialogo.viaEmpresa;
        this.romaneiosEntregaDuplicados.push(romaneioEntregaViaEmpresa);
      });
    }
  }

  expandir(romaneioEntregaProdutoMov: any) {
    romaneioEntregaProdutoMov.expandido = !romaneioEntregaProdutoMov.expandido;
    if (romaneioEntregaProdutoMov.expandido) {
      this.listarEspecifico(new Criterio('ID_MOVIMENTACAO', romaneioEntregaProdutoMov.idMovimentacao), 187).subscribe((res) => {
        romaneioEntregaProdutoMov.romaneioEntregaProdutoItens = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }
}
