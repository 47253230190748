import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Patrimonial } from 'src/app/modelo/patrimonial';
import { PatrimonialLancamentoContabil } from 'src/app/modelo/patrimonialLancamentoContabil';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './patrimonialFrm.component.html',
})
export class PatrimonialFrmComponent extends PaginaComponent {
  public patrimonial: Patrimonial = new Patrimonial();
  public empresaUsaContabilidade: boolean = false;
  public edicaoPatrimonialLancamentosContabeis: number[] = [];
  public excluirPatrimonialLancamentosContabeis: number[] = [];

  ngOnInit(): void {
    this.empresaUsaContabilidade = this.utilSessao.getEmpresa().contabilidade == 'S' ? true : false;
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.patrimonial = this.plainToClass(Patrimonial, this.ehAtualizacao(this.patrimonial));
    if (this.patrimonial.id) {
      this.listarPatrimonial(this.patrimonial.id);
    }
  }

  listarPatrimonial(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.patrimonial).subscribe((res) => {
      this.patrimonial = this.plainToClass(Patrimonial, res[0]) as any;
      this.listarPatrimonialLancamentoContabil(this.patrimonial.id);
    });
  }

  listarPatrimonialLancamentoContabil(idPatrimonial: number): void {
    if (idPatrimonial != null && idPatrimonial != 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PATRIMONIAL', idPatrimonial)), this.bibServico.patrimonialLancamentoContabil).subscribe((res) => {
        this.patrimonial.patrimonialLancamentosContabeis = this.plainToClass(PatrimonialLancamentoContabil, res) as any;
      });      
    }
  }

  ehPersistirPatrimonial(novo: boolean = false): void {
    if (this.eValido()) {
      const transporte: Transporte = new Transporte(this.patrimonial);
      transporte.adicionar(this.excluirPatrimonialLancamentosContabeis);
      super.persistir(transporte, this.bibServico.patrimonial, novo ? new Patrimonial() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.patrimonial], this.bibClasse.patrimonial, true);
          this.definirId(this.patrimonial.patrimonialLancamentosContabeis, this.bibClasse.patrimonialLancamentoContabil);
          this.excluirPatrimonialLancamentosContabeis = [];
          this.edicaoPatrimonialLancamentosContabeis = [];     
          if (novo) {
            this.patrimonial.id = null;
          } else {
            this.listarPatrimonial(this.patrimonial.id);
          }         
        } 
      });
    }
  }

  eValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoObrigatorioLancamentoContabil: boolean = this.ehValidoObrigatorioLancamentoContabil();
    const ehValidoLancamentoContabil: boolean = this.ehValidoLancamentoContabil();    
    return ehValidoObrigatorio && ehValidoObrigatorioLancamentoContabil && ehValidoLancamentoContabil;
  }

  ehValidoObrigatorio(): boolean {
    if (this.patrimonial.nome && this.patrimonial.numero && (this.patrimonial.valorAquisicao || this.patrimonial.valorAquisicao == 0) && (this.patrimonial.valorAtual || this.patrimonial.valorAtual == 0) && (this.patrimonial.percentualDepreciacao || this.patrimonial.percentualDepreciacao == 0) && (this.empresaUsaContabilidade && this.patrimonial.dataAquisicao || !this.empresaUsaContabilidade)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoObrigatorioLancamentoContabil(): boolean {
    let contagemCamposObrigatorios: number = 0;
    let contagemValorMaiorZero: number = 0;
    if (this.patrimonial.patrimonialLancamentosContabeis.length > 0) {
      this.patrimonial.patrimonialLancamentosContabeis.forEach((patrimonialLancamentoContabil) => {
        if (patrimonialLancamentoContabil.data == null || patrimonialLancamentoContabil.valor == null || patrimonialLancamentoContabil.percentual == null || patrimonialLancamentoContabil.valor == 0) {
          contagemCamposObrigatorios += 1;  
        }
        if (patrimonialLancamentoContabil.valor <= 0) {
          contagemValorMaiorZero += 1; 
        }
      });
    }
    if (contagemCamposObrigatorios > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.patrimonialDepreciacaoObrigatorios));
      return false;
    }

    if (contagemValorMaiorZero > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.depreciacaoValor));
      return false;
    }    
    return true;
  }

  ehValidoLancamentoContabil(): boolean {
    let contagemInsert: number = 0;
    if (this.patrimonial.patrimonialLancamentosContabeis.length > 0) {
      this.patrimonial.patrimonialLancamentosContabeis.forEach((patrimonialLancamentoContabil) => {
        if (patrimonialLancamentoContabil.id == null) {
          contagemInsert += 1;  
        }
      });
    }
    if (contagemInsert > 1) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.patrimonialDepreciacaoInsert));
      return false;
    }
    return true;
  }

  setValorAquisicao(patrimonial: Patrimonial, valorAquisicao: number){
    patrimonial.valorAquisicao = valorAquisicao;
    patrimonial.valorAtual = valorAquisicao;
  }
}