import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Estoque } from 'src/app/modelo/estoque';
import { Filtro } from 'src/app/modelo/filtro';
import { Loja } from 'src/app/modelo/loja';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './estoque.component.html',
})
export class EstoqueComponent extends PaginaComponent {
  public ehPermitidoEditar: boolean = false;
  public estoques: Estoque[] = [];
  public estoquesComparativo: Estoque[] = [];
  public filtros: Filtro[] = filtros;
  public focoIdQuantidade: number;
  public lojas: Loja[] = [];
  public mostraExpandirTodos: boolean = false;
  public rotuloProduto: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 7).apelido;
  public telaInterna: boolean = false;
  public totalEstoques: any[] = [];
  public visualizaTotalizadores: boolean = false;
  public focoIdSenha: number;
  public quantidadeDisponivelTotalInicial: number = 0;
  public quantidadeNfeTotalInicial: number = 0;

  ngOnInit(): void {
    this.telaInterna = this.utilSessao.telaInterna;
    this.listarLojas(this.utilSessao.getIdentificacao('idProduto')?.conteudo);
  }

  listarLojas(idProduto: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.loja).subscribe((res) => {
      this.lojas = this.plainToClass(Loja, res) as any;
      this.lojas.sort((a, b) => (a['abreviacao'] == b['abreviacao'] ? 0 : a['abreviacao'] > b['abreviacao'] ? 1 : -1));
      if (idProduto != null) {
        this.listar([new Criterio('ID_PRODUTO', idProduto)]);
      }
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.estoque).subscribe((res) => {
      this.estoques = this.plainToClass(Estoque, res) as any;
      this.estoquesComparativo = this.plainToClass(Estoque, res) as any;
      this.ehPermitidoEditar = false;
      this.mostrarExpandirTodos();
      this.visualizaTotalizadores = false;
      if (this.estoques.length > 0) {
        this.visualizaTotalizadores = this.estoques.length == this.estoques.filter((estoque) => estoque.idProduto == this.estoques[0].idProduto).length;
        if (this.visualizaTotalizadores) {
          this.quantidadeDisponivelTotalInicial = this.estoquesComparativo.reduce((quantidadeInicial, estoque) => quantidadeInicial + estoque.quantidadeDisponivel, 0);
          this.quantidadeNfeTotalInicial = this.estoquesComparativo.reduce((quantidadeInicial, estoque) => quantidadeInicial + estoque.quantidadeNfe, 0);
          this.visualizarEstoque();
          if (this.verificarFiltroPorLoja(criterios) == false) {
            setTimeout(() => this.montarEstoqueLoja(this.estoques[0].idProduto));
          }
        }
      }
    });
  }

  verificarFiltroPorLoja(criterios: Criterio[]): boolean {
    if (criterios.find((criterio) => criterio.nome == 'IDS_LOJA')) {
      return true;
    } else {
      return false;
    }
  }

  mostrarExpandirTodos(): void {
    this.mostraExpandirTodos = false;
    this.estoques.forEach((estoque) => (estoque.idLocal != null || estoque.idLote != null ? (this.mostraExpandirTodos = true) : ''));
  }

  visualizarEstoque(): void {
    this.totalEstoques = [];
    let fisicoTotal: number = 0;
    let disponivelTotal: number = 0;
    let pendenteTotal: number = 0;
    let compradoTotal: number = 0;
    let transferidoTotal: number = 0;
    let nfeTotal: number = 0;
    for (let estoque of this.estoques) {
      fisicoTotal += estoque.quantidadeFisica;
      disponivelTotal += estoque.quantidadeDisponivel;
      pendenteTotal += (estoque.quantidadeReservada + estoque.quantidadeCarteira);
      compradoTotal += estoque.quantidadeComprada;
      transferidoTotal += estoque.quantidadeTransferida;
      nfeTotal += estoque.quantidadeNfe;
    }
    this.totalEstoques.push({ totalFisico: fisicoTotal, totalDisponivel: disponivelTotal, totalPendente: pendenteTotal, totalComprado: compradoTotal, totalTransferido: transferidoTotal, totalNfe: nfeTotal });
  }

  montarEstoqueLoja(idProduto: number): void {
    this.lojas.forEach((loja) => {
      const possuiEstoqueLoja: boolean = this.estoques.find((estoque) => estoque.idLoja == loja.id) != undefined;
      if (!possuiEstoqueLoja) {
        let estoque: Estoque = new Estoque();
        estoque.abreviacao = loja.abreviacao;
        estoque.idLoja = loja.id;
        estoque.idProduto = idProduto;
        this.estoques.push(estoque);
      }
    });
    this.estoques.sort((a, b) => (a['abreviacao'] == b['abreviacao'] ? 0 : a['abreviacao'] > b['abreviacao'] ? 1 : -1));
  }

  expandirTodos(): void {
    this.estoques.forEach((estoque) => (estoque.idLocal != null || estoque.idLote != null ? (estoque.expandido = true) : (estoque.expandido = false)));
  }

  editar(): void {
    this.ehPermitidoEditar = !this.ehPermitidoEditar;
    this.ehPermitidoEditar ? (this.focoIdQuantidade = Math.random()) : '';
  }

  exigirSenha(): void {
    this.focoIdSenha = Math.random();
  }

  conferirSenha(chave: string): void {
    if (chave && chave.length == 6) {
      this.focoIdSenha = null;
      this.ehPersistirEstoque(chave);
    }
  }

  ehPersistirEstoque(chave: string): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.utilSessao.getUsuario().id)), this.bibServico.usuario).subscribe((res) => {
      const usuario: Usuario = this.plainToClass(Usuario, res[0]) as any;
      if (usuario.key == chave) {
        this.persistirEstoque();
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.senhaOperacionalNaoVinculadaAoUsuario, [this.utilSessao.getUsuario().nome])));
      }
    });
  }

  persistirEstoque(): void {
    let estoqueAlterados: Estoque[] = this.estoques.filter((estoque) => estoque.alterado);
    if (this.ehValidoEditarEstoque(estoqueAlterados)) {
      super.persistir(new Transporte(estoqueAlterados), this.bibServico.estoque, null).subscribe((res) => {
        if (this.visualizaTotalizadores) {
          this.visualizarEstoque();
        }
      });
    }
  }

  ehValidoEditarEstoque(estoqueAlterados: Estoque[]): boolean {
    if (estoqueAlterados.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumEstoqueAlterado));
    return false;
  }

  setEstoqueQuantidadeDisponivel(quantidadeDisponivel: number, estoque: Estoque): void {
    estoque.quantidadeDiferencaDisponivel = quantidadeDisponivel - this.estoquesComparativo.find((estoqueComparativo) => estoqueComparativo.idLoja == estoque.idLoja && estoqueComparativo.idProduto == estoque.idProduto && estoqueComparativo.idLocal == estoque.idLocal && estoqueComparativo.idLote == estoque.idLote).quantidadeDisponivel;
    estoque.quantidadeFisica = quantidadeDisponivel + estoque.quantidadeReservada;
    estoque.quantidadeDisponivel = quantidadeDisponivel;
    estoque.alterado = true;
    this.visualizarEstoque();
  }

  setEstoqueQuantidadeNfe(quantidadeNfe: number, estoque: Estoque): void {
    estoque.quantidadeDiferencaNfe = quantidadeNfe - this.estoquesComparativo.find((estoqueComparativo) => estoqueComparativo.idLoja == estoque.idLoja && estoqueComparativo.idProduto == estoque.idProduto && estoqueComparativo.idLocal == estoque.idLocal && estoqueComparativo.idLote == estoque.idLote).quantidadeNfe;
    estoque.quantidadeNfe = quantidadeNfe;
    estoque.alterado = true;
    this.visualizarEstoque();
  }

  ngOnDestroy(): void {
    this.utilSessao.excluirIdentificacao('idProduto');
  }
}
