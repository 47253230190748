<ng-container>
  <td class="p-0" colspan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-2">{{ bibDialogo.icmsSaida + ' - ' + bibDialogo.uf }}</th>
          <th class="r-2">{{ bibDialogo.icmsSaida + ' - ' + bibDialogo.cst }}</th>
          <th class="r-3">{{ bibDialogo.icmsSaida + ' - ' + bibDialogo.baseCalculo }}</th>
          <th class="r-3">{{ bibDialogo.icmsSaida + ' - ' + bibDialogo.aliquotaJuridica }}</th>
          <th class="r-3">{{ bibDialogo.icmsSaida + ' - ' + bibDialogo.aliquotaFisica }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let produtoIcmsSaida of produtoIcmsSaidas">
          <tr>
            <td>{{ produtoIcmsSaida.uf }}</td>
            <td>{{ produtoIcmsSaida.cst }}</td>
            <td>{{ produtoIcmsSaida.base }}</td>
            <td>{{ produtoIcmsSaida.aliquotaJuridica }}</td>
            <td>{{ produtoIcmsSaida.aliquotaFisica }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
