<layout [titulo]="bibDialogo.teclasAtalho">
  <div class="row">
    <div class="col">
      <span class="badge badge-warning">{{ bibDialogo.esc }}</span
      >{{ bibDialogo.oQueVoceDeseja }}
    </div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F2</span>{{ bibDialogo.pesquisar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F3</span>{{ bibDialogo.navegadorLocalizar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F5</span>{{ bibDialogo.navegadorAtualizar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F6</span>{{ bibDialogo.navegadorTabulacao }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F8</span>{{ bibDialogo.pesquisaProduto }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F9</span>{{ bibDialogo.fechar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F10</span>{{ bibDialogo.navegadorConfiguracoes }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">F11</span>{{ bibDialogo.navegadorTelaCheia }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + </span>{{ bibDialogo.zoomAumentar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl -</span>{{ bibDialogo.zoomDiminuir }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + Q</span>{{ bibDialogo.bloqueioTela }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + I</span>{{ bibDialogo.pesquisaCliente }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + b</span>{{ bibDialogo.salvar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + B</span>{{ bibDialogo.finalizar }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + Page Up ou Page Down</span>{{ bibDialogo.navegadorAlternaAba }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + Shift + Seta esquerda (<)</span>{{ bibDialogo.voltarRegistro }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + Shift + Seta direita (>)</span>{{ bibDialogo.avancarRegistro }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + Seta esquerda (<)</span>{{ bibDialogo.voltarPagina }}</div>
  </div>
  <div class="row">
    <div class="col"><span class="badge badge-warning">Ctrl + Seta direita (>)</span>{{ bibDialogo.avancarPagina }}</div>
  </div>
</layout>
