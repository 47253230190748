export class ProdutoFormuladoDose {
  public alterado: boolean = false;
  public aplicacao: string;
  public caldaAteAereo: number;
  public caldaAteTerrestre: number;
  public caldaDeAereo: number;
  public caldaDeTerrestre: number;
  public caldaUnidadeAereo: string;
  public caldaUnidadeTerrestre: string;
  public cultura: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public desabilitado: boolean = false;
  public doseAte: number;
  public doseDe: number;
  public doseUnidade: string;
  public epocaAplicacao: string;
  public id: number;
  public idEmpresa: number;
  public idProdutoFormulado: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public intervaloAplicacao: string;
  public intervaloSeguranca: string;
  public modalidadeAplicacao: string;
  public praga: string;
  public pragaCientifico: string;
  public tratamentoSemente: string;
  public doseFator: number;
}
