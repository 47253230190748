import { Component, Input } from '@angular/core';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'rotulo',
  templateUrl: './rotulo.component.html',
  styleUrls: ['./rotulo.component.css'],
})
export class RotuloComponent {
  @Input() ajuda: string;
  @Input() ajudaDireita: boolean = false;
  @Input() atencao: string;
  @Input() campoFor: string;
  @Input() compacto: boolean = false;
  @Input() idMenu: number = 0;
  @Input() obrigatorio: boolean = false;
  @Input() preenchido: boolean = false;
  @Input() rotulo: string;
  public bibPropriedade = bibPropriedade;
  public componente: any;
  public utilSessao: UtilSessao = new UtilSessao();

  abrirMenu(idMenu: number): void {
    if (idMenu) {
      switch (idMenu) {
        case 1:
          window.open('./#/cidade', '_blank');
          break;
        case 2:
          window.open('./#/bairro', '_blank');
          break;
        case 5:
          window.open('./#/parceiro/CLIENTE', '_blank');
          break;
        case 6:
          window.open('./#/praca', '_blank');
          break;
        case 7:
          window.open('./#/produto', '_blank');
          break;
        case 8:
          window.open('./#/unidade', '_blank');
          break;
        case 9:
          window.open('./#/fabricante', '_blank');
          break;
        case 11:
          window.open('./#/genericoHierarquia/GRUPO', '_blank');
          break;
        case 12:
          window.open('./#/genericoHierarquia/CATEGORIA', '_blank');
          break;
        case 14:
          window.open('./#/vinculo', '_blank');
          break;
        case 19:
          window.open('./#/familia/loja', '_blank');
          break;
        case 21:
          window.open('./#/ramoAtividade', '_blank');
          break;
        case 24:
          window.open('./#/cfopEmpresa', '_blank');
          break;
        case 29:
          window.open('./#/conta', '_blank');
          break;
        case 30:
          window.open('./#/tipoTitulo/T', '_blank');
          break;
        case 31:
          window.open('./#/genericoHierarquia/NATUREZA', '_blank');
          break;
        case 32:
          window.open('./#/genericoHierarquia/CENTRO_RESULTADO', '_blank');
          break;
        case 44:
          window.open('./#/naturezaOperacao', '_blank');
          break;
        case 54:
          window.open('./#/parceiro/FORNECEDOR', '_blank');
          break;
        case 55:
          window.open('./#/parceiro/TRANSPORTADORA', '_blank');
          break;
        case 56:
          window.open('./#/parceiro/COLABORADOR', '_blank');
          break;
        case 60:
          window.open('./#/classificacaoFiscal', '_blank');
          break;
        case 70:
          window.open('./#/loja', '_blank');
          break;
        case 71:
          window.open('./#/negociacao', '_blank');
          break;
        case 109:
          window.open('./#/classificacao', '_blank');
          break;
        case 120:
          window.open('./#/local', '_blank');
          break;
        case 122:
          window.open('./#/relacionamento', '_blank');
          break;
        case 123:
          window.open('./#/limiteCredito', '_blank');
          break;
        case 131:
          window.open('./#/historico', '_blank');
          break;
        case 132:
          window.open('./#/contaContabil', '_blank');
          break;
        case 133:
          window.open('./#/roteiroContabil', '_blank');
          break;
        case 135:
          window.open('./#/tipoLancamentoContabil', '_blank');
          break;
        case 137:
          window.open('./#/configuracaoFinanceiroContabil', '_blank');
          break;
        case 145:
          window.open('./#/equipamento', '_blank');
          break;
        case 146:
          window.open('./#/recomendacao', '_blank');
          break;
        case 157:
          window.open('./#/categoriaRoteiroContabil', '_blank');
          break;
        case 200:
          window.open('./#/safra', '_blank');
          break;
      }
    }
  }
}
