import { Component } from '@angular/core';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { ConfiguracaoBalancoPatrimonial } from 'src/app/modelo/configuracaoBalancoPatrimonial';
import { ConfiguracaoDemonstrativoResultado } from 'src/app/modelo/configuracaoDemonstrativoResultado';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './configuracaoRelatorioContabilFrm.component.html',
})
export class ConfiguracaoRelatorioContabilFrmComponent extends PaginaComponent {
  private classe: any;
  private servico: string;
  public componente: any = null;
  public ehMinhaModal: boolean = false;
  public idsConfiguracaoExcluir: number[] = [];
  public objetos: any[] = [];

  ngOnInit(): void {
    let parametro: string = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    this.objetos = [];
    this.permissao = this.utilSessao.getPermissao();
    if (parametro == 'balancoPatrimonial') {
      this.classe = ConfiguracaoBalancoPatrimonial;
      this.servico = this.bibServico.configuracaoBalancoPatrimonial;
    } else {
      this.classe = ConfiguracaoDemonstrativoResultado;
      this.servico = this.bibServico.configuracaoDemonstrativoResultado;
    }

    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const resultados: any[] = identificacao.conteudo;
        resultados.forEach((resultado) => {
          let objeto = new this.classe();
          objeto.idContaContabil = resultado.id;
          objeto.nome = resultado.nome;
          this.adicionarTemporario(objeto, this.objetos);
        });
      }
    });
  }

  adicionarContaContabil(): void {
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.contaContabil));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.objetos));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.contaContabil));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }

  persistirObjeto(): void {
    if (this.ehValido()) {
      let transporte: Transporte = new Transporte(this.objetos);
      transporte.adicionar(this.idsConfiguracaoExcluir);
      super.persistir(transporte, this.servico, null).subscribe((res) => {
        if (!this.utilSessao.falha) {
          this.definirId(this.objetos, this.classe, true);
          this.idsConfiguracaoExcluir = [];
        }
      });
    }
  }

  ehValido(): boolean {
    if (this.objetos.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.adicioneContaContabil));
    return false;
  }
}
