<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-10 text-center clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.emissao }}</th>
        <th class="l-15 clique" (click)="listar(add('operacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.operacao }}</th>
        <th class="l-35 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-20 clique" (click)="listar(add('colaborador'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.vendedor }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr [ngClass]="{ tachado: movimentacao.ativo != 'S' }">
          <td>{{ movimentacao.abreviacao }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td class="text-center">{{ movimentacao.data | data }}</td>
          <td class="limitar">{{ movimentacao.operacao }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(movimentacao.id, i, movimentacoes)">{{ movimentacao.parceiro }}</a>
          </td>
          <td class="limitar">{{ movimentacao.colaborador }}</td>
          <td class="row justify-content-end">
            <btnAdicional [id]="'quantidade'" [icone]="bibIcone.editar" (btnAdicional)="ir(movimentacao.id, i, movimentacoes)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
