<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [grupos]="grupos" (campoGrupoEmt)="agrupar(lancamentoContabeis, $event)" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1">{{ bibDialogo.conta }}</th>
      <th class="r">{{ bibDialogo.historico }}</th>
      <th class="r-1 text-right">{{ bibDialogo.debito }}</th>
      <th class="r-1 text-right">{{ bibDialogo.credito }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let lancamentoContabil of lancamentoContabeis; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ lancamentoContabil.abreviacao }}</td>
          <td>{{ lancamentoContabil.data | data }}</td>
          <td>{{ lancamentoContabil.numeroConta }}</td>
          <td>{{ lancamentoContabil.historico }}</td>
          <td class="text-right">{{ lancamentoContabil.debito | monetario }}</td>
          <td class="text-right">{{ lancamentoContabil.credito | monetario }}</td>
        </tr>
        <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="lancamentoContabeis.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
