<layout [titulo]="grupo.nome" [id]="grupo.id" (voltarAvancarEmt)="ehAlteracao()">
  <!-- <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.icmsEntrada" [imagem]="bibImagem.csticmsentrada"></titulo>
    <botao [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.icmsEntrada" (botaoEmt)="abrirModalIcms(1)"></botao>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5">{{ bibDialogo.uf }}</th>
        <th class="l-20">{{ bibDialogo.enquadramento }}</th>
        <th class="l-35">{{ menuClassificacaoFiscal.apelido }}</th>
        <th class="l-15">{{ bibDialogo.aliquotaJuridica }}</th>
        <th class="l-15">{{ bibDialogo.aliquotaFisica }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let grupoIcms of grupoCstsIcms | filtrarIcmsEntradaSaida: { filtro: 1 }; let i = index">
        <tr>
          <td class="limitar">{{ grupoIcms.estado }}</td>
          <td class="limitar">{{ grupoIcms.enquadramento == 1 ? this.bibDialogo.simplesNacional : grupoIcms.enquadramento == 2 ? this.bibDialogo.lucroPresumido : grupoIcms.enquadramento == 3 ? this.bibDialogo.lucroReal : this.bibDialogo.simplesNacionalExcesso }}</td>
          <td class="limitar">{{ grupoIcms.classificacaoFiscal }}</td>
          <td>{{ grupoIcms.aliquotaJuridica + '%' }}</td>
          <td>{{ grupoIcms.aliquotaFisica + '%' }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModalIcms(1, grupoIcms)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoIcms(i, grupoIcms.tipo, grupoIcms.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table> -->
  <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.icms" [imagem]="bibImagem.csticmssaida"></titulo>
    <botao [id]="'adicionarIcms'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.icms" (botaoEmt)="abrirModalIcms(-1)"></botao>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10">{{ bibDialogo.ufOrigem }}</th>
        <th class="l-10">{{ bibDialogo.ufDestino }}</th>
        <th class="l-30">{{ bibDialogo.enquadramento }}</th>
        <th class="l-30">{{ menuClassificacaoFiscal.apelido }}</th>
        <th class="l-10">{{ bibDialogo.desonerado }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let grupoIcms of grupoCstsIcms | filtrarIcmsEntradaSaida: { filtro: -1 }; let i = index">
        <tr>
          <td class="limitar">{{ grupoIcms.estadoOrigem }}</td>
          <td class="limitar">{{ grupoIcms.estado }}</td>
          <td class="limitar">{{ grupoIcms.enquadramento == 1 ? this.bibDialogo.simplesNacional : grupoIcms.enquadramento == 2 ? this.bibDialogo.lucroPresumido : grupoIcms.enquadramento == 3 ? this.bibDialogo.lucroReal : this.bibDialogo.simplesNacionalExcesso }}</td>
          <td class="limitar">{{ grupoIcms.classificacaoFiscal }}</td>
          <td class="limitar">{{ grupoIcms.usaIcmsDesonerado == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModalIcms(-1, grupoIcms)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [id]="'excluir' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoIcms(i, grupoIcms.tipo, grupoIcms.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <icmsSt [icmsSts]="grupo.grupoIcmsSts" [idsExcluir]="excluirGrupoIcmsSts" [classe]="bibClasse.grupoIcmsSt"></icmsSt>
</layout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGrupoCstIcms()" [desabilitado]="bloquearPermissao(grupo.id)"></botao>
