import { Type } from 'class-transformer';

export class ComissaoFinanceiro {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public colaborador: string;
  public id: number;
  public idComissao: number;
  public idFinanceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public lote: string;
  public movimentacaoNumero: number;
  public parceiro: string;
  public selecionado: string;
  public valor: number;
}
