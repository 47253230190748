import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { GrupoAcessoLocal } from 'src/app/modelo/grupoAcessoLocal';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'grupoAcessoLocalFrm',
  templateUrl: './grupoAcessoLocalFrm.component.html',
})
export class GrupoAcessoLocalFrmComponent extends PaginaComponent {
  @Input() grupoAcessoLocais: GrupoAcessoLocal[] = [];
  @Input() excluirGrupoAcessoLocais: number[] = [];
  @Input() edicaoGrupoAcessoLocais: number[] = [];

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;

  adicionar(): void {
    let grupoAcessoLocal = new GrupoAcessoLocal();
    this.grupoAcessoLocais.push(grupoAcessoLocal);
  }

  editar(grupoAcessoLocal: GrupoAcessoLocal): void {
    if (this.edicaoGrupoAcessoLocais.includes(grupoAcessoLocal.id)) {
      const posicao: number = this.edicaoGrupoAcessoLocais.indexOf(grupoAcessoLocal.id, 0);
      this.edicaoGrupoAcessoLocais.splice(posicao, 1);
    } else {
      this.edicaoGrupoAcessoLocais.push(grupoAcessoLocal.id);
    }
  }

  excluirGrupoAcessoLocal(posicao: number, idsGrupoAcessoLocal: number): void {
    super.ehExcluir(posicao, this.grupoAcessoLocais, this.excluirGrupoAcessoLocais, idsGrupoAcessoLocal);
  }
}
