import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';

@Component({
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css'],
})
export class PesquisaComponent extends PaginaComponent {
  @ViewChild('corpoTabela') private corpoTabela: ElementRef;
  private criterio: string = '';
  private criterios: Criterio[] = [];
  public compacto: boolean = false;
  public filtro = new Criterio(this.criterio != "" ? this.criterio : null);
  public focoBusca: number;
  public lista: any[] = [];
  public nomePesquisa: string = '';
  public numeroCaracteres: number = 3;
  public pesquisando: boolean = false;
  public posicaoLista: number = -1;
  public reiniciando: boolean;
  public selecionados: any[] = [];
  public servico: string;
  public usaAbaSelecionada: boolean = false;
  public util: Util = new Util();
  public desmarcado: boolean = false;

  ngOnInit(): void {
    this.servico = this.utilSessao.getIdentificacao('servico')?.conteudo;
    this.selecionados = this.resumir(this.utilSessao.getIdentificacao('lista-' + this.servico)?.conteudo);
    this.selecionados.forEach((selecionado) => (selecionado.selecionado = 'S'));
    this.nomePesquisa = this.utilSessao.getIdentificacao('nomePesquisa')?.conteudo;
    if (this.utilSessao.getIdentificacao('criterios')) {
      this.criterios = this.utilSessao.getIdentificacao('criterios')?.conteudo;
    }
    this.listarTodosAoIniciar();
  }

  listarTodosAoIniciar(): void {
    if (![this.bibServico.parceiro, this.bibServico.produto, this.bibServico.contaContabil].includes(this.servico)) {
      this.numeroCaracteres = 0;
      this.listar();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    switch (event.keyCode) {
      case bibAtalho.setaCima:
        event.preventDefault();
        this.marcarLista(-1);
        break;

      case bibAtalho.setaBaixo:
        event.preventDefault();
        this.marcarLista(1);
        break;

      case bibAtalho.espaco:
        event.preventDefault();
        if ((this.lista.length > 0 || this.selecionados.length > 0) && this.posicaoLista >= 0) {
          this.ehSetSelecionado(this.posicaoLista);
        }
        event.stopImmediatePropagation();
        break;

      case bibAtalho.enter:
        event.preventDefault();
        if (this.selecionados.length > 0 && !this.pesquisando) {
          this.adicionarRegistros();
        }
        break;

      case bibAtalho.cancelar:
        event.preventDefault();
        this.focoBusca = Math.random();
        event.stopImmediatePropagation();
        break;
    }
  }

  resumir(objetos: any[]): any[] {
    let resumos: any[] = [];
    if (objetos && objetos.length > 0) {
      for (const objeto of objetos) {
        let campoId: string = objeto['id' + this.util.definirPrimeiraMaiscula(this.servico)] != null ? 'id' + this.util.definirPrimeiraMaiscula(this.servico) : 'id';
        let campoNome: string = objeto[this.servico] != null ? this.servico : objeto['razaoSocial'] != null ? 'razaoSocial' : 'nome';
        resumos.push({ id: objeto[campoId], nome: objeto[campoNome] });
      }
    }
    return resumos;
  }

  marcarLista(incremento: number): void {
    if (this.lista.length > 0) {
      this.posicaoLista = this.util.marcarLista(this.posicaoLista, incremento, this.lista);
      if (this.posicaoLista >= this.posicao && this.posicaoLista < this.lista.length) {
        this.rolarBarraRolagem();
      } else {
        this.posicaoLista = this.posicaoLista + incremento * -1;
      }
      if (this.posicaoLista >= 0) {
        Promise.resolve(null).then(() => this.corpoTabela.nativeElement.focus());
      }
    }
  }

  rolarBarraRolagem(): void {
    const posicaoCalculada: number = this.posicaoLista - this.posicao;
    this.corpoTabela.nativeElement.scrollTop = posicaoCalculada * 21.39;
  }

  ehSetSelecionado(posicao: number): void {
    const registro = this.usaAbaSelecionada ? this.selecionados[posicao] : this.lista[posicao];
    registro.selecionado = registro.selecionado == 'N' || !registro.selecionado ? 'S' : 'N';
    this.setSelecionado(registro);
  }

  setSelecionado(registro: any): void {
    if (registro.selecionado == 'S') {
      this.selecionados.push(registro);
    } else {
      this.desmarcado = true;
      let posicao: number = 0;
      for (let selecionado of this.selecionados) {
        if (selecionado.id == registro.id) {
          this.selecionados.splice(posicao, 1);
        }
        posicao++;
      }
    }
  }

  adicionarRegistros(): void {
    if (this.selecionados.length > 0 || this.desmarcado) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.servico, this.selecionados));
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
    }
  }

  alterarValorFiltro(valor: string): void {
    if (this.filtro.valor != valor.toUpperCase().trim()) {
      this.pesquisando = true;
    }
    this.filtro.nome = this.filtro.nome == null ? 'NOME' : this.filtro.nome;
    this.filtro.valor = valor.toUpperCase().trim();
  }

  listar(): void {
    let criterios: Criterio[] = this.criterios;
    criterios.push(this.filtro);
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios = this.adicionarCriterioLoja(criterios);
    this.comunicacaoService.listar(new Transporte(criterios), this.servico == this.bibServico.lojaUsuario ? this.bibServico.loja : this.servico).subscribe((res) => {
      this.lista = res;
      if (this.lista.length > 0) {
        this.modificarNomeLoja();
        for (let selecionado of this.selecionados) {
          this.conferirRegistros(selecionado.id, 'S');
        }
        this.atualizarNome();
      }
    });
  }

  modificarNomeLoja(): void {
    if (this.servico == this.bibServico.loja || this.servico == this.bibServico.lojaUsuario) {
      this.lista.forEach((lista) => {
        lista.razaoSocial = lista.nomeFantasia;
      });
    }
  }

  adicionarCriterioLoja(criterios: Criterio[]): Criterio[] {
    if (this.servico == this.bibServico.loja) {
      criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    }
    return criterios;
  }

  conferirRegistros(id: number, selecionado: string): void {
    for (let registro of this.lista) {
      if (registro.id == id) {
        registro.selecionado = selecionado;
      }
    }
  }

  atualizarNome(): void {
    if (this.selecionados.length > 0 && this.selecionados[0].nome == null) {
      for (let objeto of this.lista.filter((objeto) => objeto.selecionado == 'S')) {
        const selecionado = this.selecionados.find((selecionado) => selecionado.id == objeto.id);
        selecionado.nome = objeto.razaoSocial || objeto.nome;
      }
    }
  }

  setPosicao(posicao: number): void {
    this.posicao = posicao;
    if (this.corpoTabela) {
      this.corpoTabela.nativeElement.scrollTop = 0;
    }
    if ((this.usaAbaSelecionada && this.selecionados.length > 0) || (this.usaAbaSelecionada == false && this.lista.length > 0)) {
      if (!this.pesquisando) {
        this.posicaoLista = this.posicao;
      }
    }
  }

  setAbaSelecionada(usaAbaSelecionada: boolean): void {
    this.usaAbaSelecionada = usaAbaSelecionada;
    this.posicao = 0;
    this.posicaoLista = -1;
  }
}
