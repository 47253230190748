export class MovimentacaoQuantidade {
  public abreviacao: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public excluido: boolean = false;
  public id: number;
  public idEmpresa: number;
  public idLocal: number;
  public idLoja: number = 0;
  public idLote: number;
  public idMovimentacaoProduto: number;
  public idMovimentacaoQuaOrigem: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public lojaAbreviacao: string;
  public origem: string = 'N';
  public quantidade: number = 0;
  public quantidadeDesfazimento: number;
  public quantidadeDesfeita: number = 0;
  public quantidadeDevolvida: number = 0;
  public quantidadeDevolvidaEst: number = 0;
  public quantidadeDevolvidaSub: number = 0;
  public quantidadeEntregue: number = 0;
  public quantidadeEstoqueDisponivel: number = 0;
  public quantidadeEstoqueFisico: number;
  public quantidadePendente: number = 0;
  public quantidadeSepararEntrega: number;
  public referencia: string = 'D';
  public status: string = 'N';
  public valida: boolean = false;
  public lote: string;
}
