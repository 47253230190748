<td class="p-0" colspan="4">
  <table class="tabela-complementar">
    <tbody>
      <tr>
        <td>{{ parceiro.numero + ' - ' + parceiro.razaoSocial }}</td>
        <td *ngIf="usaNomeFantasia">{{ parceiro.nomeFantasia }}</td>
        <td *ngIf="usaDocumento" class="r-2">{{ parceiro.cnpjCpf }}</td>
        <td *ngIf="usaDocumento" class="r-2">{{ parceiro.identidade }}</td>
        <td *ngIf="usaDocumento" class="r-1">{{ parceiro.dataNascimento | data }}</td>
        <td *ngIf="rotuloAgrupaAtual != utilFiltro.apelidoTelaRelacionamento && usaRelacionamento" class="r-2">{{ parceiro.relacionamento }}</td>
        <td *ngIf="rotuloAgrupaAtual != bibDialogo.uf" class="r-1">{{ parceiro.estado }}</td>
      </tr>
    </tbody>
  </table>
</td>
