import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'valorParcela', posicao: 6 },
  { campo: 'valorBaixa', posicao: 7 },
  { campo: 'valor', posicao: 8 },
  { campo: 'valorPago', posicao: 9 },
  { campo: 'valorDisponivel', posicao: 10 },
  { campo: 'valorRestante', posicao: 11 },
];
export default totais;
