import { Type } from 'class-transformer';

export class ParceiroEndereco {
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public alterado: boolean = false;
  public ativo: string = 'S';
  public bairro: string;
  public cep: string;
  public cidade: string;
  public cidadeIbge: string;
  public complemento: string;
  public endereco: string;
  public estado: string;
  public excluido: boolean = false;
  public id: number;
  public idBairro: number = 0;
  public idCidade: number = 0;
  public idEmpresa: number;
  public idEstado: number = 0;
  public idRegiao: number;
  public idPais: number;
  public idParceiro: number;
  public idTemporario: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idVinculo: number;
  public latitude: string;
  public longitude: string;
  public numero: string;
  public observacao: string;
  public pais: string;
  public regiao: string;
  public tipo: string = '';
  public uf: string;
  public vinculo: string;
}
