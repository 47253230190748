import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component } from '@angular/core';
import { Conta } from 'src/app/modelo/conta';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { LancamentoContabil } from 'src/app/modelo/lancamentoContabil';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { RoteiroContabilRateio } from 'src/app/modelo/roteiroContabilRateio';
import { TipoLancamentoContabil } from 'src/app/modelo/tipoLancamentoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './lancamentoContabilFrm.component.html',
})
export class LancamentoContabilFrmComponent extends PaginaComponent {
  public ativo: boolean = false;
  public cabecalhoBloqueado: boolean = false;
  public componente: any;
  public criterioRotuloTodos: Criterio[] = [new Criterio('SEM_ROTULO_PARCEIRO_DOCUMENTO', 'S'), new Criterio('TIPO', '0')];
  public criterioUsuarioAcessoCategoria: Criterio[] = [new Criterio('USUARIO_ACESSO_CATEGORIA_ROTEIRO_CONTABIL', 'S')];
  public criterios: Criterio[] = [new Criterio('NOME_NUMERACAO', 'S'), new Criterio('SEM_ID_ZERO', 'S'), new Criterio('TODOS_OU_AUTOMATICO', 'S')];
  public diferenca: number;
  public edicaoLancamentoContabilIds: number[] = [];
  public focoIdAdicionar: number;
  public idsLancamentoContabilExclusao: number[] = [];
  public lancamentoContabeis: LancamentoContabil[] = [];
  public lancamentoContabil: LancamentoContabil = new LancamentoContabil();
  public parametro: string;
  public tipoLancamentoContabeis: TipoLancamentoContabil[] = [];
  public totalCredito: number = 0;
  public totalDebito: number = 0;
  public data: Date = new Date();

  ngOnInit(): void {
    this.parametro = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    if (this.parametro == 'roteiro') {
      this.lancamentoContabil.origem = 4;
    }
    this.ehAlteracao();
    this.listarTipoLancamentoContabil();
  }

  ehAlteracao(): void {
    this.lancamentoContabil = this.plainToClass(LancamentoContabil, this.ehAtualizacao(this.lancamentoContabil));
    if (this.lancamentoContabil.id) {
      this.listarLancamentoContabil(this.lancamentoContabil);
      this.cabecalhoBloqueado = true;
      this.focoIdAdicionar = Math.random();
    }
  }

  listarLancamentoContabil(lancamentoContabil: LancamentoContabil): void {
    this.lancamentoContabeis = [];
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', lancamentoContabil.id)), this.bibServico.lancamentoContabil).subscribe((res) => {
      this.lancamentoContabil = this.plainToClass(LancamentoContabil, res[0]) as any;
      this.lancamentoContabeis.push(this.lancamentoContabil);
      this.comunicacaoService.listar(new Transporte(new Criterio('CHAVE', this.lancamentoContabil.chave)), this.bibServico.lancamentoContabil).subscribe((res) => {
        let lancamentoContabeis: LancamentoContabil[] = this.plainToClass(LancamentoContabil, res) as any;
        lancamentoContabeis.forEach((lancamentoContabil) => {
          if (lancamentoContabil.id != this.lancamentoContabil.id) {
            this.lancamentoContabeis.push(lancamentoContabil);
          }
        });
        this.calcularTotais();
        this.popularManual();
      });
    });
  }

  listarTipoLancamentoContabil(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoLancamentoContabil).subscribe((res) => {
      this.tipoLancamentoContabeis = this.plainToClass(TipoLancamentoContabil, res) as any;
    });
  }

  calcularTotais(): void {
    this.totalCredito = 0;
    this.totalDebito = 0;
    this.lancamentoContabeis.forEach((lancamentoContabil) => {
      this.totalCredito += lancamentoContabil.debitoCredito == -1 ? lancamentoContabil.valor : 0;
      this.totalDebito += lancamentoContabil.debitoCredito == 1 ? lancamentoContabil.valor : 0;
    });
    this.diferenca = this.totalDebito - this.totalCredito;
  }

  popularManual() {
    if (this.parametro != 'roteiro') {
      if (this.lancamentoContabeis.length == 2) {
        const lancamentoContabilDebito: LancamentoContabil = this.lancamentoContabeis.filter((lancamentoContabil) => lancamentoContabil.debitoCredito == 1)[0];
        lancamentoContabilDebito.contaContabil = lancamentoContabilDebito.contaContabil.replace(/\s/g, '');
        this.lancamentoContabil.idContaDebito = lancamentoContabilDebito.idContaContabil;
        this.lancamentoContabil.contaDebito = lancamentoContabilDebito.contaContabil;
        this.lancamentoContabil.idParceiroDebito = lancamentoContabilDebito.idParceiro;
        this.lancamentoContabil.parceiroDebito = lancamentoContabilDebito.idParceiro ? lancamentoContabilDebito.parceiro : null;
        this.lancamentoContabil.idContaBancariaDebito = lancamentoContabilDebito.idContaBancaria;
        this.lancamentoContabil.contaDebito = lancamentoContabilDebito.contaContabil;
        this.lancamentoContabil.contaBancariaDebito = lancamentoContabilDebito.idContaBancaria ? lancamentoContabilDebito.contaBancaria : null;
        this.lancamentoContabil.correntistaDebito = lancamentoContabilDebito.correntista;

        const lancamentoContabilCredito: LancamentoContabil = this.lancamentoContabeis.filter((lancamentoContabil) => lancamentoContabil.debitoCredito == -1)[0];
        lancamentoContabilCredito.contaContabil = lancamentoContabilCredito.contaContabil.replace(/\s/g, '');
        this.lancamentoContabil.idContaCredito = lancamentoContabilCredito.idContaContabil;
        this.lancamentoContabil.contaCredito = lancamentoContabilCredito.contaContabil;
        this.lancamentoContabil.idParceiroCredito = lancamentoContabilCredito.idParceiro;
        this.lancamentoContabil.parceiroCredito = lancamentoContabilCredito.idParceiro ? lancamentoContabilCredito.parceiro : null;
        this.lancamentoContabil.idContaBancariaCredito = lancamentoContabilCredito.idContaBancaria;
        this.lancamentoContabil.contaCredito = lancamentoContabilCredito.contaContabil;
        this.lancamentoContabil.contaBancariaCredito = lancamentoContabilCredito.idContaBancaria ? lancamentoContabilCredito.contaBancaria : null;
        this.lancamentoContabil.correntistaCredito = lancamentoContabilCredito.correntista;
      }
    }
  }

  adicionarLancamento(lancamentoContabil: LancamentoContabil): void {
    lancamentoContabil.idTipoLancamentoContabil = this.lancamentoContabil.idTipoLancamentoContabil;
    lancamentoContabil.valor = this.lancamentoContabil.valorReferencia;
    lancamentoContabil.valorReferencia = this.lancamentoContabil.valorReferencia;
    lancamentoContabil.percentual = 100;
    lancamentoContabil.historico = this.lancamentoContabil.historico;
    lancamentoContabil.idLoja = this.lancamentoContabil.idLoja;
    this.lancamentoContabeis.push(lancamentoContabil);
  }

  editar(lancamentoContabil: LancamentoContabil): void {
    if (this.edicaoLancamentoContabilIds.includes(lancamentoContabil.id)) {
      const posicao: number = this.edicaoLancamentoContabilIds.indexOf(lancamentoContabil.id, 0);
      this.edicaoLancamentoContabilIds.splice(posicao, 1);
    } else {
      this.edicaoLancamentoContabilIds.push(lancamentoContabil.id);
    }
  }

  ehValidoAbrirModal(): boolean {
    if (!this.lancamentoContabil.data || !this.lancamentoContabil.idTipoLancamentoContabil || !this.lancamentoContabil.idLoja) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    if (this.lancamentoContabil.valorReferencia <= 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorReferenciaMaiorQueZero));
      return false;
    }
    return true;
  }

  gerarLancamentoContabil(): void {
    if (this.lancamentoContabil.valorReferencia > 0) {
      if (this.lancamentoContabil.idRoteiroContabil && (this.lancamentoContabeis.length > 0 ? confirm(this.bibDialogo.certezaGerarNovosLancamentos) : true)) {
        this.lancamentoContabeis.forEach((lancamentoContabil) => (lancamentoContabil.id ? this.idsLancamentoContabilExclusao.push(lancamentoContabil.id) : null));
        this.lancamentoContabeis = [];
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_ROTEIRO_CONTABIL', this.lancamentoContabil.idRoteiroContabil)), this.bibServico.roteiroContabilRateio).subscribe((res) => {
          let roteiroContabilRateios: RoteiroContabilRateio[] = this.plainToClass(RoteiroContabilRateio, res) as any;
          roteiroContabilRateios.forEach((roteiroContabilRateio) => {
            let lancamentoContabilDebito: LancamentoContabil = this.criarContaContabil(roteiroContabilRateio, 1);
            lancamentoContabilDebito.contaBancaria = roteiroContabilRateio.contaBancaria;
            lancamentoContabilDebito.valor = this.lancamentoContabil.valorReferencia;
            lancamentoContabilDebito.parceiro = roteiroContabilRateio.parceiro;
            lancamentoContabilDebito.idLoja = this.lancamentoContabil.idLoja;
            lancamentoContabilDebito.origem = 4;
            this.adicionarTemporario(this.plainToClass(LancamentoContabil, lancamentoContabilDebito), this.lancamentoContabeis);
            let lancamentoContabilCredito: LancamentoContabil = this.criarContaContabil(roteiroContabilRateio, -1);
            lancamentoContabilCredito.contaBancaria = roteiroContabilRateio.contaBancaria;
            lancamentoContabilCredito.valor = this.lancamentoContabil.valorReferencia;
            lancamentoContabilCredito.parceiro = roteiroContabilRateio.parceiro;
            lancamentoContabilCredito.idLoja = this.lancamentoContabil.idLoja;
            lancamentoContabilCredito.origem = 4;
            this.adicionarTemporario(this.plainToClass(LancamentoContabil, lancamentoContabilCredito), this.lancamentoContabeis);
          });
          this.calcularTotais();
        });
      }
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorReferenciaMaiorQueZero));
    }
  }

  criarContaContabil(roteiroContabilRateio: RoteiroContabilRateio, tipoConta: number): LancamentoContabil {
    let lancamentoContabil: LancamentoContabil = new LancamentoContabil();
    lancamentoContabil.idContaContabil = tipoConta == 1 ? roteiroContabilRateio.idContaContabil : roteiroContabilRateio.idContaContabilContrapartida;
    lancamentoContabil.contaContabil = tipoConta == 1 ? roteiroContabilRateio.contaContabil : roteiroContabilRateio.contaContabilContrapartida;
    lancamentoContabil.percentual = roteiroContabilRateio.percentual;
    lancamentoContabil.valor = (this.lancamentoContabil.valorReferencia * lancamentoContabil.percentual) / 100;
    lancamentoContabil.historico = roteiroContabilRateio.historico;
    lancamentoContabil.idTipoLancamentoContabil = roteiroContabilRateio.idTipoLancamentoContabil;
    lancamentoContabil.idRoteiroContabil = this.lancamentoContabil.idRoteiroContabil;
    lancamentoContabil.tipoLancamentoContabil = roteiroContabilRateio.tipoLancamentoContabil;
    lancamentoContabil.debitoCredito = tipoConta;
    lancamentoContabil.alterado = true;
    return lancamentoContabil;
  }

  setContaDebito(lancamentoContabil: LancamentoContabil, contaContabil: ContaContabil): void {
    lancamentoContabil.idContaContabil = contaContabil.id;
    lancamentoContabil.contaContabil = contaContabil.numeracao + ' - ' + contaContabil.nome.trim();
    if (contaContabil.correntista == 2 || contaContabil.correntista == 3 || contaContabil.correntista == 4) {
      lancamentoContabil.ehCorrentistaParceiro = contaContabil.correntista == 2 ? true : false;
      lancamentoContabil.ehCorrentistaConta = contaContabil.correntista == 3 || contaContabil.correntista == 4 ? true : false;
    } else {
      lancamentoContabil.idParceiro = null;
      lancamentoContabil.parceiro = null;
      lancamentoContabil.idContaBancaria = null;
      lancamentoContabil.contaBancaria = null;
      lancamentoContabil.ehCorrentistaParceiro = false;
      lancamentoContabil.ehCorrentistaConta = false;
    }
  }

  setParceiro(lancamentoContabil: LancamentoContabil, parceiro: Parceiro): void {
    lancamentoContabil.idParceiro = parceiro.id;
    lancamentoContabil.parceiro = parceiro.razaoSocial;
  }

  setContaBancaria(lancamentoContabil: LancamentoContabil, conta: Conta): void {
    lancamentoContabil.idContaBancaria = conta.id;
    lancamentoContabil.contaBancaria = conta.nome;
  }

  persistirLancamentoContabil(): void {
    if (this.ehValido()) {
      this.lancarManual();
      const chave: string = 'L' + new Date().getTime().toString() + '_' + this.utilSessao.getUsuario().id;
      this.lancamentoContabeis.forEach((lancamentoContabil) => {
        lancamentoContabil.chave = chave;
        lancamentoContabil.data = this.data;
      });
      let transporte: Transporte = new Transporte(this.lancamentoContabeis);
      transporte.adicionar(this.idsLancamentoContabilExclusao);
      super.persistir(transporte, this.bibServico.lancamentoContabil, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId(this.lancamentoContabeis, this.bibClasse.lancamentoContabil, true);
          this.lancamentoContabil.id = this.lancamentoContabeis[0].id;
          this.idsLancamentoContabilExclusao = [];
          this.cabecalhoBloqueado = true;
        }
      });
    }
  }

  public lancarManual(): void {
    if (this.parametro != 'roteiro') {
      let lancamentoContabilDebito: LancamentoContabil = new LancamentoContabil();
      lancamentoContabilDebito.debitoCredito = 1;
      lancamentoContabilDebito.idContaContabil = this.lancamentoContabil.idContaDebito;
      this.lancamentoContabil.contaContabil = this.lancamentoContabil.contaDebito;
      lancamentoContabilDebito.idParceiro = this.lancamentoContabil.idParceiroDebito;
      lancamentoContabilDebito.idContaBancaria = this.lancamentoContabil.idContaBancariaDebito;
      //lancamentoContabilDebito.correntista = this.lancamentoContabil.idParceiroDebito ? this.lancamentoContabil.parceiroDebito : this.lancamentoContabil.idContaBancariaDebito ? this.lancamentoContabil.contaBancariaDebito : null;
      this.adicionarLancamento(lancamentoContabilDebito);

      let lancamentoContabilCredito: LancamentoContabil = new LancamentoContabil();
      lancamentoContabilCredito.debitoCredito = -1;
      lancamentoContabilCredito.idContaContabil = this.lancamentoContabil.idContaCredito;
      this.lancamentoContabil.contaCredito = this.lancamentoContabil.contaCredito;
      lancamentoContabilCredito.idParceiro = this.lancamentoContabil.idParceiroCredito;
      lancamentoContabilCredito.idContaBancaria = this.lancamentoContabil.idContaBancariaCredito;
      //lancamentoContabilDebito.correntista = this.lancamentoContabil.idParceiroCredito ? this.lancamentoContabil.parceiroCredito : this.lancamentoContabil.idContaBancariaCredito ? this.lancamentoContabil.contaBancariaCredito : null;
      this.adicionarLancamento(lancamentoContabilCredito);
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoObrigatorioManual: boolean = this.ehValidoObrigatorioManual();
    const ehValidoObrigatorioRoteiro: boolean = this.ehValidoObrigatorioRoteiro();
    const ehValidoUmRateio: boolean = this.setEhValidoUmRateio();
    const ehValidoTotal: boolean = this.setEhValidoTotal();
    return ehValidoObrigatorio && ehValidoObrigatorioManual && ehValidoObrigatorioRoteiro && ehValidoUmRateio && ehValidoTotal;
  }

  ehValidoObrigatorio(): boolean {
    if (this.lancamentoContabil.data && this.lancamentoContabil.idTipoLancamentoContabil && this.lancamentoContabil.valorReferencia && this.lancamentoContabil.valorReferencia > 0 && this.lancamentoContabil.idLoja) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoObrigatorioManual(): boolean {
    if (this.parametro != 'roteiro') {
      if (this.lancamentoContabil.historico && this.lancamentoContabil.idContaDebito && this.lancamentoContabil.idContaCredito && (this.lancamentoContabil.idParceiroDebito || this.lancamentoContabil.idContaBancariaDebito || this.lancamentoContabil.correntistaDebito == 1) && (this.lancamentoContabil.idParceiroCredito || this.lancamentoContabil.idContaBancariaCredito || this.lancamentoContabil.correntistaCredito == 1)) {
        return true;
      }
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio + ' xxxx'));
      return false;
    }
    return true;
  }

  ehValidoObrigatorioRoteiro(): boolean {
    if (this.parametro == 'roteiro') {
      let quantidadeObrigatoriosNaoPreenchidos: number = 0;
      this.lancamentoContabeis.forEach((lancamentoContabil) => {
        if (!lancamentoContabil.idContaContabil) {
          quantidadeObrigatoriosNaoPreenchidos++;
        }
      });
      if (quantidadeObrigatoriosNaoPreenchidos > 0) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
        return false;
      }
    }
    return true;
  }

  setEhValidoUmRateio(): boolean {
    if (this.parametro != 'roteiro' || this.lancamentoContabeis.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.deveConterPeloMenosUmLancamento));
    return false;
  }

  setEhValidoTotal(): boolean {
    if (this.diferenca && this.diferenca != 0 && this.parametro != 'roteiro') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.lancamentoContabilComDiferencaDeValores));
      return false;
    }
    return true;
  }

  setContaContabilDebito(contaContabil: ContaContabil): void {
    if (contaContabil) {
      this.lancamentoContabil.idContaDebito = contaContabil.idContaContabil;
      this.lancamentoContabil.contaDebito = contaContabil.numeracao + ' - ' + contaContabil.nome.trim();
      this.lancamentoContabil.correntistaDebito = contaContabil.correntista;
    } else {
      this.lancamentoContabil.idContaDebito = null;
      this.lancamentoContabil.contaDebito = null;
      this.lancamentoContabil.correntistaDebito = null;
    }
  }

  setContaContabilCredito(contaContabil: ContaContabil): void {
    if (contaContabil) {
      this.lancamentoContabil.idContaCredito = contaContabil.idContaContabil;
      this.lancamentoContabil.contaCredito = contaContabil.numeracao + ' - ' + contaContabil.nome.trim();
      this.lancamentoContabil.correntistaCredito = contaContabil.correntista;
    } else {
      this.lancamentoContabil.idContaCredito = null;
      this.lancamentoContabil.contaCredito = null;
      this.lancamentoContabil.correntistaCredito = null;
    }
  }
}
