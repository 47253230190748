export class TipoTituloCaixa {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idTipoTitulo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public parcelaAte: number;
  public parcelaDe: number;
  public taxa: number;
}
