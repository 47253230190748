import { Type } from 'class-transformer';

export class ParceiroCobranca {
  @Type(() => Date)
  public data: Date = new Date();
  @Type(() => Date)
  public dataProxima: Date;
  public id: number;
  public idParceiro: number;
  public valorAberto: number;
  public valorAtrasado: number;
  public observacao: string;
  public nomeUsuario: string;
  public formaCobranca: string;
}
