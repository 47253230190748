import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { Cheque } from 'src/app/modelo/cheque';
import { Identificacao } from 'src/app/modelo/identificacao';

@Component({
  selector: 'pesquisaCheque',
  templateUrl: './pesquisaCheque.component.html',
})
export class PesquisaChequeComponent extends PaginaComponent {
  private idsConta: number[];
  public chequeOrigemBaixa: boolean;
  public cheques: Cheque[] = [];
  public conta: string;
  public valorTotalCheques: number = 0;
  public valorTotalChequesSelecionados: number = 0;

  ngOnInit(): void {
    if (this.utilSessao.getIdentificacao('idsConta')) {
      this.idsConta = this.utilSessao.getIdentificacao('idsConta')?.conteudo;
    }
    this.conta = this.utilSessao.getIdentificacao('conta')?.conteudo;
    this.chequeOrigemBaixa = this.util.coalesce(this.utilSessao.getIdentificacao('chequeOrigemBaixa')?.conteudo, false);
    this.listar();
  }

  listar(): void {
    let criterios: Criterio[] = this.getCriterios();
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cheque).subscribe((res) => {
      this.cheques = this.plainToClass(Cheque, res) as any;
      this.adicionarChequeSelecionado();
    });
  }

  getCriterios(): Criterio[] {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('FINALIZADO', 'N'));
    if (this.chequeOrigemBaixa) {
      criterios.push(new Criterio('ORIGEM_CONCILIACAO', 'S'));
    }
    if (this.idsConta.length == 0) {
      criterios.push(new Criterio('ID_CONTA_ATUAL', this.utilSessao.getIdentificacao('idConta')?.conteudo));
    } else {
      criterios.push(new Criterio('IDS_CONTA_ATUAL', this.idsConta.toString()));
    }
    return criterios;
  }

  adicionarChequeSelecionado(): void {
    const chequesSelecionados: Cheque[] = this.plainToClass(Cheque, this.utilSessao.getIdentificacao('chequesSelecionados')?.conteudo);
    chequesSelecionados.forEach((chequeSelecionado) => {
      const cheque: Cheque = this.cheques.find((cheque) => cheque.id == chequeSelecionado.id);
      if (cheque) {
        cheque.selecionado = chequeSelecionado.selecionado;
      } else {
        this.cheques.push(chequeSelecionado);
      }
    });
    this.calcularValoresCheques();
  }

  adicionar(): void {
    const chequeSelecionados: Cheque[] = this.cheques.filter((cheque) => cheque.selecionado == 'S');
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.cheque, chequeSelecionados));
  }

  setSelecionado(cheque: Cheque, selecionado: string): void {
    cheque.selecionado = selecionado;
    this.calcularValoresCheques();
  }

  calcularValoresCheques(): void {
    this.valorTotalCheques = 0;
    this.valorTotalChequesSelecionados = 0;
    this.cheques.forEach((cheque) => {
      if (cheque.selecionado == 'S') {
        this.valorTotalChequesSelecionados += cheque.valor;
      }
      this.valorTotalCheques += cheque.valor;
    });
  }
}
