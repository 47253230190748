export class ParceiroArt {
  public alterado: boolean = false;
  public art: string;
  public ate: number;
  public atual: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public de: number;
  public estado: string;
  public id: number;
  public idEmpresa: number;
  public idEstado: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public mascaraInicial: string;
  public mascaraFinal: string;
  public integracaoUsuario: string;
  public integracaoSenha: string;
}
