<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <ng-container *ngFor="let movimentacao of movimentacoes">
    <div class="row" [ngClass]="[ehMatricial ? 'matricial' : '', ehModoClaro ? '' : 'tabela-modo-escuro']">
      <div class="col-sm-12">
        <h1 class="relatorio-rotulo">{{ bibDialogo.receituarioAgronomico }}</h1>
      </div>
    </div>
    <table [ngClass]="[ehMatricial ? 'matricial' : '', ehModoClaro ? '' : 'tabela-modo-escuro']">
      <tbody>
        <tr>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.data + ':' }}</span>
            <span class="r-1"> {{ movimentacao.data | data }} </span>
            <span *ngIf="movimentacao.documentoFiscal" class="relatorio-rotulo r-2 text-right">{{ bibDialogo.notaFiscal + ':' }} </span>
            <span class="r-1"> {{ movimentacao.documentoFiscal }} </span>
            <span *ngIf="movimentacao.chaveFiscal" class="relatorio-rotulo r-1 text-right">{{ bibDialogo.chaveNfe + ':' }} </span>
            <span class="r-4"> {{ movimentacao.chaveFiscal }} </span>
          </div>
          <div *ngIf="movimentacao.statusReceituario != 'S'" class="tarja-nao-finalizado">{{ bibDialogo.naoFinalizado }}</div>
        </tr>
        <tr>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.loja + ': ' }} </span>{{ movimentacao.loja }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.endereco + ': ' }} </span>{{ movimentacao.lojaEndereco }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ (movimentacao.agronomoTecnico == 'T' ? bibDialogo.tecnicoAgricola : bibDialogo.agronomo) + ': ' }} </span> <span class="r-3">{{ movimentacao.agronomo }} </span> 
            <span class="relatorio-rotulo r-2 text-right">{{ (movimentacao.agronomoTecnico == 'T' ? bibDialogo.cfta : bibDialogo.crea) + ':' }} </span> <span class="r-2">{{ movimentacao.crea }} </span>
            <span class="relatorio-rotulo r-1 text-right">{{ (movimentacao.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art) + ':' }} </span> <span>{{ movimentacao.art }} </span>
          </div>
        </tr>
        <tr>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.parceiro + ': ' }} </span>{{ movimentacao.parceiro }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cidadeUF + ': ' }} </span> <span class="r-3">{{ movimentacao.cidadeUf }} </span> 
            <span class="relatorio-rotulo r-2 text-right">{{ (movimentacao.tipoPessoa == 'F' ? bibDialogo.cpf : bibDialogo.cnpj) + ':' }} </span> <span class="r-2">{{ movimentacao.cnpjCpf }} </span>
            <span class="relatorio-rotulo r-1 text-right">{{ bibDialogo.inscricao + ':' }} </span> <span>{{ movimentacao.inscricaoEstadual }} </span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.localAplicacao + ': ' }} </span>{{ movimentacao.parceiroEndereco }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.latitude + ': ' }} </span> <span class="r-3">{{ movimentacao.latitude }}</span>
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.longitude + ': ' }} </span>{{ movimentacao.longitude }}
          </div>       
        </tr>
        <ng-container *ngFor="let receituario of movimentacao.receituarios">
          <tr>
            <div class="row pt-1">
              <span class="relatorio-rotulo r-2 text-right pt-1">{{ bibDialogo.receituario + ': ' }} </span>
              <h6>{{ receituario.numero }}</h6>
            </div>
            <div class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cultura + ': ' }} </span> <span class="r-6">{{ receituario.cultura }} </span> <span class="relatorio-rotulo r-1 text-right">{{ bibDialogo.area + ':' }} </span> {{ receituario.areaHa + ' HA' }}
            </div>
            <div class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.diagnostico + ': ' }} </span>{{ receituario.praga }}
            </div>
            <div class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.produto + ': ' }} </span> <span class="r-4">{{ receituario.produto }} </span> 
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.classeToxicologica + ':' }} </span> {{ receituario.classeToxicologica }}
            </div>
            <div class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.quantidade + ': ' }} </span> <span class="r-2">{{ setQuantidade(receituario.quantidade) }} </span> 
              <span class="relatorio-rotulo r-1 text-right">{{ bibDialogo.dose + ':' }} </span> <span class="r-2">{{ setDose(receituario.dose) }} </span>
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.volumeCalda + ':' }} </span> <span>{{ receituario.volumeCalda }} </span>
            </div>
            <div class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.orientacao + ': ' }} </span>{{ bibDialogo.orientacaoDescricao }}
            </div>
            <div class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.aplicacao + ': ' }} </span> <span [ngClass]="receituario.aplicacao == bibDialogo.soloSulcoPlantio.toUpperCase() ? 'r-8' : 'r-1'">{{ receituario.aplicacao }} </span> 
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.nAplicacoes + ':' }} </span> <span class="r-1">{{ receituario.numeroAplicacao }} </span>
              <span *ngIf="receituario.intervaloSeguranca" class="relatorio-rotulo r-3 text-right">{{ bibDialogo.intervaloSeguranca + ':' }} </span> <span>{{ receituario.intervaloSeguranca }} </span>
            </div>
            <div *ngIf="receituario.epocaAplicacao" class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.epocaAplicacao + ': ' }} </span>{{ receituario.epocaAplicacao }}
            </div>
            <div *ngIf="receituario.modalidadeAplicacao" class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.modalidadeAplicacao + ': ' }} </span>{{ receituario.modalidadeAplicacao }}
            </div>
            <div *ngIf="receituario.equipamento" class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.equipamento + ': ' }} </span>{{ receituario.equipamento }}
            </div>     
            <div *ngIf="receituario.recomendacao" class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.recomendacao + ': ' }} </span>{{ receituario.recomendacao }}
            </div>                       
            <div *ngIf="receituario.observacao" class="row">
              <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.observacao + ': ' }} </span>{{ receituario.observacao }}
            </div>            
            <div class="row pt-2">
              <span class="relatorio-rotulo r">{{ bibDialogo.atencaoAnexo }} </span>
            </div>
          </tr>
        </ng-container>
        <tr>
          <div class="row pt-2">
            <span class="relatorio-rotulo r">{{ bibDialogo.disposicaoResiduosEmbalagens }} </span> <span class="text-justify pt-1">{{ bibDialogo.informacoesResiduosEmbalagens }}</span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.endEtregaEmbalagens + ': ' }} </span>{{ movimentacao.receituarioEntregaEmbalagem }}
          </div>
          <div class="row justify-content-center">
            <span class="r-5 text-center">{{ bibDialogo.cienteInformacaoReceita }}</span>
            <span class="r-6 text-center">{{ bibDialogo.confirmacaoInformacoes }}</span>
          </div>      
          <div class="row justify-content-center">      
            <div class="r-5">
              <div class="r-5 assinatura text-center">{{ movimentacao.parceiro }}</div>
              <p class="r-5 text-center">{{ (movimentacao.tipoPessoa == 'F' ? bibDialogo.cpf : bibDialogo.cnpj) + ': ' + movimentacao.cnpjCpf }}</p>
            </div>
            <div class="pl-5 r-6">
              <div class="r-5 assinatura text-center">{{ movimentacao.agronomo }}</div>
              <p class="r-5 text-center">{{ (movimentacao.tipoPessoaAgronomo == 'F' ? bibDialogo.cpf : bibDialogo.cnpj) + ': ' + movimentacao.cnpjCpfAgronomo + ' ' + (movimentacao.agronomoTecnico == 'T' ? bibDialogo.cfta : bibDialogo.crea) + ' ' + movimentacao.crea }}</p>
            </div>
          </div>
          <div class="row">
            <span class="r text-center">{{ movimentacao.dataRodape }} </span>
          </div>
        </tr>
        <tr class="quebra-pagina">
          <div class="row pt-2">
            <span class="relatorio-rotulo r">{{ bibDialogo.guiaAnexoI }} </span>
            <span class="text-justify r">{{ bibDialogo.equipamentoDeProtecaoIndividual }}</span>
            <span class="text-justify r">{{ bibDialogo.leiaInformacoes }}</span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo r">{{ bibDialogo.prevencoesPrimeirosSocorros }} </span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.ingestao }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoIngestao }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.inalacao }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoInalacao }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.olhosPele }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoOlhosPele }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo r">{{ bibDialogo.precaucoesGerais }} </span>
            <span class="text-justify r">{{ bibDialogo.orientacaoPrecaucaoGerais }}</span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.precaucoesGeraisCalda + ': ' }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoPrecaucaoGeraisCalda }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.precaucoesGeraisAplicacao + ': ' }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoPrecaucaoGeraisAplicacao }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.precaucoesGeraisAposAplicacao + ': ' }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoPrecaucaoGeraisAposAplicacao }}</p>
          </div>
          <div class="row justify-content-center">
            <span class="r text-center atencao">{{ bibDialogo.atencaoAgrotoxicoVenono }} </span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left pt-1">{{ bibDialogo.recomendacaoManejoResistencia + ': ' }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoRecomendacaoManejoResistencia }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.informacaoManejoResistencia + ': ' }} </span>
            <p class="text-justify">{{ bibDialogo.orientacaoInformacaoManejoResistencia }}</p>
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-left">{{ bibDialogo.residuosEmbalagens + ': ' }} </span>
            <p class="text-justify r">{{ bibDialogo.orientacaoResiduosEmbalagens }}</p>
            <span class="text-justify r">{{ bibDialogo.orientacaoResiduosEmbalagemProduto }}</span>
          </div>
        </tr>
        <tr *ngIf="ehDiquatOuParaquat" class="quebra-pagina">
          <p class="text-center mt-3">TERMO DE CONHECIMENTO DE RISCO E DE RESPONSABILIDADE PARA USUÁRIOS DE PRODUTOS À BASE DO INGREDIENTE ATIVO</p>
          <p class="text-center">(A SER ANEXADO À RESPECTIVA RECEITA AGRONÔMICA)</p>
          <b>VOCÊ SABIA?</b> 
          <p>- UM PEQUENO GOLE DE PARAQUATE PODE MATAR.</p>
          <p>- O PARAQUATE PODE SER ABSORVIDO PELA PELE.</p>
          <p>- EVIDÊNCIAS INDICAM QUE A EXPOSIÇÃO AO PARAQUATE PODE SER UM DOS FATORES DE RISCO PARA A DOENÇA DE PARKINSON EM TRABALHADORES RURAIS.</p>
          <p>- EVIDÊNCIAS DEMONSTRAM A EXISTÊNCIA DE RISCO DA EXPOSIÇÃO AO PARAQUATE CAUSAR MUTAÇÕES GENÉTICAS EM TRABALHADORES RURAIS.</p>
      
          <b>LEI</b> 
          <p>Devido aos riscos à saúde causados pelo PARAQUATE, seu uso será proibido no país a partir de 22 de setembro de 2020, conforme estabelecido na Resolução da Diretoria Colegiada - RDC n 177, de 21 de setembro de 2017. Aplicar utilizando trator de cabine fechada para aplicação.</p>
      
          <b>POR ISSO, SIGA AS SEGUINTES RECOMENDAÇÕES</b> 
          <p>- EVITE AO MÁXIMO O CONTATO COM O PRODUTO.</p>
          <p>- UTILIZE TODOS OS EQUIPAMENTOS DE PROTEÇÃO INDIVIDUAL (EPI) RECOMENDADOS PARA O MANUSEIO E APLICAÇÃO DO PRODUTO.</p>
          <p>- UTILIZE O PRODUTO APENAS NAS CULTURAS E FORMAS DE APLICAÇÃO AUTORIZADAS.</p>
      
          <b>SAIBA QUE</b> 
          <p>I. É DEVER DO PROFISSIONAL que lhe receitou PARAQUATE informar as medidas de segurança que podem diminuir os riscos à saúde causados pelo uso e manuseio deste produto.</p>
          <p>II. É SEU DEVER informar os demais usuários deste produto sobre as recomendações deste termo.</p> 
          <p>III. É SEU DIREITO e dos DEMAIS USUÁRIOS recusar o uso do PARAQUATE.</p>
          <p>
            EU, {{ movimentacao.parceiro }}, ENDEREÇO {{ movimentacao.parceiroEndereco }}, CEP: {{ movimentacao.parceiroCep }}, {{ movimentacao.cidadeUf }}, CPF/CNPJ 
            NÚMERO {{ movimentacao.cnpjCpf }} ORGÃO EMISSOR,
            DECLARO TER ENTENDIDO AS ORIENTAÇÕES PRESTADAS E ESTAR CIENTE DOS RISCOS À SAÚDE CAUSADOS PELO USO E MANUSEIO
            DO PRODUTO E DAS RECOMENDAÇÕES QUE DEVO SEGUIR DURANTE SUA UTILIZAÇÃO.
            LOCAL: {{ movimentacao.cidadeUf }}, {{ movimentacao.data | data }}. 
          </p>
          <p>
            DADOS DO PROFISSIONAL RESPONSÁVEL PELA EMISSÃO DA RECEITA AGRONÔMICA:
            NOME: {{ movimentacao.agronomo }}, Nº INSCRIÇÃO CREA: {{ movimentacao.crea }}. LOCAL: {{ movimentacao.cidadeUf }}, DATA: {{ movimentacao.data | data }}.
          </p>
          <div class="row justify-content-center">
            <span class="r-5 text-center">{{ bibDialogo.cienteInformacaoReceita }}</span>
            <span class="r-6 text-center">{{ bibDialogo.confirmacaoInformacoes }}</span>
          </div>      
          <div class="row justify-content-center">      
            <div class="r-5">
              <div class="r-5 assinatura text-center">{{ movimentacao.parceiro }}</div>
              <p class="r-5 text-center">{{ (movimentacao.tipoPessoa == 'F' ? bibDialogo.cpf : bibDialogo.cnpj) + ': ' + movimentacao.cnpjCpf }}</p>
            </div>
            <div class="pl-5 r-6">
              <div class="r-5 assinatura text-center">{{ movimentacao.agronomo }}</div>
              <p class="r-5 text-center">{{ (movimentacao.tipoPessoaAgronomo == 'F' ? bibDialogo.cpf : bibDialogo.cnpj) + ': ' + movimentacao.cnpjCpfAgronomo + ' ' + (movimentacao.agronomoTecnico == 'T' ? bibDialogo.cfta : bibDialogo.crea) + ' ' + movimentacao.crea }}</p>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </ng-container>
</relatorioLayout>
