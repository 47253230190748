<ng-container class="border-bottom border-dark">
  <td [colSpan]="5">    
    <table *ngIf="estoquePendencias && estoquePendencias.length > 0" class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.cliente }}</th>
          <th class="r-1">{{ bibDialogo.numero }}</th>
          <th class="r-1 text-right">{{ bibDialogo.quantidadeTotal }}</th>
          <th class="r-1 text-right">{{ bibDialogo.quantidadePendente }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let estoquePendencia of estoquePendencias">
          <tr class="conteudo">
            <td>{{ estoquePendencia.nomeParceiro }}</td>        
            <td>{{ estoquePendencia.numero }}</td>
            <td class="text-right">{{ estoquePendencia.quantidadeTotal | monetario: empresaRegraCasasDecimaisEntrada }}</td>
            <td class="text-right">{{ estoquePendencia.quantidadePendente | monetario: empresaRegraCasasDecimaisEntrada }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
