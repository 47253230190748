import { Component, Input } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroTelefone } from 'src/app/modelo/parceiroTelefone';
import { ParceiroTelefoneFrmComponent } from './frm/parceiroTelefoneFrm.component';

@Component({
  selector: 'parceiroTelefone',
  templateUrl: './parceiroTelefone.component.html',
})
export class ParceiroTelefoneComponent extends PaginaComponent {
  @Input() excluirTelefones: number[] = [];
  @Input() tipoVinculo: string;
  public componente: any;
  private modalEmt: any;
  public _parceiroTelefones: ParceiroTelefone[];
  public _idParceiro: number;

  @Input() set idParceiro(idParceiro: number) {
    this._idParceiro = idParceiro;
    this.sugerirParceiroVinculoPrincipal();
  }

  @Input() set parceiroTelefones(parceiroTelefones: ParceiroTelefone[]) {
    this._parceiroTelefones = parceiroTelefones;
    this.sugerirParceiroVinculoPrincipal();
  }

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.parceiroTelefone) {
        this.adicionarTemporario(this.plainToClass(ParceiroTelefone, identificacao.conteudo), this._parceiroTelefones);
        this.excluirParceiroVinculoPrincipal();
      }
    });
  }

  sugerirParceiroVinculoPrincipal(): void {
    if ((this._idParceiro == null || this._idParceiro == undefined) && this._parceiroTelefones && this._parceiroTelefones.length == 0) {
      let parceiroTelefone: ParceiroTelefone = new ParceiroTelefone();
      parceiroTelefone.idVinculo = 3;
      parceiroTelefone.vinculo = 'PRINCIPAL';
      parceiroTelefone.telefone = null;
      this.adicionarTemporario(this.plainToClass(ParceiroTelefone, parceiroTelefone), this._parceiroTelefones);
    }
  }

  excluirParceiroVinculoPrincipal(): void {
    const posicao: number = this._parceiroTelefones.findIndex((parceiroTelefone) => parceiroTelefone.vinculo == 'PRINCIPAL' && parceiroTelefone.telefone == null);
    if (posicao >= 0) {
      this._parceiroTelefones.splice(posicao, 1);
    }
  }

  ngOnDestroy() {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  abrirModal(parceiroTelefone: ParceiroTelefone = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objetoTelefone', parceiroTelefone));
    this.utilSessao.setIdentificacao(new Identificacao('tipoVinculo', this.tipoVinculo));
    this.componente = ParceiroTelefoneFrmComponent;
  }
}
