import { Component } from '@angular/core';
import { Nomenclatura } from 'src/app/modelo/nomenclatura';
import { NomenclaturaEmpresa } from 'src/app/modelo/nomenclaturaEmpresa';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';

@Component({
  templateUrl: './nomenclaturaEmpresa.component.html',
})
export class NomenclaturaEmpresaComponent extends PaginaComponent {
  public nomenclaturas: Nomenclatura[] = [];
  public nomenclaturaEmpresas: NomenclaturaEmpresa[] = [];
  public nomenclaturaEmpresaPersistir: NomenclaturaEmpresa[] = [];

  ngOnInit(): void {
    this.listar();
  }

  listar(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.nomenclatura).subscribe((res) => {
      this.nomenclaturas = this.plainToClass(Nomenclatura, res) as any;
      this.listarNomenclaturaEmpresa();
    });
  }

  listarNomenclaturaEmpresa(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.nomenclaturaEmpresa).subscribe((res) => {
      this.vincular(this.plainToClass(NomenclaturaEmpresa, res) as any);
    });
  }

  vincular(nomenclaturaEmpresas: NomenclaturaEmpresa[]): void {
    this.nomenclaturaEmpresas = [];
    this.nomenclaturas.forEach((nomenclatura) => {
      let nomenclaturaEmpresa: NomenclaturaEmpresa = nomenclaturaEmpresas.find((nomenclaturaEmpresa) => nomenclaturaEmpresa.idNomenclatura == nomenclatura.id);
      if (nomenclaturaEmpresa) {
        this.nomenclaturaEmpresas.push(nomenclaturaEmpresa);
      } else {
        let nomenclaturaEmpresaNovo: NomenclaturaEmpresa = new NomenclaturaEmpresa();
        nomenclaturaEmpresaNovo.nomenclatura = nomenclatura.nome;
        nomenclaturaEmpresaNovo.apelido = nomenclatura.nome;
        nomenclaturaEmpresaNovo.idNomenclatura = nomenclatura.id;
        this.nomenclaturaEmpresas.push(nomenclaturaEmpresaNovo);
      }
    });
  }

  alterar(nomenclaturaEmpresa: NomenclaturaEmpresa, valor: string): void {
    if (nomenclaturaEmpresa.apelido != valor) {
      nomenclaturaEmpresa.alterado = true;
    }
    valor = valor.toLowerCase();
    valor = valor.charAt(0).toUpperCase() + valor.slice(1);
    nomenclaturaEmpresa.apelido = valor;
  }

  persistirNomenclaturaEmpresa(): void {
    let nomenclaturaEmpresas: NomenclaturaEmpresa[] = this.nomenclaturaEmpresas.filter((nomenclaturaEmpresa) => nomenclaturaEmpresa.alterado);
    if (this.ehValido(nomenclaturaEmpresas)) {
      let idsExcluir: number[] = [];
      this.criarListaPersistirExclusao(nomenclaturaEmpresas, idsExcluir);
      let transporte: Transporte = new Transporte(nomenclaturaEmpresas);
      transporte.adicionar(idsExcluir);
      super.persistir(new Transporte(nomenclaturaEmpresas), this.bibServico.nomenclaturaEmpresa, null).subscribe(() => {});
    }
  }

  criarListaPersistirExclusao(nomenclaturaEmpresas: NomenclaturaEmpresa[], idsExcluir: number[]): void {
    let nomenclaturaEmpresaPersistir: NomenclaturaEmpresa[] = [];
    nomenclaturaEmpresas.forEach((nomenclaturaEmpresa) => {
      const nomenclatura: Nomenclatura = this.nomenclaturas.find((nomenclaturaBusca) => nomenclaturaBusca.id == nomenclaturaEmpresa.idNomenclatura);
      if (nomenclaturaEmpresa.apelido == nomenclatura.nome && nomenclaturaEmpresa.id) {
        idsExcluir.push(nomenclaturaEmpresa.id);
      } else {
        nomenclaturaEmpresaPersistir.push(nomenclaturaEmpresa);
      }
    });
    nomenclaturaEmpresas = nomenclaturaEmpresaPersistir;
  }

  ehValido(nomenclaturaEmpresas: NomenclaturaEmpresa[]): boolean {
    const ehValidoAlterado = this.ehValidoAlterado(nomenclaturaEmpresas);
    const ehValidoVazio = this.ehValidoVazio(nomenclaturaEmpresas);
    return ehValidoAlterado && ehValidoVazio;
  }

  ehValidoVazio(nomenclaturaEmpresas: NomenclaturaEmpresa[]): boolean {
    for (const nomenclaturaEmpresa of nomenclaturaEmpresas) {
      if (!nomenclaturaEmpresa.apelido) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.camposSemDados + ': ' + nomenclaturaEmpresa.nomenclatura));
        return false;
      }
    }
    return true;
  }

  ehValidoAlterado(nomenclaturaEmpresas: NomenclaturaEmpresa[]): boolean {
    if (nomenclaturaEmpresas.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumaNomenclaturaAlterada));
    return false;
  }

  restaurarPadrao(): void {
    this.nomenclaturaEmpresas.forEach((nomenclaturaEmpresa) => {
      const nomenclatura: Nomenclatura = this.nomenclaturas.find((nomenclaturaBusca) => nomenclaturaBusca.id == nomenclaturaEmpresa.idNomenclatura);
      if (nomenclaturaEmpresa.apelido != nomenclatura.nome) {
        nomenclaturaEmpresa.apelido = nomenclatura.nome;
        nomenclaturaEmpresa.alterado = true;
      }
    });
  }
}
