import { Component } from '@angular/core';
import { Receituario } from 'src/app/agrow/modelo/receituario';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ReceituarioEletronicoComponent } from '../receituarioEletronico/receituarioEletronico.component';

@Component({
  templateUrl: './receituarioFrm.component.html',
})
export class ReceituarioFrmComponent extends PaginaComponent {
  private movimentacaoProdutos: MovimentacaoProduto[] = [];
  public bloqueia: boolean = false;
  public colaborador: string;
  public componente: any;
  public criteriosResponsavel: Criterio[] = [new Criterio('EH_AGRONOMO', 'S')];
  public idColaborador: number = 0;
  public movimentacao: Movimentacao = new Movimentacao();
  public receituarios: Receituario[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao() {
    const id: number = this.utilSessao.getIdentificacao('id')?.conteudo;
    this.listarMovimentacao(id);
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibServico.receituario) {
        for (let i = 0; i < this.receituarios.length; i++) {
          if (this.receituarios[i].idMovimentacaoPro == this.plainToClass(Receituario, identificacao.conteudo).idMovimentacaoPro) {
            this.receituarios[i] = this.plainToClass(Receituario, identificacao.conteudo);
            this.receituarios[i].status = 'N';
          }
        }
      }
    });
  }

  listarMovimentacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
      this.listarMovimentacaoProduto(this.movimentacao.id);
    });
  }

  listarMovimentacaoProduto(idMovimentacao: number): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_MOVIMENTACAO', idMovimentacao));
    criterios.push(new Criterio('PRODUTO_FORMULADO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoProduto).subscribe((res) => {
      this.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      this.listarReceituario();
    });
  }

  setAgronomo(idAgronomo: number) {
    this.idColaborador = idAgronomo;
    this.listarColaborador(idAgronomo);
    this.receituarios.forEach((receituario) => {
      receituario.idColaborador = this.idColaborador;
      receituario.alterado = true;
    });
  }

  listarColaborador(idColaborador: number): void {
    if (idColaborador) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID', idColaborador));
      criterios.push(new Criterio('EH_AGRONOMO', 'S'));
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiro).subscribe((res) => {
        const colaborador: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
        this.idColaborador = colaborador.id;
        this.colaborador = colaborador.razaoSocial;
      });
    }
  }

  listarReceituario(): void {
    this.receituarios = [];
    let idMovimentacaoProdutos: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_MOVIMENTACAO_PRO', idMovimentacaoProdutos.toString())]), this.bibServico.receituario).subscribe((res) => {
      const receituarios: Receituario[] = this.plainToClass(Receituario, res) as any;
      let receituario: Receituario = new Receituario();
      this.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        receituario = receituarios.find((receituarioBusca) => receituarioBusca.idMovimentacaoPro == movimentacaoProduto.id);
        if (!receituario) {
          receituario = new Receituario();
          receituario.idColaborador = this.idColaborador;
          receituario.idMovimentacao = movimentacaoProduto.idMovimentacao;
          receituario.idProduto = movimentacaoProduto.idProduto;
          receituario.idMovimentacaoPro = movimentacaoProduto.id;
          let grauToxicologica: string[] = movimentacaoProduto.classeToxicologica.split('-');
          receituario.grauToxicologica = grauToxicologica[0].trim();
        } else {
          this.idColaborador = receituario.idColaborador;
          receituario.alterado = true;
        }
        receituario.movimentacaoProduto = movimentacaoProduto;
        this.receituarios.push(receituario);
        this.listarColaborador(this.idColaborador);
        let grauToxicologica: string[] = movimentacaoProduto.classeToxicologica.split('-');
        receituario.grauToxicologica = grauToxicologica[0].trim();
      });
      this.bloqueia = this.receituarios.length == this.receituarios.filter((receituario) => receituario.status == 'S').length;
      this.bloqueia = this.bloqueia == false ? this.movimentacao.ativo == 'N' : this.bloqueia;
    });
  }

  abrirModal(receituario: Receituario = null): void {
    if (this.idColaborador > 0) {
      receituario.idColaborador = this.idColaborador;
    }
    if (this.movimentacao.ativo == 'N') {
      receituario.status = 'S';
    }
    if (this.ehValidoObrigatorio()) {
      this.utilSessao.setIdentificacao(new Identificacao('objeto', receituario));
      this.utilSessao.setIdentificacao(new Identificacao('idParceiro', this.movimentacao.idParceiro));
      this.utilSessao.setIdentificacao(new Identificacao('idParceiroDestino', this.movimentacao.idParceiroDestino));
      this.componente = ReceituarioEletronicoComponent;
    }
  }

  salvar(): void {
    if (this.ehValido() && this.setEhValidoSalvar()) {
      this.receituarios.forEach((receituario) => {
        if (receituario.alterado == true) {
          receituario.status = 'N';
        }
      });
      this.persistirReceituario();
    }
  }

  finalizar(): void {
    if (this.setEhValidoReceituarios() && this.setEhValidoCaminhoEntregaEmbalagem() && this.setEhValidoQuantidadeProdutoReceita()) {
      this.receituarios.forEach((receituario) => {
        receituario.status = 'S';
      });
      this.persistirReceituario();
    }
  }

  persistirReceituario(): void {
    let transporte: Transporte = new Transporte(this.receituarios.filter((receituario) => receituario.alterado == true));
    super.persistir(transporte, this.bibServico.receituario, null).subscribe((res) => {
      if (this.utilSessao.falha) {
        this.receituarios.forEach((receituario) => {
          if (receituario.status != null) {
            receituario.status = 'N';
          }
        });
      } else {
        // this.definirId(
        //   this.receituarios.filter((receituario) => receituario.id == null && receituario.alterado == true),
        //   this.bibClasse.receituario,
        //   true
        // );
        this.definirId(this.receituarios, this.bibClasse.receituario, true);
        this.definirDadosReceituario();
      }
    });
  }

  definirDadosReceituario(): void {
    this.bloqueia = this.receituarios.length == this.receituarios.filter((receituario) => receituario.status == 'S').length;
    this.bloqueia = this.bloqueia == false ? this.movimentacao.ativo == 'N' : this.bloqueia;
    const ids: number[] = this.receituarios.filter((receituario) => receituario.id != null).map((receituario) => receituario.id);
    this.comunicacaoService.listar(new Transporte([new Criterio('IDS', ids.toString())]), this.bibServico.receituario).subscribe((res) => {
      let receituarioPersistidos: Receituario[] = this.plainToClass(Receituario, res) as any;
      this.receituarios
        .filter((receituario) => receituario.id != null)
        .forEach((receituario) => {
          let receituarioBusca: Receituario = receituarioPersistidos.find((receituarioPersistido) => receituarioPersistido.id == receituario.id);
          receituario.numero = receituarioBusca.numero;
          receituario.art = receituarioBusca.art;
        });
    });
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    return ehValidoObrigatorio;
  }

  ehValidoObrigatorio(): boolean {
    if (this.idColaborador) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoReceituarios(): boolean {
    let receituario: Receituario = this.receituarios.find((receituario) => !receituario.cultura || !receituario.praga || !receituario.aplicacao || !receituario.idColaborador || !receituario.aplicacao || !receituario.dose || receituario.dose == 0);
    if (receituario) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.contemReceituarioNaoPreenchido));
      return false;
    }
    return true;
  }

  setEhValidoSalvar(): boolean {
    let receituarios: Receituario[] = this.receituarios.filter((receituario) => receituario.alterado == true);
    if (receituarios.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumRegistroAlterado));
    return false;
  }

  setEhValidoCaminhoEntregaEmbalagem(): boolean {
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.movimentacao.idLoja);
    if (loja.receituarioEntregaEmbalagem != null) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.endEtregaEmbalagensNaoInformado));
    return false;
  }

  setEhValidoQuantidadeProdutoReceita(): boolean {
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.movimentacao.idLoja);
    if (loja.receituarioQuantidadeProduto != null) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.quantidadeProdutoReceitaLoja));
    return false;
  }
}
