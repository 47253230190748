<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.totalFiscal }}</h1>
<table>
  <thead>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-2 text-right">{{ bibDialogo.nfeTotal }}</th>
    <th class="r-2 text-right">{{ bibDialogo.nfeTotalMes }}</th>
    <th class="r-2 text-right">{{ bibDialogo.compraTotal }}</th>
    <th class="r-2 text-right">{{ bibDialogo.compraTotalMes }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoFiscal of movimentacaoFiscais">
      <tr>
        <td>{{ movimentacaoFiscal.loja }}</td>
        <td class="text-right">{{ movimentacaoFiscal.nfeTotal | monetario }}</td>
        <td class="text-right">{{ movimentacaoFiscal.nfeTotalMes | monetario }}</td>
        <td class="text-right">{{ movimentacaoFiscal.compraTotal | monetario }}</td>
        <td class="text-right">{{ movimentacaoFiscal.compraTotalMes | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoFiscais.length > 0">
    <tr>
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFiscais[0].total | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFiscais[0].totalMes | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFiscais[0].totalCompra | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFiscais[0].totalCompraMes | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
