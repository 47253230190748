<layout [titulo]="parceiro.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art" [id]="parceiroArt.id">
  <div class="row">
    <texto class="col-sm-6" [rotulo]="parceiro.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art" [campo]="parceiroArt.art" (alteracao)="parceiroArt.art = $event" [obrigatorio]="true" [foco]="true"></texto>
    <lista class="col-sm-6" [rotulo]="bibDialogo.estado" [campo]="parceiroArt.idEstado" (alteracao)="setIdEstado($event)" [lista]="estados" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <texto class="col-sm-4" [rotulo]="bibDialogo.receitaDe" [campo]="parceiroArt.de" (alteracao)="parceiroArt.de = $event" [obrigatorio]="true" [tipo]="this.bibPropriedade.texto.numero"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.receitaAte" [campo]="parceiroArt.ate" (alteracao)="parceiroArt.ate = $event" [obrigatorio]="true" [tipo]="this.bibPropriedade.texto.numero"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.receita + ' ' + bibDialogo.atual.toLowerCase()" [campo]="parceiroArt.atual" (alteracao)="parceiroArt.atual = $event" [obrigatorio]="true" [tipo]="this.bibPropriedade.texto.numero" [prefixo]="parceiroArt.mascaraInicial" [sufixo]="parceiroArt.mascaraFinal"></texto>
  </div>
  <div class="row">
    <texto class="col-sm-4" [rotulo]="bibDialogo.mascaraInicial" [campo]="parceiroArt.mascaraInicial" (alteracao)="parceiroArt.mascaraInicial = $event"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.mascaraFinal" [campo]="parceiroArt.mascaraFinal" (alteracao)="parceiroArt.mascaraFinal = $event"></texto>
  </div>
  <div *ngIf="integracaoEidaf" class="row">
    <texto class="col-sm-4" [rotulo]="bibDialogo.integracao + ' ' + bibDialogo.usuario" [campo]="parceiroArt.integracaoUsuario" (alteracao)="parceiroArt.integracaoUsuario = $event" [ajuda]="bibDialogo.ajudaIntegracaoEidaf" [maximoCaracteres]="100" [ajudaDireita]="true" [normal]="true"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.integracao + ' ' + bibDialogo.senha" [campo]="parceiroArt.integracaoSenha" (alteracao)="parceiroArt.integracaoSenha = $event" [ajuda]="bibDialogo.ajudaIntegracaoEidaf" [maximoCaracteres]="100" [ajudaDireita]="true" [tipo]="bibPropriedade.texto.senha" [normal]="true"></texto>    
  </div>    
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirParceiroArt()" (saindo)="focoId = $event"></botao>
<auditoria *ngIf="parceiroArt.id" [idUsuarioInclusao]="parceiroArt.idUsuarioInclusao" [dataHoraInclusao]="parceiroArt.dataHoraInclusao" [idUsuarioAlteracao]="parceiroArt.idUsuarioAlteracao" [dataHoraAlteracao]="parceiroArt.dataHoraAlteracao"></auditoria>
