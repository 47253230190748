import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
  { rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
  { rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
]);

export default filtros;
