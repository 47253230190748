import { Pipe, PipeTransform } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Pipe({
  name: 'filtrarIntegracao',
})
export class IntegracaoListaPipe implements PipeTransform {
  transform(integracoes: any[], argumentos?: any): any {
    if (argumentos.filtro && argumentos.filtro != '' && argumentos.especifico != bibDialogo.pesquisarPor) {
      argumentos.filtro = argumentos.filtro.toLowerCase();
      switch (argumentos.especifico) {
        case bibDialogo.categoria:
          return integracoes.filter((integracao) => integracao.integracaoCategoria.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.nome:
          return integracoes.filter((integracao) => (integracao.idIntegracao + integracao.integracao).toLowerCase().includes(argumentos.filtro));
        default:
          return integracoes.filter((integracao) => integracao.menu.toLowerCase().includes(argumentos.filtro) || integracao.abreviacao.toLowerCase().includes(argumentos.filtro));
      }
    } else {
      return integracoes;
    }
  }
}
