import { PatrimonialLancamentoContabil } from "./patrimonialLancamentoContabil";

export class Patrimonial  {
  public id: number;
  public idEmpresa: number;
  public idContaContabil: number;
  public idLoja: number;
  public ativo: string = 'S';
  public nome: string;
  public numero: string;
  public numeroNotaFiscal: number;
  public dataAquisicao: Date;
  public valorAquisicao: number = 0;
  public valorAtual: number = 0;
  public percentualDepreciacao: number = 0;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public contaContabil: string;
  public patrimonialLancamentosContabeis: PatrimonialLancamentoContabil[] = [];
}
