import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboEstoque: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.disponivel, id: 'estoque_disponivel' },
  { nome: bibDialogo.nfe, id: 'estoque_nfe_regra' },
]);

const filtroComboMovimentacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '2, 8, 9, 10' },
  { nome: bibDialogo.entrada, id: '8, 10' },
  { nome: bibDialogo.saida, id: '2, 9' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.movimentacao, nome: 'OPERACOES', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: '8, 10', lista: filtroComboMovimentacao },
  { rotulo: bibDialogo.dataEntradaDe, nome: 'DATA_ENTRADA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'OPERACOES', visualizacaoInicial: false, visualizacao: ['8, 10'] },
  { rotulo: bibDialogo.dataEntradaAte, nome: 'DATA_ENTRADA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'OPERACOES', visualizacaoInicial: false, visualizacao: ['8, 10'] },
  { rotulo: bibDialogo.dataSaidaDe, nome: 'DATA_SAIDA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'OPERACOES', visualizacaoInicial: false, visualizacao: ['2, 9'] },
  { rotulo: bibDialogo.dataSaidaAte, nome: 'DATA_SAIDA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'OPERACOES', visualizacaoInicial: false, visualizacao: ['2, 9'] },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'OPERACOES', visualizacaoInicial: false, visualizacao: ['2, 8, 9, 10'], ajuda: bibDialogo.dataEntradaSaida },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'OPERACOES', visualizacaoInicial: false, visualizacao: ['2, 8, 9, 10'], ajuda: bibDialogo.dataEntradaSaida },
  { rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.tipoMovimento, nome: 'TIPO_ESTOQUE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'estoque_disponivel', lista: filtroComboEstoque },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
