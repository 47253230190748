import { Type } from 'class-transformer';

export class ProdutoValor {
  @Type(() => Date)
  public dataUltimaCompra: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public abreviacaoLoja: string;
  public alterado: string = 'N';
  public custoGerencial: number = 0;
  public custoGerencialOrigem: number;
  public custoReposicao: number = 0;
  public custoReposicaoOrigem: number;
  public custoNfe: number = 0;
  public editarCustoGerencial: string = 'N';
  public editarCustoReposicao: string = 'N';
  public editarMargemGerencial: string = 'N';
  public editarMargemLucro: string = 'N';
  public editarPrecoVenda: string = 'N';
  public editarValorMinimo: string = 'N';
  public grupo: string;
  public grupoMargemGerencial: number;
  public grupoMargemLucro: number;
  public id: number;
  public idEmpresa: number;
  public idGrupo: number;
  public idLoja: number;
  public idMovimentacao: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public lancamentoManual: string;
  public margemGerencial: number;
  public margemGerencialOrigem: number;
  public margemLucro: number;
  public margemLucroOrigem: number;
  public margemReal: number;
  public precoVenda: number;
  public precoVendaOrigem: number;
  public produto: string;
  public promocao: string;
  public valorMinimo: number = 0;
  public valorMinimoOrigem: number;
  public valorUltimaCompra: number;
  public unidade: string;
}
