import { Component } from '@angular/core';
import { addDays, format, isEqual } from 'date-fns';
import { Observable, of } from 'rxjs';
import { Lote } from 'src/app/agrow/modelo/lote';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { ProdutoUnidade } from 'src/app/agrow/modelo/produtoUnidade';
import { Receituario } from 'src/app/agrow/modelo/receituario';
import { TabelaPreco } from 'src/app/agrow/modelo/tabelaPreco';
import { TabelaPrecoConfiguracao } from 'src/app/agrow/modelo/tabelaPrecoConfiguracao';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoCancelamento } from 'src/app/modelo/movimentacaoCancelamento';
import { MovimentacaoChave } from 'src/app/modelo/movimentacaoChave';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { NcmEmpresa } from 'src/app/modelo/ncmEmpresa';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Operacao } from 'src/app/modelo/operacao';
import { OperacaoParametro } from 'src/app/modelo/operacaoParametro';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoParceiro } from 'src/app/modelo/produtoParceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { UtilMovimentacaoImpostos } from 'src/app/utilitario/utilMovimentacaoImpostos';
import { UtilImportacaoXml } from './utilImportacaoXml';

@Component({
  templateUrl: './movimentacaoFrm.component.html',
  styleUrls: ['./movimentacaoFrm.component.css'],
})
export class MovimentacaoFrmComponent extends PaginaComponent {
  public focoIdFinalizar: number;
  public focoIdSenha: number;
  public chave: string;
  public movimentacaoControlador: MovimentacaoControlador = new MovimentacaoControlador(this.utilSessao, this.comunicacaoService);
  public produtosParceiros: ProdutoParceiro[] = [];
  public produtosFormulados: ProdutoFormulado[] = [];
  public utilImportacaoXml: UtilImportacaoXml;
  public utilMovimentacaoImpostos: UtilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
  public mostrarSalvar: boolean = false;
  public estoqueDisponivel: number;
  public estoqueNfe: number;
  public bloqueiaPermissao: boolean;

  ngOnInit(): void {
    this.ehAlteracao();
    this.movimentacaoControlador.bloqueiaAlteracao = this.util.coalesce(this.utilSessao.getIdentificacao('bloqueiaAlteracao')?.conteudo, true);
    this.bloqueiaPermissao = this.bloquearPermissao(this.movimentacaoControlador.movimentacao.id);
  }

  ehAlteracao(): void {
    this.movimentacaoControlador.movimentacao = this.plainToClass(Movimentacao, this.ehAtualizacao(this.movimentacaoControlador.movimentacao));
    if (this.movimentacaoControlador.movimentacao.id) {
      this.listarMovimentacao(this.movimentacaoControlador.movimentacao.id);
    } else {
      const idOperacao: number = this.utilSessao.getIdentificacao('parametro')?.conteudo;
      this.inserir(idOperacao);
      this.listarOperacaoParametro(idOperacao);
      this.movimentacaoControlador.listarClassificacao();
      this.movimentacaoControlador.listarSafras();
      this.movimentacaoControlador.listarCentroResultados();
    }
    this.listarLojas();
  }

  inserir(idOperacao: number): void {
    this.movimentacaoControlador.movimentacao.idOperacao = idOperacao;
    this.movimentacaoControlador.movimentacao.data = new Date();
  }

  listarMovimentacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.movimentacaoControlador.bibServico.movimentacao).subscribe((res) => {
      this.movimentacaoControlador.movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
      this.movimentacaoControlador.movimentacao.valorTotalProdutoBruto = this.movimentacaoControlador.movimentacao.valorTotalProduto + this.movimentacaoControlador.movimentacao.descontoTotal;
      this.movimentacaoControlador.ehClone = this.util.coalesce(this.utilSessao.getIdentificacao('clone')?.conteudo, false);
      this.movimentacaoControlador.ehConsumidorFinal();
      this.movimentacaoControlador.listarConfiguracaoClienteEspecial(this.movimentacaoControlador.movimentacao.parceiroIdClienteEspecial);
      this.movimentacaoControlador.atualizarTituloParcelamento();
      this.listarOperacaoParametro(this.movimentacaoControlador.movimentacao.idOperacao);
      this.movimentacaoControlador.listarEnderecoParceiro(this.movimentacaoControlador.movimentacao.idParceiroDestino);
      this.listarParceiroContaDados();
      const lojaLimiteCustoReposicao: number = this.utilSessao.getLojas().find((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLoja).regraVariacaoCusto;
      this.movimentacaoControlador.movimentacao.limiteCustoReposicao = lojaLimiteCustoReposicao ? lojaLimiteCustoReposicao : 0;
      if (this.movimentacaoControlador.movimentacao.parceiroIdRelacionamento) {
        this.movimentacaoControlador.listarRelacionamento(this.movimentacaoControlador.movimentacao.parceiroIdRelacionamento);
      }
      this.listarProduto(this.movimentacaoControlador.movimentacao.id);
      this.listarParcela(this.movimentacaoControlador.movimentacao.id);
      this.listarMovimentacaoChave(this.movimentacaoControlador.movimentacao.id);
      this.movimentacaoControlador.listarNegociacao(this.movimentacaoControlador.movimentacao.idNegociacao);
      this.listarMovimentacaoCancelamento();
      this.movimentacaoControlador.listarClassificacao();
      this.movimentacaoControlador.listarSafras();
      this.movimentacaoControlador.listarCentroResultados();
      this.movimentacaoControlador.idTipoTituloParceiro = this.movimentacaoControlador.movimentacao.parceiroIdTipoTitulo;
      this.movimentacaoControlador.setNomeTipoTituloParceiro(this.movimentacaoControlador.idTipoTituloParceiro);
      if (this.movimentacaoControlador.movimentacao.idOperacao == 1 && this.movimentacaoControlador.movimentacao.situacao == 1) {
        this.atualizarDataOrcamento();
      }
    });
  }

  listarParceiroContaDados(): void {
    this.listarParceiro().subscribe((res) => {
      const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
      this.movimentacaoControlador.movimentacao.parceiro = parceiro.numeroNomeParceiro;
      this.movimentacaoControlador.movimentacao.parceiroCpfCnpj = this.movimentacaoControlador.movimentacao.parceiroCpfCnpj ? this.movimentacaoControlador.movimentacao.parceiroCpfCnpj : parceiro.cnpjCpf;
      this.movimentacaoControlador.movimentacao.parceiroLimiteDisponivel = parceiro.limiteDisponivel;
      this.movimentacaoControlador.movimentacao.parceiroDiaFechamento = parceiro.diaFechamento;
      this.movimentacaoControlador.movimentacao.parceiroPrazoFixo = parceiro.prazoFixo;
      this.movimentacaoControlador.movimentacao.parceiroTipoNegociacao = parceiro.tipoNegociacao;
      this.movimentacaoControlador.movimentacao.parceiroTipoPessoa = parceiro.tipoPessoa;
      this.movimentacaoControlador.movimentacao.parceiroContribuinte = parceiro.contribuinte;
      this.movimentacaoControlador.parceiroIdContaContabil = parceiro.idContaContabil;
      this.movimentacaoControlador.parceiroContaContabil = parceiro.contaContabil;
      this.movimentacaoControlador.idTipoTituloParceiro = parceiro.idTipoTitulo;
    });
  }

  listarParceiro(): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.movimentacaoControlador.movimentacao.idParceiro)), this.bibServico.parceiro);
  }

  listarTabelaPrecoProduto(idProduto: number, idGrupo: number): Observable<any> {
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    criterios.push(new Criterio('LIMITAR_ID_PRODUTO', idProduto));
    criterios.push(new Criterio('LIMITAR_ID_CLIENTE', this.movimentacaoControlador.movimentacao.idParceiro));
    criterios.push(new Criterio('LIMITAR_ID_COLABORADOR', this.movimentacaoControlador.movimentacao.idColaborador));
    criterios.push(new Criterio('LIMITAR_ID_GRUPO', idGrupo));
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.tabelaPreco);
  }

  listarProduto(idMovimentacao: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', idMovimentacao)), this.movimentacaoControlador.bibServico.movimentacaoProduto).subscribe((res) => {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      this.movimentacaoControlador.setAcessoRapido();
      this.listarQuantidades();
      this.listarReceituario();
      this.listarCfopEmpresas();
      this.listarTabelaPrecoConfiguracao();
      this.definirStatusEstoque(this.movimentacaoControlador.movimentacao.movimentacaoProdutos);
      this.movimentacaoControlador.gerarReceituario();
      this.movimentacaoControlador.verificarMovimentacaoProdutoBloqueiaLoja();
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)), this.bibServico.produtoUnidade).subscribe((res) => {
          let produtoUnidades: ProdutoUnidade[] = this.plainToClass(ProdutoUnidade, res) as any;
          if (produtoUnidades.length > 0) {
            this.comunicacaoService.listar(new Transporte(new Criterio('ID', movimentacaoProduto.idProduto)), this.bibServico.produto).subscribe((res) => {
              const produto = this.plainToClass(Produto, res[0]) as any;
              movimentacaoProduto.produtoUnidades = produtoUnidades;
              movimentacaoProduto.produtoUnidades.push(this.movimentacaoControlador.adicionarUnidadeProduto(produto, produto.idUnidade == movimentacaoProduto.idUnidade ? 'S' : 'N'));
              movimentacaoProduto.produtoUnidades.forEach((produtoUnidade) => (produtoUnidade.nome = produtoUnidade.unidadeAbreviacao));
              produtoUnidades.forEach((produtoUnidade) => (produtoUnidade.sugerirUnidade = produtoUnidade.idUnidade == movimentacaoProduto.idUnidade ? 'S' : 'N'));
            });
          }
        });
        if (this.movimentacaoControlador.devolucao && this.movimentacaoControlador.saida) {
          movimentacaoProduto.devolucaoSaida = true;
        }
        this.utilMovimentacaoImpostos.ehPreenchidoImpostos(movimentacaoProduto, this.movimentacaoControlador.entradaServico);
        if (movimentacaoProduto.classeToxicologica) {
          let grauToxicologica: string[] = movimentacaoProduto.classeToxicologica.split('-');
          movimentacaoProduto.grauToxicologica = grauToxicologica[0].trim();
        }
        this.listarTabelaPrecoProduto(movimentacaoProduto.idProduto, movimentacaoProduto.idGrupo).subscribe((res) => {
          const tabelaPrecos: TabelaPreco[] = this.plainToClass(TabelaPreco, res) as any;
          movimentacaoProduto.apresentaTabelaPreco = tabelaPrecos.length > 0;
        });

        movimentacaoProduto.descontoPorValor = this.movimentacaoControlador.entrada ? true : false;
      });
      if (this.movimentacaoControlador.balanco) {
        this.movimentacaoControlador.movimentacao.calcularBalanco();
      }
      if (this.movimentacaoControlador.ehClone && !this.movimentacaoControlador.entrada) {
        this.movimentacaoControlador.atualizarValorMovimentacaoProduto();
      }
      this.ehSetMovimentacaoClone();
      if (this.movimentacaoControlador.movimentacao.idOperacao == 1 && this.movimentacaoControlador.movimentacao.situacao == 1) {
        this.atualizarValorUnitarioProdutos();
      }
    });
  }

  listarMovimentacaoChave(idMovimentacao: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', idMovimentacao)), this.movimentacaoControlador.bibServico.movimentacaoChave).subscribe((res) => {
      this.movimentacaoControlador.movimentacao.movimentacaoChaves = this.plainToClass(MovimentacaoChave, res) as any;
    });
  }

  listarCfopEmpresas(): void {
    let idCfopEmpresas: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idCfopEmpresa != null).map((movimentacaoProduto) => movimentacaoProduto.idCfopEmpresa);
    if (idCfopEmpresas.length > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idCfopEmpresas.toString())), this.bibServico.cfopEmpresa).subscribe((res) => {
        this.movimentacaoControlador.cfopsEmpresa = this.plainToClass(CfopEmpresa, res) as any;
      });
    }
  }

  listarTabelaPrecoConfiguracao(): void {
    let idTabelaPrecoConfiguracoes: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idTabelaPrecoConfiguracao);
    idTabelaPrecoConfiguracoes = idTabelaPrecoConfiguracoes.filter((idTabelaPrecoConfiguracao) => idTabelaPrecoConfiguracao);
    if (idTabelaPrecoConfiguracoes.length > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idTabelaPrecoConfiguracoes.toString())), this.bibServico.tabelaPrecoConfiguracao).subscribe((res) => {
        const tabelaPrecoConfiguracoes: TabelaPrecoConfiguracao[] = this.plainToClass(TabelaPrecoConfiguracao, res) as any;
        tabelaPrecoConfiguracoes.forEach((tabelaPrecoConfiguracao) => {
          let movimentacaoProdutos: MovimentacaoProduto[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idTabelaPrecoConfiguracao == tabelaPrecoConfiguracao.id);
          movimentacaoProdutos.forEach((movimentacaoProduto) => {
            if (tabelaPrecoConfiguracao.dataAte == null) {
              tabelaPrecoConfiguracao.dataDe = new Date();
              tabelaPrecoConfiguracao.dataAte = addDays(new Date(), tabelaPrecoConfiguracao.quantidadeDia);
            }
            movimentacaoProduto.tabelaPrecoConfiguracao = tabelaPrecoConfiguracao;
          });
        });
      });
    }
  }

  ehSetMovimentacaoClone(): void {
    if (this.movimentacaoControlador.ehClone) {
      this.movimentacaoControlador.bloqueado = false;
      this.movimentacaoControlador.movimentacao.ativo = 'S';
      this.movimentacaoControlador.movimentacao.data = new Date();
      this.movimentacaoControlador.movimentacao.dataEntradaSaida = new Date();
      this.movimentacaoControlador.movimentacao.id = null;
      console.log('entrou')
      this.movimentacaoControlador.movimentacao.numero = 0;
      this.movimentacaoControlador.movimentacao.status = 'N';
      this.movimentacaoControlador.movimentacao.importacaoXml = 'N';
      this.movimentacaoControlador.movimentacao.serie = null;
      this.movimentacaoControlador.movimentacao.documentoFiscal = null;
      this.movimentacaoControlador.movimentacao.chaveFiscal = null;
      this.movimentacaoControlador.movimentacao.chave = null;
      this.movimentacaoControlador.movimentacao.modelo = null;
      this.movimentacaoControlador.movimentacao.valorFinanceiro = 0;
      this.movimentacaoControlador.movimentacao.situacao = 1;
      for (const movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
        movimentacaoProduto.definirRegraEstoque(this.movimentacaoControlador.estoqueComprado, this.movimentacaoControlador.estoqueFisico, this.movimentacaoControlador.estoqueReservado, this.movimentacaoControlador.estoqueTransferido, this.movimentacaoControlador.estoqueNFe, this.movimentacaoControlador.estoqueCarteira);
        movimentacaoProduto.receituarioEmitido = 'N';
      }
      this.estoqueNfe = 0;
      this.movimentacaoControlador.verificarMovimentacaoProdutoBloqueiaLoja();
      this.utilSessao.excluirIdentificacao('id');
    }
  }

  atualizarValorUnitarioProdutos(): void {
    this.movimentacaoControlador.atualizarValorMovimentacaoProduto();
  }

  listarQuantidades(): void {
    let idsMovimentacaoPro: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
    if (idsMovimentacaoPro.length > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO_PRO', idsMovimentacaoPro.toString())), this.movimentacaoControlador.bibServico.movimentacaoQuantidade).subscribe((res) => {
        let movimentacaoQuantidades: MovimentacaoQuantidade[] = this.plainToClass(MovimentacaoQuantidade, res) as any;
        this.movimentacaoControlador.vincularMovimentacaoQuantidade(this.movimentacaoControlador.movimentacao.movimentacaoProdutos, movimentacaoQuantidades);
        this.ehSetMovimentacaoProdutoClone();
      });
    } else {
      this.ehSetMovimentacaoProdutoClone();
    }
  }

  listarReceituario(): void {
    if (this.movimentacaoControlador.utilSessao.sistema != 'Sigescom-w' && this.movimentacaoControlador.saida && (this.movimentacaoControlador.pedido || this.movimentacaoControlador.nota)) {
      let idsMovimentacaoPro: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
      if (idsMovimentacaoPro.length > 0) {
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO_PRO', idsMovimentacaoPro.toString())), this.movimentacaoControlador.bibServico.receituario).subscribe((res) => {
          let receituarios: Receituario[] = this.plainToClass(Receituario, res) as any;
          this.movimentacaoControlador.vincularReceituario(this.movimentacaoControlador.movimentacao.movimentacaoProdutos, receituarios);
        });
      }
    }
  }

  ehSetMovimentacaoProdutoClone(): void {
    if (this.movimentacaoControlador.ehClone) {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.id = null;
        movimentacaoProduto.status = 'N';
        movimentacaoProduto.importacaoXml = 'N';
        movimentacaoProduto.quantidadeBalanco = 0;
        movimentacaoProduto.quantidadeDesfeita = 0;
        movimentacaoProduto.quantidadeDevolvida = 0;
        movimentacaoProduto.quantidadeDevolvidaEst = 0;
        movimentacaoProduto.quantidadeDevolvidaSub = 0;
        movimentacaoProduto.quantidadeEntregue = 0;
        movimentacaoProduto.quantidadeFaturada = 0;
        movimentacaoProduto.quantidadePendente = 0;
        movimentacaoProduto.quantidadeCancelada = 0;
        movimentacaoProduto.valorBaixa = 0;
        movimentacaoProduto.valorRestante = movimentacaoProduto.valorFinal;
        movimentacaoProduto.receituario = null;
        movimentacaoProduto.movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
          movimentacaoQuantidade.id = null;
          movimentacaoQuantidade.status = 'N';
          movimentacaoQuantidade.quantidadeDevolvida = 0;
          movimentacaoQuantidade.quantidadeDevolvidaEst = 0;
          movimentacaoQuantidade.quantidadeDevolvidaSub = 0;
          movimentacaoQuantidade.quantidadeEntregue = 0;
          movimentacaoQuantidade.quantidadePendente = 0;
          movimentacaoQuantidade.quantidadeDesfeita = 0;
        });
      });
    }
  }

  listarParcela(idMovimentacao: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', idMovimentacao)), this.movimentacaoControlador.bibServico.movimentacaoPar).subscribe((res) => {
      this.movimentacaoControlador.movimentacao.movimentacaoParcelas = this.plainToClass(MovimentacaoParcela, res) as any;
      this.movimentacaoControlador.calcularDiferencaParcelaTotal();
      if (this.movimentacaoControlador.ehClone) {
        this.movimentacaoControlador.movimentacao.movimentacaoParcelas.forEach((movimentacaoParcela) => {
          movimentacaoParcela.id = null;
          movimentacaoParcela.status = 'N';
        });
      }
    });
  }

  listarMovimentacaoCancelamento(): void {
    if (this.movimentacaoControlador.movimentacao.ativo != 'S') {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', this.movimentacaoControlador.movimentacao.id)), this.movimentacaoControlador.bibServico.movimentacaoCancelamento).subscribe((res) => {
        this.movimentacaoControlador.movimentacao.movimentacaoCancelamento = this.plainToClass(MovimentacaoCancelamento, res[0]) as any;
      });
    }
  }

  atualizarDataOrcamento(): void {
    const dataAtual: Date = new Date();
    this.movimentacaoControlador.movimentacao.data = dataAtual;
  }

  listarOperacaoParametro(idOperacao: number): void {
    this.listarOperacao(idOperacao).subscribe((res) => {
      const operacao: Operacao = this.plainToClass(Operacao, res[0]) as any;
      this.movimentacaoControlador.movimentacao.operacaoAbreviacao = operacao.abreviacao;
      this.movimentacaoControlador.operacaoParametros = this.utilSessao.getOperacaoParametros() ? this.utilSessao.getOperacaoParametros().filter((operacaoParametro) => operacaoParametro.idOperacao == idOperacao) : [];
      if (this.movimentacaoControlador.operacaoParametros.length == 0) {
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_OPERACAO', idOperacao)), this.movimentacaoControlador.bibServico.operacaoParametro).subscribe((res) => {
          this.movimentacaoControlador.operacaoParametros = this.plainToClass(OperacaoParametro, res) as any;
          this.utilSessao.setOperacaoParametros(this.movimentacaoControlador.operacaoParametros);
          this.definirRegraOperacaoParametros();
        });
      } else {
        this.definirRegraOperacaoParametros();
      }
    });
  }

  listarOperacao(idOperacao: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID_OPERACAO', idOperacao)), this.movimentacaoControlador.bibServico.operacao);
  }

  private definirRegraOperacaoParametros(): void {
    this.movimentacaoControlador.popularVariaveis();
    this.movimentacaoControlador.ehAtualizarColaborador();
    this.ehAdicionarEmitente();
    this.inicializarUtilImportacao();
    if (this.movimentacaoControlador.transferencia) {
      this.listarLojas();
    }
    this.setEhInternaEstoqueEscolha();
    this.movimentacaoControlador.listarNaturezaOperacao();
  }

  ehAdicionarEmitente(): void {
    if (!this.movimentacaoControlador.movimentacao.id) {
      this.movimentacaoControlador.movimentacao.emitente = this.movimentacaoControlador.notaTerceiro ? 1 : this.movimentacaoControlador.nota ? 0 : null;
    }
  }

  private setEhInternaEstoqueEscolha(): void {
    const INTERNA: number = 5;
    if (this.movimentacaoControlador.identificacao == INTERNA) {
      this.movimentacaoControlador.movimentacao.estoqueEscolha = this.movimentacaoControlador.movimentacao.estoqueEscolha;
    } else {
      const NAO_UTILIZA_ESTOQUE_ESCOLHA: number = 0;
      this.movimentacaoControlador.movimentacao.estoqueEscolha = NAO_UTILIZA_ESTOQUE_ESCOLHA;
    }
  }

  private inicializarUtilImportacao(): void {
    if (this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.entrada && !this.movimentacaoControlador.movimentacao.id) {
      this.utilImportacaoXml = new UtilImportacaoXml(this.comunicacaoService, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
    }
  }

  listarLojas(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.loja).subscribe((res) => {
      this.movimentacaoControlador.lojas = this.plainToClass(Loja, res) as any;
      this.movimentacaoControlador.lojas.sort((a, b) => {
        return a['abreviacao'] === b['abreviacao'] ? 0 : a['abreviacao'] > b['abreviacao'] ? 1 : -1;
      });
    });
  }

  ehExigirSenha(): void {
    if (this.utilSessao.getUsuario()?.agrow == 'S') {
      this.finalizar();
    } else {
      if (this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.movimentacao.idOperacao == 8 && !this.movimentacaoControlador.movimentacao.modelo) {
        if (confirm(this.bibDialogo.finalizarSemModeloDocumentoFiscal)) {
          this.exigirSenha();
        }
      } else {
        this.exigirSenha();
      }
    }
  }

  exigirSenha(): void {
    if (this.movimentacaoControlador.exigirSenha && this.movimentacaoControlador.movimentacao.valorTotal > 0) {
      this.focoIdSenha = Math.random();
    } else {
      this.finalizar();
    }
  }

  conferirSenha(chave: string): void {
    if (chave && chave.length == 6) {
      this.chave = chave;
      this.focoIdSenha = null;
      this.focoIdFinalizar = Math.random();
      this.finalizar(true);
    }
  }

  listarUsuarioChave(): Observable<any> {
    if (this.chave && this.movimentacaoControlador.movimentacao.idColaborador) {
      let colaborador: Observable<any> = this.comunicacaoService.listar(new Transporte([new Criterio('ID_COLABORADOR', this.movimentacaoControlador.movimentacao.idColaborador)]), this.movimentacaoControlador.bibServico.usuario);
      colaborador.subscribe((res) => {
        let usuarioFinalizar = this.plainToClass(Usuario, res[0]) as Usuario;
        if (usuarioFinalizar != undefined) {
          if (usuarioFinalizar.key == this.chave) {
            res[0] = usuarioFinalizar;
          } else {
            res[0] = {};
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.chaveNaoVinculadaAoResponsavel + ' ' + this.movimentacaoControlador.movimentacao.colaborador));
          }
        } else {
          if (this.utilSessao.getUsuario().key == this.chave) {
            res[0] = this.utilSessao.getUsuario();
          } else {
            res[0] = {};
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.chaveNaoVinculadaAoUsuario + ' ' + this.utilSessao.getUsuario().nome));
          }
        }
      });
      return colaborador;
    }
    return of({});
  }

  ehValidoChaveUsuario(usuario: Usuario, validarChave: boolean): boolean {
    if (!this.movimentacaoControlador.orcamento && validarChave == true) {
      if (usuario.id) {
        if (usuario.idColaborador == this.movimentacaoControlador.movimentacao.idColaborador || usuario.idColaborador == this.utilSessao.getUsuario().idColaborador) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  ehValidoNegociacaoMovimentacao(movimentacoes: Movimentacao[], movimentacaoProdutos: MovimentacaoProduto[], negociacoes: Negociacao[]): boolean {
    if (movimentacoes.length > 0 && movimentacaoProdutos.length > 0 && negociacoes.length > 0 && this.movimentacaoControlador.movimentacao.negociacao.especie == 'V') {
      let mensagem: string = this.usaNegociacaoDiferenteMovimentacao(movimentacoes, negociacoes);
      let quantidadeMensagem: number = 0;
      if (mensagem != null) {
        movimentacoes.forEach((movimentacao) => {
          movimentacao.movimentacaoProdutos = movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == movimentacao.id);
          mensagem += this.usaNegociacaoDiferenteOrigem(negociacoes, movimentacao);
          this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
            movimentacao.movimentacaoProdutos.forEach((movimentacaoProdutoOrigem) => {
              if (movimentacaoProduto.idMovimentacaoProOrigem == movimentacaoProdutoOrigem.id) {
                mensagem += '\n ' + this.bibDialogo.item + ': ' + movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto;
                quantidadeMensagem++;
              }
            });
          });
        });
      }
      return this.ehValidoNegociacaoDevolucao(quantidadeMensagem, mensagem);
    }
    return true;
  }

  usaNegociacaoDiferenteMovimentacao(movimentacoes: Movimentacao[], negociacoes: Negociacao[]): string {
    let negociacoesOrigem: Negociacao[] = [];
    movimentacoes.forEach((movimentacao) => {
      negociacoes.forEach((negociacao) => {
        if (negociacoesOrigem.find((negociacaoOrigem) => negociacaoOrigem.id == movimentacao.idNegociacao) == undefined) {
          negociacoesOrigem.push(negociacao);
        }
      });
    });

    const negociacaoDiferente: boolean = negociacoesOrigem.filter((negociacao) => negociacao.penalidadeTaxaFixa == this.movimentacaoControlador.movimentacao.negociacao.penalidadeTaxaFixa && negociacao.penalidadeTaxaDiaria == this.movimentacaoControlador.movimentacao.negociacao.penalidadeTaxaDiaria && negociacao.penalidadeDiaMinimo == this.movimentacaoControlador.movimentacao.negociacao.penalidadeDiaMinimo).length == 0;
    if (negociacaoDiferente) {
      return this.util.substituir(this.bibDialogo.itensComNegociacoesDiferentes, [this.movimentacaoControlador.movimentacao.negociacao.nome]);
    }
    return null;
  }

  usaNegociacaoDiferenteOrigem(negociacoes: Negociacao[], movimentacao: Movimentacao): string {
    const nomeNegociacao: string = negociacoes.find((negociacao) => negociacao.id == movimentacao.idNegociacao).nome;
    return '\n ' + this.movimentacaoControlador.menuNegociacao.apelido + ': ' + nomeNegociacao;
  }

  ehValidoNegociacaoDevolucao(quantidadeMensagem: number, mensagem: string): boolean {
    if (quantidadeMensagem > 0) {
      this.utilSessao.setResultado(new Resultado(false, mensagem));
      return false;
    }
    return true;
  }

  alterar(): void {
    const transporte: Transporte = new Transporte();
    // console.log(this.movimentacaoControlador.movimentacao)
    // this.movimentacaoControlador.movimentacao.id = this.idMovimentacao;
    transporte.adicionar(this.movimentacaoControlador.movimentacao);
    this.persistir(transporte, this.bibServico.movimentacao + 'Alteracao', null);
  }

  salvar(): void {
    if (this.movimentacaoControlador.bloqueiaAlteracao) {
      this.movimentacaoControlador.movimentacao.status = 'N';
      this.movimentacaoControlador.finalizando = 'N';
    } else {
      this.movimentacaoControlador.movimentacao.status = 'U';
      this.movimentacaoControlador.finalizando = 'U';
    }
    this.ehPersistirMovimentacao();
  }

  finalizar(validarChave: boolean = false): void {
    this.movimentacaoControlador.finalizando = 'S';
    this.movimentacaoControlador.movimentacao.contagemProduto = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
    this.movimentacaoControlador.totalizar();
    this.ehGerarParcela();
    this.ehPersistirMovimentacao(validarChave);
  }

  ehGerarParcela(): void {
    if (this.movimentacaoControlador.umaParcelaAvista) {
      this.movimentacaoControlador.gerarParcela();
    }
  }

  ehPersistirMovimentacao(validarChave: boolean = false): void {
    this.ehMovimentacaoInterna();
    if (this.ehValidoObrigatorio()) {
      if (this.ehValidoTotalProduto()) {
        this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.movimentacaoControlador.movimentacao.id ? this.movimentacaoControlador.movimentacao.id : 0)), this.movimentacaoControlador.bibServico.movimentacao).subscribe((res) => {
          const movimentacaoBusca = this.plainToClass(Movimentacao, res[0]) as any;
          if (movimentacaoBusca && movimentacaoBusca.status == 'S' && this.movimentacaoControlador.movimentacao.status != 'U') {
            this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.movimentacaoFinalizada));
          } else {
            this.setValorPadrao();
            this.movimentacaoControlador.gerarOrdenacaoProduto();
            this.listarParceiroEndereco().subscribe(() => {
              this.listarCfopEmpresaProdutos().subscribe((res) => {
                this.movimentacaoControlador.cfopsEmpresa = this.plainToClass(CfopEmpresa, res) as any;
                this.listarLotesProdutos().subscribe((res) => {
                  this.movimentacaoControlador.lotes = this.plainToClass(Lote, res) as any;
                  if (this.confirmarVinculoMovimentacao() && this.ehProdutoRepetido() && this.validarValoresMaximosEmpresa() && this.ehValido()) {
                    this.listarUsuarioChave().subscribe((res) => {
                      const usuario: Usuario = this.plainToClass(Usuario, res[0]) as Usuario;
                      if (this.ehValidoChaveUsuario(usuario, validarChave)) {
                        this.listarMovimentacaoOrigem().subscribe((res) => {
                          const movimentacoesOrigens: Movimentacao[] = this.plainToClass(Movimentacao, res) as any;
                          this.listarNegociacoesMovimentacaoProOrigem(movimentacoesOrigens).subscribe((res) => {
                            const negocicoes: Negociacao[] = this.plainToClass(Negociacao, res) as any;
                            this.listarMovimentacaoProOrigem(movimentacoesOrigens).subscribe((res) => {
                              const movimentacoesProOrigens: MovimentacaoProduto[] = this.plainToClass(MovimentacaoProduto, res) as any;
                              let cstsPisCofins: Cst[] = [];
                              let cfopsEmpresa: CfopEmpresa[] = [];
                              this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstPisCofins).subscribe((res) => {
                                cstsPisCofins = this.plainToClass(Cst, res) as any;
                                this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cfopEmpresa).subscribe((res) => {
                                  cfopsEmpresa = this.plainToClass(CfopEmpresa, res) as any;
                                  if (this.ehValidoNegociacaoMovimentacao(movimentacoesOrigens, movimentacoesProOrigens, negocicoes) && this.ehValidoCfopCstPisCofins(cstsPisCofins, cfopsEmpresa)) {
                                    this.movimentacaoControlador.ehInserirQuantidadeParcelas();
                                    this.estoqueSemReferencia();
                                    this.ehFinalizarMovimentacao();
                                  }
                                });
                              });
                            });
                          });
                        });
                      }
                    });
                  }
                });
              });
            });
          }
        });
      }
    }
  }


  ehValidoTotalProduto(): boolean {
    const ARRENDONDAMENTO_ACEITAVEL: number = 0.02;
    const util: Util = new Util();
    for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
      let valorTotalProduto: number = util.arredondar(movimentacaoProduto.quantidade * movimentacaoProduto.getValorUnitario(), new UtilSessao().getEmpresa().regraCasasDecimais) - (this.movimentacaoControlador.usaDescontoUnitario ? 0 : movimentacaoProduto.descontoValor);
      if ((movimentacaoProduto.valorTotal - valorTotalProduto) > ARRENDONDAMENTO_ACEITAVEL || (movimentacaoProduto.valorTotal - valorTotalProduto) < ARRENDONDAMENTO_ACEITAVEL * -1) {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.verifiqueOsProdutos, [movimentacaoProduto.produtoNome])));
        return false;
      }
    }
    return true;
  }

  ehFinalizarMovimentacao(): void {
    if (this.movimentacaoControlador.finalizando == 'S' && this.movimentacaoControlador.nota) {
      this.validarPreenchimentoNotaMovimentacao();
    } else {
      this.persistirMovimentacao();
    }
  }

  validarValoresMaximosEmpresa(): boolean {
    if (this.validarPesoMaximoEmpresa() && this.validarValorMaximoEmpresa() && this.validarQuantidadeMaximaEmpresa()) {
      return true;
    } else {
      return false;
    }
  }

  validarPesoMaximoEmpresa(): boolean {
    if (this.movimentacaoControlador.empresaAlertarPesoAcima > 0) {
      if (Number(this.movimentacaoControlador.empresaAlertarPesoAcima) < this.movimentacaoControlador.movimentacao.pesoBruto) {
        if (confirm(this.util.substituir(this.bibDialogo.alertaPesoAcimaDe, [this.movimentacaoControlador.movimentacao.pesoBruto, this.movimentacaoControlador.empresaAlertarPesoAcima]) + '\n' + this.bibDialogo.desejaContinuar)) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  validarValorMaximoEmpresa(): boolean {
    if (this.movimentacaoControlador.empresaAlertarValorAcima > 0) {
      if (Number(this.movimentacaoControlador.empresaAlertarValorAcima) < this.movimentacaoControlador.movimentacao.valorTotal) {
        if (confirm(this.util.substituir(this.bibDialogo.alertaValorAcimaDe, [this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.empresaAlertarValorAcima]) + '\n' + this.bibDialogo.desejaContinuar)) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  validarQuantidadeMaximaEmpresa(): boolean {
    if (this.movimentacaoControlador.empresaAlertarQuantidadeAcima > 0) {
      let quantidadeAcimaMaximoEmpresa: number = 0;
      let produtosQuantidadeAcima: string = '';
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => {
        if (movimentacaoProduto.getQuantidade() > this.movimentacaoControlador.empresaAlertarQuantidadeAcima) {
          quantidadeAcimaMaximoEmpresa++;
          produtosQuantidadeAcima += 'Item: ' + movimentacaoProduto.ordem + ' - ' + movimentacaoProduto.produto + '\n';
        }
      });
      if (quantidadeAcimaMaximoEmpresa > 0) {
        if (confirm(this.util.substituir(this.bibDialogo.alertaQuantidadeAcimaDe, [produtosQuantidadeAcima]) + '\n' + this.bibDialogo.desejaContinuar)) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  validarPreenchimentoNotaMovimentacao(): void {
    let ehValidoParceiro: boolean = true;
    const contemLetras: any = /(?=(?:.*?[A-Z]))/;
    const idProdutos: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
    const idsCstIcms: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idCstIcms);

    this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idProdutos.toString())), this.bibServico.produto).subscribe((res) => {
      let produtos: Produto[] = this.plainToClass(Produto, res) as any;
      let idNcms: number[] = produtos.filter((produto) => produto.idNcm != null).map((produto) => produto.idNcm);

      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_NCM', idNcms.length > 0 ? idNcms.toString() : 0)), this.bibServico.ncmEmpresa).subscribe((res) => {
        const ncmEmpresas: NcmEmpresa[] = this.plainToClass(NcmEmpresa, res) as any;

        this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idsCstIcms.toString())), this.bibServico.cstIcms).subscribe((res) => {
          const cstIcmss: Cst[] = this.plainToClass(Cst, res) as any;
          const produtosNcmCest: any[] = this.criarListaProdutoNcmCest(produtos, ncmEmpresas);
          this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstPisCofins).subscribe((res) => {
            const cstPISCofinss: Cst[] = this.plainToClass(Cst, res) as any;

            this.listarParceiro().subscribe((res) => {
              const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
              if (parceiro.contribuinte == 1 && (!parceiro.inscricaoEstadual || parceiro.inscricaoEstadual == '' || parceiro.inscricaoEstadual == 'ISENTO' || contemLetras.exec(parceiro.inscricaoEstadual))) {
                ehValidoParceiro = false;
                this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.parceiroInscricaoEstadualIncorreta, [parceiro.numero + ' - ' + parceiro.razaoSocial])));
              }

              this.listarParceiroEnderecoDestino().subscribe((res) => {
                const parceiroEnderecoDestino: any = res[0];
                if (parceiroEnderecoDestino.cep == null || parceiroEnderecoDestino.cep.replace('-', '').length < 8) {
                  this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.parceiroEstaComCepIncorreto, [parceiro.numero + ' - ' + parceiro.razaoSocial])));
                  ehValidoParceiro = false;
                }
                this.ehValidoPersistirMovimentacao(produtosNcmCest, cstIcmss, cstPISCofinss, ehValidoParceiro, produtos);
              });
            });
          });
        });
      });
    });
  }

  listarParceiroEnderecoDestino(): Observable<any> {
    if (this.movimentacaoControlador.movimentacao.idParceiroEndereco) {
      return this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.movimentacaoControlador.movimentacao.idParceiroEndereco)), this.bibServico.parceiroEndereco);
    }
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.movimentacaoControlador.movimentacao.idParceiroDestino)), this.bibServico.parceiroDestino);
  }

  criarListaProdutoNcmCest(produtos: Produto[], ncmEmpresas: NcmEmpresa[]): any[] {
    let produtosNcmCest: any[] = [];
    produtos.forEach((produto, i) => {
      const ncmEmpresa: NcmEmpresa = ncmEmpresas.find((ncmEmpresaBusca) => ncmEmpresaBusca.idNcm == produto.idNcm);
      produtosNcmCest.push({ id: i, idProduto: produto.id, produto: produto.nome, idNcm: produto.idNcm, ncm: produto.ncm ? produto.ncm : null, cest: produto.cest ? produto.cest : ncmEmpresa && ncmEmpresa.cest ? ncmEmpresa.cest : null });
    });
    return produtosNcmCest;
  }

  ehValidoPersistirMovimentacao(produtosNcmCest: any[], cstIcmss: Cst[], cstPISCofins: Cst[], ehValidoParceiroInscricaoEstadual: boolean, produtos: Produto[]): boolean {
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    const contagemProdutoInvalido: number = this.ehValidoProdutoFiscalNcmCest(produtosNcmCest, cstIcmss, FINALIDADE_COMPLEMENTO, FINALIDADE_AJUSTE);
    const contagemMovimentacaoProdutoBaseCalculo: number = this.ehMovimentacaoProdutoBaseCalculo(this.movimentacaoControlador.movimentacao.movimentacaoProdutos, cstIcmss, FINALIDADE_COMPLEMENTO, FINALIDADE_AJUSTE);
    const contagemMovimentacaoProdutoPisCofins: number = this.ehMovimentacaoProdutoPisCofins(this.movimentacaoControlador.movimentacao.movimentacaoProdutos, cstPISCofins, FINALIDADE_COMPLEMENTO, FINALIDADE_AJUSTE);
    const ehValidoProdutoFaturamento: boolean = this.ehValidoProdutoFaturamento(produtos);
    if (contagemProdutoInvalido > 0 || contagemMovimentacaoProdutoBaseCalculo > 0 || contagemMovimentacaoProdutoPisCofins > 0 || ehValidoParceiroInscricaoEstadual == false || ehValidoProdutoFaturamento == false) {
      return false;
    } else {
      this.persistirMovimentacao();
    }
  }

  ehValidoProdutoFaturamento(produtos: Produto[]): boolean {
    let quantidadeProdutoNaoPermiteFaturamento: number = 0;
    produtos.forEach((produto) => {
      if (produto.faturamento == 'N') {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.produtoNaoPermiteFaturamento, [produto.numero, produto.nome])));
        quantidadeProdutoNaoPermiteFaturamento++;
      }
    });
    return quantidadeProdutoNaoPermiteFaturamento > 0 ? false : true;
  }

  contarNumerosCest(cest: string): number {
    const numerosEncontrados = cest.match(/\d/g);
    return numerosEncontrados ? numerosEncontrados.length : 0;
  }

  ehValidoProdutoFiscalNcmCest(produtosNcmCest: any, cstIcmss: Cst[], FINALIDADE_COMPLEMENTO: number, FINALIDADE_AJUSTE: number): number {
    let contagemProdutoInvalido: number = 0;
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
      for (let produtosNcm of produtosNcmCest) {
        const idCstIcms: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.idProduto == produtosNcm.idProduto).idCstIcms;
        const cst: Cst = cstIcmss.find((cstIcms) => cstIcms.id == idCstIcms);
        if (['10', '30', '60', '70', '201', '202', '203', '500', '900'].includes(cst.cst.substring(1, cst.cst.length).toString())) {
          if (!produtosNcm.idNcm && !produtosNcm.cest) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.ncmCestObrigatorioParaProduto, [cst.cst, produtosNcm.produto])));
            contagemProdutoInvalido++;
          }
          if (produtosNcm.idNcm && !produtosNcm.cest) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.cestObrigatorioEmProduto, [cst.cst, produtosNcm.ncm.substring(produtosNcm.ncm.indexOf('-'), 0).trim(), produtosNcm.produto])));
            contagemProdutoInvalido++;
          }
          if (produtosNcm.cest && this.contarNumerosCest(produtosNcm.cest) != 7) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.cestErrado, [produtosNcm.produto])));
            contagemProdutoInvalido++;
          }
        } else {
          if (!produtosNcm.idNcm) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.ncmObrigatorioProduto, [cst.cst, produtosNcm.produto])));
            contagemProdutoInvalido++;
          }
        }
      }
    }
    return contagemProdutoInvalido;
  }

  ehMovimentacaoProdutoBaseCalculo(movimentacaoProdutos: MovimentacaoProduto[], cstIcmss: Cst[], FINALIDADE_COMPLEMENTO: number, FINALIDADE_AJUSTE: number): number {
    let contagemMovimentacaoProdutoInvalido: number = 0;
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
      movimentacaoProdutos.forEach((movimentacaoProduto) => {
        const cst: Cst = cstIcmss.find((cstIcms) => cstIcms.id == movimentacaoProduto.idCstIcms);
        if (cst.utilizaBaseCalculo == 'S') {
          if (movimentacaoProduto.getIcmsBase() <= 0) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.baseObrigatorioProduto, [cst.cst, movimentacaoProduto.produto])));
            contagemMovimentacaoProdutoInvalido++;
          }
          if (movimentacaoProduto.getIcmsBase() == movimentacaoProduto.valorTotal && cst.cst == '020') {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.cstReducaoComBaseIntegral, [movimentacaoProduto.produto, cst.cst])));
            contagemMovimentacaoProdutoInvalido++;
          }
        }
        if (cst.utilizaBaseCalculo == 'N') {
          if (movimentacaoProduto.getIcmsBase() > 0) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtobase, [cst.cst, movimentacaoProduto.produto])));
            contagemMovimentacaoProdutoInvalido++;
          }
        }
      });
    }
    return contagemMovimentacaoProdutoInvalido;
  }

  ehMovimentacaoProdutoPisCofins(movimentacaoProdutos: MovimentacaoProduto[], cstPISCofinss: Cst[], FINALIDADE_COMPLEMENTO: number, FINALIDADE_AJUSTE: number): number {
    let contagemMovimentacaoProdutoInvalido: number = 0;
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
      movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.idCstPis != movimentacaoProduto.idCstCofins) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.cstPisCofinsDiferentes, [movimentacaoProduto.produto])));
          contagemMovimentacaoProdutoInvalido++;
        } else {
          const cst: Cst = cstPISCofinss.find((cstPisCofins) => cstPisCofins.id == movimentacaoProduto.idCstPis);
          if (cst.exigirAliquotaMaiorZero == 'S') {
            if (movimentacaoProduto.getPisAliquota() <= 0 || movimentacaoProduto.getCofinsAliquota() <= 0) {
              this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.exigeAliquotaPISCofins, [movimentacaoProduto.produto, cst.cst])));
              contagemMovimentacaoProdutoInvalido++;
            }
          }
        }
      });
    }
    return contagemMovimentacaoProdutoInvalido;
  }

  persistirMovimentacao(): void {
    this.movimentacaoControlador.movimentacao.status = this.movimentacaoControlador.finalizando;
    const transporte: Transporte = new Transporte();
    transporte.adicionar(this.movimentacaoControlador.movimentacao);
    transporte.adicionar(this.movimentacaoControlador.produtosParceiro);
    transporte.adicionar(this.movimentacaoControlador.idProdutoParceiros);
    transporte.adicionar(this.movimentacaoControlador.idMovimentacaoChaves);
    this.persistir(transporte, this.bibServico.movimentacao, null).subscribe(
      (res) => {
        if (this.movimentacaoControlador.movimentacao.id) {
          this.definirIds();
          this.atualizarDados(this.movimentacaoControlador.movimentacao.id);
          this.movimentacaoControlador.setAcessoRapido();
        }
        if (this.utilSessao.falha) {
          this.utilSessao.getResultados().forEach((resultado) => {
            if (resultado.mensagem && resultado.mensagem == 'Entre em contato com a aGrow') {
              this.mostrarSalvar = true;
            }
          });
          this.movimentacaoControlador.movimentacao.status = 'N';
        }
        this.movimentacaoControlador.ehBloqueado();
        this.ehAbrirRelatorio();
      },
      (erro) => {
        this.movimentacaoControlador.movimentacao.status = 'N';
        this.movimentacaoControlador.ehBloqueado();
      }
    );
  }

  ehAbrirRelatorio(): void {
    this.utilSessao.setIdentificacao(new Identificacao('controlaPermissao', false));
    if (this.movimentacaoControlador.movimentacao.status == 'S' && ((this.movimentacaoControlador.pedido && this.movimentacaoControlador.saida) || this.movimentacaoControlador.interna || this.movimentacaoControlador.orcamento || (this.movimentacaoControlador.devolucao && this.movimentacaoControlador.saida && !this.movimentacaoControlador.nota && !this.movimentacaoControlador.notaTerceiro)) && this.utilSessao.getEmpresa().idRelatorioVenda) {
      this.movimentacaoControlador.util.abrirRelatorio(this.movimentacaoControlador.movimentacao.id, this.utilSessao.getEmpresa().idRelatorioVenda, true, true);
    }
  }

  ehVendaReceituario(): boolean {
    let mensagem: string = '';
    let temProdutoCultura: number = 0;
    let temProdutoSemCultura: number = 0;
    let temProdutoPraga: number = 0;
    let temProdutoSemPraga: number = 0;
    let numeroAplicacaoZerada: number = 0;
    for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
      if (movimentacaoProduto.grauToxicologica) {
        if (movimentacaoProduto.cultura) {
          temProdutoCultura += 1;
        } else {
          temProdutoSemCultura += 1;
        }
        if (movimentacaoProduto.praga) {
          temProdutoPraga += 1;
        } else {
          temProdutoSemPraga += 1;
        }
        if (movimentacaoProduto.numeroAplicacao == 0) {
          numeroAplicacaoZerada += 1;
        }
      }
    }
    if ((temProdutoCultura > 0 || temProdutoPraga > 0) && this.movimentacaoControlador.movimentacao.idAgronomo == null) {
      mensagem = this.bibDialogo.informacaoAgronomoObrigatorioReceituario + '\n ';
    }
    if (temProdutoCultura > 0 && temProdutoSemCultura > 0) {
      mensagem += this.util.substituir(this.bibDialogo.existeItensComESemCultura, [temProdutoCultura, temProdutoSemCultura]) + '\n ';
    }
    if (temProdutoPraga > 0 && temProdutoSemPraga > 0) {
      mensagem += this.util.substituir(this.bibDialogo.existeItensComESemPraga, [temProdutoPraga, temProdutoSemPraga]) + '\n ';
    }
    if (temProdutoPraga == 0 && temProdutoSemPraga > 0 && temProdutoSemCultura == 0 && temProdutoCultura > 0) {
      mensagem += this.util.substituir(this.bibDialogo.existeItensComESemPraga, [temProdutoPraga, temProdutoSemPraga]) + '\n ';
    }
    if (temProdutoCultura > 0 && temProdutoPraga > 0 && numeroAplicacaoZerada > 0) {
      mensagem += this.bibDialogo.existeItensComCulturaPragaSemNumeroAplicacao;
    }

    if (mensagem != '' && !this.movimentacaoControlador.devolucao && !this.movimentacaoControlador.entrada) {
      this.utilSessao.setResultado(new Resultado(false, mensagem));
      return false;
    }
    return true;
  }

  ehProdutoRepetido(): boolean {
    let movimentacaoProdutoRepetidos: MovimentacaoProduto[] = [];
    let mensagem: string = '';
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      let temProdutoRepetido: boolean = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProdutoBusca) => movimentacaoProdutoBusca.idProduto == movimentacaoProduto.idProduto).length >= 2;
      if (temProdutoRepetido && movimentacaoProdutoRepetidos.find((movimentacaoProdutoBusca) => movimentacaoProdutoBusca.idProduto == movimentacaoProduto.idProduto) == undefined) {
        movimentacaoProdutoRepetidos.push(movimentacaoProduto);
      }
    });
    if (movimentacaoProdutoRepetidos.length > 0 && this.movimentacaoControlador.finalizando == 'S') {
      movimentacaoProdutoRepetidos.forEach((movimentacaoProduto) => (mensagem += movimentacaoProduto.produto + '\n'));
      if (confirm(this.util.substituir(this.bibDialogo.produtosLancancadosEmDuplicidade, [mensagem]))) {
        return true;
      }
      return false;
    }
    return true;
  }

  confirmarVinculoMovimentacao(): boolean {
    let mensagem: string = '';
    let idMovimentacaoProOrigens: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacaoProOrigem > 0).length;
    let contagemMovimentacaoProduto: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
    if (idMovimentacaoProOrigens > 0 && idMovimentacaoProOrigens != contagemMovimentacaoProduto) {
      mensagem += this.util.substituir(this.bibDialogo.existeItensComESemVinculo, [idMovimentacaoProOrigens, contagemMovimentacaoProduto - idMovimentacaoProOrigens]);
    }
    if (this.movimentacaoControlador.movimentacao.importacaoXml == 'S' && this.movimentacaoControlador.movimentacao.movimentacaoProdutoFaltantes.length > 0) {
      mensagem += (mensagem != '' ? '\n' : '') + this.util.substituir(this.bibDialogo.existemItensFaltantes, [this.movimentacaoControlador.movimentacao.movimentacaoProdutoFaltantes.length]);
    }
    mensagem += this.verificarItensVinculadosForaXml();
    if (mensagem != '') {
      mensagem += (mensagem != '' ? '\n' : '') + this.bibDialogo.desejaContinuar;
    }
    return mensagem != '' ? confirm(mensagem) : true;
  }

  verificarItensVinculadosForaXml(): string {
    let mensagem: string = '';
    if (this.movimentacaoControlador.movimentacao.importacaoXml == 'S') {
      const movimentacaoProdutos: MovimentacaoProduto[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.importacaoXml == 'N' && movimentacaoProduto.idMovimentacaoProOrigem > 0);
      movimentacaoProdutos.forEach((movimentacaoProduto) => {
        mensagem += (mensagem != '' ? '\n' : '') + this.util.substituir(this.bibDialogo.produtoVinculadoForaXml, [movimentacaoProduto.produtoNumero.toString() + ' ' + movimentacaoProduto.produto]);
      });
    }
    return mensagem;
  }



  estoqueSemReferencia(): void {
    if (this.movimentacaoControlador.estoqueComprado == -1 || this.movimentacaoControlador.estoqueReservado == -1) {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.idMovimentacaoProOrigem == null) {
          movimentacaoProduto.estoqueComprado = 0;
          movimentacaoProduto.estoqueReservado = 0;
        }
      });
    }
  }

  ehMovimentacaoInterna(): void {
    if (this.movimentacaoControlador.interna) {
      const loja: Loja = this.movimentacaoControlador.lojas.find((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLojaDestino);
      this.movimentacaoControlador.movimentacao.idParceiro = loja && loja.idParceiroTransferencia ? loja.idParceiroTransferencia : this.movimentacaoControlador.movimentacao.idColaborador;
      this.movimentacaoControlador.movimentacao.parceiro = loja && loja.parceiro ? loja.parceiro : this.movimentacaoControlador.movimentacao.colaborador;
      this.movimentacaoControlador.movimentacao.nomeParceiro = loja && loja.parceiro ? loja.parceiro : this.movimentacaoControlador.movimentacao.colaborador;
    }
  }

  setValorPadrao(): void {
    this.movimentacaoControlador.movimentacao.financeiro = this.movimentacaoControlador.financeiro;
    this.movimentacaoControlador.movimentacao.identificacao = this.movimentacaoControlador.identificacao;
    for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
      if (movimentacaoProduto.getValorUnitario() == null) {
        movimentacaoProduto.setValorUnitario(0, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
      }
    }
    this.movimentacaoControlador.ratearDescontoMovimentacaoProduto(this.movimentacaoControlador.movimentacao.descontoValor, false);
    this.recalcularDataMenorHojeParcela();
  }

  recalcularDataMenorHojeParcela(): void {
    if ((this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 1) && (this.movimentacaoControlador.umaParcelaAvista)) {
      if (new Date(this.movimentacaoControlador.movimentacao.movimentacaoParcelas[0].dataVencimento.toDateString()) < new Date(this.movimentacaoControlador.movimentacao.data.toDateString())) {
        this.movimentacaoControlador.movimentacao.movimentacaoParcelas[0].dataVencimento = this.movimentacaoControlador.movimentacao.data;
      }
    }
  }

  setEhValidoValorImpostos(): boolean {
    if (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) {
      let cofinsValor: number = 0;
      let pisValor: number = 0;
      let ipiValor: number = 0;
      let icmsValor: number = 0;
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        cofinsValor += movimentacaoProduto.getCofinsValor();
        pisValor += movimentacaoProduto.getPisValor();
        ipiValor += movimentacaoProduto.getIpiValor();
        icmsValor += movimentacaoProduto.getIcmsValor();
      });
      if (this.util.arredondar(this.movimentacaoControlador.movimentacao.cofinsValor) == this.util.arredondar(cofinsValor) && this.util.arredondar(this.movimentacaoControlador.movimentacao.pisValor) == this.util.arredondar(pisValor) && this.util.arredondar(this.movimentacaoControlador.movimentacao.ipiValor) == this.util.arredondar(ipiValor) && this.util.arredondar(this.movimentacaoControlador.movimentacao.icmsValor) == this.util.arredondar(icmsValor)) {
        return true;
      }
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.somatarioDeImpostos));
      return false;
    }
    return true;
  }

  atualizarDados(id: number): void {
    if (id != null && id != 0) {
      const transporte: Transporte = new Transporte();
      transporte.adicionar(new Criterio('ID', id));
      this.comunicacaoService.listar(transporte, this.movimentacaoControlador.bibServico.movimentacao).subscribe((res) => {
        const movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
        this.movimentacaoControlador.movimentacao.numero = movimentacao.numero;
        this.movimentacaoControlador.movimentacao.chave = movimentacao.chave;
        this.movimentacaoControlador.movimentacao.financeiro = movimentacao.financeiro;
        this.movimentacaoControlador.movimentacao.valorComissao = movimentacao.valorComissao;
        this.atualizarDadosMovimentacaoProduto(id, movimentacao.status);
      });
    }
  }

  atualizarDadosMovimentacaoProduto(idMovimentacao: number, status: string): void {
    if (status == 'S') {
      const transporte: Transporte = new Transporte();
      transporte.adicionar(new Criterio('ID_MOVIMENTACAO', idMovimentacao));
      this.comunicacaoService.listar(transporte, this.movimentacaoControlador.bibServico.movimentacaoProduto).subscribe((res) => {
        const movimentacaoProdutos: MovimentacaoProduto[] = this.plainToClass(MovimentacaoProduto, res) as any;
        this.definirStatusEstoque(movimentacaoProdutos);
      })
    }
  }

  definirStatusEstoque(movimentacaoProdutos: MovimentacaoProduto[]): void {
    if (movimentacaoProdutos) {
      let movimentacaoProduto: MovimentacaoProduto = movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.estoqueFisico != 0);
      const estoqueFisico: number = movimentacaoProduto ? movimentacaoProduto.estoqueFisico : 0;
      movimentacaoProduto = movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.estoqueReservado != 0);
      const estoqueReservado: number = movimentacaoProduto ? movimentacaoProduto.estoqueReservado : 0;
      this.estoqueDisponivel = estoqueFisico == 1 || estoqueReservado == 1 ? 1 : estoqueFisico == -1 || estoqueReservado == -1 ? -1 : 0;
      movimentacaoProduto = movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.estoqueNfeRegra != 0);
      this.estoqueNfe = movimentacaoProduto ? movimentacaoProduto.estoqueNfeRegra : 0;
    }
  }

  definirIds(): void {
    this.definirId([this.movimentacaoControlador.movimentacao], this.bibClasse.movimentacao, true);
    this.definirId(this.movimentacaoControlador.movimentacao.movimentacaoProdutos, this.bibClasse.movimentacaoProduto);
    let movimentacaoQuantidades: MovimentacaoQuantidade[] = [];
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      movimentacaoProduto.movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
        movimentacaoQuantidades.push(movimentacaoQuantidade);
      });
    });
    this.definirId(movimentacaoQuantidades, this.bibClasse.movimentacaoQuantidade);
    this.definirId(this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.bibClasse.movimentacaoParcela);
    this.definirId(this.movimentacaoControlador.movimentacao.movimentacaoChaves, this.bibClasse.movimentacaoChave);
    this.limparListaExcluir();
  }

  limparListaExcluir(): void {
    this.movimentacaoControlador.excluirParcelas = [];
    this.movimentacaoControlador.excluirProdutos = [];
    this.movimentacaoControlador.excluirQuantidades = [];
    this.movimentacaoControlador.idMovimentacaoChaves = [];
  }

  ehValido(): boolean {
    const ehValidoNegociacaoParceiro: boolean = this.ehValidoNegociacaoParceiro();
    const ehValidoProdutoObrigatorio: boolean = this.ehValidoProdutoObrigatorio();
    const ehValidoImportacaoXmlProdutoVinculado: boolean = this.setEhValidoImportacaoXmlProdutoVinculado();
    const ehValidoVencimentoMenorNegociacao: boolean = this.ehValidoVencimentoMenorNegociacao();
    const ehValidoTransferenciaLoja: boolean = this.ehValidoTransferenciaLoja();
    const ehValidoMovimentacaoNomeParceiro: boolean = this.ehValidoMovimentacaoNomeParceiro();
    const ehValidoMovimentacaoQuantidade: boolean = this.ehValidoMovimentacaoQuantidade();
    const ehValidoMovimentacaoValor: boolean = this.ehValidoMovimentacaoValor();
    const ehValidoDataSaida: boolean = this.setEhValidoDataSaida();
    const ehValidoPrazoNegociacaoDataVencimento: boolean = this.ehValidoPrazoNegociacaoDataVencimento();
    const ehValidoMovimentacaoProdutoQuantidadeValorUnitarioXml: boolean = this.ehValidoMovimentacaoProdutoQuantidadeValorUnitarioXml();
    const ehValidoDesconto: boolean = this.ehValidoDesconto();
    const ehValidoInformacaoComplementar: boolean = this.ehValidoInformacaoComplementar();
    const ehValidoEmitenteNotaFiscalEntrada: boolean = this.ehValidoEmitenteNotaFiscalEntrada();
    const ehValidoNumeroNotaFiscalEntrada: boolean = this.ehValidoNumeroNotaFiscalEntrada();
    if (this.movimentacaoControlador.finalizando == 'S') {
      const ehVendaReceituario: boolean = this.ehVendaReceituario();
      const ehValidoParcelaMovimentacao: boolean = this.ehValidoParcelaMovimentacao();
      const ehValidoParcelaFinanceiro: boolean = this.ehValidoParcelaFinanceiro();
      const ehValidoParcelaDataRepetida: boolean = this.ehValidoParcelaDataRepetida();
      const ehValidoProdutoCfop: boolean = this.ehValidoProdutoCfop();
      const ehValidoUtilizacaoCfops: boolean = this.ehValidoUtilizacaoCfops();
      const ehValidoValidadeLote: boolean = this.ehValidoValidadeLotes();
      const ehValidoProdutoImposto: boolean = this.ehValidoProdutoImposto();
      const ehValidoNegociacaoQuantidadeParcela: boolean = this.ehValidoNegociacaoQuantidadeParcela();
      const ehValidoNegociacaoValorMinimo: boolean = this.ehValidoNegociacaoValorMinimo();
      const ehValidoNotaObrigatorio: boolean = this.ehValidoNotaObrigatorio();
      const ehValidoMovimentacaoNumero: boolean = this.ehValidoMovimentacaoNumero();
      const ehValidoEndereco: boolean = this.setEhValidoEndereco();
      const ehValidoValorImpostos: boolean = this.setEhValidoValorImpostos();
      const ehValidoParcelaTabelaPreco: boolean = this.setEhValidoParcelaTabelaPreco();
      const ehValidoParceiroEstado: boolean = this.ehValidoParceiroEstado();
      const ehValidoParceiroCnpjCpf: boolean = this.ehValidoParceiroCnpjCpf();
      const ehValidoValorUnitarioMenorValorTabela: boolean = this.ehValidoValorUnitarioMenorValorTabela();
      const ehValidoMovimentacaoProdutoValorUnitarioZerados: boolean = this.ehValidoMovimentacaoProdutoQuantidadeValorUnitarioZerado();
      const ehValidoMovimentacaoNaturezaOperacaoTransferenciaImposto: boolean = this.ehValidoMovimentacaoNaturezaOperacaoTransferenciaImpostoPisCofins();
      const ehValidoTransferenciaLojaIdParceiro: boolean = this.ehValidoTransferenciaLojaIdParceiro();
      const ehValidoNomeConsumidorFinal: boolean = this.movimentacaoControlador.ehValidoNomeConsumidorFinal();
      const ehValidoMovimentacaoParcela: boolean = this.ehValidoMovimentacaoParcela();
      return ehValidoProdutoObrigatorio && ehValidoVencimentoMenorNegociacao && ehValidoTransferenciaLoja && ehValidoMovimentacaoNomeParceiro && ehValidoMovimentacaoQuantidade && ehValidoMovimentacaoValor && ehValidoNegociacaoQuantidadeParcela && ehValidoNegociacaoValorMinimo && ehValidoParcelaMovimentacao && ehValidoParcelaFinanceiro && ehValidoParcelaDataRepetida && ehValidoProdutoCfop && ehValidoProdutoImposto && ehValidoNotaObrigatorio && ehValidoMovimentacaoNumero && ehValidoEndereco && ehValidoDataSaida && ehValidoNegociacaoParceiro && ehValidoValorImpostos && ehValidoValorImpostos && ehValidoParcelaTabelaPreco && ehValidoParceiroEstado && ehValidoParceiroCnpjCpf && ehValidoPrazoNegociacaoDataVencimento && ehValidoMovimentacaoProdutoQuantidadeValorUnitarioXml && ehValidoMovimentacaoProdutoValorUnitarioZerados && ehValidoMovimentacaoNaturezaOperacaoTransferenciaImposto && ehValidoTransferenciaLojaIdParceiro && ehValidoValorUnitarioMenorValorTabela && ehValidoUtilizacaoCfops && ehValidoValidadeLote && ehValidoNomeConsumidorFinal && ehValidoDesconto && ehValidoInformacaoComplementar && ehValidoEmitenteNotaFiscalEntrada && ehValidoNumeroNotaFiscalEntrada && ehVendaReceituario && ehValidoMovimentacaoParcela;
    }
    return ehValidoProdutoObrigatorio && ehValidoImportacaoXmlProdutoVinculado && ehValidoVencimentoMenorNegociacao && ehValidoTransferenciaLoja && ehValidoMovimentacaoNomeParceiro && ehValidoMovimentacaoQuantidade && ehValidoMovimentacaoValor && ehValidoDataSaida && ehValidoNegociacaoParceiro && ehValidoPrazoNegociacaoDataVencimento && ehValidoMovimentacaoProdutoQuantidadeValorUnitarioXml && ehValidoDesconto && ehValidoInformacaoComplementar && ehValidoEmitenteNotaFiscalEntrada && ehValidoNumeroNotaFiscalEntrada;
  }

  ehValidoProdutoCfop(): boolean {
    let contagemProdutoSemCfop: number = 0;
    if (this.movimentacaoControlador.movimentacao.modelo != null && (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro)) {
      for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
        if (!movimentacaoProduto.idCfopEmpresa) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoSemCfop, [movimentacaoProduto.produto])));
          contagemProdutoSemCfop++;
        }
      }
    }
    if (contagemProdutoSemCfop > 0) {
      return false;
    }
    return true;
  }

  ehValidoUtilizacaoCfops(): boolean {
    if ((this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) && this.movimentacaoControlador.cfopsEmpresa.length > 0) {
      let numeroCfops = new Set();
      for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
        if (movimentacaoProduto.idCfopEmpresa) {
          const movimentacaoProdutoCfopEmpresa: CfopEmpresa = this.movimentacaoControlador.cfopsEmpresa.find((cfopEmpresaBusca) => cfopEmpresaBusca.id == movimentacaoProduto.idCfopEmpresa);
          numeroCfops.add(movimentacaoProdutoCfopEmpresa.idCfop);
        }
      }
      if (numeroCfops.size > 1) {
        if (confirm(this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.foramUtilizadorVariosCfops, [Array.from(numeroCfops).join(', ')]))) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  ehValidoValidadeLotes(): boolean {
    if (this.movimentacaoControlador.nota && this.movimentacaoControlador.lotes.length > 0) {
      let lotesVencidos = new Set();
      for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
        for (let movimentacaoQuantidade of movimentacaoProduto.movimentacaoQuantidades) {
          if (movimentacaoQuantidade.idLote) {
            const lote: Lote = this.movimentacaoControlador.lotes.find((lote) => lote.id == movimentacaoQuantidade.idLote);
            let dataAtual: Date = new Date();
            if (lote && lote.dataValidade && new Date(lote.dataValidade) < dataAtual) {
              lotesVencidos.add(movimentacaoProduto.produtoNome);
            }
          }
        }
      }
      if (lotesVencidos.size > 0) {
        if (confirm(this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.foramUtilizadosLotesVencidos, [Array.from(lotesVencidos).join(', ')]))) {
          return true;
        }
        return false;
      }
      return true;
    }
    return true;
  }

  ehValidoProdutoImposto(): boolean {
    let contagemProdutoInvalido: number = 0;
    if ((this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.movimentacao.modelo != null) || this.movimentacaoControlador.nota) {
      for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
        if ((!this.movimentacaoControlador.entradaServico && (!movimentacaoProduto.idCstCofins || !movimentacaoProduto.idCstPis || !movimentacaoProduto.idCstIcms || !movimentacaoProduto.idCstIpi)) || !movimentacaoProduto.idCfopEmpresa) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoSemImpostos, [movimentacaoProduto.produto])));
          contagemProdutoInvalido++;
        }
        if (movimentacaoProduto.getIcmsStValor() < 0 || movimentacaoProduto.getIcmsValor() < 0 || movimentacaoProduto.getCofinsValor() < 0 || movimentacaoProduto.getPisValor() < 0 || movimentacaoProduto.getIpiValor() < 0) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoImpostoValorNegativo, [movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto])));
          contagemProdutoInvalido++;
        }
        contagemProdutoInvalido += this.ehValidarPreenchimentoBaseAliquotaValor(movimentacaoProduto);
      }
    }
    if (contagemProdutoInvalido > 0) {
      return false;
    }
    return true;
  }

  ehValidoCfopCstPisCofins(cstsPisCofins: Cst[], cfopsEmpresa: CfopEmpresa[]): boolean {
    let contagemCfopCstPisCofinsInvalido: number = 0;
    if ((this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.movimentacao.modelo != null) || this.movimentacaoControlador.nota) {
      for (let movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
        if (movimentacaoProduto.idCfopEmpresa && movimentacaoProduto.idCstPis && movimentacaoProduto.idCstCofins) {
          const cstCofins: Cst = cstsPisCofins.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstCofins);
          const cstPis: Cst = cstsPisCofins.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstPis);
          const cfopEmpresa: CfopEmpresa = cfopsEmpresa.find((cfopEmpresaBusca) => cfopEmpresaBusca.id == movimentacaoProduto.idCfopEmpresa);
          if (cfopEmpresa.cfopEntradaSaida != cstPis.entradaSaida || cfopEmpresa.cfopEntradaSaida != cstCofins.entradaSaida) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoCfopCstPisCofins, [movimentacaoProduto.produto])));
            contagemCfopCstPisCofinsInvalido++;
          }
        }
      }
      if (contagemCfopCstPisCofinsInvalido > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  ehValidarPreenchimentoBaseAliquotaValor(movimentacaoProduto: MovimentacaoProduto): number {
    let quantidade: number = 0;
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != null && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
      if ((movimentacaoProduto.getCofinsBase() <= 0 && (movimentacaoProduto.getCofinsAliquota() > 0 || movimentacaoProduto.getCofinsValor() > 0)) || (movimentacaoProduto.getCofinsBase() > 0 && (movimentacaoProduto.getCofinsAliquota() <= 0 || movimentacaoProduto.getCofinsValor() <= 0))) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.itemComValoresDeImpostosIncorretos, [movimentacaoProduto.ordem, movimentacaoProduto.produto, this.movimentacaoControlador.bibDialogo.cofins])));
        quantidade++;
      }
      if ((movimentacaoProduto.getPisBase() <= 0 && (movimentacaoProduto.getPisAliquota() > 0 || movimentacaoProduto.getPisValor() > 0)) || (movimentacaoProduto.getPisBase() > 0 && (movimentacaoProduto.getPisAliquota() <= 0 || movimentacaoProduto.getPisValor() <= 0))) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.itemComValoresDeImpostosIncorretos, [movimentacaoProduto.ordem, movimentacaoProduto.produto, this.movimentacaoControlador.bibDialogo.pis])));
        quantidade++;
      }
      if ((movimentacaoProduto.getIpiBase() <= 0 && (movimentacaoProduto.getIpiAliquota() > 0 || movimentacaoProduto.getIpiValor() > 0)) || (movimentacaoProduto.getIpiBase() > 0 && (movimentacaoProduto.getIpiAliquota() <= 0 || movimentacaoProduto.getIpiValor() <= 0))) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.itemComValoresDeImpostosIncorretos, [movimentacaoProduto.ordem, movimentacaoProduto.produto, this.movimentacaoControlador.bibDialogo.ipi])));
        quantidade++;
      }
    }
    return quantidade;
  }

  setEhValidoEndereco(): boolean {
    if (!this.movimentacaoControlador.movimentacao.idParceiroEndereco && !this.movimentacaoControlador.movimentacao.idParceiroDestino && (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro)) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.entrada ? this.bibDialogo.fornecedorNaoPossuiEndereco : this.bibDialogo.parceiroNaoPossuiEndereco));
      return false;
    }
    return true;
  }

  listarParceiroEndereco(): Observable<any> {
    if (!this.movimentacaoControlador.movimentacao.idParceiroEndereco && !this.movimentacaoControlador.movimentacao.idParceiroDestino && (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro)) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID_PARCEIRO', this.movimentacaoControlador.movimentacao.idParceiro));
      criterios.push(new Criterio('ID_VINCULO', 3));
      const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroEndereco);
      resposta.subscribe((res) => {
        const parceiroEndereco: ParceiroEndereco = this.plainToClass(ParceiroEndereco, res[0]);
        if (parceiroEndereco) {
          this.movimentacaoControlador.idEstadoParceiro = parceiroEndereco.idEstado;
          this.movimentacaoControlador.estadoParceiro = parceiroEndereco.estado;
          this.movimentacaoControlador.movimentacao.idParceiroEndereco = parceiroEndereco.id;
        }
      });
      return resposta;
    }
    return of(null);
  }

  listarCfopEmpresaProdutos(): Observable<any> {
    let idCfopEmpresas: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idCfopEmpresa != null).map((movimentacaoProduto) => movimentacaoProduto.idCfopEmpresa);
    if (idCfopEmpresas.length > 0) {
      return this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idCfopEmpresas.toString())), this.bibServico.cfopEmpresa);
    }
    return of([]);
  }

  listarLotesProdutos(): Observable<any> {
    let idLotes: number[] = [];
    if (this.movimentacaoControlador.nota) {
      for (let indice = 0; indice < this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length; indice++) {
        idLotes = this.movimentacaoControlador.movimentacao.movimentacaoProdutos[indice].movimentacaoQuantidades.filter((movimentacaoQuantidade) => movimentacaoQuantidade.idLote != null).map((movimentacaoQuantidade) => movimentacaoQuantidade.idLote);
      }
      if (idLotes.length > 0) {
        return this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idLotes.toString())), this.bibServico.lote);
      }
    }
    return of([]);
  }

  ehValidoObrigatorio(): boolean {
    if (this.ehValidoCabecalhoObrigatorio() && this.ehValidoCabecalhoNotaObrigatorio() && this.ehValidoParcelaObrigatorio() && this.ehValidoNegociacaoObrigatorio() && this.ehValidoEstoqueEscolha() && this.setEhValidoMovimentacaoTransporte() && this.ehValidoMovimentacaoManual() && this.ehValidoChaveReferenciada()) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoChaveReferenciada(): boolean {
    const FINALIDADE_DEVOLUCAO: number = 3;
    const EMITENTE_PROPRIO: number = 0;
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO && this.movimentacaoControlador.movimentacao.emitente == EMITENTE_PROPRIO && this.movimentacaoControlador.movimentacao.movimentacaoChaves.length == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.obrigatorioChaveReferenciadaParaDevolucaoPropria));
      return false;
    }
    return true;
  }

  ehValidoMovimentacaoManual(): boolean {
    let verificaEntradaSaida: number = 0;
    if (this.movimentacaoControlador.movimentacaoManual) {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.estoqueFisico != 1 && movimentacaoProduto.estoqueFisico != -1) {
          verificaEntradaSaida++;
        }
      });
    }
    if (verificaEntradaSaida > 0) {
      return false;
    }
    return true;
  }

  ehValidoProdutoObrigatorio(): boolean {
    if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.informeUmItem));
    return false;
  }

  setEhValidoImportacaoXmlProdutoVinculado(): boolean {
    if (this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.entrada && !this.movimentacaoControlador.produtosParceiro) {
      if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => !movimentacaoProduto.idProduto).length == 0) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.produtoNaoVinculado));
        return false;
      }
    }
    return true;
  }

  ehValidoNotaObrigatorio(): boolean {
    const validaChaveModeloNfe: boolean = this.movimentacaoControlador.movimentacao.modelo != null || this.movimentacaoControlador.movimentacao.serie != null || this.movimentacaoControlador.movimentacao.chaveFiscal != null || this.movimentacaoControlador.movimentacao.importacaoXml == 'S';
    const EMITENTE_TERCEIRO: number = 1;
    if (validaChaveModeloNfe && this.movimentacaoControlador.movimentacao.emitente == EMITENTE_TERCEIRO) {
      const modelosChaveObrigatorio = ['55', '65', '57'];
      if ((this.movimentacaoControlador.movimentacao.modelo && this.movimentacaoControlador.movimentacao.serie && (modelosChaveObrigatorio.includes(this.movimentacaoControlador.movimentacao.modelo) ? this.movimentacaoControlador.movimentacao.chaveFiscal && this.movimentacaoControlador.movimentacao.chaveFiscal.length == 44 : true)) || this.movimentacaoControlador.entradaServico) {
        return true;
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.verifiqueObrigatorioDocumentoFiscal));
        return false;
      }
    }
    return true;
  }

  ehValidoMovimentacaoNumero(): boolean {
    if (this.movimentacaoControlador.notaTerceiro) {
      if (this.movimentacaoControlador.movimentacao.numero == 0) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeNumeroMovimentacao));
        return false;
      }
    }
    return true;
  }

  ehValidoCabecalhoNotaObrigatorio(): boolean {
    if (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) {
      if (this.movimentacaoControlador.movimentacao.idNaturezaOperacao) {
        return true;
      }
      return false;
    }
    return true;
  }

  ehValidoVencimentoMenorNegociacao(): boolean {
    for (let movimentacaoParcela of this.movimentacaoControlador.movimentacao.movimentacaoParcelas) {
      if (new Date(movimentacaoParcela.dataVencimento.toDateString()) < new Date(this.movimentacaoControlador.movimentacao.data.toDateString())) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.vencimentoMenorNegociacao));
        return false;
      }
    }
    return true;
  }

  ehValidoTransferenciaLoja(): boolean {
    if (this.movimentacaoControlador.transferencia && this.movimentacaoControlador.movimentacao.idLoja == this.movimentacaoControlador.movimentacao.idLojaDestino) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.lojaDestinoIgual));
      return false;
    }
    return true;
  }

  ehValidoTransferenciaLojaIdParceiro(): boolean {
    if (this.movimentacaoControlador.transferencia && !this.movimentacaoControlador.transferenciaEntrada) {
      const loja: Loja = this.movimentacaoControlador.lojas.find((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLojaDestino);
      if (loja.idParceiroTransferencia == null || loja.idParceiroTransferencia != this.movimentacaoControlador.movimentacao.idParceiro) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.lojaEntradaNaoPossuiParceiro));
        return false;
      }
    }
    return true;
  }

  ehValidoMovimentacaoParcela(): boolean {
    let idTipoTituloDataVencimento: string;
    for (const movimentacaoParcela of this.movimentacaoControlador.movimentacao.movimentacaoParcelas) {
      const dataVencimentoZerada = new Date(movimentacaoParcela.dataVencimento);
      dataVencimentoZerada.setHours(0, 0, 0, 0);
      const chaveAtual = (dataVencimentoZerada ? dataVencimentoZerada.toISOString() : '0') + (movimentacaoParcela.idTipoTitulo ? movimentacaoParcela.idTipoTitulo.toString() : '0');
      if (idTipoTituloDataVencimento == null) {
        idTipoTituloDataVencimento = chaveAtual;
      } else if (idTipoTituloDataVencimento === chaveAtual) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.naoPermitidoVencimentoIgual));
        return false;
      }
    }
    return true;
  }

  ehValidoMovimentacaoNomeParceiro(): boolean {
    let quantidadeProdutoInvalidos: number = 0;
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (movimentacaoProduto.produtoNome == null) {
        quantidadeProdutoInvalidos++;
      }
    });
    if (quantidadeProdutoInvalidos > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.preenchaNomeItem));
      return false;
    }
    return true;
  }

  ehValidoMovimentacaoQuantidade(): boolean {
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    let nfeComplementarAjuste: boolean = true;
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
      nfeComplementarAjuste = false;
    }
    let quantidadeMovimentacaoQuantidadeInvalidos: number = 0;
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      movimentacaoProduto.movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
        if (!movimentacaoQuantidade.idLoja || (movimentacaoQuantidade.quantidade == 0 && !nfeComplementarAjuste) || (this.movimentacaoControlador.saida && !movimentacaoQuantidade.referencia)) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.preenchaQuantidadeProdutoObrigatorio, [movimentacaoProduto.produto])));
          quantidadeMovimentacaoQuantidadeInvalidos++;
        }
      });
    });
    return quantidadeMovimentacaoQuantidadeInvalidos > 0 ? false : true;
  }

  ehValidoMovimentacaoValor(): boolean {
    if (this.movimentacaoControlador.movimentacao.valorTotal < 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.movimentacaoValorNegativo));
      return false;
    }
    return true;
  }

  ehValidoNegociacaoQuantidadeParcela(): boolean {
    if (this.movimentacaoControlador.movimentacao.valorTotal > 0 && this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro != 'S') {
      const cartaoEspecies: number[] = [2, 10];
      if (this.movimentacaoControlador.negociacoes && this.movimentacaoControlador.movimentacao.negociacao && !cartaoEspecies.includes(this.movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie)) {
        const negociacao: Negociacao = this.movimentacaoControlador.negociacoes.find((negociacao) => negociacao.id == this.movimentacaoControlador.movimentacao.idNegociacao);
        let entrada: number = 0;
        this.movimentacaoControlador.movimentacao.movimentacaoParcelas.forEach((movimentacaoParcela) => (movimentacaoParcela.entrada == 'S' ? entrada++ : ''));
        if (negociacao && negociacao.parcelaMaximo > 0 && negociacao.parcelaMaximo + (isEqual(this.movimentacaoControlador.movimentacao.movimentacaoParcelas[0].dataVencimento, this.movimentacaoControlador.movimentacao.data) ? 1 : 0) < this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length - entrada) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.bibDialogo.negociacaoSelecionadaLimiteParcelamento, [this.menuNegociacao.apelido, negociacao.nome, negociacao.parcelaMaximo])));
          return false;
        }
        if (negociacao && negociacao.parcelaFixo && negociacao.parcelaFixo != this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length - entrada) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.bibDialogo.negociacaoSelecionadaLimiteParcelamento, [this.menuNegociacao.apelido, negociacao.nome, negociacao.parcelaFixo])));
          return false;
        }
      }
    }
    return true;
  }

  ehValidoNegociacaoValorMinimo(): boolean {
    if (this.movimentacaoControlador.negociacoes) {
      const negociacao: Negociacao = this.movimentacaoControlador.negociacoes.find((negociacao) => negociacao.id == this.movimentacaoControlador.movimentacao.idNegociacao);
      if (negociacao) {
        const movimentacaoParcelaValorIncorreto: number = this.movimentacaoControlador.movimentacao.movimentacaoParcelas.filter((movimentacaoParcela) => movimentacaoParcela.getValorParcela() < negociacao.parcelaMinimoValor).length;
        if (movimentacaoParcelaValorIncorreto > 0) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.bibDialogo.parcelaValorMinimo, [this.movimentacaoControlador.util.moeda(negociacao.parcelaMinimoValor)])));
          return false;
        }
      }
    }
    return true;
  }

  ehValidoParcelaFinanceiro(): boolean {
    const valorTotalMaiorZero: boolean = this.movimentacaoControlador.movimentacao.valorTotal - this.movimentacaoControlador.movimentacao.valorBloqueado > 0;
    if (this.movimentacaoControlador.financeiro != 'N' && valorTotalMaiorZero && this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro != 'S' && this.movimentacaoControlador.movimentacao.especie != 'S') {
      if (this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 0) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.movimentacaoFinanceiroNecessitaParcela));
        return false;
      }
    }
    return true;
  }

  ehValidoParcelaDataRepetida(): boolean {
    if (this.movimentacaoControlador.finalizando == 'S' && this.movimentacaoControlador.movimentacao.especie != 'S') {
      for (let movimentacaoParcela of this.movimentacaoControlador.movimentacao.movimentacaoParcelas) {
        const movimentacaoParcelaDataRepetida: MovimentacaoParcela = this.movimentacaoControlador.movimentacao.movimentacaoParcelas.find((movParcela) => new Date(movParcela.dataVencimento.toDateString()) == new Date(movimentacaoParcela.dataVencimento.toDateString()));
        if (movimentacaoParcelaDataRepetida) {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.parcelaDataRepetida));
          return false;
        }
      }
    }
    return true;
  }

  ehValidoCabecalhoObrigatorio(): boolean {
    if ((this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) && this.movimentacaoControlador.entrada) {
      return (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro ? this.movimentacaoControlador.movimentacao.dataEntradaSaida : true) && (this.movimentacaoControlador.movimentacao.numero || this.movimentacaoControlador.movimentacao.numero == 0) && this.movimentacaoControlador.movimentacao.data && this.movimentacaoControlador.movimentacao.idLoja > 0 && this.movimentacaoControlador.movimentacao.idColaborador > 0 && this.movimentacaoControlador.movimentacao.idParceiro > 0 && ((this.movimentacaoControlador.movimentacao.nomeParceiro && this.movimentacaoControlador.movimentacao.nomeParceiro != '') || !this.movimentacaoControlador.usaConsumidorFinal);
    }
    return (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro ? this.movimentacaoControlador.movimentacao.dataEntradaSaida : true) && this.movimentacaoControlador.movimentacao.data && this.movimentacaoControlador.movimentacao.idLoja > 0 && this.movimentacaoControlador.movimentacao.idColaborador > 0 && (this.movimentacaoControlador.movimentacao.idParceiro > 0 || this.movimentacaoControlador.movimentacao.identificacao == 5) && ((this.movimentacaoControlador.movimentacao.nomeParceiro && this.movimentacaoControlador.movimentacao.nomeParceiro != '') || !this.movimentacaoControlador.usaConsumidorFinal);
  }

  ehValidoParcelaObrigatorio(): boolean {
    if (this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length > 0) {
      for (let movimentacaoParcela of this.movimentacaoControlador.movimentacao.movimentacaoParcelas) {
        if (movimentacaoParcela.getValorParcela().toString() == '') {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.preenchaValorParcelas));
          return false;
        }
      }
    }
    return true;
  }

  ehValidoNegociacaoObrigatorio(): boolean {
    if (this.movimentacaoControlador.usaNegociacao && !this.movimentacaoControlador.movimentacao.idNegociacao) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.preenchaNegociacao, [this.menuNegociacao.apelido])));
      return false;
    }
    return true;
  }

  ehValidoEstoqueEscolha(): boolean {
    if (this.movimentacaoControlador.identificacao == 5 && !this.movimentacaoControlador.movimentacao.estoqueEscolha) {
      return false;
    }
    return true;
  }

  ehValidoEmitenteNotaFiscalEntrada(): boolean {
    if (this.movimentacaoControlador.nota && this.movimentacaoControlador.estoqueNFe == 1 && this.movimentacaoControlador.movimentacao.emitente == null) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.numeroObrigatorio));
      return false;
    }
    return true;
  }

  ehValidoNumeroNotaFiscalEntrada(): boolean {
    if (this.movimentacaoControlador.nota && this.movimentacaoControlador.estoqueNFe == 1 && this.movimentacaoControlador.movimentacao.emitente == 1 && (this.movimentacaoControlador.movimentacao.numero == null || this.movimentacaoControlador.movimentacao.numero == 0)) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.numeroObrigatorio));
      return false;
    }
    return true;
  }

  setEhValidoMovimentacaoTransporte(): boolean {
    if (!this.movimentacaoControlador.entradaServico) {
      if ((this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.movimentacao.modelo != null) || this.movimentacaoControlador.nota) {
        if (this.movimentacaoControlador.movimentacao.freteTipo) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  setEhValidoDataSaida(): boolean {
    if (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) {
      if (new Date(this.movimentacaoControlador.movimentacao.dataEntradaSaida.toDateString()) >= new Date(this.movimentacaoControlador.movimentacao.data.toDateString())) {
        return true;
      }
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataSaidaMenorDataNegociacao));
      return false;
    }
    return true;
  }

  setEhValidoParcelaTabelaPreco(): boolean {
    let ehValido: boolean = true;
    const ARRENDONDAMENTO_ACEITAVEL: number = 0.04;
    if (this.movimentacaoControlador.financeiro != 'N' && this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro != 'S') {
      let movimentacaoProdutos: MovimentacaoProduto[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idTabelaPrecoConfiguracao);
      movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.tabelaPrecoConfiguracao.quantidadeDia) {
          let movimentacaoProdutoComMesmoPeriodos: MovimentacaoProduto[] = movimentacaoProdutos.filter((movimentacaoProdutoBusca) => new Date(format(Date.parse(movimentacaoProdutoBusca.tabelaPrecoConfiguracao.dataAte.toString()), 'yyyy-MM-dd')) <= new Date(format(Date.parse(movimentacaoProduto.tabelaPrecoConfiguracao.dataAte.toString()), 'yyyy-MM-dd')));
          let valorMovimentacaoProdutoAPagarPorDataAte: number = movimentacaoProdutoComMesmoPeriodos.reduce((a, b) => a + b.valorTotal, 0);
          let movimentacaoParcelas: MovimentacaoParcela[] = this.movimentacaoControlador.movimentacao.movimentacaoParcelas.filter((movimentacaoParcela) => new Date(format(Date.parse(movimentacaoParcela.dataVencimento.toString()), 'yyyy-MM-dd')) <= new Date(format(Date.parse(movimentacaoProduto.tabelaPrecoConfiguracao.dataAte.toString()), 'yyyy-MM-dd')));
          let valorMovimentacaoParcelas: number = movimentacaoParcelas.reduce((a, b) => a + b.getValorParcela(), 0);
          let diferenca: number = this.util.arredondar(valorMovimentacaoProdutoAPagarPorDataAte) - this.util.arredondar(valorMovimentacaoParcelas, 2);
          if (diferenca > ARRENDONDAMENTO_ACEITAVEL) {
            const valorASePagar: number = valorMovimentacaoParcelas + (this.util.arredondar(valorMovimentacaoProdutoAPagarPorDataAte) - this.util.arredondar(valorMovimentacaoParcelas, 2));
            this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.parcelamentoNaoCondizComTabelaPreco, [format(movimentacaoProduto.tabelaPrecoConfiguracao.dataAte, 'dd/MM/yyyy'), this.util.moeda(valorASePagar)])));
            ehValido = false;
          }
        } else {
          const tabelaDePrecoDataDe = new Date(format(Date.parse(movimentacaoProduto.tabelaPrecoConfiguracao.dataDe.toString()), 'yyyy-MM-dd'));
          const tabelaDePrecoDataAte = new Date(format(Date.parse(movimentacaoProduto.tabelaPrecoConfiguracao.dataAte.toString()), 'yyyy-MM-dd'));
          let movimentacaoParcelas: MovimentacaoParcela[] = this.movimentacaoControlador.movimentacao.movimentacaoParcelas.filter((movimentacaoParcela) => new Date(format(Date.parse(movimentacaoParcela.dataVencimento.toString()), 'yyyy-MM-dd')).getTime() >= tabelaDePrecoDataDe.getTime()).filter((movimentacaoParcela) => new Date(format(Date.parse(movimentacaoParcela.dataVencimento.toString()), 'yyyy-MM-dd')).getTime() <= tabelaDePrecoDataAte.getTime());
          let valorMovimentacaoParcelas: number = movimentacaoParcelas.reduce((a, b) => a + b.getValorParcela(), 0);
          let movimentacaoProdutosTabela: MovimentacaoProduto[] = movimentacaoProdutos.filter((movimentacaoProduto) => new Date(format(Date.parse(movimentacaoProduto.tabelaPrecoConfiguracao.dataDe.toString()), 'yyyy-MM-dd')).getTime() >= tabelaDePrecoDataDe.getTime()).filter((movimentacaoProduto) => new Date(format(Date.parse(movimentacaoProduto.tabelaPrecoConfiguracao.dataAte.toString()), 'yyyy-MM-dd')).getTime() <= tabelaDePrecoDataAte.getTime());
          let valorMovimentacaoProdutosTabela: number = movimentacaoProdutosTabela.reduce((a, b) => a + b.valorFinal, 0);
          let diferenca: number = this.util.arredondar(valorMovimentacaoProdutosTabela) - this.util.arredondar(valorMovimentacaoParcelas, 2);
          if (diferenca > ARRENDONDAMENTO_ACEITAVEL) {
            this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.produtoDeveSerPagoEntre, [movimentacaoProduto.produtoNumero, movimentacaoProduto.produto, this.util.moeda(valorMovimentacaoProdutosTabela), format(new Date(movimentacaoProduto.tabelaPrecoConfiguracao.dataDe), 'dd/MM/yy'), format(new Date(movimentacaoProduto.tabelaPrecoConfiguracao.dataAte), 'dd/MM/yy')])));
            ehValido = false;
          }
        }
      });
    }
    return ehValido;
  }

  setarArquivo(arquivoInput: any): void {
    this.utilImportacaoXml.retornarMovimentacao(arquivoInput, this.movimentacaoControlador).then((res) => {
      this.movimentacaoControlador = res;
      this.movimentacaoControlador.totalizar();
    });
  }

  setArquivoNaturezaOperacao(): void {
    alert(this.bibDialogo.selecione + ' ' + this.menuNaturezaOperacao.apelido.toLowerCase());
  }

  ehValidoNegociacaoParceiro(): boolean {
    if (this.movimentacaoControlador.usaNegociacao && this.movimentacaoControlador.movimentacao.negociacao && this.movimentacaoControlador.movimentacao.negociacao.aVista == '2' && this.movimentacaoControlador.movimentacao.parceiroTipoNegociacao == 'A' && !this.movimentacaoControlador.devolucao) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.negociacaoNaoPermitidaParaParceiro, [this.movimentacaoControlador.movimentacao.negociacao.nome])));
      return false;
    }
    return true;
  }

  ehValidoParceiroEstado(): boolean {
    if (!this.movimentacaoControlador.entradaServico && (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro)) {
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLoja);
      if (loja.idEstado != this.movimentacaoControlador.idEstadoParceiro) {
        if (confirm(this.util.substituir(this.bibDialogo.estadoParceiroDifereEstadoLoja, [this.movimentacaoControlador.movimentacao.parceiro, this.movimentacaoControlador.estadoParceiro ? this.movimentacaoControlador.estadoParceiro : this.bibDialogo.naoInformado.toLowerCase(), loja.estado]))) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  ehValidoParceiroCnpjCpf(): boolean {
    if (this.movimentacaoControlador.saida && this.movimentacaoControlador.nota && !this.movimentacaoControlador.movimentacao.parceiroCpfCnpj) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.parceiroNaoPossuiCnpjCpf, [this.movimentacaoControlador.movimentacao.parceiro, this.movimentacaoControlador.movimentacao.parceiroTipoPessoa == 'F' ? this.bibDialogo.cpf : this.bibDialogo.cnpj])));
      return false;
    }
    return true;
  }

  ehValidoPrazoNegociacaoDataVencimento(): boolean {
    if (this.movimentacaoControlador.usaNegociacao) {
      if (this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length > 0 && this.movimentacaoControlador.movimentacao.mediaDiaPrazo == 0 && this.movimentacaoControlador.movimentacao.negociacao && this.movimentacaoControlador.movimentacao.negociacao.aVista == '2') {
        if (confirm(this.bibDialogo.negociacaoAprazoCertezaVencimento)) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  ehValidoValorUnitarioMenorValorTabela(): boolean {
    if (this.movimentacaoControlador.orcamento || (this.movimentacaoControlador.pedido && this.movimentacaoControlador.saida)) {
      let ehValorUnitarioAcimaValorTabela: boolean = false;
      let mensagemProdutos: string = '';
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        let diferencaValorTabelaValorUnitario: number = movimentacaoProduto.valorUnitarioTabela - movimentacaoProduto.getValorUnitario();
        if (diferencaValorTabelaValorUnitario > 0.07) {
          mensagemProdutos += 'Item ' + movimentacaoProduto.ordem + ': ' + movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto + '\n';
          ehValorUnitarioAcimaValorTabela = true;
        }
      });
      if (ehValorUnitarioAcimaValorTabela) {
        if (confirm(this.util.substituir(this.bibDialogo.itemAbaixoValorUnitarioMenorTabela, [mensagemProdutos]))) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  ehValidoMovimentacaoProdutoQuantidadeValorUnitarioXml(): boolean {
    if (this.movimentacaoControlador.movimentacao.importacaoXml == 'S') {
      let mensagemProdutos: string[] = this.criarMensagemProdutosXmls();
      if (mensagemProdutos.length > 0) {
        if (confirm(this.util.substituir(this.bibDialogo.itemComValorXmlDiferente, [mensagemProdutos.join('\n')]))) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  ehValidoDesconto(): boolean {
    let ehValidoDesconto: boolean = true;
    let nomeProduto: string = '';
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (movimentacaoProduto.descontoPercentual < 0) {
        ehValidoDesconto = false;
        nomeProduto = movimentacaoProduto.produtoNome;
      }
    });
    if (!ehValidoDesconto) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.produtoComDescontoNegativo + ' ' + nomeProduto));
    }
    return ehValidoDesconto;
  }

  ehValidoInformacaoComplementar(): boolean {
    const regex = /•|—|…/gi;
    if (this.movimentacaoControlador.nota && this.movimentacaoControlador.movimentacao.informacaoComplementar && this.movimentacaoControlador.movimentacao.informacaoComplementar.match(regex) != null) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.caracteresDaInformacaoComplementar + ' ' + this.movimentacaoControlador.movimentacao.informacaoComplementar.match(regex)));
      return false;
    }
    return true;
  }

  ehValidoProdutoEQuantidade(): boolean {
    let totalMovimentacaoProduto: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.reduce((quantidadeInicial, item) => quantidadeInicial + item.getQuantidade(), 0);
    let totalMovimentacaoQuantidade: number = 0;
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      totalMovimentacaoQuantidade += movimentacaoProduto.movimentacaoQuantidades.reduce((quantidadeInicial, item) => quantidadeInicial + item.quantidade, 0);
    });
    return totalMovimentacaoProduto == totalMovimentacaoQuantidade;
  }

  criarMensagemProdutosXmls(): string[] {
    let mensagemProdutos: string[] = [];
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (this.util.arredondar(movimentacaoProduto.valorUnitarioXml) > 0 || this.util.arredondar(movimentacaoProduto.quantidadeXml) > 0) {
        if (this.util.arredondar(movimentacaoProduto.getValorUnitario()) != this.util.arredondar(movimentacaoProduto.valorUnitarioXml) && this.util.arredondar(movimentacaoProduto.getQuantidade()) != this.util.arredondar(movimentacaoProduto.quantidadeXml)) {
          mensagemProdutos.push(movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto + ': ' + this.bibDialogo.valorUnitarioInformado + '(' + movimentacaoProduto.getValorUnitario().toFixed(this.movimentacaoControlador.empresaRegraCasasDecimais) + ')' + ', no XML (' + movimentacaoProduto.valorUnitarioXml.toFixed(this.movimentacaoControlador.empresaRegraCasasDecimais) + '), ' + this.bibDialogo.quantidadeInformada + '(' + this.util.arredondar(movimentacaoProduto.getQuantidade()) + ')' + ', no XML (' + this.util.arredondar(movimentacaoProduto.quantidadeXml) + ') \n');
        } else if (this.util.arredondar(movimentacaoProduto.getValorUnitario()) != this.util.arredondar(movimentacaoProduto.valorUnitarioXml)) {
          mensagemProdutos.push(movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto + ': ' + this.bibDialogo.valorUnitarioInformado + '(' + movimentacaoProduto.getValorUnitario().toFixed(this.movimentacaoControlador.empresaRegraCasasDecimais) + ')' + ', no XML (' + movimentacaoProduto.valorUnitarioXml.toFixed(this.movimentacaoControlador.empresaRegraCasasDecimais) + ') \n');
        } else if (this.util.arredondar(movimentacaoProduto.getQuantidade()) != this.util.arredondar(movimentacaoProduto.quantidadeXml)) {
          mensagemProdutos.push(movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto + ': ' + this.bibDialogo.quantidadeInformada + '(' + this.util.arredondar(movimentacaoProduto.getQuantidade()) + ')' + ', no XML (' + this.util.arredondar(movimentacaoProduto.quantidadeXml) + ') \n');
        }
      }
    });
    return mensagemProdutos;
  }

  ehValidoMovimentacaoProdutoQuantidadeValorUnitarioZerado(): boolean {
    let mensagemProdutosZerados: string[] = [];
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    if (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) {
      if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
        this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
          if (this.util.arredondar(movimentacaoProduto.getValorUnitario()) <= 0) {
            mensagemProdutosZerados.push(movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto);
          }
        });
      }
    }
    if (mensagemProdutosZerados.length > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.itemMovimentacaProdutoZerado, [mensagemProdutosZerados.join('\n')])));
      return false;
    }
    return true;
  }

  ehValidoMovimentacaoNaturezaOperacaoTransferenciaImpostoPisCofins(): boolean {
    let contagemBaseValorPisCofinsMaiorZero: number = 0;
    if ((this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade == 2) {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.getPisBase() > 0 || movimentacaoProduto.getPisValor() > 0 || movimentacaoProduto.getCofinsBase() > 0 || movimentacaoProduto.getCofinsValor() > 0) {
          this.utilSessao.setResultado(new Resultado(false, this.criarMensagemPisCofinsMovimentacaoNaturezaOperacaoTransferencia(movimentacaoProduto)));
          contagemBaseValorPisCofinsMaiorZero++;
        }
      });
    }
    return contagemBaseValorPisCofinsMaiorZero > 0 ? false : true;
  }

  criarMensagemPisCofinsMovimentacaoNaturezaOperacaoTransferencia(movimentacaoProduto: MovimentacaoProduto): string {
    if ((movimentacaoProduto.getPisBase() > 0 || movimentacaoProduto.getPisValor() > 0) && (movimentacaoProduto.getCofinsBase() > 0 || movimentacaoProduto.getCofinsValor() > 0)) {
      return this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoComPisCofinsEmOperacaoTransferencia, [movimentacaoProduto.ordem + ' - ' + movimentacaoProduto.produto, this.bibDialogo.pis + ' e ' + this.bibDialogo.cofins]);
    } else if (movimentacaoProduto.getPisBase() > 0 || movimentacaoProduto.getPisValor() > 0) {
      return this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoComPisCofinsEmOperacaoTransferencia, [movimentacaoProduto.ordem + ' - ' + movimentacaoProduto.produto, this.bibDialogo.pis]);
    } else {
      return this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.produtoComPisCofinsEmOperacaoTransferencia, [movimentacaoProduto.ordem + ' - ' + movimentacaoProduto.produto, this.bibDialogo.cofins]);
    }
  }

  irNFE(): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 126)) {
      this.utilSessao.setIdentificacao(new Identificacao('idMovimentacao', this.movimentacaoControlador.movimentacao.id));
      const url: string = '#/documentoEletronico/nf-e';
      window.open(url, '_blank');
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.semPermissaoAcesso));
    }
  }

  irNFCE(): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 152)) {
      this.utilSessao.setIdentificacao(new Identificacao('idMovimentacao', this.movimentacaoControlador.movimentacao.id));
      const url: string = '#/documentoEletronico/nfc-e';
      window.open(url, '_blank');
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.semPermissaoAcesso));
    }
  }

  irSAT(): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 217)) {
      this.utilSessao.setIdentificacao(new Identificacao('idMovimentacao', this.movimentacaoControlador.movimentacao.id));
      const url: string = '#/documentoEletronico/sat';
      window.open(url, '_blank');
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.semPermissaoAcesso));
    }
  }

  irBoleto(): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 130)) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ABERTO', 'S'));
      criterios.push(new Criterio('ATIVO', 'S'));
      criterios.push(new Criterio('TIPO', 'R'));
      criterios.push(new Criterio('TIPO_TITULO_BOLETO', 'S'));
      criterios.push(new Criterio('ID_MOVIMENTACAO', this.movimentacaoControlador.movimentacao.id));
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiro).subscribe((res) => {
        const financeiros: Financeiro[] = this.plainToClass(Financeiro, res) as any;
        if (financeiros.length > 0) {
          this.utilSessao.setIdentificacao(new Identificacao('idMovimentacao', this.movimentacaoControlador.movimentacao.id));
          const url: string = '#/financeiroBoleto';
          window.open(url, '_blank');
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.negociacaoNaoConfiguradaGerarBoleto));
        }
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.semPermissaoAcesso));
    }
  }

  irReceituario(): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 143)) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ATIVO', 'S'));
      criterios.push(new Criterio('STATUS', 'S'));
      criterios.push(new Criterio('PRODUTO_FORMULADO', 'S'));
      criterios.push(new Criterio('ENTRADA_SAIDA_INTERNA', -1));
      criterios.push(new Criterio('ID', this.movimentacaoControlador.movimentacao.id));
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacao).subscribe((res) => {
        const movimentacao: Movimentacao[] = this.plainToClass(Financeiro, res) as any;
        if (movimentacao.length > 0) {
          this.utilSessao.setIdentificacao(new Identificacao('id', this.movimentacaoControlador.movimentacao.id));
          const url: string = '#/receituario/Frm';
          window.open(url, '_blank');
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.produtoNaoConfiguradaGerarReceituarioEletronico));
        }
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.semPermissaoAcesso));
    }
  }

  irAcessoRapido(legenda: string) {
    switch (legenda) {
      case this.bibDialogo.nfe:
        this.irNFE();
        break;

      case this.bibDialogo.nfce:
        this.irNFCE();
        break;

      case this.bibDialogo.sat:
        this.irSAT();
        break;

      case this.bibDialogo.boleto:
        this.irBoleto();
        break;

      case this.bibDialogo.receituario:
        this.irReceituario();
        break;

      case this.bibDialogo.fichaEmergencia:
        this.abrirFichaEmergencia();
    }
  }

  abrirFichaEmergencia(): void {
    const produtoFormuladoIds: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.fichaEmergencia == 'S').map((movimentacaoProduto) => movimentacaoProduto.idProdutoFormulado);
    produtoFormuladoIds.forEach((id) => {
      window.open('https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/arquivos/fichaemergencia/' + id + '.pdf');
    })
  }

  listarMovimentacaoOrigem(): Observable<any> {
    if (this.movimentacaoControlador.identificacao == 4) {
      const idMovimentacaoOrigens: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacaoProOrigem != null).map((movimentacaoProduto) => movimentacaoProduto.idMovimentacaoProOrigem);
      if (idMovimentacaoOrigens.length > 0) {
        let idOperacao: string = '2, 20';
        if (!this.movimentacaoControlador.saida) {
          idOperacao = '7';
        }
        const criterios: Criterio[] = [new Criterio('ID_MOVIMENTACAO_ORIGEM', idMovimentacaoOrigens.toString()), new Criterio('ID_OPERACAO', idOperacao)];
        return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacao);
      }
    }
    return of({});
  }

  listarMovimentacaoProOrigem(movimentacoes: Movimentacao[]): Observable<any> {
    if (movimentacoes.length > 0) {
      const idsMovimentacoes: number[] = movimentacoes.map((movimentacao) => movimentacao.id);
      if (idsMovimentacoes.length > 0) {
        return this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACOES', idsMovimentacoes.toString())), this.bibServico.movimentacaoProduto);
      }
    }
    return of({});
  }

  listarNegociacoesMovimentacaoProOrigem(movimentacoes: Movimentacao[]): Observable<any> {
    if (movimentacoes.length > 0) {
      const idsNegociacoes: number[] = movimentacoes.filter((movimentacao) => movimentacao.idNegociacao != null).map((movimentacao) => movimentacao.idNegociacao);
      if (idsNegociacoes.length > 0) {
        return this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idsNegociacoes.toString())), this.bibServico.negociacao);
      }
    }
    return of({});
  }

  listarConfiguracaoMovimentacaoContabil(): Observable<any> {
    return this.movimentacaoControlador.listarOperacaoContabil(this.movimentacaoControlador.movimentacao.idOperacao);
  }

  ehValidoParcelaMovimentacao(): boolean {
    const FINALIDADE_TRANSFERENCIA: number = 2;
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    const valorTotalMaiorZero: boolean = this.movimentacaoControlador.movimentacao.valorTotal - this.movimentacaoControlador.movimentacao.valorBloqueado > 0;
    if ((!this.movimentacaoControlador.nota && valorTotalMaiorZero && this.movimentacaoControlador.movimentacao.financeiro == 'S' && this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 0) || (this.movimentacaoControlador.nota && this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 0 && ((this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_TRANSFERENCIA && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != FINALIDADE_AJUSTE && this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro != 'S') || (this.movimentacaoControlador.entrada && valorTotalMaiorZero)))) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoGerouParcelas));
      return false;
    }
    if (this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length > 0 && this.movimentacaoControlador.movimentacao.especie != 'S' && (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != 2 || this.movimentacaoControlador.entrada)) {
      let parcela: number = 0;
      let parcelaFinanceiro: number = 0;
      let parcelaBloqueado: number = 0;
      for (let movimentacaoParcela of this.movimentacaoControlador.movimentacao.movimentacaoParcelas) {
        parcela += movimentacaoParcela.getValorParcela();
        parcelaBloqueado += movimentacaoParcela.getValorBloqueado();
        parcelaFinanceiro = parcela - parcelaBloqueado;
      }
      if (this.movimentacaoControlador.util.arredondar(parcela) != this.movimentacaoControlador.util.arredondar(this.movimentacaoControlador.movimentacao.valorTotal) || this.movimentacaoControlador.diferencaParcelaTotal != 0) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.parcelaDiferenteMovimentacao, [this.movimentacaoControlador.util.moeda(this.movimentacaoControlador.movimentacao.valorTotal)])));
        return false;
      }
      if (this.movimentacaoControlador.util.arredondar(parcelaBloqueado) != this.movimentacaoControlador.util.arredondar(this.movimentacaoControlador.movimentacao.valorBloqueado) || this.movimentacaoControlador.util.arredondar(parcelaFinanceiro) != this.movimentacaoControlador.util.arredondar(this.movimentacaoControlador.movimentacao.valorFinanceiro)) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.parcelaDiferenteMovimentacaoValorBloqueado));
        return false;
      }
    }
    return true;
  }
}
