import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { Util } from 'src/app/utilitario/util';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { Formulario } from '../../formulario';

@Component({
  selector: 'pesquisaTextoBusca',
  templateUrl: './pesquisaTextoBusca.component.html',
  styleUrls: ['./pesquisaTextoBusca.component.css'],
})
export class PesquisaTextoBuscaComponent extends Formulario {
  @ViewChild('listaBusca') private listaBusca: ElementRef;
  @Input() criterios: Criterio[] = [];
  @Input() icone: string = 'fa fa-search';
  @Input() minimoCaracterBusca: number = 0;
  @Input() servico: string;
  @Output() enterEmt = new EventEmitter<boolean>();
  @Output() espacoEmt = new EventEmitter<boolean>();
  @Output() setaBaixoEmt = new EventEmitter<boolean>();
  @Output() setaCimaEmt = new EventEmitter<boolean>();
  private campoInicial: string;
  private util: Util = new Util();
  public carregando: boolean = false;
  public escolhendo: boolean = false;
  public focado: boolean = false;
  public focoBtnAdicional: boolean = false;
  public lista: any[] = [];
  public objeto: any = null;
  public posicao: number = 0;

  constructor(private comunicacaoService: ComunicacaoService, public utilNotificacao: UtilNotificacao) {
    super();
  }

  @Input()
  set campo(campo: any) {
    this.campoInicial = campo;
    this.objeto = null;
    this.iniciar(campo);
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (this.focado) {
      switch (event.keyCode) {
        case bibAtalho.enter:
          event.preventDefault();
          this.ehEnter();
          break;

        case bibAtalho.setaCima:
          event.preventDefault();
          this.marcarLista(-1);
          break;

        case bibAtalho.setaBaixo:
          event.preventDefault();
          this.marcarLista(1);
          break;
        default:
          this.lista = [];
          break;
      }
    }
  }

  marcarLista(incremento: number): void {
    this.posicao = this.util.marcarLista(this.posicao, incremento, this.lista);
    const scroll: number = 46.39 * incremento;
    if (this.listaBusca) {
      this.listaBusca.nativeElement.scrollTop += scroll;
    }
  }

  ehEnter(): void {
    if (this.lista.length > 0) {
      this.selecionar();
    } else {
      this.buscar();
    }
  }

  alterarTexto(textoAlterado: string): void {
    this.campoAtual = textoAlterado.toUpperCase();
    this.alterar(this.campoAtual);
  }

  buscar(): void {
    if (!this.desabilitado) {
      if (this.ehQuantidadeMimina()) {
        this.focado = true;
        this.focoId = Math.random();
        this.posicao = 0;
        this.carregando = true;
        this.comunicacaoService.listar(new Transporte(this.filtrar()), this.servico).subscribe((res) => {
          this.lista = res as any[];
          this.carregando = false;
          this.selecionarAutomatico();
        });
      }
    }
  }

  filtrar(): Criterio[] {
    this.criterios = this.criterios.filter((criterio) => criterio.nome != 'NOME' && criterio.nome != 'ATIVO' && criterio.nome != 'CLIENTE' && criterio.nome != 'FORNECEDOR' && criterio.nome != 'TRANSPORTADORA' && criterio.nome != 'COLABORADOR');
    this.criterios.push(new Criterio('ATIVO', 'S'));
    if (Number(this.campoAtual)) {
      this.criterios.push(new Criterio('NUMERO', this.campoAtual.replace(/[^0-9]/g, '')));
    } else {
      this.criterios.push(new Criterio('NOME', this.removerCaracter(this.campoAtual ? this.campoAtual.toUpperCase() : '')));
    }
    return this.criterios;
  }

  ehQuantidadeMimina(): boolean {
    if (this.campoAtual) {
      const caracterBuscar: string = this.removerCaracter(this.campoAtual);
      if (caracterBuscar.length >= this.minimoCaracterBusca) {
        return true;
      }
    } else if ((this.campoAtual == null || this.campoAtual.length == 0) && this.minimoCaracterBusca == 0) {
      return true;
    }
    return false;
  }

  removerCaracter(caracter: string): string {
    let caracterBusca: string = caracter;
    if (caracterBusca.length > 0) {
      while (caracterBusca && caracterBusca.indexOf('%%') >= 0) {
        caracterBusca = caracterBusca.replace('%%', '%');
      }
      return caracterBusca ? caracterBusca.trim() : caracterBusca;
    }
    this.campoAtual = '';
    return '';
  }

  clicarBtnAdicional(): void {
    this.buscar();
    super.clicarBtnAdicional();
  }

  definirNome(): void {
    if (this.objeto) {
      this.campoAtual = this.objeto.razaoSocial || this.objeto.nome;
      this.objeto = this.objeto.razaoSocial || this.objeto.nome;
    } else {
      this.campoAtual = this.campoInicial;
    }
  }

  ehEscolhendo(escolhendo: boolean, posicao: number): void {
    this.posicao = posicao;
    this.escolhendo = escolhendo;
  }

  limpar(): void {
    this.objeto = null;
    this.campoInicial = '';
    this.definirNome();
    this.preenchido = false;
    this.emitirAlteracao(new Object());
  }

  sairFocoBotao(): void {
    this.focoBtnAdicional = false;
    if (!this.focado) {
      this.navegar(false);
    }
  }

  navegar(focado: boolean): void {
    this.focado = focado;
    if (!this.focoBtnAdicional) {
      this.ocultarLista();
      super.navegar(focado);
    }
  }

  selecionar(): void {
    this.objeto = this.lista[this.posicao];
    this.limparLista(this.lista);
    this.definirNome();
    this.preenchido = true;
    this.emitirAlteracao(this.objeto);
  }

  limparLista(lista: any[]): void {
    while (lista.length > 0) {
      lista.splice(0, 1);
    }
  }

  selecionarAutomatico(): void {
    if (this.lista.length == 1) {
      this.selecionar();
    }
  }

  ocultarLista(): void {
    if (!this.escolhendo) {
      this.limparLista(this.lista);
      this.definirNome();
    }
  }
}
