<layout [titulo]="bibDialogo.telefone" [id]="parceiroTelefone.id">
  <div class="row">
    <mascara class="col-sm" [id]="'telefone'" [rotulo]="bibDialogo.telefone" [campo]="parceiroTelefone.telefone" (alteracao)="parceiroTelefone.telefone = $event" [tipo]="bibPropriedade.mascara.telefone" [obrigatorio]="true" [foco]="true" [focoId]="focoId"></mascara>
    <lista class="col-sm" [id]="'tipoDeTelefone'" [rotulo]="bibDialogo.tipoDeTelefone" [campo]="parceiroTelefone.idVinculo" (alteracao)="setVinculo($event.id)" [lista]="vinculos" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="listarVinculo()" [idMenu]="permissaoVinculo && permissaoVinculo.inserir == 'S' ? menuVinculo.idMenu : null"></lista>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="parceiroTelefone.ativo" (alteracao)="parceiroTelefone.ativo = $event"> </interruptor>
  </div>
  <div class="row">
    <texto class="col-sm-12" [id]="'observacao'" [rotulo]="bibDialogo.observacao" [campo]="parceiroTelefone.observacao" (alteracao)="parceiroTelefone.observacao = $event" [maximoCaracteres]="100"></texto>
  </div>
</layout>
<botao [id]="'adicionar'" [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirParceiroTelefone()" (saindo)="focoId = $event"></botao>
<auditoria *ngIf="parceiroTelefone.id" [idUsuarioInclusao]="parceiroTelefone.idUsuarioInclusao" [dataHoraInclusao]="parceiroTelefone.dataHoraInclusao" [idUsuarioAlteracao]="parceiroTelefone.idUsuarioAlteracao" [dataHoraAlteracao]="parceiroTelefone.dataHoraAlteracao"></auditoria>
