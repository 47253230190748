import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { Lote } from 'src/app/agrow/modelo/lote';
import { ProdutoUnidade } from 'src/app/agrow/modelo/produtoUnidade';
import bibServico from 'src/app/biblioteca/bibServico';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { NaturezaOperacao } from 'src/app/modelo/naturezaOperacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoParceiro } from 'src/app/modelo/produtoParceiro';
import { ProdutoPisCofins } from 'src/app/modelo/produtoPisCofins';
import { Transporte } from 'src/app/modelo/transporte';
import { UnidadeParceiro } from 'src/app/modelo/unidadeParceiro';
import { Usuario } from 'src/app/modelo/usuario';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { UtilMovimentacaoImpostos } from 'src/app/utilitario/utilMovimentacaoImpostos';

export class UtilImportacaoXml {
  private produtos: Produto[] = [];
  private util: Util = new Util();
  public bibServico = bibServico;
  public cfopEmpresa: CfopEmpresa[] = [];
  public cstIcms: Cst[] = [];
  public cstIpi: Cst[] = [];
  public cstPisCofins: Cst[] = [];
  public estados: Estado[];
  public naturezaOperacao: NaturezaOperacao[];
  public parceiro: Parceiro;
  public simplesNacional: String = 'N';
  public plainToClass: Function = plainToClass;
  public produtoParceiros: ProdutoParceiro[];
  public produtoUnidades: ProdutoUnidade[] = [];
  public unidadeParceiros: UnidadeParceiro[] = [];
  public utilSessao: UtilSessao = new UtilSessao();
  public utilMovimentacaoImpostos: UtilMovimentacaoImpostos;

  constructor(public comunicacaoService: ComunicacaoService, casasDecimais: number) {
    this.listarEstado();
    this.listarCstIcms();
    this.listarCstIpi();
    this.listarCfopEmpresa();
    this.listarCstPisCofins();
    this.utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, casasDecimais);

  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.estado).subscribe((res) => {
      res.forEach((estado) => {
        estado.nome = estado.abreviacao;
      });
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  listarCstIcms(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.cstIcms).subscribe((res) => {
      this.cstIcms = this.plainToClass(Cst, res) as any;
    });
  }

  listarCstPisCofins(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ENTRADA_SAIDA', 'E')), this.bibServico.cstPisCofins).subscribe((res) => {
      this.cstPisCofins = this.plainToClass(Cst, res) as any;
    });
  }

  listarCstIpi(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.cstIpi).subscribe((res) => {
      this.cstIpi = this.plainToClass(Cst, res) as any;
    });
  }

  listarCfopEmpresa(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.cfopEmpresa).subscribe((res) => {
      this.cfopEmpresa = this.plainToClass(CfopEmpresa, res) as any;
    });
  }

  retornarMovimentacao(arquivoInput: any, movimentacaoControlador: MovimentacaoControlador): Promise<any> {
    return new Promise((resolve) => {
      this.utilSessao.iniciarProcesso();
      let readXml = null;
      let reader = new FileReader();
      reader.onload = () => {
        readXml = reader.result;
        let parser = new DOMParser();
        let doc = parser.parseFromString(readXml, 'application/xml');
        this.lerDadosNfe(doc, movimentacaoControlador);
        this.listarLoja(doc, movimentacaoControlador).subscribe(() => {
          this.listarParceiro(doc, movimentacaoControlador, 'FORNECEDOR').subscribe(() => {
            this.listarParceiro(doc, movimentacaoControlador, 'TRANSPORTADORA').subscribe(() => {
              this.listarProdutoVinculadoProdutoParceiro(doc.querySelectorAll('det'), movimentacaoControlador).then(() => {
                this.lerTotal(doc.querySelector('total'), movimentacaoControlador);
                movimentacaoControlador.movimentacao.movimentacaoProdutos = this.lerProdutos(doc.querySelectorAll('det'), movimentacaoControlador);
                movimentacaoControlador.movimentacao.movimentacaoParcelas = this.lerParcelas(doc.querySelectorAll('dup'));
                movimentacaoControlador.movimentacao.importacaoXml = 'S';
                movimentacaoControlador.movimentacao.idClassificacao = null;
                this.verificarImpostos(movimentacaoControlador);
                for (const movimentacaoProduto of movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idProduto && movimentacaoProduto.loteXml != null)) {
                  movimentacaoControlador.listarPersistirLoteXml(movimentacaoProduto);
                }
                this.utilSessao.finalizarProcesso();
                resolve(movimentacaoControlador);
              });
            });
          });
        });
      };
      reader.readAsText(arquivoInput.target.files[0]);
    });
  }

  listarLoja(doc: any, movimentacaoControlador: MovimentacaoControlador): Observable<any> {
    let loja = doc.getElementsByTagName('dest')[0].childNodes;
    if (loja[0] && loja[0].childNodes[0]) {
      const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte(new Criterio('CNPJ', this.utilSessao.mascaraCnpj(loja[0].childNodes[0].nodeValue))), this.bibServico.loja);
      resposta.subscribe((res) => {
        const loja: Loja = this.plainToClass(Loja, res[0]) as any;
        if (loja) {
          this.simplesNacional = loja.enquadramento == 1 ? 'S' : 'N';
          movimentacaoControlador.movimentacao.idLoja = loja.id;
          movimentacaoControlador.movimentacao.abreviacao = loja.abreviacao;
          movimentacaoControlador.movimentacao.limiteCustoReposicao = loja.regraVariacaoCusto ? loja.regraVariacaoCusto : 0;
          this.ehAtualizarColaborador(movimentacaoControlador.movimentacao);
        }
      });
      return resposta;
    } else {
      return of(null);
    }
  }

  private ehAtualizarColaborador(movimentacao: Movimentacao): void {
    if (this.utilSessao.getLojas().length > 1) {
      let usuario: Usuario = this.utilSessao.getUsuario();
      if (usuario.idColaborador && usuario.colaborador) {
        movimentacao.idColaborador = usuario.idColaborador;
        movimentacao.colaborador = usuario.colaborador;
      } else {
        movimentacao.idColaborador = null;
        movimentacao.colaborador = null;
      }
    }
  }

  listarParceiro(doc, movimentacaoControlador: MovimentacaoControlador, tipoParceiro: string): Observable<any> {
    let parceiroXml: any = doc.getElementsByTagName(tipoParceiro == 'FORNECEDOR' ? 'emit' : 'transp')[0].childNodes;
    const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte([new Criterio('CNPJ_CPF', this.utilSessao.mascaraCnpj(parceiroXml[0].childNodes[0].nodeValue)), new Criterio(tipoParceiro, 'S')]), this.bibServico.parceiro);
    resposta.subscribe((res) => {
      const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
      if (tipoParceiro == 'FORNECEDOR' && parceiro) {
        movimentacaoControlador.movimentacao.idParceiro = parceiro.id;
        movimentacaoControlador.movimentacao.nomeParceiro = parceiro.numeroNomeParceiro;
        movimentacaoControlador.movimentacao.parceiro = parceiro.numeroNomeParceiro;
      }
      if (tipoParceiro == 'TRANSPORTADOR' && parceiro) {
        movimentacaoControlador.movimentacao.idTransportadora = parceiro.id;
        movimentacaoControlador.movimentacao.transportadora = parceiro[1].childNodes[0].nodeValue;
        movimentacaoControlador.movimentacao.transportadoraUfPlaca = parceiro[5] ? parceiro[5].childNodes[0].nodeValue : null;
        let estado: Estado = this.estados.find((filtro) => filtro.abreviacao == movimentacaoControlador.movimentacao.transportadoraUfPlaca);
        if (estado) {
          movimentacaoControlador.movimentacao.idEstadoTransportadora = estado.id;
        }
      }
      this.lerDadosTransportadora(doc, movimentacaoControlador);
    });
    return resposta;
  }

  lerDadosTransportadora(doc: any, movimentacaoControlador: MovimentacaoControlador): void {
    movimentacaoControlador.movimentacao.freteTipo = Number(this.lerTag(doc, 'modFrete')) == 0 ? 100 : Number(this.lerTag(doc, 'modFrete'));
    movimentacaoControlador.movimentacao.pesoLiquido = Number(this.lerTag(doc, 'pesoL'));
    movimentacaoControlador.movimentacao.pesoBruto = Number(this.lerTag(doc, 'pesoB'));
    movimentacaoControlador.movimentacao.transportadoraQuantidade = Number(this.lerTag(doc, 'qVol'));
    movimentacaoControlador.movimentacao.transportadoraEspecie = this.lerTag(doc, 'esp');
    movimentacaoControlador.movimentacao.transportadoraMarca = this.lerTag(doc, 'marca');
    movimentacaoControlador.movimentacao.transportadoraPlaca = this.lerTag(doc, 'placa');
    movimentacaoControlador.movimentacao.transportadoraUfPlaca = this.lerTag(doc, 'UF');
  }

  lerTotal(doc: any, movimentacaoControlador: MovimentacaoControlador): void {
    movimentacaoControlador.movimentacao.valorTotal = Number(this.lerTag(doc, 'vNF'));
    movimentacaoControlador.movimentacao.valorTotalProduto = Number(this.lerTag(doc, 'vProd'));
    movimentacaoControlador.movimentacao.valorTotalProdutoBruto = Number(this.lerTag(doc, 'vProd'));
    movimentacaoControlador.movimentacao.freteValor = Number(this.lerTag(doc, 'vFrete'));
    movimentacaoControlador.movimentacao.seguroValor = Number(this.lerTag(doc, 'vSeg'));
    movimentacaoControlador.movimentacao.despesaValor = Number(this.lerTag(doc, 'vOutro'));
  }

  lerDadosNfe(doc: any, movimentacaoControlador: MovimentacaoControlador): void {
    movimentacaoControlador.movimentacao.data = new Date(this.util.formatarData(this.lerTag(doc, 'dhEmi')) + 'T00:00:00-03:00');
    movimentacaoControlador.movimentacao.numero = Number(this.lerTag(doc, 'nNF'));
    movimentacaoControlador.movimentacao.documentoFiscal = Number(this.lerTag(doc, 'nNF'));
    movimentacaoControlador.movimentacao.modelo = this.lerTag(doc, 'mod');
    movimentacaoControlador.movimentacao.serie = this.lerTag(doc, 'serie');
    movimentacaoControlador.movimentacao.chaveFiscal = this.lerTag(doc, 'chNFe');
    movimentacaoControlador.movimentacao.informacaoComplementar = this.lerTag(doc, 'infCpl');
    movimentacaoControlador.movimentacao.emitente = 1;
  }

  lerParcelas(parcelas: any): MovimentacaoParcela[] {
    let movimentacaoParcelas: MovimentacaoParcela[] = [];
    parcelas.forEach((par) => {
      let movimentacaoParcela: MovimentacaoParcela = new MovimentacaoParcela();
      movimentacaoParcela.dataVencimento = new Date(this.lerTag(par, 'dVenc') + 'T12:00');
      movimentacaoParcela.setValorParcela(Number(this.lerTag(par, 'vDup')));
      movimentacaoParcelas.push(movimentacaoParcela);
    });
    return movimentacaoParcelas;
  }

  listarProdutoVinculadoProdutoParceiro(produtos: any, movimentacaoControlador: MovimentacaoControlador): Promise<any> {
    return new Promise((resolve) => {
      const xmlProdutoNumeros: any[] = [];
      produtos.forEach((produto) => {
        xmlProdutoNumeros.push("'" + produto.getElementsByTagName('cProd')[0].childNodes[0].nodeValue + "'");
      });
      if (xmlProdutoNumeros.length > 0 && xmlProdutoNumeros[0] != undefined) {
        this.comunicacaoService.listar(new Transporte([new Criterio('NUMEROS', xmlProdutoNumeros.toString()), new Criterio('ID_PARCEIRO', movimentacaoControlador.movimentacao.idParceiro)]), this.bibServico.produtoParceiro).subscribe((res) => {
          this.produtoParceiros = this.plainToClass(ProdutoParceiro, res) as any;
          const idProdutos: number[] = this.produtoParceiros.map((produtoParceiro) => produtoParceiro.idProduto);
          if (idProdutos.length > 0) {
            this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idProdutos.toString())), this.bibServico.produto).subscribe((res) => {
              this.produtos = this.plainToClass(Produto, res) as any;
              this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', movimentacaoControlador.movimentacao.idParceiro)), bibServico.unidadeParceiro).subscribe((res) => {
                this.unidadeParceiros = this.plainToClass(UnidadeParceiro, res);
                resolve(null);
              });
            });
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  lerProdutos(produtos: any, movimentacaoControlador: MovimentacaoControlador): MovimentacaoProduto[] {
    let movimentacaoProdutos: MovimentacaoProduto[] = [];
    produtos.forEach((prod) => {
      let movimentacaoProduto: MovimentacaoProduto = new MovimentacaoProduto();
      this.lerProduto(prod.getElementsByTagName('prod')[0], prod.getElementsByTagName('rastro')[0], movimentacaoProduto, movimentacaoControlador);
      this.lerIcms(prod.getElementsByTagName('ICMS')[0], movimentacaoProduto);
      this.lerIpi(prod.getElementsByTagName('IPI')[0], movimentacaoProduto);
      movimentacaoProduto.importacaoXml = 'S';
      movimentacaoControlador.adicionarMovimentacaoQuantidade(movimentacaoProduto, null);
      movimentacaoProduto.definirRegraEstoque(movimentacaoControlador.estoqueComprado, movimentacaoControlador.estoqueFisico, movimentacaoControlador.estoqueReservado, movimentacaoControlador.estoqueTransferido, movimentacaoControlador.estoqueNFe, movimentacaoControlador.estoqueCarteira);
      movimentacaoProdutos.push(movimentacaoProduto);
    });
    return movimentacaoProdutos;
  }

  lerProduto(produto: any, rastro: any, movimentacaoProduto: MovimentacaoProduto, movimentacaoControlador: MovimentacaoControlador): MovimentacaoProduto {
    let numeroProduto = produto.getElementsByTagName('cProd')[0].childNodes[0].nodeValue;
    let produtoDescontoValor = produto.getElementsByTagName('vDesc')[0] ? produto.getElementsByTagName('vDesc')[0].childNodes[0].nodeValue : 0;
    movimentacaoProduto.numeroProdutoNfe = produto.getElementsByTagName('cProd')[0].childNodes[0].nodeValue;
    movimentacaoProduto.produtoNfe = this.lerTag(produto, 'xProd');
    movimentacaoProduto.quantidadeXml = Number(this.lerTag(produto, 'qCom'));
    movimentacaoProduto.valorUnitarioXml = Number(this.lerTag(produto, 'vUnCom'));
    movimentacaoProduto.setQuantidade(Number(this.lerTag(produto, 'qCom')), movimentacaoControlador.movimentacao.negociacao, movimentacaoControlador.movimentacao.mediaDiaPrazo, movimentacaoControlador.movimentacao.movimentacaoParcelas, movimentacaoControlador.movimentacao.valorTotal, false);
    movimentacaoProduto.setValorUnitario(Number(this.lerTag(produto, 'vUnCom')) - movimentacaoProduto.descontoValorUnitario, movimentacaoControlador.movimentacao.negociacao, movimentacaoControlador.movimentacao.mediaDiaPrazo, movimentacaoControlador.movimentacao.movimentacaoParcelas, movimentacaoControlador.movimentacao.valorTotal, false, 0, movimentacaoControlador.entrada ? movimentacaoControlador.empresaRegraCasasDecimaisEntrada : movimentacaoControlador.empresaRegraCasasDecimais);
    movimentacaoProduto.unidadeXml = this.lerTag(produto, 'uCom');
    if (movimentacaoControlador.usaDescontoUnitario) {
      movimentacaoProduto.setDescontoValorUnitario(this.util.arredondar(produtoDescontoValor / movimentacaoProduto.quantidadeXml, 4), movimentacaoControlador.movimentacao.negociacao, movimentacaoControlador.movimentacao.movimentacaoParcelas, movimentacaoControlador.movimentacao.valorTotal, false);
    } else {
      movimentacaoControlador.setDescontoValor(movimentacaoProduto, produtoDescontoValor);
    }
    movimentacaoProduto.valorUnitarioXml = Number(this.lerTag(produto, 'vUnCom'));
    movimentacaoProduto.ncmProdutoXml = this.lerTag(produto, 'NCM');
    movimentacaoProduto.setQuantidade(Number(this.lerTag(produto, 'qCom')), movimentacaoControlador.movimentacao.negociacao, movimentacaoControlador.movimentacao.mediaDiaPrazo, movimentacaoControlador.movimentacao.movimentacaoParcelas, movimentacaoControlador.movimentacao.valorTotal, false);
    movimentacaoProduto.setValorUnitario(Number(this.lerTag(produto, 'vUnCom')) - movimentacaoProduto.descontoValorUnitario, movimentacaoControlador.movimentacao.negociacao, movimentacaoControlador.movimentacao.mediaDiaPrazo, movimentacaoControlador.movimentacao.movimentacaoParcelas, movimentacaoControlador.movimentacao.valorTotal, false, 0, movimentacaoControlador.entrada ? movimentacaoControlador.empresaRegraCasasDecimaisEntrada : movimentacaoControlador.empresaRegraCasasDecimais);
    movimentacaoProduto.unidadeXml = this.lerTag(produto, 'uCom');
    let produtoParceiro: ProdutoParceiro = this.produtoParceiros.find((produtoParceiroBusca) => produtoParceiroBusca.numero == numeroProduto && produtoParceiroBusca.idParceiro == movimentacaoControlador.movimentacao.idParceiro);
    if (produtoParceiro) {
      const produtoBusca: Produto = this.produtos.find((produto) => produto.id == produtoParceiro.idProduto);
      if (produtoBusca) {
        movimentacaoProduto.abreviacao = produtoBusca.abreviacao;
        movimentacaoProduto.idUnidade = produtoBusca.idUnidade;
        movimentacaoProduto.idProdutoParceiro = produtoParceiro.id;
        movimentacaoProduto.idProduto = produtoBusca.id;
        movimentacaoProduto.produto = produtoBusca.nome;
        movimentacaoProduto.produtoNome = produtoBusca.nome;
        movimentacaoProduto.produtoNumero = produtoBusca.numero;
        movimentacaoProduto.idUnidade = produtoBusca.idUnidade;
        movimentacaoProduto.idUnidadePadrao = produtoBusca.idUnidade;
        movimentacaoProduto.custoGerencial = produtoBusca.custoGerencial;
        movimentacaoProduto.custoNfe = produtoBusca.custoNfe;
        movimentacaoProduto.custoReposicao = produtoBusca.custoReposicao;
        movimentacaoProduto.valorUnitarioTabela = 0;
        movimentacaoProduto.descontoPorValor = true;
        movimentacaoProduto.loteXml = this.lerLoteXml(movimentacaoProduto, produtoBusca.usaLote, movimentacaoProduto.ncmProdutoXml, rastro);
      }
    } else {
      movimentacaoProduto.loteXml = this.lerLoteXml(movimentacaoProduto, 'S', movimentacaoProduto.ncmProdutoXml, rastro);
    }
    const numeroCfopNota = produto.getElementsByTagName('CFOP')[0].childNodes[0].nodeValue;
    const cfopSaida: CfopEmpresa = this.cfopEmpresa.find((cfopSaida) => cfopSaida.idCfop == numeroCfopNota);
    if (cfopSaida) {
      movimentacaoProduto.numeroCfopEmpresa = numeroCfopNota;
      movimentacaoProduto.cfopSaidaNfe = cfopSaida.nome;
      movimentacaoProduto.cfopSaida = true;
      if (cfopSaida.idCfopContrapartida) {
        movimentacaoProduto.cfopEntrada = true;
        const cfopEntrada: CfopEmpresa = this.cfopEmpresa.find((cfopEntrada) => cfopEntrada.idCfop == cfopSaida.idCfopContrapartida);
        movimentacaoProduto.idCfopEmpresa = cfopEntrada ? cfopEntrada.id : null;
        if (movimentacaoProduto.idCfopEmpresa) {
          movimentacaoProduto.setCfopEmpresa(cfopEntrada, true, 'N', 'N', 'N', movimentacaoControlador.financeiro, movimentacaoControlador.movimentacao.idOperacao);
        }
      }
    }
    return movimentacaoProduto;
  }

  lerLoteXml(movimentacaoProduto: MovimentacaoProduto, usaLote: String, ncm: string, rastro: any): Lote {
    const ncmMedicamento: boolean = this.ehNcmMedicamento(ncm);
    if (rastro && (usaLote == 'S' || ncmMedicamento)) {
      const nome: string = rastro.getElementsByTagName('nLote')[0].childNodes[0].nodeValue;
      const dataFabricacao: Date = new Date(this.util.formatarData(this.lerTag(rastro, 'dFab')) + 'T00:00:00-03:00');
      const dataValidade: Date = new Date(this.util.formatarData(this.lerTag(rastro, 'dVal')) + 'T00:00:00-03:00');
      return this.montarLote(nome, dataFabricacao, dataValidade, movimentacaoProduto.idProduto);
    }
    return null;
  }

  ehNcmMedicamento(ncm: string) {
    const inicioNcm: string = ncm.substring(0, 4);
    return (inicioNcm == "3001" || inicioNcm == "3002" || inicioNcm == "3003" || inicioNcm == "3004" || inicioNcm == "3006" || inicioNcm == "3006");
  }

  montarLote(nome: string, dataFabricacao: Date, dataValidade: Date, idProduto: number): Lote {
    const lote: Lote = new Lote();
    lote.nome = nome;
    lote.registro = nome;
    lote.dataFabricacao = dataFabricacao;
    lote.dataValidade = dataValidade;
    lote.ativo = 'S';
    lote.idProduto = idProduto;
    return lote;
  }

  lerIpi(ipi: any, movimentacaoProduto: MovimentacaoProduto): MovimentacaoProduto {
    movimentacaoProduto.setIpiBase(Number(this.lerTag(ipi, 'vBC')));
    movimentacaoProduto.setIpiAliquota(Number(this.lerTag(ipi, 'pIPI')));
    movimentacaoProduto.setIpiValor(this.lerTag(ipi, 'vIPI'));
    return movimentacaoProduto;
  }

  lerIcms(icms: any, movimentacaoProduto: MovimentacaoProduto): MovimentacaoProduto {
    let numeroCstIcms: string = this.lerTag(icms, 'CST');
    const origemCstIcms: string = this.lerTag(icms, 'orig');
    if (!numeroCstIcms) {
      numeroCstIcms = this.lerTag(icms, 'CSOSN');
    }
    const cstIcms: Cst = this.cstIcms.find((cstIcms) => cstIcms.cst == origemCstIcms + numeroCstIcms);
    if (cstIcms && cstIcms.simplesNacional == this.simplesNacional) {
      movimentacaoProduto.idCstIcms = cstIcms.id;
    }
    movimentacaoProduto.setIcmsBase(Number(this.lerTag(icms, 'vBC')));
    movimentacaoProduto.setIcmsAliquota(Number(this.lerTag(icms, 'pICMS')));
    movimentacaoProduto.setIcmsValor(Number(this.lerTag(icms, 'vICMS')));
    movimentacaoProduto.setIcmsStBase(Number(this.lerTag(icms, 'vBCST')));
    movimentacaoProduto.setIcmsStValor(Number(this.lerTag(icms, 'vICMSST')));
    movimentacaoProduto.setIcmsDesonerado(Number(this.lerTag(icms, 'vICMSDeson')));
    return movimentacaoProduto;
  }

  lerTag(no: any, tag: string): any {
    if (no) {
      if (no.getElementsByTagName(tag).length > 0) {
        return no.getElementsByTagName(tag)[0].childNodes[0].nodeValue;
      }
    }
    return null;
  }

  verificarImpostos(movimentacaoControlador: MovimentacaoControlador): void {
    movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      let produtoParceiro: ProdutoParceiro = this.produtoParceiros.find((produtoParceiroBusca) => produtoParceiroBusca.numero == movimentacaoProduto.numeroProdutoNfe);
      if (produtoParceiro && produtoParceiro.idProduto) {
        this.listarProduto(produtoParceiro.idProduto).subscribe((res) => {
          const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacaoControlador.movimentacao.idLoja);
          const produto = this.plainToClass(Produto, res[0]) as any;
          this.setIpi(movimentacaoProduto, this.plainToClass(Produto, res[0]) as any);
          this.lerPis(movimentacaoProduto, produto, movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, loja);
          this.lerCofins(movimentacaoProduto, produto, movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, loja);
          movimentacaoControlador.listarUnidadeProduto(movimentacaoProduto, produto);
        });
      }
    });
  }

  listarProduto(idProduto: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idProduto)), this.bibServico.produto);
  }

  lerPis(movimentacaoProduto: MovimentacaoProduto, produto: Produto, naturezaOperacaoFinalidade: number, loja: Loja): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_PRODUTO', produto.id.toString()));
    criterios.push(new Criterio('ENQUADRAMENTO', loja.enquadramento));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoPisCofins).subscribe((res) => {
      const cstPisCofinsProduto: ProdutoPisCofins = this.plainToClass(ProdutoPisCofins, res[0]) as any;
      movimentacaoProduto.idCstPis = cstPisCofinsProduto && cstPisCofinsProduto.idCstPisEntrada ? cstPisCofinsProduto.idCstPisEntrada : null;
      if (movimentacaoProduto.idCstPis) {
        if (naturezaOperacaoFinalidade != 2) {
          movimentacaoProduto.setPisBase(this.util.arredondar(movimentacaoProduto.valorFinal));
          movimentacaoProduto.setPisAliquota(cstPisCofinsProduto.aliquotaPisEntrada);
          if (movimentacaoProduto.getPisAliquota() == 0) {
            movimentacaoProduto.setPisBase(0);
          }
        } else {
          movimentacaoProduto.setPisBase(0);
          movimentacaoProduto.setPisAliquota(0);
        }
      }
    });
  }

  lerCofins(movimentacaoProduto: MovimentacaoProduto, produto: Produto, naturezaOperacaoFinalidade: number, loja: Loja): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_PRODUTO', produto.id.toString()));
    criterios.push(new Criterio('ENQUADRAMENTO', loja.enquadramento));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoPisCofins).subscribe((res) => {
      const cstPisCofinsProduto: ProdutoPisCofins = this.plainToClass(ProdutoPisCofins, res[0]) as any;
      movimentacaoProduto.idCstCofins = cstPisCofinsProduto && cstPisCofinsProduto.idCstCofinsEntrada ? cstPisCofinsProduto.idCstCofinsEntrada : null;
      if (movimentacaoProduto.idCstCofins) {
        if (naturezaOperacaoFinalidade != 2) {
          movimentacaoProduto.setCofinsBase(this.util.arredondar(movimentacaoProduto.valorFinal));
          movimentacaoProduto.setCofinsAliquota(cstPisCofinsProduto.aliquotaCofinsEntrada);
          if (movimentacaoProduto.getCofinsAliquota() == 0) {
            movimentacaoProduto.setCofinsBase(0);
          }
        } else {
          movimentacaoProduto.setCofinsBase(0);
          movimentacaoProduto.setCofinsAliquota(0);
        }
      }
      this.utilMovimentacaoImpostos.ehPreenchidoImpostos(movimentacaoProduto, false);

    });
  }

  setIpi(movimentacaoProduto: MovimentacaoProduto, produto: Produto): void {
    movimentacaoProduto.idCstIpi = produto.idIpiEntrada;
  }
}
