import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, campo: 'movimentacaoNumeroSerie', campoOrdenacao: 'numeroMovimentacao' },
  { rotulo: bibDialogo.dataDesfazimento, campo: 'dataDesfazimento' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.cliente, campo: 'parceiro' },
  { rotulo: bibDialogo.motivo, campo: 'motivo' },
  { rotulo: bibDialogo.operacao, campo: 'operacao' },
  { rotulo: bibDialogo.loja, campo: 'abreviacao' },
];

export default grupos;
