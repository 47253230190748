import { MdfeDocumentoFiscal } from './mdfeDocumentoFiscal';
import { MdfePercurso } from './mdfePercurso';
import { Type } from 'class-transformer';

export class Mdfe {
  @Type(() => Date)
  data: Date;
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public ativo: string;
  public chave: string;
  public chaveCancelamento: string;
  public dataHora: string;
  public id: number;
  public idCidadeFinal: number;
  public idEmpresa: number;
  public idLoja: number;
  public idParceiroTransportadora: number;
  public idTecnospeed: string;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idVeiculo: number;
  public informacaoComplementar: string;
  public motoristaCpf: string;
  public ehValidoCpf: boolean = true;
  public motoristaNome: string;
  public numero: number;
  public protocolo: string;
  public protocoloCancelamento: string;
  public serie: number;
  public situacao: number;
  public situacaoDescricao: string;
  public status: string = 'N';
  public tipoMdfe: string;
  public mdfeDocumentoFiscais: MdfeDocumentoFiscal[] = [];
  public mdfePercursos: MdfePercurso[] = [];

  public cidadeFinal: string;
  public loja: string;
  public lojaAbreviacao: string;
  public parceiroTransportadora: string;
  public placa: string;
  public placaUf: string;
  public veiculo: string;
  public expandido: boolean = false;
  public selecionado: string = 'N';
  public buscarTodosDocumentosFiscais: string = 'N';
  public idIntegracao: string;
  public estadoCidadeFinal: string;
  public ibgeCidadeFinal: string;
}
