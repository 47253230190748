import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { differenceInDays, format } from 'date-fns';
import { Observable, of } from 'rxjs';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import { Baixa } from 'src/app/modelo/baixa';
import { BaixaMovimentacaoProduto } from 'src/app/modelo/baixaMovimentacaoProduto';
import { Conferencia } from 'src/app/modelo/conferencia';
import { ConferenciaTitulo } from 'src/app/modelo/conferenciaTitulo';
import { Criterio } from 'src/app/modelo/criterio';
import { Financeiro } from 'src/app/modelo/financeiro';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { FinanceiroBaixaPro } from 'src/app/modelo/financeiroBaixaPro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { DataPipe } from 'src/app/utilitario/data.pipe';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'baixaBusca',
  templateUrl: './baixaBusca.component.html',
})
export class BaixaBuscaComponent {
  @Input() baixa: Baixa;
  @Input() criterios: Criterio[] = [];
  private baixaEmt: any;
  private params: any;
  public baixaCaixa: boolean = false;
  public baixaRapida: boolean = false;
  public baixaConferenciaOuDespesa: boolean;
  public bibDialogo = bibDialogo;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public bloqueado: any;
  public bloqueiaFiltros: boolean = false;
  public bloqueiaNumeroConferencia: boolean = false;
  public bloqueiaNumeroMovimentacao: boolean = false;
  public compacto: boolean = this.utilSessao.getEmpresa().compacto == 'S';
  public criteriosFinanceiros: Criterio[] = [];
  public criteriosMovimentacoes: Criterio[] = [];
  public dataPipe: DataPipe = new DataPipe();
  public filtroAberto = new Criterio('ABERTO', 'S');
  public filtroIdLoja = new Criterio('ID_LOJA');
  public filtroIdsFinanceiros = new Criterio('IDS_FINANCEIROS');
  public filtroIdsMovimentacoes = new Criterio('IDS_MOVIMENTACOES');
  public filtroNumero = new Criterio('NUMERO');
  public filtroNumeroConferencia = new Criterio('NUMERO_CONFERENCIA');
  public filtroParceiro = new Criterio('ID_PARCEIRO_BAIXA');
  public filtroTipo = new Criterio('TIPO');
  public filtroDataVencimentoDe = new Criterio('DATA_VENCIMENTO_DE');
  public filtroDataVencimentoAte = new Criterio('DATA_VENCIMENTO_ATE');
  public financeiroBaixaPros: FinanceiroBaixaPro[] = [];
  public lojas: Loja[] = [];
  public menuBaixaConferencia: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 110);
  public parametro: string;
  public parametroAnterior: string = '';
  public listaReceitaDespesa: any[] = [
    { id: 'R', nome: this.bibDialogo.receita.toUpperCase() },
    { id: 'D', nome: this.bibDialogo.despesa.toUpperCase() },
  ];

  constructor(private activatedRoute: ActivatedRoute, private comunicacaoService: ComunicacaoService, private utilSessao: UtilSessao) {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.utilSessao.setIdentificacao(new Identificacao('PARAMETRO', this.parametro));
      this.baixaCaixa = this.parametro == 'CAIXA';
      this.baixaRapida = this.parametro == 'RAPIDA';
      if (this.parametro != this.parametroAnterior) {
        this.limparFiltros();
        this.parametroAnterior = this.parametro;
        this.ehBaixaConferenciaOuDespesa();
      }
      this.setTipoFiltro();
    });
  }

  ngOnInit(): void {
    this.listarLoja();
    this.baixaEmt = this.baixa.baixaNotificacao.baixaEmt.subscribe((res) => {
      if (res == 2) {
        this.ir();
      }
    });
    this.baixa.baixaNotificacao.reiniciaFiltroEmt.subscribe((res) => {
      if (res == true) {
        this.limparFiltros();
        this.setTipoFiltro();
      }
    });
    if (this.utilSessao.getIdentificacao('idFinanceiro')) {
      const idFinanceiro: number = this.utilSessao.getIdentificacao('idFinanceiro')?.conteudo;
      this.baixa.utilizaIdFinanceiro = true;
      this.utilSessao.excluirIdentificacao('idFinanceiro');
      this.utilSessao.excluirIdentificacao('idParceiro');
      this.criterios.push(new Criterio('ID', idFinanceiro));
      this.listarFinanceiro();
    }
    this.baixa.mostraRelatorio = false;
  }

  ehReceitaOuDespesa() {
    if (this.parametro == 'RAPIDA') {
      this.filtroDataVencimentoDe.valor = this.filtroTipo.valor == 'D' ? new Date() : null;
    }
  }

  setTipoFiltro(): void {
    if (this.parametro == 'RECEITA' || this.parametro == 'RAPIDA') {
      this.filtroTipo.valor = 'R';
    } else {
      this.filtroTipo.valor = 'D';
    }

    if (this.parametro == 'CAIXA' || this.parametro == 'CONFERENCIA' || this.parametro == 'DUPLICATA') {
      this.filtroTipo.valor = null;
    }
    if (this.parametro == 'RAPIDA') {
      this.filtroDataVencimentoAte.valor = new Date();
    }
  }

  ehBaixaConferenciaOuDespesa(): void {
    this.baixaConferenciaOuDespesa = this.parametro == 'CONFERENCIA' || this.parametro == 'DESPESA' || this.parametro == 'DUPLICATA';
  }

  listarLoja(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.baixa.bibServico.loja).subscribe((res) => {
      this.lojas = plainToClass(Loja, res) as any;
      if (this.lojas.length == 1) {
        this.filtroIdLoja.valor = this.lojas.find((loja) => (loja.id = this.lojas[0].id)).id;
      } else {
        if (this.utilSessao.getLojas().length == 1) {
          this.filtroIdLoja.valor = this.utilSessao.getLojas()[0].id;
        }
        if (this.utilSessao.getIdLoja()) {
          this.filtroIdLoja.valor = this.utilSessao.getIdLoja();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.baixaEmt.unsubscribe();
    this.params.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.criterios.push(this.filtroParceiro);
    this.criterios.push(this.filtroTipo);
    this.criterios.push(this.filtroNumero);
    this.criterios.push(this.filtroNumeroConferencia);
    this.criterios.push(this.filtroAberto);
    this.criterios.push(this.filtroIdLoja);
    this.criterios.push(this.filtroDataVencimentoDe);
    this.criterios.push(this.filtroDataVencimentoAte);
    this.criteriosMovimentacoes.push(this.filtroIdsMovimentacoes);
    this.criteriosFinanceiros.push(this.filtroIdsFinanceiros);
  }

  limparFiltros(): void {
    this.filtroNumero.valor = null;
    this.filtroNumeroConferencia.valor = null;
    this.filtroParceiro.valor = null;
    this.filtroTipo.valor = '';
    this.bloqueiaFiltros = false;
    this.bloqueiaNumeroMovimentacao = false;
    this.bloqueiaNumeroConferencia = false;
    if (this.baixa) {
      this.listarLoja();
    }
  }

  ir(): void {
    if (this.ehValido()) {
      this.listarFinanceiro();
    }
  }

  buscar(): void {
    this.limparFiltros();
    this.setTipoFiltro();
    this.listarFinanceiro();
  }

  setParceiro(parceiro: Parceiro): void {
    this.baixa.setParceiro(parceiro);
    this.filtroParceiro.valor = parceiro ? parceiro.id : null;
  }

  ehValido(): boolean {
    if (this.filtroParceiro.valor || this.baixa.ehBaixaCaixa || this.baixa.utilizaIdFinanceiro || this.baixa.utilizaConferencia || this.bloqueiaFiltros || this.baixaCaixa || this.baixaRapida || (this.parametro == 'DESPESA' && this.filtroDataVencimentoDe.valor && this.filtroDataVencimentoAte.valor)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.baixa.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  listarFinanceiro(): void {
    this.baixa.financeiros = [];
    const baixaCriterio: Criterio = this.criterios.find((criterio) => criterio.nome == 'ID_PARCEIRO_BAIXA');
    if (baixaCriterio) {
      this.utilSessao.setIdentificacao(new Identificacao('ID_PARCEIRO_BAIXA', baixaCriterio.valor));
    }
    if (this.filtroDataVencimentoDe.valor != null) {
      this.baixa.dataVencimentoDe = new Date(Date.parse(this.filtroDataVencimentoDe.valor));
      if (this.ehFormatoData(this.filtroDataVencimentoDe.valor) == false) {
        this.filtroDataVencimentoDe.valor = format(new Date(Date.parse(this.filtroDataVencimentoDe.valor)), 'yyyy-MM-dd');
      }
    }
    if (this.filtroDataVencimentoAte.valor != null) {
      this.baixa.dataVencimentoAte = new Date(Date.parse(this.filtroDataVencimentoAte.valor));
      if (this.ehFormatoData(this.filtroDataVencimentoAte.valor) == false) {
        this.filtroDataVencimentoAte.valor = format(new Date(Date.parse(this.filtroDataVencimentoAte.valor)), 'yyyy-MM-dd');
      }
    }
    const criterios: Criterio[] = this.criterios.filter((criterio) => criterio && criterio.valor);
    criterios.push(new Criterio('ATIVO', 'S'));
    this.baixa.apresentaLoja = this.filtroIdLoja.valor != null && this.filtroIdLoja.valor != '' ? true : false;
    this.ehAdicionarCriterio(criterios, this.parametro);
    this.baixa.ehBaixaCaixa = this.baixaCaixa;
    this.baixa.baixaRapida = this.baixaRapida;
    this.baixa.utilizaConferencia = this.filtroNumeroConferencia.valor != null && this.filtroNumeroConferencia.valor != '';
    this.comunicacaoService.listar(new Transporte(criterios), this.baixa.bibServico.financeiro).subscribe((res) => {
      this.baixa.financeiros = plainToClass(Financeiro, res) as any;
      if (this.baixa.financeiros.length > 0) {
        this.ordernarFinanceiros(this.baixa.financeiros, 'dataVencimento', 'numero');
        this.configurarFinanceiro();
        this.listarFinanceiroTotal();
        this.baixa.apresentaTitulo();
        this.baixa.valorRestanteLiquido = 0;
        this.baixa.selecionadoAtrasados = 'N';
        this.listarMovimentacaoProdutos().subscribe((res) => {
          if (this.baixa.utilizaConferencia) {
            this.listarBaixaConferencia();
          } else {
            if (this.baixa.utilizaIdFinanceiro) {
              this.selecionarFinanceiroEspecifico();
            } else {
              this.baixa.financeiroBaixas = [];
              this.baixa.setEtapa(2);
            }
          }
          this.criterios = this.criterios.filter((criterio) => criterio.nome != 'ID');
        });
      } else {
        if (this.filtroNumero.valor || this.filtroNumeroConferencia.valor) {
          this.utilSessao.setResultado(new Resultado(false, this.baixa.util.substituir(this.baixa.bibDialogo.baixaNaoEncontrada, [this.filtroTipo.valor == null ? this.baixa.bibDialogo.despesa.toLocaleLowerCase() + ' ou ' + this.baixa.bibDialogo.receita.toLocaleLowerCase() : this.filtroTipo.valor == 'D' ? this.baixa.bibDialogo.despesa.toLocaleLowerCase() : this.baixa.bibDialogo.receita.toLocaleLowerCase(), ' com os critérios informados'])));
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.baixa.util.substituir(this.baixa.bibDialogo.naoEncontradoBaixa, [this.filtroTipo.valor == null ? this.baixa.bibDialogo.despesa.toLocaleLowerCase() + ' ou ' + this.baixa.bibDialogo.receita.toLocaleLowerCase() : this.filtroTipo.valor == 'D' ? this.baixa.bibDialogo.despesa.toLocaleLowerCase() : this.baixa.bibDialogo.receita.toLocaleLowerCase()])));
        }
      }
    });
  }

  ehFormatoData(data: any): boolean {
    return (/^\d{4}-\d{2}-\d{2}$/.test(data));
  }

  ehAdicionarCriterio(criterios: Criterio[], parametro: string): void {
    switch (parametro) {
      case 'CAIXA':
        if (!this.filtroNumeroConferencia.valor) {
          criterios.push(new Criterio('CAIXA_A_VISTA', 'S'));
        }
        break;
      case 'CONFERENCIA':
        criterios.push(new Criterio('CAIXA_CONFERENCIA', 'S'));
        break;

      case 'DUPLICATA':
        criterios.push(new Criterio('FINANCEIRO_COM_NUMERO_FISCAL', 'S'));
        break;

      case 'RECEITA':
        criterios = criterios.filter((criterio) => criterio.nome !== "TIPO");
        criterios.push(new Criterio('DEVOLUCAO_CLIENTE', 'S'));
        break;

      case 'DESPESA':
        criterios = criterios.filter((criterio) => criterio.nome !== "TIPO");
        criterios.push(new Criterio('DEVOLUCAO_FORNECEDOR', 'S'));
        break;
    }
  }

  ordernarFinanceiros(lista: any[], campo: string, campoSubOrdem: string): void {
    if (lista) {
      lista.sort((a, b) => (a[`${campo}`] == b[`${campo}`] ? (a[`${campoSubOrdem}`] == b[`${campoSubOrdem}`] ? 0 : a[`${campoSubOrdem}`] > b[`${campoSubOrdem}`] ? 1 : -1) : a[`${campo}`] > b[`${campo}`] ? 1 : -1));
    }
    this.baixa.financeiros = lista;
  }

  configurarFinanceiro(): void {
    this.filtroIdsMovimentacoes.valor = '';
    this.filtroIdsFinanceiros.valor = '';
    this.baixa.financeiros.forEach((financeiro) => {
      this.filtroIdsFinanceiros.valor = this.baixa.util.concatenar(this.filtroIdsFinanceiros.valor, financeiro.id);
      if (financeiro.idMovimentacao > 0) {
        this.filtroIdsMovimentacoes.valor = this.baixa.util.concatenar(this.filtroIdsMovimentacoes.valor, financeiro.idMovimentacao);
      }
      this.ehSetDiasAtrasado(financeiro);
    });
  }

  ehSetDiasAtrasado(financeiro: Financeiro): void {
    const diferencaDiasVencimento: number = differenceInDays(new Date(), financeiro.dataVencimento);
    if (diferencaDiasVencimento > 0) {
      financeiro.diasAtrasado = diferencaDiasVencimento;
    }
  }

  listarFinanceiroTotal(): void {
    if (this.baixa.parceiro) {
      this.baixa.valorTotalAberto = 0;
      const criterios: Criterio[] = [];
      criterios.push(new Criterio('ID_PARCEIRO', this.baixa.parceiro.id));
      criterios.push(new Criterio('ABERTO', 'S'));
      criterios.push(new Criterio('ATIVO', 'S'));
      this.comunicacaoService.listar(new Transporte(criterios), this.baixa.bibServico.financeiro).subscribe((res) => {
        const financeiros: Financeiro[] = plainToClass(Financeiro, res) as any;
        financeiros.forEach((financeiro) => {
          if (financeiro.tipo == 'R') {
            this.baixa.valorTotalAberto += financeiro.valorRestante;
          } else {
            this.baixa.valorTotalAberto -= financeiro.valorRestante;
          }
          this.baixa.totalizarRestanteLiquido();
        });
      });
    }
  }

  listarMovimentacaoProdutos(): Observable<any> {
    if (this.filtroIdsMovimentacoes.valor != null && this.filtroIdsMovimentacoes.valor != '') {
      const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte(this.criteriosMovimentacoes), this.baixa.bibServico.movimentacaoProduto);
      resposta.subscribe((res) => {
        const movimentacaoProdutos = plainToClass(MovimentacaoProduto, res) as any;
        this.baixa.financeiros.forEach((financeiro) => {
          financeiro.baixaMovimentacaoProdutos = [];
          const movimentacaoPros: MovimentacaoProduto[] = movimentacaoProdutos.filter((movimentacaoPro) => financeiro.idMovimentacao == movimentacaoPro.idMovimentacao);
          movimentacaoPros.forEach((movimentacaoProduto) => {
            financeiro.baixaMovimentacaoProdutos.push(plainToClass(BaixaMovimentacaoProduto, movimentacaoProduto));
          });
          this.calcularProporcaoValorMovimentacao(financeiro);
        });
        this.listarFinanceiroBaixaProduto();
      });
      return resposta;
    } else {
      return of(null);
    }
  }

  calcularProporcaoValorMovimentacao(financeiro: Financeiro): void {
    const proporcao = this.baixa.getProporcao(financeiro.valor, financeiro.valorTotalMovimentacao);
    for (let baixaMovimentacaoProduto of financeiro.baixaMovimentacaoProdutos) {
      baixaMovimentacaoProduto.valorBaixa = 0;
      baixaMovimentacaoProduto.valorRestante = baixaMovimentacaoProduto.valorFinal;
      baixaMovimentacaoProduto.valorFinal = baixaMovimentacaoProduto.valorFinal * proporcao;
      baixaMovimentacaoProduto.valorBaixa = baixaMovimentacaoProduto.valorBaixa * proporcao;
      baixaMovimentacaoProduto.valorRestante = baixaMovimentacaoProduto.valorRestante * proporcao;
    }
    this.verificarArredondamentoMovProduto(financeiro);
  }

  verificarArredondamentoMovProduto(financeiro: Financeiro): void {
    let valorFinalProdutos: number = 0;
    financeiro.baixaMovimentacaoProdutos.forEach((baixaMovimentacaoProduto, i) => {
      valorFinalProdutos += baixaMovimentacaoProduto.valorFinal;
    });
    const diferenca = valorFinalProdutos - financeiro.valor;
    if (financeiro.baixaMovimentacaoProdutos.length > 0) {
      financeiro.baixaMovimentacaoProdutos[0].valorFinal = financeiro.baixaMovimentacaoProdutos[0].valorFinal - diferenca;
      financeiro.baixaMovimentacaoProdutos[0].valorBaixa = financeiro.baixaMovimentacaoProdutos[0].valorBaixa - diferenca;
      financeiro.baixaMovimentacaoProdutos[0].valorRestante = financeiro.baixaMovimentacaoProdutos[0].valorRestante - diferenca;
    }
  }

  listarFinanceiroBaixaProduto(): void {
    this.comunicacaoService.listar(new Transporte(this.criteriosFinanceiros), this.baixa.bibServico.financeiroBaixaPro).subscribe((res) => {
      this.financeiroBaixaPros = plainToClass(FinanceiroBaixaPro, res) as any;
      this.calcularFinanceiroBaixaPro();
    });
  }

  calcularFinanceiroBaixaPro(): void {
    this.baixa.financeiros.forEach((financeiro) => {
      financeiro.baixaMovimentacaoProdutos.forEach((baixaMovimentacaoProduto) => {
        const financeiroBaixaPros = this.financeiroBaixaPros.filter((financeiroBaixaPro) => financeiroBaixaPro.idMovimentacaoPro == baixaMovimentacaoProduto.id && financeiroBaixaPro.idFinanceiro == financeiro.id);
        if (financeiroBaixaPros.length > 0) {
          for (const financeiroBaixaPro of financeiroBaixaPros) {
            baixaMovimentacaoProduto.valorBaixa += financeiroBaixaPro.getValor();
            baixaMovimentacaoProduto.valorRestante -= financeiroBaixaPro.getValor();
          }
        }
      });
    });
  }

  listarBaixaConferencia(): void {
    const criterios: Criterio[] = [this.filtroNumeroConferencia];
    this.comunicacaoService.listar(new Transporte(criterios), this.baixa.bibServico.conferencia).subscribe((res) => {
      const conferencia: Conferencia = plainToClass(Conferencia, res[0]) as any;
      this.baixa.valorDesconto = conferencia.valorDesconto;
      this.baixa.valorJuro = conferencia.valorAcrescimo;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_CONFERENCIA', conferencia.id)), this.baixa.bibServico.conferenciaTitulo).subscribe((res) => {
        conferencia.conferenciaTitulos = plainToClass(ConferenciaTitulo, res) as any;
        this.vincularFinanceiroBaixaConferencia(conferencia);
        this.baixa.financeiros.forEach((financeiro) => {
          this.baixa.setSelecionado(financeiro, 'S');
        });
        this.baixa.setEtapa(3);
      });
    });
  }

  private vincularFinanceiroBaixaConferencia(conferencia: Conferencia): void {
    while (this.baixa.financeiroBaixas.length > 0) {
      this.baixa.financeiroBaixas.splice(0, 1);
    }
    conferencia.conferenciaTitulos.forEach((conferenciaTitulo) => {
      let financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa();
      financeiroBaixa.dataBaixa = this.baixa.dataBaixa;
      financeiroBaixa.idTipoTitulo = conferenciaTitulo.idTipoTitulo;
      financeiroBaixa.idConta = conferenciaTitulo.idConta;
      financeiroBaixa.valorCheio = conferenciaTitulo.valor;
      financeiroBaixa.setValor(conferenciaTitulo.valor);
      financeiroBaixa.numeroDocumento = conferenciaTitulo.numeroDocumento;
      financeiroBaixa.descontoValor = conferenciaTitulo.descontoValor;
      financeiroBaixa.valorTroco = conferenciaTitulo.valorTroco;
      financeiroBaixa.valorCheio = conferenciaTitulo.valorCheio;
      financeiroBaixa.observacao = conferenciaTitulo.observacao;
      financeiroBaixa.tipoTitulo = conferenciaTitulo.tipoTitulo;
      financeiroBaixa.idContaContaContabil = conferenciaTitulo.idContaContabil;
      financeiroBaixa.contaContaContabil = conferenciaTitulo.contaContabil;
      financeiroBaixa.obrigaNumeroDocumento = this.parametro != 'CONFERENCIA' && (conferenciaTitulo.idEspecie == 2 || conferenciaTitulo.idEspecie == 4 || conferenciaTitulo.idEspecie == 10);
      this.baixa.financeiroBaixas.push(financeiroBaixa);
    });
    this.baixa.totalBaixaConferencia = conferencia.valor;
  }

  selecionarFinanceiroEspecifico(): void {
    this.baixa.financeiros.forEach((financeiro) => {
      this.baixa.setSelecionado(financeiro, 'S');
    });
    const financeiroBaixa = new FinanceiroBaixa(this.baixa.valorRestanteLiquido);
    this.baixa.adicionarFinanceiroBaixa(financeiroBaixa);
    this.baixa.setEtapa(3);
  }

  setData(criterio: Criterio, data: any): void {
    if (data) {
      criterio.valor = format(new Date(Date.parse(data)), 'yyyy-MM-dd');
    } else {
      criterio.valor = '';
    }
  }

  setNumeroMovimentacao(numeroMovimentacao: number): void {
    this.filtroNumero.valor = numeroMovimentacao;
    if (this.filtroNumero.valor) {
      this.bloqueiaNumeroConferencia = true;
      this.bloqueiaFiltros = true;
      this.filtroNumeroConferencia.valor = null;
      this.baixa.utilizaNumeroConferencia = false;
    } else {
      this.bloqueiaFiltros = false;
      this.bloqueiaNumeroConferencia = false;
    }
  }

  setNumeroConferencia(numeroConferencia: number): void {
    this.filtroNumeroConferencia.valor = numeroConferencia;
    if (this.filtroNumeroConferencia.valor) {
      this.bloqueiaFiltros = true;
      this.baixa.utilizaNumeroConferencia = true;
      this.bloqueiaNumeroMovimentacao = true;
      this.filtroNumero.valor = null;
    } else {
      this.bloqueiaFiltros = false;
      this.bloqueiaNumeroMovimentacao = false;
      this.baixa.utilizaNumeroConferencia = false;
    }
  }
}
