import { Component } from '@angular/core';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './compendioEletronicoIndicacoesFrm.component.html',
})
export class CompendioEletronicoIndicacoesFrmComponent extends PaginaComponent {
  public produtoFormuladoDose: ProdutoFormuladoDose = new ProdutoFormuladoDose();

  ngOnInit(): void {
    this.produtoFormuladoDose = this.plainToClass(ProdutoFormuladoDose, this.ehAtualizacao(this.produtoFormuladoDose));
    if (this.produtoFormuladoDose.id && !this.produtoFormuladoDose.alterado) {
      this.listarProdutoFormuladoDose();
    }
  }

  listarProdutoFormuladoDose(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.produtoFormuladoDose.id)), this.bibServico.produtoFormuladoDose).subscribe((res) => {
      this.produtoFormuladoDose = this.plainToClass(ProdutoFormuladoDose, res[0]) as any;
    });
  }

  persistirProdutoFormuladoDose(): void {
    if (this.ehValido() && this.ehValidoUnidadeDose() && this.ehValidoValoresAte()) {
      this.produtoFormuladoDose.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.produtoFormuladoDose, this.produtoFormuladoDose));
    }
  }

  ehValido(): boolean {
    let ehValido: boolean = false;
    if (this.produtoFormuladoDose.cultura && this.produtoFormuladoDose.praga && this.produtoFormuladoDose.doseUnidade && (this.produtoFormuladoDose.doseDe || this.produtoFormuladoDose.doseDe == 0) && (this.produtoFormuladoDose.doseAte || this.produtoFormuladoDose.doseAte == 0)) {
      ehValido = true;
    }
    if ((ehValido && (this.produtoFormuladoDose.caldaDeTerrestre || this.produtoFormuladoDose.caldaDeTerrestre == 0)) || this.produtoFormuladoDose.caldaAteTerrestre || this.produtoFormuladoDose.caldaAteTerrestre == 0) {
      if ((this.produtoFormuladoDose.caldaDeTerrestre || this.produtoFormuladoDose.caldaDeTerrestre == 0 || this.produtoFormuladoDose.caldaDeTerrestre > 0) && (this.produtoFormuladoDose.caldaAteTerrestre || this.produtoFormuladoDose.caldaAteTerrestre == 0 || this.produtoFormuladoDose.caldaAteTerrestre > 0) && this.produtoFormuladoDose.caldaUnidadeTerrestre) {
        ehValido = true;
      } else {
        ehValido = false;
      }
    }
    if ((ehValido && (this.produtoFormuladoDose.caldaDeAereo || this.produtoFormuladoDose.caldaDeAereo == 0)) || this.produtoFormuladoDose.caldaAteAereo || this.produtoFormuladoDose.caldaAteAereo == 0) {
      if ((this.produtoFormuladoDose.caldaDeAereo || this.produtoFormuladoDose.caldaDeAereo == 0 || this.produtoFormuladoDose.caldaDeAereo > 0) && (this.produtoFormuladoDose.caldaAteAereo || this.produtoFormuladoDose.caldaAteAereo == 0 || this.produtoFormuladoDose.caldaAteAereo >= 0) && this.produtoFormuladoDose.caldaUnidadeAereo) {
        ehValido = true;
      } else {
        ehValido = false;
      }
    }
    if (!ehValido) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    }
    return ehValido;
  }

  ehValidoUnidadeDose(): boolean {
    if (this.produtoFormuladoDose.doseFator && this.produtoFormuladoDose.doseFator == 0) {
      return true;
    }
    if (confirm(this.bibDialogo.doseFatorVazio)) {
      return true;
    }
    return false;
  }

  ehValidoValoresAte(): boolean {
    if (this.produtoFormuladoDose.doseDe > this.produtoFormuladoDose.doseAte) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.campoAteMaiorCampoDe, [this.bibDialogo.doseDe, this.bibDialogo.doseAte])));
      return false;
    }
    if (this.produtoFormuladoDose.caldaDeTerrestre > this.produtoFormuladoDose.caldaAteTerrestre) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.campoAteMaiorCampoDe, [this.bibDialogo.caldaTerrestreDe, this.bibDialogo.caldaTerrestreAte])));
      return false;
    }
    if (this.produtoFormuladoDose.caldaDeAereo > this.produtoFormuladoDose.caldaAteAereo) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.campoAteMaiorCampoDe, [this.bibDialogo.caldaAereoDe, this.bibDialogo.caldaAereoAte])));
      return false;
    }
    return true;
  }
}
