import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1206.component.html',
})
export class R1206Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public movimentacoes: any[] = [];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.ordenarAgrupar(this.movimentacoes);
      this.ehImprimirRelatorio();
    });
  }

  expandir(movimentacao: any) {
    movimentacao.expandido = !movimentacao.expandido;
    if (movimentacao.expandido && (movimentacao.movimentacaoProdutos == null || movimentacao.movimentacaoProdutos.length == 0) ) {
      this.listarEspecifico(new Criterio("ID_MOVIMENTACAO", movimentacao.id), 77).subscribe((res) => {
        movimentacao.movimentacaoProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });      
    }
  }
}
