import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Safra } from 'src/app/modelo/safra';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './safraFrm.component.html',
})
export class SafraFrmComponent extends PaginaComponent {
  public safra: Safra = new Safra();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.safra = this.plainToClass(Safra, this.ehAtualizacao(this.safra));
    if (this.safra.id) {
      this.listarSafra(this.safra.id);
    }
  }

  listarSafra(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.safra).subscribe((res) => {
      this.safra = this.plainToClass(Safra, res[0]) as any;
    });
  }

  persistirSafra(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.safra), this.bibServico.safra, novo ? new Safra() : null);
    }
  }

  ehValido(): boolean {
    if (this.safra.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
