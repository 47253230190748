export class RelatorioAdicional {
  public id: number;
  public idRelatorio: number;
  public idMenu: number;
  public idNomenclatura: number;
  public marcado: string = 'N';
  public mostraAdicional: string = 'S';
  public oculto: string = 'N';
  public nome: string;
  public usaRelatorioAdicionalEmpresa: boolean;

  constructor(id: number, nome: string, marcado: string = 'N', mostraAdicional: string = 'S', idRelatorio: number = null, oculto: string = 'N', idMenu: number = null, idNomenclatura: number = null) {
    this.id = id;
    this.nome = nome;
    this.marcado = marcado;
    this.oculto = oculto;
    this.mostraAdicional = mostraAdicional;
    this.idRelatorio = idRelatorio;
    this.idMenu = idMenu;
    this.idNomenclatura = idNomenclatura;
  }
}
