import { Component, Input } from '@angular/core';
import { Formulario } from '../formulario';

@Component({
  selector: 'mascara',
  templateUrl: './mascara.component.html',
  styleUrls: ['./mascara.component.css'],
})
export class MascaraComponent extends Formulario {
  @Input() tipo: string;
  public usaModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public mascaraContabil: string = this.utilSessao.getEmpresa().regraMascaraContaContabil;

  definirMascara(): string {
    switch (this.tipo) {
      case 'cep':
        this.maximoCaracteres = 9;
        return '99999-999';

      case 'cnpj':
        this.maximoCaracteres = 18;
        return '99.999.999/9999-99';

      case 'cpf':
        this.maximoCaracteres = 14;
        return '999.999.999-99';

      case 'telefone':
        this.maximoCaracteres = 14;
        let mascaraTelefone: string = '(99)99999-9999';
        if (this.campoAtual != undefined) {
          if (this.campoAtual.length == 13) {
            mascaraTelefone = '(99)9999-9999';
          }
        }
        return mascaraTelefone;

      case 'hora':
        this.maximoCaracteres = 8;
        return '99:99:99';

      case 'horaMinuto':
        this.maximoCaracteres = 5;
        return '99:99';

      case 'contabil':
        this.maximoCaracteres = 20;
        this.mascaraContabil = this.mascaraContabil.replace(/0/g, '9');
        return this.mascaraContabil;

      default:
        return null;
    }
  }

  alterarMascara(valor: string): void {
    this.campoAtual = valor;
    this.alterar();
  }
}
