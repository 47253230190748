import { Type } from 'class-transformer';
import { MovimentacaoCartaCorrecao } from './movimentacaoCartaCorrecao';

export class MovimentacaoNfe {
  @Type(() => Date)
  public dataMovimentacao: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public abreviacao: string;
  public chave: string;
  public chaveComplementar: string;
  public consultarNfe: string = 'N';
  public dataHora: string;
  public expandido: boolean;
  public finalidade: string;
  public id: number;
  public idEmpresa: number;
  public idFiscalSerie: number;
  public idLoja: number;
  public idMovimentacao: number;
  public idIntegracao: string;
  public idTecnospeed: string;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public informacaoComplementar: string;
  public modeloNotaFiscal: number;
  public movimentacaoCartaCorrecoes: MovimentacaoCartaCorrecao[] = [];
  public numeroFiscal: number;
  public numeroMovimentacao: number;
  public parceiro: string;
  public protocolo: string;
  public protocoloCancelamento: string;
  public situacaoAutorizado: string = 'N';
  public situacaoCancelado: string = 'N';
  public situacaoDenegada: string = 'N';
  public situacaoEmAndamento: string = 'N';
  public situacaoNaoAtendido: string = 'N';
  public status: number;
  public statusDescricao: string;
  public tecnospeedCancelamento: string = 'N';
  public tentativaForaPrazo: string = 'N';
  public valorTotal: number;
  public aCancelar: boolean = false;

  constructor(idMovimentacao: number, idLoja: number, status: number) {
    this.idMovimentacao = idMovimentacao;
    this.idLoja = idLoja;
    this.status = status;
  }
}
