<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('abreviacaoLoja'), this.utilSessao.posicao, this.paginacao, bibDialogo.loja)">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('idProduto'), this.utilSessao.posicao, this.paginacao, bibDialogo.numero)">{{ bibDialogo.numero }}</th>
        <th class="l-55 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao, bibDialogo.nome)">{{ bibDialogo.nome }}</th>
        <th class="l-20 clique" (click)="listar(add('grupo'), this.utilSessao.posicao, this.paginacao, rotuloGrupo)">{{ rotuloGrupo }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <tr [ngClass]="{ tachado: produto.ativo != 'S' }">
          <td>{{ produto.abreviacaoLoja }}</td>
          <td class="l-10 text-right">{{ produto.numero }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(produto.id, i, produtos)">{{ produto.nome }}</a>
          </td>
          <td class="limitar">{{ produto.grupo }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(produto.id, i, produtos)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirProduto(produto.id, produto.nome)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.clone" [ajuda]="bibDialogo.duplicar" (btnAdicional)="clonarProduto(produto.id, i, produtos)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
