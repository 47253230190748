import { Component, Input } from '@angular/core';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'usuarioIntegracaoFrm',
  templateUrl: './usuarioIntegracaoFrm.component.html',
})
export class UsuarioIntegracaoFrmComponent extends PaginaComponent {
  @Input() integracaoUsuarios: IntegracaoUsuario[] = [];
  public campoPesquisa: string = this.bibDialogo.nome;
  public filtro: string = '';
  public listaCampoPesquisa: any[] = [
    { id: this.bibDialogo.nome, nome: this.bibDialogo.nome },
    { id: this.bibDialogo.categoria, nome: this.bibDialogo.categoria },
    { id: this.bibDialogo.semAcesso, nome: this.bibDialogo.semAcesso },
    { id: this.bibDialogo.comAcesso, nome: this.bibDialogo.comAcesso },
  ];

  setCampoPesquisa(valor: string): void {
    this.campoPesquisa = valor;
    if (this.campoPesquisa == this.bibDialogo.semAcesso || this.campoPesquisa == this.bibDialogo.comAcesso) {
      this.filtro = '';
    }
  }
}
