import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ParceiroTelefone } from 'src/app/modelo/parceiroTelefone';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Vinculo } from 'src/app/modelo/vinculo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './parceiroTelefoneFrm.component.html',
})
export class ParceiroTelefoneFrmComponent extends PaginaComponent {
  public componente: any;
  public parceiroTelefone: ParceiroTelefone = new ParceiroTelefone();
  public tipoVinculo: string;
  public vinculos: any[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarVinculo();
  }

  ehAlteracao(): void {
    if (this.utilSessao.getIdentificacao('objetoTelefone')?.conteudo != null) {
      this.parceiroTelefone = this.utilSessao.getIdentificacao('objetoTelefone')?.conteudo;
    }
    this.utilSessao.excluirIdentificacao('objetoTelefone');
  }

  listarVinculo(): void {
    let criterios: Criterio[] = [];
    this.setCriterios(criterios);
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.vinculo).subscribe((res) => {
      this.vinculos = this.plainToClass(Vinculo, res) as any;
    });
  }

  setCriterios(criterios: Criterio[]): void {
    this.tipoVinculo = this.utilSessao.getIdentificacao('tipoVinculo')?.conteudo;
    if (this.tipoVinculo) {
      criterios.push(new Criterio('TIPO', this.tipoVinculo));
    }
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('TELEFONE', 'S'));
  }

  persistirParceiroTelefone(): void {
    if (this.ehValidoObrigatorio()) {
      this.parceiroTelefone.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.parceiroTelefone, this.parceiroTelefone));
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.parceiroTelefone.telefone && this.parceiroTelefone.idVinculo) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setVinculo(idVinculo: number): void {
    this.parceiroTelefone.idVinculo = idVinculo;
    this.vinculos.forEach((vinculo) => {
      if (vinculo.id == idVinculo) {
        this.parceiroTelefone.vinculo = vinculo.nome;
      }
    });
  }
}
