import { ClassificacaoOperacao } from './classificacaoOperacao';

export class Classificacao {
  public ativo: string = 'S';
  public bloqueadoContabilidade: string = 'N';
  public bloqueadoEstoque: string = 'N';
  public bloqueadoEstoqueNfe: string = 'N';
  public bloqueadoFinanceiro: string = 'N';
  public bloqueadoNfe: string = 'N';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public vinculoOutroParceiro: string;
  public classificacaoOperacoes: ClassificacaoOperacao[] = [];
}
