import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'QUITADOS', id: 'Q' },
  { nome: 'ABERTOS', id: 'A' },
  { nome: 'PARCIALMENTE BAIXADO', id: 'P' },
]);

const filtros = {
  filtroReceita: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroFiscal', rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataNegociacaoDe', rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataNegociacaoAte', rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoDe', rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoAte', rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'cliente', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'natureza', rotulo: bibDialogo.natureza, nome: 'ID_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
    { id: 'conta', rotulo: bibDialogo.contaBancaria + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
    { id: 'tipoTitulo', rotulo: bibDialogo.tipoTitulo + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'valorDe', rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'valorAte', rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'A', lista: filtroComboSituacao },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  ]),
  filtroDespesa: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroFiscal', rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataNegociacaoDe', rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataNegociacaoAte', rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoDe', rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoAte', rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'fornecedor', rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'natureza', rotulo: bibDialogo.natureza, nome: 'ID_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
    { id: 'conta', rotulo: bibDialogo.contaBancaria + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
    { id: 'tipoTitulo', rotulo: bibDialogo.tipoTitulo + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'valorDe', rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'valorAte', rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'A', lista: filtroComboSituacao },
    { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  ]),
  filtroRenegociacaoReceita: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroFiscal', rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataNegociacaoDe', rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataNegociacaoAte', rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoDe', rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoAte', rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'fornecedor', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'natureza', rotulo: bibDialogo.natureza, nome: 'ID_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
    { id: 'conta', rotulo: bibDialogo.contaBancaria + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista },
    { id: 'tipoTitulo', rotulo: bibDialogo.tipoTitulo + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'A', lista: filtroComboSituacao },
  ]),
  filtroRenegociacaoDespesa: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroFiscal', rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataNegociacaoDe', rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataNegociacaoAte', rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoDe', rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataVencimentoAte', rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'fornecedor', rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'natureza', rotulo: bibDialogo.natureza, nome: 'ID_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
    { id: 'conta', rotulo: bibDialogo.contaBancaria + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista },
    { id: 'tipoTitulo', rotulo: bibDialogo.tipoTitulo + ' ' + bibDialogo.previstoAbreviacao.toLowerCase(), nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'A', lista: filtroComboSituacao },
  ]),
};
export default filtros;
