import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001NotasTransmitidas',
  templateUrl: './r2001NotasTransmitidas.component.html',
})
export class R2001NotasTransmitidas {
  @Input() notasTransmitidas: any[] = [];
  public bibDialogo = bibDialogo;
}
