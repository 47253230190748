import { Component, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { EmpresaOperacaoBloqueio } from 'src/app/modelo/empresaOperacaoBloqueio';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';
import { Loja } from 'src/app/modelo/loja';
import { Menu } from 'src/app/modelo/menu';
import { MenuApelido } from 'src/app/modelo/menuApelido';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Modulo } from 'src/app/modelo/modulo';
import { ModuloEmpresa } from 'src/app/modelo/moduloEmpresa';
import { Nomenclatura } from 'src/app/modelo/nomenclatura';
import { Permissao } from 'src/app/modelo/permissao';
import { Preferencia } from 'src/app/modelo/preferencia';
import { Relatorio } from 'src/app/modelo/relatorio';
import { RelatorioMenu } from 'src/app/modelo/relatorioMenu';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { TipoLancamentoContabil } from 'src/app/modelo/tipoLancamentoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { UsuarioConta } from 'src/app/modelo/usuarioConta';
import { UsuarioMenu } from 'src/app/modelo/usuarioMenu';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { Util } from 'src/app/utilitario/util';
import bibDialogo from '../../biblioteca/bibDialogo';
import bibServico from '../../biblioteca/bibServico';
import { UtilNotificacao } from '../../utilitario/util.notificacao';
import { UtilSessao } from '../../utilitario/util.sessao';
import { UtilCriptografia } from '../../utilitario/utilCriptografia';
import { LoginEmitter } from './login.emitter';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  @ViewChildren('focus') focus: any;
  @ViewChildren('focusLoja') focusLoja: any;
  private bibServico = bibServico;
  private empresa: Empresa;
  private moduloEmpresas: ModuloEmpresa[] = [];
  private modulos: Modulo[] = [];
  private nomenclaturas: Nomenclatura[] = [];
  private permissoes: Permissao[] = [];
  private preferencia: Preferencia = new Preferencia();
  private relatorioMenus: RelatorioMenu[] = [];
  private relatorioUsuarios: RelatorioUsuario[] = [];
  private dashboardUsuarios: RelatorioUsuario[] = [];
  private relatorios: Relatorio[] = [];
  private usuarioContas: UsuarioConta[] = [];
  private usuarioIntegracoes: IntegracaoUsuario[] = [];
  private usuarioMenus: UsuarioMenu[] = [];
  private utilCriptografia: UtilCriptografia = new UtilCriptografia();
  public bibDialogo = bibDialogo;
  public dominio: string;
  public email: string;
  public senha: string;
  public loja: Loja;
  public lojas: Loja[] = [];
  public mensagem: string;
  public usuario: Usuario;
  public usuarioIncorreto: boolean = false;
  public usuarios: Usuario[] = [];
  public util: Util = new Util();
  public utilizaRegraLojaLogin: boolean = false;
  public mostraSenha: boolean = false;
  public relatorioResultados: RelatorioResultado[] = [];

  constructor(private utilNotificacao: UtilNotificacao, private loginEmitter: LoginEmitter, private router: Router, public utilSessao: UtilSessao, private titleService: Title, public comunicacaoService: ComunicacaoService, public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.dominio = params['dominio'];
      if (this.dominio == undefined) {
        this.dominio = null;
      }
    });

    const sistema: string = this.utilSessao.sistema.indexOf('aGrow') >= 0 ? 'aGrow+' : this.utilSessao.sistema;
    this.titleService.setTitle(sistema);
    this.loginEmitter.reiniciar(this.utilSessao);
    this.loginEmitter.sair(false);
    this.removerModoEscuro();
    this.utilSessao.processando = 0;
    //this.gerarUpdateSenha();
  }

  gerarUpdateSenha(): void {
    const ids: string[] = [];

    const senhas: string[] = [];

    let update: string = '';
    let updateErro: string = '';

    const utilCriptografia: UtilCriptografia = new UtilCriptografia();

    for (let i = 0; i < senhas.length; i++) {
      const senhaDescriptograda: string = utilCriptografia.descriptografarAntigo(senhas[i]);
      const senhaNova: string = utilCriptografia.criptografarNovo(senhaDescriptograda);
      const senhaNovaDescriptograda: string = utilCriptografia.descriptografarNovo(senhaNova);
      if (senhaNovaDescriptograda == senhaDescriptograda) {
        update += "update SGC_USUARIO set senha = '" + senhaNova + "' where id = " + ids[i] + ';\n';
      } else {
        updateErro += "#### update SGC_USUARIO set senha = '" + senhaNova + "' where id = " + ids[i] + '[' + senhaDescriptograda + ']' + ';\n';
      }
    }
  }

  private removerModoEscuro(): void {
    document.querySelectorAll('[href="assets/stylesheets/theme-dark.css"').forEach((evento) => {
      evento.remove();
    });
  }

  ngAfterViewInit(): void {
    this.focus.first.nativeElement.focus();
  }

  acessar(): void {
    this.mensagem = null;
    this.logar();
  }

  logarIncorreto(): void {
    this.mensagem = this.bibDialogo.usuarioSenhaIncorretos;
    this.usuarioIncorreto = true;
    this.utilSessao.setUsuario(null);
  }

  logar(): void {
    let criterios: Criterio[] = [];
    if (this.email && this.email[this.email.length - 1] == '@' && this.dominio == null) {
      this.email = this.email.substring(0, this.email.length - 1);
      this.dominio = 'agrownegocios.com.br'
    }
    criterios.push(new Criterio('EMAIL', this.email.trim().toUpperCase() + (this.dominio != null ? '@' + this.dominio.trim().toUpperCase() : '')));
    criterios.push(new Criterio('SENHA', this.utilCriptografia.criptografar(this.senha)));
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('SISTEMA', this.utilSessao.sistema));
    let transporte: Transporte = new Transporte(criterios);
    transporte.adicionar([bibDialogo.versaoSistema]);
    this.comunicacaoService.listar(transporte, this.bibServico.usuarioLogarCompleto, false, false).subscribe(
      (res) => {
        if (!this.utilSessao.falha && res[0] != undefined) {
          this.usuarioIncorreto = false;
          this.relatorioResultados = plainToClass(RelatorioResultado, res) as any;
          this.usuario = plainToClass(Usuario, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Usuario').lista[0]) as any;
          this.usuario.senha = this.utilCriptografia.descriptografar(this.usuario.senha);
          this.utilSessao.setUsuario(this.usuario);
          this.utilSessao.setDataUsuarioLogin(new Date());
          this.listarPermissao();
        } else {
          this.logarIncorreto();
        }
      },
      (erro) => {
        this.mensagem = this.bibDialogo.servidorEmManutencao;
      }
    );
  }

  listarPermissao(): void {
    this.permissoes = plainToClass(Permissao, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Permissao').lista) as any;
    this.utilSessao.setDominio(this.dominio);
    this.permissoes = this.usuario.idEmpresa != 1 ? this.permissoes.filter((permissao) => permissao.idMenu != 89 && (permissao.agrow != 'S' || this.usuario.agrow == 'S')) : this.permissoes;
    this.listarIntegracaoUsuario();
    this.listarRelatorio();
    this.listarMenus();
    this.listarTiposLancamentosContabeis();
  }

  listarTiposLancamentosContabeis() {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoLancamentoContabil).subscribe((res) => {
      let tiposLancamentosContabeis = plainToClass(TipoLancamentoContabil, res) as any;
      this.utilSessao.setExisteTipoLancamentoContabil(tiposLancamentosContabeis.length > 1);
    });
  }

  listarIntegracaoUsuario(): void {
    this.usuarioIntegracoes = plainToClass(IntegracaoUsuario, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'IntegracaoUsuario').lista) as any;
    this.utilNotificacao.usuarioIntegracaoEmt.emit(this.usuarioIntegracoes);
  }

  listarRelatorio(): void {
    this.relatorios = plainToClass(Relatorio, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Relatorio').lista) as any;
    this.listarRelatorioUsuarioMenus();
    this.listarRelatorioUsuarios();
  }

  listarRelatorioUsuarioMenus(): void {
    this.utilSessao.setRelatorioUsuarioMenus(plainToClass(RelatorioUsuario, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RelatorioUsuarioMenu').lista) as any);
  }

  listarRelatorioUsuarios(): void {
    this.relatorioUsuarios = plainToClass(RelatorioUsuario, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RelatorioUsuario').lista) as any;
    this.dashboardUsuarios = this.relatorioUsuarios.filter((relatorio) => relatorio.dashboard == 'S');
    this.relatorioUsuarios = this.relatorioUsuarios.filter((relatorio) => relatorio.dashboard == 'N');
    this.utilNotificacao.relatoriosEmt.emit(this.relatorioUsuarios);
    this.utilNotificacao.dashboardEmt.emit(this.dashboardUsuarios);
    this.listarUsuarioMenus();
  }

  listarMenus(): void {
    const menus: Menu[] = plainToClass(Menu, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Menu').lista) as any;
    this.listarMenuEmpresa(menus);
  }

  listarMenuEmpresa(menus: Menu[]): void {
    if (menus.length > 0) {
      let menusEmpresa = plainToClass(MenuEmpresa, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MenuEmpresa').lista) as any;
      let menusApelidos: MenuApelido[] = [];
      this.vincular(menusEmpresa, menus);
      menusEmpresa.forEach((menuEmpresa) => {
        let menuApelido: MenuApelido = new MenuApelido();
        menuApelido.id = menuEmpresa.menu;
        menuApelido.idMenu = menuEmpresa.idMenu;
        menuApelido.apelido = menuEmpresa.apelido ? menuEmpresa.apelido : menuEmpresa.menu;
        menusApelidos.push(menuApelido);
      });
      this.utilSessao.setMenuApelido(menusApelidos);
    }
  }

  vincular(menuEmpresas: MenuEmpresa[], menus: Menu[]): void {
    const usuario: Usuario = this.utilSessao.getUsuario();
    menus.forEach((menu) => {
      let menuEmpresa: MenuEmpresa = menuEmpresas.find((menuEmpresa) => menuEmpresa.idMenu == menu.id);
      if (menuEmpresa) {
        menuEmpresas.push(menuEmpresa);
      } else {
        let menuEmpresaNovo: MenuEmpresa = new MenuEmpresa();
        menuEmpresaNovo.apelido = menu.nome;
        menuEmpresaNovo.ativo = menu.ativo;
        menuEmpresaNovo.idEmpresa = usuario.idEmpresa;
        menuEmpresaNovo.idMenu = menu.id;
        menuEmpresaNovo.tag = menu.tag;
        menuEmpresaNovo.menu = menu.nome;
        menuEmpresaNovo.menuEmpresa = false;
        menuEmpresaNovo.modularizado = menu.modularizado;
        menuEmpresaNovo.parametro = menu.parametro;
        menuEmpresaNovo.movimentacao = menu.movimentacao;
        menuEmpresas.push(menuEmpresaNovo);
      }
    });
    this.utilSessao.setMenuEmpresa(menuEmpresas);
  }

  listarUsuarioMenus(): void {
    this.usuarioMenus = plainToClass(UsuarioMenu, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'UsuarioMenu').lista) as any;
    this.listarRelatorioMenu();
  }

  listarRelatorioMenu(): void {
    this.relatorioMenus = plainToClass(RelatorioMenu, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RelatorioMenu').lista) as any;
    this.listarModulo();
  }

  listarModulo(): void {
    this.modulos = plainToClass(Modulo, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Modulo').lista) as any;
    this.listarModuloEmpresa();
  }

  listarModuloEmpresa(): void {
    this.moduloEmpresas = plainToClass(ModuloEmpresa, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ModuloEmpresa').lista) as any;
    this.listarPreferencia();
  }

  listarPreferencia(): void {
    this.preferencia = plainToClass(Preferencia, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Preferencia').lista[0]) as any;
    this.listarLojasDoUsuario();
  }

  listarLojasDoUsuario(): void {
    this.lojas = plainToClass(Loja, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Loja').lista) as any;
    this.lojas.sort((a, b) => (a['nomeFantasia'] == b['nomeFantasia'] ? 0 : a['nomeFantasia'] > b['nomeFantasia'] ? 1 : -1));
    this.listarUsuarioContas();
  }

  listarUsuarioContas(): void {
    this.usuarioContas = plainToClass(UsuarioConta, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'UsuarioConta').lista) as any;
    this.listarNomenclaturas();
  }

  listarNomenclaturas(): void {
    this.nomenclaturas = plainToClass(Nomenclatura, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Nomenclatura').lista) as any;
    this.listarEmpresaOperacaoBloqueio(this.usuario.idEmpresa);
  }

  listarEmpresaOperacaoBloqueio(idEmpresa: number): void {
    this.utilSessao.setEmpresaOperacaoBloqueia(plainToClass(EmpresaOperacaoBloqueio, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'EmpresaOperacaoBloqueio').lista) as any);
    this.listarEmpresa();
  }

  listarEmpresa(): void {
    this.empresa = plainToClass(Empresa, this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Empresa').lista)[0] as any;
    this.ehIrPrincipal();
  }

  ehIrPrincipal(): void {
    this.setarSessao();
    this.verificarUtilizaRegraLogin();
    if (!this.utilizaRegraLojaLogin) {
      this.irPrincipal();
    }
    this.setarLoginEmitter();
  }

  verificarUtilizaRegraLogin(): void {
    if (this.lojas.length > 1 && this.utilSessao.getEmpresa().regraLojaLogin == 'S') {
      this.lojas = this.utilSessao.getLojas();
      this.utilizaRegraLojaLogin = true;
      setTimeout((_) => this.focusLoja.first.nativeElement.focus());
    }
  }

  continuar(): void {
    if (this.loja && this.loja.id) {
      this.utilSessao.setIdLoja(this.loja.id);
      this.irPrincipal();
    } else {
      this.mensagem = this.bibDialogo.lojaObrigatoria;
      this.usuarioIncorreto = true;
    }
  }

  irPrincipal(): void {
    this.utilSessao.setLoginCompleto(true);
    this.loginEmitter.loginCompletoEmitter.emit(true);
    this.router.navigate(['/']);
    setTimeout((_) => this.utilNotificacao.setMenuPrincipal(true));
  }

  setarSessao(): void {
    this.utilSessao.setPreferencia(this.preferencia);
    this.utilSessao.setRelatorios(this.relatorios);
    this.utilSessao.setRelatorioMenus(this.relatorioMenus);
    this.utilSessao.setRelatorioUsuarios(this.relatorioUsuarios);
    this.utilSessao.setDashboardUsuarios(this.dashboardUsuarios);
    this.utilSessao.setLojas(this.lojas);
    this.utilSessao.setUsuarioContas(this.usuarioContas);
    this.utilSessao.setUsuarioMenus(this.usuarioMenus);
    this.utilSessao.setNomenclaturaEmpresas(this.nomenclaturas);
    this.utilSessao.setEmpresa(this.empresa);
    this.utilSessao.setUsuarioIntegracoes(this.usuarioIntegracoes);
  }

  setarLoginEmitter(): void {
    this.loginEmitter.definirIdUsuario(this.usuario.id);
    this.loginEmitter.logar(this.usuario, true);
    this.loginEmitter.permitir(this.permissoes);
    this.loginEmitter.modularizar(this.modulos);
    this.loginEmitter.modularizarEmpresa(this.moduloEmpresas);
  }
}
