import { ParceiroPropriedade } from "./parceiroPropriedade";

export class ParceiroDestino {
  public area: number;
  public alterado: boolean = false;
  public ativo: string = 'S';
  public bairro: string;
  public cep: string;
  public cidade: string;
  public cidadeIbge: string;
  public cnpjCpf: string;
  public complemento: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public ehValidoCnpjCpf: boolean = true;
  public endereco: string;
  public estado: string;
  public excluido: boolean = false;
  public ibge: number;
  public id: number;
  public idBairro: number;
  public idCidade: number;
  public idRegiao: number;
  public idEmpresa: number;
  public idEstado: number;
  public idNegociacao: number;
  public idPais: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public inscricaoEstadual: string;
  public latitude: string;
  public linhaSelecionada: boolean = false;
  public longitude: string;
  public nome: string;
  public numero: string;
  public observacao: string;
  public pais: string;
  public regiao: string;
  public tipo: string;
  public tipoPessoa: string = 'J';
  public uf: string;
  public parceiroPropriedades: ParceiroPropriedade[] = [];
}
