<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="14">
    <table class="tabela-complementar">
      <thead>
        <tr>          
          <th class="r-0-8 text-right">{{ bibDialogo.numero }}</th>
          <th class="r-1 text-right">{{ bibDialogo.valorCfop }}</th>
          <th class="r-1 text-right">{{ bibDialogo.baseIcms }}</th>
          <th class="r-1 text-right">{{ bibDialogo.icms }}</th>
          <th class="r-1 text-right">{{ bibDialogo.isentas }}</th>
          <th class="r-1 text-right">{{ bibDialogo.outras }}</th>
          <th class="r-1 text-right">{{ bibDialogo.icmsSt }}</th>
          <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>
          <th class="r-1 text-right">{{ bibDialogo.despesasAcessorias }}</th>
          <th class="r-1 text-right">{{ bibDialogo.basePisCofins }}</th>
          <th class="r-1 text-right">{{ bibDialogo.pis }}</th>
          <th class="r-1 text-right">{{ bibDialogo.cofins }}</th>
          <th class="r-1 text-right">{{ bibDialogo.ipi }}</th>
          <th class="r-0-5 text-right">{{ bibDialogo.loja }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos">
          <tr class="conteudo">
            <td class="text-right">
              <a href="#" (click)="abrirModalMovimentacao(movimentacaoProduto.idMovimentacao)">{{ movimentacaoProduto.movimentacaoDocumentoFiscal }}</a>
            </td>
            <td class="text-right">{{ movimentacaoProduto.valor | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.baseIcms | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorIcms | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.isentas | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.outras | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.icmsStValor | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.descontoValor | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.despesaAcessoria | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.basePis | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorPis | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorCofins | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorIpi | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.loja }}</td>
            <td></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
