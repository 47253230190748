export class CteDocumentoFiscal {
  public id: number;
  public idEmpresa: number;
  public idCte: number;
  public chave: string;
  public idUsuarioInclusao: number;
  public idUsuarioAlteracao: number;
  public dataHoraInclusao: Date;
  public dataHoraAlteracao: Date;
}
