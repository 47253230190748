import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.loja, campo: 'abreviacao' },
  { rotulo: bibDialogo.data, campo: 'data' },
  { rotulo: bibDialogo.clienteFornecedor, campo: 'parceiro' },
  { rotulo: bibDialogo.vendedor, campo: 'colaborador' },
  { rotulo: bibDialogo.operacao, campo: 'operacao' },
  { rotulo: bibDialogo.negociacao, campo: 'negociacao' },
  { rotulo: bibDialogo.situacao, campo: 'situacaoDescricao' },
  { rotulo: bibDialogo.naturezaOperacao, campo: 'naturezaOperacao' },
  { rotulo: bibDialogo.aVistaOuAPrazo, campo: 'modalidade' },
  { rotulo: bibDialogo.propriedade, campo: 'propriedade' },
];

export default grupos;
