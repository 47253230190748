import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Grupo } from 'src/app/modelo/grupo';
import { GrupoParceiroComissao } from 'src/app/modelo/grupoParceiroComissao';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoParceiroComissao } from 'src/app/modelo/produtoParceiroComissao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ProdutoParceiroComissaoComponent } from '../produtoParceiroComissao/produtoParceiroComissao.component';
import filtros from './filtro';

@Component({
  templateUrl: './produtoComissao.component.html',
})
export class ProdutoComissaoComponent extends PaginaComponent {
  private classe: any;
  private indiceObjetoModal: number;
  private params: any;
  private servico: string;
  public componente = null;
  public empresaRegraComissao: string;
  public filtros: Filtro[];
  public objetos: any[] = [];
  public parametro: string;
  public regraComisssao: number;

  ngOnInit(): void {
    this.regraComisssao = this.utilSessao.getEmpresa().regraComissao;
    this.setEmpresaComissao();
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.objetos = [];
      this.selecionarFiltro();
    });
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.produtoParceiroComissao) {
        this.adicionarProdutoParceiroComissoes(this.plainToClass(ProdutoParceiroComissao, identificacao.conteudo));
      }
      if (identificacao.nome == this.bibClasse.grupoParceiroComissao) {
        this.adicionarGrupoParceiroComissoes(this.plainToClass(GrupoParceiroComissao, identificacao.conteudo));
      }
    });
  }

  selecionarFiltro(): void {
    switch (this.parametro) {
      case 'G':
        this.filtros = filtros.grupoFiltros;
        this.servico = this.bibServico.grupo;
        this.classe = Grupo;
        break;
      case 'P':
        this.filtros = filtros.produtoFiltros;
        this.servico = this.bibServico.produto;
        this.classe = Produto;
        break;
      case 'DMP':
        this.filtros = filtros.produtoFiltros;
        this.servico = this.bibServico.produto;
        this.classe = Produto;
        break;
      case 'DMG':
        this.filtros = filtros.grupoFiltros;
        this.servico = this.bibServico.grupo;
        this.classe = Grupo;
        break;
    }
  }

  adicionarProdutoParceiroComissoes(produtoParceiroComissoes: ProdutoParceiroComissao[]): void {
    this.objetos[this.indiceObjetoModal].produtoParceiroComissoes = produtoParceiroComissoes;
    const produtoParceiroComissoesExcluir: ProdutoParceiroComissao[] = produtoParceiroComissoes.filter((produtoParceiroComissao) => (produtoParceiroComissao.percentual == 0 || produtoParceiroComissao.percentual == null) && (produtoParceiroComissao.percentualVd == 0 || produtoParceiroComissao.percentualVd == null) && produtoParceiroComissao.id > 0);
    this.objetos[this.indiceObjetoModal].idsProdutoParceiroComissaoExcluir = produtoParceiroComissoesExcluir.map((produtoParceiroComissao) => produtoParceiroComissao.id);
  }

  adicionarGrupoParceiroComissoes(grupoParceiroComissoes: GrupoParceiroComissao[]): void {
    this.objetos[this.indiceObjetoModal].grupoParceiroComissoes = grupoParceiroComissoes;
    const grupoParceiroComissoesExcluir: GrupoParceiroComissao[] = grupoParceiroComissoes.filter((grupoParceiroComissao) => (grupoParceiroComissao.percentual == 0 || grupoParceiroComissao.percentual == null) && (grupoParceiroComissao.percentualVd == 0 || grupoParceiroComissao.percentualVd == null) && grupoParceiroComissao.id > 0);
    this.objetos[this.indiceObjetoModal].idsGrupoParceiroComissaoExcluir = grupoParceiroComissoesExcluir.map((grupoParceiroComissao) => grupoParceiroComissao.id);
  }

  setEmpresaComissao(): void {
    switch (Number(this.regraComisssao)) {
      case 1:
        this.empresaRegraComissao = this.bibDialogo.nenhuma;
        break;
      case 2:
        this.empresaRegraComissao = this.bibDialogo.valorVenda;
        break;
      case 3:
        this.empresaRegraComissao = this.bibDialogo.valorLucro;
        break;
    }
  }

  ngOnDestroy(): void {
    this.params.unsubscribe();
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.servico).subscribe((res) => {
      this.objetos = this.plainToClass(this.classe, res) as any;
      if (this.classe == Grupo) {
        this.objetos = this.objetos.filter((grupo) => grupo.idEmpresa != null);
      }
    });
  }

  abrirModal(objeto: any, indiceObjeto: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', objeto));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', this.parametro));
    this.indiceObjetoModal = indiceObjeto;
    objeto.alterado = true;
    this.componente = ProdutoParceiroComissaoComponent;
  }

  persistirParceiroComissao(): void {
    this.objetos.forEach((objeto) => (objeto.nome = objeto.nome.trim()));
    super.persistir(new Transporte(this.objetos.filter((objeto) => objeto.alterado)), this.servico, null).subscribe((res) => {
      if (res[0].sucesso) {
        this.objetos.forEach((objeto) => (this.parametro == 'P' ? (objeto.produtoParceiroComissoes = []) : (objeto.grupoParceiroComissoes = [])));
      }
    });
  }

  fecharModal(): void {
    this.componente = null;
    this.indiceObjetoModal = null;
  }

  setComissaoPercentual(objeto: any, valorComissao: number): void {
    objeto.comissaoPercentual = valorComissao ? valorComissao : 0;
    objeto.alterado = true;
  }

  setComissaoPercentualVd(objeto: any, valorComissaoVd: number): void {
    objeto.comissaoPercentualVd = valorComissaoVd ? valorComissaoVd : 0;
    objeto.alterado = true;
  }

  setDescontoMaximo(objeto: any, descontoMaximo: number): void {
    objeto.descontoMaximo = descontoMaximo ? descontoMaximo : 0;
    objeto.alterado = true;
  }
}
