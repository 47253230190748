import { Type } from 'class-transformer';

export class ParceiroOcorrencia {
  @Type(() => Date)
  public data: Date = new Date();

  public alterado: boolean = false;
  public ativo: string = 'S';
  public excluido: boolean = false;
  public id: number;
  public idMotivoOcorrencia: number;
  public idParceiro: number;
  public motivoOcorrencia: string;
}
