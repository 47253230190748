<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.resumo + '  ' + menuFabricanteApelido }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r">{{ menuFabricanteApelido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroMes }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoFabricante of movimentacaoFabricantes">
      <tr>
        <td>{{ movimentacaoFabricante.loja }}</td>
        <td class="limitar">{{ movimentacaoFabricante.fabricante }}</td>
        <td class="text-right">{{ (movimentacaoFabricante.percentualDia | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoFabricante.valorTotalDia | monetario }}</td>
        <td class="text-right">{{ movimentacaoFabricante.valorLucroDia | monetario }}</td>
        <td class="text-right">{{ (movimentacaoFabricante.percentualLucroDia | monetario) + '%' }}</td>
        <td class="text-right">{{ (movimentacaoFabricante.percentualMes | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoFabricante.valorTotalMes | monetario }}</td>
        <td class="text-right">{{ movimentacaoFabricante.valorLucroMes | monetario }}</td>
        <td class="text-right">{{ (movimentacaoFabricante.percentualLucroMes | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoFabricantes.length > 0">
    <tr>
      <td colspan="3"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFabricantes[0].totalValorDia | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFabricantes[0].totalLucroDia | monetario }}</strong>
      </td>
      <td colspan="2"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFabricantes[0].totalValorMes | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoFabricantes[0].totalLucroMes | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
