<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-40 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.produto }}</th>
        <th class="l-15 text-right clique" (click)="listar(add('cfopEmpresaIdTransferencia'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cfop + ' ' + bibDialogo.transferencia }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('cfopEmpresaIdCfopEntrada'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cfop + ' ' + bibDialogo.entrada }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('cfopEmpresaIdCfopSaida'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cfop + ' ' + bibDialogo.saida }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('cstIpiEntrada'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.ipi + ' ' + bibDialogo.entrada }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('cstIpiSaida'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.ipi + ' ' + bibDialogo.saida }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <tr [ngClass]="{ tachado: produto.ativo != 'S' }">
          <td class="limitar">
            <a href="#" (click)="ir(produto.id, i, produtos)">{{ produto.numero + ' - ' + produto.nome }}</a>
          </td>
          <td class="text-right">{{ produto.cfopEmpresaIdCfopTransferencia }}</td>
          <td class="text-right">{{ produto.cfopEmpresaIdCfopEntrada }}</td>
          <td class="text-right">{{ produto.cfopEmpresaIdCfopSaida }}</td>
          <td class="text-right">{{ produto.cstIpiEntrada }}</td>
          <td class="text-right">{{ produto.cstIpiSaida }}</td>
          <td class="row">
            <btnAdicional [id]="'editar' + i" [icone]="bibIcone.editar" (btnAdicional)="ir(produto.id, i, produtos)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
