<ng-container class="border-bottom border-dark">
  <td class="p-0" colspan="9">
    <table class="tabela-complementar mb-3">
      <thead>
        <tr>
          <th class="r-0-5">{{ bibDialogo.loja }}</th>
          <th class="r-1-5">{{ bibDialogo.dataHora }}</th>
          <th class="r-1">{{ bibDialogo.numeroEntrega }}</th>
          <th class="r">{{ bibDialogo.responsalvelEntrega }}</th>
          <th class="r-1-5">{{ bibDialogo.quantidadeEntregueAbreviacao }}</th>
          <th class="r-4">{{ bibDialogo.observacao }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let entregaProduto of entregaProdutos; let i = index">
          <tr>
            <td>{{ entregaProduto.loja }}</td>
            <td>{{ entregaProduto.dataHoraSeparacao | data: 'dd/MM/yyyy HH:mm:ss' }}</td>
            <td>{{ entregaProduto.numero }}</td>
            <td class="limitar">{{ entregaProduto.responsavelNumero + ' - ' + entregaProduto.responsavel }}</td>
            <td>{{ entregaProduto.quantidade | monetario }}</td>
            <td class="limitar">{{ entregaProduto.observacao }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
