<layout [id]="patrimonial.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm-3" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="patrimonial.numero" (alteracao)="patrimonial.numero = $event" [obrigatorio]="true" [foco]="true"></texto>
    <data class="col-sm-3" [id]="'dataAquisicao'" [rotulo]="bibDialogo.dataAquisicao" [campo]="patrimonial.dataAquisicao" (alteracao)="patrimonial.dataAquisicao = $event" [obrigatorio]="empresaUsaContabilidade" [desabilitado]="patrimonial.patrimonialLancamentosContabeis.length > 0 ? true : false"></data>
    <texto class="col-sm-3" [id]="'numeroNotaFiscal'" [rotulo]="bibDialogo.notaFiscal" [campo]="patrimonial.numeroNotaFiscal" (alteracao)="patrimonial.numeroNotaFiscal = $event" [tipo]="bibPropriedade.texto.inteiro"></texto>
    <div class="col-sm"></div>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="patrimonial.ativo" (alteracao)="patrimonial.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="patrimonial.nome" (alteracao)="patrimonial.nome = $event" [obrigatorio]="true"></texto>
  </div>  
  <div class="row">
    <decimal class="col-sm-4" [id]="'valorAquisicao'" [rotulo]="bibDialogo.valorAquisicao" [campo]="patrimonial.valorAquisicao" (alteracao)="setValorAquisicao(patrimonial, $event)" [numeroCasasDecimais]="2" [obrigatorio]="true" [desabilitado]="patrimonial.patrimonialLancamentosContabeis.length > 0 ? true : false"></decimal>
    <decimal class="col-sm-4" [id]="'percentualDepreciacao'" [rotulo]="bibDialogo.percentualDepreciacao" [campo]="patrimonial.percentualDepreciacao" (alteracao)="patrimonial.percentualDepreciacao = $event" [numeroCasasDecimais]="2" [sufixo]="bibDialogo.porcentoPorAno" [obrigatorio]="true"></decimal>
    <decimal class="col-sm-4" [id]="'valorAtual'" [rotulo]="bibDialogo.valorAtual" [campo]="patrimonial.valorAtual" (alteracao)="patrimonial.valorAtual = $event" [numeroCasasDecimais]="2" [obrigatorio]="true" [desabilitado]="true"></decimal>    
  </div>
  <div *ngIf="empresaUsaContabilidade" class="row">    
    <busca class="col-sm-12" [rotulo]="bibDialogo.contaContabil" [campo]="patrimonial.contaContabil" (alteracao)="patrimonial.idContaContabil = $event.id" [servico]="bibServico.contaContabil" [buscarPorNumero]="true" [ajuda]="bibDialogo.contaContabilVincularDepreciacao" [ajudaDireita]="true" [desabilitado]="patrimonial.patrimonialLancamentosContabeis.length > 0 ? true : false"></busca>
  </div> 
  <div class="row">
    <lista class="col-sm" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="patrimonial.idLoja" (alteracao)="patrimonial.idLoja = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [icone]="bibIcone.atualizar"></lista>
  </div>
  <patrimonialLancamentoContabil [excluirPatrimonialLancamentosContabeis]="excluirPatrimonialLancamentosContabeis" [patrimonial]="patrimonial"></patrimonialLancamentoContabil>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ehPersistirPatrimonial()" [desabilitado]="bloquearPermissao(patrimonial.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="ehPersistirPatrimonial(true)" [desabilitado]="bloquearPermissao(patrimonial.id)"></botao>
<auditoria *ngIf="patrimonial.id" [idUsuarioInclusao]="patrimonial.idUsuarioInclusao" [dataHoraInclusao]="patrimonial.dataHoraInclusao" [idUsuarioAlteracao]="patrimonial.idUsuarioAlteracao" [dataHoraAlteracao]="patrimonial.dataHoraAlteracao"></auditoria>
