import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import bibIcone from '../biblioteca/bibIcone';
import { Grupo } from '../interface/grupo';
import { Ordem } from '../interface/ordem';
import { RelatorioGrupo } from '../interface/relatorioGrupo';
import { Total } from '../interface/total';
import { Criterio } from '../modelo/criterio';
import { Empresa } from '../modelo/empresa';
import { Filtro } from '../modelo/filtro';
import { Identificacao } from '../modelo/identificacao';
import { MenuEmpresa } from '../modelo/menuEmpresa';
import { Nomenclatura } from '../modelo/nomenclatura';
import { Relatorio } from '../modelo/relatorio';
import { RelatorioAdicional } from '../modelo/relatorioAdicional';
import { RelatorioAdicionalEmpresa } from '../modelo/relatorioAdicionalEmpresa';
import { RelatorioResultado } from '../modelo/relatorioResultado';
import { Transporte } from '../modelo/transporte';
import { ComunicacaoService } from '../servico/comunicacao.service';
import { Util } from '../utilitario/util';
import { UtilNotificacao } from '../utilitario/util.notificacao';
import { UtilSessao } from '../utilitario/util.sessao';

@Component({
  template: '',
})
export class RelatorioComponent {
  private relatorioId: number;
  private cliqueOrdenacao: number = -1;
  public nomeCampoOrdem: string;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibPropriedade = bibPropriedade;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public bibServico = bibServico;
  public ehAgrupadoOcultado: boolean = false;
  public ehModoClaro: boolean;
  public empresa: Empresa;
  public empresaRegraCasasDecimais: number;
  public empresaRegraCasasDecimaisEntrada: number;
  public filtros: Filtro[] = [];
  public finalizado: boolean = false;
  public grupos: Grupo[] = [];
  public impressaoRelatorio: boolean = false;
  public menuGrupo: MenuEmpresa;
  public menuNegociacao: MenuEmpresa;
  public menuProduto: MenuEmpresa;
  public menuFabricante: MenuEmpresa;
  public ordens: Ordem[] = [];
  public plainToClass = plainToClass;
  public relatorio: Relatorio;
  public relatorioAdicionais: RelatorioAdicional[] = [];
  public relatorioGrupo: RelatorioGrupo;
  public relatorioIdOriginal: number;
  public relatorioResultados: RelatorioResultado[] = [];
  public util: Util = new Util();
  public idRelatorioInterno: number = null;
  public subTitulo: string;
  public criterios: Criterio[];
  public cores: string[] = ['#4BC0C0', '#36A2EB', '#FFCD56', '#FF6384', '#C9CBCF', '#FFDAB9', '#D8BFD8', '#EEE8AA', '#DEB887', '#9966FF'];
  public excel: string;
  public rotuloAgrupaAtual: string = '';
  public modal: boolean;

  constructor(public comunicacaoService: ComunicacaoService, public activatedRoute: ActivatedRoute, public sanitizer: DomSanitizer, public utilSessao: UtilSessao, public utilNotificacao: UtilNotificacao) {
    this.idRelatorioInterno = utilSessao.idRelatorioInterno;
    this.ehModoClaro = utilSessao.getUsuario().usaModoClaro == 'S';
    this.empresaRegraCasasDecimais = utilSessao.getEmpresa().regraCasasDecimais;
    this.empresaRegraCasasDecimaisEntrada = utilSessao.getEmpresa().regraCasasDecimaisEntrada;
    this.menuGrupo = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 11);
    this.menuNegociacao = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 71);
    this.menuProduto = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 7);
    this.menuFabricante = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 9);
    this.inicializar();
  }

  inicializar(): void {
    if (this.idRelatorioInterno == null) {
      this.utilNotificacao.usarRelatorio();
    }
    this.lerParametro();
    this.listarRelatorioAdicional();
    this.carregarRelatorioInformacao();
    this.excel = this.utilSessao.getRelatorioUsuarios().find((relatorioUsuario) => relatorioUsuario.idRelatorio == this.relatorio.id && relatorioUsuario.idUsuario == this.utilSessao.getUsuario().id)?.excel;
  }

  lerParametro(): void {
    if (this.idRelatorioInterno == null) {
      this.activatedRoute.params.subscribe((params) => {
        this.impressaoRelatorio = params.impressaoDireta == 'S';
        this.relatorioId = params.idRelatorio;
        this.lerParametroAdicional();
      });
    } else {
      this.relatorioId = this.idRelatorioInterno;
      this.lerParametroAdicional();
    }
  }

  lerParametroAdicional() {
    const relatorio = this.utilSessao.getRelatorios().find((relatorioBusca) => relatorioBusca.id == this.relatorioId);
    if (relatorio && relatorio.idRelatorioReferencia) {
      this.relatorioIdOriginal = relatorio.id;
      this.relatorioId = relatorio.idRelatorioReferencia;
    }
  }

  listar(criterios: Criterio[], realizaPlainToClass: boolean = true): Observable<any> {
    if (this.validarExigeCriterio(criterios)) {
      this.criterios = criterios;
      if (criterios.length > 0) {
        this.finalizado = false;
        this.lerParametro();
        criterios.push(new Criterio('ID_RELATORIO', this.relatorioId));
        const resposta = this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.relatorioResultado, false, true, this.relatorio.dashboard == 'N' ? true : false, true, 900000);
        resposta.subscribe((res) => {
          let relatorioResultados: any;
          if (realizaPlainToClass) {
            relatorioResultados = this.plainToClass(RelatorioResultado, res) as any;
          } else {
            relatorioResultados = res;
          }
          for (const relatorioResultado of relatorioResultados) {
            let relatorioResultadoDesejado: RelatorioResultado = this.relatorioResultados.find((relatorioResultadoDesejado) => relatorioResultadoDesejado.dado == relatorioResultado.dado);
            if (relatorioResultadoDesejado != null) {
              relatorioResultadoDesejado.lista = relatorioResultado.lista;
            } else {
              this.relatorioResultados.push(relatorioResultado);
            }
          }
          this.utilNotificacao.dataAtualEmitter.emit(res[0].dataAtual);
          this.finalizado = true;
        });
        return resposta;
      } else {
        return this.limparDadoAnterior();
      }
    }
    return of({});
  }

  validarExigeCriterio(criterios: Criterio[]): boolean {
    const atencaoIdRelatorios: number[] = [1128];
    if (atencaoIdRelatorios.includes(this.relatorioId)) {
      if (criterios.length == 0 || criterios.filter((criterio) => criterio.nome != 'ID_COLABORADOR' && criterio.nome != 'ID_RELATORIO' && criterio.valor != null).length < 1) {
        return false;
      }
    }
    return true;
  }

  limparDadoAnterior(): Observable<any> {
    this.relatorioResultados.forEach((relatorioResultado) => {
      relatorioResultado.lista = [];
    });
    return of({});
  }

  listarRelatorioAdicional(): void {
    if (this.utilSessao.getRelatorioAdicionais()) {
      this.relatorioAdicionais = plainToClass(
        RelatorioAdicional,
        this.utilSessao.getRelatorioAdicionais().filter((relatorioAdicional) => relatorioAdicional.idRelatorio == (this.relatorioIdOriginal ? this.relatorioIdOriginal : this.relatorioId) || relatorioAdicional.id < 100)
      );
    }
    const apenasAdicionaisSistema: boolean = this.relatorioAdicionais.find((relatorioAdicional) => relatorioAdicional.id > 100) ? false : true;
    if (this.relatorioAdicionais.length == 0 || apenasAdicionaisSistema == true) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_RELATORIO', this.relatorioIdOriginal ? this.relatorioIdOriginal : this.relatorioId)), this.bibServico.relatorioAdicional).subscribe((res) => {
        this.vincularRelatorioAdicionalEmpresas(plainToClass(RelatorioAdicional, res) as any);
      });
    }
  }

  carregarRelatorioInformacao(): void {
    this.empresa = plainToClass(Empresa, this.utilSessao.getEmpresa());
    this.relatorio = plainToClass(
      Relatorio,
      this.utilSessao.getRelatorios().find((relatorio) => relatorio.id == (this.relatorioIdOriginal ? this.relatorioIdOriginal : this.relatorioId))
    );
  }

  vincularRelatorioAdicionalEmpresas(relatorioAdicionais: RelatorioAdicional[]): void {
    this.relatorioAdicionalNomenclatura(relatorioAdicionais);
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_RELATORIO', this.relatorioIdOriginal ? this.relatorioIdOriginal : this.relatorioId)), this.bibServico.relatorioAdicionalEmpresa, false, true, this.relatorio.dashboard == 'S' ? false : true, true, 300000).subscribe((res) => {
      const relatorioAdicionalEmpresas: RelatorioAdicionalEmpresa[] = plainToClass(RelatorioAdicionalEmpresa, res) as any;
      relatorioAdicionalEmpresas.forEach((relatorioAdicionalEmpresa) => {
        let relatorioAdicional: RelatorioAdicional = relatorioAdicionais.find((relatorioAdicionalBusca) => relatorioAdicionalBusca.id == relatorioAdicionalEmpresa.idRelatorioAdicional);
        if (relatorioAdicional) {
          relatorioAdicional.oculto = relatorioAdicionalEmpresa.oculto;
          relatorioAdicional.marcado = relatorioAdicionalEmpresa.marcado;
          relatorioAdicional.usaRelatorioAdicionalEmpresa = true;
        }
      });
      relatorioAdicionais.sort((a, b) => (a['id'] == b['id'] ? 0 : a['id'] > b['id'] ? 1 : -1));
      this.utilSessao.setRelatorioAdicionais(relatorioAdicionais);
      this.relatorioAdicionais = relatorioAdicionais;
    });
  }

  relatorioAdicionalNomenclatura(relatorioAdicionais: RelatorioAdicional[]): void {
    relatorioAdicionais.forEach((relatorio) => {
      let nomenclatura: Nomenclatura = this.utilSessao.getNomenclaturaEmpresas().find((nomenclatura) => relatorio.nome.indexOf(nomenclatura.nome.toUpperCase()) > -1);
      if (nomenclatura) {
        relatorio.nome = relatorio.nome.replace(nomenclatura.nome.toUpperCase(), this.bibNomenclatura.escrever(nomenclatura.id)).toUpperCase();
      }
    });
  }

  setRetrato(): void {
    this.utilNotificacao.relatorioAdicionalEmitter.emit(new RelatorioAdicional(1, 'RETRATO', 'N'));
  }

  setPaisagem(): void {
    this.utilNotificacao.relatorioAdicionalEmitter.emit(new RelatorioAdicional(1, 'RETRATO', 'N'));
  }

  ordenar(lista: any[], nomeCampoOrdem: string): void {
    this.nomeCampoOrdem = nomeCampoOrdem;
    this.ordenarListas(lista, this.relatorioGrupo.nomeCampoGrupo ? this.relatorioGrupo.nomeCampoGrupo : this.nomeCampoOrdem, this.nomeCampoOrdem, (this.relatorioGrupo.nomeCampoGrupo ? true : false));
  }

  agrupar(lista: any[], agrupa: any, ordemCrescente: boolean = true): void {
    let nomeCampoGrupo: string = null;
    if (agrupa) {
      nomeCampoGrupo = agrupa.campo;
      this.rotuloAgrupaAtual = agrupa.rotulo;
    } else {
      this.rotuloAgrupaAtual = '';
    }
    this.relatorioGrupo.nomeCampoGrupo = null;
    this.ordenarListas(lista, agrupa && agrupa.campoOrdenacao ? agrupa.campoOrdenacao : nomeCampoGrupo ? nomeCampoGrupo : this.nomeCampoOrdem, this.nomeCampoOrdem, ordemCrescente);
    this.totalizar(lista, nomeCampoGrupo);
    setTimeout((_) => (this.relatorioGrupo.nomeCampoGrupo = nomeCampoGrupo));
    if (nomeCampoGrupo == null && this.ehAgrupadoOcultado) {
      this.agruparTotalizador();
    }
  }

  agruparTotalizador(): void {
    let elementos: any = document.querySelectorAll('.conteudo');
    elementos.forEach((elemento) => {
      elemento.style.display = elemento.style.display != 'none' ? 'none' : '';
    });
    this.ehAgrupadoOcultado = this.ehAgrupadoOcultado == false ? true : false;
    this.esconderTituloSemTotalizador();
    this.utilNotificacao.setEhAgrupaOcultado(this.ehAgrupadoOcultado);
  }

  esconderTituloSemTotalizador(): void {
    setTimeout((_) => {
      let thTitulos: any = document.querySelectorAll('.relatorio-tabela thead th');
      thTitulos.forEach((th, i) => {
        if (this.relatorioGrupo.totais.find((total) => total.mostrar && (total.posicaoNova ? total.posicaoNova : total.posicao) == i + 1 && total.posicao != 1) == undefined) {
          th.style.color = this.ehAgrupadoOcultado ? '#004080' : 'white';
        }
      });
    });
  }

  ordenarAgrupar(lista: any[]): void {
    if (this.relatorioGrupo.nomeCampoGrupo) {
      this.agrupar(lista, this.relatorioGrupo.nomeCampoGrupo);
    } else {
      if (this.nomeCampoOrdem) {
        this.ordenar(lista, this.nomeCampoOrdem);
      } else {
        this.utilSessao.iniciarProcesso();
        this.relatorioGrupo.lista = lista;
        if (this.relatorioGrupo.totais.length > 0) {
          this.totalizar(lista, this.relatorioGrupo.nomeCampoGrupo);
        } else {
          this.criarTotalizador(lista, this.relatorioGrupo.nomeCampoGrupo);
        }
        this.utilSessao.finalizarProcesso();
      }
    }
  }

  zerarTotalizador(): void {
    for (let total of this.relatorioGrupo.totais) {
      total.total = 0;
      total.ultimaPosicao = 0;
    }
    this.relatorioGrupo.somaResultados = [];
  }

  ordenarListas(lista: any[], campo: string, campoSubOrdem: string, ordemCrescente: boolean = false): void {
    if (ordemCrescente == false) {
      this.cliqueOrdenacao = this.cliqueOrdenacao * -1;
    } else {
      this.cliqueOrdenacao = 1;
    }
    if (lista) {
      lista.sort((a, b) => (this.substituirValor(a[`${campo}`]) == this.substituirValor(b[`${campo}`]) ? (this.substituirValor(a[`${campoSubOrdem}`]) == this.substituirValor(b[`${campoSubOrdem}`]) ? 0 : this.substituirValor(a[`${campoSubOrdem}`]) > this.substituirValor(b[`${campoSubOrdem}`]) ? 1 : -1) : this.substituirValor(a[`${campo}`]) > this.substituirValor(b[`${campo}`]) ? 1 * this.cliqueOrdenacao : -1 * this.cliqueOrdenacao));
    }
    this.relatorioGrupo.lista = lista;
  }

  substituirValor(valor: any): any {
    valor = this.verificarValorTipoData(valor);
    if (valor) {
      return valor;
    } else {
      return '';
    }
  }

  verificarValorTipoData(valor: any): any {
    if (valor && valor.length > 0) {
      const valores: string[] = valor.split(' ');
      if (['Jan', 'Fev', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].includes(valores[0]) && ['AM', 'PM'].includes(valores[valores.length - 1])) {
        valor = Date.parse(valor);
      }
    }
    return valor;
  }

  totalizar(lista: any[], campo: string = null): void {
    if (this.relatorioGrupo.totais && lista && lista.length > 0) {
      this.zerarTotalizador();
      if (this.relatorioGrupo.totais[0].campo != null) {
        const relatorioTotal: any = { campo: null, posicao: 1, mostrar: true };
        this.relatorioGrupo.totais.push(relatorioTotal);
      }
      this.ordenarPosicaoAtualizarPosicoes(lista, campo);
    } else {
      this.ehZerarTotaisSomaResultados();
    }
  }

  criarTotalizador(lista: any[], campo: string = null): void {
    if (this.relatorioGrupo.totais && lista && lista.length > 0) {
      this.zerarTotalizador();
      if (this.relatorioGrupo.totais && this.relatorioGrupo.totais[0] == null) {
        const relatorioTotal: any = { campo: null, posicao: 1, mostrar: true };
        this.relatorioGrupo.totais.push(relatorioTotal);
      }
      this.ordenarPosicaoAtualizarPosicoes(lista, campo);
    } else {
      this.ehZerarTotaisSomaResultados();
    }
  }

  ordenarPosicaoAtualizarPosicoes(lista: any[], campo: string = null): void {
    this.relatorioGrupo.totais.sort((a, b) => (a['posicao'] == b['posicao'] ? 0 : a['posicao'] > b['posicao'] ? 1 : -1));
    this.ehUltimaPosicao();
    this.totalizarPosicoes(lista, campo);
  }

  ehZerarTotaisSomaResultados(): void {
    if (this.relatorioGrupo.totais) {
      this.relatorioGrupo.totais.forEach((total) => (total.total = 0));
      this.relatorioGrupo.somaResultados = [];
    }
  }

  ehUltimaPosicao(): void {
    const ultimaPosicao: number = this.relatorioGrupo.totais[this.relatorioGrupo.totais.length - 1].posicao;
    let linhas: number[] = [];
    for (let i = 1; i <= ultimaPosicao; i++) {
      linhas.push(i);
    }
    this.relatorioGrupo.linhas = linhas;
  }

  totalizarPosicoes(lista: any[], campo: string): void {
    for (let i = 0; i < lista.length; i++) {
      if (lista[i]) {
        for (let total of this.relatorioGrupo.totais) {
          total.total = 0;
        }
        if (lista[i][campo] != (lista[i + 1] ? lista[i + 1][campo] : '')) {
          for (let j = this.relatorioGrupo.totais[0].ultimaPosicao ? this.relatorioGrupo.totais[0].ultimaPosicao : 0; j <= i; j++) {
            for (let total of this.relatorioGrupo.totais) {
              if (total.tipo != 'expressao') {
                if (total.campo == null) {
                  total.total += 1;
                } else {
                  switch (total.tipo) {
                    case 'valorMaximo':
                      total.total = lista[j][total.campo] > (total.total ? total.total : 0) ? lista[j][total.campo] : total.total;
                      break;
                    case 'repetido':
                      total.total = lista[j - 1] && lista[j][total.campo] == lista[j - 1][total.campo] ? lista[j][total.campo] : null;
                      break;
                    case 'ultimoValor':
                      total.total = lista[j][total.campo];
                      break;
                    default:
                      total.total += lista[j][total.campo];
                      break;
                  }
                }
              }
              total.ultimaPosicao = j + 1;
            }
          }
        }
      }
      this.realizarCalculoExpressao();
      this.setTotais(this.relatorioGrupo.totais, i, lista.length);
    }
  }

  realizarCalculoExpressao(): void {
    for (let total of this.relatorioGrupo.totais) {
      if (total.tipo == 'expressao') {
        const expressao: string = total.campo;
        let parametros = this.separarParametrosExpressao(expressao);
        let numero1: number = 0;
        let numero2: number = 0;
        let operador: string = '';
        numero1 = Number(this.relatorioGrupo.totais.find((total) => total.campo == parametros[0]).total);
        for (let i = 1; i < parametros.length; i++) {
          operador = parametros[i];
          const total: Total = this.relatorioGrupo.totais.find((total) => total.campo == parametros[i + 1]);
          if (total) {
            numero2 = Number(total.total);
          } else {
            numero2 = Number(parametros[i + 1]);
          }
          i += 1;
          switch (operador) {
            case '/':
              numero1 = numero1 == 0 || numero2 == 0 ? 0 : numero1 / numero2;
              break;
            case '*':
              numero1 = numero1 == 0 || numero2 == 0 ? 0 : numero1 * numero2;
              break;
            case '+':
              numero1 = numero1 + numero2;
              break;
            case '-':
              numero1 = numero1 - numero2;
              break;
          }
        }
        total.total = numero1;
      }
    }
  }

  separarParametrosExpressao(expressao: string): string[] {
    const operadores: string[] = ['/', '*', '+', '-'];
    let parametros: string[] = [];
    let campo: string = '';
    for (let i = 0; i < expressao.length; i++) {
      if (operadores.includes(expressao[i])) {
        parametros.push(campo);
        parametros.push(expressao[i]);
        campo = '';
      } else {
        campo += expressao[i];
      }
    }
    parametros.push(campo);
    return parametros;
  }

  setTotais(totais: Total[], i: number, contagem: number): void {
    this.relatorioGrupo.totais = totais.sort((a, b) => (a['posicao'] == b['posicao'] ? 0 : a['posicao'] > b['posicao'] ? 1 : -1));
    this.mostrarTotais(totais, i);
    this.totalizarRodape(contagem);
    this.relatorioGrupo.totais = totais;
  }

  mostrarTotais(totais: Total[], i: number): void {
    const ultimaPosicaoTotal: number = totais[totais.length - 1].posicao;
    for (let j = this.relatorioGrupo.somaResultados.length; j <= i; j++) {
      if (j == i) {
        let totaisLinha: any[] = [];
        for (let k = 0; k <= ultimaPosicaoTotal; k++) {
          let colunaEmBranco: boolean = true;
          for (const total of totais) {
            let mostrarTotal = true;
            if (total.mostrar != null) {
              mostrarTotal = total.mostrar;
            }
            total.mostrar = mostrarTotal;
            if (total.posicao == k) {
              totaisLinha[k] = total.total;
              colunaEmBranco = false;
            }
          }
          if (colunaEmBranco) {
            totaisLinha[k] = null;
          }
        }
        this.relatorioGrupo.somaResultados.push(totaisLinha);
      } else {
        this.relatorioGrupo.somaResultados.push(null);
      }
    }
  }

  totalizarRodape(contagem: number): void {
    let totalRodape: any[] = [];
    if (this.relatorioGrupo.somaResultados.length == contagem) {
      for (let i = 0; i < this.relatorioGrupo.somaResultados.length; i++) {
        if (this.relatorioGrupo.somaResultados[i] != null) {
          for (let propriedade in this.relatorioGrupo.somaResultados[i]) {
            if (this.relatorioGrupo.somaResultados[i][propriedade]) {
              if (totalRodape[propriedade]) {
                const total: Total = this.relatorioGrupo.totais.find((totalBusca) => totalBusca.posicao == Number(propriedade));
                if (total ? total.tipo != 'valorMaximo' : true) {
                  if (total.tipo == 'ultimoValor') {
                    totalRodape[propriedade] = this.relatorioGrupo.somaResultados[i][propriedade];
                  } else {
                    totalRodape[propriedade] += this.relatorioGrupo.somaResultados[i][propriedade];
                  }
                } else {
                  totalRodape[propriedade] = this.relatorioGrupo.somaResultados[i][propriedade] > totalRodape[propriedade] ? this.relatorioGrupo.somaResultados[i][propriedade] : totalRodape[propriedade];
                }
              } else {
                totalRodape[propriedade] = this.relatorioGrupo.somaResultados[i][propriedade];
              }
            }
          }
        }
      }
      this.relatorioGrupo.somaResultados.push(totalRodape);
    }
  }

  definirRegra(relatorioAdicional: RelatorioAdicional, idRelatorioAdicionalDesejado: number, valorOriginal: boolean): boolean {
    if (relatorioAdicional.id == idRelatorioAdicionalDesejado) {
      return relatorioAdicional.marcado == 'S';
    } else {
      return valorOriginal;
    }
  }

  posicionarTotalizador(campo: string, mostra: boolean, incremento: number = 0): void {
    for (let i = 0; i < this.relatorioGrupo.totais.length; i++) {
      if (this.relatorioGrupo.totais[i].campo == campo) {
        this.relatorioGrupo.totais[i].mostrar = mostra;
        this.relatorioGrupo.totais[i].posicaoNova = this.relatorioGrupo.totais[i].posicao + incremento;
        i = this.relatorioGrupo.totais.length;
      }
    }
    this.atualizarLinhas();
    this.esconderTituloSemTotalizador();
  }

  atualizarLinhas(): void {
    let maiorNumero: number = 0;
    this.relatorioGrupo.totais.forEach((total) => {
      maiorNumero = total.posicaoNova > maiorNumero ? total.posicaoNova : maiorNumero;
    });
    this.relatorioGrupo.linhas = [];
    for (let i = 1; i <= maiorNumero; i++) {
      this.relatorioGrupo.linhas.push(i);
    }
  }

  ehImprimirRelatorio(): void {
    // if (this.impressaoRelatorio && this.finalizado) {
    //    window.print();
    // }
  }

  public atualizarCriterio(criterio: Criterio): void {
    let criterioDesejado: Criterio = this.criterios.find((criterioDesejado) => criterioDesejado.nome == criterio.nome);
    if (criterioDesejado != null) {
      criterioDesejado.valor = criterio.valor;
    } else {
      this.criterios.push(criterio);
    }
  }

  public limparCriterioIdRelatorio() {
    const criterios: Criterio[] = this.criterios.filter((criterio) => criterio.nome != 'ID_RELATORIO');
    this.criterios = criterios;
  }

  listarEspecifico(criterio: Criterio, idRelatorioDadoEspecifico: number): Observable<any> {
    if (criterio != null) {
      this.atualizarCriterio(criterio);
    }
    this.atualizarCriterio(new Criterio('ID_RELATORIO_DADO_ESPECIFICO', idRelatorioDadoEspecifico));
    this.limparCriterioIdRelatorio();
    return this.listar(this.criterios);
  }

  abrirModalRelatorio(tela: any, identificacoes: Identificacao[], idMenu: number): any {
    this.modal = true;
    this.utilNotificacao.setModalRelatorioEmitter(true);
    return this.util.abrirModal(tela, identificacoes, idMenu);
  }

  posicionarTotalizadores(incremento: number): void {
    for (const total of this.relatorioGrupo.totais) {
      this.posicionarTotalizador(total.campo, true, incremento);
    }
  }
}
