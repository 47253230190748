import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { EntregaProduto } from 'src/app/modelo/entregaProduto';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Filtro } from 'src/app/modelo/filtro';
import filtros from './filtro';

@Component({
  templateUrl: './entregas.component.html',
})
export class EntregasComponent extends PaginaComponent {
  public entregaProdutos: EntregaProduto[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.entregaProduto).subscribe((res) => {
      this.entregaProdutos = this.plainToClass(EntregaProduto, res) as any;
    });
  }

  estornar(): void {
    if (confirm(bibDialogo.certezaEstornarEntrega)) {
      let idsExcluir: number[] = [];
      this.entregaProdutos.forEach((entregaProduto) => {
        entregaProduto.selecionado == 'S' ? idsExcluir.push(entregaProduto.id) : null;
      });
      this.excluirLista(idsExcluir, this.bibServico.entregaProduto).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.entregaProdutos = this.entregaProdutos.filter((entregaProduto) => entregaProduto.selecionado != 'S');
        }
      });
    }
  }
}
