import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtroComboLimiteCredito: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'COM LIMITE', id: '>' },
  { nome: 'SEM LIMITE', id: '=' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numeroClienteAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'cpfCnpj', rotulo: bibDialogo.cpf, nome: 'CNPJ_CPF', coluna: 6, servicoWeb: bibServico.cpf, tipo: bibPropriedade.filtro.mascara },
  { id: 'limiteDisponivel', rotulo: bibDialogo.limiteDisponivel, nome: 'LIMITE_DISPONIVEL', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboLimiteCredito }, { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
]);
export default filtros;
