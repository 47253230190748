<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('abreviacaoLoja'), this.utilSessao.posicao, this.paginacao, bibDialogo.loja)">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao, bibDialogo.numero)">{{ bibDialogo.numero }}</th>
        <th class="l-45 clique" (click)="listar(add('razaoSocial'), this.utilSessao.posicao, this.paginacao, bibDialogo.nome)">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
        <th class="l-25 clique" (click)="listar(add('cnpjCpf'), this.utilSessao.posicao, this.paginacao, bibDialogo.cpfCnpj)">{{ bibDialogo.cpfCnpj }}</th>
        <th class="l-25 clique" (click)="listar(add('relacionamento'), this.utilSessao.posicao, this.paginacao, bibDialogo.apelido)">{{ menuRelacionamento.apelido }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiro of parceiros; let i = index">
        <tr [ngClass]="{ tachado: parceiro.ativo != 'S' }">
          <td>{{ parceiro.abreviacaoLoja }}</td>
          <td class="text-right">{{ parceiro.numero }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(parceiro.id, i, parceiros)">{{ parceiro.razaoSocial }}</a>
          </td>
          <td><ajuda [ajuda]="parceiro.nomeFantasia"></ajuda></td>
          <td>{{ parceiro.cnpjCpf }}</td>
          <td>{{ parceiro.relacionamento }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(parceiro.id, i, parceiros)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirParceiro(parceiro.id, parceiro.razaoSocial)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
