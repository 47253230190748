<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">  
  <div class="row justify-content-between">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
    <div *ngIf="dataAtualizacao" class="justify-content-end fonte-menor">
      <span class="oi oi-media-record pulse mr-1 text-success "></span>
      <span class="text-uppercase">{{ bibDialogo.atualizadoEm + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm') }} </span>
    </div>  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-20 clique" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>        
        <th class="l-10 clique" (click)="listar(add('dataInicio'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataDe }}</th>
        <th class="l-10 clique" (click)="listar(add('dataFim'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataAte }}</th>
        <th class="clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.clienteFornecedor }}</th>
        <th class="l-10 clique">{{ bibDialogo.situacao }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let nfexmlpdf of nfexmlpdfs; let i = index">
        <td class="limitar">{{ nfexmlpdf.loja }}</td>
        <td>{{ nfexmlpdf.dataInicio | data }}</td>
        <td>{{ nfexmlpdf.dataFim | data }}</td>   
        <td class="limitar fonte-menor">
          <a href="#" (click)="ir(nfexmlpdf.id, i, nfexmlpdfs)">{{ nfexmlpdf.idParceiro ? nfexmlpdf.parceiro : bibDialogo.todos.toUpperCase() }}</a>
        </td>    
        <td class="limitar fonte-menor">
          <span class="badge badge-subtle" [ngClass]="nfexmlpdf.situacao == 1 ? 'badge-warning' : nfexmlpdf.situacao == 2 ? 'badge-success' : nfexmlpdf.situacao == 3 ? 'badge-dark': 'badge-danger'">{{ nfexmlpdf.situacao == 1 ? bibDialogo.emAndamento.toUpperCase() : nfexmlpdf.situacao == 2 ? bibDialogo.concluido : nfexmlpdf.situacao == 3 ? bibDialogo.nao + ' ' + bibDialogo.encontrado.toLowerCase(): bibDialogo.falha }}</span>
        </td>            
        <td>
          <btnAdicional *ngIf="nfexmlpdf.idEmpresa" [icone]="bibIcone.olho" (btnAdicional)="ir(nfexmlpdf.id, i, nfexmlpdfs)"></btnAdicional>
        </td>
        <td>
          <btnAdicional *ngIf="nfexmlpdf.tipo == 1 && nfexmlpdf.situacao == 2" [icone]="bibIcone.xml" [ajuda]="bibDialogo.xml" (btnAdicional)="this.utilNfeXmlPdf.baixar(nfexmlpdf)"></btnAdicional>
          <btnAdicional *ngIf="nfexmlpdf.tipo == 2 && nfexmlpdf.situacao == 2" [icone]="bibIcone.pdf" [ajuda]="bibDialogo.pdf" (btnAdicional)="this.utilNfeXmlPdf.baixar(nfexmlpdf)"></btnAdicional>
          <btnAdicional *ngIf="nfexmlpdf.tipo == 3 && nfexmlpdf.situacao == 2" [icone]="bibIcone.arquivo" [ajuda]="bibDialogo.xml + ' e ' + bibDialogo.pdf" (btnAdicional)="this.utilNfeXmlPdf.baixar(nfexmlpdf)"></btnAdicional>
        </td>
      </tr>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>