<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r-1-5">{{ bibDialogo.responsavel }}</th>
        <th class="r">{{ bibDialogo.dataHoraAbertura }}</th>
        <th class="r">{{ bibDialogo.dataHoraFechamento }}</th>
        <th class="r">{{ bibDialogo.situacao }}</th>
        <th class="r-1-5"></th>    
        <th class="r-1-5 text-right">{{ bibDialogo.saldoAtual }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="conciliacoes.length > 0">
        <tr>   
          <td> {{caixa.usuario}} </td>  
          <td> {{caixa.dataHoraAbertura}} </td>
          <td> {{caixa.dataHoraFechamento}} </td>
          <td><atencao [atencao]="caixa.dataHoraFechamento ? bibDialogo.fechado : bibDialogo.aberto" [tipo]="caixa.dataHoraFechamento ? bibPropriedade.atencao.cuidado : bibPropriedade.atencao.sucesso"></atencao></td>
          <td class="text-right">{{ bibDialogo.saldoInicial }}</td>  
          <td class="text-right">{{ caixa.saldoAnterior | monetario }}</td>
        </tr>
        <tr>
          <table  class="tabela-complementar">
            <thead>
              <tr>
                <th class="r-3">{{ bibDialogo.dataHora }}</th>
                <th class="r-3 text-right">{{ bibDialogo.tipoTitulo }}</th>    
                <th class="r-2-5 text-right">{{ bibDialogo.receita }}</th>
                <th class="r-2-5 text-right">{{ bibDialogo.despesa }}</th>        
                <th class="text-right saldo-atual">{{ bibDialogo.saldoAtual }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let conciliacao of conciliacoes; let i = index">
                <tr>      
                  <td>{{ conciliacao.dataHoraInclusao }}</td>     
                  <td class="text-right">{{ conciliacao.tipoTitulo }}</td>    
                  <td class="text-right">{{ conciliacao.receita | monetario  }}</td>
                  <td class="text-right">{{ conciliacao.despesa | monetario  }}</td>   
                  <td class="text-right">{{ conciliacao.saldo | monetario  }}</td>            
                </tr>          
              </ng-container>
            </tbody>
          </table>
        </tr>         
      </ng-container>
    </tbody>
  </table>
 <div class="mt-5">
  <strong> {{ bibDialogo.totaisPorTipoDeTitulo}} </strong> <br>
  <ng-container *ngFor="let conciliacaoTipoTitulo of conciliacoesAgrupadasTipoTitulos; let i = index">
    <strong>{{ conciliacaoTipoTitulo.tipoTitulo }}: {{ conciliacaoTipoTitulo.valor | monetario }}</strong> <br>
  </ng-container>
 </div>
</relatorioLayout>

