<td class="p-0" colspan="5">
  <table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.logradouro }}</th>
        <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
        <th class="r-2">{{ rotuloBairro }}</th>
        <th class="r-1">{{ rotuloCidade }}</th>
        <th class="r-1">{{ bibDialogo.cep }}</th>
        <th class="r-1">{{ bibDialogo.vinculo }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiroEndereco of parceiro.parceiroEnderecos">
        <tr>
          <td>{{ parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco }}</td>
          <td class="text-right">{{ parceiroEndereco.numero }}</td>
          <td>{{ parceiroEndereco.bairro }}</td>
          <td>{{ parceiroEndereco.cidade }}</td>
          <td>{{ parceiroEndereco.cep }}</td>
          <td>{{ parceiroEndereco.vinculo }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</td>
