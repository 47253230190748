<div class="row align-items-center">
  <titulo class="col-sm" [titulo]="bibDialogo.indicacoes" [imagem]="bibImagem.produto" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [desabilitado]="bloquearPermissao(produtoFormulado.id)"></titulo>
</div>
<div class="row form-group naoImprimir">
  <lista [campo]="campoPesquisa" [lista]="listaCampoPesquisa" (alteracao)="campoPesquisa = $event.id" [obrigatorio]="true"></lista>
  <texto [desabilitado]="campoPesquisa == bibDialogo.semAcesso ? true : false" class="col-sm" [campo]="filtro" (alteracao)="filtro = $event"></texto>
</div>
<div class="responsiva">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-15">{{ bibDialogo.cultura }}</th>
        <th class="l-35">{{ bibDialogo.praga }}</th>
        <th class="l-10">{{ bibDialogo.doseDe }}</th>
        <th class="l-10">{{ bibDialogo.doseAte }}</th>
        <th class="l-20">{{ bibDialogo.doseUnd }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoFormuladoDose of produtoFormulado.produtoFormuladoDoses | filtrarProdutoFormuladoDose: { filtro: filtro, especifico: campoPesquisa }; let i = index">
        <tr>
          <td *ngIf="i == 0 || ((produtoFormulado.produtoFormuladoDoses | filtrarProdutoFormuladoDose: { filtro: filtro, especifico: campoPesquisa })[i - 1] && (produtoFormulado.produtoFormuladoDoses | filtrarProdutoFormuladoDose: { filtro: filtro, especifico: campoPesquisa })[i - 1].cultura != produtoFormuladoDose.cultura)" [rowSpan]="ehRetornaQuantidadeCultura(produtoFormulado.produtoFormuladoDoses, produtoFormuladoDose.cultura)" class="negrito text-uppercase">
            {{ produtoFormuladoDose.cultura }}
          </td>
          <td class="limitar">{{ produtoFormuladoDose.praga }}</td>
          <td>{{ produtoFormuladoDose.doseDe | monetario }}</td>
          <td>{{ produtoFormuladoDose.doseAte | monetario }}</td>
          <td class="limitar">{{ produtoFormuladoDose.doseUnidade }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(produtoFormuladoDose)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, produtoFormulado.produtoFormuladoDoses, produtoFormulado.idsProdutoFormuladoDoseExcluir, produtoFormuladoDose.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
