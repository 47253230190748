import { Component, ElementRef, ViewChild } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';

import Chart from 'chart.js/auto';
import { format } from 'date-fns';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import filtros from './acessorio/filtro';

@Component({
  selector: 'r2018',
  templateUrl: './r2018.component.html',
  styleUrls: ['./r2018.component.css'],
})
export class R2018Component extends RelatorioComponent {
  @ViewChild('vendaDiaRef') vendaDiaRef: ElementRef;
  @ViewChild('faturamentoRef') faturamentoRef: ElementRef;
  @ViewChild('topVendedorMargensRef') topVendedorMargensRef: ElementRef;
  @ViewChild('topProdutoMargensRef') topProdutoMargensRef: ElementRef;
  @ViewChild('topProdutoValoresRef') topProdutoValoresRef: ElementRef;

  public bibPropriedade = bibPropriedade;
  public conexao: Conexao = new Conexao(this.utilSessao);
  public faturamento: any;
  public faturamentos: any[];
  public filtros = filtros;
  public idEmpresa: number = this.utilSessao.getEmpresa().id;
  public imagemUsuario: string = 'img/semfoto.jpg';
  public resumoCabecalho: any;
  public topVendedorPorMagem: any;
  public topVendedorMargens: any[];
  public topProdutoPorMagem: any; //nao esta usando ???
  public topProdutoMargens: any[];
  public topProdutoValores: any[];
  public topVendedorValores: any;
  public valorTotalPrimeiroVendedor: number = 0;
  public vendaDia: any;
  public vendaDiaGrafico: any;
  public topProdutoPorMagemGrafico: any;
  public topProdutoPorValorGrafico: any;
  public vendaHoje: any;
  public subTitulo: string;
  public dataAtualizacao: Date;
  public intervaloAtualizarDashboard: any;
  public criterios: Criterio[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    this.criterios = criterios;
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.subTitulo = this.retornarData(criterios);
      let dataAtualFormatada = format(new Date(), 'dd/MM/yyyy');
      if (this.subTitulo == dataAtualFormatada) {
        this.iniciarAtualizacaoAutomatica();
      }
      this.resumoCabecalho = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ResumoCabecalho').lista[0];
      this.vendaDia = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'VendaDia').lista[0];
      this.faturamentos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Faturamento').lista;
      this.topVendedorValores = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TopVendedorValor').lista;
      this.topVendedorMargens = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TopVendedorMargem').lista;
      this.topProdutoMargens = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TopProdutoMargem').lista;
      //this.topProdutoValores = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TopProdutoValor').lista;
      this.criarGrafico();
    });
  }

  iniciarAtualizacaoAutomatica() {
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', function () {
      abaAtiva = abaAtiva == true ? false : true;
    });
    this.dataAtualizacao = new Date();
    clearInterval(this.intervaloAtualizarDashboard);
    this.intervaloAtualizarDashboard = setInterval(() => {
      if (abaAtiva) {
        this.listarRelatorio(this.criterios);
      }
      this.dataAtualizacao = new Date();
    }, 120000);
  }

  retornarData(criterios: Criterio[]): string {
    if (criterios.length > 0) {
      let data_de = this.formatarData(criterios.find((criterio) => criterio.nome == 'DATA_DE')?.valor);
      let data_ate = this.formatarData(criterios.find((criterio) => criterio.nome == 'DATA_ATE')?.valor);
      if (data_ate) {
        return data_de.replace('-', '/').replace('-', '/') + ' a ' + data_ate.replace('-', '/').replace('-', '/');
      }
    }
    return '';
  }

  formatarData(data: string): string {
    return format(new Date(Date.parse(data)), 'dd-MM-yyyy');
  }

  criarGrafico(): void {
    this.criarGraficoVendaAvisaAPrazo();
    this.criarGraficoFaturamento();
    this.criarGraficoTopVendedorMargem();
    this.criarGraficoProdutoMargem();
    //this.criarGraficoProdutoValor();
  }

  criarGraficoVendaAvisaAPrazo(): void {
    if (this.vendaDiaGrafico != null) {
      this.vendaDiaGrafico.destroy();
    }
    if (this.vendaDia != null) {
      const vendaDiaElemento = this.vendaDiaRef.nativeElement.getContext('2d');
      this.vendaDiaGrafico = new Chart(vendaDiaElemento, {
        type: 'doughnut',
        data: {
          labels: [this.bibDialogo.aVista, this.bibDialogo.aPrazo],
          datasets: [
            {
              data: [this.vendaDia.valorTotalAVistaDia, this.vendaDia.valorTotalPrazoDia],
              backgroundColor: ['#346CB0', '#36A2EB'],
              hoverOffset: 4,
            },
          ],
        },
      });
    }
  }

  criarGraficoFaturamento(): void {
    if (this.faturamento != null) {
      this.faturamento.destroy();
    }
    if (this.faturamentos.length > 0) {
      this.faturamentos.sort((a, b) => (a['dataOrdenacao'] === b['dataOrdenacao'] ? 0 : a['dataOrdenacao'] > b['dataOrdenacao'] ? 1 : -1));
      const dataFormatadas: string[] = this.faturamentos.map((faturamento) => faturamento.dataFormatada);
      const valores: number[] = this.faturamentos.map((faturamento) => faturamento.valor);
      const faturamentoElemento = this.faturamentoRef.nativeElement.getContext('2d');
      this.faturamento = new Chart(faturamentoElemento, {
        type: 'line',
        data: {
          labels: dataFormatadas,
          datasets: [
            {
              label: this.bibDialogo.vendas,
              data: valores,
              backgroundColor: ['#00A28A'],
              borderColor: ['#00A28A'],
              borderWidth: 2,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }

  criarGraficoTopVendedorMargem(): void {
    if (this.topVendedorPorMagem != null) {
      this.topVendedorPorMagem.destroy();
    }
    if (this.topVendedorMargens.length > 0) {
      this.valorTotalPrimeiroVendedor = this.topVendedorValores[0].valorTotalAVistaDia + this.topVendedorValores[0].valorTotalPrazoDia;
      const parceiroNomeFantasias: string[] = this.topVendedorMargens.map((topVendedorMargem) => topVendedorMargem.nomeFantasia);
      const parceiroMargemLucros: number[] = this.topVendedorMargens.map((topVendedorMargem) => topVendedorMargem.margemLucro);
      const topVendedorMargemElemento = this.topVendedorMargensRef.nativeElement.getContext('2d');
      this.topVendedorPorMagem = new Chart(topVendedorMargemElemento, {
        type: 'polarArea',
        data: {
          labels: parceiroNomeFantasias,
          datasets: [
            {
              data: parceiroMargemLucros,
              backgroundColor: ['#4BC0C0', '#36A2EB', '#FFCD56', '#FF6384', '#C9CBCF'],
            },
          ],
        },
      });
    }
  }

  criarGraficoProdutoMargem(): void {
    if (this.topProdutoPorMagemGrafico != null) {
      this.topProdutoPorMagemGrafico.destroy();
    }
    if (this.topProdutoMargens.length > 0) {
      this.topProdutoMargens.forEach((topProdutoMargem) => (topProdutoMargem.nome = topProdutoMargem.numero + ' - ' + topProdutoMargem.nome.split(' ')[0]));
      const produtoNome: string[] = this.topProdutoMargens.map((topProdutoMargem) => topProdutoMargem.nome);
      const proodutoMargemLucros: number[] = this.topProdutoMargens.map((topProdutoMargem) => topProdutoMargem.margemLucro);
      const topProdutoMargem = this.topProdutoMargensRef.nativeElement.getContext('2d');
      this.topProdutoPorMagemGrafico = new Chart(topProdutoMargem, {
        type: 'bar',
        data: {
          labels: produtoNome,
          datasets: [
            {
              label: '',
              data: proodutoMargemLucros,
              backgroundColor: ['#4BC0C0', '#36A2EB', '#FFCD56', '#FF6384', '#C9CBCF', '#FFDAB9', '#D8BFD8', '#EEE8AA', '#DEB887', '#9966FF'],
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }

  // odirlei
  /*criarGraficoProdutoValor(): void {
    if (this.topProdutoPorValorGrafico != null) {
      this.topProdutoPorValorGrafico.destroy();
    }
    if (this.topProdutoValores.length > 0) {
      this.topProdutoValores.forEach((TopProdutoValor) => (TopProdutoValor.nome = TopProdutoValor.numero + ' - ' + TopProdutoValor.nome.split(' ')[0]));
      const produtoNome: string[] = this.topProdutoValores.map((TopProdutoValor) => TopProdutoValor.nome);
      const produtoValor: number[] = this.topProdutoValores.map((TopProdutoValor) => TopProdutoValor.valorVenda);
      const topProdutoValor = this.topProdutoValoresRef.nativeElement.getContext('2d');
      this.topProdutoPorValorGrafico = new Chart(topProdutoValor, {
        type: 'bar',
        data: {
          labels: produtoNome,
          datasets: [
            {
              label: '',
              data: produtoValor,
              backgroundColor: ['#4BC0C0', '#36A2EB', '#FFCD56', '#FF6384', '#C9CBCF', '#FFDAB9', '#D8BFD8', '#EEE8AA', '#DEB887', '#9966FF'],
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }*/

  ngOnDestroy(): void {
    clearInterval(this.intervaloAtualizarDashboard);
  }
}
