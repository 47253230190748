<h1 class="relatorio-titulo text-uppercase">{{ bibDialogo.apuracaoLucro }}<ajuda [ajuda]="bibDialogo.movimentacoesGeraramFinanceiro" [posicao]="bibDialogo.esquerda"></ajuda></h1>

<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
    <th class="r-0-5">{{ bibDialogo.vend }}</th>
    <th class="r-1 text-right">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTabela }}</th>
    <th class="r-1 text-right">{{ bibDialogo.negociacaoPrazoDias }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorAVista }}</th>
    <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucro }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualSimbolo }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let apuracaoDeLucro of apuracaoDeLucros">
      <tr>
        <td>{{ apuracaoDeLucro.loja }}</td>
        <td class="text-right">{{ apuracaoDeLucro.numero }}</td>
        <td>{{ apuracaoDeLucro.vendedor }}</td>
        <td class="text-right">{{ apuracaoDeLucro.custoBruto | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ apuracaoDeLucro.valorTotal | monetario }}</td>
        <td class="text-right">{{ apuracaoDeLucro.valorTabela | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ apuracaoDeLucro.negociacao }}</td>
        <td class="text-right">{{ apuracaoDeLucro.valorAVista | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ (apuracaoDeLucro.descontoTotal | monetario) + '%' }}</td>
        <td class="text-right">{{ apuracaoDeLucro.lucroValor | monetario }}</td>
        <td class="text-right">{{ (apuracaoDeLucro.lucroPercentual | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="apuracaoDeLucros.length > 0">
    <tr>
      <td colspan="4"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoDeLucros[0].valorTotalCustoGerencial | monetario }}</strong>
      </td>      
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoDeLucros[0].valorTotalMovimentacao | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoDeLucros[0].valorTotalTabela | monetario }}</strong>
      </td>       
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoDeLucros[0].valorTotalMinimo | monetario }}</strong>
      </td>      
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoDeLucros[0].valorTotalLucro | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
