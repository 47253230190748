import { Component } from '@angular/core';
import { Aprovacao } from 'src/app/modelo/aprovacao';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';
import filtros from './filtro';

@Component({
  templateUrl: './separarEntrega.component.html',
})
export class SepararEntregaComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public idLojas: string;
  public movimentacoes: Movimentacao[] = [];
  public categoria: string;

  ngOnInit(): void {
    this.utilSessao.getLojas().forEach((loja) => (this.idLojas = new Util().concatenar(this.idLojas, loja.id, ',')));
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.ehApresentarCategoriaSubTitulo(criterios);
    criterios.push(new Criterio('PENDENTE_LOJAS', this.idLojas));
    criterios.push(new Criterio('STATUS', 'S'));
    criterios.push(new Criterio('ENTRADA_SAIDA_INTERNA', -1));
    criterios.push(new Criterio('OPERACAO_VENDAS', '2'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      if (this.movimentacoes.length > 0) {
        this.utilSessao.iniciarProcesso();
        this.setMovimentacaoPermiteEntrega(this.movimentacoes.map((movimentacao) => movimentacao.id));
      }
    });
  }

  ehApresentarCategoriaSubTitulo(criterios: Criterio[]): void {
    this.categoria = '';
    const criterio: Criterio = criterios.find((criterio) => criterio.nome == 'ID_CATEGORIA');
    if (criterio) {
      this.categoria = criterio.apresentacao;
    }
    this.utilSessao.setApresentaCategoria(this.categoria);
  }

  private setMovimentacaoPermiteEntrega(idMovimentacoes: number[]): void {
    let criterios: Criterio[] = [new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString()), new Criterio('TIPO', 'R'), new Criterio('PARCIALMENTE_PAGO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiro).subscribe((res) => {
      const financeiros: Financeiro[] = this.plainToClass(Financeiro, res) as any;
      financeiros.forEach((financeiro) => {
        this.movimentacoes.find((movimentacao) => movimentacao.id == financeiro.idMovimentacao).permiteEntrega = true;
      });
      this.setMovimentacaoPermiteEntregaBonificacao(idMovimentacoes);
    });
  }

  private setMovimentacaoPermiteEntregaBonificacao(idMovimentacoes: number[]): void {
    let criterios: Criterio[] = [new Criterio('IDS', idMovimentacoes.toString()), new Criterio('BONIFICACAO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacao).subscribe((res) => {
      const movimentacoes: Movimentacao[] = this.plainToClass(Movimentacao, res) as any;
      movimentacoes.forEach((bonificacao) => {
        this.movimentacoes.find((movimentacao) => movimentacao.id == bonificacao.id).permiteEntrega = true;
      });
      this.setMovimentacaoPermiteEntregaDireta();
    });
  }

  private setMovimentacaoPermiteEntregaDireta(): void {
    let idsNegociacao: number[] = this.movimentacoes.map((movimentacao) => movimentacao.idNegociacao);
    idsNegociacao = idsNegociacao.filter((id) => id != null);
    let criterios: Criterio[] = [new Criterio('IDS', idsNegociacao ? idsNegociacao.toString() : 0)];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.negociacao).subscribe((res) => {
      let negociacoes: Negociacao[] = this.plainToClass(Negociacao, res) as any;
      let idNegociacaoEntregaDiretas: number[] = negociacoes.map((negociacao) => (negociacao.permitirEntregaDireta == 'S' ? negociacao.id : null));
      this.movimentacoes.forEach((movimentacao) => {
        movimentacao.negociacao = negociacoes.find((negociacao) => negociacao.id == movimentacao.idNegociacao);
        if (idNegociacaoEntregaDiretas.includes(movimentacao.idNegociacao)) {
          movimentacao.permitirEntregaDireta = 'S';
        }
      });
      this.verificarMovimentacaoNegociacaoEntregaAprovada();
    });
  }

  private verificarMovimentacaoNegociacaoEntregaAprovada(): void {
    let movimentacaoAprovacaoEntregaPendentes: Movimentacao[] = this.movimentacoes.filter((movimentacao) => movimentacao.negociacao?.permitirEntregaDireta == 'A' && movimentacao.permitirEntregaDireta != 'S');
    if (movimentacaoAprovacaoEntregaPendentes.length > 0) {
      let movimentacaoChaves: string[] = movimentacaoAprovacaoEntregaPendentes.map((movimentacao) => movimentacao.chave);
      const criterios: Criterio[] = [new Criterio('CHAVES', this.formatarChavesMovimentacao(movimentacaoChaves)), new Criterio('ID_MOTIVO', 13), new Criterio('SITUACAO', 'A')];
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.aprovacao).subscribe((res) => {
        const aprovacoes: Aprovacao[] = this.plainToClass(Aprovacao, res) as any;
        let chaveMovimentacaoNegociacaoEntregaAprovadas: string[] = aprovacoes.map((aprovacao) => aprovacao.chave);
        movimentacaoAprovacaoEntregaPendentes.forEach((movimentacao) => {
          if (chaveMovimentacaoNegociacaoEntregaAprovadas.includes(movimentacao.chave)) {
            movimentacao.permitirEntregaDireta = 'S';
          }
        });
        this.utilSessao.finalizarProcesso();
      });
    } else {
      this.utilSessao.finalizarProcesso();
    }
  }

  private formatarChavesMovimentacao(movimentacaoChaves: string[]): string {
    let chaveFormatadas: string[] = [];
    movimentacaoChaves.forEach((chave) => {
      if (chave) {
        let novaChave: string[] = chave.split('');
        novaChave.unshift("'");
        novaChave.push("'");
        chaveFormatadas.push(novaChave.join(''));
      }
    });
    return chaveFormatadas.toString();
  }
}
