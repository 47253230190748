<layout [titulo]="usuario && usuario.nome ? usuario.nome : parametro == 'MinhaConta' ? bibDialogo.minhaConta : bibDialogo.permissao" [id]="usuario.id" (voltarAvancarEmt)="ehAlteracao()">
  <section class="card card-fluid">
    <header class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#dados">{{ bibDialogo.dados }}</a>
        </li>
        <li *ngIf="permiteEdicao" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#menu">{{ bibDialogo.menu }}</a>
        </li>
        <li *ngIf="permiteEdicao" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#relatorio">{{ bibDialogo.relatorio }}</a>
        </li>
        <li *ngIf="permiteEdicao" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#dashboard">{{ bibDialogo.dashboard }}</a>
        </li>
        <li *ngIf="permiteEdicao && !ocultaLoja" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#loja">{{ bibDialogo.loja }}</a>
        </li>
        <li *ngIf="permiteEdicao && !ocultaConta" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#conta">{{ menuConta.apelido }}</a>
        </li>
        <li *ngIf="permiteEdicao && !ocultaRoteiroContabil" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#usuarioCategoriaRoteiro">{{ menuRoteiroContabil.apelido }}</a>
        </li>
        <li *ngIf="permiteEdicao && !ocultaIntegracao" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#integracao">{{ bibDialogo.integracao }}</a>
        </li>
        <li *ngIf="permiteEdicao" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#extra">{{ bibDialogo.extra }}</a>
        </li>
      </ul>
    </header>
    <div class="card-body">
      <div id="myTabContent" class="tab-content">
        <usuarioDadosFrm class="tab-pane fade" id="dados" [ngClass]="{ 'active show': true }" [usuario]="usuario" [usuarioMenus]="usuarioMenus" [relatorioUsuarios]="relatorioUsuarios" [permiteEdicao]="permiteEdicao" [mobilidade]="this.utilSessao.getEmpresa().mobilidade"></usuarioDadosFrm>
        <usuarioLojasFrm *ngIf="permiteEdicao" class="tab-pane fade" id="loja" [usuarioLojas]="usuarioLojas"></usuarioLojasFrm>
        <usuarioMenusFrm *ngIf="permiteEdicao" class="tab-pane fade" id="menu" [usuarioMenus]="usuarioMenus"></usuarioMenusFrm>
        <relatorioUsuariosFrm *ngIf="permiteEdicao" class="tab-pane fade" id="relatorio" [relatorioUsuarios]="relatorioUsuarios"></relatorioUsuariosFrm>
        <usuarioContasFrm *ngIf="permiteEdicao" class="tab-pane fade" id="conta" [usuarioContas]="usuarioContas"></usuarioContasFrm>
        <usuarioCategoriaRoteirosFrm *ngIf="permiteEdicao" class="tab-pane fade" id="usuarioCategoriaRoteiro" [usuarioCategoriaRoteiros]="usuarioCategoriaRoteiros"></usuarioCategoriaRoteirosFrm>
        <usuarioIntegracaoFrm *ngIf="permiteEdicao" class="tab-pane fade" id="integracao" [integracaoUsuarios]="integracaoUsuarios"></usuarioIntegracaoFrm>
        <relatorioUsuariosFrm *ngIf="permiteEdicao" class="tab-pane fade" id="dashboard" [relatorioUsuarios]="dashboardUsuarios" [dashboard]="'S'"></relatorioUsuariosFrm>
        <usuarioExtraFrm *ngIf="permiteEdicao" class="tab-pane fade" id="extra" [usuario]="usuario"></usuarioExtraFrm>
      </div>
    </div>
  </section>
</layout>
<div class="row">
  <botao [id]="'salvar'" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="exigirSenha()" [desabilitado]="bloquearPermissao(usuario.id)"></botao>
  <texto *ngIf="focoIdSenha" [id]="'senhaOperacional1'" [ngClass]="compacto ? 'mt-2' : ''" [descricao]="bibDialogo.senhaOperacional" (navegacao)="$event == false ? (focoIdSenha = null) : ''" [tipo]="bibPropriedade.texto.senha" (alteracao)="conferirSenha($event)" [texto]="bibDialogo.senha" [focoId]="focoIdSenha" autocomplete="new-password"></texto>
</div>
<auditoria *ngIf="usuario.id" [idUsuarioInclusao]="usuario.idUsuarioInclusao" [dataHoraInclusao]="usuario.dataHoraInclusao" [idUsuarioAlteracao]="usuario.idUsuarioAlteracao" [dataHoraAlteracao]="usuario.dataHoraAlteracao"></auditoria>
