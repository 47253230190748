<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.nfeTransmitida }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1 text-right">{{ bibDialogo.numeroFiscalAbreviacao }}</th>
    <th class="r-0-5">{{ bibDialogo.vend }}</th>
    <th class="r">{{ bibDialogo.cliente }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r-4">{{ bibDialogo.chave }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let notaTransmitida of notasTransmitidas">
      <tr class="conteudo">
        <td>{{ notaTransmitida.loja }}</td>
        <td class="text-right">{{ notaTransmitida.numero }}</td>
        <td>{{ notaTransmitida.colaborador }}</td>
        <td>{{ notaTransmitida.parceiro }}</td>
        <td>{{ notaTransmitida.operacao }}</td>
        <td>{{ notaTransmitida.chave }}</td>
        <td class="text-right">{{ notaTransmitida.valorTotal | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
