import { BaseCalculo } from './baseCalculo';
import { CreditoSimplesNacional } from './creditoSimplesNacional';
import { Desoneracao } from './desoneracao';
import { Diferimento } from './diferimento';
import { SubstituicaoTributaria } from './substituicaoTributaria';

export class Icms {
  public aliquota: number;
  public baseCalculo: BaseCalculo;
  public creditoSimplesNacional: CreditoSimplesNacional;
  public cst: string;
  public desoneracao: Desoneracao;
  public origem: string;
  public substituicaoTributaria: SubstituicaoTributaria;
  public valor: number;
  public diferimento: Diferimento;
}
