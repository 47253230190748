<ng-container *ngIf="usaTipoTitulo && tiposTituloAgrupados.length > 0">
  <td class="p-0" [colSpan]="10">
    <table class="tabela-totalizador">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.tipoTitulo }}</th>
          <th class="r-1-5"></th>
          <th class="r-1-5 text-right">{{ bibDialogo.receita }}</th>
          <th class="r-1-5 text-right">{{ bibDialogo.despesa }}</th>
          <th class="r-1-5"></th>
          <th *ngIf="maisInformacoes" class="r-1-5"></th>
          <th *ngIf="maisInformacoes" class="r-1-5"></th>
          <th *ngIf="maisInformacoes" class="r-1-5"></th>
          <th class="r-1"></th>          
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let tipoTituloAgrupado of tiposTituloAgrupados; let i = index">
          <tr>
            <td>{{ tipoTituloAgrupado.tipoTitulo }}</td>
            <td></td>
            <td class="text-right">{{ (!usaTaxaCartao ? tipoTituloAgrupado.tipoTituloTotalizadoReceita : tipoTituloAgrupado.tipoTituloTotalizadoReceitaValorLiquido) | monetario  }}</td>
            <td class="text-right">{{ (!usaTaxaCartao ? tipoTituloAgrupado.tipoTituloTotalizadoDespesa : tipoTituloAgrupado.tipoTituloTotalizadoDespesaValorLiquido) | monetario  }}</td>
            <td></td>
            <td *ngIf="maisInformacoes"></td>
            <td *ngIf="maisInformacoes"></td>
            <td *ngIf="maisInformacoes"></td>
            <td></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
