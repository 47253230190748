import { Component, Input } from '@angular/core';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'bloqueado',
  templateUrl: './bloqueado.component.html',
  styleUrls: ['./bloqueado.component.css'],
})
export class BloqueadoComponent {
  @Input() bloqueado: boolean = false;
  @Input() bloqueiaTela: boolean = false;
  public utilSessao: UtilSessao = new UtilSessao();
  public bibImagem = bibImagem;
  public usaModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
}
