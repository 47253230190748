import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Fabricante } from 'src/app/modelo/fabricante';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './fabricanteFrm.component.html',
})
export class FabricanteFrmComponent extends PaginaComponent {
  public fabricante: Fabricante = new Fabricante();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.fabricante = this.plainToClass(Fabricante, this.ehAtualizacao(this.fabricante));
    if (this.fabricante.id) {
      this.listarFabricante(this.fabricante.id);
    }
  }

  listarFabricante(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.fabricante).subscribe((res) => {
      this.fabricante = this.plainToClass(Fabricante, res[0]) as any;
    });
  }

  persistirFabricante(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.fabricante), this.bibServico.fabricante, novo ? new Fabricante() : null);
    }
  }

  ehValido(): boolean {
    if (this.fabricante.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
