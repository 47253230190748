import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { R2017Component } from '../r2017.component';

@Component({
  selector: '<tr>[r2017itens]</tr>',
  templateUrl: './r2017itens.component.html',
})
export class R2017ItensComponent extends R2017Component {
  @Input() produtosMovimentacoes: any;
  public bibDialogo = bibDialogo;
}
