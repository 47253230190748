<layout *ngIf="this.usuario.agrow == 'S'" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-25 clique" (click)="listar(add('empresa'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.empresa }}</th>
        <th class="l-30 clique" (click)="listar(add('nomeFantasia'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-15 clique" (click)="listar(add('cnpj'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cnpj }}</th>
        <th class="l-5 clique" (click)="listar(add('estadoAbreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.uf }}</th>        
        <th class="l-10 clique" (click)="listar(add('dataUltimoAcesso'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.acesso.toLowerCase() }}</th>
        <th class="l-10 clique" (click)="listar(add('status'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.status }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let monitoramento of monitoramentos; let i = index">
        <tr>          
          <td class="limitar altura-minima"><div class="tile bg-teal text-white mr-2">{{ monitoramento.idEmpresa }}</div>{{ monitoramento.empresa }}</td>
          <td class="limitar"><atencao class="mr-2" [atencao]="monitoramento.id"></atencao>{{ monitoramento.nomeFantasia }}<br/><small class="d-block text-muted">{{ monitoramento.razaoSocial }}</small></td>
          <td class="limitar">{{ monitoramento.cnpj }}</td>
          <td class="limitar">{{ monitoramento.estadoAbreviacao }}</td>
          <td *ngIf="monitoramento.dataUltimoAcesso && ((monitoramento.dataUltimoAcesso | data) != (hoje | data))"><atencao [atencao]="monitoramento.dataUltimoAcesso | data" [tipo]="bibPropriedade.atencao.roxo"></atencao></td>
          <td *ngIf="monitoramento.dataUltimoAcesso == null || ((monitoramento.dataUltimoAcesso | data) == (hoje | data))">{{ monitoramento.dataUltimoAcesso | data }}</td>
          <td class="limitar fonte-menor">            
            <span class="badge badge-subtle" [ngClass]="monitoramento.status == 'HOMOLOGAÇÃO' ? 'badge-warning' :'badge-success'">{{ monitoramento.status }}</span>
          <td><img *ngIf="this.usuario.idEmpresa == monitoramento.idEmpresa"  class="imagem" src="img/marcador.png" [ngbTooltip]="bibDialogo.voceEstaAqui"/></td>
          <td>
            <btnAdicional *ngIf="this.usuario.idEmpresa != monitoramento.idEmpresa" [icone]="bibIcone.setaEsquerda" [ajuda]="bibDialogo.definir + ' ' + this.bibDialogo.usuario.toLowerCase() + ' ' + this.bibDialogo.aqui.toLowerCase()" (btnAdicional)="definirUsuarioEmpresa(monitoramento.idEmpresa)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>