<layout [titulo]="moduloEmpresa.nome" [id]="moduloEmpresa.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [normal]="true" [campo]="moduloEmpresa.nome ? moduloEmpresa.nome.trim() : null" (alteracao)="moduloEmpresa.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="moduloEmpresa.ativo" (alteracao)="moduloEmpresa.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm-4" [id]="'ordem'" [rotulo]="bibDialogo.ordem" [campo]="moduloEmpresa.ordem" [obrigatorio]="true" [tipo]="this.bibPropriedade.texto.inteiro" (alteracao)="moduloEmpresa.ordem = $event"></texto>
    <lista class="col-sm" [id]="'moduloPai'" [rotulo]="bibDialogo.moduloPai" [campo]="moduloEmpresa.idModuloPai" (alteracao)="moduloEmpresa.idModuloPai = $event.id" [lista]="moduloEmpresas"></lista>
  </div>
  <section class="card card-fluid">
    <header class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <a class="nav-link active" [id]="'menu'" data-toggle="tab" href="#menuModuloEmpresa">{{ bibDialogo.menu }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [id]="'relatorio'" data-toggle="tab" href="#relatorioModuloEmpresa">{{ bibDialogo.relatorio }} <atencao></atencao></a>
        </li>
      </ul>
    </header>
    <div class="card-body">
      <div id="myTabContent" class="tab-content">
        <menuModuloEmpresa class="tab-pane fade" id="menuModuloEmpresa" [ngClass]="{ 'active show': true }" [moduloEmpresa]="moduloEmpresa" [idsMenuModuloEmpresaExcluir]="idsMenuModuloEmpresaExcluir"></menuModuloEmpresa>
        <relatorioModuloEmpresa class="tab-pane fade" id="relatorioModuloEmpresa" [moduloEmpresa]="moduloEmpresa" [idsRelatorioModuloEmpresaExcluir]="idsRelatorioModuloEmpresaExcluir"></relatorioModuloEmpresa>
      </div>
    </div>
  </section>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirModuloEmpresa()" [desabilitado]="bloquearPermissao(moduloEmpresa.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirModuloEmpresa(true)" [desabilitado]="bloquearPermissao(moduloEmpresa.id)"></botao>
<auditoria *ngIf="moduloEmpresa.id" [idUsuarioInclusao]="moduloEmpresa.idUsuarioInclusao" [dataHoraInclusao]="moduloEmpresa.dataHoraInclusao" [idUsuarioAlteracao]="moduloEmpresa.idUsuarioAlteracao" [dataHoraAlteracao]="moduloEmpresa.dataHoraAlteracao"></auditoria>
