<layout [titulo]="cidade.nome" [id]="cidade.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="cidade.nome" (alteracao)="cidade.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="cidade.ativo" (alteracao)="cidade.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm-8" [id]="'estado'" [rotulo]="bibDialogo.estado" [campo]="cidade.idEstado" (alteracao)="cidade.idEstado = $event.id" [lista]="estados" [obrigatorio]="true"></lista>
    <texto class="col-sm-4" [id]="'ibge'" [rotulo]="bibDialogo.ibge" [campo]="cidade.ibge" (alteracao)="cidade.ibge = $event" [obrigatorio]="true" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="7" [exigidoCaracteres]="7" [ajuda]="bibDialogo.seteCaracteres"  [icone]="bibIcone.globo" (btnAdicional)="abrirSiteIBGE()" [ajuda]="bibDialogo.acesseSiteCidade"></texto>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCidade()" [desabilitado]="bloquearPermissao(cidade.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirCidade(true)" [desabilitado]="bloquearPermissao(cidade.id)"></botao>
<auditoria *ngIf="cidade.id" [idUsuarioInclusao]="cidade.idUsuarioInclusao" [dataHoraInclusao]="cidade.dataHoraInclusao" [idUsuarioAlteracao]="cidade.idUsuarioAlteracao" [dataHoraAlteracao]="cidade.dataHoraAlteracao"></auditoria>
