<div class="row mt-3">
  <ng-container *ngIf="(this.movimentacaoControlador.identificacao == 4 && this.movimentacaoControlador.entrada) == false">
    <titulo class="col-sm-12" [titulo]="movimentacaoControlador.bibDialogo.total" [imagem]="movimentacaoControlador.bibImagem.acoes"></titulo>
    <apresentacao class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.totalProdutosNfe" [campo]="movimentacaoControlador.movimentacao.valorTotalProdutoBruto | monetario"> </apresentacao>
    <apresentacao class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.totalDescontosNfe" [campo]="movimentacaoControlador.movimentacao.descontoTotal | monetario"> </apresentacao>
    <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.totalIcmsDesoneradoNfe" [campo]="movimentacaoControlador.movimentacao.icmsDesoneradoValor | monetario"> </apresentacao>
    <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.totalIpiNfe" [campo]="movimentacaoControlador.movimentacao.ipiValor | monetario"> </apresentacao>
    <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.totalIcmsStNfe" [campo]="movimentacaoControlador.movimentacao.icmsStValor | monetario"> </apresentacao>
  </ng-container>
  <decimal *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-4" [id]="'frete'" [rotulo]="movimentacaoControlador.bibDialogo.totalFreteNfe" [campo]="movimentacaoControlador.movimentacao.freteValor" (alteracao)="movimentacaoControlador.setFreteValor($event)" [desabilitado]="movimentacaoControlador.bloqueado"></decimal>
  <decimal *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-4" [id]="'seguro'" [rotulo]="movimentacaoControlador.bibDialogo.totalSeguroNfe" [campo]="movimentacaoControlador.movimentacao.seguroValor" (alteracao)="movimentacaoControlador.setSeguroValor($event)" [desabilitado]="movimentacaoControlador.bloqueado"></decimal>
  <decimal *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-4" [id]="'despesaAcessoria'" [rotulo]="movimentacaoControlador.bibDialogo.totalOutros" [campo]="movimentacaoControlador.movimentacao.despesaValor" (alteracao)="movimentacaoControlador.setDespesaValor($event)" [desabilitado]="movimentacaoControlador.bloqueado" [ajuda]="movimentacaoControlador.bibDialogo.totalDespesaAcessoriasNfe"></decimal>
  <decimal *ngIf="!movimentacaoControlador.entradaServico && movimentacaoControlador.notaTerceiro" class="col-sm-4" [id]="'freteNaoSoma'" [rotulo]="movimentacaoControlador.bibDialogo.totalFreteNaoSomaNfe" [campo]="movimentacaoControlador.movimentacao.freteValorNaoSoma" (alteracao)="movimentacaoControlador.setFreteValorNaoSoma($event)" [desabilitado]="movimentacaoControlador.bloqueado" [ajuda]="movimentacaoControlador.bibDialogo.totalFreteNaoSomaNfeExplicacao"></decimal>  
  <decimal *ngIf="movimentacaoControlador.entradaServico || movimentacaoControlador.movimentacao.idOperacao == 8" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.valorTotal" [campo]="movimentacaoControlador.movimentacao.valorTotal" [desabilitado]="true"></decimal>
</div>
<div *ngIf="(this.movimentacaoControlador.identificacao == 4 && this.movimentacaoControlador.entrada) == false" class="row mt-3">
  <titulo class="col-sm-12" [titulo]="movimentacaoControlador.bibDialogo.impostos" [imagem]="movimentacaoControlador.bibImagem.dinheiro"></titulo>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.icmsBase" [campo]="movimentacaoControlador.movimentacao.icmsBase | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.cofinsBase" [campo]="movimentacaoControlador.movimentacao.cofinsBase | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.pisBase" [campo]="movimentacaoControlador.movimentacao.pisBase | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.ipiBase" [campo]="movimentacaoControlador.movimentacao.ipiBase | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.icmsValor" [campo]="movimentacaoControlador.movimentacao.icmsValor | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.cofinsValor" [campo]="movimentacaoControlador.movimentacao.cofinsValor | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.pisValor" [campo]="movimentacaoControlador.movimentacao.pisValor | monetario"> </apresentacao>
  <apresentacao *ngIf="!movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.ipiValor" [campo]="movimentacaoControlador.movimentacao.ipiValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.inssRetido" [campo]="movimentacaoControlador.movimentacao.inssRetidoValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.irrfRetido" [campo]="movimentacaoControlador.movimentacao.irrfRetidoValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.csllRetido" [campo]="movimentacaoControlador.movimentacao.csllRetidoValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.iss + ' ' + movimentacaoControlador.bibDialogo.base" [campo]="movimentacaoControlador.movimentacao.issBase | monetario"> </apresentacao>    
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.pisRetido" [campo]="movimentacaoControlador.movimentacao.pisRetidoValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.cofinsRetido" [campo]="movimentacaoControlador.movimentacao.cofinsRetidoValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.deducoes" [campo]="movimentacaoControlador.movimentacao.deducoesValor | monetario"> </apresentacao>
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.iss + ' ' + movimentacaoControlador.bibDialogo.valor" [campo]="movimentacaoControlador.movimentacao.issValor | monetario" [ajuda]="movimentacaoControlador.bibDialogo.totalIss"> </apresentacao>    
  <apresentacao *ngIf="movimentacaoControlador.entradaServico" class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.outrasRetencoes" [campo]="movimentacaoControlador.movimentacao.outrasRetencoesValor | monetario"> </apresentacao>  
</div>
