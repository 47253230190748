import { TabelaPrecoConfiguracao } from '../agrow/modelo/tabelaPrecoConfiguracao';
import { ProdutoEstoqueMinimoMaximo } from './produtoEstoqueMinimoMaximo';
import { ProdutoFoto } from './produtoFoto';
import { ProdutoIcms } from './produtoIcms';
import { ProdutoIcmsEspecifico } from './produtoIcmsEspecifico';
import { ProdutoIcmsSt } from './produtoIcmsSt';
import { ProdutoParceiroComissao } from './produtoParceiroComissao';
import { ProdutoPisCofins } from './produtoPisCofins';

export class Produto {
  public abreviacao: string;
  public abreviacaoLoja: string;
  public alterado: boolean = false;
  public anp: string;
  public anpNumero: string;
  public ativo: string = 'S';
  public categoria: string;
  public cest: string;
  public cfopEmpresaIdCfopEntrada: number;
  public cfopEmpresaIdCfopSaida: number;
  public codigoBarra: string;
  public cofinsAliquotaEntrada: number;
  public cofinsAliquotaSaida: number;
  public comissaoPercentual: number;
  public complemento: string;
  public controlarEstoque: string = 'S';
  public cstCofinsEntrada: string;
  public cstCofinsSaida: string;
  public cstIpiEntrada: string;
  public cstIpiSaida: string;
  public cstPisEntrada: string;
  public cstPisSaida: string;
  public custoGerencial: number;
  public custoGerencialReal: number;
  public custoNfe: number;
  public custoReposicao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dataUltimaCompra: Date;
  public descontoMaximo: number;
  public descricao: string;
  public devolucao: string = 'S';
  public diferimentoAliquota: number;
  public entrada: string = 'S';
  public estoqueNegativo: string = 'N';
  public fabricante: string;
  public fator: number;
  public fatorMaximoSugerido: number;
  public fatorUnidade: number;
  public faturamento: string = 'S';
  public foto: string = 'N';
  public grupo: string;
  public grupoMargemGerencial: number;
  public grupoMargemLucro: number;
  public id: number;
  public idAnp: number;
  public idCategoria: number;
  public idCfopEntrada: number;
  public idCfopSaida: number;
  public idCfopTransferencia: number;
  public idCfopEntradaForaUf: number;
  public idCfopSaidaForaUf: number;
  public idCfopTransferenciaForaUf: number;
  public idCstCofinsEntrada: number;
  public idCstCofinsSaida: number;
  public idCstPisEntrada: number;
  public idCstPisSaida: number;
  public idEmpresa: number;
  public idFabricante: number;
  public idGrupo: number;
  public idIpiEntrada: string;
  public idIpiSaida: string;
  public idLocal: number;
  public idLoja: number;
  public idNcm: number;
  public idPolegada: number;
  public idProduto: number;
  public idProdutoFormulado: number;
  public codigoGenero: string;
  public idTabelaPrecoConfiguracao: number;
  public idUnidade: number;
  public idUnidadeAlternativa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idsProdutoParceiroComissaoExcluir: number[];
  public interna: string = 'S';
  public ipiAliquotaEntrada: number;
  public ipiAliquotaSaida: number;
  public ipiEnquadramentoEntrada: string;
  public ipiEnquadramentoSaida: string;
  public ipiEntrada: string;
  public ipiSaida: string;
  public lucroPercentual: number = 0;
  public lucroValor: number = 0;
  public margemLucro: number;
  public mostrarDescricao: string = 'N';
  public multiplicador: number;
  public naturezaReceita: string;
  public ncm: string;
  public nome: string;
  public nomeFiscal: string;
  public numero: number;
  public observacao: string;
  public operacao: string;
  public ordemPolegada: number;
  public pesoBruto: number;
  public pesoLiquido: number;
  public pisAliquotaEntrada: number;
  public pisAliquotaSaida: number;
  public polegada: string;
  public precoVenda: number;
  public precoVendaMinimo: number = 0;
  public precoVendaOriginal: number;
  public produtoEstoqueMinimoMaximos: ProdutoEstoqueMinimoMaximo[] = [];
  public produtoEstoqueMinimoMaximoTotalQuantidadeMaxima: number = 0;
  public produtoEstoqueMinimoMaximoTotalQuantidadeMinima: number = 0;
  public produtoEstoqueMinimoMaximoTotalPercentual: number = 0;
  public produtoEstoqueMinimoMaximoTotalQuantidadeDisponivel: number = 0;
  public produtoEstoqueMinimoMaximoTotalQuantidadeReservadaCarteira: number = 0;
  public produtoEstoqueMinimoMaximoTotalQuantidadeNfe: number = 0;
  public produtoEstoqueMinimoMaximoTotalQuantidadeTransferida: number = 0;
  public produtoEstoqueMinimoMaximoTotalQuantidadeComprada: number = 0;
  public produtoFormulado: string;
  public produtoFotos: ProdutoFoto[] = [];
  public produtoIcmsSts: ProdutoIcmsSt[] = [];
  public produtoImpostosIcms: ProdutoIcms[] = [];
  public produtoPisCofins: ProdutoPisCofins[] = [];
  public produtoImpostosIcmsEspecifico: ProdutoIcmsEspecifico[] = [];
  public produtoParceiroComissoes: ProdutoParceiroComissao[] = [];
  public quantidade: number;
  public quantidadeDisponivel: number;
  public quantidadeTotalDisponivel: number;
  public referencia: string;
  public saida: string = 'S';
  public selecionado: string = 'N';
  public tabelaPrecoConfiguracao: TabelaPrecoConfiguracao;
  public tipo: string = 'P';
  public tipoItem: string = '00';
  public unidade: string;
  public usaIcmsDesonerado: string = 'N';
  public usaLote: string = 'N';
  public valorMinimo: number;
  public valorUltimaCompra: number;
  public unidadeAbreviacao: string;
  public motivoDesonerado: string;
  public anvisaCodigo: string;
  public anvisaMotivoIsencao: string;
  public anvisaPrecoMaximo: number;
  public codigoBarraIntegracao: string;
  public idEmbalagemSicca: number;
  public embalagemSicca: string;
}
