<layout [titulo]="nfeXmlPdf.dataInicio && nfeXmlPdf.dataFim ? (nfeXmlPdf.dataInicio | data) + ' à ' + (nfeXmlPdf.dataFim | data) : null" [id]="nfeXmlPdf.id" (voltarAvancarEmt)="ehAlteracao()">
  <div *ngIf="nfeXmlPdf.id" [ngClass]="nfeXmlPdf.situacao == 2 ? 'alert alert-success'  : 'alert alert-warning'" class="alert-dismissible fade show" role="alert">
    <strong>{{ nfeXmlPdf.situacao == 2 ? bibDialogo.solicitacaoConcluida.toUpperCase() : bibDialogo.solicitacaoEmAndamento.toUpperCase() }}</strong><br> {{ nfeXmlPdf.situacao == 2 ? bibDialogo.paraRealizarDownload : bibDialogo.estamosPreparandoTudo + '.' }} <a *ngIf="nfeXmlPdf.situacao == 2" class="clique" (click)="this.utilNfeXmlPdf.baixar(this.nfeXmlPdf)"><b>{{ bibDialogo.cliqueAqui.toLowerCase() }}</b></a>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="row">
    <data class="col-sm" [id]="'dataInicio'" [rotulo]="bibDialogo.dataDe" [campo]="nfeXmlPdf.dataInicio" (alteracao)="nfeXmlPdf.dataInicio = $event" [obrigatorio]="true" [foco]="true" [desabilitado]="nfeXmlPdf.id ? true : false"></data>
    <data class="col-sm" [id]="'dataFim'" [rotulo]="bibDialogo.dataAte" [campo]="nfeXmlPdf.dataFim" (alteracao)="nfeXmlPdf.dataFim = $event" [obrigatorio]="true" [desabilitado]="nfeXmlPdf.id ? true : false"></data>
  </div>  
  <div class="row">
    <lista class="col-sm" [id]="'status'" [rotulo]="bibDialogo.situacao + ' ' + bibDialogo.nfe" [campo]="nfeXmlPdf.status" (alteracao)="nfeXmlPdf.status = $event.id" [lista]="statusNfeOpcoes" [obrigatorio]="true" [desabilitado]="nfeXmlPdf.id ? true : false"></lista>
    <lista class="col-sm" [id]="'tipo'" [rotulo]="bibDialogo.arquivo + ' ' + bibDialogo.nfe" [campo]="nfeXmlPdf.tipo" (alteracao)="nfeXmlPdf.tipo = $event.id" [lista]="tipoOpcoes" [obrigatorio]="true" [desabilitado]="nfeXmlPdf.id ? true : false"></lista>
    <lista class="col-sm" [id]="'limitarParceiro'" [rotulo]="bibDialogo.limitar + ' ' + bibDialogo.clienteFornecedor" [campo]="nfeXmlPdf.idParceiro ? 2 : 1" (alteracao)="limitaParceiro = $event.id == 2" [lista]="limitaParceiroOpcoes" [obrigatorio]="true" [desabilitado]="nfeXmlPdf.id ? true : false"></lista>
  </div>
  <div *ngIf="limitaParceiro" class="row">
    <busca class="col-sm" [id]="'parceiro'" [rotulo]="bibDialogo.clienteFornecedor" [campo]="nfeXmlPdf.parceiro" (alteracao)="setParceiro($event)" [servico]="bibServico.parceiro" [obrigatorio]="true" [desabilitado]="nfeXmlPdf.id ? true : false"></busca>
  </div>
  <div class="row">  
    <lista class="col-sm" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="nfeXmlPdf.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="nfeXmlPdf.idLoja = $event.id" [desabilitado]="nfeXmlPdf.id ? true : false"> </lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirNfeXmlPdf()" [desabilitado]="bloquearPermissao(nfeXmlPdf.id)"  [desabilitado]="nfeXmlPdf.id ? true : false"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirNfeXmlPdf(true)" [desabilitado]="bloquearPermissao(nfeXmlPdf.id)" [desabilitado]="nfeXmlPdf.id ? true : false"></botao>
<bloqueado [bloqueado]="nfeXmlPdf.id ? true: false"></bloqueado>
<auditoria *ngIf="nfeXmlPdf.id" [idUsuarioInclusao]="nfeXmlPdf.idUsuarioInclusao" [dataHoraInclusao]="nfeXmlPdf.dataHoraInclusao" [idUsuarioAlteracao]="nfeXmlPdf.idUsuarioAlteracao" [dataHoraAlteracao]="nfeXmlPdf.dataHoraAlteracao"></auditoria>
