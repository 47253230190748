import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r1206itens]</tr>',
  templateUrl: './r1206itens.component.html',
})
export class R1206ItensComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacao: any;
  public bibDialogo = bibDialogo;
}
