import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from '../biblioteca/bibServico';
import { BaixaNotificacao } from '../pagina/baixa/baixa.notificacao';
import { Util } from '../utilitario/util';
import { UtilSessao } from '../utilitario/util.sessao';
import { BaixaMovimentacaoProduto } from './baixaMovimentacaoProduto';
import { Financeiro } from './financeiro';
import { FinanceiroBaixa } from './financeiroBaixa';
import { Parceiro } from './parceiro';
import { TipoTitulo } from './tipoTitulo';

export class Baixa {
  private etapa: number = 1;

  setEtapa(etapa: number) {
    this.etapa = etapa;
    if (this.chaveGeral == undefined) {
      this.chaveGeral = this.util.criarId();
    }

    if (this.etapa > this.etapaAtingida) {
      this.etapaAtingida = this.etapa;
    }
    if (etapa == 1) {
      this.valorDespesa = 0;
      this.valorReceita = 0;
      this.valorDesconto = 0;
      this.valorJuro = 0;
      this.valorTotalAberto = 0;
      this.financeiros = [];
      this.utilizaConferencia = false;
      this.utilizaIdFinanceiro = false;
      this.conferenciaNumero = null;
      this.parceiro = null;
      this.dataBaixa = new Date();
      this.chaveGeral = this.util.criarId();
      this.baixaNotificacao.reiniciaFiltroEmt.emit(true);
    }
    if (etapa == 2 && this.baixaRapida) {
      this.dataBaixa = this.dataVencimentoAte;
    }
  }
  getEtapa(): number {
    return this.etapa;
  }

  public apresentaLoja: boolean = false;
  public apresentaNomeParceiro: boolean = false;
  public apresentaSubTitulo: boolean = false;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibPropriedade = bibPropriedade;
  public bibServico = bibServico;
  public conferenciaNumero: number = 0;
  public chaveGeral: string;
  public dataBaixa: Date = new Date();
  public dataPrevista: Date;
  public ehBaixaCaixa: boolean = false;
  public baixaRapida: boolean = false;
  public etapaAtingida: number = 1;
  public financeiroBaixas: FinanceiroBaixa[] = [];
  public financeiros: Financeiro[] = [];
  public idConsumidorFinal: number = new UtilSessao().getEmpresa().idConsumidorFinal;
  public idFinanceiroBaixaPagos: number[] = [];
  public idParceiroBuscaSelecionado: number;
  public nomeConsumidorFinal: string = new UtilSessao().getEmpresa().consumidor;
  public parametro: string;
  public parceiro: Parceiro;
  public parceiroCredito: number = 0;
  public parceiroCreditoEmpresa: number = 0;
  public posicao: number = 0;
  public prioridadeBaixa: number = 1;
  public quemEhMaiorReceitaOuDespesa: String = 'N';
  public selecionadoAtrasados: string = 'N';
  public telaDespesa: boolean = false;
  public tipo: string = 'R';
  public tipoTitulos: TipoTitulo[] = [];
  public totalBaixaConferencia: number = 0;
  public util: Util = new Util();
  public utilizaConferencia: boolean = false;
  public utilizaEspecieCartao: boolean = false;
  public utilizaIdFinanceiro: boolean = false;
  public utilizaNumeroConferencia: boolean = false;
  public valorDesconto: number = 0;
  public valorDespesa: number = 0;
  public valorFaltante: number = 0;
  public valorJuro: number = 0;
  public valorReceita: number = 0;
  public valorRestanteLiquido: number = 0;
  public valorTotalAberto: number = 0;
  public valorTotalAbertoReceita: number = 0;
  public valorTotalAbertoDespesa: number = 0;
  public valorTotalBaixa: number = 0;
  public valorTotalBaixaCheio: number = 0;
  public valorTotalBaixaFinal: number = 0;
  public valorTotalDesconto: number = 0;
  public valorTotalJuroAutomatico: number = 0;
  public valorTotalOriginal: number = 0;
  public valorTotalRestante: number = 0;
  public valorTotalTroco: number = 0;
  public dataVencimentoDe: Date;
  public dataVencimentoAte: Date;
  public baixaNotificacao: BaixaNotificacao = new BaixaNotificacao();
  public parcelamento: number;
  public movimentacaoComCartaoCredito: boolean = false;
  public mostraRelatorio: boolean = false;
  setValorFaltante(valorFaltante: number) {
    this.valorFaltante = this.util.arredondar(valorFaltante);
  }

  getValorFaltante(): number {
    return this.valorFaltante;
  }

  setPrioridadeBaixa(prioridadeBaixa: number): void {
    this.prioridadeBaixa = prioridadeBaixa;
    this.totalizarFinanceiroBaixa();
  }

  mostrarFinanceiroSelecionados(): Financeiro[] {
    return this.financeiros.filter((financeiro) => financeiro.selecionado != 'N').sort((a, b) => (a['tipo'] == this.quemEhMaiorReceitaOuDespesa ? 1 : -1));
    ;
  }

  ehTachado(financeiro: Financeiro): boolean {
    return financeiro.selecionado == null ? true : false;
  }

  setSelecionado(financeiro: Financeiro, selecionado: string) {
    financeiro.selecionado = selecionado;
    financeiro.dataConferencia = new Date();
    for (let baixaMovimentacaoProduto of financeiro.baixaMovimentacaoProdutos) {
      this.setSelecionadoProduto(baixaMovimentacaoProduto, selecionado, financeiro);
    }
    this.totalizarRestanteLiquido();
  }

  setSelecionadoProduto(baixaMovimentacaoProduto: BaixaMovimentacaoProduto, selecionado: string, financeiro: Financeiro) {
    baixaMovimentacaoProduto.selecionado = selecionado;
    const quantidadeSelecionada: number = financeiro.baixaMovimentacaoProdutos.filter((movimentacaoPro) => movimentacaoPro.selecionado == 'S').length;
    const quantidadeTotal: number = financeiro.baixaMovimentacaoProdutos.length;
    switch (quantidadeSelecionada) {
      case 0:
        financeiro.selecionado = 'N';
        break;
      case quantidadeTotal:
        financeiro.selecionado = 'S';
        break;
      default:
        financeiro.selecionado = null;
    }
    this.calcularRestanteMovimentacaoProduto(financeiro);
    this.totalizarRestanteLiquido();
  }

  calcularRestanteMovimentacaoProduto(financeiro: Financeiro): void {
    financeiro.setValorAPagarTotalProduto(0);
    this.listarMovimentacaoProduto(financeiro.baixaMovimentacaoProdutos).forEach((movimentacaoProduto) => {
      financeiro.setValorAPagarTotalProduto(financeiro.getValorAPagarTotalProduto() + movimentacaoProduto.valorRestante);
    });
  }

  adicionarFinanceiroBaixa(financeiroBaixa: FinanceiroBaixa): void {
    this.financeiroBaixas.push(financeiroBaixa);
    this.totalizarFinanceiroBaixa();
  }

  removerFinanceiroBaixa(financeiroBaixa: FinanceiroBaixa, posicao: number): void {
    this.valorTotalDesconto = this.valorTotalDesconto - financeiroBaixa.descontoValor;
    this.financeiroBaixas.splice(posicao, 1);
    this.totalizarFinanceiroBaixa();
  }

  totalizarRestanteLiquido(): void {
    this.valorRestanteLiquido = 0;
    this.valorTotalAbertoReceita = this.financeiros.reduce((valor, financeiro) => valor + financeiro.valorRestante * (financeiro.tipo == 'R' ? 1 : -1), 0);
    this.valorTotalAbertoDespesa = this.financeiros.reduce((valor, financeiro) => valor + financeiro.valorRestante * (financeiro.tipo == 'D' ? 1 : -1), 0);
    this.valorReceita = 0;
    this.valorDespesa = 0;
    this.valorTotalJuroAutomatico = 0;
    this.valorTotalOriginal = 0;
    this.valorTotalRestante = 0;
    this.mostrarFinanceiroSelecionados().forEach((financeiro) => {
      if (financeiro.selecionado == 'S') {
        if (financeiro.tipo == 'R') {
          this.valorTotalAbertoReceita -= financeiro.valorRestante;
          this.valorTotalAbertoReceita = this.valorTotalAbertoReceita < 0 ? 0 : this.valorTotalAbertoReceita;
          this.valorRestanteLiquido += financeiro.valorRestante;
        } else {
          this.valorTotalAbertoDespesa -= financeiro.valorRestante;
          this.valorTotalAbertoDespesa = this.valorTotalAbertoDespesa < 0 ? 0 : this.valorTotalAbertoDespesa;
          this.valorRestanteLiquido -= financeiro.valorRestante;
        }
        this.somaReceitaDespesa(financeiro.tipo, financeiro.valorRestante);
      } else {
        financeiro.baixaMovimentacaoProdutos.forEach((baixaMovimentacaoProduto) => {
          if (baixaMovimentacaoProduto.selecionado == 'S') {
            if (financeiro.tipo == 'R') {
              this.valorRestanteLiquido += baixaMovimentacaoProduto.valorRestante;
            } else {
              this.valorRestanteLiquido -= baixaMovimentacaoProduto.valorRestante;
            }
            this.somaReceitaDespesa(financeiro.tipo, baixaMovimentacaoProduto.valorRestante);
          }
        });
      }
      this.valorTotalJuroAutomatico += financeiro.juroAutomatico * (financeiro.tipo == 'D' ? -1 : 1);
      this.valorTotalOriginal += financeiro.valor * (financeiro.tipo == 'D' ? -1 : 1);
      this.valorTotalRestante += financeiro.valorRestante * (financeiro.tipo == 'D' ? -1 : 1);
    });
    if (this.valorTotalJuroAutomatico < 0) {
      this.valorTotalJuroAutomatico = this.valorTotalJuroAutomatico * -1;
    }
    if (this.valorTotalOriginal < 0) {
      this.valorTotalOriginal = this.valorTotalOriginal * -1;
    }
    if (this.valorTotalRestante < 0) {
      this.valorTotalRestante = this.valorTotalRestante * -1;
    }
    if (this.valorRestanteLiquido < 0) {
      this.valorRestanteLiquido = this.valorRestanteLiquido * -1;
    }
    this.tipoBaixa();
  }

  somaReceitaDespesa(tipo: string, valorRestante: any): void {
    tipo == 'R' ? (this.valorReceita += valorRestante) : (this.valorDespesa += valorRestante);
  }

  calcularTipo(tipo: string, valorRestante: number): number {
    return tipo == 'R' ? valorRestante : valorRestante * -1;
  }

  totalizarFinanceiroBaixa(): void {
    this.valorTotalBaixa = 0;
    this.valorTotalBaixaCheio = 0;
    this.valorTotalDesconto = 0;
    this.valorTotalTroco = 0;

    for (const financeiroBaixa of this.financeiroBaixas) {
      this.valorTotalBaixa += financeiroBaixa.valor;
      this.valorTotalBaixaCheio += financeiroBaixa.valorCheio;
      this.valorTotalDesconto += financeiroBaixa.descontoValor;
      this.valorTotalTroco += financeiroBaixa.valorTroco;
    }
    this.calcularValorFaltante();
  }

  calcularValorFaltante(): void {
    this.setValorFaltante(this.valorRestanteLiquido - this.valorTotalBaixa - this.valorDesconto + this.valorJuro);
    //this.setValorFaltante(this.valorRestanteLiquido + this.valorTotalTroco - (this.valorTotalBaixa + this.valorTotalDesconto));
    this.ratearPagamento();
  }

  /* Refatorado */
  ratearPagamento(): void {
    let valorJaAbatido: number = this.abaterDespesaDaReceitaOuViceVersa();
    this.valorTotalBaixaFinal = this.valorTotalBaixa + valorJaAbatido + this.valorDesconto - this.valorJuro;
    if (this.baixaRapida) {
      this.mostrarFinanceiroSelecionados().forEach((financeiro) => {
        this.financeiroBaixas.forEach((financeiroBaixa) => {
          if (financeiroBaixa.idFinanceiro == financeiro.id) {
            financeiro.setValorAPagarTotal(financeiroBaixa.valorCheio);
            this.calcularValorAPagarMovimentacaoProduto(financeiro);
          }
        });
      });
    } else {
      this.receberReceitaOuPagarDespesa();
      if (this.quemEhMaiorReceitaOuDespesa != 'N') {
        this.arredondarReceitaOuDespesa();
      }
    }
  }

  /* Refatorado */
  abaterDespesaDaReceitaOuViceVersa(): number {
    let valorJaAbatido: number = 0;
    this.mostrarFinanceiroSelecionados().forEach((financeiro) => {
      if (this.quemEhMaiorReceitaOuDespesa == 'N' || this.quemEhMaiorReceitaOuDespesa != financeiro.tipo) {
        const valorRestante: number = this.ehTachado(financeiro) ? financeiro.getValorAPagarTotalProduto() : financeiro.valorRestante;
        financeiro.setValorAPagarTotal(valorRestante);
        if (financeiro.tipo == 'R') {
          valorJaAbatido += valorRestante;
        } else {
          valorJaAbatido -= valorRestante;
        }
      }
    });
    if (valorJaAbatido < 0) {
      valorJaAbatido = valorJaAbatido * -1;
    }
    return valorJaAbatido;
  }

  /* Refatorado */
  receberReceitaOuPagarDespesa(): void {
    let valorTotalRestante = ((this.valorTotalBaixa + this.valorDesconto) - this.valorJuro);
    this.mostrarFinanceiroSelecionados().forEach((financeiro) => {
      let valorRestante: number = financeiro.valorRestante;
      valorRestante = valorRestante * (this.quemEhMaiorReceitaOuDespesa == financeiro.tipo ? 1 : -1);
      if (this.quemEhMaiorReceitaOuDespesa != 'N' && valorTotalRestante >= 0) {
        let valorDeduzir: number = valorTotalRestante > valorRestante ? valorRestante : valorTotalRestante;
        financeiro.setValorAPagarTotal(valorDeduzir * (valorDeduzir > 0 ? 1 : -1));
        if (financeiro.getValorAPagarTotal() > valorRestante) {
          valorDeduzir = valorRestante;
          financeiro.setValorAPagarTotal(valorRestante * (valorRestante > 0 ? 1 : -1));
        }
        valorTotalRestante -= valorDeduzir;
        if (valorTotalRestante < 0) {
          valorTotalRestante = 0;
        }
      } else {
        if (this.quemEhMaiorReceitaOuDespesa == 'N') {
          financeiro.setValorAPagarTotal(financeiro.valorRestante);
        } else {
          financeiro.setValorAPagarTotal(0);
        }
      }
    });
  }

  /* Refatorado */
  getProporcao(valor: number, valorTotal: number): number {
    if (valor == 0 && valorTotal == 0) {
      return 1;
    }
    return (valor * 100) / valorTotal / 100;
  }

  /* Refatorado */
  arredondarReceitaOuDespesa(): void {
    let valorAPagarTotal: number = 0;
    let ultimaPosicao: number;
    let financeirosSelecionados: Financeiro[] = this.mostrarFinanceiroSelecionados();
    financeirosSelecionados.forEach((financeiro, i) => {
      if (this.quemEhMaiorReceitaOuDespesa == financeiro.tipo) {
        valorAPagarTotal += financeiro.getValorAPagarTotal();
        ultimaPosicao = i;
      }
    });
    const diferenca: number = this.valorTotalBaixaFinal - valorAPagarTotal;
    financeirosSelecionados[ultimaPosicao].setValorAPagarTotal(financeirosSelecionados[ultimaPosicao].getValorAPagarTotal() + diferenca);
  }

  calcularValorAPagarMovimentacaoProduto(financeiro: Financeiro): void {
    for (let baixaMovimentacaoProduto of this.listarMovimentacaoProduto(financeiro.baixaMovimentacaoProdutos)) {
      let valorAPagarTotalProduto: number = this.ehTachado(financeiro) ? financeiro.getValorAPagarTotalProduto() : financeiro.valorRestante;
      baixaMovimentacaoProduto.setValorAPagarTotal(this.getProporcao(financeiro.getValorAPagarTotal() + financeiro.desconto, valorAPagarTotalProduto) * baixaMovimentacaoProduto.valorRestante);
    }
    this.verificarArredondamentoMovimentacaoProduto(financeiro);
  }

  verificarArredondamentoMovimentacaoProduto(financeiro: Financeiro): void {
    let valorTotalProdutos: number = 0;
    for (const baixaMovimentacaoProduto of this.listarMovimentacaoProduto(financeiro.baixaMovimentacaoProdutos)) {
      valorTotalProdutos += baixaMovimentacaoProduto.getValorAPagarTotal();
    }
    const diferenca = valorTotalProdutos - financeiro.getValorAPagarTotal();
    if (financeiro.baixaMovimentacaoProdutos.length > 0) {
      financeiro.baixaMovimentacaoProdutos[0].setValorAPagarTotal(financeiro.baixaMovimentacaoProdutos[0].getValorAPagarTotal() - diferenca);
    }
  }

  listarMovimentacaoProduto(movimentacaoProdutos: BaixaMovimentacaoProduto[], somenteSelecionados: boolean = true) {
    return somenteSelecionados ? movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.selecionado == 'S') : movimentacaoProdutos;
  }

  tipoBaixa() {
    this.tipo = this.valorDespesa > this.valorReceita ? 'D' : 'R';
    this.quemEhMaiorReceitaOuDespesa = this.valorReceita == this.valorDespesa ? 'N' : this.valorReceita > this.valorDespesa ? 'R' : 'D';
  }

  apresentaTitulo(): void {
    this.apresentaSubTitulo = this.financeiros.filter((financeiro) => financeiro.idParceiro == this.financeiros[0].idParceiro).length == this.financeiros.length;
    this.apresentaNomeParceiro = this.financeiros.filter((financeiro) => this.formatarNomeParceiro(financeiro.nomeParceiro) == this.formatarNomeParceiro(this.financeiros[0].nomeParceiro)).length == this.financeiros.length;
  }

  formatarNomeParceiro(nome: string) {
    if (nome.indexOf(' - ') > 0) {
      return nome.substring(nome.indexOf(' - ') + 3, nome.length).trim();
    }
    return nome.trim();
  }
  setParceiro(parceiro: Parceiro): void {
    this.parceiro = parceiro;
    this.parceiroCredito = parceiro.credito;
    this.parceiroCreditoEmpresa = parceiro.creditoEmpresa;
  }
}
