import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1268.component.html',
})
export class R1268Component extends RelatorioComponent {
  public filtros = filtros;
  public parceiros: any[] = [];
  public parceiroComissoes: any[] = [];
  public usaDetalheComissoes: boolean = false;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.parceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Parceiro').lista;
      this.parceiroComissoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ParceiroComissao').lista;
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.vincular();
      this.ordenarAgrupar(this.parceiros);
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaDetalheComissoes = this.definirRegra(relatorioAdicional, 149, this.usaDetalheComissoes);
  }

  vincular(): void {
    this.parceiros.forEach((parceiro) => {
      parceiro.comissoes = this.parceiroComissoes.filter((parceiroComissao) => parceiroComissao.idColaborador == parceiro.id);
    });
  }
}
