import { ConfiguracaoMovimentacaoTotalContabil } from './configuracaoMovimentacaoTotalContabil';

export class ConfiguracaoMovimentacaoContabil {
  public configuracaoMovimentacaoTotalContabeis: ConfiguracaoMovimentacaoTotalContabil[] = [];
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;

  setObjetoTotais(lista: any[]): void {
    this.configuracaoMovimentacaoTotalContabeis = lista;
  }

  getObjetoTotais(): any[] {
    return this.configuracaoMovimentacaoTotalContabeis;
  }
}
