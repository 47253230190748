export class ProdutoFormuladoRestricao {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idProdutoFormulado: number;
  public idEstado: number;
  public estado: string;
  public idEquipamento: number;
  public cultura: string;
  public equipamento: string;
}
