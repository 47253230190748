import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarIcmsDesonerado',
  pure: false,
})
export class IcmsDesoneradoPipe implements PipeTransform {
  transform(impostosIcms: any[]): any {
    return impostosIcms.filter((impostosIcm) => impostosIcm.usaIcmsDesonerado == 'S');
  }
}
