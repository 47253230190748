import { Component, EventEmitter, Input, Output } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Utilitario } from 'src/app/modelo/utilitario';
import { Util } from 'src/app/utilitario/util';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { ModalNotificacao } from './modal.notificacao';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent {
  private nome: string;
  public zIndex: number;
  public utilitarioFechar = new Utilitario(bibIcone.sair, bibDialogo.fechar + ' ' + bibDialogo.f9);
  public _componente: Component;
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public botaoFecharLateral: boolean = false;
  public filtro: boolean = false;
  public bibIcone = bibIcone;
  public bibPropriedade = bibPropriedade;
  public bibDialogo = bibDialogo;

  @Input()
  set componente(componente: Component) {
    this._componente = componente;
    if (this._componente != null) {
      this.nome = new Util().criarId();
      this.utilSessao.modal = true;
      this.utilSessao.modalNomes.push(this.nome);
      this.zIndex = this.utilSessao.modalNomes.length;
      this.controlarBarraHTML('hidden');
    }
  }
  @Output() modalEmt = new EventEmitter<Component>();

  constructor(public modalNotificacao: ModalNotificacao, public utilSessao: UtilSessao, public utilNotificacao: UtilNotificacao) { }

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe(() => {
      this.fechar();
    });
    this.filtro = this.utilSessao.getIdentificacao('filtro')?.conteudo;
    this.botaoFecharLateral = this.utilSessao.getIdentificacao('botaoFecharLateral')?.conteudo;
    this.utilSessao.setIdentificacao(new Identificacao('botaoFecharLateral', false));
    this.utilSessao.setIdentificacao(new Identificacao('modalAberto', true));
  }

  ngOnDestroy(): void {
    this.controlarBarraHTML('auto');
    this.removerNomeModalSessao();
    this.utilSessao.modal = false;
    this.utilSessao.setIdentificacao(new Identificacao('botaoFecharLateral', false));
    this.utilSessao.setIdentificacao(new Identificacao('modalAberto', false));
    this.utilSessao.setIdentificacao(new Identificacao('filtro', false));
  }

  fechar(): void {
    this.utilNotificacao.setModalRelatorioEmitter(false);
    const i = this.utilSessao.modalNomes.length - 1;
    if (this.utilSessao.modalNomes[i] == this.nome) {
      this.modalEmt.emit(this._componente);
    }
    this.utilSessao.setIdentificacao(new Identificacao('botaoFecharLateral', false));
    this.utilSessao.setIdentificacao(new Identificacao('modalAberto', false));
    this.utilSessao.setIdentificacao(new Identificacao('filtro', false));
  }

  removerNomeModalSessao(): void {
    this.utilSessao.modalNomes = this.utilSessao.modalNomes.filter((modalNome) => modalNome != this.nome);
    if (this.utilSessao.modalNomes.length == 0) {
      this.utilSessao.modal = false;
    }
    if (event) {
      event.stopImmediatePropagation();
    }
  }

  controlarBarraHTML(overflow: string): void {
    (document.querySelector('html') as HTMLElement).style.overflow = overflow;
  }
}
