<layout [titulo]="titulo" [tag]="tag">
  <registro [numeroRegistro]="totalRelatorios.length"></registro>
  <div class="row form-group naoImprimir">
    <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="lista" (alteracao)="campoPesquisa = $event.id" [obrigatorio]="true"> </lista>
    <texto class="col-sm" [foco]="true" [campo]="filtro" (alteracao)="alterarValorFiltro($event)" (navegacao)="focado = $event"></texto>
  </div>
  <div class="list-group list-group-flush list-group-divider border-top" data-toggle="radiolist">
    <ng-container *ngFor="let relatorio of totalRelatorios | filtrarRelatorio: { filtro: filtro, especifico: campoPesquisa }; let i = index">
      <div class="col-sm-12" *ngIf="i == 0 ? true : totalRelatorios[i].relatorioModulo != totalRelatorios[i - 1].relatorioModulo">
        <titulo [titulo]="relatorio.relatorioModulo"></titulo>
      </div>
      <div class="list-group-item clique" [ngClass]="indice == i ? 'active' : ''" (click)="indice = i" data-toggle="sidebar" data-sidebar="show" (click)="irRelatorio()">
        <a href="#" class="stretched-link"></a>
        <div class="list-group-item-figure">
          <div class="tile tile-circle" [ngClass]="'bg-' + relatorio.tipoRelatorioModulo">{{ relatorio.idRelatorio }}</div>
        </div>
        <div class="list-group-item-body">
          <h4 class="list-group-item-title">{{ relatorio.relatorio }}</h4>
          <p class="list-group-item-text">{{ relatorio.relatorioModulo }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</layout>
