<layout [subTitulo]="movimentacoes[0] ? bibDialogo.operacao.toUpperCase() + ': ' + movimentacoes[0].operacao : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm text-nowrap">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-5 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-55 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parceiro }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorTotal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotal }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr>
          <td>{{ movimentacao.abreviacao }}</td>
          <td>{{ movimentacao.data | data }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td class="limitar">{{ movimentacao.parceiro }}</td>
          <td class="text-right">{{ movimentacao.valorTotal | monetario }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="irPara(movimentacao.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
</layout>
