<div *ngIf="_componente" class="sombra naoImprimir" [ngClass]="ehModoClaro ? '': 'sombra-modo-escuro'" [ngStyle]="{'z-index' : 1002 + zIndex}">
  <div class="dialogo" [ngClass]="{ 'modo-escuro': !ehModoClaro, 'filtro': filtro }">
    <div class="row justify-content-between">
      <div class="col-sm">
        <div class="row align-items-center">
          <h4 class="pl-3" *ngIf="filtro">{{ bibDialogo.opcoesDePesquisa }}</h4>
        </div>
      </div>
      <div [ngClass]="{ 'titulo-modo-claro': ehModoClaro, 'titulo-modo-escuro': !ehModoClaro }">
        <utilitario *ngIf="this.botaoFecharLateral != true" class="utilitario-fechar" [utilitario]="utilitarioFechar" (utilitarioEmt)="fechar()"></utilitario>
        <icone *ngIf="this.botaoFecharLateral" class="utilitario-fechar icone-fechar" [icone]="bibIcone.sair" [clique]="true" [mensagem]="bibDialogo.fechar + ' ' + bibDialogo.f9" [cor]="bibPropriedade.icone.branco" (iconeEmt)="fechar()" [centralizaVertical]="true"></icone>
      </div>
    </div>    
    <div class="container-fluid">
      <ng-container *ngComponentOutlet="_componente"></ng-container>
    </div>
  </div>
</div>
