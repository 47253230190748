import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.dataConciliacao, campo: 'dataConciliacao' },
  { rotulo: bibDialogo.dataBaixa, campo: 'dataBaixa' },
  { rotulo: bibDialogo.parceiro, campo: 'parceiro' },
  { rotulo: bibDialogo.conta, campo: 'conta' },
  { rotulo: bibDialogo.tipoTitulo, campo: 'tipoTitulo' },
];

export default grupos;
