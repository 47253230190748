import { Component, Input, ViewChild } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Resultado } from 'src/app/modelo/resultado';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UtilCriptografia } from 'src/app/utilitario/utilCriptografia';

@Component({
  selector: 'usuarioFotoFrm',
  templateUrl: './usuarioFotoFrm.component.html',
})
export class UsuarioFotoFrmComponent extends PaginaComponent {
  @ViewChild('arquivoUpload') arquivoUpload;
  @Input() usuario: Usuario = new Usuario();
  public idUsuarioSistema: number = this.utilSessao.getUsuario().id;
  public imagemUsuario: string = 'img/semfoto.jpg';
  public utilCriptografia: UtilCriptografia = new UtilCriptografia();
  public conexao: Conexao = new Conexao(this.utilSessao);

  alterarFotoUsuario(evento: any, usuario: Usuario): void {
    this.utilSessao.setAlteracao(true);
    usuario.historicoFoto = 'N';
    const leitorArquivo: FileReader = new FileReader();
    const imagem = <HTMLInputElement>document.getElementById('output');
    leitorArquivo.onload = function () {
      imagem.src = leitorArquivo.result.toString();
    };
    leitorArquivo.readAsDataURL(evento.target.files[0]);
    imagem.addEventListener('load', function () {
      usuario.foto = imagem.src.indexOf('img/semfoto.jpg') == -1 ? 'S' : 'N';
      usuario.fotoUsuario = imagem.src.indexOf('img/semfoto.jpg') == -1 ? imagem.src : null;
    });
    this.arquivoUpload.nativeElement.value = '';
  }

  deletarFoto(): void {
    this.usuario.foto = 'N';
    this.usuario.fotoUsuario = null;
  }

  setUsuarioModoClaroEscuro(): void {
    this.utilSessao.setAlteracao(true);
    let usuario = this.utilSessao.getUsuario();
    usuario.usaModoClaro = usuario.usaModoClaro == 'S' ? 'N' : 'S';
    this.usuario.usaModoClaro = usuario.usaModoClaro;
    this.ehModoClaro = usuario.usaModoClaro == 'S';
    this.utilNotificacao.setEhModoClaro(this.ehModoClaro);
    this.utilSessao.setUsuario(usuario);
    this.carregarCss();
  }

  carregarCss(): void {
    var cssDinamicos: any[] = [];
    if (this.utilSessao.getUsuario() && this.utilSessao.getUsuario().usaModoClaro != 'S') {
      cssDinamicos = ['assets/stylesheets/theme-dark.css'];
    }
    if (cssDinamicos != null) {
      for (var i = 0; i < cssDinamicos.length; i++) {
        let node = document.createElement('link');
        node.rel = 'stylesheet';
        node.href = cssDinamicos[i];
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
    this.ehRetirarModoEscuro();
  }

  ehRetirarModoEscuro(): void {
    if (this.utilSessao.getUsuario() && this.utilSessao.getUsuario().usaModoClaro == 'S') {
      document.querySelectorAll('[href="assets/stylesheets/theme-dark.css"').forEach((evento) => {
        evento.remove();
      });
    }
    this.utilNotificacao.emtUtilizaModoClaro.emit(this.utilSessao.getUsuario().usaModoClaro == 'S');
  }
}
