const bibIcone = {
  arquivo: 'far fa-file-alt',
  atencao: 'fas fa-exclamation-triangle',
  atualizar: 'fas fa-sync-alt',
  planilha: 'fas fa-table',
  borracha: 'fas fa-eraser',
  cadeadoAberto: 'fa fa-unlock-alt',
  cadeadoFechado: 'fa fa-lock ',
  cancelar: 'fas fa-times',
  carta: 'fas fa-envelope',
  checado: 'fas fa-check-circle',
  circulo: 'fa fa-circle',
  clone: 'fas fa-clone',
  codigoBarra: 'fas fa-barcode',
  cameraFoto: 'fa-solid fa-camera-retro',
  dashboard: 'menu-icon oi oi-bar-chart',
  descartar: 'fas fa-trash-alt',
  duploCheck: 'fa-check-double',
  editar: 'fa fa-pencil-alt',
  entrega: 'fas fa-truck-loading',
  estrela: 'fa fa-star',
  filtro: 'fa fa-filter',
  globo: 'fas fa-globe',
  historicoCredito: 'fas fa-money-check-alt',
  imposto: 'fas fa-calculator',
  impressora: 'fa fa-print',
  infoCirculo: 'fa fa-info-circle',
  lixo: 'fa fa-trash',
  lupa: 'fas fa-search',
  mais: 'fa fa-plus',
  marcar: 'fas fa-tasks',
  menos: 'fas fa-minus-circle',
  olho: 'far fa-eye',
  olhoBloqueado: 'fas fa-eye-slash',
  ordemCrescente: 'fas fa-sort-alpha-down',
  ordemDecrescente: 'fas fa-sort-alpha-up',
  pdf: 'far fa-file-pdf',
  prancheta: 'fas fa-clipboard-list',
  percentual: 'fa fa-percent',
  quadrado: 'fa fa-square',
  relatorio: 'fas fa-paste',
  sair: 'fa fa-times',
  salvar: 'fas fa-save',
  setaBaixo: 'fa fa-angle-down',
  setaCima: 'fa fa-angle-up',
  setaCompletaBaixo: 'fas fa-arrow-down',
  setaCompletaCima: 'fas fa-arrow-up',
  setaDireita: 'fas fa-chevron-right',
  setaEsquerda: 'fas fa-chevron-left',
  sifrao: 'fad fa-dollar-sign',
  usuario: 'fa fa-user',
  xml: 'fas fa-file-code',
  headphone: 'fa fa-headphones'
};
export default bibIcone;
