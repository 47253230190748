<layout [titulo]="bibDialogo.icmsSt" [id]="icmsSt.id">
  <div class="row">
    <lista class="col-sm-12" [id]="'estado'" [rotulo]="bibDialogo.estado" [campo]="icmsSt.idEstado" (alteracao)="setEstado(icmsSt, $event)" [lista]="estados" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <decimal class="col-sm-4" [id]="'aliquota'" [rotulo]="bibDialogo.aliquota" [campo]="icmsSt.aliquota" (alteracao)="icmsSt.aliquota = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
    <decimal class="col-sm-4" [id]="'mva'" [rotulo]="bibDialogo.mva" [campo]="icmsSt.mva" (alteracao)="icmsSt.mva = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionarIcmsSt()"></botao>
<auditoria *ngIf="icmsSt.id" [idUsuarioInclusao]="icmsSt.idUsuarioInclusao" [dataHoraInclusao]="icmsSt.dataHoraInclusao" [idUsuarioAlteracao]="icmsSt.idUsuarioAlteracao" [dataHoraAlteracao]="icmsSt.dataHoraAlteracao"></auditoria>
