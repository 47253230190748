export class EmpresaOperacaoContabilFinanceiro {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idContaContabil: number;
  public idEmpresa: number;
  public idOperacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public operacao: string;
  public contaContabil: string;
}
