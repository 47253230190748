import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r2003.component.html',
})
export class R2003Component extends RelatorioComponent {
  private lojas: any[] = [];
  public quantidadeDia: number;
  public filtros = filtros;
  public grupos = grupos;
  public nfe: number = 0;
  public ordens = ordens;
  public produtos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public tipoSugestao: string;
  public usaSemSugestaoCompra: boolean = true;

  listarRelatorio(criterios: Criterio[]): void {
    this.tipoSugestao = criterios.find((criterio) => criterio.nome == 'SUGESTAO').valor;
    if (criterios.find((criterio) => criterio.nome == 'QUANTIDADE_DIA')) {
      this.quantidadeDia = criterios.find((criterio) => criterio.nome == 'QUANTIDADE_DIA').valor;
    }
    super.listar(criterios).subscribe(() => {
      this.produtos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Produto').lista;
      this.lojas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Loja').lista;
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.vincular();
      this.ordenarAgrupar(this.produtos);
      this.atualizarRelatorioAdicionais();
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.produtos.forEach((produto) => {
      produto.lojas = this.lojas.filter((loja) => loja.idProduto == produto.id);
      this.calcularSugestao(produto);
    });
  }

  calcularSugestao(produto: any): void {
    produto = this.iniciarTotalizadorProduto(produto);
    produto.lojas.forEach((loja) => {
      loja.estoqueMaximo = loja.estoqueMaximo ? loja.estoqueMaximo : 0;
      switch (this.tipoSugestao) {
        case 'H':
          const maiorMedia: number = Math.max(loja.mediaMensalVendaAnoAnterior ? loja.mediaMensalVendaAnoAnterior : 0, loja.mediaMensalVendaTrimestreAnterior ? loja.mediaMensalVendaTrimestreAnterior : 0, loja.mediaMensalVendaTrimestrePosterior ? loja.mediaMensalVendaTrimestrePosterior : 0);
          if (maiorMedia) {
            loja.sugestao = (this.quantidadeDia / 30) * maiorMedia;
            this.definirMaiorMediaRegra(maiorMedia, loja);
          }
          break;
        case 'M':
          loja.sugestao = loja.estoqueMaximo - (loja.quantidadeFisica + loja.quantidadeComprada);
          break;
      }
      produto = this.totalizarProduto(produto, loja);
    });
  }

  definirMaiorMediaRegra(maiorMedia: number, loja: any) {
    switch (maiorMedia) {
      case loja.mediaMensalVendaAnoAnterior:
        loja.maiorMediaRegra = 'MV. ANO';
        break;

      case loja.mediaMensalVendaTrimestreAnterior:
        loja.maiorMediaRegra = 'MV. TRM. A';
        break;

      case loja.mediaMensalVendaTrimestrePosterior:
        loja.maiorMediaRegra = 'MV. TRM. P';
        break;
    }
  }

  iniciarTotalizadorProduto(produto: any): any {
    produto.estoqueMinimo = 0;
    produto.estoqueNfe = 0;
    produto.mediaMensalVendaAnoAnterior = 0;
    produto.mediaMensalVendaTrimestreAnterior = 0;
    produto.mediaMensalVendaTrimestrePosterior = 0;
    produto.vendaMesAnterior = 0;
    produto.vendaMesAtual = 0;
    produto.quantidadeFisica = 0;
    produto.quantidadeComprada = 0;
    produto.sugestao = 0;
    return produto;
  }

  totalizarProduto(produto: any, loja: any): any {
    produto.estoqueMinimo += loja.estoqueMinimo ? loja.estoqueMinimo : 0;
    produto.estoqueNfe += loja.estoqueNfe ? loja.estoqueNfe : 0;
    produto.mediaMensalVendaAnoAnterior += loja.mediaMensalVendaAnoAnterior ? loja.mediaMensalVendaAnoAnterior : 0;
    produto.mediaMensalVendaTrimestreAnterior += loja.mediaMensalVendaTrimestreAnterior ? loja.mediaMensalVendaTrimestreAnterior : 0;
    produto.mediaMensalVendaTrimestrePosterior += loja.mediaMensalVendaTrimestrePosterior ? loja.mediaMensalVendaTrimestrePosterior : 0;
    produto.vendaMesAnterior += loja.vendaMesAnterior ? loja.vendaMesAnterior : 0;
    produto.vendaMesAtual += loja.vendaMesAtual ? loja.vendaMesAtual : 0;
    produto.quantidadeFisica += loja.quantidadeFisica ? loja.quantidadeFisica : 0;
    produto.quantidadeComprada += loja.quantidadeComprada ? loja.quantidadeComprada : 0;
    produto.sugestao += loja.sugestao;
    return produto;
  }

  atualizarRelatorioAdicionais(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      if (relatorioAdicional.id == 150) {
        this.produtos.forEach((produto) => (produto.lojas = []));
        relatorioAdicional.marcado = this.usaSemSugestaoCompra ? 'S' : 'N';
        this.verificarSugestaoCompra();
      }
    });
  }

  verificarSugestaoCompra(): void {
    if (this.usaSemSugestaoCompra) {
      this.produtos.forEach((produto) => {
        produto.lojas = this.lojas.filter((loja) => loja.idProduto == produto.id);
        this.calcularSugestao(produto);
      });
    } else {
      this.produtos.forEach((produto) => {
        produto.lojas = this.lojas.filter((loja) => loja.idProduto == produto.id && loja.sugestao > 0);
        this.calcularSugestao(produto);
      });
    }
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaSemSugestaoCompra = this.definirRegra(relatorioAdicional, 150, this.usaSemSugestaoCompra);
    this.verificarSugestaoCompra();
  }
}
