<layout>
  <registro [posicao]="posicao" [paginacao]="paginacao" [numeroRegistro]="relatorios.length"></registro>
  <div class="row form-group naoImprimir">
    <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="listaCampoPesquisa" (alteracao)="campoPesquisa = $event.id" [obrigatorio]="true"></lista>
    <texto class="col-sm" [id]="'relatorio'" [campo]="filtro" (alteracao)="alterarValorFiltro($event)" [descricao]="bibDialogo.digiteNumeroNomeRelatorio"></texto>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-40 clique" (click)="util.ordenarLista(relatorios, 'nome')">{{ bibDialogo.relatorio }}</th>
        <th class="l-40 clique" (click)="util.ordenarLista(relatorios, 'relatorioModulo')">{{ bibDialogo.modulo }}</th>
        <th class="l-10"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let relatorio of relatorios | filtrarRelatorioPersonalizado: { filtro: filtro, especifico: campoPesquisa }; let i = index">
        <tr *ngIf="i >= posicao && i < posicao + paginacao">
          <td>
            <div>{{ relatorio.id + ' - ' + relatorio.nome }}</div>
          </td>
          <td>{{ relatorio.relatorioModulo }}</td>
          <td class="row justify-content-end">
            <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.editar" (btnAdicional)="abrirModalRelatorioAdicionalEmpresa(relatorio.id, relatorio.nome, relatorio.layoutEspecifico)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<paginacao *ngIf="!this.utilSessao.telaInterna" [paginacao]="paginacao" [numeroRegistro]="relatorios.length" (alteracao)="posicao = $event"></paginacao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirRelatorioAdicionalEmpresa()" [desabilitado]="temPermissaoEdicao ? false : true"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
