import { Component, Input } from '@angular/core';
import { MonetarioPipe } from 'src/app/utilitario/monetario.pipe';
import { Util } from 'src/app/utilitario/util';
import { Formulario } from '../formulario';

@Component({
  selector: 'decimal',
  templateUrl: './decimal.component.html',
  styleUrls: ['./decimal.component.css'],
})
export class DecimalComponent extends Formulario {
  @Input() numeroCasasDecimais: number = 2;
  @Input() valorMaximo: number;
  @Input() valorMinimo: number;
  @Input() aceitaValorNegativo: boolean = false;
  @Input() compactoBotao: boolean = false;
  private alterado: boolean = false;
  public monetario: MonetarioPipe = new MonetarioPipe();
  private util: Util = new Util();

  constructor() {
    super();
    this.tipoCampo = 7;
  }

  lerCampo(): string {
    if (!this.alterado && !this.focado) {
      return this.monetario.transform(this.campoAtual ? Number(this.campoAtual) : 0, this.numeroCasasDecimais);
    }
    return this.campoAtual;
  }

  alterarDecimal(decimal: string): void {
    decimal = this.removerCaracterInicialDiferenteDeNumero(decimal);
    this.alterado = true;
    if (decimal.length > 0 || this.aceitaValorNegativo) {
      if (decimal != '-') {
        this.campoAtual = decimal.replace(',', '.');
        this.campoAtual = parseFloat(this.campoAtual);
      }
    } else {
      this.campoAtual = 0;
    }
  }

  removerCaracterInicialDiferenteDeNumero(decimal: string): string {
    if (['.', ','].includes(decimal[0])) {
      return (decimal = '');
    }
    return decimal;
  }

  notificarAlteracao() {
    if (this.alterado) {
      this.campoAtual = this.util.arredondar(this.campoAtual, this.numeroCasasDecimais);
      if (this.valorMaximo) {
        this.campoAtual = parseFloat(this.campoAtual) <= this.valorMaximo ? this.campoAtual : this.valorMaximo.toString();
      }
      this.campoAtual = parseFloat(this.campoAtual);
      super.alterar();
      this.calculaMaximoCaracteres();
    }
    this.alterado = false;
    this.foco = false;
    this.navegar(false);
  }

  calculaMaximoCaracteres(): void {
    if (this.campoAtual != null && this.valorMaximo && this.campoAtual >= this.valorMaximo) {
      this.maximoCaracteres = this.valorMaximo.toString().length;
    }
  }

  validaValorMinimo(): void {
    if (this.valorMinimo) {
      this.campoAtual = parseFloat(this.campoAtual) >= this.valorMinimo ? parseFloat(this.campoAtual) : this.valorMinimo;
      super.alterar();
    }
  }

  navegar(focado: boolean): void {
    if (!focado) {
      this.validaValorMinimo();
    }
    super.navegar(focado);
  }

  retirarLetras(evento: any): boolean {
    return (evento.charCode >= 48 && evento.charCode <= 57) || evento.charCode == 46 || evento.charCode == 44 || (this.aceitaValorNegativo && evento.charCode == 45);
  }
}
