export class GrupoValor {
  public alterado: string = 'N';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public grupo: string;
  public id: number;
  public idEmpresa: number;
  public idGrupo: number;
  public idLoja: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public margemGerencial: number;
  public margemLucro: number;
}
