import { Component, Input } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoChave } from 'src/app/modelo/movimentacaoChave';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { MovimentacaoChaveFrmComponent } from './frm/movimentacaoChaveFrm.component';

@Component({
  selector: 'movimentacaoChave',
  templateUrl: './movimentacaoChave.component.html',
})
export class MovimentacaoChaveComponent extends PaginaComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;
  private modalEmt: any;
  public componente: any;
  public ehMinhaModal: boolean = false;

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.movimentacaoChave) {
        const chave: MovimentacaoChave = identificacao.conteudo;
        if (this.movimentacaoControlador.movimentacao.movimentacaoChaves.filter((movimentacaoChave) => movimentacaoChave.chave == chave.chave).length == 0) {
          this.movimentacaoControlador.movimentacao.movimentacaoChaves.push(chave);
        }
      }
    });
  }

  abrirModal(): void {
    this.utilSessao.setIdentificacao(new Identificacao('nfeModelos', this.movimentacaoControlador.nfeModelos));
    this.componente = MovimentacaoChaveFrmComponent;
  }

  editarChave(posicao: number) {
    this.utilSessao.setIdentificacao(new Identificacao('nfeModelos', this.movimentacaoControlador.nfeModelos));
    this.utilSessao.setIdentificacao(new Identificacao('movimentacaoChave', this.movimentacaoControlador.movimentacao.movimentacaoChaves[posicao]));
    this.componente = MovimentacaoChaveFrmComponent;
    this.movimentacaoControlador.movimentacao.movimentacaoChaves.splice(posicao, 1);
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }
}
