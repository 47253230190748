import { Type } from 'class-transformer';

export class Credito {
  @Type(() => Date)
  public data: Date;

  public chave: string;
  public complemento: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idFinanceiro: number;
  public idLoja: number;
  public idFinanceiroBaixa: number;
  public idMovimentacao: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public origem: string;
  public parceiro: string;
  public parceiroCredito: number;
  public pertencente: string;
  public resgateCredito: string = 'N';
  public resgateFinanceiro: number;
  public valor: number;
}
