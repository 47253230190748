import { Component, Input } from '@angular/core';
import { RelatorioComponent } from '../../relatorio.component';

@Component({
  selector: 'r1127produtos',
  templateUrl: './r1127produtos.component.html',
})
export class R1127ProdutosComponent extends RelatorioComponent {
  @Input() comissao: any;
  // public filtros = filtros;
  // public grupos = grupos;
  // public ordens = ordens;
  // public comissoes: any[] = [];
  // public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  // public filtroSituacao: string;
  // public componente: any;
}
