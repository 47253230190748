export class FinanceiroBoleto {
  public dataGeracao: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dataRemessa: Date;
  public dataVencimento: Date;
  public id: number;
  public idConta: number;
  public idEmpresa: number;
  public idFinanceiro: number;
  public idLoja: number;
  public idTecnospeed: string;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public numero: number;
  public protocoloAlteracao: string;
  public protocoloCancelamento: string;
  public protocoloImpressao: string;
  public situacao: string;
  public status: number;
  public statusDescricao: string;
  public valor: number;
  public valorPago: number = 0;
  public homologacao: string = 'S';
  public idsFinanceiros: number[] = [];
  public dataPagamento: Date;
  public acrescimoPago: number = 0;
  public descontoConcedido: number = 0;
}
