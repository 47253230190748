import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1159.component.html',
})
export class R1159Component extends RelatorioComponent {
  public estoque: string;
  public estoques: any[] = [];
  public filtros = filtros;
  public grupos = grupos;
  public lojaDoisAbreviacao: string;
  public lojaUmAbreviacao: string;
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    const lojaUm: number = criterios.find((criterio) => criterio.nome == 'ID_LOJA_UM').valor;
    const lojaDois: number = criterios.find((criterio) => criterio.nome == 'ID_LOJA_DOIS').valor;
    const estoque: string = criterios.find((criterio) => criterio.nome == 'ESTOQUE').valor;
    this.lojaUmAbreviacao = this.utilSessao.getLojas().find((loja) => loja.id == lojaUm).abreviacao;
    this.lojaDoisAbreviacao = this.utilSessao.getLojas().find((loja) => loja.id == lojaDois).abreviacao;
    this.estoque = estoque == 'EST.QUANTIDADE_DISPONIVEL' ? this.bibDialogo.disponivel : estoque == 'EST.QUANTIDADE_FISICA' ? this.bibDialogo.fisica : this.bibDialogo.nfe;
    super.listar(criterios).subscribe(() => {
      this.estoques = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Estoque').lista;
      this.ordenarAgrupar(this.estoques);
      this.ehImprimirRelatorio();
    });
  }
}
