import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { BuscaAnterior } from 'src/app/modelo/buscaAnterior';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { Util } from 'src/app/utilitario/util';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { Formulario } from '../formulario';

@Component({
  selector: 'busca',
  templateUrl: './busca.component.html',
  styleUrls: ['./busca.component.css'],
})
export class BuscaComponent extends Formulario {
  @Input() buscarPorNumero: boolean = false;
  @Input() buscarPorId: boolean = false;
  @Input() criterios: Criterio[] = [];
  @Input() icone: string = 'fa fa-search';
  @Input() listaExterna: boolean = false;
  @Input() somenteAtivo: boolean = true;
  @Output() enterEmt = new EventEmitter<boolean>();
  @Output() espacoEmt = new EventEmitter<boolean>();
  @Output() setaBaixoEmt = new EventEmitter<boolean>();
  @Output() setaCimaEmt = new EventEmitter<boolean>();
  @Input() apresentarAbreviacaoEmpresa: boolean = false;

  @ViewChild('listaBusca') private listaBusca: ElementRef;
  private campoInicial: string;
  private servicoAtualizado: string;
  private util: Util = new Util();
  public _minimoCaracterBusca: number = 0;
  public bibPropriedade = bibPropriedade;
  public carregando: boolean = false;
  public escolhendo: boolean = false;
  public focado: boolean = false;
  public focoBtnAdicional: boolean = false;
  public lista: any[] = [];
  public mensagemAtencao: string;
  public objeto: any = null;
  public posicao: number = 0;
  public _servico: string;
  public empresaAbreviacaoSessao: string;
  public bibDialogo = bibDialogo;
  public bibServico = bibServico;
  public buscaAtual: string = '';
  public buscaAnterior: string = '';
  public usaModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';

  @Input() ehFiltroVinculado: boolean = false;

  @Input()
  set servico(servico: string) {
    this._servico = servico;
    this.ehApresentarDescricao();
  }

  @Input()
  set campo(campo: any) {
    this.campoInicial = campo;
    this.objeto = null;
    this.iniciar(campo);
  }

  @Input()
  set minimoCaracterBusca(minimoCaracterBusca: any) {
    this._minimoCaracterBusca = minimoCaracterBusca;
    this.ehAtencao();
  }

  constructor(private comunicacaoService: ComunicacaoService, public utilNotificacao: UtilNotificacao) {
    super();
  }

  ngOnInit(): void {
    this.utilNotificacao.limpaBuscaEmt.subscribe(() => {
      this.campo = null;
    });
    this.empresaAbreviacaoSessao = this.utilSessao.getEmpresa().abreviacao;
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (this.focado) {
      switch (event.keyCode) {
        case bibAtalho.enter:
          event.preventDefault();
          this.ehEnter();
          break;

        case bibAtalho.tab:
          if (!this.listaExterna) {
            this.escolhendo = false;
          }
          break;

        case bibAtalho.setaCima:
          event.preventDefault();
          if (!this.listaExterna) {
            this.marcarLista(-1);
          } else {
            this.setaCimaEmt.emit(true);
          }
          break;

        case bibAtalho.setaBaixo:
          event.preventDefault();
          if (!this.listaExterna) {
            this.marcarLista(1);
          } else {
            this.setaBaixoEmt.emit(true);
          }
          break;
        default:
          this.lista = [];
          break;
      }
    }
  }

  ehEnter(): void {
    if (this.lista.length > 0) {
      this.selecionar();
    } else {
      this.buscar();
    }
  }

  alterarCampo(campoAlterado: string): void {
    this.salvarCampoInicial();
    this.campoAtual = campoAlterado;
    if (this.listaExterna) {
      super.alterar();
    }
    if (this.preenchido && !this.listaExterna) {
      this.limpar();
    }
    this.ehAtencao();
  }

  ehApresentarDescricao(): void {
    if (this.ehBuscaNumero() && !this.ehBuscaParceiro()) {
      this.ajuda = bibDialogo.permiteBuscarNumeroNome;
      this.ajudaDireita = true;
    } else {
      if (this.ehBuscaParceiro()) {
        this.ajuda = bibDialogo.permiteBuscarNumeroNomeCpfCnpj;
        this.ajudaDireita = true;
      }
    }
  }

  ehBuscaParceiro(): boolean {
    if (this._servico == this.bibServico.parceiro || this._servico == this.bibServico.cliente || this._servico == this.bibServico.destinoParceiro || this._servico == this.bibServico.colaboradorComprador || this._servico == this.bibServico.colaboradorVendedor || this._servico == this.bibServico.colaboradorInterno || this._servico == this.bibServico.transportadora || this._servico == this.bibServico.fornecedor) {
      return true;
    } else {
      return false;
    }
  }

  salvarCampoInicial(): void {
    if (this.campoInicial == null) {
      if (this.campoAtual) {
        this.campoInicial = this.campoAtual;
      } else {
        this.campoInicial = '';
      }
    }
  }

  buscar(): void {
    if (!this.desabilitado) {
      this.focado = true;
      this.ehAtencao();
      if ((this.ehQuantidadeMimina() && this.ehValidoBusca()) || this.verificarBuscaNumero()) {
        if (this.listaExterna) {
          super.clicarBtnAdicional();
        } else {
          this.focado = true;
          this.focoId = Math.random();
          this.posicao = 0;
          this.carregando = true;
          if (this.ehBuscaDiferenteDaAnterior() || this.ehFiltroVinculado) {
            this.comunicacaoService.listar(new Transporte(this.filtrar()), this.servicoAtualizado).subscribe((res) => {
              this.lista = res as any[];
              this.carregando = false;
              this.atualizarListaBuscasAnteriores(this.lista, this._rotulo);
            });
          } else {
            setTimeout(() => (this.lista = this.utilSessao.getBuscaAnterior().find((lista) => lista.rotulo == this._rotulo && lista.busca == this.campoAtual).lista));
            setTimeout(() => (this.carregando = false));
          }
        }
      }
    }
  }

  ehBuscaDiferenteDaAnterior(): boolean {
    this.buscaAtual = this.campoAtual;
    if (this.buscaAtual != this.buscaAnterior) {
      this.buscaAnterior = this.buscaAtual;
      return true;
    } else {
      if (this.buscaAtual == this.buscaAnterior) {
        return false;
      }
    }
  }

  atualizarListaBuscasAnteriores(lista: any[], rotulo: string): void {
    let listaBuscaAnterior: BuscaAnterior[] = this.utilSessao.getBuscaAnterior() != null ? this.utilSessao.getBuscaAnterior() : [];
    let buscaAnterior: BuscaAnterior = new BuscaAnterior();
    buscaAnterior.lista = plainToClass(this.objeto, lista) as any;
    buscaAnterior.rotulo = rotulo;
    buscaAnterior.busca = this.campoAtual;
    listaBuscaAnterior.push(buscaAnterior);
    this.utilSessao.setBuscaAnterior(listaBuscaAnterior);
  }

  clicarBtnAdicional(): void {
    this.buscar();
  }

  definirNome(): void {
    if (this.objeto) {
      this.campoAtual = this.objeto.numeroNomeParceiro || this.objeto.nome;
      if (this.servico == bibServico.cidade) {
        this.campoAtual = this.objeto.cidadeIbge;
      }
    } else {
      this.campoAtual = this.campoInicial;
    }
  }

  ehAtencao(): string {
    this.atualizarQuantidadeMinima();
    if (this.ehQuantidadeMimina()) {
      return (this.mensagemAtencao = '');
    } else {
      return (this.mensagemAtencao = `Mín. ${this._minimoCaracterBusca} caracteres`);
    }
  }

  ehIcone(): string {
    if (this.ehQuantidadeMimina()) {
      if (this.preenchido == false || (this.objeto && this.campoAtual != this.objeto.nome)) {
        return this.icone;
      }
    }
    return null;
  }

  ehQuantidadeMimina(): boolean {
    this.atualizarQuantidadeMinima();
    if (this._minimoCaracterBusca != 0) {
      let quantidadeNumeroLetras: number = 0;
      const campoBusca: string = this.campoAtual;
      if (Number(this.campoAtual) && (this._servico != bibServico.ncm || campoBusca.length > this._minimoCaracterBusca)) {
        return true;
      }
      if (this.campoAtual != null && this.campoAtual.length > 0) {
        for (let index = 0; index < this.campoAtual.length; index++) {
          if (this.campoAtual[index].match('[A-Za-z0-9]')) {
            quantidadeNumeroLetras++;
          }
        }
      }
      return quantidadeNumeroLetras >= this._minimoCaracterBusca;
    }
    return true;
  }

  atualizarQuantidadeMinima() {
    this.definirMinimoCaracterParceiro();
    this.definirMinimoCaracterProduto();
    this.definirMinimoCaracterProdutoFormulado();
    this.definirMinimoCaracterContaContabil();
    this.definirMinimoCaracterCidade();
    this.definirMinimoCaracterGrupo();
    this.definirMinimoCaracterFabricante();
    this.definirMinimoCaracterBairro();
    this.definirMinimoCaracterNcm();
  }

  verificarBuscaNumero(): boolean {
    const campoBusca: string = this.campoAtual;
    if (Number(this.campoAtual) && (this._servico != bibServico.ncm || campoBusca.length > this._minimoCaracterBusca)) {
      return true;
    }
    return false;
  }

  ehValidoBusca(): boolean {
    const campoBusca: string = this.campoAtual;
    if (campoBusca != null && !Number(campoBusca.substring(0, 3))) {
      let numeroDeLetras: number = 0;
      for (let index = 0; index < campoBusca.length; index++) {
        if (campoBusca[index].match('[A-Za-z0-9]')) {
          numeroDeLetras++;
        }
      }
      return numeroDeLetras >= 3 || this._minimoCaracterBusca == 0;
    }
    return true;
  }

  ehEscolhendo(escolhendo: boolean, posicao: number): void {
    this.posicao = posicao;
    this.escolhendo = escolhendo;
  }

  filtrar(): Criterio[] {
    this.servicoAtualizado = this._servico;
    if (this.ehBuscaNumero()) {
      this.buscarPorNumero = true;
    }
    if (this.ehBuscaId()) {
      this.buscarPorId = true;
    }
    this.criterios = this.criterios.filter((criterio) => criterio.nome != 'ID' && criterio.nome != 'NOME' && criterio.nome != 'NUMERO' && criterio.nome != 'ATIVO' && criterio.nome != 'CLIENTE' && criterio.nome != 'FORNECEDOR' && criterio.nome != 'TRANSPORTADORA' && criterio.nome != 'COLABORADOR');
    let ehCpf: boolean = Number(this.campoAtual) && this.campoAtual.length == 11;
    let ehCnpj: boolean = Number(this.campoAtual) && this.campoAtual.length == 14;
    if (Number(this.campoAtual) && this.buscarPorNumero && !ehCpf && !ehCnpj) {
      this.criterios.push(new Criterio('NUMERO', this.campoAtual.replace(/[^0-9]/g, '')));
    } else if (Number(this.campoAtual) && this.buscarPorId) {
      this.criterios.push(new Criterio('ID', this.campoAtual.replace(/[^0-9]/g, '')));
    } else {
      this.campoAtual = ehCpf ? this.campoAtual.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') : ehCnpj ? this.campoAtual.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : this.campoAtual;
      if ((!this.bibServico.produto) || (this.bibServico.produto && String(this.campoAtual).substring(0, 2) != '**')) {
        if (this.campoAtual) {
          this.criterios.push(new Criterio('NOME', this.campoAtual.toUpperCase()));
        }
      }
    }
    if (String(this.campoAtual).substring(0, 2) == '**') {
      this.criterios.push(new Criterio('REFERENCIA', String(this.campoAtual).substring(2, 2000).toUpperCase()));
    }

    if (this.somenteAtivo) {
      this.criterios.push(new Criterio('ATIVO', 'S'));
    }
    switch (this._servico) {
      case this.bibServico.cliente:
        this.servicoAtualizado = this.bibServico.destinoParceiro;
        this.criterios.push(new Criterio('CLIENTE', 'S'));
        return this.criterios;

      case this.bibServico.destinoParceiro:
        this.servicoAtualizado = this.bibServico.destinoParceiro;
        return this.criterios;

      case this.bibServico.colaborador:
        this.servicoAtualizado = this.bibServico.parceiro;
        this.criterios.push(new Criterio('COLABORADOR', 'S'));
        return this.criterios;

      case this.bibServico.colaboradorVendedor:
        this.servicoAtualizado = this.bibServico.parceiro;
        this.criterios.push(new Criterio('COLABORADOR', 'S'));
        this.criterios.push(new Criterio('VENDEDOR', 'S'));
        return this.criterios;

      case this.bibServico.colaboradorComprador:
        this.servicoAtualizado = this.bibServico.parceiro;
        this.criterios.push(new Criterio('COLABORADOR', 'S'));
        this.criterios.push(new Criterio('COMPRADOR', 'S'));
        return this.criterios;

      case this.bibServico.colaboradorInterno:
        this.servicoAtualizado = this.bibServico.parceiro;
        this.criterios.push(new Criterio('COLABORADOR', 'S'));
        this.criterios.push(new Criterio('INTERNO', 'S'));
        return this.criterios;

      case this.bibServico.fornecedor:
        this.servicoAtualizado = this.bibServico.destinoParceiro;
        this.criterios.push(new Criterio('FORNECEDOR', 'S'));
        return this.criterios;

      case this.bibServico.transportadora:
        this.servicoAtualizado = this.bibServico.parceiro;
        this.criterios.push(new Criterio('TRANSPORTADORA', 'S'));
        return this.criterios;

      case this.bibServico.veiculoParceiro:
        this.criterios.push(new Criterio('TRANSPORTADORA', 'S'));
        return this.criterios;

      case this.bibServico.contaBancaria:
        this.servicoAtualizado = this.bibServico.conta;
        this.criterios.push(new Criterio('CONTA_BANCARIA', 'S'));
        return this.criterios;

      case this.bibServico.contaCaixa:
        this.servicoAtualizado = this.bibServico.conta;
        this.criterios.push(new Criterio('CAIXA', 'S'));
        return this.criterios;

      default:
        return this.criterios;
    }
  }

  ehBuscaNumero(): boolean {
    return this.buscarPorNumero || this._servico == this.bibServico.parceiro || this._servico == this.bibServico.cliente || this._servico == this.bibServico.destinoParceiro || this._servico == this.bibServico.colaboradorComprador || this._servico == this.bibServico.colaboradorVendedor || this._servico == this.bibServico.colaboradorInterno || this._servico == this.bibServico.transportadora || this._servico == this.bibServico.fornecedor || this._servico == this.bibServico.produto || this._servico == this.bibServico.produtoPorUnidade;
  }

  ehBuscaId(): boolean {
    return this._servico == this.bibServico.contaContabil;
  }

  definirMinimoCaracterParceiro(): void {
    if (this._servico == this.bibServico.parceiro || this._servico == this.bibServico.transportadora || this._servico == this.bibServico.cliente || this._servico == this.bibServico.destinoParceiro || this._servico == this.bibServico.fornecedor) {
      this._minimoCaracterBusca = 3;
    }
  }

  definirMinimoCaracterProduto(): void {
    if (this._servico == this.bibServico.produto || this._servico == this.bibServico.produtoPorUnidade) {
      this._minimoCaracterBusca = 4;
    }
  }

  definirMinimoCaracterProdutoFormulado(): void {
    if (this._servico == this.bibServico.produtoFormulado) {
      this._minimoCaracterBusca = 3;
    }
  }

  definirMinimoCaracterContaContabil(): void {
    if (this._servico == this.bibServico.contaContabil || this._servico == this.bibServico.contaReferencial) {
      this._minimoCaracterBusca = 4;
    }
  }

  definirMinimoCaracterCidade(): void {
    if (this._servico == this.bibServico.cidade) {
      this._minimoCaracterBusca = 3;
    }
  }

  definirMinimoCaracterGrupo(): void {
    if (this._servico == this.bibServico.grupo) {
      this._minimoCaracterBusca = 0;
    }
  }

  definirMinimoCaracterFabricante(): void {
    if (this._servico == this.bibServico.fabricante) {
      this._minimoCaracterBusca = 3;
    }
  }

  definirMinimoCaracterBairro(): void {
    if (this._servico == this.bibServico.bairro) {
      this._minimoCaracterBusca = 3;
    }
  }

  definirMinimoCaracterNcm(): void {
    if (this._servico == this.bibServico.ncm) {
      this._minimoCaracterBusca = 5;
    }
  }

  limpar(): void {
    this.objeto = null;
    this.campoInicial = '';
    this.definirNome();
    this.preenchido = false;
    this.emitirAlteracao(new Object());
  }

  marcarLista(incremento: number): void {
    this.posicao = this.util.marcarLista(this.posicao, incremento, this.lista);
    const scroll: number = 46.39 * incremento;
    if (this.listaBusca) {
      this.listaBusca.nativeElement.scrollTop += scroll;
    }
  }

  sairFocoBotao(): void {
    this.focoBtnAdicional = false;
    if (!this.focado) {
      this.navegar(false);
    }
  }

  navegar(focado: boolean): void {
    this.focado = focado;
    if (!this.focoBtnAdicional) {
      this.ocultarLista();
      super.navegar(focado);
    }
  }

  selecionar(): void {
    this.objeto = this.lista[this.posicao];
    this.limparLista(this.lista);
    this.definirNome();
    this.preenchido = true;
    this.emitirAlteracao(this.objeto);
  }

  limparLista(lista: any[]): void {
    while (lista.length > 0) {
      lista.splice(0, 1);
    }
  }

  ocultarLista(): void {
    if (!this.escolhendo) {
      this.limparLista(this.lista);
      this.definirNome();
    }
  }
}
