<div class="mb-0" *ngIf="movimentacaoControlador">
  <div class="row justify-content-between align-items-center">
    <titulo class="ml-2" [titulo]="movimentacaoControlador.bibDialogo.itens" [imagem]="movimentacaoControlador.bibImagem.produto" [obrigatorio]="true" [preenchido]="movimentacaoControlador.movimentacao.movimentacaoProdutos.length"></titulo>
    <div>
      <botao *ngIf="movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 1 && movimentacaoControlador.movimentacao.importacaoXml == 'N'" [compacto]="movimentacaoControlador.compacto" [legenda]="ordenando ? movimentacaoControlador.bibDialogo.pronto : movimentacaoControlador.bibDialogo.ordenar" [desabilitado]="movimentacaoControlador.bloqueado" (botaoEmt)="permitirOrdenar()"></botao>
      <botao *ngIf="movimentacaoControlador.movimentacao.importacaoXml == 'N' && !movimentacaoControlador.transferenciaEntrada" [compacto]="movimentacaoControlador.compacto" [legenda]="movimentacaoControlador.bibDialogo.adicionar + ' ' + movimentacaoControlador.bibDialogo.item.toLowerCase()" [desabilitado]="movimentacaoControlador.bloqueado" (botaoEmt)="ehAbrirModalProduto()"></botao>
      <botao *ngIf="(movimentacaoControlador.movimentacao.importacaoXml == 'N' && movimentacaoControlador.vincular && movimentacaoControlador.movimentacao.idLoja && movimentacaoControlador.movimentacao.idParceiro) || (movimentacaoControlador.transferenciaEntrada && movimentacaoControlador.movimentacao.idLoja && movimentacaoControlador.movimentacao.idLojaDestino)" [compacto]="movimentacaoControlador.compacto" [legenda]="this.movimentacaoControlador.vincular ? this.movimentacaoControlador.adicionaVinculo : movimentacaoControlador.bibDialogo.adicionarComVinculo" [desabilitado]="movimentacaoControlador.bloqueado" (botaoEmt)="ehAbrirModalMovimentacao()" [focoId]="focoIdVincular"></botao>
      <botao *ngIf="movimentacaoControlador.notaTerceiro && movimentacaoControlador.movimentacao.importacaoXml == 'S'" [compacto]="movimentacaoControlador.compacto" [legenda]="this.movimentacaoControlador.vincular ? this.movimentacaoControlador.adicionaVinculoXml : movimentacaoControlador.bibDialogo.vincular" [desabilitado]="movimentacaoControlador.bloqueado" (botaoEmt)="ehAbrirModalMovimentacao(true)" [focoId]="focoIdVincular"></botao>
    </div>
  </div>
  <div class="row" *ngIf="!movimentacaoControlador.transferenciaEntrada && movimentacaoControlador.movimentacao.idLoja && movimentacaoControlador.movimentacao.movimentacaoProdutos.length == 0">
    <p class="col-sm-12 pl-3">
      {{ movimentacaoControlador.bibDialogo.nenhumaAbr + ' ' + movimentacaoControlador.bibDialogo.item.toLowerCase() + ', ' }} <strong class="clique" (click)="ehAbrirModalProduto()">{{ movimentacaoControlador.bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ movimentacaoControlador.bibDialogo.paraAdicionar.toLowerCase() }}
    </p>
  </div>
  <table *ngIf="movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0" class="table table-responsive-sm tabela-formulario mb-0">
    <thead>
      <tr>
        <th *ngIf="movimentacaoControlador.utilSessao.sistema != 'Sigescom-w' && movimentacaoControlador.saida && (movimentacaoControlador.pedido || movimentacaoControlador.nota)" class="l-5"></th>
        <th [ngClass]="ordenando ? 'l-15' : 'l-5'"></th>
        <th class="col-sm">{{ movimentacaoControlador.bibDialogo.item }}</th>
        <th class="lm-100">{{ movimentacaoControlador.bibDialogo.unidade }}</th>
        <th [ngClass]="movimentacaoControlador.orcamento ? 'lm-100' : 'lm-150'" class="text-right">{{ movimentacaoControlador.bibDialogo.quantidade }}</th>
        <th class="lm-150" *ngIf="movimentacaoControlador.movimentacaoManual">{{ movimentacaoControlador.bibDialogo.entradaSaida }}</th>
        <ng-container *ngIf="!movimentacaoControlador.interna && !movimentacaoControlador.transferencia">
          <th *ngIf="movimentacaoControlador.saida && !movimentacaoControlador.nota" class="lm-100 text-right">{{ movimentacaoControlador.bibDialogo.tabela }}</th>
          <th *ngIf="movimentacaoControlador.usaDescontoUnitario" class="lm-100 text-right">{{ movimentacaoControlador.bibDialogo.descontoAbreviacao }}<ajuda [ajuda]="bibDialogo.desconto + ' ' + bibDialogo.unitario.toLowerCase()"></ajuda></th>
          <th [ngClass]="movimentacaoControlador.orcamento && movimentacaoControlador.utilSessao.sistema == 'Sigescom-w' ? 'lm-100' : 'lm-150'" class="text-right">{{ movimentacaoControlador.bibDialogo.valorUnitario }}</th>
          <th *ngIf="movimentacaoControlador.usaDescontoUnitario == false" class="lm-100 text-right">{{ movimentacaoControlador.bibDialogo.descontoAbreviacao }}</th>
          <th class="lm-100 text-right">{{ movimentacaoControlador.bibDialogo.total }}</th>
        </ng-container>
        <th class="l-5" *ngIf="movimentacaoControlador.bloqueado"></th>
        <th class="l-5" *ngIf="!movimentacaoControlador.interna && !movimentacaoControlador.transferencia"></th>
        <th class="l-10" *ngIf="!movimentacaoControlador.bloqueado"></th>
        <th class="l-5" *ngIf="!movimentacaoControlador.balanco && !movimentacaoControlador.movimentacaoManual"></th>
        <th class="l-5" *ngIf="movimentacaoControlador.notaTerceiro"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacaoControlador.movimentacao.movimentacaoProdutos; let i = index">
        <ng-container *ngIf="i >= posicao && i < posicao + paginacaoProduto">
          <tr [ngClass]="idMovimentacaoProOrigem != null && idMovimentacaoProOrigem == movimentacaoProduto.id ? 'bg-amarelo-claro' : ''">
            <td *ngIf="movimentacaoControlador.utilSessao.sistema != 'Sigescom-w' && movimentacaoControlador.saida && (movimentacaoControlador.pedido || movimentacaoControlador.nota)" class="text-center">
              <icone Class="clique" *ngIf="movimentacaoProduto.grauToxicologica == 'I' || movimentacaoProduto.grauToxicologica== 'II' || movimentacaoProduto.grauToxicologica == 'III' || movimentacaoProduto.grauToxicologica == 'IV' || movimentacaoProduto.grauToxicologica == 'V' || movimentacaoProduto.classeToxicologica" [icone]="bibIcone.quadrado" [ajudaDireita]="true" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="movimentacaoProduto.grauToxicologica == 'I' ? bibPropriedade.icone.vermelho : movimentacaoProduto.grauToxicologica == 'II' ? bibPropriedade.icone.amarelo : movimentacaoProduto.grauToxicologica == 'III' ? bibPropriedade.icone.azul : movimentacaoProduto.grauToxicologica == 'IV' ? bibPropriedade.icone.verde : movimentacaoProduto.grauToxicologica == 'V' ? bibPropriedade.icone.verde : bibPropriedade.icone.preto" [mensagem]="movimentacaoProduto.receituario ? bibDialogo.receituarioEmitido : movimentacaoProduto.classeToxicologica" (click)="abrirInformacoesReceituario(movimentacaoProduto)"></icone>
            </td>
            <th *ngIf="!ordenando" class="text-right">{{ i + 1 }}</th>
            <th *ngIf="ordenando" class="text-right"><lista [lista]="ordens" [campo]="i + 1" (alteracao)="ordenar(i, $event)"></lista></th>
            <td>
              <busca *ngIf="movimentacaoProduto.idProduto != idProdutoPersonalizado" (click)="consultarMovimentacaoOrigem(movimentacaoProduto.idMovimentacaoProOrigem)" [campo]="(movimentacaoProduto.produtoNumero ? movimentacaoProduto.produtoNumero + ' - ' : '') + (movimentacaoProduto.produtoNome ? movimentacaoProduto.produtoNome : movimentacaoProduto.produto ? movimentacaoProduto.produto : '')" [atencao]="movimentacaoProduto.bloqueadoEstoque == 'S' && movimentacaoProduto.bloqueadoFinanceiro == 'S' ? bibDialogo.bloqueadoFinanceiro + '\n' + bibDialogo.bloquear + ' ' + bibDialogo.estoque.toLowerCase() : movimentacaoProduto.bloqueadoEstoque == 'S' ? bibDialogo.bloquear + ' ' + bibDialogo.estoque.toLowerCase() : movimentacaoProduto.bloqueadoFinanceiro == 'S' ? bibDialogo.bloqueadoFinanceiro : null" [id]="bibDialogo.item + i" [servico]="bibServico.produto" [desabilitado]="movimentacaoProduto.produtoNumero ? true : false" (alteracao)="validarProdutos($event, movimentacaoProduto)" [minimoCaracterBusca]="2" [ajuda]="movimentacaoProduto.idMovimentacaoProOrigem != null ? bibDialogo.itemVinculado : null" [fonteMenor]="true" [criterios]="[this.movimentacaoControlador.filtroIdLoja]"></busca>
              <texto *ngIf="movimentacaoProduto.idProduto == idProdutoPersonalizado" [campo]="movimentacaoProduto.produtoNome" (alteracao)="movimentacaoProduto.produtoNome = $event" [desabilitado]="movimentacaoControlador.bloqueado" [obrigatorio]="true" [maximoCaracteres]="350" [fonteMenor]="true"></texto>
            </td>
            <td>
              <texto *ngIf="movimentacaoProduto.produtoUnidades.length == 0" [campo]="movimentacaoProduto.abreviacao" [id]="bibDialogo.abreviacao + i" [desabilitado]="true" [fonteMenor]="true"></texto>
              <lista [id]="'unidade'" *ngIf="movimentacaoProduto.produtoUnidades.length > 0" [campo]="movimentacaoProduto.abreviacao" [lista]="movimentacaoProduto.produtoUnidades" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.idMovimentacaoProOrigem != null" (alteracao)="setUnidade(movimentacaoProduto, $event)" [tipo]="bibPropriedade.lista.produtoUnidade"></lista>
            </td>
            <td>
              <decimal *ngIf="((movimentacaoControlador.pedido && movimentacaoControlador.entrada) == false && !movimentacaoControlador.balanco && !movimentacaoControlador.orcamento && movimentacaoControlador.estoqueCarteira != 1)" [ajuda]="movimentacaoProduto.expandido ? bibDialogo.ocultarMaisInformacoes : bibDialogo.maisInformacoes" [campo]="movimentacaoProduto.getQuantidade()" (alteracao)="setQuantidade(movimentacaoProduto, $event)" [id]="'quantidade' + i" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.movimentacaoQuantidades.length > 1 || movimentacaoControlador.transferenciaEntrada" [icone]="movimentacaoProduto.expandido ? movimentacaoControlador.bibIcone.setaCima : movimentacaoControlador.bibIcone.setaBaixo" [iconeDesabilitado]="false" (btnAdicional)="expandir(movimentacaoProduto, i)" [numeroCasasDecimais]="this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais" [focoId]="linha == i && coluna == 1 ? 1 : null" (navegacao)="$event == true ? atualizarLinhaColuna(i, 1) : (focadoCampoMovimentacaoProduto = false)" [focoId]="movimentacaoControlador.focoIdAdicionar" [compactoBotao]="true"></decimal>
              <decimal *ngIf="(movimentacaoControlador.pedido && movimentacaoControlador.entrada) || movimentacaoControlador.orcamento || movimentacaoControlador.estoqueCarteira == 1" [campo]="movimentacaoProduto.getQuantidade()" (alteracao)="setQuantidade(movimentacaoProduto, $event)" [id]="bibDialogo.quantidade + i" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.movimentacaoQuantidades.length > 1 || movimentacaoControlador.transferenciaEntrada" [numeroCasasDecimais]="this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais" [focoId]="linha == i && coluna == 1 ? 1 : null" (navegacao)="$event == true ? atualizarLinhaColuna(i, 1) : (focadoCampoMovimentacaoProduto = false)" [focoId]="movimentacaoControlador.focoIdAdicionar"></decimal>
              <decimal *ngIf="movimentacaoControlador.balanco" [campo]="movimentacaoProduto.quantidadeBalanco" (alteracao)="movimentacaoProduto.quantidadeBalanco = $event" (navegacao)="$event == false ? definirRegraBalanco(movimentacaoProduto) : null" [desabilitado]="movimentacaoControlador.bloqueado" [numeroCasasDecimais]="this.empresaRegraCasasDecimais" [focoId]="movimentacaoControlador.focoIdAdicionar"></decimal>
            </td>
            <td *ngIf="movimentacaoControlador.movimentacaoManual">
              <lista [id]="'entradaSaida'" [campo]="movimentacaoProduto.estoqueFisico" [lista]="estoque" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado" (alteracao)="setMovimentacaoManual(movimentacaoProduto, $event.id)"></lista>
            </td>
            <ng-container *ngIf="!movimentacaoControlador.interna && !movimentacaoControlador.transferencia">
              <td *ngIf="movimentacaoControlador.saida && !movimentacaoControlador.nota">
                <decimal [campo]="movimentacaoProduto.valorUnitarioTabela" [id]="'valorUnitarioTabela' + i" [desabilitado]="true" [numeroCasasDecimais]="this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais" [focoId]="movimentacaoControlador.focoIdAdicionar"></decimal>
              </td>
              <td *ngIf="movimentacaoControlador.usaDescontoUnitario"> 
                <decimal *ngIf="!movimentacaoProduto.descontoPorValor" [ajuda]="bibDialogo.mudarPara + ' ' + bibDialogo.valorDesconto.toLowerCase()" [id]="'descontoPercentualUnitario'" [campo]="movimentacaoProduto.descontoPercentualUnitario" (alteracao)="setDescontoPercentualUnitario(movimentacaoProduto, $event)" [focoId]="linha == i && coluna == 3 ? 1 : null" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.importacaoXml == 'S' || movimentacaoProduto.valorUnitarioTabela == 0" [valorMaximo]="100" (navegacao)="$event == true ? atualizarLinhaColuna(i, 3) : (focadoCampoMovimentacaoProduto = false)" [icone]="movimentacaoControlador.bibIcone.percentual" (btnAdicional)="mudarTipoDesconto(movimentacaoProduto)" [compactoBotao]="true"></decimal>
                <decimal *ngIf="movimentacaoProduto.descontoPorValor" [ajuda]="bibDialogo.mudarPara + ' ' + bibDialogo.percentualDesconto.toLowerCase()" [id]="'descontoValorUnitario'" [campo]="movimentacaoProduto.descontoValorUnitario" (alteracao)="setDescontoValorUnitario(movimentacaoProduto, $event)" [focoId]="linha == i && coluna == 3 ? 1 : null" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.importacaoXml == 'S' || movimentacaoProduto.valorUnitarioTabela == 0" (navegacao)="$event == true ? atualizarLinhaColuna(i, 3) : (focadoCampoMovimentacaoProduto = false)" [icone]="movimentacaoControlador.bibIcone.sifrao" (btnAdicional)="mudarTipoDesconto(movimentacaoProduto)" [compactoBotao]="true"></decimal>
              </td>              
              <td>
                <decimal *ngIf="movimentacaoControlador.saida == false || movimentacaoProduto.apresentaTabelaPreco == false" [campo]="movimentacaoProduto.getValorUnitario()" (alteracao)="setValorUnitario(movimentacaoProduto, $event)" (navegacao)="validarPrecoMenor(movimentacaoProduto, $event)" [id]="bibDialogo.valor + i" [obrigatorio]="true" [valorMinimo]="0" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.idTabelaPrecoConfiguracao ? true : false" [numeroCasasDecimais]="this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais" [focoId]="linha == i && coluna == 2 ? 1 : null" (navegacao)="$event == true ? atualizarLinhaColuna(i, 2) : (focadoCampoMovimentacaoProduto = false)" [focoId]="focoIdVincular"></decimal>
                <decimal *ngIf="movimentacaoControlador.saida == true && movimentacaoProduto.apresentaTabelaPreco == true" [campo]="movimentacaoProduto.getValorUnitario()" (alteracao)="setValorUnitario(movimentacaoProduto, $event)" (navegacao)="validarPrecoMenor(movimentacaoProduto, $event)" [id]="bibDialogo.valor + i" [obrigatorio]="true" [valorMinimo]="0" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.idTabelaPrecoConfiguracao ? true : false" [numeroCasasDecimais]="this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais" [focoId]="linha == i && coluna == 2 ? 1 : null" (navegacao)="$event == true ? atualizarLinhaColuna(i, 2) : (focadoCampoMovimentacaoProduto = false)" [icone]="utilSessao.sistema == 'aGrow-w' && movimentacaoControlador.saida ? movimentacaoControlador.bibIcone.prancheta : null" (btnAdicional)="abrirModalTabelaPreco(movimentacaoProduto, i)" [focoId]="focoIdVincular" [iconeDesabilitado]="movimentacaoControlador.bloqueado" [compactoBotao]="true"></decimal>
              </td>
              <td *ngIf="movimentacaoControlador.usaDescontoUnitario == false"> 
                <decimal *ngIf="!movimentacaoProduto.descontoPorValor" [ajuda]="bibDialogo.mudarPara + ' ' + bibDialogo.valorDesconto.toLowerCase()" [id]="'descontoPercentual'" [campo]="movimentacaoProduto.getDescontoPercentual()" (alteracao)="setDescontoPercentual(movimentacaoProduto, $event)" [focoId]="linha == i && coluna == 3 ? 1 : null" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.importacaoXml == 'S'" [valorMaximo]="100" (navegacao)="$event == true ? atualizarLinhaColuna(i, 3) : (focadoCampoMovimentacaoProduto = false)" [icone]="utilSessao.sistema == 'aGrow-w' ? movimentacaoControlador.bibIcone.percentual : null" (btnAdicional)="mudarTipoDesconto(movimentacaoProduto)" [compactoBotao]="true" [sufixo]="movimentacaoControlador.bloqueado || movimentacaoProduto.importacaoXml == 'S' ? '%' : ''"></decimal>
                <decimal *ngIf="movimentacaoProduto.descontoPorValor" [ajuda]="bibDialogo.mudarPara + ' ' + bibDialogo.percentualDesconto.toLowerCase()" [id]="'descontoValor'" [campo]="movimentacaoProduto.descontoValor" (alteracao)="setDescontoValor(movimentacaoProduto, $event)" [focoId]="linha == i && coluna == 3 ? 1 : null" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.importacaoXml == 'S'" (navegacao)="$event == true ? atualizarLinhaColuna(i, 3) : (focadoCampoMovimentacaoProduto = false)" [icone]="utilSessao.sistema == 'aGrow-w' ? movimentacaoControlador.bibIcone.sifrao : null" (btnAdicional)="mudarTipoDesconto(movimentacaoProduto)" [compactoBotao]="true"></decimal>
              </td>
              <td>
                <decimal [campo]="movimentacaoProduto.valorTotal" [id]="bibDialogo.valorTotal + i" [desabilitado]="true" [ajuda]="movimentacaoControlador.saida && movimentacaoProduto.descontoValor > 0 ? bibDialogo.valorUnitarioReal + ': ' + (movimentacaoProduto.valorTotal / movimentacaoProduto.getQuantidade() | monetario: movimentacaoControlador.empresaRegraCasasDecimais) : ''"></decimal>
              </td>
            </ng-container>
            <td *ngIf="!movimentacaoControlador.bloqueado && !movimentacaoControlador.transferenciaEntrada">
              <btnAdicional [icone]="movimentacaoControlador.bibIcone.lixo" [desabilitado]="movimentacaoControlador.bloqueado" (btnAdicional)="excluirProduto(i, movimentacaoProduto.id)"></btnAdicional>
            </td>
            <td>
              <btnAdicional [id]="'impostos' + i" *ngIf="(movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro || (this.movimentacaoControlador.identificacao == 4 && this.movimentacaoControlador.entrada)) && (movimentacaoProduto.produtoTipo == 'P' ||  (movimentacaoProduto.produtoTipo == 'S' && this.movimentacaoControlador.notaTerceiro)) " [id]="bibDialogo.impostos" [icone]="movimentacaoControlador.bibIcone.imposto" [iconeCor]="movimentacaoProduto.impostosPreenchidos ? this.movimentacaoControlador.bibPropriedade.icone.verde : this.movimentacaoControlador.identificacao == 4 && this.movimentacaoControlador.entrada ? this.movimentacaoControlador.bibPropriedade.icone.preto : this.movimentacaoControlador.bibPropriedade.icone.vermelho" [ajuda]="bibDialogo.impostos" (btnAdicional)="abrirModalImposto(movimentacaoProduto, i)"></btnAdicional>
            </td>
            <td *ngIf="!movimentacaoControlador.entradaServico && movimentacaoControlador.notaTerceiro && movimentacaoProduto.margemLucro != null && movimentacaoProduto.produtoTipo != 'S'">
              <icone class="d-flex align-items-center justify-content-end" *ngIf="movimentacaoProduto.margemLucro == 0" [icone]="bibIcone.infoCirculo" [cor]="bibPropriedade.icone.vermelho" [mensagem]="bibDialogo.definirMargemLucro"></icone>
            </td>
            <td *ngIf="!movimentacaoControlador.entradaServico && movimentacaoControlador.balanco && (movimentacaoProduto.quantidadeBalanco || movimentacaoProduto.quantidadeBalanco == 0)">
              <icone *ngIf="movimentacaoProduto.estoqueFisico != 0" [icone]="bibIcone.circulo" [cor]="movimentacaoProduto.estoqueFisico == -1 ? bibPropriedade.icone.vermelho : bibPropriedade.icone.verde" [mensagem]="bibDialogo.estoque + '(' + bibDialogo.disponivel.toLowerCase() + ' + ' + bibDialogo.pendente.toLowerCase() + ')' + ': ' + (movimentacaoProduto.getQuantidade() * movimentacaoProduto.estoqueFisico * -1 + movimentacaoProduto.quantidadeBalanco | monetario: this.empresaRegraCasasDecimais) + ' ' + movimentacaoProduto.abreviacao + ' | ' + (movimentacaoProduto.estoqueFisico == 1 ? bibDialogo.entrada : bibDialogo.saida) + ': ' + (movimentacaoProduto.getQuantidade() | monetario: this.empresaRegraCasasDecimais) + ' ' + movimentacaoProduto.abreviacao + ' no ' + bibDialogo.fisico.toLowerCase()"></icone>
            </td>
            <td *ngIf="!movimentacaoControlador.entradaServico && movimentacaoControlador.movimentacaoManual">
              <icone *ngIf="movimentacaoProduto.estoqueFisico != 0" [icone]="bibIcone.circulo" [cor]="movimentacaoProduto.estoqueFisico == -1 ? bibPropriedade.icone.vermelho : bibPropriedade.icone.verde"></icone>
            </td>
          </tr>         
          <tr *ngIf="movimentacaoProduto.produtoTipo == 'S'">
            <td colspan="1"></td>
            <td colspan="3" class="row tamanho-maximo">
              <span class="col-sm p-0 limitar">
                <atencao [atencao]="bibDialogo.servico"></atencao>
              </span>
            </td>
          </tr>
          <tr *ngIf="(movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) && movimentacaoControlador.entrada && !movimentacaoControlador.movimentacao.id && movimentacaoProduto.numeroProdutoNfe">
            <td colspan="1"></td>
            <td colspan="3" class="row tamanho-maximo">
              <span class="col-sm limitar">
                <atencao [tipo]="movimentacaoProduto.produtoNumero ? bibPropriedade.atencao.sucesso : bibPropriedade.atencao.cuidado" [atencao]="movimentacaoProduto.numeroProdutoNfe + ' - ' + movimentacaoProduto.produtoNfe"></atencao>
                <atencao [atencao]="bibDialogo.ncm + ': ' + movimentacaoProduto.ncmProdutoXml"></atencao>
              </span>
              <btnAdicional class="ml-1" *ngIf="!movimentacaoControlador.bloqueado && movimentacaoProduto.produtoNumero" [icone]="bibIcone.lixo" (btnAdicional)="validarProdutos(null, movimentacaoProduto)"></btnAdicional>
            </td>
          </tr>
          <tr *ngIf="movimentacaoProduto.expandido">
            <td colspan="10">
              <movimentacaoQuantidade [movimentacaoProduto]="movimentacaoProduto" [indice]="i" [movimentacaoControlador]="movimentacaoControlador" [lojaLimitacoes]="lojaLimitacoes" [locais]="movimentacaoProduto.locais" [lotes]="movimentacaoProduto.lotes" [estoques]="movimentacaoProduto.estoques ? movimentacaoProduto.estoques : null"></movimentacaoQuantidade>
            </td>
          </tr>              
          <tr *ngIf="movimentacaoProduto.expandidoCultura">
            <td colspan="3">
              <lista [id]="'cultura'" *ngIf="movimentacaoProduto.culturas.length > 0" [rotulo]="bibDialogo.cultura" [campo]="movimentacaoProduto.idCultura" [lista]="movimentacaoProduto.culturas" [desabilitado]="movimentacaoControlador.bloqueado" (alteracao)="setCultura(movimentacaoProduto, $event)"></lista>
            </td>
            <td colspan="3">
              <lista [id]="'praga'" [rotulo]="bibDialogo.praga" [campo]="movimentacaoProduto.idPraga" [lista]="movimentacaoProduto.pragas" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoProduto.pragas.length == 0" (alteracao)="setPraga(movimentacaoProduto, $event)"></lista>
            </td>  
            <td colspan="3">
              <decimal [id]="'numeroAplicacao'" [rotulo]="bibDialogo.numeroAplicacao" [campo]="movimentacaoProduto.numeroAplicacao" (alteracao)="movimentacaoProduto.numeroAplicacao = $event" [desabilitado]="movimentacaoControlador.bloqueado"></decimal>          
            </td>            
          </tr>   
          <tr *ngIf="movimentacaoProduto.expandidoCultura">
            <td colspan="3">
              <lista class="col-sm" [rotulo]="bibDialogo.aplicacao" [campo]="movimentacaoProduto.idAplicacao" (alteracao)="setAplicacao(movimentacaoProduto, $event)" [lista]="aplicacao" [desabilitado]="movimentacaoControlador.bloqueado"> </lista>
            </td>
            <td colspan="3">
              <lista class="col-sm" [rotulo]="bibDialogo.equipamento" [campo]="movimentacaoProduto.idEquipamento" (alteracao)="movimentacaoProduto.idEquipamento = $event.id" [lista]="equipamentos" [icone]="bibIcone.atualizar" [desabilitado]="movimentacaoControlador.bloqueado" (btnAdicional)="listarEquipamentos()"></lista>
            </td>  
            <td colspan="3">
              <texto class="col-sm" [rotulo]="bibDialogo.estagioCultura" [campo]="movimentacaoProduto.estagioCultura" (alteracao)="movimentacaoProduto.estagioCultura = $event" [desabilitado]="movimentacaoControlador.bloqueado" [maximoCaracteres]="100"></texto>
            </td>            
          </tr>                  
        </ng-container>     
      </ng-container>
         
    </tbody>
  </table>
  <!-- <div class="row" *ngIf="movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0 && !movimentacaoControlador.transferencia && !movimentacaoControlador.interna">
    <decimal class="col" [rotulo]="movimentacaoControlador.desconto" [campo]="movimentacaoControlador.movimentacao.descontoPercentual" (alteracao)="movimentacaoControlador.setDescontoPercentualCabecalho($event)" [desabilitado]="movimentacaoControlador.bloqueado" [sufixo]="'%'" [valorMaximo]="100"></decimal>
    <decimal class="col" [rotulo]="movimentacaoControlador.desconto + ' ' + movimentacaoControlador.bibDialogo.valor.toLowerCase()" [campo]="movimentacaoControlador.movimentacao.descontoValor" (alteracao)="movimentacaoControlador.setDescontoValorCabecalho($event)" [desabilitado]="movimentacaoControlador.bloqueado"></decimal>
    <decimal class="col" [rotulo]="bibDialogo.valorTotal" [campo]="movimentacaoControlador.movimentacao.valorTotal" (alteracao)="alterarTotal($event)" [desabilitado]="movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao"></decimal>
  </div> -->
  <div class="row" *ngIf="movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0 && !movimentacaoControlador.transferencia && !movimentacaoControlador.interna">
    <decimal class="col" [rotulo]="movimentacaoControlador.desconto" [campo]="descontoPercentualAcumulaDesconto" (alteracao)="setDescontoPercentualAcumulaDesconto($event)" [desabilitado]="movimentacaoControlador.bloqueado" [sufixo]="'%'" [valorMaximo]="100"></decimal>
    <decimal class="col" [rotulo]="movimentacaoControlador.desconto + ' ' + movimentacaoControlador.bibDialogo.valor.toLowerCase()" [campo]="descontoValorAcumulaDesconto" (alteracao)="setDescontoValorAcumulaDesconto($event)" [desabilitado]="movimentacaoControlador.bloqueado"></decimal>
    <decimal *ngIf="movimentacaoControlador.movimentacao.idOperacao != 7 && movimentacaoControlador.movimentacao.idOperacao != 8" class="col justify-content-end" [rotulo]="bibDialogo.valorTotal" [campo]="movimentacaoControlador.movimentacao.valorTotal" (alteracao)="alterarTotal($event, true)" [desabilitado]="(!movimentacaoControlador.orcamento && !movimentacaoControlador.pedido && movimentacaoControlador.saida) || (movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao) || movimentacaoControlador.bloqueado"></decimal>
  </div>
  <div class="pt-2">
    <paginacao [paginacao]="paginacaoProduto" [utilizaPaginacaoFrm]="true" [numeroRegistro]="movimentacaoControlador.movimentacao.movimentacaoProdutos.length" (alteracao)="setPosicaoPagina($event)" (paginacaoFrmEmt)="mostrarTodosProdutos()"></paginacao>
  </div>
  <div *ngIf="(movimentacaoControlador.balanco || movimentacaoControlador.movimentacaoManual) && focoIdSenha == null">
    <totalizador [alinhamento]="bibPropriedade.totalizador.esquerda" [atencaoTotalizador]="movimentacaoControlador.bibDialogo.entrada" [valorTotalizador]="movimentacaoControlador.movimentacao.valorEntradaBalanco | monetario" [tipoTotalizador]="bibPropriedade.cor.verde"></totalizador>
    <totalizador [alinhamento]="bibPropriedade.totalizador.centro" [atencaoTotalizador]="movimentacaoControlador.bibDialogo.saida" [valorTotalizador]="movimentacaoControlador.movimentacao.valorSaidaBalanco | monetario" [tipoTotalizador]="bibPropriedade.cor.vermelho"></totalizador>
    <totalizador [alinhamento]="bibPropriedade.totalizador.direita" [atencaoTotalizador]="movimentacaoControlador.bibDialogo.diferenca" [valorTotalizador]="movimentacaoControlador.movimentacao.valorDiferencaBalanco | monetario" [tipoTotalizador]="bibPropriedade.cor.cinzaEscuro"></totalizador>
  </div>
  <modal *ngIf="componente" [componente]="componente" (modalEmt)="ehFecharModal()"></modal>
</div>
