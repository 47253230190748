<layout [titulo]="polegada.nome" [id]="polegada.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="polegada.nome" (alteracao)="polegada.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="polegada.ativo" (alteracao)="polegada.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm-4" [id]="'ordem'" [rotulo]="bibDialogo.ordem" [tipo]="bibPropriedade.texto.inteiro" [campo]="polegada.ordem" (alteracao)="polegada.ordem = $event" [obrigatorio]="true" [ajuda]="bibDialogo.polegadaAjuda"></texto>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirPolegada()" [desabilitado]="bloquearPermissao(polegada.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirPolegada(true)" [desabilitado]="bloquearPermissao(polegada.id)"></botao>
<auditoria *ngIf="polegada.id" [idUsuarioInclusao]="polegada.idUsuarioInclusao" [dataHoraInclusao]="polegada.dataHoraInclusao" [idUsuarioAlteracao]="polegada.idUsuarioAlteracao" [dataHoraAlteracao]="polegada.dataHoraAlteracao"></auditoria>
