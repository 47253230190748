<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)">
  <ng-container *ngFor="let conferencia of apresentaConferencias; let i = index" class="modelo-a5">
    <div class="container pt-3">
      <div class="row relatorio-titulo">{{ (conferencia.tipo == 'R' ? bibDialogo.previsaoPagamento : bibDialogo.comprovanteDeDevolucao) + ': ' + conferencia.numero }}</div>
      <div class="row text-justify">
        {{ conferencia.parceiroNumero + ' - ' + this.util.substituir(bibDialogo.inscricaoCpfCnpj, [conferencia.parceiro, conferencia.tipoPessoa == 'F' ? bibDialogo.cpf : bibDialogo.cnpj, conferencia.parceiroCnpjCpf ? conferencia.parceiroCnpjCpf : '']) }}
        {{ (conferencia.tipo == 'R' ? bibDialogo.declaraPrevisaoPagamento : bibDialogo.declaraDevolucaoPagamento) + ' ' + (conferencia.valorTotal | monetario) + ' ' + this.util.escreverValor(conferencia.valorTotal) }}
        {{ conferencia.conferenciaFinanceiros.length > 1 ? bibDialogo.dosFinanceiroN : bibDialogo.doFinanceiroN }}<ng-container *ngFor="let conferenciaFinanceiro of conferencia.conferenciaFinanceiros; let i = index"> {{ conferencia.conferenciaFinanceiros.length == i + 1 ? conferenciaFinanceiro.numero + '.' : conferenciaFinanceiro.numero + ', ' }} </ng-container> <br />
        {{ bibDialogo.porSerVerdadeFirmamosOPresente }}
      </div>
    </div>
    <table>
      <tbody>
        <tr class="border-bottom border-dark" style="border-top: none;">
          <td>
            <table>
              <tr>
                <th class="r">{{ rotuloTipoDeTitulo }}</th>
                <th class="r-5 text-right">{{ bibDialogo.numeroDocumento }}</th>
                <th class="r-2 text-right">{{ bibDialogo.valor }}</th>
              </tr>
              <tbody>
                <tr class="sem-borda" *ngFor="let conferenciaTipoTitulo of conferencia.conferenciaTitulos">
                  <td>{{ conferenciaTipoTitulo.tipoTitulo }}</td>
                  <td class="text-right">{{ conferenciaTipoTitulo.numeroDocumento }}</td>
                  <td class="text-right">{{ conferenciaTipoTitulo.valor | monetario }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="sem-borda">
          <div class="row mt-2 justify-content-center">{{ conferencia.cidade ? conferencia.cidade + ', ' : '' }} {{ conferencia.data | data: "dd 'de' MMMM 'de' yyyy" }}</div>
          <div class="row justify-content-center">
            <div class="r-12 assinatura text-center">{{ conferencia.loja }}</div>
          </div>
        </tr>
      </tbody>
      <tfoot *ngIf="usaDuplicarRecibo && i + 1 != apresentaConferencias.length">
        <tr class="borda-separador-recibo">
          <td class="separador-recibo"></td>
        </tr>
      </tfoot>
      <div *ngIf="i + 1 != apresentaConferencias.length" class="quebra-pagina"></div>
    </table>
  </ng-container>
</relatorioLayout>
