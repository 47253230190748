<form>
  <div class="row">
    <texto class="col-sm-8" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="_produtoFormulado.nome" (alteracao)="_produtoFormulado.nome = $event" [obrigatorio]="true" [foco]="true" [desabilitado]="_produtoFormulado.desabilitado"></texto>
    <texto class="col-sm-3" [id]="'registro'" [rotulo]="bibDialogo.registro" [campo]="_produtoFormulado.registro" (alteracao)="_produtoFormulado.registro = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="_produtoFormulado.ativo" (alteracao)="_produtoFormulado.ativo = $event" [desabilitado]="!temPermissaoInativar || _produtoFormulado.desabilitado"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'nomeTecnico'" [rotulo]="bibDialogo.nomeTecnico" [campo]="_produtoFormulado.nomeTecnico" (alteracao)="_produtoFormulado.nomeTecnico = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
    <texto class="col-sm" [id]="'nomeEmbarque'" [rotulo]="bibDialogo.nomeEmbarque" [campo]="_produtoFormulado.nomeEmbarque" (alteracao)="_produtoFormulado.nomeEmbarque = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'marcaFabricante'" [rotulo]="bibDialogo.marcaFabricante" [campo]="_produtoFormulado.empresa" (alteracao)="_produtoFormulado.empresa = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
    <texto class="col-sm-6" [id]="'formulacao'" [rotulo]="bibDialogo.formulacao" [campo]="_produtoFormulado.formulacao" (alteracao)="_produtoFormulado.formulacao = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>    
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'ingredienteAtivo'" [rotulo]="bibDialogo.ingredienteAtivo" [campo]="_produtoFormulado.ingredienteAtivo" (alteracao)="_produtoFormulado.ingredienteAtivo = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
    <texto class="col-sm" [id]="'classeAmbiental'" [rotulo]="bibDialogo.classeAmbiental" [campo]="_produtoFormulado.classeAmbiental" (alteracao)="_produtoFormulado.classeAmbiental = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
  </div>
  <div class="row">
    <texto class="col-sm-6" [id]="'descricaoClasse'" [rotulo]="bibDialogo.descricao + ' ' + bibDialogo.classe.toLowerCase()" [campo]="_produtoFormulado.descricaoClasse" (alteracao)="_produtoFormulado.descricaoClasse = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>        
    <texto class="col-sm-6" [id]="'grupoEmbalagem'" [rotulo]="bibDialogo.grupoEmbalagem" [campo]="_produtoFormulado.grupoEmbalagem" (alteracao)="_produtoFormulado.grupoEmbalagem = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>    
  </div>
  <div class="row">
    <texto class="col-sm-6" [id]="'numeroRisco'" [rotulo]="bibDialogo.numeroRisco" [campo]="_produtoFormulado.numeroRisco" (alteracao)="_produtoFormulado.numeroRisco = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>
    <texto class="col-sm-6" [id]="'numeroOnu'" [rotulo]="bibDialogo.numeroOnu" [campo]="_produtoFormulado.numeroOnu" (alteracao)="_produtoFormulado.numeroOnu = $event" [desabilitado]="_produtoFormulado.desabilitado"></texto>    
  </div>  
  <div class="row">
    <texto class="col-sm-5" [id]="'classeToxicologica'" [rotulo]="bibDialogo.classeToxicologica" [campo]="_produtoFormulado.classeToxicologica" (alteracao)="setClasseToxicologica($event)" [desabilitado]="_produtoFormulado.desabilitado" [obrigatorio]="true"></texto>
    <icone class="col-sm-1 col-sm d-flex align-items-center" [ngClass]="compacto ? '' : 'pt-4'" [icone]="bibIcone.quadrado" [cor]="_produtoFormulado.corIcone"></icone>
    <atencao *ngIf="!_produtoFormulado.desabilitado" class="col-sm-6 col-sm d-flex align-items-center justify-content-end" [ngClass]="compacto ? '' : 'pt-4'" [atencao]="bibDialogo.preenchimentoAutomaticoClasseToxicologica"></atencao>
  </div>
  <div *ngIf="!_produtoFormulado.desabilitado" class="row justify-content-end">
    <botao [id]="'extremamente'" [compacto]="compacto" [legenda]="bibDialogo.extramente" [desabilitado]="false" [tipo]="bibPropriedade.botao.cuidado" (botaoEmt)="setEhIconeClasseToxicologica(bibDialogo.extramente)"></botao>
    <botao [id]="'altamente'" [compacto]="compacto" [legenda]="bibDialogo.altamente" [desabilitado]="false" [tipo]="bibPropriedade.botao.cuidado" (botaoEmt)="setEhIconeClasseToxicologica(bibDialogo.altamente)"></botao>
    <botao [id]="'moderadamente'" [compacto]="compacto" [legenda]="bibDialogo.moderadamente" [desabilitado]="false" [tipo]="bibPropriedade.botao.atencao" (botaoEmt)="setEhIconeClasseToxicologica(bibDialogo.moderadamente)"></botao>
    <botao [id]="'pouco'" [compacto]="compacto" [legenda]="bibDialogo.pouco" [desabilitado]="false" (botaoEmt)="setEhIconeClasseToxicologica(bibDialogo.pouco)"></botao>
    <botao [id]="'improvavel'" [compacto]="compacto" [legenda]="bibDialogo.improvavel" [desabilitado]="false" (botaoEmt)="setEhIconeClasseToxicologica(bibDialogo.improvavel)"></botao>
    <botao [id]="'naoClassificado'" [compacto]="compacto" [legenda]="bibDialogo.naoClassificado" [desabilitado]="false" [tipo]="bibPropriedade.botao.sucesso" (botaoEmt)="setEhIconeClasseToxicologica(bibDialogo.naoClassificado)"></botao>
  </div>
</form>
