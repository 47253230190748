import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Cheque } from 'src/app/modelo/cheque';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { Resultado } from 'src/app/modelo/resultado';
import { Criterio } from 'src/app/modelo/criterio';

@Component({
  selector: 'chequeFrm',
  templateUrl: './chequeFrm.component.html',
})
export class ChequeFrmComponent extends PaginaComponent {
  public cheque: Cheque = new Cheque();
  public tipoTitulos: TipoTitulo[] = [];
  public tipos: any = [
    { id: 'R', nome: this.bibDialogo.receita },
    { id: 'D', nome: this.bibDialogo.despesa },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.cheque = this.plainToClass(Cheque, this.ehAtualizacao(this.cheque));
    if (this.cheque.id) {
      this.listarCheque(this.cheque.id);
    }
  }

  listarCheque(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.cheque).subscribe((res) => {
      this.cheque = this.plainToClass(Cheque, res[0]) as any;
    });
  }

  setIdContaInicial(cheque: Cheque, id: number): void {
    cheque.idContaInicial = id;
    cheque.idContaAtual = id;
  }

  persistirCheque(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.cheque), this.bibServico.cheque, novo ? new Cheque() : null);
    }
  }

  ehValido(): boolean {
    if (this.cheque.idLoja && this.cheque.idParceiro && this.cheque.idContaInicial && this.cheque.idContaAtual && this.cheque.numero && this.cheque.valor && this.cheque.data) {
      return true;
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
  }
}
