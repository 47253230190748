<ng-container class="border-bottom border-dark">
  <td *ngIf="detalhes.length > 0" class="p-0" [colSpan]="5">
    <table class="tabela-complementar">
      <thead>
        <th class="r-1">{{ bibDialogo.campo }}</th>
        <th class="r-2">{{ bibDialogo.antes }}</th>
        <th class="r-2">{{ bibDialogo.depois }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let detalhe of detalhes">
          <tr class="conteudo">
            <td>{{ detalhe.campo }}</td>
            <td>{{ detalhe.antes }}</td>
            <td>{{ detalhe.depois }}</td>
          </tr>
        </ng-container>        
      </tbody>
    </table>
  </td>

  <td *ngIf="detalhes.length == 0" class="texto-negrito" [colSpan]="5">
      {{ bibDialogo.semdetalheLog.toLowerCase() }}
  </td> 
</ng-container>
