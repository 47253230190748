<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.conciliacaoLancamentoContabil }}</h1>
<table>
  <thead>
    <th class="r-2">{{ bibDialogo.chave }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.dataLancamento }}</th>
    <th class="r-1">{{ bibDialogo.dataConciliacao }}</th>
    <th class="r-1">{{ bibDialogo.tipo }}</th>
    <th class="r-1">{{ bibDialogo.situacao }}</th>
    <th class="r-1">{{ bibDialogo.valor }}</th>
    <th class="r-1">{{ bibDialogo.valorPago }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let conciliacaoLancamentoContabil of conciliacaoLancamentoContabeis">
      <tr class="conteudo">
        <td>{{ conciliacaoLancamentoContabil.id }}</td>
        <td>{{ conciliacaoLancamentoContabil.idLoja + ' - ' + conciliacaoLancamentoContabil.loja }}</td>
        <td>{{ conciliacaoLancamentoContabil.idEmpresa + ' - ' + conciliacaoLancamentoContabil.empresa }}</td>
        <td>{{ conciliacaoLancamentoContabil.dataLancamento | data }}</td>
        <td>{{ conciliacaoLancamentoContabil.dataConciliacao | data }}</td>
        <td>{{ conciliacaoLancamentoContabil.tipo }}</td>
        <td>{{ conciliacaoLancamentoContabil.situacao }}</td>
        <td>{{ conciliacaoLancamentoContabil.valorEsperado | monetario }}</td>
        <td>{{ conciliacaoLancamentoContabil.valorEncontrado | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
