import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015ValorBaixaFinanceiroFinanceiroBaixa',
  templateUrl: './r2015ValorBaixaFinanceiroFinanceiroBaixa.component.html',
})
export class R2015ValorBaixaFinanceiroFinanceiroBaixa {
  @Input() valorBaixaFinanceiroFinanceiroBaixas: any[] = [];
  public bibDialogo = bibDialogo;
}
