import { BaseCalculo } from './baseCalculo';

export class Pis {
  public aliquota: number;
  public baseCalculo: BaseCalculo;
  public cst: string;
  public valor: number;

  constructor(cst: string) {
    this.cst = cst;
  }
}
