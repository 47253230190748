import { Type } from 'class-transformer';

export class Polegada {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public ordem: number = 0;
  public ativo: string = 'S';
}
