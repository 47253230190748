export class EnderEmit {
  public xLgr: string;
  public nro: string;
  public xCpl: string;
  public xBairro: string;
  public cMun: string;
  public xMun: string;
  public UF: string;
  public CEP: string;
  public cPais: string = '1058';
  public xPais: string = 'BRASIL';
  public fone: string;
}