import { Type } from 'class-transformer';

export class ParceiroHistorico {
  @Type(() => Date)
  public dataUltimaNegociacao: Date;

  public atrasoMaximo: number;
  public despesasAbertas: number;
  public id: number;
  public idParceiro: number;
  public lucroAno: number;
  public mediaAtraso: number;
  public receitaAno: number;
  public receitaAtrasada: number;
  public receitaVencer: number;
}
