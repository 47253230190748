export class ProdutoIcmsSt {
  public aliquota: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public estado: string;
  public excluido: boolean = false;
  public id: number;
  public idEmpresa: number;
  public idEstado: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public mva: number;
  public produto: string;
}
