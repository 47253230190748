import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';

@Component({
  selector: 'r2001Devolucao',
  templateUrl: './r2001Devolucao.component.html',
})
export class R2001DevolucaoComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() devolucoes: any[];
  public bibDialogo = bibDialogo;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
}
