import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.numero, campo: 'numero' },
  { rotulo: bibDialogo.data, campo: 'data' },
  { rotulo: bibDialogo.dataPreDatada, campo: 'dataPreDatada' },
  { rotulo: bibDialogo.dataFinalizado, campo: 'dataFinalizado' },
  { rotulo: bibDialogo.parceiro, campo: 'parceiro' },
  { rotulo: bibDialogo.contaAtual, campo: 'conta' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
];
export default ordens;
