import { EnderDest } from "./enderDest";

export class Dest {
    public CNPJ: string;
    public CPF: string;
    public xNome: string;
    public enderDest: EnderDest;
    public indIEDest: string = '1';
    public IE: string;
    public email: string;
}