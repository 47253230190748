import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import { Utilitario } from 'src/app/modelo/utilitario';
import { PaginaComponent } from '../../utilitario/pagina.component';

@Component({
  selector: 'imagem',
  templateUrl: './imagem.component.html',
})
export class ImagemComponent extends PaginaComponent {
  public imagemBase64: string;
  public utilitarioFechar = new Utilitario(bibIcone.sair, bibDialogo.fechar + ' ' + bibDialogo.f9);

  ngOnInit(): void {
    this.imagemBase64 = this.utilSessao.getIdentificacao('imagemBase64')?.conteudo;
  }
}
