<ng-container>
  <td class="p-0" colSpan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-3">{{ bibDialogo.ipi + ' - ' + bibDialogo.cstEntrada }}</th>
          <th class="r-3">{{ bibDialogo.ipi + ' - ' + bibDialogo.aliquotaEntrada }}</th>
          <th class="r-3">{{ bibDialogo.ipi + ' - ' + bibDialogo.cstSaida }}</th>
          <th class="r-3">{{ bibDialogo.ipi + ' - ' + bibDialogo.aliquotaSaida }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ produto.ipiEntrada }}</td>
          <td>{{ produto.ipiAliquotaEntrada }}</td>
          <td>{{ produto.ipiSaida }}</td>
          <td>{{ produto.ipiAliquotaSaida }}</td>
        </tr>
      </tbody>
    </table>
  </td>
</ng-container>
