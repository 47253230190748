import { Component, HostListener, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Mdfe } from 'src/app/modelo/mdfe';
import { MdfeDocumentoFiscal } from 'src/app/modelo/mdfeDocumentoFiscal';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'mdfeDocumentoFiscal',
  templateUrl: './mdfeDocumentoFiscalFrm.component.html',
})
export class MdfeDocumentoFiscalFrmComponent extends PaginaComponent {
  @Input() mdfe: Mdfe;
  @Input() excluirDocumentosFiscais: number[] = [];
  @Input() bloqueado: boolean = false;

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public componente: any;
  public ehMinhaModal: boolean = false;

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent): void {
    if (event.keyCode == this.bibAtalho.novo) {
      this.ehAbrirModalDocumentoFiscal();
    }
  }

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const nfes: any[] = identificacao.conteudo;
        nfes.forEach((nfe) => {
          if (this.mdfe.mdfeDocumentoFiscais.filter((mdfeDocumentoFiscal) => mdfeDocumentoFiscal.idMovimentacao == nfe.id).length == 0) {
            let mdfeDocumentoFiscal: MdfeDocumentoFiscal = new MdfeDocumentoFiscal();
            mdfeDocumentoFiscal.idMovimentacao = nfe.id;
            mdfeDocumentoFiscal.parceiroNomeFantasia = nfe.parceiro;
            mdfeDocumentoFiscal.numeroMovimentacao = nfe.numero;
            mdfeDocumentoFiscal.dataMovimentacao = nfe.data;
            mdfeDocumentoFiscal.chaveFiscal = nfe.chaveFiscal;
            mdfeDocumentoFiscal.valorTotalMovimentacao = nfe.valorTotal;
            this.mdfe.mdfeDocumentoFiscais.push(mdfeDocumentoFiscal);
          }
        });
      }
    });
  }

  abrirModalMovimentacao(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }

  excluirDocumentoFiscal(posicao: number, id: number): void {
    super.ehExcluir(posicao, this.mdfe.mdfeDocumentoFiscais, this.excluirDocumentosFiscais, id);
  }

  ehAbrirModalDocumentoFiscal() {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('MDFE', this.mdfe.buscarTodosDocumentosFiscais));
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.movimentacao));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.mdfe.mdfeDocumentoFiscais));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.documentoFiscal));
    this.utilSessao.setIdentificacao(new Identificacao('criterios', criterios));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }
}
