import { Type } from 'class-transformer';

export class TransferenciaCheque {
  @Type(() => Date)
  public chequeData: Date;
  @Type(() => Date)
  public chequeDataPreDatada;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public chequeAbreviacao: string;
  public chequeIdLoja: number;
  public chequeNumero: string;
  public chequeParceiro: string;
  public chequeValor: number;
  public excluido: boolean = false;
  public id: number;
  public idCheque: number;
  public idEmpresa: number;
  public idTransferencia: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public valor: number;
}
