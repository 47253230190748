<layout [titulo]="cultura.nome" [id]="cultura.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <busca class="col-sm" [rotulo]="bibDialogo.nome" [campo]="cultura.nome" (alteracao)="setNome($event)" [servico]="bibServico.culturaCompendio" [obrigatorio]="true" [foco]="true"></busca>
  </div>
  <div *ngIf="integracaoEidaf" class="row">
    <texto class="col-sm-4" [id]="'integracao'" [rotulo]="bibDialogo.integracao" [campo]="cultura.eidaf" (alteracao)="cultura.eidaf = $event" [ajuda]="bibDialogo.ajudaIntegracaoEidaf" [maximoCaracteres]="20" [ajudaDireita]="true"></texto>
  </div>  
  <div *ngIf="integracaoAgroDefesa" class="row">
    <texto class="col-sm-4" [id]="'integracao'" [rotulo]="bibDialogo.integracao" [campo]="cultura.agroDefesa" (alteracao)="cultura.agroDefesa = $event" [ajuda]="bibDialogo.ajudaIntegracaoAgroDefesa" [maximoCaracteres]="20" [ajudaDireita]="true"></texto>
  </div>    
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCultura()" [desabilitado]="bloquearPermissao(cultura.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirCultura(true)" [desabilitado]="bloquearPermissao(cultura.id)"></botao>
<auditoria *ngIf="cultura.id" [idUsuarioInclusao]="cultura.idUsuarioInclusao" [dataHoraInclusao]="cultura.dataHoraInclusao" [idUsuarioAlteracao]="cultura.idUsuarioAlteracao" [dataHoraAlteracao]="cultura.dataHoraAlteracao"></auditoria>
