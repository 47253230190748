import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Cfop } from 'src/app/modelo/cfop';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { CfopEmpresaConfiguracaoMovimentacaoContabil } from 'src/app/modelo/cfopEmpresaConfiguracaoMovimentacaoContabil';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { ConfiguracaoMovimentacaoContabil } from 'src/app/modelo/configuracaoMovimentacaoContabil';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Loja } from 'src/app/modelo/loja';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './cfopEmpresaFrm.component.html',
})
export class CfopEmpresaFrmComponent extends PaginaComponent {
  public cfopContrapartidas: Cfop[] = [];
  public cfopEmpresa: CfopEmpresa = new CfopEmpresa();
  public cfops: Cfop[] = [];
  public configuracaoMovimentacaoContabeis: ConfiguracaoMovimentacaoContabil[] = [];
  public listaLojas: Loja[] = [];
  public criteriosEntradaSaida: Criterio[] = [];
  public criteriosDevolucaoEntradaSaida: Criterio[] = [];
  public entradaSaida: string;
  public classificacaoFiscais: ClassificacaoFiscal[] = [];
  public cstsPisCofins: Cst[] = [];
  public cstIpis: Cst[] = [];
  public edicaoCfopEmpresaConfiguracaoMovimentacaoContabeis: number[] = [];
  public focoLoja: number;
  public regimeCompetenciaF550: boolean = false;

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarConfiguracaoMovimentacaoContabil();
    this.listarClassificacaoFiscal();
    this.listarCstPisCofins();
    this.listarCstIpi();
    this.listaLojas = this.utilSessao.getLojas();
    this.regimeCompetenciaF550 = this.utilSessao.getLojas().filter((loja) => loja.spedContCritEscrituracao == 2).length > 0 ? true : false;
  }

  ehAlteracao(): void {
    this.cfopEmpresa = this.plainToClass(CfopEmpresa, this.ehAtualizacao(this.cfopEmpresa));
    if (this.cfopEmpresa.id) {
      this.listarCfopEmpresa(this.cfopEmpresa.id);
    }
  }

  listarCfopEmpresa(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.cfopEmpresa).subscribe((res) => {
      this.cfopEmpresa = this.plainToClass(CfopEmpresa, res[0]) as any;
      this.entradaSaida = this.cfopEmpresa.cfopEntradaSaida == 'S' ? bibDialogo.entrada : bibDialogo.saida;
      this.criteriosEntradaSaida.push(new Criterio('ENTRADA_SAIDA', this.cfopEmpresa.cfopEntradaSaida == 'S' ? 'E' : 'S'));
      this.criteriosEntradaSaida.push(new Criterio('CFOP_EMPRESA', 'S'));
      this.criteriosDevolucaoEntradaSaida.push(new Criterio('ENTRADA_SAIDA', this.cfopEmpresa.cfopEntradaSaida == 'S' ? 'E' : 'S'));
      this.criteriosDevolucaoEntradaSaida.push(new Criterio('CFOP_EMPRESA', 'S'));
      this.criteriosDevolucaoEntradaSaida.push(new Criterio('DEVOLUCAO', 'S'));
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_CFOP_EMPRESA', this.cfopEmpresa.id)), this.bibServico.cfopEmpresaConfiguracaoMovimentacaoContabil).subscribe((res) => {
        this.cfopEmpresa.cfopEmpresaConfiguracaoMovimentacaoContabeis = this.plainToClass(CfopEmpresaConfiguracaoMovimentacaoContabil, res) as any;
      });
    });
  }

  listarConfiguracaoMovimentacaoContabil(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.configuracaoMovimentacaoContabil).subscribe((res) => {
      this.configuracaoMovimentacaoContabeis = this.plainToClass(ConfiguracaoMovimentacaoContabil, res) as any;
    });
  }

  listarClassificacaoFiscal(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.classificacaoFiscal).subscribe((res) => {
      this.classificacaoFiscais = this.plainToClass(ClassificacaoFiscal, res) as any;
    });
  }

  listarCstPisCofins(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstPisCofins).subscribe((res) => {
      this.cstsPisCofins = this.plainToClass(Cst, res) as any;
    });
  }

  listarCstIpi(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstIpi).subscribe((res) => {
      this.cstIpis = this.plainToClass(Cst, res) as any;
    });
  }

  setIdCfop(cfop: Cfop): void {
    this.criteriosEntradaSaida = [];
    this.criteriosDevolucaoEntradaSaida = [];
    this.cfopEmpresa.idCfop = cfop.id;
    this.entradaSaida = cfop.entradaSaida == 'S' ? bibDialogo.entrada : bibDialogo.saida;
    this.cfopEmpresa.idCfopContrapartida = null;
    this.cfopEmpresa.cfopEntradaSaida = null;
    this.criteriosEntradaSaida.push(new Criterio('ENTRADA_SAIDA', cfop.entradaSaida == 'S' ? 'E' : 'S'));
    this.criteriosEntradaSaida.push(new Criterio('CFOP_EMPRESA', 'S'));
    this.criteriosDevolucaoEntradaSaida.push(new Criterio('ENTRADA_SAIDA', cfop.entradaSaida == 'S' ? 'S' : 'E'));
    this.criteriosDevolucaoEntradaSaida.push(new Criterio('CFOP_EMPRESA', 'S'));
    this.criteriosDevolucaoEntradaSaida.push(new Criterio('DEVOLUCAO', 'S'));
  }

  setIdContaContabilSped(contaContabil: ContaContabil): void {
    this.cfopEmpresa.idContaContabilSped = contaContabil.id;
    this.cfopEmpresa.contaContabilSped = contaContabil.nome;
  }

  persistirCfopEmpresa(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.cfopEmpresa), this.bibServico.cfopEmpresa, novo ? new CfopEmpresa() : null).subscribe(() => {});
    }
  }

  ehValido(): boolean {
    const ehValidoConfiguracaoMovimentacaoContabil: boolean = this.setEhValidoConfiguracaoMovimentacaoContabil();
    if (this.cfopEmpresa.idCfop && ehValidoConfiguracaoMovimentacaoContabil) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoConfiguracaoMovimentacaoContabil(): boolean {
    if (this.cfopEmpresa.cfopEmpresaConfiguracaoMovimentacaoContabeis.length > 0 && this.cfopEmpresa.cfopEmpresaConfiguracaoMovimentacaoContabeis.filter((cfopEmpresaConfiguracaoMovimentacaoContabil) => cfopEmpresaConfiguracaoMovimentacaoContabil.idLoja == null || cfopEmpresaConfiguracaoMovimentacaoContabil.idConfMovimentacaoContabil == null || (cfopEmpresaConfiguracaoMovimentacaoContabil.idLojaDestino == null && this.cfopEmpresa.cfopTransferencia == 'S')).length > 0) {
      return false;
    }
    return true;
  }

  adicionar(): void {
    let cfopEmpresaConfiguracaoMovimentacaoContabil: CfopEmpresaConfiguracaoMovimentacaoContabil = new CfopEmpresaConfiguracaoMovimentacaoContabil();
    this.cfopEmpresa.cfopEmpresaConfiguracaoMovimentacaoContabeis.push(cfopEmpresaConfiguracaoMovimentacaoContabil);
  }

  editar(cfopEmpresaConfiguracaoMovimentacaoContabil: CfopEmpresaConfiguracaoMovimentacaoContabil): void {
    if (this.edicaoCfopEmpresaConfiguracaoMovimentacaoContabeis.includes(cfopEmpresaConfiguracaoMovimentacaoContabil.id)) {
      const posicao: number = this.edicaoCfopEmpresaConfiguracaoMovimentacaoContabeis.indexOf(cfopEmpresaConfiguracaoMovimentacaoContabil.id, 0);
      this.edicaoCfopEmpresaConfiguracaoMovimentacaoContabeis.splice(posicao, 1);
    } else {
      this.edicaoCfopEmpresaConfiguracaoMovimentacaoContabeis.push(cfopEmpresaConfiguracaoMovimentacaoContabil.id);
    }
  }
}
