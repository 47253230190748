import { APP_BASE_HREF, CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, PlatformLocation } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComunicacaoTecnoSpeedService } from 'src/app/servico/comunicacaoTecnoSpeed.service';
import { AgrowRoutingModule } from './agrow-routing.module';
import { CompendioEletronicoModule } from './agrow/pagina/compendioEletronico/compendioEletronico.module';
import { FinanceiroBoletoComponent } from './agrow/pagina/financeiroBoleto/financeiroBoleto.component';
import { ProdutoFormuladoModule } from './agrow/pagina/produtoFormulado/produtoFormulado.module';
import { ReceituarioModule } from './agrow/pagina/receituario/receituario.module';
import { VisitaModule } from './agrow/pagina/visita/visita.module';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { BibNomenclatura } from './biblioteca/bibNomenclatura';
import { CompartilhadoModule } from './compartilhado/compartilhado.module';
import { FiltroComponent } from './essencial/filtro/filtro.component';
import { ModalNotificacao } from './essencial/modal/modal.notificacao';
import { Progresso } from './essencial/progresso/progresso.component';
import { PesquisaMovimentacaoComponent } from './formulario/pesquisa/movimentacao/pesquisaMovimentacao.component';
import { PesquisaProdutoComponent } from './formulario/pesquisa/produto/pesquisaProduto.component';
import { AutenticacaoGuard } from './guardas/autenticacao.guard';
import { AprovacaoFrmComponent } from './pagina/aprovacao/aprovacaoFrm.component';
import { BaixaNotificacao } from './pagina/baixa/baixa.notificacao';
import { FinanceiroFrmComponent } from './pagina/financeiro/frm/financeiroFrm.component';
import { LoginEmitter } from './pagina/login/login.emitter';
import { MovimentacaoFrmComponent } from './pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { MovimentacaoProdutoImpostoFrmComponent } from './pagina/movimentacao/frm/produto/imposto/movimentacaoProdutoImpostoFrm.component';
import { ParceiroContatoFrmComponent } from './pagina/parceiro/frm/contato/frm/parceiroContatoFrm.component';
import { ParceiroDestinoFrmComponent } from './pagina/parceiro/frm/destino/frm/parceiroDestinoFrm.component';
import { ParceiroEnderecoFrmComponent } from './pagina/parceiro/frm/endereco/frm/parceiroEnderecoFrm.component';
import { ParceiroTelefoneFrmComponent } from './pagina/parceiro/frm/telefone/frm/parceiroTelefoneFrm.component';
import { ParceiroVeiculoFrmComponent } from './pagina/parceiro/frm/veiculo/frm/parceiroVeiculoFrm.component';
import { ComunicacaoService } from './servico/comunicacao.service';
import { ComunicacaoNsService } from './servico/comunicacaoNs.service';
import { ComunicacaoNsCTeService } from './servico/comunicacaoNsCTe.service';
import { ComunicacaoTecnoSpeedBoletoService } from './servico/comunicacaoTecnoSpeedBoleto.service';
import { ComunicacaoTecnoSpeedMDFeService } from './servico/comunicacaoTecnoSpeedMDFe.service';
import { DecimalPipe } from './utilitario/decimal.pipe';
import { UtilNotificacao } from './utilitario/util.notificacao';
import { UtilSessao } from './utilitario/util.sessao';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  imports: [BrowserModule, CommonModule, FormsModule, HttpClientModule, NgbModule, AgrowRoutingModule, AppModule, CompartilhadoModule, CompendioEletronicoModule, ProdutoFormuladoModule, ReceituarioModule, VisitaModule],
  declarations: [FinanceiroBoletoComponent],
  providers: [AutenticacaoGuard, BaixaNotificacao, BibNomenclatura, ComunicacaoService, DatePipe, DecimalPipe, LoginEmitter, Progresso, UtilNotificacao, UtilSessao, ComunicacaoNsService, ComunicacaoNsCTeService, ComunicacaoTecnoSpeedService, ComunicacaoTecnoSpeedBoletoService, ComunicacaoTecnoSpeedMDFeService, ModalNotificacao, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: APP_BASE_HREF, useFactory: getBaseHref, deps: [PlatformLocation] }],
  entryComponents: [FinanceiroFrmComponent, MovimentacaoFrmComponent, ParceiroDestinoFrmComponent, ParceiroTelefoneFrmComponent, ParceiroVeiculoFrmComponent, ParceiroEnderecoFrmComponent, ParceiroContatoFrmComponent, PesquisaProdutoComponent, PesquisaMovimentacaoComponent, FiltroComponent, MovimentacaoProdutoImpostoFrmComponent, AprovacaoFrmComponent],
  bootstrap: [AppComponent],
})
export class AgrowModule { }
