import { Component } from '@angular/core';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './contaContabilFrm.component.html',
})
export class ContaContabilFrmComponent extends PaginaComponent {
  public alteracao: boolean = true;
  public busca: boolean = false;
  public contaContabil: ContaContabil = new ContaContabil();
  public criterios: Criterio[] = [new Criterio('NOME_NUMERACAO'), new Criterio('SEM_ID_ZERO', 'S')];
  public editaNumeracao: boolean = false;
  public idFocoContaContabilPai: number;
  public maximoCaracteresEdicaoNumeracao: number;
  public novaNumeracaoFinal: string;
  public tipoLista: string;
  public trabalhaCorrentista: boolean = false;

  public lista: any[] = [
    { id: 'N', nome: this.menuPlanoConta.apelido.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.sub.toUpperCase() + '-' + this.menuPlanoConta.apelido.toUpperCase() },
  ];

  public correntistas: any[] = [
    { id: 1, nome: this.bibDialogo.nao.toUpperCase() },
    { id: 2, nome: this.bibDialogo.clienteFornecedor.toUpperCase() },
    { id: 3, nome: this.bibDialogo.contaBancaria.toUpperCase() },
    { id: 4, nome: this.bibDialogo.caixa.toUpperCase() },
    { id: 5, nome: this.bibDialogo.grupoDeProduto.toUpperCase() },
  ];

  public origemContaContabeis: any[] = [
    { id: 1, nome: this.bibDialogo.nao.toUpperCase() },
    { id: 2, nome: this.bibDialogo.cliente.toUpperCase() },
    { id: 3, nome: this.bibDialogo.fornecedor.toUpperCase() },
    { id: 4, nome: this.bibDialogo.contaBancaria.toUpperCase() },
    { id: 5, nome: this.bibDialogo.caixa.toUpperCase() },
  ];

  public tipoDeLancamentos: any[] = [
    { id: 1, nome: this.bibDialogo.todos.toUpperCase() },
    { id: 2, nome: this.bibDialogo.manual.toUpperCase() },
    { id: 3, nome: this.bibDialogo.automatico.toUpperCase() },
  ];

  public tiposConta: any[] = [
    { id: 'A', nome: this.bibDialogo.analiticaConta },
    { id: 'S', nome: this.bibDialogo.sinteticaConta },
  ];

  public naturezas: any[] = [
    { id: '09', nome: this.bibDialogo.outros },
    { id: '01', nome: this.bibDialogo.contasDeAtivo },
    { id: '02', nome: this.bibDialogo.contasDePassivo },
    { id: '03', nome: this.bibDialogo.patrimonioLiquido },
    { id: '04', nome: this.bibDialogo.contasDeResultado },
    { id: '05', nome: this.bibDialogo.contasDeCompensacao },
  ];

  ngOnInit(): void {
    this.utilSessao.mostraId = true;
    if (this.utilSessao.getIdentificacao('contaContabilPai')) {
      const contaContabilPai: ContaContabil = this.utilSessao.getIdentificacao('contaContabilPai')?.conteudo;
      this.utilSessao.excluirIdentificacao('contaContabilPai');
      this.setLista('S');
      this.setConta(contaContabilPai);
      this.contaContabil.correntista = 1;
    } else {
      this.ehAlteracao();
    }
    this.trabalhaCorrentista = this.utilSessao.getEmpresa().correntista == 'S' ? true : false;
  }

  setLista(tipo: string): void {
    this.tipoLista = tipo;
    this.contaContabil.idPlanoContaPai = null;
    this.contaContabil.numeracao = null;
    this.editaNumeracao = false;
    if (this.tipoLista == 'N') {
      this.contaContabil.idPlanoContaPai = 0;
      this.setNumeracaoPai('0');
      this.setConta(null);
    }
  }

  setConta(contaContabil: ContaContabil): void {
    this.editaNumeracao = false;
    if (contaContabil) {
      this.contaContabil.contaContabilPai = contaContabil ? contaContabil.nome : null;
      this.contaContabil.idPlanoContaPai = contaContabil ? contaContabil.id : null;
      this.comunicacaoService.listar(new Transporte([new Criterio('ID_PAI', contaContabil.id)]), this.bibServico.contaContabil).subscribe((res) => {
        this.setNumeracao(this.plainToClass(ContaContabil, res), false, contaContabil.numeracao, contaContabil.nivel);
        this.idFocoContaContabilPai = Math.random();
      });
    } else {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PAI', 0)), this.bibServico.contaContabil).subscribe((res) => {
        this.setNumeracao(this.plainToClass(ContaContabil, res), true);
        this.idFocoContaContabilPai = Math.random();
      });
    }
  }

  ehAlteracao(): void {
    this.contaContabil = this.plainToClass(ContaContabil, this.ehAtualizacao(this.contaContabil));
    if (this.contaContabil.id) {
      this.listarContaContabil(this.contaContabil.id);
      this.alteracao = false;
      this.busca = true;
    } else {
      this.contaContabil.correntista = 1;
    }
  }

  listarContaContabil(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.contaContabil).subscribe((res) => {
      this.contaContabil = this.plainToClass(ContaContabil, res[0]) as any;
      this.tipoLista = this.contaContabil.idPlanoContaPai != null ? (this.contaContabil.idPlanoContaPai == 0 ? 'N' : 'S') : null;
    });
  }

  setNumeracao(contaContabeis: ContaContabil[], inicial: boolean, numeracaoPai: string = null, nivel: number = null): void {
    let ultimoNumero: string = '0';
    let numeracao: string;
    if (contaContabeis.length > 0) {
      let numeros: string[] = this.retornarNumeros(this.verificarSequenciaNumeracao(contaContabeis));
      numeracao = numeros.join('.');
      if (inicial) {
        ultimoNumero = numeros[numeros.length - 1];
      } else {
        if (numeros.length > 1) {
          ultimoNumero = numeros[numeros.length - 1];
        }
      }
    }
    if (inicial) {
      this.numeracaoInicial(numeracao, nivel);
    } else {
      this.numeracaoFinal(Number(ultimoNumero), numeracaoPai, nivel + 1);
    }
  }

  retornarNumeros(contaContabil: ContaContabil): string[] {
    let numeros: string[] = [];
    contaContabil.numeracao.split('.').forEach((numero) => {
      if (Number(numero) > 0) {
        numeros.push(numero);
      }
    });
    if (numeros.length > 0) {
      return numeros;
    }
    numeros.push(contaContabil.numeracao);
    return numeros;
  }

  verificarSequenciaNumeracao(contaContabeis: ContaContabil[]): ContaContabil {
    let numeracaoContabil: any[] = [];
    contaContabeis.forEach((contaContabil) => numeracaoContabil.push({ id: contaContabil.id, numero: contaContabil.numeracao.split('.').pop() }));
    numeracaoContabil.sort((a, b) => (Number(a['numero']) == Number(b['numero']) ? 0 : Number(a['numero']) > Number(b['numero']) ? 1 : -1));
    if (Number(numeracaoContabil[0].numero) > 1 && this.tipoLista == 'S') {
      let contaContabil: ContaContabil = contaContabeis.find((contaContabil) => (contaContabil.id = numeracaoContabil[0].id));
      let numeracoes: string[] = contaContabil.numeracao.split('.');
      numeracoes[numeracoes.length - 1] = '0';
      contaContabil.numeracao = numeracoes.join('.');
      return contaContabil;
    } else {
      // for (let index = 0; index < numeracaoContabil.length; index++) {
      //   if (Number(numeracaoContabil[index].numero) + 1 != Number(numeracaoContabil[index + 1 == numeracaoContabil.length ? index : index + 1].numero)) {
      //     return contaContabeis.find((contaContabil) => contaContabil.id == numeracaoContabil[index].id);
      //     break;
      //   }
      // }
      return contaContabeis.find((contaContabil) => contaContabil.id == numeracaoContabil[numeracaoContabil.length - 1].id);
    }
  }

  numeracaoInicial(numeracao: string, nivel: number): void {
    this.contaContabil.numeracao = (Number(numeracao) + 1).toString();
    this.setMascaraContaContabil(this.contaContabil.numeracao, nivel);
    this.setNumeracaoPai(this.contaContabil.numeracaoPai);
  }

  numeracaoFinal(ultimoNumero: number, numeracaoPai: string, nivel: number): void {
    this.setNumeracaoPai(numeracaoPai);
    let numeros: string[] = numeracaoPai.split('.');
    numeros[nivel] = (Number(ultimoNumero) + 1).toString();
    this.contaContabil.numeracao = numeracaoPai + '.' + (Number(ultimoNumero) + 1).toString();
    this.setMascaraContaContabil(this.contaContabil.numeracao, nivel);
  }

  setMascaraContaContabil(numeracao: string, nivel: number): void {
    let nivelConta = nivel == 0 || nivel == null ? 1 : nivel;
    let numeracaoEmpresaContabil: string[] = this.utilSessao.getEmpresa().regraMascaraContaContabil.split('.');
    let numeracaoNiveisSeparados: string[] = numeracao.split('.');
    let quantidadeCaracteresMascaraNivel: number = numeracaoEmpresaContabil[nivelConta - 1] ? numeracaoEmpresaContabil[nivelConta - 1].length : 0;
    let quantidadeCaracteresNumeracaoNivel: number = numeracaoNiveisSeparados[nivelConta - 1] ? numeracaoNiveisSeparados[nivelConta - 1].length : 0;
    if (quantidadeCaracteresMascaraNivel > 0 && quantidadeCaracteresNumeracaoNivel > 0) {
      let diferencaCaracteresMascaraNumeracao: number = quantidadeCaracteresMascaraNivel - quantidadeCaracteresNumeracaoNivel;
      let numeracaoNivelComMascara = numeracaoNiveisSeparados.map((numeracaoNivel) => {
        if (diferencaCaracteresMascaraNumeracao > 0) {
          numeracaoNivel = numeracaoNivel.padStart(quantidadeCaracteresMascaraNivel, '0');
        }
        return numeracaoNivel;
      });
      numeracaoNiveisSeparados.pop();
      numeracaoNiveisSeparados.push(numeracaoNivelComMascara[nivelConta - 1]);
      this.contaContabil.numeracao = numeracaoNiveisSeparados.join('.');
    }
  }

  setNumeracaoPai(numeracaoPai: string): void {
    this.contaContabil.numeracaoPai = numeracaoPai;
  }

  removerPontos(texto: string): string {
    if (texto) {
      let numeros: string[] = texto.split('.');
      return numeros.join('');
    }
    return texto;
  }

  persistirContaContabil(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.contaContabil), this.bibServico.contaContabil, novo ? new ContaContabil() : null);
    }
    if (novo == true) {
      this.tipoLista = null;
    }
  }

  ehValido(): boolean {
    const ehValidoNumeracao: boolean = this.setEhValidoNumeracao();
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoContaContabilPai: boolean = this.setEhValidoContaContabilPai();
    return ehValidoNumeracao && ehValidoObrigatorio && ehValidoContaContabilPai;
  }

  setEhValidoNumeracao(): boolean {
    let ultimoCaracter: string = this.contaContabil.numeracao.charAt(this.contaContabil.numeracao.length - 1);
    if (ultimoCaracter == '.') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.numeracaoNaoPodeTerminarEmPonto));
      return false;
    }
    return true;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.contaContabil.nome && this.contaContabil.numeracao) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoContaContabilPai(): boolean {
    let ehValido: boolean = true;
    if (this.contaContabil.idPlanoContaPai == null) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.contaContabilPaiNaoEncontrada, [this.contaContabil.numeracaoPai])));
      ehValido = false;
    }
    if (this.contaContabil.id && this.contaContabil.idPlanoContaPai == this.contaContabil.id) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.planoContaPaiNaoPodeEleMesmo));
      ehValido = false;
    }
    return ehValido;
  }

  setEditarNumeracao(editar: boolean) {
    const numeracoes: string[] = this.contaContabil.numeracao.split('.');
    this.maximoCaracteresEdicaoNumeracao = numeracoes[numeracoes.length - 1].length;
    this.novaNumeracaoFinal = numeracoes.pop();
    this.editaNumeracao = this.editaNumeracao == editar ? false : true;
  }

  setNovaNumeracao(numeracao: string): void {
    this.novaNumeracaoFinal = numeracao;
  }

  setAlteracaoNumeracao(navegar: boolean): void {
    if (navegar == false && this.contaContabil.numeracao) {
      const numeracaoContabeis: string[] = this.contaContabil.numeracao.split('.');
      numeracaoContabeis[numeracaoContabeis.length - 1] = this.novaNumeracaoFinal;
      numeracaoContabeis[numeracaoContabeis.length - 1] = numeracaoContabeis[numeracaoContabeis.length - 1].padStart(this.maximoCaracteresEdicaoNumeracao, '0');
      this.novaNumeracaoFinal = numeracaoContabeis[numeracaoContabeis.length - 1];
      this.contaContabil.numeracao = numeracaoContabeis.join('.');
    }
  }

  ngOnDestroy(): void {
    this.utilSessao.mostraId = false;
  }
}
