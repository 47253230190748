export class ProdutoIcmsEspecifico {
  public aliquotaFisica: number;
  public aliquotaInterna: number;  
  public aliquotaJuridica: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public enquadramento: number;
  public estado: string;
  public estadoOrigem: string;
  public excluido: boolean = false;
  public id: number;
  public idEmpresa: number;
  public idEstado: number;
  public idEstadoOrigem: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
}
