import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { RelatorioComponent } from '../../relatorio.component';

@Component({
  selector: ' <tr>[r1168MovimentacaoFinanceira]</tr>',
  templateUrl: './r1168MovimentacaoFinanceira.component.html',
})
export class R1168MovimentacaoFinanceiraComponent extends RelatorioComponent {
  @Input() movimentacaoFinanceiras: any[];
  public bibDialogo = bibDialogo;
}
