<div class="largura-maxima">
  <containerFormulario [vertical]="vertical" [rotulo]="_rotulo" [preenchido]="preenchido" [obrigatorio]="obrigatorio && _rotulo && !compacto" [compacto]="compacto" [ajuda]="ajuda" [atencaoMensagem]="null" [icone]="icone" [prefixo]="prefixo" [sufixo]="sufixo" [desabilitado]="desabilitado" [campoFor]="_id" [iconeDesabilitado]="iconeDesabilitado" (btnAdicional)="clicarBtnAdicional()">
    <div *ngIf="mostraCalendario" class="input-group input-group-alt flatpickr" [id]="'flatpickr' + id" data-toggle="flatpickr" wrap="true" data-wrap="true" [ngClass]="{ 'flatpickr-compacto': compacto }">
      <input #input tabindex="{{ desabilitado ? '-1' : '0' }}" [name]="_rotulo" [ngModel]="campoAtual | data: 'dd/MM/yyyy'" (focus)="navegar(true)" (ngModelChange)="alterarData($event)" [id]="_id" type="text" class="form-control flatpickr-input" data-input="" (blur)="navegar(false)" [disabled]="desabilitado" [mascara]="'99/99/9999'" autocomplete="off" (change)="alterarData($event.target.value)" [readonly]="desabilitado" [ngClass]="{ 'fonte-preta': !usaModoClaro && desabilitado }" />
      <div class="input-group-append" [ngClass]="desabilitado ? 'ocultar' : ''">
        <button [ngbTooltip]="focado && campo == null ? bibDialogo.ajudaData : ''" tabindex="-1" type="button" class="btn btn-secondary" data-toggle="">
          <i class="far fa-calendar"></i>
        </button>
      </div>
      <obrigatorio class="align-self-center" *ngIf="obrigatorio && !campoAtual" [obrigatorio]="obrigatorio" [compacto]="true"> </obrigatorio>
      <atencao *ngIf="!rotulo && atencaoMensagem" class="align-self-center mr-md-1" [atencao]="atencaoMensagem" [tipo]="bibPropriedade.atencao.alerta" [compacto]="true"> </atencao>
      <ajuda class="align-self-center mr-md-1" *ngIf="!_rotulo && ajuda" [ajuda]="'ajuda'"></ajuda>
    </div>
    <div *ngIf="mostraCalendario == false" class="input-group input-group-alt" [ngClass]="{ 'flatpickr-compacto': compacto }">
      <input type="text" class="form-control flatpickr-input fonte-preta" [ngModel]="campoAtual | data: 'dd/MM/yyyy'" autocomplete="off" [readonly]="true"/>
    </div>
  </containerFormulario>
</div>
