<div *ngIf="!apresentaTodos" class="row">
  <check (alteracao)="setAgrupado()" [compacto]="true"></check>
  {{ bibDialogo.vizualizarProdutosTotalizados }}
</div>
<table *ngIf="!agrupado" class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l-5">{{ bibDialogo.loja }}</th>
      <th class="l-10">{{ bibDialogo.data }}</th>
      <th class="l-10 text-right">{{ bibDialogo.numero }}</th>
      <th class="l-10">{{ bibDialogo.responsavel }}</th>
      <th class="l-20">{{ bibDialogo.produto }}</th>
      <th class="l-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="l-10 text-right">{{ bibDialogo.qtd }}</th>
      <th class="l-10 text-right">{{ bibDialogo.valorUnitario }}</th>
      <th class="l-10 text-right">{{ bibDialogo.desconto }}</th>
      <th class="l-10 text-right">{{ bibDialogo.valorTotal }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacao of movimentacoes">
      <ng-container *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos">
        <tr>
          <td>{{ movimentacao.abreviacao }}</td>
          <td>{{ movimentacao.data | data }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td class="limitar">{{ movimentacao.colaboradorAbreviacao }} <ajuda class="naoImprimir" [ajuda]="movimentacao.colaborador"></ajuda></td>
          <td class="limitar">{{ movimentacaoProduto.produto }}</td>
          <td>{{ movimentacaoProduto.abreviacao }}</td>
          <td class="text-right">{{ movimentacaoProduto.getQuantidade() | monetario: empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ movimentacaoProduto.getValorUnitario() | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.descontoValor | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorTotal | monetario }}</td>
        </tr>
        <tr *ngIf="apresentaTodos" >
          <atencao  [atencao]="movimentacao.operacao"></atencao>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
<table *ngIf="agrupado" class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l-35">{{ bibDialogo.produto }}</th>
      <th class="l-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="l-15 text-right">{{ bibDialogo.qtd }}</th>
      <th class="l-15 text-right">{{ bibDialogo.valorUnitario }}</th>
      <th class="l-15 text-right">{{ bibDialogo.desconto }}</th>
      <th class="l-15 text-right">{{ bibDialogo.valorTotal }}</th>
    </tr>
  </thead>
  <tbody *ngIf="agrupado">
    <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutoAgrupados">
      <tr>
        <td class="limitar">{{ movimentacaoProduto.produto }}</td>
        <td>{{ movimentacaoProduto.abreviacao }}</td>
        <td class="text-right">{{ movimentacaoProduto.getQuantidade() | monetario }}</td>
        <td class="text-right">{{ movimentacaoProduto.getValorUnitario() | monetario }}</td>
        <td class="text-right">{{ movimentacaoProduto.descontoValor | monetario }}</td>
        <td class="text-right">{{ movimentacaoProduto.valorTotal | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
