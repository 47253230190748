<layout [titulo]="negociacao.nome" [id]="negociacao.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="negociacao.nome" (alteracao)="negociacao.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="negociacao.ativo" (alteracao)="negociacao.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm-4" [id]="'tipoTitulo'" [idMenu]="permissaoTipoDeTitulo && permissaoTipoDeTitulo.inserir == 'S' ? menuTipoDeTitulo.idMenu : null" [rotulo]="menuTipoDeTitulo.apelido" [campo]="negociacao.idTipoTitulo" (alteracao)="setTipoTitulo($event)" [lista]="tipoTitulos" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarTipoTitulo()"></lista>
    <lista class="col-sm-4" [id]="'especie'" [rotulo]="bibDialogo.especie" [campo]="negociacao.especie" (alteracao)="negociacao.especie = $event.id" [lista]="especies" [obrigatorio]="true"></lista>
    <texto class="col-sm-4" [id]="'abreviacao'" [rotulo]="bibDialogo.abreviacao" [campo]="negociacao.abreviacao" (alteracao)="negociacao.abreviacao = $event" [maximoCaracteres]="3" [obrigatorio]="true"></texto>
  </div>
  <div class="row">
    <lista class="col-sm-4" [id]="'usadoComo'" [rotulo]="bibDialogo.usadoComo" [campo]="negociacao.entradaSaida" (alteracao)="negociacao.entradaSaida = $event.id" [lista]="entradaSaida" [obrigatorio]="true" [desabilitado]="negociacao.id"></lista>
    <lista class="col-sm-4" [id]="'modalidade'" [rotulo]="bibDialogo.modalidade" [campo]="negociacao.aVista" (alteracao)="setModalidade($event.id)" [lista]="modalidades" [obrigatorio]="true"></lista>
    <lista class="col-sm" [id]="'restricao'" [rotulo]="bibDialogo.restricao" [campo]="negociacao.restricao" [lista]="restricoes" (alteracao)="setRestricao($event.id)" [obrigatorio]="true"></lista>
  </div>
  <titulo [titulo]="bibDialogo.parcelamento" [imagem]="bibImagem.acoes"></titulo>
  <div class="row">
    <texto class="col-sm-4" [id]="'numeroFixo'" [ajuda]="bibDialogo.numeroExatoParcela" [rotulo]="bibDialogo.numeroFixoParcela" [campo]="negociacao.parcelaFixo" [sufixo]="bibDialogo.parcelas.toLowerCase()" (alteracao)="setParcelaFixo($event)" [desabilitado]="negociacao.especie != 'V'" [tipo]="bibPropriedade.texto.inteiro"></texto>
    <decimal class="col-sm-4" [id]="'valorMinimoParcela'" [rotulo]="bibDialogo.valorMinimoParcela" [campo]="negociacao.parcelaMinimoValor" (alteracao)="negociacao.parcelaMinimoValor = $event" [desabilitado]="negociacao.especie != 'V'"></decimal>
    <texto class="col-sm-3" *ngIf="negociacao.parcelaFixo == 0 || negociacao.parcelaFixo == null" class="col-sm-4" [ajuda]="bibDialogo.quantidadeMaximaParcela" [rotulo]="bibDialogo.numeroMaximo" [campo]="negociacao.parcelaMaximo" [sufixo]="bibDialogo.parcelas.toLowerCase()" [tipo]="bibPropriedade.texto.inteiro" (alteracao)="setParcelaMaximo($event)" [desabilitado]="negociacao.especie != 'V'"></texto>
  </div>
  <div class="row">
    <texto *ngIf="negociacao.parcelaFixo != 1" class="col-sm-3" [id]="'diasEntre'" [rotulo]="bibDialogo.diasEntre" [campo]="negociacao.diasEntre" (alteracao)="setDiasEntre($event)" [tipo]="bibPropriedade.texto.inteiro" [desabilitado]="negociacao.diaFechamentoPadrao || negociacao.especie != 'V'"></texto>
    <p *ngIf="negociacao.parcelaFixo != 1" class="col-sm-1 mb-0">{{ 'ou' }}</p>
    <texto class="col-sm-4" [id]="'diaFixo'" [rotulo]="bibDialogo.diaFixo" [campo]="negociacao.diaFechamentoPadrao" (alteracao)="setDiaFechamentoPadrao($event)" [valorMaximo]="31" [ajuda]="bibDialogo.informeUmDia" [tipo]="bibPropriedade.texto.inteiro" [desabilitado]="negociacao.diasEntre || negociacao.especie != 'V'"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.prazoMaximo" [campo]="negociacao.prazoMaximoDias" (alteracao)="negociacao.prazoMaximoDias = $event" [ajuda]="bibDialogo.ajudaPrazoMaximo" [tipo]="bibPropriedade.texto.inteiro" [sufixo]="bibDialogo.dias.toLowerCase()" ></texto>  
    <lista *ngIf="negociacao.diasEntre > 0" class="col-sm-3" [id]="'permitirAlterarDiasEntre'" [rotulo]="bibDialogo.permitirAlterarDiasEntre" [campo]="negociacao.permitirAlterarDiasEntre" (alteracao)="negociacao.permitirAlterarDiasEntre = $event.id" [lista]="listaSimNao" [ajuda]="bibDialogo.ajudaPermitirAlterarDiasEntre" [obrigatorio]="true"></lista>  
  </div>
  <div *ngIf="negociacao.parcelaFixo != 1" class="row">
    <lista class="col-sm-4" [id]="'diaDeGeracao'" [rotulo]="bibDialogo.diaDeGeracao" [campo]="negociacao.diaDeGeracao" (alteracao)="negociacao.diaDeGeracao = $event.id" [lista]="diaGeracao" [obrigatorio]="true" [desabilitado]="negociacao.especie != 'V'"></lista>
  </div>
  <titulo [titulo]="bibDialogo.penalidade" [imagem]="bibImagem.penalidade"></titulo>
  <div class="row">
    <decimal class="col-sm-4" [id]="'taxaFixa'" [rotulo]="bibDialogo.taxaFixa" [campo]="negociacao.penalidadeTaxaFixa" (alteracao)="negociacao.penalidadeTaxaFixa = $event" [sufixo]="'%'" [obrigatorio]="negociacao.especie == 'V' ? true : false" [desabilitado]="negociacao.especie != 'V'"></decimal>
    <decimal class="col-sm-4" [id]="'penalidadeTaxaMensal'" [rotulo]="bibDialogo.taxaMensal" [campo]="negociacao.penalidadeTaxaMensal" (alteracao)="setTaxaDiaria($event)" [sufixo]="'%'" [obrigatorio]="negociacao.especie == 'V' ? true : false" [desabilitado]="negociacao.especie != 'V'" [numeroCasasDecimais]="2"></decimal>
    <decimal class="col-sm-4" [id]="'penalidadeTaxaDiaria'" [rotulo]="bibDialogo.taxaDiaria" [campo]="negociacao.penalidadeTaxaDiaria" (alteracao)="negociacao.penalidadeTaxaDiaria = $event" [sufixo]="'%'" [obrigatorio]="negociacao.especie == 'V' ? true : false" [desabilitado]="true" [numeroCasasDecimais]="4"></decimal>
  </div>
  <div class="row">
    <texto class="col-sm-4" [id]="'penalizarAPartirDe'" [tipo]="bibPropriedade.texto.inteiro" [rotulo]="bibDialogo.penalizarPartirDe" [campo]="negociacao.penalidadeDiaMinimo" (alteracao)="negociacao.penalidadeDiaMinimo = $event" [sufixo]="'dias'" [obrigatorio]="negociacao.especie == 'V' ? true : false" [desabilitado]="negociacao.especie != 'V'"></texto>
    <texto class="col-sm-4" [id]="'diaPrazoReferenciaLucro'" [ajuda]="bibDialogo.penalidadeDiaPrazoReferenciaLucro" [sufixo]="'dias'" [tipo]="bibPropriedade.texto.inteiro" [rotulo]="bibDialogo.penalidadeDiaPrazoLucro" [campo]="negociacao.prazoReferenciaLucro" (alteracao)="negociacao.prazoReferenciaLucro = $event"></texto>
  </div>
  <titulo [titulo]="bibDialogo.validacoes" [imagem]="bibImagem.checkbox"></titulo>
  <div class="row">
    <lista class="col-sm-3" [id]="'permitirEntregaDireta'" [rotulo]="bibDialogo.permitirEntregaDireta" [campo]="negociacao.permitirEntregaDireta" (alteracao)="negociacao.permitirEntregaDireta = $event.id" [lista]="permiteEntregaDireta" [obrigatorio]="true"></lista>
    <lista class="col-sm-3" [id]="'exigeAprovacao'" [ajuda]="bibDialogo.exigeAprovacaoCrediario" [rotulo]="bibDialogo.exigeAprovacao" [campo]="negociacao.exigeAprovacao" (alteracao)="negociacao.exigeAprovacao = $event.id" [lista]="listaSimNao" [obrigatorio]="true"></lista>
    <lista class="col-sm-3" [id]="'validaLimiteCredito'" [rotulo]="bibDialogo.validaLimiteCredito" [campo]="negociacao.validaLimiteCredito" (alteracao)="negociacao.validaLimiteCredito = $event.id" [lista]="listaSimNao" [obrigatorio]="negociacao.especie == 'V' ? true : false" [desabilitado]="negociacao.especie != 'V'"></lista>
    <lista class="col-sm-3" [id]="'validaInadimplente'" [rotulo]="bibDialogo.validaInadimplente" [campo]="negociacao.validaInadimplente" (alteracao)="negociacao.validaInadimplente = $event.id" [lista]="listaSimNao" [obrigatorio]="negociacao.especie == 'V' ? true : false" [desabilitado]="negociacao.especie != 'V'"></lista>
    <decimal class="col-sm-3" [id]="'descontoMaximo'" [rotulo]="bibDialogo.descontoMaximo" [campo]="negociacao.descontoMaximo" (alteracao)="negociacao.descontoMaximo = $event ? $event : 0" [sufixo]="'%'" [numeroCasasDecimais]="2" [desabilitado]="negociacao.especie != 'V'" [valorMaximo]="100" [obrigatorio]="true"></decimal>
  </div>
  <titulo [titulo]="bibDialogo.lojaOrigem" [imagem]="bibImagem.loja"></titulo>
  <div class="row">
    <lista class="col-sm-12" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="negociacao.idLoja" (alteracao)="negociacao.idLoja = $event.id" [obrigatorioLoja]="true" [tipo]="bibPropriedade.lista.lojaUsuario" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirNegociacao()" [desabilitado]="bloquearPermissao(negociacao.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirNegociacao(true)" [desabilitado]="bloquearPermissao(negociacao.id)"></botao>
<auditoria *ngIf="negociacao.id" [idUsuarioInclusao]="negociacao.idUsuarioInclusao" [dataHoraInclusao]="negociacao.dataHoraInclusao" [idUsuarioAlteracao]="negociacao.idUsuarioAlteracao" [dataHoraAlteracao]="negociacao.dataHoraAlteracao"></auditoria>
