import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtroComboSituação: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'ABERTOS', id: '>' },
  { nome: 'TODOS', id: '-' },
  { nome: 'QUITADOS', id: '=' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { id: 'dataNegociacaoDe', rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataNegociacaoAte', rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataVencimentoDe', rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataVencimentoAte', rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'parceiro', rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
  { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
  { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '>', lista: filtroComboSituação },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboAtivo },
  { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
