export class MovimentacaoCartaCorrecao {
  private idUsuarioAlteracao: number;
  private idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public expandido: boolean = false;
  public id: number;
  public idEmpresa: number;
  public idMovimentacao: number;
  public justificativa: string;
  public sequencia: number;
  public status: number;
  public statusDescricao: string;
}
