export class Prod {
    public cProd: string;
    public cEAN: string;
    public xProd: string;
    public NCM: string;
    public CEST: string;
    public CFOP: string;
    public uCom: string;
    public qCom: string;
    public vUnCom: string;
    public vProd: string;
    public vDesc: string;
    public vFrete: string;
    public cEANTrib: string;
    public uTrib: string;
    public qTrib: string;
    public vUnTrib: string;
    public indTot: string;
    public nItemPed: string;
    public vOutro: string;
}