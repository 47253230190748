export class Menu {
  public acessar: string;
  public ativo: string = 'S';
  public descricao: string;
  public descricaoOriginal: string;
  public editar: string;
  public excluir: string;
  public filtro: string = 'N';
  public icone: string;
  public id: number;
  public idMenuFiltro: number;
  public idModulo: number;
  public imprimir: string = 'S';
  public inativar: string;
  public insercao: string;
  public inserir: string;
  public modularizado: string;
  public modulo: string;
  public moduloEmpresa: string;
  public moduloEmpresaPai: string;
  public moduloPai: string;
  public movimentacao: string;
  public nome: string;
  public nomeOriginal: string;
  public novo: string;
  public ordem: number;
  public parametro: string;
  public servico: string;
  public sistema: string;
  public tag: string;
  public tagOriginal: string;
}
