<layout [titulo]="bibDialogo.mdfe" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div *ngIf="emiteEmContingencia" class="col-sm-12">
     <div class="alert alert-warning has-icon" role="alert">
      <div class="alert-icon">
        <span class="fa fa-bullhorn"></span>
      </div>
      {{ bibDialogo.configuradoContingencia }}
    </div> 
  </div>
  <div *ngIf="emiteEmHomologacao" class="col-sm-12">
    <div class="alert alert-warning has-icon" role="alert">
     <div class="alert-icon">
       <span class="fa fa-bullhorn"></span>
     </div>
     {{ bibDialogo.configuradoHomologacao }}
   </div> 
 </div>  
  <div class="row justify-content-between">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
    <span *ngIf="dataAtualizacao" class="text-uppercase">{{ bibDialogo.dataAtualizacao + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm:ss') }} </span>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-20 clique limitar" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-20 limitar" (click)="listar(add('cidadeFinal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cidade + ' ' + bibDialogo.final.toLowerCase() }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let mdfe of mdfes; let i = index">
        <tr>
          <td>
            <check (alteracao)="setMdfeSelecionado(mdfe)"></check>
          </td>
          <td>
              <icone [icone]="bibIcone.circulo" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="mdfe?.situacao == 1 ? bibPropriedade.icone.cinza : mdfe?.situacao == 2 ? bibPropriedade.icone.cinza : mdfe?.situacao == 3 ? bibPropriedade.icone.verde : mdfe?.situacao == 4 ? bibPropriedade.icone.azul : mdfe?.situacao == 5 ? bibPropriedade.icone.vermelho : bibPropriedade.icone.preto" [mensagem]="mdfe.situacao == 1 ? bibDialogo.aguardandoEnvio : mdfe.situacao == 2 ? bibDialogo.aguardandoEnvio : mdfe.situacao == 3 ? bibDialogo.transmitido : mdfe.situacao == 4 ? bibDialogo.encerrado : mdfe.situacao == 5 ? bibDialogo.rejeicao : bibDialogo.cancelada" [ajudaDireita]="true"></icone>
          </td>
          <td>{{ mdfe.data | data }}</td>
          <td class="text-right">{{ mdfe.numero }}</td>
          <td class="limitar"><a href="#" (click)="abrirModalMdfe(mdfe.id)">{{ mdfe.loja }}</a></td>
          <td>{{ mdfe.cidadeFinal }}</td>
          <ng-container>
            <td><btnAdicional [icone]="bibIcone.lupa" [ajuda]="bibDialogo.consultarMDFe" (btnAdicional)="consultarMdfeTecnospeed(mdfe)"></btnAdicional></td>
          </ng-container>
          <td><btnAdicional [icone]="mdfe.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="mdfe.expandido = !mdfe.expandido" [desabilitado]="mdfe?.status != 1 ? false : true"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.xml" [ajuda]="bibDialogo.xml" (btnAdicional)="baixarXml(mdfe)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.pdf" [ajuda]="bibDialogo.pdf" (btnAdicional)="baixarPdf(mdfe)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.checado" [ajuda]="bibDialogo.enviarSolicitacaoEncerramento" (btnAdicional)="enviarEncerramento(mdfe)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.atencao" [ajuda]="bibDialogo.enviarSolicitacaoCancelamento" (btnAdicional)="enviarCancelamento(mdfe)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.carta" [ajuda]="bibDialogo.enviarPdfXmlParaCliente" (btnAdicional)="ehEnviarEmail(mdfe.idParceiroTransportadora, mdfe)"></btnAdicional></td>
        </tr>
        <ng-container *ngIf="mdfe.expandido">
          <tr *ngIf="mdfe?.situacao != 0 && mdfe?.situacao != 100 && mdfe?.situacao != -1">
            <td colspan="15"><descricao [rotulo]="bibDialogo.descricao" [campo]="mdfe?.situacaoDescricao" [normal]="true" [maximoCaracteres]="1500" [desabilitado]="true"></descricao></td>
          </tr>
          <ng-container *ngIf="mdfe?.status == 100">
            <tr>
              <td colspan="8"><apresentacao [rotulo]="bibDialogo.chave" [campo]="mdfe.chave"></apresentacao></td>
              <td colspan="7"><apresentacao [rotulo]="bibDialogo.protocolo" [campo]="mdfe?.protocolo"></apresentacao></td>
            </tr>
            <tr>
              <td colspan="8"><apresentacao [rotulo]="bibDialogo.situacao" [campo]="bibDialogo.transmitidaComSucesso"> </apresentacao></td>
              <td colspan="7"><apresentacao [rotulo]="bibDialogo.dataAutorizacao" [campo]="mdfe?.dataHora"></apresentacao></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="row p-0 m-0">
    <botao [compacto]="compacto" [legenda]="bibDialogo.emitir + ' ' + bibDialogo.mdfe" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="iniciarProcessamentoMdfe()"></botao>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>

