<layout [titulo]="produtoControlador.produto.nome" [id]="produtoControlador.produto.id" (voltarAvancarEmt)="ehAlteracao()">
  <produtoCabecalhoFrm [produtoControlador]="produtoControlador"></produtoCabecalhoFrm>
  <div *ngIf="produtoControlador.produto.controlarEstoque == 'S'">
    <produtoEstoqueMinimoMaximoFrm *ngIf="produtoControlador.produto.tipo == 'P'" [produtoControlador]="produtoControlador"> </produtoEstoqueMinimoMaximoFrm>
  </div>
  <produtoRodapeFrm [produtoControlador]="produtoControlador" [produtoHistoricoMesAnual]="produtoControlador.produtoHistoricoMesAnual ? produtoControlador.produtoHistoricoMesAnual : null"></produtoRodapeFrm>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirProduto()" [desabilitado]="bloquearPermissao(produtoControlador.produto.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirProduto(true)" [desabilitado]="bloquearPermissao(produtoControlador.produto.id)"></botao>
<auditoria *ngIf="produtoControlador.produto.id" [idUsuarioInclusao]="produtoControlador.produto.idUsuarioInclusao" [dataHoraInclusao]="produtoControlador.produto.dataHoraInclusao" [idUsuarioAlteracao]="produtoControlador.produto.idUsuarioAlteracao" [dataHoraAlteracao]="produtoControlador.produto.dataHoraAlteracao"></auditoria>
