import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { FinanceiroFrmComponent } from 'src/app/pagina/financeiro/frm/financeiroFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';

@Component({
  styleUrls: ['./r2033.component.css'],
  templateUrl: './r2033.component.html',
})
export class R2033Component extends RelatorioComponent {
  public filtros = filtros;
  public totais = totais;
  public grupos = grupos;
  public financeiros: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public modal: boolean = false;
  public componente: any;

  listarRelatorio(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_EMPRESA', this.utilSessao.getEmpresa().id));
    super.listar(criterios).subscribe(() => {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.totalizar(this.financeiros);
    });
  }

  abrirModalFinanceiro(id: number, tipo: string): void {
    const MENU_RECEITA = 28;
    const MENU_DESPESA = 41;
    const idMenu: number = tipo == 'D' ? MENU_DESPESA : MENU_RECEITA;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('parametro', tipo), new Identificacao('objeto', id)];
    this.componente = this.util.abrirModal(FinanceiroFrmComponent, identificacoes, idMenu);
  }
}
