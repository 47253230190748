import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibServico from 'src/app/biblioteca/bibServico';
import { Hierarquia } from './hierarquia';

export class Categoria implements Hierarquia {
  private pai: string = 'N';
  public ativo: string = 'S';
  public bibDialogo = bibDialogo;
  public bibServico = bibServico;
  public categoriaPai: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idCategoriaPai: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public rotulo: string = this.bibDialogo.categoriaPai;
  public servico: string = this.bibServico.categoria;

  setIdPai(idPai: number) {
    this.idCategoriaPai = idPai;
  }

  getIdPai(): number {
    return this.idCategoriaPai;
  }

  getPai(): string {
    return this.categoriaPai;
  }
}
