import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { differenceInHours } from 'date-fns';
import { EMPTY, Observable } from 'rxjs';
import { share, timeout } from 'rxjs/operators';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Conexao } from '../biblioteca/conexao';
import { Criterio } from '../modelo/criterio';
import { Log } from '../modelo/log';
import { LogDetalhe } from '../modelo/logDetalhe';
import { Resultado } from '../modelo/resultado';
import { Transporte } from '../modelo/transporte';
import { Usuario } from '../modelo/usuario';
import { Util } from '../utilitario/util';
import { UtilSessao } from '../utilitario/util.sessao';
import { UtilCriptografia } from '../utilitario/utilCriptografia';

@Injectable()
export class ComunicacaoService {
  private bibDialogo = bibDialogo;
  private conexao: Conexao = new Conexao(this.utilSessao);
  private mostraCarregando: boolean = true;
  private uri: string = 'http://localhost:8080';
  private utilCriptografia: UtilCriptografia = new UtilCriptografia();
  private util: Util = new Util();
  private objetoAnterior: any;
  public plainToClass: Function = plainToClass;
  public ipRede: string;
  public dataHoraIp: Date;
  constructor(private http: HttpClient, private utilSessao: UtilSessao) { }

  listar(transporte: Transporte, servico: string, urlGrade: boolean = false, validaUsuario: boolean = true, validaRegistro: boolean = true, mostraCarregando: boolean = true, tempoEspera: number = 120000, registraLog: boolean = false, mensagemErro: string = '', nomeCampoOrdem: string = ''): Observable<any> {
    if (nomeCampoOrdem.length > 0) {
      if (confirm(this.util.substituir(this.bibDialogo.certezaOrdenar, [nomeCampoOrdem]))) {
        return this.listarContar(transporte, servico, urlGrade, validaUsuario, validaRegistro, mostraCarregando, tempoEspera, registraLog, false, mensagemErro);
      }
      return EMPTY;
    }
    return this.listarContar(transporte, servico, urlGrade, validaUsuario, validaRegistro, mostraCarregando, tempoEspera, registraLog, false, mensagemErro);
  }

  contar(transporte: Transporte, servico: string, urlGrade: boolean = false, validaUsuario: boolean = true, validaRegistro: boolean = true, mostraCarregando: boolean = true, tempoEspera: number = 120000, registraLog: boolean = false): Observable<any> {
    return this.listarContar(transporte, servico, urlGrade, validaUsuario, validaRegistro, mostraCarregando, tempoEspera, registraLog, true);
  }

  listarContar(transporte: Transporte, servico: string, urlGrade: boolean = false, validaUsuario: boolean = true, validaRegistro: boolean = true, mostraCarregando: boolean = true, tempoEspera: number = 120000, registraLog: boolean = false, contar: boolean = false, mensagemErro: string = ''): Observable<any> {
    if (transporte.posicaoNaSessao == true) {
      this.utilSessao.posicao = transporte.posicao;
    }
    this.mostraCarregando = mostraCarregando;
    let resposta: Observable<any>;
    if (this.bibDialogo.backendW == 'S') {
      const criterio: string = transporte.objetos.length > 0 ? this.modificarCriterios(transporte.objetos) : '';
      const idEmpresa: number = this.utilSessao.getUsuario().idEmpresa;
      const url: string = urlGrade ? '/grade' : '';
      resposta = this.verificarResultadoWeb(this.http.get<any>(`${this.uri}/${servico}${url}?idEmpresa=${idEmpresa}${criterio}`));
    } else {
      const url: string = contar ? this.conexao.contarUrl(servico) : this.conexao.listarUrl(servico);
      resposta = this.consumir(transporte, url, validaUsuario, tempoEspera, mensagemErro);
    }
    resposta.subscribe((res) => {
      if (registraLog) {
        this.objetoAnterior = res;
      }
      if (res.length > 0) {
        if (res[res.length - 1].numeroRegistro != null) {
          this.utilSessao.numeroRegistro = res[res.length - 1].numeroRegistro;
          res.splice(res.length - 1, 1);
        }
      }
      if (this.utilSessao.modalFiltro) {
        if (res == '' && validaRegistro) {
          //this.utilSessao.setResultado(new Resultado(false, bibDialogo.nenhumRegistroEncontrado));
        }
        this.utilSessao.modalFiltro = false;
      }
    });
    return resposta;
  }

  definirNumeroRegistro(resultado: any) {
    if (resultado.length > 0) {
      if (resultado[resultado.length - 1].numeroRegistro) {
        this.utilSessao.numeroRegistro = resultado[resultado.length - 1].numeroRegistro;
      }
    }
  }

  listarLog(transporte: Transporte, servico: string): Observable<any> {
    return this.listar(transporte, servico, false, true, true, true, 120000, true);
  }

  excluir(transporte: Transporte, servico: string, tempoEspera: number = 60000, mensagemErro: string = ''): Observable<any | null> {
    if (this.bibDialogo.backendW == 'S') {
      return this.http.delete<any>(`${this.uri}/${servico}/${transporte.objetos[0]}`);
    } else {
      const url: string = this.conexao.excluirUrl(servico);
      return this.consumir(transporte, url, true, tempoEspera, mensagemErro);
    }
  }

  persistir(transporteOuObjetos: any, servico: string, tempoEspera: number = 1200000, mensagemErro: string = ''): Observable<any | null> {
    this.criarLogDetalhe(transporteOuObjetos);
    if (this.bibDialogo.backendW == 'S') {
      this.definirUsuarioEmpresa(transporteOuObjetos);
      return this.verificarResultadoWeb(this.http.post(`${this.uri}/${servico}`, transporteOuObjetos).pipe(share()).pipe(timeout(tempoEspera)));
    } else {
      const url: string = this.conexao.persistirUrl(servico);
      return this.consumir(transporteOuObjetos, url, true, tempoEspera, mensagemErro);
    }
  }

  criarLogDetalhe(transporteOuObjetos: any): void {
    if (this.objetoAnterior != null) {
      if (this.utilSessao.getPermissao() && this.utilSessao.getPermissao().idMenu) {
        let log: Log = this.criarLog(this.utilSessao.getPermissao().idMenu);
        this.criarDetalhe(transporteOuObjetos, log);
        if (log.logDetalhes.length != 0) {
          transporteOuObjetos.logs.push(log);
        }
      }
    }
  }

  criarLog(idMenu: number, titulo: string = 'Padrão'): Log {
    const log: Log = new Log();
    log.idMenu = idMenu;
    log.titulo = titulo;
    return log;
  }

  criarDetalhe(transporteOuObjetos: any, log: Log): void {
    transporteOuObjetos.transporteItens.forEach((transporteItem) => {
      if (transporteItem.lista.length >= 0) {
        transporteItem.lista.forEach((objeto) => {
          log = this.compararObjeto(objeto, log);
        });
      } else {
        log = this.compararObjeto(transporteItem.lista, log);
      }
    });
  }

  compararObjeto(objetoAtual: any, log: Log): Log {
    Object.keys(objetoAtual).forEach((atributo) => {
      Object.values(this.objetoAnterior).forEach((objeto) => {
        if (atributo.toUpperCase() != 'DATAHORAINCLUSAO' && atributo.toUpperCase() != 'DATAHORAALTERACAO') {
          if (objetoAtual['id']) {
            log.operacao = 2;
          } else {
            log.operacao = 1;
          }
          if (objeto[atributo] != undefined && objetoAtual[atributo] != undefined && objetoAtual[atributo] != objeto[atributo]) {
            const logDetalhe: LogDetalhe = new LogDetalhe(atributo.toUpperCase(), objeto[atributo], objetoAtual[atributo]);
            objeto[atributo] = objetoAtual[atributo];
            log.logDetalhes.push(logDetalhe);
          }
        }
      });
    });
    return log;
  }

  definirUsuarioEmpresa(objetos: any[]): void {
    const usuario: Usuario = this.utilSessao.getUsuario();
    objetos.forEach((objeto) => {
      if (objeto.id == null) {
        objeto.idUsuarioInclusao = usuario.id;
        objeto.idEmpresa = usuario.idEmpresa;
      }
      objeto.idUsuarioAlteracao = usuario.id;
    });
  }

  private consumir(transporte: Transporte, url: string, validaUsuario: boolean = true, tempoEspera: number = 120000, mensagemErro: string = ''): Observable<any | null> {
    if (this.mostraCarregando) {
      setTimeout(() => this.utilSessao.iniciarProcesso());
    }
    this.pegarIpRede();
    return this.consumirEfetivo(transporte, url, validaUsuario, tempoEspera, mensagemErro);
  }

  private consumirEfetivo(transporte: Transporte, url: string, validaUsuario: boolean = true, tempoEspera: number = 120000, mensagemErro: string = ''): Observable<any | null> {
    if (validaUsuario) {
      const usuario: Usuario = this.utilSessao.getUsuario();
      transporte.email = usuario.email;
      transporte.token = this.utilCriptografia.criptografar(usuario.senha);
      transporte.serial = this.utilCriptografia.criptografar((Math.random() + 1).toString(36).substring(7));
      transporte.idEmpresa = usuario.idEmpresa;
      transporte.ipRede = this.ehIp(this.ipRede) ? this.ipRede : null;
    }
    const resposta: Observable<any> = this.http.post(url, JSON.stringify(transporte)).pipe(share()).pipe(timeout(tempoEspera));
    resposta.subscribe(
      () => {
        if (this.mostraCarregando) {
          this.utilSessao.finalizarProcesso();
        }
      },
      (error) => {
        if (mensagemErro != null && mensagemErro == '') {
          this.utilSessao.setResultado(new Resultado(false, bibDialogo.naoFoiPossivelConectarServidor));
        } else {
          this.utilSessao.setResultado(new Resultado(false, mensagemErro));
        }
        if (this.mostraCarregando) {
          this.utilSessao.finalizarProcesso();
        }
      });
    return resposta;
  }

  pegarIpRede(): void {
    if (this.utilSessao.getUsuario() && this.utilSessao.getUsuario().idGrupoAcesso != null && this.ehAtualizarIp()) {
      let resposta: Observable<any> = this.http.get<any>('https://api.ipify.org?format=json').pipe(timeout(10000));
      resposta.subscribe(
        (res) => {
          this.ipRede = res.ip;
          this.dataHoraIp = new Date();
        },
        (error) => {
          this.ipRede = null;
        }
      );
    }
  }

  ehAtualizarIp(): boolean {
    return this.dataHoraIp == null || differenceInHours(new Date(), this.dataHoraIp) > 1;
  }

  ehIp(ip: string): boolean {
    if (ip != null) {
      let quantidadePonto: number = 0;
      for (let i = 0; i < ip.length; i++) {
        if (ip[i] == '.') {
          quantidadePonto++
        }
      }
      return quantidadePonto == 3 && ip.length <= 15 && /\d/.test(ip);
    }
    return false;
  }

  verificarResultadoWeb(resposta: Observable<any>): Observable<any> {
    resposta.subscribe(
      () => {
        if (this.mostraCarregando) {
          this.utilSessao.finalizarProcesso();
        }
      },
      (error) => {
        this.utilSessao.setResultado(new Resultado(false, error.error.mensagem));
        if (this.mostraCarregando) {
          this.utilSessao.finalizarProcesso();
        }
      }
    );
    return resposta;
  }

  private modificarCriterios(criterios: Criterio[]): string {
    let filtroCriterio: string = '';
    criterios.forEach((criterio) => {
      filtroCriterio += `&${criterio.nome.toLowerCase()}=${criterio.valor}`;
    });
    return filtroCriterio;
  }
}
