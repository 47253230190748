import { forEach } from 'cypress/types/lodash';
import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import totais from './acessorio/total';
@Component({
  templateUrl: './r1009.component.html',
})
export class R1009Component extends RelatorioComponent {
  public filtros = filtros;
  public conciliacaoAgrupados: any[] = [];
  public tiposTituloAgrupados: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public maisInformacoes: boolean = false;
  public usaTipoTitulo: boolean = false;
  public usaTaxaCartao: boolean = false;
  public conciliacaoAgrupadosTodas: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.conciliacaoAgrupados = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ConciliacaoAgrupado').lista;
      this.conciliacaoAgrupadosTodas = this.clonarSemReferencia(this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ConciliacaoAgrupado').lista);
      this.ordenarAgrupar(this.conciliacaoAgrupados);
      if (this.usaTipoTitulo) {
        this.expandirTipoTitulo();
      }
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N'));
      this.ehImprimirRelatorio();
      this.atualizarTotalizador();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.maisInformacoes = this.definirRegra(relatorioAdicional, 172, this.maisInformacoes);
    this.usaTipoTitulo = this.definirRegra(relatorioAdicional, 173, this.usaTipoTitulo);
    this.usaTaxaCartao = this.definirRegra(relatorioAdicional, 244, this.usaTaxaCartao);
    this.atualizarTotalizador();
    if (this.usaTipoTitulo) {
      this.expandirTipoTitulo();
    }
    if (this.maisInformacoes) {
      this.conciliacaoAgrupados = this.clonarSemReferencia(this.conciliacaoAgrupadosTodas);
    } else {
      for (let conciliacaoAgrupado of this.conciliacaoAgrupados) {
        conciliacaoAgrupado.conciliacoes = this.clonarSemReferencia(this.conciliacaoAgrupadosTodas.find((conciliacaoAgrupadosToda) => conciliacaoAgrupadosToda.conta == conciliacaoAgrupado.conta).conciliacoes.filter((conciliacao) => conciliacao.conciliado == 'S'));
      }
    }
  }

  atualizarTotalizador(): void {
    this.posicionarTotalizador('receitaAConciliar', this.maisInformacoes, 0);
    this.posicionarTotalizador('despesaAConciliar', this.maisInformacoes, 0);
    this.posicionarTotalizador('saldo+receitaAConciliar-despesaAConciliar', this.maisInformacoes, 0);
    this.atualizarValorTotalizadores();
  }

  atualizarValorTotalizadores(): void {
    this.relatorioGrupo.totais.forEach((total) => {
      this.relatorioGrupo.somaResultados.forEach((soma) => {
        soma.forEach((_soma, index, array) => {
          const POSICAO_INICIAL_TOTALIZADORES: number = 2;
          if (index >= POSICAO_INICIAL_TOTALIZADORES) {
            const SUBTRAIR_TAXA_CARTAO: boolean = index == total.posicao && this.usaTaxaCartao && total.mostrar == false;
            const NAO_SUBTRAIR_TAXA_CARTAO: boolean = index == total.posicao && this.usaTaxaCartao == false && total.mostrar == true;
            if (SUBTRAIR_TAXA_CARTAO) {
              array[index] = total.total;
            }
            if (NAO_SUBTRAIR_TAXA_CARTAO) {
              array[index] = total.total;
            }
          }
        });
      });
    });
  }

  expandir(conciliacaoAgrupado: any, indice: number) {
    conciliacaoAgrupado.expandido = !conciliacaoAgrupado.expandido;
    if (conciliacaoAgrupado.expandido && (conciliacaoAgrupado.movimentacaoProdutos == null || conciliacaoAgrupado.movimentacaoProdutos.length == 0)) {
      this.listarEspecifico(new Criterio('ID_CONTA', conciliacaoAgrupado.id), 41).subscribe((res) => {
        conciliacaoAgrupado.conciliacoes = this.plainToClass(RelatorioResultado, res)[0].lista;
        this.conciliacaoAgrupadosTodas[indice].conciliacoes = this.plainToClass(RelatorioResultado, res)[0].lista;
        this.conciliacaoAgrupadosTodas[indice].expandido = !this.conciliacaoAgrupadosTodas[indice].expandido
        if (this.maisInformacoes == false) {
          conciliacaoAgrupado.conciliacoes = conciliacaoAgrupado.conciliacoes.filter((conciliacao) => conciliacao.conciliado == 'S');
        } else {
          this.conciliacaoAgrupados = this.clonarSemReferencia(this.conciliacaoAgrupadosTodas);
        }
      });
    }
  }

  expandirTipoTitulo() {
    this.listarEspecifico(null, 125).subscribe((res) => {
      this.tiposTituloAgrupados = this.plainToClass(RelatorioResultado, res)[0].lista;
    });
  }

  clonarSemReferencia(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      const cloneArray = [];
      for (const item of obj) {
        cloneArray.push(this.clonarSemReferencia(item));
      }
      return cloneArray;
    }
    const cloneObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        cloneObj[key] = this.clonarSemReferencia(obj[key]);
      }
    }
    return cloneObj;
  }

}
