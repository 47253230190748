import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Unidade } from 'src/app/modelo/unidade';

@Component({
  templateUrl: './unidadeFrm.component.html',
})
export class UnidadeFrmComponent extends PaginaComponent {
  public unidade: Unidade = new Unidade();
  public integracaoEidaf: boolean = false;
  public integracaoAgroDefesa: boolean = false;

  ngOnInit(): void {
    this.integracaoEidaf = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
    this.integracaoAgroDefesa = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO').length > 0 ? true : false;
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.unidade = this.plainToClass(Unidade, this.ehAtualizacao(this.unidade));
    if (this.unidade.id) {
      this.listarUnidade(this.unidade.id);
    }
  }

  listarUnidade(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.unidade).subscribe((res) => {
      this.unidade = this.plainToClass(Unidade, res[0]) as any;
    });
  }

  persistirUnidade(novo: boolean = false): void {
    if (this.ehValidoObrigatorio() && this.ehValidoAbreviacao()) {
      super.persistir(new Transporte(this.unidade), this.bibServico.unidade, novo ? new Unidade() : null);
    }
  }

  ehValidoAbreviacao(): boolean {
    if (this.unidade.abreviacao && this.unidade.abreviacao.length >= 2) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.abreviacaoMaxMin));
    return false;
  }

  ehValidoObrigatorio(): boolean {
    if (this.unidade.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
