import { Component, Input } from '@angular/core';
import { Lote } from 'src/app/agrow/modelo/lote';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { Estoque } from 'src/app/modelo/estoque';
import { Local } from 'src/app/modelo/local';
import { Loja } from 'src/app/modelo/loja';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { Transporte } from 'src/app/modelo/transporte';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'movimentacaoQuantidade',
  templateUrl: './movimentacaoQuantidade.component.html',
})
export class MovimentacaoQuantidadeComponent extends PaginaComponent {
  @Input() lojaLimitacoes: Loja[];
  public _estoques: Estoque[];
  public _locais: Local[];
  public _lotes: Lote[];
  public _movimentacaoControlador: MovimentacaoControlador;
  public _movimentacaoProduto: MovimentacaoProduto;
  public formaEntregas: any[] = [];
  public lojaLocais: any[] = [];
  public _indice: number;
  public simNao: any[] = [
    { id: 'S', nome: 'POSTERIORMENTE' },
    { id: 'N', nome: 'AGORA' }, //this.bibDialogo.nao
  ];
  @Input()
  set estoques(estoques: Estoque[]) {
    this._estoques = estoques;
    this.ehMontarLojaLocal();
    this.ehSetQuantidadeEstoqueDisponivel();
  }

  @Input()
  set locais(locais: Local[]) {
    this._locais = locais;
    this.ehMontarLojaLocal();
  }

  @Input()
  set lotes(lotes: Lote[]) {
    this._lotes = lotes;
    this.ehMontarLojaLocal();
    this.ehSetQuantidadeEstoqueDisponivel();
  }

  @Input()
  set movimentacaoControlador(movimentacaoControlador: MovimentacaoControlador) {
    this._movimentacaoControlador = movimentacaoControlador;
    this.ehMontarFormaEntrega();
  }

  @Input()
  set movimentacaoProduto(movimentacaoProduto: MovimentacaoProduto) {
    this._movimentacaoProduto = movimentacaoProduto;
    this.popularListas();

  }

  @Input()
  set indice(indice: number) {
    this._indice = indice;
    this.popularListas();
  }

  popularListas(): void {
    if (this._movimentacaoProduto && this._indice) {
      this.ehMontarFormaEntrega();
      this.ehMontarLojaLocal();
      this.ehSetQuantidadeEstoqueDisponivel();
    }
  }

  ehMontarLojaLocal(): void {
    if (this._estoques && this._locais && this._movimentacaoProduto && this._lotes) {
      for (let posicao = 0; posicao < this._movimentacaoProduto.movimentacaoQuantidades.length; posicao++) {
        this.montarLojaLocal(this._movimentacaoProduto.movimentacaoQuantidades[posicao].idLoja, posicao);
      }
    }
  }

  montarLojaLocal(idLoja: number, posicao: number): void {
    const filtroLojaLocais: Local[] = this._locais.filter((local) => local.idLoja == idLoja);
    if (this.lojaLocais[posicao]) {
      this.lojaLocais[posicao].filtroLojaLocais = filtroLojaLocais;
    } else {
      this.lojaLocais.push({ filtroLojaLocais: filtroLojaLocais });
    }
  }

  ehMontarFormaEntrega(): void {
    if (this._movimentacaoControlador && this._movimentacaoProduto) {
      for (let posicao = 0; posicao < this._movimentacaoProduto.movimentacaoQuantidades.length; posicao++) {
        const loja = this.utilSessao.getLojas().find((loja) => loja.id == this._movimentacaoProduto.movimentacaoQuantidades[posicao].idLoja);
        this.montarFormaEntrega(loja, posicao);
      }
    }
  }

  montarFormaEntrega(loja: Loja, posicao: number): void {
    let opcaoEntregas: any[] = [];
    let abreviacaoLoja: string = this.utilSessao.getLojas().find((loja) => loja.id == this._movimentacaoControlador.movimentacao.idLoja).abreviacao;
    if (loja.id == this._movimentacaoControlador.movimentacao.idLoja || !this._movimentacaoControlador.saida) {
      opcaoEntregas.push({ id: 'D', nome: bibDialogo.retirada + ' ' + loja.abreviacao });
    } else {
      opcaoEntregas.push({ id: 'T', nome: bibDialogo.transferir + ' ' + loja.abreviacao + ' ' + bibDialogo.para + ' ' + abreviacaoLoja });
      opcaoEntregas.push({ id: 'C', nome: bibDialogo.clienteIraBuscarNa + ' ' + loja.abreviacao });
      opcaoEntregas.push({ id: 'E', nome: loja.abreviacao + ' ' + bibDialogo.entregaParaCliente });
    }
    if (this.formaEntregas[posicao]) {
      this.formaEntregas[posicao].opcaoEntregas = opcaoEntregas;
    } else {
      this.formaEntregas.push({ opcaoEntregas: opcaoEntregas });
    }
  }

  ehSetQuantidadeEstoqueDisponivel(): void {
    if (this._estoques && this._movimentacaoProduto && this._lotes) {
      for (const movimentacaoQuantidade of this._movimentacaoProduto.movimentacaoQuantidades) {
        this.setQuantidadeEstoqueDisponivel(movimentacaoQuantidade);
      }
    }
  }

  setQuantidadeEstoqueDisponivel(movimentacaoQuantidade: MovimentacaoQuantidade): void {
    if (this._estoques != null) {
      const estoque = this._estoques.find((estoque) => estoque.idLoja == movimentacaoQuantidade.idLoja && estoque.idLocal == movimentacaoQuantidade.idLocal && estoque.idLote == movimentacaoQuantidade.idLote);
      movimentacaoQuantidade.quantidadeEstoqueDisponivel = estoque ? (this._movimentacaoControlador.nota ? estoque.quantidadeNfe : estoque.quantidadeDisponivel) : 0;
    } else {
      movimentacaoQuantidade.quantidadeEstoqueDisponivel = 0;
    }
  }

  setLoja(movimentacaoQuantidade: MovimentacaoQuantidade, loja: Loja, posicao: number): void {
    movimentacaoQuantidade.idLoja = loja.id;
    movimentacaoQuantidade.idLocal = null;
    if (this._movimentacaoControlador.movimentacao.idLoja != loja.id) {
      movimentacaoQuantidade.referencia = null;
    }
    this.montarFormaEntrega(loja, posicao);
    this.montarLojaLocal(loja.id, posicao);
    this.setQuantidadeEstoqueDisponivel(movimentacaoQuantidade);
  }

  setLocal(movimentacaoQuantidade: MovimentacaoQuantidade, idLocal: number): void {
    movimentacaoQuantidade.idLocal = idLocal;
    this.setQuantidadeEstoqueDisponivel(movimentacaoQuantidade);
  }

  setLote(movimentacaoQuantidade: MovimentacaoQuantidade, idLote: number): void {
    movimentacaoQuantidade.idLote = idLote;
    this.setQuantidadeEstoqueDisponivel(movimentacaoQuantidade);
  }

  setQuantidade(movimentacaoQuantidade: MovimentacaoQuantidade, quantidade: any): void {
    movimentacaoQuantidade.quantidade = quantidade;
    this.somarQuantidade();
  }

  somarQuantidade(): void {
    let quantidadeTotal: number = this._movimentacaoProduto.movimentacaoQuantidades.reduce((a, b) => a + b.quantidade, 0);
    this._movimentacaoProduto.setQuantidade(quantidadeTotal, null, this._movimentacaoControlador.movimentacao.mediaDiaPrazo, this._movimentacaoControlador.movimentacao.movimentacaoParcelas, this._movimentacaoControlador.movimentacao.valorTotal, this._movimentacaoControlador.saida);
    this._movimentacaoControlador.ratearDescontoMovimentacaoProduto(this._movimentacaoControlador.movimentacao.descontoValor, false);
    this._movimentacaoControlador.totalizar();
    this._movimentacaoControlador.verUsaMovimentacaoQuantidade(this._movimentacaoProduto);
  }

  excluirQuantidade(posicao: number, id: number): void {
    if (this._movimentacaoProduto.movimentacaoQuantidades.length > 1) {
      super.ehExcluir(posicao, this._movimentacaoProduto.movimentacaoQuantidades, this._movimentacaoControlador.excluirQuantidades, id);
      this.formaEntregas.splice(posicao, 1);
      this.somarQuantidade();
    } else {
      this.setQuantidade(this._movimentacaoProduto.movimentacaoQuantidades[0], 0);
      this._movimentacaoProduto.expandido = false;
    }
  }

  listarLocais(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('PAI', 'N')), this.bibServico.local).subscribe((res) => {
      this._locais = this.plainToClass(Local, res) as any;
      this.ehMontarLojaLocal();
    });
  }
}
