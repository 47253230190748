import { Component, ElementRef, ViewChild } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';

import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

import { format } from 'date-fns';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import filtros from './acessorio/filtro';

@Component({
  selector: 'r2019',
  templateUrl: './r2019.component.html',
  styleUrls: ['./r2019.component.css'],
})
export class R2019Component extends RelatorioComponent {
  @ViewChild('receitaBaixadaTipoTituloRef') receitaBaixadaTipoTituloRef: ElementRef;
  @ViewChild('receitaInadimplenteParceiroRef') receitaInadimplenteParceiroRef: ElementRef;
  @ViewChild('despesaNaturezaRef') despesaNaturezaRef: ElementRef;
  @ViewChild('receitaDespesaAnualRef') receitaDespesaAnualRef: ElementRef;
  public receitaBaixadaTipoTituloGrafico: any;
  public receitaInadimplenteParceiroGrafico: any;
  public despesaNaturezaGrafico: any;
  public receitaDespesaAnualGrafico: any;
  public bibPropriedade = bibPropriedade;
  public conexao: Conexao = new Conexao(this.utilSessao);
  public filtros = filtros;
  public criterios: Criterio[] = [];
  public idEmpresa: number = this.utilSessao.getEmpresa().id;
  public receitaDespesaBaixada: any;
  public receitaDespesaAberta: any;
  public receitaBaixadaTipoTitulos: any[];
  public receitaInadimplenteParceiros: any[];
  public despesaContaContabeis: any[];
  public receitaDespesaAnual: any;
  public receitaBaixadaDesconto: any;
  public saldo: any;
  public dataAtualizacao: Date;
  public intervaloAtualizarDashboard: any;
  public meses: String[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  public posicaoReceitaInadimplente: number;

  listarRelatorio(criterios: Criterio[]): void {
    this.subTitulo = format(new Date(), 'dd/MM/yyyy');
    this.criterios = criterios;
    super.listar(criterios).subscribe(() => {
      this.receitaDespesaBaixada = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ReceitaDespesaBaixada').lista[0];
      this.receitaDespesaAberta = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ReceitaDespesaAberta').lista[0];
      this.receitaBaixadaTipoTitulos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ReceitaBaixadaTipoTitulo').lista;
      this.receitaInadimplenteParceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ReceitaInadimplenteParceiro').lista;
      this.despesaContaContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'DespesaContaContabil').lista;
      this.receitaDespesaAnual = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ReceitaDespesaAnual').lista[0];
      this.receitaBaixadaDesconto = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ReceitaBaixadaDesconto').lista[0];
      this.saldo = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Saldo').lista[0];
      this.iniciarAtualizacaoAutomatica();
      this.criarGrafico();
    });
  }

  iniciarAtualizacaoAutomatica() {
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', function () {
      abaAtiva = abaAtiva == true ? false : true;
    });
    this.dataAtualizacao = new Date();
    clearInterval(this.intervaloAtualizarDashboard);
    this.intervaloAtualizarDashboard = setInterval(() => {
      if (abaAtiva) {
        this.listarRelatorio(this.criterios);
        this.dataAtualizacao = new Date();
      }
    }, 120000);
  }

  criarGrafico(): void {
    this.criarGraficoreceitaBaixadaTipoTitulo();
    this.criarGraficoreceitaInadimplenteParceiro();
    this.criarGraficoDespesaNatureza();
    this.criarGraficoReceitaDespesaAnual();
  }

  criarGraficoreceitaBaixadaTipoTitulo(): void {
    if (this.receitaBaixadaTipoTituloGrafico != null) {
      this.receitaBaixadaTipoTituloGrafico.destroy();
    }
    if (this.receitaBaixadaTipoTitulos.length > 0) {
      this.receitaBaixadaTipoTituloGrafico = new Chart(this.receitaBaixadaTipoTituloRef.nativeElement.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: this.receitaBaixadaTipoTitulos.map((receitaBaixadaTipoTitulo) => receitaBaixadaTipoTitulo.tipoTitulo),
          datasets: [
            {
              data: this.receitaBaixadaTipoTitulos.map((receitaBaixadaTipoTitulo) => receitaBaixadaTipoTitulo.valor),
              hoverOffset: 4,
              backgroundColor: this.cores,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }

  criarGraficoreceitaInadimplenteParceiro(): void {
    if (this.receitaInadimplenteParceiros.length > 0) {
      if (this.receitaInadimplenteParceiroGrafico != null) {
        this.receitaInadimplenteParceiroGrafico.destroy();
      }
      let valoresDias: any[] = [];
      for (const receitaInadimplenteParceiro of this.receitaInadimplenteParceiros) {
        valoresDias.push({ x: receitaInadimplenteParceiro.maximoDiaAtraso, y: receitaInadimplenteParceiro.valorRestante });
      }
      let $this = this;
      this.receitaInadimplenteParceiroGrafico = new Chart(this.receitaInadimplenteParceiroRef.nativeElement.getContext('2d'), {
        type: 'scatter',
        data: {
          datasets: [
            {
              data: valoresDias,
              backgroundColor: this.cores,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          onClick: function (c, i) {
            if (i[0] && i[0].index) {
              $this.posicaoReceitaInadimplente = i[0].index;
            } else {
              $this.posicaoReceitaInadimplente = null;
            }
          },
          onHover: function (e) {
            e.native.target.style.cursor = 'pointer';
          },
          onLeave: function (e) {
            e.native.target.style.cursor = 'default';
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Máximo dias de atraso',
              },
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      });
    }
  }

  criarGraficoDespesaNatureza(): void {
    if (this.despesaContaContabeis.length > 0) {
      if (this.despesaNaturezaGrafico != null) {
        this.despesaNaturezaGrafico.destroy();
      }
      this.despesaNaturezaGrafico = new Chart(this.despesaNaturezaRef.nativeElement.getContext('2d'), {
        type: 'bar',
        data: {
          labels: this.despesaContaContabeis.map((despesaNatureza) => despesaNatureza.contaContabil),
          datasets: [
            {
              axis: 'y',
              data: this.despesaContaContabeis.map((despesaNatureza) => despesaNatureza.valorFinal),
              hoverOffset: 10,
              backgroundColor: this.cores,
            },
          ],
        },
        options: {
          //maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          indexAxis: 'y',
        },
      });
    }
  }

  criarGraficoReceitaDespesaAnual(): void {
    if (this.receitaDespesaAnual != null) {
      if (this.receitaDespesaAnualGrafico != null) {
        this.receitaDespesaAnualGrafico.destroy();
      }
      const receitas: number[] = [this.receitaDespesaAnual.janeiroReceita, this.receitaDespesaAnual.fevereiroReceita, this.receitaDespesaAnual.marcoReceita, this.receitaDespesaAnual.abrilReceita, this.receitaDespesaAnual.maioReceita, this.receitaDespesaAnual.junhoReceita, this.receitaDespesaAnual.julhoReceita, this.receitaDespesaAnual.agostoReceita, this.receitaDespesaAnual.setembroReceita, this.receitaDespesaAnual.outubroReceita, this.receitaDespesaAnual.novembroReceita, this.receitaDespesaAnual.dezembroReceita];
      const despesas: number[] = [this.receitaDespesaAnual.janeiroDespesa, this.receitaDespesaAnual.fevereiroDespesa, this.receitaDespesaAnual.marcoDespesa, this.receitaDespesaAnual.abrilDespesa, this.receitaDespesaAnual.maioDespesa, this.receitaDespesaAnual.junhoDespesa, this.receitaDespesaAnual.julhoDespesa, this.receitaDespesaAnual.agostoDespesa, this.receitaDespesaAnual.setembroDespesa, this.receitaDespesaAnual.outubroDespesa, this.receitaDespesaAnual.novembroDespesa, this.receitaDespesaAnual.dezembroReceita];
      this.receitaDespesaAnualGrafico = new Chart(this.receitaDespesaAnualRef.nativeElement.getContext('2d'), {
        type: 'line',
        data: {
          labels: this.meses,
          datasets: [
            {
              label: this.bibDialogo.receita,
              data: receitas,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: ['#346CB0'],
              tension: 0.4,
              fill: true,
            },
            {
              label: this.bibDialogo.despesa,
              data: despesas,
              backgroundColor: 'rgba(235, 54, 54, 0.2)',
              borderColor: ['#b03434'],
              tension: 0.4,
              fill: true,
            },
          ],
        },
      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervaloAtualizarDashboard);
  }
}
