<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="(usaLote && usaLocal) ? 9 : usaLote ? 8 : usaLocal ? 8 : 7">
    <a *ngIf="estoqueHistoricoItens && estoqueHistoricoItens.length == 0">{{ bibDialogo.produtoSemMovimentacoes }}</a>
    <table *ngIf="estoqueHistoricoItens && estoqueHistoricoItens.length > 0" class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-1">{{ bibDialogo.data }}</th>
          <th class="r-1">{{ bibDialogo.numero }}</th>
          <th class="r-1">{{ bibDialogo.usuario }}</th>
          <th class="r">{{ bibDialogo.clienteFornecedor }}</th>
          <th class="r-1 text-right">{{ bibDialogo.saldoAnterior }}</th>
          <th class="r-1 text-right">{{ bibDialogo.entrada }}</th>
          <th class="r-1 text-right">{{ bibDialogo.saida }}</th>
          <th class="r-1 text-right">{{ bibDialogo.saldo }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let estoqueHistoricoItem of estoqueHistoricoItens">
          <tr class="conteudo">
            <td>{{ estoqueHistoricoItem.data | data }}</td>
            <td>{{ estoqueHistoricoItem.origem + ' ' + (estoqueHistoricoItem.numero ? estoqueHistoricoItem.numero : '') }}</td>
            <td>{{ estoqueHistoricoItem.usuarioAlteracao }}</td>
            <td>{{ estoqueHistoricoItem.parceiro ? estoqueHistoricoItem.parceiroNumero + ' - ' + estoqueHistoricoItem.parceiro : '' }}</td>
            <td class="text-right">{{ estoqueHistoricoItem.saldoAnterior | monetario: empresaRegraCasasDecimaisEntrada }}</td>
            <td class="text-right">{{ estoqueHistoricoItem.quantidade > 0 ? (estoqueHistoricoItem.quantidade | monetario: empresaRegraCasasDecimaisEntrada) : '' }}</td>
            <td class="text-right">{{ estoqueHistoricoItem.quantidade < 0 ? (estoqueHistoricoItem.quantidade * -1 | monetario: empresaRegraCasasDecimaisEntrada) : '' }}</td>
            <td class="text-right">{{ estoqueHistoricoItem.saldo | monetario: empresaRegraCasasDecimaisEntrada }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
