<layout [titulo]="grupo.nome" [id]="grupo.id">
  <div class="row">
    <lista [id]="'tipo'" [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.tipo" [campo]="tipoLista" [lista]="lista" (alteracao)="setLista($event.id)" [obrigatorio]="true" [foco]="true"></lista>
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome + ' ' + (tipoLista != null ? (tipoLista == 'N' ? this.rotuloPai.toLowerCase() : this.bibDialogo.sub.toLowerCase() + '-' + this.rotuloPai.toLowerCase()) : '')" [campo]="grupo.nome ? grupo.nome.trim() : null" (alteracao)="grupo.nome = $event" [obrigatorio]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="grupo.ativo" (alteracao)="grupo.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <busca [id]="'nomePai'" *ngIf="this.tipoLista == 'S'" [rotulo]="rotuloPai" [campo]="grupo.getPai()" (alteracao)="grupo.setIdPai($event.id)" [servico]="grupo.servico" [criterios]="criterioSemIdZero" [obrigatorio]="true"></busca>
  <div class="row">
    <lista [id]="'usoConsumo'" class="col-sm-6" [rotulo]="bibDialogo.usoConsumo" [campo]="grupo.usoConsumo" [lista]="listaSimNao" (alteracao)="grupo.usoConsumo = $event.id" [obrigatorio]="true" [ajuda]="bibDialogo.usoConsumoMensagem" [ajudaDireita]="true"></lista>
    <lista *ngIf="this.utilSessao.sistema == 'aGrow-w'" [id]="'bloqueiaSemInscricao'" class="col-sm-6" [rotulo]="bibDialogo.exigeInscricao" [campo]="grupo.bloqueiaSemInscricao" [lista]="listaSimNao" (alteracao)="grupo.bloqueiaSemInscricao = $event.id" [obrigatorio]="true" [ajuda]="bibDialogo.bloqueiaSemInscricaoAjuda" [ajudaDireita]="true"></lista>
  </div>
  <div *ngIf="grupo.pai != 'S' && this.permissaoProdutoImposto" class="mt-2">
    <atencao [atencao]="bibDialogo.atencao.toUpperCase()" [tipo]="bibPropriedade.atencao.alerta"></atencao><atencao [atencao]="bibDialogo.grupoAlteraProduto"></atencao>        
    <titulo [titulo]="bibDialogo.cfop + ' ' + bibDialogo.dentro.toLowerCase() + ' '  + bibDialogo.estado" [imagem]="bibImagem.cstcfop"></titulo>
    <div class="row">
      <lista class="col-sm-12" [id]="'cfopEntrada'" [rotulo]="bibDialogo.entrada" [campo]="grupo.idCfopEntrada" (alteracao)="grupo.idCfopEntrada = $event.id" [lista]="cfopsEmpresaEntrada" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
      <lista class="col-sm-12" [id]="'cfopSaida'" [rotulo]="bibDialogo.saida" [campo]="grupo.idCfopSaida" (alteracao)="grupo.idCfopSaida = $event.id" [lista]="cfopsEmpresaSaida" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
      <lista class="col-sm-12" [id]="'cfopTransferencia'" [rotulo]="bibDialogo.transferencia" [campo]="grupo.idCfopTransferencia" (alteracao)="grupo.idCfopTransferencia = $event.id" [lista]="cfopsEmpresaTransferencia" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
    </div>
    <titulo [titulo]="bibDialogo.cfop + ' ' + bibDialogo.fora.toLowerCase() + ' '  + bibDialogo.estado" [imagem]="bibImagem.cstcfop"></titulo>
    <div class="row">
      <lista class="col-sm-12" [id]="'cfopEntradaForaUf'" [rotulo]="bibDialogo.entrada" [campo]="grupo.idCfopEntradaForaUf" (alteracao)="grupo.idCfopEntradaForaUf = $event.id" [lista]="cfopsEmpresaEntrada" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
      <lista class="col-sm-12" [id]="'cfopSaidaForaUf'" [rotulo]="bibDialogo.saida" [campo]="grupo.idCfopSaidaForaUf" (alteracao)="grupo.idCfopSaidaForaUf = $event.id" [lista]="cfopsEmpresaSaida" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
      <lista class="col-sm-12" [id]="'cfopTransferenciaForaUf'" [rotulo]="bibDialogo.transferencia" [campo]="grupo.idCfopTransferenciaForaUf" (alteracao)="grupo.idCfopTransferenciaForaUf = $event.id" [lista]="cfopsEmpresaTransferencia" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()"></lista>
    </div>   
    <titulo [titulo]="bibDialogo.icmsDesonerado" [imagem]="bibImagem.icmsdesonerado"></titulo>
    <div class="row m-0 p-0">
      <check class="col-sm-3" [id]="'usaIcmsDesonerado'" [rotulo]="bibDialogo.usaIcmsDesonerado" [campo]="grupo.usaIcmsDesonerado" (alteracao)="grupo.usaIcmsDesonerado = grupo.usaIcmsDesonerado == 'S' ? 'N' : 'S'"></check>    
    </div>
    <titulo [titulo]="bibDialogo.cofins" [imagem]="bibImagem.cstcofins"></titulo>
    <div class="row">
      <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'" [rotulo]="bibDialogo.entrada" [id]="'cofinsEntrada'" [campo]="grupo.idCstCofinsEntrada" (alteracao)="grupo.idCstCofinsEntrada = $event.id" [lista]="cstsPisCofinsEntrada"></lista>
      <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'cofinsEntradaAliquota'" [campo]="grupo.cofinsAliquotaEntrada" (alteracao)="grupo.cofinsAliquotaEntrada = $event" [obrigatorio]="grupo.idCstCofinsEntrada != null" [sufixo]="'%'"></decimal>
    </div>
    <div class="row">
      <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'" [rotulo]="bibDialogo.saida" [id]="'cofinsSaida'" [campo]="grupo.idCstCofinsSaida" (alteracao)="grupo.idCstCofinsSaida = $event.id" [lista]="cstsPisCofinsSaida"></lista>
      <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'cofinsSaidaAliquota'" [campo]="grupo.cofinsAliquotaSaida" (alteracao)="grupo.cofinsAliquotaSaida = $event" [obrigatorio]="grupo.idCstCofinsSaida != null" [sufixo]="'%'"></decimal>
    </div>    
    <titulo [titulo]="bibDialogo.pis" [imagem]="bibImagem.cstpis"></titulo>
    <div class="row">
      <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'" [rotulo]="bibDialogo.entrada" [id]="'pisEntrada'" [campo]="grupo.idCstPisEntrada" (alteracao)="grupo.idCstPisEntrada = $event.id" [lista]="cstsPisCofinsEntrada"></lista>
      <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'pisEntradaAliquota'" [campo]="grupo.pisAliquotaEntrada" (alteracao)="grupo.pisAliquotaEntrada = $event" [obrigatorio]="grupo.idCstPisEntrada != null" [sufixo]="'%'"></decimal>
    </div>
    <div class="row">
      <lista [ngClass]="compacto ? 'col-sm-8' : 'col-sm-9'" [rotulo]="bibDialogo.saida" [id]="'pisSaida'" [campo]="grupo.idCstPisSaida" (alteracao)="grupo.idCstPisSaida = $event.id" [lista]="cstsPisCofinsSaida"></lista>
      <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'pisSaidaAliquota'" [campo]="grupo.pisAliquotaSaida" (alteracao)="grupo.pisAliquotaSaida = $event" [obrigatorio]="grupo.idCstPisSaida != null" [sufixo]="'%'"></decimal>
    </div>
    <titulo [titulo]="bibDialogo.ipi" [imagem]="bibImagem.cstipi"></titulo>
    <div class="row">
      <lista class="col-sm-5" [rotulo]="bibDialogo.entrada" [id]="'ipiEntrada'" [campo]="grupo.idIpiEntrada" (alteracao)="grupo.idIpiEntrada = $event.id" [lista]="cstIpiEntradas"></lista>
      <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'ipiEntradaAliquota'" [campo]="grupo.ipiAliquotaEntrada" (alteracao)="grupo.ipiAliquotaEntrada = $event" [obrigatorio]="grupo.idIpiEntrada != null" [sufixo]="'%'"></decimal>
      <texto class="col-sm" [rotulo]="bibDialogo.enquadramento" [id]="'ipiEnquadramentoEntrada'" [campo]="grupo.ipiEnquadramentoEntrada" (alteracao)="grupo.ipiEnquadramentoEntrada = $event" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="3" [ajuda]="bibDialogo.maximo3Caracteres"></texto>
    </div>
    <div class="row">
      <lista class="col-sm-5" [rotulo]="bibDialogo.saida" [id]="'ipiSaida'" [campo]="grupo.idIpiSaida" (alteracao)="grupo.idIpiSaida = $event.id" [lista]="cstIpiSaidas"></lista>
      <decimal class="col-sm" [rotulo]="bibDialogo.aliquota" [id]="'ipiSaidaAliquota'" [campo]="grupo.ipiAliquotaSaida" (alteracao)="grupo.ipiAliquotaSaida = $event" [obrigatorio]="grupo.idIpiSaida != null" [sufixo]="'%'"></decimal>
      <texto class="col-sm" [rotulo]="bibDialogo.enquadramento" [id]="'ipiEnquadramentoSaida'" [campo]="grupo.ipiEnquadramentoSaida" (alteracao)="grupo.ipiEnquadramentoSaida = $event" [tipo]="bibPropriedade.texto.numero" [maximoCaracteres]="3" [ajuda]="bibDialogo.maximo3Caracteres"></texto>
    </div>  
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirObjeto()" [desabilitado]="bloquearPermissao(grupo.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirObjeto(true)" [desabilitado]="bloquearPermissao(grupo.id)"></botao>
<auditoria *ngIf="grupo.id" [idUsuarioInclusao]="grupo.idUsuarioInclusao" [dataHoraInclusao]="grupo.dataHoraInclusao" [idUsuarioAlteracao]="grupo.idUsuarioAlteracao" [dataHoraAlteracao]="grupo.dataHoraAlteracao"></auditoria>
