import { Component } from '@angular/core';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { GrupoIcms } from 'src/app/modelo/grupoIcms';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ProdutoIcms } from 'src/app/modelo/produtoIcms';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './icmsFrm.component.html',
})
export class IcmsFrmComponent extends PaginaComponent {
  private classe: string;
  public classificacaoFiscais: ClassificacaoFiscal[] = [];
  public estados: Estado[] = [];
  public estadosOrigem: Estado[] = [];
  public icms: any;
  public tipo: number;
  public listaEnquadramento: any = [
    { id: '2', nome: this.bibDialogo.lucroPresumido.toUpperCase() },
    { id: '3', nome: this.bibDialogo.lucroReal.toUpperCase() },
    { id: '1', nome: this.bibDialogo.simplesNacional.toUpperCase() },
    { id: '4', nome: this.bibDialogo.simplesNacionalExcesso.toUpperCase() },
  ];

  ngOnInit(): void {
    this.listarEstado();
    this.tipo = this.utilSessao.getIdentificacao('tipo')?.conteudo;
    this.classe = this.utilSessao.getIdentificacao('classe')?.conteudo;
    this.getIcms();
    let enquadramentosLojas: string[] = this.utilSessao.getLojas().map((loja) => loja.enquadramento?.toString());
    this.listaEnquadramento = this.listaEnquadramento.filter((enquadramento) => enquadramentosLojas.includes(enquadramento.id));
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
      let idsEstadoLojas: string[] = this.utilSessao.getLojas().map((loja) => loja.idEstado.toString());
      this.estadosOrigem = this.estados.filter((estado) => idsEstadoLojas.includes(estado.id.toString()));
    });
  }

  listarClassificacaoFiscal(enquadramento: number): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('ENQUADRAMENTO', enquadramento));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.classificacaoFiscal).subscribe((res) => {
      this.classificacaoFiscais = this.plainToClass(ClassificacaoFiscal, res) as any;
    });
  }

  setEnquadramento(enquadramento: number): void {
    this.icms.enquadramento = enquadramento;
    this.listarClassificacaoFiscal(enquadramento);
    this.icms.idClassificacaoFiscal = null;
  }

  getIcms(): void {
    switch (this.classe) {
      case this.bibClasse.produtoIcms:
        this.icms = this.plainToClass(ProdutoIcms, this.utilSessao.getIdentificacao('icms')?.conteudo);
        if (this.icms == null) {
          this.icms = new ProdutoIcms();
        }
        this.listarClassificacaoFiscal(this.icms.enquadramento);
        break;
      case this.bibClasse.grupoIcms:
        this.icms = this.plainToClass(GrupoIcms, this.utilSessao.getIdentificacao('icms')?.conteudo);
        if (this.icms == null) {
          this.icms = new GrupoIcms();
        }
        this.listarClassificacaoFiscal(this.icms.enquadramento);
        break;
    }
    this.icms.tipo = this.tipo;
  }

  adicionarIcms(): void {
    if (this.ehValidoObrigatorio()) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.classe, this.icms));
    }
  }

  ehValidoObrigatorio(): boolean {
    this.icms.aliquotaJuridica = 0;
    this.icms.aliquotaFisica = 0;
    if (this.icms.estado && this.icms.estadoOrigem && this.icms.idClassificacaoFiscal) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEstado(icms: any, estado: Estado): void {
    icms.idEstado = estado.id;
    icms.estado = estado.abreviacao;
  }

  setEstadoOrigem(icms: any, estado: Estado): void {
    icms.idEstadoOrigem = estado.id;
    icms.estadoOrigem = estado.abreviacao;
  }

  setClassificacaoFiscal(icms: any, classificacaoFiscal: ClassificacaoFiscal): void {
    icms.idClassificacaoFiscal = classificacaoFiscal.id;
    icms.classificacaoFiscal = classificacaoFiscal.nome;
    icms.cstIcms = classificacaoFiscal.cstIcms.substring(0, 3);
  }
}
