import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'valorInicial', posicao: 3 },
  { campo: 'valorEntrada', posicao: 4 },
  { campo: 'valorSaida', posicao: 5 },
  { campo: 'valorFinal', posicao: 6 },
  { campo: 'valorCMV', posicao: 7 },
];

export default totais;
