import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';

const filtros = {
  filtros: plainToClass(Filtro, [
    { rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.numeroDocumento, nome: 'NUMERO_DOCUMENTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataBaixaDe, nome: 'DATA_BAIXA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.dataBaixaAte, nome: 'DATA_BAIXA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.conta, nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista },
    { rotulo: bibDialogo.tipoTitulo, nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.lista },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  ]),
};
export default filtros;
