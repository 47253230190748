import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.dataEntrega, campo: 'dataEntrega' },
  { rotulo: bibDialogo.dataNegociacao, campo: 'dataNegociacao' },
  { rotulo: bibDialogo.numero, campo: 'movimentacaoNumero' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.cliente, campo: 'parceiro' },
  { rotulo: bibDialogo.responsalvelEntrega, campo: 'responsavelEntrega' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
];
export default ordens;
