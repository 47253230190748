<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="8">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-0-5">{{ bibDialogo.loja }}</th>
          <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
          <th class="r-1">{{ bibDialogo.data }}</th>
          <th class="r">{{ bibDialogo.cliente }}</th>
          <th class="r-1 text-right">{{ bibDialogo.quantidade }}</th>
          <th class="r-3">{{ bibDialogo.vendedor }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let pedidoCarteira of pedidoCarteiras">
          <tr>
            <td>{{ pedidoCarteira.abreviacao }}</td>
            <td class="text-right">
              <a href="#" *ngIf="telaPedidoCarteira" (click)="irMovimentacao(pedidoCarteira.idMovimentacao)">
                {{ pedidoCarteira.numero }}
              </a>
              <ng-container *ngIf="telaPedidoCarteira == null">{{ pedidoCarteira.numero }}</ng-container>
            </td>            
            <td>{{ pedidoCarteira.data | data }}</td>
            <td>{{ pedidoCarteira.parceiroNumero + ' - ' + pedidoCarteira.parceiro }}</td>
            <td class="text-right">{{ pedidoCarteira.quantidadePendenteFaturamento | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
            <td>{{ pedidoCarteira.colaboradorNumero + ' - ' + pedidoCarteira.colaborador }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <br/>
  </td>
</ng-container>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>