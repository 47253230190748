import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UsuarioMenu } from 'src/app/modelo/usuarioMenu';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'usuarioMenusFrm',
  templateUrl: './usuarioMenusFrm.component.html',
})
export class UsuarioMenusFrmComponent extends PaginaComponent {
  @Input() usuarioMenus: UsuarioMenu[] = [];
  @Output() alteracao = new EventEmitter<any>();
  public campoPesquisa: string = this.bibDialogo.menu;
  public filtro: string = '';
  public listaCampoPesquisa: any[] = [
    { id: this.bibDialogo.menu, nome: this.bibDialogo.menu },
    { id: this.bibDialogo.semAcesso, nome: this.bibDialogo.semAcesso },
    { id: this.bibDialogo.comAcesso, nome: this.bibDialogo.comAcesso },
  ];

  setCampoPesquisa(valor: string): void {
    this.campoPesquisa = valor;
    if (this.campoPesquisa == this.bibDialogo.semAcesso || this.campoPesquisa == this.bibDialogo.comAcesso) {
      this.filtro = '';
    }
  }

  setUsuarioMenuAcessar(usuarioMenu: UsuarioMenu): void {
    usuarioMenu.acessar == 'S' ? this.alterarTodos(usuarioMenu, 'N') : (usuarioMenu.acessar = 'S');
    this.utilNotificacao.ocultaRoteiroContabil.emit(this.verificarPermissaoRoteiroContabil());
    this.utilNotificacao.ocultaConta.emit(this.verificarPermissaoConta());
  }

  alterarTodos(usuario: UsuarioMenu, valor: string): void {
    usuario.acessar = valor;
    usuario.editar = valor;
    usuario.inserir = valor;
    usuario.excluir = valor;
    usuario.inativar = valor;
    this.utilNotificacao.ocultaRoteiroContabil.emit(this.verificarPermissaoRoteiroContabil());
    this.utilNotificacao.ocultaConta.emit(this.verificarPermissaoConta());
    if (valor == 'N') usuario.favorito = valor;
  }

  setUsuarioMenuInserir(usuarioMenu: UsuarioMenu): void {
    usuarioMenu.inserir = usuarioMenu.inserir == 'S' ? 'N' : 'S';
  }

  setUsuarioMenuEditar(usuarioMenu: UsuarioMenu): void {
    usuarioMenu.editar = usuarioMenu.editar == 'S' ? 'N' : 'S';
  }

  setUsuarioMenuExcluir(usuarioMenu: UsuarioMenu): void {
    usuarioMenu.excluir = usuarioMenu.excluir == 'S' ? 'N' : 'S';
  }

  setUsuarioMenuFavorito(usuarioMenu: UsuarioMenu): void {
    usuarioMenu.favorito = usuarioMenu.favorito == 'S' ? 'N' : 'S';
  }

  setUsuarioMenuInativar(usuarioMenu: UsuarioMenu): void {
    usuarioMenu.inativar = usuarioMenu.inativar == 'S' ? 'N' : 'S';
  }

  verificarPermissaoRoteiroContabil(): boolean {
    const idsMenusUtilizamRoteiroContabil: number[] = [167, 157, 133];
    const permissaoRoteiroContabil: UsuarioMenu[] = this.usuarioMenus.filter((usuarioMenu) => idsMenusUtilizamRoteiroContabil.includes(usuarioMenu.idMenu) && usuarioMenu.acessar == 'S');
    if (permissaoRoteiroContabil.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  verificarPermissaoConta(): boolean {
    const idsMenusUtilizamConta: number[] = [93, 104, 166, 110, 49, 33, 29, 28, 41, 42, 115, 159, 158, 39, 30, 114, 130];
    const permissaoConta: UsuarioMenu[] = this.usuarioMenus.filter((usuarioMenu) => idsMenusUtilizamConta.includes(usuarioMenu.idMenu) && usuarioMenu.acessar == 'S');
    if (permissaoConta.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  permitirAcessoModulo(modulo: String) {
    let usuarioMenuModulos: UsuarioMenu[] = this.usuarioMenus.filter((usuarioMenu) => usuarioMenu.modulo == modulo);
    for (const usuarioMenu of usuarioMenuModulos) {
      this.alterarTodos(usuarioMenu, 'S');
    }
  }
}
