import { Component } from '@angular/core';
import { ConfiguracaoFinanceiroContabil } from 'src/app/modelo/configuracaoFinanceiroContabil';
import { ConfiguracaoMovimentacaoContabil } from 'src/app/modelo/configuracaoMovimentacaoContabil';
import { ConfiguracaoTransferenciaContabil } from 'src/app/modelo/configuracaoTransferenciaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './configuracaoContabil.component.html',
})
export class ConfiguracaoContabilComponent extends PaginaComponent {
  private classe: any;
  public filtros: Filtro[] = filtros;
  public objetos: any[] = [];
  public servico: string;

  ngOnInit(): void {
    this.objetos = [];
    this.permissao = this.utilSessao.getPermissao();
    switch (this.permissao.servico) {
      case this.bibServico.configuracaoMovimentacaoContabil:
        this.classe = ConfiguracaoMovimentacaoContabil;
        this.servico = this.bibServico.configuracaoMovimentacaoContabil;
        break;
      case this.bibServico.configuracaoFinanceiroContabil:
        this.classe = ConfiguracaoFinanceiroContabil;
        this.servico = this.bibServico.configuracaoFinanceiroContabil;
        break;
      case this.bibServico.configuracaoTransferenciaContabil:
        this.classe = ConfiguracaoTransferenciaContabil;
        this.servico = this.bibServico.configuracaoTransferenciaContabil;
        break;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.servico).subscribe((res) => {
      this.objetos = this.plainToClass(this.classe, res) as any;
    });
  }

  excluirObjeto(id: number, nome: string): void {
    super.excluir(id, this.objetos, nome);
  }

  irPara(id: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('id', id));
    let url: string = this.permissao.tela + 'Frm';
    this.router.navigate([url]);
  }
}
