import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001MovimentacaoManual',
  templateUrl: './r2001MovimentacaoManual.component.html',
})
export class R2001MovimentacaoManualComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacaoManuais: any[];
  @Input() movimentacaoManualApelido: string;
  public bibDialogo = bibDialogo;
}
