<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="10">
    <table *ngIf="conciliacaoAgrupado.conciliacoes" class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.cliente }}</th>
          <th class="r-2">{{ bibDialogo.tipoTitulo }}</th>
          <th class="r-1-5 text-right">{{ bibDialogo.receita }}</th>
          <th class="r-1-5 text-right">{{ bibDialogo.despesa }}</th>
          <th class="r-1-5">{{ bibDialogo.responsavel }}</th>
          <th *ngIf="maisInformacoes" class="r-1-5 text-right">{{ bibDialogo.receitaAConciliar }}</th>
          <th *ngIf="maisInformacoes" class="r-1-5 text-right">{{ bibDialogo.despesaAConciliar }}</th>
          <th *ngIf="maisInformacoes" class="r-1-5">{{ bibDialogo.dataConciliacao }}</th>
          <th class="r-1"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let conciliacao of conciliacaoAgrupado.conciliacoes; let i = index">
          <tr>
            <td>{{ conciliacao.origemDestino }}</td>
            <td>{{ conciliacao.tipoTitulo }}</td>
            <td class="text-right">{{ conciliacao.receita != 0 ? ((!usaTaxaCartao ? conciliacao.receita : conciliacao.receitaValorLiquido) | monetario) : '' }}</td>
            <td class="text-right">{{ conciliacao.despesa != 0 ? ((!usaTaxaCartao ? conciliacao.despesa : conciliacao.despesaValorLiquido) | monetario) : '' }}</td>
            <td>{{ conciliacao.usuario }}</td>
            <td *ngIf="maisInformacoes" class="text-right">{{ conciliacao.receitaAConciliar != 0 ? ((!usaTaxaCartao ? conciliacao.receitaAConciliar : conciliacao.receitaAConciliarValorLiquido) | monetario) : '' }}</td>
            <td *ngIf="maisInformacoes" class="text-right">{{ conciliacao.despesaAConciliar != 0 ? ((!usaTaxaCartao ? conciliacao.despesaAConciliar : conciliacao.despesaAConciliarValorLiquido) | monetario) : '' }}</td>
            <td *ngIf="maisInformacoes">{{ conciliacao.dataConciliacao | data }}</td>
            <td><ajuda class="naoImprimir" *ngIf="conciliacao.observacao" [ajuda]="conciliacao.observacao"></ajuda></td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <th class="sem-borda">
            <span class="font-weight-bold border-dark border-top">{{ conciliacaoAgrupado.conciliacoes.length }}</span>
          </th>
        </tr>
      </tfoot>
    </table>
  </td>
</ng-container>
