export class RelatorioAdicionalEmpresa {
  public alterado: boolean;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idRelatorio: number;
  public idRelatorioAdicional: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public marcado: string;
  public oculto: string = 'N';
}
