import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { Usuario } from 'src/app/modelo/usuario';
import { Util } from 'src/app/utilitario/util';
import bibDialogo from '../../biblioteca/bibDialogo';
import { UtilSessao } from '../../utilitario/util.sessao';

@Component({
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css'],
})
export class PrincipalComponent {
  private util: Util = new Util();
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibPropriedade = bibPropriedade;
  public lojaAbreviacao: string;
  public usuarioNome: string;
  public idRelatorioInterno: number;
  public cliqueManagerSas: number = 0;

  constructor(private titleService: Title, public utilSessao: UtilSessao) {
    this.montarNome();
  }

  ngOnInit(): void {
    const sistema: string = this.utilSessao.sistema.indexOf("aGrow") >= 0 ? 'aGrow+' : this.utilSessao.sistema;
    this.titleService.setTitle(sistema);
    this.utilSessao.setPermissao(null);
    this.lojaAbreviacao = this.util.getEhAbreviacaoLoja(true, '');
    this.lerDashboardPrincipal();
  }

  lerDashboardPrincipal() {
    this.idRelatorioInterno = this.utilSessao.getDashboardUsuarios().find((relatorio) => relatorio.dashboardInicial == 'S')?.idRelatorio;
    this.utilSessao.idRelatorioInterno = this.idRelatorioInterno;
  }

  montarNome(): void {
    const usuario: Usuario = this.utilSessao.getUsuario();
    if (usuario) {
      let indice: number = usuario.nome.indexOf(' ');
      let primeiraLetra: string = usuario.nome.substr(0, 1).toUpperCase();
      let restante: string = usuario.nome.substr(1, indice != -1 ? indice - 1 : usuario.nome.length).toLowerCase();
      this.usuarioNome = primeiraLetra + restante;
    }
  }

  irSuporteTecnospeed(): void {
    const senha: string = '2024AGR@';
    this.util.copiar(senha);
    window.open('https://tecnospeed.zendesk.com/auth/v2/login/signin?return_to=https%3A%2F%2Fatendimento.tecnospeed.com.br%2Fhc%2Fpt-br&theme=hc&locale=pt-br&brand_id=360000386474&auth_origin=360000386474%2Ctrue%2Ctrue', "_blank");
  }

  irManageSas(): void {
    this.cliqueManagerSas++;
    this.util.copiar(this.cliqueManagerSas == 1 ? 'PLUG_09647414000181' : this.cliqueManagerSas == 2 ? 'admin' : 'C39E2D14BDE86C1A85B864431454EA4E');
    if (this.cliqueManagerSas == 1) {
      window.open('https://managersaas.tecnospeed.com.br:8081/', "_blank");
    }
    this.cliqueManagerSas = this.cliqueManagerSas > 3 ? 0 : this.cliqueManagerSas;
  }

}
