<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.totalReceberPagar }}</h1>
<table>
  <thead>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-2 text-right">{{ bibDialogo.receitaAmanha }}</th>
    <th class="r-2 text-right">{{ bibDialogo.receitaGeral }}</th>
    <th class="r-2 text-right">{{ bibDialogo.despesaAmanha }}</th>
    <th class="r-2 text-right">{{ bibDialogo.despesaGeral }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let financeiroReceitaDespesa of financeiroReceitaDespesas">
      <tr>
        <td>{{ financeiroReceitaDespesa.loja }}</td>
        <td class="text-right">{{ financeiroReceitaDespesa.receitaAmanha | monetario }}</td>
        <td class="text-right">{{ financeiroReceitaDespesa.receitaGeral | monetario }}</td>
        <td class="text-right">{{ financeiroReceitaDespesa.despesaAmanha | monetario }}</td>
        <td class="text-right">{{ financeiroReceitaDespesa.despesaGeral | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="financeiroReceitaDespesas.length > 0">
    <tr>
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ financeiroReceitaDespesas[0].totalReceitaAmanha | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ financeiroReceitaDespesas[0].totalReceitaGeral | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ financeiroReceitaDespesas[0].totalDespesaAmanha | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ financeiroReceitaDespesas[0].totalDspesaGeral | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
