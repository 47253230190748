export class EmpresaFilial {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idParceiroFilial: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nomeFilial: string;
  public cnpjFilial: string;
}
