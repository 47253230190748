<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r clique" (click)="ordenar(produtos, 'produto')">{{ bibDialogo.produto }}</th>
      <th class="r-1 clique" (click)="ordenar(produtos, 'unidade')">{{ bibDialogo.unidade }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'quantidadeEntrada')">{{ bibDialogo.entrada }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'quantidadeSaida')">{{ bibDialogo.saida }}</th>
      <th *ngIf="produtos.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <tr class="conteudo" [ngClass]="produto.expandido ? 'sem-borda' : ''">
          <td>{{ produto.numeroProduto + ' - ' + produto.produto }}</td>
          <td>{{ produto.unidade }}</td>
          <td class="text-right">{{ produto.quantidadeEntrada | monetario }}</td>
          <td class="text-right">{{ produto.quantidadeSaida | monetario }}</td>
          <td class="naoImprimir" *ngIf="produtos.length > 0"><btnAdicional [icone]="produto.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="expandir(produto)"></btnAdicional></td>
        </tr>
        <tr *ngIf="produto.expandido" r2017itens [produtosMovimentacoes]="produto.produtosMovimentacoes"></tr>
        <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" class="sem-borda"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="produtos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
