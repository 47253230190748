import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1127.component.html',
})
export class R1127Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public comissoes: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public filtroSituacao: string;
  public componente: any;
  public usaExpandido: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.comissoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Comissao').lista;
      this.ordenarAgrupar(this.comissoes);
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N'));
      this.ehImprimirRelatorio();
    });
  }

  listarProdutos(comissao: any) {
    comissao.expandido = !comissao.expandido;
    if (comissao.expandido && (comissao.comissaoProduto == null || comissao.comissaoProduto.length == 0)) {
      this.listarEspecifico(new Criterio('ID_MOVIMENTACAO', comissao.idMovimentacao), 203).subscribe((res) => {
        comissao.comissaoProduto = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

  expandir(): void {
    this.comissoes.forEach((comissao) => {
      comissao.expandido = false;
      if (this.usaExpandido) {
        this.listarProdutos(comissao);
      }
    })
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaExpandido = this.definirRegra(relatorioAdicional, 270, this.usaExpandido);
    this.expandir();
  }

  abrirModalMovimentacao(id: number): void {
    this.componente = this.abrirModalRelatorio(MovimentacaoFrmComponent, [new Identificacao('objeto', id)], null);
  }

  agruparInterno(lista: any[], agrupa: any): void {
    this.agrupar(lista, agrupa);
    if (this.rotuloAgrupaAtual == this.bibDialogo.vendedor || this.rotuloAgrupaAtual == this.bibDialogo.cliente) {
      this.posicionarTotalizadores(-1);
    } else {
      this.posicionarTotalizadores(0);
    }
  }
}
