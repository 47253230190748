<layout [titulo]="bibNomenclatura.escrever(bibNomenclatura.destino)" [id]="parceiroDestino.id">
  <botao [compacto]="compacto" [legenda]="bibDialogo.copiarEnderecoPrincipal" (botaoEmt)="setEnderecoParceiroDestino()" (saindo)="focoId = $event"></botao>
  <enderecoCep [objeto]="parceiroDestino" [tipoVinculo]="tipoVinculo" [mostrarVinculo]="false" [focoCep]="focoId"></enderecoCep>
  <texto [id]="'nomeDestino'" [rotulo]="bibDialogo.nome" [campo]="parceiroDestino.nome" (alteracao)="parceiroDestino.nome = $event" [obrigatorio]="true" [maximoCaracteres]="50"></texto>
  <texto [id]="'observacao'" [rotulo]="bibDialogo.observacao" [campo]="parceiroDestino.observacao" (alteracao)="parceiroDestino.observacao = $event" [maximoCaracteres]="250"></texto>
  <div class="row">
    <lista class="col-sm" [rotulo]="bibDialogo.tipo" [campo]="parceiroDestino.tipoPessoa" (alteracao)="setTipoPessoa($event.id)" [lista]="listaPessoas"></lista>
    <texto class="col-sm" [rotulo]="parceiroDestino.tipoPessoa == 'F' ? bibDialogo.inscricaoProdutor : bibDialogo.inscricaoEstadual" [campo]="parceiroDestino.inscricaoEstadual" (alteracao)="parceiroDestino.inscricaoEstadual = $event" [maximoCaracteres]="20"></texto>
    <mascara [id]="'cpfDestino'" class="col-sm" [rotulo]="parceiroDestino.tipoPessoa == 'F' ? bibDialogo.cpf : bibDialogo.cnpj" [campo]="parceiroDestino.cnpjCpf" (alteracao)="setCnpjCpf(parceiroDestino, $event)" [obrigatorio]="obrigaCpfCnpj == 1 ? true : false" [tipo]="parceiroDestino.tipoPessoa == 'F' ? bibPropriedade.mascara.cpf : bibPropriedade.mascara.cnpj" [atencao]="!parceiroDestino.ehValidoCnpjCpf && parceiroDestino.cnpjCpf ? bibDialogo.incorreto : null" [icone]="bibIcone.clone" [ajuda]="ajuda" (btnAdicional)="setCnpjCpfParceiroDestino()"></mascara>  </div>
  <div *ngIf="utilSessao.sistema != 'Sigescom-w'">
    <decimal class="col-sm-4" [rotulo]="bibDialogo.area" [campo]="parceiroDestino.area"  (alteracao)="setArea(parceiroDestino, $event)" [sufixo]="'HA'"></decimal>
    <parceiroDestinoPropriedade [parceiroDestino]="parceiroDestino"></parceiroDestinoPropriedade>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirParceiroDestino()" (saindo)="focoId = !parceiroDestino.latitude || !parceiroDestino.longitude ? $event : focoId"></botao>
<botao *ngIf="parceiroDestino.latitude && parceiroDestino.longitude" [compacto]="compacto" [legenda]="bibDialogo.mostrarNoMapa" (botaoEmt)="abrirMapa()" (saindo)="focoId = $event"></botao>
<auditoria *ngIf="parceiroDestino.id" [idUsuarioInclusao]="parceiroDestino.idUsuarioInclusao" [dataHoraInclusao]="parceiroDestino.dataHoraInclusao" [idUsuarioAlteracao]="parceiroDestino.idUsuarioAlteracao" [dataHoraAlteracao]="parceiroDestino.dataHoraAlteracao"></auditoria>
