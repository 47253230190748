import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboUnidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 9, tipo: bibPropriedade.filtro.texto },
  { id: 'abreviacao', rotulo: bibDialogo.abreviacao, nome: 'ABREVIACAO', coluna: 3, tipo: bibPropriedade.filtro.texto },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboUnidade },
]);
export default filtros;
