export class Criterio {
  public apresentacao: any;
  public idIntegracao: number;
  public idMenu: number;
  public idRelatorio: number;
  public manual: string;
  public nome: string;
  public rotulo: string;
  public tipo: number;
  public valor: any;
  public visualizacaoInicial: boolean;

  constructor(nome: string = null, valor: any = null, idMenu: number = null, idRelatorio: number = null, rotulo: string = null, apresentacao: any = null, tipo: number = null, idIntegracao: number = null, manual: string = 'N', visualizacaoInicial: boolean = true) {
    this.nome = nome;
    this.valor = valor;
    this.idMenu = idMenu;
    this.idRelatorio = idRelatorio;
    this.rotulo = rotulo;
    this.apresentacao = apresentacao;
    this.tipo = tipo;
    this.idIntegracao = idIntegracao;
    this.manual = manual;
    this.visualizacaoInicial = visualizacaoInicial;
  }
}
