<layout [id]="entrega.id ? entrega.id : null" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <apresentacao class="col-sm" [rotulo]="bibDialogo.numeroAbreviado + ' ' + bibNomenclatura.escrever(bibNomenclatura?.movimentacao).toLowerCase()" [campo]="movimentacao.numero"></apresentacao>
    <apresentacao class="col-sm" [rotulo]="bibDialogo.data" [campo]="movimentacao.data | data"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm" [rotulo]="bibDialogo.loja" [campo]="movimentacao.loja"></apresentacao>
    <apresentacao class="col-sm" [rotulo]="bibDialogo.vendedor" [campo]="movimentacao.colaborador"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.cliente" [campo]="movimentacao.parceiro"></apresentacao>
    <busca class="col-sm-12" [rotulo]="bibDialogo.responsalvelEntrega" [campo]="colaborador" (alteracao)="setColaborador($event)" [focoId]="focoBuscaResponsavel" [servico]="bibServico.colaborador" [criterios]="criteriosResponsavel" [obrigatorio]="true" [desabilitado]="confirmada || (!movimentacao.permiteEntrega && movimentacao.permitirEntregaDireta != 'S')"></busca>
  </div>
  <div class="row justify-content-end">
    <botao [compacto]="compacto" [legenda]="bibDialogo.entregarTodos" (botaoEmt)="entregarTodos()" [desabilitado]="confirmada || (movimentacao.permiteEntrega == false && movimentacao.permitirEntregaDireta == 'N' && movimentacao.financeiro != 'N')"></botao>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-50">{{ bibDialogo.produto }}</th>
        <th class="l-5">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="l-5">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right">{{ bibDialogo.comprado }}</th>
        <th class="l-10 text-right">{{ bibDialogo.disponivel }}</th>
        <th class="l-10 text-right">{{ bibDialogo.pendente }}</th>
        <th class="l-10 text-right">{{ bibDialogo.quantidade }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos; let j = index">
        <tr [ngClass]="[movimentacao.ativo != 'S' ? 'tachado' : '', quantidadeFocada && j == posicaoProduto ? 'bg-amarelo-claro' : '']" *ngFor="let movimentaoQuantidade of movimentacaoProduto.movimentacaoQuantidades; let i = index">
          <td class="limitar">
            {{ movimentacaoProduto.numeroProduto + ' - ' + movimentacaoProduto.produto }} <br />
            <span class="fonte-menor">{{ (movimentacaoProduto.grupo ? movimentacaoProduto.grupo : '') + (movimentacaoProduto.grupo && movimentacaoProduto.categoria ? ' - ' : ' ') }} </span> <span [ngClass]="movimentacaoProduto.categoria != categoria ? 'fonte-menor' : 'fonte-categoria'">{{ movimentacaoProduto.categoria ? movimentacaoProduto.categoria : '' }}</span>
          </td>
          <td>{{ movimentacaoProduto.abreviacao }}</td>
          <td>{{ movimentaoQuantidade.lojaAbreviacao }}</td>
          <td class="text-right">{{ movimentaoQuantidade.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right" [ngClass]="movimentacaoProduto.quantidadeDisponvel > 0 ? '' : 'font-weight-bold text-danger'">{{ movimentacaoProduto.quantidadeDisponvel | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ movimentaoQuantidade.quantidadePendente | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">
            <decimal [id]="'quantidade' + i" [desabilitado]="confirmada || movimentaoQuantidade.quantidadePendente == 0 || (movimentacao.permiteEntrega == false && movimentacao.permitirEntregaDireta == 'N' && movimentacao.financeiro != 'N')" [id]="'P' + j + '_' + i" [campo]="movimentaoQuantidade.quantidadeSepararEntrega" (click)="posicaoProduto = j; posicaoQuantidade = i" (alteracao)="movimentaoQuantidade.quantidadeSepararEntrega = $event" [focoId]="indiceFoco == 'p' + j + '_' + i" (keypress)="setQuantidadeEntregue($event, j, i)" (navegacao)="setPosicao($event, j, i)" [numeroCasasDecimais]="4"></decimal>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <descricao [rotulo]="bibDialogo.observacao" [campo]="observacao" (alteracao)="observacao = $event" [desabilitado]="confirmada" [maximoCaracteres]="200" [focoId]="focoObservacao"></descricao>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="row">
    <botao class="col" [id]="'salvar'" [compacto]="compacto" [legenda]="bibDialogo.finalizar" [fundo]="true" [desabilitado]="confirmada || (movimentacao.permiteEntrega == false && movimentacao.permitirEntregaDireta == 'N' && movimentacao.financeiro != 'N')" (botaoEmt)="exigirSenha()"></botao>
    <texto *ngIf="focoIdSenha && utilSessao.getUsuario()?.agrow == 'N'" [id]="'senhaOperacional'" [ngClass]="compacto ? 'mt-2' : ''" [descricao]="bibDialogo.senhaOperacional" (navegacao)="$event == false ? (focoIdSenha = null) : ''" [tipo]="bibPropriedade.texto.senha" (alteracao)="conferirSenha($event)" [texto]="bibDialogo.senha" [focoId]="focoIdSenha" autocomplete="new-password"></texto>
  </div>
  <div class="row p-0 m-0 j ustify-content-end">
    <atencao [atencao]="bibDialogo.teclaEspacoQuantidadeRestante"></atencao>
  </div>
</div>
<bloqueado [bloqueado]="confirmada || (movimentacao.permiteEntrega == false && movimentacao.permitirEntregaDireta == 'N' && movimentacao.financeiro != 'N')"></bloqueado>
