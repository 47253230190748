import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibServico from 'src/app/biblioteca/bibServico';
import { Hierarquia } from './hierarquia';

export class CentroResultado implements Hierarquia {
  private pai: string = 'N';
  public ativo: string = 'S';
  public bibDialogo = bibDialogo;
  public bibServico = bibServico;
  public centroResultadoPai: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idCentroResultadoPai: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public rotulo: string = this.bibDialogo.centroResultadoPai;
  public servico: string = this.bibServico.centroResultado;

  setIdPai(idPai: number) {
    this.idCentroResultadoPai = idPai;
  }

  getIdPai(): number {
    return this.idCentroResultadoPai;
  }

  getPai(): string {
    return this.centroResultadoPai;
  }
}
