import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoGuard } from 'src/app/guardas/autenticacao.guard';
import { CidadeComponent } from './cidade.component';
import { CidadeFrmComponent } from './cidadeFrm.component';

const routes: Routes = [
  {
    path: '',
    component: CidadeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'Frm',
    component: CidadeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CidadeRoutingModule {}
