<layout [titulo]="orgao + agronomo" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div class="row justify-content-between">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
    <span *ngIf="dataAtualizacao" class="text-uppercase">{{ bibDialogo.dataAtualizacao + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm:ss') }} </span>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th *ngIf="ehEidaf" class="l-10">{{ bibDialogo.assinatura }}</th>        
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-5 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviado + ' ' + bibDialogo.fiscal }}</th>
        <th class="limitar l-10 text-right clique" (click)="listar(add('receita'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviado + ' ' + bibDialogo.receita }}</th>        
        <th class="l-20 clique limitar" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="limitar clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parceiro }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let receituarioEletronicoGrade of receituariosEletronicoGrade; let i = index">
        <tr>
          <td>
            <check *ngIf="receituarioEletronicoGrade?.transmitido != 'S'" (alteracao)="setReceituarioEletronicoSelecionado(receituarioEletronicoGrade)"></check>
          </td>
          <td>
              <icone [icone]="bibIcone.circulo" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="receituarioEletronicoGrade?.cancelado == 'S' ? bibPropriedade.icone.preto : receituarioEletronicoGrade?.transmitido == 'S' ? bibPropriedade.icone.verde : receituarioEletronicoGrade?.enviando == 'S' ? bibPropriedade.icone.amarelo : receituarioEletronicoGrade?.enviando != 'N' ? bibPropriedade.icone.vermelho : bibPropriedade.icone.cinza" [mensagem]="receituarioEletronicoGrade?.cancelado == 'S' ? bibDialogo.cancelada : receituarioEletronicoGrade?.transmitido == 'S' ? bibDialogo.transmitido : receituarioEletronicoGrade?.enviando != 'E' ? bibDialogo.aguardandoEnvio : bibDialogo.aguardandoEnvio" [ajudaDireita]="true"></icone>
          </td>
          <td *ngIf="ehEidaf"><lista [campo]="receituarioEletronicoGrade.receituarioEletronico?.tipoAssinatura" [lista]="assinaturaEidaf" (alteracao)="receituarioEletronicoGrade.receituarioEletronico.tipoAssinatura = $event.id" [obrigatorio]="receituarioEletronicoGrade?.selecionado == 'S' ? true : false" [desabilitado]="receituarioEletronicoGrade?.selecionado == 'N' || receituarioEletronicoGrade.receituarioEletronico?.enviado == 'S'"></lista></td>
          <td>{{ receituarioEletronicoGrade.data | data }}</td>
          <td class="text-right"><a href="#" (click)="abrirModalMovimentacao(receituarioEletronicoGrade.idMovimentacao)">{{ receituarioEletronicoGrade.numero }}</a></td>          
          <td class="text-right"><a href="#" (click)="abrirModalReceituario(receituarioEletronicoGrade.idMovimentacao)">{{ receituarioEletronicoGrade.receita }}</a></td>                    
          <td class="limitar">{{ receituarioEletronicoGrade.loja }}</td>
          <td class="limitar">{{ receituarioEletronicoGrade.nomeParceiro }}</td>
          <ng-container>
            <td *ngIf="receituarioEletronicoGrade?.enviando != 'N'"><btnAdicional [icone]="bibIcone.lupa" [ajuda]="bibDialogo.consultarReceituarioEletronico" (btnAdicional)="consultarReceituarioEletronico(receituarioEletronicoGrade)"></btnAdicional></td>
          </ng-container>
          <td *ngIf="receituarioEletronicoGrade?.transmitido == 'S' || receituarioEletronicoGrade?.cancelado == 'S' || receituarioEletronicoGrade?.enviando != 'N'"><btnAdicional [icone]="receituarioEletronicoGrade?.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="receituarioEletronicoGrade.expandido = !receituarioEletronicoGrade.expandido" [desabilitado]="receituarioEletronicoGrade?.enviando != 'S' || receituarioEletronicoGrade?.transmitido!= 'S' || receituarioEletronicoGrade?.cancelado != 'S' ? false : true"></btnAdicional></td>
          <td *ngIf="receituarioEletronicoGrade?.transmitido == 'S' && receituarioEletronicoGrade?.cancelado == 'N'"><btnAdicional [icone]="bibIcone.atencao" [ajuda]="bibDialogo.enviarSolicitacaoCancelamento" (btnAdicional)="enviarCancelamento(receituarioEletronicoGrade)"></btnAdicional></td>
        </tr>
        <ng-container *ngIf="receituarioEletronicoGrade?.expandido">
          <tr *ngIf="receituarioEletronicoGrade?.transmitido != 'S'">
            <td colspan="9"><descricao [rotulo]="bibDialogo.descricao" [campo]="receituarioEletronicoGrade?.statusDescricao" [normal]="true" [maximoCaracteres]="1000" [desabilitado]="true"></descricao></td>
          </tr>
          <ng-container *ngIf="receituarioEletronicoGrade?.transmitido == 'S' || receituarioEletronicoGrade?.cancelado == 'S'">
            <tr>
              <td colspan="5"><apresentacao [rotulo]="bibDialogo.dataAutorizacao" [campo]="receituarioEletronicoGrade?.cancelado == 'S' ? receituarioEletronicoGrade?.dataCancelamento : receituarioEletronicoGrade?.dataTransmissao"></apresentacao></td>
              <td colspan="4"><apresentacao [rotulo]="bibDialogo.protocolo" [campo]="receituarioEletronicoGrade?.cancelado == 'S' ? receituarioEletronicoGrade?.protocoloCancelamento : receituarioEletronicoGrade?.protocoloTransmissao"></apresentacao></td>
            </tr>
            <tr>
              <td colspan="9"><apresentacao [rotulo]="bibDialogo.situacao" [campo]="receituarioEletronicoGrade?.statusDescricao"> </apresentacao></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <div *ngIf="ehEidaf">
    <input type="file" (change)="enviarReceituario($event)" accept="application/pdf" style="display: none;" #fileInput>
    <botao [compacto]="compacto" [legenda]="bibDialogo.emitir + ' ' + orgao" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="fileInput.click()"></botao>
  </div>

  <div *ngIf="ehAgroDefesaGO" class="d-flex flex-row align-items-center justify-content-between">
    <div class="row p-0 m-0">
      <botao [compacto]="compacto" [legenda]="bibDialogo.emitir + ' ' + orgao" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="enviarReceituario($event)"></botao>
    </div>
  </div>  
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>

