import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Mdfe } from 'src/app/modelo/mdfe';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './mdfe.component.html',
})
export class MdfeComponent extends PaginaComponent {
  public mdfes: Mdfe[] = [];
  public filtros: Filtro[] = filtros;
  public rotuloGrupo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 193).apelido;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.mdfe).subscribe((res) => {
      this.mdfes = this.plainToClass(Mdfe, res) as any;
    });
  }

  excluirProduto(id: number, mdfe: string): void {
    super.excluir<Mdfe>(id, this.mdfes, mdfe);
  }

  abrirMdfe(mdfe: Mdfe): void {
    if (mdfe.situacao != 3 && mdfe.situacao != 4 && mdfe.situacao != 6) {
      this.listarMdfe(mdfe.id).subscribe((res) => {
        let mdfePersistir: Mdfe = this.plainToClass(Mdfe, res[0]) as any;
        if (confirm(this.bibDialogo.desejaAbrirMdfeNumero + ' ' + (mdfe.numero != undefined ? mdfe.numero : '0'))) {
          mdfePersistir.status = 'N';
          const transporte: Transporte = new Transporte(mdfePersistir);
          super.persistir(transporte, this.bibServico.mdfe, null).subscribe(() => {
            if (!this.utilSessao.falha) {
              mdfe.status = 'N';
            } else {
              mdfe.status = 'S';
            }
          });
        }
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.validarAbrirMdfeJaEnviado));
    }
  }

  listarMdfe(idMdfe: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idMdfe)), this.bibServico.mdfe);
  }
}
