<div *ngIf="!somenteVisualizacao" class="row justify-content-between align-items-center">
  <titulo class="ml-2" [titulo]="bibDialogo.fotos" [imagem]="bibImagem.fotos"></titulo>
  <div class="botao-imagem mr-2">
    <div [ngClass]="compacto ? 'btn btn-outline-primary btn-xs borda' : 'btn btn-outline-primary borda'" tabindex="0">
      {{ bibDialogo.adicionar + ' ' + bibDialogo.fotos.toLowerCase() }}
      <input type="file" accept="image/*" (change)="setarArquivo($event)" class="botao-upload-imagem" #arquivoUpload />
    </div>
  </div>
</div>
<div *ngIf="fotos.length > 0" class="row linha-fotos">
  <ng-container *ngFor="let foto of fotos">
    <div *ngIf="foto.excluido != 'S'" class="col-2">
      <div (click)="abrirImagem(foto.arquivoRetorno ? foto.arquivoRetorno : conexao.caminhoAwsS3 + '' + foto.idEmpresa + '/' + pasta + '/' + idPai + '/' + foto.id + '.jpg')" class="imagem-amostra" [ngStyle]="{ 'background-image': 'url(' + (foto.arquivoRetorno ? foto.arquivoRetorno : conexao.caminhoAwsS3 + '' + foto.idEmpresa + '/' + pasta + '/' + idPai + '/' + foto.id + '.jpg') + ')' }"></div>
      <div *ngIf="!somenteVisualizacao && foto.excluido != 'S'" [ngbTooltip]="bibDialogo.excluir" class="imagem-amostra-deletar">
        <button type="button" class="btn btn-sm btn-icon btn-secondary" (click)="deletar(foto)"><i class="far fa-trash-alt"></i></button>
      </div>
    </div>
  </ng-container>
</div>
