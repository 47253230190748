export class bibServicoNs {

  getToken(): string {
    return "QUdST1cgU09GVFdBUkUgdnNWWmI=";
  }

  getAmbiente(): string {
    //return "2"; // homologação
    return "1"; // produção    
  }

  getUrlEmissao(): string {
    return "nfe/issue";
  }

  getUrlConsultar(): string {
    return "nfe/issue/status";
  }

  getUrlPdf(): string {
    return "nfe/get";
  }

  getUrlCancelamento(): string {
    return "nfe/cancel";
  }

  getUrlCartaCorrecao(): string {
    return "nfe/cce";
  }

  getUrlEmail(): string {
    return "util/resendemail";
  }

  getUrlEventos(): string {
    return "nfe/get/event";
  }
}
