import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { CompendioEletronicoComponent } from './compendioEletronico.component';
import { CompendioEletronicoDadosFrmComponent } from './compendioEletronicoFrm/compendioEletronicoDadosFrm/compendioEletronicoDadosFrm.component';
import { CompendioEletronicoFrmComponent } from './compendioEletronicoFrm/compendioEletronicoFrm.component';
import { CompendioEletronicoIndicacoesComponent } from './compendioEletronicoFrm/compendioEletronicoIndicacoes/compendioEletronicoIndicacoes.component';
import { CompendioEletronicoIndicacoesFrmComponent } from './compendioEletronicoFrm/compendioEletronicoIndicacoes/compendioEletronicoIndicacoesFrm/compendioEletronicoIndicacoesFrm.component';
import { CompendioEletronicoRestricaoComponent } from './compendioEletronicoFrm/compendioEletronicoRestricao/compendioEletronicoRestricao.component';
import { ProdutoFormuladoDosePipe } from './compendioEletronicoFrm/compendioEletronicoIndicacoes/produtoFormuladoDose.pipe';

const routes: Routes = [
  {
    path: '',
    component: CompendioEletronicoComponent,
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'Frm',
    component: CompendioEletronicoFrmComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  declarations: [CompendioEletronicoComponent, CompendioEletronicoFrmComponent, CompendioEletronicoDadosFrmComponent, CompendioEletronicoIndicacoesComponent, CompendioEletronicoIndicacoesFrmComponent, CompendioEletronicoRestricaoComponent, ProdutoFormuladoDosePipe],
  imports: [CommonModule, FormsModule, RouterModule, RouterModule.forChild(routes), CompartilhadoModule],
})
export class CompendioEletronicoModule {}
