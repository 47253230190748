import { differenceInDays } from 'date-fns';
import { MovimentacaoParcela } from '../modelo/movimentacaoParcela';
import { Negociacao } from '../modelo/negociacao';
import { Util } from './util';

export class UtilProduto {
  private util = new Util();

  calcularLucroPercentual(valorTotal: number, custoGerencialTotal: number, icmsDesoneradoValor: number): number {
    if (valorTotal && valorTotal > 0 && custoGerencialTotal > 0) {
      return this.util.arredondar(((valorTotal - custoGerencialTotal - icmsDesoneradoValor) * 100) / (valorTotal - icmsDesoneradoValor));
    }
    return 0;
  }

  calcularLucroValor(valorTotal: number = 0, custoGerencialReal: number = 0, icmsDesoneradoValor: number = 0): number {
    if (valorTotal >= 0 && custoGerencialReal > 0) {
      return this.util.arredondar(valorTotal - custoGerencialReal - icmsDesoneradoValor);
    }
    return 0;
  }

  calcularPenalidadeParcelas(negociacao: Negociacao, valorTotaltem: number, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): number {
    let penalidadeValor: number = 0;
    let taxaPenalidade: number = 0;
    if (movimentacaoParcelas.length == 0) {
      taxaPenalidade = (negociacao.prazoReferenciaLucro * negociacao.penalidadeTaxaDiaria) / 100;
      penalidadeValor += taxaPenalidade * valorMovimentacao;
    }
    movimentacaoParcelas.forEach((movimentacaoParcela) => {
      const diasPrazoParcela: number = differenceInDays(movimentacaoParcela.dataVencimento, new Date()) + 1;
      if (diasPrazoParcela > negociacao.penalidadeDiaMinimo) {
        const valorItemSobreParcela: number = (valorTotaltem * movimentacaoParcela.getValorParcela()) / valorMovimentacao;
        taxaPenalidade = (diasPrazoParcela * negociacao.penalidadeTaxaDiaria) / 100;
        penalidadeValor += taxaPenalidade * valorItemSobreParcela;
      }
    });
    return penalidadeValor;
  }

  calcularPenalidadeValor(negociacao: Negociacao, valorTotaltem: number, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): number {
    let penalidadeValor: number = 0;
    if (negociacao && valorTotaltem >= 0) {
      if (negociacao.penalidadeTaxaDiaria > 0) {
        penalidadeValor += this.calcularPenalidadeParcelas(negociacao, valorTotaltem, movimentacaoParcelas, valorMovimentacao);
      }
      if (negociacao.penalidadeTaxaFixa > 0) {
        penalidadeValor += (negociacao.penalidadeTaxaFixa * valorTotaltem) / 100;
      }
    }
    return this.util.arredondar(penalidadeValor);
  }
}
