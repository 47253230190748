<div *ngIf="idRelatorioInterno == null" style="min-height: 250px;">
  <header class="page-title-bar">
    <p class="lead mb-0">
      <span class="font-weight-bold">Olá, {{ usuarioNome }}!</span>
    </p>
    <p>
      <span class="font-weight-bold"> {{ lojaAbreviacao }}</span>
    </p>
  </header>
  <div *ngIf="idRelatorioInterno == null" class="centralizar">
    <img [src]="'img/logomarca/logo_' + utilSessao.sistema.toLowerCase() + '.png'" height="100" />
  </div>
</div>
<r2018 *ngIf="idRelatorioInterno != null && idRelatorioInterno == 2018"></r2018>
<r2019 *ngIf="idRelatorioInterno != null && idRelatorioInterno == 2019"></r2019>
<div *ngIf="utilSessao.getUsuario()?.agrow == 'S'">
  <div class="fixed-bottom ml-3 altura">
    <icone class="icone clique" [icone]="bibIcone.xml" [cor]="bibPropriedade.icone.preto" [mensagem]="bibDialogo.loginManagerSas" [ajudaSuperiorDireita]="true" (click)="irManageSas()"></icone>
  </div>
   <div class="fixed-bottom ml-3 mb-1">
     <icone class="icone clique" [icone]="bibIcone.headphone" [cor]="bibPropriedade.icone.preto" [mensagem]="bibDialogo.loginTecnospeed" [ajudaSuperiorDireita]="true" (click)="irSuporteTecnospeed()"></icone>
   </div>
</div>