import { Component, Input } from '@angular/core';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'relatorioUsuariosFrm',
  templateUrl: './relatorioUsuariosFrm.component.html',
  styleUrls: ['./relatorioUsuariosFrm.component.css'],
})
export class RelatorioUsuariosFrmComponent extends PaginaComponent {
  public _relatorioUsuarios: RelatorioUsuario[] = [];
  public campoPesquisa: string = this.bibDialogo.numero;
  public filtro: string = '';
  @Input() dashboard: String = 'N';

  public listaCampoPesquisa: any[] = [
    { id: this.bibDialogo.numero, nome: this.bibDialogo.numero },
    { id: this.bibDialogo.nome, nome: this.bibDialogo.nome },
    { id: this.bibDialogo.semAcesso, nome: this.bibDialogo.semAcesso },
    { id: this.bibDialogo.comAcesso, nome: this.bibDialogo.comAcesso },
  ];

  @Input()
  set relatorioUsuarios(relatorioUsuarios: RelatorioUsuario[]) {
    this._relatorioUsuarios = relatorioUsuarios;
  }

  setCampoPesquisa(valor: string): void {
    this.campoPesquisa = valor;
    if (this.campoPesquisa == this.bibDialogo.semAcesso || this.campoPesquisa == this.bibDialogo.comAcesso) {
      this.filtro = '';
    }
  }

  setRelatorioUsuarioAcessar(relatorioUsuario: RelatorioUsuario): void {
    relatorioUsuario.acessar = relatorioUsuario.acessar == 'S' ? 'N' : 'S';
  }

  setRelatorioUsuarioFavorito(relatorioUsuario: RelatorioUsuario): void {
    relatorioUsuario.favorito = relatorioUsuario.favorito == 'S' ? 'N' : 'S';
  }

  alterarValorFiltro(valor: string): void {
    if (this.campoPesquisa != this.bibDialogo.numero && Number(valor)) {
      this.campoPesquisa = this.bibDialogo.numero;
    } else {
      if (this.campoPesquisa == this.bibDialogo.numero && !Number(valor)) {
        this.campoPesquisa = this.bibDialogo.nome;
      }
    }
    this.filtro = valor;
  }

  setFiltro(filtro: string): void {
    this.filtro = filtro;
    if (this.filtro != null) {
      if (this.campoPesquisa == this.bibDialogo.nome) {
        this._relatorioUsuarios = this._relatorioUsuarios.filter((relatorio) => relatorio.relatorio.toLowerCase().includes(this.filtro.toLowerCase()));
      } else if (this.campoPesquisa == this.bibDialogo.numero) {
        this._relatorioUsuarios = this._relatorioUsuarios.filter((relatorio) => relatorio.idRelatorio.toString() == this.filtro);
      }
    } else {
      this._relatorioUsuarios.filter((relatorio) => relatorio.idRelatorio > 0);
    }
  }

  permitirAcessoModulo(relatorioModulo: String) {
    let relatorioUsuarioModulos: RelatorioUsuario[] = this._relatorioUsuarios.filter((relatorioUsuario) => relatorioUsuario.relatorioModulo == relatorioModulo);
    for (const relatorioUsuario of relatorioUsuarioModulos) {
      relatorioUsuario.acessar = 'S';
    }
  }

  setDashboardInicial(relatorioUsuario: RelatorioUsuario) {
    relatorioUsuario.dashboardInicial = relatorioUsuario.dashboardInicial == 'S' ? 'N' : 'S';
    if (relatorioUsuario.dashboardInicial == 'S') {
      relatorioUsuario.acessar = 'S';
      this._relatorioUsuarios.forEach((_relatorioUsuario) => {
        if (_relatorioUsuario.id != relatorioUsuario.id) {
          _relatorioUsuario.dashboardInicial = 'N';
        }
      });
    }
  }

  setRelatorioUsuarioExportarExcel(relatorioUsuario: RelatorioUsuario): void {
    if (relatorioUsuario.excel == 'N') {
      if (confirm(this.bibDialogo.certezaArquivoExcelPermissao + ' ' + this.bibDialogo.desejaContinuar)) {
        relatorioUsuario.excel = 'S';
      }
    } else {
      relatorioUsuario.excel = 'N';
    }
  }
}
