import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';

@Component({
  selector: 'r2001MovimentacaoDistorcida',
  templateUrl: './r2001MovimentacaoDistorcida.component.html',
})
export class R2001MovimentacaoDistorcidaComponent {
  @Input() movimentacaoDistorcidas: any[];
  @Input() empresaRegraCasasDecimais: number;
  private utilSessao: UtilSessao = new UtilSessao();
  public bibDialogo = bibDialogo;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public empresaAlertaDesconto: number = this.utilSessao.getEmpresa().alertaDesconto;
  public empresaAlertaPrazo: number = this.utilSessao.getEmpresa().alertaPrazo;
}
