export class ConfiguracaoFinanceiroTotalContabil {
  public contaContabil: string;
  public contaCredito: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public debitoCredito: number;
  public historico: string;
  public id: number;
  public idConfFinanceiroContabil: number;
  public idContaContabil: number;
  public idContaCredito: number;
  public idEmpresa: number;
  public idTipoLancamentoContabil: number;
  public idTotalFinanceiroContabil: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public lancamentoDireto: string = 'S';
  public origem: number = 1;
  public tipoLancamentoContabil: string;
  public totalFinanceiroContabil: string;
  public totalContabilContraPartida: string;
}
