import { Component } from '@angular/core';
import { FinanceiroBoleto } from 'src/app/agrow/modelo/financeiroBoleto';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './conta.component.html',
})
export class ContaComponent extends PaginaComponent {
  public contas: Conta[] = [];
  public financeiroBoletos: FinanceiroBoleto[] = [];
  public filtros: Filtro[] = filtros;
  public quantidadeBoletoRebidos: number = 0;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.conta).subscribe((res) => {
      this.contas = this.plainToClass(Conta, res) as any;
      this.contarFinanceiroBoletoRecebido();
    });
  }

  contarFinanceiroBoletoRecebido(): void {
    for (let conta of this.contas) {
      if (conta.cedente != null && conta.homologacao != 'S') {
        this.comunicacaoService.contar(new Transporte([new Criterio('ID_CONTA', conta.id), new Criterio('RECEBIDO', 'S')]), this.bibServico.financeiroBoleto).subscribe((res) => {
          conta.quantidadeBoletoRecebido = res as number;
        });
      }
    }
  }

  excluirConta(id: number, nome: string): void {
    super.excluir<Conta>(id, this.contas, nome);
  }
}
