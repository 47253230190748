import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { CidadeRoutingModule } from './cidade-routing.module';
import { CidadeComponent } from './cidade.component';
import { CidadeFrmComponent } from './cidadeFrm.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CidadeComponent, CidadeFrmComponent],
  imports: [CommonModule, FormsModule, CidadeRoutingModule, CompartilhadoModule],
})
export class CidadeModule {}
