import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: bibDialogo.ignorarInadimplentes, id: 'current_date' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataVencimentoAte, obrigatorio: 'S', nome: 'DATA_VENCIMENTO_ATE', coluna: 12, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.conta, nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.contaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboSituacao, valor: '-' },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
