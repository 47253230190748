export class BibServicoMdfeTecnoSpeed {
  url: string = 'https://managersaas.tecnospeed.com.br:8081/ManagerAPIWeb';

  getUrlMdfe(servico: string): string {
    return this.url + `/${servico}/envia`;
  }

  getUrlDescarta(servico: string): string {
    return this.url + `/${servico}/descarta`;
  }

  getUrlEnviarEmail(servico: string): string {
    return this.url + `/${servico}/email`;
  }

  getUrlCancelaMdfe(servico: string): string {
    return this.url + `/${servico}/cancela`;
  }

  getUrlEncerraMdfe(servico: string): string {
    return this.url + `/${servico}/encerra`;
  }

  getUrlConsultaCancelamentoMdfe(servico: string, idNota: string): string {
    return this.url + `/${servico}/${idNota}/cancelamento/status`;
  }

  getUrlConsultaMdfe(servico: string): string {
    return this.url + `/${servico}/consulta`;
  }

  getUrlBaixaPdfMdfe(servico: string): string {
    return this.url + `/${servico}/imprime`;
  }

  getUrlBaixaXmlMdfe(servico: string): string {
    return this.url + `/${servico}/xml`;
  }

  getUrlLogotipo(cnpj: string): string {
    return this.url + `/empresa/${cnpj}/logotipo`;
  }

  getUrlAmbiente(servico: string): string {
    return this.url + `/${servico}/modo`;
  }
}
