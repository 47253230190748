import { Component } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { Visita } from 'src/app/agrow/modelo/visita';
import { VisitaFoto } from 'src/app/agrow/modelo/visitaFoto';
import { ImagemComponent } from 'src/app/essencial/imagem/imagem.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './visitaFrm.component.html',
})
export class VisitaFrmComponent extends PaginaComponent {
  public visita: Visita = new Visita();
  public componente: any = null;

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.visita = this.plainToClass(Visita, this.ehAtualizacao(this.visita));
    if (this.visita.id) {
      this.listarVisita(this.visita.id);
    } else {
      this.inserir();
    }
  }

  inserir(): void {
    let usuario: Usuario = this.utilSessao.getUsuario();
    this.visita.idResponsavel = usuario.idColaborador;
    this.visita.responsavel = usuario.colaborador;
  }

  listarVisita(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.visita).subscribe((res) => {
      this.visita = this.plainToClass(Visita, res[0]) as any;
      this.listarVisitaFoto();
    });
  }

  listarVisitaFoto(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_VISITA', this.visita.id)), this.bibServico.visitaFoto).subscribe((res) => {
      this.visita.visitaFotos = this.plainToClass(VisitaFoto, res) as any;
    });
  }

  persistirVisita(novo: boolean = false): void {
    if (this.ehValido() && this.ehValidoDataMaximoMes()) {
      const excluirIdVisitaFotos: number[] = this.visita.visitaFotos.filter((visitaFoto) => visitaFoto.excluido == 'S' && visitaFoto.id).map((visitaFoto) => visitaFoto.id);
      this.visita.visitaFotos = this.visita.visitaFotos.filter((produtoFoto) => produtoFoto.excluido != 'S');
      const transporte: Transporte = new Transporte(this.visita);
      transporte.adicionar(excluirIdVisitaFotos);
      super.persistir(transporte, this.bibServico.visita, novo ? new Visita() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.visita.visitaFotos = this.visita.visitaFotos.filter((produtoFoto) => produtoFoto.excluido != 'S');
          this.visita.visitaFotos.forEach((produtoFoto) => (produtoFoto.pendente = 'N'));
          this.definirIds();
          if (novo) {
            this.visita.id = null;
          }
        }
      });
    }
  }

  ehValido(): boolean {
    if (this.visita.data && this.visita.idParceiro) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoDataMaximoMes(): boolean {
    if (this.visita.data && this.visita.dataProxima) {
      const diferencaDias: Number = differenceInDays(this.visita.dataProxima, this.visita.data);
      if (diferencaDias >= 31) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataIntervaloUmMes));
        return false;
      }
    }
    return true;
  }

  definirIds(): void {
    const visitaFotos: VisitaFoto[] = this.visita.visitaFotos.filter((visitaFoto) => visitaFoto.id == null);
    this.definirId([this.visita], this.bibClasse.visita, true);
    this.definirId(visitaFotos, this.bibClasse.visitaFoto);
  }

  setParceiro(parceiro: Parceiro): void {
    this.visita.idParceiro = parceiro.id;
    this.visita.idParceiroDestino = parceiro.idParceiroDestino ? parceiro.idParceiroDestino : null;
    this.visita.parceiro = parceiro.razaoSocial;
  }

  setResponsavel(parceiro: Parceiro): void {
    this.visita.idResponsavel = parceiro.id;
    this.visita.responsavel = parceiro.razaoSocial;
  }

  abrirModalImagem(imagemBase64: string): void {
    this.utilSessao.setIdentificacao(new Identificacao('imagemBase64', imagemBase64));
    this.componente = ImagemComponent;
  }
}
