import bibDialogo from '../biblioteca/bibDialogo';
import { Parceiro } from '../modelo/parceiro';
import { ParceiroHistoricoLimite } from '../modelo/parceiroHistoricoLimite';
import { Util } from './util';
import { UtilSessao } from './util.sessao';

export class UtilLimiteCredito {
  public bibDialogo = bibDialogo;
  public util: Util = new Util();
  public utilSessao: UtilSessao = new UtilSessao();

  setReceitaMensal(parceiro: Parceiro, valor: number): void {
    parceiro.receitaMensal = valor ? valor : 0;
    parceiro.alterado = true;
  }

  setLimiteMaximo(parceiro: Parceiro, valor: number, parceiroLimites: any[] = []): void {
    parceiro.limiteMaximo = valor;
    parceiro.alterado = true;
  }

  setLimiteCredito(parceiro: Parceiro, valor: number, parceiroLimites: any[] = []): void {
    const limiteCreditoAnterior = parceiro.limiteCredito;
    parceiro.limiteCredito = valor ? valor : 0;
    parceiro.limiteDisponivel = parceiro.limiteDisponivel + (parceiro.limiteCredito - limiteCreditoAnterior);
    parceiro.alterado = true;
    this.setLimiteParceiroHistorico(parceiro, parceiroLimites);
  }

  setLimiteCreditoDisponivel(parceiro: Parceiro, valor: number, parceiroLimites: any[] = []): void {
    parceiro.limiteDisponivel = valor ? valor : 0;
    parceiro.alterado = true;
    this.setLimiteParceiroHistorico(parceiro, parceiroLimites);
  }

  sehEhValidoLimiteCredito(parceiros: Parceiro[], empresaLimiteCreditoAutomatico: boolean): boolean {
    let valido: boolean = true;
    let parceiroAlterados: Parceiro[] = parceiros.filter((parceiro) => parceiro.alterado);
    if (parceiroAlterados.length > 0) {
      if (empresaLimiteCreditoAutomatico) {
        parceiroAlterados.forEach((parceiro) => {
          if (parceiro.limiteMaximo < parceiro.limiteDisponivel || parceiro.limiteMaximo < parceiro.limiteCredito) {
            if (confirm(this.util.substituir(bibDialogo.limiteMaximoMenorQueLimiteALiberar, [parceiro.razaoSocial, bibDialogo.limiteCredito.toLowerCase()]))) {
              valido = true;
            } else {
              valido = false;
            }
          }
          // if (parceiro.receitaMensal * 0.5 < parceiro.limiteMaximo) {
          //   if (confirm(this.util.substituir(bibDialogo.limiteMaximoMaiorPorcentagemRenda, [parceiro.razaoSocial]))) {
          //     valido = true;
          //   } else {
          //     valido = false;
          //   }
          // }
        });
      } else {
        parceiroAlterados.forEach((parceiro) => {
          if (parceiro.limiteDisponivel > parceiro.limiteMaximo) {
            if (confirm(this.util.substituir(bibDialogo.limiteMaximoMenorQueLimiteALiberar, [parceiro.razaoSocial, bibDialogo.limiteDisponivel.toLowerCase()]))) {
              valido = true;
            } else {
              valido = false;
            }
          }
        });
      }
    }
    return valido;
  }

  setLimiteParceiroHistorico(parceiro: Parceiro, parceiroLimites: any[]): void {
    if (parceiroLimites.length > 0) {
      this.ehAdicionarParceiroHistoricoLimite(parceiro, parceiroLimites);
    }
  }

  ehAdicionarParceiroHistoricoLimite(parceiro: Parceiro, parceiroLimites: any[]): void {
    const parceiroLimite: any = parceiroLimites.find((parceiroLimite) => parceiroLimite.id == parceiro.id);
    if (parceiroLimite.limiteCredito != parceiro.limiteCredito || parceiroLimite.limiteDisponivel != parceiro.limiteDisponivel || parceiroLimite.limiteMaximo != parceiro.limiteMaximo) {
      parceiro.parceiroHistoricoLimites.push(this.criarHistoricoLimite(parceiro, parceiroLimite));
    }
  }

  criarHistoricoLimite(parceiro: Parceiro, parceiroLimite: any): ParceiroHistoricoLimite {
    let parceiroHistoricoLimite: ParceiroHistoricoLimite = new ParceiroHistoricoLimite();
    parceiroHistoricoLimite.limiteCredito = parceiro.limiteCredito ? parceiro.limiteCredito : 0;
    parceiroHistoricoLimite.variacaoLimiteCredito = (parceiro.limiteCredito ? parceiro.limiteCredito : 0) - (parceiroLimite.limiteCredito ? parceiroLimite.limiteCredito : 0);
    parceiroHistoricoLimite.limiteDisponivel = parceiro.limiteDisponivel ? parceiro.limiteDisponivel : 0;
    parceiroHistoricoLimite.variacaoLimiteDisponivel = (parceiro.limiteDisponivel ? parceiro.limiteDisponivel : 0) - (parceiroLimite.limiteDisponivel ? parceiroLimite.limiteDisponivel : 0);
    parceiroHistoricoLimite.limiteMaximo = parceiro.limiteMaximo ? parceiro.limiteMaximo : 0;
    parceiroHistoricoLimite.variacaoLimiteMaximo = (parceiro.limiteMaximo ? parceiro.limiteMaximo : 0) - (parceiroLimite.limiteMaximo ? parceiroLimite.limiteMaximo : 0);
    return parceiroHistoricoLimite;
  }
}
