import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2028.component.html',
  styleUrls: ['./r2028.component.css'],
})
export class R2028Component extends RelatorioComponent {
    public dados: any;
    public notasLista: any[] = [];
    public filtros = filtros;
    public mostraNotas: boolean = false;
    public notas: string = "";
    public mensagemFinal: string = "";
    public tipoRelatorio: string = "";
    public empresa: Empresa = this.utilSessao.getEmpresa();
    public ehRelatorio2028: boolean = false;
    public ehRelatorio1233: boolean = false;
    public foto: string;
    public imagemEmpresa: string = 'img/empresa-semfoto.jpg';

    ngOnInit(): void {
      this.activatedRoute.params.subscribe((params) => {
        if (params.idRelatorio == 2028) {
          this.ehRelatorio2028 = true;
          this.tipoRelatorio = "DUPLICATA";
        }
        if (params.idRelatorio == 1233) {
          this.ehRelatorio1233 = true;
          this.tipoRelatorio = "NOTA PROMISSÓRIA";
        }
      });
    }

    listarRelatorio(criterios: Criterio[]): void {
      super.listar(criterios).subscribe(() => {
        if (this.ehRelatorio2028){
          this.dados = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'DuplicataMercantil').lista[0];      
          this.notasLista = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'DuplicataMercantilMovimentacao').lista;    
        }  
        if (this.ehRelatorio1233){
          this.dados = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista[0];      
          this.notasLista = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;    
        } 
        this.ehImprimirRelatorio();
        this.formartarMensagemFinal(this.dados.loja, this.notas);
      });
    }

    formartarMensagemFinal(nomeLoja: string, notas: string): void {
      this.notas = "";
      this.notasLista.find((nota) => nota.id == this.dados.id);    
      this.notasLista.forEach((nota) => {
        this.notas += this.notas == "" ? nota.numeroFiscal : ", " + nota.numeroFiscal;
      });   
      this.notas = this.notas == "" ? this.dados.numero : this.notas;     
      this.mensagemFinal = this.util.substituir(this.bibDialogo.mensagemDuplicataMercantil, [nomeLoja, nomeLoja, notas]);
    }
  }
 
