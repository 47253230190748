<div class="responsiva">
  <atencao class="p-2" [atencao]="bibDialogo.somenteFinanceiro"></atencao>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90 clique" (click)="util.ordenarLista(usuarioContas, 'conta')">{{ menuConta.apelido }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioContas, 'acessar')">{{ bibDialogo.permitir }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usuarioConta of usuarioContas; let i = index">
        <td class="limitar">{{ usuarioConta.conta }}</td>
        <td class="text-center">
          <icone [icone]="usuarioConta.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="usuarioConta.acessar = usuarioConta.acessar == 'S' ? 'N' : 'S'" [cor]="usuarioConta.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza"></icone>
        </td>
      </tr>
    </tbody>
  </table>
</div>
