<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-5">{{ bibDialogo.cliente }}</th>
      <th class="r">{{ bibDialogo.vendedor }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th *ngIf="movimentacoes.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes">
        <tr class="conteudo" [ngClass]="movimentacao.expandido ? 'sem-borda' : ''">
          <td>{{ movimentacao.loja }}</td>
          <td>{{ movimentacao.data | data }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td class="r-2">{{ movimentacao.parceiro }}</td>
          <td class="r-2">{{ movimentacao.responsavelNumero + ' - ' + movimentacao.responsavel }}</td>
          <td class="text-right">{{ movimentacao.valorTotal | monetario }}</td>
          <td class="naoImprimir" *ngIf="movimentacoes.length > 0"><btnAdicional [icone]="movimentacao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacao)"></btnAdicional></td>
        </tr>
        <tr *ngIf="movimentacao.expandido" r2000Itens [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacaoProdutos]="movimentacao.movimentacaoProdutos" class="sem-borda"></tr>
        <tr *ngIf="movimentacao.expandido" r2000Entregas [entregaProdutos]="movimentacao.entregaProdutos"></tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
