import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { R2014Component } from '../r2014.component';

@Component({
  selector: ' <tr>[r2014itens]</tr>',
  templateUrl: './r2014itens.component.html',
  styleUrls: ['.././r2014.component.css'],
})
export class R2014ItensComponent extends R2014Component {
  @Input() financeiros: any;
  public utilSessao: UtilSessao = new UtilSessao();
  public bibDialogo = bibDialogo;
  public componente: any;

  abrirModalFinanceiro(idFinanceiro: number, receitaDespesa: string): void {
    this.emitirModal(idFinanceiro, receitaDespesa);
  }
}
