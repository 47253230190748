import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { UtilDocumentoEletronico } from 'src/app/pagina/documentoEletronico/utilDocumentoEletronico';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r1128.component.html',
  styleUrls: ['./r1128.component.css'],
})
export class R1128Component extends RelatorioComponent {
  public apresentaMovimentacoes: any[] = [];
  public contrato: string;
  public dataAtual: Date = new Date();
  public ehMatricial: boolean = false;
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public filtros = filtros;
  public foto: string;
  public idAdicionalDuplicar: number = 153;
  public idAdicionalValorUnitario: number = 151;
  public idAdicionalDesconto: number = 0;
  public idAdicionalValorUnitarioComDesconto: number = 0;
  public idAdicionalDescontoValorFinal: number = 239;
  public imagemEmpresa: string = 'img/empresa-semfoto.jpg';
  public movimentacaoParcelas: any[] = [];
  public movimentacaoProdutoTotais: any[] = [];
  public movimentacaoProdutos: any[] = [];
  public movimentacoes: any[] = [];
  public movimentacoesDuplicadas: any[] = [];
  public operacaoMovimentacao: number;
  public usaDuplicarEspelhoMovimentacao: boolean = false;
  public usaValorUnitario: boolean = true;
  public mostraVia = true;
  public mostraTotal = true;
  public conexao: Conexao = new Conexao(this.utilSessao);
  public utilDocumentoEletronico = new UtilDocumentoEletronico(this.comunicacaoService, this.utilSessao);
  public usaObservacao: boolean = false;
  public usaDescontoValorFinal: boolean = false;
  public usaDesconto: boolean = false;
  public usaValorUnitarioComDesconto: boolean = false;
  public usaReferencia: boolean = false;
  public parametro: number;
  public preDanfe: boolean = false;
  public relatorio1128: boolean = false;
  public ESTUDO_DE_VENDA: number = 2;
  public PEDIDO_EM_CARTEIRA: number = 2;
  public DEVOLUCAO_DE_VENDA: number = 11;
  public PEDIDO_COMPRA: number = 7;
  public OUTRAS_OPERACOES: number = 99;
  public ORCAMENTO: number = 1;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.parametro = params.idRelatorio;
      if (this.parametro == 2020) {
        this.preDanfe = true;
      }
      if (this.parametro == 1128) {
        this.relatorio1128 = true;
      }
    });
  }

  listarRelatorio(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().permitir != 'S' ? this.utilSessao.getUsuario().idColaborador : null));
    super.listar(criterios).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.movimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.vincularMovimentacaoProdutos(this.movimentacoes, this.movimentacaoProdutos);
      this.movimentacaoParcelas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoParcela').lista;
      if (this.movimentacoes.length > 0) {
        this.utilizarFotoLojaEmpresa(this.movimentacoes[0].idLoja);
      }
      this.calcularTotalDescontoMovimentacaoProduto();
      this.atualizarRelatorioAdicionais();
      this.ehImprimirRelatorio();
      if (this.preDanfe) {
        this.movimentacoes[0].selecionado = 'S';
        this.utilDocumentoEletronico.iniciar(this.movimentacoes, true).subscribe();
      }
    });
  }

  vincularMovimentacaoProdutos(movimentacoes: any[], movimentacaoProdutos: any[]): void {
    movimentacoes.forEach((movimentacao) => {
      movimentacao.movimentacaoProdutos = [];
      movimentacaoProdutos.forEach((movimentacaoProduto) => {

        if ((movimentacaoProduto.idMovimentacao = movimentacao.id)) {
          movimentacao.movimentacaoProdutos.push(movimentacaoProduto);
        }
      });
    });
  }

  utilizarFotoLojaEmpresa(idLoja: number): void {
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == idLoja);
    this.foto = loja.foto == 'S' ? this.conexao.caminhoAwsS3 + '' + loja.idEmpresa + '/loja/' + loja.id + '.jpg' : this.conexao.caminhoAwsS3 + '' + this.empresa.id + '/empresa/' + this.empresa.id + '.jpg';
  }

  operacaoNome(movimentacao: Movimentacao): void {
    if (movimentacao.idOperacao == 2) {
      movimentacao.operacao = this.utilSessao.getUsuarioMenus().filter((menu) => menu.idMenu == 17)[0].menu.toUpperCase();
    }
  }

  calcularTotalDescontoMovimentacaoProduto(): void {
    this.movimentacaoProdutoTotais = [];
    this.movimentacoes.forEach((movimentacao) => {
      movimentacao.numeroFormatado = (String('0').repeat(9) + String(movimentacao.numero)).substr(9 * -1, 9);
      movimentacao.numeroFormatado = movimentacao.numeroFormatado.substring(0, 3) + '.' + movimentacao.numeroFormatado.substring(3, 6) + '.' + movimentacao.numeroFormatado.substring(6, 9);
      let valorTotalMovimentacaoProduto: number = 0;
      let descontoTotalMovimentacaoProduto: number = 0;
      let pesoTotalBruto: number = 0;
      movimentacao.valorTotalProdutoBruto = 0;
      movimentacao.descontoValorUnitarioTotal = 0;
      this.operacaoNome(movimentacao);
      const movimentacaoProdutoVinculados: any[] = this.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == movimentacao.id);
      movimentacaoProdutoVinculados.forEach((movimentacaoProduto) => {
        valorTotalMovimentacaoProduto += movimentacaoProduto.valorTotal;
        descontoTotalMovimentacaoProduto += movimentacaoProduto.descontoValor;
        pesoTotalBruto += movimentacaoProduto.produtoPesoBruto;
        movimentacao.descontoValorUnitarioTotal += (movimentacaoProduto.quantidade * movimentacaoProduto.descontoValorUnitario);
        movimentacao.valorTotalProdutoBruto += this.util.arredondar(movimentacaoProduto.quantidade * movimentacaoProduto.valorUnitario);
      });
      this.movimentacaoProdutoTotais.push({ idMovimentacao: movimentacao.id, valorTotalMovimentacaoProduto, descontoTotalMovimentacaoProduto, pesoTotalBruto });
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    if (relatorioAdicional.id == 259 && relatorioAdicional.marcado == 'S') {
      const relatorioAdicionalValorUnitario: RelatorioAdicional = this.relatorioAdicionais.find((relatorioAdicional) => relatorioAdicional.id == 171);
      relatorioAdicionalValorUnitario.marcado = 'N';
    } else {
      if (relatorioAdicional.id == 171 && relatorioAdicional.marcado == 'S') {
        const relatorioAdicionalDesconto: RelatorioAdicional = this.relatorioAdicionais.find((relatorioAdicional) => relatorioAdicional.id == 259);
        relatorioAdicionalDesconto.marcado = 'N';
      }
    }
    this.ehMatricial = this.definirRegra(relatorioAdicional, 4, this.ehMatricial);
    this.usaObservacao = this.definirRegra(relatorioAdicional, 232, this.usaObservacao);
    if (this.relatorio1128) {
      this.usaDescontoValorFinal = this.definirRegra(relatorioAdicional, 239, this.usaDescontoValorFinal);
    }
    this.usaDesconto = this.definirRegra(relatorioAdicional, 259, this.usaDesconto)
    this.usaValorUnitarioComDesconto = this.definirRegra(relatorioAdicional, 260, this.usaValorUnitarioComDesconto);
    this.usaReferencia = this.definirRegra(relatorioAdicional, 261, this.usaReferencia);
    this.atualizarRelatorioAdicionais();
  }

  atualizarRelatorioAdicionais(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      const OPERACAO = this.verificarMovimentacaoOperacao();
      this.tratarValorUnitario(relatorioAdicional, OPERACAO);
      this.tratarDesconto(relatorioAdicional, OPERACAO);
      this.tratarDuplicar(relatorioAdicional, OPERACAO);
      this.tratarValorUnitarioComDesconto(relatorioAdicional, OPERACAO);
    });
  }

  tratarValorUnitario(relatorioAdicional: RelatorioAdicional, OPERACAO): void {
    if (relatorioAdicional.id == this.idAdicionalValorUnitario) {
      if (OPERACAO == this.ESTUDO_DE_VENDA || OPERACAO == this.DEVOLUCAO_DE_VENDA || OPERACAO == this.PEDIDO_COMPRA) {
        relatorioAdicional.oculto = 'S';
        relatorioAdicional.marcado = 'S';
        this.usaValorUnitario = true;
      } else {
        if (OPERACAO == this.OUTRAS_OPERACOES) {
          relatorioAdicional.oculto = 'S';
          relatorioAdicional.marcado = 'N';
          this.mostraVia = false;
          this.mostraTotal = false;
          this.usaValorUnitario = false;
        } else {
          this.usaValorUnitario = this.definirRegra(relatorioAdicional, relatorioAdicional.id, this.usaValorUnitario);
          if (this.usaValorUnitario || this.usaDescontoValorFinal || this.usaValorUnitarioComDesconto) {
            this.usaDesconto = false;
          }
          if (this.usaValorUnitario || this.usaDescontoValorFinal || this.usaDesconto) {
            this.usaValorUnitarioComDesconto = false;
          }
          if (this.usaDesconto) {
            this.usaValorUnitarioComDesconto = false;
            this.usaValorUnitario = false;
            this.usaDescontoValorFinal = false;
          }
        }
      }
    }
  }

  tratarDesconto(relatorioAdicional: RelatorioAdicional, OPERACAO): void {
    if (relatorioAdicional.id == this.idAdicionalDesconto) {
      relatorioAdicional.nome = this.bibDialogo.desconto.toUpperCase();
      if (OPERACAO == this.ESTUDO_DE_VENDA || OPERACAO == this.DEVOLUCAO_DE_VENDA) {
        if (this.usaDesconto) {
          this.usaValorUnitario = false;
        } else {
          this.usaValorUnitario = true;
        }
        if (this.usaDescontoValorFinal) {
          this.usaDesconto = false;
        }
      }
    }
  }

  tratarValorUnitarioComDesconto(relatorioAdicional: RelatorioAdicional, OPERACAO): void {
    if (relatorioAdicional.id == this.idAdicionalValorUnitarioComDesconto) {
      relatorioAdicional.nome = bibDialogo.valorUnitarioComDesconto.toUpperCase();
      if (OPERACAO == this.ESTUDO_DE_VENDA || OPERACAO == this.DEVOLUCAO_DE_VENDA) {
        relatorioAdicional.oculto = 'S';
      }
    }
  }

  tratarDuplicar(relatorioAdicional: RelatorioAdicional, OPERACAO): void {
    if (relatorioAdicional.id == this.idAdicionalDuplicar) {
      const ORCAMENTO: number = 1;
      if (OPERACAO == ORCAMENTO || OPERACAO == 99) {
        relatorioAdicional.oculto = 'S';
        relatorioAdicional.marcado = 'N';
        this.mostrarMovimentacaoSemDuplicar();
      } else {
        if (relatorioAdicional.marcado == 'S') {
          this.clonar();
          this.apresentaMovimentacoes = this.movimentacoesDuplicadas;
        } else {
          this.mostrarMovimentacaoSemDuplicar();
        }
      }
    }
  }

  mostrarMovimentacaoSemDuplicar(): void {
    this.apresentaMovimentacoes = this.movimentacoes;
    this.movimentacoesDuplicadas = [];
  }

  verificarMovimentacaoOperacao(): number {
    this.movimentacoes.forEach((movimentacao) => {
      switch (movimentacao.idOperacao) {
        case 1:
          this.operacaoMovimentacao = this.ORCAMENTO;
          return;

        case 2:
          this.operacaoMovimentacao = this.ESTUDO_DE_VENDA;
          return;

        case 18:
          this.operacaoMovimentacao = this.PEDIDO_EM_CARTEIRA;
          return;

        case 11:
          this.operacaoMovimentacao = this.DEVOLUCAO_DE_VENDA;
          return;

        case 7:
          this.operacaoMovimentacao = this.PEDIDO_COMPRA;
          return;

        default:
          this.operacaoMovimentacao = this.OUTRAS_OPERACOES;
          return this.OUTRAS_OPERACOES;
      }
    });
    return this.operacaoMovimentacao;
  }

  clonar(): void {
    if (this.movimentacoesDuplicadas.length == 0) {
      this.movimentacoes.forEach((movimentacao) => {
        let movimentacaoViaCliente: any = JSON.parse(JSON.stringify(movimentacao));
        movimentacaoViaCliente.via = bibDialogo.viaCliente;
        this.movimentacoesDuplicadas.push(movimentacaoViaCliente);
        let movimentacaoViaEmpresa: any = JSON.parse(JSON.stringify(movimentacao));
        movimentacaoViaEmpresa.via = bibDialogo.viaEmpresa;
        this.movimentacoesDuplicadas.push(movimentacaoViaEmpresa);
      });
    }
  }
}
