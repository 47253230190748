import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1125icmsSt]</tr>',
  templateUrl: './r1125icmsSt.component.html',
})
export class R1125icmsStComponent {
  @Input() produtoIcmsSts: any[];
  public bibDialogo = bibDialogo;
}
