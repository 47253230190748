import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { ImagemComponent } from 'src/app/essencial/imagem/imagem.component';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoControlador } from 'src/app/modelo/produtoControlador';
import { Resultado } from 'src/app/modelo/resultado';
import { ProdutoImpostoFrmComponent } from 'src/app/pagina/imposto/produtoImposto/produtoImpostoFrm.component';
import { ProdutoValorComponent } from 'src/app/pagina/produtoValor/produtoValor.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ProdutoFrmComponent } from '../produtoFrm.component';

@Component({
  selector: 'produtoRodapeFrm',
  templateUrl: './produtoRodapeFrm.component.html',
  styleUrls: ['./produtoRodapeFrm.component.css'],
})
export class ProdutoRodapeFrmComponent extends PaginaComponent {
  public componente: any = null;
  public focoLojaAbreviacaoValor: number;
  public focoLojaCustoGerencial: number;
  public focoLojaCustoReposicao: number;
  public listaLojas: any[] = [];
  public lojaAbreviacao: string;
  public lojaAbreviacaoId: number;
  public visualizaLoja: boolean = false;
  @ViewChild('historicoRef') historicoRef: ElementRef;
  public historicoGrafico: any;
  @Input() produtoControlador: ProdutoControlador;
  public _produtoHistoricoMesAnual: any[] = [];
  @Input() set produtoHistoricoMesAnual(produtoHistoricoMesAnual: any[]) {
    this._produtoHistoricoMesAnual = produtoHistoricoMesAnual;
    this.criarGraficoHistorico();
  }

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == 'produto') {
        this.produtoControlador.produto = identificacao.conteudo;
      }
    });
    this.prepararListaLojas();
    this.mostrarOcultarLoja();
  }

  mostrarOcultarLoja() {
    if (this.utilSessao.getLojas().length > 1) {
      this.visualizaLoja = true;
    }
  }

  abrirModalImagem(imagemBase64: string): void {
    this.utilSessao.setIdentificacao(new Identificacao('imagemBase64', imagemBase64));
    this.componente = ImagemComponent;
  }

  abrirModalProdutoImpostos(produto: Produto): void {
    this.utilSessao.setIdentificacao(new Identificacao('produto', produto));
    this.utilSessao.setIdentificacao(new Identificacao('modal', true));
    this.utilSessao.setPermissaoEspecifica(this.permissaoProdutoImposto);
    this.componente = ProdutoImpostoFrmComponent;
  }


  prepararListaLojas(): void {
    this.utilSessao.getLojas().forEach((loja) => {
      this.listaLojas.push({ id: loja.id, nome: loja.abreviacao });
    });
  }

  setLojaProdutoValor(loja: any): void {
    this.lojaAbreviacao = loja.nome;
    this.lojaAbreviacaoId = loja.id;
  }

  modalProdutoValor(): void {
    if (this.lojaAbreviacaoId) {
      this.utilSessao.setIdentificacao(new Identificacao('idProduto', this.produtoControlador.produto.id));
      this.utilSessao.setIdentificacao(new Identificacao('idLoja', this.lojaAbreviacaoId));
      this.utilSessao.setPermissaoEspecifica(this.permissaoProdutoValor);
      this.componente = ProdutoValorComponent;
    } else {
      this.focoLojaAbreviacaoValor = Math.random();
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueLojaObrigatorio));
    }
  }

  criarGraficoHistorico(): void {
    if (this.historicoGrafico != null) {
      this.historicoGrafico.destroy();
    }

    if (this._produtoHistoricoMesAnual.length > 0) {
      const mesesAnos: string[] = this._produtoHistoricoMesAnual.map((produtoHistoricoMes) => produtoHistoricoMes.nome);
      const quantidades: number[] = this._produtoHistoricoMesAnual.map((produtoHistoricoMes) => produtoHistoricoMes.quantidade);
      const historico = this.historicoRef.nativeElement.getContext('2d');
      this.historicoGrafico = new Chart(historico, {
        type: 'bar',
        data: {
          labels: mesesAnos,
          datasets: [
            {
              label: '',
              data: quantidades,
              backgroundColor: ['#FF0000'],
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },

      });
    }
  }
}
