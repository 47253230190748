import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { ProdutoFormuladoComponent } from './produtoFormulado.component';

const routes: Routes = [
  {
    path: '',
    component: ProdutoFormuladoComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  declarations: [ProdutoFormuladoComponent],
  imports: [CommonModule, FormsModule, CompartilhadoModule, RouterModule, RouterModule.forChild(routes)],
})
export class ProdutoFormuladoModule {}
