import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r1152itens]</tr>',
  templateUrl: './r1152itens.component.html',
  styleUrls: ['./r1152itens.component.css'],
})
export class R1152ItensComponent {
  @Input() descricao: any;
  public bibDialogo = bibDialogo;
}
