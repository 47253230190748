import { Component, ElementRef, ViewChild } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ReceituarioFrmComponent } from 'src/app/agrow/pagina/receituario/receituarioFrm/receituarioFrm.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { ReceituarioEletronico } from 'src/app/modelo/receituarioEletronico';
import { ReceituarioEletronicoGrade } from 'src/app/modelo/receituarioEletronicoGrade';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import filtros from './filtro';

@Component({
  templateUrl: './documentoEletronicoReceituario.component.html',
  styleUrls: ['./documentoEletronicoReceituario.component.css'],
})
export class DocumentoEletronicoReceituarioComponent extends PaginaComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  private modalEmt: any;
  public componente: any;
  public filtros = filtros;
  public receituariosEletronicoGrade: ReceituarioEletronicoGrade[] = [];
  public receituariosEletronicoPersistir: ReceituarioEletronicoGrade[] = [];
  public orgao: string = '';
  public agronomo: string = '';
  public loja: Loja;
  public assinaturaEidaf: any[] = [];
  public ehEidaf: boolean = false;
  public ehAgroDefesaGO: boolean = false;
  public arquivoPDFEidaf: string = '';
  public idMovimentacoes: number[] = [];
  public intervaloAtualizarReceituario: any;
  public dataAtualizacao: Date;

  ngOnInit(): void {
    this.ehEidaf = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
    this.ehAgroDefesaGO = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO').length > 0 ? true : false;
    this.orgao = this.ehEidaf ? 'Eidaf' : this.ehAgroDefesaGO ? 'AgroDefesa' : '';
    this.loja = this.utilSessao.getLojas().find((loja) => ((loja.estado == 'GO') || (loja.estado == 'ES')))[0];
    // clearInterval(this.intervaloAtualizarReceituario);
    this.tipoAssinatura();
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
    //clearInterval(this.intervaloAtualizarNfe);
  }

  tipoAssinatura() {
    if (this.ehEidaf) {
      this.assinaturaEidaf = [
        { id: 'M', nome: this.bibDialogo.manual },
        { id: 'D', nome: this.bibDialogo.digital },
      ];
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, idMovimentacoes: number[] = []): void {
    //clearInterval(this.intervaloAtualizarNfe);
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    if (idMovimentacoes.length > 0) {
      criterios.push(new Criterio('ID_MOVIMENTACAO', idMovimentacoes.toString()));
    }
    this.dataAtualizacao = new Date();
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.receituarioEletronicoGrade).subscribe((res) => {
      this.receituariosEletronicoGrade = this.plainToClass(ReceituarioEletronicoGrade, res) as any;
      if (this.receituariosEletronicoGrade.length > 0) {
        this.listarEmpresa(this.receituariosEletronicoGrade[0].idEmpresa).subscribe((res) => {
          this.listarReceituarioEletronico(this.receituariosEletronicoGrade.map((receituarioEletronicoGrade) => receituarioEletronicoGrade.idMovimentacao));
          this.loja = this.utilSessao.getLojas().find((loja) => loja.id == this.receituariosEletronicoGrade[0].idLoja);
          this.agronomo = this.receituariosEletronicoGrade.length > 0 ? ' - ' + this.receituariosEletronicoGrade[0].agronomo : '';
        });
      }
    });
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', () => {
      abaAtiva = abaAtiva == true ? false : true;
    });

    // setInterval(() => {
    //   if (abaAtiva) {
    //     this.dataAtualizacao = new Date();
    //     this.listar([], 0, 0, this.idMovimentacoes)
    //   }
    // }, 30000);
  }

  private listarEmpresa(idEmpresa: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idEmpresa)), this.bibServico.empresa);
  }

  private listarReceituarioEletronico(idMovimentacao: number[]): void {
    let criterios: Criterio = new Criterio('IDS_MOVIMENTACAO', idMovimentacao.toString());
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.receituarioEletronico).subscribe((res) => {
      this.vincularReceituarioReceituarioEletronico(this.plainToClass(ReceituarioEletronico, res) as any);
    });
  }

  private vincularReceituarioReceituarioEletronico(receituariosEletronico: ReceituarioEletronico[]): void {
    this.receituariosEletronicoGrade.forEach((receituarioGrade) => {
      const receituarioEletronico: ReceituarioEletronico = receituariosEletronico.find((receituarioEletronico) => receituarioEletronico.idMovimentacao == receituarioGrade.idMovimentacao);
      if (receituarioEletronico) {
        receituarioGrade.receituarioEletronico = receituarioEletronico;
      } else {
        receituarioGrade.receituarioEletronico = new ReceituarioEletronico(receituarioGrade.idMovimentacao, receituarioGrade.idEmpresa, receituarioGrade.idLoja, 0);
      }
    });
  }

  abrirModalMovimentacao(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }

  abrirModalReceituario(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('id', id));
    this.componente = ReceituarioFrmComponent;
  }

  setReceituarioEletronicoSelecionado(receituarioGrade: ReceituarioEletronicoGrade): void {
    receituarioGrade.selecionado = receituarioGrade.selecionado == 'S' ? 'N' : 'S';
  }

  consultarReceituarioEletronico(receituarioGrade: ReceituarioEletronicoGrade): void {
    const servico: string = this.ehEidaf ? this.bibServico.eidafConsultar : this.bibServico.agroDefesaConsultar;
    this.utilSessao.iniciarProcesso();
    this.idMovimentacoes.push(receituarioGrade.idMovimentacao);
    super.persistir(new Transporte(receituarioGrade.receituarioEletronico), servico, null).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.receituariosEletronicoPersistir = [];
        this.listar([], 0, 0, this.idMovimentacoes);
        this.idMovimentacoes = [];
        this.utilSessao.finalizarProcesso();
      } else {
        this.idMovimentacoes = [];
        this.utilSessao.finalizarProcesso();
      }
    });
  }

  ehValido(): Observable<boolean> {
    const movimentacoesSelecionadas = this.receituariosEletronicoGrade.filter(receituarioGrade => receituarioGrade.selecionado === 'S');
    return new Observable<boolean>(observer => {
      this.idMovimentacoes = movimentacoesSelecionadas.map(receituarioGrade => receituarioGrade.idMovimentacao);
      if (this.ehEidaf && movimentacoesSelecionadas[0]?.receituarioEletronico.tipoAssinatura == "") {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
        observer.next(false);
        observer.complete();
      } else if (movimentacoesSelecionadas.length === 0) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
        observer.next(false);
        observer.complete();
      } else if (movimentacoesSelecionadas.length > 1) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarValidacaoReceituarioSomenteUm));
        observer.next(false);
        observer.complete();
      } else {
        let criterios: Criterio = new Criterio('IDS_MOVIMENTACAO', this.idMovimentacoes.toString());
        this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.receituarioEletronicoGrade).subscribe((res) => {
          const receituarioEletronicoGrade = this.plainToClass(ReceituarioEletronicoGrade, res) as any;
          if (receituarioEletronicoGrade.length == 0 || receituarioEletronicoGrade[0]?.transmitido != 'S') {
            observer.next(true);
          } else {
            this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarValidacaoReceituarioEletronico));
            observer.next(false);
          }
          observer.complete();
        });
      }
    });
  }

  converterArquivoBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error('');
      };
    });
  }

  enviarReceituario(arquivoInput: any = null): void {
    if (this.ehEidaf) {
      this.enviarReceituarioEidaf(arquivoInput);
    }
    if (this.ehAgroDefesaGO) {
      this.enviarReceituarioAgroDefesaGO();
    }
  }

  enviarReceituarioEidaf(arquivoInput: any): void {
    this.ehValido().subscribe(
      valido => {
        if (valido) {
          this.comunicacaoService.listar(new Transporte(new Criterio('IDS', this.idMovimentacoes.toString())), this.bibServico.movimentacao).subscribe((res) => {
            this.receituariosEletronicoPersistir = this.receituariosEletronicoGrade.filter((receituarioGrade) => receituarioGrade.selecionado === 'S');
            if (arquivoInput && arquivoInput.target && arquivoInput.target.files && arquivoInput.target.files.length > 0) {
              this.converterArquivoBase64(arquivoInput.target.files[0]).subscribe((res) => {
                this.listar([], 0, 0, this.idMovimentacoes);
                this.arquivoPDFEidaf = res.replace('data:application/pdf;base64,', '');
                this.emitirEidaf();
                if (this.fileInput && this.fileInput.nativeElement) {
                  this.fileInput.nativeElement.value = null;
                }
              });
            } else {
              if (this.fileInput && this.fileInput.nativeElement) {
                this.fileInput.nativeElement.value = null;
              }
            }
          });
        } else {
          if (this.fileInput && this.fileInput.nativeElement) {
            this.fileInput.nativeElement.value = null;
          }
        }
      }
    );
  }

  enviarReceituarioAgroDefesaGO(): void {
    this.ehValido().subscribe(
      valido => {
        if (valido) {
          this.comunicacaoService.listar(new Transporte(new Criterio('IDS', this.idMovimentacoes.toString())), this.bibServico.movimentacao).subscribe((res) => {
            this.receituariosEletronicoPersistir = this.receituariosEletronicoGrade.filter((receituarioGrade) => receituarioGrade.selecionado === 'S');
            this.utilSessao.iniciarProcesso();
            this.listar([], 0, 0, this.idMovimentacoes);
            this.emitirAgroDefesaGO();
          });
        }
      }
    );
  }

  emitirEidaf(): void {
    this.utilSessao.iniciarProcesso();
    this.receituariosEletronicoPersistir.forEach((receituarioEletronicoGrade) => {
      receituarioEletronicoGrade.receituarioEletronico.arquivoPdf = this.arquivoPDFEidaf;
      super.persistir(new Transporte(receituarioEletronicoGrade.receituarioEletronico), this.bibServico.eidafEmitir, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.receituariosEletronicoPersistir = [];
          this.listar([], 0, 0, this.idMovimentacoes);
          this.idMovimentacoes = [];
          this.utilSessao.finalizarProcesso();
        } else {
          this.idMovimentacoes = [];
          this.utilSessao.finalizarProcesso();
        }
      });
    });
  }

  emitirAgroDefesaGO(): void {
    this.utilSessao.iniciarProcesso();
    this.receituariosEletronicoPersistir.forEach((receituarioEletronicoGrade) => {
      super.persistir(new Transporte(receituarioEletronicoGrade.receituarioEletronico), this.bibServico.agroDefesaEmitir, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.receituariosEletronicoPersistir = [];
          this.listar([], 0, 0, this.idMovimentacoes);
          this.idMovimentacoes = [];
          this.utilSessao.finalizarProcesso();
        } else {
          this.idMovimentacoes = [];
          this.utilSessao.finalizarProcesso();
        }
      });
      this.utilSessao.finalizarProcesso();
    });
  }

  enviarCancelamento(receituarioEletronicoGrade: ReceituarioEletronicoGrade) {
    if (receituarioEletronicoGrade.receituarioEletronico.transmitido != 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarCancelamentoReceituarioSomenteEnviado));
    } else {
      if (confirm(this.bibDialogo.enviarSolicitacaoCancelamento)) {
        const servico: string = this.ehEidaf ? this.bibServico.eidafCancelar : this.bibServico.agroDefesaCancelar;
        this.utilSessao.iniciarProcesso();
        this.idMovimentacoes.push(receituarioEletronicoGrade.idMovimentacao);
        super.persistir(new Transporte(receituarioEletronicoGrade.receituarioEletronico), servico, null).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.receituariosEletronicoPersistir = [];
            this.listar([], 0, 0, this.idMovimentacoes);
            this.idMovimentacoes = [];
            this.utilSessao.finalizarProcesso();
          } else {
            this.idMovimentacoes = [];
            this.utilSessao.finalizarProcesso();
          }
        });
      }
    }
  }
}