import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001MovimentoFiscal',
  templateUrl: './r2001MovimentoFiscal.component.html',
})
export class R2001MovimentoFiscalComponent {
  @Input() movimentacaoFiscais: any[];
  public bibDialogo = bibDialogo;
}
