export class IntegracaoUsuario {
  public acessar: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public favorito: string = 'N';
  public filtro: string;
  public id: number;
  public idEmpresa: number;
  public idIntegracao: number;
  public idIntegracaoCategoria: number;
  public idUsuario: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public integracao: string;
  public integracaoCategoria: string;
  public novo: string;
  public ordem: number;
  public principal: string;
  public tipoIntegracaoCategoria: string;
}
