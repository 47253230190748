import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2006.component.html',
})
export class R2006Component extends RelatorioComponent {
  public contaContabeis: any[] = [];
  public dataAte: Date;
  public filtros = filtros;
  public lancamentoContabeis: any[] = [];
  public pagina: number;

  listarRelatorio(criterios: Criterio[]): void {
    this.dataAte = criterios.find((criterio) => criterio.nome == 'DATA_ATE').valor;
    this.pagina = criterios.find((criterio) => criterio.nome == 'PAGINA').valor;
    super.listar(criterios).subscribe(() => {
      this.contaContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ContaContabil').lista;
      this.lancamentoContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'LancamentoContabil').lista;
      this.vincular();
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.contaContabeis.forEach((contaContabil) => {
      contaContabil.lancamentoContabeis = this.lancamentoContabeis.filter((lancamentoContabil) => (lancamentoContabil.idContaContabil == contaContabil.id && lancamentoContabil.debito > 0) || (lancamentoContabil.idContaContabil == contaContabil.id && lancamentoContabil.credito > 0));
      let saldo: number = contaContabil.saldoAnterior;
      contaContabil.debito = 0;
      contaContabil.credito = 0;
      contaContabil.lancamentoContabeis.forEach((lancamentoContabil) => {
        saldo += lancamentoContabil.debito;
        saldo -= lancamentoContabil.credito;
        lancamentoContabil.saldoAtual = saldo;
        contaContabil.debito += lancamentoContabil.debito;
        contaContabil.credito += lancamentoContabil.credito;
      });
    });
  }
}
