import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';

@Component({
  selector: ' <tr>[r1053itens]</tr>',
  templateUrl: './r1053itens.component.html',
})
export class R1053ItensComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacao: any;
  @Input() usaNegociacao: boolean;
  public bibDialogo = bibDialogo;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
}
