import { Component } from '@angular/core';
import { Caixa } from 'src/app/modelo/caixa';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './caixa.component.html',
})
export class CaixaComponent extends PaginaComponent {
  public ativo: boolean = false;
  public caixas: Caixa[] = [];
  public filtros: Filtro[] = filtros;
  public name: string;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.caixa, true).subscribe((res) => {
      this.caixas = this.plainToClass(Caixa, res) as any;
    });
  }

  abrirCaixa(caixa: Caixa){
      let dataHoraFechamento: Date = caixa.dataHoraFechamento;
      caixa.dataHoraFechamento = null;
      super.persistir(new Transporte(caixa, 0, 0, false, null), this.bibServico.caixa, null).subscribe((res) => {
        if(this.utilSessao.falha){
          caixa.dataHoraFechamento = dataHoraFechamento;
        }
      });
  }

  excluirCaixa(id: number, nome: string): void {
    super.excluir<Caixa>(id, this.caixas, nome, this.bibServico.caixa);
  }
}
