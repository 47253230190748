<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.ajusteDeEstoque }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
    <th class="r">{{ bibDialogo.responsavel }}</th>
    <th class="r-2 text-right">{{ bibDialogo.operacao }}</th>
    <th class="r-1-5 text-right">{{ bibDialogo.ajuste }}</th>
    <th class="r-1-5 text-right">{{ bibDialogo.entrada }}</th>
    <th class="r-1-5 text-right">{{ bibDialogo.saida }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoAjusteEstoque of movimentacaoAjusteEstoques">
      <tr>
        <td>{{ movimentacaoAjusteEstoque.loja }}</td>
        <td class="text-right">{{ movimentacaoAjusteEstoque.numero }}</td>
        <td>{{ movimentacaoAjusteEstoque.vendedorNumero + ' - ' + movimentacaoAjusteEstoque.vendedor }}</td>
        <td class="text-right">{{ movimentacaoAjusteEstoque.operacao }}</td>
        <td class="text-right">{{ movimentacaoAjusteEstoque.totalAjuste | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ movimentacaoAjusteEstoque.totalEntrada | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ movimentacaoAjusteEstoque.totalSaida | monetario: this.empresaRegraCasasDecimais }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoAjusteEstoques.length > 0">
    <tr>
      <td colspan="4"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoAjusteEstoques[0].valorTotalAjuste | monetario: this.empresaRegraCasasDecimais }}</strong>
      </td>      
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoAjusteEstoques[0].valorTotalEntrada | monetario: this.empresaRegraCasasDecimais }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoAjusteEstoques[0].valorTotalSaida | monetario: this.empresaRegraCasasDecimais }}</strong>
      </td>   
    </tr>
  </tfoot>
</table>
