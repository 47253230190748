<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.situacaoMovimentacaoParcela }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r">{{ bibDialogo.operacao }}</th>
    <th class="r-1">{{ bibDialogo.quantidade }}</th>
    <th class="r-1">{{ bibDialogo.quantidadeTotalAbreviado }}</th>
    <th class="r-0-5">{{ bibDialogo.esperado }}</th>
    <th class="r-0-5">{{ bibDialogo.encontradoAbreviacao }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let situacaoMovimentacaoParMovimentacao of situacaoMovimentacaoParMovimentacoes">
      <tr class="conteudo">
        <td>{{ situacaoMovimentacaoParMovimentacao.id }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.data | data }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.numero }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.idLoja + ' - ' + situacaoMovimentacaoParMovimentacao.loja }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.idEmpresa + ' - ' + situacaoMovimentacaoParMovimentacao.empresa }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.idOperacao + ' - ' + situacaoMovimentacaoParMovimentacao.operacao }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.quantidadeEsperada | monetario }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.quantidadeEncontrada | monetario }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.situacaoEsperada }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.situacaoEncontrada }}</td>
        <td>{{ situacaoMovimentacaoParMovimentacao.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
