import { Component } from '@angular/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { BloqueioPeriodo } from 'src/app/modelo/bloqueioPeriodo';
import { Criterio } from 'src/app/modelo/criterio';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './bloqueioPeriodoFrm.component.html',
})
export class BloqueioPeriodoFrmComponent extends PaginaComponent {
  public bloqueiaPeriodo: BloqueioPeriodo = new BloqueioPeriodo();
  public parametro: number;
  public movimentacoesAbertas: Movimentacao[] = [];
  public nfesSemChave: Movimentacao[] = [];
  public operacoesComMovimentacaoAbertas: any;
  public operacoesFormatadas: string;
  public dataDe: string;
  public dataAte: string;
  public valido: boolean = false;

  ngOnInit(): void {
    this.parametro = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.bloqueiaPeriodo = this.plainToClass(BloqueioPeriodo, this.ehAtualizacao(this.bloqueiaPeriodo));
    if (this.bloqueiaPeriodo.id) {
      this.listarBloqueiaPeriodo(this.bloqueiaPeriodo.id);
    }
  }

  listarBloqueiaPeriodo(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.bloqueioPeriodo).subscribe((res) => {
      this.bloqueiaPeriodo = this.plainToClass(BloqueioPeriodo, res[0]) as any;
    });
  }

  ehPersistirBloqueiaPeriodo(novo: boolean = false): void {
    const bloqueioMovimentacao: number = 1;
    if (this.parametro == bloqueioMovimentacao && this.bloqueiaPeriodo.ativo == 'S') {
      this.formatarData();
      this.listarMovimentacoesAbertas().subscribe(() => {
        this.listarNfeSemChave().subscribe(() => {
          if (this.ehValidoObrigatorio() && this.ehValidoData()) {
            if (this.movimentacoesAbertas.length == 0 && this.nfesSemChave.length == 0) {
              this.persistirBloqueiaPeriodo(novo);
            }
          }
        });
      });
    } else {
      this.persistirBloqueiaPeriodo(novo);
    }
  }

  formatarData(): void {
    this.dataDe = format(new Date(Date.parse(this.bloqueiaPeriodo.dataDe.toString())), 'yyyy-MM-dd');
    this.dataAte = format(new Date(Date.parse(this.bloqueiaPeriodo.dataAte.toString())), 'yyyy-MM-dd');
  }

  listarMovimentacoesAbertas(): Observable<any> {
    const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte([new Criterio('STATUS', 'A'), new Criterio('ID_LOJA', this.bloqueiaPeriodo.idLoja), new Criterio('DATA_DE', this.dataDe), new Criterio('DATA_ATE', this.dataAte)]), this.bibServico.movimentacao);
    resposta.subscribe((res) => {
      this.movimentacoesAbertas = this.plainToClass(Movimentacao, res);
      if (this.movimentacoesAbertas.length > 0) {
        this.separarMovimentacoesPorOperacao();
      }
    });
    return resposta;
  }

  separarMovimentacoesPorOperacao(): void {
    let operacoesMovimentacoes: any[] = [];
    this.movimentacoesAbertas.forEach((movimentacao) => operacoesMovimentacoes.push(movimentacao.operacao));
    const operacaoes: any[] = operacoesMovimentacoes.reduce((contador, operacao) => ((contador[operacao] = contador[operacao] + 1 || 1), contador), {});
    if (operacoesMovimentacoes.length > 0) {
      for (var atributo in operacaoes) {
        this.utilSessao.setResultado(new Resultado(false, 'Operação: ' + atributo + ', contém ' + operacaoes[atributo] + ' registros abertos'));
      }
    }
  }

  listarNfeSemChave(): Observable<any> {
    const resposta = this.comunicacaoService.listar(new Transporte([new Criterio('PERMITE_TRANSMITIR_NFE', 'S'), new Criterio('SEM_CHAVE', 'S'), new Criterio('ID_LOJA', this.bloqueiaPeriodo.idLoja), new Criterio('DATA_DE', this.dataDe), new Criterio('DATA_ATE', this.dataAte)]), this.bibServico.movimentacao);
    resposta.subscribe((res) => {
      this.nfesSemChave = this.plainToClass(Movimentacao, res);
      if (this.nfesSemChave.length > 0) {
        this.separarNfesPorOperacao();
      }
    });
    return resposta;
  }

  separarNfesPorOperacao(): void {
    let operacoesNfes: any[] = [];
    this.nfesSemChave.forEach((nfe) => operacoesNfes.push(nfe.operacao));
    const operacaoesNfe: any[] = operacoesNfes.reduce((contador, operacao) => ((contador[operacao] = contador[operacao] + 1 || 1), contador), {});
    if (operacoesNfes.length > 0) {
      for (var atributo in operacaoesNfe) {
        this.utilSessao.setResultado(new Resultado(false, 'Operação: ' + atributo + ', contém ' + operacaoesNfe[atributo] + ' sem transmissão'));
      }
    }
  }

  ehValidoObrigatorio(): boolean {
    if ((this.bloqueiaPeriodo.motivo || this.parametro == 3) && this.bloqueiaPeriodo.dataDe && this.bloqueiaPeriodo.dataAte && this.bloqueiaPeriodo.idLoja) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoData(): boolean {
    if (new Date(this.bloqueiaPeriodo.dataAte) > new Date(this.bloqueiaPeriodo.dataDe)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataMaiorDataAte));
    return false;
  }

  persistirBloqueiaPeriodo(novo: boolean): void {
    this.bloqueiaPeriodo.tipo = this.parametro;
    super.persistir(new Transporte(this.bloqueiaPeriodo), this.bibServico.bloqueioPeriodo, novo ? new BloqueioPeriodo() : null);
  }
}
