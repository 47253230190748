import { Cofins } from "./cofins";
import { Icms } from "./icms";
import { Ipi } from "./ipi";
import { Pis } from "./pis";

export class Imposto {
    public vTotTrib: string;
    public ICMS: Icms;
    public IPI: Ipi;
    public PIS: Pis;
    public COFINS: Cofins;
}