import { Type } from 'class-transformer';
import { FinanceiroBaixa } from './financeiroBaixa';
import { TipoTitulo } from './tipoTitulo';

export class Conciliacao {
  @Type(() => Date)
  public data: Date;
  @Type(() => Date)
  public dataConciliacao: Date;
  @Type(() => Date)
  public dataBaixa: Date = new Date();
  @Type(() => Date)
  public dataLancamento: Date = new Date();
  @Type(() => Date)
  public dataPrevista: Date;

  public abreviacao: string;
  public bloqueadoComissao: string = 'N';
  public chave: string;
  public conciliado: string;
  public conta: string;
  public contaTipo: string;
  public desconto: number = 0;
  public expandido: string;
  public financeiroBaixas: FinanceiroBaixa[] = [];
  public id: number;
  public idCheque: number;
  public idConciliacaoVinculada: number;
  public idConta: number;
  public idEmpresa: number;
  public idEspecie: number;
  public idLoja: number;
  public idParceiro: number;
  public idTipoTitulo: number;
  public idTransferencia: number;
  public idTransferenciaCheque: number;
  public juro: number = 0;
  public nomeParceiro: string;
  public numeroDocumento: string;
  public observacao: string;
  public origem: number;
  public parcela: string;
  public tipo: string;
  public tipoTitulo: string;
  public tipoTituloDia: number;
  public tipoTituloIdEspecie: number;
  public tipoTituloRegraRecebimento: number;
  public tiposTituloPermitidos: TipoTitulo[] = [];
  public transferenciaId: number;
  public valor: number;
  public valorLiquido: number;
  public valorTotal: number;
  public chaveCartaoTransferencia: string;
  public idCaixa: number;
  public valorTroco: number;
  public contaTransferencia: String;
  public selecionada: string = 'N';

}
