import { EventEmitter, Injectable } from '@angular/core';
import { IntegracaoUsuario } from '../modelo/integracaoUsuario';
import { RelatorioAdicional } from '../modelo/relatorioAdicional';
import { RelatorioUsuario } from '../modelo/relatorioUsuario';

@Injectable()
export class UtilNotificacao {
  acrescentaNomeEmt = new EventEmitter<string>();
  adicionaProdutos = new EventEmitter<boolean>();
  dataAtualEmitter = new EventEmitter<Date>();
  ehAgrupaOcultado = new EventEmitter<boolean>();
  ehModoClaroEmmiter = new EventEmitter<boolean>();
  limpaBuscaEmt = new EventEmitter<boolean>();
  menuPersonalizadoEmt = new EventEmitter<number>();
  menuPrincipalEmitter = new EventEmitter<boolean>();
  modalEmt = new EventEmitter<string>();
  modalRelatorioEmitter = new EventEmitter<boolean>();
  modalMovEmitter = new EventEmitter<boolean>();
  mostrarIdEmitter = new EventEmitter<boolean>();
  ocultaConta = new EventEmitter<boolean>();
  ocultaRoteiroContabil = new EventEmitter<boolean>();
  pesquisaEmitter = new EventEmitter<Object>();
  pesquisaProdutoEmitter = new EventEmitter<boolean>();
  pesquisandoClienteEmitter = new EventEmitter<boolean>();
  pesquisandoEmitter = new EventEmitter<boolean>();
  pesquisandoFotoEmitter = new EventEmitter<boolean>();
  pesquisandoMovimentacaoEmitter = new EventEmitter<boolean>();
  proximoEmitter = new EventEmitter<number>();
  relatorioAdicionalEmitter = new EventEmitter<RelatorioAdicional>();
  relatoriosEmt = new EventEmitter<RelatorioUsuario[]>();
  dashboardEmt = new EventEmitter<RelatorioUsuario[]>();
  salvarEmt = new EventEmitter<boolean>();
  finalizarEmt = new EventEmitter<boolean>();
  servicoEmitter = new EventEmitter<String>();
  sucessoEmitter = new EventEmitter<boolean>();
  usaRelatorioEmt = new EventEmitter<boolean>();
  usuarioIntegracaoEmt = new EventEmitter<IntegracaoUsuario[]>();
  verificarAprovadoEmitter = new EventEmitter<boolean>();
  emtUtilizaModoClaro = new EventEmitter<boolean>();
  alterandoSenhaEmitter = new EventEmitter<boolean>();
  agrupamento = new EventEmitter<Object>();

  setAlterandoSenha(): void {
    this.alterandoSenhaEmitter.emit(false);
  }

  retornarPesquisa(objeto: Object) {
    this.pesquisaEmitter.emit(objeto);
  }

  setMenuPrincipal(recarregaMenu: boolean = false) {
    this.menuPrincipalEmitter.emit(recarregaMenu);
  }

  setSucesso() {
    this.sucessoEmitter.emit(true);
  }

  setVerificarAprovado() {
    this.verificarAprovadoEmitter.emit(true);
  }

  setPesquisaProduto() {
    this.pesquisaProdutoEmitter.emit(true);
  }

  setModalMov() {
    this.modalMovEmitter.emit(true);
  }

  setServico(servico: String) {
    this.servicoEmitter.emit(servico);
  }

  setProximo(idMenuAnterior: number) {
    this.proximoEmitter.emit(idMenuAnterior);
  }

  setPesquisando(pesquisando: boolean) {
    this.pesquisandoEmitter.emit(pesquisando);
  }

  setPesquisandoCliente(pesquisandoCliente: boolean) {
    this.pesquisandoClienteEmitter.emit(pesquisandoCliente);
  }

  setPesquisandoFoto(pesquisandoFoto: boolean) {
    this.pesquisandoFotoEmitter.emit(pesquisandoFoto);
  }

  setPesquisandoMovimentacao(pesquisandoMovimentacao: boolean) {
    this.pesquisandoMovimentacaoEmitter.emit(pesquisandoMovimentacao);
  }

  setMostrarId(mostraId: boolean): void {
    this.mostrarIdEmitter.emit(mostraId);
  }

  setRelatorioAdicional(relatorioAdicional: RelatorioAdicional): void {
    this.relatorioAdicionalEmitter.emit(relatorioAdicional);
  }

  setEhModoClaro(ehModoClaro: boolean): void {
    this.ehModoClaroEmmiter.emit(ehModoClaro);
  }

  usarRelatorio() {
    this.usaRelatorioEmt.emit(true);
  }

  setEhAgrupaOcultado(ehAgrupadoOcultado: boolean) {
    this.ehAgrupaOcultado.emit(ehAgrupadoOcultado);
  }

  setModalRelatorioEmitter(modal: boolean) {
    this.modalRelatorioEmitter.emit(modal);
  }

  setAgrupamento(objeto: Object) {
    this.agrupamento.emit(objeto);
  }
}
