import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboOperacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'INSERÇÃO', id: '0' },
  { nome: 'EDIÇÃO', id: '1' },
  { nome: 'EXCLUSÃO', id: '2' },  
  { nome: 'TODOS', id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroUnico, nome: 'ID_REGISTRO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.menu, nome: 'ID_MENU', coluna: 6, servicoWeb: bibServico.menu, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.usuario, nome: 'USUARIO_LOG', coluna: 6, servicoWeb: bibServico.usuario, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.situacao, nome: 'OPERACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboOperacao },
]);
export default filtros;
