import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Relatorio } from 'src/app/modelo/relatorio';
import { RelatorioAdicionalEmpresa } from 'src/app/modelo/relatorioAdicionalEmpresa';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { RelatorioPersonalizadoFrmComponent } from './relatorioPersonalizadoFrm/relatorioPersonalizadoFrm.component';

@Component({
  selector: 'relatorioPersonalizado',
  templateUrl: './relatorioPersonalizado.component.html',
})
export class RelatorioPersonalizadoComponent extends PaginaComponent {
  private relatorioAdicionalEmpresas: RelatorioAdicionalEmpresa[] = [];
  public campoPesquisa: string = this.bibDialogo.nome;
  public componente: any;
  public filtro: string = '';
  public relatorios: Relatorio[] = [];
  public relatoriosBusca: Relatorio[] = [];

  public listaCampoPesquisa: any = [
    { id: this.bibDialogo.modulo, nome: this.bibDialogo.modulo },
    { id: this.bibDialogo.nome, nome: this.bibDialogo.nome },
    { id: this.bibDialogo.numero, nome: this.bibDialogo.numero },
  ];

  ngOnInit(): void {
    this.listarRelatorios();
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.relatorioAdicionalEmpresa) {
        identificacao.conteudo.forEach((relatorioAdicionalEmpresa) => {
          let ehRelatorioAdicionalEmpresaExistente: boolean = this.relatorioAdicionalEmpresas.find((relatorioAdicionalEmpresaBusca) => relatorioAdicionalEmpresaBusca.idRelatorioAdicional == relatorioAdicionalEmpresa.idRelatorioAdicional && relatorioAdicionalEmpresaBusca.idRelatorio == relatorioAdicionalEmpresa.idRelatorio) != undefined;
          if (!ehRelatorioAdicionalEmpresaExistente) {
            this.relatorioAdicionalEmpresas.push(relatorioAdicionalEmpresa);
          }
        });
      }
    });
  }

  listarRelatorios(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('SISTEMA', this.utilSessao.sistema), new Criterio('ATIVO', 'S')]), this.bibServico.relatorio).subscribe((res) => {
      this.relatorios = this.plainToClass(Relatorio, res) as any;
      this.ordenarRelatorios();
      this.relatoriosBusca = this.plainToClass(Relatorio, res) as any;
    });
  }

  persistirRelatorioAdicionalEmpresa(): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.relatorioAdicionalEmpresas), this.bibServico.relatorioAdicionalEmpresa, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.relatorioAdicionalEmpresas = [];
        }
      });
    }
  }

  ehValido(): boolean {
    if (this.relatorioAdicionalEmpresas.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.modificacaoEmRelatorioNaoIdentificada));
    return false;
  }

  abrirModalRelatorioAdicionalEmpresa(idRelatorio: number, relatorioNome: string, layoutEspecifico: string): void {
    this.utilSessao.setIdentificacao(new Identificacao('idRelatorio', idRelatorio));
    this.utilSessao.setIdentificacao(new Identificacao('relatorioNome', relatorioNome));
    this.utilSessao.setIdentificacao(new Identificacao('layoutEspecifico', layoutEspecifico));
    this.utilSessao.setIdentificacao(new Identificacao('relatorioAdicionalEmpresas', this.getRelatorioAdicionalEmpresas(idRelatorio)));
    this.componente = RelatorioPersonalizadoFrmComponent;
  }

  getRelatorioAdicionalEmpresas(idRelatorio): RelatorioAdicionalEmpresa[] {
    return this.relatorioAdicionalEmpresas.filter((relatorioAdicionalEmpresa) => (relatorioAdicionalEmpresa.idRelatorio = idRelatorio));
  }

  alterarValorFiltro(valor: string): void {
    if (this.campoPesquisa != this.bibDialogo.numero && Number(valor)) {
      this.campoPesquisa = this.bibDialogo.numero;
    } else {
      if (this.campoPesquisa == this.bibDialogo.numero && !Number(valor)) {
        this.campoPesquisa = this.bibDialogo.nome;
      }
    }
    this.setFiltro(valor);
  }

  setFiltro(filtro: string): void {
    this.filtro = filtro;
    if (this.filtro != null) {
      if (this.campoPesquisa == this.bibDialogo.nome) {
        this.relatorios = this.relatoriosBusca.filter((relatorio) => relatorio.nome.toLowerCase().includes(this.filtro.toLowerCase()));
        this.ordenarRelatorios();
      } else if (this.campoPesquisa == this.bibDialogo.modulo) {
        this.relatorios = this.relatoriosBusca.filter((relatorio) => relatorio.relatorioModulo.toLowerCase().includes(this.filtro.toLowerCase()));
        this.ordenarRelatorios();
      } else if (this.campoPesquisa == this.bibDialogo.numero) {
        this.relatorios = this.relatoriosBusca.filter((relatorio) => relatorio.id.toString() == this.filtro.toString());
        this.ordenarRelatorios();
      }
    } else {
      this.relatorios = this.relatoriosBusca;
      this.ordenarRelatorios();
    }
  }

  ordenarRelatorios(): void {
    this.relatorios.sort((a, b) => (Number(a['id']) == Number(b['id']) ? 0 : Number(a['id']) < Number(b['id']) ? -1 : 1));
  }
}
