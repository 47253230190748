export class Imposto {
  public cfop: string;
  public cofinsAliquota: number;
  public cofinsCst: string;
  public icmsAliquota: number;
  public icmsBase: number;
  public icmsCst: string;
  public id: number;
  public idCfop: number;
  public idCstCofins: number;
  public idCstIcms: string;
  public idCstPis: number;
  public idIpi: string;
  public ipiAliquota: number;
  public ipiCst: string;
  public pisAliquota: number;
  public pisCst: string;
}
