import { Component, Input } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';

@Component({
  selector: 'cabecalhoGrupo',
  templateUrl: './cabecalhoGrupo.component.html',
})
export class CabecalhoGrupoComponent {
  @Input() i: number;
  @Input() relatorioGrupo: RelatorioGrupo;
  @Input() ehAgrupadoOcultado: boolean = false;
}
