<layout [titulo]="bibDialogo.equipe" [id]="equipe.id" (voltarAvancarEmt)="ehAlteracao()">
  <div *ngIf="visualizaLoja" class="row">
    <lista class="col-sm-12" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="equipe.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="setLoja($event)" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()" [ajudaDireita]="true" [foco]="true"></lista>
  </div>
  <div class="row">
    <busca class="col-sm-12" [id]="'cidade'" [rotulo]="bibDialogo.gestor" [campo]="equipe.gestor" (alteracao)="setGestor($event)" [servico]="bibServico.colaborador" [obrigatorio]="true"></busca>
  </div>
  <div class="row">
    <equipeParceiro [equipe]="equipe" [excluirEquipeParceiros]="excluirEquipeParceiros" [excluirEquipeParceiros]="excluirEquipeParceiros"></equipeParceiro>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirEquipe()"></botao>
<auditoria *ngIf="equipe.id" [idUsuarioInclusao]="equipe.idUsuarioInclusao" [dataHoraInclusao]="equipe.dataHoraInclusao" [idUsuarioAlteracao]="equipe.idUsuarioAlteracao" [dataHoraAlteracao]="equipe.dataHoraAlteracao"></auditoria>
