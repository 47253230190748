import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './financeiro.component.html',
})
export class FinanceiroComponent extends PaginaComponent {
  private params: any;
  public componente = null;
  public filtros: Filtro[];
  public financeiroTipo: string = '';
  public financeiros: Financeiro[] = [];
  public tipo: string = '';
  public totalFinanceiros: any[] = [];
  public visualizaTotalizadores: boolean = false;
  public somenteUmParceiro: boolean = false;

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.tipo = params['tipo'].substring(0, 1);
      this.financeiroTipo = params['tipo'];
      this.financeiros = [];
      this.selecionarFiltro();
    });
  }

  selecionarFiltro(): void {
    switch (this.financeiroTipo) {
      case 'RR':
        this.filtros = filtros.filtroRenegociacaoReceita;
        break;
      case 'DD':
        this.filtros = filtros.filtroRenegociacaoDespesa;
        break;
      case 'D':
        this.filtros = filtros.filtroDespesa;
        break;
      case 'R':
        this.filtros = filtros.filtroReceita;
        break;
    }
    this.visualizaTotalizadores = false;
    this.somenteUmParceiro = false;
  }

  ngOnDestroy(): void {
    this.params.unsubscribe();
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('TIPO', this.tipo));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.financeiro).subscribe((res) => {
      this.financeiros = this.plainToClass(Financeiro, res) as any;
      if (this.financeiros.length > 0) {
        this.visualizaTotalizadores = this.financeiros.length > 1 ? true : false;
        this.somenteUmParceiro = this.financeiros.length == this.financeiros.filter((financeiro) => financeiro.idParceiro == this.financeiros[0].idParceiro).length;
        if (this.visualizaTotalizadores) {
          this.totalizarFinanceiros();
        }
      }
    });
  }

  excluirFin(financeiro: Financeiro): void {
    const nome: string = 'Financeiro número ' + financeiro.numero + ' do parceiro ' + financeiro.parceiro + ' no valor restante de ' + this.util.formatarMilhar(financeiro.valorRestante) + ' e vencimento para ' + financeiro.dataVencimento;
    super.excluir<Financeiro>(financeiro.id, this.financeiros, nome).subscribe(() => {
      this.totalizarFinanceiros();
    });
  }

  totalizarFinanceiros(): void {
    this.totalFinanceiros = [];
    let valorFinalTotalizado: number = 0;
    let valorRestanteTotalizado: number = 0;
    for (let financeiro of this.financeiros) {
      valorFinalTotalizado += financeiro.valorFinal;
      valorRestanteTotalizado += financeiro.valorRestante;
    }
    this.totalFinanceiros.push({ totalValorFinal: valorFinalTotalizado, totalValorRestante: valorRestanteTotalizado });
  }
}
