import { Component } from '@angular/core';
import { BloqueioPeriodo } from 'src/app/modelo/bloqueioPeriodo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './bloqueioPeriodo.component.html',
})
export class BloqueioPeriodoComponent extends PaginaComponent {
  public bloqueioPeriodos: BloqueioPeriodo[] = [];
  public filtros: Filtro[] = filtros;
  public parametro: number;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.bloqueioPeriodos = [];
      this.parametro = Number(params.parametro);
      this.utilSessao.setIdentificacao(new Identificacao('parametro', this.parametro));
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('TIPO', this.parametro));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.bloqueioPeriodo).subscribe((res) => {
      this.bloqueioPeriodos = this.plainToClass(BloqueioPeriodo, res) as any;
    });
  }

  excluirBloqueioPeriodo(id: number, nome: string): void {
    super.excluir(id, this.bloqueioPeriodos, nome);
  }
}
