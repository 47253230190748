import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { RelatorioComponent } from '../../relatorio.component';

@Component({
  selector: ' <tr>[r1001notas]</tr>',
  templateUrl: './r1001notas.component.html',
})
export class R1001NotasComponent extends RelatorioComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacaoProduto: any;
  @Input() pedidoCarteira: boolean = false;
  public bibDialogo = bibDialogo;
  public componente: any;

  abrirModalMovimentacao(id: number): void {
    this.componente = this.abrirModalRelatorio(MovimentacaoFrmComponent, [new Identificacao('objeto', id)], null);
  }
}
