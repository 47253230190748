<layout [titulo]="visita.parceiro" [id]="visita.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <data class="col-sm" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="visita.data" (alteracao)="visita.data = $event" [obrigatorio]="true"></data>
    <!-- <mascara class="col-sm-3" [rotulo]="bibDialogo.horarioInicial" [campo]="visita.horaInicio" (alteracao)="setHoraInicio($event)" [tipo]="bibPropriedade.mascara.horaMinuto"  [obrigatorio]="true" [foco]="true"></mascara>
    <mascara class="col-sm-3" [rotulo]="bibDialogo.horarioFinal" [campo]="visita.horaFim" (alteracao)="setHoraFim($event)" [tipo]="bibPropriedade.mascara.horaMinuto"  [obrigatorio]="true"></mascara> -->
  </div>
  <div class="row">
    <busca class="col-sm" [id]="'responsavel'" [idMenu]="56" [rotulo]="bibDialogo.responsavel" [campo]="visita.responsavel" (alteracao)="setResponsavel($event)" [servico]="bibServico.colaborador" [obrigatorio]="true"></busca>
  </div>  
  <div class="row">
    <busca class="col-sm" [id]="'parceiro'" [idMenu]="5" [rotulo]="bibDialogo.cliente" [campo]="visita.parceiro" (alteracao)="setParceiro($event)" [servico]="bibServico.cliente" [obrigatorio]="true"></busca>
  </div>
  <div class="row">    
    <descricao class="col-sm" [id]="'descricao'" [rotulo]="bibDialogo.descricao" [campo]="visita.descricao" (alteracao)="visita.descricao = $event" [maximoCaracteres]="1000"></descricao>
  </div> 
  <div class="row">
    <data class="col-sm" [id]="'data'" [rotulo]="bibDialogo.proxima + ' ' + bibDialogo.visita" [campo]="visita.dataProxima" (alteracao)="visita.dataProxima = $event"></data>
  </div>  
  <carrossel [pasta]="'visita'" [idPai]="visita.id" [fotos]="visita.visitaFotos" (imagem)="abrirModalImagem($event)"></carrossel>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirVisita()" [desabilitado]="bloquearPermissao(visita.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirVisita(true)" [desabilitado]="bloquearPermissao(visita.id)"></botao>
<auditoria *ngIf="visita.id" [idUsuarioInclusao]="visita.idUsuarioInclusao" [dataHoraInclusao]="visita.dataHoraInclusao" [idUsuarioAlteracao]="visita.idUsuarioAlteracao" [dataHoraAlteracao]="visita.dataHoraAlteracao"></auditoria>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>