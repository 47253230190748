import { Dest } from "./dest";
import { Emit } from "./emit";
import { Ide } from "./ide";
import { Imp } from "./imp";
import { Compl } from "./compl";
import { InfCTeNorm } from "./infCTeNorm";
import { ObsCont } from "./obsCont";
import { Rem } from "./rem";
import { VPrest } from "./vPrest";

export class InfCte {
  public ide: Ide;
  public obsCont: ObsCont[];
  public emit: Emit;
  public rem: Rem;
  public dest: Dest;
  public vPrest: VPrest;
  public imp: Imp;
  public infCTeNorm: InfCTeNorm;
  public compl: Compl;
  public versao: string = "4.00"
}