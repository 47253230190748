<div class="row">
  <mascara class="col-sm-4" [id]="'cep'" [rotulo]="bibDialogo.cep" [campo]="objeto.cep" (alteracao)="setCep($event)" [tipo]="bibPropriedade.mascara.cep" [obrigatorio]="true" [focoId]="focoCep"></mascara>
  <div class="col-sm"></div>
  <interruptor *ngIf="tipo != bibDialogo.loja" [rotulo]="bibDialogo.ativo" [campo]="objeto.ativo" (alteracao)="objeto.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
</div>
<div class="row">
  <lista class="col-sm-4" [id]="'logradouro'" [rotulo]="bibDialogo.logradouro" [campo]="objeto.enderecoTipo && objeto.enderecoTipo != '' ? objeto.enderecoTipo : objeto.tipo" (alteracao)="setTipoEndereco($event)" [lista]="tipoEnderecos" [obrigatorio]="true" [focoId]="focoTipoEndereco"></lista>
  <texto class="col-sm-8" [id]="'endereco'" [rotulo]="bibDialogo.endereco" [campo]="objeto.endereco" (alteracao)="objeto.endereco = $event" [obrigatorio]="true" [maximoCaracteres]="200"></texto>
</div>
<div class="row">
  <texto class="col-sm-4" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="objeto.numero" (alteracao)="objeto.numero = $event" [obrigatorio]="true" [focoId]="focoNumero"></texto>
  <busca class="col-sm-8" [id]="'bairro'" [rotulo]="rotuloBairro" [campo]="objeto.bairro" [idMenu]="permissaoBairro && permissaoBairro.inserir == 'S' ? menuBairro.idMenu : null" (alteracao)="setBairro($event)" [servico]="bibServico.bairro" [obrigatorio]="true"></busca>
</div>
<div class="row">
  <texto class="col-sm-12" [id]="'complemento'" [rotulo]="bibDialogo.complemento" [campo]="objeto.complemento" (alteracao)="objeto.complemento = $event" [maximoCaracteres]="50"></texto>
</div>
<div class="row">
  <busca class="col-sm-8" [id]="'cidade'" [rotulo]="rotuloCidade" [campo]="objeto.cidade" (alteracao)="setCidade($event)" [servico]="bibServico.cidade" [obrigatorio]="true"></busca>
  <lista *ngIf="tipo != bibDialogo.loja" class="col-sm-4" [rotulo]="bibDialogo.estado" [campo]="objeto.idEstado" (alteracao)="objeto.idEstado = $event.id" [lista]="estados" [obrigatorio]="true" [desabilitado]="true"></lista>
</div>
<div *ngIf="utilSessao.sistema == 'aGrow-w'" class="row">
  <busca class="col-sm-8" [id]="'regiao'" [rotulo]="bibDialogo.regiao" [campo]="objeto.regiao" (alteracao)="setRegiao($event)" [servico]="bibServico.regiao"></busca>
</div>
<div *ngIf="utilSessao.sistema == 'aGrow-w'" class="row">
  <texto class="col-sm" [id]="'latitude'" [rotulo]="bibDialogo.latitude" [campo]="objeto.latitude" (alteracao)="objeto.latitude = $event" [maximoCaracteres]="20"></texto>
  <texto class="col-sm" [id]="'longitude'" [rotulo]="bibDialogo.longitude" [campo]="objeto.longitude" (alteracao)="objeto.longitude = $event" [maximoCaracteres]="20"></texto>
</div>
<div *ngIf="lojaEmiteReceituarioEletronico" class="row">
  <texto class="col-sm" [id]="'utme'" [rotulo]="bibDialogo.utme" [campo]="objeto.utme" (alteracao)="objeto.utme = $event" [maximoCaracteres]="20"></texto>
  <texto class="col-sm" [id]="'utmn'" [rotulo]="bibDialogo.utmn" [campo]="objeto.utmn" (alteracao)="objeto.utmn = $event" [maximoCaracteres]="20"></texto>
</div>
<div class="row">
  <lista *ngIf="tipo != bibDialogo.loja && mostrarVinculo" class="col-sm-12" [id]="'tipoEndereco'" [rotulo]="bibDialogo.tipoEndereco" [campo]="objeto.idVinculo" (alteracao)="objeto.idVinculo = $event.id" [lista]="vinculos" [obrigatorio]="true" [idMenu]="permissaoVinculo && permissaoVinculo.inserir == 'S' ? menuVinculo.idMenu : null" [icone]="bibIcone.atualizar" (btnAdicional)="listarVinculo()"></lista>
</div>
