import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001MovimentacaoAjusteEstoque',
  templateUrl: './r2001MovimentacaoAjusteEstoque.component.html',
})
export class R2001MovimentacaoAjusteEstoqueComponent {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacaoAjusteEstoques: any[];
  public bibDialogo = bibDialogo;
}
