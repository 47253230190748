import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { id: 'categoria', rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  { id: 'codigoBarra', rotulo: bibDialogo.codigoDeBarra, nome: 'CODIGO_BARRA', coluna: 6, tipo: bibPropriedade.filtro.texto },
]);
export default filtros;
