import { UtilSessao } from '../utilitario/util.sessao';

export class UtilFiltro {
  private utilSessao: UtilSessao = new UtilSessao();
  public apelidoTelaFabricante: string = '';
  public apelidoTelaCategoria: string = '';
  public apelidoTelaGrupo: string = '';
  public apelidoTelaPraca: string = '';
  public apelidoTelaTipoTitulo: string = '';
  public apelidoTelaRelacionamento: string = '';
  public apelidoCategoriaRoteiroContabil: string = '';
  public apelidoTelaContaBancaria: string = '';
  public apelidoTelaLocal: string = '';
  public apelidoTelaLote: string = '';
  public apelidoCentroResultado: string = '';
  public apelidoPropriedade: string = 'Propriedade';

  constructor() {
    if (this.utilSessao.getMenuEmpresa()) {
      this.apelidoTelaCategoria = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 12)?.apelido;
      this.apelidoTelaFabricante = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 9)?.apelido;
      this.apelidoTelaGrupo = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 11)?.apelido;
      this.apelidoTelaPraca = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 6)?.apelido;
      this.apelidoTelaTipoTitulo = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30)?.apelido;
      this.apelidoTelaRelacionamento = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 122)?.apelido;
      this.apelidoCategoriaRoteiroContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 157)?.apelido;
      this.apelidoTelaContaBancaria = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 29)?.apelido;
      this.apelidoTelaLocal = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 120)?.apelido;
      this.apelidoTelaLote = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 151)?.apelido;
      this.apelidoCentroResultado = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 32).apelido;
    }

    if (this.utilSessao.getNomenclaturaEmpresas()) {
      this.apelidoPropriedade = this.utilSessao.getNomenclaturaEmpresas().find((nomenclaturaEmpresa) => nomenclaturaEmpresa.id == 5).apelido;
    }
  }
}
