import { Component } from '@angular/core';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './classificacaoFiscal.component.html',
})
export class ClassificacaoFiscalComponent extends PaginaComponent {
  public classificacaoFiscais: ClassificacaoFiscal[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.classificacaoFiscal).subscribe((res) => {
      this.classificacaoFiscais = this.plainToClass(ClassificacaoFiscal, res) as any;
    });
  }

  excluirCidade(id: number, nome: string): void {
    super.excluir<ClassificacaoFiscal>(id, this.classificacaoFiscais, nome);
  }
}
