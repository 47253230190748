import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'dataSaldo', rotulo: bibDialogo.dataSaldo, nome: 'DATA_SALDO', obrigatorio: 'S', coluna: 3, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'conta', rotulo: bibDialogo.conta, nome: 'ID_CONTA', coluna: 12, servicoWeb: bibServico.contaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
]);
export default filtros;
