<div class="row form-inline justify-content-end p-0">
  <titulo class="col-sm p-0 ml-sm-2" [titulo]="movimentacaoControlador.bibDialogo.chaveReferenciada" [imagem]="bibImagem.documentos" [obrigatorio]="this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade == 3 && this.movimentacaoControlador.movimentacao.emitente == 0 && this.movimentacaoControlador.movimentacao.movimentacaoChaves.length == 0"></titulo>
  <botao [compacto]="movimentacaoControlador.compacto" [legenda]="movimentacaoControlador.bibDialogo.adicionarChave" (botaoEmt)="abrirModal()" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoControlador.movimentacao.status == 'S'"></botao>
</div>
<div class="row" *ngIf="movimentacaoControlador.movimentacao.movimentacaoChaves.length == 0 && !movimentacaoControlador.bloqueado && movimentacaoControlador.movimentacao.status != 'S'">
  <p class="col-sm-12 pl-3">
    {{ movimentacaoControlador.bibDialogo.nenhumaAbr + ' ' + movimentacaoControlador.bibDialogo.chaveReferenciada.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirModal()">{{ movimentacaoControlador.bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ movimentacaoControlador.bibDialogo.paraAdicionar.toLowerCase() }}
    <ajuda class="ml-1" [ajuda]="this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade == 3 && this.movimentacaoControlador.movimentacao.emitente == 0 ? bibDialogo.ajudaChaveReferenciada : ''" [ajudaDireita]="true"></ajuda>
  </p>
</div>
<table *ngIf="movimentacaoControlador.movimentacao.movimentacaoChaves.length > 0" class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l-60">{{ movimentacaoControlador.bibDialogo.chave }}</th>
      <th class="l-10">{{ movimentacaoControlador.bibDialogo.modelo }}</th>
      <th class="l-10">{{ movimentacaoControlador.bibDialogo.serie }}</th>
      <th class="l-15">{{ movimentacaoControlador.bibDialogo.numeroFiscal }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let movimentacaoChave of movimentacaoControlador.movimentacao.movimentacaoChaves; let i = index">
      <td>{{ movimentacaoChave.chave }}</td>
      <td>{{ movimentacaoChave.modelo }}</td>
      <td>{{ movimentacaoChave.serie }}</td>
      <td>{{ movimentacaoChave.numeroFiscal }}</td>
      <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editarChave(i)" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoControlador.movimentacao.status == 'S'"></btnAdicional></td>
      <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, movimentacaoControlador.movimentacao.movimentacaoChaves, movimentacaoControlador.idMovimentacaoChaves, movimentacaoChave.id)" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoControlador.movimentacao.status == 'S'"></btnAdicional></td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
