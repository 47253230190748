import { Type } from 'class-transformer';

export class ConferenciaFinanceiro {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public id: number;
  public idConferencia: number;
  public idEmpresa: number;
  public idFinanceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
}
