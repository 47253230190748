<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r-1">{{ bibDialogo.numero }}</th>
      <th class="r">{{ bibDialogo.nome }}</th>
      <th class="r-3">{{ menuCategoriaRoteiroContabilApelido }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let roteiroContabil of roteiroContabeis; let i = index">
        <tr class="conteudo" class="sem-borda">
          <td>{{ roteiroContabil.numero }}</td>
          <td>{{ roteiroContabil.nome }}</td>
          <td>{{ roteiroContabil.categoriaRoteiroContabil }}</td>
        </tr>
        <tr r2004itens [roteiroContabeis]="roteiroContabil.roteiroContabilRateios"></tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
