import { Type } from 'class-transformer';
import { RomaneioEntregaDocumentoFiscal } from './romaneioEntregaDocumentoFiscal';

export class RomaneioEntrega {
  @Type(() => Date)
  data: Date;
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idParceiroTransportadora: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idVeiculo: number;
  public observacao: string;
  public motoristaCpf: string;
  public ehValidoCpf: boolean = true;
  public motoristaNome: string;
  public numero: number;
  public status: string = 'N';
  public romaneioEntregaDocumentoFiscais: RomaneioEntregaDocumentoFiscal[] = [];

  public loja: string;
  public lojaAbreviacao: string;
  public parceiroTransportadora: string;
  public placa: string;
  public placaUf: string;
  public veiculo: string;
  public expandido: boolean = false;
  public selecionado: string = 'N';
  public buscarTodosDocumentosFiscais: string = 'N';
}
