<ng-container class="border-bottom border-dark">
  <td class="p-0" colspan="7">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.produto }}</th>
          <th class="r-1">{{ bibDialogo.unidade }}</th>
          <th class="text-right r-1">{{ bibDialogo.quantidadeProduto }}</th>
          <th class="text-right r-1">{{ bibDialogo.valor }}</th>
          <th class="text-right r-1">{{ bibDialogo.desconto }}</th>
          <th class="text-right r-1">{{ bibDialogo.frete }}</th>
          <th class="text-right r-1">{{ bibDialogo.seguro }}</th>
          <th class="text-right r-1">{{ bibDialogo.outros }}</th>
          <th class="text-right r-1">{{ bibDialogo.valorFinal }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos">
          <tr>
            <td>{{ movimentacaoProduto.numero + ' - ' + movimentacaoProduto.produto }}</td>
            <td>{{ movimentacaoProduto.unidade }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.descontoValor | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.freteRateado | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.seguroRateado | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.outroRateado | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
