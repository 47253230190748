import { Component, Input } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { ParceiroEnderecoFrmComponent } from './frm/parceiroEnderecoFrm.component';

@Component({
  selector: 'parceiroEndereco',
  templateUrl: './parceiroEndereco.component.html',
})
export class ParceiroEnderecoComponent extends PaginaComponent {
  @Input() excluirEnderecos: number[] = [];
  @Input() tipoVinculo: string;
  public _parceiroEnderecos: ParceiroEndereco[];
  public _idParceiro: number;

  private modalEmt: any;
  public componente: any;
  public rotuloBairro: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 2).apelido;
  public rotuloCidade: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 1).apelido;

  @Input() set idParceiro(idParceiro: number) {
    this._idParceiro = idParceiro;
    this.sugerirParceiroVinculoPrincipal();
  }

  @Input() set parceiroEnderecos(parceiroEnderecos: ParceiroEndereco[]) {
    this._parceiroEnderecos = parceiroEnderecos;
    this.sugerirParceiroVinculoPrincipal();
  }

  ngOnInit(): void {
    this.sugerirParceiroVinculoPrincipal();
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.parceiroEndereco) {
        this.adicionarTemporario(this.plainToClass(ParceiroEndereco, identificacao.conteudo), this._parceiroEnderecos);
        this.excluirParceiroVinculoPrincipal();
      }
    });
  }

  ngOnDestroy() {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  abrirModal(parceiroEndereco: ParceiroEndereco = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', parceiroEndereco));
    this.utilSessao.setIdentificacao(new Identificacao('tipoVinculo', this.tipoVinculo));
    this.componente = ParceiroEnderecoFrmComponent;
  }

  sugerirParceiroVinculoPrincipal(): void {
    if ((this._idParceiro == null || this._idParceiro == undefined) && this._parceiroEnderecos && this._parceiroEnderecos.length == 0) {
      let parceiroEndereco: ParceiroEndereco = new ParceiroEndereco();
      parceiroEndereco.idVinculo = 3;
      parceiroEndereco.vinculo = 'PRINCIPAL';
      parceiroEndereco.endereco = null;
      this.adicionarTemporario(this.plainToClass(ParceiroEndereco, parceiroEndereco), this._parceiroEnderecos);
    }
  }

  excluirParceiroVinculoPrincipal(): void {
    const posicao: number = this._parceiroEnderecos.findIndex((parceiroEndereco) => parceiroEndereco.vinculo == 'PRINCIPAL' && parceiroEndereco.endereco == null);
    if (posicao >= 0) {
      this._parceiroEnderecos.splice(posicao, 1);
    }
  }
}
