import { APP_BASE_HREF, CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, PlatformLocation } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AARouteReuseStrategy } from 'src/AARouteReuseStrategy';
import { ComunicacaoTecnoSpeedService } from 'src/app/servico/comunicacaoTecnoSpeed.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BibNomenclatura } from './biblioteca/bibNomenclatura';
import { CompartilhadoModule } from './compartilhado/compartilhado.module';
import { CabecalhoGrupoComponent } from './essencial/cabecalhoGrupo/cabecalhoGrupo.component';
import { EnderecoCepComponent } from './essencial/enderecoCep/enderecoCep.component';
import { FiltroComponent } from './essencial/filtro/filtro.component';
import { ImagemComponent } from './essencial/imagem/imagem.component';
import { MenuComponent } from './essencial/menu/menu.component';
import { ModalNotificacao } from './essencial/modal/modal.notificacao';
import { Progresso } from './essencial/progresso/progresso.component';
import { RodapeTotalComponent } from './essencial/rodapeTotal/rodapeTotal.component';
import { TotalizadorComponent } from './essencial/totalizador/totalizador.component';
import { CheckComponent } from './formulario/check/check.component';
import { CheckIcone } from './formulario/checkIcone/checkIcone.component';
import { PesquisaMovimentacaoComponent } from './formulario/pesquisa/movimentacao/pesquisaMovimentacao.component';
import { ChequePipe } from './formulario/pesquisa/pesquisaCheque/cheque.pipe';
import { PesquisaChequeComponent } from './formulario/pesquisa/pesquisaCheque/pesquisaCheque.component';
import { PesquisaTextoBuscaComponent } from './formulario/pesquisa/pesquisaTextoBusca/pesquisaTextoBusca.component';
import { PesquisaProdutoComponent } from './formulario/pesquisa/produto/pesquisaProduto.component';
import { RadioComponent } from './formulario/radio/radio.component';
import { AutenticacaoGuard } from './guardas/autenticacao.guard';
import { AutenticacaoRelatorioGuard } from './guardas/autenticacaoRelatorio.guard';
import { AlterarMovimentacaoComponent } from './pagina/alterarMovimentacao/alterarMovimentacao.component';
import { AprovacaoComponent } from './pagina/aprovacao/aprovacao.component';
import { AprovacaoFrmComponent } from './pagina/aprovacao/aprovacaoFrm.component';
import { AtalhoComponent } from './pagina/atalhos/atalhos.component';
import { BaixaComponent } from './pagina/baixa/baixa.component';
import { BaixaNotificacao } from './pagina/baixa/baixa.notificacao';
import { BaixaBaixaComponent } from './pagina/baixa/baixaBaixa/baixaBaixa.component';
import { BaixaBuscaComponent } from './pagina/baixa/baixaBusca/baixaBusca.component';
import { BaixaComprovanteComponent } from './pagina/baixa/baixaComprovante/baixaComprovante.component';
import { BaixaListaComponent } from './pagina/baixa/baixaLista/baixaLista.component';
import { BloqueiaEstoqueComponent } from './pagina/bloquearEstoque/bloqueiaEstoque.component';
import { BloqueiaComponent } from './pagina/bloqueia/bloqueia.component';
import { BloqueioPeriodoComponent } from './pagina/bloqueioPeriodo/bloqueioPeriodo.component';
import { BloqueioPeriodoFrmComponent } from './pagina/bloqueioPeriodo/bloqueioPeriodoFrm.component';
import { CaixaComponent } from './pagina/caixa/caixa.component';
import { CaixaFrmComponent } from './pagina/caixa/caixaFrm.component';
import { CancelamentoComponent } from './pagina/cancelamento/cancelamento.component';
import { CancelamentoFrmComponent } from './pagina/cancelamento/cancelamentoFrm.component';
import { CancelamentoEstornoComponent } from './pagina/cancelamentoEstorno/cancelamentoEstorno.component';
import { CartaCorrecaoComponent } from './pagina/cartaCorrecao/cartaCorrecao.component';
import { CartaCorrecaoFrmComponent } from './pagina/cartaCorrecao/cartaCorrecaoFrm.component';
import { CestComponent } from './pagina/cest/cest.component';
import { CestFrmComponent } from './pagina/cest/cestFrm.component';
import { CfopEmpresaComponent } from './pagina/cfopEmpresa/cfopEmpresa.component';
import { CfopEmpresaFrmComponent } from './pagina/cfopEmpresa/cfopEmpresaFrm.component';
import { ChequeComponent } from './pagina/cheque/cheque.component';
import { ChequeFrmComponent } from './pagina/cheque/chequeFrm.component';
import { CidadeModule } from './pagina/cidade/cidade.module';
import { ClassificacaoComponent } from './pagina/classificacao/classificacao.component';
import { ClassificacaoFrmComponent } from './pagina/classificacao/classificacaoFrm.component';
import { ClassificacaoOperacaoComponent } from './pagina/classificacao/classificacaoOperacao/classificacaoOperacao.component';
import { ClassificacaoFiscalComponent } from './pagina/classificacaoFiscal/classificacaoFiscal.component';
import { ClassificacaoFiscalFrmComponent } from './pagina/classificacaoFiscal/classificacaoFiscalFrm.component';
import { ClienteEspecialComponent } from './pagina/clienteEspecial/clienteEspecial.component';
import { ComissaoFaturamentoComponent } from './pagina/comissaoFaturamento/comissaoFaturamento.component';
import { ComissaoFaturamentoEstornoComponent } from './pagina/comissaoFaturamentoEstorno/comissaoFaturamentoEstorno.component';
import { ConciliacaoComponent } from './pagina/conciliacao/conciliacao.component';
import { ConfiguracaoContabilComponent } from './pagina/configuracaoContabil/configuracaoContabil.component';
import { ConfiguracaoContabilFrmComponent } from './pagina/configuracaoContabil/configuracaoContabilFrm/configuracaoContabilFrm.component';
import { ConfiguracaoContabilTotalFrmComponent } from './pagina/configuracaoContabil/configuracaoContabilTotalFrm/configuracaoContabilTotalFrm.component';
import { ConfiguracaoRelatorioContabilComponent } from './pagina/configuracaoRelatorioContabil/configuracaoRelatorioContabil.component';
import { ConfiguracaoRelatorioContabilFrmComponent } from './pagina/configuracaoRelatorioContabil/configuracaoRelatorioContabilFrm.component';
import { ContaComponent } from './pagina/conta/conta.component';
import { ContaFrmComponent } from './pagina/conta/contaFrm.component';
import { ContaContabilComponent } from './pagina/contaContabil/contaContabil.component';
import { ContaContabilFrmComponent } from './pagina/contaContabil/contaContabilFrm.component';
import { ContaCorrenteComponent } from './pagina/contaCorrente/contaCorrente.component';
import { ContaCorrenteFrmComponent } from './pagina/contaCorrente/frm/contaCorrenteFrm.component';
import { CreditoComponent } from './pagina/credito/credito.component';
import { CulturaComponent } from './pagina/cultura/cultura.component';
import { CulturaFrmComponent } from './pagina/cultura/culturaFrm.component';
import { DesfazimentoComponent } from './pagina/desfazimento/desfazimento.component';
import { DesfazimentoFrmComponent } from './pagina/desfazimento/desfazimentoFrm/desfazimentoFrm.component';
import { DesfazimentoEstornoComponent } from './pagina/desfazimentoEstorno/desfazimentoEstorno.component';
import { DocumentoEletronicoComponent } from './pagina/documentoEletronico/documentoEletronico.component';
import { DocumentoEletronicoMDFeComponent } from './pagina/documentoEletronicoMDFe/documentoEletronicoMDFe.component';
import { DocumentoEletronicoCTeComponent } from './pagina/documentoEletronicoCTe/documentoEletronicoCTe.component';
import { DocumentoEletronicoReceituarioComponent } from './pagina/documentoEletronicoReceituario/documentoEletronicoReceituario.component';
import { EmpresaComponent } from './pagina/empresa/empresa.component';
import { EncerramentoContabilComponent } from './pagina/encerramentoContabil/encerramentoContabil.component';
import { EncerramentoContabilFrmComponent } from './pagina/encerramentoContabil/encerramentoContabilFrm.component';
import { EntregasComponent } from './pagina/entregas/entregas.component';
import { EquipamentoComponent } from './pagina/equipamento/equipamento.component';
import { EquipamentoFrmComponent } from './pagina/equipamento/equipamentoFrm.component';
import { EquipeComponent } from './pagina/equipe/equipe.component';
import { EquipeFrmComponent } from './pagina/equipe/frm/equipeFrm.component';
import { EquipeParceiroComponent } from './pagina/equipe/frm/equipeParceiro/equipeParceiro.component';
import { EspecialComponent } from './pagina/especial/especial.component';
import { EspecialFrmComponent } from './pagina/especial/especialFrm.component';
import { EstoqueComponent } from './pagina/estoque/estoque.component';
import { FabricanteComponent } from './pagina/fabricante/fabricante.component';
import { FabricanteFrmComponent } from './pagina/fabricante/fabricanteFrm.component';
import { FinanceiroComponent } from './pagina/financeiro/financeiro.component';
import { FinanceiroFrmBaiComponent } from './pagina/financeiro/frm/baixa/financeiroFrmBai.component';
import { FinanceiroFrmComponent } from './pagina/financeiro/frm/financeiroFrm.component';
import { FinanceiroFrmNegComponent } from './pagina/financeiro/frm/negociacao/financeiroFrmNeg.component';
import { FinanceiroEstornoComponent } from './pagina/financeiroEstorno/financeiroEstorno.component';
import { GenericoComponent } from './pagina/generico/generico.component';
import { GenericoFrmComponent } from './pagina/generico/genericoFrm.component';
import { GenericoHierarquiaComponent } from './pagina/genericoHierarquia/genericoHierarquia.component';
import { GenericoHierarquiaFrmComponent } from './pagina/genericoHierarquia/genericoHierarquiaFrm.component';
import { GrupoComponent } from './pagina/grupo/grupo.component';
import { GrupoFrmComponent } from './pagina/grupo/grupoFrm.component';
import { GrupoAcessoComponent } from './pagina/grupoAcesso/grupoAcesso.component';
import { GrupoAcessoFrmComponent } from './pagina/grupoAcesso/grupoAcessoFrm.component';
import { GrupoAcessoHorarioFrmComponent } from './pagina/grupoAcesso/grupoAcessoHorario/grupoAcessoHorarioFrm.component';
import { GrupoAcessoLocalFrmComponent } from './pagina/grupoAcesso/grupoAcessoLocal/grupoAcessoLocalFrm.component';
import { GrupoContabilComponent } from './pagina/grupoContabil/grupoContabil.component';
import { GrupoContabilFrmComponent } from './pagina/grupoContabil/grupoContabilFrm/grupoContabilFrm.component';
import { GrupoPermissaoFrmComponent } from './pagina/grupoPermissao/frm/grupoPermissaoFrm.component';
import { GrupoPermissaoMenusFrmComponent } from './pagina/grupoPermissao/frm/grupoPermissaoMenusFrm/grupoPermissaoMenusFrm.component';
import { GrupoPermissaoRelatoriosFrmComponent } from './pagina/grupoPermissao/frm/grupoPermissaoRelatoriosFrm/grupoPermissaoRelatoriosFrm.component';
import { GrupoPermissaoComponent } from './pagina/grupoPermissao/grupoPermissao.component';
import { GrupoValorComponent } from './pagina/grupoValor/grupoValor.component';
import { HistoricoComponent } from './pagina/historico/historico.component';
import { HistoricoFrmComponent } from './pagina/historico/historicoFrm.component';
import { GrupoImpostoComponent } from './pagina/imposto/grupoImposto/grupoImposto.component';
import { GrupoImpostoFrmComponent } from './pagina/imposto/grupoImposto/grupoImpostoFrm.component';
import { IcmsPipe } from './pagina/imposto/icms/icms.pipe';
import { IcmsDesoneradoPipe } from './pagina/imposto/icms/icmsDesonerado.pipe';
import { IcmsFrmComponent } from './pagina/imposto/icms/icmsFrm.component';
import { IcmsStComponent } from './pagina/imposto/icmsSt/icmsSt.component';
import { IcmsStFrmComponent } from './pagina/imposto/icmsSt/icmsStFrm.component';
import { PisCofinsFrmComponent } from './pagina/imposto/pisCofins/pisCofinsFrm.component';
import { ProdutoImpostoComponent } from './pagina/imposto/produtoImposto/produtoImposto.component';
import { ProdutoImpostoFrmComponent } from './pagina/imposto/produtoImposto/produtoImpostoFrm.component';
import { IntegracaoListaComponent } from './pagina/integracaoLista/integracaoLista.component';
import { IntegracaoListaPipe } from './pagina/integracaoLista/integracaoLista.pipe';
import { LancamentoContabilComponent } from './pagina/lancamentoContabil/lancamentoContabil.component';
import { LancamentoContabilFrmComponent } from './pagina/lancamentoContabil/lancamentoContabilFrm.component';
import { LimiteCreditoComponent } from './pagina/limiteCredito/limiteCredito.component';
import { LimiteCreditoHistoricoComponent } from './pagina/limiteCredito/limiteCreditoHistorico/limiteCreditoHistorico.component';
import { LocalComponent } from './pagina/local/local.component';
import { LocalFrmComponent } from './pagina/local/localFrm.component';
import { LoginComponent } from './pagina/login/login.component';
import { LoginEmitter } from './pagina/login/login.emitter';
import { LojaEstadoFrmComponent } from './pagina/loja/frm/lojaEstado/lojaEstadoFrm.component';
import { LojaFrmComponent } from './pagina/loja/frm/lojaFrm.component';
import { LojaComponent } from './pagina/loja/loja.component';
import { MdfeDocumentoFiscalFrmComponent } from './pagina/mdfe/frm/mdfeDocumentoFiscalfrm/mdfeDocumentoFiscalFrm.component';
import { MdfeFrmComponent } from './pagina/mdfe/frm/mdfeFrm.component';
import { MdfePercursoFrmComponent } from './pagina/mdfe/frm/mdfePercusofrm/mdfePercursoFrm.component';
import { MdfeComponent } from './pagina/mdfe/mdfe.component';
import { MenuEmpresaComponent } from './pagina/menuEmpresa/menuEmpresa.component';
import { MenuPersonalizadoComponent } from './pagina/menuPersonalizado/menuPersonalizado.component';
import { MenuModuloEmpresaComponent } from './pagina/moduloEmpresa/menuModuloFrm/menuModuloEmpresa/menuModuloEmpresa.component';
import { ModuloEmpresaFrmComponent } from './pagina/moduloEmpresa/menuModuloFrm/moduloEmpresaFrm.component';
import { RelatorioModuloEmpresaComponent } from './pagina/moduloEmpresa/menuModuloFrm/relatorioModuloEmpresa/relatorioModuloEmpresa.component';
import { ModuloEmpresaComponent } from './pagina/moduloEmpresa/moduloEmpresa.component';
import { MonitoramentoComponent } from './pagina/monitoramento/monitoramento.component';
import { MovimentacaoCabecalhoAlternativoFrmComponent } from './pagina/movimentacao/frm/cabecalho/alternativo/movimentacaoCabecalhoAlternativoFrm.component';
import { MovimentacaoCabecalhoFrmComponent } from './pagina/movimentacao/frm/cabecalho/movimentacaoCabecalhoFrm.component';
import { MovimentacaoCabecalhoPadraoFrmComponent } from './pagina/movimentacao/frm/cabecalho/padrao/movimentacaoCabecalhoPadraoFrm.component';
import { MovimentacaoChaveFrmComponent } from './pagina/movimentacao/frm/chave/frm/movimentacaoChaveFrm.component';
import { MovimentacaoChaveComponent } from './pagina/movimentacao/frm/chave/movimentacaoChave.component';
import { MovimentacaoFrmComponent } from './pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { MovimentacaoParcelaFrmComponent } from './pagina/movimentacao/frm/parcela/movimentacaoParcelaFrm.component';
import { MovimentacaoProdutoImpostoFrmComponent } from './pagina/movimentacao/frm/produto/imposto/movimentacaoProdutoImpostoFrm.component';
import { MovimentacaoProdutoFrmComponent } from './pagina/movimentacao/frm/produto/movimentacaoProdutoFrm.component';
import { MovimentacaoQuantidadeComponent } from './pagina/movimentacao/frm/produto/quantidade/movimentacaoQuantidade.component';
import { TabelaPrecoModalComponent } from './pagina/movimentacao/frm/produto/tabelaPrecoModal/tabelaPrecoModal.component';
import { MovimentacaoRodapeFrmComponent } from './pagina/movimentacao/frm/rodape/movimentacaoRodapeFrm.component';
import { MovimentacaoTotalizadorFrmComponent } from './pagina/movimentacao/frm/totalizador/movimentacaoTotalizadorFrm.component';
import { MovimentacaoTransporteFrmComponent } from './pagina/movimentacao/frm/transporte/movimentacaoTransporteFrm.component';
import { MovimentacaoComponent } from './pagina/movimentacao/movimentacao.component';
import { MovimentacaoAbrirComponent } from './pagina/movimentacaoAbrir/movimentacaoAbrir.component';
import { NaturezaOperacaoComponent } from './pagina/naturezaOperacao/naturezaOperacao.component';
import { NaturezaOperacaoFrmComponent } from './pagina/naturezaOperacao/naturezaOperacaoFrm.component';
import { NegociacaoComponent } from './pagina/negociacao/negociacao.component';
import { NegociacaoFrmComponent } from './pagina/negociacao/negociacaoFrm.component';
import { NfeXmlPdfComponent } from './pagina/nfeXmlPdf/nfeXmlPdf.component';
import { NfeXmlPdfFrmComponent } from './pagina/nfeXmlPdf/nfeXmlPdfFrm.component';
import { NomenclaturaEmpresaComponent } from './pagina/nomenclaturaEmpresa/nomenclaturaEmpresa.component';
import { ParceiroAtualizacaoComponent } from './pagina/parceiro/frm/atualizacao/parceiroAtualizacao.component';
import { ParceiroCobrancaComponent } from './pagina/parceiro/frm/cobranca/parceiroCobranca.component';
import { ParceiroContatoFrmComponent } from './pagina/parceiro/frm/contato/frm/parceiroContatoFrm.component';
import { ParceiroContatoComponent } from './pagina/parceiro/frm/contato/parceiroContato.component';
import { ParceiroDestinoFrmComponent } from './pagina/parceiro/frm/destino/frm/parceiroDestinoFrm.component';
import { ParceiroDestinoPropriedadeFrmComponent } from './pagina/parceiro/frm/destino/frm/parceiroDestinoPropriedadeFrm.component';
import { ParceiroDestinoComponent } from './pagina/parceiro/frm/destino/parceiroDestino.component';
import { ParceiroEnderecoFrmComponent } from './pagina/parceiro/frm/endereco/frm/parceiroEnderecoFrm.component';
import { ParceiroEnderecoComponent } from './pagina/parceiro/frm/endereco/parceiroEndereco.component';
import { ParceiroNegociacaoComponent } from './pagina/parceiro/frm/negociacao/parceiroNegociacao.component';
import { ParceiroOcorrenciaComponent } from './pagina/parceiro/frm/ocorrencia/parceiroOcorrencia.component';
import { ParceiroArtFrmComponent } from './pagina/parceiro/frm/parceiroArt/frm/parceiroArtFrm.component';
import { ParceiroArtComponent } from './pagina/parceiro/frm/parceiroArt/parceiroArt.component';
import { ParceiroFrmComponent } from './pagina/parceiro/frm/parceiroFrm.component';
import { PesquisaClienteMovimentacaoProdutoComponent } from './pagina/parceiro/frm/pesquisaClienteMovimentacaoProduto/pesquisaClienteMovimentacaoProduto.component';
import { ParceiroTelefoneFrmComponent } from './pagina/parceiro/frm/telefone/frm/parceiroTelefoneFrm.component';
import { ParceiroTelefoneComponent } from './pagina/parceiro/frm/telefone/parceiroTelefone.component';
import { ParceiroVeiculoFrmComponent } from './pagina/parceiro/frm/veiculo/frm/parceiroVeiculoFrm.component';
import { ParceiroVeiculoComponent } from './pagina/parceiro/frm/veiculo/parceiroVeiculo.component';
import { ParceiroComponent } from './pagina/parceiro/parceiro.component';
import { PatrimonialFrmComponent } from './pagina/patrimonial/frm/patrimonialFrm.component';
import { PatrimonialLancamentoContabilFrmComponent } from './pagina/patrimonial/frm/patrimonialLancamentoContabil/patrimonialLancamentoContabilFrm.component';
import { PatrimonialComponent } from './pagina/patrimonial/patrimonial.component';
import { PolegadaComponent } from './pagina/polegada/polegada.component';
import { PolegadaFrmComponent } from './pagina/polegada/polegadaFrm.component';
import { PragaComponent } from './pagina/praga/praga.component';
import { PragaFrmComponent } from './pagina/praga/pragaFrm.component';
import { PrincipalComponent } from './pagina/principal/principal.component';
import { ProdutoEstoqueMinimoMaximoFrmComponent } from './pagina/produto/frm/estoqueMinimoMaximo/produtoEstoqueMininoMaximoFrm.component';
import { ProdutoCabecalhoFrmComponent } from './pagina/produto/frm/produtoCabecalhoFrm/produtoCabecalhoFrm.component';
import { ProdutoFrmComponent } from './pagina/produto/frm/produtoFrm.component';
import { ProdutoRodapeFrmComponent } from './pagina/produto/frm/produtoRodapeFrm/produtoRodapeFrm.component';
import { ProdutoComponent } from './pagina/produto/produto.component';
import { ProdutoComissaoComponent } from './pagina/produtoComissao/produtoComissao.component';
import { ProdutoParceiroComissaoComponent } from './pagina/produtoParceiroComissao/produtoParceiroComissao.component';
import { ProdutoValorComponent } from './pagina/produtoValor/produtoValor.component';
import { ProdutoValorHistoricoComponent } from './pagina/produtoValorHistorico/produtoValorHistorico.component';
import { RegiaoComponent } from './pagina/regiao/regiao.component';
import { RegiaoFrmComponent } from './pagina/regiao/regiaoFrm.component';
import { RelacionamentoComponent } from './pagina/relacionamento/relacionamento.component';
import { RelacionamentoFrmComponent } from './pagina/relacionamento/relacionamentoFrm.component';
import { RelatorioListaComponent } from './pagina/relatorioLista/relatorioLista.component';
import { RelatorioListaPipe } from './pagina/relatorioLista/relatorioLista.pipe';
import { RelatorioPersonalizadoComponent } from './pagina/relatorioPersonalizado/relatorioPersonalizado.component';
import { RelatorioPersonalizadoPipe } from './pagina/relatorioPersonalizado/relatorioPersonalizado.pipe';
import { RelatorioPersonalizadoFrmComponent } from './pagina/relatorioPersonalizado/relatorioPersonalizadoFrm/relatorioPersonalizadoFrm.component';
import { RomaneioEntregaDocumentoFiscalFrmComponent } from './pagina/romaneioEntrega/frm/romaneioEntregaDocumentoFiscalfrm/romaneioEntregaDocumentoFiscalFrm.component';
import { RomaneioEntregaFrmComponent } from './pagina/romaneioEntrega/frm/romaneioEntregaFrm.component';
import { RomaneioEntregaComponent } from './pagina/romaneioEntrega/romaneioEntrega.component';
import { RoteiroContabilComponent } from './pagina/roteiroContabil/roteiroContabil.component';
import { RoteiroContabilFrmComponent } from './pagina/roteiroContabil/roteiroContabilFrm.component';
import { RoteiroContabilRateioFrmComponent } from './pagina/roteiroContabil/roteiroContabilRateioFrm/roteiroContabilRateioFrm.component';
import { SafraComponent } from './pagina/safra/safra.component';
import { SafraFrmComponent } from './pagina/safra/safraFrm.component';
import { SaldoBancarioComponent } from './pagina/saldoBancario/saldoBancario.component';
import { SepararEntregaComponent } from './pagina/separarEntrega/separarEntrega.component';
import { SepararEntregaFrmComponent } from './pagina/separarEntrega/separarEntregaFrm.component';
import { TipoTituloComponent } from './pagina/tipoTitulo/tipoTitulo.component';
import { TipoTituloCaixaFrmComponent } from './pagina/tipoTitulo/tipoTituloCaixa/tipoTituloCaixaFrm.component';
import { TipoTituloFrmComponent } from './pagina/tipoTitulo/tipoTituloFrm.component';
import { TransferenciaBancariaComponent } from './pagina/transferenciaBancaria/transferenciaBancaria.component';
import { TransferenciaBancariaFrmComponent } from './pagina/transferenciaBancaria/transferenciaBancariaFrm.component';
import { TransferenciaChequePipe } from './pagina/transferenciaBancaria/transferenciaCheque.pipe';
import { UnidadeComponent } from './pagina/unidade/unidade.component';
import { UnidadeFrmComponent } from './pagina/unidade/unidadeFrm.component';
import { UsuarioCategoriaRoteirosFrmComponent } from './pagina/usuario/frm/usuarioCategoriaRoteirosFrm/usuarioCategoriaRoteirosFrm.component';
import { UsuarioContasFrmComponent } from './pagina/usuario/frm/usuarioContasFrm/usuarioContasFrm.component';
import { UsuarioDadosFrmComponent } from './pagina/usuario/frm/usuarioDadosFrm/usuarioDadosFrm.component';
import { UsuarioExtraFrmComponent } from './pagina/usuario/frm/usuarioExtraFrm/usuarioExtraFrm.component';
import { UsuarioFotoFrmComponent } from './pagina/usuario/frm/usuarioFotoFrm/usuarioFotoFrm.component';
import { UsuarioFrmComponent } from './pagina/usuario/frm/usuarioFrm.component';
import { UsuarioIntegracaoPipe } from './pagina/usuario/frm/usuarioIntegracaoFrm/usuarioIntegracao.pipe';
import { UsuarioIntegracaoFrmComponent } from './pagina/usuario/frm/usuarioIntegracaoFrm/usuariosIntegracaoFrm.component';
import { UsuarioLojasFrmComponent } from './pagina/usuario/frm/usuarioLojasFrm/usuarioLojasFrm.component';
import { UsuarioMenuPipe } from './pagina/usuario/frm/usuarioMenusFrm/usuarioMenus.pipe';
import { UsuarioMenusFrmComponent } from './pagina/usuario/frm/usuarioMenusFrm/usuarioMenusFrm.component';
import { RelatorioUsuariosFrmComponent } from './pagina/usuario/frm/usuarioRelatoriosFrm/relatorioUsuariosFrm.component';
import { UsuarioComponent } from './pagina/usuario/usuario.component';
import { VideoComponent } from './pagina/video/video.component';
import { VinculoComponent } from './pagina/vinculo/vinculo.component';
import { VinculoFrmComponent } from './pagina/vinculo/vinculoFrm.component';
import { R1Component } from './relatorio/r1/r1.component';
import { R1001Component } from './relatorio/r1001/r1001.component';
import { R1001ItensComponent } from './relatorio/r1001/r1001itens/r1001itens.component';
import { R1001NotasComponent } from './relatorio/r1001/r1001notas/r1001notas.component';
import { R1002Component } from './relatorio/r1002/r1002.component';
import { R1002ItensComponent } from './relatorio/r1002/r1002itens/r1002itens.component';
import { R1003Component } from './relatorio/r1003/r1003.component';
import { R1004Component } from './relatorio/r1004/r1004.component';
import { R1004ItensComponent } from './relatorio/r1004/r1004itens/r1004itens.component';
import { R1009Component } from './relatorio/r1009/r1009.component';
import { R1009ItensComponent } from './relatorio/r1009/r1009itens/r1009itens.component';
import { R1009TiposTituloComponent } from './relatorio/r1009/r1009tiposTitulo/r1009tiposTitulo.component';
import { R1010Component } from './relatorio/r1010/r1010.component';
import { R1012Component } from './relatorio/r1012/r1012.component';
import { R1012ItensComponent } from './relatorio/r1012/r1012itens/r1012itens.component';
import { R1014Component } from './relatorio/r1014/r1014.component';
import { R1043Component } from './relatorio/r1043/r1043.component';
import { R1053Component } from './relatorio/r1053/r1053.component';
import { R1053ItensComponent } from './relatorio/r1053/r1053itens/r1053itens.component';
import { R1085Component } from './relatorio/r1085/r1085.component';
import { R1089Component } from './relatorio/r1089/r1089.component';
import { R1105Component } from './relatorio/r1105/r1105.component';
import { R1105TabelasComponent } from './relatorio/r1105/r1105tabelas/r1105tabelas.component';
import { R1109Component } from './relatorio/r1109/r1109.component';
import { R1109ItensComponent } from './relatorio/r1109/r1109itens/r1109itens.component';
import { R1110Component } from './relatorio/r1110/r1110.component';
import { R1111Component } from './relatorio/r1111/r1111.component';
import { R1112Component } from './relatorio/r1112/r1112.component';
import { R1113Component } from './relatorio/r1113/r1113.component';
import { R1114Component } from './relatorio/r1114/r1114.component';
import { R1115Component } from './relatorio/r1115/r1115.component';
import { R1116Component } from './relatorio/r1116/r1116.component';
import { R1117Component } from './relatorio/r1117/r1117.component';
import { R1118Component } from './relatorio/r1118/r1118.component';
import { R1125Component } from './relatorio/r1125/r1125.component';
import { R1125cofinsComponent } from './relatorio/r1125/r1125cofins/r1125cofins.component';
import { R1125icmsEntradaComponent } from './relatorio/r1125/r1125icmsEntrada/r1125icmsEntrada.component';
import { R1125icmsSaidaComponent } from './relatorio/r1125/r1125icmsSaida/r1125icmsSaida.component';
import { R1125icmsStComponent } from './relatorio/r1125/r1125icmsSt/r1125icmsSt.component';
import { R1125ipiComponent } from './relatorio/r1125/r1125ipi/r1125ipi.component';
import { R1125pisComponent } from './relatorio/r1125/r1125pis/r1125pis.component';
import { R1126Component } from './relatorio/r1126/r1126.component';
import { R1126AssinaturaComponent } from './relatorio/r1126/r1126assinatura/r1126assinatura.component';
import { R1126ContatoComponent } from './relatorio/r1126/r1126contato/r1126contato.component';
import { R1126DadosComponent } from './relatorio/r1126/r1126dados/r1126dados.component';
import { R1126DestinoComponent } from './relatorio/r1126/r1126destino/r1126destino.component';
import { R1126EnderecoComponent } from './relatorio/r1126/r1126endereco/r1126endereco.component';
import { R1126ItemComponent } from './relatorio/r1126/r1126item/r1126item.component';
import { R1126TelefoneComponent } from './relatorio/r1126/r1126telefone/r1126telefone.component';
import { R1127Component } from './relatorio/r1127/r1127.component';
import { R1127ProdutosComponent } from './relatorio/r1127/r1127produtos/r1127produtos.component';
import { MovimentacaoVinculadaPipe } from './relatorio/r1128/movimentacaoVinculada.pipe';
import { R1128Component } from './relatorio/r1128/r1128.component';
import { R1131Component } from './relatorio/r1131/r1131.component';
import { R1134Component } from './relatorio/r1134/r1134.component';
import { R1138Component } from './relatorio/r1138/r1138.component';
import { R1144Component } from './relatorio/r1144/r1144.component';
import { R1145Component } from './relatorio/r1145/r1145.component';
import { R1151Component } from './relatorio/r1151/r1151.component';
import { R1151ContasComponent } from './relatorio/r1151/r1151contas/r1151contas.component';
import { R1151FinanceiroComponent } from './relatorio/r1151/r1151financeiro/r1151financeiro.component';
import { R1152Component } from './relatorio/r1152/r1152.component';
import { R1152ItensComponent } from './relatorio/r1152/r1152itens/r1152itens.component';
import { R1159Component } from './relatorio/r1159/r1159.component';
import { R1167Component } from './relatorio/r1167/r1167.component';
import { R1168Component } from './relatorio/r1168/r1168.component';
import { R1168MovimentacaoFinanceiraComponent } from './relatorio/r1168/r1168MovimentacaoFinanceira/r1168MovimentacaoFinanceira.component';
import { R1198Component } from './relatorio/r1198/r1198.component';
import { R1198ItensComponent } from './relatorio/r1198/r1198Itens/r1198Itens.component';
import { R1206Component } from './relatorio/r1206/r1206.component';
import { R1206ItensComponent } from './relatorio/r1206/r1206itens/r1206itens.component';
import { R1233Component } from './relatorio/r1233/r1233.component';
import { R1233MovimentacaoComponent } from './relatorio/r1233/r1233Movimentacao/r1233Movimentacao.component';
import { R1268Component } from './relatorio/r1268/r1268.component';
import { R1268ComissaoComponent } from './relatorio/r1268/r1268Comissao/r1268Comissao.component';
import { R2Component } from './relatorio/r2/r2.component';
import { R2000Component } from './relatorio/r2000/r2000.component';
import { R2000Entregas } from './relatorio/r2000/r2000Entregas/r2000Entregas.component';
import { R2000Itens } from './relatorio/r2000/r2000Itens/r2000Itens.component';
import { R2001Component } from './relatorio/r2001/r2001.component';
import { R2001AlteracaoLimiteMaximo } from './relatorio/r2001/r2001AlteracaoLimiteMaximo/r2001AlteracaoLimiteMaximo.component';
import { R2001ApuracaoLucroComponent } from './relatorio/r2001/r2001ApuracaoLucro/r2001ApuracaoLucro.component';
import { R2001ApuracaoLucroClienteEspecialComponent } from './relatorio/r2001/r2001ApuracaoLucroClienteEspecial/r2001ApuracaoLucroClienteEspecial.component';
import { R2001DebitoMaiorLimiteComponent } from './relatorio/r2001/r2001DebitoMaiorLimite/r2001DebitoMaiorLimite.component';
import { R2001DevolucaoComponent } from './relatorio/r2001/r2001Devolucao/r2001Devolucao.component';
import { R2001DevolucaoEspecialComponent } from './relatorio/r2001/r2001DevolucaoEspecial/r2001DevolucaoEspecial.component';
import { R2001FinanceiroReceitaDespesaComponent } from './relatorio/r2001/r2001FinanceiroReceitaDespesa/r2001FinanceiroReceitaDespesa.component';
import { R2001MovimentacaoAjusteEstoqueComponent } from './relatorio/r2001/r2001MovimentacaoAjusteEstoque/r2001MovimentacaoAjusteEstoque.component';
import { R2001MovimentacaoDistorcidaComponent } from './relatorio/r2001/r2001MovimentacaoDistorcida/r2001MovimentacaoDistorcida.component';
import { R2001MovimentacaoFabricanteComponent } from './relatorio/r2001/r2001MovimentacaoFabricante/r2001MovimentacaoFabricante.component';
import { R2001MovimentoFiscalComponent } from './relatorio/r2001/r2001MovimentacaoFiscal/r2001MovimentoFiscal.component';
import { R2001MovimentacaoGrupoProdutoComponent } from './relatorio/r2001/r2001MovimentacaoGrupoProduto/r2001MovimentacaoGrupoProduto.component';
import { R2001MovimentacaoManualComponent } from './relatorio/r2001/r2001MovimentacaoManual/r2001MovimentacaoManual.component';
import { R2001MovimentacaoNegociacaoComponent } from './relatorio/r2001/r2001MovimentacaoNegociacao/r2001MovimentacaoNegociacao.component';
import { R2001MovimentacaoOcorrenciaComponent } from './relatorio/r2001/r2001MovimentacaoOcorrencia/r2001MovimentacaoOcorrencia.component';
import { R2001MovimentacaoResumoNegociacaoComponent } from './relatorio/r2001/r2001MovimentacaoResumoNegociacao/r2001MovimentacaoResumoNegociacao.component';
import { R2001MovimentacaoVendedorComponent } from './relatorio/r2001/r2001MovimentacaoVendedor/r2001MovimentacaoVendedor.component';
import { R2001NotasNaoTransmitidas } from './relatorio/r2001/r2001NotasNaoTransmitidas/r2001NotasNaoTransmitidas.component';
import { R2001NotasTransmitidas } from './relatorio/r2001/r2001NotasTransmitidas/r2001NotasTransmitidas.component';
import { R2002Component } from './relatorio/r2002/r2002.component';
import { R2003Component } from './relatorio/r2003/r2003.component';
import { R2003ItensComponent } from './relatorio/r2003/r2003itens/r2003itens.component';
import { R2004Component } from './relatorio/r2004/r2004.component';
import { R2004ItensComponent } from './relatorio/r2004/r2004itens/r2004itens.component';
import { R2005Component } from './relatorio/r2005/r2005.component';
import { R2005CorrentistasComponent } from './relatorio/r2005/r2005correntista/r2005correntistas.component';
import { R2006Component } from './relatorio/r2006/r2006.component';
import { R2006ItensComponent } from './relatorio/r2006/r2006itens/r2006itens.component';
import { R2007Component } from './relatorio/r2007/r2007.component';
import { R2008Component } from './relatorio/r2008/r2008.component';
import { R2009Component } from './relatorio/r2009/r2009.component';
import { R2010Component } from './relatorio/r2010/r2010.component';
import { R2011Component } from './relatorio/r2011/r2011.component';
import { R2012Component } from './relatorio/r2012/r2012.component';
import { R2013Component } from './relatorio/r2013/r2013.component';
import { R2014Component } from './relatorio/r2014/r2014.component';
import { R2014ItensComponent } from './relatorio/r2014/r2014itens/r2014itens.component';
import { R2015Component } from './relatorio/r2015/r2015.component';
import { R2015ConciliacaoFinanceiroBaixa } from './relatorio/r2015/r2015ConciliacaoFinanceiroBaixa/r2015ConciliacaoFinanceiroBaixa.component';
import { R2015ConciliacaoLancamentoContabil } from './relatorio/r2015/r2015ConciliacaoLancamentoContabil/r2015ConciliacaoLancamentoContabil.component';
import { R2015EntregaProdutoMovimentacaoProduto } from './relatorio/r2015/r2015EntregaProdutoMovimentacaoProduto/r2015EntregaProdutoMovimentacaoProduto.component';
import { R2015EstoqueHistorico } from './relatorio/r2015/r2015EstoqueHistorico/r2015EstoqueHistorico.component';
import { R2015MovimentacaoMovimentacaoPendente } from './relatorio/r2015/r2015MovimentacaoMovimentacaoPendente/r2015MovimentacaoMovimentacaoPendente.component';
import { R2015MovimentacaoProdutoEstoqueHistorico } from './relatorio/r2015/r2015MovimentacaoProdutoEstoqueHistorico/r2015MovimentacaoProdutoEstoqueHistorico.component';
import { R2015OperacaoMovimentacao } from './relatorio/r2015/r2015OperacaoMovimentacao/r2015OperacaoMovimentacao.component';
import { R2015QuantidadeMovimentacaoProMovimentacaoQua } from './relatorio/r2015/r2015QuantidadeMovimentacaoProMovimentacaoQua/r2015QuantidadeMovimentacaoProMovimentacaoQua.component';
import { R2015SituacaoMovimentacaoParMovimentacao } from './relatorio/r2015/r2015SituacaoMovimentacaoParMovimentacao/r2015SituacaoMovimentacaoParMovimentacao.component';
import { R2015SituacaoMovimentacaoProMovimentacao } from './relatorio/r2015/r2015SituacaoMovimentacaoProMovimentacao/r2015SituacaoMovimentacaoProMovimentacao.component';
import { R2015SituacaoMovimentacaoQuaMovimentacao } from './relatorio/r2015/r2015SituacaoMovimentacaoQuaMovimentacao/r2015SituacaoMovimentacaoQuaMovimentacao.component';
import { R2015TransferenciaPisCofins } from './relatorio/r2015/r2015TransferenciaPisCofins/r2015TransferenciaPisCofins.component';
import { R2015ValorBaixaFinanceiroFinanceiroBaixa } from './relatorio/r2015/r2015ValorBaixaFinanceiroFinanceiroBaixa/r2015ValorBaixaFinanceiroFinanceiroBaixa.component';
import { R2015ValorDescontoFinanceiroFinanceiroBaixa } from './relatorio/r2015/r2015ValorDescontoFinanceiroFinanceiroBaixa/r2015ValorDescontoFinanceiroFinanceiroBaixa.component';
import { R2015ValorFinalFinanceiro } from './relatorio/r2015/r2015ValorFinalFinanceiro/r2015ValorFinalFinanceiro.component';
import { R2015ValorFinanceiroMovimentacaoFinanceiro } from './relatorio/r2015/r2015ValorFinanceiroMovimentacaoFinanceiro/r2015ValorFinanceiroMovimentacaoFinanceiro.component';
import { R2015ValorFinanceiroMovimentacaoMovimentacaoPar } from './relatorio/r2015/r2015ValorFinanceiroMovimentacaoMovimentacaoPar/r2015ValorFinanceiroMovimentacaoMovimentacaoPar.component';
import { R2015ValorJuroFinanceiroFinanceiroBaixa } from './relatorio/r2015/r2015ValorJuroFinanceiroFinanceiroBaixa/r2015ValorJuroFinanceiroFinanceiroBaixa.component';
import { R2015ValorLiquidoMovimentacaoParFinanceiros } from './relatorio/r2015/r2015ValorLiquidoMovimentacaoParFinanceiro/r2015ValorLiquidoMovimentacaoParFinanceiro.component';
import { R2015ValorMovimentacaoMovimentacaoPar } from './relatorio/r2015/r2015ValorMovimentacaoMovimentacaoPar/r2015ValorMovimentacaoMovimentacaoPar.component';
import { R2015ValorMovimentacaoSemFinanceiroFinanceiro } from './relatorio/r2015/r2015ValorMovimentacaoSemFinanceiroFinanceiro/r2015ValorMovimentacaoSemFinanceiroFinanceiro.component';
import { R2015ValorRestanteFinanceiro } from './relatorio/r2015/r2015ValorRestanteFinanceiro/r2015ValorRestanteFinanceiro.component';
import { R2016Component } from './relatorio/r2016/r2016.component';
import { R2017Component } from './relatorio/r2017/r2017.component';
import { R2017ItensComponent } from './relatorio/r2017/r2017itens/r2017itens.component';
import { R2018Component } from './relatorio/r2018/r2018.component';
import { R2019Component } from './relatorio/r2019/r2019.component';
import { R2020Component } from './relatorio/r2020/r2020.component';
import { R2021Component } from './relatorio/r2021/r2021.component';
import { R2021PedidoCarteiraComponent } from './relatorio/r2021/r2021pedidoCarteira/r2021pedidoCarteira.component';
import { R2022Component } from './relatorio/r2022/r2022.component';
import { R2023Component } from './relatorio/r2023/r2023.component';
import { R2024Component } from './relatorio/r2024/r2024.component';
import { R2025Component } from './relatorio/r2025/r2025.component';
import { R2026Component } from './relatorio/r2026/r2026.component';
import { R2026ItensComponent } from './relatorio/r2026/r2026itens/r2026itens.component';
import { R2027Component } from './relatorio/r2027/r2027.component';
import { R2028Component } from './relatorio/r2028/r2028.component';
import { R2028ItensComponent } from './relatorio/r2028/r2028itens/r2028itens.component';
import { R2029Component } from './relatorio/r2029/r2029.component';
import { R2030Component } from './relatorio/r2030/r2030.component';
import { R2030DetalhesComponent } from './relatorio/r2030/r2030detalhes/r2030detalhes.component';
import { R2031Component } from './relatorio/r2031/r2031.component';
import { R2031DetalhesComponent } from './relatorio/r2031/r2031detalhes/r2031detalhes.component';
import { R2032Component } from './relatorio/r2032/r2032.component';
import { R2033Component } from './relatorio/r2033/r2033.component';
import { R3Component } from './relatorio/r3/r3.component';
import { R4Component } from './relatorio/r4/r4.component';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { RelatorioLayoutComponent } from './relatorio/relatorioLayout.component';
import { ComunicacaoService } from './servico/comunicacao.service';
import { ComunicacaoNsService } from './servico/comunicacaoNs.service';
import { ComunicacaoNsCTeService } from './servico/comunicacaoNsCTe.service';
import { ComunicacaoTecnoSpeedBoletoService } from './servico/comunicacaoTecnoSpeedBoleto.service';
import { ComunicacaoTecnoSpeedMDFeService } from './servico/comunicacaoTecnoSpeedMDFe.service';
import { DecimalPipe } from './utilitario/decimal.pipe';
import { FavoritoPipe } from './utilitario/favorito.pipe';
import { FocusDirective } from './utilitario/focus';
import { UtilNotificacao } from './utilitario/util.notificacao';
import { UtilSessao } from './utilitario/util.sessao';
import { UtilLimiteCredito } from './utilitario/utilLimiteCredito';
import { CteComponent } from './pagina/cte/cte.component';
import { CteFrmComponent } from './pagina/cte/cteFrm.component';
import { CteInformacaoCargaFrmComponent } from './pagina/cte/cteInformacaoCarga/cteInformacaoCargaFrm.component';
import { CteDocumentoFiscalFrmComponent } from './pagina/cte/cteDocumentoFiscal/cteDocumentoFiscalFrm.component';
import { R1248Component } from './relatorio/r1248/r1248.component';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  imports: [AppRoutingModule, BrowserModule, CommonModule, FormsModule, HttpClientModule, CidadeModule, CompartilhadoModule],
  declarations: [
    AlterarMovimentacaoComponent,
    AppComponent,
    AprovacaoComponent,
    AprovacaoFrmComponent,
    AtalhoComponent,
    BaixaBaixaComponent,
    BaixaBuscaComponent,
    BaixaComponent,
    BaixaComprovanteComponent,
    BaixaListaComponent,
    BloqueiaComponent,
    BloqueiaEstoqueComponent,
    BloqueioPeriodoComponent,
    BloqueioPeriodoFrmComponent,
    CaixaComponent,
    CaixaFrmComponent,
    CabecalhoGrupoComponent,
    CancelamentoComponent,
    CancelamentoEstornoComponent,
    CancelamentoFrmComponent,
    CartaCorrecaoComponent,
    CartaCorrecaoFrmComponent,
    CestComponent,
    CestFrmComponent,
    CfopEmpresaComponent,
    CfopEmpresaFrmComponent,
    CheckIcone,
    ChequeComponent,
    ChequeFrmComponent,
    ChequePipe,
    ClassificacaoComponent,
    ClassificacaoFiscalComponent,
    ClassificacaoFiscalFrmComponent,
    ClassificacaoFrmComponent,
    ClassificacaoOperacaoComponent,
    ClienteEspecialComponent,
    ComissaoFaturamentoComponent,
    ComissaoFaturamentoEstornoComponent,
    ConciliacaoComponent,
    ConfiguracaoContabilComponent,
    ConfiguracaoContabilFrmComponent,
    ConfiguracaoContabilTotalFrmComponent,
    ConfiguracaoRelatorioContabilComponent,
    ConfiguracaoRelatorioContabilFrmComponent,
    ContaComponent,
    ContaContabilComponent,
    ContaContabilFrmComponent,
    ContaCorrenteComponent,
    ContaCorrenteFrmComponent,
    ContaFrmComponent,
    CulturaComponent,
    CulturaFrmComponent,
    CteComponent,
    CteFrmComponent,
    CteDocumentoFiscalFrmComponent,
    CteInformacaoCargaFrmComponent,
    CreditoComponent,
    DecimalPipe,
    DesfazimentoComponent,
    DesfazimentoEstornoComponent,
    DesfazimentoFrmComponent,
    DocumentoEletronicoComponent,
    DocumentoEletronicoMDFeComponent,
    DocumentoEletronicoCTeComponent,
    DocumentoEletronicoReceituarioComponent,
    EmpresaComponent,
    EncerramentoContabilComponent,
    EncerramentoContabilFrmComponent,
    EnderecoCepComponent,
    EntregasComponent,
    EspecialComponent,
    EspecialFrmComponent,
    EstoqueComponent,
    EquipeComponent,
    EquipeFrmComponent,
    EquipeParceiroComponent,
    EquipamentoComponent,
    EquipamentoFrmComponent,
    FabricanteComponent,
    FabricanteFrmComponent,
    FavoritoPipe,
    FinanceiroComponent,
    FinanceiroEstornoComponent,
    FinanceiroFrmBaiComponent,
    FinanceiroFrmComponent,
    FinanceiroFrmNegComponent,
    FocusDirective,
    GenericoComponent,
    GenericoFrmComponent,
    GenericoHierarquiaComponent,
    GenericoHierarquiaFrmComponent,
    GrupoComponent,
    GrupoFrmComponent,
    GrupoAcessoComponent,
    GrupoAcessoFrmComponent,
    GrupoAcessoHorarioFrmComponent,
    GrupoAcessoLocalFrmComponent,
    GrupoContabilComponent,
    GrupoContabilFrmComponent,
    GrupoImpostoComponent,
    GrupoImpostoFrmComponent,
    GrupoPermissaoComponent,
    GrupoPermissaoFrmComponent,
    GrupoPermissaoMenusFrmComponent,
    GrupoPermissaoRelatoriosFrmComponent,
    GrupoValorComponent,
    HistoricoComponent,
    HistoricoFrmComponent,
    IcmsFrmComponent,
    IcmsPipe,
    IcmsDesoneradoPipe,
    IcmsStComponent,
    IcmsStFrmComponent,
    ImagemComponent,
    IntegracaoListaComponent,
    IntegracaoListaPipe,
    LancamentoContabilComponent,
    LancamentoContabilFrmComponent,
    LimiteCreditoComponent,
    LimiteCreditoHistoricoComponent,
    LocalComponent,
    LocalFrmComponent,
    LoginComponent,
    LojaComponent,
    LojaEstadoFrmComponent,
    LojaFrmComponent,
    MdfeComponent,
    MdfeFrmComponent,
    MdfeDocumentoFiscalFrmComponent,
    MdfePercursoFrmComponent,
    MenuComponent,
    MenuEmpresaComponent,
    MenuModuloEmpresaComponent,
    MenuPersonalizadoComponent,
    ModuloEmpresaComponent,
    ModuloEmpresaFrmComponent,
    MovimentacaoAbrirComponent,
    MovimentacaoCabecalhoAlternativoFrmComponent,
    MovimentacaoCabecalhoFrmComponent,
    MovimentacaoCabecalhoPadraoFrmComponent,
    MovimentacaoChaveComponent,
    MovimentacaoChaveFrmComponent,
    MovimentacaoComponent,
    MovimentacaoFrmComponent,
    MovimentacaoParcelaFrmComponent,
    MovimentacaoProdutoFrmComponent,
    MovimentacaoProdutoImpostoFrmComponent,
    MovimentacaoQuantidadeComponent,
    MovimentacaoRodapeFrmComponent,
    MovimentacaoTotalizadorFrmComponent,
    MovimentacaoTransporteFrmComponent,
    MovimentacaoVinculadaPipe,
    NaturezaOperacaoComponent,
    NaturezaOperacaoFrmComponent,
    NegociacaoComponent,
    NegociacaoFrmComponent,
    NomenclaturaEmpresaComponent,
    ParceiroArtComponent,
    ParceiroArtFrmComponent,
    ParceiroAtualizacaoComponent,
    ParceiroCobrancaComponent,
    ParceiroComponent,
    ParceiroContatoComponent,
    ParceiroContatoFrmComponent,
    ParceiroDestinoComponent,
    ParceiroDestinoFrmComponent,
    ParceiroDestinoPropriedadeFrmComponent,
    ParceiroEnderecoComponent,
    ParceiroEnderecoFrmComponent,
    ParceiroFrmComponent,
    ParceiroNegociacaoComponent,
    ParceiroOcorrenciaComponent,
    ParceiroTelefoneComponent,
    ParceiroVeiculoComponent,
    ParceiroTelefoneFrmComponent,
    ParceiroVeiculoFrmComponent,
    PesquisaChequeComponent,
    PesquisaClienteMovimentacaoProdutoComponent,
    PesquisaMovimentacaoComponent,
    PesquisaProdutoComponent,
    VideoComponent,
    PesquisaTextoBuscaComponent,
    PisCofinsFrmComponent,
    PolegadaComponent,
    PolegadaFrmComponent,
    PatrimonialComponent,
    PatrimonialFrmComponent,
    PatrimonialLancamentoContabilFrmComponent,
    PragaComponent,
    PragaFrmComponent,
    PrincipalComponent,
    ProdutoCabecalhoFrmComponent,
    ProdutoComissaoComponent,
    ProdutoComponent,
    ProdutoEstoqueMinimoMaximoFrmComponent,
    ProdutoFrmComponent,
    ProdutoImpostoComponent,
    ProdutoImpostoFrmComponent,
    ProdutoParceiroComissaoComponent,
    ProdutoRodapeFrmComponent,
    ProdutoValorComponent,
    ProdutoValorHistoricoComponent,
    Progresso,
    R1001Component,
    R1001ItensComponent,
    R1001NotasComponent,
    R1002Component,
    R1002ItensComponent,
    R1003Component,
    R1004Component,
    R1004ItensComponent,
    R1009Component,
    R1009ItensComponent,
    R1009TiposTituloComponent,
    R1010Component,
    R1012Component,
    R1012ItensComponent,
    R1014Component,
    R1043Component,
    R1053Component,
    R1053ItensComponent,
    R1085Component,
    R1089Component,
    R1105Component,
    R1105TabelasComponent,
    R1109Component,
    R1109ItensComponent,
    R1110Component,
    R1111Component,
    R1112Component,
    R1113Component,
    R1114Component,
    R1115Component,
    R1116Component,
    R1117Component,
    R1118Component,
    R1125Component,
    R1125cofinsComponent,
    R1125icmsEntradaComponent,
    R1125icmsSaidaComponent,
    R1125icmsStComponent,
    R1125ipiComponent,
    R1125pisComponent,
    R1126AssinaturaComponent,
    R1126Component,
    R1126ContatoComponent,
    R1126DadosComponent,
    R1126DestinoComponent,
    R1126EnderecoComponent,
    R1126ItemComponent,
    R1126TelefoneComponent,
    R1127Component,
    R1127ProdutosComponent,
    R1128Component,
    R1131Component,
    R1134Component,
    R1138Component,
    R1144Component,
    R1145Component,
    R1151Component,
    R1151ContasComponent,
    R1151FinanceiroComponent,
    R1152Component,
    R1152ItensComponent,
    R1159Component,
    R1167Component,
    R1168Component,
    R1168MovimentacaoFinanceiraComponent,
    R1198Component,
    R1198ItensComponent,
    R1206Component,
    R1206ItensComponent,
    R1233Component,
    R1233MovimentacaoComponent,
    R1248Component,
    R1268ComissaoComponent,
    R1268Component,
    R1Component,
    R2000Component,
    R2000Entregas,
    R2000Itens,
    R2001AlteracaoLimiteMaximo,
    R2001ApuracaoLucroClienteEspecialComponent,
    R2001ApuracaoLucroComponent,
    R2001Component,
    R2001DebitoMaiorLimiteComponent,
    R2001DevolucaoComponent,
    R2001DevolucaoEspecialComponent,
    R2001FinanceiroReceitaDespesaComponent,
    R2001MovimentacaoAjusteEstoqueComponent,
    R2001MovimentacaoDistorcidaComponent,
    R2001MovimentacaoFabricanteComponent,
    R2001MovimentacaoGrupoProdutoComponent,
    R2001MovimentacaoManualComponent,
    R2001MovimentacaoNegociacaoComponent,
    R2001MovimentacaoOcorrenciaComponent,
    R2001MovimentacaoResumoNegociacaoComponent,
    R2001MovimentacaoVendedorComponent,
    R2001MovimentoFiscalComponent,
    R2001NotasNaoTransmitidas,
    R2001NotasTransmitidas,
    R2002Component,
    R2003Component,
    R2003ItensComponent,
    R2004Component,
    R2004ItensComponent,
    R2005Component,
    R2005CorrentistasComponent,
    R2006Component,
    R2006ItensComponent,
    R2007Component,
    R2008Component,
    R2009Component,
    R2010Component,
    R2011Component,
    R2012Component,
    R2013Component,
    R2014Component,
    R2014ItensComponent,
    R2015Component,
    R2015ConciliacaoFinanceiroBaixa,
    R2015ConciliacaoLancamentoContabil,
    R2015EntregaProdutoMovimentacaoProduto,
    R2015EstoqueHistorico,
    R2015MovimentacaoMovimentacaoPendente,
    R2015MovimentacaoProdutoEstoqueHistorico,
    R2015OperacaoMovimentacao,
    R2015QuantidadeMovimentacaoProMovimentacaoQua,
    R2015SituacaoMovimentacaoParMovimentacao,
    R2015SituacaoMovimentacaoProMovimentacao,
    R2015SituacaoMovimentacaoQuaMovimentacao,
    R2015TransferenciaPisCofins,
    R2015ValorBaixaFinanceiroFinanceiroBaixa,
    R2015ValorDescontoFinanceiroFinanceiroBaixa,
    R2015ValorFinalFinanceiro,
    R2015ValorFinanceiroMovimentacaoFinanceiro,
    R2015ValorFinanceiroMovimentacaoMovimentacaoPar,
    R2015ValorJuroFinanceiroFinanceiroBaixa,
    R2015ValorLiquidoMovimentacaoParFinanceiros,
    R2015ValorMovimentacaoMovimentacaoPar,
    R2015ValorMovimentacaoSemFinanceiroFinanceiro,
    R2015ValorRestanteFinanceiro,
    R2016Component,
    R2017Component,
    R2017ItensComponent,
    R2018Component,
    R2019Component,
    R2020Component,
    R2021Component,
    R2021PedidoCarteiraComponent,
    R2022Component,
    R2023Component,
    R2024Component,
    R2025Component,
    R2026Component,
    R2026ItensComponent,
    R2027Component,
    R2028Component,
    R2028ItensComponent,
    R2029Component,
    R2030Component,
    R2030DetalhesComponent,
    R2031Component,
    R2031DetalhesComponent,
    R2032Component,
    R2033Component,
    R2Component,
    R3Component,
    R4Component,
    RadioComponent,
    RelacionamentoComponent,
    RelacionamentoFrmComponent,
    RelatorioComponent,
    RelatorioLayoutComponent,
    RelatorioListaComponent,
    RelatorioListaPipe,
    RelatorioModuloEmpresaComponent,
    RelatorioPersonalizadoComponent,
    RelatorioPersonalizadoFrmComponent,
    RelatorioPersonalizadoPipe,
    RelatorioUsuariosFrmComponent,
    RegiaoComponent,
    RegiaoFrmComponent,
    RodapeTotalComponent,
    RomaneioEntregaComponent,
    RomaneioEntregaFrmComponent,
    RomaneioEntregaDocumentoFiscalFrmComponent,
    RoteiroContabilComponent,
    RoteiroContabilFrmComponent,
    RoteiroContabilRateioFrmComponent,
    SafraComponent,
    SafraFrmComponent,
    SaldoBancarioComponent,
    SepararEntregaComponent,
    SepararEntregaFrmComponent,
    TabelaPrecoModalComponent,
    TipoTituloCaixaFrmComponent,
    TipoTituloComponent,
    TipoTituloFrmComponent,
    TotalizadorComponent,
    TransferenciaBancariaComponent,
    TransferenciaBancariaFrmComponent,
    TransferenciaChequePipe,
    UnidadeComponent,
    UnidadeFrmComponent,
    UsuarioCategoriaRoteirosFrmComponent,
    UsuarioComponent,
    UsuarioContasFrmComponent,
    UsuarioDadosFrmComponent,
    UsuarioExtraFrmComponent,
    UsuarioFotoFrmComponent,
    UsuarioFrmComponent,
    UsuarioIntegracaoFrmComponent,
    UsuarioIntegracaoPipe,
    UsuarioLojasFrmComponent,
    UsuarioMenuPipe,
    UsuarioMenusFrmComponent,
    VinculoComponent,
    VinculoFrmComponent,
    NfeXmlPdfComponent,
    NfeXmlPdfFrmComponent,
    MonitoramentoComponent,
  ],
  providers: [AutenticacaoGuard, AutenticacaoRelatorioGuard, BaixaNotificacao, BibNomenclatura, ComunicacaoService, DatePipe, DecimalPipe, LoginEmitter, Progresso, UtilNotificacao, UtilSessao, UtilLimiteCredito, ComunicacaoNsService, ComunicacaoTecnoSpeedService, ComunicacaoTecnoSpeedBoletoService, ComunicacaoTecnoSpeedMDFeService, ModalNotificacao, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: APP_BASE_HREF, useFactory: getBaseHref, deps: [PlatformLocation] }, { provide: RouteReuseStrategy, useClass: AARouteReuseStrategy }],
  entryComponents: [FinanceiroFrmComponent, MovimentacaoFrmComponent, ParceiroDestinoFrmComponent, ParceiroTelefoneFrmComponent, ParceiroEnderecoFrmComponent, ParceiroContatoFrmComponent, PesquisaProdutoComponent, PesquisaMovimentacaoComponent, FiltroComponent, MovimentacaoProdutoImpostoFrmComponent, AprovacaoFrmComponent, VideoComponent],
  bootstrap: [AppComponent],
  exports: [CheckComponent, TotalizadorComponent],
})
export class AppModule { }
