import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1125icmsSaida]</tr>',
  templateUrl: './r1125icmsSaida.component.html',
})
export class R1125icmsSaidaComponent {
  @Input() produtoIcmsSaidas: any[];
  public bibDialogo = bibDialogo;
}
