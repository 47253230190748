import { Component, Input } from '@angular/core';
import { Util } from 'src/app/utilitario/util';

@Component({
  selector: 'registro',
  templateUrl: './registro.component.html',
})
export class RegistroComponent {
  @Input() numeroRegistro: number = 0;
  @Input() paginacao: number;
  @Input() posicao: number;
  public util: Util = new Util();
}
