import { CommonModule, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DecimalDirective } from 'src/app/utilitario/decimal.directive';
import { AjudaComponent } from '../essencial/ajuda/ajuda.component';
import { AtencaoComponent } from '../essencial/atencao/atencao.component';
import { AuditoriaComponent } from '../essencial/auditoria/auditoria.component';
import { BloqueadoComponent } from '../essencial/bloqueado/bloqueado.component';
import { BotaoComponent } from '../essencial/botao/botao.component';
import { FiltroComponent } from '../essencial/filtro/filtro.component';
import { IconeComponent } from '../essencial/icone/icone.component';
import { LayoutComponent } from '../essencial/layout/layout.component';
import { UtilitarioComponent } from '../essencial/layout/utilitario/utilitario.component';
import { LayoutFiltroComponent } from '../essencial/layoutFiltro/layoutFiltro.component';
import { ModalComponent } from '../essencial/modal/modal.component';
import { PaginacaoComponent } from '../essencial/paginacao/paginacao.component';
import { RegistroComponent } from '../essencial/registro/registro.component';
import { WhatsappComponent } from '../essencial/whatsapp/whatsapp.component';
import { ApresentacaoComponent } from '../formulario/apresentacao/apresentacao.component';
import { BuscaComponent } from '../formulario/busca/busca.component';
import { CarrosselComponent } from '../formulario/carrossel/carrossel.component';
import { CheckComponent } from '../formulario/check/check.component';
import { CheckListaComponent } from '../formulario/checkLista/checkLista.component';
import { DataComponent } from '../formulario/data/data.component';
import { DecimalComponent } from '../formulario/decimal/decimal.component';
import { BotaoAdicionalComponent } from '../formulario/dependencia/botao/adicional/btnAdicional.component';
import { ContainerFormularioComponent } from '../formulario/dependencia/containerFormulario/containerFormulario.component';
import { ObrigatorioComponent } from '../formulario/dependencia/obrigatorio/obrigatorio.component';
import { RotuloComponent } from '../formulario/dependencia/rotulo/rotulo.component';
import { DescricaoComponent } from '../formulario/descricao/descricao.component';
import { InterruptorComponent } from '../formulario/interruptor/interruptor.component';
import { ListaComponent } from '../formulario/lista/lista.component';
import { MascaraComponent } from '../formulario/mascara/mascara.component';
import { PesquisaComponent } from '../formulario/pesquisa/pesquisa/pesquisa.component';
import { TextoComponent } from '../formulario/texto/texto.component';
import { TituloComponent } from '../formulario/titulo/titulo.component';
import { RelatorioUsuarioPipe } from '../pagina/usuario/frm/usuarioRelatoriosFrm/relatorioUsuarios.pipe';
import { DataPipe } from '../utilitario/data.pipe';
import { MascaraDirective } from '../utilitario/mascara.directive';
import { MonetarioPipe } from '../utilitario/monetario.pipe';
import { PaginaComponent } from '../utilitario/pagina.component';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [LayoutComponent, RegistroComponent, ContainerFormularioComponent, PaginacaoComponent, BotaoAdicionalComponent, TextoComponent, BotaoComponent, InterruptorComponent, ListaComponent, AuditoriaComponent, PaginaComponent, ModalComponent, IconeComponent, AtencaoComponent, UtilitarioComponent, BloqueadoComponent, AjudaComponent, FiltroComponent, BuscaComponent, DecimalComponent, MascaraComponent, RotuloComponent, ObrigatorioComponent, ContainerFormularioComponent, DataComponent, DataPipe, MascaraDirective, DecimalDirective, RelatorioUsuarioPipe, MonetarioPipe, DescricaoComponent, ApresentacaoComponent, TituloComponent, PesquisaComponent, CheckComponent, CarrosselComponent, CheckListaComponent, LayoutFiltroComponent, WhatsappComponent],
  imports: [CommonModule, FormsModule, NgbModule],
  exports: [LayoutComponent, RegistroComponent, PaginacaoComponent, BotaoAdicionalComponent, TextoComponent, BotaoComponent, InterruptorComponent, ListaComponent, AuditoriaComponent, PaginaComponent, ModalComponent, IconeComponent, AtencaoComponent, UtilitarioComponent, BloqueadoComponent, AjudaComponent, FiltroComponent, BuscaComponent, DecimalComponent, MascaraComponent, RotuloComponent, ObrigatorioComponent, ContainerFormularioComponent, DataComponent, DataPipe, MascaraDirective, ContainerFormularioComponent, NgbModule, RelatorioUsuarioPipe, MonetarioPipe, DescricaoComponent, ApresentacaoComponent, TituloComponent, CheckComponent, CarrosselComponent, PesquisaComponent, CheckListaComponent, LayoutFiltroComponent, WhatsappComponent],
})
export class CompartilhadoModule { }
