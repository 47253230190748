<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)">
  <ng-container *ngFor="let entrega of entregas; let index_produto = index">
    <ng-container *ngFor="let entregaProduto of entrega.entregaProdutos; let i = index; count as contagem">
      <table [ngClass]="[index_produto + 1 == entregas.length && i + 20 >= contagem ? 'impedir-quebra-ultima-pagina' :  i % 20 == 0 || i == 0 ? 'quebra-pagina' : '']">
        <tbody>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 20 == 0) && i + 1 != contagem) || contagem == 1" class="top">
            <td>
              <div class="r relatorio-titulo">
                {{ bibDialogo.comprovanteEntregaProdutos }}
              </div>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.cliente + ':  ' }} </span> <span class="r-5 limitar">{{ entrega.parceiro }}</span> <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.cpfCnpj + ': ' }} </span> <span>{{ entrega.parceiroCnpjCpf }} </span>
              </div>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo fonte-endereco">{{ bibDialogo.endEntrega + ': ' }} </span>
                <span class="r-7 limitar fonte-endereco">{{ entrega.parceiroEndereco ? entrega.parceiroEndereco : '' }} </span>
              </div>
              <div class="row">
                <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.vendedor + ':  ' }} </span><span class="r-7 limitar">{{ entrega.colaboradorLoja + ' - ' + entrega.colaborador + ' (' + entrega.colaboradorNumero + ')' }} </span>
              </div>
            </td>
            <td class="r-2-5 align-items-center">
              <div class="row ml-0">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.movimentacaoNumeroAbreviacao + ': ' }} </span> <span>{{ entrega.movimentacaoNumero + ' - ' + entrega.numero }} </span>
              </div>
              <div class="row ml-0">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.emissao + ': ' }} </span> <span> {{ entrega.dataHoraSeparacao | data }} </span>
              </div>
              <div class="row ml-0">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.dataDocumentoAbreviado + ': ' }} </span> <span>{{ entrega.dataAtual | data: 'dd/MM/yyyy HH:mm:ss' }} </span>
              </div>
              <div class="row ml-0">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.formaPagamentoAbreviado + ': ' }} </span> <span>{{ entrega.negociacao }} </span>
              </div>
            </td>
          </tr>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 20 == 0) && i + 1 != contagem) || contagem == 1">
            <td colspan="2">
              <table class="tabela-produtos" style="border: none;">
                <thead>
                  <tr>
                    <th class="r-0-5 text-right">{{ bibDialogo.numeroAbreviacao }}</th>
                    <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
                    <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
                    <th class="r">{{ bibDialogo.descricaoPodutos }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entregaProduto of entrega.entregaProdutos; let j = index">
                    <ng-container *ngIf="j + 1 >= i + 1 + (i == 0 ? 0 : 1) && j + 1 <= i + 20 + (i == 0 ? 0 : 1)">
                      <td class="r-0-5 text-right">{{ entregaProduto.numero }}</td>
                      <td class="r-1 text-right">{{ entregaProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
                      <td class="r-0-5">{{ entregaProduto.unidade }}</td>
                      <td class="r limitar">{{ entregaProduto.produto }}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 20 == 0) && i + 1 != contagem) || contagem == 1">
            <td colspan="2">
              <div class="row">
                <span class="r-1 ml-2 text-right relatorio-rotulo">{{ bibDialogo.observacao + ': ' }} </span><span class="col-sm">{{ entrega.observacao }}</span>
              </div>
            </td>
          </tr>
          <tr *ngIf="((i + 1 == 1 || (i + 1) % 20 == 0) && i + 1 != contagem) || contagem == 1">
            <td colspan="2">
              <div class="row p-0 m-0 relatorio-rotulo">{{ bibDialogo.declaracaoRecebimentoMercadoria }}</div>
              <div class="row p-0 m-0 justify-content-between">
                <span class="r-4-5 relatorio-rotulo">{{ bibDialogo.assinaturaRecebedor + ': _______________________________________' }} </span>
                <span class="r-2 relatorio-rotulo">{{ bibDialogo.dataEntrega + ': ' + '___/___/____' }}</span>
                <span class="r-4-5 relatorio-rotulo">{{ bibDialogo.assinaturaEntregador + ': _______________________________________' }} </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
</relatorioLayout>
