<layout [titulo]="contaContabil.nome" [id]="contaContabil.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <lista class="col-sm-4" [id]="'tipo'" [rotulo]="bibDialogo.tipo" [campo]="tipoLista" [lista]="lista" (alteracao)="setLista($event.id)" [obrigatorio]="true" [foco]="true"></lista>
    <busca *ngIf="this.tipoLista == 'S'" class="col-sm" [id]="'numeracaoPai'" [rotulo]="menuPlanoConta.apelido + ' (' + bibDialogo.pai + ')'" [campo]="contaContabil.contaContabilPai" (alteracao)="setConta($event)" [servico]="bibServico.contaContabil" [criterios]="criterios"></busca>
  </div>
  <div class="row">
    <texto class="col-sm-4" [id]="'numeracao'" [rotulo]="bibDialogo.numeracao" [campo]="contaContabil.numeracao" (alteracao)="contaContabil.numeracao = $event" [desabilitado]="true"></texto>
    <icone [id]="'editarNumeracao'" *ngIf="(tipoLista == 'N' || (tipoLista == 'S' && this.contaContabil.contaContabilPai && this.contaContabil.idPlanoContaPai)) && contaContabil.pai == 'N'" [icone]="bibIcone.editar" [clique]="true" (iconeEmt)="setEditarNumeracao($event)" [mensagem]="bibDialogo.numeracao + ' ' + bibDialogo.manual" [centralizaVertical]="true"></icone>
    <texto [id]="'edicaoNumeracao'" *ngIf="editaNumeracao" [rotulo]="bibDialogo.numeracao + ' ' + bibDialogo.manual" [tipo]="this.bibPropriedade.texto.numero" [campo]="novaNumeracaoFinal" (alteracao)="setNovaNumeracao($event)" [maximoCaracteres]="maximoCaracteresEdicaoNumeracao" (navegacao)="setAlteracaoNumeracao($event)"></texto>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome + ' ' + (tipoLista != null ? (tipoLista == 'N' ? menuPlanoConta.apelido.toLowerCase() : this.bibDialogo.sub.toLowerCase() + '-' + menuPlanoConta.apelido.toLowerCase()) : '')" [campo]="contaContabil.nome ? contaContabil.nome.trim() : null" (alteracao)="contaContabil.nome = $event" [obrigatorio]="true" [focoId]="idFocoContaContabilPai"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="contaContabil.ativo" (alteracao)="contaContabil.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista *ngIf="trabalhaCorrentista" class="col-sm-4" [id]="'correntista'" [rotulo]="bibDialogo.correntista" [campo]="contaContabil.correntista" [lista]="correntistas" (alteracao)="contaContabil.correntista = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div class="row">  
    <busca [id]="'contaReferencial'" class="col" [rotulo]="bibDialogo.contaReferencial" [campo]="contaContabil.contaReferencial" (alteracao)="contaContabil.idContaReferencial = $event.id" [servico]="bibServico.contaReferencial"></busca>
  </div>
  <div class="row">
    <decimal class="col-sm-4" [id]="'saldoInicial'" [rotulo]="bibDialogo.saldoInicial" [campo]="contaContabil.saldoInicial" (alteracao)="contaContabil.saldoInicial = $event" [aceitaValorNegativo]="true"></decimal>
  </div>
  <div class="row">
    <decimal class="col-sm-4" [id]="'nivel'" [rotulo]="bibDialogo.nivel" [campo]="contaContabil.nivelSped" (alteracao)="contaContabil.nivelSped = $event" [numeroCasasDecimais]="0"></decimal>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'tipoDaConta'" [rotulo]="bibDialogo.tipoDaConta" [campo]="contaContabil.tipoDaConta" [lista]="tiposConta" (alteracao)="contaContabil.tipoDaConta = $event.id" [obrigatorio]="true"></lista> 
    <lista class="col-sm-6" [id]="'natureza'" [rotulo]="bibDialogo.natureza" [campo]="contaContabil.natureza" [lista]="naturezas" (alteracao)="contaContabil.natureza = $event.id" [obrigatorio]="true"></lista>
  </div>  
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirContaContabil()" [desabilitado]="bloquearPermissao(contaContabil.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirContaContabil(true)" [desabilitado]="bloquearPermissao(contaContabil.id)"></botao>
<auditoria *ngIf="contaContabil.id" [idUsuarioInclusao]="contaContabil.idUsuarioInclusao" [dataHoraInclusao]="contaContabil.dataHoraInclusao" [idUsuarioAlteracao]="contaContabil.idUsuarioAlteracao" [dataHoraAlteracao]="contaContabil.dataHoraAlteracao"></auditoria>
