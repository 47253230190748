import { Component } from '@angular/core';
import filtros from './acessorio/filtro';
import { R1109ItensComponent } from '../r1109/r1109itens/r1109itens.component';
import { R1109Component } from '../r1109/r1109.component';

@Component({
  templateUrl: './r2032.component.html',
})
export class R2032Component extends R1109Component {
  public filtros = filtros;
}
