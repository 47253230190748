import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ComunicacaoTecnoSpeedService {
  private token: string = '7d689946-55be-4f73-8314-e908f36caf38'; //produção
  //private token: string = '2da392a6-79d2-4304-a8b7-959572c7e44d';//sandbox

  constructor(private http: HttpClient) { }

  listar(url: string, parametros: any): Observable<any> {
    return this.consumir(url, parametros, 'GET');
  }

  persistir(url: string, parametros: any): Observable<any> {
    return this.consumir(url, parametros, 'POST');
  }

  private consumir(url: string, parametros: any, metodo: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Content-Type', 'application/json');
    if (metodo == 'POST') {
      let json: string = JSON.stringify(parametros);
      json = json.replace('importacaoIi', 'importacao')
      return this.http.post(url, json, { headers });
    } else if (metodo == 'GET') {
      return this.http.get(url, { headers: headers });
    }
  }

  consumirPdf(url: string): Observable<Blob> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Accept', 'application/pdf');
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

  consumirXml(url: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Accept', 'application/octet-stream');
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

  consumirResumoNfe(url: string): Observable<Blob> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Accept', 'application/json');
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

  persistirLogotipo(url: string, parametros: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Accept', 'multipart/form-data');
    return this.http.post(url, parametros, { headers: headers });
  }

  deletarLogotipo(url: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('x-api-key', this.token).set('Content-Type', 'application/json');
    return this.http.delete(url, { headers: headers });
  }
}
