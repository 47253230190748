import { Component, EventEmitter, Input, Output } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';

@Component({
  selector: 'btnAdicional',
  templateUrl: './btnAdicional.component.html',
  styleUrls: ['./btnAdicional.component.css'],
})
export class BotaoAdicionalComponent {
  @Input() ajuda: string;
  @Input() mensagem: string;
  @Input() icone: string;
  @Input() desabilitado: boolean = false;
  @Input() compacto: boolean = false;
  @Input() compactoBotao: boolean = false;
  @Input() focoIndice: number = 0;
  @Output() btnAdicional = new EventEmitter<boolean>();

  public _iconeCor: string = '';
  @Input()
  set iconeCor(iconeCor: string) {
    this.definirCor(iconeCor);
  }

  private bibPropriedade = bibPropriedade;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;

  clicar(): void {
    if (!this.desabilitado) {
      event.preventDefault();
      this.btnAdicional.emit(true);
    }
  }

  definirCor(cor): void {
    switch (cor) {
      case this.bibPropriedade.icone.vermelho:
        this._iconeCor = 'fa-vermelho';
        break;
      case this.bibPropriedade.icone.roxo:
        this._iconeCor = 'fa-roxo';
        break;
      case this.bibPropriedade.icone.verde:
        this._iconeCor = 'fa-verde';
        break;
      case this.bibPropriedade.icone.amarelo:
        this._iconeCor = 'fa-amarelo';
        break;
      case this.bibPropriedade.icone.azul:
        this._iconeCor = 'fa-azul';
        break;
      case this.bibPropriedade.icone.cinza:
        this._iconeCor = 'fa-cinza';
        break;
      case this.bibPropriedade.icone.laranja:
        this._iconeCor = 'fa-laranja';
        break;
      case this.bibPropriedade.icone.cinzaEscuro:
        this._iconeCor = 'fa-cinza-escuro';
        break;
      default:
        this._iconeCor = 'black';
    }
  }
}
