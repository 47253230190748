<layout [titulo]="bibDialogo.chaveReferenciada">
  <div class="row">
    <lista class="col-sm" [rotulo]="bibDialogo.modelo" [campo]="movimentacaoChave.modelo" (alteracao)="movimentacaoChave.modelo = $event.id" [lista]="nfeModelos" [obrigatorio]="true"></lista>
    <texto class="col-sm-3" [rotulo]="bibDialogo.serie" [campo]="movimentacaoChave.serie" (alteracao)="movimentacaoChave.serie = $event" [maximoCaracteres]="4" [obrigatorio]="true"></texto>
  </div>
  <div class="row align-items-center">
    <texto class="col-sm" [rotulo]="bibDialogo.chave" [campo]="movimentacaoChave.chave" (alteracao)="movimentacaoChave.chave = $event" [maximoCaracteres]="44" [obrigatorio]="true"></texto>
    <texto class="col-sm-3" [rotulo]="bibDialogo.numeroFiscal" [campo]="movimentacaoChave.numeroFiscal" [tipo]="bibPropriedade.texto.inteiro" (alteracao)="movimentacaoChave.numeroFiscal = $event" [obrigatorio]="true"></texto>
  </div>
  <botao [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.referencia.toLowerCase()" (botaoEmt)="adicionarReferencia()" [compacto]="compacto"></botao>
</layout>
