export class ProdutoIcms {
  public aliquotaFisica: number = 0;
  public aliquotaJuridica: number = 0;
  public classificacaoFiscal: string;
  public classificacaoFiscalBase: number;
  public classificacaoFiscalIdCstIcms: number;
  public classificacaoFiscalIdCstIcmsSemInscricao: number;
  public cstIcms: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public enquadramento: number;
  public estado: string;
  public estadoOrigem: string;
  public excluido: boolean = false;
  public id: number;
  public idClassificacaoFiscal: number;
  public idEmpresa: number;
  public idEstado: number;
  public idEstadoOrigem: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public produto: string;
  public tipo: number;
  public usaIcmsDesonerado: string = 'N';
}
