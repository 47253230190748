<table class="tabela-complementar">
  <thead>
    <tr>
      <th class="r-1">{{ bibDialogo.numero }}</th>
      <th class="r-2">{{ bibDialogo.dataNegociacao }}</th>
      <th class="r">{{ bibDialogo.cliente }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th class="r-2" colSpan="2"></th>
    </tr>
  </thead>
  <tbody>
      <tr *ngFor="let comissaoDetalhe of comissao.comissaoDetalhes">
        <a href="#" (click)="abrirModalMovimentacao(comissaoDetalhe.idMovimentacao)">{{ comissaoDetalhe.numero }}</a>
        <td>{{ comissaoDetalhe.data | data }}</td>
        <td>{{ comissaoDetalhe.cliente }}</td>
        <td class="text-right">{{ comissaoDetalhe.valor | monetario }}</td>
        <td colSpan="2"></td>
      </tr>
  </tbody>
  <tfoot>
    <tr>
      <td class="text-right" colSpan="3"></td>
      <th class="text-right"><strong>{{ comissao.valor | monetario  }}</strong></th>
    </tr>
  </tfoot>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
