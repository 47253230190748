<layout [titulo]="produtoFormuladoDose.cultura || produtoFormuladoDose.praga ? produtoFormuladoDose.cultura + (produtoFormuladoDose.praga ? ' - ' + produtoFormuladoDose.praga : '') : bibDialogo.indicacao" [id]="produtoFormuladoDose.id">
  <div class="row">
    <texto class="col-sm-6" [id]="'cultura'" [rotulo]="bibDialogo.cultura" [campo]="produtoFormuladoDose.cultura" (alteracao)="produtoFormuladoDose.cultura = $event" [foco]="true" [obrigatorio]="true" [desabilitado]="produtoFormuladoDose.desabilitado"></texto>
    <texto class="col-sm-6" [id]="'praga'" [rotulo]="bibDialogo.praga" [campo]="produtoFormuladoDose.praga" (alteracao)="produtoFormuladoDose.praga = $event" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="true"></texto>
  </div>
  <div class="row">
    <decimal class="col-sm-6" [id]="'doseDe'" [rotulo]="bibDialogo.doseDe" [campo]="produtoFormuladoDose.doseDe" (alteracao)="produtoFormuladoDose.doseDe = $event" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="true"></decimal>
    <decimal class="col-sm-6" [id]="'doseAte'" [rotulo]="bibDialogo.doseAte" [campo]="produtoFormuladoDose.doseAte" (alteracao)="produtoFormuladoDose.doseAte = $event" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="true"></decimal>
    <texto class="col-sm-6" [id]="'unidadeDose'" [rotulo]="bibDialogo.unidadeDose" [campo]="produtoFormuladoDose.doseUnidade" (alteracao)="produtoFormuladoDose.doseUnidade = $event" [obrigatorio]="true" [desabilitado]="produtoFormuladoDose.desabilitado"></texto>
    <decimal class="col-sm-6" [id]="'doseFator'" [rotulo]="bibDialogo.fator" [campo]="produtoFormuladoDose.doseFator" (alteracao)="produtoFormuladoDose.doseFator = $event" [desabilitado]="produtoFormuladoDose.desabilitado" [ajuda]="bibDialogo.ajudaDoseFator" [numeroCasasDecimais]="4"></decimal>
  </div>
  <div class="row">
    <decimal class="col-sm-6" [id]="'caldaTerrestreDe'" [rotulo]="bibDialogo.caldaTerrestreDe" [campo]="produtoFormuladoDose.caldaDeTerrestre" (alteracao)="produtoFormuladoDose.caldaDeTerrestre = $event" [obrigatorio]="produtoFormuladoDose.caldaAteTerrestre || produtoFormuladoDose.caldaAteTerrestre == 0" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="produtoFormuladoDose.caldaDeTerrestre || produtoFormuladoDose.caldaAteTerrestre || produtoFormuladoDose.caldaUnidadeTerrestre"></decimal>
    <decimal class="col-sm-6" [id]="'caldaTerrestreAte'" [rotulo]="bibDialogo.caldaTerrestreAte" [campo]="produtoFormuladoDose.caldaAteTerrestre" (alteracao)="produtoFormuladoDose.caldaAteTerrestre = $event" [obrigatorio]="produtoFormuladoDose.caldaDeTerrestre || produtoFormuladoDose.caldaDeTerrestre == 0" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="produtoFormuladoDose.caldaDeTerrestre || produtoFormuladoDose.caldaAteTerrestre || produtoFormuladoDose.caldaUnidadeTerrestre"></decimal>
    <texto class="col-sm-12" [id]="'undCaldaTerrestre'" [rotulo]="bibDialogo.undCaldaTerrestre" [campo]="produtoFormuladoDose.caldaUnidadeTerrestre" (alteracao)="produtoFormuladoDose.caldaUnidadeTerrestre = $event" [obrigatorio]="produtoFormuladoDose.caldaDeTerrestre || produtoFormuladoDose.caldaDeTerrestre == 0 || produtoFormuladoDose.caldaAteTerrestre || produtoFormuladoDose.caldaAteTerrestre == 0" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="produtoFormuladoDose.caldaDeTerrestre || produtoFormuladoDose.caldaAteTerrestre || produtoFormuladoDose.caldaUnidadeTerrestre"></texto>
  </div>
  <div class="row">
    <decimal class="col-sm-6" [id]="'caldaAereoDe'" [rotulo]="bibDialogo.caldaAereoDe" [campo]="produtoFormuladoDose.caldaDeAereo" (alteracao)="produtoFormuladoDose.caldaDeAereo = $event" [obrigatorio]="produtoFormuladoDose.caldaAteAereo || produtoFormuladoDose.caldaAteAereo == 0" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="produtoFormuladoDose.caldaDeAereo || produtoFormuladoDose.caldaAteAereo || produtoFormuladoDose.caldaUnidadeAereo"></decimal>
    <decimal class="col-sm-6" [id]="'caldaAereoAte'" [rotulo]="bibDialogo.caldaAereoAte" [campo]="produtoFormuladoDose.caldaAteAereo" (alteracao)="produtoFormuladoDose.caldaAteAereo = $event" [obrigatorio]="produtoFormuladoDose.caldaDeAereo || produtoFormuladoDose.caldaDeAereo == 0" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="produtoFormuladoDose.caldaDeAereo || produtoFormuladoDose.caldaAteAereo || produtoFormuladoDose.caldaUnidadeAereo"></decimal>
    <texto class="col-sm-6" [id]="'undCaldaAereo'" [rotulo]="bibDialogo.undCaldaAereo" [campo]="produtoFormuladoDose.caldaUnidadeAereo" (alteracao)="produtoFormuladoDose.caldaUnidadeAereo = $event" [obrigatorio]="produtoFormuladoDose.caldaDeAereo || produtoFormuladoDose.caldaDeAereo == 0 || produtoFormuladoDose.caldaAteAereo || produtoFormuladoDose.caldaAteAereo == 0" [desabilitado]="produtoFormuladoDose.desabilitado" [obrigatorio]="produtoFormuladoDose.caldaDeAereo || produtoFormuladoDose.caldaAteAereo || produtoFormuladoDose.caldaUnidadeAereo"></texto>
  </div>
  <div class="row">
    <texto class="col-sm-12" [id]="'intervaloSeguranca'" [rotulo]="bibDialogo.intervaloSeguranca" [campo]="produtoFormuladoDose.intervaloSeguranca" (alteracao)="produtoFormuladoDose.intervaloSeguranca = $event" [desabilitado]="produtoFormuladoDose.desabilitado"></texto>
    <texto class="col-sm-12" [id]="'epocaAplicacao'" [rotulo]="bibDialogo.epocaAplicacao" [campo]="produtoFormuladoDose.epocaAplicacao" (alteracao)="produtoFormuladoDose.epocaAplicacao = $event" [desabilitado]="produtoFormuladoDose.desabilitado"></texto>
  </div>
  <div class="row">
    <descricao class="col-sm-12" [id]="'modalidadeAplicacao'" [rotulo]="bibDialogo.modalidadeAplicacao" [campo]="produtoFormuladoDose.modalidadeAplicacao" (alteracao)="produtoFormuladoDose.modalidadeAplicacao = $event" [desabilitado]="produtoFormuladoDose.desabilitado" [maximoCaracteres]="5000"></descricao>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirProdutoFormuladoDose()" [desabilitado]="produtoFormuladoDose.desabilitado"></botao>
<auditoria *ngIf="produtoFormuladoDose.id" [idUsuarioInclusao]="produtoFormuladoDose.idUsuarioInclusao" [dataHoraInclusao]="produtoFormuladoDose.dataHoraInclusao" [idUsuarioAlteracao]="produtoFormuladoDose.idUsuarioAlteracao" [dataHoraAlteracao]="produtoFormuladoDose.dataHoraAlteracao"></auditoria>
