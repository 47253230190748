import { Type } from 'class-transformer';

export class EquipeParceiro {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public comissao: number = 0;
  public id: number;
  public idEmpresa: number;
  public idEquipe: number;
  public idLoja: number;  
  public idParceiro: number;    
  public idGestor: number;  
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;

  public parceiro: string;
  public loja: string;
}
