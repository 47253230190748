export class RelatorioModuloEmpresa {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idModuloEmpresa: number;
  public idRelatorio: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public ordem: number = 0;
}
