import { Classificacao } from 'src/app/modelo/classificacao';
import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { ClassificacaoOperacao } from 'src/app/modelo/classificacaoOperacao';

@Component({
  templateUrl: './classificacaoFrm.component.html',
})
export class ClassificacaoFrmComponent extends PaginaComponent {
  public classificacao: Classificacao = new Classificacao();
  public operacoes: any[] = [];
  public idsClassificacaoOperacaoExcluir: number[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarOperacoes();
  }

  ehAlteracao(): void {
    this.classificacao = this.plainToClass(Classificacao, this.ehAtualizacao(this.classificacao));
    if (this.classificacao.id) {
      this.listarClassificacao(this.classificacao.id);
    }
  }

  listarClassificacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.classificacao).subscribe((res) => {
      this.classificacao = this.plainToClass(Classificacao, res[0]) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_CLASSIFICACAO', this.classificacao.id)), this.bibServico.classificacaoOperacao).subscribe((res) => {
        this.classificacao.classificacaoOperacoes = this.plainToClass(ClassificacaoOperacao, res) as any;
      });
    });
  }

  persistirClassificacao(novo: boolean = false): void {
    if (this.ehValidoObrigatorio()) {
      let transporte: Transporte = new Transporte(this.classificacao);
      transporte.adicionar(this.idsClassificacaoOperacaoExcluir);
      super.persistir(transporte, this.bibServico.classificacao, novo ? new Classificacao() : null);
      if (!this.utilSessao.falha) {
        this.definirId([this.classificacao], this.bibClasse.classificacaoOperacao, true);
        this.definirId(this.classificacao.classificacaoOperacoes, this.bibClasse.classificacaoOperacao);
        this.idsClassificacaoOperacaoExcluir = [];
      }
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.classificacao.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  listarOperacoes(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID')), this.bibServico.operacao).subscribe((res) => {
      this.operacoes = this.plainToClass(Classificacao, res) as any;
    });
  }
}
