import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { RelatorioComponent } from '../../relatorio.component';

@Component({
  selector: ' <tr>[r1105tabelas]</tr>',
  templateUrl: './r1105tabelas.component.html',
  styleUrls: ['.././r1105.component.css'],
})
export class R1105TabelasComponent extends RelatorioComponent{
  @Input() empresaRegraCasasDecimais: number;
  @Input() produto: any;
  public bibDialogo = bibDialogo;
}