import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Cte } from 'src/app/modelo/cte';
import { CteDocumentoFiscal } from 'src/app/modelo/cteDocumentoFiscal';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'cteDocumentoFiscal',
  templateUrl: './cteDocumentoFiscalFrm.component.html',
})
export class CteDocumentoFiscalFrmComponent extends PaginaComponent {
  @Input() cte: Cte;
  @Input() excluirCteDocumentoFiscais: number[] = [];
  @Input() edicaoCteDocumentoFiscais: number[] = [];
  @Input() bloqueado: boolean = false;

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public focoChave: number;

  ngOnInit(): void {

  }

  adicionarDocumentoFiscal(): void {
    if (this.bloqueado != true) {
      let cteDocumentoFiscal = new CteDocumentoFiscal();
      this.cte.cteDocumentoFiscais.push(cteDocumentoFiscal);
      this.focoChave = Math.random();
    }
  }

  excluirDocumentoFiscal(posicao: number, idCteDocumentoFiscal: number): void {
    if (this.bloqueado != true) {
      super.ehExcluir(posicao, this.cte.cteDocumentoFiscais, this.excluirCteDocumentoFiscais, idCteDocumentoFiscal);
    }
  }

  editarDocumentoFiscal(cteDocumentoFiscal: CteDocumentoFiscal): void {
    if (this.bloqueado != true) {
      if (this.edicaoCteDocumentoFiscais.includes(cteDocumentoFiscal.id)) {
        const posicao: number = this.edicaoCteDocumentoFiscais.indexOf(cteDocumentoFiscal.id, 0);
        this.edicaoCteDocumentoFiscais.splice(posicao, 1);
      } else {
        this.edicaoCteDocumentoFiscais.push(cteDocumentoFiscal.id);
      }
    }
  }
}
