import { Component } from '@angular/core';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { LojaEstado } from 'src/app/modelo/lojaEstado';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { Negociacao } from 'src/app/modelo/negociacao';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UtilMovimentacaoImpostos } from 'src/app/utilitario/utilMovimentacaoImpostos';

@Component({
  selector: 'movimentacaoProdutoImpostosFrm',
  templateUrl: './movimentacaoProdutoImpostoFrm.component.html',
  styleUrls: ['./movimentacaoProdutoImpostoFrm.component.css'],
})
export class MovimentacaoProdutoImpostoFrmComponent extends PaginaComponent {
  private devolucao: boolean;
  private faturamento: boolean;
  private financeiro: string;
  private idLoja: number;
  private idParceiro: number;
  private idParceiroDestino: number;
  private saida: boolean;
  public bloqueado: boolean = false;
  public bloqueiaAlteracao: boolean = true;
  public cfopsEmpresa: CfopEmpresa[] = [];
  public classificacaoBloqueadoEstoque: string;
  public classificacaoBloqueadoEstoqueNfe: string;
  public classificacaoBloqueadoFinanceiro: string;
  public compacto: boolean = false;
  public icmsCsts: Cst[] = [];
  public idOperacao: number;
  public ipiCsts: Cst[] = [];
  public movimentacaoProduto: MovimentacaoProduto;
  public movimentacaoProdutoIndice: number;
  public naturezaOperacaoFinalidade: number;
  public obrigatorio = true;
  public completo = true;
  public mostraCodigoBeneficioFiscal: boolean = false;
  public parceiroDestino: ParceiroDestino;
  public parceiroEndereco: ParceiroEndereco;
  public parceiroTipoPessoa: string;
  public pisCofinsCsts: Cst[] = [];
  public casasDecimais: number = 2;
  public utilMovimentacaoImpostos: UtilMovimentacaoImpostos;
  public nomeParceiro: string = 'Cliente';
  public parceiroInscricao: string;
  public entradaServico: boolean = false;
  public alimentaCustoReposicao: boolean = false;
  public movimentacao: Movimentacao;

  ngOnInit(): void {
    this.bloqueado = this.utilSessao.getIdentificacao('bloqueado')?.conteudo;
    this.classificacaoBloqueadoEstoque = this.utilSessao.getIdentificacao('classificacaoBloqueadoEstoque')?.conteudo;
    this.classificacaoBloqueadoEstoqueNfe = this.utilSessao.getIdentificacao('classificacaoBloqueadoEstoqueNfe')?.conteudo;
    this.classificacaoBloqueadoFinanceiro = this.utilSessao.getIdentificacao('classificacaoBloqueadoFinanceiro')?.conteudo;
    this.compacto = this.utilSessao.getIdentificacao('compacto')?.conteudo;
    this.financeiro = this.utilSessao.getIdentificacao('financeiro')?.conteudo;
    this.idLoja = this.utilSessao.getIdentificacao('idLoja')?.conteudo;
    this.idParceiro = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
    this.idParceiroDestino = this.utilSessao.getIdentificacao('idParceiroDestino')?.conteudo;
    this.movimentacaoProduto = this.plainToClass(MovimentacaoProduto, this.utilSessao.getIdentificacao('movimentacaoProduto', false)?.conteudo);
    this.movimentacaoProdutoIndice = this.utilSessao.getIdentificacao('movimentacaoProdutoIndice')?.conteudo;
    this.obrigatorio = this.utilSessao.getIdentificacao('obrigatorio')?.conteudo;
    this.parceiroDestino = this.utilSessao.getIdentificacao('parceiroDestino')?.conteudo;
    this.parceiroEndereco = this.utilSessao.getIdentificacao('parceiroEndereco')?.conteudo;
    this.saida = this.utilSessao.getIdentificacao('saida')?.conteudo;
    this.bloqueiaAlteracao = this.utilSessao.getIdentificacao('bloqueiaAlteracao')?.conteudo;
    this.devolucao = this.utilSessao.getIdentificacao('devolucao')?.conteudo;
    this.idOperacao = this.utilSessao.getIdentificacao('idOperacao')?.conteudo;
    this.naturezaOperacaoFinalidade = this.utilSessao.getIdentificacao('naturezaOperacaoFinalidade')?.conteudo;
    this.parceiroTipoPessoa = this.utilSessao.getIdentificacao('parceiroTipoPessoa')?.conteudo;
    this.parceiroInscricao = this.utilSessao.getIdentificacao('parceiroInscricao')?.conteudo;
    this.faturamento = this.utilSessao.getIdentificacao('faturamento')?.conteudo;
    this.nomeParceiro = this.utilSessao.getIdentificacao('nomeParceiro')?.conteudo;
    this.entradaServico = this.utilSessao.getIdentificacao('entradaServico')?.conteudo;
    this.alimentaCustoReposicao = this.utilSessao.getIdentificacao('alimentaCustoReposicao')?.conteudo;
    this.casasDecimais = this.utilSessao.getIdentificacao('casasDecimais')?.conteudo;
    this.movimentacao = this.utilSessao.getIdentificacao('movimentacao')?.conteudo;
    this.utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.casasDecimais);
    this.movimentacaoProduto.indice = this.movimentacaoProdutoIndice;
    const identificacao: number = this.utilSessao.getIdentificacao('identificacao')?.conteudo;
    const identificacaoDevolucao: number = 4;
    if (identificacao == identificacaoDevolucao && this.saida == false) {
      this.completo = false;
    }
    this.listarCfopEmpresa();
    this.listarCst();
    this.ehMostrarCodigoBeneficioFiscal();
  }

  listarCst(): void {
    const simplesNacional: string = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento == 1 ? 'S' : 'N';
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    if (this.faturamento) {
      criterios.push(new Criterio('SIMPLES_NACIONAL', simplesNacional));
    }
    if (this.movimentacaoProduto.idCstIcms) {
      criterios.push(new Criterio('ID_SELECIONADO', this.movimentacaoProduto.idCstIcms));
    }
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cstIcms).subscribe((res) => {
      this.icmsCsts = this.plainToClass(Cst, res) as any;
    });
    let criteriosImpostos: Criterio[] = [];

    if (this.devolucao) {
      criteriosImpostos.push(new Criterio('ENTRADA_SAIDA', this.saida ? 'E' : 'S'));
    } else {
      criteriosImpostos.push(new Criterio('ENTRADA_SAIDA', this.saida ? 'S' : 'E'));
    }

    criteriosImpostos.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criteriosImpostos), this.bibServico.cstPisCofins).subscribe((res) => {
      this.pisCofinsCsts = this.plainToClass(Cst, res) as any;
    });
    this.comunicacaoService.listar(new Transporte(criteriosImpostos), this.bibServico.cstIpi).subscribe((res) => {
      this.ipiCsts = this.plainToClass(Cst, res) as any;
    });
  }

  listarCfopEmpresa(): void {
    const cfopOperacao: string = this.verificarOperacaoCfop();
    if (cfopOperacao || this.movimentacaoProduto.idCfopEmpresa) {
      let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
      if (this.naturezaOperacaoFinalidade != 2) {
        if (this.devolucao || this.naturezaOperacaoFinalidade == 3) {
          criterios.push(new Criterio('ENTRADA_SAIDA', this.saida ? 'S' : 'E'));
          criterios.push(new Criterio('DEVOLUCAO', 'S'));
        } else {
          criterios.push(new Criterio('ENTRADA_SAIDA', this.saida ? 'S' : 'E'));
          criterios.push(new Criterio('DEVOLUCAO', 'N'));
        }
      } else {
        criterios.push(new Criterio('TRANSFERENCIA', 'S'));
        criterios.push(new Criterio('ENTRADA_SAIDA', this.saida ? 'S' : 'E'));
      }
      if (cfopOperacao && this.movimentacaoProduto.importacaoXml != 'S') {
        criterios.push(new Criterio('OPERACAO', cfopOperacao));
      }
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cfopEmpresa).subscribe((res) => {
        this.cfopsEmpresa = this.plainToClass(CfopEmpresa, res) as any;
      });
    }
  }

  verificarOperacaoCfop(): string {
    const loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
    if (this.parceiroDestino) {
      return this.parceiroDestino.idPais != loja.idPais ? 'I' : this.parceiroDestino.idEstado == loja.idEstado ? 'E' : 'F';
    } else if (this.parceiroEndereco) {
      return this.parceiroEndereco.idPais != loja.idPais ? 'I' : this.parceiroEndereco.idEstado == loja.idEstado ? 'E' : 'F';
    }
  }

  getCriterioIcms(): Criterio[] {
    const criterios: Criterio[] = [new Criterio('ID_PRODUTO', this.movimentacaoProduto.idProduto), new Criterio('TIPO', this.saida ? '-1' : '1'), new Criterio('ATIVO', 'S')];
    if (this.idParceiroDestino) {
      criterios.push(new Criterio('ID_PARCEIRO_DESTINO', this.idParceiroDestino));
    } else {
      criterios.push(new Criterio('ID_PARCEIRO', this.idParceiro));
    }
    const enquadramento: number = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento;
    criterios.push(new Criterio('ENQUADRAMENTO', enquadramento));
    criterios.push(new Criterio('ESTADO_LOJA', this.idLoja));
    return criterios;
  }

  zerarAliquotaIcms(cst: string = null): boolean {
    const simplesNacional: string = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento == 1 ? 'S' : 'N';
    const cstFormatado: string = cst ? cst.substring(1, cst.length) : null;
    if (simplesNacional == 'S') {
      return this.ehEnquadramentoSimplesNacionalIsento(cstFormatado);
    }
    return this.ehEnquadramentoIsento(cstFormatado);
  }

  ehEnquadramentoSimplesNacionalIsento(cst: string = null): boolean {
    if (cst && cst != '900') {
      this.movimentacaoProduto.setIcmsAliquota(0);
      return true;
    }
    return false;
  }

  ehEnquadramentoIsento(cst: string = null): boolean {
    if ((cst && ['30', '40', '41', '50', '60'].includes(cst)) && this.movimentacaoProduto.usaIcmsDesonerado == "N") {
      this.movimentacaoProduto.setIcmsAliquota(0);
      return true;
    }
    return false;
  }

  montarCriterioLojaEstado(): Criterio[] {
    const criterios: Criterio[] = [new Criterio('ID_LOJA', this.idLoja)];
    if (this.idParceiroDestino) {
      criterios.push(new Criterio('ID_PARCEIRO_DESTINO', this.idParceiroDestino));
    } else {
      criterios.push(new Criterio('ID_PARCEIRO', this.idParceiro));
    }
    return criterios;
  }

  setCstIcms(cstIcms: Cst): void {
    this.movimentacaoProduto.idCstIcms = cstIcms.id;
    this.zerarAliquotaIcms(cstIcms.cst);
  }

  setCfopEmpresa(cfopEmpresa: CfopEmpresa): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID', cfopEmpresa.idClassificacaoFiscal != null ? cfopEmpresa.idClassificacaoFiscal : 0));
    criterios.push(new Criterio('ATIVO', 'S'));
    let classificacaoFiscal: ClassificacaoFiscal = null;
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.classificacaoFiscal).subscribe((res) => {
      classificacaoFiscal = this.plainToClass(ClassificacaoFiscal, res)[0] as any;
      const icmsCst: Cst = null;
      if (classificacaoFiscal != null) {
        this.icmsCsts.find((icmsCst) => icmsCst.id == (this.utilMovimentacaoImpostos.verificarIsento() && classificacaoFiscal.idCstIcmsSemInscricao ? classificacaoFiscal.idCstIcmsSemInscricao : classificacaoFiscal.idCstIcms));
      }
      this.utilMovimentacaoImpostos.configurar(this.saida, this.parceiroDestino, this.parceiroEndereco, this.naturezaOperacaoFinalidade, this.devolucao, this.bloqueiaAlteracao, this.financeiro, this.idLoja, this.classificacaoBloqueadoEstoque, this.classificacaoBloqueadoEstoqueNfe, this.classificacaoBloqueadoFinanceiro, this.idOperacao, this.idParceiro, this.idParceiroDestino, this.parceiroTipoPessoa, this.parceiroInscricao, true, this.movimentacao);
      this.utilMovimentacaoImpostos.listaLojaEstado(this.movimentacaoProduto, icmsCst != null ? icmsCst.cst : null, this.naturezaOperacaoFinalidade).subscribe((res) => {
        const lojaEstado: LojaEstado = this.plainToClass(LojaEstado, res[0]) as any;
        this.utilMovimentacaoImpostos.listarImpostoDevolucao(this.movimentacaoProduto, this.naturezaOperacaoFinalidade).subscribe((res) => {
          let impostoDevolucoes: any[] = this.plainToClass(MovimentacaoProduto, res[0]) as any;
          if (cfopEmpresa && cfopEmpresa.idClassificacaoFiscal && this.naturezaOperacaoFinalidade != 3) {
            this.movimentacaoProduto.idCstIcms = this.utilMovimentacaoImpostos.verificarIsento() && classificacaoFiscal.idCstIcmsSemInscricao ? classificacaoFiscal.idCstIcmsSemInscricao : classificacaoFiscal.idCstIcms;
            this.movimentacaoProduto.icmsBasePercentual = classificacaoFiscal.base;
            this.movimentacaoProduto.codigoBeneficioFiscal = classificacaoFiscal?.codigoBeneficioFiscal;
          }
          this.utilMovimentacaoImpostos.setCfopEmpresa(this.movimentacaoProduto, cfopEmpresa, lojaEstado, impostoDevolucoes, this.naturezaOperacaoFinalidade);
        });
      });
    });
  }

  aceitarProdutoImposto(): void {
    if (this.ehValido()) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.movimentacaoProdutoImposto, this.movimentacaoProduto));
    }
  }

  listarImpostos(): void {
    let negociacao: Negociacao = this.utilSessao.getIdentificacao('negociacao')?.conteudo;
    let mediaDiaPrazo: number = this.utilSessao.getIdentificacao('mediaDiaPrazo')?.conteudo;
    let movimentacaoParcelas: MovimentacaoParcela[] = this.utilSessao.getIdentificacao('movimentacaoParcelas')?.conteudo;
    let valorTotal: number = this.utilSessao.getIdentificacao('valorTotal')?.conteudo;
    this.movimentacaoProduto.listandoImpostos = 'S';
    if (!this.movimentacaoProduto.idCstCofins || !this.movimentacaoProduto.idCstPis || !this.movimentacaoProduto.idCstIpi || !this.movimentacaoProduto.idCstIcms || !this.movimentacaoProduto.idCfopEmpresa) {
      this.utilMovimentacaoImpostos.listarImpostos(this.movimentacaoProduto, this.saida, this.parceiroDestino, this.parceiroEndereco, this.naturezaOperacaoFinalidade, this.devolucao, this.bloqueiaAlteracao, this.financeiro, this.idLoja, this.classificacaoBloqueadoEstoque, this.classificacaoBloqueadoEstoqueNfe, this.classificacaoBloqueadoFinanceiro, this.idOperacao, this.idParceiro, this.idParceiroDestino, this.parceiroTipoPessoa, this.parceiroInscricao, true, negociacao, mediaDiaPrazo, movimentacaoParcelas, valorTotal, null, this.entradaServico, this.movimentacao);
    } else {
      if (confirm(this.bibDialogo.listarNovamenteInformacoesFiscais)) {
        this.utilMovimentacaoImpostos.listarImpostos(this.movimentacaoProduto, this.saida, this.parceiroDestino, this.parceiroEndereco, this.naturezaOperacaoFinalidade, this.devolucao, this.bloqueiaAlteracao, this.financeiro, this.idLoja, this.classificacaoBloqueadoEstoque, this.classificacaoBloqueadoEstoqueNfe, this.classificacaoBloqueadoFinanceiro, this.idOperacao, this.idParceiro, this.idParceiroDestino, this.parceiroTipoPessoa, this.parceiroInscricao, true, negociacao, mediaDiaPrazo, movimentacaoParcelas, valorTotal, null, this.entradaServico, this.movimentacao);
      }
    }
  }

  ehMostrarCodigoBeneficioFiscal(): void {
    let listaDeEstadosPermitidos: string[] = ['DF', 'GO', 'PR', 'RJ', 'RS', 'SC', 'ES'];
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
    if (listaDeEstadosPermitidos.includes(loja.estado)) {
      this.mostraCodigoBeneficioFiscal = true;
    }
  }

  ehValido(): boolean {
    return this.ehNotaComplementoAjuste() || (this.ehValidoAliquotaPisCofins() && this.ehValidoCstReducao());
  }

  ehNotaComplementoAjuste(): boolean {
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    return this.naturezaOperacaoFinalidade == FINALIDADE_COMPLEMENTO || this.naturezaOperacaoFinalidade == FINALIDADE_AJUSTE;
  }

  ehValidoAliquotaPisCofins(): boolean {
    return this.validarAliquotaPis() && this.validarAliquotaCofins();
  }

  validarAliquotaPis(): boolean {
    if (this.movimentacaoProduto.getPisAliquota() != 0 && this.movimentacaoProduto.getPisAliquota() != 0.65 && this.movimentacaoProduto.getPisAliquota() != 1.65) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.aliquotaPisCofinsIncorreta, [this.bibDialogo.pis])));
      return false;
    }
    return true;
  }

  validarAliquotaCofins(): boolean {
    if (this.movimentacaoProduto.getCofinsAliquota() != 0 && this.movimentacaoProduto.getCofinsAliquota() != 3 && this.movimentacaoProduto.getCofinsAliquota() != 7.60) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.aliquotaPisCofinsIncorreta, [this.bibDialogo.cofins])));
      return false;
    }
    return true;
  }

  ehValidoCstReducao(): boolean {
    const cstIcms: Cst = this.icmsCsts.find((cst) => cst.id == this.movimentacaoProduto.idCstIcms);
    return this.validarCstReducaoBaseIntegral(cstIcms) && this.validarCstReducaoBaseZerada(cstIcms);
  }

  validarCstReducaoBaseIntegral(cstIcms: Cst): boolean {
    if (this.movimentacaoProduto.getIcmsBase() == this.movimentacaoProduto.valorTotal && cstIcms.reducaoIcms == 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.cstReducaoComBaseIntegral, [cstIcms.nome])));
      return false;
    }
    return true;
  }

  validarCstReducaoBaseZerada(cstIcms: Cst): boolean {
    if (this.movimentacaoProduto.getIcmsBase() == 0 && cstIcms.reducaoIcms == 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.cstReducaoComBaseZerada, [cstIcms.nome])));
      return false;
    }
    return true;
  }
}
