import { EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { toDate } from 'date-fns';
import { isDate } from 'util';
import bibPropriedade from '../biblioteca/bibPropriedade';
import { Empresa } from '../modelo/empresa';
import { Util } from '../utilitario/util';
import { UtilSessao } from '../utilitario/util.sessao';

export class Formulario {
  @ViewChildren('input') input: any;
  @Input() ajuda: string;
  @Input() ajudaDireita: boolean = false;
  @Input() compacto: boolean = false;
  @Input() desabilitado: boolean = false;
  @Input() descricao: string = '';
  @Input() descricaoAcima: string;
  @Input() exigidoCaracteres: number;
  @Input() filtroEmiteAlteracao: boolean = true;
  @Input() foco: boolean = false;
  @Input() icone: string;
  @Input() iconeDesabilitado: boolean = true;
  @Input() idMenu: number = 0;
  @Input() maximoCaracteres: number = 999;
  @Input() obrigatorio: boolean = false;
  @Input() prefixo: string;
  @Input() sufixo: string;
  @Input() vertical: boolean = true;
  @Output() alteracao = new EventEmitter<any>();
  @Output() btnAdicional = new EventEmitter<boolean>();
  @Output() navegacao = new EventEmitter<boolean>();
  @Input() fonteMenor: boolean = false;

  public atencaoMensagem: string = '';
  public bibPropriedade = bibPropriedade;
  public campoAtual: any;
  public focado: boolean = false;
  public preenchido: boolean = false;
  public tipoCampo: number = 0;
  public utilSessao: UtilSessao = new UtilSessao();
  public _rotulo: string;

  @Input()
  set rotulo(rotulo: string) {
    this._rotulo = rotulo;
    // this._id = this.formatarId(rotulo);
  }
  public _id: string = '';
  @Input()
  set id(id: string) {
    this._id = id;
  }
  private _atencao: string;
  @Input()
  set atencao(atencao: string) {
    this._atencao = atencao;
    this.ehAtencao();
  }

  @Input()
  set campo(campo: string) {
    if (typeof campo == 'string' && campo.indexOf('-') == -1) {
      if (this.campoAtual == null || (this.campoAtual && this.campoAtual != undefined && this.campoAtual.toUpperCase() != campo)) {
        this.iniciar(campo);
      }
    } else {
      this.iniciar(campo);
    }
  }

  @Input()
  set focoId(focoId: number) {
    if (focoId) {
      Promise.resolve(null).then(() => this.input.first.nativeElement.focus());
      Promise.resolve(null).then(() => this.input.first.nativeElement.select());
    }
  }

  constructor() {
    const empresa: Empresa = new UtilSessao().getEmpresa();
    this.compacto = empresa.compacto == 'S';
    this.utilSessao = new UtilSessao();
  }

  ngAfterViewInit(): void {
    this.focar();
  }

  iniciar(campo: string): void {
    this.campoAtual = campo;
    this.ehPreenchido();
    this.ehAtencao();
    this.focar();
  }

  focar(): void {
    if (this.foco) {
      if (this.input && this.input.first) {
        Promise.resolve(null).then(() => this.input.first.nativeElement.focus());
      }
    }
  }

  alterar(conteudoEspecifico: any = null): void {
    this.ehPreenchido();
    this.ehAtencao();
    this.emitirAlteracao(conteudoEspecifico ? conteudoEspecifico : this.campoAtual);
  }

  emitirAlteracao(valor: any, sugestaoAutomatica: boolean = false): void {
    if (this.filtroEmiteAlteracao && sugestaoAutomatica == false) {
      this.utilSessao.setAlteracao(true);
    }
    this.alteracao.emit(valor);
  }

  ehPreenchido(): void {
    if (this.campoAtual != null) {
      switch (this.tipoCampo) {
        case 2:
          this.ehPreenchidoData();
          break;
        case 7:
          this.ehPreenchidoDecimal();
          break;
        default:
          this.ehPreenchidoTexto();
          break;
      }
    } else {
      this.preenchido = false;
    }
  }

  ehPreenchidoData(): void {
    if (this.campoAtual && isDate(toDate(new Date(this.campoAtual).getTime()))) {
      this.preenchido = true;
    } else {
      this.preenchido = false;
    }
  }

  ehPreenchidoDecimal(): void {
    if (this.campoAtual != null) {
      let campoSemFormatacao: String = String(this.campoAtual);
      campoSemFormatacao = campoSemFormatacao.replace('.', '');
      campoSemFormatacao = campoSemFormatacao.replace(',', '.');
      if (Number(campoSemFormatacao) >= 0) {
        this.preenchido = true;
      } else {
        this.preenchido = false;
      }
    } else {
      this.preenchido = false;
    }
  }

  ehPreenchidoTexto(): void {
    if (String(this.campoAtual).length > 0 || Number(this.campoAtual) > 0) {
      if (this.exigidoCaracteres) {
        this.preenchido = Number(this.campoAtual.toString().length) >= this.exigidoCaracteres;
      } else {
        this.preenchido = true;
      }
    } else {
      this.preenchido = false;
    }
  }

  ehAtencao(): void {
    this.atencaoMensagem = '';
    if (this.exigidoCaracteres && this.campoAtual) {
      if (this.exigidoCaracteres != this.campoAtual.length) {
        const util: Util = new Util();
        this.atencaoMensagem = util.concatenar(this._atencao, `${this.exigidoCaracteres} caracteres`, ' | ');
      }
    }
    this.atencaoMensagem = this._atencao;
  }

  clicarBtnAdicional() {
    this.btnAdicional.emit(true);
  }

  navegar(focado: boolean) {
    this.focado = focado;
    this.navegacao.emit(focado);
  }

  formatarId(id: string): string {
    let idFormatado: string = '';
    if (id) {
      let primeiraLetraMaiusculo: boolean = id[0] == id[0].toUpperCase();
      const caracteresInvalidos: string[] = [' ', '-', '(', ')', '+', '-', '='];
      for (let i = 0; i < id.length; i++) {
        let letra: string = id[i];
        const letraAnterior: string = id[i - 1];
        letra = primeiraLetraMaiusculo ? letra.toLowerCase() : letra;
        if (letraAnterior && caracteresInvalidos.includes(letraAnterior) && letraAnterior != '-') {
          letra = letra.toUpperCase();
        }
        if (!caracteresInvalidos.includes(letra)) {
          idFormatado += letra;
        }
      }
      idFormatado = idFormatado.charAt(0).toLowerCase() + idFormatado.slice(1);
    }
    return idFormatado;
  }
}
