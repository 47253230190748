<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <ng-container *ngFor="let romaneioEntrega of apresentaRomaneiosEntrega; let i = index">
    <table [ngClass]="[ehMatricial ? 'matricial' : '', ehModoClaro ? '' : 'tabela-modo-escuro']">
      <tbody>
        <tr style="border-top: none;">
          <div class="row" [ngClass]="[ehMatricial ? 'matricial' : '', ehModoClaro ? '' : 'tabela-modo-escuro']">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <div class="row">
                <img [src]="foto ? foto : imagemEmpresa" [alt]="empresa.nome" height="80" width="80" />
              </div>
            </div>
            <div class="p-0 mt-3 ml-3">
              <h1 class="relatorio-rotulo">{{ empresa ? empresa.nome : '' }}</h1>
              <h2 class="relatorio-rotulo">{{ operacao + ' ' + (mostraVia == true && romaneioEntrega.via != null ? romaneioEntrega.via : '') }}</h2>
              <div *ngIf="romaneioEntrega.status != 'S'" class="tarja-nao-finalizado">{{ bibDialogo.naoFinalizado }}</div>
              <div class="row">
                <span class="relatorio-rotulo text-right"> {{ bibDialogo.numero + ':' }} </span> {{ romaneioEntrega.numero }}
              </div>
              <div class="row">
                <span class="relatorio-rotulo text-right">{{ bibDialogo.data + ':' }}</span>
                <span class="r-3"> {{ romaneioEntrega.data | data }} </span>
                <span class="relatorio-rotulo text-right">{{ bibDialogo.dataHoraImpressao + ':' }} </span>
                <span class="r-2"> {{ romaneioEntrega.dataAtual | data: 'dd/MM/yyyy HH:mm:ss' }} </span>
              </div>
            </div>
          </div>
        </tr>
        <tr>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.loja + ': ' }} </span>{{ romaneioEntrega.loja }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo text-uppercase r-2 text-right">{{ bibDialogo.email + ': ' }} </span> <span class="r-8 text-lowercase"> {{ romaneioEntrega.lojaEmail }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.telefone + ':' }} </span> <span>{{ romaneioEntrega.lojaTelefone }} </span>
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.endereco + ': ' }} </span>{{ romaneioEntrega.lojaEndereco }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cnpj + ': ' }} </span> <span class="r-3">{{ romaneioEntrega.lojaCnpj }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.inscricao + ':' }} </span> <span class="r-3">{{ romaneioEntrega.lojaInscricao }} </span>
          </div>
        </tr>
        <tr>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.transportadora + ':' }} </span> {{ romaneioEntrega.transportadora }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.endereco + ':' }} </span>{{ romaneioEntrega.transportadoraEnderecoResumo }}
          </div>
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.cpfCnpj + ':' }} </span> <span class="r-3"> {{ romaneioEntrega.transportadoraCnpjCpf }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.inscricao + ':' }} </span> <span class="r-4"> {{ romaneioEntrega.transportadoraInscricao }} </span><span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.telefone + ':' }} </span> <span class="r-2"> {{ romaneioEntrega.transportadoraTelefone }} </span>
          </div>
        </tr>
        <tr class="border-bottom border-dark" style="border-top: none;">
          <td>
            <div class="tabela-complementar">
              <thead>
                <tr>
                  <th class="r-1-5 text-right">{{ bibDialogo.numeroDocumento }}</th>
                  <th class="r-1 text-right">{{ bibDialogo.data }}</th>
                  <th class="r">{{ bibDialogo.parceiro }}</th>
                  <th class="r-1 text-right">{{ bibDialogo.valor }}</th>      
                  <th class="r-1 text-right">{{ bibDialogo.pesoBruto }}</th>     
                  <th class="r-1"></th>                             
                </tr>
              </thead>
              <tbody>
                  <ng-container *ngFor="let romaneioEntregaProdutoMov of romaneioEntrega.romaneioEntregaProdutosMov; let i = index">
                    <tr class="conteudo">
                      <td class="r-1-5 text-right">{{ romaneioEntregaProdutoMov.documentoFiscal }}</td>
                      <td class="r-1 text-right">{{ romaneioEntregaProdutoMov.dataMovimentacao | data }}</td>
                      <td class="r">{{ romaneioEntregaProdutoMov.parceiro }}</td>  
                      <td class="text-right">{{ romaneioEntregaProdutoMov.valorTotal | monetario }}</td>
                      <td class="text-right">{{ romaneioEntregaProdutoMov.pesoBruto | monetario }}</td>
                      <td class="naoImprimir"><btnAdicional [icone]="romaneioEntregaProdutoMov.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(romaneioEntregaProdutoMov)"></btnAdicional></td>                                                          
                    </tr>
                    <tr *ngIf="romaneioEntregaProdutoMov.expandido" r2026itens [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [romaneioEntregaProdutoMov]="romaneioEntregaProdutoMov"></tr>  
                  </ng-container>
              </tbody>
            </div>
          </td>
        </tr>      
        <tr>
        </tr>
        <tr *ngIf="usaObservacao && romaneioEntrega.observacao">
          <div class="row">
            <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.observacao + ':' }} </span>{{ romaneioEntrega.observacao }}
          </div>
        </tr>
        <tr>
          <div class="row justify-content-center">
            <div class="r-6 assinatura text-center">{{ romaneioEntrega.transportadora }}</div>
          </div>
        </tr>
      </tbody>
      <div *ngIf="i + 1 < romaneiosEntrega.length" class="separador naoImprimir" [ngClass]="ehModoClaro ? '' : 'separador-modo-escuro'"></div>
    </table>
  </ng-container>
</relatorioLayout>
