<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoGrupoEmt)="agrupar(movimentacaoProdutos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="clique r-0-5" (click)="ordenar(movimentacaoProdutos, 'lojaAbreviacao')">{{ bibDialogo.loja }}</th>
      <th class="clique r-1" (click)="ordenar(movimentacaoProdutos, 'data')">{{ bibDialogo.data }}</th>
      <th class="clique r-0-5 text-right" (click)="ordenar(movimentacaoProdutos, 'data')">{{ bibDialogo.numeroAbreviado }}</th>
      <th class="clique r-0-5" (click)="ordenar(movimentacaoProdutos, 'operacaoAbreviacao')">{{ bibDialogo.op }}<ajuda [ajuda]="bibDialogo.operacao"></ajuda></th>
      <th class="clique r" (click)="ordenar(movimentacaoProdutos, 'produto')">{{ bibDialogo.produto }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'quantidade')">{{ bibDialogo.quantidade }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'valorUnitario')">{{ bibDialogo.valorUnitario }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'custoGerencial')">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'valorTabela')">{{ bibDialogo.valorTabela }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'valorFinal')">{{ bibDialogo.valorFinal }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'lucroValor')">{{ bibDialogo.lucro }}</th>
      <th class="clique r-1 text-right" (click)="ordenar(movimentacaoProdutos, 'margem')">{{ bibDialogo.margem }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="movimentacaoProduto.expandido ? 'sem-borda' : ''">
          <td>{{ movimentacaoProduto.lojaAbreviacao }}</td>
          <td>{{ movimentacaoProduto.data | data: 'dd/MM/yy' }}</td>
          <td class="text-right"><a href="#" (click)="visualizarMovimentacao(movimentacaoProduto.id, movimentacaoProduto.idMovimentacao)">{{ movimentacaoProduto.numero }}</a></td>
          <td><atencao [atencao]="movimentacaoProduto.operacaoAbreviacao" [tipo]="movimentacaoProduto.operacaoAbreviacao == 'DV' ? bibPropriedade.atencao.roxo : bibPropriedade.atencao.discreto" [ajuda]="movimentacaoProduto.operacao"></atencao></td>
          <td>{{ movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto }}</td>
          <td class="text-right">{{ movimentacaoProduto.quantidade | decimal }}</td> 
          <td class="text-right">{{ movimentacaoProduto.valorUnitario | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.custoGerencial | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorTabela | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.lucroValor | monetario }}</td>
          <td class="text-right">{{ (movimentacaoProduto.margem | monetario) + '%' }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacaoProdutos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupoFinal"></tr>
    </tfoot>
  </table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>