import { Component } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoChave } from 'src/app/modelo/movimentacaoChave';
import { Resultado } from 'src/app/modelo/resultado';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'movimentacaoChaveFrm',
  templateUrl: './movimentacaoChaveFrm.component.html',
})
export class MovimentacaoChaveFrmComponent extends PaginaComponent {
  public movimentacaoChave: MovimentacaoChave = new MovimentacaoChave();
  public nfeModelos: any[] = [];

  ngOnInit(): void {
    this.nfeModelos = this.utilSessao.getIdentificacao('nfeModelos')?.conteudo;
    setTimeout(() => {
      if (this.utilSessao.getIdentificacao('movimentacaoChave')?.conteudo) {
        this.movimentacaoChave = this.utilSessao.getIdentificacao('movimentacaoChave')?.conteudo;
      }
    });
  }

  ehValidoTamanhoChave(): boolean {
    if (this.movimentacaoChave.chave.length < 44) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.chaveMinimoCaracteres));
      return false;        
    }
    return true; 
  }  

  private calcularDVChave(chaveSemDV: string): string {
    let peso: number = 2;
    let soma: number = 0;
    let contador: number;
    let digito: number;
    for (contador = chaveSemDV.length - 1; contador >= 0; contador--) {
        soma = soma + (parseInt(chaveSemDV[contador]) * peso);
        if (peso < 9) {
            peso++;
        } else {
            peso = 2;
        }
    }
    digito = 11 - (soma % 11);
    if (digito > 9) {
        digito = 0;
    }
    return digito.toString();
  }

  ehValidoDigitoVerificador(): boolean {
    const dvCalculado = this.calcularDVChave(this.movimentacaoChave.chave.substring(0, 43));
    const dvInformado = this.movimentacaoChave.chave.substring(43);
    if (dvCalculado != dvInformado) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueChaveComplementarDigitoVerificador));
      return false;  
    }
    return true;
  }   

  ehValidoNumero(): boolean {
    if (this.movimentacaoChave.chave.substring(25, 34) != this.movimentacaoChave.numeroFiscal.toString().padStart(9, '0')){
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueChaveComplementarModeloSerieNumero));
      return false;    
    }  
    return true;
  }   

  ehValidoSerie(): boolean {
    if (this.movimentacaoChave.chave.substring(22, 25) != this.movimentacaoChave.serie.padStart(3, '0')){
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueChaveComplementarModeloSerieNumero));
      return false;    
    }  
    return true;
  } 
  
  ehValidoModelo(chave: string): boolean { 
    if (this.movimentacaoChave.chave.substring(20, 22) != this.movimentacaoChave.modelo){
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueChaveComplementarModeloSerieNumero));
      return false;    
    }  
    return true;
  }  
  
  ehValidoObrigatorio(){
    if (!this.movimentacaoChave.modelo || !this.movimentacaoChave.serie || !this.movimentacaoChave.chave || !this.movimentacaoChave.numeroFiscal) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;        
    }
    return true; 
  }

  ehValidoChaveEspacosEmBranco(){
    if (this.movimentacaoChave.chave && this.movimentacaoChave.chave.includes(' ')) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.chaveNaoPodeConterEspacoMinimoCaracteres));
      return false;        
    }
    return true; 
  }  

  ehValido(): boolean {
    let ehValidoTamanhoChave: boolean = this.ehValidoTamanhoChave();
    let ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    let ehValidoChaveEspacosEmBranco: boolean = this.ehValidoChaveEspacosEmBranco();
    let ehValidoNumero: boolean = this.ehValidoNumero();
    let ehValidoSerie: boolean = this.ehValidoSerie();
    let ehValidoModelo: boolean = this.ehValidoModelo(this.movimentacaoChave.chave);
    let ehValidoDigitoVerificador: boolean = this.ehValidoDigitoVerificador();
    return ehValidoTamanhoChave && ehValidoObrigatorio && ehValidoChaveEspacosEmBranco && ehValidoNumero && ehValidoSerie && ehValidoModelo && ehValidoDigitoVerificador;    
  }

  adicionarReferencia(): void {
    if (this.ehValido()) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.movimentacaoChave, this.movimentacaoChave));
    }
  }

  ngOnDestroy() {
    this.utilSessao.setIdentificacao(new Identificacao('movimentacaoChave', new MovimentacaoChave()));
  }
}
