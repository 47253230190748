<div class="naoImprimir">
  <section>
    <div class="responsiva">
      <header [ngClass]="ehModoClaro ? '' : 'modo-escuro'">
        <div class="text-center">
          <ol class="progress-list mb-0 mb-sm-4">
            <li *ngFor="let item of progresso; let i = index" [ngClass]="{ success: etapa > i + 1, active: etapa == i + 1 }" (click)="alterarEtapa(i)">
              <button [disabled]="!clique" type="button" [ngClass]="{ clique: clique }" tabindex="-1">
                <span class="progress-indicator"></span>
              </button>
              <span class="progress-label d-none d-sm-inline-block clique">{{ item }}</span>
            </li>
          </ol>
        </div>
      </header>
    </div>
  </section>
</div>
