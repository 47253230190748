import { Caixa } from 'src/app/modelo/caixa';
import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Conta } from 'src/app/modelo/conta';
import parse from 'date-fns/parse';

@Component({
  templateUrl: './caixaFrm.component.html',
})
export class CaixaFrmComponent extends PaginaComponent {
  public caixa: Caixa = new Caixa();
  public contas: Conta[] = [];

  ngOnInit(): void {
    this.listarContas();
    this.ehAlteracao();
    this.utilSessao.setIdCaixa(this.caixa.id ? this.caixa.id : 0);
    this.utilSessao.setDataAberturaCaixa(this.caixa.dataHoraAbertura ? this.caixa.dataHoraAbertura : null);
    this.utilSessao.setIdConta(this.caixa.idConta ? this.caixa.idConta : null);
  }

  ehAlteracao(): void {
    this.caixa = this.plainToClass(Caixa, this.ehAtualizacao(this.caixa));
    if (this.caixa.id) {
      this.listarCaixa(this.caixa.id);
    }
  }

  listarContas(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('ID_USUARIO_CONTA', this.utilSessao.getUsuario().id));
    criterios.push(new Criterio('EXIGE_ABERTURA_CAIXA', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.conta).subscribe((res) => {
      this.contas = this.plainToClass(Conta, res) as any;
    });
  }

  listarCaixa(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.caixa).subscribe((res) => {
      this.caixa = this.plainToClass(Caixa, res[0]) as any;
    });
  }

  persistirCaixa(fechando: boolean = false): void {
    if(fechando){
      this.caixa.dataHoraFechamento = new Date();
    }else{      
      this.caixa.dataHoraAbertura = new Date();
    }
    super.persistir(new Transporte(this.caixa, 0, 0, false, null), this.bibServico.caixa, null);
    this.caixa.usuario = this.utilSessao.getUsuario().nome;
  }
}
