import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { R1001Component } from '../r1001/r1001.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r1131.component.html',
})
export class R1131Component extends R1001Component {
  public filtros = filtros;
}
