export class UtilCriptografia {
  private ids: number[] = [124, 72, 115, 42, 77, 43, 45, 64, 70, 67, 75, 68, 105, 78, 33, 46, 121, 85, 35, 114, 118, 59, 98, 94, 36, 110, 47, 122, 100, 101, 120, 102, 91, 73, 104, 119, 65, 99, 81, 107, 87, 108, 84, 69, 76, 83, 71, 112, 97, 88, 38, 79, 58, 41, 90, 106, 86, 93, 63, 80, 40, 66, 103, 126, 82, 109, 117, 113, 74, 61, 89, 111, 37, 116];
  private tokens: String = '|Hs*M+-@FC\KDiN!.yU#rv;b^$n/zdexf[IhwAcQkWlTELSGpaX&O:)ZjV]?P(Bg~RmuqJ=Yo%t';
  private caracteres: String = 'abcdefghijklmnopqrstuvxzywABCDEFGHIJKLMNOPQRSTUVXZYW?!@#$%&*()-+^~[].:;/\=|';
  private novaDinamica: boolean = true;

  criptografar(dado: any): string {
    if (this.novaDinamica == true) {
      return this.criptografarNovo(dado);
    } else {
      return this.criptografarAntigo(dado);
    }
  }

  criptografarAntigo(dado: any): string {
    const contemNumeros: any = /[0-9]/;
    if (dado) {
      dado = dado.toString();
      dado = this.embaralharNumero(dado);
      let mensagem: string = '';
      let l: number;
      let i: number;
      let j: number = 0;
      let chave: string;
      //chave deve conter 51 caracteres
      chave = 'assbdFbdpdPdpfPdAAdpeoseslsQQEcDDldiVVkadiedkdkLLnm';
      chave = this.embaralharChave(chave);
      for (i = 0; i < dado.length; i++) {
        j++;
        if (contemNumeros.test(dado[i])) {
          mensagem += this.criptografarNumero(Number(dado[i]));
        } else {
          l = this.retornarCodigoAsc(dado.substr(i, 1)) + this.retornarCodigoAsc(chave.substr(j, 1));
          if (j == 50) {
            j = 1;
          }
          if (l > 255) {
            l -= 256;
          }
          mensagem += this.retornarLetraDeCodigoAsc(l);
        }
      }
      return mensagem;
    }
  }

  criptografarNovo(dado: any): string {
    if (dado) {
      dado = dado.toString();
      let mensagem: string = '';
      dado = this.embaralharChaveNovo(dado);
      for (let i = 0; i < dado.length; i++) {
        const contemNumeros: any = /[0-9]/;
        if (contemNumeros.test(dado[i])) {
          mensagem += this.criptografarNumero(Number(dado[i]));
        } else {
          const l: number = this.retornarCodigoAsc(dado.substr(i, 1));
          const letra = this.retornarLetraDeCodigoAscNovo(l);
          mensagem += letra ? letra : dado[i];
        }
      }
      return mensagem;
    }
  }

  descriptografar(dado: any): string {
    if (this.novaDinamica == true) {
      return this.descriptografarNovo(dado);
    } else {
      return this.descriptografarAntigo(dado);
    }
  }

  descriptografarAntigo(dado: any): string {
    const contemNumeros: any = /[0-9]/;
    if (dado) {
      dado = this.embaralharNumero(dado);
      let mensagem: string = '';
      let l: number;
      let i: number;
      let j: number = 0;
      let chave: string;
      //chave deve conter 51 caracteres
      chave = 'assbdFbdpdPdpfPdAAdpeoseslsQQEcDDldiVVkadiedkdkLLnm';
      chave = this.embaralharChave(chave);
      for (i = 0; i < dado.length; i++) {
        j++;
        if (contemNumeros.test(dado[i])) {
          mensagem += this.descriptografarNumero(Number(dado[i]));
        } else {
          l = this.retornarCodigoAsc(dado.substr(i, 1)) - this.retornarCodigoAsc(chave.substr(j, 1));
          if (j == 50) {
            j = 1;
          }
          if (l < 0) {
            l += 256;
          }
          mensagem += this.retornarLetraDeCodigoAsc(l);
        }
      }
      return mensagem;
    }
  }

  descriptografarNovo(dado: any): string {
    if (dado) {
      let mensagem: string = '';
      dado = this.desembaralharChaveNovo(dado);
      for (let i = 0; i < dado.length; i++) {
        const contemNumeros: any = /[0-9]/;
        if (contemNumeros.test(dado[i])) {
          mensagem += this.descriptografarNumero(Number(dado[i]));
        } else {        
          const l: number = this.retornarCodigoAscNovo(dado.substr(i, 1));
          if (l) {
            mensagem += this.retornarLetraDeCodigoAscNovoInverso(l);
          } else {
            mensagem += dado[i];
          }
        }
      }
      return mensagem;
    }
  }

  retornarCodigoAsc(letra: string): number {
    return letra.charCodeAt(0);
  }

  retornarCodigoAscNovo(letra: string): number {
    for (let i = 0; i < this.caracteres.length; i++) {
      if (letra == this.caracteres[i]) {
        return this.ids[i];
      }
    }
  }

  retornarLetraDeCodigoAsc(codigo: number): string {
    return String.fromCharCode(codigo);
  }

  retornarLetraDeCodigoAscNovo(codigo: number): string {
    for (let i = 0; i < this.ids.length; i++) {
      if (codigo == this.ids[i]) {
        return this.caracteres[i];
      }
    }
    return null;    
  }

  retornarLetraDeCodigoAscNovoInverso(codigo: number): string {
    for (let i = 0; i < this.ids.length; i++) {
      if (codigo == this.ids[i]) {
        return this.tokens[i];
      }
    }
    return null;    
  }

  embaralharChave(chave: string): string {
    let primeiraParte: string = chave.substring(0, 25);
    primeiraParte = primeiraParte.split('').reverse().join('');
    let segundaParte: string = chave.substring(25, chave.length);
    segundaParte = segundaParte.split('').reverse().join('');
    return segundaParte + primeiraParte;
  }

  embaralharChaveNovo(chave: string): string {  
    if (chave && chave != "" && chave != undefined) {
      let inicio: String = this.caracteres[chave.length];
      inicio += this.tokens[chave.length];
      let primeiraParte: string = chave.substring(0, Math.floor(chave.length / 2));
      primeiraParte = primeiraParte.split('').reverse().join('');
      let meio: String = this.caracteres[Math.floor(chave.length/2)];
      meio += this.tokens[Math.floor(chave.length/2)];
      let segundaParte: string = chave.substring(chave.length / 2, chave.length);
      segundaParte = segundaParte.split('').reverse().join('');
      let fim: String = this.caracteres[chave.length-1];
      fim += this.tokens[chave.length-1];
      return inicio + segundaParte + meio + primeiraParte + fim;
    } else {
      return "";
    }
  }
  
  desembaralharChaveNovo(chave: string): string {   
    if (chave && chave != "" && chave != undefined && chave.length > 0) {
      chave = chave.substring(2, chave.length - 2).trim();
      let meio: number = Math.floor(chave.length / 2);     
      const diferenca = meio == (chave.length / 2) ?  3 : 0;
      const incremento = meio == (chave.length / 2) ?  2 : 0;
      const diferencaSegunda = meio == (chave.length / 2) ?  1 : 2;
      chave = chave.substring(0, meio - diferenca + incremento) + chave.substring(meio + diferencaSegunda, chave.length);
      chave = chave.trim();

      let primeiraParte: string = chave.substring(0, chave.length / 2);
      primeiraParte = primeiraParte.split('').reverse().join('');
      let segundaParte: string = chave.substring(chave.length / 2, chave.length);
      segundaParte = segundaParte.split('').reverse().join('');
      return segundaParte + primeiraParte;
    } else {
      return "";
    }
  }


  criptografarNumero(numero: number): string {
    switch (numero) {
      case 0:
        return '2';
      case 1:
        return '0';
      case 2:
        return '5';
      case 3:
        return '3';
      case 4:
        return '7';
      case 5:
        return '1';
      case 6:
        return '9';
      case 7:
        return '8';
      case 8:
        return '4';
      case 9:
        return '6';
    }
  }

  descriptografarNumero(numero: number): string {
    switch (numero) {
      case 0:
        return '1';
      case 1:
        return '5';
      case 2:
        return '0';
      case 3:
        return '3';
      case 4:
        return '8';
      case 5:
        return '2';
      case 6:
        return '9';
      case 7:
        return '4';
      case 8:
        return '7';
      case 9:
        return '6';
    }
  }

  embaralharNumero(dado: string): string {
    const contemNumeros: any = /[0-9]/;
    let numeros: string[] = [];
    let posicoes: number[] = [];
    for (let i = 0; i < dado.length; i++) {
      if (contemNumeros.test(dado[i])) {
        numeros.push(dado[i]);
        posicoes.push(i);
      }
    }
    numeros = this.inverterNumero(numeros);
    dado = this.substituirNumero(dado, numeros, posicoes);
    return dado;
  }

  inverterNumero(numeros: string[]): string[] {
    if (numeros.length >= 3) {
      let meio: number = Math.trunc(numeros.length / 2);
      let menorMeioInvertido: string[] = numeros.slice(0, meio).reverse();
      let maiorMeioInvertido: string[] = numeros.slice(meio, numeros.length + 1).reverse();
      return menorMeioInvertido.concat(maiorMeioInvertido);
    }
    return numeros;
  }

  substituirNumero(dado: string, numeros: string[], posicoes: number[]): string {
    for (let i = 0; i < posicoes.length; i++) {
      dado = dado.substring(0, posicoes[i]) + numeros[i] + dado.substring(posicoes[i] + 1, dado.length + 1);
    }
    return dado;
  }

  validarCaracteres(senha: string): boolean {
    for (let i = 0; i < senha.length; i++) {
      if (this.caracteres.indexOf(senha[i]) < 0) {
        return false;
      }
    }
    return true;
  }
}
