<ng-container>
  <td class="p-0" colSpan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-3">{{ bibDialogo.icmsSt + ' - ' + bibDialogo.uf }}</th>
          <th class="r-3 text-right">{{ bibDialogo.icmsSt + ' - ' + bibDialogo.aliquota }}</th>
          <th class="r-3 text-right">{{ bibDialogo.icmsSt + ' - ' + bibDialogo.mva }}</th>
          <th class="r"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let produtoIcmsSt of produtoIcmsSts">
          <tr>
            <td>{{ produtoIcmsSt.uf }}</td>
            <td class="text-right">{{ produtoIcmsSt.aliquota }}</td>
            <td class="text-right">{{ produtoIcmsSt.mva }}</td>
            <td></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
