export class MenuEmpresa {
  public alterado: boolean = false;
  public apelido: string;
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricao: string;
  public empresa: string;
  public expandido: boolean = false;
  public icone: string;
  public id: number;
  public idEmpresa: number;
  public idMenu: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public insercao: string;
  public menu: string;
  public menuEmpresa: boolean = true;
  public modularizado: string;
  public movimentacao: string;
  public novo: string;
  public parametro: string;
  public tag: string;
}
