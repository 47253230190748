<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" (campoOrdemEmt)="ordenar(produtos, $event)" (filtroEmt)="listarRelatorio($event)" (campoGrupoEmt)="agrupar(produtos, $event)">
  <table> 
    <thead>
      <th class="r-0-5"></th>
      <th class="r clique" (click)="ordenar(produtos, 'nome')">{{ bibDialogo.produto }}</th>     
      <th class="r-1 clique" (click)="ordenar(produtos, 'abreviacao')">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-0-5 text-center clique" (click)="ordenar(produtos, 'cstDentro')">{{ bibDialogo.cst }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'custoLiquido')">{{ bibNomenclatura.escrever(bibNomenclatura.custoReposicao) }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'custoBruto')">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'indice')">{{ bibDialogo.indice }}</th>      
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'valorTabela')">{{ bibDialogo.valorTabela }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'disponivelLoja')">{{ bibDialogo.disponivelLoja }}</th>
      <th class="r-1 text-right clique" (click)="ordenar(produtos, 'disponivelTotal')">{{ bibDialogo.disponivelTotal }}</th>
      <th class="r-1 text-right clique"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">    
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>            
        <tr class="conteudo" [ngClass]="produto.mostraFoto ? 'sem-borda' : ''">  
          <td >
            <svg width="16" height="16" class="bi bi-camera clique" *ngIf="produto.foto == 'S'" (click)="mostrarFoto(produto)">
              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z"/>
              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
            </svg>
          </td>         
          <td class="limitar"><a href="#" (click)="irProduto(produto.id)">{{ produto.numero + ' - ' + produto.nome }}</a></td>
          <td>{{ produto.abreviacao }}</td>        
          <td class="text-center">{{ produto.cstDentro }}</td>
          <td class="text-right"><a href="#" (click)="irProdutoValor(produto.id, produto.idLoja)">{{ produto.custoLiquido | monetario }}</a></td>
          <td class="text-right"><a href="#" (click)="irProdutoValor(produto.id, produto.idLoja)">{{ produto.custoBruto | monetario }}</a></td>
          <td class="text-right">{{ (produto.indice | monetario) + '%' }}</td>          
          <td class="text-right"><a href="#" (click)="irProdutoValor(produto.id, produto.idLoja)">{{ produto.valorTabela | monetario }}</a></td>
          <td class="text-right">{{ produto.disponivelLoja | monetario }}</td>
          <td class="text-right">{{ produto.disponivelTotal | monetario }}</td>
          <td class="text-center">
            <atencao *ngIf="produto.pendenteTotal > 0" [atencao]="'P'" [ajuda]="bibDialogo.pendente + ' ' + produto.abreviacaoLoja + ': ' + (produto.pendenteLoja | monetario) + ' | ' + bibDialogo.pendente + ' ' + bibDialogo.total + ': ' + (produto.pendenteTotal | monetario)" [tipo]="bibPropriedade.atencao.alerta"></atencao>
            <atencao *ngIf="produto.compradoTotal > 0" [atencao]="'C'" [ajuda]="bibDialogo.comprado + ' ' + produto.abreviacaoLoja + ': ' + (produto.compradoLoja | monetario) + ' | ' + bibDialogo.comprado + ' ' + bibDialogo.total + ': ' + (produto.compradoTotal | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
          </td>
        </tr>        
        <tr *ngIf="produto.mostraFoto">
          <td colspan="12"><carrossel *ngIf="produto.produtoFotos" [pasta]="'produto'" [idPai]="produto.id" [fotos]="produto.produtoFotos" [somenteVisualizacao]="true" (imagem)="abrirModalImagem($event)"></carrossel></td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody> 
    <tfoot>
     <tr *ngIf="produtos?.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
