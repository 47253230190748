import { Component } from '@angular/core';
import { ConfiguracaoFinanceiroContabil } from 'src/app/modelo/configuracaoFinanceiroContabil';
import { ConfiguracaoFinanceiroTotalContabil } from 'src/app/modelo/configuracaoFinanceiroTotalContabil';
import { ConfiguracaoMovimentacaoContabil } from 'src/app/modelo/configuracaoMovimentacaoContabil';
import { ConfiguracaoMovimentacaoTotalContabil } from 'src/app/modelo/configuracaoMovimentacaoTotalContabil';
import { ConfiguracaoTransferenciaContabil } from 'src/app/modelo/configuracaoTransferenciaContabil';
import { ConfiguracaoTransferenciaTotalContabil } from 'src/app/modelo/configuracaoTransferenciaTotalContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ConfiguracaoContabilTotalFrmComponent } from '../configuracaoContabilTotalFrm/configuracaoContabilTotalFrm.component';

@Component({
  templateUrl: './configuracaoContabilFrm.component.html',
})
export class ConfiguracaoContabilFrmComponent extends PaginaComponent {
  public classe: any;
  public classeTotal: any;
  public componente: any;
  public idObjetoTotaisExcluir: number[] = [];
  public objeto: any;
  public objetoTotais: any[] = [];
  public servico: string;

  public receitaDespesa: any[] = [
    { id: 'R', nome: this.bibDialogo.receita },
    { id: 'D', nome: this.bibDialogo.despesa },
  ];

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.configuracaoFinanceiroTotalContabil || identificacao.nome == this.bibClasse.configuracaoMovimentacaoTotalContabil || identificacao.nome == this.bibClasse.configuracaoTransferenciaTotalContabil) {
        let objetos = this.plainToClass(this.classeTotal, identificacao.conteudo);
        objetos.forEach((objeto) => {
          this.objetoTotais.push(objeto);
        });
      }
    });
    switch (this.permissao.servico) {
      case this.bibServico.configuracaoMovimentacaoContabil:
        this.classe = ConfiguracaoMovimentacaoContabil;
        this.classeTotal = ConfiguracaoMovimentacaoTotalContabil;
        this.objeto = new ConfiguracaoMovimentacaoContabil();
        this.servico = this.bibServico.configuracaoMovimentacaoContabil;
        break;
      case this.bibServico.configuracaoFinanceiroContabil:
        this.classe = ConfiguracaoFinanceiroContabil;
        this.classeTotal = ConfiguracaoFinanceiroTotalContabil;
        this.objeto = new ConfiguracaoFinanceiroContabil();
        this.servico = this.bibServico.configuracaoFinanceiroContabil;
        break;
      case this.bibServico.configuracaoTransferenciaContabil:
        this.classe = ConfiguracaoTransferenciaContabil;
        this.classeTotal = ConfiguracaoTransferenciaTotalContabil;
        this.objeto = new ConfiguracaoTransferenciaContabil();
        this.servico = this.bibServico.configuracaoTransferenciaContabil;
        break;
    }
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.objeto = this.plainToClass(this.classe, this.ehAtualizacao(this.objeto));
    if (this.objeto.id) {
      this.listarObjeto(this.objeto.id);
    }
  }

  listarObjeto(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.servico).subscribe((res) => {
      this.objeto = this.plainToClass(this.classe, res[0]) as any;
      this.listarObjetoTotais();
    });
  }

  listarObjetoTotais(): void {
    let servico: string = this.servico == this.bibServico.configuracaoMovimentacaoContabil ? this.bibServico.configuracaoMovimentacaoTotalContabil : this.servico == this.bibServico.configuracaoFinanceiroContabil ? this.bibServico.configuracaoFinanceiroTotalContabil : this.bibServico.configuracaoTransferenciaTotalContabil;
    let criterios: Criterio[] = [];
    if (servico == this.bibServico.configuracaoMovimentacaoTotalContabil) {
      criterios.push(new Criterio('ID_CONF_MOVIMENTACAO_CONTABIL', this.objeto.id));
    } else if (servico == this.bibServico.configuracaoTransferenciaTotalContabil) {
      criterios.push(new Criterio('ID_CONF_TRANSFERENCIA_CONTABIL', this.objeto.id));
    } else if (servico == this.bibServico.configuracaoFinanceiroTotalContabil) {
      criterios.push(new Criterio('ID_CONF_FINANCEIRO_CONTABIL', this.objeto.id));
    }
    this.comunicacaoService.listar(new Transporte(criterios), servico).subscribe((res) => {
      let classe: any = this.servico == this.bibServico.configuracaoMovimentacaoContabil ? ConfiguracaoMovimentacaoTotalContabil : this.servico == this.bibServico.configuracaoFinanceiroContabil ? ConfiguracaoFinanceiroTotalContabil : ConfiguracaoTransferenciaTotalContabil;
      this.objetoTotais = this.plainToClass(classe, res) as any;
    });
  }

  abrirModal(objeto: any): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', objeto));
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.permissao.servico));
    this.componente = ConfiguracaoContabilTotalFrmComponent;
  }

  persistirObjeto(): void {
    if (this.ehValido()) {
      this.objeto.setObjetoTotais(this.objetoTotais);
      let transporte = new Transporte(this.objeto);
      transporte.adicionar(this.idObjetoTotaisExcluir);
      this.persistir(transporte, this.servico, null).subscribe(() => {
        this.definirId([this.objeto], this.servico == this.bibServico.configuracaoMovimentacaoContabil ? this.bibClasse.configuracaoMovimentacaoContabil : this.bibClasse.configuracaoFinanceiroContabil, true);
        this.definirId(this.objeto.objetoTotais, this.servico == this.bibServico.configuracaoMovimentacaoContabil ? this.bibClasse.configuracaoMovimentacaoTotalContabil : this.servico == this.bibServico.configuracaoFinanceiroContabil ? this.bibClasse.configuracaoFinanceiroTotalContabil : this.bibClasse.configuracaoTransferenciaTotalContabil);
        this.idObjetoTotaisExcluir = [];
      });
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoAoMenosUmaConfiguracao: boolean = this.setEhValidoAoMenosUmaConfiguracao();
    return ehValidoObrigatorio && ehValidoAoMenosUmaConfiguracao;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.objeto.nome && (this.servico == this.bibServico.configuracaoFinanceiroContabil ? this.objeto.tipo != null : true)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoAoMenosUmaConfiguracao(): boolean {
    if (this.objetoTotais.length == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.deveConterPeloMenosUmaConfiguracao, [this.permissao.menu])));
      return false;
    }
    return true;
  }

  excluirObjetoTotal(indice: number): void {
    if (confirm(this.bibDialogo.certezaExcluirConfiguracao)) {
      if (this.objetoTotais[indice].id) {
        this.idObjetoTotaisExcluir.push(this.objetoTotais[indice].id);
      }
      this.objetoTotais.splice(indice, 1);
    }
  }
}
