import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const filtros = {
  conciliacao: plainToClass(Filtro, [
    { id: 'numeroFinanceiro', rotulo: bibDialogo.nFinanceiro, nome: 'NUMERO_FINANCEIRO', coluna: '6', tipo: bibPropriedade.filtro.numero },
    { id: 'numeroDocumento', rotulo: bibDialogo.numeroDocumento, nome: 'NUMERO_DOCUMENTO', coluna: '6', tipo: bibPropriedade.filtro.numero },
    { id: 'dataLancamentoDe', rotulo: bibDialogo.dataLancamentoDe, nome: 'DATA_LANCAMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataLancamentoAte', rotulo: bibDialogo.dataLancamentoAte, nome: 'DATA_LANCAMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataPrevistaDe', rotulo: bibDialogo.dataPrevistaDe, nome: 'DATA_PREVISTA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataPrevistaAte', rotulo: bibDialogo.dataPrevistaAte, nome: 'DATA_PREVISTA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'valorDe', rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'valorAte', rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { id: 'parceiro', rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'conta', rotulo: bibDialogo.conta, nome: 'ID_CONTA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
    { id: 'tipoTitulo', rotulo: bibDialogo.tipoTitulo, nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  ]),
  conciliacaoEstorno: plainToClass(Filtro, [
    { id: 'numeroFinanceiro', rotulo: bibDialogo.nFinanceiro, nome: 'NUMERO_FINANCEIRO', coluna: '6', tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.numeroDocumento, nome: 'NUMERO_DOCUMENTO', coluna: '6', tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataLancamentoDe, nome: 'DATA_LANCAMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataLancamentoAte, nome: 'DATA_LANCAMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataPrevistaDe, nome: 'DATA_PREVISTA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataPrevistaAte, nome: 'DATA_PREVISTA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataConciliacaoDe, nome: 'DATA_CONCILIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.dataConciliacaoAte, nome: 'DATA_CONCILIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
    { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.conta, nome: 'ID_CONTA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
    { rotulo: bibDialogo.tipoTitulo, nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  ]),
  controleCartao: plainToClass(Filtro, [
    { id: 'numeroFinanceiro', rotulo: bibDialogo.nFinanceiro, nome: 'NUMERO_FINANCEIRO', coluna: '12', tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_PREVISTA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.contaBancaria, nome: 'ID_CONTA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S'), new Criterio('CONTA_BANCARIA', 'S')] },
    { rotulo: bibDialogo.tipoTitulo, nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  ]),
};
export default filtros;
