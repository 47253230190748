import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { NfeXmlPdf } from 'src/app/modelo/nfeXmlPdf';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtros';
import { UtilNfeXmlPdf } from './utilNfeXmlPdf';

@Component({
  templateUrl: './nfeXmlPdf.component.html',
})
export class NfeXmlPdfComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public nfexmlpdfs: NfeXmlPdf[] = [];
  public dataAtualizacao: Date;
  private intervaloAtualizar: any;
  public criterios: Criterio[] = [];
  public posicao: number = 0;
  public quantidade: number = 0;
  public utilNfeXmlPdf: UtilNfeXmlPdf = new UtilNfeXmlPdf(this.utilSessao, this.http);

  ngOnDestroy(): void {
    clearInterval(this.intervaloAtualizar);
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.criterios = criterios;
    this.posicao = posicao;
    this.quantidade = quantidade;
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.nfeXmlPdf).subscribe((res) => {
      this.nfexmlpdfs = this.plainToClass(NfeXmlPdf, res) as any;
      this.iniciarAtualizacaoAutomatica();
    });
  }

  iniciarAtualizacaoAutomatica() {
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', function () {
      abaAtiva = abaAtiva == true ? false : true;
    });
    this.dataAtualizacao = new Date();
    clearInterval(this.intervaloAtualizar);
    this.intervaloAtualizar = setInterval(() => {
      if (abaAtiva) {
        this.listar(this.criterios, this.posicao, this.quantidade);
      }
      this.dataAtualizacao = new Date();
    }, 120000);
  }

  atualizarNfeXmlPdf(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.nfeXmlPdf).subscribe((res) => {
      this.nfexmlpdfs = this.plainToClass(NfeXmlPdf, res) as any;
      this.dataAtualizacao = new Date();
    });
  }

  excluirNfeXmlPdf(id: number, nome: string): void {
    super.excluir<NfeXmlPdf>(id, this.nfexmlpdfs, nome);
  }
}
