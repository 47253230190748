import { plainToClass } from 'class-transformer';
import { addDays, addMonths, addWeeks, differenceInDays, isLastDayOfMonth, lastDayOfMonth, lastDayOfWeek, setDate } from 'date-fns';
import { Local } from 'src/app/modelo/local';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { Lote } from 'src/app/agrow/modelo/lote';
import { ProdutoUnidade } from 'src/app/agrow/modelo/produtoUnidade';
import { Receituario } from 'src/app/agrow/modelo/receituario';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { CentroResultado } from 'src/app/modelo/centroResultado';
import { Safra } from 'src/app/modelo/safra';
import { UnidadeParceiro } from 'src/app/modelo/unidadeParceiro';
import { UtilMovimentacaoImpostos } from 'src/app/utilitario/utilMovimentacaoImpostos';
import { BibNomenclatura } from '../../../biblioteca/bibNomenclatura';
import bibParametro from '../../../biblioteca/bibParametro';
import bibPropriedade from '../../../biblioteca/bibPropriedade';
import bibServico from '../../../biblioteca/bibServico';
import { CfopEmpresa } from '../../../modelo/cfopEmpresa';
import { Classificacao } from '../../../modelo/classificacao';
import { ClienteEspecial } from '../../../modelo/clienteEspecial';
import { Criterio } from '../../../modelo/criterio';
import { Empresa } from '../../../modelo/empresa';
import { EmpresaFilial } from '../../../modelo/empresaFilial';
import { EmpresaOperacaoBloqueio } from '../../../modelo/empresaOperacaoBloqueio';
import { Loja } from '../../../modelo/loja';
import { MenuEmpresa } from '../../../modelo/menuEmpresa';
import { Movimentacao } from '../../../modelo/movimentacao';
import { MovimentacaoParcela } from '../../../modelo/movimentacaoParcela';
import { MovimentacaoProduto } from '../../../modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from '../../../modelo/movimentacaoQuantidade';
import { NaturezaOperacao } from '../../../modelo/naturezaOperacao';
import { Negociacao } from '../../../modelo/negociacao';
import { OperacaoParametro } from '../../../modelo/operacaoParametro';
import { ParceiroDestino } from '../../../modelo/parceiroDestino';
import { ParceiroEndereco } from '../../../modelo/parceiroEndereco';
import { Permissao } from '../../../modelo/permissao';
import { Preferencia } from '../../../modelo/preferencia';
import { Produto } from '../../../modelo/produto';
import { ProdutoParceiro } from '../../../modelo/produtoParceiro';
import { Relacionamento } from '../../../modelo/relacionamento';
import { Resultado } from '../../../modelo/resultado';
import { TipoTitulo } from '../../../modelo/tipoTitulo';
import { Transporte } from '../../../modelo/transporte';
import { Usuario } from '../../../modelo/usuario';
import { Utilitario } from '../../../modelo/utilitario';
import { ComunicacaoService } from '../../../servico/comunicacao.service';
import { Util } from '../../../utilitario/util';
import { UtilSessao } from '../../../utilitario/util.sessao';

export class MovimentacaoControlador {
  public acrescimo: string;
  public acrescimoDiaFixo: number = 0;
  public adicionaVinculo: string;
  public adicionaVinculoXml: string;
  public alimentaCustoReposicao: boolean = false;
  public balanco: boolean = false;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public bibParametro: any = bibParametro;
  public bibPropriedade = bibPropriedade;
  public bibServico = bibServico;
  public bloqueado: boolean = false;
  public bloqueiaAlteracao: boolean = true;
  public cfopEmpresa: CfopEmpresa[] = [];
  public cfopsEmpresa: CfopEmpresa[] = [];
  public classificacoes: Classificacao[] = [];
  public clienteEspecial: ClienteEspecial;
  public comissao: boolean = false;
  public compacto: boolean = false;
  public custoReposicao: boolean = false;
  public desconto: string;
  public devolucao: boolean = false;
  public diaFixo: number;
  public diaFixoBloqueado: boolean = false;
  public diasEntre: number;
  public diasEntreBloqueado: boolean = false;
  public diferencaParcelaTotal: number = 0;
  public ehClone: boolean = false;
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public empresaMovimentacaoEstilo: number;
  public entrada: boolean = false;
  public estadoParceiro: string;
  public estoqueComprado: number = 0;
  public estoqueFisico: number = 0;
  public estoqueNFe: number = 0;
  public estoqueReservado: number = 0;
  public estoqueCarteira: number = 0;
  public estoqueTransferido: number = 0;
  public excluirMovimentacaoLancamentoContabeis: number[] = [];
  public excluirParcelas: number[] = [];
  public excluirProdutos: number[] = [];
  public excluirQuantidades: number[] = [];
  public exigirSenha: boolean = false;
  public financeiro: string = 'N';
  public focoIdAdicionar: number;
  public focoIdObservacao: number;
  public focoParceiro: number;
  public icmsCsts: any[] = [];
  public idConsumidorFinal: number;
  public idEstadoParceiro: number;
  public idMenuParceiro: number = null;
  public idMovimentacaoChaves: number[] = [];
  public idProdutoParceiros: number[] = [];
  public idUnidadesParceiros: number[] = [];
  public idTipoTitulo: number;
  public idTipoTituloEntrada: number;
  public idTipoTituloNegociacao: number;
  public idTipoTituloParceiro: number;
  public identificacao: number;
  public inicializado: boolean;
  public interna: boolean = false;
  public listaIdConfiMovimentacaoContabil: number[] = [];
  public lojaCampoBloqueado: boolean = false;
  public lojas: Loja[] = [];
  public lotes: Lote[] = [];
  public locais: Local[] = [];
  public movimentacao: Movimentacao = new Movimentacao();
  public movimentacaoEstilo: number;
  public movimentacaoManual: boolean = false;
  public movimentacaoProdutoValorUnitarioFinal: number;
  public naturezaOperacoes: NaturezaOperacao[] = [];
  public negociacaoBloqueada: boolean = false;
  public negociacoes: Negociacao[];
  public nota: boolean = false;
  public notaDevolucao: boolean = false;
  public notaTerceiro: boolean = false;
  public operacaoParametros: OperacaoParametro[] = [];
  public orcamento: boolean = false;
  public parceiroContaContabil: string;
  public parceiroDestino: ParceiroDestino;
  public parceiroEndereco: ParceiroEndereco;
  public parceiroIdContaContabil: number;
  public parcelaEntrada: string = 'N';
  public parcelaQuantidade: number = 1;
  public parcelaQuantidadeBloqueado: boolean = false;
  public pedido: boolean = false;
  public percentualDescontoItem: number = 0;
  public permiteValorMenor: boolean = false;
  public pesoBruto: number;
  public pesoLiquido: number;
  public produtoEstoqueComprado: number = 0;
  public produtoEstoqueFisico: number = 0;
  public produtoEstoqueReservado: number = 0;
  public produtoEstoqueTransferido: number = 0;
  public produtoEstoqueCarteira: number = 0;
  public produtosParceiro: ProdutoParceiro[] = [];
  public unidadesParceiros: UnidadeParceiro[] = [];
  public saida: boolean = false;
  public template: string = '';
  public tipoColaborador: string;
  public tipoNegociacaoParceiro: string = '';
  public tipoParceiro: string;
  public tipoParceiroServico: string;
  public tipoTituloParceiro: string;
  public tipoTituloPermitidos: TipoTitulo[] = [];
  public tipoTitulos: TipoTitulo[] = [];
  public transferencia: boolean = false;
  public transferenciaEntrada: boolean = false;
  public umaParcelaAvista: boolean = false;
  public usaConsumidorFinal: boolean = false;
  public usaMovimentacaoQuantidade: boolean = false;
  public usaNegociacao: boolean = false;
  public usuarioPermiteMovimentacaoData: string = 'N';
  public util: Util = new Util();
  public valorEntrada: number;
  public valorTotalImpostoBloqueado: number = 0;
  public valorTotalProdutoBloqueado: number = 0;
  public vincular: boolean = false;
  public vincularOperacao: string = '';
  public vinculoIdMovimentacaoProOrigens: number[] = [];
  public ehValidoCnpjCpf: boolean = true;
  public finalizando: string = 'N';
  public filtroIdLoja = new Criterio('ID_LOJA');
  public vendaDireta: boolean = false;
  public entradaServico: boolean = false;
  public safras: Safra[] = [];
  public centroResultados: CentroResultado[] = [];
  public criteriosAgronomo: Criterio[] = [new Criterio('CREA', 'S')];
  public mostrarAgronomo: boolean = false;
  public cartaoAVista: boolean = false;
  public documentoFiscalEmitido: boolean = false;
  public usaDescontoUnitario: boolean = false;
  public atualizouValor: boolean = false;
  public plainToClass: Function = plainToClass;
  public ignorarMesAtual: boolean = false;
  public nfeModelos = [
    { id: '01', nome: '01 - NOTA FISCAL' },
    { id: '1B', nome: '1B - NOTA FISCAL AVULSA' },
    { id: '02', nome: '02 - NOTA FISCAL DE VENDA A CONSUMIDOR' },
    { id: '2D', nome: '2D - CUPOM FISCAL EMITIDO POR ECF' },
    { id: '2E', nome: '2E - BILHETE DE PASSAGEM EMITIDO POR ECF' },
    { id: '04', nome: '04 - NOTA FISCAL DE PRODUTOR' },
    { id: '06', nome: '06 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA' },
    { id: '07', nome: '07 - NOTA FISCAL DE SERVIÇO DE TRANSPORTE' },
    { id: '08', nome: '08 - CONHECIMENTO DE TRANSPORTE RODOVIÁRIO DE CARGAS' },
    { id: '8B', nome: '8B - CONHECIMENTO DE TRANSPORTE DE CARGAS AVULSO' },
    { id: '09', nome: '09 - CONHECIMENTO DE TRANSPORTE AQUAVIÁRIO DE CARGAS' },
    { id: '10', nome: '10 - CONHECIMENTO AÉREO' },
    { id: '11', nome: '11 - CONHECIMENTO DE TRANSPORTE FERROVIÁRIO DE CARGAS' },
    { id: '13', nome: '13 - BILHETE DE PASSAGEM RODOVIÁRIO' },
    { id: '14', nome: '14 - BILHETE DE PASSAGEM AQUAVIÁRIO' },
    { id: '15', nome: '15 - BILHETE DE PASSAGEM E NOTA DE BAGAGEM' },
    { id: '17', nome: '17 - DESPACHO DE TRANSPORTE' },
    { id: '16', nome: '16 - BILHETE DE PASSAGEM FERROVIÁRIO' },
    { id: '18', nome: '18 - RESUMO DE MOVIMENTO DIÁRIO' },
    { id: '20', nome: '20 - ORDEM DE COLETA DE CARGAS' },
    { id: '21', nome: '21 - NOTA FISCAL DE SERVIÇO DE COMUNICAÇÃO' },
    { id: '22', nome: '22 - NOTA FISCAL DE SERVIÇO DE TELECOMUNICAÇÃO' },
    { id: '23', nome: '23 - GNRE' },
    { id: '24', nome: '24 - AUTORIZAÇÃO DE CARREGAMENTO E TRANSPORTE' },
    { id: '25', nome: '25 - MANIFESTO DE CARGA' },
    { id: '26', nome: '26 - CONHECIMENTO DE TRANSPORTE MULTIMODAL DE CARGAS' },
    { id: '27', nome: '27 - NOTA FISCAL DE TRANSPORTE FERROVIÁRIO DE CARGAS' },
    { id: '28', nome: '28 - NOTA FISCAL/CONTA DE FORNECIMENTO DE GÁS CANALIZADO' },
    { id: '29', nome: '29 - NOTA FISCAL/CONTA DE FORNECIMENTO DE ÁGUA CANALIZADA' },
    { id: '30', nome: '30 - BILHETE/RECIBO DO PASSAGEIRO' },
    { id: '55', nome: '55 - NOTA FISCAL ELETRÔNICA' },
    { id: '57', nome: '57 - CONHECIMENTO DE TRANSPORTE ELETRÔNICO – CT-E' },
    { id: '65', nome: '65 - NOTA FISCAL DE CONSUMIDOR ELETRÔNICA (NFC-E)' },
    { id: '66', nome: '66 - NOTA FISCAL DE ENERGIA ELÉTRICA ELETRÔNICA – NF3E' },
  ];

  public utilitarioBoleto: Utilitario = new Utilitario(this.bibIcone.codigoBarra, this.bibDialogo.boleto, null, false);
  public utilitarioNfe: Utilitario = new Utilitario(this.bibIcone.arquivo, this.bibDialogo.nfe, null, false);
  public utilitarioNfce: Utilitario = new Utilitario(this.bibIcone.arquivo, this.bibDialogo.nfce, null, false);
  public utilitarioReceituario: Utilitario = new Utilitario(this.bibIcone.pdf, this.bibDialogo.receituario, null, false);
  public utilitarioFichaEmergencia: Utilitario = new Utilitario(this.bibIcone.pdf, this.bibDialogo.fichaEmergencia, null, false);
  public utilitarios: Utilitario[] = [this.utilitarioNfe, this.utilitarioNfce, this.utilitarioBoleto, this.utilitarioReceituario, this.utilitarioFichaEmergencia];
  public empresaRegraCasasDecimais: number = this.utilSessao.getEmpresa().regraCasasDecimais;
  public empresaRegraCasasDecimaisEntrada: number = this.utilSessao.getEmpresa().regraCasasDecimaisEntrada;
  public empresaAlertarValorAcima: number = this.utilSessao.getEmpresa().alertarValorAcima;
  public empresaAlertarPesoAcima: number = this.utilSessao.getEmpresa().alertarPesoAcima;
  public empresaAlertarQuantidadeAcima: number = this.utilSessao.getEmpresa().alertarQuantidadeAcima;
  public menuClassificacao: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 109);
  public menuCliente: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 5);
  public menuColaborador: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 56);
  public menuFornecedor: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 54);
  public menuNaturezaOperacao: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 44);
  public menuNegociacao: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 71);
  public menuTipoTitulo: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30);
  public menuTransportadora: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 55);
  public telaClassificacao: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 109);
  public telaCliente: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 5);
  public telaColaborador: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 56);
  public telaFornecedor: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 54);
  public telaNaturezaOperacao: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 44);
  public telaNegociacao: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 71);
  public telaTransportadora: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 55);
  public telaSat: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 217);

  constructor(public utilSessao: UtilSessao, public comunicacaoService: ComunicacaoService) {
    const empresa: Empresa = new UtilSessao().getEmpresa();
    this.movimentacaoEstilo = empresa.movimentacaoEstilo;
    this.compacto = empresa.compacto == 'S' ? true : false;
  }

  buscarValorParametro(idParametro: number): string {
    if (this.operacaoParametros.length > 0) {
      return this.operacaoParametros.find((operacaoParametro) => operacaoParametro.idParametro == idParametro)?.valor;
    }
    return null;
  }

  popularVariaveis(): void {
    this.ehAlimentaCustoReposicao();
    this.ehBalanco();
    this.ehNotaDevolucao();
    this.ehMovimentacaoManual();
    this.ehComissao();
    this.ehBloqueado();
    this.ehCustoReposicao();
    this.ehDevolucao();
    this.ehEntrada();
    this.ehEstoque();
    this.ehFinanceiro();
    this.ehVendaDireta();
    this.ehEntradaServico();
    this.ehInterna();
    this.ehNegociacao();
    this.ehNota();
    this.ehNotaTerceiro();
    this.ehOrcamento();
    this.ehSaida();
    this.ehTransferencia();
    this.ehTransferenciaEntrada();
    this.inicializado = true;
    this.setAprovarMovimentacao();
    this.ehConsumidorFinal();
    this.setExigirKey();
    this.setNomenclatura();
    this.setTipo();
    this.setVincular();
    this.ehIdentificacao();
    this.ehPermiteValorMenor();
    this.ehPedido();
    this.ehDocumentoFiscalEmitido();
    this.setAcessoRapido();
    this.setIdMenuParceiro();
    this.usuarioPermiteMovimentacaoData = this.utilSessao.getUsuario().permiteMovimentacaoData;
    this.usaDescontoUnitario = this.utilSessao.sistema == 'Sigescom-w';
  }

  ehBalanco(): void {
    this.balanco = this.buscarValorParametro(this.bibParametro.identificacao) == '8';
  }

  ehNotaDevolucao(): void {
    this.notaDevolucao = this.buscarValorParametro(this.bibParametro.devolucao) == 'S';
  }

  ehMovimentacaoManual(): void {
    this.movimentacaoManual = this.buscarValorParametro(this.bibParametro.identificacao) == '9';
  }

  ehComissao(): void {
    this.comissao = this.buscarValorParametro(this.bibParametro.comissao) == 'S';
  }

  ehIdentificacao(): void {
    this.identificacao = Number(this.buscarValorParametro(this.bibParametro.identificacao));
  }

  ehDevolucao(): void {
    this.devolucao = this.buscarValorParametro(this.bibParametro.identificacao) == '4';
  }

  ehNota(): void {
    this.nota = this.buscarValorParametro(this.bibParametro.identificacao) == '3';
  }

  ehNotaTerceiro(): void {
    this.notaTerceiro = this.buscarValorParametro(this.bibParametro.identificacao) == '10';
  }

  ehOrcamento(): void {
    this.orcamento = this.buscarValorParametro(this.bibParametro.identificacao) == '1';
  }

  ehTransferencia(): void {
    this.transferencia = this.buscarValorParametro(this.bibParametro.identificacao) == '6' || this.buscarValorParametro(this.bibParametro.identificacao) == '7';
  }

  ehTransferenciaEntrada(): void {
    this.transferenciaEntrada = this.buscarValorParametro(this.bibParametro.identificacao) == '7';
  }

  ehBloqueado(): void {
    this.bloqueado = this.movimentacao == null || this.movimentacao.status == 'S' || this.movimentacao.ativo != 'S' || this.bloqueado || this.utilSessao.modal || this.movimentacao.situacaoDenegada == 'S';
  }

  ehCustoReposicao(): void {
    this.custoReposicao = this.buscarValorParametro(this.bibParametro.custoReposicao) == 'S';
  }

  ehAlimentaCustoReposicao(): void {
    this.alimentaCustoReposicao = this.buscarValorParametro(this.bibParametro.alimentaCustoReposicao) != 'N';
  }

  ehEntrada(): void {
    this.entrada = this.buscarValorParametro(this.bibParametro.entradaSaida) == '1';
  }

  ehInterna(): void {
    this.interna = this.buscarValorParametro(this.bibParametro.entradaSaida) == '0';
  }

  ehNegociacao(): void {
    this.usaNegociacao = this.buscarValorParametro(this.bibParametro.negociacao) == 'S';
  }

  ehSaida(): void {
    this.saida = this.buscarValorParametro(this.bibParametro.entradaSaida) == '-1';
    this.movimentacao.entradaSaida = this.buscarValorParametro(this.bibParametro.entradaSaida);
  }

  ehEstoque(): void {
    this.estoqueComprado = parseInt(this.buscarValorParametro(this.bibParametro.estoqueComprado));
    this.estoqueFisico = parseInt(this.buscarValorParametro(this.bibParametro.estoqueFisico));
    this.estoqueReservado = parseInt(this.buscarValorParametro(this.bibParametro.estoqueReservado));
    this.estoqueTransferido = parseInt(this.buscarValorParametro(this.bibParametro.estoqueTransferido));
    this.estoqueNFe = parseInt(this.buscarValorParametro(this.bibParametro.estoqueNFe));
    this.estoqueCarteira = parseInt(this.buscarValorParametro(this.bibParametro.estoqueCarteira));
  }

  ehFinanceiro(): void {
    this.listarEmpresaOperacaoBloqueio(this.empresa.id).subscribe((res) => {
      const empresaOperacaoBloqueios: EmpresaOperacaoBloqueio[] = plainToClass(EmpresaOperacaoBloqueio, res) as any;
      this.financeiro = this.buscarValorParametro(this.bibParametro.financeiro);
      if (empresaOperacaoBloqueios.find((empresaOperacaoBloqueio) => empresaOperacaoBloqueio.idOperacao == this.movimentacao.idOperacao) != undefined) {
        this.financeiro = 'N';
      }
      if (this.financeiro != 'N') {
        this.listarCentroResultados();
      }
    });
  }

  ehVendaDireta(): void {
    this.vendaDireta = this.buscarValorParametro(this.bibParametro.vendaDireta) == 'S';
  }

  ehEntradaServico(): void {
    this.entradaServico = this.buscarValorParametro(this.bibParametro.entradaServico) == 'S';
  }

  ehPermiteValorMenor(): void {
    this.permiteValorMenor = this.buscarValorParametro(this.bibParametro.permiteValorMenor) == 'S';
  }

  ehPedido(): void {
    this.pedido = this.buscarValorParametro(this.bibParametro.identificacao) == '2';
  }

  ehDocumentoFiscalEmitido(): void {
    this.documentoFiscalEmitido = this.movimentacao.numero > 0 && this.movimentacao.modelo != undefined && this.movimentacao.emitente != undefined && this.movimentacao.chaveFiscal != undefined ? true : false;
  }

  setAcessoRapido(): void {
    this.setAcessoRapidoNfe();
    this.setAcessoRapidoNfce();
    this.setAcessoRapidoBoleto();
    this.setAcessoRapidoReceituario();
    this.setAcessoRapidoFichaEmergencia();
  }

  setAcessoRapidoNfe(): void {
    this.utilitarioNfe.visivel = this.nota && this.movimentacao.chaveFiscal == null && this.movimentacao.status == 'S' && this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 126) != null;
  }

  setAcessoRapidoNfce(): void {
    this.utilitarioNfce.visivel = this.nota && this.movimentacao.chaveFiscal == null && this.movimentacao.status == 'S' && this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 152) != null;
  }

  setAcessoRapidoBoleto(): void {
    this.financeiro = this.buscarValorParametro(this.bibParametro.financeiro);
    if (this.movimentacao.negociacao != null) {
      if (this.movimentacao.negociacao.tipoTituloIdEspecie != null) {
        this.utilitarioBoleto.visivel = this.movimentacao.negociacao.tipoTituloIdEspecie == 3 && this.financeiro == 'R' && this.movimentacao.status == 'S' && this.movimentacao.valorTotal > 0 && this.utilSessao.sistema == 'aGrow-w' && this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 130) != null;
      }
    } else {
      this.utilitarioBoleto.visivel = false;
    }
  }

  setAcessoRapidoReceituario(): void {
    this.utilitarioReceituario.visivel = this.saida && this.nota && this.movimentacao.status == 'S' && this.utilSessao.sistema == 'aGrow-w' && this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 143) != null && this.movimentacao.movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.idProdutoFormulado != null) != null;
  }

  setAcessoRapidoFichaEmergencia(): void {
    this.utilitarioFichaEmergencia.visivel = this.saida && this.nota && this.movimentacao.status == 'S' && this.utilSessao.sistema == 'aGrow-w' && this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 143) != null && this.movimentacao.movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.fichaEmergencia == 'S') != null;
  }

  setExigirKey(): void {
    this.exigirSenha = this.buscarValorParametro(this.bibParametro.exigirSenha) == 'S';
  }

  setAprovarMovimentacao(): void {
    this.movimentacao.aprovarMovimentacao = this.buscarValorParametro(this.bibParametro.aprovarMovimentacao);
  }

  ehAtualizarColaborador(): void {
    let usuario: Usuario = this.utilSessao.getUsuario();
    if (!this.movimentacao.idColaborador) {
      if (usuario.idColaborador && usuario.colaborador) {
        this.movimentacao.idColaborador = usuario.idColaborador;
        this.movimentacao.colaborador = usuario.colaborador;
        this.alertarDescricao(usuario.observacao, usuario.mostrarAlerta, usuario.colaborador);
      } else {
        this.movimentacao.idColaborador = null;
        this.movimentacao.colaborador = null;
      }
    }
  }

  alertarDescricao(observacao: string, mostrarAlerta: string, parceiro: string): void {
    if (observacao != null && mostrarAlerta == 'S') {
      this.utilSessao.informacaoAlert = true;
      const resultado: Resultado = new Resultado(null, parceiro + '\n' + observacao);
      this.utilSessao.setResultado(resultado);
    }
  }

  setVincular(): void {
    this.vincular = this.buscarValorParametro(this.bibParametro.vincular) != '0';
    this.vincularOperacao = this.buscarValorParametro(this.bibParametro.vincular);
    if (this.vincular) {
      this.adicionaVinculo = this.bibDialogo.vincularCom;
      this.adicionaVinculoXml = this.bibDialogo.vincularCom;
      this.montarNomeVinculoOperacao();
    }
  }

  montarNomeVinculoOperacao(): void {
    const vinculoOperacoes: string[] = this.vincularOperacao.split(',');
    vinculoOperacoes.forEach((vincular, i) => {
      const operacao: any = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.movimentacao == 'S' && menuEmpresa.parametro == vincular);
      const operacaoNome: string = operacao != undefined ? (operacao.menuEmpresa ? operacao.apelido : operacao.menu) : '';
      if (i == vinculoOperacoes.length - 1 || (operacaoNome == 'Orçamento' && this.utilSessao.sistema == 'Sigescom-w')) {
        this.adicionarNomeVinculoOperacao(operacaoNome, true);
      } else {
        this.adicionarNomeVinculoOperacao(operacaoNome, false);
      }
    });
  }

  adicionarNomeVinculoOperacao(operacao: string, utilizaSeparador: boolean = false): void {
    if (utilizaSeparador) {
      this.adicionaVinculo += ' ' + operacao.toLowerCase();
      this.adicionaVinculoXml += ' ' + operacao.toLowerCase();
    } else {
      this.adicionaVinculo += ' ' + operacao.toLowerCase() + ' ' + bibDialogo.ou.toLowerCase();
      this.adicionaVinculoXml += ' ' + operacao.toLowerCase() + ' ' + bibDialogo.ou.toLowerCase();
    }
  }

  setDescontoValor(movimentacaoProduto: MovimentacaoProduto, descontoValor: number): void {
    movimentacaoProduto.descontoValor = descontoValor;
    const valorTotal: number = movimentacaoProduto.getQuantidade() * movimentacaoProduto.getValorUnitario();
    movimentacaoProduto.descontoPercentual = this.util.arredondar((movimentacaoProduto.descontoValor / valorTotal) * 100);
    movimentacaoProduto.calcularTotal(this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
    this.totalizar();
  }

  setDespesaValor(despesaValor: number): void {
    this.movimentacao.despesaValor = despesaValor;
    if (despesaValor > 0) {
      this.ratearDespesaMovimentacaoProduto(despesaValor);
    } else {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.despesaRateado = 0;
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }

    this.totalizar();
  }

  setOutroValor(outroValor: number): void {
    this.movimentacao.outroValor = outroValor;
    if (outroValor > 0) {
      this.ratearOutroMovimentacaoProduto(outroValor);
    } else {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.outroRateado = 0;
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }
    this.totalizar();
  }

  setFreteValor(freteValor: number): void {
    this.movimentacao.freteValor = freteValor;
    this.ehFreteValor(freteValor);
    this.totalizar();
  }

  setFreteValorNaoSoma(freteValorNaoSoma: number): void {
    this.movimentacao.freteValorNaoSoma = freteValorNaoSoma;
    this.ehFreteValorNaoSoma(freteValorNaoSoma);
  }

  setSeguroValor(seguroValor: number): void {
    this.movimentacao.seguroValor = seguroValor;
    if (seguroValor > 0) {
      this.ratearSeguroMovimentacaoProduto(seguroValor);
    } else {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.seguroRateado = 0;
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }
    this.totalizar();
  }

  setDescontoPercentualCabecalho(descontoPercentual: number, acumulaDesconto: boolean = false): void {
    let descontoValor: number = 0;
    if (acumulaDesconto) {
      descontoValor = this.util.arredondar(this.movimentacao.valorTotal * (descontoPercentual * 0.01));
    } else {
      this.movimentacao.setDescontoPercentual(descontoPercentual ? descontoPercentual : 0);
      descontoValor = this.util.arredondar((this.movimentacao.valorTotalProduto + this.movimentacao.descontoValor) * (this.movimentacao.descontoPercentual * 0.01));
      this.movimentacao.descontoValor = descontoValor;
    }
    this.ehDescontoValor(descontoValor, acumulaDesconto);
    this.totalizar();
  }

  setDescontoValorCabecalho(descontoValor: number, acumulaDesconto: boolean = false): void {
    const descontoValorHistorico: number = this.movimentacao.descontoValor;
    descontoValor = descontoValor ? this.util.arredondar(descontoValor) : 0;
    if (acumulaDesconto == false) {
      this.movimentacao.descontoValor = descontoValor;
      this.movimentacao.setDescontoPercentual((this.movimentacao.descontoValor / (this.movimentacao.valorTotalProduto + descontoValorHistorico)) * 100);
    }
    this.ehDescontoValor(descontoValor, acumulaDesconto);
    this.totalizar();
  }

  ehDescontoValor(descontoValor: number, acumulaDesconto: boolean): void {
    if (descontoValor > 0) {
      this.ratearDescontoMovimentacaoProduto(descontoValor, acumulaDesconto);
    } else {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.descontoRateado = 0;
      });
    }
  }

  ehFreteValor(freteValor: number): void {
    if (freteValor > 0) {
      this.ratearFreteMovimentacaoProduto(freteValor);
    } else {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.freteRateado = 0;
      });
    }
  }

  ehFreteValorNaoSoma(freteValorNaoSoma: number): void {
    if (freteValorNaoSoma > 0) {
      this.ratearFreteNaoSomaMovimentacaoProduto(freteValorNaoSoma);
    } else {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.freteRateadoNaoSoma = 0;
      });
    }
  }

  calcularPesoBrutoLiquido(): void {
    this.pesoBruto = 0;
    this.pesoLiquido = 0;
    this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      const pesoBruto: number = movimentacaoProduto.produtoPesoBruto ? movimentacaoProduto.produtoPesoBruto : 0;
      const pesoLiquido: number = movimentacaoProduto.produtoPesoLiquido ? movimentacaoProduto.produtoPesoLiquido : 0;
      const totalPesoBruto: number = pesoBruto * movimentacaoProduto.getQuantidade();
      const totalPesoLiquido: number = pesoLiquido * movimentacaoProduto.getQuantidade();
      this.pesoBruto += totalPesoBruto;
      this.pesoLiquido += totalPesoLiquido;
    });
    this.movimentacao.pesoBruto = this.pesoBruto;
    this.movimentacao.pesoLiquido = this.pesoLiquido;
  }

  ratearDescontoMovimentacaoProduto(descontoValor: number, acumulaDesconto: boolean): void {
    if (descontoValor > 0) {
      let valorTotalProduto: number = 0;
      let valorDescontoTotal: number = 0;
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        const descontoMaximoNegociacao: number = this.movimentacao.idNegociacao != undefined ? this.negociacoes.find((negocicao) => negocicao.id == this.movimentacao.idNegociacao).descontoMaximo : 100;
        valorTotalProduto += movimentacaoProduto.valorTotal;
        valorDescontoTotal += (movimentacaoProduto.getValorUnitario() - descontoMaximoNegociacao * 0.01 * movimentacaoProduto.getValorUnitario()) * movimentacaoProduto.getQuantidade();
        if (acumulaDesconto) {
          valorDescontoTotal -= movimentacaoProduto.descontoValor;
        }
      });
      valorDescontoTotal = this.util.arredondar(valorTotalProduto - valorDescontoTotal);

      let valorDiferencaDesconto: number = 0;
      if (valorDescontoTotal < descontoValor) {
        valorDiferencaDesconto = descontoValor - valorDescontoTotal;
      }

      if (this.usaDescontoUnitario) {
        this.calcularDescontoUnitarioRateado(valorTotalProduto, valorDescontoTotal, valorDiferencaDesconto, descontoValor, acumulaDesconto);
      } else {
        this.calcularDescontoRateado(valorTotalProduto, valorDescontoTotal, valorDiferencaDesconto, descontoValor, acumulaDesconto);
      }
    }
  }

  calcularDescontoUnitarioRateado(valorTotalProduto: number, valorDescontoTotalDisponivel: number, valorDiferencaDesconto: number, descontoValor: number, acumulaDesconto: boolean): void {
    let totalDescontoRateado: number = 0;
    let descontoRateado: number = 0;
    let descontoUnitarioRateado: number = 0;
    this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
      const descontoMaximoNegociacao: number = this.negociacoes && this.negociacoes.length > 0 && this.movimentacao.idNegociacao ? this.negociacoes.find((negocicao) => negocicao.id == this.movimentacao.idNegociacao).descontoMaximo : 100;
      if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
        descontoRateado = this.util.arredondar(descontoValor - totalDescontoRateado);
        descontoUnitarioRateado = descontoRateado / movimentacaoProduto.quantidade;
        if (acumulaDesconto) {
          movimentacaoProduto.setDescontoValorUnitario((this.util.arredondar(movimentacaoProduto.descontoValorUnitario + descontoUnitarioRateado, 4)), this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal, acumulaDesconto);
        } else {
          movimentacaoProduto.descontoRateadoUnitario = descontoUnitarioRateado;
        }
      } else {
        let descontoMaximo: number = ((movimentacaoProduto.getValorUnitario() * descontoMaximoNegociacao) / 100) * movimentacaoProduto.getQuantidade();
        let valorRateioDescontoMaximo: number = 0;
        let valorRateioDiferenca: number = 0;
        if (valorDescontoTotalDisponivel > 0) {
          valorRateioDescontoMaximo = this.util.arredondar((descontoMaximo * descontoValor) / valorDescontoTotalDisponivel);
        }
        if (valorDiferencaDesconto > 0) {
          valorRateioDiferenca = this.util.arredondar((movimentacaoProduto.valorTotal * valorDiferencaDesconto) / valorTotalProduto);
        }
        descontoRateado = this.util.arredondar(valorRateioDescontoMaximo + valorRateioDiferenca);
        descontoUnitarioRateado = descontoRateado / movimentacaoProduto.quantidade;
        if (acumulaDesconto) {
          movimentacaoProduto.setDescontoValorUnitario(this.util.arredondar(movimentacaoProduto.descontoValorUnitario + descontoUnitarioRateado, 4), this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal, acumulaDesconto);
        } else {
          movimentacaoProduto.descontoRateadoUnitario = descontoUnitarioRateado;
        }
        totalDescontoRateado += valorRateioDescontoMaximo + valorRateioDiferenca;
      }
    });
  }

  calcularDescontoRateado(valorTotalProduto: number, valorDescontoTotalDisponivel: number, valorDiferencaDesconto: number, descontoValor: number, acumulaDesconto: boolean): void {
    let totalDescontoRateado: number = 0;
    let descontoRateado: number = 0;
    this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
      const descontoMaximoNegociacao: number = this.negociacoes && this.negociacoes.length > 0 && this.movimentacao.idNegociacao ? this.negociacoes.find((negocicao) => negocicao.id == this.movimentacao.idNegociacao).descontoMaximo : 100;
      if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
        descontoRateado = this.util.arredondar(descontoValor - totalDescontoRateado);
        if (acumulaDesconto) {
          //movimentacaoProduto.descontoRateadoNovo = descontoRateado;
          movimentacaoProduto.setDescontoValor(this.util.arredondar(movimentacaoProduto.descontoValor + descontoRateado), this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
        } else {
          movimentacaoProduto.descontoRateado = descontoRateado;
        }
      } else {
        let descontoMaximo: number = ((movimentacaoProduto.getValorUnitario() * descontoMaximoNegociacao) / 100) * movimentacaoProduto.getQuantidade();
        let valorRateioDescontoMaximo: number = 0;
        let valorRateioDiferenca: number = 0;
        if (valorDescontoTotalDisponivel > 0) {
          //valorRateioDescontoMaximo = this.util.arredondar((descontoMaximo * (descontoValor >= descontoMaximo ? valorDescontoTotalDisponivel : descontoValor)) / valorDescontoTotalDisponivel);
          valorRateioDescontoMaximo = this.util.arredondar((descontoMaximo * descontoValor) / valorDescontoTotalDisponivel);
        }
        if (valorDiferencaDesconto > 0) {
          valorRateioDiferenca = this.util.arredondar((movimentacaoProduto.valorTotal * valorDiferencaDesconto) / valorTotalProduto);
        }
        descontoRateado = this.util.arredondar(valorRateioDescontoMaximo + valorRateioDiferenca);
        if (acumulaDesconto) {
          //movimentacaoProduto.descontoRateadoNovo = descontoRateado;
          movimentacaoProduto.setDescontoValor(this.util.arredondar(movimentacaoProduto.descontoValor + descontoRateado), this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
        } else {
          movimentacaoProduto.descontoRateado = descontoRateado;
        }
        totalDescontoRateado += valorRateioDescontoMaximo + valorRateioDiferenca;
      }
    });
  }

  ratearSeguroMovimentacaoProduto(seguroValor: number): void {
    if (seguroValor > 0) {
      let valorSeguroTotal: number = 0;
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
        movimentacaoProduto.seguroRateado = this.util.arredondar(this.movimentacao.valorTotalProduto > 0 ? seguroValor * (movimentacaoProduto.valorTotal / this.movimentacao.valorTotalProduto) : 0);
        valorSeguroTotal += this.util.arredondar(movimentacaoProduto.seguroRateado);
        if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
          let valorDiferencaSeguro: number = 0;
          if (valorSeguroTotal < seguroValor) {
            valorDiferencaSeguro = seguroValor - valorSeguroTotal;
            movimentacaoProduto.seguroRateado = this.util.arredondar(movimentacaoProduto.seguroRateado + valorDiferencaSeguro);
          }
        }
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }
  }

  ratearDespesaMovimentacaoProduto(despesaValor: number): void {
    if (despesaValor > 0) {
      let valorDespesaTotal: number = 0;
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
        movimentacaoProduto.despesaRateado = this.util.arredondar(this.movimentacao.valorTotalProduto > 0 ? despesaValor * (movimentacaoProduto.valorTotal / this.movimentacao.valorTotalProduto) : 0);
        valorDespesaTotal += this.util.arredondar(movimentacaoProduto.despesaRateado);
        if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
          let valorDiferencaDespesa: number = 0;
          if (valorDespesaTotal < despesaValor) {
            valorDiferencaDespesa = despesaValor - valorDespesaTotal;
            movimentacaoProduto.despesaRateado = this.util.arredondar(movimentacaoProduto.despesaRateado + valorDiferencaDespesa);
          }
        }
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }
  }

  ratearOutroMovimentacaoProduto(outroValor: number): void {
    if (outroValor > 0) {
      let valorOutroTotal: number = 0;
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
        movimentacaoProduto.outroRateado = this.util.arredondar(this.movimentacao.valorTotalProduto > 0 ? outroValor * (movimentacaoProduto.valorTotal / this.movimentacao.valorTotalProduto) : 0);
        valorOutroTotal += this.util.arredondar(movimentacaoProduto.outroRateado);
        if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
          let valorDiferencaOutro: number = 0;
          if (valorOutroTotal < outroValor) {
            valorDiferencaOutro = outroValor - valorOutroTotal;
            movimentacaoProduto.outroRateado = this.util.arredondar(movimentacaoProduto.outroRateado + valorDiferencaOutro);
          }
        }
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }
  }

  ratearFreteMovimentacaoProduto(freteValor: number): void {
    if (freteValor > 0) {
      let valorFreteTotal: number = 0;
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
        movimentacaoProduto.freteRateado = this.util.arredondar(this.movimentacao.valorTotalProduto > 0 ? freteValor * (movimentacaoProduto.valorTotal / this.movimentacao.valorTotalProduto) : 0);
        valorFreteTotal += this.util.arredondar(movimentacaoProduto.freteRateado);
        if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
          let valorDiferencaFrete: number = 0;
          if (valorFreteTotal < freteValor) {
            valorDiferencaFrete = freteValor - valorFreteTotal;
            movimentacaoProduto.freteRateado = this.util.arredondar(movimentacaoProduto.freteRateado + valorDiferencaFrete);
          }
        }
        let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
        utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida);
      });
    }
  }

  ratearFreteNaoSomaMovimentacaoProduto(freteValorNaoSoma: number): void {
    if (freteValorNaoSoma > 0) {
      let valorFreteNaoSomaTotal: number = 0;
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
        movimentacaoProduto.freteRateadoNaoSoma = this.util.arredondar(this.movimentacao.valorTotalProduto > 0 ? freteValorNaoSoma * (movimentacaoProduto.valorTotal / this.movimentacao.valorTotalProduto) : 0);
        valorFreteNaoSomaTotal += this.util.arredondar(movimentacaoProduto.freteRateadoNaoSoma);
        if (this.movimentacao.movimentacaoProdutos[i + 1] == null) {
          let valorDiferencaFreteNaoSoma: number = 0;
          if (valorFreteNaoSomaTotal < freteValorNaoSoma) {
            valorDiferencaFreteNaoSoma = freteValorNaoSoma - valorFreteNaoSomaTotal;
            movimentacaoProduto.freteRateadoNaoSoma = this.util.arredondar(movimentacaoProduto.freteRateadoNaoSoma + valorDiferencaFreteNaoSoma);
          }
        }
      });
    }
  }

  setCofinsAliquota(movimentacaoProduto: MovimentacaoProduto, aliquotaCofins: number): void {
    movimentacaoProduto.setCofinsAliquota(aliquotaCofins);
  }

  setCofinsBase(movimentacaoProduto: MovimentacaoProduto, baseCofins: number): void {
    movimentacaoProduto.setCofinsBase(baseCofins);
    this.setCofinsValor(movimentacaoProduto);
  }

  setCofinsValor(movimentacaoProduto: MovimentacaoProduto, valorCofins: number = null): void {
    movimentacaoProduto.setCofinsValor(valorCofins ? valorCofins : this.util.arredondar((movimentacaoProduto.getCofinsBase() / 100) * movimentacaoProduto.getCofinsAliquota()));
    this.totalizar();
  }

  setIcmsAliquota(movimentacaoProduto: MovimentacaoProduto, aliquotaIcms: number): void {
    movimentacaoProduto.setIcmsAliquota(aliquotaIcms);
    this.setIcmsValor(movimentacaoProduto);
  }

  setIcmsBase(movimentacaoProduto: MovimentacaoProduto, baseIcms: number): void {
    movimentacaoProduto.setIcmsBase(baseIcms);
    this.setIcmsValor(movimentacaoProduto);
  }

  setIcmsValor(movimentacaoProduto: MovimentacaoProduto, valorIcms: number = null): void {
    movimentacaoProduto.setIcmsValor(valorIcms ? valorIcms : this.util.arredondar((movimentacaoProduto.getIcmsBase() / 100) * movimentacaoProduto.getIcmsAliquota()));
    this.totalizar();
  }

  setIcmsDesonerado(movimentacaoProduto: MovimentacaoProduto, icmsDesonerado: number): void {
    movimentacaoProduto.setIcmsDesonerado(icmsDesonerado);
    this.totalizar();
  }

  setIcmsStBase(movimentacaoProduto: MovimentacaoProduto, baseIcmsSt: number): void {
    movimentacaoProduto.setIcmsStBase(baseIcmsSt);
    this.totalizar();
  }

  setIcmsStValor(movimentacaoProduto: MovimentacaoProduto, valorIcmsSt: number): void {
    movimentacaoProduto.setIcmsStValor(valorIcmsSt);
    this.totalizar();
  }

  setIpiAliquota(movimentacaoProduto: MovimentacaoProduto, aliquotaIpi: number): void {
    movimentacaoProduto.setIpiAliquota(aliquotaIpi);
    this.setIpiValor(movimentacaoProduto);
  }

  setIpiBase(movimentacaoProduto: MovimentacaoProduto, baseIpi: number): void {
    movimentacaoProduto.setIpiBase(baseIpi);
    this.setIpiValor(movimentacaoProduto);
  }

  setIpiValor(movimentacaoProduto: MovimentacaoProduto, valorIpi: number = null): void {
    movimentacaoProduto.setIpiValor(valorIpi ? valorIpi : (movimentacaoProduto.getIpiBase() / 100) * movimentacaoProduto.getIpiAliquota());
    this.totalizar();
  }

  setPisAliquota(movimentacaoProduto: MovimentacaoProduto, aliquotaPis: number): void {
    movimentacaoProduto.setPisAliquota(aliquotaPis);
    this.setPisValor(movimentacaoProduto);
  }

  setPisBase(movimentacaoProduto: MovimentacaoProduto, basePis: number): void {
    movimentacaoProduto.setPisBase(basePis);
    this.setPisValor(movimentacaoProduto);
  }

  setPisValor(movimentacaoProduto: MovimentacaoProduto, valorPis: number = null): void {
    movimentacaoProduto.setPisValor(valorPis ? valorPis : (movimentacaoProduto.getPisBase() / 100) * movimentacaoProduto.getPisAliquota());
    this.totalizar();
  }

  setIssAliquota(movimentacaoProduto: MovimentacaoProduto, aliquotaIss: number): void {
    movimentacaoProduto.setIssAliquota(aliquotaIss);
    this.setIssValor(movimentacaoProduto);
  }

  setIssBase(movimentacaoProduto: MovimentacaoProduto, baseIss: number): void {
    movimentacaoProduto.setIssBase(baseIss);
    this.setIssValor(movimentacaoProduto);
  }

  setIssValor(movimentacaoProduto: MovimentacaoProduto, valorIss: number = null): void {
    movimentacaoProduto.setPisValor(valorIss ? valorIss : (movimentacaoProduto.getIssBase() / 100) * movimentacaoProduto.getIssAliquota());
    this.totalizar();
  }

  totalizar(recalculaDesonerado: boolean = true): void {
    if (this.movimentacao.freteValor > 0) {
      this.ehFreteValor(this.movimentacao.freteValor);
    }

    this.zerarValores();
    this.movimentacao.descontoTotal = 0;
    for (const movimentacaoProduto of this.movimentacao.movimentacaoProdutos) {
      this.totalizarValorTotalProduto(movimentacaoProduto);
      this.totalizarItem(movimentacaoProduto, recalculaDesonerado);
      this.totalizarImpostos(movimentacaoProduto);
      if (movimentacaoProduto.descontoValorUnitario > 0) {
        this.movimentacao.descontoTotal += (movimentacaoProduto.getQuantidade() * movimentacaoProduto.descontoValorUnitario);
      } else {
        this.movimentacao.descontoTotal += Number(movimentacaoProduto.descontoValor);
      }
    }

    this.movimentacao.descontoTotal += Number(this.movimentacao.descontoValor);
    this.movimentacao.valorTotal = this.util.arredondar(this.movimentacao.valorTotalProduto - this.movimentacao.icmsDesoneradoValor - this.movimentacao.issValor + this.movimentacao.icmsStValor + this.movimentacao.ipiValor + this.movimentacao.freteValor + this.movimentacao.outroValor + this.movimentacao.despesaValor + this.movimentacao.seguroValor);
    this.movimentacao.valorBloqueado = this.util.arredondar(this.valorTotalProdutoBloqueado + this.valorTotalImpostoBloqueado);
    this.movimentacao.valorFinanceiro = this.util.arredondar(this.movimentacao.valorTotal - this.movimentacao.valorBloqueado);
    this.movimentacao.valorFinanceiro = this.movimentacao.valorFinanceiro > 0 ? this.movimentacao.valorFinanceiro : 0;

    this.calcularLucro();
    this.calcularDiferencaParcelaTotal();
  }

  zerarValores(): void {
    this.valorTotalProdutoBloqueado = 0;
    this.valorTotalImpostoBloqueado = 0;
    this.movimentacao.valorTotalProduto = 0;
    this.movimentacao.valorTotalProdutoBruto = 0;
    this.movimentacao.cofinsBase = 0;
    this.movimentacao.cofinsValor = 0;
    this.movimentacao.icmsBase = 0;
    this.movimentacao.icmsDesoneradoValor = 0;
    this.movimentacao.icmsDispensadoValor = 0;
    this.movimentacao.icmsStBase = 0;
    this.movimentacao.icmsStValor = 0;
    this.movimentacao.icmsValor = 0;
    this.movimentacao.ipiBase = 0;
    this.movimentacao.ipiValor = 0;
    this.movimentacao.pisBase = 0;
    this.movimentacao.pisValor = 0;
    this.movimentacao.valorTotalProdutoEntrada = 0;
    this.movimentacao.valorTotalProdutoSaida = 0;
    this.movimentacao.valorTotal = 0;
    this.movimentacao.valorFinanceiro = 0;
    this.movimentacao.inssRetidoValor = 0;
    this.movimentacao.irrfRetidoValor = 0;
    this.movimentacao.csllRetidoValor = 0;
    this.movimentacao.pisRetidoValor = 0;
    this.movimentacao.cofinsRetidoValor = 0;
    this.movimentacao.deducoesValor = 0;
    this.movimentacao.outrasRetencoesValor = 0;
    this.movimentacao.issBase = 0;
    this.movimentacao.issValor = 0;
  }

  totalizarValorTotalProduto(movimentacaoProduto: MovimentacaoProduto): void {
    this.movimentacao.valorTotalProduto += this.util.arredondar(this.util.arredondar(movimentacaoProduto.valorTotal));
    this.movimentacao.valorTotalProdutoBruto += this.util.arredondar(movimentacaoProduto.getQuantidade() * movimentacaoProduto.getValorUnitario()) + (movimentacaoProduto.getQuantidade() * movimentacaoProduto.descontoValorUnitario) + movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado;
    if (movimentacaoProduto.bloqueadoFinanceiro == 'S') {
      this.valorTotalProdutoBloqueado += this.util.arredondar(movimentacaoProduto.valorTotal);
    }
  }

  totalizarItem(movimentacaoProduto: MovimentacaoProduto, recalculaDesonerado: boolean = true): void {
    movimentacaoProduto.valorFinal = (movimentacaoProduto.valorTotal + movimentacaoProduto.freteRateado + movimentacaoProduto.despesaRateado + movimentacaoProduto.seguroRateado + movimentacaoProduto.outroRateado + movimentacaoProduto.getIcmsStValor() + movimentacaoProduto.getIpiValor() - movimentacaoProduto.descontoRateado - movimentacaoProduto.getIcmsDesonerado() - movimentacaoProduto.getIssValor());
    let utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
    utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacao.naturezaOperacaoFinalidade, this.saida, recalculaDesonerado);
  }

  totalizarImpostos(movimentacaoProduto: MovimentacaoProduto): void {
    this.movimentacao.cofinsBase += movimentacaoProduto.getCofinsBase();
    this.movimentacao.cofinsValor += movimentacaoProduto.getCofinsValor();
    this.movimentacao.icmsBase += movimentacaoProduto.getIcmsBase();
    this.movimentacao.icmsDesoneradoValor += movimentacaoProduto.getIcmsDesonerado();
    this.movimentacao.icmsDispensadoValor += movimentacaoProduto.icmsDispensado;
    this.movimentacao.icmsStValor += movimentacaoProduto.getIcmsStValor();
    this.movimentacao.icmsStBase += movimentacaoProduto.getIcmsStBase();
    this.movimentacao.icmsValor += movimentacaoProduto.getIcmsValor();
    this.movimentacao.ipiBase += movimentacaoProduto.getIpiBase();
    this.movimentacao.ipiValor += movimentacaoProduto.getIpiValor();
    this.movimentacao.pisBase += movimentacaoProduto.getPisBase();
    this.movimentacao.pisValor += movimentacaoProduto.getPisValor();
    this.movimentacao.inssRetidoValor += movimentacaoProduto.getInssRetidoValor();
    this.movimentacao.irrfRetidoValor += movimentacaoProduto.getIrrfRetidoValor();
    this.movimentacao.csllRetidoValor += movimentacaoProduto.getCsllRetidoValor();
    this.movimentacao.pisRetidoValor += movimentacaoProduto.getPisRetidoValor();
    this.movimentacao.cofinsRetidoValor += movimentacaoProduto.getCofinsRetidoValor();
    this.movimentacao.deducoesValor += movimentacaoProduto.getDeducoesValor();
    this.movimentacao.outrasRetencoesValor += movimentacaoProduto.getOutrasRetencoesValor();
    this.movimentacao.issBase += movimentacaoProduto.getIssBase();
    this.movimentacao.issValor += movimentacaoProduto.getIssValor();
    if (movimentacaoProduto.bloqueadoFinanceiro == 'S') {
      this.valorTotalImpostoBloqueado += this.util.arredondar(movimentacaoProduto.getIpiValor()) + this.util.arredondar(movimentacaoProduto.getIcmsStValor()) - this.util.arredondar(movimentacaoProduto.getIcmsDesonerado());
    }
  }

  calcularLucro(): void {
    if (this.movimentacao.status != 'S' && this.saida) {
      let custoGerencialTotal: number = 0;
      this.movimentacao.lucroValor = 0;
      this.movimentacao.lucroPercentual = 0;
      this.movimentacao.usaLucro = 'N';
      for (const movimentacaoProduto of this.movimentacao.movimentacaoProdutos) {
        this.ehUsaLucro(movimentacaoProduto);
        movimentacaoProduto.calcularLucro(this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal, this.financeiro == 'N' && !this.orcamento, movimentacaoProduto.getIcmsDesonerado());
        this.movimentacao.lucroValor += movimentacaoProduto.lucroValor;
        custoGerencialTotal += movimentacaoProduto.custoGerencial * movimentacaoProduto.quantidadeConvertida + movimentacaoProduto.penalidadeValor;
      }
      if ((this.movimentacao.usaLucro == 'S')) {
        if (this.devolucao || (this.notaDevolucao && this.saida)) {
          this.movimentacao.lucroValor = this.movimentacao.lucroValor * -1;
        }
      }
      this.ehCalcularLucroPercentual(custoGerencialTotal, this.movimentacao.icmsDesoneradoValor);
    }
  }

  ehUsaLucro(movimentacaoProduto: MovimentacaoProduto): void {
    const ESPECIE_CREDITO: string = 'C';
    movimentacaoProduto.usaLucro = 'N';
    if (this.saida && this.movimentacao.naturezaOperacaoUsaCustoReposicao != 'S' && (this.financeiro != 'N' || this.movimentacao.especie == ESPECIE_CREDITO)) {
      movimentacaoProduto.usaLucro = 'S';
      this.movimentacao.usaLucro = 'S';
    }
  }

  ehCalcularLucroPercentual(custoGerencialTotal: number, icmsDesoneradoValor): void {
    if (this.movimentacao.valorTotal > 0 && custoGerencialTotal > 0 && this.saida) {
      this.movimentacao.lucroPercentual = this.util.arredondar(((this.movimentacao.valorTotalProduto - custoGerencialTotal - icmsDesoneradoValor) * 100) / (this.movimentacao.valorTotalProduto - this.movimentacao.icmsDesoneradoValor));
      if (this.devolucao) {
        this.movimentacao.lucroPercentual = this.movimentacao.lucroPercentual * -1;
      }
    }
  }

  tratarTipo(saida: string, entrada: string, interna: string, nota: string): string {
    return (this.saida && this.nota) || (this.entrada && this.nota) ? nota : this.saida ? saida : this.entrada ? entrada : interna;
  }

  setIdMenuParceiro(): void {
    if (this.entrada && this.telaFornecedor && this.telaFornecedor.inserir == 'S') {
      this.idMenuParceiro = this.menuFornecedor.idMenu;
    } else if (this.saida && this.telaCliente && this.telaCliente.inserir == 'S') {
      this.idMenuParceiro = this.menuCliente.idMenu;
    } else if (this.interna && this.telaColaborador && this.telaColaborador.inserir == 'S') {
      this.idMenuParceiro = this.menuColaborador.idMenu;
    } else {
      this.idMenuParceiro = null;
    }
  }

  setTipo(): void {
    this.tipoColaborador = this.tratarTipo((this.saida && this.nota) || this.devolucao ? this.bibDialogo.responsavel : this.bibDialogo.vendedor, this.notaTerceiro || this.devolucao ? this.bibDialogo.responsavel : this.bibDialogo.comprador, this.bibDialogo.responsavel, this.bibDialogo.responsavel);
    this.tipoParceiro = this.tratarTipo(this.bibDialogo.cliente, this.bibDialogo.fornecedor, this.bibDialogo.colaborador, this.saida && this.nota ? this.bibDialogo.destinatario : this.entrada && this.nota ? this.bibDialogo.clienteFornecedor : this.bibDialogo.cliente);
    this.tipoParceiroServico = this.tratarTipo(this.bibServico.cliente, this.bibServico.fornecedor, this.saida ? this.bibServico.colaboradorVendedor : this.entrada ? this.bibServico.colaboradorComprador : this.bibServico.colaboradorInterno, this.bibServico.destinoParceiro);
  }

  setNomenclatura(): void {
    this.acrescimo = this.bibNomenclatura.escrever(this.bibNomenclatura.acrescimo);
    this.desconto = this.bibNomenclatura.escrever(this.bibNomenclatura.desconto);
  }

  adicionarMovimentacaoQuantidade(movimentacaoProduto: MovimentacaoProduto, idLocal: number = null): void {
    let movimentacaoQuantidade: MovimentacaoQuantidade = new MovimentacaoQuantidade();
    movimentacaoQuantidade.idEmpresa = movimentacaoProduto.idEmpresa;
    movimentacaoQuantidade.idMovimentacaoProduto = movimentacaoProduto.id;
    movimentacaoQuantidade.status = 'N';
    movimentacaoQuantidade.idLocal = idLocal;
    const quantidade: number = movimentacaoProduto.movimentacaoQuantidades.length == 0 ? movimentacaoProduto.getQuantidade() : null;
    movimentacaoQuantidade.quantidade = quantidade;
    movimentacaoQuantidade.idLoja = movimentacaoProduto.movimentacaoQuantidades.length == 0 ? this.movimentacao.idLoja : null;
    movimentacaoQuantidade.referencia = 'D';
    if (movimentacaoProduto.lotes && movimentacaoProduto.lotes.length == 1) {
      movimentacaoQuantidade.idLote = movimentacaoProduto.lotes[0].id;
    }
    movimentacaoProduto.movimentacaoQuantidades.push(movimentacaoQuantidade);
    this.verUsaMovimentacaoQuantidade(movimentacaoProduto);
  }

  verUsaMovimentacaoQuantidade(movimentacaoProduto: MovimentacaoProduto): void {
    movimentacaoProduto.usaMovimentacaoQuantidade = movimentacaoProduto.movimentacaoQuantidades.length > 1 ? true : false;
  }

  vincularMovimentacaoQuantidade(movimentacaoProdutos: MovimentacaoProduto[], movimentacaoQuantidades: MovimentacaoQuantidade[]): void {
    for (let movimentacaoProduto of movimentacaoProdutos) {
      movimentacaoProduto.movimentacaoQuantidades = [];
      for (let movimentacaoQuantidade of movimentacaoQuantidades) {
        if (movimentacaoProduto.id == movimentacaoQuantidade.idMovimentacaoProduto) {
          movimentacaoProduto.movimentacaoQuantidades.push(movimentacaoQuantidade);
        }
      }
      this.verUsaMovimentacaoQuantidade(movimentacaoProduto);
    }
  }

  vincularReceituario(movimentacaoProdutos: MovimentacaoProduto[], receituarios: Receituario[]): void {
    for (let movimentacaoProduto of movimentacaoProdutos) {
      movimentacaoProduto.receituario = null;
      for (let receituario of receituarios) {
        if (movimentacaoProduto.id == receituario.idMovimentacaoPro) {
          movimentacaoProduto.receituario = receituario;
        }
      }
    }
  }

  totalizarParcelas(): number {
    let valorParcelaTotal: number = 0;
    for (const movimentacaoParcela of this.movimentacao.movimentacaoParcelas) {
      valorParcelaTotal += movimentacaoParcela.getValorParcela();
    }
    return valorParcelaTotal;
  }

  totalizarBloqueadoParcelas(): number {
    let valorParcelaTotal: number = 0;
    for (const movimentacaoParcela of this.movimentacao.movimentacaoParcelas) {
      valorParcelaTotal += movimentacaoParcela.getValorBloqueado();
    }
    return valorParcelaTotal;
  }

  calcularDiferencaParcelaTotal(): void {
    if (this.movimentacao.movimentacaoParcelas.length == 0) {
      this.diferencaParcelaTotal = 0;
    } else {
      this.diferencaParcelaTotal = this.util.arredondar(this.totalizarParcelas()) - this.util.arredondar(this.movimentacao.valorTotal);
    }
  }

  setIdNegociacao(negociacao: Negociacao, ehLimparParcelas: boolean = true): void {
    if (negociacao) {
      this.movimentacao.idNegociacao = negociacao.id;
      this.movimentacao.negociacao = negociacao;
      this.movimentacao.especie = negociacao.especie;
      if (ehLimparParcelas) {
        this.limparParcelas();
      }
      this.ehDefinirRegraEstoque();
      this.calcularLucro();
      this.tratarNegociacao();
      if (negociacao.idTipoTitulo != undefined) {
        this.idTipoTituloNegociacao = negociacao.idTipoTitulo;
      } else {
        this.idTipoTituloNegociacao = null;
      }
      this.atualizarTipoTituloParcelas(this.idTipoTituloNegociacao);
      this.listarTipoTitulos(this.idTipoTituloNegociacao);
    }
    this.setAcessoRapidoBoleto();
  }

  setFlagSat(): void {
    const loja: Loja = this.utilSessao.getLojas().find((loj) => loj.id == this.movimentacao.idLoja);
    if (loja.sat && loja.sat == 'S') {
      this.utilitarioNfce.legenda = bibDialogo.sat;
    }
  }

  private ehDefinirRegraEstoque(): void {
    if (this.devolucao && this.entrada) {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.definirRegraEstoque(this.estoqueComprado, this.estoqueFisico, this.estoqueReservado, this.estoqueTransferido, this.estoqueNFe, this.estoqueCarteira);
      });
    }
  }

  atualizarTituloParcelamento(): void {
    this.tipoNegociacaoParceiro = this.bibDialogo.negociacao + ' ' + this.bibDialogo.cliente.toLowerCase() + ': ';
    switch (this.movimentacao.parceiroTipoNegociacao) {
      case 'L':
        this.tipoNegociacaoParceiro += this.bibDialogo.livre.toUpperCase();
        break;
      case 'S':
        this.tipoNegociacaoParceiro += this.bibDialogo.semanal.toUpperCase();
        break;
      case 'Q':
        this.tipoNegociacaoParceiro += this.bibDialogo.quinzenal.toUpperCase();
        break;
      case 'M':
        this.tipoNegociacaoParceiro += this.bibDialogo.mensal.toUpperCase();
        break;
      case 'D':
        this.tipoNegociacaoParceiro += this.bibDialogo.diaFixo.toUpperCase();
        break;
      case 'P':
        this.tipoNegociacaoParceiro += this.bibDialogo.prazoFixo.toUpperCase();
        break;
      case 'A':
        this.tipoNegociacaoParceiro += this.bibDialogo.somenteVendaAVista.toUpperCase();
        break;
      default:
        this.tipoNegociacaoParceiro = '';
        break;
    }
    this.tipoNegociacaoParceiro += this.movimentacao.parceiroPrazoFixo ? ' +' + this.movimentacao.parceiroPrazoFixo : '';
    this.tipoNegociacaoParceiro += this.movimentacao.parceiroDiaFechamento ? ' ' + this.movimentacao.parceiroDiaFechamento : '';
    this.tipoNegociacaoParceiro += this.movimentacao.idTipoTituloParceiro ? ' - ' + this.movimentacao.tipoTituloParceiro : '';
  }

  tratarNegociacao(): void {
    this.parcelaEntrada = 'N';
    this.valorEntrada = null;
    this.idTipoTituloEntrada = null;
    this.parcelaQuantidade = this.movimentacao.negociacao.parcelaFixo != null && this.movimentacao.negociacao.parcelaFixo > 0 ? this.movimentacao.negociacao.parcelaFixo : 1;
    this.parcelaQuantidadeBloqueado = this.movimentacao.negociacao.parcelaFixo ? true : false;
    this.ehUmaParcelaAvista();
    this.ehNegociacaoBloqueada();
    this.bloquearDiasEntreOuDiaFixo();
  }

  ehUmaParcelaAvista(): void {
    this.umaParcelaAvista = false;
    if (this.movimentacao.negociacao.parcelaFixo == 1 && this.movimentacao.negociacao.aVista == '1' && (this.movimentacao.negociacao.diasEntre == 0 || (this.movimentacao.negociacao.diaFechamentoPadrao && this.movimentacao.negociacao.diaFechamentoPadrao == 0))) {
      this.umaParcelaAvista = true;
    }
  }

  ehNegociacaoBloqueada(): void {
    this.negociacaoBloqueada = false;
    if (this.movimentacao.negociacao) {
      if (this.movimentacao.negociacao.restricao == 1 && this.movimentacao.negociacao.permitido == 'N') {
        this.negociacaoBloqueada = true;
      }
      if (this.movimentacao.negociacao.aVista != '1' && this.movimentacao.parceiroTipoNegociacao == 'A') {
        this.negociacaoBloqueada = true;
      }
      if (this.movimentacao.negociacao.exigeAprovacao == 'S' && this.movimentacao.parceiroTipoNegociacao != 'L') {
        this.negociacaoBloqueada = true;
      }

      if (this.movimentacao.negociacao.aVista == '3' && this.movimentacao.parceiroTipoNegociacao != 'A') {
        this.negociacaoBloqueada = false;
      }
    }
  }

  limparParcelas(): void {
    while (this.movimentacao.movimentacaoParcelas.length > 0) {
      this.util.excluir(0, this.movimentacao.movimentacaoParcelas, this.excluirParcelas);
    }
  }

  verificarMovimentacaoProdutoBloqueiaLoja(): void {
    let quantidadeMovimentacaoProdutoBloqueiaLoja: number = 0;
    this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (movimentacaoProduto.idMovimentacaoProOrigem != null) {
        quantidadeMovimentacaoProdutoBloqueiaLoja++;
      }
    });
    this.lojaCampoBloqueado = quantidadeMovimentacaoProdutoBloqueiaLoja > 0;
  }

  calcularMediaDiaPrazo(): void {
    let totalDias: number = 0;
    this.movimentacao.movimentacaoParcelas.forEach((movimentacaoParcela, i) => {
      totalDias += differenceInDays(movimentacaoParcela.dataVencimento, this.movimentacao.data);
    });
    this.movimentacao.mediaDiaPrazo = 0;
    if (this.movimentacao.movimentacaoParcelas.length > 0) {
      this.movimentacao.mediaDiaPrazo = totalDias / this.movimentacao.movimentacaoParcelas.length;
      this.movimentacao.mediaDiaPrazo = Number.parseInt(this.movimentacao.mediaDiaPrazo.toString());
    }
    this.calcularLucro();
  }

  // Cabeçalho ********************************************************************************

  //refatorada
  listarNegociacao(idNegociacao: number = null): Observable<any> {
    if (this.movimentacao.idLoja && this.movimentacao.idParceiro) {
      let criterios: Criterio[] = [];
      if (this.movimentacao.status == 'N') {
        criterios.push(new Criterio('ATIVO', 'S'));
      }
      if (!idNegociacao) {
        if (this.devolucao) {
          criterios.push(new Criterio('DEVOLUCAO', '3, 4'));
        } else {
          criterios.push(new Criterio('ENTRADA_SAIDA', '-1, 4'));
        }
      }
      if (this.empresa.limitarNegociacao == 3) {
        criterios.push(new Criterio('ID_LOJA', this.movimentacao.idLoja));
      }
      const resposta = this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.negociacao);
      resposta.subscribe((res) => {
        this.negociacoes = plainToClass(Negociacao, res) as any;
        this.ehNegociacaoDevolucao();
        const idNegociacaoBusca: number = this.movimentacao.idNegociacao ? this.movimentacao.idNegociacao : idNegociacao;
        const negociacao: Negociacao = this.negociacoes.find((negociacao) => negociacao.id == idNegociacaoBusca);
        if (negociacao) {
          this.movimentacao.idNegociacao = negociacao.id;
          this.movimentacao.especie = negociacao.especie;
          this.movimentacao.negociacao = negociacao;
          this.setIdNegociacao(negociacao, false);
          this.tratarNegociacao();
          this.calcularLucro();
        }
        return resposta;
      });
    }
    return of({});
  }

  listarNaturezaOperacao(): void {
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    const ENTRADA_RECEBIMENTO_OU_NOTA_VENDA: number = 3;
    const NOTA_DE_DEVOLUCAO_DE_VENDA: number = 10;
    if ((this.identificacao == ENTRADA_RECEBIMENTO_OU_NOTA_VENDA && this.entrada) || this.identificacao == NOTA_DE_DEVOLUCAO_DE_VENDA) {
      criterios.push(new Criterio('ENTRADA_SAIDA', '1'));
    } else {
      criterios.push(new Criterio('ENTRADA_SAIDA', '-1'));
    }
    this.comunicacaoService.listar(new Transporte(), this.bibServico.empresaFilial).subscribe((res) => {
      let empresaFiliais: EmpresaFilial[] = plainToClass(EmpresaFilial, res) as any;
      let idsFiliais: number[] = empresaFiliais.filter((filial) => filial.id != null).map((filial) => filial.idParceiroFilial);
      if (this.movimentacao.idParceiro && idsFiliais.length > 0) {
        if (idsFiliais.includes(this.movimentacao.idParceiro)) {
          criterios.push(new Criterio('FINALIDADE', '2'));
        }
      }
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.naturezaOperacao).subscribe((res) => {
        this.naturezaOperacoes = plainToClass(NaturezaOperacao, res) as any;
      });
    });
  }

  listarConfiguracaoClienteEspecial(idClienteEspecial: number): void {
    if (idClienteEspecial) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', idClienteEspecial)), this.bibServico.clienteEspecial).subscribe((res) => {
        this.clienteEspecial = plainToClass(ClienteEspecial, res[0]) as any;
      });
    }
  }

  listarEnderecoParceiro(idParceiroDestino: number, parceiroEnderecoCompleto: string = null): void {
    if (idParceiroDestino > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiroDestino)), this.bibServico.parceiroDestino).subscribe((res) => {
        let enderecoParceiroDestino: ParceiroDestino = plainToClass(ParceiroDestino, res[0]) as any;
        this.idEstadoParceiro = enderecoParceiroDestino.idEstado;
        this.estadoParceiro = enderecoParceiroDestino.estado;
        if (this.usaConsumidorFinal) {
          this.movimentacao.enderecoOutro = "RETIRADA NA LOJA";
        } else {
          if (parceiroEnderecoCompleto) {
            this.movimentacao.enderecoOutro = parceiroEnderecoCompleto;
          }
        }
        if (enderecoParceiroDestino.inscricaoEstadual) {
          this.movimentacao.parceiroInscricao = enderecoParceiroDestino.inscricaoEstadual;
        }
      });
    }
  }

  listarTipoTitulos(idTipoTitulo: number): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.tipoTitulo).subscribe((res) => {
      this.tipoTitulos = plainToClass(TipoTitulo, res) as any;
      this.listarTipoTituloPermitidos(idTipoTitulo);
    });
  }

  listarTipoTituloPermitidos(idTipoTitulo: number): void {
    if (idTipoTitulo) {
      let tipoTituloNegociacao: TipoTitulo = this.tipoTitulos.find((tipoTitulo) => tipoTitulo.id == idTipoTitulo);
      if (tipoTituloNegociacao) {
        this.movimentacao.negociacao.tipoTituloIdEspecie = tipoTituloNegociacao.idEspecie;
        this.tipoTituloPermitidos = this.tipoTitulos.filter((tipoTitulo) => tipoTitulo.especieNivel <= tipoTituloNegociacao.especieNivel);
      }
    } else {
      this.tipoTituloPermitidos = this.tipoTitulos;
    }
  }

  atualizarTipoTituloParcelas(idTipoTitulo: number): void {
    if (this.movimentacao.movimentacaoParcelas.length > 0) {
      this.movimentacao.movimentacaoParcelas.forEach((movimentacaoParcela) => {
        if (movimentacaoParcela.entrada == 'N') {
          movimentacaoParcela.idTipoTitulo = idTipoTitulo ? idTipoTitulo : this.idTipoTituloParceiro;
        }
      });
    }
  }

  ehNegociacaoDevolucao(): void {
    if (!this.devolucao) {
      this.negociacoes = this.negociacoes.filter((negociacao) => negociacao.especie == 'V');
    }
  }

  ehConsumidorFinal(): void {
    const preferencia: Preferencia = this.utilSessao.getPreferencia();
    this.idConsumidorFinal = preferencia.idConsumidorFinal;
    this.usaConsumidorFinal = this.idConsumidorFinal == this.movimentacao.idParceiro;
    this.movimentacao.consumidorFinal = this.usaConsumidorFinal ? 'S' : 'N';
  }

  listarRelacionamento(idRelacionamento: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID', idRelacionamento), new Criterio('ATIVO', 'S')]), this.bibServico.relacionamento).subscribe((res) => {
      this.movimentacao.relacionamento = plainToClass(Relacionamento, res[0]) as any;
    });
  }

  listarClassificacao(): void {
    let criterios: Criterio[] = [];
    if (this.movimentacao.status == 'N') {
      criterios.push(new Criterio('ATIVO', 'S'))
    }
    criterios.push(new Criterio('ID_OPERACAO', this.movimentacao.idOperacao))
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.classificacao).subscribe((res) => {
      this.classificacoes = plainToClass(Classificacao, res) as any;
    });
  }

  listarSafras(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S')]), this.bibServico.safra).subscribe((res) => {
      this.safras = plainToClass(Safra, res) as any;
    });
  }

  listarCentroResultados(): void {
    if (this.financeiro != 'N') {
      let criterios: Criterio[] = [];
      if (this.movimentacao.status == 'N') {
        criterios.push(new Criterio('ATIVO', 'S'));
      }
      criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
      criterios.push(new Criterio('PAI', 'N'));
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.centroResultado).subscribe((res) => {
        this.centroResultados = plainToClass(CentroResultado, res) as any;
      });
    }
  }

  vincularProdutoParceiro(idParceiro: number, movimentacaoProdutos: MovimentacaoProduto[]): void {
    let idProdutos: number[] = [];
    const numeroProdutos: string[] = movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.numeroProdutoNfe);
    if (numeroProdutos.length > 0 && numeroProdutos[0] != undefined) {
      this.comunicacaoService.listar(new Transporte([new Criterio('NUMEROS', numeroProdutos.toString()), new Criterio('ID_PARCEIRO', idParceiro)]), this.bibServico.produtoParceiro).subscribe((res) => {
        const produtoParceiro: ProdutoParceiro[] = plainToClass(ProdutoParceiro, res) as any;
        if (produtoParceiro.length > 0) {
          movimentacaoProdutos.forEach((movimentacaoProduto) => {
            let produtoParceiroVincular = produtoParceiro.find((produtoParceiro) => produtoParceiro.numero == movimentacaoProduto.numeroProdutoNfe);
            if (produtoParceiroVincular.idProduto != movimentacaoProduto.idProduto) {
              idProdutos.push(produtoParceiroVincular.idProduto);
            }
          });
        }
        if (idProdutos.length > 0) {
          this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idProdutos.toString())), this.bibServico.produto).subscribe((res) => {
            let produtos: Produto[] = plainToClass(Produto, res) as any;
            movimentacaoProdutos.forEach((movimentacaoProduto) => {
              let produtoParceiroVincular = produtoParceiro.find((produtoParceiro) => produtoParceiro.numero == movimentacaoProduto.numeroProdutoNfe);
              if (produtoParceiroVincular.idProduto != movimentacaoProduto.idProduto) {
                const produto = produtos.find((produto) => produto.id == produtoParceiroVincular.idProduto);
                movimentacaoProduto.idProduto = produto.id;
                movimentacaoProduto.produto = produto.nome;
                movimentacaoProduto.produtoNome = produto.nome;
                movimentacaoProduto.custoNfe = produto.custoNfe;
                movimentacaoProduto.idUnidade = produto.idUnidade;
                movimentacaoProduto.idUnidadePadrao = produto.idUnidade;
                movimentacaoProduto.produtoNumero = produto.numero;
                movimentacaoProduto.abreviacao = produto.abreviacao;
                movimentacaoProduto.custoGerencial = produto.custoGerencial;
                movimentacaoProduto.custoReposicao = produto.custoReposicao;
                movimentacaoProduto.valorUnitarioTabela = produto.valorMinimo;
                movimentacaoProduto.idGrupo = produto.idGrupo;
                movimentacaoProduto.numeroProdutoNfe = produtoParceiroVincular.numero;
              }
            });
          });
        }
      });
    }
  }

  atualizarValorMovimentacaoProduto(): void {
    if (this.movimentacao.idOperacao == 1 || this.movimentacao.idOperacao == 2 || this.movimentacao.idOperacao == 18 || this.movimentacao.idOperacao == 20) {
      let idProdutos: number[] = this.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idProduto > 0).map((movimentacaoProduto) => movimentacaoProduto.idProduto);
      if (idProdutos.length > 0) {
        let criterios: Criterio[] = [new Criterio('IDS', idProdutos.toString()), new Criterio('ID_LOJA', this.movimentacao.idLoja)];
        this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produto).subscribe((res) => {
          const produtos: Produto[] = plainToClass(Produto, res) as any;
          this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
            const produto: Produto = produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
            movimentacaoProduto.custoNfe = produto.custoNfe;
            movimentacaoProduto.custoReposicao = produto.custoReposicao;
            movimentacaoProduto.custoGerencial = produto.custoGerencial;
            movimentacaoProduto.valorUnitarioTabela = this.saida ? produto.precoVenda : 0;
            movimentacaoProduto.setValorUnitario(this.saida ? produto.precoVenda : produto.custoReposicao, this.movimentacao.negociacao, this.movimentacao.mediaDiaPrazo, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal, this.saida, 0, this.entrada ? this.empresaRegraCasasDecimaisEntrada : this.empresaRegraCasasDecimais);
            if (this.usaDescontoUnitario) {
              movimentacaoProduto.setDescontoPercentualUnitario(movimentacaoProduto.descontoPercentual > 0 ? movimentacaoProduto.descontoPercentual : movimentacaoProduto.descontoPercentualUnitario, this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
              movimentacaoProduto.descontoPercentual = 0;
              movimentacaoProduto.descontoValor = 0;
              movimentacaoProduto.calcularTotal(this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
            } else {
              movimentacaoProduto.descontoPercentualUnitario = 0;
              movimentacaoProduto.descontoValorUnitario = 0;
              movimentacaoProduto.calcularTotal(this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
            }
          });
          this.totalizar();
        });
      }
    }
  }

  listarConfiguracaoContabil(idConfiguracaoContabieis: number[]): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('IDS_CONF_MOVIMENTACAO_CONTABIL', idConfiguracaoContabieis.toString())), this.bibServico.configuracaoMovimentacaoTotalContabil);
  }

  listarOperacaoContabil(idOperacao: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID_OPERACAO', idOperacao)), this.bibServico.empresaOperacaoContabil);
  }

  inverterValor(lista: any[], nomeCampo: string): void {
    const valor: number = lista[lista.length - 1][nomeCampo];
    lista[lista.length - 1][nomeCampo] = lista[0][nomeCampo];
    lista[0][nomeCampo] = valor;
  }

  gerarOrdenacaoProduto(): void {
    for (let i = 0; i < this.movimentacao.movimentacaoProdutos.length; i++) {
      this.movimentacao.movimentacaoProdutos[i].ordem = i + 1;
    }
  }

  setDiasEntre(diasEntre: number, autoBloqueio: boolean = false, gerenciaDiaFixo: boolean = true): void {
    this.diasEntre = diasEntre;
    this.diaFixo = null;
    this.diasEntreBloqueado = autoBloqueio;
    if (gerenciaDiaFixo) {
      this.diaFixoBloqueado = diasEntre ? true : false;
    }
  }

  setDiaFixo(diaFixo: number, autoBloqueio: boolean = false, gerenciaDiasEntre: boolean = true): void {
    this.diaFixo = diaFixo;
    this.diasEntre = null;
    this.diaFixoBloqueado = autoBloqueio;
    if (gerenciaDiasEntre) {
      this.diasEntreBloqueado = diaFixo ? true : false;
    }
    this.ignorarMesAtual = diaFixo ? this.ignorarMesAtual : false;
  }

  gerarParcela(): void {
    this.limparParcelas();
    const cartaoEspecies: number[] = [2, 10];
    const quantidadeParcelas: number = this.movimentacao.negociacao && cartaoEspecies.includes(this.movimentacao.negociacao.tipoTituloIdEspecie) ? 1 : Number(this.parcelaQuantidade);
    let quantidadeParcelaComEntrada: number = quantidadeParcelas + (this.parcelaEntrada == 'S' ? 1 : 0);
    let valorASerParcelado: number = this.movimentacao.valorTotal;
    let valorParcelado: number = 0;
    let diaIncremento: number = 0;
    for (let i = 1; i <= quantidadeParcelaComEntrada; i++) {
      let movimentacaoParcela: MovimentacaoParcela = new MovimentacaoParcela();
      movimentacaoParcela.idTipoTitulo = this.idTipoTituloNegociacao ? this.idTipoTituloNegociacao : this.idTipoTituloParceiro;
      movimentacaoParcela.setValorParcela(this.util.arredondar(valorASerParcelado / Number(this.parcelaQuantidade)));
      if (i == 1 && this.parcelaEntrada == 'S') {
        movimentacaoParcela = this.gerarEntrada(movimentacaoParcela);
        valorASerParcelado -= this.valorEntrada;
        movimentacaoParcela.entrada = 'S';
      } else {
        diaIncremento = this.incrementarDias(diaIncremento);
        valorParcelado += movimentacaoParcela.getValorParcela();
      }
      let dataVencimento: Date = addDays(this.movimentacao.data, diaIncremento);
      this.cartaoAVista = (this.movimentacao?.negociacao?.aVista != null && this.movimentacao?.negociacao?.aVista == '1' && (this.movimentacao.negociacao?.tipoTituloIdEspecie != null && cartaoEspecies.includes(this.movimentacao.negociacao.tipoTituloIdEspecie)));
      movimentacaoParcela.incrementouDomingo = dataVencimento != this.incrementarDomingo(dataVencimento);
      dataVencimento = this.cartaoAVista ? new Date() : dataVencimento;
      movimentacaoParcela.dataVencimento = this.incrementarDomingo(dataVencimento);
      movimentacaoParcela.setValorBloqueado(0);
      this.movimentacao.movimentacaoParcelas.push(movimentacaoParcela);
    }
    this.ajustarDiferencaArredondamento(valorASerParcelado, valorParcelado);
    this.recalcular(); /* Refatorar */
    this.calcularMediaDiaPrazo();
    this.ehInserirQuantidadeParcelas();
  }

  gerarEntrada(movimentacaoParcela: MovimentacaoParcela): MovimentacaoParcela {
    movimentacaoParcela.setValorParcela(this.valorEntrada);
    if (this.idTipoTituloEntrada) {
      movimentacaoParcela.idTipoTitulo = this.idTipoTituloEntrada;
    }
    return movimentacaoParcela;
  }

  incrementarDias(diaIncremento: number): number {
    if (this.umaParcelaAvista == false) {
      if (Number(this.diasEntre) > 0) {
        return (diaIncremento += this.util.coalesce(Number(this.diasEntre), 0));
      } else {
        if (Number(this.diaFixo) > 0) {
          return this.analisarDiaFixo(diaIncremento);
        } else {
          switch (this.movimentacao.parceiroTipoNegociacao) {
            case 'S':
              return this.incrementarSemanal(diaIncremento) + this.util.coalesce(this.movimentacao.parceiroPrazoFixo, 0);
            case 'Q':
              return this.incrementarQuinzenal(diaIncremento) + this.util.coalesce(this.movimentacao.parceiroPrazoFixo, 0);
            case 'M':
              return this.incrementarFimMes(diaIncremento);
          }
        }
      }
    }
    return 0;
  }

  analisarDiaFixo(diaIncremento: number): number {
    if (Number(this.diaFixo) >= 29) {
      return this.incrementarFimMes(diaIncremento, Number(this.diaFixo));
    } else {
      return this.incrementarDiaFixo(diaIncremento);
    }
  }

  incrementarDiaFixo(diaIncremento: number): number {
    let data = addDays(this.movimentacao.data, diaIncremento);
    data = setDate(data, Number(this.diaFixo));
    if (this.movimentacao.data.getDate() >= Number(this.diaFixo) || diaIncremento > 0 || this.ignorarMesAtual) {
      data = addMonths(data, 1);
    }
    return differenceInDays(data, this.movimentacao.data);
  }

  incrementarFimMes(diaIncremento: number, diaLimite: number = 0): number {
    let data = addDays(this.movimentacao.data, diaIncremento);
    if (this.ignorarMesAtual || this.movimentacao.movimentacaoParcelas.length > 0) {
      data = addMonths(data, 1);
    }
    let dataUltimaDiaMes: Date = lastDayOfMonth(data);
    if (diaLimite > 0) {
      dataUltimaDiaMes = this.tratarDiaLimite(dataUltimaDiaMes, diaLimite);
    }
    return differenceInDays(dataUltimaDiaMes, this.movimentacao.data) + 1;
  }

  tratarDiaLimite(dataUltimaDiaMes: Date, diaLimite: number): Date {
    const dia: number = dataUltimaDiaMes.getDate();
    if (diaLimite > 0 && dia > diaLimite) {
      const diferenca: number = diaLimite - dia;
      return addDays(dataUltimaDiaMes, diferenca);
    } else {
      return dataUltimaDiaMes;
    }
  }

  incrementarMensal(diaIncremento: number): number {
    let data = addDays(this.movimentacao.data, diaIncremento);
    data = addMonths(data, 1);
    return differenceInDays(data, this.movimentacao.data);
  }

  incrementarSemanal(diaIncremento: number): number {
    if (diaIncremento == 0) {
      diaIncremento = 7;
    }
    let data = addDays(this.movimentacao.data, diaIncremento);
    const sexta: number = 5;
    if (data.getDay() >= sexta) {
      data = addWeeks(data, 1);
    }
    const dataUltimoSabadoSemana: Date = lastDayOfWeek(data);
    const dataUltimaSextaSemana: Date = addDays(dataUltimoSabadoSemana, -1);
    return differenceInDays(dataUltimaSextaSemana, this.movimentacao.data);
  }

  incrementarQuinzenal(diaIncremento: number): number {
    let data = addDays(this.movimentacao.data, diaIncremento + 1);
    const diaAtual: number = data.getDate();
    if (data.getDate() < 15 || data.getDate() >= 30) {
      data = setDate(data, 15);
      if (diaAtual >= 30) {
        data = addMonths(data, 1);
      }
    } else {
      data = this.incrementarQuinzenalFevereiro(data);
    }
    return differenceInDays(data, this.movimentacao.data);
  }

  incrementarQuinzenalFevereiro(data: Date): Date {
    const fevereiro: number = 1;
    if (data.getMonth() == fevereiro) {
      if (isLastDayOfMonth(data)) {
        data = setDate(data, 15);
        data = addMonths(data, 1);
      } else {
        data = lastDayOfMonth(data);
      }
    } else {
      data = setDate(data, 30);
    }
    return data;
  }

  incrementarDomingo(dataVencimento: Date): Date {
    const domingo: number = 0;
    if (dataVencimento.getDay() == domingo) {
      dataVencimento = addDays(dataVencimento, 1);
    }
    return dataVencimento;
  }

  ajustarDiferencaArredondamento(valorASerParcelado: number, valorParcelado: number): void {
    const posicaoParcelaASerAjustada: number = this.parcelaEntrada == 'S' ? 1 : 0;
    const diferenca: number = valorASerParcelado - valorParcelado;
    if (diferenca != 0) {
      let valorParcela: number = this.movimentacao.movimentacaoParcelas[posicaoParcelaASerAjustada].getValorParcela();
      valorParcela += diferenca;
      this.movimentacao.movimentacaoParcelas[posicaoParcelaASerAjustada].setValorParcela(valorParcela);
    }
  }

  bloquearDiasEntreOuDiaFixo(): void {
    this.setDiasEntre(null);
    this.setDiaFixo(null);
    this.bloquearDiasEntreOuDiaFixoNegociacao();
  }

  bloquearDiasEntreOuDiaFixoNegociacao(): void {
    const cartaoEspecies: number[] = [2, 10];
    if (this.movimentacao.negociacao && this.movimentacao.negociacao.tipoTituloIdEspecie && cartaoEspecies.includes(this.movimentacao.negociacao.tipoTituloIdEspecie)) {
      this.setDiasEntre(null, false, false);
      this.setDiaFixo(null, true, false);
    } else {
      if (this.movimentacao.negociacao?.diasEntre > 0) {
        const bloquerDiasEntre: boolean = this.movimentacao.negociacao.permitirAlterarDiasEntre == 'S' ? false : true;
        this.setDiasEntre(this.movimentacao.negociacao.diasEntre, bloquerDiasEntre);
      } else {
        if (this.movimentacao.negociacao?.diaFechamentoPadrao > 0) {
          this.setDiaFixo(this.movimentacao.negociacao.diaFechamentoPadrao, true);
        } else {
          this.bloquearDiasEntreOuDiaFixoParceiro();
        }
      }
    }
  }

  bloquearDiasEntreOuDiaFixoParceiro(): void {
    const prazoFixo: string = 'P';
    if (this.movimentacao.parceiroTipoNegociacao == prazoFixo) {
      this.setDiasEntre(this.movimentacao.parceiroPrazoFixo, true);
    } else {
      const diaFixo: string = 'D';
      if (this.movimentacao.parceiroTipoNegociacao == diaFixo) {
        this.setDiaFixo(this.movimentacao.parceiroDiaFechamento, true);
      } else {
        const SemanalOuQuinzenalOuMensal: string[] = ['S', 'Q', 'M'];
        if (SemanalOuQuinzenalOuMensal.includes(this.movimentacao.parceiroTipoNegociacao)) {
          this.setDiasEntre(null, true, false);
          this.setDiaFixo(null, true, false);
        }
      }
    }
  }

  setNomeTipoTituloParceiro(idTipoTitulo: number): void {
    this.movimentacao.idTipoTituloParceiro = idTipoTitulo;
    if (idTipoTitulo != null) {
      this.comunicacaoService.listar(new Transporte([new Criterio('ID', idTipoTitulo)]), this.bibServico.tipoTitulo).subscribe((res) => {
        const tipoTitulo: TipoTitulo = plainToClass(TipoTitulo, res[0]) as any;
        this.movimentacao.tipoTituloParceiro = tipoTitulo.nome;
        this.atualizarTituloParcelamento();
      });
    }
  }

  setMovimentacaoProdutoValorUnitatioFinal(movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.valorTotal > 0) {
      this.movimentacaoProdutoValorUnitarioFinal = movimentacaoProduto.valorTotal / movimentacaoProduto.getQuantidade();
    }
  }

  listarLojas(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.loja).subscribe((res) => {
      this.lojas = plainToClass(Loja, res) as any;
    });
  }

  recalcular(): void {
    if (this.movimentacao.movimentacaoParcelas.length > 0 && this.movimentacao.valorTotal > 0) {
      this.recalcularProporcionalParcela(this.totalizarParcelas());
      this.calcularDiferencaParcela(this.totalizarParcelas(), this.totalizarBloqueadoParcelas());
      this.calcularDiferencaParcelaTotal();
      this.calcularLucro();
    } else if (this.movimentacao.valorTotal == 0) {
      this.limparParcelas();
    }
  }

  recalcularProporcionalParcela(parcelaTotal: number): void {
    for (const movimentacaoParcela of this.movimentacao.movimentacaoParcelas) {
      let proporcionalParcela: number = movimentacaoParcela.getValorParcela() / parcelaTotal;
      let novoValorParcela: number = this.util.arredondar(this.movimentacao.valorTotal * proporcionalParcela);
      movimentacaoParcela.setValorParcela(novoValorParcela);
      movimentacaoParcela.setValorBloqueado(((this.movimentacao.valorTotal / this.movimentacao.movimentacaoParcelas.length) * this.movimentacao.valorBloqueado) / this.movimentacao.valorTotal);
    }
  }

  calcularDiferencaParcela(parcelaTotalNovo: number, parcelaTotalBloqueadoNovo: number): void {
    if (parcelaTotalNovo != this.movimentacao.valorTotal || parcelaTotalBloqueadoNovo != this.movimentacao.valorBloqueado) {
      const diferenca: number = this.movimentacao.valorTotal - parcelaTotalNovo;
      const diferencaBloqueado: number = this.movimentacao.valorBloqueado - parcelaTotalBloqueadoNovo;
      const posicao: number = diferenca < 0 || diferencaBloqueado < 0 ? this.movimentacao.movimentacaoParcelas.length - 1 : 0;
      let parcelaDiferenca: MovimentacaoParcela = this.movimentacao.movimentacaoParcelas[posicao];
      parcelaDiferenca.setValorParcela(parcelaDiferenca.getValorParcela() + diferenca);
      parcelaDiferenca.setValorBloqueado(parcelaDiferenca.getValorBloqueado() + diferencaBloqueado);
    }
  }

  listarEmpresaOperacaoBloqueio(idEmpresa: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID_EMPRESA', idEmpresa)), this.bibServico.empresaOperacaoBloqueio);
  }

  ehValidoNomeConsumidorFinal(): boolean {
    if (this.usaConsumidorFinal) {
      if (this.movimentacao.nomeParceiro.length < 3 || this.movimentacao.nomeParceiro.indexOf('+') != -1 || this.movimentacao.nomeParceiro.indexOf('%') != -1) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.preenchaNomeConsumidorFinal));
        return false;
      }
    }
    return true;
  }

  listarUnidadeProduto(movimentacaoProduto: MovimentacaoProduto, produto: Produto): void {
    if (movimentacaoProduto.idProduto) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)), this.bibServico.produtoUnidade).subscribe((res) => {
        let produtoUnidades: ProdutoUnidade[] = plainToClass(ProdutoUnidade, res) as any;
        if (produto == null) {
          produto = plainToClass(Produto, this.listarProduto(movimentacaoProduto.idProduto));
        }
        if (produtoUnidades.length > 0) {
          produtoUnidades.forEach((produtoUnidade) => (produtoUnidade.nome = produtoUnidade.unidadeAbreviacao));
          produtoUnidades.push(this.adicionarUnidadeProduto(produto));
          movimentacaoProduto.produtoUnidades = produtoUnidades;
          this.vincularUnidadeParceiro(movimentacaoProduto);
        }
      });
    }
  }

  vincularUnidadeParceiro(movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.importacaoXml == 'S') {
      this.comunicacaoService.listar(new Transporte([new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto), new Criterio('ID_PARCEIRO', this.movimentacao.idParceiro)]), bibServico.unidadeParceiro).subscribe((res) => {
        let unidadesParceiros: UnidadeParceiro[] = plainToClass(UnidadeParceiro, res) as any;
        unidadesParceiros.forEach((unidadeParceiro) => {
          if (movimentacaoProduto.unidadeXml == unidadeParceiro.abreviacao) {
            movimentacaoProduto.abreviacao = unidadeParceiro.abreviacao;
            movimentacaoProduto.idUnidade = unidadeParceiro.idUnidade;
            movimentacaoProduto.produtoUnidades.forEach((produtoUnidade) => {
              if (produtoUnidade.unidadeAbreviacao == movimentacaoProduto.abreviacao) {
                produtoUnidade.sugerirUnidade = 'S';
              } else {
                produtoUnidade.sugerirUnidade = 'N';
              }
            });
          }
        });
      });
    }
  }

  adicionarUnidadeProduto(produto: Produto, sugerirUnidade: string = 'S'): any {
    const produtoUnidade: any = new ProdutoUnidade();
    produtoUnidade.id = produto.idUnidade;
    produtoUnidade.idUnidade = produto.idUnidadeAlternativa;
    produtoUnidade.unidadeAbreviacao = produto.abreviacao;
    produtoUnidade.idProduto = produto.id;
    produtoUnidade.nome = produto.unidade;
    produtoUnidade.sugerirUnidade = sugerirUnidade;
    return produtoUnidade;
  }

  listarProduto(idProduto: number): Observable<any> {
    const criterios: Criterio[] = [new Criterio('ID', idProduto), new Criterio('ID_LOJA', this.movimentacao.idLoja)];
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produto);
  }

  ehListarParceiroDestino(): Observable<any> {
    if (this.movimentacao.idParceiroDestino) {
      let ehListaParceiroDestino: Boolean = true;
      if (this.parceiroDestino) {
        ehListaParceiroDestino = false;
        if (this.parceiroDestino.id != this.movimentacao.idParceiroDestino) {
          ehListaParceiroDestino = true;
        }
      }
      if (ehListaParceiroDestino) {
        return this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.movimentacao.idParceiroDestino)), this.bibServico.parceiroDestino);
      }
    } else {
      this.parceiroDestino = null;
    }
    return of([this.parceiroDestino]);
  }

  ehListarParceiroEndereco(): Observable<any> {
    if (!this.parceiroDestino) {
      let ehListaParceiroEndereco: Boolean = true;
      if (this.parceiroEndereco) {
        ehListaParceiroEndereco = false;
        if (this.parceiroEndereco.idParceiro != this.movimentacao.idParceiro) {
          ehListaParceiroEndereco = true;
        }
      }
      if (ehListaParceiroEndereco && this.movimentacao.idParceiro) {
        let criterios: Criterio[] = [];
        criterios.push(new Criterio('ID_PARCEIRO', this.movimentacao.idParceiro));
        criterios.push(new Criterio('ID_VINCULO', 3));
        return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroEndereco);
      }
    } else {
      this.parceiroEndereco = null;
    }
    return of([this.parceiroEndereco]);
  }

  ehPreencherParceiroEndereco(parceiroEnderecoPrincipal: ParceiroEndereco): void {
    if (parceiroEnderecoPrincipal && parceiroEnderecoPrincipal != undefined) {
      this.parceiroEndereco = parceiroEnderecoPrincipal;
    } else {
      this.parceiroEndereco = null;
    }
  }

  gerarReceituario(): void {
    this.mostrarAgronomo = this.movimentacao.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.cultura != undefined).length > 0;
  }

  ehInserirQuantidadeParcelas(): void {
    if (this.movimentacao.negociacao && this.movimentacao.negociacao.parcelaFixo) {
      this.movimentacao.parcelamento = this.movimentacao.negociacao.parcelaFixo;
    } else {
      const cartaoEspecies: number[] = [2, 10];
      if (this.negociacoes && this.movimentacao.negociacao && cartaoEspecies.includes(this.movimentacao.negociacao.tipoTituloIdEspecie)) {
        this.movimentacao.parcelamento = this.parcelaQuantidade;
      }
    }
  }

  ehListarLote(indice: number, movimentacaoProduto: MovimentacaoProduto, idProduto: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('ID_PRODUTO', idProduto)]), this.bibServico.lote).subscribe((res) => {
      const lotes: Lote[] = this.plainToClass(Lote, res) as any;
      this.ordenarPorNome(lotes, idProduto);
      movimentacaoProduto.lotes = this.lotes;
      if (movimentacaoProduto.estoques.length > 0 && (this.saida || (this.interna && (this.estoqueFisico == -1 || this.estoqueNFe == -1)))) {
        this.filtrarLotesDisponiveis(indice, movimentacaoProduto);
      }
      this.atualizarNomeLote();
    });
  }


  ehListarLocal(indice: number, movimentacaoProduto: MovimentacaoProduto): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('PAI', 'N')), this.bibServico.local).subscribe((res) => {
      this.locais = this.plainToClass(Local, res) as any;
      movimentacaoProduto.locais = this.locais;
      if (movimentacaoProduto.estoques.length > 0 && (this.saida || (this.interna && (this.estoqueFisico == -1 || this.estoqueNFe == -1)))) {
        this.filtrarLocaisDisponiveis(indice, movimentacaoProduto);
      }
    });
  }

  ordenarPorNome(lotes: Lote[], idProduto: number) {
    lotes = lotes.sort((a, b) => (a['nome'] == b['nome'] ? 0 : a['nome'] > b['nome'] ? 1 : -1));
    this.lotes = [];
    this.popularLotesVinculados(lotes, idProduto);
  }

  popularLotesVinculados(lotes: Lote[], idProduto: number) {
    const lotesVinculados = lotes.filter((lote) => lote.idProduto == idProduto);
    for (const lotesVinculado of lotesVinculados) {
      this.lotes.push(lotesVinculado);
    }
    this.popularNaoLotesVinculados(lotes, lotesVinculados);
  }

  popularNaoLotesVinculados(lotes: Lote[], lotesVinculados: Lote[]) {
    const lotesNaoVinculados = lotes.filter((lote) => lote.idProduto == null);
    if (lotesNaoVinculados.length > 0 && lotesVinculados.length > 0) {
      const lote: Lote = new Lote();
      lote.id = 0;
      lote.nome = '';
      this.lotes.push(lote);
    }
    for (const loteNaoVinculado of lotesNaoVinculados) {
      this.lotes.push(loteNaoVinculado);
    }
  }

  filtrarLotesDisponiveis(indice: number, movimentacaoProduto: MovimentacaoProduto) {
    if (this.movimentacao.status != 'S') {
      let idsEstoquesComLote: number[] = [];
      if (!this.devolucao) {
        if (this.nota) {
          idsEstoquesComLote = movimentacaoProduto.estoques
            .filter((estoque) => estoque.quantidadeNfe > 0)
            .filter((estoque) => estoque.idProduto == movimentacaoProduto.idProduto)
            .map((estoque) => estoque.idLote);
        } else {
          if (this.interna) {
            idsEstoquesComLote = movimentacaoProduto.estoques
              .filter((estoque) => estoque.quantidadeDisponivel > 0 || estoque.quantidadeNfe > 0)
              .filter((estoque) => estoque.idProduto == movimentacaoProduto.idProduto)
              .map((estoque) => estoque.idLote);
          } else {
            idsEstoquesComLote = movimentacaoProduto.estoques
              .filter((estoque) => estoque.quantidadeDisponivel > 0)
              .filter((estoque) => estoque.idProduto == movimentacaoProduto.idProduto)
              .map((estoque) => estoque.idLote);
          }
        }
        this.lotes = this.lotes.filter((lote) => idsEstoquesComLote.includes(lote.id));
        movimentacaoProduto.lotes = this.lotes;
        if (this.lotes.length == 1 && movimentacaoProduto.movimentacaoQuantidades && movimentacaoProduto.movimentacaoQuantidades.length == 1) {
          movimentacaoProduto.movimentacaoQuantidades[0].idLote = this.lotes[0].id;
        }
      }
    }
  }

  filtrarLocaisDisponiveis(indice: number, movimentacaoProduto: MovimentacaoProduto) {
    if (this.movimentacao.status != 'S') {
      let idsEstoquesComLocal: number[] = [];
      if (!this.devolucao) {
        if (this.nota) {
          idsEstoquesComLocal = movimentacaoProduto.estoques
            .filter((estoque) => estoque.quantidadeNfe > 0)
            .filter((estoque) => estoque.idProduto == movimentacaoProduto.idProduto)
            .map((estoque) => estoque.idLocal);
        } else {
          if (this.interna) {
            idsEstoquesComLocal = movimentacaoProduto.estoques
              .filter((estoque) => estoque.quantidadeDisponivel > 0 || estoque.quantidadeNfe > 0)
              .filter((estoque) => estoque.idProduto == movimentacaoProduto.idProduto)
              .map((estoque) => estoque.idLocal);
          } else {
            idsEstoquesComLocal = movimentacaoProduto.estoques
              .filter((estoque) => estoque.quantidadeDisponivel > 0)
              .filter((estoque) => estoque.idProduto == movimentacaoProduto.idProduto)
              .map((estoque) => estoque.idLocal);
          }
        }
        this.locais = this.locais.filter((local) => idsEstoquesComLocal.includes(local.id));
        movimentacaoProduto.locais = this.locais;
        if (this.locais.length == 1 && movimentacaoProduto.movimentacaoQuantidades && movimentacaoProduto.movimentacaoQuantidades.length == 1) {
          movimentacaoProduto.movimentacaoQuantidades[0].idLocal = this.locais[0].id;
        }
      }
    }
  }

  atualizarNomeLote() {
    this.lotes.forEach((lote) => (lote.nome = lote.nome + (lote.dataValidade ? ' - ' + this.util.formatarDataBoleto(lote.dataValidade) : '')));
  }

  listarPersistirLoteXml(movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.idProduto && movimentacaoProduto.loteXml) {
      this.comunicacaoService.listar(new Transporte([new Criterio('NOME', movimentacaoProduto.loteXml.nome), new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)]), this.bibServico.lote).subscribe((res) => {
        const lote: Lote = this.plainToClass(Lote, res[0]) as any;
        if (lote) {
          this.atualizarListaLote(movimentacaoProduto, lote.id);
        } else {
          this.persistirLoteXml(movimentacaoProduto, movimentacaoProduto.loteXml);
        }
      });
    }
  }

  persistirLoteXml(movimentacaoProduto: MovimentacaoProduto, lote: Lote) {
    if (movimentacaoProduto.idProduto) {
      lote.idProduto = movimentacaoProduto.idProduto;
      const transporte: Transporte = new Transporte(lote);
      const ID_MENU_LOTE: number = 60;
      transporte.idMenu = ID_MENU_LOTE;
      this.comunicacaoService.persistir(transporte, this.bibServico.lote).pipe(share()).subscribe((res) => {
        if (res && res[0] && res[0].id && res[0].id > 0) {
          this.atualizarListaLote(movimentacaoProduto, res[0].id);
        }
      });
    }
  }

  atualizarListaLote(movimentacaoProduto: MovimentacaoProduto, idLote: number): void {
    movimentacaoProduto.loteXml.id = idLote;
    movimentacaoProduto.lotes = [movimentacaoProduto.loteXml];
    movimentacaoProduto.movimentacaoQuantidades[0].idLote = movimentacaoProduto.loteXml.id;
  }
}
