import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Resultado } from 'src/app/modelo/resultado';
import { Cte } from 'src/app/modelo/cte';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Cidade } from 'src/app/modelo/cidade';
import { Observable } from 'rxjs';
import { Loja } from 'src/app/modelo/loja';
import { ParceiroVeiculo } from 'src/app/modelo/parceiroVeiculo';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Estado } from 'src/app/modelo/estados';
import { Cst } from 'src/app/modelo/cst';
import { Util } from 'src/app/utilitario/util';
import { Cfop } from 'src/app/modelo/cfop';
import { CteInformacaoCarga } from 'src/app/modelo/cteInformacaoCarga';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Utilitario } from 'src/app/modelo/utilitario';
import { CteDocumentoFiscal } from 'src/app/modelo/cteDocumentoFiscal';

@Component({
  templateUrl: './cteFrm.component.html',
})
export class CteFrmComponent extends PaginaComponent {
  public cte: Cte = new Cte();
  public parceiroVeiculos: ParceiroVeiculo[];
  public bloqueado: boolean = false;
  public visualizaLoja: boolean = false;
  public estados: Estado[];
  public icmsCsts: Cst[] = [];
  public menuTransportadora: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 55);
  public excluirCteInformacaoCargas: number[] = [];
  public edicaoCteInformacaoCargas: number[] = [];
  public excluirCteDocumentoFiscais: number[] = [];
  public edicaoCteDocumentoFiscais: number[] = [];
  public cfopsCte: CfopEmpresa[] = [];
  public utilitarios: Utilitario[] = [];

  public listaTipoFrete: any[] = [
    { id: '0', nome: this.bibDialogo.transporteTipoFreteCteRemetente },
    { id: '1', nome: this.bibDialogo.transporteTipoFreteCteExpedidor },
    { id: '2', nome: this.bibDialogo.transporteTipoFreteCteRecebedor },
    { id: '3', nome: this.bibDialogo.transporteTipoFreteCteDestinatario },
    { id: '4', nome: this.bibDialogo.transporteTipoFreteCteOutros },
  ];

  ngOnInit(): void {
    this.listarEstados();
    this.listarCst();
    this.ehAlteracao();
    this.mostrarOcultarLoja();
    this.ehBloqueado();
    this.listarCfopEmpresaCte();
    this.utilitarios.push(new Utilitario(this.bibIcone.arquivo, this.bibDialogo.cte, null, true));
  }

  listarEstados(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  listarCfopEmpresaCte(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cfopEmpresa).subscribe((res) => {
      const cfopsEmpresa: CfopEmpresa[] = this.plainToClass(Cfop, res) as any;
      this.cfopsCte = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopEntradaSaida == 'S');
    });
  }

  listarCst(): void {
    if (this.cte.idLoja) {
      const simplesNacional: string = this.utilSessao.getLojas().find((loja) => loja.id == this.cte?.idLoja).enquadramento == 1 ? 'S' : 'N';
      let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
      criterios.push(new Criterio('SIMPLES_NACIONAL', simplesNacional));
      if (this.cte?.idCstIcms) {
        criterios.push(new Criterio('ID_SELECIONADO', this.cte.idCstIcms));
      }
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cstIcms).subscribe((res) => {
        this.icmsCsts = this.plainToClass(Cst, res) as any;
        if (this.cte?.idCstIcms) {
          this.setCstIcms(this.icmsCsts.find((icmsCst) => icmsCst.id == this.cte?.idCstIcms));
        }
      });
    }
  }

  mostrarOcultarLoja() {
    if (this.utilSessao.getLojas().length > 1) {
      this.visualizaLoja = true;
    }
  }

  ehAlteracao(): void {
    this.cte = this.plainToClass(Cte, this.ehAtualizacao(this.cte));
    if (this.cte.id) {
      this.listarCte(this.cte.id);
    } else {
      this.cte.ativo = 'S';
      this.cte.data = new Date();
      this.cte.situacao = 1;
      this.cte.status = 'N';
      this.definirLoja();
      this.ehBloqueado();
    }
  }

  definirLoja(): void {
    if (this.utilSessao.getLojas().length == 1) {
      this.setLoja(this.utilSessao.getLojas()[0]);
    }
  }

  listarCte(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.cte).subscribe((res) => {
      this.cte = this.plainToClass(Cte, res[0]) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_CTE', id)), this.bibServico.cteInformacaoCarga).subscribe((res) => {
        this.cte.cteInformacaoCargas = this.plainToClass(CteInformacaoCarga, res);
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_CTE', id)), this.bibServico.cteDocumentoFiscal).subscribe((res) => {
          this.cte.cteDocumentoFiscais = this.plainToClass(CteDocumentoFiscal, res);
          this.listarCst();
          this.listarParceirosVeiculos();
          this.ehBloqueado();
        });
      });
    });
  }

  persistirCte(): void {
    if (this.ehValido()) {
      const transporte: Transporte = new Transporte(this.cte);
      transporte.adicionar(this.excluirCteInformacaoCargas);
      transporte.adicionar(this.excluirCteDocumentoFiscais);
      super.persistir(transporte, this.bibServico.cte, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.cte], this.bibClasse.cte, true);
          this.definirId(this.cte.cteDocumentoFiscais, this.bibClasse.cteDocumentoFiscal);
          this.definirId(this.cte.cteInformacaoCargas, this.bibClasse.cteInformacaoCarga);
          this.excluirCteInformacaoCargas = [];
          this.excluirCteDocumentoFiscais = [];
        } else {
          this.cte.status = 'N';
        }
      });
      this.ehBloqueado();
    }
  }

  finalizar(): void {
    this.cte.status = 'S';
    this.persistirCte();
  }

  setEhValidoCpf(): boolean {
    if (this.util.validarCnpjCpf(this.cte.motoristaCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cpfIncorreto));
    return false;
  }

  ehValidoInformacoesCarga(): boolean {
    if (this.cte.cteInformacaoCargas.length > 0 || this.cte.status == 'N ') {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cteInformacoesCargaVazio));
    return false;
  }

  ehValidoDocumentosFiscais(): boolean {
    if (this.cte.cteDocumentoFiscais.length > 0 || this.cte.status == 'N ') {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cteDocumentoFiscalVazio));
    return false;
  }

  setEhValorMaiorZero(): boolean {
    if (this.cte.valor > 0 && this.cte.valorCarga > 0) {
      return true;
    }
    if (!this.cte.valor || this.cte.valor == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorDeveSerMaiorQueZero));
    }
    if (!this.cte.valorCarga || this.cte.valorCarga == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorCargaDeveSerMaiorQueZero));
    }
    return false;
  }

  ehValidoChave() {
    let contagemChaveQuantidadeCaracteres: number = 0;
    let contagemChaveEspacosBranco: number = 0;
    if (this.cte.cteDocumentoFiscais.length > 0) {
      this.cte.cteDocumentoFiscais.forEach(cteDocumentoFiscal => {
        if (cteDocumentoFiscal.chave.length != 44) {
          contagemChaveQuantidadeCaracteres += 1;
        }
        if (cteDocumentoFiscal.chave && cteDocumentoFiscal.chave.includes(' ')) {
          contagemChaveEspacosBranco += 1;
        }
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.documentoFiscalObrigatorio));
      return false;
    }
    if (contagemChaveEspacosBranco > 0 || contagemChaveQuantidadeCaracteres > 0) {
      if (contagemChaveEspacosBranco > 0) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.chaveNaoPodeConterEspacoMinimoCaracteres));
      }
      if (contagemChaveQuantidadeCaracteres > 0) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.chaveMinimoCaracteres));
      }
      return false;
    }
    return true;
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoInformacoesCarga: boolean = this.ehValidoInformacoesCarga();
    const ehValidoDOcumentosFiscais: boolean = this.ehValidoDocumentosFiscais();
    const ehValorMaiorZero: boolean = this.setEhValorMaiorZero();
    return ehValidoObrigatorio && ehValidoInformacoesCarga && ehValidoDOcumentosFiscais && ehValorMaiorZero;
  }

  ehValidoObrigatorio(): boolean {
    if (this.cte.idLoja && this.cte.idCfopEmpresa && this.cte.idParceiroDestinatario && this.cte.idParceiroRemetente && this.cte.idCidadeInicial && this.cte.idCidadeFinal && this.cte.idCstIcms && this.cte.idVeiculo && this.cte.data && this.cte.tipoFrete && this.cte.produtoPredominante && this.cte.rntrc) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  listarEnderecoPrincipalParceiro(idParceiro: number): Observable<ParceiroEndereco> {
    const idEnderecoPrincipal: string = '3';
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_PARCEIRO', idParceiro));
    criterios.push(new Criterio('ID_VINCULO', idEnderecoPrincipal));
    return new Observable(observer => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroEndereco)
        .subscribe(
          (res) => {
            const parceiroEndereco = this.plainToClass(ParceiroEndereco, res[0]) as ParceiroEndereco;
            observer.next(parceiroEndereco);
            observer.complete();
          });
    });
  }

  setParceiroRemetente(parceiro: Parceiro): void {
    this.cte.idParceiroRemetente = parceiro.id;
    this.cte.parceiroRemetente = parceiro.razaoSocial;
    this.listarEnderecoPrincipalParceiro(parceiro.id).subscribe((parceiroEndereco) => {
      if (parceiroEndereco != undefined) {
        this.cte.idCidadeInicial = parceiroEndereco.idCidade;
        this.cte.cidadeInicial = parceiroEndereco.cidade;
      }
    });
  }

  setCidadeInicial(cidade: Cidade) {
    this.cte.idCidadeInicial = cidade.id;
    this.cte.cidadeInicial = cidade.nome + ' - ' + cidade.estado;
  }

  setParceiroDestinatario(parceiro: Parceiro): void {
    this.cte.idParceiroDestinatario = parceiro.id;
    this.cte.parceiroDestinatario = parceiro.razaoSocial;
    this.listarEnderecoPrincipalParceiro(parceiro.id).subscribe((parceiroEndereco) => {
      if (parceiroEndereco != undefined) {
        this.cte.idCidadeFinal = parceiroEndereco.idCidade;
        this.cte.cidadeFinal = parceiroEndereco.cidade;
      }
    });
  }

  setCidadeFinal(cidade: Cidade) {
    this.cte.idCidadeFinal = cidade.id;
    this.cte.cidadeFinal = cidade.nome + ' - ' + cidade.estado;
  }

  setLoja(loja: Loja): void {
    this.cte.idLoja = loja.id;
    this.cte.loja = loja.razaoSocial;
    this.cte.serie = loja.cteSerie;
    this.listarCst();
  }

  setCfop(cfop: Cfop): void {
    this.cte.idCfopEmpresa = cfop.id;
    this.cte.cfop = cfop.nome;
  }

  listarParceirosVeiculos(): void {
    this.parceiroVeiculos = [];
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('ID_PARCEIRO', this.cte?.idParceiroTransportadora));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroVeiculo).subscribe((res) => {
      this.parceiroVeiculos = this.plainToClass(ParceiroVeiculo, res) as any;
      this.setVeiculo(this.parceiroVeiculos.find((parceiroVeiculo) => parceiroVeiculo.id == this.cte?.idVeiculo));
    });
  }

  setTransportadora(parceiro: Parceiro): void {
    this.cte.idParceiroTransportadora = parceiro.id;
    this.cte.parceiroTransportadora = parceiro.razaoSocial;
    if (this.cte.status == 'N') {
      this.cte.motoristaCpf = null;
      this.cte.motoristaNome = null;
      this.cte.placa = null;
      this.cte.placaUf = null;
      this.cte.idVeiculo = null;
      this.cte.veiculo = null;
      this.cte.rntrc = null;
    }
    this.listarParceirosVeiculos();
  }

  setEstado(idEstado: number) {
    this.estados.forEach((estado) => {
      if (estado.id == idEstado) {
        this.cte.placaUf = estado.nome;
      }
    });
  }

  setVeiculo(parceiroVeiculo: ParceiroVeiculo): void {
    this.cte.idVeiculo = parceiroVeiculo.id;
    this.cte.veiculo = parceiroVeiculo.nome;
    if (this.cte.status == 'N') {
      this.cte.motoristaNome = parceiroVeiculo.motoristaNome;
      this.cte.motoristaCpf = parceiroVeiculo.motoristaCpf;
      this.cte.placa = parceiroVeiculo.placa;
      this.cte.rntrc = parceiroVeiculo.rntrc;
      this.setEstado(parceiroVeiculo.idEstado);
    }
  }

  setCpf(cte: Cte, valor: string): void {
    cte.motoristaCpf = valor == '' ? null : valor;
    cte.ehValidoCpf = this.util.validarCnpjCpf(cte.motoristaCpf);
  }

  setIcmsBase(cte: Cte, valor: number): void {
    cte.icmsBase = new Util().arredondar(valor);
    cte.icmsValor = new Util().arredondar((cte.icmsBase / 100) * cte.icmsAliquota);
  }

  setIcmsAliquota(cte: Cte, valor: number): void {
    cte.icmsAliquota = new Util().arredondar(valor);
    cte.icmsValor = new Util().arredondar((cte.icmsBase / 100) * cte.icmsAliquota);
  }

  ehEnquadramentoSimplesNacionalIsento(cst: string = null): boolean {
    if (cst && cst != '900') {
      this.setIcmsAliquota(this.cte, 0);
      this.setIcmsBase(this.cte, 0);
      return true;
    }
    return false;
  }

  ehEnquadramentoIsento(cst: string = null): boolean {
    if ((cst && ['30', '40', '41', '50', '60'].includes(cst))) {
      this.setIcmsAliquota(this.cte, 0);
      this.setIcmsBase(this.cte, 0);
      return true;
    }
    return false;
  }

  zerarAliquotaIcms(cst: string = null): boolean {
    const simplesNacional: string = this.utilSessao.getLojas().find((loja) => loja.id == this.cte.idLoja).enquadramento == 1 ? 'S' : 'N';
    const cstFormatado: string = cst ? cst.substring(1, cst.length) : null;
    if (simplesNacional == 'S') {
      return this.ehEnquadramentoSimplesNacionalIsento(cstFormatado);
    }
    return this.ehEnquadramentoIsento(cstFormatado);
  }

  setCstIcms(cstIcms: Cst): void {
    this.cte.idCstIcms = cstIcms.id;
    this.zerarAliquotaIcms(cstIcms.cst);
  }

  ehBloqueado(): void {
    this.bloqueado = this.cte == null || this.cte.status == 'S' || this.cte.ativo == 'N';
  }

  irCte(): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 216)) {
      this.utilSessao.setIdentificacao(new Identificacao('idCte', this.cte.id));
      const url: string = '#/documentoEletronicoCTe/TRA';
      window.open(url, '_blank');
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.semPermissaoAcesso));
    }
  }
}
