import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarMovimentacaoVinculada',
  pure: false,
})
export class MovimentacaoVinculadaPipe implements PipeTransform {
  transform(movimentacaoVinculadas: any[], argumentos?: any): any {
    return movimentacaoVinculadas.filter((movimentacaoVinculada) => movimentacaoVinculada.idMovimentacao == argumentos.idMovimentacao);
  }
}
