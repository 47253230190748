import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.data, campo: 'data' },
  { rotulo: bibDialogo.loja, campo: 'abreviacao' },
  { rotulo: bibDialogo.numero, campo: 'numero' },
  { rotulo: bibDialogo.cliente, campo: 'parceiro' },
  { rotulo: bibDialogo.valor, campo: 'valorTotal' },
];
export default ordens;
