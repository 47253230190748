<div *ngIf="parceiroDestino?.area && parceiroDestino?.area > 0">
  <titulo class="col-sm" [titulo]="bibDialogo.detalhamentoDeArea" [imagem]="bibImagem.produto" [icone]="bibIcone.mais"  (iconeEmt)="adicionarParceiroPropriedade()"></titulo>
</div>
<table class="table table-responsive-sm" *ngIf="parceiroDestino.parceiroPropriedades ? (parceiroDestino.parceiroPropriedades.length > 0 ? true : false) : false">
  <thead>
    <tr>
      <th class="l-30">{{ bibDialogo.nome }}</th>
      <th class="l-20">{{ bibDialogo.hectares }}</th>
      <th class="l-30">{{ bibDialogo.cultura }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parceiroPropriedade of parceiroDestino.parceiroPropriedades; let i = index">     
      <td class="limitar">
        <texto *ngIf="!parceiroPropriedade.id || edicaoParceiroPropriedades.includes(parceiroPropriedade.id)" [campo]="parceiroPropriedade.nome" (alteracao)="parceiroPropriedade.nome = $event"></texto>
        {{ parceiroPropriedade.id && edicaoParceiroPropriedades.includes(parceiroPropriedade.id) == false ? parceiroPropriedade.nome : ''}}
      </td>
      <td class="limitar">
        <decimal *ngIf="!parceiroPropriedade.id || edicaoParceiroPropriedades.includes(parceiroPropriedade.id)" [campo]="parceiroPropriedade.hectares" (alteracao)="parceiroPropriedade.hectares = $event" [sufixo]="'HA'"></decimal>
        {{ parceiroPropriedade.id && edicaoParceiroPropriedades.includes(parceiroPropriedade.id) == false ? parceiroPropriedade.hectares : ''}}
      </td>
      <td class="limitar">
        <texto *ngIf="!parceiroPropriedade.id || edicaoParceiroPropriedades.includes(parceiroPropriedade.id)" [campo]="parceiroPropriedade.cultura" (alteracao)="parceiroPropriedade.cultura = $event"></texto>
        {{ parceiroPropriedade.id && edicaoParceiroPropriedades.includes(parceiroPropriedade.id) == false && parceiroPropriedade.cultura ? parceiroPropriedade.cultura : ''}}
      </td>        
      <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editarParceiroPropriedades(parceiroPropriedade)" [compacto]="compacto" ></btnAdicional></td>
      <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirParceiroPropriedades(parceiroPropriedade.id, i)" [compacto]="compacto" ></btnAdicional></td>
    </tr>
  </tbody>
</table>