export class Negociacao {
  public aVista: string = '2';
  public abreviacao: string;
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descontoMaximo: number = 0;
  public diaDeGeracao: number = 8;
  public diaFechamentoPadrao: number;
  public diasEntre: number;
  public entradaSaida: number = -1;
  public especie: string = 'V';
  public exigeAprovacao: string = 'N';
  public formaFechamento: string = 'N';
  public id: number;
  public idEmpresa: number;
  public idLoja: number = 0;
  public idTipoTitulo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public loja: string;
  public nome: string;
  public parcelaFixo: number;
  public parcelaMaximo: number = 0;
  public parcelaMinimoValor: number = 0;
  public penalidadeDiaFixo: number = 0;
  public penalidadeDiaMinimo: number = 0;
  public penalidadeTaxaDiaria: number = 0;
  public penalidadeTaxaFixa: number = 0;
  public penalidadeTaxaMensal: number = 0;
  public permitido: string;
  public permitirEntregaDireta: string = 'N';
  public permitirAlterarDiasEntre: string = 'N';
  public prazoFixo: number;
  public prazoReferenciaLucro: number = 0;
  public restricao: number = 2;
  public tipoFiscal: string = '99';
  public tipoTitulo: string;
  public tipoTituloIdEspecie: number;
  public usuarioAlteracao: string;
  public usuarioIncluscao: string;
  public validaLimiteCredito: string = 'S';
  public validaInadimplente: string = 'S';
  public prazoMaximoDias: number;

  getNome(): string {
    return this.nome;
  }
}
