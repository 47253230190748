import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'responsavel', rotulo: bibDialogo.responsavel, nome: 'ID_RESPONSAVEL', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { id: 'parceiro', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
]);
export default filtros;
