export class Icmssn500 {
    public orig: string;
    public CSOSN: string = '500';
    public vBCSTRet: string;
    public vICMSSTRet: string;
    public pST: string;
    public vBCFCPSTRet: string;
    public pFCPSTRet: string;
    public vFCPSTRet: string;
    public pRedBCEfet: string;
    public vBCEfet: string;
    public pICMSEfet: string;
    public vICMSEfet: string;
    public vICMSSubstituto: string;
}