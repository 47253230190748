import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'r2001MovimentacaoResumoNegociacao',
  templateUrl: './r2001MovimentacaoResumoNegociacao.component.html',
})
export class R2001MovimentacaoResumoNegociacaoComponent {
  @Input() movimentacaoResumoNegociacoes: any[];
  public menuNegociacaoApelido = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 71).apelido;
  public bibDialogo = bibDialogo;
}
