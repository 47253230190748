<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="usaNegociacao ? 13 : 12">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.produto }}</th>
          <th class="r-1">{{ bibDialogo.unidade }}</th>
          <th class="text-right r-1">{{ bibDialogo.quantidade }}</th>
          <th class="text-right r-1 quebra-linha">{{ bibDialogo.valorUnitario }}</th>
          <th class="text-right r-1">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
          <th class="text-right r-1">{{ bibDialogo.valorTabela }}</th>
          <th class="text-right r-1">{{ bibDialogo.valorFinal }}</th>
          <th class="text-right r-1">{{ bibDialogo.lucro }}</th>
          <th class="text-right r-1">{{ bibDialogo.margem }}</th>
          <th class="text-right r-1">{{ bibDialogo.descontoAbreviacao }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos">
          <tr>
            <td>{{ movimentacaoProduto.numero + ' - ' + movimentacaoProduto.produto }}</td>
            <td>{{ movimentacaoProduto.unidadeAbreviacao }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.custoGerencial | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorTabela | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.lucroValor | monetario }}</td>
            <td class="text-right">{{ (movimentacaoProduto.margem | monetario) + '%' }}</td>
            <td class="text-right">{{ (movimentacao.descontoPercentual | monetario) + '%' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
