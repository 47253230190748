<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(cheques, $event)" (campoGrupoEmt)="agrupar(cheques, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1">{{ bibDialogo.dataPreDatada }}</th>
      <th class="r">{{ bibDialogo.parceiro }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th class="r-2-5">{{ bibDialogo.contaAtual }}</th>
      <th class="r-1">{{ bibDialogo.dataConciliacao }}</th>
      <th class="r-1">{{ bibDialogo.situacao }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let cheque of cheques; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo sem-borda">
          <td>{{ cheque.loja }}</td>
          <td class="text-right">{{ cheque.numero }}</td>
          <td>{{ cheque.data | data }}</td>
          <td>{{ cheque.dataPreDatada | data }}</td>
          <td class="limitar">{{ cheque.parceiro }}</td>
          <td class="text-right">{{ cheque.valor | monetario }}</td>
          <td class="limitar">{{ cheque.conta }}</td>
          <td>{{ cheque.dataFinalizado | data }}</td>
          <td>{{ cheque.idConciliacao ? bibDialogo.conciliado : bibDialogo.aberto }}</td>
        </tr>
        <tr *ngIf="cheque.observacao" class="conteudo">
          <td colspan="9"><span>{{ bibDialogo.observacao + ': ' }}</span>{{ cheque.observacao }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
