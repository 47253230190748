import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1152.component.html',
})
export class R1152Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public aprovacoes: any[] = [];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public usaDescricao: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.aprovacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Aprovacao').lista;
      this.aprovacoes.forEach((aprovacao) => (aprovacao.dataOrdenacao = new Date(aprovacao.data)));
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.ordenarAgrupar(this.aprovacoes);
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaDescricao = this.definirRegra(relatorioAdicional, 135, this.usaDescricao);
  }
}
