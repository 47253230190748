<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-70 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-20 clique" (click)="listar(add('especie'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.especie }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let tipoTitulo of tipoTitulos; let i = index">
        <tr [ngClass]="{ tachado: tipoTitulo.ativo != 'S' }">
          <td>
            <a href="#" (click)="ir(tipoTitulo.id, i, tipoTitulos)">{{ tipoTitulo.nome }}</a>
          </td>
          <td class="limitar">{{ tipoTitulo.especie }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(tipoTitulo.id, i, tipoTitulos)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirTipoTitulo(tipoTitulo.id, tipoTitulo.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
