<layout [titulo]="historico.nome" [id]="historico.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="historico.nome" (alteracao)="setHistorico($event)" [obrigatorio]="true" [foco]="true" [maximoCaracteres]="100"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="historico.ativo" (alteracao)="historico.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row justify-content-end">
    <botao [compacto]="compacto" [legenda]="bibDialogo.adicionarRotuloTipoDeTitulo" [desabilitado]="bloqueiaMarcacaoTipoDeTitulo" (botaoEmt)="flagTipoDeTitulo()"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.adicionarRotuloParcela" [desabilitado]="bloqueiaMarcacaoParcela" (botaoEmt)="flagParcela()"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.adicionarRotuloOperacao" [desabilitado]="bloqueiaMarcacaoOperacao" (botaoEmt)="flagOperacao()"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.adicionarRotuloParceiro" [desabilitado]="bloqueiaMarcacaoParceiro" (botaoEmt)="flagParceiro()"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.adicionarRotuloNumeroDocumentoAbreviacao" [desabilitado]="bloqueiaMarcacaoNDocumento" (botaoEmt)="flagNumeroDocumento()"></botao>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'tipo'" [rotulo]="bibDialogo.tipo" [campo]="historico.tipo" (alteracao)="historico.tipo = $event.id" [lista]="debitoCredito" [obrigatorio]="true"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirhistorico()" [desabilitado]="bloquearPermissao(historico.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirhistorico(true)" [desabilitado]="bloquearPermissao(historico.id)"></botao>
<auditoria *ngIf="historico.id" [idUsuarioInclusao]="historico.idUsuarioInclusao" [dataHoraInclusao]="historico.dataHoraInclusao" [idUsuarioAlteracao]="historico.idUsuarioAlteracao" [dataHoraAlteracao]="historico.dataHoraAlteracao"></auditoria>
