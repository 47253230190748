<div *ngIf="this.parametro != 'RAPIDA'" class="row pt-2">
  <texto [id]="'numero'" *ngIf="this.parametro != 'CONFERENCIA' && this.parametro != 'DUPLICATA'" class="col-sm-6" [rotulo]="baixa.bibDialogo.numeroAbreviado + ' ' + (this.parametro != 'DESPESA' ? bibNomenclatura.escrever(bibNomenclatura.movimentacao).toLowerCase() : bibDialogo.movimentacao.toLowerCase())" [campo]="filtroNumero.valor" (alteracao)="setNumeroMovimentacao($event)" [tipo]="baixa.bibPropriedade.texto.inteiro" [desabilitado]="(bloqueado != null && bloqueado != '') || bloqueiaNumeroMovimentacao" [foco]="true"></texto>
  <texto [id]="'numeroConferencia'" *ngIf="!baixaConferenciaOuDespesa || this.parametro == 'DUPLICATA'" class="col-sm-6" [rotulo]="baixa.bibDialogo.numeroAbreviado + ' ' + (this.parametro == 'DUPLICATA' ? bibDialogo.duplicataMercantil.toLowerCase() : menuBaixaConferencia.apelido.toLowerCase())" [campo]="filtroNumeroConferencia.valor" (alteracao)="setNumeroConferencia($event)" [tipo]="baixa.bibPropriedade.texto.inteiro" [desabilitado]="(bloqueado != null && bloqueado != '') || bloqueiaNumeroConferencia"></texto>
</div>
<div *ngIf="this.parametro == 'RAPIDA' || this.parametro == 'DESPESA'" class="row pt-2">
  <data *ngIf="filtroTipo.valor == 'D' || this.parametro == 'DESPESA'" class="col-sm-3" [rotulo]="baixa.bibDialogo.dataVencimentoDe" [campo]="filtroDataVencimentoDe.valor" (alteracao)="filtroDataVencimentoDe.valor = $event" [foco]="true" [obrigatorio]="parametro == 'DESPESA' && filtroParceiro.valor ? false : true"></data>
  <data class="col-sm-3" [rotulo]="baixa.bibDialogo.dataVencimentoAte" [campo]="filtroDataVencimentoAte.valor" (alteracao)="filtroDataVencimentoAte.valor = $event" [foco]="true" [obrigatorio]="parametro == 'DESPESA' && filtroParceiro.valor ? false : true"></data>
  <lista *ngIf="this.parametro != 'DESPESA'" class="col-sm-3" [lista]="listaReceitaDespesa" [rotulo]="baixa.bibDialogo.tipo" [campo]="filtroTipo.valor" (alteracao)="filtroTipo.valor = $event.id" [obrigatorio]="true" (click)="ehReceitaOuDespesa()"></lista>
</div>
<div class="row">
  <atencao class="pl-2" [atencao]="'OU'"></atencao>
</div>
<div class="row">
  <busca *ngIf="!baixaCaixa" class="col-sm-12" [id]="'parceiro'" [rotulo]="parametro == 'RAPIDA' ? baixa.bibDialogo.cliente  + ' ou ' + baixa.bibDialogo.fornecedor.toLocaleLowerCase() : parametro == 'DESPESA' ? baixa.bibDialogo.fornecedor : baixa.bibDialogo.cliente" [campo]="baixa.parceiro?.razaoSocial" [servico]="baixa.bibServico.parceiro" [obrigatorio]="bloqueiaFiltros == false && !baixaCaixa && parametro != 'RAPIDA' ? (parametro == 'DESPESA' && filtroDataVencimentoDe.valor && filtroDataVencimentoAte.valor ? false : true) : false" (alteracao)="setParceiro($event)" [minimoCaracterBusca]="5" [foco]="this.parametro == 'CONFERENCIA' ? true : false" [somenteAtivo]="false" [ajudaDireita]="true"></busca>
</div>
<div class="row">
  <lista class="col-sm-12" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="filtroIdLoja.valor" (alteracao)="filtroIdLoja.valor = $event.id" [lista]="lojas"></lista>
</div>
<div class="mt-2">
  <botao [id]="'proximo'" [compacto]="compacto" [legenda]="baixa.bibDialogo.avancar" [tipo]="baixa.bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ir()"></botao>
  <botao style="float: right;" *ngIf="parametro == 'CAIXA'" [compacto]="compacto" [legenda]="baixa.bibDialogo.visualizarTodosAberto" [tipo]="baixa.bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="buscar()"></botao>
</div>