<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <table class="border-top border-right border-left border-dark">
    <tbody>
      <th class="r">{{ relatorio.nome }}</th>
      <th class="r-2 text-right">{{ bibDialogo.pagina + ': ' + pagina }}</th>
    </tbody>
    <tbody class="border-top border-dark">
      <th class="r"></th>
      <th class="r-2 text-right">{{ bibDialogo.data + ': ' + (dataAte | data) }}</th>
    </tbody>
  </table>
  <table>
    <thead>
      <th class="r-1">{{ bibDialogo.numeracao }}</th>
      <th class="r">{{ bibDialogo.conta }}</th>
      <th class="r-1 text-right">{{ bibDialogo.saldoAnterior }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let contaContabil of contaContabeis">
        <tr>
          <td>{{ contaContabil.numeracao }}</td>
          <td class="limitar">{{ contaContabil.nome }}</td>
          <td class="text-right">{{ contaContabil.saldoAnterior | monetario }}</td>
        </tr>
        <tr class="sem-borda" r2006itens [contaContabil]="contaContabil"></tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
