import { Component, ViewChild } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { ConfiguracaoMovimentacaoContabil } from 'src/app/modelo/configuracaoMovimentacaoContabil';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { EmpresaFilial } from 'src/app/modelo/empresaFilial';
import { EmpresaOperacaoBloqueio } from 'src/app/modelo/empresaOperacaoBloqueio';
import { EmpresaOperacaoContabil } from 'src/app/modelo/empresaOperacaoContabil';
import { EmpresaOperacaoContabilFinanceiro } from 'src/app/modelo/empresaOperacaoContabilFinanceiro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Operacao } from 'src/app/modelo/operacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css'],
})
export class EmpresaComponent extends PaginaComponent {
  @ViewChild('arquivoUpload') arquivoUpload;
  public componente: any = null;
  public criterioRelatorio: Criterio[] = [new Criterio('SISTEMA', this.utilSessao.sistema), new Criterio('ATIVO', 'S')];
  public criterioContaContabilAtivo: Criterio[] = [new Criterio('ATIVO', 'S')];
  public criterioOperacaoFinanceiro: Criterio[] = [new Criterio('OPERACAO_FINANCEIRO', 'S')];
  public ehMinhaModal: boolean = false;
  public ehMinhaModalFilial: boolean = false;
  public empresa: Empresa;
  public idsOperacaoBloqueioExcluir: number[] = [];
  public idsOperacaoContabilExcluir: number[] = [];
  public idsOperacaoContabilFinanceiroExcluir: number[] = [];
  public idsEmpresaFilalExcluir: number[] = [];
  public imagemEmpresa: string = 'img/empresa-semfoto.jpg';
  public edicaoOperacoesContabeis: number[] = [];
  public edicaoOperacoesContabeisFinanceiro: number[] = [];
  public edicaoEmpresaFilial: number[] = [];
  public operacoesEmpresa: Operacao[];
  public focoOperacao: number;
  public focoOperacaoFinanceiro: number;
  public conexao: Conexao = new Conexao(this.utilSessao);
  public criteriosOperacaoContabilFinanceiro: Criterio[] = [];
  public ehSistemaaGrow: boolean = this.utilSessao.sistema.indexOf('aGrow') >= 0 ? true : false;

  public listaMostraMargemLucro: any[] = [
    { id: 'S', nome: this.bibDialogo.sim },
    { id: 'N', nome: this.bibDialogo.excetoPermissaoProdutoValor },
  ];

  public movimentacao: any[] = [
    { id: 1, nome: this.bibDialogo.salvarFinalizar },
    { id: 2, nome: this.bibDialogo.finalizar },
  ];

  public regraCustoReposicao: any[] = [
    { id: 1, nome: this.bibDialogo.mediaPonderada },
    { id: 2, nome: this.bibDialogo.seMaior },
    { id: 3, nome: this.bibDialogo.sempre },
    { id: 4, nome: this.bibDialogo.nao },
  ];

  public limitar: any[] = [
    { id: 1, nome: this.bibDialogo.empresa },
    { id: 2, nome: this.bibDialogo.praca },
    { id: 3, nome: this.bibDialogo.loja },
  ];

  public retornoLimiteCredito: any[] = [
    { id: 1, nome: this.bibDialogo.retornoDisponivelAutomatico },
    { id: 2, nome: this.bibDialogo.retornoDisponivelManual },
  ];

  public regraComissao: any[] = [
    { id: 1, nome: this.bibDialogo.nenhuma },
    { id: 2, nome: this.bibDialogo.valorVenda },
    { id: 3, nome: this.bibDialogo.valorLucro },
  ];

  public regraCnpjCpf: any[] = [
    { id: 1, nome: this.bibDialogo.obrigar },
    { id: 2, nome: this.bibDialogo.naoObrigar },
  ];

  public regraCnpjCpfDuplicados: any[] = [
    { id: 1, nome: this.bibDialogo.nao },
    { id: 2, nome: this.bibDialogo.sim },
  ];

  public simNao: any[] = [
    { id: 'S', nome: this.bibDialogo.sim },
    { id: 'N', nome: this.bibDialogo.nao },
  ];

  public ordemPagamento: any[] = [
    { id: '1', nome: this.bibDialogo.nao },
    { id: '2', nome: this.bibDialogo.simTodasDespesas },
    { id: '3', nome: this.bibDialogo.simOriginadasMovimentacao },
  ];

  public validarInadimplencias: any[] = [
    { id: 1, nome: this.bibDialogo.sempre },
    { id: 2, nome: this.bibDialogo.somenteSemLimiteDisponivel },
  ];

  public regraContingencias: any[] = [
    { id: 1, nome: this.bibDialogo.normal },
    { id: 2, nome: this.bibDialogo.comInstabilidade },
    { id: 3, nome: this.bibDialogo.contingenciaDisponivel },
  ];

  criarCriterioOperacaoContabilFinanceiro() {
    this.criteriosOperacaoContabilFinanceiro.push(new Criterio('OPERACAO_FINANCEIRO', 'S'));
  }

  ngOnInit(): void {
    this.criarCriterioOperacaoContabilFinanceiro();

    this.listarEmpresa(this.utilSessao.getEmpresa().id);
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const operacoes: Operacao[] = identificacao.conteudo;
        operacoes.forEach((operacao) => {
          if (this.empresa.empresaOperacaoBloqueios.filter((empresaOperacaoBloqueio) => empresaOperacaoBloqueio.idOperacao == operacao.id).length == 0) {
            let empresaOperacaoBloqueio: EmpresaOperacaoBloqueio = new EmpresaOperacaoBloqueio();
            empresaOperacaoBloqueio.idOperacao = operacao.id;
            empresaOperacaoBloqueio.nome = operacao.nome;
            empresaOperacaoBloqueio.idEmpresa = this.empresa.id;
            this.empresa.empresaOperacaoBloqueios.push(empresaOperacaoBloqueio);
          }
        });
      }
      if (this.ehMinhaModalFilial) {
        this.adicionarFilial(identificacao);
      }
    });
  }

  listarEmpresa(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.empresa).subscribe((res) => {
      this.empresa = this.plainToClass(Empresa, res[0]) as Empresa;
      this.empresa.historicoFoto = this.empresa.foto;
      this.listarEmpresaOperacaoBloqueio(this.empresa.id);
      this.listarEmpresaConfiguracaoContabil(this.empresa.id);
      this.listarEmpresaConfiguracaoContabilFinanceiro(this.empresa.id);
      this.listarEmpresaFilial(this.empresa.id);
    });
  }

  listarEmpresaOperacaoBloqueio(idEmpresa: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_EMPRESA', idEmpresa)), this.bibServico.empresaOperacaoBloqueio).subscribe((res) => {
      this.empresa.empresaOperacaoBloqueios = this.plainToClass(EmpresaOperacaoBloqueio, res) as any;
    });
  }

  listarEmpresaConfiguracaoContabil(idEmpresa: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_EMPRESA', idEmpresa)), this.bibServico.empresaOperacaoContabil).subscribe((res) => {
      this.empresa.empresaOperacaoContabeis = this.plainToClass(EmpresaOperacaoContabil, res) as any;
    });
  }

  listarEmpresaConfiguracaoContabilFinanceiro(idEmpresa: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_EMPRESA', idEmpresa)), this.bibServico.empresaOperacaoContabilFinanceiro).subscribe((res) => {
      this.empresa.empresaOperacaoContabeisFinanceiro = this.plainToClass(EmpresaOperacaoContabilFinanceiro, res) as any;
    });
  }

  listarEmpresaFilial(idEmpresa: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_EMPRESA', idEmpresa)), this.bibServico.empresaFilial).subscribe((res) => {
      this.empresa.empresaFiliais = this.plainToClass(EmpresaFilial, res) as any;
    });
  }

  persistirEmpresa(): void {
    if (this.bibDialogo.backendW == 'S') {
      this.empresa.idsOperacaoBloqueioExcluir = this.idsOperacaoBloqueioExcluir;
      this.empresa.idsOperacaoContabilExcluir = this.idsOperacaoContabilExcluir;
      this.empresa.idsOperacaoContabilFinanceiroExcluir = this.idsOperacaoContabilFinanceiroExcluir;
      this.empresa.idsOperacaoContabilExcluir = this.idsEmpresaFilalExcluir;
    }
    if (this.ehValido()) {
      let transporte: Transporte = new Transporte(this.empresa);
      transporte.adicionar(this.idsOperacaoBloqueioExcluir);
      transporte.adicionar(this.idsOperacaoContabilExcluir);
      transporte.adicionar(this.idsEmpresaFilalExcluir);
      transporte.adicionar(this.idsOperacaoContabilFinanceiroExcluir);
      super.persistir(transporte, this.bibServico.empresa, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.empresa], this.bibClasse.empresa, true);
          this.definirId(this.empresa.empresaOperacaoBloqueios, this.bibClasse.empresaOperacaoBloqueio);
          this.definirId(this.empresa.empresaOperacaoContabeis, this.bibClasse.empresaOperacaoContabil);
          this.definirId(this.empresa.empresaFiliais, this.bibClasse.empresaFilial);
          this.definirId(this.empresa.empresaOperacaoContabeisFinanceiro, this.bibClasse.empresaOperacaoContabilFinanceiro);
          this.idsOperacaoBloqueioExcluir = [];
          this.idsOperacaoContabilExcluir = [];
          this.idsOperacaoContabilFinanceiroExcluir = [];
          this.idsEmpresaFilalExcluir = [];
          this.utilSessao.alertaAlteracao = true;
          window.scrollTo(0, 0);
        }
      });
    }
  }

  ehValido(): boolean {
    if (this.empresa.regraCasasDecimais > 0 && this.empresa.regraCasasDecimaisEntrada > 0 && this.empresa.abreviacao && this.empresa.mostraMargemLucro) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  alterarFoto(evento: any): void {
    const empresa = this.empresa;
    this.empresa.historicoFoto = 'N';
    const leitorArquivo: FileReader = new FileReader();
    const imagem = <HTMLInputElement>document.getElementById('output');
    leitorArquivo.onload = function () {
      imagem.src = leitorArquivo.result.toString();
    };
    leitorArquivo.readAsDataURL(evento.target.files[0]);
    imagem.addEventListener('load', function (evento) {
      empresa.foto = imagem.src.indexOf('img/semfoto.jpg') == -1 ? 'S' : 'N';
      empresa.fotoEmpresa = imagem.src.indexOf('img/semfoto.jpg') == -1 ? imagem.src : null;
    });
    this.arquivoUpload.nativeElement.value = '';
  }

  deletarFoto(): void {
    this.empresa.foto = 'N';
    this.empresa.fotoEmpresa = null;
  }

  abrirPesquisa(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('OPERACAO_FINANCEIRO', 'S'));
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.operacao));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.empresa.empresaOperacaoBloqueios));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.operacao));
    this.utilSessao.setIdentificacao(new Identificacao('criterios', criterios));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }

  adicionar(): void {
    let operacaoContabil = new EmpresaOperacaoContabil();
    this.empresa.empresaOperacaoContabeis.push(operacaoContabil);
    this.focoOperacao = Math.random();
  }

  adicionarConfiguracaoContabilFinanceiro(): void {
    let operacaoContabilFinanceiro = new EmpresaOperacaoContabilFinanceiro();
    this.empresa.empresaOperacaoContabeisFinanceiro.push(operacaoContabilFinanceiro);
    this.focoOperacaoFinanceiro = Math.random();
  }

  editar(operacaoContabil: EmpresaOperacaoContabil): void {
    if (this.edicaoOperacoesContabeis.includes(operacaoContabil.id)) {
      const posicao: number = this.edicaoOperacoesContabeis.indexOf(operacaoContabil.id, 0);
      this.edicaoOperacoesContabeis.splice(posicao, 1);
    } else {
      this.edicaoOperacoesContabeis.push(operacaoContabil.id);
    }
  }

  editarOperacaoContabeisFinanceiro(operacaoContabilFinanceiro: EmpresaOperacaoContabilFinanceiro): void {
    if (this.edicaoOperacoesContabeisFinanceiro.includes(operacaoContabilFinanceiro.id)) {
      const posicao: number = this.edicaoOperacoesContabeis.indexOf(operacaoContabilFinanceiro.id, 0);
      this.edicaoOperacoesContabeisFinanceiro.splice(posicao, 1);
    } else {
      this.edicaoOperacoesContabeisFinanceiro.push(operacaoContabilFinanceiro.id);
    }
  }

  ehExcluirOperacaoContabeisFinanceiro(posicao: number, idsOperacaoContabeisFinanceiro: number): void {
    super.ehExcluir(posicao, this.empresa.empresaOperacaoContabeisFinanceiro, this.idsOperacaoContabilFinanceiroExcluir, idsOperacaoContabeisFinanceiro);
  }

  setOperacaoContabil(operacaoContabil: EmpresaOperacaoContabil, operacao: Operacao): void {
    operacaoContabil.idOperacao = operacao.id;
    operacaoContabil.operacao = operacao.nome;
  }

  setOperacaoContabilFinanceiro(empresaOperacaoContabilFinanceiro: EmpresaOperacaoContabilFinanceiro, operacao: Operacao): void {
    empresaOperacaoContabilFinanceiro.idOperacao = operacao.id;
    empresaOperacaoContabilFinanceiro.operacao = operacao.nome;
  }

  setConfiguracaoContabil(operacaoContabil: EmpresaOperacaoContabil, configuracaoMovimentacaoContabil: ConfiguracaoMovimentacaoContabil): void {
    operacaoContabil.idConfigMovContabil = configuracaoMovimentacaoContabil.id;
    operacaoContabil.configuracaoContabil = configuracaoMovimentacaoContabil.nome;
  }

  setContaContabilFinanceiro(operacaoContabilFinanceiro: EmpresaOperacaoContabilFinanceiro, contaContabil: ContaContabil): void {
    operacaoContabilFinanceiro.idContaContabil = contaContabil.id;
    operacaoContabilFinanceiro.contaContabil = contaContabil.nome;
  }

  adicionarFilial(identificacao: any): void {
    this.ehMinhaModalFilial = false;
    const filiais: Parceiro[] = identificacao.conteudo;
    filiais.forEach((filial) => {
      if (this.empresa.empresaFiliais.filter((empresaFilial) => empresaFilial.idParceiroFilial == filial.id).length == 0) {
        if (filial.cnpjCpf && filial.nomeFantasia) {
          let empresaFilial: EmpresaFilial = new EmpresaFilial();
          empresaFilial.idParceiroFilial = filial.id;
          empresaFilial.idEmpresa = this.empresa.id;
          empresaFilial.nomeFilial = filial.nomeFantasia;
          empresaFilial.cnpjFilial = filial.cnpjCpf;
          this.empresa.empresaFiliais.push(empresaFilial);
        }
      }
    });
  }

  abrirPesquisaFilial(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_EMPRESA', 'S'));
    criterios.push(new Criterio('FORNCEDOR', 'S'));
    criterios.push(new Criterio('CLIENTE', 'S'));
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.parceiro));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.empresa.empresaFiliais));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.filial));
    this.utilSessao.setIdentificacao(new Identificacao('criterios', criterios));
    this.ehMinhaModalFilial = true;
    this.componente = PesquisaComponent;
  }
}
