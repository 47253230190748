import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.dataVencimento, campo: 'dataVencimento' },
  { rotulo: bibDialogo.dataMovimentacao, campo: 'dataMovimentacao' },
  { rotulo: bibDialogo.numero, campo: 'numero' },
  { rotulo: bibDialogo.vendedor, campo: 'vendedor' },
  { rotulo: bibDialogo.cliente, campo: 'cliente' },
];
export default ordens;
