export class Cep {
  public bairro: string;
  public cidade: string;
  public endereco: string;
  public estado: string;
  public ibge: number;
  public idBairro: number;
  public idCidade: number;
  public idEstado: number;
  public tipoEndereco: string;
}
