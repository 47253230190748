<layout>
  <div class="row align-items-center">
    <titulo class="col-sm" [titulo]="bibDialogo.contaContabil" [imagem]="bibImagem.contabilidade"></titulo>
    <botao class="text-right" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.contaContabil.toLowerCase()" (botaoEmt)="adicionarContaContabil()"></botao>
  </div>
  <div class="responsiva">
    <table class="table table-responsive-sm">
      <thead>
        <tr>
          <th class="l-95">{{ bibDialogo.nome }}</th>
          <th class="l-5"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let objeto of objetos; let i = index">
          <td>{{ objeto.nome.split(' ').join('&nbsp;') }}</td>
          <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, objetos, idsConfiguracaoExcluir, objeto.id)"></btnAdicional></td>
        </tr>
      </tbody>
    </table>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirObjeto()" [desabilitado]="temPermissaoEdicao == false || temPermissaoInserir == false"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
