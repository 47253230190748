<layout [titulo]="''">
  <div class="row">
    <lista class="col-sm-8" [id]="'enquadramento'" [rotulo]="bibDialogo.enquadramento" [campo]="pisCofins.enquadramento" [lista]="listaEnquadramento" (alteracao)="setEnquadramento($event.id)" [obrigatorio]="true"></lista>
  </div>
  <br>
<layout [titulo]="bibDialogo.pis" [id]="pisCofins.id">
  <br>
  <div class="row">
    <lista class="col-sm-8" [rotulo]="bibDialogo.entrada" [id]="'pisCofins'" [campo]="pisCofins.idCstPisEntrada" (alteracao)="setCstPisEntrada(pisCofins, $event)" [lista]="cstsPisCofinsEntrada" [obrigatorio]="true"></lista>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.aliquota" [campo]="pisCofins.aliquotaPisEntrada" (alteracao)="pisCofins.aliquotaPisEntrada = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div>
  <div class="row">
    <lista class="col-sm-8" [rotulo]="bibDialogo.saida" [id]="'pisCofins'" [campo]="pisCofins.idCstPisSaida" (alteracao)="setCstPisSaida(pisCofins, $event)" [lista]="cstsPisCofinsSaida" [obrigatorio]="true"></lista>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.aliquota" [campo]="pisCofins.aliquotaPisSaida" (alteracao)="pisCofins.aliquotaPisSaida = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div>
</layout>

<layout [titulo]="bibDialogo.cofins" [id]="pisCofins.id">
  <div class="row">
    <lista class="col-sm-8" [rotulo]="bibDialogo.entrada" [id]="'pisCofins'" [campo]="pisCofins.idCstCofinsEntrada" (alteracao)="setCstCofinsEntrada(pisCofins, $event)" [lista]="cstsPisCofinsEntrada" [obrigatorio]="true"></lista>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.aliquota" [campo]="pisCofins.aliquotaCofinsEntrada" (alteracao)="pisCofins.aliquotaCofinsEntrada = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div>
  <div class="row">
    <lista class="col-sm-8" [rotulo]="bibDialogo.saida" [id]="'pisCofins'" [campo]="pisCofins.idCstCofinsSaida" (alteracao)="setCstCofinsSaida(pisCofins, $event)" [lista]="cstsPisCofinsSaida" [obrigatorio]="true"></lista>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.aliquota" [campo]="pisCofins.aliquotaCofinsSaida" (alteracao)="pisCofins.aliquotaCofinsSaida = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div>
</layout>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionarPisCofins()"></botao>
<auditoria *ngIf="pisCofins.id" [idUsuarioInclusao]="pisCofins.idUsuarioInclusao" [dataHoraInclusao]="pisCofins.dataHoraInclusao" [idUsuarioAlteracao]="pisCofins.idUsuarioAlteracao" [dataHoraAlteracao]="pisCofins.dataHoraAlteracao"></auditoria>