import { Component } from '@angular/core';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoValor } from 'src/app/modelo/produtoValor';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';
import { ProdutoFrmComponent } from '../produto/frm/produtoFrm.component';
import { Filtros } from './filtro';

@Component({
  templateUrl: './produtoValor.component.html',
  styleUrls: ['./produtoValor.component.css'],
})
export class ProdutoValorComponent extends PaginaComponent {
  private idLoja: number;
  public atualizarMargem: string = 'N';
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public componente: any;
  public filtros: Filtro[] = new Filtros().filtros;
  public formaEscolhida: string = 'A';
  public indice: string;
  public loja: Loja;
  public margemEscolhida: string = 'G';
  public percentual: number = 0;
  public produtoValores: ProdutoValor[] = [];
  public util: Util = new Util();
  public atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['os ' + this.menuProduto.apelido.toLowerCase() + 's']);
  public focoEdicao: boolean = false;
  public mostraSufixoPercentual = true;
  private ordemCrescente: boolean = false;

  public margemEscolhidas: any = [
    { id: 'R', nome: this.bibNomenclatura.escrever(this.bibNomenclatura.custoReposicao) },
    { id: 'G', nome: this.bibNomenclatura.escrever(this.bibNomenclatura.margemGerencial) },
    { id: 'L', nome: this.bibDialogo.margemLucro },
    { id: 'P', nome: this.bibDialogo.precoVenda },
  ];

  public formaEscolhidas: any =
    this.margemEscolhida != 'R' && this.margemEscolhida != 'P'
      ? [
        { id: 'A', nome: this.bibDialogo.acrescentar },
        { id: 'M', nome: this.bibDialogo.diminuir },
        { id: 'D', nome: this.bibDialogo.definir },
      ]
      : [
        { id: 'A', nome: this.bibDialogo.acrescentar },
        { id: 'M', nome: this.bibDialogo.diminuir },
      ];

  ngOnInit(): void {
    let idProduto: Identificacao = this.utilSessao.getIdentificacao('idProduto')?.conteudo;
    let idLoja: Identificacao = this.utilSessao.getIdentificacao('idLoja')?.conteudo;
    if (idProduto != null && idLoja != null) {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID_PRODUTO', idProduto));
      criterios.push(new Criterio('ID_LOJA', idLoja));
      this.listar(criterios);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('ATIVO', 'S'));
    this.idLoja = criterios.find((criterio) => criterio.nome == 'ID_LOJA').valor;
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.produtoValor, false, true, false).subscribe((res) => {
      this.produtoValores = this.plainToClass(ProdutoValor, res) as any;
      this.produtoValores.forEach((produtoValor) => this.setMargemReal(produtoValor));
      if (this.verificarUtilizaCriteriosProduto(criterios) == true) {
        let criteriosProduto: Criterio[] = criterios.filter((criterio) => criterio.nome != 'ID_PRODUTO');
        if (criterios.find((criterio) => criterio.nome == 'ID_PRODUTO')) {
          criteriosProduto.push(new Criterio('ID', criterios.find((criterio) => criterio.nome == 'ID_PRODUTO').valor));
        }
        this.comunicacaoService.listar(new Transporte(criteriosProduto, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.produto).subscribe((res) => {
          const produtos: Produto[] = this.plainToClass(Produto, res) as any;
          this.popularProdutoValores(produtos);
        });
      }
    });
    this.percentual = 0;
    this.atualizarMargem = 'N';
    this.loja = this.utilSessao.getLojas().find((loja) => loja.id == criterios.find((criterio) => criterio.nome == 'ID_LOJA').valor);
    this.mostraSufixoPercentual = this.loja.margemLucroDiferenciada != 'S';
    this.focoEdicao = false;
  }

  verificarUtilizaCriteriosProduto(criterios: Criterio[]): boolean {
    if (criterios.find((criterio) => ['ID_PRODUTO', 'NUMERO', 'NOME', 'ID_FABRICANTE', 'ID_GRUPO', 'ID_CATEGORIA', 'REFERENCIA'].includes(criterio.nome))) {
      return true;
    } else {
      return false;
    }
  }

  popularProdutoValores(produtos: Produto[]): void {
    produtos.forEach((produto) => {
      if (this.produtoValores.find((produtoValor) => produtoValor.idProduto == produto.id) == undefined) {
        const produtoValor: ProdutoValor = new ProdutoValor();
        produtoValor.dataUltimaCompra = produto.dataUltimaCompra ? produto.dataUltimaCompra : null;
        produtoValor.valorUltimaCompra = produto.valorUltimaCompra ? produto.valorUltimaCompra : 0;
        produtoValor.custoReposicao = produto.custoReposicao ? new Util().arredondar(produto.custoReposicao, this.empresaRegraCasasDecimais) : 0;
        produtoValor.grupoMargemGerencial = produto.grupoMargemGerencial ? Number(produto.grupoMargemGerencial) : 0;
        produtoValor.margemGerencial = null;
        produtoValor.grupoMargemLucro = produto.grupoMargemLucro ? Number(produto.grupoMargemLucro) : 0;
        produtoValor.margemLucro = null;
        produtoValor.idGrupo = produto.idGrupo;
        produtoValor.idLoja = this.idLoja;
        produtoValor.idProduto = produto.id;
        produtoValor.produto = produto.numero + ' - ' + produto.nome;
        produtoValor.unidade = produto.unidade;
        this.produtoValores.push(produtoValor);
      }
    });
    this.ordenarPorProduto();
  }

  ordenarPorProduto(): void {
    this.ordemCrescente = !this.ordemCrescente;
    this.produtoValores.sort((a, b) => {
      if (this.ordemCrescente) {
        return a.produto.substring(a.produto.indexOf(' - ') + 3, a.produto.length) == b.produto.substring(b.produto.indexOf(' - ') + 3, b.produto.length) ? 0 : a.produto.substring(a.produto.indexOf(' - ') + 3, a.produto.length) > b.produto.substring(b.produto.indexOf(' - ') + 3, b.produto.length) ? 1 : -1;
      } else {
        return a.produto.substring(a.produto.indexOf(' - ') + 3, a.produto.length) == b.produto.substring(b.produto.indexOf(' - ') + 3, b.produto.length) ? 0 : a.produto.substring(a.produto.indexOf(' - ') + 3, a.produto.length) < b.produto.substring(b.produto.indexOf(' - ') + 3, b.produto.length) ? 1 : -1;
      }
    });
  }

  verificarUtilizaCriteriosProdutoValor(criterios: Criterio[]): boolean {
    if (criterios.find((criterio) => ['DATA_DE', 'DATA_ATE', 'MARGEM_GERENCIAL_DE', 'MARGEM_GERENCIAL_ATE', 'MARGEM_LUCRO_DE', 'MARGEM_LUCRO_ATE', 'MARGEM_REAL_DE', 'MARGEM_REAL_ATE', 'CUSTO_MAIOR_VENDA', 'LUCRO_DIFERENTE_REAL'].includes(criterio.nome))) {
      return true;
    } else {
      return false;
    }
  }

  atualizarMargemProdutoValor(): void {
    this.produtoValores.forEach((produtoValor) => {
      let valor: number = 0;
      switch (this.margemEscolhida) {
        case 'G':
          produtoValor.margemGerencial = produtoValor.margemGerencial ? produtoValor.margemGerencial : produtoValor.grupoMargemGerencial;
          valor = this.formaEscolhida == 'A' ? produtoValor.margemGerencial + this.percentual : this.formaEscolhida == 'M' ? produtoValor.margemGerencial - this.percentual : this.percentual;
          this.setMargemGerencial(produtoValor, valor);
          break;
        case 'L':
          produtoValor.margemLucro = produtoValor.margemLucro ? produtoValor.margemLucro : produtoValor.grupoMargemLucro;
          valor = this.formaEscolhida == 'A' ? produtoValor.margemLucro + this.percentual : this.formaEscolhida == 'M' ? produtoValor.margemLucro - this.percentual : (valor = this.percentual);
          this.setMargemLucro(produtoValor, valor);
          break;
        case 'R':
          produtoValor.custoReposicao = produtoValor.custoReposicao ? produtoValor.custoReposicao : 0;
          valor = this.formaEscolhida == 'A' ? produtoValor.custoReposicao + (this.percentual * produtoValor.custoReposicao) / 100 : this.formaEscolhida == 'M' ? produtoValor.custoReposicao - (this.percentual * produtoValor.custoReposicao) / 100 : this.percentual;
          this.setCustoReposicao(produtoValor, valor);
          break;
        case 'P':
          produtoValor.precoVenda = produtoValor.precoVenda ? produtoValor.precoVenda : 0;
          valor = this.formaEscolhida == 'A' ? produtoValor.precoVenda + (this.percentual * produtoValor.precoVenda) / 100 : this.formaEscolhida == 'M' ? produtoValor.precoVenda - (this.percentual * produtoValor.precoVenda) / 100 : this.percentual;
          this.setPrecoVenda(produtoValor, valor);
          break;
      }
    });
  }

  setMargemGerencial(produtoValor: ProdutoValor, margemGerencial: number): void {
    produtoValor.idMovimentacao = null;
    produtoValor.margemGerencial = margemGerencial;
    this.setCustoGerencial(produtoValor);
    this.setAlterado(produtoValor);
  }

  setMargemLucro(produtoValor: ProdutoValor, margemLucro: number): void {
    produtoValor.idMovimentacao = null;
    produtoValor.margemLucro = this.util.arredondar(margemLucro);
    this.setPrecoVenda(produtoValor);
    this.setAlterado(produtoValor);
  }

  setCustoReposicao(produtoValor: ProdutoValor, custoReposicao: number): void {
    produtoValor.idMovimentacao = null;
    produtoValor.custoReposicao = this.util.arredondar(custoReposicao, this.empresaRegraCasasDecimais);
    this.setCustoGerencial(produtoValor);
    this.setAlterado(produtoValor);
  }

  setCustoNfe(produtoValor: ProdutoValor, custoNfe: number): void {
    produtoValor.idMovimentacao = null;
    produtoValor.custoNfe = this.util.arredondar(custoNfe, this.empresaRegraCasasDecimais);
    this.setAlterado(produtoValor);
  }

  setPrecoVenda(produtoValor: ProdutoValor, valor: number = null, focado: boolean = false): void {
    produtoValor.idMovimentacao = null;
    if (produtoValor.margemLucro != null || produtoValor.grupoMargemLucro != null) {
      if (this.loja.margemLucroDiferenciada == 'S') {
        const margemLucro: number = produtoValor.margemLucro ? produtoValor.margemLucro : produtoValor.grupoMargemLucro;
        produtoValor.precoVenda = valor ? valor : produtoValor.custoGerencial / (margemLucro > 0 ? margemLucro : 1);
      } else {
        produtoValor.precoVenda = valor ? valor : produtoValor.custoGerencial + (produtoValor.margemLucro ? produtoValor.margemLucro / 100 : produtoValor.grupoMargemLucro / 100) * produtoValor.custoGerencial;
      }
      produtoValor.precoVenda = this.util.arredondar(produtoValor.precoVenda, this.empresaRegraCasasDecimais);
    } else {
      produtoValor.precoVenda = 0;
    }
    this.setMargemReal(produtoValor);
    this.setAlterado(produtoValor);
  }

  setCustoGerencial(produtoValor: ProdutoValor, custoGerencial: number = 0, alterandoManualmente: boolean = false): void {
    produtoValor.idMovimentacao = null;
    if (alterandoManualmente) {
      produtoValor.custoGerencial = custoGerencial;
    } else {
      if (this.loja.margemLucroDiferenciada == 'S') {
        const margemGerencial: number = produtoValor.margemGerencial ? produtoValor.margemGerencial : produtoValor.grupoMargemGerencial;
        produtoValor.custoGerencial = produtoValor.custoReposicao / (margemGerencial > 0 ? margemGerencial : 1);
      } else {
        produtoValor.custoGerencial = produtoValor.custoReposicao + ((produtoValor.margemGerencial ? produtoValor.margemGerencial : produtoValor.grupoMargemGerencial) / 100) * produtoValor.custoReposicao;
      }
      produtoValor.custoGerencial = this.util.arredondar(produtoValor.custoGerencial, this.empresaRegraCasasDecimais);
    }
    this.setPrecoVenda(produtoValor);
    this.setAlterado(produtoValor);
  }

  setMargemReal(produtoValor: ProdutoValor): void {
    produtoValor.idMovimentacao = null;
    if (this.loja.margemLucroDiferenciada == 'S') {
      produtoValor.margemReal = this.util.arredondar(produtoValor.custoGerencial / produtoValor.precoVenda, this.empresaRegraCasasDecimais);
    } else {
      produtoValor.margemReal = this.util.arredondar(this.util.arredondar(produtoValor.precoVenda / produtoValor.custoGerencial - 1) * 100, this.empresaRegraCasasDecimais);
    }
  }

  setValorMinimo(produtoValor: ProdutoValor, valor: number) {
    produtoValor.valorMinimo = valor;
    this.setAlterado(produtoValor);
  }

  setAlterado(produtoValor: ProdutoValor): void {
    produtoValor.alterado = 'S';
  }

  excluirProdutoValor(id: number, nome: string): void {
    super.excluir<ProdutoValor>(id, this.produtoValores, nome, this.bibServico.produtoValor);
  }

  ehPersistirProdutoValor(): void {
    const produtoValores: ProdutoValor[] = this.selecionarProdutoValorPersistir();
    let contagem: number = 0;
    produtoValores.forEach((produtoValor) => {
      if (produtoValor.custoReposicao > produtoValor.custoGerencial) {
        contagem++;
      }
    });
    if (contagem > 0) {
      if (confirm(this.bibDialogo.custoReposicaoMaiorCustoGerencial)) {
        this.persistirProdutoValor(produtoValores);
      }
    } else {
      this.persistirProdutoValor(produtoValores);
    }
  }

  persistirProdutoValor(produtoValores: ProdutoValor[], novo: boolean = false): void {
    if (this.ehValido(produtoValores)) {
      const transporte: Transporte = new Transporte(produtoValores);
      this.persistir(transporte, this.bibServico.produtoValor, novo ? new ProdutoValor() : null).subscribe((res) => {
        if (!this.utilSessao.falha) {
          this.definirId(
            this.produtoValores.filter((produtoValor) => produtoValor.alterado == 'S'),
            this.bibClasse.produtoValor,
            true
          );
          this.produtoValores.forEach((produtoValor) => produtoValor.alterado = 'N');
        }
      });
    }
  }

  ehValido(produtoValores: ProdutoValor[]): boolean {
    if (produtoValores.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.alterePeloMenusUmValorParaEfetuarAlteracao));
    return false;
  }

  selecionarProdutoValorPersistir(): ProdutoValor[] {
    const produtoValores = this.produtoValores.filter((produtoValor) => produtoValor.alterado == 'S');
    produtoValores.forEach((produtoValor) => {
      produtoValor.lancamentoManual = 'S';
    });
    return produtoValores;
  }

  editarCampo(indice: string): void {
    this.indice = indice;
    this.focoEdicao = true;
  }

  visualizar(idProduto: number): void {
    if (this.permissaoProduto) {
      this.utilSessao.setIdentificacao(new Identificacao('idProduto', idProduto));
      this.componente = ProdutoFrmComponent;
    }
  }
}
