import { Component, Input } from '@angular/core';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'usuarioExtraFrm',
  templateUrl: './usuarioExtraFrm.component.html',
})
export class UsuarioExtraFrmComponent extends PaginaComponent {
  @Input() usuario: Usuario = new Usuario();
}
