import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  selector: 'r2001',
  templateUrl: './r2001.component.html',
})
export class R2001Component extends RelatorioComponent {
  public alteracaoLimiteMaximos: any[] = [];
  public apuracaoDeLucros: any[] = [];
  public apuracaoLucroClienteEspeciais: any[] = [];
  public debitoMaiorLimites: any[] = [];
  public devolucaoClienteEspeciais: any[] = [];
  public devolucoes: any[] = [];
  public filtros = filtros;
  public financeiroReceitaDespesas: any[] = [];
  public movimentacaoAjusteEstoques: any[] = [];
  public movimentacaoDistorcidas: any[] = [];
  public movimentacaoFabricantes: any[] = [];
  public movimentacaoFiscais: any[] = [];
  public movimentacaoGrupoProdutos: any[] = [];
  public movimentacaoManuais: any[] = [];
  public movimentacaoManualApelido: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 163).apelido;
  public movimentacaoNegociacoes: any[] = [];
  public movimentacaoOcorrencias: any[] = [];
  public movimentacaoResumoNegociacoes: any[] = [];
  public movimentacaoVendedores: any[] = [];
  public notasNaoTransmitidas: any[] = [];
  public mostraResumoGrupo: boolean = false;
  public mostraResumoFabricante: boolean = false;
  public notasTransmitidas: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().permitir != 'S' ? this.utilSessao.getUsuario().idColaborador : null));
    super.listar(criterios).subscribe(() => {
      this.apuracaoDeLucros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ApuracaoDeLucro').lista;
      this.apuracaoLucroClienteEspeciais = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ApuracaoDeLucroClienteEspecial').lista;
      this.devolucoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Devolucao').lista;
      this.devolucaoClienteEspeciais = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'DevolucaoClienteEspecial').lista;
      this.movimentacaoDistorcidas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoDistorcida').lista;
      this.movimentacaoNegociacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoNegociacao').lista;
      this.financeiroReceitaDespesas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'FinanceiroReceitaDespesa').lista;
      this.movimentacaoFiscais = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoFiscal').lista;
      this.movimentacaoAjusteEstoques = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoAjusteEstoque').lista;
      this.movimentacaoManuais = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoManual').lista;
      this.movimentacaoOcorrencias = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoOcorrencia').lista;
      this.movimentacaoGrupoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoGrupoProduto').lista;
      this.movimentacaoFabricantes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoFabricante').lista;
      this.movimentacaoVendedores = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoVendedor').lista;
      this.movimentacaoResumoNegociacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoResumoNegociacao').lista;
      this.alteracaoLimiteMaximos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'AlteracaoLimiteMaximo').lista;
      this.debitoMaiorLimites = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'DebitoMaiorLimite').lista;
      this.notasNaoTransmitidas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'NotasNaoTransmitidas').lista;
      this.notasTransmitidas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'NotasTransmitidas').lista;
      this.totalizarRelatorios();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.mostraResumoGrupo = this.definirRegra(relatorioAdicional, 180, this.mostraResumoGrupo);
    this.mostraResumoFabricante = this.definirRegra(relatorioAdicional, 181, this.mostraResumoFabricante);
  }

  totalizarRelatorios(): void {
    this.totalizarApuracaoLucro();
    this.totalizarApuracaoLucroEspecial();
    this.totalizarMovimentacoesDistorcidas();
    this.totalizarDebitoMaiorLimite();
    this.totalizarDevolucao();
    this.totalizarDevoclucaoEspecial();
    this.totalizarMovimentacaoGrupoProdutos();
    this.totalizarMovimentacaoFabricantes();
    this.totalizarMovimentacaoVendedores();
    this.totalizarMovimentacaoResumoNegociacoes();
    this.totalizarFinanceiroReceitaDespesas();
    this.totalizarMovimentacaoFiscais();
    this.totalizarMovimentacaoNegociacoes();
    this.totalizarMovimentacaoAjuste();
  }

  totalizarApuracaoLucro(): void {
    if (this.apuracaoDeLucros.length > 0) {
      this.apuracaoDeLucros[0].valorTotalCustoGerencial = 0;
      this.apuracaoDeLucros[0].valorTotalMinimo = 0;
      this.apuracaoDeLucros[0].valorTotalMovimentacao = 0;
      this.apuracaoDeLucros[0].valorTotalLucro = 0;
      this.apuracaoDeLucros[0].valorTotalTabela = 0;
      this.apuracaoDeLucros.forEach((apuracaoLucro) => {
        this.apuracaoDeLucros[0].valorTotalCustoGerencial += apuracaoLucro.custoGerencial;
        this.apuracaoDeLucros[0].valorTotalMinimo += apuracaoLucro.valorMinimo;
        this.apuracaoDeLucros[0].valorTotalMovimentacao += apuracaoLucro.valorTotal;
        this.apuracaoDeLucros[0].valorTotalLucro += apuracaoLucro.lucroValor;
        this.apuracaoDeLucros[0].valorTotalTabela += apuracaoLucro.valorUnitarioTabela;
      });
    }
  }

  totalizarApuracaoLucroEspecial(): void {
    if (this.apuracaoLucroClienteEspeciais.length > 0) {
      this.apuracaoLucroClienteEspeciais[0].valorTotalCustoGerencial = 0;
      this.apuracaoLucroClienteEspeciais[0].valorTotalMinimo = 0;
      this.apuracaoLucroClienteEspeciais[0].valorTotalMovimentacao = 0;
      this.apuracaoLucroClienteEspeciais[0].valorTotalLucro = 0;
      this.apuracaoLucroClienteEspeciais[0].valorTotalTabela = 0;
      this.apuracaoLucroClienteEspeciais.forEach((apuracaoLucroClienteEspecial) => {
        this.apuracaoLucroClienteEspeciais[0].valorTotalCustoGerencial += apuracaoLucroClienteEspecial.custoGerencial;
        this.apuracaoLucroClienteEspeciais[0].valorTotalMinimo += apuracaoLucroClienteEspecial.valorMinimo;
        this.apuracaoLucroClienteEspeciais[0].valorTotalMovimentacao += apuracaoLucroClienteEspecial.valorTotal;
        this.apuracaoLucroClienteEspeciais[0].valorTotalLucro += apuracaoLucroClienteEspecial.lucroValor;
        this.apuracaoLucroClienteEspeciais[0].valorTotalTabela += apuracaoLucroClienteEspecial.valorUnitarioTabela;
      });
    }
  }

  totalizarMovimentacoesDistorcidas(): void {
    if (this.movimentacaoDistorcidas.length > 0) {
      this.movimentacaoDistorcidas[0].valorTotalCustoGerencial = 0;
      this.movimentacaoDistorcidas[0].valorTotalMinimo = 0;
      this.movimentacaoDistorcidas[0].valorTotalMovimentacao = 0;
      this.movimentacaoDistorcidas[0].valorTotalLucro = 0;
      this.movimentacaoDistorcidas[0].valorTotalTabela = 0;
      this.movimentacaoDistorcidas.forEach((movimentacaoDistorcida) => {
        this.movimentacaoDistorcidas[0].valorTotalCustoGerencial += movimentacaoDistorcida.custoGerencial;
        this.movimentacaoDistorcidas[0].valorTotalMinimo += movimentacaoDistorcida.valorMinimo;
        this.movimentacaoDistorcidas[0].valorTotalMovimentacao += movimentacaoDistorcida.valorTotal;
        this.movimentacaoDistorcidas[0].valorTotalLucro += movimentacaoDistorcida.lucroValor;
        this.movimentacaoDistorcidas[0].valorTotalTabela += movimentacaoDistorcida.valorUnitarioTabela;
      });
    }
  }

  totalizarDevolucao(): void {
    if (this.devolucoes.length > 0) {
      this.devolucoes[0].valorTotalCustoGerencial = 0;
      this.devolucoes[0].valorTotalMinimo = 0;
      this.devolucoes[0].valorTotalMovimentacao = 0;
      this.devolucoes[0].valorTotalLucro = 0;
      this.devolucoes[0].valorTotalTabela = 0;
      this.devolucoes.forEach((devolucao) => {
        this.devolucoes[0].valorTotalCustoGerencial += devolucao.custoGerencial;
        this.devolucoes[0].valorTotalMinimo += devolucao.valorMinimo;
        this.devolucoes[0].valorTotalMovimentacao += devolucao.valorTotal;
        this.devolucoes[0].valorTotalLucro += devolucao.lucroValor;
        this.devolucoes[0].valorTotalTabela += devolucao.valorUnitarioTabela;
      });
    }
  }

  totalizarDevoclucaoEspecial(): void {
    if (this.devolucaoClienteEspeciais.length > 0) {
      this.devolucaoClienteEspeciais[0].valorTotalCustoGerencial = 0;
      this.devolucaoClienteEspeciais[0].valorTotalMinimo = 0;
      this.devolucaoClienteEspeciais[0].valorTotalMovimentacao = 0;
      this.devolucaoClienteEspeciais[0].valorTotalLucro = 0;
      this.devolucaoClienteEspeciais[0].valorTotalTabela = 0;
      this.devolucaoClienteEspeciais.forEach((devolucaoClienteEspecial) => {
        this.devolucaoClienteEspeciais[0].valorTotalCustoGerencial += devolucaoClienteEspecial.custoGerencial;
        this.devolucaoClienteEspeciais[0].valorTotalMinimo += devolucaoClienteEspecial.valorMinimo;
        this.devolucaoClienteEspeciais[0].valorTotalMovimentacao += devolucaoClienteEspecial.valorTotal;
        this.devolucaoClienteEspeciais[0].valorTotalLucro += devolucaoClienteEspecial.lucroValor;
        this.devolucaoClienteEspeciais[0].valorTotalTabela += devolucaoClienteEspecial.valorUnitarioTabela;
      });
    }
  }

  totalizarMovimentacaoGrupoProdutos(): void {
    if (this.movimentacaoGrupoProdutos.length > 0) {
      this.movimentacaoGrupoProdutos[0].totalValorDia = 0;
      this.movimentacaoGrupoProdutos[0].totalLucroDia = 0;
      this.movimentacaoGrupoProdutos[0].totalValorMes = 0;
      this.movimentacaoGrupoProdutos[0].totalLucroMes = 0;
      this.movimentacaoGrupoProdutos.forEach((devolucaoClienteEspecial) => {
        this.movimentacaoGrupoProdutos[0].totalValorDia += devolucaoClienteEspecial.valorTotalDia;
        this.movimentacaoGrupoProdutos[0].totalLucroDia += devolucaoClienteEspecial.valorLucroDia;
        this.movimentacaoGrupoProdutos[0].totalValorMes += devolucaoClienteEspecial.valorTotalMes;
        this.movimentacaoGrupoProdutos[0].totalLucroMes += devolucaoClienteEspecial.valorLucroMes;
      });
    }
  }

  totalizarMovimentacaoFabricantes(): void {
    if (this.movimentacaoFabricantes.length > 0) {
      this.movimentacaoFabricantes[0].totalValorDia = 0;
      this.movimentacaoFabricantes[0].totalLucroDia = 0;
      this.movimentacaoFabricantes[0].totalValorMes = 0;
      this.movimentacaoFabricantes[0].totalLucroMes = 0;
      this.movimentacaoFabricantes.forEach((movimentacaoFabricante) => {
        this.movimentacaoFabricantes[0].totalValorDia += movimentacaoFabricante.valorTotalDia;
        this.movimentacaoFabricantes[0].totalLucroDia += movimentacaoFabricante.valorLucroDia;
        this.movimentacaoFabricantes[0].totalValorMes += movimentacaoFabricante.valorTotalMes;
        this.movimentacaoFabricantes[0].totalLucroMes += movimentacaoFabricante.valorLucroMes;
      });
    }
  }

  totalizarMovimentacaoVendedores(): void {
    if (this.movimentacaoVendedores.length > 0) {
      this.movimentacaoVendedores[0].totalValorDia = 0;
      this.movimentacaoVendedores[0].totalLucroDia = 0;
      this.movimentacaoVendedores[0].totalValorMes = 0;
      this.movimentacaoVendedores[0].totalLucroMes = 0;
      this.movimentacaoVendedores.forEach((movimentacaoVendedor) => {
        this.movimentacaoVendedores[0].totalValorDia += movimentacaoVendedor.valorTotalDia;
        this.movimentacaoVendedores[0].totalLucroDia += movimentacaoVendedor.valorLucroDia;
        this.movimentacaoVendedores[0].totalValorMes += movimentacaoVendedor.valorTotalMes;
        this.movimentacaoVendedores[0].totalLucroMes += movimentacaoVendedor.valorLucroMes;
      });
    }
  }

  totalizarMovimentacaoResumoNegociacoes(): void {
    if (this.movimentacaoResumoNegociacoes.length > 0) {
      this.movimentacaoResumoNegociacoes[0].totalValorDia = 0;
      this.movimentacaoResumoNegociacoes[0].totalLucroDia = 0;
      this.movimentacaoResumoNegociacoes[0].totalValorMes = 0;
      this.movimentacaoResumoNegociacoes[0].totalLucroMes = 0;
      this.movimentacaoResumoNegociacoes.forEach((movimentacaoResumoNegociacao) => {
        this.movimentacaoResumoNegociacoes[0].totalValorDia += movimentacaoResumoNegociacao.valorTotalDia;
        this.movimentacaoResumoNegociacoes[0].totalLucroDia += movimentacaoResumoNegociacao.valorLucroDia;
        this.movimentacaoResumoNegociacoes[0].totalValorMes += movimentacaoResumoNegociacao.valorTotalMes;
        this.movimentacaoResumoNegociacoes[0].totalLucroMes += movimentacaoResumoNegociacao.valorLucroMes;
      });
    }
  }

  totalizarFinanceiroReceitaDespesas(): void {
    if (this.financeiroReceitaDespesas.length > 0) {
      this.financeiroReceitaDespesas[0].totalReceitaAmanha = 0;
      this.financeiroReceitaDespesas[0].totalReceitaGeral = 0;
      this.financeiroReceitaDespesas[0].totalDespesaAmanha = 0;
      this.financeiroReceitaDespesas[0].totalDspesaGeral = 0;
      this.financeiroReceitaDespesas.forEach((financeiroReceitaDespesa) => {
        this.financeiroReceitaDespesas[0].totalReceitaAmanha += financeiroReceitaDespesa.receitaAmanha;
        this.financeiroReceitaDespesas[0].totalReceitaGeral += financeiroReceitaDespesa.receitaGeral;
        this.financeiroReceitaDespesas[0].totalDespesaAmanha += financeiroReceitaDespesa.despesaAmanha;
        this.financeiroReceitaDespesas[0].totalDspesaGeral += financeiroReceitaDespesa.despesaGeral;
      });
    }
  }

  totalizarMovimentacaoFiscais(): void {
    if (this.movimentacaoFiscais.length > 0) {
      this.movimentacaoFiscais[0].total = 0;
      this.movimentacaoFiscais[0].totalMes = 0;
      this.movimentacaoFiscais[0].totalCompra = 0;
      this.movimentacaoFiscais[0].totalCompraMes = 0;
      this.movimentacaoFiscais.forEach((movimentacaoFiscal) => {
        this.movimentacaoFiscais[0].total += movimentacaoFiscal.nfeTotal;
        this.movimentacaoFiscais[0].totalMes += movimentacaoFiscal.nfeTotalMes;
        this.movimentacaoFiscais[0].totalCompra += movimentacaoFiscal.compraTotal;
        this.movimentacaoFiscais[0].totalCompraMes += movimentacaoFiscal.compraTotalMes;
      });
    }
  }

  totalizarMovimentacaoNegociacoes(): void {
    if (this.movimentacaoNegociacoes.length > 0) {
      this.movimentacaoNegociacoes[0].valorVenda = 0;
      this.movimentacaoNegociacoes[0].valorDevolucao = 0;
      this.movimentacaoNegociacoes.forEach((movimentacaoNegociacao) => {
        this.movimentacaoNegociacoes[0].valorVenda += movimentacaoNegociacao.venda;
        this.movimentacaoNegociacoes[0].valorDevolucao += movimentacaoNegociacao.devolucao;
      });
    }
  }

  totalizarMovimentacaoAjuste(): void {
    if (this.movimentacaoAjusteEstoques.length > 0) {
      this.movimentacaoAjusteEstoques[0].valorTotalAjuste = 0;
      this.movimentacaoAjusteEstoques[0].valorTotalEntrada = 0;
      this.movimentacaoAjusteEstoques[0].valorTotalSaida = 0;
      this.movimentacaoAjusteEstoques.forEach((movimentacaoAjusteEstoque) => {
        this.movimentacaoAjusteEstoques[0].valorTotalAjuste += movimentacaoAjusteEstoque.totalAjuste;
        this.movimentacaoAjusteEstoques[0].valorTotalEntrada += movimentacaoAjusteEstoque.totalEntrada;
        this.movimentacaoAjusteEstoques[0].valorTotalSaida += movimentacaoAjusteEstoque.totalSaida;
      });
    }
  }

  totalizarDebitoMaiorLimite(): void {
    if (this.debitoMaiorLimites.length > 0) {
      this.debitoMaiorLimites[0].valorTotalTotal = 0;
      this.debitoMaiorLimites.forEach((debitoMaiorLimite) => {
        this.debitoMaiorLimites[0].valorTotalTotal += debitoMaiorLimite.valorTotal;
      });
    }
  }
}
