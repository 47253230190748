import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'valorVencido', posicao: 7 },
  { campo: 'valorOriginal', posicao: 6 },
  { campo: 'acrescimo', posicao: 8 },
  { campo: 'desconto', posicao: 9 },
  { campo: 'valorTotal', posicao: 10 },
  { campo: 'valorCredito', posicao: 11 },
  { campo: 'valorTotal-valorCredito', posicao: 12, tipo: 'expressao' },
];
export default totais;
