import { Component, Input } from '@angular/core';
import { Equipamento } from 'src/app/agrow/modelo/equipamento';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import { ProdutoFormuladoRestricao } from 'src/app/agrow/modelo/ProdutoFormuladoRestricao';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'compendioEletronicoRestricao',
  templateUrl: './compendioEletronicoRestricao.component.html',
})
export class CompendioEletronicoRestricaoComponent extends PaginaComponent {
  @Input() produtoFormulado: ProdutoFormulado = new ProdutoFormulado();
  public estados: Estado[];
  public equipamentos: Equipamento[] = [];
  public culturas: any[] = [];

  ngOnInit(): void {
    this.listarEstado();
    this.listarEquipamento();
    this.listarProdutoFormuladoDose();
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  listarEquipamento(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.equipamento).subscribe((res) => {
      this.equipamentos = this.plainToClass(Equipamento, res) as any;
    });
  }

  listarProdutoFormuladoDose(): void {
    if (this.produtoFormulado.id) {
      this.culturas = [];
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO_FORMULADO', this.produtoFormulado.id)), this.bibServico.produtoFormuladoDose).subscribe((res) => {
        const produtoFormuladoDoses: ProdutoFormuladoDose[] = this.plainToClass(ProdutoFormuladoDose, res) as any;
        for (const produtoFormuladoDose of produtoFormuladoDoses) {
          if (this.culturas.find((cultura) => cultura.nome == produtoFormuladoDose.cultura) == null) {
            this.culturas.push({ id: produtoFormuladoDose.cultura, nome: produtoFormuladoDose.cultura });
          }
        }
        this.culturas = this.culturas.sort((a, b) => {
          return a > b ? 1 : -1;
        });
      });
    }
  }

  adicionarRestricao(): void {
    if (this.produtoFormulado.id) {
      const produtoFormuladoRestricao: ProdutoFormuladoRestricao = new ProdutoFormuladoRestricao();
      this.produtoFormulado.produtoFormuladoRestricoes.push(produtoFormuladoRestricao);
    } else {
      this.utilSessao.setResultado(new Resultado(false, 'Clique em salvar antes e adicionar uma restrição'));
    }
  }

  nomearEstado(idEstado: number): string {
    return this.estados.find((estado) => estado.id == idEstado).nome;
  }

  nomearEquipamento(idEquipamento: number): string {
    return this.equipamentos.find((equipamento) => equipamento.id == idEquipamento).nome;
  }
}
