import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const grupos: Grupo[] = [
  { rotulo: bibDialogo.dataVencimento, campo: 'dataVencimento' },
  { rotulo: bibDialogo.cliente, campo: 'cliente' },
  { rotulo: utilFiltro.apelidoPropriedade, campo: 'propriedade' },
];

export default grupos;
