import { Component, HostListener } from '@angular/core';
import { addDays } from 'date-fns';
import { TabelaPreco } from 'src/app/agrow/modelo/tabelaPreco';
import { TabelaPrecoConfiguracao } from 'src/app/agrow/modelo/tabelaPrecoConfiguracao';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './tabelaPrecoModal.component.html',
})
export class TabelaPrecoModalComponent extends PaginaComponent {
  private idCliente: number;
  private idColaborador: number;
  private idGrupo: number;
  private idProduto: number;
  public produto: string;
  public bloqueado: boolean = false;
  public ehVencido: boolean = false;
  public ehTabelaPrecoConfiguracaoSelecionado: boolean = false;
  public retornaConsulta: boolean = true;
  public tabelaPreco: TabelaPreco = new TabelaPreco();
  public tabelaPrecoConfiguracao: TabelaPrecoConfiguracao = new TabelaPrecoConfiguracao();
  public tabelaPrecos: TabelaPreco[] = [];
  public valor: number;
  public dataMovimentacao: Date;
  public indiceTabelaDePreco: number;

  ngOnInit(): void {
    this.dataMovimentacao = new Date(this.util.coalesce(this.utilSessao.getIdentificacao('data')?.conteudo, new Date()));
    this.valor = this.utilSessao.getIdentificacao('valor')?.conteudo;
    this.idCliente = this.utilSessao.getIdentificacao('idCliente')?.conteudo;
    this.idColaborador = this.utilSessao.getIdentificacao('idColaborador')?.conteudo;
    this.idProduto = this.utilSessao.getIdentificacao('idProduto')?.conteudo;
    this.produto = this.utilSessao.getIdentificacao('produto')?.conteudo + ' - ' + this.util.moeda(this.valor);
    this.retornaConsulta = this.util.coalesce(this.utilSessao.getIdentificacao('retornaConsulta')?.conteudo, true);
    if (this.idCliente && this.idColaborador && this.idProduto && !this.retornaConsulta) {
      this.retornaConsulta = true;
    }
    this.ehListarGrupo();
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    switch (event.keyCode) {
      case this.bibAtalho.enter:
        event.preventDefault();
        this.adicionarTabelaPrecoConfiguracao();
        break;
    }
  }

  ehListarGrupo(): void {
    if (this.idProduto) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.idProduto)), this.bibServico.produto).subscribe((res) => {
        const produto: Produto = this.plainToClass(Produto, res[0]) as any;
        this.idGrupo = produto.idGrupo;
        this.listarTabelaPreco();
      });
    } else {
      this.listarTabelaPreco();
    }
  }

  getCriterios(): Criterio[] {
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    criterios.push(new Criterio('LIMITAR_ID_PRODUTO', this.idProduto ? this.idProduto : 0));
    criterios.push(new Criterio('LIMITAR_ID_CLIENTE', this.idCliente ? this.idCliente : 0));
    criterios.push(new Criterio('LIMITAR_ID_COLABORADOR', this.idColaborador ? this.idColaborador : 0));
    criterios.push(new Criterio('LIMITAR_ID_GRUPO', this.idGrupo ? this.idGrupo : 0));
    return criterios;
  }

  listarTabelaPreco(): void {
    let criterios: Criterio[] = this.getCriterios();
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.tabelaPreco).subscribe((res) => {
      this.tabelaPrecos = this.plainToClass(TabelaPreco, res) as any;
      this.listarTabelaPrecoConfiguracao();
    });
  }

  listarTabelaPrecoConfiguracao(): void {
    const idsTabelaPreco: number[] = this.tabelaPrecos.filter((tabelaPreco) => tabelaPreco.id != null).map((tabelaPreco) => tabelaPreco.id);
    if (idsTabelaPreco.length > 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_TABELA_PRECO', idsTabelaPreco.toString())), this.bibServico.tabelaPrecoConfiguracao).subscribe((res) => {
        this.vincularTabelaPrecoTabelaPrecoConfiguracao(this.plainToClass(TabelaPrecoConfiguracao, res) as any);
        if (this.tabelaPrecos.length == 1) {
          this.setTabelaPreco(this.tabelaPrecos[0]);
        }
      });
    }
  }

  vincularTabelaPrecoTabelaPrecoConfiguracao(tabelaPrecoConfiguracoes: TabelaPrecoConfiguracao[]): void {
    this.tabelaPrecos.forEach((tabelaPreco) => {
      tabelaPreco.tabelaPrecoConfiguracoes = tabelaPrecoConfiguracoes.filter((tabelaPrecoConfiguracao) => tabelaPrecoConfiguracao.idTabelaPreco == tabelaPreco.id);
      tabelaPreco.tabelaPrecoConfiguracoes.forEach((tabelaPrecoConfiguracao) => {
        tabelaPrecoConfiguracao.ehVencido = new Date(tabelaPrecoConfiguracao.dataAte).getTime() < this.dataMovimentacao.getTime() ? true : false;
      });
    });
  }

  setTabelaPreco(tabelaPreco: TabelaPreco): void {
    this.tabelaPreco = tabelaPreco;
    tabelaPreco.tabelaPrecoConfiguracoes.forEach((tabelaPrecoConfiguracao, i) => {
      this.ehSetPopularData(tabelaPreco, tabelaPrecoConfiguracao, i);
      this.calcularValorTabelaPrecoConfiguracao(tabelaPreco, tabelaPrecoConfiguracao, i);
    });
  }

  ehSetPopularData(tabelaPreco: TabelaPreco, tabelaPrecoConfiguracao: TabelaPrecoConfiguracao, indice: number): void {
    if (tabelaPreco.tipo == 1) {
      if (tabelaPreco.tabelaPrecoConfiguracoes[indice - 1]) {
        tabelaPrecoConfiguracao.dataDe = addDays(tabelaPreco.tabelaPrecoConfiguracoes[indice - 1].dataAte, 1);
        tabelaPrecoConfiguracao.dataAte = addDays(new Date(), tabelaPrecoConfiguracao.quantidadeDia);
      } else {
        tabelaPrecoConfiguracao.dataDe = new Date();
        tabelaPrecoConfiguracao.dataAte = addDays(new Date(), tabelaPrecoConfiguracao.quantidadeDia);
      }
    }
  }

  calcularValorTabelaPrecoConfiguracao(tabelaPreco: TabelaPreco, tabelaPrecoConfiguracao: TabelaPrecoConfiguracao, indice: number): void {
    if (tabelaPreco.taxa == 1) {
      let juroValor: number = (tabelaPrecoConfiguracao.juroPercentual / 100) * this.valor;
      tabelaPrecoConfiguracao.valor = this.valor + juroValor;
    } else {
      let juroValor: number;
      if (indice == 0) {
        juroValor = (tabelaPrecoConfiguracao.juroPercentual / 100) * this.valor;
      } else {
        juroValor = (tabelaPrecoConfiguracao.juroPercentual / 100) * tabelaPreco.tabelaPrecoConfiguracoes[indice - 1].valor;
      }
      if (tabelaPreco.taxa == 2) {
        tabelaPrecoConfiguracao.valor = this.valor + juroValor;
      }
      if (tabelaPreco.taxa == 3) {
        tabelaPrecoConfiguracao.valor = this.valor - juroValor;
      }
    }
  }

  setSelecionado(tabelaPrecoConfiguracao: TabelaPrecoConfiguracao) {
    tabelaPrecoConfiguracao.selecionado = tabelaPrecoConfiguracao.selecionado == 'N' ? 'S' : 'N';
    if (tabelaPrecoConfiguracao.selecionado == 'S') {
      this.tabelaPrecoConfiguracao = tabelaPrecoConfiguracao;
      this.ehTabelaPrecoConfiguracaoSelecionado = true;
    } else {
      this.tabelaPrecoConfiguracao = new TabelaPrecoConfiguracao();
      this.ehTabelaPrecoConfiguracaoSelecionado = false;
    }
  }

  adicionarTabelaPrecoConfiguracao(): void {
    if (this.ehTabelaPrecoConfiguracaoSelecionado) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.tabelaPrecoConfiguracao, this.tabelaPrecoConfiguracao));
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecioneAoMenosUmPeriodo));
    }
  }
}
