<layout [titulo]="bibDialogo.contato" [id]="parceiroContato.id">
  <div class="row">
    <texto class="col-sm" [id]="'nomeContato'" [rotulo]="bibDialogo.nome" [campo]="parceiroContato.nome" (alteracao)="parceiroContato.nome = $event" [obrigatorio]="true" [foco]="true" [focoId]="focoId" [maximoCaracteres]="100"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="parceiroContato.ativo" (alteracao)="parceiroContato.ativo = $event"></interruptor>
  </div>
  <div class="row">
    <lista [ngClass]="compacto ? 'col-sm-6' : 'col-sm-4'" [id]="'tipoDeContato'" [rotulo]="bibDialogo.tipoDeContato" [campo]="parceiroContato.idVinculo" (alteracao)="setVinculo($event.id)" [lista]="vinculos" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="listarVinculo()" [idMenu]="permissaoVinculo && permissaoVinculo.inserir == 'S' ? menuVinculo.idMenu : null"></lista>
    <mascara [ngClass]="compacto ? 'col-sm-6' : 'col-sm-4'" [id]="'telefone'" [rotulo]="bibDialogo.telefone" [campo]="parceiroContato.telefone" (alteracao)="setTelefone($event)" [tipo]="bibPropriedade.mascara.telefone"></mascara>
  </div>
  <div class="row">
    <texto class="col-sm-6" [id]="'email'" [rotulo]="bibDialogo.email" [campo]="parceiroContato.email" (alteracao)="parceiroContato.email = $event" [tipo]="bibPropriedade.texto.email" [normal]="true" [ajuda]="bibDialogo.ajudaEmailTamanho" [ajudaDireita]="true" [maximoCaracteres]="50"> </texto>
  </div>  
  <div class="row">
    <texto class="col-sm" [id]="'observacao'" [rotulo]="bibDialogo.observacao" [campo]="parceiroContato.observacao" (alteracao)="parceiroContato.observacao = $event" [maximoCaracteres]="100"></texto>
  </div>
</layout>
<botao [id]="'adicionar'" [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirParceiroContato()" (saindo)="focoId = $event"></botao>
<auditoria *ngIf="parceiroContato.id" [idUsuarioInclusao]="parceiroContato.idUsuarioInclusao" [dataHoraInclusao]="parceiroContato.dataHoraInclusao" [idUsuarioAlteracao]="parceiroContato.idUsuarioAlteracao" [dataHoraAlteracao]="parceiroContato.dataHoraAlteracao"></auditoria>
