export class CfopEmpresaConfiguracaoMovimentacaoContabil {
  public id: number;
  public idCfopEmpresa: number;
  public idLoja: number;
  public idConfMovimentacaoContabil: number;
  public idLojaDestino: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public loja: string;
  public lojaDestino: string;
  public configuracaoMovimentacaoContabil: string;
}
