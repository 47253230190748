import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BibServicoMdfeTecnoSpeed } from 'src/app/biblioteca/bibServicoMdfeTecnoSpeed';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { Condutor } from 'src/app/integracao/tecnospeed/mdfe/condutor';
import { Emit } from 'src/app/integracao/tecnospeed/mdfe/emit';
import { EnderEmit } from 'src/app/integracao/tecnospeed/mdfe/enderEmit';
import { Ide } from 'src/app/integracao/tecnospeed/mdfe/ide';
import { InfAdic } from 'src/app/integracao/tecnospeed/mdfe/infAdic';
import { InfContrato } from 'src/app/integracao/tecnospeed/mdfe/infContrato';
import { InfMDFe } from 'src/app/integracao/tecnospeed/mdfe/infMDFe';
import { InfModal } from 'src/app/integracao/tecnospeed/mdfe/infModal';
import { InfMunCarrega } from 'src/app/integracao/tecnospeed/mdfe/infMunCarrega';
import { InfMunDescarga } from 'src/app/integracao/tecnospeed/mdfe/infMunDescarga';
import { InfNFe } from 'src/app/integracao/tecnospeed/mdfe/infNFe';
import { InfPercurso } from 'src/app/integracao/tecnospeed/mdfe/infPercurso';
import { Tot } from 'src/app/integracao/tecnospeed/mdfe/tot';
import { ReenviarEmail } from 'src/app/integracao/tecnospeed/nfe/reenviarEmail';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Estado } from 'src/app/modelo/estados';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Mdfe } from 'src/app/modelo/mdfe';
import { MdfeDocumentoFiscal } from 'src/app/modelo/mdfeDocumentoFiscal';
import { MdfePercurso } from 'src/app/modelo/mdfePercurso';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { MdfeFrmComponent } from '../mdfe/frm/mdfeFrm.component';
import filtros from './filtro';

@Component({
  templateUrl: './documentoEletronicoMDFe.component.html',
})
export class DocumentoEletronicoMDFeComponent extends PaginaComponent {
  private modalEmt: any;
  public emiteEmHomologacao: boolean = false;
  public emiteEmContingencia: boolean = false;
  public bibServicoTecnoSpeed: bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public componente: any;
  public dataAtualizacao: Date;
  public empresaRegraEmitirNfeContingencia: number = 1;
  public filtros = filtros;
  public mdfes: Mdfe[] = [];
  public intervaloAtualizarNfe: any;
  public operacao: string;
  public servico: string;
  public loja: Loja;
  public lojaCnpj: string;
  public informacaoComplementarProduto: string;
  public usaNfe: boolean;
  public valorMovimentacaoTotalizado: number = 0;
  public mdfesPersistir: Mdfe[];
  public mdfeDocumentosFiscais: MdfeDocumentoFiscal[];
  public mdfePercursos: MdfePercurso[];
  private idsMdfePersistir: number[];
  public valorTotalMdfe: number = 0;
  public pesoBrutoMdfe: number = 0;
  public bibServicoMdfeTecnoSpeed: BibServicoMdfeTecnoSpeed = new BibServicoMdfeTecnoSpeed();
  public arquivoTx2: string = '';
  private estados: Estado[];
  public lojaMdfeContingencia: boolean = true;

  ngOnInit(): void {
    this.servico = 'mdfe';
    this.ehListarAtalhoMdfe();
    if (this.mdfes.length == 0) {
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.utilSessao.getIdLoja());
      this.emiteEmContingencia = loja.mdfeEnvio == 'NORMAL' ? false : true;
      this.emiteEmHomologacao = loja.mdfeAmbiente == 1 ? false : true;
    }
    clearInterval(this.intervaloAtualizarNfe);
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
    clearInterval(this.intervaloAtualizarNfe);
  }

  ehListarAtalhoMdfe(): void {
    const idMDFe: number = this.utilSessao.getIdentificacao('idMDFe')?.conteudo;
    if (idMDFe) {
      this.listar([], 0, 0, [idMDFe]);
    }
  }

  private listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, idMDFes: number[] = []): void {
    clearInterval(this.intervaloAtualizarNfe);
    criterios.push(new Criterio('STATUS', 'S'));
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    if (idMDFes.length > 0) {
      criterios.push(new Criterio('IDS', idMDFes.toString()));
    }

    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.mdfe).subscribe((res) => {
      this.mdfes = this.plainToClass(Mdfe, res) as any;
      if (this.mdfes.length > 0) {
        this.listarEmpresa(this.mdfes[0].idEmpresa).subscribe((res) => {
          const empresa: Empresa = this.plainToClass(Empresa, res[0]) as any;
          this.empresaRegraEmitirNfeContingencia = empresa.regraEmitirNfeContingencia;
          this.lojaCnpj = this.util.retirarCaracteresInvalidos(this.utilSessao.getLojas().find((loja) => loja.id == this.mdfes[0].idLoja).cnpj);
          this.listarMdfe(this.mdfes.map((mdfe) => mdfe.id));
          this.loja = this.utilSessao.getLojas().find((loja) => loja.id == this.mdfes[0].idLoja);
          this.emiteEmContingencia = this.loja.mdfeEnvio == 'NORMAL' ? false : true;
          this.emiteEmHomologacao = this.loja.mdfeAmbiente == 1 ? false : true;
        });
      }
    });
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', () => {
      abaAtiva = abaAtiva == true ? false : true;
    });
  }

  private listarEmpresa(idEmpresa: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idEmpresa)), this.bibServico.empresa);
  }

  private listarMdfe(idMdfes: number[]): void {
    let criterios: Criterio = new Criterio('IDS_MDFE', idMdfes.toString());
  }

  setMdfeSelecionado(mdfe: Mdfe): void {
    mdfe.selecionado = mdfe.selecionado == 'S' ? 'N' : 'S';
  }

  tratarMensagemPersistir(retorno: string, mdfe: Mdfe): void {
    const statusMdfe: string = retorno.substr(0, retorno.indexOf(','));
    retorno = retorno.substr(retorno.indexOf(',') + 1, 10000);
    const chave: string = retorno.substr(0, retorno.indexOf(','));
    retorno = retorno.substr(retorno.indexOf(',') + 1, 10000);
    const protocoloEnvio: string = retorno.substr(0, retorno.indexOf(','));
    retorno = retorno.substr(retorno.indexOf(',') + 1, 10000);
    const numero: string = retorno.substr(0, retorno.indexOf(','));
    retorno = retorno.substr(retorno.indexOf(',') + 1, 10000);
    const idSituacaoReceita: string = retorno.substr(0, retorno.indexOf(','));
    retorno = retorno.substr(retorno.indexOf(',') + 1, 10000);
    const situacaoDescricao: string = retorno.substr(0, retorno.indexOf(','));
    retorno = retorno.substr(retorno.indexOf(',') + 1, 10000);
    const protocoloCancelamento: string = retorno.substr(0, 10000);

    mdfe.chave = chave;
    mdfe.protocolo = protocoloEnvio;
    mdfe.numero = Number(numero);
    mdfe.situacao = statusMdfe == 'AUTORIZADA' ? 3 : statusMdfe == 'ENCERRADA' ? 4 : statusMdfe == 'REJEITADA' ? 5 : statusMdfe == 'CANCELADA' ? 6 : 2;
    mdfe.situacaoDescricao = statusMdfe == 'AUTORIZADA' ? 'MDF-E AUTORIZADO' : statusMdfe == 'ENCERRADA' ? 'MDF-E ENCERRADO' : statusMdfe == 'CANCELADA' ? 'MDF-E CANCELADO' : statusMdfe + ': ' + idSituacaoReceita + ' - ' + situacaoDescricao;
    mdfe.protocoloCancelamento = protocoloCancelamento == '' ? null : protocoloCancelamento;
    mdfe.chaveCancelamento = mdfe.chaveCancelamento ? mdfe.chaveCancelamento : idSituacaoReceita == '135' ? chave : null;
    mdfe.ativo = mdfe.ativo == 'S' && statusMdfe == 'CANCELADA' ? 'N' : mdfe.ativo;
    mdfe.status = mdfe.status == 'S' && statusMdfe == 'CANCELADA' ? 'N' : mdfe.status;
    mdfe.protocoloCancelamento = statusMdfe == 'CANCELADA' ? protocoloCancelamento : '';

    if (statusMdfe == 'REJEITADA') {
      this.descartaMdfe(mdfe);
    }

    let mdfes: Mdfe[] = [];
    mdfes.push(mdfe);
    this.persistirMdfe(mdfes);
  }

  consultarMdfeTecnospeed(mdfe: Mdfe): void {
    if (!this.utilSessao.falha) {
      this.comunicacaoTecnoSpeedMDFeService.consultar(this.bibServicoMdfeTecnoSpeed.getUrlConsultaMdfe(this.servico), mdfe.id, this.lojaCnpj).subscribe((res) => {
        this.tratarMensagemPersistir(res, mdfe);
      });
    }
  }

  listarMdfeConfirmacao(criterios: Criterio[]): Observable<Mdfe> {
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.mdfe);
  }

  persistirCancelamento(mdfe: Mdfe): void {
    this.utilSessao.iniciarProcesso();
    this.persistirMdfe([mdfe]).subscribe(() => {
      this.mdfesPersistir = [mdfe];
      if (!this.utilSessao.falha) {
        this.comunicacaoTecnoSpeedMDFeService.persistir(this.bibServicoMdfeTecnoSpeed.getUrlCancelaMdfe(this.servico), mdfe.chave, this.lojaCnpj).subscribe(
          (res) => {
            this.setMdfeRetornoEnvioTecnospeed((this.mdfes = this.mdfes.filter((mdfeAtual) => mdfeAtual.id == mdfe.id)));
            this.persistirMdfe(this.mdfesPersistir);
          },
          (erro) => {
            this.setMdfeRetornoEnvioTecnospeed((this.mdfes = this.mdfes.filter((mdfeAtual) => mdfeAtual.id == mdfe.id)));
            this.persistirMdfe(this.mdfesPersistir);
          }
        );
      }
    });
    this.utilSessao.finalizarProcesso();
  }

  descartaMdfe(mdfe: Mdfe): void {
    this.utilSessao.iniciarProcesso();
    this.comunicacaoTecnoSpeedMDFeService.persistir(this.bibServicoMdfeTecnoSpeed.getUrlDescarta(this.servico), mdfe.chave, this.lojaCnpj).subscribe();
    this.utilSessao.finalizarProcesso();
  }

  persistirEncerramento(mdfe: Mdfe): void {
    this.utilSessao.iniciarProcesso();
    this.persistirMdfe([mdfe]).subscribe(() => {
      if (!this.utilSessao.falha) {
        const dataEncerramento = this.formatarData(new Date());
        this.comunicacaoTecnoSpeedMDFeService.persistirEncerramento(this.bibServicoMdfeTecnoSpeed.getUrlEncerraMdfe(this.servico), mdfe.chave, this.lojaCnpj, mdfe.ibgeCidadeFinal, dataEncerramento).subscribe(
          (res) => {
            this.setMdfeRetornoEnvioTecnospeed((this.mdfes = this.mdfes.filter((mdfeAtual) => mdfeAtual.id == mdfe.id)));
            this.persistirMdfe([mdfe]);
          },
          (erro) => {
            this.setMdfeRetornoEnvioTecnospeed((this.mdfes = this.mdfes.filter((mdfeAtual) => mdfeAtual.id == mdfe.id)));
            this.persistirMdfe([mdfe]);
          }
        );
      }
    });
    this.utilSessao.finalizarProcesso();
  }

  verificarDescricao(descricao: string): string {
    if (descricao && descricao.length > 1000) {
      return descricao.substring(0, 997) + '...';
    }
    return descricao;
  }

  persistirMdfe(mdfes: Mdfe[]): Observable<any> {
    if (mdfes[0].situacao != 2) {
      if (mdfes[0].situacao != 5) {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.mdfeMensagemRetornoSucesso, [mdfes[0].situacaoDescricao, mdfes[0].numero, mdfes[0].chave])));
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.mdfeMensagemRetornoErro, [mdfes[0].situacaoDescricao])));
      }
    }
    return this.persistir(new Transporte(mdfes), this.bibServico.mdfe, null);
  }

  iniciarProcessamentoMdfe(): void {
    const ids = this.mdfes.filter((mdfe) => mdfe.selecionado == 'S').map((mdfe) => mdfe.id);
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_', ids.toString())), this.bibServico.mdfe).subscribe((res) => {
      this.listarEstado();
      this.mdfesPersistir = this.mdfes.filter((mdfe) => mdfe.selecionado == 'S');
      if (this.mdfesPersistir.length == 1) {
        if (this.mdfesPersistir[0].situacao != 3 && this.mdfesPersistir[0].situacao != 4 && this.mdfesPersistir[0].situacao != 6) {
          this.utilSessao.iniciarProcesso();
          this.idsMdfePersistir = this.mdfesPersistir.map((mdfe) => mdfe.id);
          this.listarDocumentosFiscais();
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarValidacaoMDFe));
        }
      } else {
        if (this.mdfesPersistir.length == 0) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionePeloMenosUmRegistro));
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarValidacaoMDFeSomenteUm));
        }
      }
    });
  }

  listarDocumentosFiscais() {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MDFES', this.idsMdfePersistir.toString())), this.bibServico.mdfeDocumentoFiscal).subscribe((res) => {
      this.mdfeDocumentosFiscais = this.plainToClass(MdfeDocumentoFiscal, res) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.mdfeDocumentosFiscais[0].idMovimentacao.toString())), this.bibServico.movimentacao).subscribe((res) => {
        this.listarPercurso();
      });
    });
  }

  listarPercurso() {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MDFES', this.idsMdfePersistir.toString())), this.bibServico.mdfePercurso).subscribe((res) => {
      this.mdfePercursos = this.plainToClass(MdfePercurso, res) as any;
      this.emitirMdfe();
    });
  }

  ehEnviarEmail(idParceiro: number, mdfe: Mdfe): void {
    if (mdfe.situacao != 3 && mdfe.situacao != 4) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarEmailMDFeSomenteEnviadoEncerrado));
    } else {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiro)), this.bibServico.parceiro).subscribe((res) => {
        const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
        if (parceiro && parceiro.email) {
          if (confirm(this.util.substituir(this.bibDialogo.enviarNotaFiscalParaCliente, [parceiro.email]))) {
            this.enviarEmail(parceiro.email, mdfe);
          }
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.transportadoraNaoPossuiEmailCadastrado));
        }
      });
    }
  }

  enviarEmail(email: string, mdfe: Mdfe): void {
    let reenviarEmail: ReenviarEmail = new ReenviarEmail();
    if (email.split(';').length > 0) {
      email.split(';').forEach((email) => {
        reenviarEmail.destinatarios.push(email);
      });
    }
    this.comunicacaoTecnoSpeedMDFeService.enviarEmail(this.bibServicoMdfeTecnoSpeed.getUrlEnviarEmail(this.servico), reenviarEmail, this.lojaCnpj, mdfe.chave, mdfe.numero).subscribe(
      (res) => {
        this.utilSessao.setResultado(new Resultado(true, res.mensagem));
      },
      (erro) => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelReenviarEmail));
      }
    );
  }

  apenasNumeros(valor: string): string {
    let numsStr = valor.replace(/[^0-9]/g, '');
    return numsStr;
  }

  gerarIde(mdfe: Mdfe) {
    const ide: Ide = new Ide();
    ide.cUF_5 = this.loja.estadoIbge;
    ide.tpAmb_6 = this.loja.mdfeAmbiente;
    ide.tpEmit_7 = 2; //Transportador de Carga Própria
    ide.serie_9 = mdfe.serie;
    ide.nMDF_10 = mdfe.numero ? mdfe.numero : 0;
    ide.cMDF_11 = mdfe.id;
    ide.cDV_12 = '';
    ide.dhEmi_14 = this.formatarData(mdfe.data) + 'T00:00:00-03:00';

    if (this.mdfeDocumentosFiscais[0].entradaSaidaInterna == 1) {
      ide.UFIni_18 = mdfe.estadoCidadeFinal;
      ide.UFFim_19 = this.estados.find((estado) => estado.id == this.loja.idEstado).abreviacao;
    } else {
      ide.UFIni_18 = this.estados.find((estado) => estado.id == this.loja.idEstado).abreviacao;
      ide.UFFim_19 = mdfe.estadoCidadeFinal;
    }

    ide.tpEmis_15 = this.emiteEmContingencia ? '2' : '1';
    this.converterTx2('', '', ide);
    this.gerarEmit(mdfe);
  }

  gerarEmit(mdfe: Mdfe) {
    const emit: Emit = new Emit();
    emit.CNPJ_26 = this.apenasNumeros(this.loja.cnpj);
    emit.IE_27 = this.apenasNumeros(this.loja.inscricaoEstadual);
    emit.xNome_28 = this.loja.razaoSocial;
    emit.xFant_29 = this.loja.nomeFantasia;
    this.converterTx2('', '', emit);
    this.gerarEnderEmit(mdfe);
  }

  gerarInfModal(mdfe) {
    const infModal: InfModal = new InfModal();
    this.converterTx2('', '', infModal);
    this.gerarTot(mdfe);
  }

  gerarEnderEmit(mdfe: Mdfe) {
    const enderEmit: EnderEmit = new EnderEmit();
    enderEmit.xLgr_31 = this.loja.endereco;
    enderEmit.nro_32 = this.loja.enderecoNumero.toString();
    enderEmit.xCpl_33 = this.loja.complemento;
    enderEmit.xBairro_34 = this.loja.bairro;
    enderEmit.cMun_35 = this.apenasNumeros(this.loja.cidadeIbge);
    enderEmit.xMun_36 = this.loja.cidade;
    enderEmit.CEP_37 = this.apenasNumeros(this.loja.cep);
    enderEmit.UF_38 = this.loja.estado;
    enderEmit.fone_39 = this.apenasNumeros(this.loja.telefone);
    enderEmit.email_40 = this.loja.email;
    this.converterTx2('', '', enderEmit);
    this.gerarInfModal(mdfe);
  }

  gerarTot(mdfe: Mdfe) {
    const tot: Tot = new Tot();
    tot.qNFe_71 = this.mdfeDocumentosFiscais.length;
    tot.vCarga_73 = this.valorTotalMdfe.toFixed(2);
    tot.qCarga_75 = this.pesoBrutoMdfe.toFixed(4);
    this.converterTx2('', '', tot);
    this.gerarInfAdic(mdfe);
  }

  gerarInfMunDescarga(mdfe: Mdfe) {
    const infMunDescarga: InfMunDescarga = new InfMunDescarga();
    if (this.mdfeDocumentosFiscais[0].entradaSaidaInterna == 1) {
      infMunDescarga.cMunDescarga_46 = this.loja.cidadeIbge;
      infMunDescarga.xMunDescarga_47 = this.loja.cidade;
    } else {
      infMunDescarga.cMunDescarga_46 = mdfe.ibgeCidadeFinal;
      infMunDescarga.xMunDescarga_47 = mdfe.cidadeFinal;
    }

    this.converterTx2('incluirinfMunDescarga', 'salvarinfMunDescarga', infMunDescarga);
    this.gerarInfNFe(this.mdfeDocumentosFiscais, mdfe);
  }

  gerarInfMunCarrega(mdfe: Mdfe) {
    const infMunCarrega: InfMunCarrega = new InfMunCarrega();

    if (this.mdfeDocumentosFiscais[0].entradaSaidaInterna == 1) {
      infMunCarrega.cMunCarrega_21 = mdfe.ibgeCidadeFinal;
      infMunCarrega.xMunCarrega_22 = mdfe.cidadeFinal;
    } else {
      infMunCarrega.cMunCarrega_21 = this.loja.cidadeIbge;
      infMunCarrega.xMunCarrega_22 = this.loja.cidade;
    }
    this.converterTx2('incluirinfMunCarrega', 'salvarinfMunCarrega', infMunCarrega);
    this.gerarInfPercurso(this.mdfePercursos, mdfe);
  }

  gerarInfPercurso(mdfePercursos: MdfePercurso[], mdfe: Mdfe) {
    let infPercursos: InfPercurso[] = [];
    mdfePercursos.forEach((mdfePercurso) => {
      let infPercurso: InfPercurso = new InfPercurso();
      infPercurso.UFPer_24 = mdfePercurso.estado;
      infPercursos.push(infPercurso);
    });
    this.converterTx2Lista('incluirinfPercurso', 'salvarinfPercurso', infPercursos);
    this.gerarInfContrato(mdfe);
  }

  gerarInfNFe(mdfeDocumentosFiscais: MdfeDocumentoFiscal[], mdfe: Mdfe) {
    let infNFes: InfNFe[] = [];
    mdfeDocumentosFiscais.forEach((mdfeDocumentoFiscal) => {
      let infNFe: InfNFe = new InfNFe();
      infNFe.chNFe_58 = mdfeDocumentoFiscal.chaveFiscal;
      infNFes.push(infNFe);
    });
    this.converterTx2Lista('incluirinfNFe', 'salvarinfNFe', infNFes);
    this.gerarInfMunCarrega(mdfe);
  }

  gerarInfContrato(mdfe: Mdfe) {
    const infContrato: InfContrato = new InfContrato();
    infContrato.placa_rodo_6 = mdfe.placa;
    infContrato.UF_rodo_36 = mdfe.placaUf;
    this.converterTx2('incluirrodo', 'salvarrodo', infContrato);
    this.gerarCondutor(mdfe);
  }

  gerarCondutor(mdfe: Mdfe) {
    const condutor: Condutor = new Condutor();
    condutor.CPF_rodo_14 = this.apenasNumeros(mdfe.motoristaCpf);
    condutor.xNome_rodo_13 = mdfe.motoristaNome;
    this.converterTx2('incluircondutor', 'salvarcondutor', condutor);
  }

  gerarInfAdic(mdfe: Mdfe) {
    const infAdic: InfAdic = new InfAdic();
    infAdic.infCpl_80 = mdfe.informacaoComplementar ? mdfe.informacaoComplementar : '';
    this.converterTx2('', 'salvarenviMDFe', infAdic);
    this.gerarInfMunDescarga(mdfe);
  }

  gerarInfMDFe(mdfe: Mdfe) {
    const infMDFe: InfMDFe = new InfMDFe();
    infMDFe.Versao_2 = '3.00';
    infMDFe.idLote = '1';
    this.converterTx2('incluirenviMDFe', '', infMDFe);
    this.gerarIde(mdfe);
  }

  gerarEnviMDFe(mdfe: Mdfe) {
    this.mdfeDocumentosFiscais.forEach((mdfeDocumentoFiscal) => {
      this.valorTotalMdfe += mdfeDocumentoFiscal.valorTotalMovimentacao;
      this.pesoBrutoMdfe += mdfeDocumentoFiscal.pesoBrutoMovimentacao;
    });
    this.gerarInfMDFe(mdfe);
  }

  private populandoMdfe() {
    this.valorTotalMdfe = 0;
    this.pesoBrutoMdfe = 0;
    this.arquivoTx2 = 'Formato=tx2' + '\n';
    this.loja = this.utilSessao.getLojas().find((loja) => loja.id == this.mdfes[0].idLoja);
    this.mdfes = this.mdfes.filter((mdfe) => mdfe.selecionado == 'S');
    this.mdfes.forEach((mdfe) => {
      this.gerarEnviMDFe(mdfe);
    });
  }

  converterTx2(tagInicial: string, tagFinal: string, objeto: any) {
    if (tagInicial != '' && objeto) {
      this.arquivoTx2 += '\n' + tagInicial;
    }

    for (const property in objeto) {
      if (objeto[property] != undefined) {
        this.arquivoTx2 += '\n' + property + '=' + objeto[property];
      }
    }

    if (tagFinal != '' && objeto) {
      this.arquivoTx2 += '\n' + tagFinal + '\n';
    }
  }

  converterTx2Lista(tagInicial: string, tagFinal: string, listaObjetos: any[]) {
    listaObjetos.forEach((objeto) => {
      this.converterTx2(tagInicial, tagFinal, objeto);
    });
  }

  private emitirMdfe(): void {
    this.populandoMdfe();
    this.comunicacaoTecnoSpeedMDFeService.persistir(this.bibServicoMdfeTecnoSpeed.getUrlMdfe(this.servico), this.arquivoTx2, this.lojaCnpj).subscribe(
      (res) => {
        this.setMdfeRetornoEnvioTecnospeed((this.mdfes = this.mdfes.filter((mdfe) => mdfe.selecionado == 'S')));
        this.persistirMdfe(this.mdfesPersistir);
      },
      (erro) => {
        this.setMdfeRetornoEnvioTecnospeed((this.mdfes = this.mdfes.filter((mdfe) => mdfe.selecionado == 'S')));
        this.persistirMdfe(this.mdfesPersistir);
      }
    );
    this.utilSessao.finalizarProcesso();
  }

  // setMdfeRetornoEncerramentoCancelamentoTecnospeed(mdfes: Mdfe[]) {
  //   mdfes.forEach((mdfe) => {
  //     this.consultarMdfeTecnospeed(mdfe);
  //   });
  // }

  setMdfeRetornoEnvioTecnospeed(mdfes: Mdfe[]) {
    mdfes.forEach((mdfe) => {
      this.consultarMdfeTecnospeed(mdfe);
    });
  }

  formatarDataPadrao(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }

  baixarXml(mdfe: Mdfe): void {
    if (mdfe.situacao != 3 && mdfe.situacao != 4 && mdfe.situacao != 6) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.downloadValidacao, ['XML'])));
    } else {
      this.comunicacaoTecnoSpeedMDFeService.consumirXml(this.bibServicoMdfeTecnoSpeed.getUrlBaixaXmlMdfe(this.servico), this.lojaCnpj, mdfe.chave, mdfe.situacao == 6 ? 'Cancelamento' : mdfe.situacao == 4 ? 'Encerramento' : '').subscribe(
        (res) => {
          this.downloadXml(res);
        },
        (erro) => {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
        }
      );
    }
  }

  baixarPdf(mdfe: Mdfe): void {
    if (mdfe.situacao != 3 && mdfe.situacao != 4 && mdfe.situacao != 6) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.downloadValidacao, ['PDF'])));
    } else {
      this.comunicacaoTecnoSpeedMDFeService.consumirPdf(this.bibServicoMdfeTecnoSpeed.getUrlBaixaPdfMdfe(this.servico), this.lojaCnpj, mdfe.chave).subscribe(
        (res) => {
          this.downloadPdf(res);
        },
        (erro) => {
          var reader = new FileReader();
          reader.readAsDataURL(erro.error.text);
          this.downloadPdf(erro.error.text);
        }
      );
    }
  }

  downloadPdf(data: any): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  downloadXml(data: any): void {
    const blob = new Blob([data], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    var link: any = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
  }

  abrirModalMdfe(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MdfeFrmComponent;
  }

  enviarCancelamento(mdfe: Mdfe) {
    if (mdfe.situacao != 3) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarCancelamentoMDFeSomenteEnviado));
    } else {
      if (confirm(this.bibDialogo.enviarSolicitacaoCancelamento)) {
        this.persistirCancelamento(mdfe);
      }
    }
  }

  enviarEncerramento(mdfe: Mdfe) {
    if (mdfe.situacao != 3) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.enviarEncerramentoMDFeSomenteEnviado));
    } else {
      if (confirm(this.bibDialogo.enviarSolicitacaoEncerramento)) {
        this.persistirEncerramento(mdfe);
      }
    }
  }
}
