import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.diaMes, campo: 'diaMes' },
  { rotulo: bibDialogo.mes, campo: 'mes' },
  { rotulo: bibDialogo.conta, campo: 'contaContabil' },
];

export default grupos;
