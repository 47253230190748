import { Type } from 'class-transformer';

export class NfeXmlPdf {
  public id: number;
  @Type(() => Date)
  public dataInicio: Date;
  @Type(() => Date)
  public dataFim: Date;
  public idParceiro: number;
  public tipo: number = 1;
  public situacao: number = 1;
  public status: number = 1;
  public idEmpresa: number;
  public idLoja: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public parceiro: string;
  public loja: string;
}
