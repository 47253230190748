import { Component } from '@angular/core';
import { ConfiguracaoFinanceiroTotalContabil } from 'src/app/modelo/configuracaoFinanceiroTotalContabil';
import { ConfiguracaoMovimentacaoTotalContabil } from 'src/app/modelo/configuracaoMovimentacaoTotalContabil';
import { ConfiguracaoTransferenciaTotalContabil } from 'src/app/modelo/configuracaoTransferenciaTotalContabil';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoLancamentoContabil } from 'src/app/modelo/tipoLancamentoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './configuracaoContabilTotalFrm.component.html',
})
export class ConfiguracaoContabilTotalFrmComponent extends PaginaComponent {
  public classe: any;
  public criterios: Criterio[] = [new Criterio('NOME_NUMERACAO'), new Criterio('SEM_ID_ZERO', 'S'), new Criterio('TODOS_OU_AUTOMATICO', 'S')];
  public criterioHistorico: Criterio[] = [];
  public objeto: any;
  public servico: string;
  public tipoLancamentoContabeis: TipoLancamentoContabil[] = [];
  public totais: any[] = [];

  public debitoCredito: any = [
    { id: '1', nome: this.bibDialogo.debito.toUpperCase() },
    { id: '-1', nome: this.bibDialogo.credito.toUpperCase() },
    { id: '0', nome: this.bibDialogo.debitoCredito.toUpperCase() },
  ];

  public lancamentoDiretos: any = [
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.aguardandoNfe.toUpperCase() },
  ];

  public alterarDebitoCredito: any = [
    { id: '1', nome: this.bibDialogo.debito.toUpperCase() },
    { id: '-1', nome: this.bibDialogo.credito.toUpperCase() },
  ];

  public origens: any = [
    { id: '1', nome: this.bibDialogo.todas.toUpperCase() },
    { id: '2', nome: this.bibDialogo.tradicional.toUpperCase() },
    { id: '3', nome: this.bibDialogo.devolucao.toUpperCase() },
  ];

  ngOnInit(): void {
    switch (this.utilSessao.getIdentificacao('servico')?.conteudo) {
      case this.bibServico.configuracaoMovimentacaoContabil:
        this.servico = this.bibServico.configuracaoMovimentacaoTotalContabil;
        this.classe = ConfiguracaoMovimentacaoTotalContabil;
        this.objeto = new ConfiguracaoMovimentacaoTotalContabil();
        this.criterioHistorico.push(new Criterio('SEM_ROTULO_PARCELA_TIPO_DE_TITULO', 'S'));
        break;
      case this.bibServico.configuracaoFinanceiroContabil:
        this.servico = this.bibServico.configuracaoFinanceiroTotalContabil;
        this.classe = ConfiguracaoFinanceiroTotalContabil;
        this.objeto = new ConfiguracaoFinanceiroTotalContabil();
        break;
      case this.bibServico.configuracaoTransferenciaContabil:
        this.servico = this.bibServico.configuracaoTransferenciaTotalContabil;
        this.classe = ConfiguracaoTransferenciaTotalContabil;
        this.objeto = new ConfiguracaoTransferenciaTotalContabil();
        this.criterioHistorico.push(new Criterio('SEM_ROTULO', 'S'));
        break;
    }
    this.listarTotalContabil();
    this.listarTipoLancamentoContabil();
    this.ehAlteracao();
  }

  listarTotalContabil(): void {
    let servico: any = this.servico == this.bibServico.configuracaoMovimentacaoTotalContabil ? this.bibServico.totalMovimentacaoContabil : this.servico == this.bibServico.configuracaoFinanceiroTotalContabil ? this.bibServico.totalFinanceiroContabil : this.bibServico.totalTransferenciaContabil;
    this.comunicacaoService.listar(new Transporte(), servico).subscribe((res) => {
      this.totais = this.plainToClass(this.classe, res) as any;
    });
  }

  listarTipoLancamentoContabil(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoLancamentoContabil).subscribe((res) => {
      this.tipoLancamentoContabeis = this.plainToClass(TipoLancamentoContabil, res) as any;
      if (this.tipoLancamentoContabeis.length == 1) {
        this.setTipoLancamnetoContabil(this.tipoLancamentoContabeis[0]);
      }
    });
  }

  ehAlteracao(): void {
    this.objeto = this.plainToClass(this.classe, this.ehAtualizacao(this.objeto));
    if (this.objeto.id && !this.objeto.alterado) {
      this.listarObjeto();
    }
  }

  setTipoLancamnetoContabil(tipoLancamnetoContabil: TipoLancamentoContabil): void {
    if (tipoLancamnetoContabil) {
      this.objeto.idTipoLancamentoContabil = tipoLancamnetoContabil.id;
      this.objeto.tipoLancamentoContabil = tipoLancamnetoContabil.nome;
    } else {
      this.objeto.idTipoLancamentoContabil = null;
      this.objeto.tipoLancamentoContabil = null;
    }
  }

  listarObjeto(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.objeto.id)), this.servico).subscribe((res) => {
      this.objeto = this.plainToClass(this.classe, res[0]) as any;
    });
  }

  persistirObjeto(): void {
    if (this.ehValido()) {
      const historicoDebitoCredito: number = this.objeto.debitoCredito;
      let configuracaoContabeis: any[] = [];
      this.objeto.debitoCredito = historicoDebitoCredito == 0 ? 1 : historicoDebitoCredito;
      this.objeto.alterado = true;
      configuracaoContabeis.push(this.objeto);
      if (historicoDebitoCredito == 0) {
        let configuracaoContabilCredito: any = this.criarContaCredito();
        configuracaoContabeis.push(configuracaoContabilCredito);
      }
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.servico == this.bibServico.configuracaoMovimentacaoTotalContabil ? 'ConfiguracaoMovimentacaoTotalContabil' : 'ConfiguracaoFinanceiroTotalContabil', configuracaoContabeis));
    }
  }

  criarContaCredito(): any {
    let configuracaoContabil: any = this.servico == this.bibServico.configuracaoMovimentacaoTotalContabil ? new ConfiguracaoMovimentacaoTotalContabil() : new ConfiguracaoFinanceiroTotalContabil();
    configuracaoContabil.debitoCredito = -1;
    configuracaoContabil.contaContabil = this.objeto.contaCredito;
    configuracaoContabil.idContaContabil = this.objeto.idContaCredito;
    configuracaoContabil.historico = this.objeto.historico;
    configuracaoContabil.alterado = true;
    configuracaoContabil.tipoLancamentoContabil = this.objeto.tipoLancamentoContabil;
    configuracaoContabil.idTipoLancamentoContabil = this.objeto.idTipoLancamentoContabil;
    if (this.bibServico.configuracaoMovimentacaoTotalContabil == this.servico) {
      configuracaoContabil.idTotalMovimentacaoContabil = this.objeto.idTotalMovimentacaoContabil;
      configuracaoContabil.totalMovimentacaoContabil = this.objeto.totalMovimentacaoContabil;
    } else if (this.bibServico.configuracaoTransferenciaTotalContabil == this.servico) {
      configuracaoContabil.idTotalTransferenciaContabil = this.objeto.idTotalTransferenciaContabil;
      configuracaoContabil.totalTransferenciaContabil = this.objeto.totalTransferenciaContabil;
    } else {
      configuracaoContabil.idTotalFinanceiroContabil = this.objeto.idTotalFinanceiroContabil;
      configuracaoContabil.totalFinanceiroContabil = this.objeto.totalFinanceiroContabil;
      configuracaoContabil.lancamentoDireto = this.objeto.lancamentoDireto;
    }
    return configuracaoContabil;
  }

  ehValido(): boolean {
    let ehValido: boolean = true;
    if (this.objeto.idTipoLancamentoContabil && this.objeto && this.objeto.idContaContabil && this.objeto.historico && (this.servico == this.bibServico.configuracaoMovimentacaoTotalContabil ? this.objeto.idTotalMovimentacaoContabil : this.servico == this.bibServico.configuracaoFinanceiroTotalContabil ? this.objeto.idTotalFinanceiroContabil : this.objeto.idTotalTransferenciaContabil)) {
      ehValido = true;
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      ehValido = false;
    }
    if (this.objeto && this.objeto.debitoCredito == '0' && this.objeto.idContaContabil == this.objeto.idContaCredito) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.contasContabeisDevemSerDiferentes));
      ehValido = false;
    }
    return ehValido;
  }

  setContaContabil(contaContabil: ContaContabil): void {
    contaContabil = this.plainToClass(ContaContabil, contaContabil);
    this.objeto.idContaContabil = contaContabil.id;
    this.objeto.contaContabil = contaContabil.numeracao + ' - ' + contaContabil.nome.trim();
  }

  setContaCredito(contaContabil: ContaContabil): void {
    contaContabil = this.plainToClass(ContaContabil, contaContabil);
    this.objeto.idContaCredito = contaContabil.id;
    this.objeto.contaCredito = contaContabil.numeracao + ' - ' + contaContabil.nome.trim();
  }

  setIdTotal(total: any): void {
    if (this.bibServico.configuracaoMovimentacaoTotalContabil == this.servico) {
      this.objeto.idTotalMovimentacaoContabil = total.id;
      this.objeto.totalMovimentacaoContabil = total.nome;
    } else if (this.bibServico.configuracaoTransferenciaTotalContabil == this.servico) {
      this.objeto.idTotalTransferenciaContabil = total.id;
      this.objeto.totalTransferenciaContabil = total.nome;
    } else {
      this.objeto.idTotalFinanceiroContabil = total.id;
      this.objeto.totalFinanceiroContabil = total.nome;
    }
  }
}
