import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ComunicacaoTecnoSpeedBoletoService {
  private tokenSh: string = '1118fd19059010b40ff96e8e5c23218b';
  private cnpjSh: string = '09647414000181';

  constructor(private http: HttpClient) {}

  listar(url: string, cnpjCedente: string): Observable<any> {
    return this.consumir(url, null, cnpjCedente, 'GET');
  }

  persistir(url: string, parametros: any, cnpjCedente: string): Observable<any> {
    return this.consumir(url, parametros, cnpjCedente, 'POST');
  }

  deletar(url: string, parametros: any, cnpjCedente: string): Observable<any> {
    return this.consumir(url, parametros, cnpjCedente, 'DELETE');
  }

  private consumir(url: string, parametros: any, cnpjCedente: string, metodo: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('token-sh', this.tokenSh).set('cnpj-sh', this.cnpjSh).set('cnpj-cedente', cnpjCedente);
    if (metodo == 'POST') {
      return this.http.post(url, JSON.stringify(parametros, null, 4), { headers });
    } else if (metodo == 'GET') {
      return this.http.get(url, { headers: headers });
    } else if (metodo == 'DELETE') {
      return this.http.delete(url, { headers: headers });
    }
  }
}
