<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 naoImprimir"></th>
        <th class="l-5">{{ bibDialogo.loja }}</th>
        <th class="l-10">{{ bibDialogo.dataBaixa }}</th>
        <th class="l-15">{{ bibDialogo.parceiro }}</th>
        <th class="l-10 text-right">{{ bibDialogo.valor }}</th>
        <th class="l-15">{{ bibDialogo.tipoTitulo }}</th>
        <th class="l-20">{{ bibDialogo.numeroDocumento }}</th>
        <th class="l-10">{{ bibDialogo.dataConciliacao }}</th>
        <th class="l-10 naoImprimir"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiroBaixa of financeiroBaixas; let i = index">
        <tr>
          <td class="naoImprimir">
            <check [campo]="financeiroBaixa.selecionado" (alteracao)="financeiroBaixa.selecionado = $event"></check>
          </td>
          <td>{{ financeiroBaixa.abreviacao }}</td>
          <td>
            {{ financeiroBaixa.dataBaixa | data }}
          </td>
          <td class="limitar">{{ financeiroBaixa.parceiro }}</td>
          <td class="text-right">{{ financeiroBaixa.valor | monetario }}</td>
          <td>{{ financeiroBaixa.tipoTitulo }}</td>
          <td>{{ financeiroBaixa.numeroDocumento }}</td>
          <td>{{ financeiroBaixa.dataConciliacao | data }}</td>
          <td class="row justify-content-end naoImprimir"><btnAdicional [icone]="bibIcone.olho" (btnAdicional)="visualizar(financeiroBaixa.idFinanceiro, parametro)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.estornar" [tipo]="bibPropriedade.botao.sucesso" (botaoEmt)="estornar()" [fundo]="true"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
