<layout>
  <bloqueado [bloqueado]="bloqueado"></bloqueado>
  <div class="row">
    <lista class="col-sm-4" [id]="'tipo'" [campo]="financeiro.tipo" [rotulo]="bibDialogo.tipo" [lista]="tipos" [obrigatorio]="true" (alteracao)="financeiro.tipo = $event.id" [foco]="true" [desabilitado]="bloqueado"></lista>
    <lista class="col-sm-4" [id]="'categoria'" [campo]="financeiro.categoria" [rotulo]="bibDialogo.categoria" [lista]="categorias" [obrigatorio]="true" (alteracao)="financeiro.categoria = $event.id" [desabilitado]="bloqueado"></lista>
    <lista class="col-sm-4" [id]="'conta'" [campo]="financeiro.idConta" [rotulo]="bibDialogo.conta" [tipo]="bibPropriedade.lista.contaUsuario" (alteracao)="financeiro.idConta = $event.id" [obrigatorio]="true" [desabilitado]="bloqueado"></lista>
  </div>
  <div class="row">
    <busca class="col-sm-8" [id]="'parceiro'" [rotulo]="bibDialogo.parceiro" [campo]="financeiro.parceiro" (alteracao)="setNome(financeiro, $event)" [minimoCaracterBusca]="5" [servico]="bibServico.parceiro" [obrigatorio]="true" [desabilitado]="bloqueado"></busca>
    <lista class="col-sm-4" [id]="'loja'" [rotulo]="bibDialogo.lojaOrigem" [tipo]="bibPropriedade.lista.lojaUsuario" [campo]="financeiro.idLoja" [obrigatorio]="true" (alteracao)="financeiro.idLoja = $event.id" [desabilitado]="bloqueado"></lista>
  </div>
  <div class="row">
    <data class="col-sm-4" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="financeiro.dataNegociacao" (alteracao)="setDataNegociacao(financeiro, $event)" [desabilitado]="bloqueado"></data>
    <data class="col-sm-4" [id]="'dataVencimento'" [rotulo]="bibDialogo.dataVencimento" [campo]="financeiro.dataVencimento" (alteracao)="financeiro.dataVencimento = $event" [desabilitado]="bloqueado"></data>
  </div>
  <div class="row">
    <decimal class="col-sm-4" [id]="'valor'" [rotulo]="'(+) ' + bibDialogo.valor" [campo]="financeiro.valor" (alteracao)="setValor($event)" [obrigatorio]="true" [desabilitado]="bloqueado"></decimal>
    <decimal class="col-sm-4" [id]="'acrescimo'" [rotulo]="'(+) ' + bibDialogo.acrescimo" [campo]="financeiro.multa" (alteracao)="setAcrescimo($event)" [desabilitado]="bloqueado"></decimal>
    <decimal class="col-sm-4" [id]="'abatimento'" [rotulo]="'(-) ' + bibDialogo.abatimento" [campo]="financeiro.desconto" (alteracao)="setDesconto($event)" [valorMaximo]="financeiro.valor + (financeiro.multa ? financeiro.multa : 0)" [desabilitado]="financeiro.valor == 0 || bloqueado"></decimal>
  </div>
  <div class="row">
    <decimal class="col-sm-4" [id]="'valorFinal'" [rotulo]="bibDialogo.valorFinal" [campo]="financeiro.valorFinal" [desabilitado]="true"></decimal>
    <decimal class="col-sm-4" [id]="'valorRestante'" [rotulo]="bibDialogo.valorRestante" [campo]="financeiro.valorFinal" [desabilitado]="true"></decimal>
  </div>
  <div class="row">
    <botao class="col-sm-3 mt-3" [id]="'adicionar'" [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="salvarFinanceiro()" [desabilitado]="bloqueado"></botao>
  </div>
</layout>
