import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacaoComissao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'ANALISAR HISTÓRICO DE VENDA E CALCULAR COMPRA PARA X DIAS FUTURO', id: 'H' },
  { nome: 'ANALISAR ESTOQUE MÁXIMO', id: 'M' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.produtoDescricao, nome: 'NOME_PRODUTO', coluna: 6, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 6, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { rotulo: bibDialogo.praca, nome: 'ID_PRACA', coluna: 6, servicoWeb: bibServico.praca, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.sugestaoAnalisar, nome: 'SUGESTAO', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.combo, lista: filtroComboSituacaoComissao },
  { rotulo: bibDialogo.quantidadeDiaX, nome: 'QUANTIDADE_DIA', obrigatorio: 'S', coluna: 4, tipo: bibPropriedade.filtro.numero, vinculoVisualizacao: 'SUGESTAO', visualizacaoInicial: false, visualizacao: ['H'] },
]);
export default filtros;
