<layout [titulo]="tipo == 1 ? bibDialogo.icmsEntrada : bibDialogo.icms" [id]="icms.id">
  <div class="row">
    <lista class="col-sm-6" [id]="'enquadramento'" [rotulo]="bibDialogo.enquadramento" [campo]="icms.enquadramento" [lista]="listaEnquadramento" (alteracao)="setEnquadramento($event.id)" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'estadoOrigem'" [rotulo]="bibDialogo.estadoOrigem" [campo]="icms.idEstadoOrigem" (alteracao)="setEstadoOrigem(icms, $event)" [lista]="estadosOrigem" [obrigatorio]="true" [desabilitado]="estadosOrigem.length == 1" [ajuda]="bibDialogo.ajudaEstadoOrigem"></lista>
    <lista class="col-sm-6" [id]="'estadoDestino'" [rotulo]="bibDialogo.estadoDestino" [campo]="icms.idEstado" (alteracao)="setEstado(icms, $event)" [lista]="estados" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm-12" [id]="'classificacaoFiscal'" [idMenu]="permissaoClassificacaoFiscal && permissaoClassificacaoFiscal.inserir == 'S' ? menuClassificacaoFiscal.idMenu : null" [rotulo]="menuClassificacaoFiscal.apelido" [campo]="icms.idClassificacaoFiscal" (alteracao)="setClassificacaoFiscal(icms, $event)" [lista]="classificacaoFiscais" [obrigatorio]="true" [desabilitado]="!icms.enquadramento" [icone]="bibIcone.atualizar" (btnAdicional)="listarClassificacaoFiscal(icms.enquadramento)"></lista>
  </div>
  <titulo [titulo]="bibDialogo.icmsDesonerado" [imagem]="bibImagem.icmsdesonerado"></titulo>
  <div class="row m-0 p-0">
    <check class="col-sm-3" [id]="'usaIcmsDesonerado'" [rotulo]="bibDialogo.usaIcmsDesonerado" [campo]="icms.usaIcmsDesonerado" (alteracao)="icms.usaIcmsDesonerado = icms.usaIcmsDesonerado == 'S' ? 'N' : 'S'"></check>
  </div>  
  <!-- <div class="row">
    <decimal class="col-sm-4" [rotulo]="bibDialogo.aliquotaJuridica" [campo]="icms.aliquotaJuridica" (alteracao)="icms.aliquotaJuridica = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.aliquotaFisica" [campo]="icms.aliquotaFisica" (alteracao)="icms.aliquotaFisica = $event" [sufixo]="'%'" [obrigatorio]="true"></decimal>
  </div> -->
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionarIcms()"></botao>
<auditoria *ngIf="icms.id" [idUsuarioInclusao]="icms.idUsuarioInclusao" [dataHoraInclusao]="icms.dataHoraInclusao" [idUsuarioAlteracao]="icms.idUsuarioAlteracao" [dataHoraAlteracao]="icms.dataHoraAlteracao"></auditoria>
