<div class="d-flex align-items-center" [ngClass]="compacto ? 'espacamento-compacto' : 'espacamento'">
  <img *ngIf="imagem" [src]="'img/' + imagem + '.png'" [ngbTooltip]="ajuda" [ngClass]="compacto ? 'imagem-compacto' : ''" />
  <h5 *ngIf="!compacto" [ngClass]="!imagem ? 'compacto' : ''">{{ titulo }}</h5>
  <h5 *ngIf="compacto" class="align-items-center mr-1" [ngClass]="compacto || !imagem ? 'compacto' : ''">{{ titulo }}</h5>
  <obrigatorio class="ml-2" [obrigatorio]="obrigatorio" [compacto]="compacto" [preenchido]="preenchido"> </obrigatorio>
  <atencao [atencao]="atencao"></atencao>
  <div class="col-sm-1">
    <a href="#" [ngClass]="getClasse()" (click)="clicar()" tabindex="0" #focus *ngIf="icone" class="clique btn btn-success icone-borda" [ngbTooltip]="ajuda">
      <i [ngClass]="icone"></i>
    </a>
  </div>
</div>
