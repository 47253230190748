<div class="row">
  <apresentacao *ngIf="!informarNumero" class="col-sm-3" [rotulo]="bibDialogo.numero" [campo]="produtoControlador.produto.numero" [icone]="bibIcone.editar" (btnAdicional)="editarCampo()"></apresentacao>
  <texto *ngIf="informarNumero" class="col-sm-3" [id]="'numeroProduto'" [rotulo]="bibDialogo.numero" [tipo]="bibPropriedade.texto.inteiro" [campo]="produtoControlador.produto.numero" (alteracao)="produtoControlador.produto.numero = $event" [focoId]="focoNumero" ></texto>  
  <lista class="col-sm-4" [id]="'tipo'" [rotulo]="bibDialogo.tipo" [campo]="produtoControlador.produto.tipo" [lista]="tipo" (alteracao)="setTipo($event.id)" [obrigatorio]="true"></lista>
  <div class="col-sm"></div>
  <interruptor [rotulo]="bibDialogo.ativo" [campo]="produtoControlador.produto.ativo" (alteracao)="produtoControlador.produto.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
</div>
<div class="row" *ngIf="regraProdutoNomeAutomatico == 'S'">
  <texto class="col-sm-12" [id]="'descricao'" [rotulo]="bibDialogo.descricao" [campo]="produtoControlador.produto.descricao" (alteracao)="setProdutoDescricao($event)" [maximoCaracteres]="100" [obrigatorio]="true" [foco]="true"></texto>
</div>
<div class="row" *ngIf="regraProdutoNomeAutomatico == 'N'">
  <texto class="col-sm-12" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="produtoControlador.produto.nome" (alteracao)="setNome($event)" [maximoCaracteres]="100" [obrigatorio]="true" [foco]="true"></texto>
</div>
<div class="row">
  <busca *ngIf="produtoControlador.produto.tipo != 'S'" [ngClass]="produtoControlador.polegadas.length > 0 ? 'col-sm-6' : 'col-sm-12'" [id]="'fabricante'" [rotulo]="menuFabricante.apelido" [campo]="produtoControlador.produto.fabricante" (alteracao)="setFabricante($event)" [servico]="bibServico.fabricante" [idMenu]="permissaoFabricante && permissaoFabricante.inserir == 'S' ? menuFabricante.idMenu : null"></busca>
  <busca *ngIf="produtoControlador.polegadas.length > 0 && produtoControlador.produto.tipo != 'S'" class="col-sm-6" [id]="'polegada'" [rotulo]="menuPolegada.apelido" [campo]="produtoControlador.produto.polegada" (alteracao)="produtoControlador.produto.idPolegada = $event.id" [servico]="bibServico.polegada" [idMenu]="permissaoPolegada && permissaoPolegada.inserir == 'S' ? menuPolegada.idMenu : null"></busca>
</div>
<div class="row">
  <busca [ngClass]="produtoControlador.categorias.length > 0 ? 'col-sm-6' : 'col-sm-12'" [id]="'grupo'" [rotulo]="menuGrupo.apelido" [campo]="produtoControlador.produto.grupo" (alteracao)="setGrupo($event)" [servico]="bibServico.grupo" [criterios]="criterios" [idMenu]="permissaoGrupo && permissaoGrupo.inserir == 'S' ? menuGrupo.idMenu : null" [obrigatorio]="true"></busca>
  <busca *ngIf="produtoControlador.categorias.length > 0 && produtoControlador.produto.tipo != 'S'" class="col-sm-6" [id]="'categoria'" [rotulo]="menuCategoria.apelido" [campo]="produtoControlador.produto.categoria" (alteracao)="produtoControlador.produto.idCategoria = $event.id" [servico]="bibServico.categoria" [criterios]="criterios" [idMenu]="permissaoCategoria && permissaoCategoria.inserir == 'S' ? menuCategoria.idMenu : null"></busca>
</div>
<div class="row">
  <lista class="col-sm-3" [id]="'unidade'" [idMenu]="permissaoUnidade && permissaoUnidade.inserir == 'S' ? menuUnidade.idMenu : null" [rotulo]="menuUnidade.apelido" [campo]="produtoControlador.produto.idUnidade" (alteracao)="produtoControlador.produto.idUnidade = $event.id" [lista]="produtoControlador.unidades" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="produtoControlador.listarUnidade()"></lista>
  <decimal *ngIf="produtoControlador.produto.tipo != 'S'" [ngClass]="utilSessao.sistema == 'aGrow-w' ? 'col-sm-3' : 'col-sm-6'" [id]="'multiplos'" [rotulo]="bibDialogo.multiplos" [campo]="produtoControlador.produto.multiplicador" (alteracao)="produtoControlador.produto.multiplicador = $event" [ajuda]="bibDialogo.multiplicadorComercializacao" [numeroCasasDecimais]="this.empresaRegraCasasDecimais"></decimal>
  <decimal *ngIf="utilSessao.sistema == 'aGrow-w' && produtoControlador.produto.tipo != 'S'" class="col-sm-3" [id]="'pesoLiquido'" [numeroCasasDecimais]="3" [rotulo]="bibDialogo.pesoLiquido" [campo]="produtoControlador.produto.pesoLiquido" (alteracao)="produtoControlador.produto.pesoLiquido = $event" [sufixo]="'KG'"></decimal>
  <decimal *ngIf="produtoControlador.produto.tipo != 'S'" class="col-sm-3" [id]="'peso'" [numeroCasasDecimais]="3" [rotulo]="utilSessao.sistema == 'aGrow-w' ? bibDialogo.pesoBruto: bibDialogo.peso" [campo]="produtoControlador.produto.pesoBruto" (alteracao)="produtoControlador.produto.pesoBruto = $event" [sufixo]="'KG'"></decimal>
</div>
<div class="row">
  <busca *ngIf="produtoControlador.produto.tipo != 'S'" class="col-sm-9" [id]="'ncm'" [rotulo]="bibDialogo.ncm" [campo]="produtoControlador.produto.ncm" [minimoCaracterBusca]="5" (alteracao)="setNcm($event)" [servico]="bibServico.ncm" [obrigatorio]="produtoControlador.produto.faturamento == 'S'" [ajuda]="descricaoUtilizacaoNcm"></busca>
  <texto *ngIf="produtoControlador.produto.tipo != 'S'" class="col-sm-3" [id]="'cest'" [rotulo]="bibDialogo.cest" [campo]="produtoControlador.produto.cest" (alteracao)="produtoControlador.produto.cest = $event" [ajuda]="bibDialogo.acesseSiteConfaz" [tipo]="bibPropriedade.texto.inteiro" (btnAdicional)="abrirSiteConfaz()" [maximoCaracteres]="7" [icone]="bibIcone.globo"></texto>
</div>
<div class="row">
  <texto class="col-sm-6" [id]="'referencia'" [rotulo]="bibDialogo.referencia" [campo]="produtoControlador.produto.referencia" (alteracao)="setReferencia($event)" [maximoCaracteres]="30" [ajuda]="bibDialogo.codigoAdicionalIdentificador" [ajudaDireita]="true"></texto>
  <texto [ngClass]="produtoControlador.empresaRealizaIntegracaoFornecedores == 'S' ? 'col-sm-3' : 'col-sm-6'" [id]="'codigoDeBarra'" [rotulo]="bibDialogo.codigoDeBarra" [campo]="produtoControlador.produto.codigoBarra" (alteracao)="produtoControlador.produto.codigoBarra = $event" [maximoCaracteres]="43"></texto>
  <texto *ngIf="produtoControlador.empresaRealizaIntegracaoFornecedores == 'S'" class="col-sm-3" [id]="'codigoDeBarraIntegracao'" [rotulo]="bibDialogo.codigoDeBarraIntegracao" [campo]="produtoControlador.produto.codigoBarraIntegracao" (alteracao)="produtoControlador.produto.codigoBarraIntegracao = $event" [maximoCaracteres]="43" [ajuda]="bibDialogo.codigoDeBarraIntegracaoMensagem"></texto>
</div>
<div class="row">
  <texto class="col-sm-12" *ngIf="regraProdutoNomeAutomatico == 'S'" [rotulo]="bibDialogo.nome" [campo]="produtoControlador.produto.nome" (alteracao)="setNome($event)" [ajuda]="bibDialogo.produtoNomeAutomaticoAjuda" [desabilitado]="true" [maximoCaracteres]="100" [ajudaDireita]="true"></texto>
  <texto class="col-sm-12" [id]="'nomeNfe'" [rotulo]="produtoControlador.produto.tipo != 'S' ? bibDialogo.produtoNomeNFe : bibDialogo.produtoNomeNFSe" [campo]="produtoControlador.produto.nomeFiscal" [obrigatorio]="produtoControlador.produto.faturamento == 'S'" (alteracao)="produtoControlador.produto.nomeFiscal = $event" [maximoCaracteres]="120"></texto>
</div>
<titulo *ngIf="!compacto" [titulo]="bibDialogo.observacao" [imagem]="bibImagem.aspas"></titulo>
<div class="row">
  <descricao class="col-sm" [id]="'observacao'" [rotulo]="bibDialogo.observacao" [obrigatorio]="produtoControlador.produto.mostrarDescricao == 'S'" [campo]="produtoControlador.produto.observacao" (alteracao)="produtoControlador.produto.observacao = $event" [ajuda]="bibDialogo.observacaoProduto" [maximoCaracteres]="250" [ajudaDireita]="true"></descricao>
  <interruptor [id]="'mostrarAlerta'" [rotulo]="bibDialogo.mostrarAlerta" [campo]="produtoControlador.produto.mostrarDescricao" (alteracao)="produtoControlador.produto.mostrarDescricao = $event" [ajuda]="bibDialogo.mostraAlertaProduto"></interruptor>
</div>
<div *ngIf="produtoControlador.ehNcmAnvisa">
  <titulo *ngIf="!compacto" [titulo]="bibDialogo.anvisa" [imagem]="bibImagem.anvisa" [ajuda]="bibDialogo.anvisaNcm"></titulo>
  <div class="row">
    <texto class="col-sm-6" [id]="'anvisaCodigo'" [rotulo]="!compacto ? bibDialogo.codigo : bibDialogo.anvisaCodigo" [campo]="produtoControlador.produto.anvisaCodigo" (alteracao)="setAnvisaCodigo($event)" [ajuda]="bibDialogo.anvisaCodigoExplicacao" [atencao]="validoCodigoAnvisa == false ? bibDialogo.incorreto : null" [maximoCaracteres]="13" [ajudaDireita]="true"></texto>
    <decimal class="col-sm-3" [id]="'anvisaPrecoMaximo'" [numeroCasasDecimais]="3" [rotulo]="bibDialogo.valorMaximo" [campo]="produtoControlador.produto.anvisaPrecoMaximo" (alteracao)="produtoControlador.produto.anvisaPrecoMaximo = $event" [ajuda]="bibDialogo.anvisaPrecoMaximo" [ajudaDireita]="true" [numeroCasasDecimais]="this.empresaRegraCasasDecimais"></decimal> 
  </div>
  <div class="row">
    <texto class="col-sm-12" [id]="'anvisaMotivoIsencao'" *ngIf="produtoControlador.ehAnvisaIsencao" [rotulo]="bibDialogo.motivoIsencao" [campo]="produtoControlador.produto.anvisaMotivoIsencao" (alteracao)="produtoControlador.produto.anvisaMotivoIsencao = $event" [ajuda]="bibDialogo.anvisaMotivoIsencao" [maximoCaracteres]="255" [ajudaDireita]="true"></texto>
  </div>  
</div>
<!-- <titulo *ngIf="!compacto" [titulo]="bibDialogo.complemento" [imagem]="bibImagem.complementoProduto"></titulo>
<descricao [id]="'complemento'" [rotulo]="bibDialogo.complemento" [campo]="produtoControlador.produto.complemento" (alteracao)="produtoControlador.produto.complemento = $event" [maximoCaracteres]="250"></descricao> -->
<div>
  <titulo *ngIf="!compacto" [titulo]="bibDialogo.permissoes" [imagem]="bibImagem.permissoes"></titulo>
  <div *ngIf="produtoControlador.produto.tipo == 'P' && utilSessao.sistema == 'aGrow-w'" class="row">
    <lista class="col-sm-4" [id]="'controlarEstoque'" [rotulo]="bibDialogo.controlarEstoque" [campo]="produtoControlador.produto.controlarEstoque" [lista]="listaSimNao" (alteracao)="setControlaEstoque($event.id)" [obrigatorio]="true"></lista>
    <lista class="col-sm-4" [rotulo]="bibDialogo.controlarLote" [campo]="produtoControlador.produto.usaLote" [lista]="listaSimNao" (alteracao)="produtoControlador.produto.usaLote = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-sm-4" [id]="'estoqueNegativo'" [rotulo]="bibDialogo.estoqueNegativo" [ajuda]="this.utilSessao.getEmpresa().separarEntregarOpcional == 'S' ? bibDialogo.ajudaPermiteEstoqueFisicoNegativo : bibDialogo.ajudaPermiteEstoqueNegativo" [campo]="produtoControlador.produto.estoqueNegativo" [lista]="listaSimNao" (alteracao)="produtoControlador.produto.estoqueNegativo = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div class="row">  
    <lista *ngIf="produtoControlador.produto.tipo == 'P' && utilSessao.sistema == 'aGrow-w'" class="col-sm-4" [id]="'permitirDevolucao'" [rotulo]="bibDialogo.permitirDevolucao" [campo]="produtoControlador.produto.devolucao" [lista]="listaSimNao" (alteracao)="produtoControlador.produto.devolucao = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-sm-4" [rotulo]="bibDialogo.permitirEmitirNfe" [campo]="produtoControlador.produto.faturamento" [lista]="listaSimNao" (alteracao)="produtoControlador.produto.faturamento = $event.id" [obrigatorio]="true"></lista>
  </div>
</div>
