<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-3">{{ bibDialogo.nome }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valorAquisicao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valorAtual }}</th>

    </thead>
    <tbody>
      <ng-container *ngFor="let patrimonial of patrimoniais; let i = index">
        <tr class="conteudo">
          <td>{{ patrimonial.abreviacao }}</td>
          <td class="text-right">{{ patrimonial.numero }}</td>
          <td>{{ patrimonial.data | data }}</td>
          <td class="limitar">{{ patrimonial.nome }}</td>
          <td class="text-right">{{ patrimonial.valorAquisicao | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ patrimonial.valorAtual | monetario: this.empresaRegraCasasDecimais }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
