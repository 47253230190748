import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'r2001MovimentacaoGrupoProduto',
  templateUrl: './r2001MovimentacaoGrupoProduto.component.html',
})
export class R2001MovimentacaoGrupoProdutoComponent {
  @Input() movimentacaoGrupoProdutos: any[] = [];
  public menuGrupoApelido = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 11).apelido;
  public bibDialogo = bibDialogo;
}
