import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinanceiroBoletoComponent } from './agrow/pagina/financeiroBoleto/financeiroBoleto.component';
import { ProdutoFormuladoComponent } from './agrow/pagina/produtoFormulado/produtoFormulado.component';
import { AutenticacaoGuard } from './guardas/autenticacao.guard';
import { GenericoComponent } from './pagina/generico/generico.component';
import { GenericoFrmComponent } from './pagina/generico/genericoFrm.component';
import { SafraComponent } from './pagina/safra/safra.component';
import { SafraFrmComponent } from './pagina/safra/safraFrm.component';

const routes: Routes = [
  {
    path: 'safra',
    component: SafraComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'safraFrm',
    component: SafraFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'recomendacao',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'recomendacaoFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'financeiroBoleto',
    component: FinanceiroBoletoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'compendioEletronico',
    loadChildren: () => import('./agrow/pagina/compendioEletronico/compendioEletronico.module').then((compendioEletronico) => compendioEletronico.CompendioEletronicoModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'receituario',
    loadChildren: () => import('./agrow/pagina/receituario/receituario.module').then((receituario) => receituario.ReceituarioModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'produtoFormulado/:parametro',
    component: ProdutoFormuladoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'unidadeAlternativa',
    loadChildren: () => import('./agrow/pagina/unidadeAlternativa/unidadeAlternativa.module').then((unidadeAlternativa) => unidadeAlternativa.UnidadeAlternativaModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'lote',
    loadChildren: () => import('./agrow/pagina/lote/lote.module').then((lote) => lote.LoteModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'tabelaPreco',
    loadChildren: () => import('./agrow/pagina/tabelaPreco/tabelaPreco.module').then((tabelaPreco) => tabelaPreco.TabelaPrecoModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'visita',
    loadChildren: () => import('./agrow/pagina/visita/visita.module').then((visita) => visita.VisitaModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AgrowRoutingModule { }
