import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { EncerramentoContabil } from 'src/app/modelo/encerramentoContabil';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './encerramentoContabilFrm.component.html',
})
export class EncerramentoContabilFrmComponent extends PaginaComponent {
  public encerramentoContabil: EncerramentoContabil = new EncerramentoContabil();
  public dataAte: string;
  public valido: boolean = false;

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.encerramentoContabil = this.plainToClass(EncerramentoContabil, this.ehAtualizacao(this.encerramentoContabil));
    if (this.encerramentoContabil.id) {
      this.listarEncerramentoContabil(this.encerramentoContabil.id);
    }
  }

  listarEncerramentoContabil(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.encerramentoContabil).subscribe((res) => {
      this.encerramentoContabil = this.plainToClass(EncerramentoContabil, res[0]) as any;
    });
  }

  ehValidoObrigatorio(): boolean {
    if (this.encerramentoContabil.dataAte && this.encerramentoContabil.idLoja) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  persistirEncerramentoContabil(novo: boolean = false): void {
      if (this.ehValidoObrigatorio()) {
        super.persistir(new Transporte(this.encerramentoContabil), this.bibServico.encerramentoContabil, novo ? new EncerramentoContabil() : null);
      }
    }
}
