<div *ngIf="this.produtoControlador.produto.id" class="row pl-sm-2 align-items-center justify-content-between">
  <titulo [titulo]="bibDialogo.historico" [imagem]="bibImagem.historico"></titulo>
  <botao *ngIf="utilSessao.sistema == 'aGrow-w'" class="float-right" [compacto]="compacto" [legenda]="bibDialogo.visualizarHistorico" (botaoEmt)="this.produtoControlador.apresentarHistorico(true)"></botao>
</div>
<atencao *ngIf="produtoControlador.visualizaHistorico && produtoControlador.totalHistorico == 0" [atencao]="'Produto ainda sem histórico'"></atencao>
<ng-container *ngIf="produtoControlador.visualizaHistorico && produtoControlador.totalHistorico > 0">
  <table *ngIf="produtoControlador.produtoMedias.length > 0" class="table table-responsive-sm tabela-formulario">
    <thead>
      <tr>
        <th class="l-20">{{ bibDialogo.mediaMensalVendaAnoAanteriorAbreviacao }} <ajuda class="naoImprimir" [ajudaDireita]="true" [ajuda]="bibDialogo.mediaMensalVendaAnoAanterior"></ajuda></th>
        <th class="l-20">{{ bibDialogo.mediaMensalVendaTrimestreAnteriorAbreviacao }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.mediaMensalVendaTrimestreAnterior"></ajuda></th>
        <th class="l-20">{{ bibDialogo.mediaMensalVendaTrimestrePosteriorAbreviacao }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.mediaMensalVendaTrimestrePosterior"></ajuda></th>
        <th class="l-20">{{ bibDialogo.vendaMesAnteriorAbreviacao }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.vendaMesAnterior"></ajuda></th>
        <th class="l-20">{{ bibDialogo.vendaMesAtualAbreviacao }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.vendaMesAtual"></ajuda></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoMedia of produtoControlador.produtoMedias">
        <tr>
          <td><apresentacao [campo]="produtoMedia.mediaMensalVendaAnoAnterior | monetario: empresaRegraCasasDecimais"></apresentacao></td>
          <td><apresentacao [campo]="produtoMedia.mediaMensalVendaTrimestreAnterior | monetario: empresaRegraCasasDecimais"></apresentacao></td>
          <td><apresentacao [campo]="produtoMedia.mediaMensalVendaTrimestrePosterior | monetario: empresaRegraCasasDecimais"></apresentacao></td>
          <td><apresentacao [campo]="produtoMedia.vendaMesAnterior | monetario: empresaRegraCasasDecimais"></apresentacao></td>
          <td><apresentacao [campo]="produtoMedia.vendaMesAtual | monetario: empresaRegraCasasDecimais"></apresentacao></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="row m-0 espaco-grafico-barra">
    <div [hidden]="_produtoHistoricoMesAnual == null || _produtoHistoricoMesAnual.length == 0" class="col">
      <div class="chartjs">
        <canvas class="espaco-grafico-barra"
          #historicoRef
          id="historico"
          >{{ historicoGrafico }}</canvas
        >
      </div>
    </div>
  </div> 
</ng-container>
<carrossel *ngIf="produtoControlador.produto.tipo != 'S'" [pasta]="'produto'" [idPai]="produtoControlador.produto.id" [fotos]="this.produtoControlador.produto.produtoFotos" (imagem)="($event)"></carrossel>
<div *ngIf="this.produtoControlador.produto.id && this.produtoControlador.produto.faturamento == 'S' && this.permissaoProdutoImposto" class="row ml-0 justify-content-between align-items-center">
  <titulo [titulo]="bibDialogo.informacoesFiscais" [imagem]="bibImagem.produtoimpostos"></titulo>
  <botao [compacto]="compacto" [legenda]="bibDialogo.configurarInformacoesFiscais" (botaoEmt)="abrirModalProdutoImpostos(this.produtoControlador.produto)"></botao>
</div>
<div class="row pl-sm-2 align-items-center justify-content-between" *ngIf="this.produtoControlador.produto.id && this.permissaoProdutoValor">
  <titulo class="pr-3" [titulo]="bibDialogo.valores" [imagem]="bibImagem.unidadealternativa"></titulo>
  <div class="justify-content-end">
    <lista [ngClass]="compacto ? 'mt-2' : ''" [rotulo]="bibDialogo.lojaValor" [campo]="lojaAbreviacaoId" (alteracao)="setLojaProdutoValor($event)" [lista]="listaLojas" [focoId]="focoLojaAbreviacaoValor"></lista>
    <botao [ngClass]="compacto ? 'ml-3' : 'ml-3 mt-4'" [compacto]="compacto" [legenda]="bibDialogo.configurarValor + (lojaAbreviacao ? ' ' + lojaAbreviacao : '')" (botaoEmt)="modalProdutoValor()"></botao>
  </div>
</div>
<titulo *ngIf="visualizaLoja && utilSessao.sistema == 'aGrow-w'" [titulo]="bibDialogo.lojaOrigem" [imagem]="bibImagem.loja"></titulo>
<div *ngIf="visualizaLoja && utilSessao.sistema == 'aGrow-w'" class="row">
  <lista class="col-sm-12" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="produtoControlador.produto.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="produtoControlador.produto.idLoja = $event.id" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()" [ajudaDireita]="true" [ajuda]="bibDialogo.lojaCadastro"></lista>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
