<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(movimentacoes, $event)" (campoGrupoEmt)="agrupar(movimentacoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numeroFiscalAbreviacao }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r">{{ bibDialogo.cliente }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th class="r-4">{{ bibDialogo.chave }}</th>
      <th class="r-1-5">{{ bibDialogo.situacao }}</th>
      <th *ngIf="movimentacoes.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="[movimentacao.situacaoCancelado == 'S' ? 'tachado' : '', movimentacao.expandido ? 'sem-borda' : '']">
          <td>{{ movimentacao.loja }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td>{{ movimentacao.data | data }}</td>
          <td>{{ movimentacao.parceiro }}</td>
          <td class="text-right">{{ movimentacao.valorTotal > 0 ? (movimentacao.valorTotal | monetario) : (movimentacao.valorTotal * -1 | monetario) }}</td>
          <td>{{ movimentacao.chave }}</td>
          <td>{{ movimentacao.situacao }}</td>
          <td class="naoImprimir" *ngIf="movimentacoes.length > 0"><btnAdicional [icone]="movimentacao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacao)"></btnAdicional></td>
        </tr>
        <tr *ngIf="movimentacao.expandido" r1206itens [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacao]="movimentacao"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
