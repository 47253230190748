export class GrupoIcms {
  public aliquotaFisica: number;
  public aliquotaJuridica: number;
  public classificacaoFiscal: string;
  public classificacaoFiscalBase: number;
  public classificacaoFiscalIdCstIcms: number;
  public classificacaoFiscalIdCstIcmsSemInscricao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public enquadramento: number;
  public estado: string;
  public estadoOrigem: string;
  public excluido: boolean = false;
  public grupo: string;
  public id: number;
  public idClassificacaoFiscal: number;
  public idEmpresa: number;
  public idEstado: number;
  public idEstadoOrigem: number;
  public idGrupo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public tipo: number;
  public usaIcmsDesonerado: string = 'N';
  public cstIcms: string;
}
