import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { EmbalagemSicca } from 'src/app/modelo/embalagemSicca';
import { Filtro } from 'src/app/modelo/filtro';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './produtoFormulado.component.html',
})
export class ProdutoFormuladoComponent extends PaginaComponent {
  public produtos: Produto[] = [];
  public filtros: Filtro[] = filtros;
  public mostrarSicca: boolean = false;
  public embalagensSicca: EmbalagemSicca[];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.produto).subscribe((res) => {
      this.produtos = this.plainToClass(Produto, res) as any;
      this.mostrarSicca = this.utilSessao.getLojas().find((loja) => loja.estado == 'MG') ? true : false;
      this.listarEmbalagensSicca();
    });
  }

  listarEmbalagensSicca(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.embalagemSicca).subscribe((res) => {
      this.embalagensSicca = this.plainToClass(EmbalagemSicca, res) as any;
    });
  }

  setProdutoFormulado(idProdutoFormulado: number, produto: Produto) {
    produto.idProdutoFormulado = idProdutoFormulado;
    this.setAlterado(produto);
  }

  setFator(fator: number, produto: Produto) {
    produto.fator = fator;
    this.setAlterado(produto);
  }

  setEmbalagemSicca(idEmbalagemSicca: number, produto: Produto) {
    produto.idEmbalagemSicca = idEmbalagemSicca;
    this.setAlterado(produto);
  }

  setAlterado(produto: Produto): void {
    produto.alterado = true;
  }

  persistirProduto(): void {
    let produtosAlterados: Produto[] = this.produtos.filter((produto) => produto.alterado == true);
    if (this.ehValido(produtosAlterados)) {
      const transporte: Transporte = new Transporte(produtosAlterados);
      this.persistir(transporte, this.bibServico.produto, null);
    }
  }

  ehValido(produtosAlterados: Produto[]): boolean {
    const ehValidoProdutosAlterados: boolean = this.ehValidoProdutosAlterados(produtosAlterados);
    const ehValidoProdutoFormulado: boolean = this.ehValidoProdutoFormulado(produtosAlterados);
    const ehValidoProdutoFator: boolean = this.ehValidoProdutoFator(produtosAlterados);
    const ehValidoEmbalagemSicca: boolean = this.ehValidoEmbalagemSicca(produtosAlterados);
    return ehValidoProdutosAlterados && ehValidoProdutoFormulado && ehValidoProdutoFator && ehValidoEmbalagemSicca;
  }

  ehValidoProdutosAlterados(produtosAlterados: Produto[]): boolean {
    if (produtosAlterados.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumProdutoAlterada));
    return false;
  }

  ehValidoProdutoFormulado(produtosAlterados: Produto[]): boolean {
    let contemProdutoSemProdutoFormulado: boolean = false;
    produtosAlterados.forEach((produtoAlterado) => {
      if (!produtoAlterado.idProdutoFormulado && produtoAlterado.fator > 0) {
        contemProdutoSemProdutoFormulado = true;
      }
    });

    if (contemProdutoSemProdutoFormulado) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.produtoFormuladoVazio));
      return false;
    }
    return true;
  }

  ehValidoEmbalagemSicca(produtosAlterados: Produto[]): boolean {
    let contemProdutoSemEmbalagemSicca: boolean = false;
    if (this.mostrarSicca) {
      produtosAlterados.forEach((produtoAlterado) => {
        if (!produtoAlterado.idEmbalagemSicca && produtoAlterado.idProdutoFormulado) {
          contemProdutoSemEmbalagemSicca = true;
        }
      });
    }
    if (contemProdutoSemEmbalagemSicca) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.embalagemSiccaVazia));
      return false;
    }
    return true;
  }

  ehValidoProdutoFator(produtosAlterados: Produto[]): boolean {
    let contemProdutoFatorMenorUm: boolean = false;
    produtosAlterados.forEach((produtoAlterado) => {
      if ((!produtoAlterado.fator || produtoAlterado.fator <= 0) && produtoAlterado.idProdutoFormulado) {
        contemProdutoFatorMenorUm = true;
      }

      if (produtoAlterado.fator < 0) {
        contemProdutoFatorMenorUm = true;
      }
    });
    if (contemProdutoFatorMenorUm) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.fatorNaoPodeSerZero));
      return false;
    }
    return true;
  }
}
