<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.apuracaoLucroClienteEspecial }}<ajuda [ajuda]="bibDialogo.movimentacoesGeraramFinanceiro" [posicao]="bibDialogo.esquerda"></ajuda></h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
    <th class="r-0-5">{{ bibDialogo.vend }}</th>
    <th class="r">{{ bibDialogo.cliente }}</th>
    <th class="r-1 text-right">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTabela }}</th>
    <th class="r-1 text-right">{{ bibDialogo.negociacaoPrazoDias }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorAVista }}</th>
    <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucro }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualSimbolo }}</th> 
  </thead>
  <tbody>
    <ng-container *ngFor="let ApuracaoLucroClienteEspecial of apuracaoLucroClienteEspeciais">
      <tr>
        <td>{{ ApuracaoLucroClienteEspecial.loja }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.numero }}</td>
        <td>{{ ApuracaoLucroClienteEspecial.vendedor }}</td>
        <td class="limitar">{{ ApuracaoLucroClienteEspecial.cliente }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.custoGerencial | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.valorTotal | monetario }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.valorUnitarioTabela | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.negociacaoPrazo }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.valorMinimo | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ (ApuracaoLucroClienteEspecial.descontoPercentual | monetario) + '%' }}</td>
        <td class="text-right">{{ ApuracaoLucroClienteEspecial.lucroValor | monetario }}</td>
        <td class="text-right">{{ (ApuracaoLucroClienteEspecial.lucroPercentual | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="apuracaoLucroClienteEspeciais.length > 0">
    <tr>
      <td colspan="4"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoLucroClienteEspeciais[0].valorTotalCustoGerencial | monetario }}</strong>
      </td>      
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoLucroClienteEspeciais[0].valorTotalMovimentacao | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoLucroClienteEspeciais[0].valorTotalTabela | monetario }}</strong>
      </td>   
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoLucroClienteEspeciais[0].valorTotalMinimo | monetario }}</strong>
      </td>  
      <td colspan="1"></td>    
      <td class="text-right">
        <strong class="border-top border-dark">{{ apuracaoLucroClienteEspeciais[0].valorTotalLucro | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
