<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.movimentacaoMovimentacaoPendente }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r">{{ bibDialogo.produto }}</th>
    <th class="r-1-5">{{ bibDialogo.quantidadePendenteAbreviada }}</th>
    <th class="r-1-5">{{ bibDialogo.pendente }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoMovimentacaoPendente of movimentacaoMovimentacaoPendentes">
      <tr class="conteudo">
        <td>{{ movimentacaoMovimentacaoPendente.id }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.data | data }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.numero }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.idLoja + ' - ' + movimentacaoMovimentacaoPendente.loja }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.idEmpresa + ' - ' + movimentacaoMovimentacaoPendente.empresa }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.idOperacao + ' - ' + movimentacaoMovimentacaoPendente.operacao }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.produto }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.quantidadePendente | monetario }}</td>
        <td>{{ movimentacaoMovimentacaoPendente.pendente | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
