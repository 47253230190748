export class Icmssn202 {
    public orig: string;
    public CSOSN: string = '202';
    public modBCST: string;
    public pMVAST: string;
    public pRedBCST: string;
    public vBCST: string;
    public pICMSST: string;
    public vICMSST: string;
    public vBCFCPST: string;
    public pFCPST: string;
    public vFCPST: string;
}