export class EncerramentoContabil {
  public ativo: string = 'S';
  public dataAte: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public observacao: string;

  public loja: string;
}
