export class Relacionamento {
  private empresa: string;
  private idEmpresa: number;
  public ativo: string = 'S';
  public cor: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricao: string;
  public id: number;
  public idCor: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
}
