import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001NotasNaoTransmitidas',
  templateUrl: './r2001NotasNaoTransmitidas.component.html',
})
export class R2001NotasNaoTransmitidas {
  @Input() notasNaoTransmitidas: any[] = [];
  public bibDialogo = bibDialogo;
}
