import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.dia, campo: 'diaExtenso' },
  { rotulo: bibDialogo.semanaAno, campo: 'semanaAnoExtenso' },
  { rotulo: bibDialogo.mesAno, campo: 'mesAnoExtenso' },
];

export default grupos;
