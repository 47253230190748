<layout [titulo]="parceiro">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-30">{{ bibDialogo.usuario }}</th>
        <th class="l-10">{{ bibDialogo.data }}</th>
        <th [ngClass]="empresaLimiteCreditoAutomatico ? 'l-20' : 'l-30'">{{ bibDialogo.limiteMaximo }}</th>
        <th *ngIf="empresaLimiteCreditoAutomatico" class="l-20">{{ bibDialogo.limiteCredito }}</th>
        <th [ngClass]="empresaLimiteCreditoAutomatico ? 'l-20' : 'l-30'">{{ bibDialogo.limiteDisponivel }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let parceiroHistoricoLimite of parceiroHistoricoLimites">
        <td>{{ parceiroHistoricoLimite.usuario }}</td>
        <td>{{ parceiroHistoricoLimite.data | data }}</td>
        <td>{{ parceiroHistoricoLimite.limiteMaximo | monetario }}<sup *ngIf="parceiroHistoricoLimite.variacaoLimiteMaximo != 0"  [ngClass]="parceiroHistoricoLimite.variacaoLimiteMaximo > 0 ? 'badge text-success' : 'badge text-danger'">{{  parceiroHistoricoLimite.variacaoLimiteMaximo > 0 ? '+' + (parceiroHistoricoLimite.variacaoLimiteMaximo | monetario) :  (parceiroHistoricoLimite.variacaoLimiteMaximo | monetario)}}</sup></td>
        <td *ngIf="empresaLimiteCreditoAutomatico" >{{ parceiroHistoricoLimite.limiteCredito | monetario }} <sup *ngIf="parceiroHistoricoLimite.variacaoLimiteCredito != 0" [ngClass]="parceiroHistoricoLimite.variacaoLimiteCredito > 0 ? 'badge text-success' : 'badge text-danger'">{{ parceiroHistoricoLimite.variacaoLimiteCredito > 0 ? '+' + (parceiroHistoricoLimite.variacaoLimiteCredito| monetario) : (parceiroHistoricoLimite.variacaoLimiteCredito | monetario) }}</sup></td>
        <td>{{ (parceiroHistoricoLimite.limiteDisponivel | monetario)  }}<sup *ngIf="parceiroHistoricoLimite.variacaoLimiteDisponivel != 0" [ngClass]="parceiroHistoricoLimite.variacaoLimiteDisponivel > 0 ? 'badge text-success' : 'badge text-danger'">{{ parceiroHistoricoLimite.variacaoLimiteDisponivel > 0 ? '+' + (parceiroHistoricoLimite.variacaoLimiteDisponivel| monetario) : (parceiroHistoricoLimite.variacaoLimiteDisponivel | monetario) }}</sup></td>
      </tr>
    </tbody>
  </table>
</layout>
