import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'janeiro', posicao: 2 },
  { campo: 'fevereiro', posicao: 3 },
  { campo: 'marco', posicao: 4 },
  { campo: 'abril', posicao: 5 },
  { campo: 'maio', posicao: 6 },
  { campo: 'junho', posicao: 7 },
  { campo: 'julho', posicao: 8 },
  { campo: 'agosto', posicao: 9 },
  { campo: 'setembro', posicao: 10 },
  { campo: 'outubro', posicao: 11 },
  { campo: 'novembro', posicao: 12 },
  { campo: 'dezembro', posicao: 13 },
  { campo: 'total', posicao: 14 },
];
export default totais;
