<layout [titulo]="naturezaOperacao.nome" [id]="naturezaOperacao.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="naturezaOperacao.nome" (alteracao)="naturezaOperacao.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="naturezaOperacao.ativo" (alteracao)="naturezaOperacao.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista [ngClass]="usaMobilidade ? 'col-md-3' : 'col-md-4'" [id]="'usadoComo'" [rotulo]="bibDialogo.usadoComo" [campo]="naturezaOperacao.entradaSaida" [lista]="listaUsadoComo" (alteracao)="naturezaOperacao.entradaSaida = $event.id" [obrigatorio]="true" [desabilitado]="naturezaOperacao.id"></lista>
    <lista [ngClass]="usaMobilidade ? 'col-md-3' : 'col-md-4'" [id]="'usaCusto'" [rotulo]="bibDialogo.valorUnitario" [campo]="naturezaOperacao.usaCustoReposicao" [lista]="listaCusto" (alteracao)="naturezaOperacao.usaCustoReposicao = $event.id" [obrigatorio]="true"></lista>
    <lista [ngClass]="usaMobilidade ? 'col-md-3' : 'col-md-4'" [id]="'finalidade'" [rotulo]="bibDialogo.finalidade" [campo]="naturezaOperacao.finalidade" [lista]="finalidades" (alteracao)="naturezaOperacao.finalidade = $event.id" [obrigatorio]="true"></lista>
    <lista [ngClass]="usaMobilidade ? 'col-md-3' : 'col-md-4'" *ngIf="usaMobilidade" [id]="'mobilidade'" [rotulo]="bibDialogo.naturezaPadraoMobilidade" [campo]="naturezaOperacao.mobilidadePadrao" [lista]="simNao" (alteracao)="naturezaOperacao.mobilidadePadrao = $event.id" [obrigatorio]="true" [ajuda]="bibDialogo.naturezaPadraoMobilidadeAjuda"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirNatureaOperacao()" [desabilitado]="bloquearPermissao(naturezaOperacao.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirNatureaOperacao(true)" [desabilitado]="bloquearPermissao(naturezaOperacao.id)"></botao>
<auditoria *ngIf="naturezaOperacao.id" [idUsuarioInclusao]="naturezaOperacao.idUsuarioInclusao" [dataHoraInclusao]="naturezaOperacao.dataHoraInclusao" [idUsuarioAlteracao]="naturezaOperacao.idUsuarioAlteracao" [dataHoraAlteracao]="naturezaOperacao.dataHoraAlteracao"></auditoria>
