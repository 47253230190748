<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(movimentacaoProdutos, $event)" (campoGrupoEmt)="agrupar(movimentacaoProdutos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r">{{ criterio ? criterio.apresentacao : bibDialogo.totalizador }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.totalAnoAnterior }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.volume"></ajuda></th>
      <th class="r-1-5 text-right">{{ bibDialogo.totalPeriodoInformado }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.volume"></ajuda></th>
      <th class="r-1-5 text-right">{{ bibDialogo.diferenca }}</th>
      <th *ngIf="movimentacaoProdutos.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos; let i = index">
        <tr class="conteudo" [ngClass]="movimentacaoProduto.expandido ? 'sem-borda' : ''">
          <td>{{ movimentacaoProduto.totalizador ? movimentacaoProduto.totalizador : criterio.apresentacao + ' ' + bibDialogo.emBranco }}</td>
          <td class="text-right">{{ movimentacaoProduto.totalAnoAnterior | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.totalPeriodoInformado | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.diferenca | monetario }}</td>
          <td class="naoImprimir" *ngIf="movimentacaoProdutos.length > 0"><btnAdicional [icone]="movimentacaoProduto.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacaoProduto)"></btnAdicional></td>
        </tr>
        <tr *ngIf="movimentacaoProduto.expandido" r1198Itens [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacaoProdutos]="movimentacaoProduto.movimentacaoProdutos"></tr>
        <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacaoProdutos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
