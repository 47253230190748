export class Identificacao {
  public conteudo: any;
  public nome: string;
  public sessao: boolean;

  constructor(nome: string = null, conteudo: any = null, sessao = true) {
    this.nome = nome;
    this.conteudo = conteudo;
    this.sessao = sessao;
  }
}
