import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'data', rotulo: bibDialogo.data, nome: 'DATA', coluna: 12, tipo: bibPropriedade.filtro.data, valor: new Date(), obrigatorio: 'S' },
  { rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista, obrigatorio: 'S' },
  { rotulo: bibDialogo.descontoAcimaDe, nome: 'DESCONTO_ACIMA', coluna: 12, tipo: bibPropriedade.filtro.numero, obrigatorio: 'S', valor: 15, sufixo: '%' },
  { rotulo: bibDialogo.prazoMaiorQue, nome: 'MEDIA_DIA_PRAZO', coluna: 12, tipo: bibPropriedade.filtro.numero, obrigatorio: 'S', valor: 34, sufixo: 'dias' },
  { rotulo: bibDialogo.lucroMenorOue, nome: 'LUCRO', coluna: 12, tipo: bibPropriedade.filtro.numero, obrigatorio: 'S', valor: 0 },
]);
export default filtros;
