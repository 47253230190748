import { Endereco } from './endereco';
import { Telefone } from './telefone';

export class Destinatario {
  public cpfCnpj: string;
  public email: string;
  public endereco: Endereco;
  public incentivadorCultural: boolean;
  public incentivoFiscal: boolean;
  public indicadorInscricaoEstadual: number;
  public inscricaoEstadual: string;
  public nomeFantasia: string;
  public razaoSocial: string;
  public telefone: Telefone;
}
