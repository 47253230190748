<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th [ngClass]="mostrarSicca ? 'l-30 clique' : 'l-40 clique'" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ menuProduto.apelido }}</th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="l-35 clique" (click)="listar(add('produtoFormulado'), this.utilSessao.posicao, this.paginacao)">{{ menuProdutoFormulado }}</th>
        <th [ngClass]="mostrarSicca ? 'l-10 clique' : 'l-20 clique'" (click)="listar(add('fator'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.fator }} <ajuda [ajuda]="bibDialogo.descricaoFator"></ajuda></th>
        <th *ngIf="mostrarSicca" class="l-20 clique" (click)="listar(add('fator'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.embalagemSicca }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <tr>
          <td class="limitar">
            <a> <ajuda [ajuda]="produto.numero + ' - ' + produto.nome" [ajudaDireita]="true"></ajuda> {{ produto.numero + ' - ' + produto.nome }} </a>
          </td>
          <td>{{ produto.abreviacao }}</td>
          <td><busca [id]="'produtoFormulado' + i" [campo]="produto.produtoFormulado" (alteracao)="setProdutoFormulado($event.id, produto)" [servico]="bibServico.produtoFormulado" [minimoCaracterBusca]="4"></busca></td>
          <td><decimal [id]="'fator' + i" [campo]="produto.fator" (alteracao)="setFator($event, produto)" [numeroCasasDecimais]="3"></decimal></td>
          <td><lista *ngIf="mostrarSicca" [id]="'sicca' + i" [campo]="produto.idEmbalagemSicca" (alteracao)="setEmbalagemSicca($event.id, produto)" [lista]="embalagensSicca" [icone]="bibIcone.atualizar" (btnAdicional)="listarEmbalagensSicca()"></lista></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<botao *ngIf="produtos.length > 0" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirProduto()" [desabilitado]="temPermissaoEdicao ? false : true"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
