import { Type } from 'class-transformer';

export class LancamentoContabil {
  @Type(() => Date)
  public data: Date = new Date();

  public alterado: boolean = false;
  public ativo: string = 'S';
  public chave: string;
  public complemento: string;
  public contaBancaria: string;
  public contaContabil: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public debitoCredito: number;
  public expandido: boolean = false;
  public historico: string;
  public id: number;
  public idContaBancaria: number;
  public idContaContabil: number;
  public idEmpresa: number;
  public idLoja: number;
  public idParceiro: number;
  public idRoteiroContabil: number;
  public idTipoLancamentoContabil: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public numeracaoCorrentista: string;
  public origem: number = 1;
  public parceiro: string;
  public percentual: number;
  public roteiroContabil: string;
  public tipoLancamentoContabil: string;
  public tipoTotal: string;
  public valor: number;
  public valorReferencia: number = 0;
  public totalContabil: string;

  public ehCorrentistaParceiro: boolean = false;
  public ehCorrentistaConta: boolean = false;
  public correntista: number;

  public idContaDebito: number;
  public contaDebito: string;
  public correntistaDebito: number;
  public idContaCredito: number;
  public contaCredito: string;
  public correntistaCredito: number;
  public idParceiroDebito: number;
  public parceiroDebito: string;
  public idContaBancariaDebito: number;
  public contaBancariaDebito: string;
  public idParceiroCredito: number;
  public parceiroCredito: string;
  public idContaBancariaCredito: number;
  public contaBancariaCredito: string;
  public nomeUsuarioAlteracao: string;
}
