<div *ngIf="movimentacaoControlador">
  <div class="row" *ngIf="movimentacaoControlador.transferencia">
    <lista class="col-sm-12" [id]="'lojaSaida'" [rotulo]="movimentacaoControlador.transferenciaEntrada ? movimentacaoControlador.bibDialogo.lojaEntrada : movimentacaoControlador.bibDialogo.lojaSaida" [campo]="movimentacaoControlador.movimentacao.idLoja" (alteracao)="setLoja($event)" [obrigatorioLoja]="true" [desabilitado]="movimentacaoControlador.bloqueado" [foco]="movimentacaoControlador.transferencia" [icone]="movimentacaoControlador.bibIcone.atualizar" [tipo]="movimentacaoControlador.bibPropriedade.lista.lojaUsuario" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
    <lista class="col-sm-12" [id]="'lojaEntrada'" [rotulo]="movimentacaoControlador.transferenciaEntrada ? movimentacaoControlador.bibDialogo.lojaSaida : movimentacaoControlador.bibDialogo.lojaEntrada" [campo]="movimentacaoControlador.movimentacao.idLojaDestino" (alteracao)="movimentacaoControlador.movimentacao.idLojaDestino = $event.id" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado" [icone]="movimentacaoControlador.bibIcone.atualizar" [lista]="movimentacaoControlador.lojas" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
  </div>
  <div class="row">
    <lista *ngIf="!movimentacaoControlador.transferencia" class="col-sm-12" [id]="'loja'" [rotulo]="movimentacaoControlador.bibDialogo.loja" [campo]="movimentacaoControlador.movimentacao.idLoja" (alteracao)="setLoja($event)" [tipo]="movimentacaoControlador.bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoControlador.usaMovimentacaoQuantidade || movimentacaoControlador.lojaCampoBloqueado" [foco]="true" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true" [filtroEmiteAlteracao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
  </div>
  <div class="row">
    <busca class="col-sm" *ngIf="!movimentacaoControlador.interna && !movimentacaoControlador.usaConsumidorFinal && !movimentacaoControlador.transferencia" [idMenu]="movimentacaoControlador.idMenuParceiro" [id]="'parceiro'" [rotulo]="movimentacaoControlador.tipoParceiro" [campo]="movimentacaoControlador.movimentacao.nomeParceiro" (alteracao)="setParceiro($event)" [desabilitado]="movimentacaoControlador.bloqueado || (movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0 && movimentacaoControlador.movimentacao.idParceiro && movimentacaoControlador.nota)" [servico]="movimentacaoControlador.tipoParceiroServico" [obrigatorio]="true" [atencao]="'3'" [focoId]="movimentacaoControlador.interna ? '' : movimentacaoControlador.focoParceiro"></busca>
    <icone class="col-sm-1" [ngClass]="!movimentacaoControlador.compacto ? 'pt-4' : 'mb-0 d-flex align-items-center'" *ngIf="!movimentacaoControlador.interna && !movimentacaoControlador.usaConsumidorFinal && !movimentacaoControlador.transferencia && movimentacaoControlador.movimentacao.relacionamento" [icone]="movimentacaoControlador.bibIcone.circulo" [cor]="movimentacaoControlador.bibPropriedade.icone[movimentacaoControlador.movimentacao.relacionamento.cor.toLowerCase()]" [mensagem]="movimentacaoControlador.movimentacao.relacionamento.nome"></icone>
    <texto class="col-sm" *ngIf="!movimentacaoControlador.interna && movimentacaoControlador.usaConsumidorFinal && !movimentacaoControlador.transferencia" [rotulo]="movimentacaoControlador.tipoParceiro" [campo]="movimentacaoControlador.movimentacao.nomeParceiro" (alteracao)="movimentacaoControlador.movimentacao.nomeParceiro = $event" [desabilitado]="movimentacaoControlador.bloqueado" [obrigatorio]="true" (btnAdicional)="limparParceiro()" [icone]="movimentacaoControlador.bibIcone.sair" [focoId]="focoNomeParceiro" [sufixo]="movimentacaoControlador.movimentacao.parceiro" [maximoCaracteres]="150"></texto>
    <a href="#" *ngIf="((this.movimentacaoControlador.saida && movimentacaoControlador.telaCliente) || (this.movimentacaoControlador.entrada && movimentacaoControlador.telaFornecedor)) && !movimentacaoControlador.interna && !movimentacaoControlador.usaConsumidorFinal && ((movimentacaoControlador.movimentacao.idParceiro && this.movimentacaoControlador.saida && this.movimentacaoControlador.telaCliente.editar == 'S') || (!movimentacaoControlador.movimentacao.idParceiro && this.movimentacaoControlador.saida && this.movimentacaoControlador.telaCliente.inserir == 'S') || (movimentacaoControlador.movimentacao.idParceiro && this.movimentacaoControlador.entrada && this.movimentacaoControlador.telaFornecedor.editar == 'S') || (!movimentacaoControlador.movimentacao.idParceiro && this.movimentacaoControlador.entrada && this.movimentacaoControlador.telaFornecedor.inserir == 'S'))" class="mb-0 col-sm-2 d-flex align-items-center fonte-link" [ngClass]="movimentacaoControlador.compacto ? '' : 'pt-4'" (click)="irParceiro(movimentacaoControlador.movimentacao.idParceiro)">
      {{ movimentacaoControlador.movimentacao.idParceiro ? movimentacaoControlador.bibDialogo.visualizar + ' ' + movimentacaoControlador.tipoParceiro.toLowerCase() : movimentacaoControlador.bibDialogo.adicionar + ' ' + movimentacaoControlador.tipoParceiro.toLowerCase() }}
    </a>
  </div>
  <div class="row">
    <texto class="col-sm" *ngIf="movimentacaoControlador.saida && !movimentacaoControlador.devolucao" [rotulo]="movimentacaoControlador.bibDialogo.enderecoEntrega" [campo]="movimentacaoControlador.movimentacao.enderecoOutro || movimentacaoControlador.movimentacao.parceiroEndereco" (alteracao)="movimentacaoControlador.movimentacao.enderecoOutro = $event == '' ? null : $event" [desabilitado]="movimentacaoControlador.bloqueado" [maximoCaracteres]="300"></texto>
    <mascara *ngIf="movimentacaoControlador.movimentacao.idParceiro && movimentacaoControlador.usaConsumidorFinal && !this.movimentacaoControlador.orcamento && !this.movimentacaoControlador.nota" class="col-sm-4" [id]="'cpfCnpj'" [rotulo]="movimentacaoControlador.movimentacao.parceiroTipoPessoa == 'F' ? movimentacaoControlador.bibDialogo.cpf : movimentacaoControlador.bibDialogo.cnpj" [campo]="movimentacaoControlador.movimentacao.parceiroCpfCnpj" [tipo]="movimentacaoControlador.movimentacao.parceiroTipoPessoa == 'F' ? bibPropriedade.mascara.cpf : bibPropriedade.mascara.cnpj" (alteracao)="setParceiroCpfCnpj($event)" [atencao]="!movimentacaoControlador.ehValidoCnpjCpf && movimentacaoControlador.movimentacao.parceiroCpfCnpj ? bibDialogo.incorreto : null" [desabilitado]="movimentacaoControlador.bloqueado" [icone]="bibIcone.usuario" [iconeDesabilitado]="false" (btnAdicional)="abrirModalCadastroCliente()" [ajuda]="bibDialogo.cadastrarCliente"></mascara>
  </div>
  <div class="row">
    <descricao class="col-sm" [normal]="true" [id]="'observacao'" [rotulo]="movimentacaoControlador.bibDialogo.observacao" [campo]="movimentacaoControlador.movimentacao.observacao" (alteracao)="movimentacaoControlador.movimentacao.observacao = $event" [desabilitado]="movimentacaoControlador.bloqueado" [focoId]="movimentacaoControlador.focoIdObservacao" [maximoCaracteres]="1000"></descricao>
    <texto *ngIf="this.movimentacaoControlador.notaTerceiro || (movimentacaoControlador.nota && movimentacaoControlador.estoqueNFe == 1 && movimentacaoControlador.movimentacao.emitente == 1)" class="col-sm-4 offset-md-3'" [id]="'numero'" [rotulo]="movimentacaoControlador.bibDialogo.numero" [campo]="movimentacaoControlador.movimentacao.numero" (alteracao)="movimentacaoControlador.movimentacao.numero = $event" [tipo]="movimentacaoControlador.bibPropriedade.texto.inteiro" [desabilitado]="movimentacaoControlador.bloqueado" [obrigatorio]="movimentacaoControlador.notaTerceiro"></texto>
    <apresentacao *ngIf="!this.movimentacaoControlador.notaTerceiro && ((movimentacaoControlador.nota && movimentacaoControlador.estoqueNFe == 1 && movimentacaoControlador.movimentacao.emitente == 1) == false)" class="col-sm-4 offset-md-3'" [id]="'numeroFiscal'" [rotulo]="movimentacaoControlador.bibDialogo.numero" [campo]="movimentacaoControlador.nota || movimentacaoControlador.notaTerceiro ? movimentacaoControlador.movimentacao.documentoFiscal : movimentacaoControlador.movimentacao.numero" [icone]="movimentacaoControlador.documentoFiscalEmitido && movimentacaoControlador.movimentacao.situacaoDenegada != 'S' ? movimentacaoControlador.bibIcone.pdf : ''" (btnAdicional)="baixarPdf()" [ajuda]="movimentacaoControlador.documentoFiscalEmitido ? bibDialogo.visualizarDanfe : ''"></apresentacao>
  </div>
  <div class="row">
    <busca class="col-sm" [idMenu]="movimentacaoControlador.telaColaborador && movimentacaoControlador.telaColaborador.inserir == 'S' ? movimentacaoControlador.menuColaborador.idMenu : null" [id]="'responsavel'" [rotulo]="movimentacaoControlador.tipoColaborador" [campo]="movimentacaoControlador.movimentacao.colaborador" (alteracao)="setColaborador($event)" [desabilitado]="movimentacaoControlador.bloqueado && this.movimentacaoControlador.bloqueiaAlteracao" [servico]="movimentacaoControlador.bibServico.colaborador" [obrigatorio]="true"></busca>
    <data [id]="'data'" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.data" [campo]="movimentacaoControlador.movimentacao.data" (alteracao)="setData($event)" [obrigatorio]="true" [desabilitado]="(movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao) || movimentacaoControlador.usuarioPermiteMovimentacaoData == 'N'"></data>
  </div>
  <div class="row">
    <lista class="col-sm" *ngIf="movimentacaoControlador.classificacoes.length > 0" [idMenu]="movimentacaoControlador.telaClassificacao && movimentacaoControlador.telaClassificacao.inserir == 'S' ? movimentacaoControlador.menuClassificacao.idMenu : null" [id]="'classificacao'" [rotulo]="movimentacaoControlador.menuClassificacao.apelido" [campo]="movimentacaoControlador.movimentacao.idClassificacao" (alteracao)="setClassificacao($event)" [lista]="movimentacaoControlador.classificacoes" [desabilitado]="movimentacaoControlador.bloqueado" [icone]="this.movimentacaoControlador.bibIcone.atualizar" (btnAdicional)="movimentacaoControlador.listarClassificacao()" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm" *ngIf="movimentacaoControlador.safras.length > 0" [id]="'safra'" [rotulo]="bibDialogo.safra" [campo]="movimentacaoControlador.movimentacao.idSafra" (alteracao)="movimentacaoControlador.movimentacao.idSafra = $event.id" [lista]="movimentacaoControlador.safras" [desabilitado]="movimentacaoControlador.bloqueado" [icone]="this.movimentacaoControlador.bibIcone.atualizar" (btnAdicional)="movimentacaoControlador.listarSafras()" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
  </div>
  <div class="row" *ngIf="movimentacaoControlador.usaNegociacao">
    <lista [id]="'negociacao'" class="col-sm" *ngIf="!(movimentacaoControlador.devolucao && movimentacaoControlador.entrada)" [id]="bibDialogo.negociacao" [rotulo]="movimentacaoControlador.menuNegociacao.apelido" [idMenu]="movimentacaoControlador.telaNegociacao && movimentacaoControlador.telaNegociacao.inserir == 'S' ? movimentacaoControlador.menuNegociacao.idMenu : null" [rotulo]="movimentacaoControlador.menuNegociacao.apelido" [campo]="movimentacaoControlador.movimentacao.idNegociacao" (alteracao)="setNegociacao($event)" [lista]="movimentacaoControlador.negociacoes" [desabilitado]="movimentacaoControlador.bloqueado" [obrigatorio]="true" [icone]="this.movimentacaoControlador.bibIcone.atualizar" (btnAdicional)="movimentacaoControlador.listarNegociacao()" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
    <icone class="col-sm-1" *ngIf="movimentacaoControlador.negociacaoBloqueada" [icone]="movimentacaoControlador.bibIcone.cadeadoFechado" [mensagem]="movimentacaoControlador.movimentacao.negociacao.exigeAprovacao == 'S' ? movimentacaoControlador.bibDialogo.exigeAprovacaoCreditario : movimentacaoControlador.bibDialogo.negociacaoBloqueada"></icone>
    <decimal class="col-sm-2" *ngIf="!movimentacaoControlador.devolucao" [rotulo]="movimentacaoControlador.acrescimo" [campo]="movimentacaoControlador.movimentacao.acrescimoPercentual" [vertical]="false" [desabilitado]="movimentacaoControlador.bloqueado" [sufixo]="'%'" (alteracao)="setAcrescimoPercentual($event)"></decimal>
    <decimal class="col-sm-2" *ngIf="!movimentacaoControlador.devolucao" [rotulo]="movimentacaoControlador.desconto" [campo]="movimentacaoControlador.percentualDescontoItem" (alteracao)="setPercentualDescontoItem($event)" [vertical]="false" [desabilitado]="movimentacaoControlador.bloqueado" [sufixo]="'%'"></decimal>
  </div>
  <atencao class="p-0" *ngIf="movimentacaoControlador.saida && movimentacaoControlador.usaNegociacao" [atencao]="movimentacaoControlador.tipoNegociacaoParceiro.toUpperCase()"></atencao>
  <div *ngIf="movimentacaoControlador.nota || movimentacaoControlador.notaTerceiro">
    <div class="row">
      <lista class="col-sm" [id]="'naturezaOperacao'" [idMenu]="movimentacaoControlador.telaNaturezaOperacao && movimentacaoControlador.telaNaturezaOperacao.inserir == 'S' ? movimentacaoControlador.menuNaturezaOperacao.idMenu : null" [rotulo]="movimentacaoControlador.menuNaturezaOperacao.apelido" [campo]="movimentacaoControlador.movimentacao.idNaturezaOperacao" (alteracao)="setNaturezaOperacao($event)" [lista]="movimentacaoControlador.naturezaOperacoes" [obrigatorio]="true" [desabilitado]="(movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao) || movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0" [icone]="this.movimentacaoControlador.bibIcone.atualizar" (btnAdicional)="movimentacaoControlador.listarNaturezaOperacao()" [ehEdicao]="movimentacaoControlador.movimentacao.id ? false : true"></lista>
      <ajuda *ngIf="movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0" class="col-sm-1" [ajuda]="bibDialogo.excluaProdutosParaAlterar"></ajuda>
    </div>
    <div class="row">
      <data class="col-sm-4 p-0" [id]="'dataMovimento'" [rotulo]="movimentacaoControlador.entrada ? movimentacaoControlador.bibDialogo.dataEntrada : movimentacaoControlador.bibDialogo.dataSaida" [campo]="movimentacaoControlador.movimentacao.dataEntradaSaida" (alteracao)="movimentacaoControlador.movimentacao.dataEntradaSaida = $event" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado" [vertical]="false"></data>
      <mascara *ngIf="movimentacaoControlador.nota" class="col-sm-4 p-0" [rotulo]="movimentacaoControlador.bibDialogo.horario" [campo]="movimentacaoControlador.movimentacao.horaSaida" (alteracao)="setHoraSaida($event)" [tipo]="bibPropriedade.mascara.hora" [desabilitado]="movimentacaoControlador.bloqueado" [vertical]="false"></mascara>
    </div>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
