import { Component, HostListener } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './relatorioLista.component.html',
  styleUrls: ['./relatorioLista.component.css'],
})
export class RelatorioListaComponent extends PaginaComponent {
  public campoPesquisa: string = this.bibDialogo.nome;
  public filtro: string = '';
  public focado: boolean;
  public indice: number = 0;
  public relatorios: RelatorioUsuario[] = [];
  public tag: string;
  public titulo: string;
  public totalRelatorios: RelatorioUsuario[] = [];

  public lista: any[] = [
    { id: this.bibDialogo.modulo, nome: this.bibDialogo.modulo },
    { id: this.bibDialogo.nome, nome: this.bibDialogo.nome },
    { id: this.bibDialogo.numero, nome: this.bibDialogo.numero },
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.relatorios = [];
      this.selecionar(params['operacao']);
      this.titulo = params['operacao'] == 'relatorio' ? this.bibDialogo.relatorios : this.bibDialogo.dashboard;
      this.tag = params['operacao'] == 'relatorio' ? 'rel' : 'das';
      this.utilSessao.setPermissao(null);
      this.relatorios.sort((a, b) => (a['relatorioModulo'] == b['relatorioModulo'] ? (a['idRelatorio'] == b['idRelatorio'] ? 0 : a['idRelatorio'] > b['idRelatorio'] ? 1 : -1) : a['relatorioModulo'] > b['relatorioModulo'] ? 1 : -1));
      this.totalRelatorios = this.relatorios;
    });
  }

  selecionar(operacao: string) {
    if (operacao == 'relatorio') {
      this.relatorios = this.plainToClass(Criterio, this.utilSessao.getRelatorioUsuarios()) as any;
    } else {
      this.relatorios = this.plainToClass(Criterio, this.utilSessao.getDashboardUsuarios()) as any;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (this.focado) {
      switch (event.keyCode) {
        case this.bibAtalho.setaCima:
          event.preventDefault();
          this.indice--;
          if (this.indice < 0) this.indice = 0;
          break;
        case this.bibAtalho.setaBaixo:
          event.preventDefault();
          this.indice++;
          if (this.indice >= this.totalRelatorios.length) {
            this.indice = this.totalRelatorios.length - 1;
          }
          break;
        case this.bibAtalho.enter:
          event.preventDefault();
          this.irRelatorio();
          break;
      }
    }
  }

  irRelatorio(): void {
    if (this.indice > -1) {
      const relatorioId: number = this.getIdRelatorio();
      this.util.irRelatorio(relatorioId);
    }
  }

  alterarValorFiltro(valor: string): void {
    if (this.campoPesquisa != this.bibDialogo.numero && Number(valor)) {
      this.campoPesquisa = this.bibDialogo.numero;
    } else {
      if (this.campoPesquisa == this.bibDialogo.numero && !Number(valor)) {
        this.campoPesquisa = this.bibDialogo.nome;
      }
    }
    this.setFiltro(valor);
  }

  getIdRelatorio(): number {
    if (this.filtro != '' && this.filtro != null) {
      let relatorios: RelatorioUsuario[] = [];
      this.filtro = this.filtro.toLowerCase();
      switch (this.campoPesquisa) {
        case this.bibDialogo.modulo:
          relatorios = this.relatorios.filter((relatorio) => relatorio.relatorioModulo != null && relatorio.relatorioModulo.toLowerCase().includes(this.filtro));
          return relatorios[this.indice].idRelatorio ? relatorios[this.indice].idRelatorio : null;
        case this.bibDialogo.nome:
          relatorios = this.relatorios.filter((relatorio) => relatorio.relatorio.toLowerCase().includes(this.filtro));
          return relatorios[this.indice].idRelatorio ? relatorios[this.indice].idRelatorio : null;
        case this.bibDialogo.numero:
          relatorios = this.relatorios.filter((relatorio) => relatorio.idRelatorio.toString().includes(this.filtro));
          return relatorios[this.indice].idRelatorio ? relatorios[this.indice].idRelatorio : null;
      }
    } else {
      return this.relatorios[this.indice].idRelatorio ? this.relatorios[this.indice].idRelatorio : null;
    }
  }

  setFiltro(filtro: string): void {
    this.filtro = filtro;
    if (this.filtro != null) {
      if (this.campoPesquisa == this.bibDialogo.modulo) {
        this.totalRelatorios = this.relatorios.filter((relatorio) => relatorio.relatorioModulo != null && relatorio.relatorioModulo.toLowerCase().includes(this.filtro.toLowerCase()));
      } else if (this.campoPesquisa == this.bibDialogo.nome) {
        this.totalRelatorios = this.relatorios.filter((relatorio) => relatorio.relatorio.toLowerCase().includes(this.filtro.toLowerCase()));
      } else if (this.campoPesquisa == this.bibDialogo.numero) {
        this.totalRelatorios = this.relatorios.filter((relatorio) => relatorio.idRelatorio.toString() == this.filtro);
      }
    } else {
      this.totalRelatorios = this.relatorios;
    }
  }
}
