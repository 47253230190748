import { NsSatDestinatario } from "./nsSatDestinatario";
import { NsSatEmitente } from "./nsSatEmitente";
import { NsSatLocalEntrega } from "./nsSatLocalEntrega";
import { NsSatPagamento } from "./nsSatPagamento";
import { NsSatProduto } from "./nsSatProduto";

export class NsSat {
    public tipo: string;
    public numeroCaixa: string;
    public identificador: string;
    public nsSatEmitente: NsSatEmitente;
    public nsSatDestinatario: string;
    public nsSatLocalEntrega: NsSatLocalEntrega;
    public nsSatProdutos: NsSatProduto[];
    public tributos: string;
    public separador: string;
    public vDescSubtot: string;
    public vAcresSubtot: string;
    public nsSatPagamentos: NsSatPagamento[];
    public infCpl: string;
    public impressora: string;
    public copias: string;
    public tipoImpressao: string;
}