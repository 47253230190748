import { Type } from 'class-transformer';

export class Integracao {
  @Type(() => Date)
  public data: Date;

  public arquivo: string;
  public ativo: string = 'S';
  public extensao: string;
  public favorito: string;
  public filtro: string;
  public id: number;
  public idEmpresa: number;
  public idIntegracaoCategoria: number;
  public integracaoCategoria: string;
  public nome: string;
  public ordem: string;
  public principal: string;
  public projeto: string;
  public separadorCampo: string;
  public separadorLinha: string;
  public sistema: string;
  public tipoIntegracaoCategoria: string;
  public totalizarBloco: string;
  public usaCaracterEspecial: string;
  public usaFiltro: boolean = true;
  public versao: number;
}
