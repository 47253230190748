import { Component } from '@angular/core';
import { ComissaoFinanceiro } from 'src/app/modelo/comissaoFinanceiro';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  selector: 'comissaoFaturamentoEstorno',
  templateUrl: './comissaoFaturamentoEstorno.component.html',
})
export class ComissaoFaturamentoEstornoComponent extends PaginaComponent {
  public comissaoFinanceiros: ComissaoFinanceiro[] = [];
  public filtros = filtros;
  public valorTotalEstornar: number;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.comissaoFinanceiro).subscribe((res) => {
      this.comissaoFinanceiros = this.plainToClass(ComissaoFinanceiro, res) as any;
      this.valorTotalEstornar = 0;
    });
  }

  setSelecionado(comissaoFinanceiro: ComissaoFinanceiro, marcado: string): void {
    comissaoFinanceiro.selecionado = marcado;
    this.calcularTotalEstorno();
  }

  calcularTotalEstorno(): void {
    let valor: number = 0;
    this.comissaoFinanceiros.forEach((comissaoFinanceiro) => {
      if (comissaoFinanceiro.selecionado == 'S') valor += comissaoFinanceiro.valor;
    });
    this.valorTotalEstornar = valor;
  }

  estornar(): void {
    let comissaoFinanceiroExcluir: number[] = [];
    this.comissaoFinanceiros.forEach((comissaoFinanceiro) => {
      if (comissaoFinanceiro.selecionado == 'S') {
        comissaoFinanceiroExcluir.push(comissaoFinanceiro.id);
      }
    });
    this.temPermissaoExcluir = true;
    this.excluirLista(comissaoFinanceiroExcluir, this.bibServico.comissaoFinanceiro).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.comissaoFinanceiros = this.comissaoFinanceiros.filter((comissaoFinanceiro) => comissaoFinanceiro.selecionado != 'S');
      }
    });
  }
}
