<titulo [titulo]="bibDialogo.depreciacao" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.depreciacao.toLocaleLowerCase()" [desabilitado]="!temPermissaoEdicao"></titulo>
<div *ngIf="patrimonial.patrimonialLancamentosContabeis.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.depreciacao.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="patrimonial.patrimonialLancamentosContabeis.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l-15 text-right">{{ bibDialogo.data }}</th>
      <th class="l-15 text-right">{{ bibDialogo.valor }}</th>
      <th class="l-65"></th>      
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let patrimonialLancamentoContabil of patrimonial.patrimonialLancamentosContabeis; let i = index">
      <tr>
        <td class="text-right">
          <data *ngIf="!patrimonialLancamentoContabil.id" [campo]="patrimonialLancamentoContabil.data" (alteracao)="setData(patrimonialLancamentoContabil, $event)" [obrigatorio]="true" [focoId]="focoData"></data>
          <ng-container *ngIf="patrimonialLancamentoContabil.id">{{ patrimonialLancamentoContabil.data | data }}</ng-container>
        </td>
        <td class="text-right">
          <decimal *ngIf="!patrimonialLancamentoContabil.id" [campo]="patrimonialLancamentoContabil.valor" (alteracao)="patrimonialLancamentoContabil.valor = $event" [obrigatorio]="true" [desabilitado]="true"></decimal>
          <ng-container *ngIf="patrimonialLancamentoContabil.id">{{ patrimonialLancamentoContabil.valor | monetario }}</ng-container>
        </td> 
        <td></td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirPatrimonialLancamentoContabil(i, patrimonialLancamentoContabil.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
