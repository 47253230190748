<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="10">
    <table class="tabela-complementar">
      <thead>
        <th class="r-0-5">{{ bibDialogo.loja }}</th>
        <th class="r-1">{{ bibDialogo.data }}</th>
        <th class="r-1">{{ bibDialogo.vencimento }}</th>
        <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
        <th class="r-1 text-right">{{ bibDialogo.numeroFiscal }}</th>
        <th class="r">{{ bibDialogo.vendedor }}</th>
        <th class="r-0-5 text-right fonte-pequena">{{ bibDialogo.diasAtraso }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorVencido }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
        <th class="r-1 text-right">{{ bibDialogo.credito }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorLiquido }}</th>
        <th class="r-1 text-right">{{ bibDialogo.juro }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let financeiro of financeiros">
          <tr class="conteudo">
            <td>{{ financeiro.abreviacao }}</td>
            <td>{{ financeiro.data | data }}</td>
            <td>{{ financeiro.dataVencimento | data }}</td>
            <td class="text-right">
              <a href="#" (click)="abrirModalFinanceiro(financeiro.id, financeiro.tipo)">{{ financeiro.numero }}</a>
            </td>
            <td class="text-right">{{ financeiro.numeroFiscal }}</td>
            <td>{{ financeiro.numeroColaborador && financeiro.colaborador ? financeiro.numeroColaborador + ' - ' + financeiro.colaborador : '' }}</td>
            <td class="text-right">{{ financeiro.diaAtraso }}</td>
            <td class="text-right">{{ financeiro.valorVencido > 0 ? (financeiro.valorVencido | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.valorTotal > 0 ? (financeiro.valorTotal | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.valorCredito > 0 ? (financeiro.valorCredito | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.valorLiquido > 0 ? (financeiro.valorLiquido | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.juros > 0 ? (financeiro.juros | monetario) : '' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
