<ng-container class="border-bottom border-dark">
  <td class="p-0" colspan="7">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.produto }}</th>
          <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
          <th class="text-right r-1">{{ bibDialogo.qtd }}</th>
          <th class="r-2 text-right quebra-linha">{{ bibDialogo.quantidadeEntregueAbreviacao }}</th>
          <th class="r-2 text-right">{{ bibDialogo.quantidadePendenteAbreviada }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos; let i = index">
          <tr>
            <td>{{ movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto }}</td>
            <td>{{ movimentacaoProduto.unidadeAbreviacao }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidadeEntregue | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidadePendente | monetario: this.empresaRegraCasasDecimais }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
