export class Icms20 {
    public orig: string;
    public CST: string;
    public modBC: string;
    public pRedBC: string;
    public vBC: string;
    public pICMS: string;
    public vICMS: string;
    public vICMSDeson: string;
    public motDesICMS: string;
    public vBCFCP: string;
    public pFCP: string;
    public vFCP: string;
}