import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - TODOS', id: '-' },
  { nome: '02 - CONCILIADOS', id: '1' },
  { nome: '03 - NÃO CONCILIADOS', id: '2' },
]);

const filtroComboTransferencia: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.sim, id: '-' },
  { nome: bibDialogo.nao, id: ' is null and con.id_transferencia_cheque is null' },
]);

const filtroComboTipo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - TODOS', id: '-' },
  { nome: '02 - CRÉDITO', id: '2' },
  { nome: '03 - DÉBITO', id: '10' },
]);
const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataLancamentoDe, nome: 'DATA_LANCAMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataLancamentoAte, nome: 'DATA_LANCAMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.transferencia, nome: 'TRANSFERENCIA', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboTransferencia },
  { rotulo: bibDialogo.tipoTitulo, nome: 'IDS_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ATIVO', 'S'), new Criterio('IDS_ESPECIE', '2, 10')] },
  { rotulo: bibDialogo.conta, nome: 'IDS_CONTA', coluna: 6, servicoWeb: bibServico.conta, obrigatorio: 'S', tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboSituacao },
  { rotulo: bibDialogo.tipo, nome: 'ID_ESPECIE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboTipo },
]);
export default filtros;
