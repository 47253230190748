<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th [ngClass]="servico == bibServico.configuracaoFinanceiroContabil ? 'l-80' : 'l-90'" class="clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th *ngIf="servico == bibServico.configuracaoFinanceiroContabil" class="l-10">{{ bibDialogo.tipo }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let objeto of objetos; let i = index">
        <tr>
          <td class="limitar">
            <a href="#" (click)="ir(objeto.id, i, objetos)">{{ objeto.nome }}</a>
          </td>
          <td *ngIf="servico == bibServico.configuracaoFinanceiroContabil" class="limitar">{{ objeto.tipo == 'R' ? bibDialogo.receita : bibDialogo.despesa }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="irPara(objeto.id)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirObjeto(objeto.id, objeto.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
