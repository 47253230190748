import { Component, HostListener, Input } from '@angular/core';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Baixa } from 'src/app/modelo/baixa';
import { Financeiro } from 'src/app/modelo/financeiro';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Resultado } from 'src/app/modelo/resultado';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { FinanceiroFrmComponent } from '../../financeiro/frm/financeiroFrm.component';
import { Permissao } from 'src/app/modelo/permissao';

@Component({
  selector: 'baixaLista',
  templateUrl: './baixaLista.component.html',
  styleUrls: ['./baixaLista.component.css'],
})
export class BaixaListaComponent {
  @Input() baixa: Baixa;
  @Input() financeiros: Financeiro[] = [];
  private baixaEmt: any;
  public bibDialogo = bibDialogo;
  public compacto: boolean;
  public dataAtual: Date = new Date();
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public focoIdInicial: number;
  public menuTipoDeTitulo: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30);
  public selecionaTodos: string = 'N';
  public selecionaVencimento: string = 'N';
  public util: Util = new Util();
  public componente: any;

  constructor(public utilSessao: UtilSessao) {
    this.compacto = this.utilSessao.getEmpresa().compacto == 'S';
  }

  ngOnInit(): void {
    this.baixaEmt = this.baixa.baixaNotificacao.baixaEmt.subscribe((res) => {
      if (res == 3) {
        this.irBaixa();
      }
      this.baixa.selecionadoAtrasados = 'N';
    });
    if (this.baixa.financeiros.length == 1 && this.baixa.financeiros[0].dataConferencia == null) {
      this.baixa.setSelecionado(this.baixa.financeiros[0], 'S');
      this.irBaixa();
    }
    this.focoIdInicial = Math.random();
  }

  ngOnDestroy(): void {
    this.baixaEmt.unsubscribe();
  }

  irBaixa(): void {
    if (this.ehValido()) {
      this.baixa.parcelamento = null;
      this.baixa.mostrarFinanceiroSelecionados().forEach((financeiro) => (financeiro.expandido = false));
      this.baixa.setEtapa(3);
      this.baixa.financeiroBaixas = [];
      if (this.baixa.baixaRapida) {
        this.adicionarFinanceiroBaixaRapida();
      } else {
        const financeiroBaixa = new FinanceiroBaixa(this.baixa.valorRestanteLiquido);
        this.baixa.adicionarFinanceiroBaixa(financeiroBaixa);
      }
    }
  }

  abrirFinanceiro(idFinanceiro: number, tipo: string): void {
    const MENU_RECEITA = 28;
    const MENU_DESPESA = 41;
    const idMenu: number = tipo == 'D' ? MENU_DESPESA : MENU_RECEITA;
    const identificacoes: Identificacao[] = [new Identificacao('objeto', idFinanceiro), new Identificacao('parametro', tipo)];
    this.componente = this.util.abrirModal(FinanceiroFrmComponent, identificacoes, idMenu)
  }

  adicionarFinanceiroBaixaRapida() {
    for (const financeiro of this.baixa.mostrarFinanceiroSelecionados()) {
      const financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa(financeiro.valorRestante);
      financeiroBaixa.idTipoTitulo = financeiro.idTipoTitulo;
      financeiroBaixa.idConta = financeiro.idConta;
      financeiroBaixa.idFinanceiro = financeiro.id;
      this.baixa.adicionarFinanceiroBaixa(financeiroBaixa);
    }
  }

  ehClienteAvista(): boolean {
    const idClienteAVista: number = this.utilSessao.getEmpresa().idConsumidorFinal;
    return this.baixa.mostrarFinanceiroSelecionados().find((financeiroSelecionado) => financeiroSelecionado.idParceiro == idClienteAVista) != null;
  }

  setSelecionadoTodos(): void {
    this.selecionaTodos = this.selecionaTodos == 'S' ? 'N' : 'S';
    for (let financeiro of this.baixa.financeiros) {
      this.baixa.setSelecionado(financeiro, this.selecionaTodos);
    }
  }

  setSelecionadoAtrasados(): void {
    this.baixa.financeiros.forEach((financeiro) => {
      if (financeiro.dataVencimento.getTime() < new Date().getTime()) {
        financeiro.selecionado = this.baixa.selecionadoAtrasados == 'N' ? 'S' : 'N';
        this.baixa.setSelecionado(financeiro, financeiro.selecionado);
      }
    });
    this.baixa.selecionadoAtrasados = this.baixa.selecionadoAtrasados == 'N' ? 'S' : 'N';
    this.baixa.totalizarRestanteLiquido();
  }

  setSelecionadoVencimento(): void {
    this.baixa.financeiros.forEach((financeiro) => {
      if (financeiro.dataVencimento.getDate() == this.baixa.dataVencimentoAte.getDate() && financeiro.dataVencimento.getMonth() == this.baixa.dataVencimentoAte.getMonth() && financeiro.dataVencimento.getFullYear() == this.baixa.dataVencimentoAte.getFullYear()) {
        financeiro.selecionado = this.baixa.selecionadoAtrasados == 'N' ? 'S' : 'N';
        this.baixa.setSelecionado(financeiro, financeiro.selecionado);
      }
    });
    this.selecionaVencimento = this.selecionaVencimento == 'N' ? 'S' : 'N';
    this.baixa.totalizarRestanteLiquido();
  }

  ehValido(): boolean {
    const financeiroSelecionados: Financeiro[] = this.baixa.financeiros.filter((financeiro) => financeiro.selecionado == 'S');
    let quantidadeParceiros: number = 0;
    if (this.ehClienteAvista()) {
      quantidadeParceiros = financeiroSelecionados.filter((financeiro) => financeiro.idParceiro == financeiroSelecionados[0].idParceiro && financeiro.parceiro == financeiroSelecionados[0].parceiro).length;
    } else {
      quantidadeParceiros = financeiroSelecionados.filter((financeiro) => financeiro.idParceiro == financeiroSelecionados[0].idParceiro).length;
    }
    const numeroBaixaMovimentacaoProdutoSelecionados: number = quantidadeParceiros > 0 ? 0 : this.quantidadeProdutosSelecionados();
    if (this.ehValidoFinanceiroUnificado(financeiroSelecionados)) {
      if (financeiroSelecionados.length > 0 || numeroBaixaMovimentacaoProdutoSelecionados > 0) {
        if (this.baixa.baixaRapida == false) {
          if (financeiroSelecionados.length != quantidadeParceiros && this.baixa.parametro != 'DESPESA') {
            this.utilSessao.setResultado(new Resultado(false, this.baixa.parametro == 'DESPESA' ? this.baixa.bibDialogo.naoPermitidoBaixarFinanceiroDeFornecedoresDiferentes : this.baixa.bibDialogo.naoPermitidoBaixarFinanceiroDeParceirosDiferentes));
            return false;
          }
          this.baixa.idParceiroBuscaSelecionado = financeiroSelecionados[0].idParceiro;
        }
        return true;
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.baixa.bibDialogo.selecioneFinanceiro));
        return false;
      }
    }
  }

  quantidadeProdutosSelecionados(): number {
    let produtosSelecionados: number = 0;
    this.baixa.financeiros.forEach((financeiro) => {
      financeiro.baixaMovimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.selecionado == 'S') {
          produtosSelecionados++;
        }
      });
    });
    return produtosSelecionados;
  }

  ehValidoFinanceiroUnificado(financeiroSelecionados: Financeiro[]): boolean {
    this.baixa.movimentacaoComCartaoCredito = false;
    const emCartaoFinanceiros: Financeiro[] = financeiroSelecionados.filter((financeiro) => financeiro.tipoTituloIdEspecie == 2 || financeiro.tipoTituloIdEspecie == 10);
    if (emCartaoFinanceiros.length > 0) {
      this.baixa.movimentacaoComCartaoCredito = true;
      let parcela: string = emCartaoFinanceiros[0].parcela;
      let mesmaParcela: boolean = true;
      emCartaoFinanceiros.forEach((financeiro) => {
        if (parcela != financeiro.parcela) {
          mesmaParcela = false;
          this.utilSessao.setResultado(new Resultado(false, this.baixa.bibDialogo.entreAsMovimentacoesExisteNegociacaoCartaoParcelaDiferente));
          return;
        }
      });
      return mesmaParcela;
    }
    return true;
  }
}
