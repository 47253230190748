<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.transferenciaPisCofins }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r-1">{{ bibDialogo.movimentacaoNumeroAbreviacao }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.pisBase }}</th>
    <th class="r-1">{{ bibDialogo.pisValor }}</th>
    <th class="r-1">{{ bibDialogo.cofinsBase }}</th>
    <th class="r-1">{{ bibDialogo.cofinsValor }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let transferencia of transferenciaPisCofins">
      <tr class="conteudo">
        <td>{{ transferencia.id }}</td>
        <td>{{ transferencia.data | data }}</td>
        <td>{{ transferencia.idOperacao + ' - ' + transferencia.operacao }}</td>
        <td>{{ transferencia.numero }}</td>
        <td>{{ transferencia.idLoja + ' - ' + transferencia.loja }}</td>
        <td>{{ transferencia.idEmpresa + ' - ' + transferencia.empresa }}</td>
        <td>{{ transferencia.basePis | monetario }}</td>
        <td>{{ transferencia.valorPis | monetario }}</td>
        <td>{{ transferencia.baseCofins | monetario }}</td>
        <td>{{ transferencia.valorCofins | monetario }}</td>
        <td>{{ transferencia.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
