<layout [titulo]="movimentacoes.length > 0 ? permissao.menu + ' - ' + movimentacoes[0].operacao : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm text-nowrap">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('status'), this.utilSessao.posicao, this.paginacao)"></th>
        <th class="l-5 clique"></th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-30 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parceiro }}</th>
        <th class="l-20 clique" (click)="listar(add('colaborador'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.responsavel }}</th>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorTotal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotal }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr>
          <td>
            <check [campo]="movimentacao.status == 'S' ? 'N' : 'S'" (alteracao)="movimentacao.status = $event == 'S' ? 'A' : 'S'"></check>
          </td>
          <td><i *ngIf="movimentacao.status == 'S'" [ngClass]="bibIcone.cadeadoFechado"></i></td>
          <td>{{ movimentacao.abreviacao }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td class="limitar">{{ movimentacao.parceiro }}</td>
          <td class="limitar">{{ movimentacao.colaborador }}</td>
          <td>{{ movimentacao.data | data }}</td>
          <td class="text-right">{{ movimentacao.valorTotal | monetario }}</td>
          <td class="row justify-content-end">
            <btnAdicional [icone]="bibIcone.olho" (btnAdicional)="visualizar(movimentacao.id)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<div class="ml-1 row">
  <botao [compacto]="compacto" [legenda]="bibDialogo.abrir" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="exigirSenha()"></botao>
  <texto *ngIf="focoIdSenha" [id]="'senhaOperacional'" [ngClass]="compacto ? 'mt-2' : ''" [descricao]="bibDialogo.senhaOperacional" (navegacao)="$event == false ? (focoIdSenha = null) : ''" [tipo]="bibPropriedade.texto.senha" (alteracao)="conferirSenha($event)" [texto]="bibDialogo.senha" [focoId]="focoIdSenha" autocomplete="new-password"></texto>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
