<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-40 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-35 clique" (click)="listar(add('empresa'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.fabricante }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoFormulado of produtoFormulados; let i = index">
        <tr [ngClass]="{ tachado: produtoFormulado.ativo != 'S' }">
          <td class="limitar">
            <icone *ngIf="produtoFormulado.grauToxicologica == 'I' || produtoFormulado.grauToxicologica == 'II' || produtoFormulado.grauToxicologica == 'III' || produtoFormulado.grauToxicologica == 'IV'" [icone]="bibIcone.quadrado" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="produtoFormulado.grauToxicologica == 'I' ? bibPropriedade.icone.vermelho : produtoFormulado.grauToxicologica == 'II' ? bibPropriedade.icone.amarelo : produtoFormulado.grauToxicologica == 'III' ? bibPropriedade.icone.azul : bibPropriedade.icone.verde" [mensagem]="produtoFormulado.classeToxicologica"></icone>
          </td>
          <td class="limitar">
            <a href="#" (click)="ir(produtoFormulado.id, i, produtoFormulados)">{{ produtoFormulado.nome }}</a>
          </td>

          <td class="limitar">{{ produtoFormulado.empresa }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(produtoFormulado.id, i, produtoFormulados)"></btnAdicional>
          </td>
          <td>
            <btnAdicional *ngIf="produtoFormulado.idEmpresa" [icone]="bibIcone.lixo" (btnAdicional)="excluirCidade(produtoFormulado.id, produtoFormulado.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
