import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Permissao } from 'src/app/modelo/permissao';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: ' <tr>[r2021pedidoCarteira]</tr>',
  templateUrl: './r2021pedidoCarteira.component.html',
})
export class R2021PedidoCarteiraComponent {
  @Input() empresaRegraCasasDecimaisEntrada: number;
  @Input() pedidoCarteiras: any[] = [];
  @Input() utilSessao: UtilSessao;
  @Input() telaPedidoCarteira: Permissao;
  public bibDialogo = bibDialogo;
  public componente: any = null;

  irMovimentacao(id: number) {
    this.componente = new Util().abrirModal(MovimentacaoFrmComponent, [new Identificacao('objeto', id)], null);
  }
}
