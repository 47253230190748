import { Component } from '@angular/core';
import { bibServicoNs } from 'src/app/biblioteca/bibServicoNs';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoCartaCorrecao } from 'src/app/modelo/movimentacaoCartaCorrecao';
import { MovimentacaoNfe } from 'src/app/modelo/movimentacaoNfe';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { CartaCorrecaoFrmComponent } from './cartaCorrecaoFrm.component';

@Component({
  templateUrl: './cartaCorrecao.component.html',
})
export class CartaCorrecaoComponent extends PaginaComponent {
  public bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public bibServicoNs: bibServicoNs = new bibServicoNs();
  public componente: any;
  public contagemCartaCorrecaoAutorizado: number = 0;
  public data: Date;
  public movimentacaoNfe: MovimentacaoNfe;
  public parceiro: string;

  ngOnInit(): void {
    this.movimentacaoNfe = this.utilSessao.getIdentificacao('movimentacaoNfe')?.conteudo;
    this.data = this.utilSessao.getIdentificacao('data')?.conteudo;
    this.parceiro = this.utilSessao.getIdentificacao('parceiro')?.conteudo;
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibServico.movimentacaoCartaCorrecao) {
        this.movimentacaoNfe.movimentacaoCartaCorrecoes.unshift(identificacao.conteudo);
      }
    });
    this.listarCartaCorrecao();
  }

  private listarCartaCorrecao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', this.movimentacaoNfe.idMovimentacao)), this.bibServico.movimentacaoCartaCorrecao).subscribe((res) => {
      this.movimentacaoNfe.movimentacaoCartaCorrecoes = this.plainToClass(MovimentacaoCartaCorrecao, res) as any;
      this.contagemCartaCorrecaoAutorizado = this.movimentacaoNfe.movimentacaoCartaCorrecoes.filter((movimentacaoCartaCorrecao) => movimentacaoCartaCorrecao.status == 135).length;
      if (this.movimentacaoNfe.movimentacaoCartaCorrecoes.length == 0 && this.movimentacaoNfe.situacaoAutorizado == 'S') {
        this.abrirModal();
      }
    });
  }

  excluirMovimentacaoCartaCorrecao(movimentacaoCartaCorrecao: MovimentacaoCartaCorrecao): void {
    if (confirm(this.bibDialogo.desejaExcluirCartaCorrecao)) {
      this.excluirLista([movimentacaoCartaCorrecao.id], this.bibServico.movimentacaoCartaCorrecao).subscribe();
      this.movimentacaoNfe.movimentacaoCartaCorrecoes = this.movimentacaoNfe.movimentacaoCartaCorrecoes.filter((movimentacaoCartaCorrecaoBusca) => movimentacaoCartaCorrecaoBusca.id != movimentacaoCartaCorrecao.id);
    }
  }

  abrirModal(): void {
    this.utilSessao.setIdentificacao(new Identificacao('idMovimentacao', this.movimentacaoNfe.idMovimentacao));
    this.utilSessao.setIdentificacao(new Identificacao('idTecnospeed', this.movimentacaoNfe.idTecnospeed));
    this.componente = CartaCorrecaoFrmComponent;
  }


  consultarCartaCorrecao(movimentacaoCartaCorrecao: MovimentacaoCartaCorrecao): void {
    if (this.utilSessao.getEmpresa().integracaoNs == 'S') {
      this.consultarCartaCorrecaoNs(movimentacaoCartaCorrecao);
    } else {
      this.consultarCartaCorrecaoTecnospeed(movimentacaoCartaCorrecao);
    }
  }

  consultarCartaCorrecaoTecnospeed(movimentacaoCartaCorrecao: MovimentacaoCartaCorrecao): void {
    this.comunicacaoTecnoSpeedService.listar(this.bibServicoTecnoSpeed.getUrlConsultaCorrecaoNfe(this.movimentacaoNfe.idTecnospeed), null).subscribe((res) => {
      if (res && res.data) {
        movimentacaoCartaCorrecao.status = res.data.cStat ? res.data.cStat : -1;
        movimentacaoCartaCorrecao.sequencia = res.data.numeroSequencia;
        movimentacaoCartaCorrecao.statusDescricao = res.data.respostaSefaz;
        this.persistir(new Transporte(movimentacaoCartaCorrecao), this.bibServico.movimentacaoCartaCorrecao, null).subscribe();
      }
    });
  }

  consultarCartaCorrecaoNs(movimentacaoCartaCorrecao: MovimentacaoCartaCorrecao, tipo: string = 'j', sequencia: number = 1) {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
      "chNFe": this.utilSessao.getIdentificacao('chaveFiscal').conteudo,
      "tpDown": tipo.toString(),
      "tpEvento": "cce",
      "tpAmb": this.bibServicoNs.getAmbiente(),
      "nSeqEvento": sequencia.toString(),
    });
    this.comunicacaoNsService.persistir(this.bibServicoNs.getUrlEventos(), transporte).subscribe(
      (res) => {
        const PDF: string = 'p';
        if (tipo == PDF) {
          console.log(res)
          var data = Uint8Array.from(atob(res.pdf), c => c.charCodeAt(0));
          var blob = new Blob([data], { type: 'application/pdf' });
          window.open(window.URL.createObjectURL(blob));
        } else {
          movimentacaoCartaCorrecao.status = res.retEvento.cStat ? res.retEvento.cStat : -1;
          movimentacaoCartaCorrecao.sequencia = res.json.evento.infEvento.nSeqEvento;
          movimentacaoCartaCorrecao.statusDescricao = res.retEvento.xMotivo ? res.retEvento.xMotivo : -1;
          this.persistir(new Transporte(movimentacaoCartaCorrecao), this.bibServico.movimentacaoCartaCorrecao, null).subscribe();
        }
      },
      (error) => {
        console.log(error)
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEnviarCartaCorrecao));
      }
    );
  }

  baixarPdf(): void {
    if (this.utilSessao.getEmpresa().integracaoNs == 'S') {
      let sequencia: number = 0;
      this.movimentacaoNfe.movimentacaoCartaCorrecoes.forEach((cartaCorrecao) => {
        cartaCorrecao.sequencia > sequencia ? sequencia = cartaCorrecao.sequencia : '';
      })
      this.consultarCartaCorrecaoNs(this.movimentacaoNfe.movimentacaoCartaCorrecoes[0], 'p', sequencia);
    } else {
      this.comunicacaoTecnoSpeedService.consumirPdf(this.bibServicoTecnoSpeed.getUrlBaixaPdfCartaCorrecao(this.movimentacaoNfe.idTecnospeed)).subscribe(
        (res) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (erro) => {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
        }
      );
    }
  }
}
