import { Component, Input } from '@angular/core';
import bibIcone from 'src/app/biblioteca/bibIcone';
@Component({
  selector: 'ajuda',
  templateUrl: './ajuda.component.html',
})
export class AjudaComponent {
  @Input() ajuda: string;
  @Input() ajudaDireita: boolean = false;
  @Input() icone: string;
  @Input() posicao: string = 'direita';
  public bibIcone = bibIcone;
}
