import { BaseCalculo } from "./baseCalculo";

export class UfDestino {
  public baseCalculoIcms: number;
  public baseCalculoFcp: number;
  public percentualIcmsFcp: number;
  public aliquotaInterna: number;
  public aliquotaInterestadual: number;
  public icmsRelativoFcp: number;
  public icmsInterestadual: number;
  public baseCalculo: BaseCalculo;
  public valor: number;
}
