import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import bibIcone from 'src/app/biblioteca/bibIcone';
import { AjudaComponent } from 'src/app/essencial/ajuda/ajuda.component';

@Component({
  selector: 'r2001MovimentacaoNegociacao',
  templateUrl: './r2001MovimentacaoNegociacao.component.html',
})
export class R2001MovimentacaoNegociacaoComponent {
  @Input() movimentacaoNegociacoes: any[] = [];
  public menuNegociacaoApelido = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 71).apelido;
  public bibDialogo = bibDialogo;
}
