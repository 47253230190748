import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Identificacao } from '../modelo/identificacao';
import { LoginEmitter } from '../pagina/login/login.emitter';
import { UtilSessao } from '../utilitario/util.sessao';

@Injectable()
export class AutenticacaoGuard implements CanActivate, CanLoad {
  private activatedRouteSnapshot: ActivatedRouteSnapshot = null;
  constructor(private router: Router, private loginEmitter: LoginEmitter, private titleService: Title, private utilSessao: UtilSessao, route: ActivatedRoute) {
    this.activatedRouteSnapshot = route.snapshot;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    this.activatedRouteSnapshot = route;
    return this.validar();
  }

  canLoad(): Observable<boolean> | boolean {
    if (this.activatedRouteSnapshot != null) {
      return this.validar();
    } else {
      return false;
    }
  }

  validar(): boolean {
    let nomeRotaOriginal: string = '';
    let nomeRota: string = '';
    let telaHistorico: string = this.utilSessao.getTelaHistorico() ? this.utilSessao.getTelaHistorico() : '';
    let voltandoDoFrm: boolean = telaHistorico.indexOf('Frm') != -1;

    if (this.activatedRouteSnapshot.url[0]) {
      nomeRota = this.activatedRouteSnapshot.url[0].path;
      nomeRotaOriginal = this.activatedRouteSnapshot.url[0].path;
      if (this.activatedRouteSnapshot['_routerState'].url.indexOf('/Frm') > 0) {
        this.activatedRouteSnapshot.url[0].path;
        nomeRota = this.activatedRouteSnapshot['_routerState'].url.toString().split("/")[1] + 'Frm';
        nomeRotaOriginal = this.activatedRouteSnapshot['_routerState'].url.toString().split("/")[1] + 'Frm';
      }
    }

    let atalhoReceituario: Boolean = false;
    if (telaHistorico == '/movimentacaoFrm' && nomeRotaOriginal == 'receituario') {
      atalhoReceituario = true;
    }

    let permitido: boolean = false;
    if (nomeRota == '' || nomeRota == '**' || nomeRota == 'relatorioLista') {
      permitido = true;
    }
    if (nomeRota == '' || nomeRota == '**' || nomeRota == 'integracaoLista') {
      permitido = true;
    }
    let parametro: string = null;
    let parametroHistorico = telaHistorico?.split('/').length > 2 && nomeRota.indexOf('Frm') != 0 ? telaHistorico?.split('/')[2] : this.utilSessao.getParametroHistorico();
    this.utilSessao.setParametroHistorico(parametroHistorico);
    if (!permitido) {
      if (nomeRota.indexOf('Frm') > 0) {
        nomeRota = nomeRota.substr(0, nomeRota.indexOf('Frm'));
        const identificacao: Identificacao = this.utilSessao.getIdentificacao('parametro');
        if (identificacao) {
          parametro = identificacao.conteudo;
        }
      } else {
        if (this.activatedRouteSnapshot.url[1] != null) {
          parametro = this.activatedRouteSnapshot.url[1].path;
        }
      }
    }

    let permissoes = this.utilSessao.getPermissoes();
    let usuario = this.utilSessao.getUsuario();

    if (permitido == false) {
      if (permissoes) {
        let permissao = null;
        if (parametro) {
          permissao = permissoes.filter((permissao) => permissao.tela == nomeRota && permissao.parametro == parametro)[0];
        } else {
          permissao = permissoes.filter((permissao) => permissao.tela == nomeRota)[0];
        }
        if (permissao != null) {
          if (nomeRotaOriginal.indexOf('Frm') > 0 && permissao.inserir != 'S' && permissao.acessar != 'S' && this.utilSessao.getIdentificacao('id') == null) {
            permitido = false;
          } else {
            this.utilSessao.modal = false;
            this.utilSessao.setPermissao(permissao);
            this.titleService.setTitle(permissao.menu);
            permitido = true;
          }
        }
      }
    }

    if (usuario != null && permitido) {
      this.loginEmitter.permitir(permissoes);
      this.loginEmitter.logar(usuario, true);
      return true;
    } else {
      if (usuario == null) {
        this.router.navigate(['login']);
        this.utilSessao.setParametro(null);
        this.utilSessao.setParametroCadastro(null);
      }
      return false;
    }
  }
}
