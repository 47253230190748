<layout [titulo]="servico == bibServico.configuracaoMovimentacaoTotalContabil ? bibDialogo.configuracaoMovimentacaoAtalho : bibDialogo.configuracaoFinanceiro" [id]="objeto?.id">
  <div class="row">
    <lista class="col-sm" [id]="'totalizador'" [rotulo]="bibDialogo.totalizador" [campo]="bibServico.configuracaoMovimentacaoTotalContabil == servico ? objeto?.idTotalMovimentacaoContabil : bibServico.configuracaoFinanceiroTotalContabil == servico ? objeto?.idTotalFinanceiroContabil : objeto?.idTotalTransferenciaContabil" [foco]="true" (alteracao)="setIdTotal($event)" [lista]="totais" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm" [id]="'tipoDebitoCredito'" [rotulo]="bibDialogo.tipo" [campo]="objeto.debitoCredito" [obrigatorio]="true" (alteracao)="objeto.debitoCredito = $event.id" [lista]="!objeto.id && !objeto.alterado ? debitoCredito : alterarDebitoCredito"></lista>
  </div>
  <div class="row">
    <busca class="col-sm-12" [id]="'contaDebito'" [rotulo]="objeto.debitoCredito == 1 || objeto.debitoCredito == 0 ? bibDialogo.contaDebito : objeto.debitoCredito == -1 ? bibDialogo.contaCredito : bibDialogo.conta" [campo]="objeto?.contaContabil" (alteracao)="setContaContabil($event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [obrigatorio]="true" [minimoCaracterBusca]="1" [idMenu]="permissaoContaContabil && permissaoContaContabil.inserir == 'S' ? menuContaContabil.idMenu : null"></busca>
    <busca class="col-sm-12" *ngIf="objeto.debitoCredito == 0" [id]="'contaCredito'" [rotulo]="bibDialogo.contaCredito" [campo]="objeto?.contaCredito" (alteracao)="setContaCredito($event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [minimoCaracterBusca]="1" [obrigatorio]="objeto.debitoCredito == 0 ? true : false" [idMenu]="permissaoContaContabil && permissaoContaContabil.inserir == 'S' ? menuContaContabil.idMenu : null"></busca>
  </div>
  <div class="row">
    <pesquisaTextoBusca class="col-sm" [id]="'historico'" [rotulo]="menuHistorico.apelido" [campo]="objeto?.historico" (alteracao)="objeto.historico = $event" [obrigatorio]="true" [icone]="bibIcone.lupa" [servico]="bibServico.historico" [criterios]="criterioHistorico" [idMenu]="permissaoHistorico && permissaoHistorico.inserir == 'S' ? menuHistorico.idMenu : null"></pesquisaTextoBusca>
  </div>
  <div class="row">
    <lista class="col-sm" *ngIf="tipoLancamentoContabeis.length > 1" [idMenu]="permissaoTipoLancamentoContabil && permissaoTipoLancamentoContabil.inserir == 'S' ? menuTipoLancamentoContabil.idMenu : null" [id]="'tipoLancamentoContabil'" [rotulo]="menuTipoLancamentoContabil.apelido" [campo]="objeto?.idTipoLancamentoContabil" (alteracao)="setTipoLancamnetoContabil($event)" [lista]="tipoLancamentoContabeis" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="listarTipoLancamentoContabil()"></lista>
    <lista class="col-sm-6" *ngIf="utilSessao.sistema != 'aGrow-w'" [id]="'lancamentoDireto'" [rotulo]="bibDialogo.lancamentoDireto" [campo]="objeto?.lancamentoDireto" (alteracao)="objeto.lancamentoDireto = $event.id" [lista]="lancamentoDiretos" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm-6" *ngIf="utilSessao.sistema != 'aGrow-w'" [id]="'origem'" [rotulo]="bibDialogo.origem" [campo]="objeto?.origem" (alteracao)="objeto.origem = $event.id" [lista]="origens" [obrigatorio]="true"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirObjeto()"></botao>
