import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const grupos: Grupo[] = [
  { rotulo: utilFiltro.apelidoTelaRelacionamento, campo: 'relacionamento' },
  { rotulo: bibDialogo.uf, campo: 'estado' },
  { rotulo: bibDialogo.vendedor, campo: 'vendedor' },
];
export default grupos;
