import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r1151contas]</tr>',
  templateUrl: './r1151contas.component.html',
  styleUrls: ['./r1151contas.component.css'],
})
export class R1151ContasComponent {
  @Input() conciliacoes: any[] = [];
  public bibDialogo = bibDialogo;
  @Input() saldoTotal: number = 0;
  public inadimplenteFinanceiros: any[] = [];
  public indadimplenteReceita: number = 0;
  public indadimplenteDespesa: number = 0;
}
