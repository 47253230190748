<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)">
      <table>
        <tbody>
          <colgroup>
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
            <col width="10%"><col width="10%">
          </colgroup>        
          <tr>
            <td colspan="1" rowspan="5" class="text-center bordaTop bordaLeft"><img [src]="empresa.fotoEmpresa ? empresa.fotoEmpresa : imagemEmpresa" [alt]="empresa.nome" height="80" width="80" /></td>
            <td colspan="8" class="align-items-center bordaTop"><h6>{{ dados.loja }}</h6></td>
            <td colspan="1" rowspan="2" class="text-center align-items-center bordaTop bordaLeft bordaRight centraliza-vertical limitar"><b> {{ tipoRelatorio }} <br> {{ dados.numero  }}</b></td>
          </tr>  
          <tr>
            <td colspan="4" class="align-items-center bordaTopWhite">{{ bibDialogo.cnpj + ': ' + dados.lojaCnpj}}</td>
            <td colspan="5" class="align-items-center bordaTopWhite">{{ bibDialogo.inscricaoEstadual  + ': ' + dados.lojaInscricaoEstadual}}</td>
          </tr>   
          <tr>
            <td colspan="8" class="align-items-center bordaTopWhite">{{ bibDialogo.endereco + ': ' + dados.lojaEndereco}}</td>
            <td colspan="1" class="text-center bordaLeft bordaRight">{{ dados.tipo == "R" ? 'CRÉDITO' : 'DÉBITO' }}</td>
          </tr>    
          <tr>
            <td colspan="8" class="align-items-center bordaTopWhite">{{ bibDialogo.telefone + ': ' + dados.lojaTelefone}}</td>
            <td colspan="1" class="text-center bordaLeft bordaRight">{{ bibDialogo.emissao }}</td>
          </tr>  
          <tr>
            <td colspan="8" class="align-items-center bordaTopWhite">{{ bibDialogo.email + ': ' + dados.lojaEmail}}</td>
            <td colspan="1" class="text-center bordaTopWhite bordaLeft bordaRight">{{ dados.dataEmissao | data }}</td>
          </tr>  
          <tr>
            <td colspan="1" rowspan="11" class="bordaLeft bordaRight alinharVertical limitar">{{ dados.loja}}</td>
            <td colspan="2" class="text-center align-items-center bordaRight"><b>{{ bibDialogo.numero + ' ' + bibDialogo.fatura }}</b></td>
            <td colspan="2" class="text-center align-items-center bordaRight"><b>{{ bibDialogo.valor }} </b></td>
            <td colspan="2" class="text-center align-items-center bordaRight"><b>{{ bibDialogo.numero + ' ' + bibDialogo.ordem }}</b></td>
            <td colspan="2" class="text-center align-items-center bordaRight"><b>{{ bibDialogo.vencimento }}</b></td>
            <td colspan="1" rowspan="8" class="text-center align-items-center bordaRight"><b>{{ bibDialogo.paraUsoInstituicao }} <br> {{ bibDialogo.financeira }}</b></td>              
          </tr>   
          <tr>
            <td colspan="2" class="text-center align-items-center bordaRight">{{ notas }}</td>
            <td colspan="2" class="text-center align-items-center bordaRight">{{ dados.valorTotal | monetario }}</td>
            <td colspan="2" class="text-center align-items-center bordaRight">{{ "01/01" }}</td>
            <td colspan="2" class="text-center align-items-center bordaRight">{{ dados.dataVencimento | data}}</td>
          </tr>   
          <tr>
            <td colspan="8" class="bordaRight">{{ bibDialogo.sacado + ': ' + dados.parceiro}}</td>
          </tr>    
          <tr>
            <td colspan="8" class="bordaRight bordaTopWhite">{{ bibDialogo.endereco + ': ' + dados.parceiroEndereco}}</td>
          </tr>   
          <tr>
            <td colspan="8" class="bordaRight bordaTopWhite">{{ bibDialogo.praca + ' ' + bibDialogo.pagamento + ': ' + dados.lojaEndereco}}</td>
          </tr>   
          <tr>
            <td colspan="4" class="align-items-center bordaTopWhite">{{ bibDialogo.cnpjCpf + ': ' + dados.parceiroCnpjCpf}}</td>
            <td colspan="4" class="align-items-center bordaRight bordaTopWhite">{{ bibDialogo.inscricaoEstadual  + ': ' + dados.parceiroInscricaoEstadual}}</td>
          </tr>  
          <tr>
            <td colspan="4" class="align-items-center bordaTopWhite"><br></td>
            <td colspan="4" class="align-items-center bordaRight bordaTopWhite"><br></td>
          </tr>                        
          <tr>
            <td colspan="2" class="align-items-center bordaRight centraliza-vertical"><b>{{ bibDialogo.valorPorExtenso }}</b></td>
            <td colspan="6" class="align-items-center bordaRight">{{ this.util.escreverValor(dados.valorTotal) }}</td>
          </tr>   
          <tr>
            <td colspan="9" class="align-items-center bordaRight">{{ mensagemFinal }}<br> <br></td>
          </tr>  
          <tr>
            <td colspan="4" class="align-items-center bordaTopWhite data">  ______/______/______  <br>{{ bibDialogo.dataDoAceite }}</td>
            <td colspan="5" class="align-items-center bordaRight bordaTopWhite data"> ________________________________________________________________  <br>{{ dados.parceiro }}</td>
          </tr>      
        </tbody>
      </table>
</relatorioLayout>          