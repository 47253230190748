import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { Classificacao } from 'src/app/modelo/classificacao';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { NaturezaOperacao } from 'src/app/modelo/naturezaOperacao';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Safra } from 'src/app/modelo/safra';
import { Transporte } from 'src/app/modelo/transporte';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { ParceiroFrmComponent } from 'src/app/pagina/parceiro/frm/parceiroFrm.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';
import { UtilMovimentacaoImpostos } from 'src/app/utilitario/utilMovimentacaoImpostos';

@Component({
  selector: 'movimentacaoCabecalhoFrm',
  templateUrl: './movimentacaoCabecalhoFrm.component.html',
})
export class MovimentacaoCabecalhoFrmComponent extends PaginaComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;
  public componente: any;
  public focoNomeParceiro: number;
  public focoParceiro: number = 0;
  public alteracaoParceiroConsumidorFinal: boolean = false;
  public bibServicoTecnoSpeed: bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public servico: string;

  setData(data: Date): void {
    this.movimentacaoControlador.movimentacao.data = data;
    this.movimentacaoControlador.calcularMediaDiaPrazo();
    if (this.movimentacaoControlador.nota == false && this.movimentacaoControlador.notaTerceiro == false) {
      this.movimentacaoControlador.movimentacao.dataEntradaSaida = data;
    }
  }

  setLoja(loja: Loja): void {
    this.movimentacaoControlador.movimentacao.idLoja = loja.id;
    this.movimentacaoControlador.filtroIdLoja.valor = loja.id;
    this.movimentacaoControlador.movimentacao.abreviacao = loja.abreviacao;
    this.movimentacaoControlador.movimentacao.limiteCustoReposicao = loja.regraVariacaoCusto ? loja.regraVariacaoCusto : 0;
    this.limparNegociacao();
    this.atualizarLojaMovimentacaoQuantidade();
    this.movimentacaoControlador.listarNegociacao();
    this.movimentacaoControlador.atualizarValorMovimentacaoProduto();
    this.movimentacaoControlador.focoParceiro = Math.random();
    this.utilSessao.setIdLojaMovimentacao(loja.id);
    this.movimentacaoControlador.setFlagSat();
  }

  private limparNegociacao(): void {
    this.movimentacaoControlador.movimentacao.idNegociacao = null;
    this.movimentacaoControlador.movimentacao.negociacao = null;
  }

  private atualizarLojaMovimentacaoQuantidade(): void {
    for (const movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
      movimentacaoProduto.expandido = false;
      for (const movimentacaoQuantidade of movimentacaoProduto.movimentacaoQuantidades) {
        if (movimentacaoQuantidade.referencia == 'D') {
          movimentacaoQuantidade.idLoja = this.movimentacaoControlador.movimentacao.idLoja;
        }
      }
    }
  }

  setParceiro(parceiro: Parceiro): void {
    this.movimentacaoControlador.movimentacao.enderecoOutro = null;
    this.movimentacaoControlador.movimentacao.idParceiro = parceiro.id;
    this.movimentacaoControlador.movimentacao.idParceiroDestino = parceiro.idParceiroDestino ? parceiro.idParceiroDestino : null;
    this.movimentacaoControlador.movimentacao.parceiro = parceiro.numeroNomeParceiro;
    this.movimentacaoControlador.movimentacao.nomeParceiro = parceiro.numeroNomeParceiro;
    this.movimentacaoControlador.movimentacao.parceiroLimiteDisponivel = parceiro.limiteDisponivel;
    this.movimentacaoControlador.movimentacao.parceiroDiaFechamento = parceiro.diaFechamento;
    this.movimentacaoControlador.movimentacao.parceiroPrazoFixo = parceiro.prazoFixo;
    this.movimentacaoControlador.movimentacao.parceiroTipoNegociacao = parceiro.tipoNegociacao;
    this.movimentacaoControlador.movimentacao.parceiroTipoPessoa = parceiro.tipoPessoa;
    this.movimentacaoControlador.movimentacao.parceiroContribuinte = parceiro.contribuinte;
    this.movimentacaoControlador.movimentacao.parceiroInscricao = parceiro.inscricaoEstadual;
    this.movimentacaoControlador.movimentacao.parceiroIdClienteEspecial = parceiro.idClienteEspecial;
    this.movimentacaoControlador.movimentacao.parceiroCpfCnpj = parceiro.cnpjCpf;
    if (!this.movimentacaoControlador.movimentacao.idColaborador) {
      if (this.utilSessao.getUsuario().idColaborador && this.utilSessao.getUsuario().colaborador) {
        this.movimentacaoControlador.movimentacao.idColaborador = this.utilSessao.getUsuario().idColaborador;
        this.movimentacaoControlador.movimentacao.colaborador = this.utilSessao.getUsuario().colaborador;
      } else {
        this.movimentacaoControlador.movimentacao.idColaborador = null;
        this.movimentacaoControlador.movimentacao.colaborador = null;
      }
    }
    this.sugerirVendedor(parceiro);
    this.movimentacaoControlador.parceiroIdContaContabil = parceiro.idContaContabil;
    this.movimentacaoControlador.parceiroContaContabil = parceiro.contaContabil;
    this.movimentacaoControlador.alertarDescricao(parceiro.observacao, parceiro.mostrarAlerta, parceiro.numeroNomeParceiro);
    this.movimentacaoControlador.idTipoTituloParceiro = parceiro.idTipoTitulo;
    this.movimentacaoControlador.setNomeTipoTituloParceiro(this.movimentacaoControlador.idTipoTituloParceiro);
    this.movimentacaoControlador.ehConsumidorFinal();
    this.movimentacaoControlador.atualizarTituloParcelamento();
    this.movimentacaoControlador.listarConfiguracaoClienteEspecial(parceiro.idClienteEspecial);
    this.movimentacaoControlador.listarNaturezaOperacao();
    if (!this.alteracaoParceiroConsumidorFinal && parceiro && parceiro.id && this.movimentacaoControlador.movimentacao.importacaoXml == 'N') {
      this.movimentacaoControlador.movimentacao.naturezaOperacao = null;
      this.movimentacaoControlador.movimentacao.idNaturezaOperacao = null;
    }
    this.movimentacaoControlador.usaConsumidorFinal == true ? (this.alteracaoParceiroConsumidorFinal = true) : (this.alteracaoParceiroConsumidorFinal = false);
    if (this.movimentacaoControlador.usaConsumidorFinal) {
      this.movimentacaoControlador.movimentacao.nomeParceiro = null;
    }
    this.ehSetNegociacaoPadrao(parceiro.idNegociacao);
    this.ehSetRelacionamento(parceiro.idRelacionamento);
    this.focoNomeParceiro = this.movimentacaoControlador.usaConsumidorFinal ? Math.random() : null;
    if (this.movimentacaoControlador.movimentacao.importacaoXml == 'S' && this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0) {
      this.vincularProdutoParceiro(parceiro.id, this.movimentacaoControlador.movimentacao.movimentacaoProdutos);
    }
    if (this.movimentacaoControlador.saida) {
      this.movimentacaoControlador.bloquearDiasEntreOuDiaFixo();
    }
    if (parceiro.idParceiroDestino) {
      this.movimentacaoControlador.listarEnderecoParceiro(parceiro.idParceiroDestino, parceiro.enderecoCompleto);
    } else {
      if (this.movimentacaoControlador.usaConsumidorFinal) {
        this.movimentacaoControlador.movimentacao.enderecoOutro = "RETIRADA NA LOJA";
      } else {
        this.movimentacaoControlador.movimentacao.parceiroEndereco = parceiro.enderecoCompleto;
      }
    }
  }

  sugerirVendedor(parceiro: Parceiro): void {
    if (parceiro.idParceiroVendedor && this.movimentacaoControlador.movimentacao.idColaborador == null) {
      this.movimentacaoControlador.movimentacao.idColaborador = parceiro.idParceiroVendedor;
      this.movimentacaoControlador.movimentacao.colaborador = parceiro.nomeParceiroVendedor;
    }
  }

  setNegociacao(negociacao: Negociacao): void {
    this.movimentacaoControlador.setIdNegociacao(negociacao);
  }

  private ehSetNegociacaoPadrao(idNegociacao: number): void {
    if ((this.movimentacaoControlador.saida || this.movimentacaoControlador.devolucao) && this.movimentacaoControlador.movimentacao.idParceiro) {
      this.movimentacaoControlador.listarNegociacao(idNegociacao);
    }
  }

  private ehSetRelacionamento(idRelacionamento: number): void {
    if (idRelacionamento) {
      this.movimentacaoControlador.listarRelacionamento(idRelacionamento);
    } else {
      this.movimentacaoControlador.movimentacao.relacionamento = null;
    }
  }

  vincularProdutoParceiro(idParceiro: number, movimentacaoProdutos: MovimentacaoProduto[]): void {
    if (idParceiro != null) {
      this.movimentacaoControlador.vincularProdutoParceiro(idParceiro, movimentacaoProdutos);
    } else {
      movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.produtoNumero = null;
        movimentacaoProduto.idProduto = null;
        movimentacaoProduto.idUnidadePadrao = null;
        movimentacaoProduto.produto = null;
      });
    }
  }

  setColaborador(parceiro: Parceiro): void {
    this.movimentacaoControlador.movimentacao.idColaborador = parceiro.id;
    this.movimentacaoControlador.movimentacao.colaborador = parceiro.razaoSocial;
    this.movimentacaoControlador.alertarDescricao(parceiro.observacao, parceiro.mostrarAlerta, parceiro.razaoSocial);
  }

  setAcrescimoPercentual(acrescimoPercentual: number = 0): void {
    this.movimentacaoControlador.movimentacao.acrescimoPercentual = acrescimoPercentual ? new Util().arredondar(acrescimoPercentual) : 0;
    this.listarProduto().subscribe((res) => {
      this.atualizarValorUnitario(plainToClass(Produto, res) as any);
    });
  }

  setPercentualDescontoItem(descontoItem: number = 0): void {
    this.movimentacaoControlador.percentualDescontoItem = descontoItem ? new Util().arredondar(descontoItem) : 0;
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (this.movimentacaoControlador.usaDescontoUnitario) {
        movimentacaoProduto.setDescontoPercentualUnitario(descontoItem, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
      } else {
        movimentacaoProduto.setDescontoPercentual(descontoItem, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
      }
      new UtilMovimentacaoImpostos(this.comunicacaoService, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais).atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
      this.movimentacaoControlador.totalizar();
    });
  }

  private listarProduto(): Observable<any> {
    if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0) {
      const idProdutos: number[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
      const criterios: Criterio[] = [new Criterio('IDS', idProdutos.toString()), new Criterio('ID_LOJA', this.movimentacaoControlador.movimentacao.idLoja)];
      return this.movimentacaoControlador.comunicacaoService.listar(new Transporte(criterios), this.movimentacaoControlador.bibServico.produto);
    }
    return of([]);
  }

  private atualizarValorUnitario(produtos: Produto[]): void {
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      const precoVenda = produtos.find((produto) => produto.id == movimentacaoProduto.idProduto).precoVenda;
      movimentacaoProduto.valorUnitarioTabela = this.movimentacaoControlador.util.arredondar(precoVenda + (precoVenda * this.movimentacaoControlador.movimentacao.acrescimoPercentual) / 100, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
      movimentacaoProduto.setValorUnitario(movimentacaoProduto.valorUnitarioTabela, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
    });
    this.movimentacaoControlador.totalizar();
  }

  limparParceiro(): void {
    this.movimentacaoControlador.movimentacao.nomeParceiro = null;
    this.movimentacaoControlador.movimentacao.idParceiro = null;
    this.movimentacaoControlador.movimentacao.parceiro = null;
    this.movimentacaoControlador.movimentacao.consumidorFinal = 'N';
    this.movimentacaoControlador.usaConsumidorFinal == true ? (this.alteracaoParceiroConsumidorFinal = true) : (this.alteracaoParceiroConsumidorFinal = false);
    this.movimentacaoControlador.usaConsumidorFinal = false;
    this.focoParceiro = Math.random();
  }

  setClassificacao(classificacao: Classificacao): void {
    this.movimentacaoControlador.movimentacao.idClassificacao = classificacao.id;
    this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque = classificacao.bloqueadoEstoque;
    this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe = classificacao.bloqueadoEstoqueNfe;
    this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro = classificacao.bloqueadoFinanceiro;
    this.movimentacaoControlador.movimentacao.classificacaoVinculaOutroParceiro = classificacao.vinculoOutroParceiro == 'S';
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      movimentacaoProduto.definirRegraEstoque(this.movimentacaoControlador.estoqueComprado, this.movimentacaoControlador.estoqueFisico, this.movimentacaoControlador.estoqueReservado, this.movimentacaoControlador.estoqueTransferido, this.movimentacaoControlador.estoqueNFe, this.movimentacaoControlador.estoqueCarteira);
      movimentacaoProduto.setBloquearFinanceiroEstoque(this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro, this.movimentacaoControlador.financeiro, this.movimentacaoControlador.movimentacao.idOperacao);
    });
    this.movimentacaoControlador.totalizar();
  }

  setSafra(safra: Safra) {
    this.movimentacaoControlador.movimentacao.idSafra = safra.id;
  }

  irParceiro(idParceiro: number): void {
    const MENU_FORNECEDOR = 54;
    const MENU_CLIENTE = 5;
    const idMenu: number = this.movimentacaoControlador.entrada ? MENU_FORNECEDOR : MENU_CLIENTE;
    const identificacoes: Identificacao[] = [new Identificacao('idParceiro', idParceiro), new Identificacao('pesquisaCliente', false), new Identificacao('parametro', this.movimentacaoControlador.entrada ? 'FORNECEDOR' : 'CLIENTE')];
    this.componente = this.util.abrirModal(ParceiroFrmComponent, identificacoes, idMenu);
  }

  setNaturezaOperacao(naturezaOperacao: NaturezaOperacao): void {
    this.movimentacaoControlador.movimentacao.idNaturezaOperacao = naturezaOperacao.id;
    this.movimentacaoControlador.movimentacao.naturezaOperacao = naturezaOperacao.nome;
    this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao = naturezaOperacao.usaCustoReposicao;
    this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade = naturezaOperacao.finalidade;
    this.movimentacaoControlador.limparParcelas();
    this.movimentacaoControlador.calcularLucro();
  }

  setHoraSaida(hora: string): void {
    this.movimentacaoControlador.movimentacao.horaSaida = hora;
  }

  setParceiroCpfCnpj(valor: string): void {
    this.movimentacaoControlador.movimentacao.parceiroCpfCnpj = valor == '' ? null : valor;
    this.movimentacaoControlador.ehValidoCnpjCpf = this.util.validarCnpjCpf(this.movimentacaoControlador.movimentacao.parceiroCpfCnpj);
  }

  setAgronomo(agronomo: Parceiro): void {
    this.movimentacaoControlador.movimentacao.idAgronomo = agronomo.id;
    this.movimentacaoControlador.movimentacao.agronomo = agronomo.razaoSocial;
  }

  abrirModalCadastroCliente(): void {
    const MENU_CLIENTE = 5;
    const identificacoes: Identificacao[] = [new Identificacao('ehModalCadastroCliente', true), new Identificacao('parceiroCpf', this.movimentacaoControlador.movimentacao.parceiroCpfCnpj), new Identificacao('nomeParceiro', this.movimentacaoControlador.movimentacao.nomeParceiro), new Identificacao('pesquisaCliente', false), new Identificacao('parametro', 'CLIENTE')];
    this.componente = this.util.abrirModal(ParceiroFrmComponent, identificacoes, MENU_CLIENTE);
  }

  downloadPdf(data: any): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  baixarPdf(): void {
    switch (this.movimentacaoControlador.movimentacao.modelo) {
      case '55':
        this.servico = 'nfe';
        break;
      case '65':
        this.servico = 'nfce';
        break;
    }
    this.comunicacaoTecnoSpeedService.consumirPdf(this.bibServicoTecnoSpeed.getUrlBaixaPdfNfe(this.servico, this.movimentacaoControlador.movimentacao.idTecnospeed)).subscribe(
      (res) => {
        this.downloadPdf(res);
      },
      () => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
      }
    );
  }
}
