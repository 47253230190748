import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monetario',
})
export class MonetarioPipe implements PipeTransform {
  transform(value: number, decimalLength: number = 2, chunkDelimiter: string = '.', decimalDelimiter: string = ',', chunkLength: number = 3): string {
    let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    let num = (typeof value == 'undefined' || isNaN(value) || value == null ? 0 : value).toFixed(Math.max(0, ~~decimalLength));
    return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
  }
}
