<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-30 clique" (click)="listar(add('menu'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.menu }}</th>
        <th class="l-10 clique" (click)="listar(add('menuEmpresa'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.alterado }}</th>
        <th class="l-5 clique" (click)="listar(add('ativo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.ativo }}</th>
        <th class="l-30 clique" (click)="listar(add('apelido'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.apelido }}</th>
        <th class="l-10 clique" (click)="listar(add('tag'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.atalho }}</th>
        <th class="l-10 text-right"><botao [compacto]="compacto" [legenda]="bibDialogo.restauraPadrao" (botaoEmt)="restaurarPadrao()"></botao></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let menuEmpresa of menuEmpresas; let i = index">
        <tr>
          <td>{{ menuEmpresa.menu }}</td>
          <td><atencao [atencao]="menuEmpresa.menuEmpresa ? bibDialogo.alterado : ''"></atencao></td>
          <td><interruptor [id]="bibDialogo.ativo + i" [campo]="menuEmpresa.ativo" (alteracao)="alterar(menuEmpresa, 'ativo', $event)"></interruptor></td>
          <td><texto [id]="'apelido' + i" [campo]="menuEmpresa.apelido" [normal]="true" (alteracao)="alterar(menuEmpresa, 'apelido', $event)"></texto></td>
          <td colspan="2"><texto [id]="'atalho' + i" [campo]="menuEmpresa.tag" [normal]="true" (alteracao)="alterar(menuEmpresa, 'tag', $event)"></texto></td>
          <td>
            <btnAdicional [icone]="menuEmpresa.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="menuEmpresa.expandido = !menuEmpresa.expandido"></btnAdicional>
          </td>
        </tr>
        <tr *ngIf="menuEmpresa.expandido">
          <td colspan="7"><descricao [rotulo]="bibDialogo.descricao" [campo]="menuEmpresa.descricao" (alteracao)="alterar(menuEmpresa, 'descricao', $event)"></descricao></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<botao *ngIf="menuEmpresas.length > 0" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirMenuEmpresa()" [desabilitado]="!temPermissaoEdicao"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
