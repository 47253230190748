import { Component } from '@angular/core';
import { Cultura } from 'src/app/agrow/modelo/cultura';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './cultura.component.html',
})
export class CulturaComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public culturas: Cultura[] = [];
  public integracaoEidaf: boolean = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
  public integracaoAgroDefesa: boolean = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO').length > 0 ? true : false;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.cultura).subscribe((res) => {
      this.culturas = this.plainToClass(Cultura, res) as any;
    });
  }

  excluirCultura(id: number, nome: string): void {
    super.excluir<Cultura>(id, this.culturas, nome);
  }
}
