export class Lote {
  public ativo: string = 'S';
  public dataFabricacao: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dataValidade: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public registro: string;
  public idProduto: number;
  public produto: string;
}
