<lista *ngIf="compacto && campo != 'S'" [lista]="lista" [rotulo]="rotulo" [campo]="campo" (alteracao)="alterarLista($event)" [compacto]="true" [desabilitado]="desabilitado" [obrigatorio]="obrigatorio"></lista>
<div *ngIf="!compacto || campo == 'S'" class="section-block text-center text-sm-left d-flex flex-wrap">
  <ng-container *ngFor="let objeto of lista; let i = index">
    <div class="visual-picker visual-picker has-peek">
      <div [id]="id + i" [ngClass]="objeto.restricao == 1 && objeto.permitido == 'N' ? 'transparente' : ''">
        <input [disabled]="desabilitado" type="checkbox" [ngModel]="campo == objeto[nomeCampoRegra]" (ngModelChange)="alterar(objeto)" id="vpr0{{ i }}" name="vprLG" />        
        <label class="visual-picker-figure espacamento" for="vpr0{{ i }}" [ngClass]="{ selecionado: campo == objeto[nomeCampoRegra] }">
          <span class="visual-picker-content">
            <span [ngClass]="'tile tile-lg bg-' + objeto.corTipo">
              <i [ngClass]="objeto.imagemUrl"></i>
            </span>
          </span>
        </label>
        <span class="visual-picker-peek">
          {{ objeto.getNome() }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
