import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import filtros from 'src/app/relatorio/r2011/filtro';
import { RelatorioComponent } from '../relatorio.component';

@Component({
  templateUrl: './r2011.component.html',
  styleUrls: ['./r2011.component.css'],
})
export class R2011Component extends RelatorioComponent {
  public apresentaConferencias: any[] = [];
  public conferenciaDuplicadas: any[] = [];
  public conferenciaFinanceiros: any[] = [];
  public conferenciaTitulos: any[] = [];
  public conferencias: any[] = [];
  public filtros = filtros;
  public rotuloTipoDeTitulo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30).apelido;
  public usaDuplicarRecibo: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.conferencias = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Conferencia').lista;
      this.conferenciaFinanceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ConferenciaFinanceiro').lista;
      this.conferenciaTitulos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ConferenciaTitulo').lista;
      this.vincular();
      this.atualizarRelatorioAdicionais();
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.conferencias.forEach((conferencia) => {
      if (this.conferenciaFinanceiros.length > 0) {
        conferencia.conferenciaFinanceiros = this.conferenciaFinanceiros.filter((conferenciaFinanceiro) => conferenciaFinanceiro.idConferencia == conferencia.id);
      }
      if (this.conferenciaTitulos.length > 0) {
        conferencia.conferenciaTitulos = this.conferenciaTitulos.filter((conferenciaTitulo) => conferenciaTitulo.idConferencia == conferencia.id);
      }
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.atualizarRelatorioAdicionais();
  }

  atualizarRelatorioAdicionais(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      if (relatorioAdicional.id == 152) {
        this.usaDuplicarRecibo = this.definirRegra(relatorioAdicional, 152, this.usaDuplicarRecibo);
        relatorioAdicional.marcado = this.usaDuplicarRecibo ? 'S' : 'N';
        if (relatorioAdicional.marcado == 'S') {
          this.clonar();
          this.apresentaConferencias = this.conferenciaDuplicadas;
        } else {
          this.mostrarReciboSemDuplicar();
        }
      } else {
        this.mostrarReciboSemDuplicar();
      }
    });
  }

  mostrarReciboSemDuplicar(): void {
    this.apresentaConferencias = this.conferencias;
    this.conferenciaDuplicadas = [];
  }

  clonar(): void {
    this.conferencias.forEach((conciliacao) => {
      this.conferenciaDuplicadas.push(conciliacao);
      this.conferenciaDuplicadas.push(conciliacao);
    });
  }
}
