<ng-container *ngIf="tipo == 'menuPersonalizado'">
  <div class="row">
    <div class="col-3 middle-align text-center margin-auto">
      <div class="col-12">
        <nav aria-label="breadcrumb" class="naoImprimir">
          <ol class="breadcrumb">
            <li *ngFor="let menu of listaMenuNomes; let i = index">{{ menu }}<i class="breadcrumb-icon fa fa-angle-right mr-2 ml-2"></i></li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-menu middle-align text-center margin-auto">
      <div *ngFor="let menuPersonalizado of listarMenuPersonalizado(); let i = index" class="margin-bottom">
        <button class="col-12 btn position-relativa" (click)="entrarMenu(menuPersonalizado)" (mouseover)="mudarEstilo(i)" (mouseout)="mudarEstilo(i)" [focus]="i == posicao" [ngClass]="{ 'btn-outline-primary': i != posicao, 'btn-primary': i == posicao }">{{ menuPersonalizado.menu }} <i *ngIf="(menuPersonalizado.link == 'N' || menuPersonalizado.idModuloRelatorio != null) && menuPersonalizado.idRelatorio == null" class="fa fa-angle-right icone-personalizado"></i></button>
      </div>
    </div>
  </div>
  <div class="row col-3 middle-align justify-content-between margin-auto">
    <div>
      <atencao *ngIf="listaMenuNomes.length > 0" class="clique" [atencao]="bibDialogo.esc + ' ' + bibDialogo.voltar" (click)="voltar()"></atencao>
    </div>
    <div>
      <atencao [atencao]="bibDialogo.enter + ' ' + bibDialogo.abrir"></atencao>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="tipo == 'menuPrincipal'">
  <ul class="menu tamanho-ul">
    <ng-container *ngFor="let menuPersonalizado of listarMenuPrincipal()">
      <li *ngIf="menuPersonalizado.titulo == 'S'" class="menu-header">{{ menuPersonalizado.nome }}</li>
      <li *ngIf="menuPersonalizado.titulo == 'N'" class="menu-item menu-item-01" (click)="selecionaMenuPersonalizado(menuPersonalizado)">
        <span *ngIf="menuPersonalizado.nivel == 0" class="oi icone-oi" [ngClass]="verIcone(menuPersonalizado)"></span>
        <span *ngIf="menuPersonalizado && menuPersonalizado.link == 'N'" [ngStyle]="{ 'margin-left': indentar(menuPersonalizado) }" class="menu-link">{{ menuPersonalizado.nome }}</span>
        <a *ngIf="(menuPersonalizado.link == 'S' && menuPersonalizado.idRelatorio == null) || menuPersonalizado.idRelatorio" [href]="'#/' + irDestinoMenuRelatorio(menuPersonalizado)" tabindex="-1" (click)="irParaMenuRelatorio(menuPersonalizado)" data-toggle="aside" class="menu-link">
          <span [ngStyle]="{ 'margin-left': indentar(menuPersonalizado) }" class="menu-text">{{ menuPersonalizado.nome }}</span>
          <span *ngIf="menuPersonalizado.novo == 'S'" class="badge badge-warning">Novo</span>
        </a>
        <i *ngIf="menuPersonalizado.possuiFilhos == true" [ngClass]="{ 'fa-angle-down': menuPersonalizado.aberto == true, 'fa-angle-right': menuPersonalizado.aberto == false }" class="fa possui-filhos"></i>
      </li>
    </ng-container>
  </ul>
</ng-container>
