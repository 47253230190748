import { ConfiguracaoFinanceiroTotalContabil } from './configuracaoFinanceiroTotalContabil';

export class ConfiguracaoFinanceiroContabil {
  public configuracaoFinanceiroTotalContabeis: ConfiguracaoFinanceiroTotalContabil[] = [];
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public tipo: string;

  setObjetoTotais(lista: any[]): void {
    this.configuracaoFinanceiroTotalContabeis = lista;
  }

  getObjetoTotais(): any[] {
    return this.configuracaoFinanceiroTotalContabeis;
  }
}
