import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Fabricante } from 'src/app/modelo/fabricante';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './fabricante.component.html',
})
export class FabricanteComponent extends PaginaComponent {
  public fabricantes: Fabricante[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.fabricante).subscribe((res) => {
      this.fabricantes = this.plainToClass(Fabricante, res) as any;
    });
  }

  excluirFabricante(id: number, nome: string): void {
    super.excluir<Fabricante>(id, this.fabricantes, nome);
  }
}
