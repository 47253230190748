<div [ngClass]="compacto ? 'mt-4' : ''" class="row">
  <lista class="col-sm-6" [id]="'lojaPagamento'" [rotulo]="bibDialogo.lojaPagamento" [campo]="lojaPagamento?.id" (alteracao)="lojaPagamento = $event" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [foco]="true"></lista>
  <data class="col-sm-4" [id]="'dataBaixa'" [rotulo]="baixa.bibDialogo.dataBaixa" [campo]="baixa.dataBaixa" (alteracao)="baixa.dataBaixa = $event" [obrigatorio]="true"></data>
</div>
<div *ngIf="baixa.baixaRapida == false" class="row">
  <decimal class="col-sm-6" [id]="'desconto'" [rotulo]="bibDialogo.desconto" [campo]="baixa.valorDesconto" (alteracao)="setValorDesconto($event)"></decimal>
  <decimal class="col-sm-4" [id]="'acrescimo'" [rotulo]="bibDialogo.juro" [campo]="baixa.valorJuro" (alteracao)="setValorAcrescimo($event)"></decimal>
</div>
<div *ngIf="baixa.movimentacaoComCartaoCredito" class="row">
  <texto class="col-sm-6" [id]="'parcelamento'" [rotulo]="bibDialogo.parcelas" [campo]="baixa.parcelamento" (alteracao)="baixa.parcelamento = $event" [tipo]="bibPropriedade.texto.inteiro" [ajuda]="bibDialogo.parcelasAjuda" [ajudaDireita]="true"></texto>
</div>
<table class="table table-responsive-sm mb-0">
  <thead>
    <tr>
      <th>{{ menuTipoDeTitulo.apelido }}</th>
      <th [ngClass]="parametro != 'CAIXA' && parametro != 'RAPIDA' ? 'l-20' : 'l-30'">{{ menuConta.apelido }}</th>
      <th class="l-10 text-right">{{ baixa.bibDialogo.valor }}</th>
      <th *ngIf="parametro != 'CONFERENCIA' && parametro != 'CAIXA' && parametro != 'RAPIDA'" class="l-10 text-right">{{ baixa.bibDialogo.troco }}</th>
      <th [ngClass]="parametro != 'CONFERENCIA' && parametro != 'CAIXA' && parametro != 'RAPIDA' ? 'l-10' : 'l-20'" class="l-10 text-right">{{ tipoTituloCheque && tipoTituloCartao ? baixa.bibDialogo.numeroDocumento : tipoTituloCheque ? bibDialogo.numeroChequeAbreviacao : tipoTituloCartao ? bibDialogo.numeroAutorizacao : baixa.bibDialogo.numeroDocumento }}</th>
      <th class="l-5 text-center">{{ tipoTituloCheque ? baixa.bibDialogo.simboloMais : baixa.bibDialogo.obs }}</th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let financeiroBaixa of baixa.financeiroBaixas; let i = index">
      <tr>
        <td>
          <lista [id]="'tipoTitulo'" [campo]="financeiroBaixa.idTipoTitulo" (alteracao)="setTipoTitulo(financeiroBaixa, $event)" [lista]="financeiroBaixa.idCheque ? tipoTituloCheques : tipoTituloPermitidos" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="definirRegrasFinanceiroBaixa()"></lista>
        </td>
        <td>
          <lista [id]="'conta'" [campo]="financeiroBaixa.idConta" (alteracao)="setFinanceiroBaixaConta(financeiroBaixa, $event)" [lista]="contasSelecionadas" [desabilitado]="financeiroBaixa.bloqueiaConta || financeiroBaixa.idCheque" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="definirRegrasFinanceiroBaixa()"></lista>
        </td>
        <td>
          <decimal [id]="'valor'" [campo]="financeiroBaixa.valorCheio" (alteracao)="setValorCheio(financeiroBaixa, $event)" [desabilitado]="financeiroBaixa.idCheque"></decimal>
        </td>
        <td *ngIf="parametro != 'CONFERENCIA' && parametro != 'CAIXA' && parametro != 'RAPIDA'">
          <decimal [campo]="financeiroBaixa.valorTroco" (alteracao)="setValorTroco(financeiroBaixa, $event)" [valorMaximo]="util.arredondar(financeiroBaixa.valorCheio - financeiroBaixa.descontoValor)"></decimal>
        </td>
        <td>
          <texto [id]="'numeroDocumento'" [campo]="financeiroBaixa.numeroDocumento" (alteracao)="financeiroBaixa.numeroDocumento = $event" [obrigatorio]="financeiroBaixa.obrigaNumeroDocumento" [ajuda]="financeiroBaixa.tipoTituloIdEspecie == 2 || financeiroBaixa.tipoTituloIdEspecie == 10 ? bibDialogo.ajudaAutorizacaoCartao : financeiroBaixa.tipoTituloIdEspecie == 4 ? bibDialogo.preenchaNumeroCheque : ''" [desabilitado]="financeiroBaixa.idCheque" [tipo]="bibPropriedade.texto.numero"></texto>
        </td>
        <td><btnAdicional [icone]="financeiroBaixa.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="financeiroBaixa.expandido = !financeiroBaixa.expandido" [id]="'Expandido'" [mensagem]="financeiroBaixa.expandido ? bibDialogo.ocultar : bibDialogo.expandir" ></btnAdicional></td>
        <td *ngIf="parametro != 'RAPIDA'">
          <btnAdicional [icone]="baixa.bibIcone.lixo" (btnAdicional)="baixa.removerFinanceiroBaixa(financeiroBaixa, i)" [id]="'Excluir'"></btnAdicional>
        </td>
        <td *ngIf="parametro == 'RAPIDA'">
        <btnAdicional [compacto]="compacto" (btnAdicional)="replicarTitulosEContaParaTodosVazios(financeiroBaixa)" [icone]="bibIcone.clone" [ajuda]="bibDialogo.replicarParaTodosVazios" [desabilitado]="financeiroBaixa.idTipoTitulo == null || financeiroBaixa.idConta == null"></btnAdicional>
        </td>
        <td *ngIf="parametro == 'RAPIDA'">
          <ajuda [ajuda]="this.baixa.mostrarFinanceiroSelecionados()[i].parceiro"></ajuda>
        </td>
      </tr>
      <ng-container *ngIf="financeiroBaixa.expandido">
        <tr>
          <td colspan="6">
            <div class="row">
              <pesquisaTextoBusca class="col-sm" [id]="'historico'" [rotulo]="bibDialogo.historico" [campo]="financeiroBaixa.observacao" (alteracao)="setObservacao(financeiroBaixa, $event)" [icone]="bibIcone.lupa" [servico]="bibServico.historico" [minimoCaracterBusca]="2"></pesquisaTextoBusca>
              <data *ngIf="tipoTituloCheque" class="col-sm-4" [id]="'dataPrevista'" [rotulo]="baixa.bibDialogo.dataPrevista" [campo]="financeiroBaixa.dataPrevista" (alteracao)="financeiroBaixa.dataPrevista = $event"></data>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
  <tfoot>
    <tr>
      <td [colSpan]="2">
        <div class="row" *ngIf="baixa.parceiroCredito > 0">
          <atencao class="texto-normal" [atencao]="util.substituir(bibDialogo.parceiroComCreditoDisponivel, [baixa.parceiroCredito | monetario])"></atencao>
          <btnAdicional class="ml-1" [id]="'buscaHistorico'" [icone]="bibIcone.olho" [ajuda]="bibDialogo.vizualizarHistoricoCredito" (btnAdicional)="abrirModalHistoricoParceiro()"></btnAdicional>
        </div>
      </td>
      <td class="text-right negrito">
        <p class="mr-2">{{ baixa.valorTotalBaixaCheio | monetario }}</p>
      </td>
      <td *ngIf="parametro != 'CONFERENCIA' && parametro != 'CAIXA' && parametro != 'RAPIDA'" class="text-right negrito">
        <p class="mr-2">{{ baixa.valorTotalTroco | monetario }}</p>
      </td>
    </tr>
    <tr *ngIf="baixa.parceiroCreditoEmpresa > 0">
      <td [colSpan]="2">
        <div class="row">
          <atencao class="texto-normal" [atencao]="util.substituir(bibDialogo.empresaComCreditoDisponivel, [baixa.parceiroCreditoEmpresa | monetario])"></atencao>
          <btnAdicional class="ml-1" [id]="'buscaHistorico'" [icone]="bibIcone.olho" [ajuda]="bibDialogo.vizualizarHistoricoCredito" (btnAdicional)="abrirModalHistoricoEmpresa()"></btnAdicional>
        </div>
      </td>
    </tr>
  </tfoot>
</table>
<div class="row">
  <p *ngIf="baixa.getValorFaltante() < 0" class="col-sm-12 pl-3">{{ bibDialogo.valorInformadoSuperiorEm + ': ' }} <atencao [atencao]="baixa.getValorFaltante() * -1 | monetario" [tipo]="bibPropriedade.atencao.cuidado"></atencao></p>
  <p *ngIf="baixa.getValorFaltante() > 0 || baixa.financeiroBaixas.length == 0" class="col-sm-12 pl-3">
    {{ bibDialogo.estaFaltandoValorDe + ':' }} <atencao [atencao]="baixa.getValorFaltante() | monetario" [tipo]="bibPropriedade.atencao.alerta"></atencao><ng-container *ngIf="parametro != 'RAPIDA'"><strong class="clique" (click)="adicionar()">{{ ' ' + bibDialogo.cliqueAqui.toLowerCase() + ' '}}</strong>{{ bibDialogo.paraAdicionar.toLowerCase() }}</ng-container>
  </p>
</div>
<titulo [titulo]="this.baixa.parametro == 'DESPESA' ? bibDialogo.informativoPagamentos : bibDialogo.informativoRecebimentos" [imagem]="bibImagem.financeiroSelecionados"></titulo>
<baixaLista [baixa]="baixa" [financeiros]="baixa.mostrarFinanceiroSelecionados()"></baixaLista>
<div class="mt-2 form-inline naoImprimir mb-5">
  <botao [compacto]="compacto" [legenda]="baixa.bibDialogo.anterior" (botaoEmt)="ehSetEtapa()" [desabilitado]="temPermissaoInserir ? false : true"></botao>
  <botao *ngIf="parametro != 'CONFERENCIA' && parametro != 'DUPLICATA'" [id]="'finalizar'" [compacto]="compacto" [legenda]="parametro == 'DESPESA' || parametro == 'RAPIDA' ? baixa.bibDialogo.finalizar : bibDialogo.finalizarLiberarEntrega" [tipo]="baixa.bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ir()" [desabilitado]="temPermissaoInserir ? false : true"></botao>
  <botao *ngIf="parametro == 'CONFERENCIA' || parametro == 'DUPLICATA'" [id]="'finalizar'" [compacto]="compacto" [legenda]="baixa.bibDialogo.finalizar" [tipo]="baixa.bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ehPersistirConferencia()" [desabilitado]="temPermissaoInserir ? false : true"></botao>
</div>
<bloqueado [bloqueado]="temPermissaoInserir ? false : true"></bloqueado>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
