<ng-container *ngIf="_movimentacaoControlador">
  <botao [compacto]="_movimentacaoControlador.compacto" [legenda]="_movimentacaoControlador.bibDialogo.adicionar" (botaoEmt)="_movimentacaoControlador.adicionarMovimentacaoQuantidade(_movimentacaoProduto)" [desabilitado]="_movimentacaoControlador.bloqueado || _movimentacaoControlador.transferenciaEntrada"></botao>
  <table class="table table-responsive-sm bg-cinza-claro" [ngClass]="ehModoClaro ? '' : 'modo-escuro'">
    <thead>
      <tr>
        <th class="l-10">{{ _movimentacaoControlador.bibDialogo.loja }}</th>
        <th class="l-20">{{ _movimentacaoControlador.bibDialogo.local }}</th>
        <th class="l">{{ _movimentacaoControlador.bibDialogo.lote }}</th>
        <th class="l-10 text-right">{{ _movimentacaoControlador.bibDialogo.quantidade }}</th>
        <th *ngIf="_movimentacaoControlador.movimentacao.status != 'S' && _movimentacaoControlador.bloqueado == false" class="l-10 text-right">{{ _movimentacaoControlador.nota ? _movimentacaoControlador.bibDialogo.nfe : _movimentacaoControlador.bibDialogo.disponivel }}</th>
        <th class="l-20" *ngIf="_movimentacaoControlador.saida">{{ _movimentacaoControlador.bibDialogo.formaEntrega }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let movimentacaoQuantidade of _movimentacaoProduto.movimentacaoQuantidades; let i = index">
        <td><lista [campo]="movimentacaoQuantidade.idLoja" [lista]="lojaLimitacoes" (alteracao)="setLoja(movimentacaoQuantidade, $event, i)" [obrigatorio]="true" [desabilitado]="_movimentacaoControlador.bloqueado || (_movimentacaoControlador.saida && _movimentacaoControlador.devolucao) || _movimentacaoControlador.transferenciaEntrada"></lista></td>
        <td><lista [campo]="movimentacaoQuantidade.idLocal" [lista]="_locais" (alteracao)="setLocal(movimentacaoQuantidade, $event.id)" [desabilitado]="_movimentacaoControlador.bloqueado" [icone]="_movimentacaoControlador.bibIcone.atualizar" (btnAdicional)="_movimentacaoControlador.ehListarLocal(_indice, _movimentacaoProduto)"></lista></td>
        <td *ngIf="_movimentacaoControlador.movimentacao.status != 'S' && _movimentacaoControlador.bloqueado == false"><lista [campo]="movimentacaoQuantidade.idLote" [lista]="_lotes" [tipo]="bibPropriedade.lista.lote" (alteracao)="setLote(movimentacaoQuantidade, $event.id)" [desabilitado]="_movimentacaoControlador.bloqueado" [icone]="_movimentacaoControlador.bibIcone.atualizar" (btnAdicional)="_movimentacaoControlador.ehListarLote(_indice, _movimentacaoProduto, _movimentacaoProduto.idProduto)"></lista></td>
        <td *ngIf="_movimentacaoControlador.movimentacao.status == 'S' || _movimentacaoControlador.bloqueado">{{movimentacaoQuantidade.lote}}</td>
        <td><decimal [campo]="movimentacaoQuantidade.quantidade" (alteracao)="setQuantidade(movimentacaoQuantidade, $event)" [obrigatorio]="true" [desabilitado]="_movimentacaoControlador.bloqueado || _movimentacaoControlador.transferenciaEntrada" [numeroCasasDecimais]="_movimentacaoControlador.entrada ? _movimentacaoControlador.empresaRegraCasasDecimaisEntrada : _movimentacaoControlador.empresaRegraCasasDecimais"></decimal></td>
        <td *ngIf="_movimentacaoControlador.movimentacao.status != 'S' && _movimentacaoControlador.bloqueado == false" class="text-right">{{ movimentacaoQuantidade.quantidadeEstoqueDisponivel | monetario }}</td>
        <td *ngIf="_movimentacaoControlador.saida && this.utilSessao.getEmpresa().separarEntregarOpcional != 'S'">
          <lista [campo]="movimentacaoQuantidade.referencia" [lista]="formaEntregas[i] ? formaEntregas[i].opcaoEntregas : []" (alteracao)="movimentacaoQuantidade.referencia = $event.id" [desabilitado]="movimentacaoQuantidade.idLoja == _movimentacaoControlador.movimentacao.idLoja || _movimentacaoControlador.bloqueado" [obrigatorio]="true"></lista>
        </td> 
        <td *ngIf="_movimentacaoControlador.saida && this.utilSessao.getEmpresa().separarEntregarOpcional == 'S'">
          <lista [campo]="_movimentacaoProduto.ignoraSepararEntregar" [lista]="simNao" (alteracao)="_movimentacaoProduto.ignoraSepararEntregar = $event.id" [desabilitado]="_movimentacaoControlador.bloqueado" [obrigatorio]="true"></lista>
        </td>
        <td>
          <btnAdicional [icone]="_movimentacaoControlador.bibIcone.lixo" [desabilitado]="_movimentacaoControlador.bloqueado" (btnAdicional)="excluirQuantidade(i, movimentacaoQuantidade.id)" [desabilitado]="_movimentacaoControlador.bloqueado || _movimentacaoControlador.transferenciaEntrada"></btnAdicional>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
