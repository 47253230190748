import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { NaturezaOperacao } from 'src/app/modelo/naturezaOperacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './naturezaOperacaoFrm.component.html',
})
export class NaturezaOperacaoFrmComponent extends PaginaComponent {
  public naturezaOperacao: NaturezaOperacao = new NaturezaOperacao();
  public naturezasOperacoes: NaturezaOperacao[] = [];
  public usaMobilidade: boolean = this.utilSessao.getEmpresa().mobilidade == 'S' ? true : false;

  public listaCusto: any[] = [
    { id: '1', nome: this.bibDialogo.precoVenda.toUpperCase() },
    { id: '2', nome: this.bibDialogo.custoReposicao.toUpperCase() },
    { id: '3', nome: this.bibDialogo.custoMedioNfe.toUpperCase() },
  ];

  public finalidades: any[] = [
    { id: 1, nome: this.bibDialogo.normal.toUpperCase() },
    { id: 2, nome: this.bibDialogo.transferencia.toUpperCase() },
    { id: 3, nome: this.bibDialogo.devolucao.toUpperCase() },
    { id: 4, nome: this.bibDialogo.complementar.toUpperCase() },
    { id: 5, nome: this.bibDialogo.ajuste.toUpperCase() },
  ];

  public listaUsadoComo: any[] = [
    { id: '-1', nome: this.bibDialogo.saida.toUpperCase() },
    { id: '1', nome: this.bibDialogo.entrada.toUpperCase() },
  ];

  public simNao: any[] = [
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
  ];


  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.naturezaOperacao = this.plainToClass(NaturezaOperacao, this.ehAtualizacao(this.naturezaOperacao));
    if (this.naturezaOperacao.id) {
      this.listarNaturezaOperacao(this.naturezaOperacao.id);
    }
  }

  listarNaturezaOperacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.naturezaOperacao).subscribe((res) => {
      this.naturezaOperacao = this.plainToClass(NaturezaOperacao, res[0]) as any;
    });
    this.comunicacaoService.listar(new Transporte(), this.bibServico.naturezaOperacao).subscribe((res) => {
      this.naturezasOperacoes = this.plainToClass(NaturezaOperacao, res) as any;
    });
  }

  persistirNatureaOperacao(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.naturezaOperacao), this.bibServico.naturezaOperacao, novo ? new NaturezaOperacao() : null);
    }
  }

  ehValido(): boolean {
    let ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    let ehValidoUtilizarPrecoVenda: boolean = this.ehValidoUtilizarPrecoVenda();
    let ehValidoUtilizarCustoReposicaoOuMedio: boolean = this.ehValidoUtilizarCustoReposicaoOuMedio();
    let ehValidoMobilidadePadrao: boolean = this.ehValidoMobilidadePadrao();
    let ehValidoMobilidadePadraoDuplicado: boolean = this.ehValidoMobilidadePadraoDuplicado();
    return ehValidoObrigatorio && ehValidoUtilizarPrecoVenda && ehValidoUtilizarCustoReposicaoOuMedio && ehValidoMobilidadePadrao && ehValidoMobilidadePadraoDuplicado;
  }

  ehValidoObrigatorio(): boolean {
    if (this.naturezaOperacao.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoUtilizarPrecoVenda(): boolean {
    if (this.naturezaOperacao.entradaSaida == -1 && (this.naturezaOperacao.usaCustoReposicao == '2' || this.naturezaOperacao.usaCustoReposicao == '3')) {
      if (this.naturezaOperacao.entradaSaida == -1 && this.naturezaOperacao.usaCustoReposicao == '2') {
        if (!confirm(this.util.substituir(this.bibDialogo.naturezaOperacaoBuscandoValoresParaOperacoes, [this.menuNaturezaOperacao.apelido, this.naturezaOperacao.nome, this.bibDialogo.venda.toUpperCase(), this.bibDialogo.custoReposicao.toUpperCase()]))) {
          return false;
        }
      } else {
        if (!confirm(this.util.substituir(this.bibDialogo.naturezaOperacaoBuscandoValoresParaOperacoes, [this.menuNaturezaOperacao.apelido, this.naturezaOperacao.nome, this.bibDialogo.venda.toUpperCase(), this.bibDialogo.custoMedioNfe.toUpperCase()]))) {
          return false;
        }
      }
    }
    return true;
  }

  ehValidoUtilizarCustoReposicaoOuMedio(): boolean {
    if (this.naturezaOperacao.entradaSaida == 1 && this.naturezaOperacao.usaCustoReposicao == '1') {
      if (!confirm(this.util.substituir(this.bibDialogo.naturezaOperacaoBuscandoValoresParaOperacoes, [this.menuNaturezaOperacao.apelido, this.naturezaOperacao.nome, this.bibDialogo.compra.toUpperCase(), this.bibDialogo.precoVenda.toUpperCase()]))) {
        return false;
      }
    }
    return true;
  }

  ehValidoMobilidadePadraoDuplicado(): boolean {
    if (this.naturezaOperacao.mobilidadePadrao == 'S') {
      if (this.naturezasOperacoes.filter((naturezaOperacao) => naturezaOperacao.mobilidadePadrao == 'S' && naturezaOperacao.id != this.naturezaOperacao.id).length > 0) {
        if (!confirm(this.bibDialogo.naturezaOperacaoPadraoMobilidadePergunta)) {
          return false;
        }
      }
    }
    return true;
  }


  ehValidoMobilidadePadrao(): boolean{
    if (this.naturezaOperacao.entradaSaida != -1 && this.naturezaOperacao.mobilidadePadrao == 'S') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naturezaOperacaoPadraoMobilidade));
      return false;
    }
    return true;
  }
}
