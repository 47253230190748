import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { ProdutoValorHistorico } from 'src/app/modelo/produtoValorHistorico';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ProdutoFrmComponent } from '../produto/frm/produtoFrm.component';
@Component({
  templateUrl: './produtoValorHistorico.component.html',
})
export class ProdutoValorHistoricoComponent extends PaginaComponent {
  public filtros: Filtro[];
  public produtoValorHistoricos: ProdutoValorHistorico[] = [];
  public rotuloProduto: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 7).apelido;
  public servico: string;
  public verificaParametro: boolean = true;
  public atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['os ' + this.menuProduto.apelido.toLowerCase() + 's']);
  public componente: any;

  ngOnInit(): void {
    this.verificaParametro = this.util.coalesce(this.utilSessao.getIdentificacao('verificaParametro')?.conteudo, true);
    if (this.verificaParametro) {
      this.activatedRoute.params.subscribe((params) => {
        this.filtros = filtros.filtros;
        this.produtoValorHistoricos = [];
        switch (params.parametro) {
          case 'PRECOVENDA':
            this.servico = this.bibServico.produtoPrecoVenda;
            break;
          case 'CUSTOREPOSICAO':
            this.servico = this.bibServico.produtoCustoReposicao;
            break;
          case 'PRECOGERENCIAL':
            this.servico = this.bibServico.produtoCustoGerencial;
            break;
          case 'CUSTONFE':
            this.servico = this.bibServico.produtoCustoNfe;
            break;
        }
      });
    } else {
      this.visualizarHistorico();
    }
  }

  visualizarHistorico(): void {
    let idProduto: number = this.utilSessao.getIdentificacao('idProduto')?.conteudo;
    let idLoja: number = this.utilSessao.getIdentificacao('idLoja')?.conteudo;
    let servico: string = this.utilSessao.getIdentificacao('servico')?.conteudo;
    if (idProduto != null && idLoja != null && servico != null) {
      this.filtros = filtros.filtros;
      this.produtoValorHistoricos = [];
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('ID_PRODUTO', idProduto));
      criterios.push(new Criterio('ID_LOJA', idLoja));
      criterios.push(new Criterio('ATIVO', 'S'));
      if (servico == 'CUSTOREPOSICAO') {
        this.servico = this.bibServico.produtoCustoReposicao;
      } else if (servico == 'CUSTONFE') {
        this.servico = this.bibServico.produtoCustoNfe;
      } else {
        this.servico = this.bibServico.produtoCustoGerencial;
      }
      this.listar(criterios);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.servico).subscribe((res) => {
      this.produtoValorHistoricos = this.plainToClass(ProdutoValorHistorico, res) as any;
    });
  }

  visualizarProduto(idProduto: number): void {
    if (this.permissaoProduto) {
      event.preventDefault();
      this.utilSessao.setIdentificacao(new Identificacao('idProduto', idProduto));
      this.componente = ProdutoFrmComponent;
    }
  }
}
