export class Estoque {
  public abreviacao: string;
  public alterado: boolean = false;
  public arquivoFoto: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dataValidade: Date;
  public expandido: boolean = false;
  public id: number;
  public idEmpresa: number;
  public idLocal: number;
  public idLoja: number;
  public idLote: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public local: string;
  public loja: string;
  public lote: string;
  public numero: number;
  public periodo: string = '';
  public produto: string;
  public quantidadeComprada: number = 0;
  public quantidadeDiferencaDisponivel: number = 0;
  public quantidadeDiferencaNfe: number = 0;
  public quantidadeDisponivel: number = 0;
  public quantidadeFisica: number = 0;
  public quantidadeNfe: number = 0;
  public quantidadeReservada: number = 0;
  public quantidadeTransferida: number = 0;
  public quantidadeCarteira: number = 0;
  public unidade: string;
  public estoqueVendas: any[];
  public ativoLote: string;
}
