import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numeroNcm', rotulo: bibDialogo.numeroNcm, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'numeroCest', rotulo: bibDialogo.numeroCest, nome: 'NUMERO_CEST', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'descricaoNcm', rotulo: bibDialogo.descricaoNcm, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
]);
export default filtros;
