import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r2000Entregas]</tr>',
  templateUrl: './r2000Entregas.component.html',
})
export class R2000Entregas {
  @Input() entregaProdutos: any[];
  public bibDialogo = bibDialogo;
}
