import { Permissao } from './permissao';

export class MenuPrincipal {
  public aberto: boolean = false;
  public feito: boolean = false;
  public icone: string;
  public id: number;
  public idMenu: number;
  public idPai: number;
  public idRelatorio: number;
  public link: string;
  public nivel: number;
  public nome: string;
  public novo: string;
  public ordem: string;
  public permissao: Permissao;
  public possuiFilhos: boolean = false;
  public selecionado: boolean = false;
  public titulo: string;
  public visualiza: boolean = false;
  public parametro: string;
}
