import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Component, Input } from '@angular/core';
import { Formulario } from '../formulario';

@Component({
  selector: 'interruptor',
  templateUrl: './interruptor.component.html',
  styleUrls: ['./interruptor.component.css'],
})
export class InterruptorComponent extends Formulario {
  public bibDialogo = bibDialogo;
  @Input() tabindex: number = 0;

  setCampoAtual(campoAtual: boolean): void {
    if (!this.desabilitado) {
      event.preventDefault();
      this.campoAtual = campoAtual ? 'S' : 'N';
      super.alterar();
    }
  }

  alterarRotulo(rotulo: string): string {
    if (rotulo == this.bibDialogo.ativo) {
      this.campoAtual == 'S' ? (rotulo = bibDialogo.ativo) : (rotulo = bibDialogo.inativo);
    }
    return rotulo;
  }
}
