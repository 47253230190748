import { Imposto } from "../nfe/imposto";
import { NsSatImposto } from "./nsSatImposto";

export class NsSatProduto {
    public separador: string;
    public registro: string;
    public nItem: string;
    public infAdProd: string;
    public cProd: string;
    public cEAN: string;
    public xProd: string;
    public NCM: string;
    public reservado1: string;
    public CFOP: string;
    public uCom: string;
    public qCom: string;
    public vUnCom: string;
    public reservado2: string;
    public reservado3: string;
    public reservado4: string;
    public reservado5: string;
    public reservado6: string;
    public reservado7: string;
    public vDesc: string;
    public vOutro: string;
    public CEST: string;
    public reservado8: string;
    public indRegra: string;
    public xCampoDet: string;
    public xTextoDet: string;
    public vItem12741: string;
    public separador1: string;
    public imposto: NsSatImposto;
    // public nsSatIcmsNormal: NsSatIcmsNormal;
    // public nsSatIcmsIsentaNaoTributadaSuspensao: NsSatIcmsIsentaNaoTributadaSuspensao;
    // public nsSatIcmsSimplesNacional: NsSatIcmsSimplesNacional;
    // public nsSatIcmsSimplesNacional900: NsSatIcmsSimplesNacional900;
    // public nsSatPisAliquota: NsSatPisAliquota;
    // public nsSatPisQtde: NsSatPisQtde;
    // public nsSatPisNaoTributado: NsSatPisNaoTributado;
    // public nsSatPisOutro: NsSatPisOutro;
    // public nsSatPisSubstituicaoTributaria: NsSatPisSubstituicaoTributaria;
    // public nsSatCofinsAliquota: NsSatCofinsAliquota;
    // public nsSatCofinsQtde: NsSatCofinsQtde;
    // public nsSatCofinsNaoTributado: NsSatCofinsNaoTributado;
    // public nsSatCofinsOutro: NsSatCofinsOutro;
    // public nsSatCofinsSubstituicaoTributaria: NsSatCofinsSubstituicaoTributaria;
}