export class ContaContabil {
  public ativo: string = 'S';
  public contaContabilPai: string;
  public correntista: number = 1;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public debitoCredito: number = 2;
  public empresaAbreviacao: string;
  public id: number;
  public idEmpresa: number;
  public idPlanoContaPai: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nivel: number;
  public nivelSped: number;
  public nome: string;
  public numeracao: string;
  public numeracaoPai: string;
  public pai: string = 'N';
  public tipoDeLancamento: number = 1;
  public idPaiCorrentista: number;
  public idContaContabil: number;
  public origemContaContabil: number;
  public saldoInicial: number = 0;
  public idContaReferencial: number;
  public contaReferencial: string;
  public tipoDaConta: string;
  public natureza: string;
}
