export class Icms30 {
    public orig: string;
    public CST: string;
    public modBCST: string;
    public pMVAST: string;
    public pRedBCST: string;
    public vBCST: string;
    public pICMSST: string;
    public vICMSST: string;
    public vICMSDeson: string;
    public motDesICMS: string;
    public vBCFCPST: string;
    public pFCPST: string;
    public vFCPST: string;
}