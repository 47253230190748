import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Formulario } from '../formulario';

@Component({
  selector: 'descricao',
  templateUrl: './descricao.component.html',
  styleUrls: ['./descricao.component.css'],
})
export class DescricaoComponent extends Formulario {
  @Input() normal: boolean = false;
  public usaModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';

  alterarTexto(valor: string): void {
    this.campoAtual = valor;
    let campoAtualMaisuculo: string = plainToClass(String, this.campoAtual) as string;
    if (campoAtualMaisuculo) {
      campoAtualMaisuculo = this.normal ? campoAtualMaisuculo : campoAtualMaisuculo.toUpperCase();
    }
    this.alterar(campoAtualMaisuculo);
  }
}
