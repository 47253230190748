<layout [id]="transferencia.id" (voltarAvancarEmt)="ehAlteracao()">
  <bloqueado [bloqueado]="transferencia.id || bloqueado"></bloqueado>
  <div class="row">
    <data class="col-sm-6" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="transferencia.data" (alteracao)="transferencia.data = $event" [desabilitado]="transferencia.id || bloqueado"></data>
    <lista class="col-sm-6" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [tipo]="bibPropriedade.lista.lojaUsuario" [campo]="transferencia.idLoja" [obrigatorioLoja]="true" (alteracao)="setIdLoja(transferencia, $event.id)" [foco]="true" [desabilitado]="bloqueado || transferencia.conciliacoes.length > 0 || (transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length > 0" [desabilitado]="transferencia.id"></lista>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'contaOrigem'" [idMenu]="permissaoConta && permissaoConta.inserir == 'S' ? menuConta.idMenu : null" [rotulo]="bibDialogo.origem" [lista]="origemContas" [campo]="transferencia.idContaOrigem" (alteracao)="setIdContaOrigem(transferencia, $event)" (navegacao)="$event == true ? ehLoja() : ''" [obrigatorio]="true" [desabilitado]="transferencia.id || bloqueado || transferencia.conciliacoes.length > 0 || (transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length > 0"></lista>
    <lista class="col-sm-6" [id]="'contaDestino'" [idMenu]="permissaoConta && permissaoConta.inserir == 'S' ? menuConta.idMenu : null" [rotulo]="bibDialogo.destino" [lista]="contas" [campo]="transferencia.idContaDestino" (alteracao)="setContaDestino(transferencia, $event)" [obrigatorio]="true" [desabilitado]="transferencia.id || bloqueado || transferencia.conciliacoes.length > 0 || (transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length > 0"></lista>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'idEspecie'" [rotulo]="bibDialogo.especie" [campo]="transferencia.especie" [lista]="especies" (alteracao)="setIdEspecie(transferencia, $event.id)" [obrigatorio]="true" [desabilitado]="transferencia.id || bloqueado || transferencia.conciliacoes.length > 0 || (transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length > 0"></lista>
    <decimal class="col-sm-3" [id]="'valor'" [rotulo]="bibDialogo.valor" [campo]="transferencia.valor" (alteracao)="transferencia.valor = $event" [obrigatorio]="true" [desabilitado]="transferencia.id || bloqueado || transferencia.tipoTituloTipo == 4 || transferencia.tipoTituloTipo == 2"></decimal>
  </div>
  <descricao [id]="'observacao'" [rotulo]="bibDialogo.observacao" [campo]="transferencia.observacao" (alteracao)="transferencia.observacao = $event" [maximoCaracteres]="250" [normal]="true" [desabilitado]="transferencia.id || bloqueado"></descricao>

  <div *ngIf="transferencia.id == null && transferencia.tipoTituloTipo == 4 && transferencia.idContaOrigem && transferencia.idContaDestino && (transferencia.valor == 0 || (transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length > 0)">
    <titulo [titulo]="bibDialogo.cheques" [imagem]="bibImagem.dinheiro" [icone]="bibIcone.mais" (iconeEmt)="abrirModalCheque()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.cheque.toLowerCase()" [desabilitado]="bloqueado || temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
    <div class="row" *ngIf="(transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length == 0">
      <p class="col-sm-12 pl-3">
        {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.cheque.toLowerCase() + ', ' }} <strong class="clique" [id]="'adicionarEndereco'" (click)="abrirModalCheque()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
      </p>
    </div>
  </div>

  <div *ngIf="transferencia.id == null && transferencia.tipoTituloTipo == 2 && transferencia.idContaOrigem && transferencia.idContaDestino && (transferencia.valor == 0 || transferencia.conciliacoes.length > 0)">
    <titulo [titulo]="bibDialogo.cartao" [imagem]="bibImagem.dinheiro" [icone]="bibIcone.mais" (iconeEmt)="buscarConciliacaoCartao()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.cartao.toLowerCase()" [desabilitado]="bloqueado || temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
    <div class="row" *ngIf="(transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length == 0">
      <p class="col-sm-12 pl-3">
        {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.cartao.toLowerCase() + ', ' }} <strong class="clique" [id]="'adicionarEndereco'" (click)="buscarConciliacaoCartao()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
      </p>
    </div>
  </div>

  <table class="table table-responsive-sm" *ngIf="(transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido).length > 0 && transferencia.tipoTituloTipo == 4">
    <thead>
      <th class="text-right l-15">{{ bibDialogo.numeroDocumento }}</th>
      <th class="text-right l-15">{{ bibDialogo.data }}</th>
      <th class="text-right l-15">{{ bibDialogo.dataPreDatada }}</th>
      <th class="l-30">{{ bibDialogo.parceiro }}</th>
      <th class="text-right l-15">{{ bibDialogo.valor }}</th>
      <th class="l-10"></th>
    </thead>
    <tbody>
      <tr *ngFor="let transferenciaCheque of transferencia.transferenciaCheques | filtrarTransferenciaChequeNaoExcluido">
        <td class="text-right">{{ transferenciaCheque.chequeNumero }}</td>
        <td class="text-right">{{ transferenciaCheque.chequeData | data }}</td>
        <td class="text-right">{{ transferenciaCheque.chequeDataPreDatada | data }}</td>
        <td class="limitar">{{ transferenciaCheque.chequeParceiro }}</td>
        <td class="text-right">{{ transferenciaCheque.chequeValor | monetario }}</td>
        <td class="row justify-content-end">
          <btnAdicional *ngIf="transferencia.id == null" [icone]="bibIcone.lixo" (btnAdicional)="excluirTransferenciaCheque(transferenciaCheque)" [desabilitado]="bloqueado"></btnAdicional>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="conciliacoes.length > 0 && transferencia.especie == 2 && bloqueado == false" style="display: inline-flex; align-items: center;">
    <label class="mt-1">{{ bibDialogo.selecionarTodos }}</label>
    <check [campo]="marcado ? 'S': 'N'" (alteracao)="selecionarTodos($event)"></check>
  </div>
  <table class="table table-responsive-sm" *ngIf="conciliacoes.length > 0 && transferencia.especie == 2">
    <thead>
      <th *ngIf="bloqueado == false" class="l-5"></th>
      <th class="l-15">{{ bibDialogo.numeroDocumento }}</th>
      <th class="l-15">{{ bibDialogo.dataConciliacao }}</th>
      <th class="l-30">{{ bibDialogo.conta }}</th>
      <th class="l-30">{{ bibDialogo.parceiro }}</th>
      <th class="text-right l-10">{{ bibDialogo.valor }}</th>
    </thead>
    <tbody>
      <tr *ngFor="let conciliacao of conciliacoes">
        <td *ngIf="bloqueado == false">
          <check [campo]="conciliacao.selecionada" (alteracao)="setConciliacaoSelecionada(conciliacao, $event)"></check>
        </td>
        <td>{{ conciliacao.numeroDocumento }}</td>
        <td>{{ conciliacao.dataConciliacao | data }}</td>
        <td class="limitar">{{ conciliacao.conta }}</td>
        <td class="limitar">{{ conciliacao.nomeParceiro }}</td>
        <td class="text-right">{{ conciliacao.valor | monetario }}</td>
      </tr>
    </tbody>
    <tfoot>
      <td [colSpan]="bloqueado ? 4 : 5"></td>
      <td class="text-right font-weight-bold borda-cima">{{ this.transferencia.valor | monetario }}</td>
    </tfoot>
  </table>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirTransferencia()" [desabilitado]="transferencia.id || bloqueado"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirTransferencia(true)" [desabilitado]="transferencia.id || bloqueado"></botao>
<auditoria *ngIf="transferencia.id" [idUsuarioInclusao]="transferencia.idUsuarioInclusao" [dataHoraInclusao]="transferencia.dataHoraInclusao" [idUsuarioAlteracao]="transferencia.idUsuarioAlteracao" [dataHoraAlteracao]="transferencia.dataHoraAlteracao"></auditoria>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
