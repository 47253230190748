import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { R1128Component } from '../r1128/r1128.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2020.component.html',
  styleUrls: ['./r2020.component.css'],
})
export class R2020Component extends R1128Component {
  public filtros = filtros;
  public dataAtual: Date = new Date();
  public bibDialogo = bibDialogo;

  irNFE(idMovimentacao: number): void {
    if (this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 126)) {
      this.utilSessao.setIdentificacao(new Identificacao('idMovimentacao', idMovimentacao));
      const url: string = '#/documentoEletronico/nf-e';
      window.open(url, '_blank');
    } else {
      this.utilSessao.setResultado(new Resultado(false, bibDialogo.semPermissaoAcesso));
    }
  }
}
