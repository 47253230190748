<layout [titulo]="vinculo.nome" [id]="vinculo.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="vinculo.nome" (alteracao)="vinculo.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="vinculo.ativo" (alteracao)="vinculo.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <lista [id]="'tipo'" [rotulo]="bibDialogo.tipo" [campo]="vinculo.tipo" [lista]="opcoes" [obrigatorio]="true" (alteracao)="vinculo.tipo = $event.id"></lista>
  <titulo [obrigatorio]="true" [preenchido]="vinculo.endereco != 'N' || vinculo.telefone != 'N' || vinculo.contato != 'N'" [titulo]="bibDialogo.usadoComo" [imagem]="bibImagem.checkbox"></titulo>
  <div class="row">
    <interruptor class="col-sm-4" [id]="'endereco'" [rotulo]="bibDialogo.endereco" [campo]="vinculo.endereco" (alteracao)="vinculo.endereco = $event"> </interruptor>
    <interruptor class="col-sm-4" [id]="'telefone'" [rotulo]="bibDialogo.telefone" [campo]="vinculo.telefone" (alteracao)="vinculo.telefone = $event"> </interruptor>
    <interruptor class="col-sm-4" [id]="'contato'" [rotulo]="bibDialogo.contato" [campo]="vinculo.contato" (alteracao)="vinculo.contato = $event"> </interruptor>
  </div>
  <titulo *ngIf="vinculo.contato == 'S'" [titulo]="bibDialogo.sugestaoAutomatica" [imagem]="bibImagem.checkbox"></titulo>
  <div class="row">
    <interruptor *ngIf="vinculo.contato == 'S'" [id]="'sugestaoAutomatica'" class="col-sm-4" [rotulo]="bibDialogo.sugestaoAutomatica" [campo]="vinculo.sugestaoAutomatica" (alteracao)="vinculo.sugestaoAutomatica = $event"> </interruptor>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirVinculo()" [desabilitado]="bloquearPermissao(vinculo.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirVinculo(true)" [desabilitado]="bloquearPermissao(vinculo.id)"></botao>
<auditoria *ngIf="vinculo.id" [idUsuarioInclusao]="vinculo.idUsuarioInclusao" [dataHoraInclusao]="vinculo.dataHoraInclusao" [idUsuarioAlteracao]="vinculo.idUsuarioAlteracao" [dataHoraAlteracao]="vinculo.dataHoraAlteracao"></auditoria>
