import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { Filtros2016 } from 'src/app/relatorio/r2016/acessorio/filtro';
import { RelatorioComponent } from '../relatorio.component';
import { Filtros } from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  selector: 'r1002',
  templateUrl: './r1002.component.html',
  styleUrls: ['./r1002.component.css'],
})
export class R1002Component extends RelatorioComponent {
  public estoques: any[] = [];
  public filtros: Filtro[] = new Filtros().filtros;
  public filtros2016: Filtro[] = new Filtros2016().filtros;
  public grupos = grupos;
  public ordens = ordens;
  public parametro: number;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public usaCst: boolean = false;
  public usaCustoGerencial: boolean = false;
  public usaCustoNfe: boolean = false;
  public usaCustoReposicao: boolean = true;
  public usaDiferencaPendente: boolean = false;
  public usaLote: boolean = false;
  public usaLocal: boolean = false;
  public usaFabricante: boolean = false;
  public usaGrupo: boolean = false;
  public usaMargemReal: boolean = true;
  public usaPrecoVenda: boolean = true;
  public usaQuantidadeComprada: boolean = false;
  public usaQuantidadeAComprar: boolean = false;
  public usaQuantidadeDisponivel: boolean = true;
  public usaQuantidadeDisponivelxNfe: boolean = false;
  public usaQuantidadeEmBranco: boolean = false;
  public usaQuantidadeMinima: boolean = false;
  public usaQuantidadeNfe: boolean = true;
  public usaQuantidadePendente: boolean = false;
  public usaQuantidadeTotal: boolean = true;
  public usaQuantidadeFisica: boolean = false;
  public usaVendaUltimosMeses: boolean = false;
  public usaSugestaoCompra: boolean = false;
  public usaAgrupaProduto: boolean = false;
  public inventario: boolean = false;
  public usaEntrega: boolean = false;
  public totalCustoGerencial: number;
  public totalCustoMedio: number;
  public totalCustoReposicao: number;
  public estoquesAgrupadoProduto: any[] = [];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.parametro = params.idRelatorio;
      if (this.parametro == 2016) {
        this.usaCst = true;
        this.usaQuantidadeNfe = false;
        this.usaQuantidadePendente = true;
        this.usaQuantidadeComprada = true;
      } else {
        this.usaEntrega = this.utilSessao.getEmpresa().separarEntregar == 'S';
      }
    });
  }

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.estoques = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Estoque').lista;
      this.estoques.forEach((estoque) => estoque.estoquePendencias = [])
      this.ordenarAgrupar(this.estoques);
      this.calcularMargemReal();
      this.calcularQuantidadeComprar();
      this.totalizarEstoques();
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaLote = this.definirRegra(relatorioAdicional, 228, this.usaLote);
    this.usaLocal = this.definirRegra(relatorioAdicional, 229, this.usaLocal);
    this.usaGrupo = this.definirRegra(relatorioAdicional, 178, this.usaGrupo);
    this.usaFabricante = this.definirRegra(relatorioAdicional, 179, this.usaFabricante);
    this.usaQuantidadeDisponivel = this.definirRegra(relatorioAdicional, 184, this.usaQuantidadeDisponivel);
    this.usaQuantidadeNfe = this.definirRegra(relatorioAdicional, 186, this.usaQuantidadeNfe);
    this.usaQuantidadePendente = this.definirRegra(relatorioAdicional, 187, this.usaQuantidadePendente);
    this.usaQuantidadeEmBranco = this.definirRegra(relatorioAdicional, 192, this.usaQuantidadeEmBranco);
    this.usaQuantidadeComprada = this.definirRegra(relatorioAdicional, 193, this.usaQuantidadeComprada);
    this.usaQuantidadeDisponivelxNfe = this.definirRegra(relatorioAdicional, 194, this.usaQuantidadeDisponivelxNfe);
    this.usaQuantidadeTotal = this.definirRegra(relatorioAdicional, 214, this.usaQuantidadeTotal);
    this.inventario = this.definirRegra(relatorioAdicional, 241, this.inventario);

    this.usaCst = this.definirRegra(relatorioAdicional, 197, this.usaCst);
    this.usaQuantidadeNfe = this.definirRegra(relatorioAdicional, 198, this.usaQuantidadeNfe);
    this.usaQuantidadePendente = this.definirRegra(relatorioAdicional, 199, this.usaQuantidadePendente);
    this.usaDiferencaPendente = this.definirRegra(relatorioAdicional, 200, this.usaDiferencaPendente);
    this.usaQuantidadeComprada = this.definirRegra(relatorioAdicional, 201, this.usaQuantidadeComprada);
    this.usaQuantidadeDisponivelxNfe = this.definirRegra(relatorioAdicional, 202, this.usaQuantidadeDisponivelxNfe);
    this.usaQuantidadeDisponivel = this.definirRegra(relatorioAdicional, 203, this.usaQuantidadeDisponivel);
    this.usaCustoReposicao = this.definirRegra(relatorioAdicional, 205, this.usaCustoReposicao);
    this.usaCustoGerencial = this.definirRegra(relatorioAdicional, 206, this.usaCustoGerencial);
    this.usaCustoNfe = this.definirRegra(relatorioAdicional, 207, this.usaCustoNfe);
    this.usaMargemReal = this.definirRegra(relatorioAdicional, 208, this.usaMargemReal);
    this.usaPrecoVenda = this.definirRegra(relatorioAdicional, 209, this.usaPrecoVenda);
    this.usaVendaUltimosMeses = this.definirRegra(relatorioAdicional, 210, this.usaVendaUltimosMeses);
    this.usaQuantidadeTotal = this.definirRegra(relatorioAdicional, 212, this.usaQuantidadeTotal);
    this.usaQuantidadeAComprar = this.definirRegra(relatorioAdicional, 215, this.usaQuantidadeAComprar);
    this.usaQuantidadeMinima = this.definirRegra(relatorioAdicional, 216, this.usaQuantidadeMinima);
    this.usaSugestaoCompra = this.definirRegra(relatorioAdicional, 268, this.usaSugestaoCompra);
    this.usaAgrupaProduto = this.definirRegra(relatorioAdicional, 271, this.usaAgrupaProduto);
    this.usaQuantidadeFisica = this.definirRegra(relatorioAdicional, 272, this.usaQuantidadeFisica);
    this.agruparPorProduto();
  }

  calcularMargemReal(): void {
    this.estoques.forEach((estoque) => {
      let margemReal = this.util.arredondar(this.util.arredondar(estoque.precoVenda / (estoque.custoGerencial != 0 ? estoque.custoGerencial : 1) - 1) * 100);
      estoque.margemReal = margemReal == -100 ? 0 : margemReal;
    });
  }

  calcularQuantidadeComprar(): void {
    this.estoques.forEach((estoque) => {
      estoque.quantidadeAComprar = this.util.arredondar(this.util.arredondar(estoque.quantidadeMaxima < estoque.quantidadeDisponivel ? 0 : estoque.quantidadeMaxima - estoque.quantidadeDisponivel));
    });
  }

  expandir(estoque: any) {
    estoque.expandido = !estoque.expandido;
    if (estoque.expandido && (estoque.estoquePendencias == null || estoque.estoquePendencias.length == 0)) {
      this.listarEspecifico(new Criterio('ID_REFERENCIA', estoque.idProduto), 196).subscribe((res) => {
        estoque.estoquePendencias = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

  totalizarEstoques(): void {
    this.totalCustoReposicao = 0;
    this.totalCustoGerencial = 0;
    this.totalCustoMedio = 0;
    this.estoques.forEach((estoque) => {
      this.totalCustoReposicao += estoque.quantidadeDisponivel * estoque.custoReposicao;
      this.totalCustoGerencial += estoque.quantidadeDisponivel * estoque.custoGerencial;
      this.totalCustoMedio += estoque.quantidadeNfe * estoque.custoNfe;
      estoque.sugestaoCompra = estoque.quantidadeMinimaTotal - estoque.quantidadeTotal;
    });
  }

  agruparPorProduto(): void {
    this.estoquesAgrupadoProduto = [];
    if (this.usaAgrupaProduto) {
      let idProduto: number = 0;
      for (const estoque of this.estoques) {
        if (estoque.idProduto != idProduto) {
          idProduto = this.agruparAdicionar(estoque);
        } else {
          this.agruparAcumular(estoque);
        }
      }
    }
  }

  agruparAdicionar(estoque: any): number {
    const estoqueClone: any = this.plainToClass(Object, estoque);
    this.estoquesAgrupadoProduto.push(estoqueClone);
    return estoqueClone.idProduto;
  }

  agruparAcumular(estoque: any): void {
    this.estoquesAgrupadoProduto[this.estoquesAgrupadoProduto.length - 1].quantidadeDisponivel += estoque.quantidadeDisponivel;
    this.estoquesAgrupadoProduto[this.estoquesAgrupadoProduto.length - 1].quantidadeNfe += estoque.quantidadeNfe;
    this.estoquesAgrupadoProduto[this.estoquesAgrupadoProduto.length - 1].quantidadePendente += estoque.quantidadePendente;
    this.estoquesAgrupadoProduto[this.estoquesAgrupadoProduto.length - 1].quantidadeComprada += estoque.quantidadeComprada;
    this.estoquesAgrupadoProduto[this.estoquesAgrupadoProduto.length - 1].quantidadeAComprar += estoque.quantidadeAComprar;
  }


}
