import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import { Filtro } from 'src/app/modelo/filtro';
import { Criterio } from 'src/app/modelo/criterio';

const filtroComboMes: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - JANEIRO', id: '01' },
  { nome: '02 - FEVEREIRO', id: '02' },
  { nome: '03 - MARÇO', id: '03' },
  { nome: '04 - ABRIL', id: '04' },
  { nome: '05 - MAIO', id: '05' },
  { nome: '06 - JUNHO', id: '06' },
  { nome: '07 - JULHO', id: '07' },
  { nome: '08 - AGOSTO', id: '08' },
  { nome: '09 - SETEMBRO', id: '09' },
  { nome: '10 - OUTUBRO', id: '10' },
  { nome: '11 - NOVEMBRO', id: '11' },
  { nome: '12 - DEZEMBRO', id: '12' },
]);

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

const filtros2005: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.mes, nome: 'MES', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboMes },
  { rotulo: bibDialogo.ano, nome: 'ANO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.numero, valor: new Date().getFullYear() },
  { rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
  { rotulo: bibDialogo.nivel, nome: 'NIVEL', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.numero, valor: '4' },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, obrigatorio: 'S', servicoWeb: bibServico.loja, todos: 'S', tipo: bibPropriedade.filtro.checklista },
]);

export default filtros2005;
