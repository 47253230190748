import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
]);
export default filtros;
