<layout [id]="encerramentoContabil.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <data class="col-sm" [id]="'dataAte'" [rotulo]="bibDialogo.dataAte" [campo]="encerramentoContabil.dataAte" (alteracao)="encerramentoContabil.dataAte = $event" [obrigatorio]="true" [foco]="true"></data>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="encerramentoContabil.ativo" (alteracao)="encerramentoContabil.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="encerramentoContabil.idLoja" (alteracao)="encerramentoContabil.idLoja = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [icone]="bibIcone.atualizar"></lista>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'observacao'" [rotulo]="bibDialogo.observacao" [campo]="encerramentoContabil.observacao" (alteracao)="encerramentoContabil.observacao = $event"></texto>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirEncerramentoContabil()" [desabilitado]="bloquearPermissao(encerramentoContabil.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirEncerramentoContabil(true)" [desabilitado]="bloquearPermissao(encerramentoContabil.id)"></botao>
<auditoria *ngIf="encerramentoContabil.id" [idUsuarioInclusao]="encerramentoContabil.idUsuarioInclusao" [dataHoraInclusao]="encerramentoContabil.dataHoraInclusao" [idUsuarioAlteracao]="encerramentoContabil.idUsuarioAlteracao" [dataHoraAlteracao]="encerramentoContabil.dataHoraAlteracao"></auditoria>
