import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { GrupoIcmsSt } from 'src/app/modelo/grupoIcmsSt';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ProdutoIcms } from 'src/app/modelo/produtoIcms';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './icmsStFrm.component.html',
})
export class IcmsStFrmComponent extends PaginaComponent {
  private classe: string;
  public estados: Estado;
  public icmsSt: any;

  ngOnInit(): void {
    this.listarEstado();
    this.classe = this.utilSessao.getIdentificacao('classe')?.conteudo;
    this.getIcmsSt();
  }

  private listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  private getIcmsSt(): void {
    switch (this.classe) {
      case this.bibClasse.produtoIcmsSt:
        this.icmsSt = this.plainToClass(ProdutoIcms, this.utilSessao.getIdentificacao('icmsSt')?.conteudo);
        if (this.icmsSt == null) {
          this.icmsSt = new ProdutoIcms();
        }
        break;
      case this.bibClasse.grupoIcmsSt:
        this.icmsSt = this.plainToClass(GrupoIcmsSt, this.utilSessao.getIdentificacao('icmsSt')?.conteudo);
        if (this.icmsSt == null) {
          this.icmsSt = new GrupoIcmsSt();
        }
        break;
    }
  }

  setEstado(icms: any, estado: Estado): void {
    icms.idEstado = estado.id;
    icms.estado = estado.abreviacao;
  }

  adicionarIcmsSt(): void {
    if (this.ehValidoObrigatorio()) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.classe, this.icmsSt));
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.icmsSt.idEstado && (this.icmsSt.mva || this.icmsSt.mva == 0) && (this.icmsSt.aliquota || this.icmsSt.aliquota == 0)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
