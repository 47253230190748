<ng-container class="border-bottom border-dark">
  <td class="p-0" colspan="7">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-0-5">{{ bibDialogo.loja }}</th>
          <th class="text-right r-1">{{ bibDialogo.estMin }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.estoqueMinimo"></ajuda></th>
          <th class="text-right r-1">{{ bibDialogo.estNfe }}</th>
          <th class="text-right r-1">{{ bibDialogo.custoMedio }}</th>
          <th class="text-right r-1">{{ bibDialogo.mvAno }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.mediaMensalVendasAnoAnterior"></ajuda></th>
          <th class="text-right r-1">{{ bibDialogo.mvTrmA }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.mediaMensalVendasTrimestreAnterior"></ajuda></th>
          <th class="text-right r-1">{{ bibDialogo.mvTrmP }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.mediaMensalVendasTrimestrePosterior"></ajuda></th>
          <th class="text-right r-1">{{ bibDialogo.vmAnt }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.vendaMesAnoPassado"></ajuda></th>
          <th class="text-right r-1">{{ bibDialogo.vmAtual }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.vendasMesAtual"></ajuda></th>
          <th class="text-right r-1">{{ bibDialogo.estFisico }}</th>
          <th class="text-right r-1">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
          <th class="text-right r-1">{{ bibDialogo.estoqueComprado }}</th>
          <th class="text-right r-1">{{ bibDialogo.sugestao }}<ajuda class="naoImprimir" [ajuda]="tipoSugestao == 'H' ? this.definirRegraDia() : tipoSugestao == 'M' ? bibDialogo.calculoEstoqueMaximo : ''"></ajuda></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let loja of produto.lojas">
          <tr>
            <td>{{ loja.abreviacao }}</td>
            <td class="text-right">{{ loja.estoqueMinimo | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ loja.estoqueNfe | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ loja.custoMedio | monetario }}</td>
            <td class="text-right">{{ loja.mediaMensalVendaAnoAnterior | monetario }}</td>
            <td class="text-right">{{ loja.mediaMensalVendaTrimestreAnterior | monetario }}</td>
            <td class="text-right">{{ loja.mediaMensalVendaTrimestrePosterior | monetario }}</td>
            <td class="text-right">{{ loja.vendaMesAnterior | monetario }}</td>
            <td class="text-right">{{ loja.vendaMesAtual | monetario }}</td>
            <td class="text-right">{{ loja.quantidadeFisica | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ loja.custoGerencial | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
            <td class="text-right">{{ loja.quantidadeComprada | monetario }}</td>
            <td class="text-right">{{ (loja.sugestao > 0 ? loja.sugestao : 0) | monetario: this.empresaRegraCasasDecimais }}<ajuda *ngIf="loja.sugestao > 0 && tipoSugestao == 'H'" class="naoImprimir" [ajuda]="this.definirRegraDiaMaiorMedia(loja)"></ajuda><ajuda *ngIf="loja.sugestao < 0" [ajuda]="bibDialogo.excedente + ': ' + (loja.sugestao * -1 | monetario)" [icone]="bibIcone.mais"></ajuda></td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="text-right font-weight-bold">
          <td></td> 
          <td>{{ produto.estoqueMinimo | monetario: this.empresaRegraCasasDecimais }}</td>
          <td>{{ produto.estoqueNfe | monetario: this.empresaRegraCasasDecimais }}</td>      
          <td></td> 
          <td>{{ produto.mediaMensalVendaAnoAnterior | monetario }}</td>
          <td>{{ produto.mediaMensalVendaTrimestreAnterior | monetario }}</td>
          <td>{{ produto.mediaMensalVendaTrimestrePosterior | monetario }}</td>
          <td>{{ produto.vendaMesAnterior | monetario }}</td>
          <td>{{ produto.vendaMesAtual | monetario }}</td>
          <td>{{ produto.quantidadeFisica | monetario: this.empresaRegraCasasDecimais }}</td>   
          <td></td> 
          <td>{{ produto.quantidadeComprada | monetario }}</td>
          <td>{{ (produto.sugestao > 0 ? produto.sugestao : 0)  | monetario: this.empresaRegraCasasDecimais }}</td>
        </tr>
      </tfoot>      
    </table>
  </td>
</ng-container>
