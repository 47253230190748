import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.somenteReceitaMaiorDespesa, id: 'VALOR_RESTANTE' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtroComboDevolucao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.desconsiderarDevolucaoDeCompra, id: '12' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, obrigatorio: 'S' },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.relacionamento, nome: 'ID_RELACIONAMENTO', coluna: 6, servicoWeb: bibServico.relacionamento, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.dataCobrancaDe, nome: 'COBRANCA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataCobrancaAte, nome: 'COBRANCA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataNegociacaoDe, nome: 'NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataNegociacaoAte, nome: 'NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.situacao, nome: 'RECEITA_MAIOR_DESPESA', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: 'VALOR_RESTANTE', lista: filtroComboSituacao, obrigatorio: 'S' },
  { rotulo: bibDialogo.devolucoes, nome: 'DESCONSIDERAR_DEVOLUCAO', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboDevolucao, obrigatorio: 'S' },
  { id: 'natureza', rotulo: bibDialogo.natureza, nome: 'IDS_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.checklista },
  { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'IDS_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.contaPrevista, nome: 'IDS_CONTA_PREVISTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
  { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
]);
export default filtros;
