import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroContato } from 'src/app/modelo/parceiroContato';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Vinculo } from 'src/app/modelo/vinculo';

@Component({
  templateUrl: './parceiroContatoFrm.component.html',
})
export class ParceiroContatoFrmComponent extends PaginaComponent {
  public buscaCep: boolean = false;
  public compacto: boolean = this.utilSessao.getEmpresa().compacto == 'S';
  public componente: any;
  public estados: Estado[];
  public parceiroContato: ParceiroContato = new ParceiroContato();
  public tipoEnderecos: any[] = [];
  public tipoVinculo: string;
  public vinculos: any[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarVinculo();
  }

  ehAlteracao(): void {
    this.parceiroContato = this.plainToClass(ParceiroContato, this.ehAtualizacao(this.parceiroContato));
    if (this.parceiroContato.id && !this.parceiroContato.alterado) {
      this.listarParceiroContato();
    }
  }

  listarParceiroContato(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.parceiroContato.id)), this.bibServico.parceiroContato).subscribe((res) => {
      this.parceiroContato = this.plainToClass(ParceiroContato, res[0]) as any;
    });
  }

  listarVinculo(): void {
    let criterios: Criterio[] = [];
    this.setCriterios(criterios);
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.vinculo).subscribe((res) => {
      this.vinculos = this.plainToClass(Vinculo, res) as any;
    });
  }

  setCriterios(criterios: Criterio[]): void {
    this.tipoVinculo = this.utilSessao.getIdentificacao('tipoVinculo')?.conteudo;
    if (this.tipoVinculo) {
      criterios.push(new Criterio('TIPO', this.tipoVinculo));
    }
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('CONTATO', 'S'));
  }

  setVinculo(idVinculo: number): void {
    this.parceiroContato.idVinculo = idVinculo;
    this.vinculos.forEach((vinculo) => {
      if (vinculo.id == idVinculo) {
        this.parceiroContato.vinculo = vinculo.nome;
      }
    });
  }

  persistirParceiroContato(): void {
    if (this.ehValidoObrigatorio()) {
      this.parceiroContato.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.parceiroContato, this.parceiroContato));
    }
  }

  setTelefone(numero: string): void {
    this.parceiroContato.telefone = numero;
  }

  ehValidoObrigatorio(): boolean {
    if (this.parceiroContato.nome && this.parceiroContato.idVinculo) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
