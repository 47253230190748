<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-15 clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-15 clique" (click)="listar(add('dataPreDatada'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataPreDatada }}</th>
        <th class="l-15 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parceiro }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
        <th class="l-15 clique" (click)="listar(add('contaAtual'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.contaAtual }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let cheque of cheques; let i = index">
        <tr>
          <td><i *ngIf="cheque.finalizado == 'S'" [ngClass]="bibIcone.cadeadoFechado"></i></td>
          <td>{{ cheque.abreviacao }}</td>
          <td class="limitar">{{ cheque.numero }}</td>
          <td>{{ cheque.data | data }}</td>
          <td>{{ cheque.dataPreDatada | data }}</td>
          <td class="limitar">{{ cheque.parceiro }}</td>
          <td class="text-right">{{ cheque.valor | monetario }}</td>
          <td class="limitar">{{ cheque.contaAtual }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(cheque.id, i, cheques)"></btnAdicional>
          </td>
          <td>
            <btnAdicional *ngIf="cheque.idConciliacao == null" [icone]="bibIcone.lixo" (btnAdicional)="excluirCheque(cheque.id, cheque.numero)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
