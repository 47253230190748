import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboStatus: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.aberta, id: 'N' },
  { nome: '02 - ' + bibDialogo.cancelada, id: 'C' },
  { nome: '03 - ' + bibDialogo.concluida, id: 'S' },
  { nome: '04 - ' + bibDialogo.todos, id: '-' },
]);

const filtroSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.aberta, id: 1 },
  { nome: '02 - ' + bibDialogo.aguardandoFinanceiro, id: 2 },
  { nome: '03 - ' + bibDialogo.aguardandoEntrega, id: 3 },
  { nome: '04 - ' + bibDialogo.entregaParcial, id: 4 },
  { nome: '05 - ' + bibDialogo.concluida, id: 5 },
  { nome: '06 - ' + bibDialogo.cancelada, id: 6 },
  { nome: '07 - ' + bibDialogo.todos, id: '-' },
]);

const filtroSituacaoPedidoCarteira: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.todos, id: '-' },
  { nome: '02 - ' + bibDialogo.pendente, id: 'P' },
  { nome: '03 - ' + bibDialogo.concluido, id: 'C' },
]);

const filtros = {
  compraOperacaoPedido: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'parceiro', rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.fornecedor, tipo: bibPropriedade.filtro.busca },
    { id: 'responsavel', rotulo: bibDialogo.comprador, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  ]),

  compraOperacaoEntradaRecebimento: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataEmissaoDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataEmissaoAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataSaidaDe', rotulo: bibDialogo.dataEntradaDe, nome: 'DATA_SAIDA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataSaidaAte', rotulo: bibDialogo.dataEntradaAte, nome: 'DATA_SAIDA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'fornecedor', rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.fornecedor, tipo: bibPropriedade.filtro.busca },
    { id: 'colaborador', rotulo: bibDialogo.responsavel, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'transportadora', rotulo: bibDialogo.transportadora, nome: 'ID_TRANSPORTADORA', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'idNaturezaOperacao', rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, criterios: [new Criterio('ENTRADA_SAIDA', '1, 2')], tipo: bibPropriedade.filtro.checklista },
  ]),

  compraOperacaoDevolucao: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'fornecedor', rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.fornecedor, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto }, { id: 'responsavel', rotulo: bibDialogo.responsavel, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  ]),

  vendaOperacaoOrcamento: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'parceiro', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
    { id: 'responsavel', rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  ]),

  vendaOperacaoEstudoVenda: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroFiscal', rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL_VINCULADO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'cliente', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
    { id: 'responsavel', rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroSituacao },
  ]),

  vendaOperacaoPedidoCarteira: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroFiscal', rotulo: bibDialogo.numeroFiscal, nome: 'NUMERO_FISCAL_VINCULADO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'cliente', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
    { id: 'responsavel', rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO_CARTEIRA', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroSituacaoPedidoCarteira },
  ]),

  vendaOperacaoDevolucao: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'cliente', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
    { id: 'responsavel', rotulo: bibDialogo.responsavel, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  ]),

  vendaOperacaoNotaVenda: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataSaidaDe', rotulo: bibDialogo.dataSaidaDe, nome: 'DATA_SAIDA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataSaidaAte', rotulo: bibDialogo.dataSaidaAte, nome: 'DATA_SAIDA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'cliente', rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'responsavel', rotulo: bibDialogo.responsavel, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'transportadora', rotulo: bibDialogo.transportadora, nome: 'ID_TRANSPORTADORA', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'ID_CENTRO_RESULTADO', coluna: 12, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
    { id: 'idNaturezaOperacao', rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, criterios: [new Criterio('ENTRADA_SAIDA', '-1, 2, 4')], tipo: bibPropriedade.filtro.checklista },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  ]),

  internaTransferenciaBalancoMovimentacaoManual: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'responsavel', rotulo: bibDialogo.responsavel, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  ]),

  transferenciaFiltros: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'parceiro', rotulo: bibDialogo.responsavel, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'status', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
    { id: 'lojaDestino', rotulo: bibDialogo.lojaDestino, nome: 'IDS_LOJA_DESTINO', coluna: 6, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.checklista },
    { id: 'idNaturezaOperacao', rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, tipo: bibPropriedade.filtro.checklista },
  ]),

  transferenciaSaidaFiltros: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataSaidaDe', rotulo: bibDialogo.dataSaidaDe, nome: 'DATA_SAIDA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'dataSaidaAte', rotulo: bibDialogo.dataSaidaAte, nome: 'DATA_SAIDA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'parceiro', rotulo: bibDialogo.responsavel, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'referencia', rotulo: bibDialogo.referencia + ' (Produto)', nome: 'REFERENCIA_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'produto', rotulo: bibDialogo.nome + ' (Produto)', nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'exceto', rotulo: bibDialogo.exceto + ' (Produto)', nome: 'EXCETO_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { id: 'status', rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
    { id: 'lojaDestino', rotulo: bibDialogo.lojaDestino, nome: 'IDS_LOJA_DESTINO', coluna: 6, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.checklista },
    { id: 'idNaturezaOperacao', rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, tipo: bibPropriedade.filtro.checklista },
  ]),
};

export default filtros;
