import { Cofins } from './cofins';
import { Icms } from './icms';
import { importacaoII } from './importacaoII';
import { Ipi } from './ipi';
import { Partilha } from './partilha';
import { Pis } from './pis';

export class Tributo {
  public cofins: Cofins;
  public icms: Icms;
  public ipi: Ipi;
  public pis: Pis;
  public importacaoIi: importacaoII;
  public partilha: Partilha;
  public valorAproximadoTributos: number;
}
