import { Component, Input } from '@angular/core';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroAtualizacao } from 'src/app/modelo/parceiroAtualizacao';
import { ParceiroCobranca } from 'src/app/modelo/parceiroCobranca';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'parceiroCobranca',
  templateUrl: './parceiroCobranca.component.html',
})
export class ParceiroCobrancaComponent extends PaginaComponent {
  public idUsuario: number;
  public nomeUsuario: string;
  @Input() parceiro: Parceiro;
  @Input() parceiroCobrancas: ParceiroCobranca[] = [];
  @Input() excluirParceiroCobrancas: number[];

  public listaFormaCobranca: any[] = [
    { id: 'LIGAÇÃO TELEFÔNICA', nome: this.bibDialogo.ligacaoTelefone.toUpperCase() },
    { id: 'SMS OU WHATS APP', nome: this.bibDialogo.sms.toUpperCase() + ' OU ' + this.bibDialogo.whatsapp.toUpperCase() },
    { id: 'E-MAIL', nome: this.bibDialogo.email.toUpperCase() },
    { id: 'TERCEIRIZAÇÃO', nome: this.bibDialogo.tercerizacao.toUpperCase() },
    { id: 'PROTESTO CARTORIAL', nome: this.bibDialogo.protestoCartorial.toUpperCase() },
    { id: 'ASSESSORIA JURÍDICA', nome: this.bibDialogo.assesoriaJuridica.toUpperCase() },
    { id: 'CORRESPONDÊNCIA', nome: this.bibDialogo.correspondencia.toUpperCase() },
  ];

  adicionarCobranca(): void {
    const parceiroCobranca: ParceiroCobranca = new ParceiroCobranca();
    parceiroCobranca.idParceiro = this.parceiro.id;
    parceiroCobranca.nomeUsuario = this.utilSessao.getUsuario().nome;
    this.parceiro.parceiroCobrancas.push(parceiroCobranca);
  }
}
