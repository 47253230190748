<layout [subTitulo]="visualizaTotalizadores == true && estoques.length > 0 && estoques[0].produto ? bibDialogo.produto.toUpperCase() + ': ' + estoques[0].numero + ' - ' + estoques[0].produto + ' - ' + estoques[0].unidade : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div class="row justify-content-end">
    <botao *ngIf="estoques.length > 0 && mostraExpandirTodos == true" [compacto]="compacto" [legenda]="bibDialogo.expandirTodos" (botaoEmt)="expandirTodos()"></botao>
    <!-- <botao *ngIf="estoques.length > 0 && temPermissaoEdicao && telaInterna == false" [compacto]="compacto" [legenda]="bibDialogo.editar" (botaoEmt)="editar()"></botao> -->
  </div>
  <registro *ngIf="estoques.length > 0 && telaInterna == false" [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th *ngIf="!visualizaTotalizadores" class="l-5 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviacao }}</th>
        <th *ngIf="!visualizaTotalizadores" class="l-25" class="clique" (click)="listar(add('produto'), this.utilSessao.posicao, this.paginacao)">{{ rotuloProduto }}</th>
        <th *ngIf="!visualizaTotalizadores" class="l-5 clique" (click)="listar(add('unidade'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th *ngIf="this.utilSessao.getEmpresa().separarEntregarOpcional == 'S'" class="text-right clique" [ngClass]="visualizaTotalizadores ? 'l-15' : 'l-10'" (click)="listar(add('quantidadeDisponivel'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.fisica }}</th>
        <th class="text-right clique" [ngClass]="visualizaTotalizadores ? 'l-15' : 'l-10'" (click)="listar(add('quantidadeDisponivel'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.disponivel }}</th>
        <th class="l-5" *ngIf="ehPermitidoEditar"></th>
        <th *ngIf="!ehPermitidoEditar" class="text-right clique" [ngClass]="visualizaTotalizadores ? 'l-15' : 'l-10'" (click)="listar(add('quantidadeReservada'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.pendente }}</th>
        <th class="text-right clique" [ngClass]="visualizaTotalizadores ? 'l-15' : 'l-10'" (click)="listar(add('quantidadeNfe'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nfe }}</th>
        <th *ngIf="!ehPermitidoEditar" class="text-right clique fonte-menor" [ngClass]="visualizaTotalizadores ? 'l-15' : 'l-10'" (click)="listar(add('quantidadeTransferida'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.emTransferencia }}<ajuda [ajuda]="bibDialogo.aguardandoChegar"></ajuda></th>
        <th *ngIf="!ehPermitidoEditar" class="text-right clique" [ngClass]="visualizaTotalizadores ? 'l-15' : 'l-10'" (click)="listar(add('quantidadeComprada'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.comprado }}</th>
        <th class="l-5" *ngIf="ehPermitidoEditar"></th>
        <th class="l-5 naoImprimir"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let estoque of estoques; let i = index">
        <tr>
          <td>{{ estoque.abreviacao }}</td>
          <td *ngIf="!visualizaTotalizadores" class="text-right">{{ estoque.numero }}</td>
          <td *ngIf="!visualizaTotalizadores" class="limitar">{{ estoque.produto }}</td>
          <td *ngIf="!visualizaTotalizadores" class="limitar">{{ estoque.unidade }}</td>
          <td *ngIf="this.utilSessao.getEmpresa().separarEntregarOpcional == 'S'" class="text-right">
            {{estoque.quantidadeFisica | monetario: empresaRegraCasasDecimais }}
          </td>          
          <td class="text-right">
            {{ !ehPermitidoEditar ? (estoque.quantidadeDisponivel | monetario: empresaRegraCasasDecimais) : '' }}
            <decimal *ngIf="ehPermitidoEditar" [campo]="estoque.quantidadeDisponivel" (alteracao)="setEstoqueQuantidadeDisponivel($event, estoque)" [numeroCasasDecimais]="empresaRegraCasasDecimais" [focoId]="focoIdQuantidade && i == 0"></decimal>
          </td>
          <td *ngIf="ehPermitidoEditar">
            <sup *ngIf="ehPermitidoEditar && estoque.quantidadeDiferencaDisponivel != 0" [ngClass]="estoque.quantidadeDiferencaDisponivel > 0 ? 'badge text-success' : 'badge text-danger'" class="text-right">{{ estoque.quantidadeDiferencaDisponivel | monetario }}</sup>
          </td>
          <td *ngIf="!ehPermitidoEditar" class="text-right">{{ estoque.quantidadeReservada + estoque.quantidadeCarteira | monetario: empresaRegraCasasDecimais }}</td>
          <td class="text-right">
            {{ !ehPermitidoEditar ? (estoque.quantidadeNfe | monetario: empresaRegraCasasDecimais) : '' }}
            <decimal *ngIf="ehPermitidoEditar" [campo]="estoque.quantidadeNfe" (alteracao)="setEstoqueQuantidadeNfe($event, estoque)" [numeroCasasDecimais]="empresaRegraCasasDecimais"> </decimal>
          </td>          
          <td *ngIf="!ehPermitidoEditar" class="text-right">{{ estoque.quantidadeTransferida | monetario: empresaRegraCasasDecimais }}</td>
          <td *ngIf="!ehPermitidoEditar" class="text-right">{{ estoque.quantidadeComprada | monetario: empresaRegraCasasDecimais }}</td>
          <td *ngIf="ehPermitidoEditar">
            <sup *ngIf="estoque.quantidadeDiferencaNfe != 0" [ngClass]="estoque.quantidadeDiferencaNfe > 0 ? 'badge text-success' : 'badge text-danger'" class="text-right">{{ estoque.quantidadeDiferencaNfe | monetario }}</sup>
          </td>
          <td class="naoImprimir"><i class="fa fa-circle fa-vermelho" *ngIf="estoque.disponivel < estoque.quantidadeReservada" placement="top" [ngbTooltip]="bibDialogo.pendenteMaiorFisico"></i></td>
          <td *ngIf="estoque.idLocal || estoque.idLote"><btnAdicional [icone]="estoque.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="estoque.expandido = !estoque.expandido"></btnAdicional></td>
        </tr>
        <tr *ngIf="estoque.expandido">
          <td *ngIf="estoque.idLocal" [colSpan]="2">
            <atencao [atencao]="bibDialogo.local + ': ' + estoque.local"></atencao>
          </td>
          <td *ngIf="estoque.idLote">
            <atencao [atencao]="bibDialogo.lote + ': ' + estoque.lote + (estoque.dataValidade ? ' - ' + bibDialogo.dataValidade + ': ' + (estoque.dataValidade | data) : '')"> </atencao>
            <atencao *ngIf="estoque.ativoLote == 'N'" [atencao]="bibDialogo.inativo.toUpperCase()" [tipo]="bibPropriedade.atencao.cuidado"></atencao>

          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="visualizaTotalizadores && totalEstoques.length > 0">
      <tr *ngFor="let totalEstoque of totalEstoques">
        <td *ngIf="!visualizaTotalizadores" colspan="3"></td>
        <td *ngIf="visualizaTotalizadores"></td>
        <td *ngIf="this.utilSessao.getEmpresa().separarEntregarOpcional == 'S'" class="text-right font-weight-bold">{{ totalEstoque.totalfisico | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right font-weight-bold">{{ totalEstoque.totalDisponivel | monetario: empresaRegraCasasDecimais }}</td>
        <td *ngIf="ehPermitidoEditar">
          <sup *ngIf="totalEstoque.totalDisponivel != quantidadeDisponivelTotalInicial" [ngClass]="totalEstoque.totalDisponivel - quantidadeDisponivelTotalInicial > 0 ? 'badge text-success' : 'badge text-danger'" class="text-right">{{ totalEstoque.totalDisponivel - quantidadeDisponivelTotalInicial | monetario }}</sup>
        </td>
        <td *ngIf="!ehPermitidoEditar" class="text-right font-weight-bold">{{ totalEstoque.totalPendente | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right font-weight-bold">{{ totalEstoque.totalNfe | monetario: empresaRegraCasasDecimais }}</td>
        <td *ngIf="ehPermitidoEditar">
          <sup *ngIf="totalEstoque.totalNfe != quantidadeNfeTotalInicial" [ngClass]="totalEstoque.totalNfe - quantidadeNfeTotalInicial > 0 ? 'badge text-success' : 'badge text-danger'" class="text-right">{{ totalEstoque.totalNfe - quantidadeNfeTotalInicial | monetario }}</sup>
        </td>        
        <td *ngIf="!ehPermitidoEditar" class="text-right font-weight-bold">{{ totalEstoque.totalTransferido | monetario: empresaRegraCasasDecimais }}</td>
        <td *ngIf="!ehPermitidoEditar" class="text-right font-weight-bold">{{ totalEstoque.totalComprado | monetario: empresaRegraCasasDecimais }}</td>
        <td></td>
      </tr>
    </tfoot>
  </table>
  <div class="row">
    <botao *ngIf="ehPermitidoEditar" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="exigirSenha()"></botao>
    <texto *ngIf="focoIdSenha" [id]="'senhaOperacional'" [ngClass]="compacto ? 'mt-2' : ''" (navegacao)="$event == false ? (focoIdSenha = null) : ''" [tipo]="bibPropriedade.texto.senha" (alteracao)="conferirSenha($event)" [texto]="bibDialogo.senha" [focoId]="focoIdSenha" [descricao]="bibDialogo.senhaOperacional" autocomplete="new-password"></texto>
  </div>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
