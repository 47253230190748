<layout [subTitulo]="financeiro.gerouBoleto == 'S' ? bibDialogo.boletoGerado : ''" [id]="financeiro.id" [mostraRelatorio]="financeiro.financeiroBaixas.length > 0" [utilitarios]="utilitarios" (utilitarioEmt)="verificarUtilitario($event)" (voltarAvancarEmt)="ehAlteracao()">
  <bloqueado [bloqueado]="this.financeiro.baixado"></bloqueado>
  <div class="row">
    <texto class="col-sm-3" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="financeiro.numero" (alteracao)="financeiro.numero = $event" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()" [tipo]="bibPropriedade.texto.inteiro" [foco]="!financeiro.id" [icone]="financeiro.idMovimentacao ? bibIcone.olho : ''" [iconeDesabilitado]="false" (btnAdicional)="abrirModalMovimentacao(financeiro.idMovimentacao)" [ajuda]="bibDialogo.abrirMovimentacao"></texto>
    <texto class="col-sm-3" [id]="'numeroFiscal'" [rotulo]="bibDialogo.numeroFiscal" [campo]="financeiro.numeroFiscal" (alteracao)="financeiro.numeroFiscal = $event" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()" [tipo]="bibPropriedade.texto.inteiro"></texto>
    <texto class="col-sm" [id]="'parcela'" [rotulo]="bibDialogo.parcela" [campo]="financeiro.parcela" (alteracao)="financeiro.parcela = $event" [obrigatorio]="true" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="financeiro.ativo" (alteracao)="financeiro.ativo = $event" [desabilitado]="financeiro.ehBloqueiaFinanceiroBaixadoBoleto()" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <data class="col-sm-6" [id]="'dataNegociacao'" [rotulo]="bibDialogo.dataNegociacao" [campo]="financeiro.dataNegociacao" (alteracao)="financeiro.dataNegociacao = $event" [obrigatorio]="true" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()"></data>
    <data class="col-sm-6" [id]="'dataVencimento'" [rotulo]="bibDialogo.dataVencimento" [campo]="financeiro.dataVencimento" (alteracao)="financeiro.dataVencimento = $event" [obrigatorio]="true" [desabilitado]="this.financeiro.baixado" [foco]="financeiro.id"></data>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [tipo]="bibPropriedade.lista.lojaUsuario" [campo]="financeiro.idLoja" [idMenu]="permissaoLoja && permissaoLoja.inserir == 'S' ? permissaoLoja.idMenu : null" [obrigatorioLoja]="true" (alteracao)="financeiro.idLoja = $event.id" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()"></lista>
    <texto *ngIf="financeiro.idMovimentacao" class="col-sm-6" [rotulo]="tipo == 'R' || tipo == 'RR' ? bibDialogo.cliente : bibDialogo.fornecedor" [campo]="financeiro.parceiro" (alteracao)="financeiro.parceiro = $event" [idMenu]="(tipo == 'R' || tipo == 'RR') ? ((permissaoCliente && permissaoCliente.inserir == 'S') ? permissaoCliente.idMenu : ((permissaoFornecedor && permissaoFornecedor.inserir == 'S') ? permissaoFornecedor.idMenu : null)) : null" [obrigatorio]="true" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()"></texto>
    <busca *ngIf="!financeiro.idMovimentacao" class="col-sm-6" [id]="'parceiro'" [rotulo]="tipo == 'R' || tipo == 'RR' ? bibDialogo.cliente : bibDialogo.fornecedor" [campo]="financeiro.parceiro" (alteracao)="setFinanceiroParceiro($event)" [idMenu]="(tipo == 'R' || tipo == 'RR') ? (permissaoCliente && permissaoCliente.inserir == 'S' ? permissaoCliente.idMenu : null) : (permissaoFornecedor && permissaoFornecedor.inserir == 'S' ? permissaoFornecedor.idMenu : null)" [minimoCaracterBusca]="5" [servico]="bibServico.parceiro" [obrigatorio]="true" [desabilitado]="financeiro.ehBloqueiaFinanceiroBaixadoBoleto() || financeiro.idCredito" [minimoCaracterBusca]="2"></busca>
    <lista class="col-sm-12" *ngIf="financeiro.idParceiroDestino > 0" [id]="'destino'" [rotulo]="bibDialogo.destino" [campo]="financeiro.idParceiroDestino" [lista]="parceiroDestinos" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()"></lista>
    <busca *ngIf="menuNatureza?.ativo == 'S'" class="col-sm-6" [idMenu]="permissaoNatureza && permissaoNatureza.inserir == 'S' ? permissaoNatureza.idMenu : null" [id]="'natureza'" [rotulo]="menuNatureza.apelido" [campo]="financeiro.natureza" (alteracao)="financeiro.idNatureza = $event.id" [servico]="bibServico.natureza" [criterios]="criterios" [desabilitado]="this.financeiro.baixado"></busca>    
    <busca *ngIf="menuCentroDeResultado?.ativo == 'S'" class="col-sm-6" [idMenu]="permissaoCentroDeResultado && permissaoCentroDeResultado.inserir == 'S' ? menuCentroDeResultado.idMenu : null" [id]="'centroResultado'" [rotulo]="menuCentroDeResultado.apelido" [campo]="financeiro.centroResultado" (alteracao)="financeiro.idCentroResultado = $event.id" [servico]="bibServico.centroResultado" [criterios]="criterios" [desabilitado]="this.financeiro.baixado"></busca>
    <busca *ngIf="menuContaContabil?.ativo == 'S'" class="col-sm-6" [idMenu]="permissaoContaContabil && permissaoContaContabil.inserir == 'S' ? menuContaContabil.idMenu : null" [id]="'contaContabil'" [rotulo]="bibDialogo.contaContabil" [campo]="financeiro.contaContabil" (alteracao)="financeiro.idContaContabil = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterios" [desabilitado]="this.financeiro.baixado || (this.utilSessao.getEmpresa().contabilidade == 'S' && (this.financeiro.idMovimentacao != null || this.financeiro.valorBaixa > 0))" [minimoCaracterBusca]="4"></busca>
    <lista class="col-sm-6" [idMenu]="permissaoConta && permissaoConta.inserir == 'S' ? menuConta.idMenu : null" [id]="'contaPrevista'" [rotulo]="menuConta.apelido + ' ' + bibDialogo.previstoAbreviacao.toLowerCase()" [campo]="financeiro.idConta" [tipo]="bibPropriedade.lista.contaUsuario" (alteracao)="setidConta(financeiro, $event)" [desabilitado]="financeiro.ehBloqueiaFinanceiroBaixadoBoleto()"></lista>
    <lista class="col-sm-6" [idMenu]="permissaoTipoDeTitulo && permissaoTipoDeTitulo.inserir == 'S' ? permissaoTipoDeTitulo.idMenu : null" [id]="'tipoTituloPrevisto'" [rotulo]="menuTipoDeTitulo.apelido + ' ' + bibDialogo.previstoAbreviacao.toLowerCase()" [campo]="financeiro.idTipoTitulo" [lista]="tipoTitulos" (alteracao)="setTipoTitulo(financeiro, $event)" [desabilitado]="financeiro.ehBloqueiaFinanceiroBaixadoBoleto()" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarTipoTitulo()"></lista>
    <apresentacao *ngIf="utilSessao.sistema == 'aGrow-w'" class="col-sm-6" [rotulo]="bibDialogo.safra" [idMenu]="permissaoSafra && permissaoSafra.inserir == 'S' ? permissaoSafra.idMenu : null" [campo]="financeiro.safra"></apresentacao>
  </div>
  <titulo [titulo]="bibDialogo.valores" [imagem]="bibImagem.acoes"></titulo>
  <div class="row">
    <decimal class="col-sm-3" [id]="'valor'" [rotulo]="bibDialogo.valor" [campo]="financeiro.valor" (alteracao)="setValor($event, 'valor')" [obrigatorio]="financeiro.multa <= 0" [desabilitado]="financeiro.ehBloqueaFinanceiroCabecalho()"></decimal>
    <apresentacao class="col-sm-3" [rotulo]="bibDialogo.desconto" [campo]="financeiro.desconto | monetario"></apresentacao>
    <apresentacao class="col-sm-3" [rotulo]="bibDialogo.juro" [campo]="financeiro.juro | monetario"></apresentacao>
    <apresentacao class="col-sm-3" [rotulo]="bibDialogo.juroAutomatico" [campo]="financeiro.juroAutomatico | monetario"></apresentacao>
  </div>
  <div class="row">
    <decimal class="col-sm-3" [id]="'acrescimo'" [rotulo]="bibDialogo.acrescimo" [campo]="financeiro.multa" (alteracao)="setValor($event, 'multa')" [obrigatorio]="true" [desabilitado]="((tipo == 'R' || tipo == 'D') && (permissaoRenegociacao == null || permissaoRenegociacao.editar == 'N')) || financeiro.ehBloqueiaFinanceiroBaixadoBoleto()"></decimal>
    <apresentacao class="col-sm-3 text-right" [rotulo]="bibDialogo.valorFinal" [ajuda]="bibDialogo.calculoValorFinal" [campo]="financeiro.valorFinal | monetario"></apresentacao>
    <apresentacao class="col-sm-3 text-right" [rotulo]="bibDialogo.valorPago" [campo]="financeiro.valorBaixa | monetario"></apresentacao>
    <apresentacao class="col-sm-3 text-right" [rotulo]="bibDialogo.valorRestante" [campo]="financeiro.valorRestante | monetario" [ajuda]="bibDialogo.calculoValorRestante"></apresentacao>
    <decimal class="col-sm-3" [id]="'anulado'" [rotulo]="bibDialogo.valorAnulado" [campo]="financeiro.valorAnulado" (alteracao)="setValor($event, 'valorAnulado')" [obrigatorio]="true" [desabilitado]="((tipo == 'R' || tipo == 'D') && (permissaoRenegociacao == null || permissaoRenegociacao.editar == 'N')) || financeiro.ehBloqueiaFinanceiroBaixadoBoleto()"></decimal>
    <!-- <apresentacao class="col-sm-3 text-right" [rotulo]="bibDialogo.valorAnterior" [campo]="financeiro.valorAnterior > 0 ? (financeiro.valorAnterior | monetario) : (financeiro.valorAnterior * -1 | monetario)" [ajuda]="bibDialogo.calculoValorAnterior"></apresentacao> -->
  </div>
  <titulo [titulo]="bibDialogo.observacao" [imagem]="bibImagem.observacao"></titulo>
  <div class="row">
    <descricao class="col-sm" [id]="'observacao'" [campo]="financeiro.observacao" (alteracao)="financeiro.observacao = $event" [desabilitado]="this.financeiro.baixado" [maximoCaracteres]="2000"></descricao>
    <interruptor [id]="'mostrarAlerta'" [rotulo]="bibDialogo.destacar" [campo]="financeiro.destacaObservacao" (alteracao)="financeiro.destacaObservacao = $event" [ajuda]="bibDialogo.destacaObservacao"></interruptor>
  </div>  
  <financeiroFrmBai *ngIf="tipo != 'RR' && tipo != 'DD'" [financeiro]="financeiro" [excluirFinanceiroBaixas]="excluirFinanceiroBaixas" [tipoTitulos]="tipoTitulos"></financeiroFrmBai>
  <financeiroFrmNeg *ngIf="financeiro.id && financeiro.valorRestante > 0 && (tipo == 'RR' || tipo == 'DD' || (permissaoRenegociacao && permissaoRenegociacao.editar == 'S'))" [financeiro]="financeiro" [financeiros]="financeiros"></financeiroFrmNeg>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="salvar()" [desabilitado]="bloquearPermissao(this.financeiro.id) == false ? this.financeiro.baixado : true"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="salvar(true)" [desabilitado]="this.financeiro.baixado"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<auditoria *ngIf="financeiro.id" [idUsuarioInclusao]="financeiro.idUsuarioInclusao" [dataHoraInclusao]="financeiro.dataHoraInclusao" [idUsuarioAlteracao]="financeiro.idUsuarioAlteracao" [dataHoraAlteracao]="financeiro.dataHoraAlteracao"></auditoria>


                                      
