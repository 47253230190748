<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r">{{ bibDialogo.conta }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.saldoInicial }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.receita }}<ajuda [ajuda]="bibDialogo.conciliadas + ' no ' + bibDialogo.periodo.toLowerCase()"></ajuda></th>
      <th class="r-1-5 text-right">{{ bibDialogo.despesa }}<ajuda [ajuda]="bibDialogo.conciliadas + ' no ' + bibDialogo.periodo.toLowerCase()"></ajuda></th>
      <th class="r-1-5 text-right">{{ bibDialogo.saldo }}<ajuda [ajuda]="bibDialogo.atePeriodoInformado"></ajuda></th>
      <th *ngIf="maisInformacoes" class="r-1-5 text-right">{{ bibDialogo.receita }}<br>{{' a ' + bibDialogo.conciliar }}<ajuda [ajuda]="bibDialogo.baixada + 's e não ' + bibDialogo.conciliadas.toLowerCase() +', ' + bibDialogo.independente.toLowerCase() + ' do ' + bibDialogo.periodo.toLowerCase()"></ajuda></th>
      <th *ngIf="maisInformacoes" class="r-1-5 text-right">{{ bibDialogo.despesa }}<br>{{' a ' + bibDialogo.conciliar }}<ajuda [ajuda]="bibDialogo.baixada + 's e não ' + bibDialogo.conciliadas.toLowerCase() +', ' + bibDialogo.independente.toLowerCase() + ' do ' + bibDialogo.periodo.toLowerCase()"></ajuda></th>
      <th *ngIf="maisInformacoes" class="r-1-5 text-right">{{ bibDialogo.saldoPrevisto }}<ajuda [ajuda]="bibDialogo.atePeriodoInformado"></ajuda></th>
      <th *ngIf="conciliacaoAgrupados.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let conciliacaoAgrupado of conciliacaoAgrupados; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="conciliacaoAgrupado.expandido ? 'sem-borda' : ''">
          <td>{{ conciliacaoAgrupado.conta }}</td>
          <td class="text-right">{{ (!usaTaxaCartao ? conciliacaoAgrupado.saldoInicial :  conciliacaoAgrupado.saldoInicialValorLiquido) | monetario }}</td>
          <td class="text-right">{{ (!usaTaxaCartao ? conciliacaoAgrupado.receita : conciliacaoAgrupado.receitaValorLiquido) | monetario }}</td>
          <td class="text-right">{{ (!usaTaxaCartao ? conciliacaoAgrupado.despesa : conciliacaoAgrupado.despesaValorLiquido) | monetario }}</td>
          <td class="text-right">{{ (!usaTaxaCartao ? conciliacaoAgrupado.saldo : conciliacaoAgrupado.saldoValorLiquido) | monetario }}</td>
          <td *ngIf="maisInformacoes" class="text-right">{{ (!usaTaxaCartao ? conciliacaoAgrupado.receitaAConciliar : conciliacaoAgrupado.receitaAConciliarValorLiquido) | monetario }}</td>
          <td *ngIf="maisInformacoes" class="text-right">{{ (!usaTaxaCartao ? conciliacaoAgrupado.despesaAConciliar : conciliacaoAgrupado.despesaAConciliarValorLiquido) | monetario }}</td>
          <td *ngIf="maisInformacoes" class="text-right">{{ (!usaTaxaCartao ? (conciliacaoAgrupado.saldo + conciliacaoAgrupado.receitaAConciliar - conciliacaoAgrupado.despesaAConciliar) : (conciliacaoAgrupado.saldoValorLiquido + conciliacaoAgrupado.receitaAConciliarValorLiquido - conciliacaoAgrupado.despesaAConciliarValorLiquido) ) | monetario }}</td>
          <td *ngIf="conciliacaoAgrupados.length > 0"><div class="naoImprimir"><btnAdicional *ngIf="conciliacaoAgrupado.receita > 0 || conciliacaoAgrupado.despesa > 0 || conciliacaoAgrupado.receitaAConciliar > 0 || conciliacaoAgrupado.despesaAConciliar > 0" [icone]="conciliacaoAgrupado.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(conciliacaoAgrupado, i)"></btnAdicional></div></td>
        </tr>
        <tr *ngIf="conciliacaoAgrupado.expandido" r1009itens [conciliacaoAgrupado]="conciliacaoAgrupado" [maisInformacoes]="maisInformacoes" [usaTaxaCartao]="usaTaxaCartao"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="conciliacaoAgrupados.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
  <r1009tiposTitulo [tiposTituloAgrupados]="tiposTituloAgrupados" [maisInformacoes]="maisInformacoes" [usaTipoTitulo]="usaTipoTitulo" [usaTaxaCartao]="usaTaxaCartao" class="sem-borda"></r1009tiposTitulo>
</relatorioLayout>
