import { ParametroValor } from './parametroValor';

export class OperacaoParametro {
  public id: number;
  public idOperacao: number;
  public idParametro: number;
  public idParametroValor: number;
  public parametroDescricao: string;
  public parametroGrupo: string;
  public parametroValores: ParametroValor[] = [];
  public valor: string;
}
