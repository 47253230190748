import { UtilSessao } from '../utilitario/util.sessao';
import { Nomenclatura } from '../modelo/nomenclatura';
import { Injectable } from '@angular/core';

@Injectable()
export class BibNomenclatura {
  private utilSessao: UtilSessao = new UtilSessao();
  public acrescimo: number = 6;
  public custoGerencial: number = 4;
  public custoReposicao: number = 1;
  public desconto: number = 7;
  public destino: number = 5;
  public margemGerencial: number = 3;
  public movimentacao: number = 8;

  escrever(idNomenclatura: number): string {
    let nomenclaturas: Nomenclatura[] = [];
    nomenclaturas = this.utilSessao.getNomenclaturaEmpresas() ? this.utilSessao.getNomenclaturaEmpresas() : nomenclaturas;
    if (nomenclaturas.length > 0) {
      const nomenclatura: Nomenclatura = nomenclaturas.find((nomenclatura) => nomenclatura.id == idNomenclatura);
      if (nomenclatura != null) {
        return nomenclatura.apelido;
      } else {
        return '';
      }
    }
  }
}
