import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import format from 'date-fns/format';
import { Observable } from 'rxjs';

@Injectable()
export class ComunicacaoNsCTeService {
  private token: string = 'QUdST1cgU09GVFdBUkUgdnNWWmI='; //produção
  private url: string = 'https://cte.ns.eti.br/';

  constructor(private http: HttpClient) { }

  private consumir(rota: string, parametros: any, metodo: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    if (metodo == 'POST') {
      return this.http.post(this.url + rota, parametros, { headers });
    }
  }
  persistir(url: string, parametros: any): Observable<any> {
    return this.consumir(url, parametros, 'POST');
  }

  consumirPdfXml(url: string, cnpj: string, chave: string, producaoHomologacao: string, tipoArquivo: string): Observable<any> {
    const parametros = JSON.stringify({
      "X-AUTH-TOKEN": this.token,
      "chCTe": chave,
      "tpAmb": producaoHomologacao,
      "CNPJ": cnpj,
      "tpDown": tipoArquivo
    });
    return this.consumir(url, parametros, 'POST');
  }

  listar(url: string, cnpj: string, codigoIntegracao: string, producaoHomologacao: string): Observable<any> {
    const parametros = JSON.stringify({
      "X-AUTH-TOKEN": this.token,
      "nsNRec": codigoIntegracao.toString(),
      "CNPJ": cnpj,
      "tpAmb": producaoHomologacao
    })
    return this.consumir(url, parametros, 'POST');
  }

  consumirCancelamento(url: string, chave: string, protocolo: string, justificativa: string, producaoHomologacao: string): Observable<any> {
    const parametros = JSON.stringify({
      "X-AUTH-TOKEN": this.token,
      "chCTe": chave,
      "tpAmb": producaoHomologacao,
      "dhEvento": format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'-03:00'"),
      "nProt": protocolo,
      "xJust": justificativa
    });
    return this.consumir(url, parametros, 'POST');
  }

  consultar(url: string, cnpj: string, chave: string, producaoHomologacao: string): Observable<any> {
    const parametros = JSON.stringify({
      "X-AUTH-TOKEN": this.token,
      "licencaCnpj": cnpj,
      "chCTe": chave,
      "tpAmb": producaoHomologacao
    })
    return this.consumir(url, parametros, 'POST');
  }

  enviarEmail(url: string, email: string, chave: string, producaoHomologacao: string): Observable<any> {
    const parametros = JSON.stringify({
      "X-AUTH-TOKEN": this.token,
      "chCTe": chave,
      "tpAmb": producaoHomologacao,
      "enviaEmailDoc": true,
      "email": email,
      "anexarPDF": true,
      "anexarEvento": true
    })
    return this.consumir(url, parametros, 'POST');
  }
}
