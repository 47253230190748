<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l clique" (click)="listar(add('produto'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.produto }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
        <th class="l-10 clique" (click)="listar(add('usuario'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.usuario }}</th>
        <th class="l-10 naoImprimir clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>        
        <th class="l-15 naoImprimir clique" (click)="listar(add('dataHoraInclusao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.horario + ' ' + bibDialogo.insercao }}</th>
        <th class="l-20 clique" (click)="listar(add('movimentacaoNumero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.origem }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoValorHistorico of produtoValorHistoricos; let i = index">
        <tr>
          <td>{{ produtoValorHistorico.lojaAbreviacao }}</td>
          <td class="limitar"><ajuda class="p-2" [ajuda]="produtoValorHistorico.produto" [ajudaDireita]="true"></ajuda>
            <a *ngIf="this.permissaoProduto" href="#" (click)="visualizarProduto(produtoValorHistorico.idProduto)">{{ produtoValorHistorico.produto }}</a>
            <span *ngIf="this.permissaoProduto == null">{{ produtoValorHistorico.produto }}</span>
          </td>
          <td class="text-right">{{ produtoValorHistorico.valor | monetario }}</td>
          <td class="limitar">{{ produtoValorHistorico.usuario }}</td>
          <td class="naoImprimir">{{ produtoValorHistorico.data | data: 'dd/MM/yyyy' }}</td>
          <td class="naoImprimir">{{ produtoValorHistorico.dataHoraInclusao | data: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ produtoValorHistorico.movimentacaoNumero ? bibDialogo.movimentacao + ' Nº : ' + produtoValorHistorico.movimentacaoNumero : bibDialogo.manual }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<atencao *ngIf="this.produtoValorHistoricos.length == 0" [atencao]="atencao" [tipo]="bibPropriedade.atencao.alerta"></atencao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>