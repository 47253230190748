<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique clique" (click)="listar(add('status'), this.utilSessao.posicao, this.paginacao)"></th>
        <th class="l-5 text-right clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao, bibDialogo.loja)">{{ bibDialogo.loja }}</th>
        <th *ngIf="idOperacao == 9 || idOperacao == 10" class="l-10 text-right clique" (click)="listar(add('abreviacaoLojaDestino'), this.utilSessao.posicao, this.paginacao, bibDialogo.destino)">{{ bibDialogo.destino }}</th>
        <th *ngIf="!operacaoEmiteNota" class="l-10 text-center clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao, bibDialogo.numero)">{{ bibDialogo.numero }}</th>
        <th class="l-10 clique" [ngClass]="operacaoEmiteNota ? 'text-center' : ''" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao, bibDialogo.data)">{{ bibDialogo.data }}</th>
        <th *ngIf="operacaoEmiteNota" class="l-10 text-right clique" (click)="listar(add('status'), this.utilSessao.posicao, this.paginacao, bibDialogo.numeroFiscalAbreviacao)">{{ bibDialogo.numeroFiscalAbreviacao }}</th>
        <th [ngClass]="idOperacao == 2 || idOperacao == 20 ? 'l-15' : operacaoInterna ? 'l-45' : 'l-35'" class="clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao, parceiro)">{{ parceiro }}</th>
        <th class="l-5"></th>
        <th *ngIf="idOperacao != 4 && operacaoDifereInterna" class="clique" [ngClass]="idOperacao == 9 || idOperacao == 10 ? 'l-10' : 'l-15'" (click)="listar(add('colaborador'), this.utilSessao.posicao, this.paginacao, colaborador)">{{ colaborador }}</th>
        <th *ngIf="idOperacao == 2 || idOperacao == 20" class="l-15 clique" (click)="listar(add('situacao'), this.utilSessao.posicao, this.paginacao, bibDialogo.situacao)">{{ bibDialogo.situacao }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorTotal'), this.utilSessao.posicao, this.paginacao, bibDialogo.valorTotal)">{{ bibDialogo.valorTotal }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr [ngClass]="{ tachado: movimentacao.ativo != 'S' }">
          <td>
            <i *ngIf="movimentacao.status == 'S' && empresaUtilizaAbrirMovimentacao == 'N'" [ngClass]="bibIcone.cadeadoFechado"></i>
            <btnAdicional *ngIf="movimentacao.status == 'S' && empresaUtilizaAbrirMovimentacao == 'S'" [icone]="bibIcone.cadeadoFechado" (btnAdicional)="abrirMovimentacao(movimentacao)" [ajuda]="bibDialogo.abrirMovimentacao"></btnAdicional>
          </td>
          <td class="text-right">{{ movimentacao.abreviacao }}</td>
          <td *ngIf="idOperacao == 9 || idOperacao == 10" class="text-right">{{ movimentacao.abreviacaoLojaDestino }}</td>
          <td *ngIf="!operacaoEmiteNota" class="text-center">{{ movimentacao.numero }}</td>
          <td [ngClass]="operacaoEmiteNota ? 'text-right' : ''">{{ movimentacao.data | data }}</td>
          <td *ngIf="operacaoEmiteNota" class="text-right">{{ movimentacao.documentoFiscal }}</td>
          <td class="limitar fonte-menor">
            <a href="#" (click)="ir(movimentacao.id, i, movimentacoes)">{{ operacaoInterna ? movimentacao.colaborador : movimentacao.parceiro }}</a>
          </td>
          <td><ajuda [ajuda]="operacaoInterna ? movimentacao.colaborador : movimentacao.clienteNomeFantasia"></ajuda></td>
          <td *ngIf="idOperacao != 4 && idOperacao != 5 && idOperacao != 9 && idOperacao != 10 && idOperacao != 13 && idOperacao != 14" class="limitar fonte-menor">{{ movimentacao.colaborador }}</td>
          <td *ngIf="idOperacao == 2 || idOperacao == 20" class="limitar fonte-menor">
            <span class="badge badge-subtle" [ngClass]="movimentacao.situacao == 1 ? 'badge-danger' : movimentacao.situacao == 2 ? 'badge-warning' : movimentacao.situacao == 3 ? 'badge-light' : movimentacao.situacao == 4 ? 'badge-primary' : movimentacao.situacao == 5 ? 'badge-success' : 'badge-dark'">{{ movimentacao.situacao == 1 ? bibDialogo.aberta.toUpperCase() : movimentacao.situacao == 2 ? bibDialogo.aguardandoFinanceiro : movimentacao.situacao == 3 ? bibDialogo.aguardandoEntrega : movimentacao.situacao == 4 ? bibDialogo.entregaParcial : movimentacao.situacao == 5 ? bibDialogo.concluida : bibDialogo.cancelada }}</span>
          </td>
          <td class="text-right" [ngClass]="{ 'l-20': movimentacao.idOperacao == 10 }">{{ movimentacao.valorTotal | monetario }}</td>
          <td><btnAdicional [icone]="movimentacao.status == 'S' || movimentacao.ativo != 'S' ? bibIcone.olho : bibIcone.editar" (btnAdicional)="ir(movimentacao.id, i, movimentacoes)"></btnAdicional></td>
          <td><btnAdicional *ngIf="movimentacao.ativo != 'N'" [icone]="bibIcone.lixo" (btnAdicional)="excluirMovimentacao(movimentacao.id, movimentacao.numero)"></btnAdicional></td>
          <td><btnAdicional *ngIf="movimentacao.idOperacao != 10" [icone]="bibIcone.clone" [ajuda]="bibDialogo.duplicar" (btnAdicional)="clonarMovimentacao(movimentacao.id, i, movimentacoes)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="visualizaTotalizadores && movimentacoes.length > 0">
      <tr *ngFor="let totalMovimentacao of totalMovimentacoes">
        <td [colSpan]="idOperacao == 1 || idOperacao == 7 || idOperacao == 9 || idOperacao == 10 || idOperacao == 11 || idOperacao == 12 ? 7 : idOperacao == 3 || idOperacao == 8 || idOperacao == 15 || idOperacao == 16 || idOperacao == 17 || idOperacao == 18 ? 7 : idOperacao == 2 || idOperacao == 20 ? 8 : 6"></td>
        <td class="text-right font-weight-bold">{{ totalMovimentacao.valorTotalizado | monetario }}</td>
        <td></td>
      </tr>
    </tfoot>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
