import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  selector: 'app-r1144',
  templateUrl: './r1144.component.html',
  styleUrls: ['./r1144.component.css'],
})
export class R1144Component extends RelatorioComponent {
  public financeiros: any[] = [];
  public financeirosDuplicados: any[] = [];
  public financeiroBaixas: any[] = [];
  public financeiroAgrupados: any[] = [];
  public numeroFinanceiros: number[] = [];
  public duplicando: boolean = false;
  public agrupando: boolean = false;
  public expandindoFinanceiro: boolean = false;
  public observacao: boolean = false;
  public filtros = filtros;
  public rotuloTipoDeTitulo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30).apelido;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      let relatorio: ReadonlyArray<any> = this.relatorioResultados;
      this.financeiros = relatorio.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.financeiroBaixas = relatorio.find((relatorioResultado) => relatorioResultado.dado == 'FinanceiroBaixa').lista;
      this.vincularFinanceiroBaixa();
    });
  }

  vincularFinanceiroBaixa(): void {
    this.financeiros.forEach((financeiro) => {
      financeiro.valorCalculado = 0;
      financeiro.financeiroBaixas = this.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.idFinanceiro == financeiro.id);
      financeiro.financeiroBaixas.forEach((financeiroBaixa) => {
        financeiro.valorCalculado += financeiroBaixa.valor;
        financeiro.juro += financeiroBaixa.juroTotal;
        financeiro.desconto += financeiroBaixa.descontoValor;
      })
    })
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    switch (relatorioAdicional.id) {
      case 146:
        this.duplicando = this.definirRegra(relatorioAdicional, 146, this.duplicando);
        this.duplicar();
        break;

      case 237:
        this.agrupando = this.definirRegra(relatorioAdicional, 237, this.agrupando);
        this.agruparFinanceiroParceiro();
        if (this.agrupando == false) {
          const relatorioAdicionalExpandirTitulo: RelatorioAdicional = this.relatorioAdicionais.find((relatorioAdicional) => relatorioAdicional.id == 266);
          relatorioAdicionalExpandirTitulo.marcado = 'N';
          this.definirRegraRelatorio(relatorioAdicionalExpandirTitulo);
        }
        break;

      case 266:
        this.expandindoFinanceiro = this.definirRegra(relatorioAdicional, 266, this.expandindoFinanceiro);
        if (this.expandindoFinanceiro) {
          const relatorioAdicionalAgrupar: RelatorioAdicional = this.relatorioAdicionais.find((relatorioAdicional) => relatorioAdicional.id == 237);
          relatorioAdicionalAgrupar.marcado = 'S';
          this.definirRegraRelatorio(relatorioAdicionalAgrupar);
        }
        break;

      case 267:
        this.observacao = this.definirRegra(relatorioAdicional, 267, this.observacao);
        break;
    }
  }

  duplicar(): void {
    this.financeirosDuplicados = [];
    if (this.duplicando) {
      for (const financeiro of this.financeiroAgrupados.length > 0 ? this.financeiroAgrupados : this.financeiros) {
        this.financeirosDuplicados.push(financeiro);
        this.financeirosDuplicados.push(financeiro);
      }
    }
  }

  agruparFinanceiroParceiro(): void {
    this.financeiroAgrupados = [];
    if (this.agrupando) {
      let idParceiro: number = 0;
      for (const financeiro of this.financeiros) {
        if (financeiro.idParceiro != idParceiro) {
          idParceiro = this.agruparAdicionar(financeiro);
        } else {
          this.agruparAcumular(financeiro);
        }
      }
      this.unificarTitulo();
    }
    this.duplicar();
  }

  agruparAdicionar(financeiro: any): number {
    const financeiroCopia: any = this.plainToClass(Object, financeiro);
    if (financeiroCopia.idMovimentacao == undefined) {
      financeiroCopia.numero = '';
    }
    this.financeiroAgrupados.push(financeiroCopia);
    this.adicionarFinanceiroAoAgrupado(financeiro);
    return financeiro.idParceiro;
  }

  agruparAcumular(financeiro: any): void {
    this.financeiroAgrupados[this.financeiroAgrupados.length - 1].valorCalculado += financeiro.valorCalculado;
    this.financeiroAgrupados[this.financeiroAgrupados.length - 1].juro += financeiro.juro;
    this.financeiroAgrupados[this.financeiroAgrupados.length - 1].desconto += financeiro.desconto;
    this.adicionarNumero(financeiro);
    this.adicionarFinanceiroAoAgrupado(financeiro);
    for (const financeiroBaixa of financeiro.financeiroBaixas) {
      this.financeiroAgrupados[this.financeiroAgrupados.length - 1].financeiroBaixas.push(this.plainToClass(Object, financeiroBaixa));
    }
  }

  adicionarNumero(financeiro: any): void {
    if (financeiro.idMovimentacao) {
      this.financeiroAgrupados[this.financeiroAgrupados.length - 1].idMovimentacao = financeiro.idMovimentacao;
      if (this.financeiroAgrupados[this.financeiroAgrupados.length - 1].numero != '') {
        this.financeiroAgrupados[this.financeiroAgrupados.length - 1].numero += ', ';
      }
      this.financeiroAgrupados[this.financeiroAgrupados.length - 1].numero += financeiro.numero.toString();
    }
  }

  unificarTitulo(): void {
    for (let financeiroAgrupado of this.financeiroAgrupados) {
      let financeiroBaixasAgrupados: any[] = [];
      financeiroAgrupado.financeiroBaixas.forEach((financeiroBaixa) => {
        financeiroBaixasAgrupados = this.adicionarOuAcumularTitulo(financeiroBaixasAgrupados, financeiroBaixa);
      });
      financeiroAgrupado.financeiroBaixas = financeiroBaixasAgrupados;
    }
  }

  adicionarOuAcumularTitulo(financeiroBaixasAgrupados: any[], financeiroBaixa: any): any[] {
    let financeiroBaixaTitulo: any = financeiroBaixasAgrupados.find((financeiroBaixaAgrupadoTitulo) => financeiroBaixaAgrupadoTitulo.tipoTitulo == financeiroBaixa.tipoTitulo);
    if (financeiroBaixaTitulo == null) {
      financeiroBaixasAgrupados.push(financeiroBaixa);
    } else {
      financeiroBaixaTitulo.valor += financeiroBaixa.valor;
    }
    return financeiroBaixasAgrupados;
  }

  adicionarFinanceiroAoAgrupado(financeiro: any): void {
    if (this.financeiroAgrupados[this.financeiroAgrupados.length - 1].financeiros == null) {
      this.financeiroAgrupados[this.financeiroAgrupados.length - 1].financeiros = [];
    }
    this.financeiroAgrupados[this.financeiroAgrupados.length - 1].financeiros.push(financeiro);
  }
}
