<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-40 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-30 clique" (click)="listar(add('destino'), this.utilSessao.posicao, this.paginacao)">{{ bibNomenclatura.escrever(bibNomenclatura.destino) }}</th>
        <th class="l-10 clique" (click)="listar(add('responsavel'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.responsavel }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let visita of visitas; let i = index">
        <tr>
          <td>{{ visita.data | data }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(visita.id, i, visitas)">{{ visita.parceiro }}</a>
          </td>
          <td>{{ visita.destino }}</td>
          <td class="limitar fonte-menor">{{ visita.responsavel }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(visita.id, i, visitas)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirVisita(visita.id, visita.parceiro)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
