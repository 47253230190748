import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { R1004Component } from '../r1004.component';

@Component({
  selector: '<tr>[r1004itens]</tr>',
  templateUrl: './r1004itens.component.html',
})
export class R1004ItensComponent extends R1004Component {
  @Input() estoqueHistoricoItens: any;
  @Input() empresaRegraCasasDecimaisEntrada: number;
  @Input() usaLote: boolean;
  @Input() usaLocal: boolean;

  public bibDialogo = bibDialogo;
}
