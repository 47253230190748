<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-60 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-30 clique" (click)="listar(add('nomeModuloPai'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.pai }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let moduloEmpresa of moduloEmpresas; let i = index">
        <tr [ngClass]="{ tachado: moduloEmpresa.ativo != 'S' }">
          <td class="limitar">
            <a href="#" (click)="ir(moduloEmpresa.id, i, moduloEmpresas)">{{ moduloEmpresa.nome.split(' ').join('&nbsp;') }}</a>
          </td>
          <td class="limitar">{{ moduloEmpresa.nomeModuloPai }}</td>
          <td>
            <btnAdicional [id]="'editar0'" [icone]="bibIcone.editar" (btnAdicional)="ir(moduloEmpresa.id, i, moduloEmpresas)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [id]="'excluir0'" [icone]="bibIcone.lixo" (btnAdicional)="excluir(moduloEmpresa.id, moduloEmpresas, moduloEmpresa.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
