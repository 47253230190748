export class CamposExtras {
  public copiasEmail: string[] = [];

  constructor(copiasEmail: string) {
    if (copiasEmail) {
      let emails = copiasEmail.split(';');
      emails.forEach((email) => {
        this.copiasEmail.push(email ? email.replace(';', '').replace(' ', '') : null);
      });
    }
  }
}
