import { Type } from 'class-transformer';
import { ConferenciaFinanceiro } from './conferenciaFinanceiro';
import { ConferenciaTitulo } from './conferenciaTitulo';

export class Conferencia {
  @Type(() => Date)
  public data: Date;
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public conferenciaFinanceiros: ConferenciaFinanceiro[] = [];
  public conferenciaTitulos: ConferenciaTitulo[] = [];
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public prioridade: number;
  public tipo: string = 'R';
  public valor: number = 0;
  public valorAcrescimo: number = 0;
  public valorDesconto: number = 0;
  public origem: string;
}
