<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.resumo + ' ' + menuColaboradorApelido }}<ajuda [ajuda]="bibDialogo.movimentacoesGeraramFinanceiro" [posicao]="bibDialogo.esquerda"></ajuda></h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r">{{ menuColaboradorApelido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroDia }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucroTotalMes }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualLucroMes }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoVendedor of movimentacaoVendedores">
      <tr>
        <td>{{ movimentacaoVendedor.loja }}</td>
        <td>{{ movimentacaoVendedor.vendedorNumero + ' - ' + movimentacaoVendedor.vendedor }}</td>
        <td class="text-right">{{ (movimentacaoVendedor.percentualDia | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoVendedor.valorTotalDia | monetario }}</td>
        <td class="text-right">{{ movimentacaoVendedor.valorLucroDia | monetario }}</td>
        <td class="text-right">{{ (movimentacaoVendedor.percentualLucroDia | monetario) + '%' }}</td>
        <td class="text-right">{{ (movimentacaoVendedor.percentualMes | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoVendedor.valorTotalMes | monetario }}</td>
        <td class="text-right">{{ movimentacaoVendedor.valorLucroMes | monetario }}</td>
        <td class="text-right">{{ (movimentacaoVendedor.percentualLucroMes | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoVendedores.length > 0">
    <tr>
      <td colspan="3"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoVendedores[0].totalValorDia | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoVendedores[0].totalLucroDia | monetario }}</strong>
      </td>
      <td colspan="2"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoVendedores[0].totalValorMes | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoVendedores[0].totalLucroMes | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
