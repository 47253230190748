import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Empresa } from 'src/app/modelo/empresa';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'titulo',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.css'],
})
export class TituloComponent {
  @Input() ajuda: string;
  @Input() atencao: string;
  @Input() compacto: boolean;
  @Input() icone: string;
  @Input() imagem: string;
  @Input() obrigatorio: boolean = false;
  @Input() preenchido: boolean = false;
  @Input() titulo: string;
  @Input() desabilitado: boolean = false;
  @Output() iconeEmt = new EventEmitter<boolean>();

  constructor() {
    const empresa: Empresa = new UtilSessao().getEmpresa();
    this.compacto = empresa.compacto == 'S' ? true : false;
  }

  clicar(): void {
    if (!this.desabilitado) {
      event.preventDefault();
      this.iconeEmt.emit(true);
    }
  }

  getClasse(): string {
    return `btn  ${this.desabilitado ? 'disabled transparente' : ''}`;
  }
}
