<layout [id]="lancamentoContabil.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <data class="col-sm" [rotulo]="bibDialogo.data" [campo]="lancamentoContabil.data" (alteracao)="data = $event" [obrigatorio]="true" [desabilitado]="parametro == 'roteiro' ? true : cabecalhoBloqueado" [foco]="parametro != 'roteiro' ? true : null"></data>
    <lista class="col-sm" [id]="'tipoLancamentoContabil'" [idMenu]="permissaoTipoLancamentoContabil && permissaoTipoLancamentoContabil.inserir == 'S' ? menuTipoLancamentoContabil.idMenu : null" [rotulo]="menuTipoLancamentoContabil.apelido" [campo]="lancamentoContabil.idTipoLancamentoContabil" (alteracao)="lancamentoContabil.idTipoLancamentoContabil = $event.id" [lista]="tipoLancamentoContabeis" [obrigatorio]="true" [icone]="bibIcone.atualizar" (btnAdicional)="listarTipoLancamentoContabil()" [desabilitado]="(lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4) || cabecalhoBloqueado"  [foco]="parametro == 'roteiro' ? true : null"></lista>
    <decimal class="col-sm" [id]="'valor'" [rotulo]="bibDialogo.valor" [campo]="lancamentoContabil.valorReferencia" (alteracao)="lancamentoContabil.valorReferencia = $event" [obrigatorio]="true" [desabilitado]="(lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4) || cabecalhoBloqueado"></decimal>
    <!-- <interruptor *ngIf="lancamentoContabil.id" [rotulo]="bibDialogo.ativo" [campo]="lancamentoContabil.ativo" (alteracao)="lancamentoContabil.ativo = $event" [desabilitado]="!temPermissaoInativar || cabecalhoBloqueado"></interruptor> -->
  </div>
  <div *ngIf="parametro != 'roteiro'">
    <div class="row">
      <busca class="col-sm-12" [id]="'contaDebito'" [rotulo]="bibDialogo.contaDebito" [campo]="lancamentoContabil.contaDebito" (alteracao)="setContaContabilDebito($event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [obrigatorio]="true" [desabilitado]="cabecalhoBloqueado"></busca>
      <busca *ngIf="lancamentoContabil.correntistaDebito == 2" class="col-sm-12" [id]="'parceiroDebito'" [rotulo]="bibDialogo.correntista" [campo]="lancamentoContabil.parceiroDebito" (alteracao)="lancamentoContabil.idParceiroDebito = $event.id" [servico]="bibServico.parceiro" [minimoCaracterBusca]="5" [obrigatorio]="true" [desabilitado]="cabecalhoBloqueado"></busca>
      <busca *ngIf="[3,4].includes(lancamentoContabil.correntistaDebito)" class="col-sm-12" [id]="'contaDebito'" [rotulo]="bibDialogo.correntista" [campo]="lancamentoContabil.contaBancariaDebito" (alteracao)="lancamentoContabil.idContaBancariaDebito = $event.id" [servico]="lancamentoContabil.correntistaCredito == 3 ? bibServico.contaBancaria : bibServico.contaCaixa" [obrigatorio]="true" [desabilitado]="cabecalhoBloqueado"></busca>    
    </div>
    <div class="row">
      <busca class="col-sm-12" [id]="'contaCredito'" [rotulo]="bibDialogo.contaCredito" [campo]="lancamentoContabil.contaCredito" (alteracao)="setContaContabilCredito($event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [obrigatorio]="true" [obrigatorio]="true" [desabilitado]="cabecalhoBloqueado"></busca>
      <busca *ngIf="lancamentoContabil.correntistaCredito == 2" class="col-sm-12" [id]="'parceiroCredito'" [rotulo]="bibDialogo.correntista" [campo]="lancamentoContabil.parceiroCredito" (alteracao)="lancamentoContabil.idParceiroCredito = $event.id" [servico]="bibServico.parceiro" [minimoCaracterBusca]="5" [obrigatorio]="true" [desabilitado]="cabecalhoBloqueado"></busca>
      <busca *ngIf="[3,4].includes(lancamentoContabil.correntistaCredito)" class="col-sm-12" [id]="'contaCredito'" [rotulo]="bibDialogo.correntista" [campo]="lancamentoContabil.contaBancariaCredito" (alteracao)="lancamentoContabil.idContaBancariaCredito = $event.id" [servico]="lancamentoContabil.correntistaCredito == 3 ? bibServico.contaBancaria : bibServico.contaCaixa" [obrigatorio]="true" [desabilitado]="cabecalhoBloqueado"></busca>    
    </div>
  </div>   
  <div class="row">
    <pesquisaTextoBusca *ngIf="lancamentoContabil.origem == 1" class="col-sm-12" [id]="'historico'" [idMenu]="permissaoHistorico && permissaoHistorico.inserir == 'S' ? menuHistorico.idMenu : null" [rotulo]="menuHistorico.apelido" [campo]="lancamentoContabil.historico" (alteracao)="lancamentoContabil.historico = $event" [obrigatorio]="true" [icone]="bibIcone.lupa" [criterios]="criterioRotuloTodos" [servico]="bibServico.historico" [minimoCaracterBusca]="2" [desabilitado]="cabecalhoBloqueado"></pesquisaTextoBusca>
  </div>  
  <div class="row">
    <lista class="col-sm" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="lancamentoContabil.idLoja" (alteracao)="lancamentoContabil.idLoja = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [icone]="bibIcone.atualizar" [desabilitado]="(lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4) || cabecalhoBloqueado"></lista>
  </div>
  <div *ngIf="parametro == 'roteiro'" class="row">
    <busca class="col-sm" [id]="'roteiroContabil'" [idMenu]="permissaoRoteiroContabil && permissaoRoteiroContabil.inserir == 'S' ? menuRoteiroContabil.idMenu : null" [rotulo]="menuRoteiroContabil.apelido" [campo]="lancamentoContabil.roteiroContabil" (alteracao)="lancamentoContabil.idRoteiroContabil = $event.id" [servico]="bibServico.roteiroContabil" [criterios]="criterioUsuarioAcessoCategoria" [desabilitado]="lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4" [buscarPorNumero]="true"></busca>  </div>
  <div *ngIf="parametro == 'roteiro'" class="row align-items-center">
    <titulo class="col-sm" [titulo]="bibDialogo.lancamentos"></titulo>
    <botao *ngIf="parametro == 'roteiro'" class="text-right" [compacto]="compacto" [legenda]="bibDialogo.gerarLancamentos" (botaoEmt)="gerarLancamentoContabil()" [desabilitado]="lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4" [desabilitado]="parametro == 'roteiro' && lancamentoContabil.idRoteiroContabil && lancamentoContabil.id"></botao>
  </div>
  <table class="table table-responsive-sm" *ngIf="parametro == 'roteiro' && lancamentoContabeis.length > 0">
    <thead>
      <tr>
        <th class="l-45">{{ bibDialogo.conta }}</th>
        <th class="l-35">{{ bibDialogo.correntista }}</th>
        <th class="l-5">{{ bibDialogo.dc }}</th>
        <th class="l-10">{{ bibDialogo.valor }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let lancamentoContabil of lancamentoContabeis; let i = index">
        <tr>
          <td>
            <busca *ngIf="(!lancamentoContabil.id || edicaoLancamentoContabilIds.includes(lancamentoContabil.id)) && parametro != 'roteiro'" [id]="'contaDebito'" [campo]="lancamentoContabil.contaContabil" (alteracao)="setContaDebito(lancamentoContabil, $event)" [servico]="bibServico.contaContabil" [criterios]="criterios" [obrigatorio]="true" [compacto]="compacto"></busca>
            {{ (lancamentoContabil.id && edicaoLancamentoContabilIds.includes(lancamentoContabil.id) == false) || parametro == 'roteiro' ? lancamentoContabil.contaContabil : '' }}
          </td>
          <td>
            <busca *ngIf="(!lancamentoContabil.id || edicaoLancamentoContabilIds.includes(lancamentoContabil.id)) && lancamentoContabil.ehCorrentistaParceiro && parametro != 'roteiro'" [id]="'parceiro'" [campo]="lancamentoContabil.correntista" (alteracao)="setParceiro(lancamentoContabil, $event)" [servico]="bibServico.parceiro" [minimoCaracterBusca]="5" [compacto]="compacto"></busca>
            <busca *ngIf="(!lancamentoContabil.id || edicaoLancamentoContabilIds.includes(lancamentoContabil.id)) && lancamentoContabil.ehCorrentistaConta && parametro != 'roteiro'" [id]="'conta'" [campo]="lancamentoContabil.correntista" (alteracao)="setContaBancaria(lancamentoContabil, $event)" [servico]="bibServico.conta" [compacto]="compacto"></busca>
            {{ lancamentoContabil.id && edicaoLancamentoContabilIds.includes(lancamentoContabil.id) == false ? (lancamentoContabil.parceiro != null ? lancamentoContabil.parceiro : lancamentoContabil.contaBancaria) : '' }}
          </td>
          <td>
            <atencao [atencao]="lancamentoContabil.debitoCredito == 1 ? ' D ' : ' C '" [tipo]="lancamentoContabil.debitoCredito == 1 ? null : bibPropriedade.atencao.cuidado" [ajuda]="lancamentoContabil.debitoCredito == 1 ? bibDialogo.debito : bibDialogo.credito"></atencao>
          </td>
          <td>
            {{ lancamentoContabil.valor | monetario }}<atencao *ngIf="lancamentoContabil.percentual != 100" class="p-2" [atencao]="(lancamentoContabil.percentual | monetario) + '%'"></atencao>
          </td>
          <td>
            <btnAdicional *ngIf="parametro != 'roteiro'" [icone]="bibIcone.editar" (btnAdicional)="editar(lancamentoContabil)" [desabilitado]="lancamentoContabil.origem != 1 && lancamentoContabil.origem != 4"></btnAdicional>
          </td>
          <td><btnAdicional [icone]="lancamentoContabil.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="lancamentoContabil.expandido = !lancamentoContabil.expandido"></btnAdicional></td>
        </tr>
        <tr *ngIf="lancamentoContabil.expandido">
          <td class="limitar" colspan="7"><apresentacao [rotulo]="bibDialogo.historico" [campo]="lancamentoContabil.historico"></apresentacao></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirLancamentoContabil()" [desabilitado]="bloquearPermissao(lancamentoContabil.id)"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<div *ngIf="parametro == 'roteiro'">
  <totalizador [alinhamento]="bibPropriedade.totalizador.esquerda" [atencaoTotalizador]="bibDialogo.debito" [valorTotalizador]="totalDebito | monetario" [tipoTotalizador]="bibPropriedade.cor.verde"></totalizador>
  <totalizador [alinhamento]="bibPropriedade.totalizador.centro" [atencaoTotalizador]="bibDialogo.credito" [valorTotalizador]="totalCredito | monetario" [tipoTotalizador]="bibPropriedade.cor.vermelho"></totalizador>
  <totalizador [alinhamento]="bibPropriedade.totalizador.direita" [atencaoTotalizador]="bibDialogo.diferenca" [valorTotalizador]="diferenca | monetario" [tipoTotalizador]="bibPropriedade.cor.cinzaEscuro"></totalizador>
</div>
<auditoria *ngIf="lancamentoContabil.id" [idUsuarioInclusao]="lancamentoContabil.idUsuarioInclusao" [dataHoraInclusao]="lancamentoContabil.dataHoraInclusao" [idUsuarioAlteracao]="lancamentoContabil.idUsuarioAlteracao" [dataHoraAlteracao]="lancamentoContabil.dataHoraAlteracao"></auditoria>
