import { Component, HostListener } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Financeiro } from 'src/app/modelo/financeiro';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';
import { ContaCorrenteFrmComponent } from './frm/contaCorrenteFrm.component';

@Component({
  selector: 'contaCorrente',
  templateUrl: './contaCorrente.component.html',
  styleUrls: ['./contaCorrente.component.css'],
})
export class ContaCorrenteComponent extends PaginaComponent {
  private coluna: number = 2;
  private idTipoTituloPadrao: number;
  private linha: number = 0;
  private usuario: Usuario = this.utilSessao.getUsuario();
  public adicionando: boolean = false;
  public componente: any;
  public datas: string[] = [];
  public filtros: Filtro[] = filtros;
  public financeiroMostrar: Financeiro[] = [];
  public financeiros: Financeiro[] = [];
  public formularioAberto: boolean = false;
  public indice: string = 'l0c2';
  public limitePaginacao: number[] = [0, 11];
  public listaUsuarioContas: any[];
  public lojas: Loja[];
  public posicaoPaginacao: number = 0;

  public categorias: any[] = [
    { id: 'M', nome: bibDialogo.mensal.toUpperCase() },
    { id: 'E', nome: bibDialogo.extra.toUpperCase() },
    { id: 'P', nome: bibDialogo.planejado.toUpperCase() },
  ];
  public tipos: any[] = [
    { id: 'R', nome: bibDialogo.receita.toUpperCase() },
    { id: 'D', nome: bibDialogo.despesa.toUpperCase() },
  ];

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (!this.adicionando) {
      switch (event.keyCode) {
        case this.bibAtalho.setaEsquerda:
          if (!this.formularioAberto) {
            event.preventDefault();
            if (this.coluna == 1) {
              this.coluna = 12;
              this.linha -= 1;
            } else if (this.coluna > 1) this.coluna -= 1;
            this.calcularIndice(this.linha, this.coluna);
          }
          break;
        case this.bibAtalho.setaCima:
          if (!this.formularioAberto) {
            event.preventDefault();
            this.linha = this.linha != 0 ? this.linha - 1 : this.linha;
            this.calcularIndice(this.linha, this.coluna);
          }
          break;
        case this.bibAtalho.setaDireita:
          if (!this.formularioAberto) {
            event.preventDefault();
            if (this.coluna == 12) {
              this.coluna = 1;
              this.linha += 1;
            } else if (this.coluna < 12) this.coluna += 1;
            this.calcularIndice(this.linha, this.coluna);
          }
          break;
        case this.bibAtalho.setaBaixo:
          if (!this.formularioAberto) {
            event.preventDefault();
            this.linha = this.linha != this.financeiros.length - 1 ? this.linha + 1 : this.linha;
            this.calcularIndice(this.linha, this.coluna);
          }
          break;
        case this.bibAtalho.enter:
          if (!this.formularioAberto) {
            this.abrirFormulario(this.linha, this.coluna);
          }
          break;
        case this.bibAtalho.tab:
          if (this.coluna == 11) {
            this.coluna = 1;
            this.linha += 1;
          } else if (this.coluna < 11) this.coluna += 1;
          break;

        case this.bibAtalho.espaco:
          event.preventDefault();
          if (this.coluna == 12) {
            this.setPago(this.financeiros[this.linha], this.financeiros[this.linha].financeiroBaixas.length > 0 ? 'N' : 'S');
          }
          break;
        case this.bibAtalho.novo:
          if (this.temPermissaoInserir) {
            this.abrirModal();
          }
          break;
      }
    }
  }

  ngOnInit(): void {
    this.listarUsuarioContas();
    this.lojas = this.utilSessao.getLojas();
    this.setTipoTituloPadrao();
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.financeiro) {
        if (identificacao.conteudo.id) {
          const indiceFinanceiro: number = this.financeiros.findIndex((financeiro) => financeiro.id == identificacao.conteudo.id);
          this.financeiros = this.financeiros.map((financeiro, indice) => (indice == indiceFinanceiro ? (financeiro = this.plainToClass(Financeiro, identificacao.conteudo)) : (financeiro = financeiro)));
        } else {
          this.financeiros.push(this.plainToClass(Financeiro, identificacao.conteudo));
        }
        this.financeiros.sort((a, b) => (a['dataNegociacao'] == b['dataNegociacao'] ? 0 : a['dataNegociacao'] < b['dataNegociacao'] ? 1 : -1));
        this.popularFinanceiroMostrar();
      }
      this.adicionando = false;
    });
  }

  setTipoTituloPadrao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('PADRAO_CONTA_CORRENTE', 'S')), this.bibServico.tipoTitulo).subscribe((res) => {
      const tipoTitulo: TipoTitulo = this.plainToClass(TipoTitulo, res) as any;
      this.idTipoTituloPadrao = tipoTitulo[0].id;
    });
  }

  listar(criterios: Criterio[]): void {
    event.preventDefault();
    this.financeiros = [];
    this.financeiroMostrar = [];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiro).subscribe((res) => {
      this.financeiros = this.plainToClass(Financeiro, res) as any;
      this.financeiros.sort((a, b) => (a['dataNegociacao'] == b['dataNegociacao'] ? 0 : a['dataNegociacao'] < b['dataNegociacao'] ? 1 : -1));
      this.popularDatas();
      this.popularFinanceiroMostrar();
    });
  }

  popularDatas(): void {
    this.datas = [];
    this.financeiros.forEach((financeiro) => {
      let mes: string = (financeiro.dataNegociacao.getMonth() + 1).toString().padStart(2, '0');
      let ano: string = financeiro.dataNegociacao.getFullYear().toString();
      ano = ano.substring(2, ano.length);
      if (!this.datas.includes(mes + '/' + ano)) {
        this.datas.push(mes + '/' + ano);
      }
    });
    this.datas.sort((a, b) => this.retornaObjetoDataDeString(a).getTime() - this.retornaObjetoDataDeString(b).getTime());
  }

  popularFinanceiroMostrar(data: string = null, indice: number = null): void {
    this.posicaoPaginacao = indice;
    data = data ? data : this.getMesInicial();
    let dataObjeto: Date = this.retornaObjetoDataDeString(data ? data : this.datas[0]);
    this.financeiroMostrar = this.financeiros.filter((financeiro) => {
      if (financeiro.dataNegociacao.getMonth() == dataObjeto.getMonth() && financeiro.dataNegociacao.getFullYear() == dataObjeto.getFullYear()) {
        return financeiro;
      }
    });
    if (!indice && indice != 0) {
      this.posicaoPaginacao = this.datas.findIndex((dataBusca) => dataBusca == data);
      this.posicaoPaginacao = this.posicaoPaginacao == -1 ? 0 : this.posicaoPaginacao;
      this.limitePaginacao[1] = this.posicaoPaginacao;
      this.limitePaginacao[0] = this.posicaoPaginacao - 11 < 0 ? 0 : this.posicaoPaginacao - 11;
    }
  }

  getMesInicial(): string {
    let mes: string = (new Date().getMonth() + 1).toString().padStart(2, '0');
    let ano: string = new Date().getFullYear().toString();
    ano = ano.substring(2, ano.length);
    if (this.datas.includes(mes + '/' + ano)) {
      return mes + '/' + ano;
    }
    return this.datas[this.datas.length];
  }

  retornaObjetoDataDeString(data: string): Date {
    let dataEmArray: string[] = data.split('/');
    dataEmArray.unshift(data.split('/')[0]);
    return new Date(dataEmArray.join('/'));
  }

  listarUsuarioContas(): void {
    let contaUsuarios = this.utilSessao.getUsuarioContas();
    for (let contaUsuario of contaUsuarios) {
      contaUsuario.id = contaUsuario.idConta;
      contaUsuario.nome = contaUsuario.conta;
    }
    this.listaUsuarioContas = contaUsuarios;
  }

  abrirFormulario(linha: number, coluna: number): void {
    this.calcularIndice(linha, coluna);
    if (this.temPermissaoEdicao && this.financeiros.length && this.financeiros[this.linha].valorRestante != 0) {
      this.financeiros[this.linha].alterado = true;
      this.formularioAberto = true;
    }
  }

  fecharFormulario(foco: boolean): void {
    this.formularioAberto = foco;
    setTimeout(() => {
      this.setFoco();
    }, 100);
  }

  calcularIndice(linha: number, coluna: number): void {
    this.linha = linha;
    this.coluna = coluna;
    this.indice = 'l' + linha + 'c' + coluna;
    this.setFoco();
  }

  setFoco(): void {
    let elemento = document.getElementById(this.indice);
    if (elemento != undefined) elemento.focus();
  }

  getNomeTipo(idTipo: string): string {
    let nomeTipo = this.tipos.find((tipo) => tipo.id == idTipo) != undefined ? this.tipos.find((tipo) => tipo.id == idTipo).nome : '';
    if (nomeTipo == '') return '';
    return nomeTipo == bibDialogo.receita ? 'R' : 'D';
  }

  getNomeCategoria(idCategoria: string): string {
    let nomeCategoria = this.categorias.find((categoria) => categoria.id == idCategoria) != undefined ? this.categorias.find((categoria) => categoria.id == idCategoria).nome : '';
    return nomeCategoria;
  }

  getNomeConta(idConta: string): string {
    let conta = this.listaUsuarioContas.find((conta) => conta.id == idConta);
    return conta != undefined ? conta.nome : '';
  }

  getNomeLoja(idLoja: number): string {
    let loja = this.lojas.find((loja) => loja.id == idLoja);
    return loja != undefined ? loja.abreviacao : '';
  }

  setPago(financeiro: Financeiro, pago: string): void {
    if (pago == 'N') {
      financeiro.financeiroBaixas = [];
    } else {
      const financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa(financeiro.valorRestante, financeiro.id, financeiro.idConta, this.idTipoTituloPadrao, this.usuario.idEmpresa, this.usuario.id, this.usuario.id, 'F', null, financeiro.idLoja, financeiro.desconto, financeiro.juroAutomatico);
      financeiro.financeiroBaixas.push(financeiroBaixa);
    }
    if (financeiro.valorRestante > 0) financeiro.alterado = true;
  }

  setValor(financeiro: Financeiro, valor: number, tipo: string): void {
    let historicoValorFinal: number = financeiro.valorFinal;
    let historicoValorRestante: number = financeiro.valorRestante;
    financeiro.valorFinal = 0;
    financeiro[tipo] = valor == Number('') ? 0 : valor;
    if (financeiro.valor) {
      financeiro.valorFinal += Number(financeiro.valor);
    }
    if (financeiro.multa) {
      financeiro.valorFinal += Number(financeiro.multa);
    }
    if (financeiro.juro) {
      financeiro.valorFinal += Number(financeiro.juro);
    }
    if (financeiro.desconto) {
      if (Number(financeiro.desconto) <= historicoValorFinal) {
        financeiro.valorFinal -= Number(financeiro.desconto);
      }
    }
    financeiro.valorFinal += financeiro.juroAutomatico;
    financeiro.valorRestante = financeiro.valorFinal - financeiro.valorBaixa;
    if (financeiro.valorRestante < 0 || financeiro.valorFinal < 0) {
      financeiro.valorFinal = historicoValorFinal;
      financeiro.valorRestante = historicoValorRestante;
    }
  }

  setValorFinal(financeiro: Financeiro, valor: number): void {
    if (financeiro.valorFinalOriginal == null) financeiro.valorFinalOriginal = financeiro.valorFinal;
    if (financeiro.valorDescontoOriginal == null) financeiro.valorDescontoOriginal = financeiro.desconto;
    if (financeiro.valorMultaOriginal == null) financeiro.valorMultaOriginal = financeiro.multa;
    financeiro.valorFinal = valor;
    if (Math.sign(financeiro.valorFinal - financeiro.valorFinalOriginal) == -1) {
      financeiro.desconto = financeiro.valorDescontoOriginal;
      financeiro.desconto += (financeiro.valorFinal - financeiro.valorFinalOriginal) * -1;
    } else if (Math.sign(financeiro.valorFinal - financeiro.valorFinalOriginal) == 1) {
      financeiro.multa = financeiro.valorMultaOriginal;
      financeiro.multa += financeiro.valorFinal - financeiro.valorFinalOriginal;
    } else {
      financeiro.multa = financeiro.valorMultaOriginal;
      financeiro.desconto = financeiro.valorDescontoOriginal;
    }
  }

  persistirFinanceiros(): void {
    const financeirosSalvar: Financeiro[] = this.financeiros.filter((financeiro) => financeiro.alterado == true);
    if (this.ehValido(financeirosSalvar)) {
      this.configurarFinanceiros(financeirosSalvar);
      this.persistir(new Transporte(financeirosSalvar), this.bibServico.financeiro, false ? new Financeiro() : null).subscribe((res) => {
        if (!this.utilSessao.falha) {
          financeirosSalvar.forEach((financeiro) => {
            if (financeiro.financeiroBaixas.length > 0) financeiro.valorRestante = 0;
            financeiro.alterado = false;
          });
        }
      });
    }
  }

  configurarFinanceiros(financeiros: Financeiro[]): void {
    financeiros.forEach((financeiro) => {
      financeiro.idTipoTitulo = financeiro.idTipoTitulo ? financeiro.idTipoTitulo : this.idTipoTituloPadrao;
      financeiro.usaGeraFinanceiroBaixaPro = true;
      financeiro.financeiroBaixas.forEach((financeiroBaixa) => (financeiroBaixa.idConta = !financeiroBaixa.idConta && !financeiroBaixa.id ? financeiro.idConta : financeiroBaixa.idConta));
    });
  }

  ehValido(financeiros: Financeiro[]): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorioESelecionado(financeiros);
    const ehValidoIdTipoTituloPadrao: boolean = this.ehValidoIdTipoTituloPadrao();
    return ehValidoObrigatorio && ehValidoIdTipoTituloPadrao;
  }

  ehValidoObrigatorioESelecionado(financeiros: Financeiro[]): boolean {
    let validacao: boolean = true;
    if (financeiros.length > 0) {
      financeiros.forEach((financeiro) => {
        if (!financeiro.dataNegociacao || !financeiro.dataVencimento || !financeiro.parcela || !financeiro.idLoja || !financeiro.idParceiro || !financeiro.idConta) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorioFinanceiro));
          validacao = false;
        }
      });
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumFinanceiroAlterado));
      validacao = false;
    }
    return validacao;
  }

  ehValidoIdTipoTituloPadrao(): boolean {
    if (this.idTipoTituloPadrao) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.definaTipoTituloPadrao));
    return false;
  }

  selecionaNomeMes(mes: number): string {
    let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return meses[mes];
  }

  abrirModal(financeiro: Financeiro = null): void {
    this.adicionando = true;
    this.utilSessao.setIdentificacao(new Identificacao('tipos', this.tipos));
    this.utilSessao.setIdentificacao(new Identificacao('categorias', this.categorias));
    this.utilSessao.setIdentificacao(new Identificacao('financeiro', financeiro));
    this.componente = ContaCorrenteFrmComponent;
  }

  voltar(): void {
    event.preventDefault();
    this.posicaoPaginacao = this.posicaoPaginacao > 0 ? this.posicaoPaginacao - 1 : this.posicaoPaginacao;
    if (this.posicaoPaginacao > 0) {
      this.limitePaginacao[0]--;
      this.limitePaginacao[1]--;
    }
    this.popularFinanceiroMostrar(this.datas[this.posicaoPaginacao], this.posicaoPaginacao);
  }

  avancar(): void {
    event.preventDefault();
    this.posicaoPaginacao = this.posicaoPaginacao + 1 < this.datas.length ? this.posicaoPaginacao + 1 : this.posicaoPaginacao;
    if (this.posicaoPaginacao + 1 < this.datas.length) {
      this.limitePaginacao[0]++;
      this.limitePaginacao[1]++;
    }
    this.popularFinanceiroMostrar(this.datas[this.posicaoPaginacao], this.posicaoPaginacao);
  }

  mostrarTodosFinanceiros(): void {
    event.preventDefault();
    this.financeiroMostrar = this.financeiros;
    this.posicaoPaginacao = null;
    this.popularDatas();
  }

  fecharModal(): void {
    this.componente = null;
    this.adicionando = false;
  }
}
