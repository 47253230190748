<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(financeiros, $event)" (campoGrupoEmt)="agrupar(financeiros, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <tr>
        <th class="r-0-5 clique" (click)="ordenar(financeiros, 'loja')">{{ bibDialogo.loja }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'numero')">{{ bibDialogo.numero }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'numeroFiscal')">{{ bibDialogo.numeroFiscal }}</th>
        <th *ngIf="usaDataVencimento" class="r-1 text-right clique" (click)="ordenar(financeiros, 'dataVencimentoOrdenacao')">{{ bibDialogo.dataVencimento }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'dataBaixaOrdenacao')">
          {{ bibDialogo.data }} <br />
          {{ bibDialogo.pagamento }}
        </th>
        <th *ngIf="this.maisInformacoes == false" class="c-1-2 text-right clique" (click)="ordenar(financeiros, 'diaAtraso')">
          {{ bibDialogo.dia }} <br />
          {{ bibDialogo.atraso }}
        </th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'parcela')">{{ bibDialogo.parcela }}</th>
        <th class="c-0-3"></th>
        <th class="r clique" (click)="ordenar(financeiros, 'parceiro')">{{ bibDialogo.fornecedor }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'desconto')">{{ bibDialogo.descontoAbreviacao }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'juro')">{{ bibDialogo.juro }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'valorPagamento')">{{ bibDialogo.valor }}</th>
        <th *ngIf="this.maisInformacoes" class="r-2">{{ bibDialogo.contaContabil }}</th>
        <th *ngIf="this.maisInformacoes" class="r-2">{{ rotuloContaCentroResultado }}</th>           
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ financeiro.loja }}</td>
          <td class="text-right">{{ financeiro.numero }}</td>
          <td class="text-right">{{ financeiro.numeroFiscal }}</td>
          <td *ngIf="usaDataVencimento" class="text-right">{{ financeiro.dataVencimento | data }}</td>
          <td class="text-right">{{ financeiro.dataBaixa | data }}</td>
          <td *ngIf="this.maisInformacoes == false" [ngClass]="financeiro.diaAtraso > 0 ? 'atraso' : ''" class="text-right">{{ financeiro.diaAtraso }}</td>
          <td class="text-right">{{ financeiro.parcela }}</td>
          <td></td>
          <td>{{ financeiro.parceiro }}</td>
          <td class="text-right">{{ financeiro.desconto | monetario }}</td>
          <td class="text-right">{{ financeiro.juro | monetario }}</td>
          <td class="text-right">{{ financeiro.valorPagamento | monetario }}</td>
          <td *ngIf="this.maisInformacoes" class="limitar">{{ financeiro.contaContabil }}</td>
          <td *ngIf="this.maisInformacoes" class="limitar">{{ financeiro.centroResultado }}</td>          
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="financeiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
