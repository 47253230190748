<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-40 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ menuGrupo.apelido }}</th>
        <th class="l-5 clique" (click)="listar(add('bloqueadoBalanco'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.bloquear + ' ' + bibDialogo.estoque.toLowerCase() }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let grupo of grupos; let i = index">
        <tr>
          <td class="limitar">
            <a *ngIf="grupo.pai == 'N'">{{ grupo.nome.split(' ').join('&nbsp;') }}</a>
            <strong *ngIf="grupo.pai == 'S'">{{ grupo.nome.split(' ').join('&nbsp;') }}</strong>
          </td>
          <td><interruptor *ngIf="grupo.pai == 'N'" [id]="bibDialogo.grupo + i" [campo]="grupo.bloqueadoBalanco" (alteracao)="setBloqueiaEstoque(grupo, $event)"></interruptor></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <div class="row">
    <atencao *ngIf="this.grupos.length == 0" [atencao]="atencao" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>      
</layout>
<botao *ngIf="grupos.length > 0" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGrupo()" [desabilitado]="temPermissaoInserir ? false : true"></botao>
<bloqueado [bloqueado]="temPermissaoInserir == false"></bloqueado>
