<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" (campoOrdemEmt)="ordenar(estoques, $event)" (campoGrupoEmt)="agrupar(estoques, $event)" (filtroEmt)="listarRelatorio($event)">
    <div *ngIf="estoques.length > 0">
        <tr class="sem-borda">
          <td [colSpan]="10" class="r">
            <strong>{{ bibDialogo.totalizadores }}</strong>
          </td>
        </tr>
        <tr>
          <td [colSpan]="10" class="r">
            <strong>{{ bibDialogo.quantidadeDisponivel + ' x ' + bibNomenclatura.escrever(bibNomenclatura.custoReposicao) + ': ' }}</strong> {{ totalCustoReposicao | monetario }}
          </td>
        </tr>
        <tr>
          <td [colSpan]="10" class="r">
            <strong>{{ bibDialogo.quantidadeDisponivel + ' x ' + bibNomenclatura.escrever(bibNomenclatura.custoGerencial) + ': ' }}</strong> {{ totalCustoGerencial | monetario }}
          </td>
        </tr>
        <tr>
          <td [colSpan]="10" class="r">
            <strong>{{ bibDialogo.quantidadeNfe + ' x ' + bibDialogo.custoNfe + ': ' }}</strong> {{ totalCustoMedio | monetario }}
          </td>
        </tr>
      </div>
  </relatorioLayout>