import { Component, Input } from '@angular/core';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { TipoTituloCaixa } from 'src/app/modelo/tipoTituloCaixa';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'tipoTituloCaixaFrm',
  templateUrl: './tipoTituloCaixaFrm.component.html',
})
export class TipoTituloCaixaFrmComponent extends PaginaComponent {
  @Input() tipoTitulo: TipoTitulo;
  @Input() idExclusaoTipoTituloCaixas: number[] = [];
  public edicaoTipoTituloCaixas: number[] = [];
  @Input() especieCartaoDebito: boolean = false;

  adicionar(): void {
    if (this.especieCartaoDebito == false || this.tipoTitulo.tipoTituloCaixas.length == 0) {
      let tipoTituloCaixa: TipoTituloCaixa = new TipoTituloCaixa();
      if (this.tipoTitulo.tipoTituloCaixas.length == 0) {
        tipoTituloCaixa.parcelaDe = 1;
      }
      this.tipoTitulo.tipoTituloCaixas.push(tipoTituloCaixa);
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cartaoDebitoSomenteUmaTaxa));
    }
  }

  excluirTipoTituloCaixa(posicao: number): void {
    super.ehExcluir(posicao, this.tipoTitulo.tipoTituloCaixas, this.idExclusaoTipoTituloCaixas, this.tipoTitulo.id);
  }

  editar(tipoTituloCaixa: TipoTituloCaixa): void {
    if (this.edicaoTipoTituloCaixas.includes(tipoTituloCaixa.id)) {
      const posicao: number = this.edicaoTipoTituloCaixas.indexOf(tipoTituloCaixa.id, 0);
      this.edicaoTipoTituloCaixas.splice(posicao, 1);
    } else {
      this.edicaoTipoTituloCaixas.push(tipoTituloCaixa.id);
    }
  }
}
