import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import ordens from './acessorio/ordem';
import grupos from './acessorio/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

@Component({
  templateUrl: './r1126.component.html',
  styleUrls: ['r1126.component.css'],
})
export class R1126Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public parceiros: any[] = [];
  public parceiroEnderecos: any[] = [];
  public parceiroContatos: any[] = [];
  public parceiroTelefones: any[] = [];
  public parceiroDestinos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public usaDadosAdicionais: boolean = true;
  public usaEndereco: boolean = true;
  public usaContato: boolean = true;
  public usaTelefone: boolean = true;
  public usaDestino: boolean = true;
  public usaAssinatura: boolean = false;
  public usaDocumento: boolean = true;
  public usaRelacionamento: boolean = false;
  public utilFiltro: UtilFiltro = new UtilFiltro();
  public usaNomeFantasia: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {


    super.listar(criterios).subscribe(() => {
      this.parceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Parceiro').lista;
      this.parceiroEnderecos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ParceiroEndereco').lista;
      this.parceiroContatos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ParceiroContato').lista;
      this.parceiroTelefones = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ParceiroTelefone').lista;
      this.parceiroDestinos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ParceiroDestino').lista;
      this.ordenarAgrupar(this.parceiros);
      this.vincular();
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.parceiros.forEach((parceiro) => {
      parceiro.parceiroEnderecos = this.parceiroEnderecos.filter((parceiroEndereco) => parceiroEndereco.idParceiro == parceiro.id);
      parceiro.parceiroContatos = this.parceiroContatos.filter((parceiroContato) => parceiroContato.idParceiro == parceiro.id);
      parceiro.parceiroTelefones = this.parceiroTelefones.filter((parceiroTelefone) => parceiroTelefone.idParceiro == parceiro.id);
      parceiro.parceiroDestinos = this.parceiroDestinos.filter((parceiroDestino) => parceiroDestino.idParceiro == parceiro.id);
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaDadosAdicionais = this.definirRegra(relatorioAdicional, 127, this.usaDadosAdicionais);
    this.usaEndereco = this.definirRegra(relatorioAdicional, 128, this.usaEndereco);
    this.usaContato = this.definirRegra(relatorioAdicional, 129, this.usaContato);
    this.usaTelefone = this.definirRegra(relatorioAdicional, 130, this.usaTelefone);
    this.usaDestino = this.definirRegra(relatorioAdicional, 131, this.usaDestino);
    this.usaAssinatura = this.definirRegra(relatorioAdicional, 132, this.usaAssinatura);
    this.usaDocumento = this.definirRegra(relatorioAdicional, 262, this.usaDocumento);
    this.usaRelacionamento = this.definirRegra(relatorioAdicional, 263, this.usaRelacionamento);
    this.usaNomeFantasia = this.definirRegra(relatorioAdicional, 264, this.usaNomeFantasia);
  }
}
