<div class="responsiva">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90">{{ bibDialogo.permitirVisualizar }}</th>
        <th class="l-10 text-center">{{ bibDialogo.permitir }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="limitar">{{ bibDialogo.mostrarMovimentacaoDeOutroUsuario }}</td>
        <td class="text-center">
          <icone [icone]="usuario.permitir == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="usuario.permitir = usuario.permitir == 'S' ? 'N' : 'S'" [cor]="usuario.permitir == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza"></icone>
        </td>
      </tr>
      <tr>
        <td class="limitar">{{ bibDialogo.permitirAlterarDataNegociacao }}</td>
        <td class="text-center">
          <icone [icone]="usuario.permiteMovimentacaoData == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="usuario.permiteMovimentacaoData = usuario.permiteMovimentacaoData == 'S' ? 'N' : 'S'" [cor]="usuario.permiteMovimentacaoData == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza"></icone>
        </td>
      </tr>
      <tr>
        <td class="limitar">{{ bibDialogo.permitirBaixaEstornoRetroativo }}</td>
        <td class="text-center">
          <icone [icone]="usuario.permiteBaixaEstornoRetroativo == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="usuario.permiteBaixaEstornoRetroativo = usuario.permiteBaixaEstornoRetroativo == 'S' ? 'N' : 'S'" [cor]="usuario.permiteBaixaEstornoRetroativo == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza"></icone>
        </td>
      </tr>
    </tbody>
  </table>
</div>
