<div class="page-section">
  <section class="card card-fluid">
    <div class="card-body">
      <ng-content></ng-content>
    </div>
  </section>
</div>
<div class="altura">
  <button class="esconder-botao" type="button"></button>
</div>
