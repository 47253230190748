import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ProdutoFormulado } from '../../modelo/produtoFormulado';
import { ProdutoFormuladoRestricao } from '../../modelo/ProdutoFormuladoRestricao';
import filtros from './filtro';

@Component({
  templateUrl: './compendioEletronico.component.html',
})
export class CompendioEletronicoComponent extends PaginaComponent {
  public ativo: boolean = false;
  public produtoFormulados: ProdutoFormulado[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.produtoFormulado).subscribe((res) => {
      this.produtoFormulados = this.plainToClass(ProdutoFormulado, res) as any;
      this.produtoFormulados.forEach((produtoFormulado) => {
        let grauToxicologica: string[] = produtoFormulado.classeToxicologica.split('-');
        produtoFormulado.grauToxicologica = grauToxicologica[0].trim();
      });
    });
  }

  excluirCidade(id: number, nome: string): void {
    super.excluir<ProdutoFormulado>(id, this.produtoFormulados, nome);
  }
}
