<header class="pt-2 pb-2">
  <div class="text-center upper">
    <div class="user-avatar user-avatar-xl fileinput-button">
      <div class="fileinput-button-label">{{ bibDialogo.alterarFoto }}</div>
      <img id="output" [src]="usuario.fotoUsuario ? usuario.fotoUsuario : usuario.foto == 'S' ? conexao.caminhoAwsS3 + '' + usuario.idEmpresa + '/usuario/' + usuario.id + '.jpg' : imagemUsuario" [alt]="usuario.nome" />
      <input accept="image/*" type="file" id="fileupload-avatar" (change)="alterarFotoUsuario($event, usuario)" #arquivoUpload />
    </div>
    <div class="row justify-content-center">
      <div *ngIf="usuario.foto == 'S'" class="pt-2"><i class="fa fa-trash fa-cinza-escuro clique row" (click)="deletarFoto()"></i></div>
    </div>
    <div *ngIf="idUsuarioSistema == usuario.id" class="row justify-content-center">
      <botao class="btn btn-light text-primary" [legenda]="ehModoClaro ? bibDialogo.modoEscuro : bibDialogo.modoClaro" [desabilitado]="false" [icone]="ehModoClaro ? 'fas fa-moon' : 'far fa-moon'" (botaoEmt)="setUsuarioModoClaroEscuro()"></botao>
    </div>
  </div>
</header>
