import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { GrupoContabil } from 'src/app/modelo/grupoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './grupoContabil.component.html',
})
export class GrupoContabilComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public grupoContabeis: GrupoContabil[] = [];

  listar(criterios: Criterio[]): void {
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.grupoContabil).subscribe((res) => {
      this.grupoContabeis = this.plainToClass(GrupoContabil, res) as any;
    });
  }

  excluirGrupoContabil(id: number, nome: string): void {
    super.excluir(id, this.grupoContabeis, nome);
  }
}
