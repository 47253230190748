<layout [titulo]="permissao ? permissao.menu : movimentacaoControlador.movimentacao.operacao" [subTitulo]="movimentacaoControlador.movimentacao.situacaoDenegada == 'S' ? bibDialogo.notaDenegada.toUpperCase() : (movimentacaoControlador.movimentacao.valorBloqueado > 0 ? ((movimentacaoControlador.movimentacao.valorBloqueado == movimentacaoControlador.movimentacao.valorTotal && movimentacaoControlador.financeiro != 'N') ? bibDialogo.financeiroBloqueado : bibDialogo.financeiroParcialmenteBloqueado) : null)"  [id]="movimentacaoControlador.movimentacao.id" [utilitarios]="movimentacaoControlador.utilitarios" (utilitarioEmt)="irAcessoRapido($event)" (voltarAvancarEmt)="ehAlteracao()">
  <div *ngIf="movimentacaoControlador.movimentacao.ativo != 'S'" class="tarja-cancelada">{{ bibDialogo.cancelada }}</div>
  <div *ngIf="!movimentacaoControlador.entradaServico && movimentacaoControlador.notaTerceiro && !movimentacaoControlador.movimentacao.id" class="row justify-content-end m-0 p-0 mb-2">
    <div *ngIf="movimentacaoControlador.movimentacao.idNaturezaOperacao" class="botao-nfe">
      <div class="btn btn-primary">
        {{ bibDialogo.importarXmlNfe }}
        <input type="file" (change)="setarArquivo($event)" class="botao-upload-nfe" />
      </div>
    </div>
    <botao *ngIf="!movimentacaoControlador.movimentacao.idNaturezaOperacao" [legenda]="bibDialogo.importarXmlNfe" [fundo]="true" (botaoEmt)="setArquivoNaturezaOperacao()"></botao>
  </div>
  <movimentacaoCabecalhoFrm [movimentacaoControlador]="movimentacaoControlador"></movimentacaoCabecalhoFrm>
  <movimentacaoProdutoFrm [casasDecimais]="this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais" [movimentacaoControlador]="movimentacaoControlador" [focoIdSenha]="focoIdSenha"></movimentacaoProdutoFrm>
  <div *ngIf="movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0 && movimentacaoControlador.identificacao != 5 && movimentacaoControlador.identificacao != 8 && !movimentacaoControlador.transferencia && !movimentacaoControlador.movimentacaoManual && !movimentacaoControlador.entradaServico" class="row mt-2 m-0">
    <div class="d-flex flex-row align-items-center justify-content-center">
      <div *ngIf="utilSessao.getEmpresa()?.mostraMargemLucro == 'S' || permissaoProdutoValor">
        <atencao *ngIf="movimentacaoControlador.saida && movimentacaoControlador.movimentacao.valorFinanceiro > 0 && movimentacaoControlador.diferencaParcelaTotal == 0" [atencao]="movimentacaoControlador.bibDialogo.lcr + ': ' + (movimentacaoControlador.movimentacao.lucroPercentual | monetario) + bibDialogo.percentualSimbolo"></atencao>
        <atencao *ngIf="movimentacaoControlador.saida && movimentacaoControlador.movimentacao.valorFinanceiro > 0 && movimentacaoControlador.diferencaParcelaTotal == 0" [atencao]="movimentacaoControlador.bibDialogo.vlr + ': ' + (movimentacaoControlador.movimentacao.lucroValor | monetario)"></atencao>
      </div>
      <atencao *ngIf="movimentacaoControlador.saida && movimentacaoControlador.movimentacao.valorFinanceiro > 0 && movimentacaoControlador.movimentacao.movimentacaoParcelas?.length > 0 && movimentacaoControlador.diferencaParcelaTotal != 0" [atencao]="movimentacaoControlador.bibDialogo.recalcular + ' ' + movimentacaoControlador.bibDialogo.parcelamento.toLowerCase()" [tipo]="movimentacaoControlador.bibPropriedade.atencao.alerta"></atencao>
      <atencao [atencao]="bibDialogo.pesoBruto + ': ' + (movimentacaoControlador.pesoBruto ? (movimentacaoControlador.pesoBruto | monetario) : 0) + ' KG'"></atencao>
      <atencao *ngIf="!movimentacaoControlador.nota && movimentacaoControlador.movimentacao.valorTotalProdutoBruto != (movimentacaoControlador.movimentacao.valorBloqueado + movimentacaoControlador.movimentacao.valorFinanceiro)" [atencao]="bibDialogo.subTotal + ': ' + (movimentacaoControlador.movimentacao.valorTotalProdutoBruto | monetario)"></atencao>
      <atencao *ngIf="!movimentacaoControlador.nota && movimentacaoControlador.movimentacao.descontoTotal > 0" [atencao]="bibDialogo.desconto + ': ' + (movimentacaoControlador.movimentacao.descontoTotal | monetario)"></atencao>
      <atencao *ngIf="!movimentacaoControlador.nota" [atencao]="bibDialogo.valorTotal + ': ' + (movimentacaoControlador.movimentacao.valorBloqueado + movimentacaoControlador.movimentacao.valorFinanceiro | monetario)"></atencao>
      <atencao *ngIf="!movimentacaoControlador.nota && movimentacaoControlador.movimentacao.valorBloqueado > 0" [atencao]="bibDialogo.valorBloqueado + ': ' + (movimentacaoControlador.movimentacao.valorBloqueado ? (movimentacaoControlador.movimentacao.valorBloqueado | monetario) : 0)"></atencao>
      <atencao *ngIf="!movimentacaoControlador.nota && movimentacaoControlador.movimentacao.valorFinanceiro > 0 && movimentacaoControlador.movimentacao.valorFinanceiro != movimentacaoControlador.movimentacao.valorBloqueado + movimentacaoControlador.movimentacao.valorFinanceiro" [atencao]="bibDialogo.valorFinanceiro + ': ' + (movimentacaoControlador.movimentacao.valorFinanceiro | monetario)"></atencao>
    </div>
  </div>
  <movimentacaoTransporteFrm *ngIf="!this.movimentacaoControlador.entradaServico && (this.movimentacaoControlador.nota || movimentacaoControlador.notaTerceiro)" [movimentacaoControlador]="movimentacaoControlador"></movimentacaoTransporteFrm>
  <movimentacaoTotalizadorFrm *ngIf="this.movimentacaoControlador.nota || movimentacaoControlador.notaTerceiro || (this.movimentacaoControlador.identificacao == 4 && this.movimentacaoControlador.entrada)" [movimentacaoControlador]="movimentacaoControlador"></movimentacaoTotalizadorFrm>
  <movimentacaoParcelaFrm *ngIf="(!movimentacaoControlador.pedido || movimentacaoControlador.financeiro != 'N') && !movimentacaoControlador.interna && movimentacaoControlador.movimentacao.especie != 'S' && (movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade != 2 || movimentacaoControlador.movimentacao.idOperacao == 20 || movimentacaoControlador.entrada)" [movimentacaoControlador]="movimentacaoControlador"></movimentacaoParcelaFrm>
  <movimentacaoRodapeFrm [movimentacaoControlador]="movimentacaoControlador"></movimentacaoRodapeFrm>
  <movimentacaoChave *ngIf="movimentacaoControlador.nota" [movimentacaoControlador]="movimentacaoControlador"></movimentacaoChave>
</layout>
<div class="row">
  <botao *ngIf="this.movimentacaoControlador.bloqueiaAlteracao == false" class="ml-2" [legenda]="movimentacaoControlador.bibDialogo.alterar" [tipo]="movimentacaoControlador.bibPropriedade.botao.escuro" [fundo]="true" (botaoEmt)="alterar()" [desabilitado]="bloqueiaPermissao == false ? (movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao) : true" [compacto]="movimentacaoControlador.compacto"></botao>
  <botao *ngIf="(this.movimentacaoControlador.bloqueiaAlteracao == true) && (mostrarSalvar ? true : movimentacaoControlador.empresa.movimentacaoSalvarFinalizar == 1 ? true : movimentacaoControlador.devolucao && movimentacaoControlador.saida && !movimentacaoControlador.nota ? false : movimentacaoControlador.pedido && movimentacaoControlador.saida && movimentacaoControlador.vendaDireta == false ? false : true)" class="ml-2" [legenda]="movimentacaoControlador.bibDialogo.salvar" [tipo]="movimentacaoControlador.bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="salvar()" [desabilitado]="bloqueiaPermissao == false ? (movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao) : true" [compacto]="movimentacaoControlador.compacto"></botao>
  <botao *ngIf="this.movimentacaoControlador.bloqueiaAlteracao == true" [id]="'finalizar'" [legenda]="movimentacaoControlador.bibDialogo.finalizar" [fundo]="true" (botaoEmt)="ehExigirSenha()" [focoId]="focoIdFinalizar" [desabilitado]="movimentacaoControlador.bloqueado" [compacto]="movimentacaoControlador.compacto"></botao>
  <texto [id]="'senhaOperacional'" *ngIf="utilSessao.getUsuario()?.agrow == 'N' && focoIdSenha" [ngClass]="compacto ? 'mt-2' : ''" [descricao]="bibDialogo.senhaOperacional" (navegacao)="$event == false ? (focoIdSenha = null) : ''" [tipo]="bibPropriedade.texto.senha" (alteracao)="conferirSenha($event)" [focoId]="focoIdSenha" autocomplete="new-password"></texto>
  <div *ngIf="movimentacaoControlador.movimentacao.status == 'S' && movimentacaoControlador.bloqueado" class="col-sm d-flex align-items-center justify-content-end">
      <atencao *ngIf="estoqueDisponivel != 0" [atencao]="bibDialogo.estoqueDisponivel + (estoqueDisponivel == -1 ? ' (-)' : estoqueDisponivel == 1 ? ' (+)' : '')" [tipo]="estoqueDisponivel == -1 ? bibPropriedade.atencao.cuidado : estoqueDisponivel == 1 ? bibPropriedade.atencao.sucesso : ''"></atencao>
      <atencao *ngIf="estoqueNfe != 0" [atencao]="bibDialogo.estoque + ' ' + bibDialogo.nfe + (estoqueNfe == -1 ? ' (-)' : estoqueNfe == 1 ? ' (+)' : '')" [tipo]="estoqueDisponivel == -1 ? bibPropriedade.atencao.cuidado : estoqueDisponivel == 1 ? bibPropriedade.atencao.sucesso : ''"></atencao>
      <atencao *ngIf="movimentacaoControlador.movimentacao.valorBloqueado > 0" [atencao]="(movimentacaoControlador.movimentacao.valorBloqueado == movimentacaoControlador.movimentacao.valorTotal && movimentacaoControlador.financeiro != 'N') ? bibDialogo.financeiroBloqueado : bibDialogo.financeiroParcialmenteBloqueado" [tipo]="bibPropriedade.atencao.alerta"></atencao>
    <div *ngIf="movimentacaoControlador.movimentacao.financeiro != 'N'">
      <atencao [atencao]="movimentacaoControlador.movimentacao.financeiro == 'R' ? bibDialogo.receita : bibDialogo.despesa" [tipo]="movimentacaoControlador.movimentacao.financeiro == 'R' ? bibPropriedade.atencao.sucesso : bibPropriedade.atencao.cuidado"></atencao>
      <atencao *ngIf="movimentacaoControlador.movimentacao.valorComissao > 0" [atencao]="bibDialogo.comissao"></atencao>
    </div>
    <atencao [atencao]="bibDialogo.finalizado" [tipo]="bibPropriedade.atencao.sucesso" class="ml-4"></atencao>
  </div>
</div>
<atencao *ngIf="movimentacaoControlador.movimentacao.valorBloqueado > 0" [atencao]="bibDialogo.atencao.toUpperCase() + ' ' + ((movimentacaoControlador.movimentacao.valorBloqueado == movimentacaoControlador.movimentacao.valorTotal && movimentacaoControlador.financeiro != 'N') ? bibDialogo.financeiroBloqueado : util.substituir(bibDialogo.financeiroParcialmenteBloqueadoDetalhe, [(movimentacaoControlador.movimentacao.valorTotal | monetario), (movimentacaoControlador.movimentacao.valorFinanceiro | monetario)]))" [tipo]="bibPropriedade.atencao.alerta"></atencao>
<bloqueado [bloqueado]="movimentacaoControlador.bloqueado"></bloqueado>
<auditoria *ngIf="movimentacaoControlador.movimentacao.id" [idUsuarioInclusao]="movimentacaoControlador.movimentacao.idUsuarioInclusao" [dataHoraInclusao]="movimentacaoControlador.movimentacao.dataHoraInclusao" [idUsuarioAlteracao]="movimentacaoControlador.movimentacao.idUsuarioAlteracao" [dataHoraAlteracao]="movimentacaoControlador.movimentacao.dataHoraAlteracao"></auditoria>
