import { Component, Input } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UsuarioConta } from 'src/app/modelo/usuarioConta';

@Component({
  selector: 'usuarioContasFrm',
  templateUrl: './usuarioContasFrm.component.html',
})
export class UsuarioContasFrmComponent extends PaginaComponent {
  @Input() usuarioContas: UsuarioConta[] = [];
}
