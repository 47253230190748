<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.quantidadeEstoqueEstoque }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <!-- <th class="r-1">{{ bibDialogo.lote }}</th>
    <th class="r-1">{{ bibDialogo.local }}</th> -->
    <th class="r-1">{{ bibDialogo.fisico }}</th>
    <th class="r-1">{{ bibDialogo.fisico }}</th>
    <th class="r-1">{{ bibDialogo.reservado }}</th>
    <th class="r-1">{{ bibDialogo.reservado }}</th>
    <th class="r-1">{{ bibDialogo.comprado }}</th>
    <th class="r-1">{{ bibDialogo.comprado }}</th>
    <th class="r-1">{{ bibDialogo.emTransferencia }}</th>
    <th class="r-1">{{ bibDialogo.emTransferencia }}</th>
    <th class="r-1">{{ bibDialogo.nfe }}</th>
    <th class="r-1">{{ bibDialogo.nfe }}</th>
    <!-- <th class="r-1">{{ bibDialogo.disponivel }}</th>
    <th class="r-1">{{ bibDialogo.disponivel }}</th> -->
    <!-- <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th> -->
  </thead>
  <tbody>
    <ng-container *ngFor="let estoqueHistorico of estoqueHistoricos">
      <tr class="conteudo">
        <td>{{ estoqueHistorico.id }}</td>
        <td>{{ estoqueHistorico.idLoja + ' - ' + estoqueHistorico.loja }}</td>
        <td>{{ estoqueHistorico.idEmpresa + ' - ' + estoqueHistorico.empresa }}</td>
        <!-- <td>{{ estoqueHistorico.idLote + ' - ' + estoqueHistorico.lote }}</td>
        <td>{{ estoqueHistorico.idLocal + ' - ' + estoqueHistorico.local }}</td> -->
        <td>{{ estoqueHistorico.quantidadeFisicaEsperada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeFisicaEncontrada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeReservadaEsperada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeReservadaEncontrada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeCompradaEsperada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeCompradaEncontrada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeTransferidaEsperada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeTransferidaEncontrada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeNfeEsperada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeNfeEncontrada | monetario }}</td>
        <!-- <td>{{ estoqueHistorico.quantidadeDisponivelEsperada | monetario }}</td>
        <td>{{ estoqueHistorico.quantidadeDisponivelEncontrada | monetario }}</td> -->
        <!-- <td>{{ estoqueHistorico.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td> -->
      </tr>
    </ng-container>
  </tbody>
</table>
