import { Component } from '@angular/core';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './classificacaoFiscalFrm.component.html',
})
export class ClassificacaoFiscalFrmComponent extends PaginaComponent {
  public classificacaoFiscal: ClassificacaoFiscal = new ClassificacaoFiscal();
  public cstIcmsNaoSimplesNacionais: Cst[] = [];
  public cstIcmsSimplesNacionais: Cst[] = [];
  public cstIcmsNaoSimplesNacionaisTributadoIntegralmente: Cst[] = [];
  public cstIcmsSimplesNacionaisTributadoIntegralmente: Cst[] = [];
  public mostraCodigoBeneficioFiscal: boolean = false;
  public listaEnquadramento: any = [
    { id: '2', nome: this.bibDialogo.lucroPresumido.toUpperCase() },
    { id: '3', nome: this.bibDialogo.lucroReal.toUpperCase() },
    { id: '1', nome: this.bibDialogo.simplesNacional.toUpperCase() },
    { id: '4', nome: this.bibDialogo.simplesNacionalExcesso.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarCstIcms();
    this.ehMostrarCodigoBeneficioFiscal();
  }

  ehAlteracao(): void {
    this.classificacaoFiscal = this.plainToClass(ClassificacaoFiscal, this.ehAtualizacao(this.classificacaoFiscal));
    if (this.classificacaoFiscal.id) {
      this.listarClassificacaoFiscal(this.classificacaoFiscal.id);
    }
  }

  listarClassificacaoFiscal(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.classificacaoFiscal).subscribe((res) => {
      this.classificacaoFiscal = this.plainToClass(ClassificacaoFiscal, res[0]) as any;
    });
  }

  listarCstIcms(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cstIcms).subscribe((res) => {
      const cstIcms = this.plainToClass(Cst, res) as any;
      this.cstIcmsSimplesNacionais = cstIcms.filter((cstIcmsBusca) => cstIcmsBusca.simplesNacional == 'S');
      this.cstIcmsNaoSimplesNacionais = cstIcms.filter((cstIcmsBusca) => cstIcmsBusca.simplesNacional == 'N');
      this.cstIcmsSimplesNacionaisTributadoIntegralmente = cstIcms.filter((cstIcmsBusca) => cstIcmsBusca.simplesNacional == 'S').filter((cstIcmsBusca) => cstIcmsBusca.nome.indexOf('TRIBUTADA INTEGRALMENTE') > 0);
      this.cstIcmsNaoSimplesNacionaisTributadoIntegralmente = cstIcms.filter((cstIcmsBusca) => cstIcmsBusca.simplesNacional == 'N').filter((cstIcmsBusca) => cstIcmsBusca.nome.indexOf('TRIBUTADA INTEGRALMENTE') > 0);;
    });
  }

  ehMostrarCodigoBeneficioFiscal(): void {
    let listaDeEstadosPermitidos: string[] = ['DF', 'GO', 'PR', 'RJ', 'RS', 'SC', 'ES'];
    this.utilSessao.getLojas().forEach((loja) => {
      if (listaDeEstadosPermitidos.includes(loja.estado)) {
        this.mostraCodigoBeneficioFiscal = true;
      }
    });
  }

  setEnquadramento(enquadramento: number): void {
    this.classificacaoFiscal.enquadramento = enquadramento;
    if (enquadramento == 1) {
      if (this.cstIcmsSimplesNacionais.find((cstIcms) => cstIcms.id == this.classificacaoFiscal.idCstIcms) == undefined) {
        this.classificacaoFiscal.idCstIcms = null;
      }
    }
  }

  persistirClassificacaoFiscal(novo: boolean = false): void {
    if (this.ehValidoObrigatorio() && this.ehValidoCodigoBeneficioFiscal()) {
      super.persistir(new Transporte(this.classificacaoFiscal), this.bibServico.classificacaoFiscal, novo ? new ClassificacaoFiscal() : null);
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.classificacaoFiscal.nome && (this.classificacaoFiscal.base || this.classificacaoFiscal.base == 0) && this.classificacaoFiscal.idCstIcms && this.classificacaoFiscal.enquadramento) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoCodigoBeneficioFiscal(): boolean {
    if (this.classificacaoFiscal.codigoBeneficioFiscal) {
      if (this.classificacaoFiscal.codigoBeneficioFiscal.length == 8 || this.mostraCodigoBeneficioFiscal == false) {
        return true;
      }
    } else {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.codigoBeneficioFiscalOitoCaracteres));
    return false;
  }
}
