<layout [id]="cheque?.id" (voltarAvancarEmt)="ehAlteracao()">
  <bloqueado [bloqueado]="cheque.finalizado == 'S'"></bloqueado>
  <div class="row">
    <texto class="col-sm" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="cheque.numero" (alteracao)="cheque.numero = $event" [obrigatorio]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'" [foco]="true"></texto>
    <data class="col-sm" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="cheque.data" (alteracao)="cheque.data = $event" [obrigatorio]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'"></data>
    <data class="col-sm" [id]="'dataPreDatada'" [rotulo]="bibDialogo.dataPreDatada" [campo]="cheque.dataPreDatada" (alteracao)="cheque.dataPreDatada = $event" [desabilitado]="cheque.finalizado == 'S'"></data>
  </div>
  <div class="row">
    <busca class="col-sm" [id]="'parceiro'" [rotulo]="bibDialogo.parceiro" [campo]="cheque.parceiro" (alteracao)="cheque.idParceiro = $event.id" [servico]="bibServico.parceiro" [minimoCaracterBusca]="5" [obrigatorio]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'"></busca>
    <lista class="col-sm" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="cheque.idLoja" (alteracao)="cheque.idLoja = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'"></lista>
  </div>
  <div class="row">
    <decimal class="col-sm" [id]="'valor'" [rotulo]="bibDialogo.valor" [campo]="cheque.valor" (alteracao)="cheque.valor = $event" [obrigatorio]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'"></decimal>
    <lista class="col-sm" [id]="'tipo'" [rotulo]="bibDialogo.tipo" [campo]="cheque.tipo" [lista]="tipos" (alteracao)="cheque.tipo = $event.id" [obrigatorio]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'"></lista>
  </div>
  <texto [id]="'emitente'" [rotulo]="bibDialogo.emitente" [campo]="cheque.emitente" (alteracao)="cheque.emitente = $event" [desabilitado]="cheque.finalizado == 'S'"></texto>
  <texto [id]="'nominal'" [rotulo]="bibDialogo.nominal" [campo]="cheque.nominal" (alteracao)="cheque.nominal = $event" [desabilitado]="cheque.finalizado == 'S'"></texto>
  <titulo [titulo]="bibDialogo.informacoesBancarias" [imagem]="bibImagem.dinheiro"></titulo>
  <div class="row">
    <texto class="col-sm" [id]="'numeroBanco'" [rotulo]="bibDialogo.numeroBanco" [campo]="cheque.numeroBanco" (alteracao)="cheque.numeroBanco = $event" [tipo]="bibPropriedade.texto.inteiro" [desabilitado]="cheque.finalizado == 'S'"></texto>
    <texto class="col-sm" [id]="'agenciaBanco'" [rotulo]="bibDialogo.agenciaBanco" [campo]="cheque.agenciaBanco" (alteracao)="cheque.agenciaBanco = $event" [tipo]="bibPropriedade.texto.inteiro" [desabilitado]="cheque.finalizado == 'S'"></texto>
    <texto class="col-sm" [id]="'numeroConta'" [rotulo]="bibDialogo.numeroConta" [campo]="cheque.numeroConta" (alteracao)="cheque.numeroConta = $event" [tipo]="bibPropriedade.texto.inteiro" [desabilitado]="cheque.finalizado == 'S'"></texto>
  </div>
  <titulo [titulo]="bibDialogo.destino" [imagem]="bibImagem.destino"></titulo>
  <div class="row">
    <busca class="col-sm" [id]="'destino'" [rotulo]="bibDialogo.destino" [campo]="cheque.parceiroDestino" (alteracao)="cheque.idParceiroDestino = $event.id" [servico]="bibServico.parceiro" [minimoCaracterBusca]="5" [desabilitado]="cheque.finalizado == 'S'"></busca>
    <data class="col-sm" [id]="'dataRepasse'" [rotulo]="bibDialogo.dataRepasse" [campo]="cheque.dataRepasse" (alteracao)="cheque.dataRepasse = $event" [desabilitado]="cheque.finalizado == 'S'"></data>
  </div>
  <div class="row">
    <lista class="col-sm" [id]="'contaInicial'" [rotulo]="bibDialogo.contaInicial" [campo]="cheque.idContaInicial" (alteracao)="setIdContaInicial(cheque, $event.id)" [tipo]="bibPropriedade.lista.contaUsuario" [obrigatorio]="true" [desabilitado]="cheque.idConciliacao != null || cheque.finalizado == 'S'"></lista>
    <lista class="col-sm" [id]="'contaAtual'" [rotulo]="bibDialogo.contaAtual" [campo]="cheque.idContaAtual" (alteracao)="cheque.idContaAtual = $event.id" [tipo]="bibPropriedade.lista.contaUsuario" [desabilitado]="true"></lista>
  </div>
  <descricao [id]="'observacao'" [rotulo]="bibDialogo.observacao" [campo]="cheque.observacao" (alteracao)="cheque.observacao = $event" [maximoCaracteres]="250" [desabilitado]="cheque.finalizado == 'S'"></descricao>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCheque()" [desabilitado]="bloquearPermissao(cheque.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirCheque(true)" [desabilitado]="bloquearPermissao(cheque.id)"></botao>
<auditoria *ngIf="cheque?.id" [idUsuarioInclusao]="cheque.idUsuarioInclusao" [dataHoraInclusao]="cheque.dataHoraInclusao" [idUsuarioAlteracao]="cheque.idUsuarioAlteracao" [dataHoraAlteracao]="cheque.dataHoraAlteracao"></auditoria>
