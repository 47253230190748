import { PisAliq } from "./pisAliq";
import { PisNt } from "./pisNt";
import { PisOutr } from "./pisOutr";
import { PisQtde } from "./pisQtde";

export class Pis {
    public PISNT: PisNt;
    public PISAliq: PisAliq;
    public PISQtde: PisQtde;
    public PISOutr: PisOutr;
}