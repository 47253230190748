import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'movimentacaoRodapeFrm',
  templateUrl: './movimentacaoRodapeFrm.component.html',
})
export class MovimentacaoRodapeFrmComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;

  public bibDialogo = bibDialogo;
  public compacto: boolean = false;
  public componente: any;
  public utilSessao: UtilSessao = new UtilSessao();

  public listaEstoqueEscolha: any[] = [
    { id: 1, nome: this.bibDialogo.fisico.toUpperCase() },
    { id: 2, nome: this.bibDialogo.nfe.toUpperCase() },
    { id: 3, nome: this.bibDialogo.fisico.toUpperCase() + ' E ' + this.bibDialogo.nfe.toUpperCase() },
  ];

  public tipoEmissoesTerceiro: any[] = [{ id: 1, nome: this.bibDialogo.terceiro.toUpperCase() }];
  public tipoEmissoesProprioTerceiro: any[] = [
    { id: 0, nome: this.bibDialogo.proprio.toUpperCase() },
    { id: 1, nome: this.bibDialogo.terceiro.toUpperCase() },
  ];

  ngOnInit(): void {
    this.compacto = this.movimentacaoControlador.compacto;
  }

  consultarNfe(): void {
    const chaveCopiar = document.createElement('textarea');
    chaveCopiar.value = this.movimentacaoControlador.movimentacao.chaveFiscal;
    document.body.appendChild(chaveCopiar);
    chaveCopiar.focus();
    chaveCopiar.select();
    document.execCommand('copy');
    document.body.removeChild(chaveCopiar);
    window.open('https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=&AspxAutoDetectCookieSupport=1');
  }

  setEmitente(emitente: number) {
    this.movimentacaoControlador.movimentacao.emitente = emitente;
    const EMITENTE_TERCEIRO: number = 1;
    if (this.movimentacaoControlador.movimentacao.emitente != EMITENTE_TERCEIRO) {
      this.movimentacaoControlador.movimentacao.modelo = null;
      this.movimentacaoControlador.movimentacao.serie = null;
      this.movimentacaoControlador.movimentacao.chaveFiscal = null;
    }
  }
}
