import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { ReceituarioComponent } from './receituario.component';
import { ReceituarioEletronicoComponent } from './receituarioEletronico/receituarioEletronico.component';
import { ReceituarioFrmComponent } from './receituarioFrm/receituarioFrm.component';
import { AutenticacaoGuard } from 'src/app/guardas/autenticacao.guard';

const routes: Routes = [
  {
    path: '',
    component: ReceituarioComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'Frm',
    component: ReceituarioFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  declarations: [ReceituarioComponent, ReceituarioFrmComponent, ReceituarioEletronicoComponent],
  imports: [CommonModule, FormsModule, CompartilhadoModule, RouterModule, RouterModule.forChild(routes)],
})
export class ReceituarioModule {}
