import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { GrupoUsuario } from 'src/app/modelo/grupoUsuario';
import { GrupoUsuarioMenu } from 'src/app/modelo/grupoUsuarioMenu';
import { Menu } from 'src/app/modelo/menu';
import { Permissao } from 'src/app/modelo/permissao';
import { Relatorio } from 'src/app/modelo/relatorio';
import { RelatorioGrupoUsuario } from 'src/app/modelo/relatorioGrupoUsuario';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { UsuarioMenu } from 'src/app/modelo/usuarioMenu';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './grupoPermissaoFrm.component.html',
})
export class GrupoPermissaoFrmComponent extends PaginaComponent {
  public grupoUsuario: GrupoUsuario = new GrupoUsuario();
  public grupoUsuarioMenus: GrupoUsuarioMenu[] = [];
  public grupoUsuarioMenusInicial: GrupoUsuarioMenu[] = [];
  public grupoUsuarioRelatoriosInicial: RelatorioGrupoUsuario[] = [];
  public grupoUsuarioMenusExcluir: GrupoUsuarioMenu[] = [];
  public relatorioGrupoUsuarioExcluir: RelatorioGrupoUsuario[] = [];
  public relatorioGrupoUsuarios: RelatorioGrupoUsuario[] = [];
  public usuariosDoGrupo: Usuario[] = [];
  public usuarioMenus: UsuarioMenu[] = [];
  public menusDoGrupoInseridos: GrupoUsuarioMenu[] = [];
  public menusUsuariosDoGrupo: UsuarioMenu[] = [];
  public usuarioMenusExcluir: number[] = [];
  public usuarioRelatorios: RelatorioUsuario[] = [];
  public relatoriosDoGrupoInseridos: RelatorioGrupoUsuario[] = [];
  public relatoriosUsuariosDoGrupo: RelatorioUsuario[] = [];
  public usuarioRelatoriosExcluir: number[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarMenu();
    this.listarRelatorio();
  }

  ehAlteracao(): void {
    this.grupoUsuario = this.plainToClass(GrupoUsuario, this.ehAtualizacao(this.grupoUsuario));
    if (this.grupoUsuario.id) {
      this.listarGrupoUsuario(this.grupoUsuario.id);
    }
  }

  listarGrupoUsuario(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.grupoUsuario).subscribe((res) => {
      this.grupoUsuario = this.plainToClass(GrupoUsuario, res[0]) as any;
    });
  }

  listarMenu(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('SISTEMA', this.utilSessao.sistema), new Criterio('MENU_EMPRESA_ATIVO', 'S')]), this.bibServico.menu).subscribe((res) => {
      const menus: Menu[] = this.plainToClass(Menu, res) as any;
      if (this.grupoUsuario.id) {
        this.listarGrupoUsuarioMenu(menus);
      } else {
        this.completarGrupoUsuarioMenu(menus);
      }
    });
  }

  listarGrupoUsuarioMenu(menus: Menu[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_USUARIO', this.grupoUsuario.id)), this.bibServico.grupoUsuarioMenu).subscribe((res) => {
      this.grupoUsuarioMenus = this.plainToClass(UsuarioMenu, res) as any;
      this.grupoUsuarioMenusInicial = this.plainToClass(UsuarioMenu, res) as any;
      this.grupoUsuarioMenus.forEach((grupoUsuarioMenu) => {
        if (grupoUsuarioMenu.moduloEmpresaPai || grupoUsuarioMenu.moduloEmpresa) {
          grupoUsuarioMenu.modulo = grupoUsuarioMenu.moduloEmpresaPai ? grupoUsuarioMenu.moduloEmpresaPai : grupoUsuarioMenu.moduloEmpresa;
        } else {
          grupoUsuarioMenu.modulo = grupoUsuarioMenu.moduloPai ? grupoUsuarioMenu.moduloPai : grupoUsuarioMenu.modulo;
        }
      });
      this.completarGrupoUsuarioMenu(menus);
    });
  }

  completarGrupoUsuarioMenu(menus: Menu[]): void {
    for (const menu of menus) {
      if (this.grupoUsuarioMenus.filter((usuarioMenu) => usuarioMenu.idMenu == menu.id).length == 0) {
        let grupoUsuarioMenu: GrupoUsuarioMenu = new GrupoUsuarioMenu();
        grupoUsuarioMenu.idMenu = menu.id;
        grupoUsuarioMenu.menu = menu.nome;
        if (menu.moduloEmpresaPai || menu.moduloEmpresa) {
          grupoUsuarioMenu.modulo = menu.moduloEmpresaPai ? menu.moduloEmpresaPai : menu.moduloEmpresa;
        } else {
          grupoUsuarioMenu.modulo = menu.moduloPai ? menu.moduloPai : menu.modulo;
        }
        grupoUsuarioMenu.acessar = 'N';
        grupoUsuarioMenu.favorito = 'N';
        grupoUsuarioMenu.menuAcessar = menu.acessar;
        grupoUsuarioMenu.menuInserir = menu.inserir;
        grupoUsuarioMenu.menuEditar = menu.editar;
        grupoUsuarioMenu.menuExcluir = menu.excluir;
        grupoUsuarioMenu.menuInativar = menu.inativar;
        grupoUsuarioMenu.descricao = menu.descricao;
        this.grupoUsuarioMenus.push(grupoUsuarioMenu);
      }
    }
    this.conferirFavoritos();
    this.grupoUsuarioMenus.sort((a, b) => (a['modulo'] == b['modulo'] ? 0 : a['modulo'] > b['modulo'] ? 1 : -1));
  }

  listarRelatorio(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('SISTEMA', this.utilSessao.sistema)]), this.bibServico.relatorio).subscribe((res) => {
      const relatorios: Relatorio[] = this.plainToClass(Relatorio, res) as any;
      if (this.grupoUsuario.id) {
        this.listarRelatorioGrupoUsuario(relatorios);
        this.ordenarRelatorios(relatorios);
      } else {
        this.completarRelatorioGrupoUsuario(relatorios);
        this.ordenarRelatorios(relatorios);
      }
    });
  }

  listarRelatorioGrupoUsuario(relatorios: Relatorio[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_USUARIO', this.grupoUsuario.id)), this.bibServico.relatorioGrupoUsuario).subscribe((res) => {
      this.relatorioGrupoUsuarios = this.plainToClass(RelatorioGrupoUsuario, res) as any;
      this.completarRelatorioGrupoUsuario(relatorios);
    });
  }

  ordenarRelatorios(lista: Relatorio[]): void {
    lista.sort((a, b) => {
      return a['nome'] === b['nome'] ? 0 : a['nome'] > b['nome'] ? 1 : -1;
    });
  }

  completarRelatorioGrupoUsuario(relatorios: Relatorio[]): void {
    for (const relatorio of relatorios) {
      if (this.relatorioGrupoUsuarios.filter((relatorioGrupoUsuario) => relatorioGrupoUsuario.idRelatorio == relatorio.id).length == 0) {
        let relatorioGrupoUsuario: RelatorioGrupoUsuario = new RelatorioGrupoUsuario();
        relatorioGrupoUsuario.acessar = 'N';
        relatorioGrupoUsuario.favorito = 'N';
        relatorioGrupoUsuario.idRelatorio = relatorio.id;
        relatorioGrupoUsuario.relatorio = relatorio.nome;
        relatorioGrupoUsuario.relatorioModulo = relatorio.relatorioModulo;
        relatorioGrupoUsuario.tipoRelatorioModulo = relatorio.tipoRelatorioModulo;
        this.relatorioGrupoUsuarios.push(relatorioGrupoUsuario);
      }
    }
    this.relatorioGrupoUsuarios.sort((a, b) => (a['relatorioModulo'] == b['relatorioModulo'] ? 0 : a['relatorioModulo'] > b['relatorioModulo'] ? 1 : -1));
  }

  conferirFavoritos(): void {
    const permissoes: Permissao[] = this.utilSessao.getPermissoes();
    if (this.grupoUsuario.id) {
      this.grupoUsuarioMenus.forEach((usuarioMenu) => {
        const listarPermissoes: Permissao[] = permissoes.filter((permissao) => permissao.idMenu == usuarioMenu.idMenu);
        if (listarPermissoes.length > 0) {
          usuarioMenu.favorito = listarPermissoes[0].favorito;
        }
      });
    }
  }

  persistirGrupoUsuario(novo: boolean = false): void {
    if (this.ehValido()) {
      this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('ID_GRUPO_USUARIO', this.grupoUsuario.id ? this.grupoUsuario.id.toString() : '0')]), this.bibServico.usuario).subscribe((res) => {
        this.usuariosDoGrupo = this.plainToClass(Usuario, res);
        const idsUsuarios: number[] = this.usuariosDoGrupo.map((usuario) => usuario.id);
        if (idsUsuarios.length > 0) {
          this.comunicacaoService.listar(new Transporte([new Criterio('IDS_USUARIO', idsUsuarios.toString().replace('[', '').replace(']', ''))]), this.bibServico.usuarioMenu).subscribe((res) => {
            this.menusUsuariosDoGrupo = this.plainToClass(UsuarioMenu, res);
            this.comunicacaoService.listar(new Transporte([new Criterio('IDS_USUARIO', idsUsuarios.toString().replace('[', '').replace(']', ''))]), this.bibServico.relatorioUsuario).subscribe((res) => {
              this.relatoriosUsuariosDoGrupo = this.plainToClass(UsuarioMenu, res);
              this.configurarGrupoUsuarioPersistir();
              const transporte: Transporte = this.gerarTransportePersistir();
              super.persistir(transporte, this.bibServico.grupoUsuario, novo ? new GrupoUsuario() : null).subscribe((res) => {
                if (!this.utilSessao.falha) {
                  this.limparIds();
                  this.definirIds();
                  this.limparListasUsuario();
                }
                if (res[0].sucesso && novo) {
                  this.limparListas();
                }
              });
            });
          });
        } else {
          this.configurarGrupoUsuarioPersistir();
          const transporte: Transporte = this.gerarTransportePersistir();
          super.persistir(transporte, this.bibServico.grupoUsuario, novo ? new GrupoUsuario() : null).subscribe((res) => {
            if (!this.utilSessao.falha) {
              this.limparIds();
              this.definirIds();
              this.limparListasUsuario();
            }
            if (res[0].sucesso && novo) {
              this.limparListas();
            }
          });
        }
      });
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    return ehValidoObrigatorio;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.grupoUsuario.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  configurarGrupoUsuarioPersistir(): void {
    this.grupoUsuario.grupoUsuarioMenus = this.grupoUsuarioMenus.filter((grupoUsuarioMenu) => grupoUsuarioMenu.acessar == 'S');
    this.gerarListaDeMenusDosUsuariosDoGrupo();
    this.grupoUsuarioMenusExcluir = this.grupoUsuarioMenus.filter((grupoUsuarioMenu) => grupoUsuarioMenu.id && grupoUsuarioMenu.acessar == 'N');
    this.gerarListaExclusaoDeMenusDosUsuariosDoGrupo();
    this.grupoUsuario.relatorioGrupoUsuarios = this.relatorioGrupoUsuarios.filter((relatorioUsuario) => relatorioUsuario.acessar == 'S');
    this.gerarListaDeRelatoriosDosUsuariosDoGrupo();
    this.relatorioGrupoUsuarioExcluir = this.relatorioGrupoUsuarios.filter((relatorioUsuario) => relatorioUsuario.id && relatorioUsuario.acessar == 'N');
    this.gerarListaExclusaoDeRelatoriosDosUsuariosDoGrupo();
  }

  gerarListaDeMenusDosUsuariosDoGrupo(): void {
    const idsMenusIniciaisDoGrupo: number[] = this.grupoUsuarioMenusInicial.map((menu) => menu.id);
    this.menusDoGrupoInseridos = this.grupoUsuario.grupoUsuarioMenus.filter((menu) => !idsMenusIniciaisDoGrupo.includes(menu.id));
    this.usuariosDoGrupo.forEach((usuario) => {
      this.menusDoGrupoInseridos.forEach((menuInserido) => {
        let usuarioMenu: UsuarioMenu = new UsuarioMenu();
        usuarioMenu.acessar = menuInserido.acessar;
        usuarioMenu.idMenu = menuInserido.idMenu;
        usuarioMenu.menu = menuInserido.menu;
        usuarioMenu.inserir = menuInserido.inserir;
        usuarioMenu.editar = menuInserido.editar;
        usuarioMenu.excluir = menuInserido.excluir;
        usuarioMenu.inativar = menuInserido.inativar;
        usuarioMenu.descricao = menuInserido.descricao;
        usuarioMenu.idUsuario = usuario.id;
        usuarioMenu.id = menuInserido.id;
        this.usuarioMenus.push(usuarioMenu);
        this.grupoUsuarioMenusInicial.push(menuInserido);
      });
    });
  }

  gerarListaExclusaoDeMenusDosUsuariosDoGrupo(): void {
    this.grupoUsuarioMenusExcluir.forEach((grupoMenuExcluir) => {
      this.menusUsuariosDoGrupo.forEach((menuUsuario) => {
        if (grupoMenuExcluir.idMenu == menuUsuario.idMenu) {
          this.usuarioMenusExcluir.push(menuUsuario.id);
        }
      });
    });
  }

  gerarListaDeRelatoriosDosUsuariosDoGrupo(): void {
    const idsRelatoriosIniciaisDoGrupo: number[] = this.grupoUsuarioRelatoriosInicial.map((relatorio) => relatorio.id);
    this.relatoriosDoGrupoInseridos = this.grupoUsuario.relatorioGrupoUsuarios.filter((relatorio) => !idsRelatoriosIniciaisDoGrupo.includes(relatorio.id));
    this.usuariosDoGrupo.forEach((usuario) => {
      this.relatoriosDoGrupoInseridos.forEach((relatorioInserido) => {
        let usuarioRelatorio: RelatorioUsuario = new RelatorioUsuario();
        usuarioRelatorio.acessar = relatorioInserido.acessar;
        usuarioRelatorio.idRelatorio = relatorioInserido.idRelatorio;
        usuarioRelatorio.acessar = relatorioInserido.acessar;
        usuarioRelatorio.idUsuario = usuario.id;
        usuarioRelatorio.id = relatorioInserido.id;
        this.usuarioRelatorios.push(usuarioRelatorio);
        this.grupoUsuarioRelatoriosInicial.push(relatorioInserido);
      });
    });
  }

  gerarListaExclusaoDeRelatoriosDosUsuariosDoGrupo(): void {
    this.relatorioGrupoUsuarioExcluir.forEach((grupoRelatorioExcluir) => {
      this.relatoriosUsuariosDoGrupo.forEach((relatorioUsuario) => {
        if (grupoRelatorioExcluir.idRelatorio == relatorioUsuario.idRelatorio) {
          this.usuarioRelatoriosExcluir.push(relatorioUsuario.id);
        }
      });
    });
  }

  gerarTransportePersistir(): Transporte {
    const transporte: Transporte = new Transporte(this.grupoUsuario);
    transporte.adicionar(this.grupoUsuarioMenusExcluir.map((usuarioMenu) => usuarioMenu.id));
    transporte.adicionar(this.relatorioGrupoUsuarioExcluir.map((relatorioUsuario) => relatorioUsuario.id));
    transporte.adicionar(this.usuarioMenus.filter((usuarioMenu) => !usuarioMenu.id));
    transporte.adicionar(this.usuarioMenusExcluir);
    transporte.adicionar(this.usuarioRelatorios.filter((usuarioRelatorio) => !usuarioRelatorio.id));
    transporte.adicionar(this.usuarioRelatoriosExcluir);
    return transporte;
  }

  limparIds(): void {
    for (const usuarioMenu of this.grupoUsuarioMenusExcluir) {
      usuarioMenu.id = null;
    }
    for (const relatorioUsuario of this.relatorioGrupoUsuarioExcluir) {
      relatorioUsuario.id = null;
    }
  }

  definirIds(): void {
    this.definirId([this.grupoUsuario], this.bibClasse.grupoUsuario, true);
    this.definirId(this.grupoUsuario.grupoUsuarioMenus, this.bibClasse.grupoUsuarioMenu);
    this.definirId(this.grupoUsuario.relatorioGrupoUsuarios, this.bibClasse.relatorioGrupoUsuario);
    this.definirId(this.usuarioMenus, this.bibClasse.usuarioMenu);
    this.definirId(this.usuarioRelatorios, this.bibClasse.relatorioUsuario);
  }

  limparListas(): void {
    this.relatorioGrupoUsuarios.forEach((rel) => ((rel.acessar = 'N'), (rel.favorito = 'N')));
    this.grupoUsuarioMenus.forEach((men) => ((men.acessar = 'N'), (men.inserir = 'N'), (men.editar = 'N'), (men.excluir = 'N'), (men.favorito = 'N')));
  }

  limparListasUsuario(): void {
    this.usuarioMenus = [];
    this.menusDoGrupoInseridos = [];
    this.usuarioMenusExcluir = [];
    this.relatoriosDoGrupoInseridos = [];
    this.usuarioRelatoriosExcluir = [];
  }
}
