<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" [filtros]="filtros" (filtroEmt)="listarRelatorio($event)" [apresentaEmpresa]="true">
  <ng-container *ngFor="let financeiro of duplicando ? financeirosDuplicados : (agrupando ? financeiroAgrupados : financeiros); let indiceRecibos = index">
    <div class="container" [ngClass]="indiceRecibos > 0 ? 'pt-5' : ''">
      <div class="row relatorio-titulo">{{ bibDialogo.recibo }}</div>
      <div class="row text-justify">
        {{ this.util.substituir(bibDialogo.declaracaoRecibo, [financeiro.tipo == 'R' ? 'Recebemos de' : 'Pagamos a', financeiro.parceiro, financeiro.tipoPessoa == 'F' ? bibDialogo.cpf : bibDialogo.cnpj, financeiro.parceiroCnpjCpf ? financeiro.parceiroCnpjCpf : ' - ', financeiro.valorCalculado < 0 ? (financeiro.valorCalculado * -1 | monetario) : financeiro.valorCalculado | monetario, this.util.escreverValor(financeiro.valorCalculado ? financeiro.valorCalculado : '')]) + (financeiro.idMovimentacao || (observacao && financeiro.observacao && agrupando == false) ? bibDialogo.referenteAQuitacao : '.') + (financeiro.idMovimentacao ? (financeiro.numero.toString().indexOf(',') > 0 ? bibDialogo.dasMovimentacoesN : bibDialogo.daMovimentacaoN) + ' ' + financeiro.numero : (observacao && financeiro.observacao && agrupando == false ? financeiro.observacao : '')) }}          
        <br>
        <ng-container *ngIf="financeiro.numeroFiscal">{{ bibDialogo.documentoFiscal + ' ' + 'Nº' + ' : ' + financeiro.numeroFiscal }}</ng-container><br/>
      </div>
    </div>
    <table>
      <tbody>
        <tr *ngIf="agrupando && expandindoFinanceiro" class="border-bottom border-dark" style="border-top: none;">
          <td>
            <table>
              <tr>
                <th class="r-1">{{ bibDialogo.numeroAbreviado +  ' ' + bibDialogo.financeiro }}</th>
                <th class="r-2">{{ bibDialogo.dataNegociacao }}</th>
                <th class="r-2">{{ bibDialogo.dataVencimento }}</th>
                <th class="r-2 text-right">{{ bibDialogo.valor }}</th>
              </tr>
              <tbody>
                <tr class="sem-borda" *ngFor="let _financeiro of financeiro?.financeiros">
                  <td>{{ _financeiro.numero }}</td>
                  <td>{{ _financeiro.dataNegociacao | data }}</td>
                  <td>{{ _financeiro.dataVencimento | data }}</td>
                  <td class="text-right">{{ _financeiro.valorCalculado | monetario }}</td>
                </tr>
            </table>
        </tr>
        <tr class="border-bottom border-dark" style="border-top: none;">
          <td>
            <table>
              <tr>
                <th class="r">{{ rotuloTipoDeTitulo }}</th>
                <th class="r-3 text-right">{{ bibDialogo.numeroDocumento }}</th>
                <th class="r-2 text-right">{{ bibDialogo.valor }}</th>
                <th class="r-2">{{ bibDialogo.dataPreDatada }}</th>
              </tr>
              <tbody>
                <tr class="sem-borda" *ngFor="let financeiroBaixa of financeiro.financeiroBaixas">
                  <td>{{ financeiroBaixa.tipoTitulo }}</td>
                  <td class="text-right">{{ financeiroBaixa.numeroDocumento }}</td>
                  <td class="text-right">{{ financeiroBaixa.valor | monetario }}<ajuda *ngIf="financeiroBaixa.valor != financeiroBaixa.valorConciliacao" [ajuda]="bibDialogo.valor + ' ' + bibDialogo.conciliacao.toLowerCase() + ': ' + (financeiroBaixa.valorConciliacao | monetario)"></ajuda></td>
                  <td>{{ financeiroBaixa.dataPrevista | data }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
        <div class="row" *ngIf="financeiro.juro > 0">
          <div class="ml-3">{{ bibDialogo.juro + ':'}} </div>
          <div class="col">{{ financeiro.juro | monetario }}</div>
        </div>
        <div class="row" *ngIf="financeiro.desconto > 0">
          <div class="ml-3">{{ bibDialogo.desconto + ':'}} </div>
          <div class="col">{{ financeiro.desconto | monetario }}</div>
        </div>     
        <div *ngIf="financeiro.observacao && agrupando == false && financeiro.idMovimentacao" class="row">
          <div class="ml-3 negrito">{{ bibDialogo.observacao + ': '}} </div>
          <div class="col">{{ financeiro.observacao  }}</div>
        </div>
        </tr>
        <tr class="sem-borda">
          <div class="row mt-2 justify-content-center">{{ financeiro.cidade ? financeiro.cidade + ', ' : '' }} {{ financeiro.financeiroBaixas[financeiro.financeiroBaixas.length - 1]?.dataBaixa | data: "dd 'de' MMMM 'de' yyyy" }}</div>
          <div class="mt-3">{{ bibDialogo.porSerVerdadeFirmamosOPresente }}</div>
          <div class="row pt-5 justify-content-center">
            <div class="lp-5 r-6 border-top border-dark relatorio-assinatura text-center">{{ financeiro.valorCalculado < 0 ? financeiro.parceiro : financeiro.loja }}</div>            
          </div>
        </tr>
      </tbody>
    </table>
  </ng-container>
</relatorioLayout>
