<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.movimentacaoPor + ' ' + menuNegociacaoApelido }} <ajuda [ajuda]="bibDialogo.movimentacoesGeraramFinanceiro" [posicao]="bibDialogo.esquerda"></ajuda></h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r">{{ menuNegociacaoApelido }}</th>
    <th class="r-1 text-right">{{ bibDialogo.saidas }}</th>
    <th class="r-1 text-right">{{ bibDialogo.devolucoes }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let negociacao of movimentacaoNegociacoes">
      <tr>
        <td>{{ negociacao.loja }}</td>
        <td>{{ negociacao.abreviacao + ' - ' + negociacao.negociacao }}</td>
        <td class="text-right">{{ negociacao.venda | monetario }}</td>
        <td class="text-right">{{ negociacao.devolucao | monetario }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoNegociacoes.length > 0">
    <tr>
      <td colspan="2"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoNegociacoes[0].valorVenda | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoNegociacoes[0].valorDevolucao | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
