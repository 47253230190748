<main class="auth auth-floated alturaPadrao">
  <form #formulario="ngForm" class="auth-form" autocomplete="off">
    <div class="mb-4">
      <div class="mb-3">
        <img [src]="'img/logomarca/logo_' + utilSessao.sistema.toLowerCase() + '.png'" height="72" />
      </div>
      <!-- <h1 class="h3"> Acesso </h1> -->
    </div>
    <div class="form-group mb-4">
      <label class="d-block text-left" for="inputUser">{{ bibDialogo.usuario }}</label>
      <div class="col-sm input-group">
        <input #focus type="text" id="inputUser" name="email" [disabled]="utilizaRegraLojaLogin" [(ngModel)]="email" class="form-control form-control-lg normal" required="true" />
        <div  *ngIf="dominio" class="input-group-append">
          <span class="input-group-text prefixo-sufixo" id="basic-addon2">{{ '@' + dominio }}</span>
        </div>
      </div>
    </div>
    <div class="form-group mb-4">
      <div *ngIf="!utilizaRegraLojaLogin" class="form-group">
        <label class="d-block text-left" for="inputPassword">{{ bibDialogo.senha }}</label>
        <div class="input-group p-0'">
          <input type="{{mostraSenha ? 'text' : 'password'}}" id="inputPassword" name="senha" [(ngModel)]="senha" class="form-control form-control-lg normal" required="true" />
          <div class="input-group-append">
            <icone *ngIf="senha && senha.length > 0" [icone]="mostraSenha ? 'fa fa-eye-slash' : 'fa fa-eye fa-fw'" [clique]="true" [centralizaVertical]="true" (iconeEmt)="mostraSenha == true ? mostraSenha = false : mostraSenha = true"></icone>
          </div>
        </div>
      </div>
      <div *ngIf="utilizaRegraLojaLogin" class="row">
        <div class="col-sm-12">
          <label class="d-block text-left" for="inputPassword">{{ bibDialogo.loja }}</label>
          <div class="form-group">
            <select [id]="'loja'" #focusLoja class="form-control" [ngModelOptions]="{ standalone: true }" [(ngModel)]="loja" required="true">
              <option *ngFor="let loja of lojas" [ngValue]="loja">{{ loja.nomeFantasia }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mb-4">
      <div *ngIf="!utilizaRegraLojaLogin" class="form-group">
        <button class="btn btn-lg btn-primary btn-block" [disabled]="formulario.form.invalid" (click)="acessar()">{{ bibDialogo.acessar }}</button>
      </div>
      <div *ngIf="utilizaRegraLojaLogin" class="form-group">
        <button class="btn btn-lg btn-primary btn-block" [disabled]="formulario.form.invalid" (click)="continuar()">{{ bibDialogo.continuar }}</button>
      </div>
    </div>
    <div *ngIf="mensagem" class="form-group">
      <div class="alert alert-danger alert-dismissible fade show">{{ mensagem }}</div>
    </div>
    <div class="versao">
      <p class="mb-0 text-muted text-center">© 2024 Todos os direitos reservados.</p>
      <p class="mb-0 text-muted text-center">
        Versão beta <b>{{ bibDialogo.versaoSistema }}</b>
      </p>
    </div>
  </form>
  <div id="announcement" class="auth-announcement" [ngStyle]="{ 'background-image': 'url(img/logomarca/login_' + utilSessao.sistema.toLowerCase() + '.png)' }"></div>
</main>

<script src="assets/vendor/particles.js/particles.min.js"></script>
<script>
  particlesJS.load('auth-header', 'assets/javascript/particles.json');
</script>
