import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoCancelamento } from 'src/app/modelo/movimentacaoCancelamento';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import filtros from './filtro';

@Component({
  templateUrl: './cancelamentoEstorno.component.html',
})
export class CancelamentoEstornoComponent extends PaginaComponent {
  public componente: any;
  public movimentacoes: Movimentacao[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('MOVIMENTACAO_INATIVA', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      if (this.movimentacoes.length > 0) {
        this.listarMovimentacaoCancelamento(this.movimentacoes.map((movimentacao) => movimentacao.id));
      }
    });
  }

  private listarMovimentacaoCancelamento(idMovimentacoes: number[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString())), this.bibServico.movimentacaoCancelamento).subscribe((res) => {
      this.vincularMovimentacaoMovimentacaoCancelamento(this.plainToClass(MovimentacaoCancelamento, res) as any);
    });
  }

  private vincularMovimentacaoMovimentacaoCancelamento(cancelamentos: MovimentacaoCancelamento[]): void {
    this.movimentacoes.forEach((movimentacao) => {
      const cancelamento: MovimentacaoCancelamento = cancelamentos.find((cancelamento) => cancelamento.idMovimentacao == movimentacao.id);
      if (cancelamento) {
        movimentacao.movimentacaoCancelamento = cancelamento;
      }
    });
  }

  abrirModalMovimentacao(id: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.utilSessao.setIdentificacao(new Identificacao('bloqueado', true));
    this.componente = MovimentacaoFrmComponent;
  }

  estornar(): void {
    if (confirm(bibDialogo.certezaEstornarCancelamento)) {
      let idsExcluir: number[] = [];
      this.movimentacoes.forEach((movimentacao) => {
        movimentacao.selecionado == 'S' ? idsExcluir.push(movimentacao.movimentacaoCancelamento.id) : null;
      });
      this.excluirLista(idsExcluir, this.bibServico.movimentacaoCancelamento).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.movimentacoes = this.movimentacoes.filter((movimentacao) => movimentacao.selecionado != 'S');
        }
      });
    }
  }
}
