<containerFormulario [vertical]="vertical" [idMenu]="idMenu" [rotulo]="_rotulo" [campoAtual]="campoAtual" [preenchido]="preenchido" [compacto]="compacto" [ajuda]="ajuda" [obrigatorio]="obrigatorio" [prefixo]="prefixo" [sufixo]="sufixo" [desabilitado]="desabilitado" [campoFor]="_id">
  <input #input class="form-control" [ngClass]="compacto ? 'compacto' : ''" [id]="_id" [name]="_rotulo" [maxlength]="exigidoCaracteres || maximoCaracteres" [ngModel]="campoAtual != null && campoAtual != '' && campoAtual.length > 0 ? campoAtual.trim() : campoAtual" (ngModelChange)="alterarTexto($event)" (focus)="navegar(true)" (blur)="navegar(false)" [disabled]="desabilitado" type="text" autocomplete="off" />
  <atencao *ngIf="!ehQuantidadeMimina() && minimoCaracterBusca > 0 && focado" [atencao]="minimoCaracterBusca" [tipo]="bibPropriedade.atencao.alerta" class="align-self-center mr-md-1"></atencao>
  <ajuda class="align-self-center mr-md-1" *ngIf="!_rotulo && ajuda" [ajuda]="ajuda"></ajuda>
  <label *ngIf="carregando" class="input-group-prepend" for="pi2">
    <span class="input-group-text">
      <div class="loader loader-sm"></div>
    </span>
  </label>
  <icone *ngIf="campoAtual || desabilitado" [icone]="'fas fa-times'" [clique]="true" [desabilitado]="desabilitado" (iconeEmt)="limpar()" [centralizaVertical]="true"></icone>
  <btnAdicional [compacto]="compacto" [icone]="(campoAtual?.length >= minimoCaracterBusca || minimoCaracterBusca == 0) && (preenchido == false || campoAtual != objeto?.nome) ? icone : null" [ajuda]="ajuda" [id]="'busca' + _id.charAt(0).toUpperCase() + _id.slice(1)" [desabilitado]="desabilitado && iconeDesabilitado" [focoIndice]="-1" (btnAdicional)="clicarBtnAdicional()" (mouseover)="focoBtnAdicional = true" (mouseout)="sairFocoBotao()"></btnAdicional>
  <div #listaBusca *ngIf="lista.length > 1" class="listaBusca">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let registro of lista; let i = index" [id]="i" [ngClass]="posicao == i ? 'selecionado' : ''" (mouseenter)="ehEscolhendo(true, i)" (mouseleave)="ehEscolhendo(false, i)" (click)="selecionar()">
        {{ registro.nome ? registro.nome.split('  ').join('&nbsp;') : '' || registro.razaoSocial }}
      </li>
    </ul>
  </div>
</containerFormulario>
