import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import filtros from './acessorio/filtro';
import { RelatorioComponent } from '../relatorio.component';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import totais from './acessorio/total';

@Component({
  selector: 'app-r1113',
  templateUrl: './r1113.component.html',
})
export class R1113Component extends RelatorioComponent {
  public conciliacoes: any[] = [];
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.conciliacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Conciliacao').lista;
      this.ordenarAgrupar(this.conciliacoes);
      this.ehImprimirRelatorio();
    });
  }
}
