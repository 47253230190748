import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarIcmsEntradaSaida',
  pure: false,
})
export class IcmsPipe implements PipeTransform {
  transform(impostosIcms: any[], argumentos?: any): any {
    switch (argumentos.filtro) {
      case 1:
        return impostosIcms.filter((icms) => icms.tipo == 1);
      case -1:
        return impostosIcms.filter((icms) => icms.tipo == -1);
      default:
        return impostosIcms;
    }
  }
}
