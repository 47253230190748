const bibServico = {
  agroDefesaCancelar: 'agroDefesaCancelar',
  agroDefesaConsultar: 'agroDefesaConsultar',
  agroDefesaEmitir: 'agroDefesaEmitir',
  safra: 'safra',
  contaReferencial: 'contaReferencial',
  caixa: 'caixa',
  parceiroPropriedade: 'parceiroPropriedade',
  unidadeParceiro: 'unidadeParceiro',
  email: 'email',
  aprovacao: 'aprovacao',
  aprovacaoMotivo: 'aprovacaoMotivo',
  cfopEmpresaConfiguracaoMovimentacaoContabil: 'cfopEmpresaConfiguracaoMovimentacaoContabil',
  bairro: 'bairro',
  baixa: 'baixa',
  balanco: 'balanco',
  nfeXmlPdf: 'nfeXmlPdf',
  balancoProduto: 'balancoProduto',
  bloqueioPeriodo: 'bloqueioPeriodo',
  boletoTecnospeed: 'boletos',
  buscarCEP: 'cep',
  categoria: 'categoria',
  categoriaRoteiroContabil: 'categoriaRoteiroContabil',
  centroResultado: 'centroResultado',
  cfop: 'cfop',
  cfopEmpresa: 'cfopEmpresa',
  chaveMovimentacao: 'chaveMovimentacao',
  cheque: 'cheque',
  cidade: 'cidade',
  classificacao: 'classificacao',
  classificacaoFiscal: 'classificacaoFiscal',
  classificacaoOperacao: 'classificacaoOperacao',
  cliente: 'cliente',
  clienteEspecial: 'clienteEspecial',
  colaborador: 'colaborador',
  colaboradorComprador: 'colaboradorComprador',
  colaboradorInterno: 'colaboradorInterno',
  colaboradorVendedor: 'colaboradorVendedor',
  comissao: 'comissao',
  comissaoFinanceiro: 'comissaoFinanceiro',
  conciliacao: 'conciliacao',
  conferencia: 'conferencia',
  conferenciaFinanceiro: 'conferenciaFinanceiro',
  conferenciaTitulo: 'conferenciaTitulo',
  configuracaoBalancoPatrimonial: 'configuracaoBalancoPatrimonial',
  configuracaoDemonstrativoResultado: 'configuracaoDemonstrativoResultado',
  configuracaoFinanceiroContabil: 'configuracaoFinanceiroContabil',
  configuracaoFinanceiroTotalContabil: 'configuracaoFinanceiroTotalContabil',
  configuracaoMovimentacaoContabil: 'configuracaoMovimentacaoContabil',
  configuracaoMovimentacaoTotalContabil: 'configuracaoMovimentacaoTotalContabil',
  configuracaoTransferenciaContabil: 'configuracaoTransferenciaContabil',
  configuracaoTransferenciaTotalContabil: 'configuracaoTransferenciaTotalContabil',
  conta: 'conta',
  contaBancaria: 'contaBancaria',
  contaCaixa: 'contaCaixa',
  contaBancariaCorrentista: 'contaBancariaCorrentista',
  contaContabil: 'contaContabil',
  contaUsuario: 'contaUsuario',
  contabil: 'contabil',
  cor: 'cor',
  cpf: 'cpf',
  credito: 'credito',
  cstIcms: 'cstIcms',
  cstIpi: 'cstIpi',
  cstPisCofins: 'cstPisCofins',
  cte: 'cte',
  cteDocumentoFiscal: 'cteDocumentoFiscal',
  cteInformacaoCarga: 'cteInformacaoCarga',
  cultura: 'cultura',
  culturaCompendio: 'culturaCompendio',
  desfazimento: 'desfazimento',
  desfazimentoProduto: 'desfazimentoProduto',
  destinoParceiro: 'destinoParceiro',
  devolucao: 'devolucao',
  eidafEmitir: 'eidafEmitir',
  eidafCancelar: 'eidafCancelar',
  eidafConsultar: 'eidafConsultar',
  embalagemSicca: 'embalagemSicca',
  empresa: 'empresa',
  empresaFilial: 'empresaFilial',
  empresaOperacaoBloqueio: 'empresaOperacaoBloqueio',
  empresaOperacaoContabil: 'empresaOperacaoContabil',
  empresaOperacaoContabilFinanceiro: 'empresaOperacaoContabilFinanceiro',
  encerramentoContabil: 'encerramentoContabil',
  endereco: 'endereco',
  enderecoTipo: 'enderecoTipo',
  entrega: 'entrega',
  entregaProduto: 'entregaProduto',
  equipamento: 'equipamento',
  equipe: 'equipe',
  equipeParceiro: 'equipeParceiro',
  especie: 'especie',
  estado: 'estado',
  estoque: 'estoque',
  fabricante: 'fabricante',
  familia: 'familia',
  filtro: 'filtro',
  filtroCombo: 'filtroCombo',
  financeiro: 'financeiro',
  financeiroBaixa: 'financeiroBaixa',
  financeiroBaixaPro: 'financeiroBaixaPro',
  financeiroBoleto: 'financeiroBoleto',
  financeiroNegociacao: 'financeiroNegociacao',
  fornecedor: 'fornecedor',
  foto: 'foto',
  geradorNfe: 'geradorNfe',
  grupo: 'grupo',
  grupoPai: 'grupoPai',
  grupoAcesso: 'grupoAcesso',
  grupoAcessoHorario: 'grupoAcessoHorario',
  grupoAcessoLocal: 'grupoAcessoLocal',
  grupoContaContabil: 'grupoContaContabil',
  grupoContabil: 'grupoContabil',
  grupoIcms: 'grupoIcms',
  grupoIcmsSt: 'grupoIcmsSt',
  grupoParceiroComissao: 'grupoParceiroComissao',
  grupoUsuario: 'grupoUsuario',
  grupoUsuarioMenu: 'grupoUsuarioMenu',
  grupoValor: 'grupoValor',
  historico: 'historico',
  historicoDetalhe: 'historicoDetalhe',
  imposto: 'imposto',
  impostoDevolucao: 'impostoDevolucao',
  integracao: 'integracao',
  monitoramento: 'monitoramento',
  integracaoResultado: 'integracaoResultado',
  integracaoUsuario: 'integracaoUsuario',
  lancamentoContabil: 'lancamentoContabil',
  local: 'local',
  logarUsuario: 'logarUsuario',
  loja: 'loja',
  lojaDocumentoFiscal: 'lojaDocumentoFiscal',
  lojaEstado: 'lojaEstado',
  lojaUsuario: 'lojaUsuario',
  lote: 'lote',
  mdfe: 'mdfe',
  mdfeDocumentoFiscal: 'mdfeDocumentoFiscal',
  mdfePercurso: 'mdfePercurso',
  menu: 'menu',
  menuEmpresa: 'menuEmpresa',
  menuModuloEmpresa: 'menuModuloEmpresa',
  modulo: 'modulo',
  moduloEmpresa: 'moduloEmpresa',
  motivoDesfazimento: 'motivoDesfazimento',
  motivoOcorrencia: 'motivoOcorrencia',
  motorista: 'motorista',
  movimentacao: 'movimentacao',
  movimentacaoAbrir: 'movimentacaoAbrir',
  movimentacaoCancelamento: 'movimentacaoCancelamento',
  movimentacaoCartaCorrecao: 'movimentacaoCartaCorrecao',
  movimentacaoChave: 'movimentacaoChave',
  movimentacaoDevolucao: 'movimentacaoDevolucao',
  movimentacaoEntrega: 'movimentacaoEntrega',
  movimentacaoGrade: 'movimentacaoGrade',
  movimentacaoNfe: 'movimentacaoNfe',
  movimentacaoPar: 'movimentacaoParcela',
  movimentacaoParcela: 'movimentacaoParcela',
  movimentacaoProduto: 'movimentacaoProduto',
  movimentacaoProdutoImposto: 'movimentacaoProdutoImposto',
  movimentacaoProdutoPendente: 'movimentacaoProdutoPendente',
  movimentacaoProdutoQuantidade: 'movimentacaoProdutoQuantidade',
  movimentacaoProdutoTotalizar: 'movimentacaoProdutoTotalizar',
  movimentacaoQuantidade: 'movimentacaoQuantidade',
  natureza: 'natureza',
  naturezaOperacao: 'naturezaOperacao',
  ncm: 'ncm',
  ncmEmpresa: 'ncmEmpresa',
  negociacao: 'negociacao',
  negociacaoComRestricao: 'negociacaoComRestricao',
  nfe: 'nfe',
  nfeCancelamento: 'nfeCancelamento',
  nomenclatura: 'nomenclatura',
  nomenclaturaEmpresa: 'nomenclaturaEmpresa',
  operacao: 'operacao',
  operacaoParametro: 'operacaoParametro',
  pais: 'pais',
  patrimonial: 'patrimonial',
  patrimonialLancamentoContabil: 'patrimonialLancamentoContabil',
  parametro: 'parametro',
  parametroValor: 'parametroValor',
  parceiro: 'parceiro',
  parceiroArt: 'parceiroArt',
  parceiroAtualizacao: 'parceiroAtualizacao',
  parceiroCobranca: 'parceiroCobranca',
  parceiroOcorrencia: 'parceiroOcorrencia',
  parceiroContato: 'parceiroContato',
  parceiroCorrentista: 'parceiroCorrentista',
  parceiroDestino: 'parceiroDestino',
  parceiroEndereco: 'parceiroEndereco',
  parceiroHistorico: 'parceiroHistorico',
  parceiroHistoricoLimite: 'parceiroHistoricoLimite',
  parceiroNegociacao: 'parceiroNegociacao',
  parceiroTelefone: 'parceiroTelefone',
  parceiroVeiculo: 'parceiroVeiculo',
  permissao: 'permissao',
  polegada: 'polegada',
  praca: 'praca',
  praga: 'praga',
  pragaCompendio: 'pragaCompendio',
  preferencia: 'preferencia',
  produto: 'produto',
  produtoAlternativo: 'produtoAlternativo',
  produtoComissao: 'produtoComissao',
  produtoCustoGerencial: 'produtoCustoGerencial',
  produtoCustoNfe: 'produtoCustoNfe',
  produtoCustoReposicao: 'produtoCustoReposicao',
  produtoEstoqueMinimoMaximo: 'produtoEstoqueMinimoMaximo',
  produtoFormulado: 'produtoFormulado',
  produtoFormuladoDose: 'produtoFormuladoDose',
  produtoFormuladoRestricao: 'produtoFormuladoRestricao',
  produtoFoto: 'produtoFoto',
  produtoHistoricoAnual: 'produtoHistoricoAnual',
  produtoIcms: 'produtoIcms',
  produtoIcmsEspecifico: 'produtoIcmsEspecifico',
  produtoIcmsSt: 'produtoIcmsSt',
  produtoPisCofins: 'produtoPisCofins',
  produtoLojaEspecifica: 'produtoLojaEspecifica',
  produtoMedia: 'produtoMedia',
  produtoParceiro: 'produtoParceiro',
  produtoParceiroComissao: 'produtoParceiroComissao',
  produtoPorUnidade: 'produtoPorUnidade',
  produtoPrecoVenda: 'produtoPrecoVenda',
  produtoUnidade: 'produtoUnidade',
  produtoValor: 'produtoValor',
  ramoAtividade: 'ramoAtividade',
  receita: 'receita',
  receituario: 'receituario',
  receituarioEletronico: 'receituarioEletronico',
  receituarioEletronicoGrade: 'receituarioEletronicoGrade',
  recomendacao: 'recomendacao',
  relacionamento: 'relacionamento',
  relatorio: 'relatorio',
  relatorioAdicional: 'relatorioAdicional',
  relatorioAdicionalEmpresa: 'relatorioAdicionalEmpresa',
  relatorioGrupoUsuario: 'relatorioGrupoUsuario',
  relatorioMenu: 'relatorioMenu',
  relatorioModuloEmpresa: 'relatorioModuloEmpresa',
  relatorioResultado: 'relatorioResultado',
  relatorioSql: 'relatorioSql',
  relatorioSqlResultado: 'relatorioSqlResultado',
  relatorioUsuario: 'relatorioUsuario',
  relatorioUsuarioMenu: 'relatorioUsuarioMenu',
  remessaTecnospeed: 'remessas',
  resultarAcessoSemLogin: 'resultarAcessoSemLogin',
  regiao: 'regiao',
  romaneioEntrega: 'romaneioEntrega',
  romaneioEntregaDocumentoFiscal: 'romaneioEntregaDocumentoFiscal',
  roteiroContabil: 'roteiroContabil',
  roteiroContabilRateio: 'roteiroContabilRateio',
  saldoBancario: 'saldoBancario',
  separacao: 'separacao',
  spedContribuicao: 'spedContribuicao',
  spedFiscal: 'spedFiscal',
  subGrupo: 'subGrupo',
  tabelaPreco: 'tabelaPreco',
  tabelaPrecoCliente: 'tabelaPrecoCliente',
  tabelaPrecoColaborador: 'tabelaPrecoColaborador',
  tabelaPrecoConfiguracao: 'tabelaPrecoConfiguracao',
  tabelaPrecoGrupo: 'tabelaPrecoGrupo',
  tabelaPrecoProduto: 'tabelaPrecoProduto',
  tipoLancamentoContabil: 'tipoLancamentoContabil',
  tipoTitulo: 'tipoTitulo',
  tipoTituloCaixa: 'tipoTituloCaixa',
  totalFinanceiroContabil: 'totalFinanceiroContabil',
  totalMovimentacaoContabil: 'totalMovimentacaoContabil',
  totalTransferenciaContabil: 'totalTransferenciaContabil',
  transferencia: 'transferencia',
  transferenciaCheque: 'transferenciaCheque',
  transportadora: 'transportadora',
  unidade: 'unidade',
  uploadFoto: 'uploadFoto',
  usadoComo: 'usadoComo',
  usuario: 'usuario',
  usuarioCategoriaRoteiro: 'usuarioCategoriaRoteiro',
  usuarioConta: 'usuarioConta',
  usuarioGrupoUsuario: 'usuarioGrupoUsuario',
  usuarioLogar: 'usuarioLogar',
  usuarioLogarCompleto: 'usuarioLogarCompleto',
  usuarioLoja: 'usuarioLoja',
  usuarioMenu: 'usuarioMenu',
  validarUsuario: 'validarUsuario',
  vinculo: 'vinculo',
  visita: 'visita',
  visitaFoto: 'visitaFoto',
  veiculoParceiro: 'veiculoParceiro',
};

export default bibServico;
