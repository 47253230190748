<layout (filtroEmt)="listar($event)" [filtros]="filtros">
  <registro [posicao]="posicao" [paginacao]="paginacao" [numeroRegistro]="grupos.length"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-95 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.grupo }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let grupo of grupos; let i = index">
        <tr *ngIf="i >= posicao && i < posicao + paginacao && grupo.id != 0">
          <td>
            <a *ngIf="grupo.pai == 'N'" href="#" (click)="ir(grupo.id, i, grupos)"> {{ grupo.nome.split(' ').join('&nbsp;') }}</a>
            <a *ngIf="grupo.pai == 'S'" href="#" (click)="ir(grupo.id, i, grupos)"
              ><strong>{{ grupo.nome.split(' ').join('&nbsp;') }}</strong></a
            >
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(grupo.id, i, grupos)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [paginacao]="paginacao" [numeroRegistro]="grupos.length" (alteracao)="posicao = $event"></paginacao>
</layout>
