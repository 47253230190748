<ng-container class="border-bottom border-dark">
  <td class="p-0">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.tabelaPreco }}</th>
          <th class="text-right r-1">{{ bibDialogo.dataDe }}</th>
          <th class="text-right r-1">{{ bibDialogo.dataAte }}</th>
          <th class="text-right r-1">{{ bibDialogo.taxa }}</th>
          <th class="text-right r-2">{{ bibDialogo.valor }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let tabelaPreco of produto.tabelasPrecos; let i = index">
          <tr>
            <td>{{ tabelaPreco.tabelaPreco }}</td>
            <td class="text-right">{{ tabelaPreco.dataInicial | data }}</td>
            <td class="text-right">{{ tabelaPreco.dataFinal | data }}</td>
            <td class="text-right">{{ tabelaPreco.juroPercentual | monetario }}</td>
            <td class="text-right">{{ tabelaPreco.valorVenda | monetario }}</td>
          </tr>
          <tr *ngIf="tabelaPreco.ultimo == 'S'" class="espaco"> {{ '  . ' }}</tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
