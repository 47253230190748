<layout [subTitulo]="loja ? bibDialogo.loja.toUpperCase() + ': ' + loja.nomeFantasia : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div [ngClass]="ehModoClaro ? 'bg-cinza-fraco' : 'badge-light'" class="row" *ngIf="grupoValores.length > 1">
    <div [ngClass]="compacto ? 'pt-3' : 'pt-3'" class="col-sm-3 d-flex">
      <p>{{ bibDialogo.atualizarMargemLote }}</p>
      <check [campo]="atualizarMargem" (alteracao)="atualizarMargem = $event"></check>
    </div>
    <div [ngClass]="compacto ? 'pt-3' : ''" class="col-sm-3" *ngIf="atualizarMargem == 'S'">
      <lista [campo]="margemEscolhida" [obrigatorio]="true" (alteracao)="margemEscolhida = $event.id" [lista]="margens"></lista>
    </div>
    <div [ngClass]="compacto ? 'pt-3' : ''" class="col-sm-3" *ngIf="atualizarMargem == 'S'">
      <lista [campo]="formaEscolhida" [obrigatorio]="true" (alteracao)="formaEscolhida = $event.id" [lista]="formaEscolhidas"></lista>
    </div>
    <div [ngClass]="compacto ? 'pt-3' : ''" class="col-sm-2" *ngIf="atualizarMargem == 'S'">
      <decimal [campo]="percentual" [sufixo]="'%'" (alteracao)="percentual = $event"></decimal>
    </div>
    <div [ngClass]="compacto ? 'pt-1' : 'pt-1'" class="col-sm-1" *ngIf="atualizarMargem == 'S'">
      <botao [compacto]="compacto" [legenda]="bibDialogo.aplicar" (botaoEmt)="atualizarMargemGrupoValor()"></botao>
    </div>
  </div>
  <div class="mt-2">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-65 clique" (click)="listar(add('grupo'), this.utilSessao.posicao, this.paginacao)">{{ rotuloGrupo }}</th>
        <th class="l-15 text-right clique" (click)="listar(add('margemGerencial'), this.utilSessao.posicao, this.paginacao)">{{ bibNomenclatura.escrever(bibNomenclatura.margemGerencial) }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.margemGerencialManterProduto"></ajuda></th>
        <th class="l-15 text-right clique" (click)="listar(add('margemLucro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.margemLucro }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let grupoValor of grupoValores; let i = index">
        <tr class="border-0">
          <td class="border-0">{{ grupoValor.grupo }}</td>
          <td class="text-right border-0">
            <decimal [id]="'margemGerencial'" [campo]="grupoValor.margemGerencial" (alteracao)="setMargemGerencial(grupoValor, $event)" [numeroCasasDecimais]="4" [foco]="i == 0" [sufixo]="mostraSufixoPercentual ? '%' : ''"></decimal>
          </td>
          <td class="text-right border-0">
            <decimal [id]="'margemLucro'" [campo]="grupoValor.margemLucro" (alteracao)="setMargemLucro(grupoValor, $event)" [numeroCasasDecimais]="4" [foco]="i == 0" [sufixo]="mostraSufixoPercentual ? '%' : ''"></decimal>
          </td>
          <td>
            <btnAdicional *ngIf="grupoValor.id != null" [id]="'excluirGrupoValor' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoValor(grupoValor.id, grupoValor.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <div class="row">
    <atencao *ngIf="this.grupoValores.length == 0" [atencao]="atencao" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>
</layout>
<botao *ngIf="this.grupoValores.length > 0" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGrupoValor()" [desabilitado]="!temPermissaoEdicao"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
