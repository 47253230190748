import { Component, Input } from '@angular/core';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { Resultado } from 'src/app/modelo/resultado';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'movimentacaoParcelaFrm',
  templateUrl: './movimentacaoParcelaFrm.component.html',
  styleUrls: ['./movimentacaoParcelaFrm.component.css'],
})
export class MovimentacaoParcelaFrmComponent extends PaginaComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;
  public acrescimoDiaFixo: number = 0;
  public bibPropriedade = bibPropriedade;
  public cartaoEspecies: number[] = [2, 10];
  public listaFrequencia: any[] = [];

  public entrada: any[] = [
    { id: 'S', nome: this.bibDialogo.sim },
    { id: 'N', nome: this.bibDialogo.nao },
  ];

  gerarParcelas(): void {
    if (this.ehValido()) {
      if (this.desejaGerarParcela()) {
        this.movimentacaoControlador.gerarParcela();
      }
    }
  }

  ehValido(): boolean {
    const ehValidoQtdParcela: boolean = this.ehValidoQtdParcela();
    const ehValidoDias: boolean = this.ehValidoDiasFixos();
    const ehValidoEntrada: boolean = this.ehValidoEntrada();
    return ehValidoQtdParcela && ehValidoDias && ehValidoEntrada;
  }

  ehValidoQtdParcela(): boolean {
    if (this.movimentacaoControlador.parcelaQuantidade > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoDiasFixos(): boolean {
    if (!this.movimentacaoControlador.diasEntreBloqueado || !this.movimentacaoControlador.diaFixoBloqueado) {
      if (this.movimentacaoControlador.parcelaQuantidade > 1 && !this.movimentacaoControlador.diasEntre && !this.movimentacaoControlador.diaFixo) {
        const cartaoEspecies: number[] = [2, 10];
        if (this.movimentacaoControlador.movimentacao.negociacao && this.movimentacaoControlador.negociacoes && cartaoEspecies.includes(this.movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie)) {
          return true;
        }
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.verifiqueDiasEntreDiaFixo));
        return false;
      }
    }
    return true;
  }

  ehValidoEntrada(): boolean {
    if (this.movimentacaoControlador.parcelaEntrada == 'S') {
      if (this.util.coalesce(this.movimentacaoControlador.valorEntrada, 0) <= 0) {
        this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.bibDialogo.informeValorEntrada));
        return false;
      }
    }
    return true;
  }

  desejaGerarParcela(): boolean {
    if (this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length > 0) {
      if (confirm(this.movimentacaoControlador.bibDialogo.desejaExcluirParcela)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  setIgnorarMesAtual() {
    this.movimentacaoControlador.ignorarMesAtual = this.movimentacaoControlador.ignorarMesAtual == false ? true : false;
  }

  setValorParcela(movimentacaoParcela: MovimentacaoParcela, valor: number): void {
    movimentacaoParcela.setValorParcela(valor);
    this.movimentacaoControlador.calcularDiferencaParcelaTotal();
  }

  setDataVencimento(movimentacaoParcela: MovimentacaoParcela, dataVencimento: Date): void {
    movimentacaoParcela.dataVencimento = dataVencimento;
    this.movimentacaoControlador.calcularMediaDiaPrazo();
  }

  setMovimentacaoEntrada(): void {
    this.movimentacaoControlador.parcelaEntrada = this.movimentacaoControlador.parcelaEntrada == 'N' ? 'S' : 'N';
    if (this.movimentacaoControlador.parcelaEntrada == 'N') {
      this.movimentacaoControlador.valorEntrada = null;
      this.movimentacaoControlador.idTipoTituloEntrada = null;
    }
  }

  excluirParcela(movimentacaoParcela: MovimentacaoParcela, indice: number): void {
    super.ehExcluir(indice, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.excluirParcelas, movimentacaoParcela.id);
    this.movimentacaoControlador.calcularDiferencaParcelaTotal();
    this.movimentacaoControlador.calcularMediaDiaPrazo();
  }
}
