<relatorioLayout [empresa]="empresa" [relatorio]="relatorio" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (filtroEmt)="listarRelatorio($event)" [filtros]="filtros">
  <r2001ApuracaoLucro [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [apuracaoDeLucros]="apuracaoDeLucros"></r2001ApuracaoLucro>
  <r2001ApuracaoLucroClienteEspecial [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [apuracaoLucroClienteEspeciais]="apuracaoLucroClienteEspeciais"></r2001ApuracaoLucroClienteEspecial>
  <r2001MovimentacaoDistorcida [movimentacaoDistorcidas]="movimentacaoDistorcidas" [empresaRegraCasasDecimais]="empresaRegraCasasDecimais"></r2001MovimentacaoDistorcida>
  <r2001Devolucao [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [devolucoes]="devolucoes"></r2001Devolucao>
  <r2001DevolucaoEspecial [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [devolucaoClienteEspeciais]="devolucaoClienteEspeciais"></r2001DevolucaoEspecial>
  <r2001MovimentacaoNegociacao [movimentacaoNegociacoes]="movimentacaoNegociacoes"></r2001MovimentacaoNegociacao>
  <r2001FinanceiroReceitaDespesa [financeiroReceitaDespesas]="financeiroReceitaDespesas"></r2001FinanceiroReceitaDespesa>
  <r2001MovimentoFiscal [movimentacaoFiscais]="movimentacaoFiscais"></r2001MovimentoFiscal>
  <r2001MovimentacaoAjusteEstoque [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacaoAjusteEstoques]="movimentacaoAjusteEstoques"></r2001MovimentacaoAjusteEstoque>
  <r2001MovimentacaoManual [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacaoManuais]="movimentacaoManuais" [movimentacaoManualApelido]="movimentacaoManualApelido"></r2001MovimentacaoManual>
  <r2001MovimentacaoOcorrencia [movimentacaoOcorrencias]="movimentacaoOcorrencias"></r2001MovimentacaoOcorrencia>
  <r2001DebitoMaiorLimite [debitoMaiorLimites]="debitoMaiorLimites"></r2001DebitoMaiorLimite>
  <r2001AlteracaoLimiteMaximo [alteracaoLimiteMaximos]="alteracaoLimiteMaximos"></r2001AlteracaoLimiteMaximo>
  <r2001MovimentacaoVendedor [movimentacaoVendedores]="movimentacaoVendedores"></r2001MovimentacaoVendedor>
  <r2001MovimentacaoGrupoProduto *ngIf="mostraResumoGrupo" [movimentacaoGrupoProdutos]="movimentacaoGrupoProdutos"></r2001MovimentacaoGrupoProduto>
  <r2001MovimentacaoFabricante *ngIf="mostraResumoFabricante" [movimentacaoFabricantes]="movimentacaoFabricantes"></r2001MovimentacaoFabricante>
  <r2001MovimentacaoResumoNegociacao [movimentacaoResumoNegociacoes]="movimentacaoResumoNegociacoes"></r2001MovimentacaoResumoNegociacao>
  <r2001NotasNaoTransmitidas [notasNaoTransmitidas]="notasNaoTransmitidas"></r2001NotasNaoTransmitidas>
</relatorioLayout>
