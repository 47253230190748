import { Component } from '@angular/core';
import { format } from 'date-fns';
import { bibServicoNs } from 'src/app/biblioteca/bibServicoNs';
import { bibServicoTecnoSpeed } from 'src/app/biblioteca/bibServicoTecnoSpeed';
import { Correcao } from 'src/app/integracao/tecnospeed/nfe/correcao';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoCartaCorrecao } from 'src/app/modelo/movimentacaoCartaCorrecao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './cartaCorrecaoFrm.component.html',
})
export class CartaCorrecaoFrmComponent extends PaginaComponent {
  private idTecnospeed: string;
  public bibServicoTecnoSpeed: bibServicoTecnoSpeed = new bibServicoTecnoSpeed();
  public bibServicoNs: bibServicoNs = new bibServicoNs();
  public correcao: Correcao = new Correcao();
  public movimentacaoCartaCorrecao: MovimentacaoCartaCorrecao = new MovimentacaoCartaCorrecao();

  ngOnInit(): void {
    this.idTecnospeed = this.utilSessao.getIdentificacao('idTecnospeed')?.conteudo;
    this.movimentacaoCartaCorrecao.idMovimentacao = this.utilSessao.getIdentificacao('idMovimentacao')?.conteudo;
  }

  persistirCartaCorrecao(): void {
    this.movimentacaoCartaCorrecao.status = -1;
    if (this.ehValido()) {
      super.persistir(new Transporte(this.movimentacaoCartaCorrecao), this.bibServico.movimentacaoCartaCorrecao, null);
      this.emitirCartaCorrecao();
    }
  }

  private emitirCartaCorrecao(): void {
    this.correcao.correcao = this.movimentacaoCartaCorrecao.justificativa;
    if (this.idTecnospeed) {
      this.comunicacaoTecnoSpeedService.persistir(this.bibServicoTecnoSpeed.getUrlCorrigiNfe(this.idTecnospeed), this.correcao).subscribe();
    } else {

      this.emitirCartaCorrecaoNs();

    }
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibServico.movimentacaoCartaCorrecao, this.movimentacaoCartaCorrecao));
  }

  emitirCartaCorrecaoNs() {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
      "chNFe": this.utilSessao.getIdentificacao('chaveFiscal').conteudo,
      "tpAmb": this.bibServicoNs.getAmbiente(),
      "dhEvento": format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'-03:00'"),
      "nSeqEvento": "1",
      "xCorrecao": this.movimentacaoCartaCorrecao.justificativa.replace(/\n/g, '').replace(/\t/g, '').replace(': ', ':')
    });
    this.comunicacaoNsService.persistir(this.bibServicoNs.getUrlCartaCorrecao(), transporte).subscribe(
      (res) => {
        console.log(res)
      },
      (error) => {
        console.log(error)
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEnviarCartaCorrecao));
      }
    );
  }

  ehValido(): boolean {
    if (this.movimentacaoCartaCorrecao?.justificativa && this.movimentacaoCartaCorrecao?.status != 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
