import { Total } from '../../ns/nfe/total';
import { Cobr } from './cobr';
import { Dest } from './dest';
import { Det } from './det';
import { Emit } from './emit';
import { Ide } from './ide';
import { InfAdic } from './infAdic';
import { Pag } from './pag';
import { Transp } from './transp';


export class NfeNs {
  public versao: string = "4.00";
  public ide: Ide;
  public emit: Emit;
  public dest: Dest;
  public total: Total;
  public transp: Transp;
  public cobr: Cobr;
  public pag: Pag;
  public infAdic: InfAdic;
  public det: Det[];
}