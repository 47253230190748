<div *ngIf="movimentacaoControlador && (movimentacaoControlador.movimentacao.valorTotal > 0 || (movimentacaoControlador.nota && movimentacaoControlador.movimentacao.valorBloqueado > 0))">
  <div class="row">
    <titulo class="col-sm-2 p-0 ml-sm-2" [titulo]="movimentacaoControlador.bibDialogo.vencimentos" [imagem]="movimentacaoControlador.bibImagem.dinheiro"></titulo>
    <ajuda *ngIf="movimentacaoControlador.movimentacao.negociacao && movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie && cartaoEspecies.includes(movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie)" [ngClass]="!movimentacaoControlador.compacto ? 'alinhar-ajuda' : 'alinhar-ajuda-compacto'" [ajuda]="bibDialogo.ajudaCartaoParcelamento" [posicao]="bibDialogo.esquerda" [ajudaDireita]="true"></ajuda>
  </div>
  <div *ngIf="!movimentacaoControlador.umaParcelaAvista">
    <div *ngIf="!movimentacaoControlador.bloqueado" class="row">
      <div class="col-sm-10">
        <div class="row">
          <div class="col-sm-4">
            <div class="col-sm-9 p-0">
              <texto [rotulo]="bibDialogo.quantidadeParcelas" [obrigatorio]="true" [campo]="movimentacaoControlador.movimentacao.valorTotal > 0 ? movimentacaoControlador.movimentacao.parcelamento && movimentacaoControlador.movimentacao.parcelamento > 0 ? movimentacaoControlador.movimentacao.parcelamento : movimentacaoControlador.parcelaQuantidade : 1" (alteracao)="movimentacaoControlador.parcelaQuantidade = $event" [desabilitado]="movimentacaoControlador.parcelaQuantidadeBloqueado && movimentacaoControlador.bloqueiaAlteracao" [tipo]="bibPropriedade.texto.inteiro"></texto>
            </div>
          </div>
          <texto class="col-sm-3" [rotulo]="movimentacaoControlador.bibDialogo.diasEntre" [campo]="movimentacaoControlador.diasEntre" (alteracao)="movimentacaoControlador.setDiasEntre($event)" [obrigatorio]="false" [tipo]="bibPropriedade.texto.inteiro" [desabilitado]="movimentacaoControlador.diasEntreBloqueado"></texto>
          <p class="col-sm-1 sublinhado">{{ 'ou' }}</p>
          <div class="col-sm-4">
            <div class="col-sm-9 p-0">
              <texto [rotulo]="movimentacaoControlador.bibDialogo.diaFixo" [campo]="movimentacaoControlador.diaFixo" (alteracao)="movimentacaoControlador.setDiaFixo($event)" [obrigatorio]="false" [tipo]="bibPropriedade.texto.inteiro" [valorMaximo]="31" [desabilitado]="movimentacaoControlador.diaFixoBloqueado"></texto>
              <check class="mt-2" *ngIf="movimentacaoControlador.diaFixo > 0" [rotulo]="bibDialogo.ignorarMesAtual" (click)="setIgnorarMesAtual()" [compacto]="true"></check>
            </div>
          </div>
        </div>
        <div class="row">
          <lista class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.entrada" [campo]="movimentacaoControlador.parcelaEntrada" [lista]="entrada" (alteracao)="setMovimentacaoEntrada()" [obrigatorio]="true" [ajuda]="movimentacaoControlador.movimentacao.negociacao && movimentacaoControlador.movimentacao.negociacao.aVista != '1' ? util.substituir(bibDialogo.entradaPermitida, [movimentacaoControlador.menuNegociacao.apelido.toLowerCase()]) : null"></lista>
          <lista *ngIf="movimentacaoControlador.parcelaEntrada == 'S'" class="col-sm-4" [id]="'tipoDeTitulo'" [rotulo]="movimentacaoControlador.menuTipoTitulo.apelido" [campo]="movimentacaoControlador.idTipoTituloEntrada" (alteracao)="movimentacaoControlador.idTipoTituloEntrada = $event.id" [lista]="movimentacaoControlador.tipoTituloPermitidos"></lista>
          <decimal *ngIf="movimentacaoControlador.parcelaEntrada == 'S'" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.valorEntrada" [campo]="movimentacaoControlador.valorEntrada" (alteracao)="movimentacaoControlador.valorEntrada = $event" [obrigatorio]="true"></decimal>
        </div>
      </div>
      <div class="col-sm-2 alinhar-abaixo">
        <div>
          <botao class="p-0 m-0 mr-0" [legenda]="movimentacaoControlador.bibDialogo.gerarParcelas" [desabilitado]="movimentacaoControlador.bloqueado" (botaoEmt)="gerarParcelas()" [compacto]="movimentacaoControlador.compacto"></botao>
          <atencao class="ml-0" *ngIf="movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 0" [atencao]="'!'" [tipo]="movimentacaoControlador.bibPropriedade.atencao.cuidado"></atencao>
        </div>
      </div>
    </div>
    <texto *ngIf="movimentacaoControlador.bloqueiaAlteracao == false" [rotulo]="bibDialogo.quantidadeParcelas" [obrigatorio]="true" [campo]="movimentacaoControlador.movimentacao.valorTotal > 0 ? movimentacaoControlador.movimentacao.parcelamento && movimentacaoControlador.movimentacao.parcelamento > 0 ? movimentacaoControlador.movimentacao.parcelamento : movimentacaoControlador.parcelaQuantidade : 1" (alteracao)="movimentacaoControlador.movimentacao.parcelamento = $event" [desabilitado]="movimentacaoControlador.parcelaQuantidadeBloqueado && movimentacaoControlador.bloqueiaAlteracao" [tipo]="bibPropriedade.texto.inteiro"></texto>
    <div class="row" *ngIf="(movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 0 && movimentacaoControlador.movimentacao.valorTotal > 0 && !movimentacaoControlador.bloqueado) || (movimentacaoControlador.nota && movimentacaoControlador.movimentacao.valorBloqueado > 0 && movimentacaoControlador.movimentacao.importacaoXml == 'N' && movimentacaoControlador.movimentacao.movimentacaoParcelas.length == 0)">
      <p class="col-sm-12 pl-3 mt-2">
        {{ movimentacaoControlador.bibDialogo.nenhumaAbr + ' ' + movimentacaoControlador.bibDialogo.parcelamento.toLowerCase() + ', ' }} <strong class="clique" (click)="gerarParcelas()">{{ movimentacaoControlador.bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ movimentacaoControlador.bibDialogo.para.toLowerCase() + ' ' + movimentacaoControlador.bibDialogo.gerarParcelas.toLowerCase() }}
      </p>
    </div>
    <div class="row m-0">
      <div class="col-sm-4 p-0" *ngFor="let movimentacaoParcela of movimentacaoControlador.movimentacao.movimentacaoParcelas; let i = index">
        <table class="table table-responsive-sm">
          <thead *ngIf="i < 3">
            <tr>
              <th class="l-10"></th>
              <th class="l-45">{{ movimentacaoControlador.bibDialogo.vencimento.toLowerCase() }}</th>
              <th class="l-45 text-center">{{ movimentacaoControlador.bibDialogo.valor }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="l-10 text-right">{{ i + 1 }} ª</th>
              <td class="l-45 comprimentoIeMoz"><data [campo]="movimentacaoParcela.dataVencimento" (alteracao)="setDataVencimento(movimentacaoParcela, $event)" [obrigatorio]="true" [atencao]="movimentacaoControlador.movimentacao.negociacao && movimentacaoControlador.movimentacao.negociacao?.parcelaMaximo > 0 && movimentacaoControlador.movimentacao.negociacao?.parcelaMaximo < movimentacaoControlador.movimentacao.movimentacaoParcelas.length ? movimentacaoControlador.bibDialogo.numeroMaximoParcela + ': ' + movimentacaoControlador.movimentacao.negociacao?.parcelaMaximo : ''" [desabilitado]="movimentacaoControlador.bloqueado || (movimentacaoControlador.diasEntreBloqueado && movimentacaoControlador.diaFixoBloqueado) || movimentacaoControlador.cartaoAVista" [calendario]="false" [ajuda]="movimentacaoParcela.incrementouDomingo ? bibDialogo.incrementouDomingo : ''"></data></td>
              <td class="l-45">
              <decimal [campo]="movimentacaoParcela.getValorParcela()" (alteracao)="setValorParcela(movimentacaoParcela, $event)" [obrigatorio]="true" [atencao]="movimentacaoParcela.getValorParcela() < movimentacaoControlador.movimentacao.negociacao?.parcelaMinimoValor ? movimentacaoControlador.bibDialogo.valorMinimoParcela + ': ' + movimentacaoControlador.movimentacao.negociacao?.parcelaMinimoValor : ''" [desabilitado]="movimentacaoControlador.bloqueado || movimentacaoControlador.movimentacao.negociacao?.formaFechamento == 'H' || (this.movimentacaoControlador.movimentacao.mediaDiaPrazo > 0 && movimentacaoControlador.movimentacao.negociacao && cartaoEspecies.includes(movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie))" [icone]="movimentacaoControlador.bibIcone.lixo" [iconeDesabilitado]="movimentacaoControlador.bloqueado" (btnAdicional)="excluirParcela(movimentacaoParcela, i)"></decimal>
              </td>
            </tr>
            <tr *ngIf="utilSessao.getEmpresa().contabilidade == 'S' && movimentacaoControlador.entrada && movimentacaoControlador.movimentacao.data.setHours(0, 0, 0, 0) == movimentacaoParcela.dataVencimento.setHours(0, 0, 0, 0)">
              <div class="row mt-2">
                <check class="col-sm" [rotulo]="bibDialogo.recebimentoNaoSeraRealizadoImediato" [campo]="movimentacaoParcela.parcelaPrazo" (alteracao)="movimentacaoParcela.parcelaPrazo = $event" [compacto]="false"></check>
              </div>
            </tr>
          </tbody>
          <tfoot *ngIf="movimentacaoControlador.movimentacao.parcelamento > 0 && movimentacaoControlador.movimentacao.negociacao && movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie && cartaoEspecies.includes(movimentacaoControlador.movimentacao.negociacao.tipoTituloIdEspecie)" >
            <tr>
              <atencao class="p-0" [atencao]="bibDialogo.parcelamento.toUpperCase() +  ' ' + bibDialogo.cartao.toUpperCase() + ': ' + movimentacaoControlador.movimentacao.parcelamento + 'x'"></atencao>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <atencao class="p-0" *ngIf="movimentacaoControlador.umaParcelaAvista" [atencao]="bibDialogo.aVista"></atencao>
</div>

<ng-container *ngIf="movimentacaoControlador.movimentacao.movimentacaoParcelas?.length > 0 && movimentacaoControlador.diferencaParcelaTotal != 0">
  <botao [legenda]="movimentacaoControlador.bibDialogo.recalcular + ' ' + movimentacaoControlador.bibDialogo.parcelamento.toLowerCase()" [desabilitado]="movimentacaoControlador.bloqueado" (botaoEmt)="this.movimentacaoControlador.recalcular()"></botao>
  <atencao [atencao]="movimentacaoControlador.bibDialogo.diferenca + ': ' + (movimentacaoControlador.diferencaParcelaTotal | monetario)" [tipo]="movimentacaoControlador.bibPropriedade.atencao.alerta"></atencao>
</ng-container>
