<td class="p-0" colspan="5">
  <table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.telefone }}</th>
        <th class="r-1 vinculo">{{ bibDialogo.vinculo }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiroTelefone of parceiro.parceiroTelefones">
        <tr>
          <td>{{ parceiroTelefone.telefone }}</td>
          <td>{{ parceiroTelefone.vinculo }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</td>
