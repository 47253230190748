import { Log } from './log';
import { TransporteItem } from './transporteItem';

export class Transporte {
  public email: string = '';
  public idEmpresa: number = 0;
  public logs: Log[] = [];
  public objetos: any[] = [];
  public posicao: number = 0;
  public quantidade: number = 0;
  public serial: string;
  public transporteItens: TransporteItem[] = [];
  public token: string = '';
  public posicaoNaSessao: boolean = false;
  public contagem: boolean = false;
  public idMenu: number = 0;
  public ipRede: string;

  constructor(listaOuObjeto: any = null, posicao: number = 0, quantidade: number = 0, posicaoNaSessao: boolean = false, numeroRegistro: number = null, backendW: string = 'N') {
    this.posicao = posicao;
    this.quantidade = quantidade;
    this.posicaoNaSessao = posicaoNaSessao;
    if (numeroRegistro != null) {
      this.contagem = true;
    }
    if (backendW == 'S') {
      if (listaOuObjeto.length >= 0) {
        this.objetos = listaOuObjeto;
      } else {
        this.objetos.push(listaOuObjeto);
      }
    } else {
      if (listaOuObjeto) {
        this.adicionar(listaOuObjeto);
      }
    }
  }

  adicionar(listaOuObjeto: any): void {
    const transporteItem: TransporteItem = new TransporteItem(listaOuObjeto);
    this.transporteItens.push(transporteItem);
  }
}
