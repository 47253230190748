export class Cst {
  public cst: string;
  public entradaSaida: string;
  public id: number;
  public nome: string;
  public simplesNacional: string;
  public utilizaBaseCalculo: string;
  public exigirAliquotaMaiorZero: string;
  public reducaoIcms: string;
}
