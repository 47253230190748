import { Component } from '@angular/core';
import { Praga } from 'src/app/agrow/modelo/praga';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './praga.component.html',
})
export class PragaComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public pragas: Praga[] = [];
  public integracaoEidaf: boolean = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
  public integracaoAgroDefesa: boolean = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO').length > 0 ? true : false;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.praga).subscribe((res) => {
      this.pragas = this.plainToClass(Praga, res) as any;
    });
  }

  excluirPraga(id: number, nome: string): void {
    super.excluir<Praga>(id, this.pragas, nome);
  }
}
