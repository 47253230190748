<containerFormulario [vertical]="vertical" [rotulo]="_rotulo" [idMenu]="idMenu" [campoAtual]="campoAtual" [preenchido]="campoAtual ? true : false" [compacto]="compacto" [ajudaDireita]="ajudaDireita" [ajuda]="ajuda" [obrigatorio]="obrigatorio" [atencaoMensagem]="atencaoMensagem" [icone]="icone" [prefixo]="prefixo" [sufixo]="sufixo" [desabilitado]="desabilitado" [campoFor]="_id" [iconeDesabilitado]="iconeDesabilitado" (btnAdicional)="clicarBtnAdicionalLista()">
  <label *ngIf="descricaoAcima" [for]="id">{{ descricaoAcima + ': ' }}</label>
  <select class="form-control clique" [ngClass]="[fonteMenor ? 'fonte-menor' : '', compacto ? 'compacto ml-2' : '', ehModoClaro ? '' : 'modo-escuro']" #input [id]="_id" [name]="_rotulo" [(ngModel)]="campoAtual" (ngModelChange)="alterar()" (focus)="navegar(true)" (blur)="navegar(false)" [disabled]="desabilitado" autocomplete="off" tabindex="{{ _lista && _lista.length == 1 && obrigatorio ? -1 : 0 }}">
    <option *ngIf="!obrigatorio" [value]="''">{{ _apresentaDescricaoTodos ? 'TODOS' : textoVazio ? textoVazio : '' }}</option>
    <option *ngFor="let objeto of _lista" [value]="objeto.id">
      {{ objeto.nome || objeto.nomeFantasia || objeto.razaoSocial || objeto.rotulo }}
    </option>
  </select>
  <ajuda class="align-self-center mr-md-1" *ngIf="!_rotulo && ajuda" [ajudaDireita]="ajudaDireita" [ajuda]="ajuda"></ajuda>
</containerFormulario>
