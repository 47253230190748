import { Component } from '@angular/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './movimentacao.component.html',
  styleUrls: ['./movimentacao.component.css'],
})
export class MovimentacaoComponent extends PaginaComponent {
  public colaborador: string;
  public empresaUtilizaAbrirMovimentacao: string = this.utilSessao.getEmpresa().regraAbrirMovimentacao;
  public filtros: Filtro[];
  public idOperacao: number;
  public movimentacoes: Movimentacao[] = [];
  public operacaoDifereInterna: boolean;
  public operacaoEmiteNota: boolean;
  public operacaoInterna: boolean;
  public parceiro: string;
  public totalMovimentacoes: any[] = [];
  public visualizaTotalizadores: boolean = false;

  private filtroSituacaoSemEntrega: FiltroCombo[] = this.plainToClass(FiltroCombo, [
    { nome: '01 - ' + bibDialogo.aberta, id: 1 },
    { nome: '02 - ' + bibDialogo.aguardandoFinanceiro, id: 2 },
    { nome: '03 - ' + bibDialogo.concluida, id: 5 },
    { nome: '04 - ' + bibDialogo.cancelada, id: 6 },
    { nome: '05 - ' + bibDialogo.todos, id: '-' },
  ]);

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.idOperacao = params['idOperacao'];
      this.movimentacoes = [];
      this.selecionarFiltro(params['idOperacao']);
      this.permissao = this.utilSessao.getPermissao();
      this.operacaoDifereInterna = this.ehDiferenteInterna();
      this.operacaoInterna = this.ehInterna();
      this.operacaoEmiteNota = this.ehEmissaoNota();
    });
  }

  ehDiferenteInterna(): boolean {
    return this.idOperacao != 5 && this.idOperacao != 9 && this.idOperacao != 10 && this.idOperacao != 13 && this.idOperacao != 14;
  }

  ehInterna(): boolean {
    return this.idOperacao == 4 || this.idOperacao == 5 || this.idOperacao == 9 || this.idOperacao == 10 || this.idOperacao == 13 || this.idOperacao == 14;
  }

  ehEmissaoNota(): boolean {
    return this.idOperacao == 3 || this.idOperacao == 15 || this.idOperacao == 16 || this.idOperacao == 17;
  }

  selecionarFiltro(operacao: string): void {
    switch (operacao) {
      case '1':
        this.filtros = filtros.vendaOperacaoOrcamento;
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.vendedor;
        break;
      case '2':
        this.filtros = filtros.vendaOperacaoEstudoVenda;
        this.ajustarSituacaoEntrega(this.filtros);
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.vendedor;
        break;
      case '3':
        this.filtros = filtros.vendaOperacaoNotaVenda;
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '4':
        this.filtros = filtros.internaTransferenciaBalancoMovimentacaoManual;
        this.parceiro = bibDialogo.responsavel;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '5':
        this.filtros = filtros.internaTransferenciaBalancoMovimentacaoManual;
        this.parceiro = bibDialogo.responsavel;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '7':
        this.filtros = filtros.compraOperacaoPedido;
        this.parceiro = bibDialogo.fornecedor;
        this.colaborador = bibDialogo.comprador;
        break;
      case '8':
        this.filtros = filtros.compraOperacaoEntradaRecebimento;
        this.parceiro = bibDialogo.fornecedor;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '9':
        this.filtros = filtros.transferenciaFiltros;
        this.parceiro = bibDialogo.responsavel;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '10':
        this.filtros = filtros.transferenciaSaidaFiltros;
        this.parceiro = bibDialogo.responsavel;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '11':
        this.filtros = filtros.vendaOperacaoDevolucao;
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '12':
        this.filtros = filtros.compraOperacaoDevolucao;
        this.parceiro = bibDialogo.fornecedor;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '13':
        this.filtros = filtros.internaTransferenciaBalancoMovimentacaoManual;
        this.parceiro = bibDialogo.responsavel;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '14':
        this.filtros = filtros.internaTransferenciaBalancoMovimentacaoManual;
        this.parceiro = bibDialogo.responsavel;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '17':
        this.filtros = filtros.vendaOperacaoNotaVenda;
        this.parceiro = bibDialogo.fornecedor;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '18':
        this.filtros = filtros.vendaOperacaoPedidoCarteira;
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.vendedor;
        break;
      case '19':
        this.filtros = filtros.vendaOperacaoEstudoVenda;
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.vendedor;
        break;
      case '20':
        this.filtros = filtros.vendaOperacaoNotaVenda;
        this.parceiro = bibDialogo.cliente;
        this.colaborador = bibDialogo.responsavel;
        break;
      case '21':
        this.filtros = filtros.compraOperacaoEntradaRecebimento;
        this.parceiro = bibDialogo.fornecedor;
        this.colaborador = bibDialogo.responsavel;
        break;
    }
  }

  ajustarSituacaoEntrega(filtros: Filtro[]): void {
    filtros.forEach((filtro) => {
      if (filtro.id == 'situacao') {
        if (this.utilSessao.getEmpresa().separarEntregar == 'N') {
          filtro.lista = this.filtroSituacaoSemEntrega;
        }
      }
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, nomeCampoOrdem: string = ''): void {
    if (this.utilSessao.getUsuario().permitir != 'S') {
      criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().idColaborador));
    }
    criterios.push(new Criterio('ID_OPERACAO', this.idOperacao));
    criterios.push(new Criterio('LOJA_USUARIO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacaoGrade, false, true, true, true, 120000, false, '', nomeCampoOrdem).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      if (this.movimentacoes.length > 0) {
        this.visualizaTotalizadores = this.movimentacoes.length > 1 ? true : false;
        if (this.visualizaTotalizadores) {
          this.totalizarMovimentacoes();
        }
      }
    });
  }

  totalizarMovimentacoes(): void {
    this.totalMovimentacoes = [];
    let valorMovimentacaoTotalizado: number = 0;
    for (let movimentacao of this.movimentacoes) {
      valorMovimentacaoTotalizado += movimentacao.valorTotal;
    }
    this.totalMovimentacoes.push({ valorTotalizado: valorMovimentacaoTotalizado });
  }

  excluirMovimentacao(id: number, nome: string): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id ? id : 0)), this.bibServico.movimentacao).subscribe((res) => {
      const movimentacaoBusca = this.plainToClass(Movimentacao, res[0]) as any;
      if (movimentacaoBusca && movimentacaoBusca.status == 'S' && movimentacaoBusca.status != 'U') {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.movimentacaoFinalizada));
      } else {
        super.excluir<Movimentacao>(id, this.movimentacoes, nome).subscribe(() => {
          this.totalizarMovimentacoes();
        });
      }
    });
  }

  clonarMovimentacao(id: number, posicao: number, movimentacao: Movimentacao[]): void {
    this.ir(id, posicao, movimentacao);
    this.utilSessao.setIdentificacao(new Identificacao('clone', true));
  }

  abrirMovimentacao(movimentacao: Movimentacao): void {
    if (this.ehValidoAbrirMovimentacao(movimentacao)) {
      const situacaoMovimentacao: number = movimentacao.situacao;
      this.listarMovimentacao(movimentacao.id).subscribe((res) => {
        let movimentacaoPersistir: Movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
        if (confirm(bibDialogo.desejaAbrirMovimentacaoNumero + ' ' + movimentacao.numero)) {
          movimentacaoPersistir.status = 'A';
          movimentacaoPersistir.abrindo = true;
          const transporte: Transporte = new Transporte(movimentacaoPersistir);
          super.persistir(transporte, this.bibServico.movimentacao, null).subscribe(() => {
            if (!this.utilSessao.falha) {
              movimentacao.status = 'A';
              movimentacao.situacao = 1;
            } else {
              movimentacao.status = 'S';
              movimentacao.situacao = situacaoMovimentacao;
            }
          });
        }
      });
    }
  }

  listarMovimentacao(idMovimentacao: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idMovimentacao)), this.bibServico.movimentacao);
  }

  ehValidoAbrirMovimentacao(movimentacao: Movimentacao): boolean {
    let ehValido: number = 0;
    if (this.permissaoAbrirMovimentacao == undefined) {
      if (movimentacao.idColaborador != this.utilSessao.getUsuario().idColaborador) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.abrirMovimentacaoResponsavel));
        ehValido++;
      }
      if (format(new Date(Date.parse(movimentacao.data.toString())), 'yyyy-MM-dd') != format(new Date(Date.parse(new Date().toString())), 'yyyy-MM-dd')) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.abrirMovimentacaoData));
        ehValido++;
      }
    }
    return ehValido > 0 ? false : true;
  }
}
