import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'valor', posicao: 2 },
  { campo: 'baseIcms', posicao: 3 },
  { campo: 'valorIcms', posicao: 4 },
  { campo: 'isentas', posicao: 5 },
  { campo: 'outras', posicao: 6 },
  { campo: 'icmsStValor', posicao: 7 },
  { campo: 'descontoValor', posicao: 8 },
  { campo: 'despesaAcessoria', posicao: 9 },
  { campo: 'basePis', posicao: 10 },
  { campo: 'valorPis', posicao: 11 },
  { campo: 'valorCofins', posicao: 12 },
  { campo: 'valorIpi', posicao: 13 },
];
export default totais;
