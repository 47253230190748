export class GrupoContaContabil {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idConta: number;
  public idEmpresa: number;
  public idGrupoConta: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nomeConta: string;
}
