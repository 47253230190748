import { Router } from '@angular/router';
import bibDialogo from '../biblioteca/bibDialogo';
import { Criterio } from '../modelo/criterio';
import { Identificacao } from '../modelo/identificacao';
import { Permissao } from '../modelo/permissao';
import { RelatorioUsuario } from '../modelo/relatorioUsuario';
import { UtilSessao } from '../utilitario/util.sessao';

export class Util {
  private ordenacaoAtual: string = '';
  public bibDialogo = bibDialogo;
  public ordenacaoFinal: number = 1;
  public ordenacaoInicial: number = -1;
  public utilSessao: UtilSessao = new UtilSessao();

  constructor(public router: Router = null) { }

  criarId(): string {
    const data = new Date();
    return Math.floor(Math.random() * 100).toString() + data.getMilliseconds() * Math.floor(Math.random() * 100) + data.getTime();
  }

  copiar(conteudo: string) {
    event.preventDefault();
    navigator.clipboard.writeText(conteudo);
  }

  marcarLista(posicaoAtual: number, incremento: number, lista: any[]): number {
    posicaoAtual += incremento;
    if (posicaoAtual < 0) {
      posicaoAtual = 0;
    } else {
      if (posicaoAtual > lista.length - 1) {
        posicaoAtual = lista.length - 1;
      }
    }
    return posicaoAtual;
  }

  arredondar(value: number, decimalLength: number = 2, chunkDelimiter: string = '.', decimalDelimiter: string = ',', chunkLength: number = 3): number {
    let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    let num = (typeof value == 'undefined' || isNaN(value) || value == null ? 0 : value).toFixed(Math.max(0, ~~decimalLength));
    let valorFormatado: string = (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
    let numeroInteiro: string = valorFormatado.substring(0, valorFormatado.indexOf(','));
    numeroInteiro = numeroInteiro.split('.').join('');
    let fracao: string = valorFormatado.substring(valorFormatado.indexOf(','), valorFormatado.length);
    valorFormatado = numeroInteiro + fracao;
    valorFormatado = valorFormatado.replace(',', '.');
    return Number(valorFormatado);
  }

  concatenar(mensagem: any = '', novaMensagem: any = '', separador: string = ', '): string {
    if (novaMensagem.toString().length > 0) {
      if (mensagem.toString().length > 0) {
        mensagem += separador;
      }
      mensagem += novaMensagem;
    }
    return mensagem;
  }

  excluir(posicao: number, lista: any[], excluir: Number[]): void {
    if (lista[posicao].id) {
      excluir.push(lista[posicao].id);
    }
    lista.splice(posicao, 1);
  }

  substituir(mensagem: string, lista: any[]): string {
    let i: number = 0;
    Array.from(mensagem).forEach((caracter) => {
      if (caracter == '_') {
        mensagem = mensagem.replace(caracter, lista[i]);
        i++;
      }
    });
    return mensagem.trim();
  }

  validarCnpjCpf(cnpjCpf: string): boolean {
    if (cnpjCpf != null) {
      if (cnpjCpf.length <= 14) {
        return this.validarCpf(cnpjCpf);
      } else {
        return this.validarCnpj(cnpjCpf);
      }
    }
    return true;
  }

  validarCnpj(cnpj: string): boolean {
    var i, tamanho, soma, pos, numeros, digitos, resultado;

    cnpj = this.retirarCaracteresInvalidos(cnpj);

    if (cnpj == '') {
      return false;
    }

    if (cnpj.length != 14) {
      return false;
    }

    if (cnpj == '00000000000000' || cnpj == '11111111111111' || cnpj == '22222222222222' || cnpj == '33333333333333' || cnpj == '44444444444444' || cnpj == '55555555555555' || cnpj == '66666666666666' || cnpj == '77777777777777' || cnpj == '88888888888888' || cnpj == '99999999999999') {
      return false;
    }

    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) {
      return false;
    }
    return true;
  }

  validarCpf(cpf: string): boolean {
    cpf = this.retirarCaracteresInvalidos(cpf);
    if (cpf == null) {
      return false;
    }
    if (cpf.length != 11) {
      return false;
    }
    if (cpf == '22222222222' || cpf == '33333333333' || cpf == '44444444444' || cpf == '55555555555' || cpf == '66666666666' || cpf == '77777777777' || cpf == '88888888888' || cpf == '99999999999') {
      return false;
    }
    let numero: number = 0;
    let caracter: string = '';
    let numeros: string = '0123456789';
    let j: number = 10;
    let somatorio: number = 0;
    let resto: number = 0;
    let digito1: number = 0;
    let digito2: number = 0;
    let cpfAux: string = '';
    cpfAux = cpf.substring(0, 9);
    for (let i: number = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + numero * j;
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i: number = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + numero * j;
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    } else {
      return true;
    }
  }

  retirarCaracteresInvalidos(strCNPJCPF: string): string {
    while (strCNPJCPF.indexOf('.') !== -1) {
      strCNPJCPF = strCNPJCPF.replace('.', '');
    }
    while (strCNPJCPF.indexOf('-') !== -1) {
      strCNPJCPF = strCNPJCPF.replace('-', '');
    }
    while (strCNPJCPF.indexOf('/') !== -1) {
      strCNPJCPF = strCNPJCPF.replace('/', '');
    }
    return strCNPJCPF;
  }

  moeda(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  formatarMilhar(valor: number): string {
    return valor.toLocaleString('pt-BR');
  }

  fecharMenuPrincipal(): void {
    document.querySelectorAll('aside').forEach((evento) => {
      setTimeout((_) => evento.classList.remove('has-open'));
    });
    document.querySelectorAll('.hamburger-squeeze').forEach((evento) => {
      setTimeout((_) => evento.classList.remove('has-active'));
    });
    document.querySelectorAll('.app-backdrop').forEach((evento) => {
      setTimeout((_) => evento.classList.remove('show'));
    });
  }

  montarUrlRerlatorio(relatorioId: number, impressaoDireta: boolean = false): string {
    const listaRelatorioDashborad: RelatorioUsuario[] = this.utilSessao.getRelatorioUsuarios().concat(this.utilSessao.getDashboardUsuarios());
    if (listaRelatorioDashborad.find((relatorio) => relatorio.idRelatorio == relatorioId) != undefined) {
      return 'relatorio/r' + relatorioId + '/' + relatorioId + '/' + (impressaoDireta ? 'S' : 'N');
    }
    return null;
  }

  irRelatorio(relatorioId: number): void {
    const url = this.montarUrlRerlatorio(relatorioId);
    if (url != null) {
      window.open('./#/' + url, '_blank');
    }
  }

  definirPrimeiraMaiscula(texto: string): string {
    let primeiraLetraMaiscula = texto.slice(0, 1).toUpperCase();
    let textoFormatado = primeiraLetraMaiscula + texto.slice(1, texto.length);
    return textoFormatado.trim();
  }

  escreverValor(valor: any, c = true): string {
    valor = valor.toString().replace('.', ',');
    var ex = [
      ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove', 'dez', 'onze', 'doze', 'treze', 'quatorze', 'quinze', 'dezesseis', 'dezessete', 'dezoito', 'dezenove'],
      ['dez', 'vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa'],
      ['cem', 'cento', 'duzentos', 'trezentos', 'quatrocentos', 'quinhentos', 'seiscentos', 'setecentos', 'oitocentos', 'novecentos'],
      ['mil', 'milhão', 'bilhão', 'trilhão', 'quadrilhão', 'quintilhão', 'sextilhão', 'setilhão', 'octilhão', 'nonilhão', 'decilhão', 'undecilhão', 'dodecilhão', 'tredecilhão', 'quatrodecilhão', 'quindecilhão', 'sedecilhão', 'septendecilhão', 'octencilhão', 'nonencilhão'],
    ];
    var a,
      n,
      v,
      i,
      n: any = valor.replace(c ? /[^,\d]/g : /\D/g, '').split(','),
      e = ' e ',
      $ = 'real',
      d = 'centavo',
      sl;
    for (var f = n.length - 1, l, j: any = -1, r = [], s = [], t: any = ''; ++j <= f; s = []) {
      let ponto: any = '.';
      j && (n[j] = ((ponto + n[j]) * 1).toFixed(2).slice(2));
      if (!((a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g)), (v = l % 3 ? [v.slice(0, l % 3)] : []), (v = a ? v.concat(a) : v)).length) continue;
      for (a = -1, l = v.length; ++a < l; t = '') {
        if (!(i = v[a] * 1)) continue;
        (i % 100 < 20 && (t += ex[0][i % 100])) || ((i % 100) + 1 && (t += ex[1][(((i % 100) / 10) >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : '')));
        s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : (i / 100) >> 0] : ex[2][(i / 100) >> 0] + e + t) + ((t = l - a - 2) > -1 ? ' ' + (i > 1 && t > 0 ? ex[3][t].replace('ão', 'ões') : ex[3][t]) : ''));
      }
      a = (sl = s.length) > 1 ? ((a = s.pop()), s.join(' ') + e + a) : s.join('') || ((!j && n[j + 1] * 1 > 0) || r.length ? '' : ex[0][0]);
      a && r.push(a + (c ? ' ' + (v.join('') * 1 > 1 ? (j ? d + 's' : (/0{6,}$/.test(n[0]) ? 'de ' : '') + $.replace('l', 'is')) : j ? d : $) : ''));
    }
    return r.join(e);
  }

  getProporcao(valor: number, valorTotal: number): number {
    return (valor * 100) / valorTotal / 100;
  }

  getQuantidadeLojaUsuario(): number {
    return this.utilSessao.getLojas().length;
  }

  getLojaUsuario(): number {
    if (this.getQuantidadeLojaUsuario() == 1) {
      return this.utilSessao.getLojas()[0].id;
    }
    return null;
  }

  getEhAbreviacaoLoja(usaBibDialogo: boolean, usuarioSobrenome: string): string {
    let abreviacao = '';
    if (this.utilSessao.getIdLoja()) {
      abreviacao = this.utilSessao.getLojas().find((loja) => loja.id == this.utilSessao.getIdLoja()).abreviacao;
      return usaBibDialogo ? this.bibDialogo.sejaBemVindo + ' ' + abreviacao : abreviacao;
    } else {
      return usuarioSobrenome;
    }
  }

  abrirRelatorio(id: number, idRelatorio: number, impressaoDireta: boolean = true, abrirSemVerificarPermissao: boolean = false): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID', id.toString(), null, idRelatorio));
    this.utilSessao.setRelatorioCriterios(criterios);
    const idsRelatoriosSemControlePermissao: number[] = this.utilSessao
      .getRelatorios()
      .filter((relatorio) => relatorio.controlaPermissao == 'N')
      .map((relatorio) => relatorio.id);
    if (abrirSemVerificarPermissao && idsRelatoriosSemControlePermissao.includes(Number(idRelatorio))) {
      const url = 'relatorio/r' + idRelatorio + '/' + idRelatorio + '/' + (impressaoDireta ? 'S' : 'N');
      window.open('./#/' + url, '_blank', 'width=1200,height=800,text-align=center');
    } else {
      const url: string = this.montarUrlRerlatorio(idRelatorio, true);
      window.open('./#/' + url, '_blank', 'width=1200,height=800,text-align=center');
    }
  }

  ordenarLista(lista: any[], ordenacao: string): void {
    if (this.ordenacaoAtual == ordenacao) {
      this.ordenacaoInicial = this.ordenacaoInicial == -1 ? 1 : -1;
      this.ordenacaoFinal = this.ordenacaoFinal == 1 ? -1 : 1;
    } else {
      this.ordenacaoInicial = 1;
      this.ordenacaoFinal = -1;
    }
    this.ordenacaoAtual = ordenacao;
    lista.sort((a, b) => (this.coalesce(a[ordenacao]) === this.coalesce(b[ordenacao]) ? 0 : this.coalesce(a[ordenacao]) > this.coalesce(b[ordenacao]) ? this.ordenacaoInicial : this.ordenacaoFinal));
  }

  coalesce(conteudo: any, retorno: any = '', ignoraZero: boolean = false): any {
    if (conteudo != null && conteudo != undefined && (conteudo != 0 || ignoraZero == false)) {
      return conteudo;
    } else {
      return retorno;
    }
  }

  truncarStringAdicionarReticencias(descricao: string, quantidadeCaracteres: number): string {
    if (descricao.length > quantidadeCaracteres) {
      descricao = descricao.slice(0, quantidadeCaracteres - 3);
      descricao += '...';
      return descricao;
    }
    return descricao;
  }

  verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios: Criterio[]): Criterio[] {
    if (this.utilSessao.getUsuario().permitir != 'S') {
      const criterioColaborador: Criterio = criterios.find((criterio) => criterio.nome == 'ID_COLABORADOR');
      if (criterioColaborador && criterioColaborador.valor == this.utilSessao.getUsuario().idColaborador) {
        criterios.find((criterio) => criterio.nome == 'ID_COLABORADOR').valor = this.utilSessao.getUsuario().idColaborador;
      } else {
        if (!criterioColaborador) {
          criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().permitir != 'S' ? this.utilSessao.getUsuario().idColaborador : null));
        } else {
          criterios.find((criterio) => criterio.nome == 'ID_COLABORADOR').valor = 0;
        }
      }
    }
    return criterios;
  }

  public formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }

  public formatarDataBoleto(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  escreverMes(mes: number, numerado: boolean = false): string {
    let meses: string[];
    if (numerado) {
      meses = ['[01] Janeiro', '[02] Fevereiro', '[03] Março', '[04] Abril', '[05] Maio', '[06] Junho', '[07] Julho', '[08] Agosto', '[09] Setembro', '[10] Outubro', '[11] Novembro', '[12] Dezembro'];
    } else {
      meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    }
    return meses[mes - 1];
  }

  abrirModal(tela: any, identificacoes: Identificacao[], idMenu: number): any {
    event.preventDefault();
    for (const identificacao of identificacoes) {
      this.utilSessao.setIdentificacao(identificacao);
    }
    let permissao: Permissao = new Permissao();
    if (idMenu) {
      permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == idMenu);
    } else {
      permissao.editar = 'N';
      permissao.inserir = 'N';
      permissao.excluir = 'N';
      permissao.inativar = 'N';
      permissao.editar = 'N';
    }
    this.utilSessao.setPermissaoEspecifica(permissao);
    return tela;
  }
}
