import { Component, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuPrincipal } from 'src/app/modelo/menuPrincipal';
import { Modulo } from 'src/app/modelo/modulo';
import { Util } from 'src/app/utilitario/util';
import bibAtalho from '../../biblioteca/bibAtalho';
import bibDialogo from '../../biblioteca/bibDialogo';
import { UtilNotificacao } from '../../utilitario/util.notificacao';
import { UtilSessao } from '../../utilitario/util.sessao';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent {
  @Input() tipo: string = 'menuPersonalizado';
  @Input() usuarioLogado: boolean = false;
  private destinoCtrl: string = null;
  private menuPrincipalEmitter;
  private novaAba: boolean = false;
  public bibAtalho = bibAtalho;
  public bibDialogo = bibDialogo;
  public idPai: number = 0;
  public idPaiAnterior1: number = 0;
  public idPaiAnterior2: number = 0;
  public idPaiAnterior3: number = 0;
  public idPaiAnterior4: number = 0;
  public idPaiAnterior5: number = 0;
  public listaMenuNomes: string[] = [];
  public menu: any;
  public menuPersonalizado: any[] = [];
  public menuPrincipais: MenuPrincipal[] = [];
  public menus: any[] = [];
  public mostraSenha: boolean = false;
  public nivel: number = 1;
  public posicao: number = 0;
  public util: Util = new Util();

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    let contar: number = 0;
    if (this.tipo == 'menuPersonalizado') {
      contar = this.listarMenuPersonalizado().length;
    }
    if (event.keyCode == this.bibAtalho.setaBaixo && this.tipo == 'menuPersonalizado') {
      if (this.mostraSenha == false) {
        this.posicao++;
        if (this.posicao >= contar) {
          this.posicao--;
        }
      }
    }
    if (event.keyCode == this.bibAtalho.setaCima && this.tipo == 'menuPersonalizado') {
      if (this.mostraSenha == false) {
        this.posicao--;
        if (this.posicao < 0) {
          this.posicao = 0;
        }
      }
    }

    if (event.keyCode == this.bibAtalho.cancelar && this.tipo == 'menuPersonalizado') {
      if (this.mostraSenha == false) {
        event.preventDefault();
        this.voltarMenu();
      } else {
        this.mostraSenha = false;
      }
    }
  }

  constructor(private utilNotificacao: UtilNotificacao, private utilSessao: UtilSessao, private router: Router, private readonly renderer: Renderer2, private readonly element: ElementRef, private titleService: Title) {}

  ngOnInit(): void {
    this.menuPrincipalEmitter = this.utilNotificacao.menuPrincipalEmitter.subscribe(() => {
      setTimeout((_) => this.iniciar());
    });
    if (this.tipo == 'menuPersonalizado') {
      this.utilSessao.setMenuPersonalizado(true);
      this.iniciarMenu();
      this.titleService.setTitle(bibDialogo.menuRapido);
    }
    this.utilNotificacao.menuPersonalizadoEmt.subscribe(() => {
      this.listaMenuNomes = [];
      this.iniciarMenu();
      this.nivel = 1;
    });
  }

  iniciar(): void {
    if (this.usuarioLogado == true) {
      this.iniciarMenu();
    }
  }

  verificarExigirSenha(valor: string = ''): void {
    const { key } = this.utilSessao.getUsuario();
    if (key == valor) {
      this.finalizar();
    }
  }

  finalizar(): void {
    this.entrarMenu(this.menu);
    this.mostraSenha = false;
  }

  getModuloPaisRecursivo(idModuloPai: number = 0, modulos: Modulo[] = []): Modulo[] {
    if (idModuloPai > 0) {
      const modulo: Modulo = this.utilSessao.getModulos().find((modulo) => modulo.id == idModuloPai);
      modulos.push(modulo);
      this.getModuloPaisRecursivo(modulo.idModuloPai, modulos);
    }
    return modulos;
  }

  retornaModulosPermitidos(idModuloEmpresas: number[]): any[] {
    const modulos: any[] = this.utilSessao.getModulos();
    let modulosPermitidos: any[] = [];
    modulos.forEach((modulo) => {
      let moduloPermitido: boolean = idModuloEmpresas.includes(modulo.id);
      if (moduloPermitido) {
        modulosPermitidos.push(modulo);
        let moduloPais: Modulo[] = this.getModuloPaisRecursivo(modulo.idModuloPai);
        moduloPais.forEach((moduloPai) => {
          modulosPermitidos.push(moduloPai);
        });
      }
    });
    return modulosPermitidos;
  }

  iniciarMenu(): void {
    this.menus = [];
    this.posicao = 0;
    let permissoes: any[] = this.utilSessao.getPermissoes();
    let idModuloEmpresas: number[] = this.retornaIdModuloEmpresas();
    let modulosPermitidos: any[] = this.retornaModulosPermitidos(idModuloEmpresas);
    modulosPermitidos = modulosPermitidos.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));
    this.criarModuloMenus(this.menus, modulosPermitidos);
    let idModuloRelatorio: number = 1000;
    for (let moduloPermitido of modulosPermitidos) {
      const permissoesModuloAtual: any[] = permissoes.filter((permissao) => permissao.idModuloEmpresa == moduloPermitido.id);
      for (const permissao of permissoesModuloAtual) {
        if (permissao.idModuloEmpresa > 0) {
          let parametro: any = permissao.parametro != null ? permissao.parametro : null;
          this.menus.push({
            idMenu: permissao.idMenu,
            idModulo: null,
            idRelatorio: null,
            idModuloPai: moduloPermitido.id,
            menu: permissao.menu,
            nivel: moduloPermitido.nivel + 2,
            link: 'S',
            destino: permissao.tela,
            parametro: parametro,
            permissao: permissao,
            exigeSenha: permissao.exigeSenha,
            novo: permissao.novo,
            ordem: permissao.ordem,
            titulo: 'N',
            icone: permissao.icone ? permissao.icone : '',
            idModuloRelatorio: null,
          });
        }
      }

      if (this.utilSessao.getRelatorioUsuarioMenus().find((relatorioUsuario) => relatorioUsuario.idModuloEmpresa == moduloPermitido.id) != undefined) {
        idModuloRelatorio++;
        this.menus.push({
          idMenu: null,
          idModulo: idModuloRelatorio,
          idRelatorio: null,
          idModuloPai: moduloPermitido.id,
          menu: 'Relatório',
          nivel: moduloPermitido.nivel + 2,
          link: 'S',
          destino: null,
          parametro: null,
          permissao: null,
          exigeSenha: 'N',
          novo: 'N',
          ordem: 9000,
          titulo: 'N',
          icone: '',
          idModuloRelatorio: moduloPermitido.id,
        });

        for (const relatorioUsuario of this.utilSessao.getRelatorioUsuarioMenus().filter((relatorioUsuarioMenu) => relatorioUsuarioMenu.idModuloEmpresa == moduloPermitido.id)) {
          if (relatorioUsuario.idModuloEmpresa > 0) {
            if (moduloPermitido.titulo == null || moduloPermitido.titulo == 'N') {
              this.menus.push({
                idMenu: null,
                idModulo: null,
                idRelatorio: relatorioUsuario.idRelatorio,
                idModuloPai: idModuloRelatorio,
                menu: relatorioUsuario.idRelatorio + ' - ' + this.util.definirPrimeiraMaiscula(relatorioUsuario.relatorio.toLowerCase()),
                nivel: moduloPermitido.nivel + 3,
                link: 'S',
                destino: null,
                parametro: null,
                permissao: null,
                exigeSenha: 'N',
                novo: 'N',
                ordem: relatorioUsuario.ordem,
                titulo: 'N',
                icone: '',
                idModuloRelatorio: moduloPermitido.id,
              });
            }
          }
        }
      }
    }
    this.menuPersonalizado = this.listarMenuPersonalizado();
    if (this.tipo == 'menuPrincipal') {
      this.montarMenuPrincipal();
    }
  }

  retornaIdModuloEmpresas(): number[] {
    let permissaoIdModuloEmpresas: number[] = this.utilSessao.getPermissoes().map((permissao) => permissao.idModuloEmpresa);
    let relatorioIdModuloEmpresas: number[] = this.utilSessao.getRelatorioUsuarios().map((relatorioUsuario) => relatorioUsuario.idModuloEmpresa);
    relatorioIdModuloEmpresas.forEach((relatorioIdModuloEmpresa) => {
      if (permissaoIdModuloEmpresas.find((idModuloPermitido) => idModuloPermitido == relatorioIdModuloEmpresa) == undefined) {
        permissaoIdModuloEmpresas.push(relatorioIdModuloEmpresa);
      }
    });
    return permissaoIdModuloEmpresas;
  }

  criarModuloMenus(menus: any[], modulosPermitidos: any[]): void {
    for (let modulo of modulosPermitidos) {
      let nivel: number = modulo.nivel + 1;
      menus.push({
        idMenu: null,
        idModulo: modulo.id,
        idRelatorio: null,
        idModuloPai: modulo.idModuloPai,
        menu: modulo.nome,
        nivel: nivel,
        link: 'N',
        destino: null,
        parametro: null,
        permissao: null,
        exigeSenha: 'N',
        novo: 'N',
        ordem: modulo.ordem,
        titulo: modulo.titulo ? modulo.titulo : 'N',
        icone: modulo.icone ? modulo.icone : '',
        idModuloRelatorio: null,
      });
    }
  }

  entrarMenu(menuPersonalizado: any): void {
    if (menuPersonalizado.parametro && menuPersonalizado.parametro.indexOf('pesquisa') == 0) {
      this.utilNotificacao.modalEmt.emit(menuPersonalizado.parametro);
    } else {
      if (menuPersonalizado && !menuPersonalizado.idRelatorio) {
        this.listaMenuNomes.push(menuPersonalizado.menu);
      }
      if (menuPersonalizado.idRelatorio != null) {
        this.util.irRelatorio(menuPersonalizado.idRelatorio);
      } else {
        if (menuPersonalizado.link == 'N' || menuPersonalizado.idModuloRelatorio != null) {
          let atual: any = null;
          let i = 0;
          for (let menuAtual of this.listarMenuPersonalizado()) {
            if (i == this.posicao) {
              atual = menuAtual;
            }
            i++;
          }
          if (atual != null) {
            let subMenu = this.menus.filter((menu) => menu.idModuloPai == atual.idModulo);
            if (subMenu != null) {
              if (subMenu.length > 0) {
                if (this.nivel == 1) {
                  this.idPaiAnterior1 = atual.idModulo;
                  this.idPai = atual.idModulo;
                }
                if (this.nivel == 2) {
                  this.idPaiAnterior2 = atual.idModulo;
                  this.idPai = atual.idModulo;
                }
                if (this.nivel == 3) {
                  this.idPaiAnterior3 = atual.idModulo;
                  this.idPai = atual.idModulo;
                }
                if (this.nivel == 4) {
                  this.idPaiAnterior4 = atual.idModulo;
                  this.idPai = atual.idModulo;
                }
                if (this.nivel == 5) {
                  this.idPaiAnterior5 = atual.idModulo;
                  this.idPai = atual.idModulo;
                }
                this.nivel++;
                this.posicao = 0;
              }
            }
          }
        } else {
          this.irPara(menuPersonalizado.permissao);
        }
      }
    }
  }

  voltarMenu(): void {
    this.listaMenuNomes.pop();
    this.mostraSenha = false;
    if (this.nivel > 1) {
      this.nivel--;
      if (this.nivel == 1) {
        this.idPai = 0;
      }
      if (this.nivel == 2) {
        this.idPai = this.idPaiAnterior1;
      }
      if (this.nivel == 3) {
        this.idPai = this.idPaiAnterior2;
      }
      if (this.nivel == 4) {
        this.idPai = this.idPaiAnterior3;
      }
      if (this.nivel == 5) {
        this.idPai = this.idPaiAnterior4;
      }
      this.posicao = 0;
    }
  }

  listarMenuPersonalizado(): any {
    return this.menus.filter((menu) => this.listarPermissoes(menu) > 0).sort((a, b) => (a['ordem'].toString() == b['ordem'].toString() ? 0 : a['ordem'].toString() > b['ordem'].toString() ? 1 : -1));
  }

  listarMenuPrincipal(): MenuPrincipal[] {
    return this.menuPrincipais.filter((menuPrincipal) => menuPrincipal.visualiza == true);
  }

  ir(destino: string, limparParametro: boolean = true): void {
    if (limparParametro) {
      this.utilSessao.setParametro(null);
    }
    this.destinoCtrl = destino;
    this.novaAba = false;
    this.ehCtrl();
  }

  ehCtrl(): void {
    this.renderer.listen(this.element.nativeElement, 'click', (event) => {
      if (this.destinoCtrl) {
        if (this.novaAba == true) {
          window.open('./#/' + this.destinoCtrl, '_blank');
        } else {
          if (!event.ctrlKey) {
            this.router.navigate(['/' + this.destinoCtrl]);
          }
        }
        this.destinoCtrl = null;
        this.util.fecharMenuPrincipal();
      }
    });
  }

  listarFilhos(idModulo: number): number {
    return this.menus.filter((menu) => menu.idModuloPai == idModulo).length;
  }

  listarPermissoes(menu: any): any {
    let retorno: number = 0;
    let modulosPrimarios: any[] = this.utilSessao.getModulos().filter((modulo) => modulo.nivel == 0 && (modulo.titulo == 'S' || modulo.idModuloPai == null || modulo.idModuloPai == 0));
    if (this.nivel == 1) {
      if (this.nivel == menu.nivel + 1) {
        retorno = 2;
        if (this.listarFilhos(menu.idModulo) == 0) {
          retorno = 1;
        }
      } else if (modulosPrimarios.find((modulosPrimario) => modulosPrimario.id == menu.idModulo)) {
        retorno = 2;
      }
    } else if (menu.idModuloPai == this.idPai) {
      if (menu.link == 'S') {
        retorno++;
        if (menu.idMenu == 101) {
          retorno = 1;
        }
      } else {
        if (menu.idModuloPai == this.idPai) {
          retorno++;
        }
        if (this.listarFilhos(menu.idModulo) == 0) {
          retorno = 1;
        }
      }
    }
    return retorno;
  }

  mudarEstilo(valor: any): void {
    if (this.mostraSenha == false) {
      this.posicao = valor;
    }
  }

  montarMenuPrincipal(): void {
    let a: number = 0;
    let b: number = 0;
    this.menuPrincipais = [];
    for (let menu of this.menus) {
      let menuPrincipal = new MenuPrincipal();
      if (menu.link == 'S') {
        if (menu.idRelatorio == null && menu.idModuloRelatorio != null) {
          menuPrincipal.id = menu.idModulo;
        } else {
          menuPrincipal.id = -1;
        }
      } else {
        menuPrincipal.id = menu.idModulo;
      }
      menuPrincipal.idPai = menu.idModuloPai;
      menuPrincipal.idMenu = menu.idMenu;
      menuPrincipal.link = menu.idModuloRelatorio && !menu.idRelatorio ? 'N' : menu.link;
      menuPrincipal.nivel = menu.nivel - 1;
      menuPrincipal.nome = menu.menu;
      menuPrincipal.novo = menu.novo;
      menuPrincipal.titulo = menu.titulo;
      menuPrincipal.icone = menu.icone;
      menuPrincipal.ordem = String(menu.ordem);
      menuPrincipal.permissao = menu.permissao;
      menuPrincipal.idRelatorio = menu.idRelatorio;
      menuPrincipal.parametro = menu.parametro;
      menuPrincipal.visualiza = menuPrincipal.nivel == 0 ? true : false;
      this.menuPrincipais.push(menuPrincipal);
      a++;
    }
    do {
      for (let lis of this.menuPrincipais) {
        if (lis.idPai == 0 || lis.idPai == null) {
          if (lis.feito == false) {
            lis.aberto = false;
            lis.feito = true;
            lis.nivel = 0;
            lis.visualiza = true;
            lis.possuiFilhos = false;
            lis.ordem = String(lis.ordem);
            b++;
          }
        } else if (lis.idPai > 0) {
          for (let lisIdPai of this.menuPrincipais) {
            if (lisIdPai.id == lis.idPai && lis.feito == false) {
              if (lisIdPai.nivel != null && lisIdPai.feito == true) {
                lis.aberto = false;
                lis.feito = true;
                if (lisIdPai.titulo == 'S') {
                  lis.nivel = lisIdPai.nivel;
                  lis.visualiza = true;
                } else {
                  lis.nivel = lisIdPai.nivel + 1;
                  lis.visualiza = false;
                }
                lis.ordem = lisIdPai.ordem + '-' + String(lis.ordem);
                lisIdPai.possuiFilhos = true;
                b++;
              }
            }
          }
        }
      }
    } while (a > b);
    let menusListaFinal: MenuPrincipal[] = [];
    for (let menusLista of this.menuPrincipais) {
      let adicionar = true;
      if (menusLista.link == 'N' && menusLista.possuiFilhos == false) {
        adicionar = false;
      }
      if (adicionar == true) {
        menusListaFinal.push(menusLista);
      }
    }
    menusListaFinal.sort((a, b) => a.ordem.localeCompare(b.ordem));
    this.menuPrincipais = [];
    this.menuPrincipais = menusListaFinal;
  }

  indentar(menuPrincipal: MenuPrincipal): string {
    let margin: any = Number(menuPrincipal.nivel * 12) + 24;
    return margin + 'px';
  }

  selecionaMenuPersonalizado(menuPrincipal: MenuPrincipal): void {
    menuPrincipal.aberto = !menuPrincipal.aberto;
    if (menuPrincipal.aberto == false) {
      this.fecharMenuFilhos(menuPrincipal, 'S');
    } else {
      this.fecharMenuFilhos(menuPrincipal, 'N');
    }
  }

  fecharMenuFilhos(linha: MenuPrincipal, fecharTudo: string): void {
    let menus: MenuPrincipal[] = this.menuPrincipais.filter((menu) => menu.idPai == linha.id);
    if (menus != null) {
      if (menus.length > 0) {
        for (let menu of menus) {
          if (fecharTudo == 'S') {
            menu.visualiza = false;
            menu.aberto = false;
            this.fecharMenuFilhos(menu, 'S');
          } else {
            menu.visualiza = !menu.visualiza;
          }
        }
      }
    }
  }

  irDestinoMenuRelatorio(menu: any): string {
    if (menu.idRelatorio != null) {
      return 'relatorio/r' + menu.idRelatorio + '/' + menu.idRelatorio;
    } else {
      return this.irDestino(menu.permissao);
    }
  }

  irDestino(permissao: any): string {
    let ehFormulario: boolean = false;
    if (permissao.tela.indexOf('Frm') > 0) {
      if (permissao.inserir == 'S') {
        ehFormulario = true;
      } else {
        permissao.tela = permissao.tela.substring(0, permissao.tela.indexOf('Frm'));
      }
    }
    if (ehFormulario) {
      return permissao.tela;
    } else {
      if (permissao.idOperacao) {
        return permissao.tela + '/' + permissao.idOperacao;
      } else {
        if (permissao.parametro) {
          return permissao.tela + '/' + permissao.parametro;
        } else {
          return permissao.tela;
        }
      }
    }
  }

  irParaMenuRelatorio(menu: any) {
    if (menu.parametro && menu.parametro.indexOf('pesquisa') == 0) {
      event.preventDefault();
      this.utilNotificacao.modalEmt.emit(menu.parametro);
      this.util.fecharMenuPrincipal();
    } else {
      if (menu.idRelatorio != null) {
        event.preventDefault();
        this.destinoCtrl = this.util.montarUrlRerlatorio(menu.idRelatorio);
        this.novaAba = true;
        this.ehCtrl();
      } else {
        this.irPara(menu.permissao);
      }
    }
  }

  irPara(permissao: any, limparParametro: boolean = true): void {
    if (limparParametro) {
      this.utilSessao.setParametro(null);
    }
    this.ir(this.irDestino(permissao), limparParametro);
  }

  verIcone(menuPrincipal: MenuPrincipal): string {
    let icone = 'oi-tablet';
    if (menuPrincipal.icone != '') {
      icone = menuPrincipal.icone;
    }
    return icone;
  }

  voltar(): void {
    this.voltarMenu();
  }

  ngOnDestroy(): void {
    if (this.tipo == 'menuPersonalizado') {
      this.utilSessao.setMenuPersonalizado(false);
      this.listaMenuNomes = [];
    }
    this.menuPrincipalEmitter.unsubscribe();
  }
}
