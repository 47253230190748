import { Component, Input } from '@angular/core';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { CompendioEletronicoIndicacoesFrmComponent } from './compendioEletronicoIndicacoesFrm/compendioEletronicoIndicacoesFrm.component';

@Component({
  selector: 'compendioEletronicoIndicacoes',
  templateUrl: './compendioEletronicoIndicacoes.component.html',
})
export class CompendioEletronicoIndicacoesComponent extends PaginaComponent {
  @Input() produtoFormulado: ProdutoFormulado = new ProdutoFormulado();
  public componente: any = null;
  public campoPesquisa: string = this.bibDialogo.cultura;
  public filtro: string = '';

  public listaCampoPesquisa: any[] = [{ id: this.bibDialogo.cultura, nome: this.bibDialogo.cultura }];

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.produtoFormuladoDose) {
        this.adicionarTemporario(this.plainToClass(ProdutoFormuladoDose, identificacao.conteudo), this.produtoFormulado.produtoFormuladoDoses);
        this.produtoFormulado.produtoFormuladoDoses.sort((a, b) => {
          return a.cultura === b.cultura ? 0 : a.cultura > b.cultura ? 1 : -1;
        });
      }
    });
  }

  ehRetornaQuantidadeCultura(produtoFormuladoDoses: ProdutoFormuladoDose[], buscaProdutoNome: string): number {
    return produtoFormuladoDoses.filter((produtoFormuladoDose) => produtoFormuladoDose.cultura == buscaProdutoNome).length;
  }

  abrirModal(produtoFormuladoDose: ProdutoFormuladoDose = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', produtoFormuladoDose));
    this.componente = CompendioEletronicoIndicacoesFrmComponent;
  }
}
