<layout [titulo]="operacao" [subTitulo]="pesquisaTodasOperacoes == false && movimentacoes.length > 0 ? movimentacoes[0].operacao : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div *ngIf="empresaRegraEmitirNfeContingencia != 1" class="row">
    <div class="col-sm-12">
      <div [ngClass]="empresaRegraEmitirNfeContingencia == 3 ? 'alert alert-danger has-icon' : 'alert alert-warning has-icon'" role="alert">
        <div class="alert-icon">
          <span class="fa fa-bullhorn"></span>
        </div>
        {{ empresaRegraEmitirNfeContingencia == 3 ? bibDialogo.servidorDaReceitaFederalEstaForaDeOperacao : bibDialogo.servidorDaReceitaFederalEstaComLentidao }}
      </div>
    </div>
  </div>
  <div class="row justify-content-between">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
    <span *ngIf="dataAtualizacao" class="text-uppercase">{{ bibDialogo.dataAtualizacao + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm:ss') }} </span>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-10">{{ bibDialogo.finalidade }}</th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-5 text-right clique" (click)="listar(add('documentoFiscal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviado + ' ' + bibDialogo.fiscal }}</th>
        <th class="clique" [ngClass]="usaSat == false && utilSessao.getUsuario()?.agrow == 'S' ? 'l-10' : 'l-20'" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parceiro }}</th>
        <th class="l-5 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorTotal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotal }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr [ngClass]="movimentacao.movimentacaoNfe?.aCancelar ? 'bg-amarelo-claro' : ''">
          <td>
            <check *ngIf="movimentacao.movimentacaoNfe?.status == 0 || movimentacao.movimentacaoNfe?.status == -3 || (movimentacao.movimentacaoNfe?.status != 302 && movimentacao.movimentacaoNfe?.status > 200 && movimentacao.movimentacaoNfe?.status <= 1000)" (alteracao)="setMovimentacaoSelecionado(movimentacao)"></check>
          </td>
          <td>
            <div class="row">
              <div>
                <icone *ngIf="movimentacao.ativo == 'S' || movimentacao.movimentacaoNfe?.situacaoEmAndamento == 'S' || movimentacao.movimentacaoNfe?.situacaoCancelado == 'S' || movimentacao.movimentacaoNfe?.situacaoDenegada == 'S'" [icone]="bibIcone.circulo" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="movimentacao.movimentacaoNfe?.status == 0 ? bibPropriedade.icone.cinza : movimentacao.movimentacaoNfe?.situacaoEmAndamento == 'S' ? (movimentacao.movimentacaoNfe?.status == 200 || movimentacao.movimentacaoNfe?.status == -1 ? bibPropriedade.icone.amarelo : bibPropriedade.icone.laranja) : movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' ? bibPropriedade.icone.verde : movimentacao.movimentacaoNfe?.situacaoCancelado == 'S' ? null : movimentacao.movimentacaoNfe?.situacaoDenegada == 'S' ? bibPropriedade.icone.roxo : bibPropriedade.icone.vermelho" [mensagem]="movimentacao.movimentacaoNfe?.status == 0 ? bibDialogo.naoEnviada : movimentacao.movimentacaoNfe?.situacaoEmAndamento == 'S' ? (movimentacao.movimentacaoNfe?.status == 200 ? bibDialogo.emAndamento : bibDialogo.cancelamentoEmAndamento) : movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' ? bibDialogo.transmitidaComSucesso : movimentacao.movimentacaoNfe?.situacaoCancelado == 'S' ? bibDialogo.cancelada : movimentacao.movimentacaoNfe?.situacaoDenegada == 'S' ? bibDialogo.notaDenegada : bibDialogo.naoAutorizado" [ajudaDireita]="true"></icone>
                <icone *ngIf="movimentacao.movimentacaoNfe?.aCancelar" [icone]="bibIcone.atencao" [cor]="bibPropriedade.icone.preto" [mensagem]="bibDialogo.notaACancelar" [ajudaDireita]="true"></icone>
              </div>
              <div>
                <icone *ngIf="movimentacao.movimentacaoNfe && movimentacao.movimentacaoNfe.movimentacaoCartaCorrecoes.length > 0 && (movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe?.situacaoCancelado == 'S')" [icone]="bibIcone.borracha" [mensagem]="movimentacao.movimentacaoNfe?.movimentacaoCartaCorrecoes.length + ' ' + bibDialogo.cartaCorrecaoVinculada"></icone>
              </div>
            </div>
          </td>
          <td><lista [campo]="movimentacao.movimentacaoNfe?.finalidade" [lista]="movimentacao.naturezaOperacaoFinalidade == 3 || movimentacao.movimentacaoNfe?.finalidade == 4 ? finalidadesDevolucao : finalidades" (alteracao)="movimentacao.movimentacaoNfe.finalidade = $event.id" [obrigatorio]="movimentacao.selecionado == 'S' ? true : false" [desabilitado]="movimentacao.selecionado == 'N' || movimentacao.movimentacaoNfe?.situacaoEmAndamento == 'S' || movimentacao.movimentacaoNfe?.status == -1 || movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe?.situacaoCancelado == 'S'"></lista></td>
          <td>{{ movimentacao.abreviacao }}</td>
          <td class="text-right">{{ movimentacao.documentoFiscal }}</td>
          <td class="limitar">
            <a href="#" (click)="abrirModalMovimentacao(movimentacao.id)">{{ movimentacao.parceiro }}</a>
          </td>
          <td>{{ movimentacao.data | data }}</td>
          <td class="text-right">{{ movimentacao.valorTotal | monetario }}</td>
          <ng-container *ngIf="usaSat == false && (movimentacao.movimentacaoNfe && movimentacao.movimentacaoNfe?.situacaoEmAndamento == 'S') || movimentacao.movimentacaoNfe?.cancelamentoEmAndamento == 'S'">
            <td *ngIf="movimentacao.movimentacaoNfe?.situacaoEmAndamento == 'S' || movimentacao.movimentacaoNfe?.situacaoNaoAtendido == 'S' || movimentacao.movimentacaoNfe?.aCancelar"><btnAdicional [icone]="bibIcone.lupa" [ajuda]="bibDialogo.consultarNfe" (btnAdicional)="consultarNfe(movimentacao)"></btnAdicional></td>
          </ng-container>
          <td><btnAdicional *ngIf="movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe?.situacaoNaoAtendido == 'S' || movimentacao.movimentacaoNfe?.situacaoDenegada == 'S' || movimentacao.movimentacaoNfe?.aCancelar" [icone]="movimentacao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="movimentacao.expandido = !movimentacao.expandido" [desabilitado]="movimentacao.movimentacaoNfe?.status != -1 && movimentacao.movimentacaoNfe?.status != 0 ? false : true"></btnAdicional></td>
          <td *ngIf="usaSat == false && movimentacao.movimentacaoNfe?.situacaoDenegada == 'S' && movimentacao.status == 'S'"><atencao [atencao]="bibDialogo.abraAMovimentacao" [tipo]="bibPropriedade.atencao.roxo"></atencao></td>
          <td><btnAdicional *ngIf="usaSat == false && (movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe?.situacaoCancelado == 'S')" [icone]="bibIcone.xml" [ajuda]="bibDialogo.xml" (btnAdicional)="baixarXml(movimentacao.movimentacaoNfe?.idTecnospeed, movimentacao.movimentacaoNfe?.status, movimentacao.chaveFiscal, movimentacao.idLoja)"></btnAdicional></td>
          <td><btnAdicional *ngIf="movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe?.situacaoCancelado == 'S' || usaSat == true" [icone]="bibIcone.pdf" [ajuda]="bibDialogo.pdf" (btnAdicional)="baixarPdf(movimentacao.movimentacaoNfe?.idTecnospeed, movimentacao.chaveFiscal, movimentacao.idLoja)"></btnAdicional></td>
          <td><btnAdicional *ngIf="movimentacao.movimentacaoNfe?.aCancelar == false && usaSat == false && usaNfe && movimentacao.ativo == 'S' && movimentacao.movimentacaoNfe && (movimentacao.movimentacaoNfe.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe.situacaoCancelado == 'S')" [icone]="bibIcone.borracha" [ajuda]="bibDialogo.cartaCorrecao" (btnAdicional)="abrirModalCartaCorrecao(movimentacao)"></btnAdicional></td>
          <td><btnAdicional *ngIf="movimentacao.movimentacaoNfe?.aCancelar == false && (movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || usaSat == true)" [icone]="bibIcone.atencao" [ajuda]="movimentacao.data.setHours(0, 0, 0, 0) != this.dataAtual.setHours(0, 0, 0, 0) ? bibDialogo.cancelamentoForaPrazo : bibDialogo.enviarSolicitacaoCancelamento" (btnAdicional)="ehAbrirModalCancelamento(movimentacao)"></btnAdicional></td>
          <td><btnAdicional *ngIf="movimentacao.movimentacaoNfe?.aCancelar == false && usaSat == false && (movimentacao.movimentacaoNfe?.situacaoAutorizado == 'S' || movimentacao.movimentacaoNfe?.situacaoCancelado == 'S')" [icone]="bibIcone.carta" [ajuda]="bibDialogo.enviarPdfXmlParaCliente" (btnAdicional)="ehEnviarEmail(movimentacao.idParceiro, movimentacao.movimentacaoNfe?.idTecnospeed, movimentacao.chaveFiscal, movimentacao.idLoja)"></btnAdicional></td>
          <!-- <td *ngIf="movimentacao.movimentacaoNfe?.aCancelar">
            <botao [compacto]="compacto" [legenda]="bibDialogo.desistirCancelamento" [fundo]="true" (click)="consultarNfe(movimentacao, true)"></botao>
          </td> -->
          <td><btnAdicional *ngIf="movimentacao.movimentacaoNfe?.aCancelar == false && usaSat == false && usaNfe && movimentacao.movimentacaoNfe?.situacaoAutorizado != 'S' && movimentacao.movimentacaoNfe?.situacaoCancelado != 'S' && movimentacao.movimentacaoNfe?.situacaoEmAndamento != 'S'" [icone]="bibIcone.olho" [ajuda]="bibDialogo.previsualizarDanfe" (btnAdicional)="preVisualizarDanfe(movimentacao.id)"></btnAdicional></td>
        </tr>
        <tr *ngIf="utilSessao.getUsuario()?.agrow == 'S' && movimentacao.movimentacaoNfe?.idTecnospeed">
          <td colspan="15" class="border-0 fonte-menor">
            <div class="justify-content-end p-0">
              <p class="clique mr-3 mb-0" (click)="abrirResumoNfe(movimentacao.movimentacaoNfe?.idTecnospeed)">{{ bibDialogo.resumo + ' ' + bibDialogo.nfe }}</p>
              <p ngbTooltip="{{ bibDialogo.ajudaValidarXml }}" class="clique mr-3 mb-0" (click)="buscarXml(movimentacao.movimentacaoNfe?.idTecnospeed)">{{ bibDialogo.validarXml }}</p>
              <p class="clique mb-0" (click)="buscarXml(movimentacao.movimentacaoNfe?.idTecnospeed, true)">{{ bibDialogo.formatarXml }}</p>
            </div>
          </td>
        </tr>
        <ng-container *ngIf="movimentacao.expandido">
          <tr *ngIf="movimentacao.movimentacaoNfe?.status != 0 && movimentacao.movimentacaoNfe?.status != 100 && movimentacao.movimentacaoNfe?.status != -1">
            <td colspan="15"><descricao [rotulo]="bibDialogo.rejeicao" [campo]="(movimentacao.movimentacaoNfe?.situacaoDenegada == 'S' ? movimentacao.parceiro + ' - ' + bibDialogo.estaComInscricaoReceitaIrregular + ' - ' : '') + movimentacao.movimentacaoNfe?.statusDescricao" [normal]="true" [maximoCaracteres]="1500" [desabilitado]="true"></descricao></td>
          </tr>
          <ng-container *ngIf="movimentacao.movimentacaoNfe?.status == 100">
            <tr>
              <td [colSpan]="movimentacao.movimentacaoNfe?.modeloNotaFiscal != 59 ? 8 : 15"><apresentacao [rotulo]="bibDialogo.chave" [campo]="movimentacao.chaveFiscal"></apresentacao></td>
              <td *ngIf="movimentacao.movimentacaoNfe?.modeloNotaFiscal != 59" colspan="7"><apresentacao [rotulo]="bibDialogo.protocolo" [campo]="movimentacao.movimentacaoNfe?.protocolo"></apresentacao></td>
            </tr>
            <tr>
              <td colspan="8"><apresentacao [rotulo]="bibDialogo.situacao" [campo]="movimentacao.movimentacaoNfe?.tentativaForaPrazo == 'S' ? bibDialogo.cancelamentoForaPrazo : bibDialogo.transmitidaComSucesso"> </apresentacao></td>
              <td colspan="7"><apresentacao [rotulo]="bibDialogo.dataAutorizacao" [campo]="movimentacao.movimentacaoNfe?.dataHora"></apresentacao></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot *ngIf="movimentacoes.length > 0">
      <tr *ngIf="valorMovimentacaoTotalizado">
        <td [colSpan]="7"></td>
        <td class="text-right font-weight-bold">{{ valorMovimentacaoTotalizado | monetario }}</td>
        <td></td>
      </tr>
    </tfoot>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <totalizador *ngIf="contagemMovimentacaoCancelar > 0" [atencaoTotalizador]="bibDialogo.nNotasSeremCanceladas" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta" [valorTotalizador]="contagemMovimentacaoCancelar" [tipoTotalizador]="bibPropriedade.cor.vermelho"></totalizador>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="row p-0 m-0">
    <botao [compacto]="compacto" [legenda]="bibDialogo.emitir + ' ' + (usaNfe ? bibDialogo.nfe : usaSat ? bibDialogo.sat : bibDialogo.nfce)" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="iniciarProcessamentoNfe()" [desabilitado]="!temPermissaoInserir"></botao>
    <botao *ngIf="empresaRegraEmitirNfeContingencia == 3" [compacto]="compacto" [legenda]="bibDialogo.emitirEmContingencia" [fundo]="true" (botaoEmt)="iniciarProcessamentoNfe(true)" [desabilitado]="!temPermissaoInserir"></botao>
  </div>
  <div class="row m-0 p-0 align-content-center">
    <icone class="mt-1" *ngIf="contagemMovimentacaoCancelar > 0" [icone]="bibIcone.atencao" [cor]="bibPropriedade.icone.amarelo"></icone>
    <atencao *ngIf="contagemMovimentacaoCancelar > 0" [atencao]="bibDialogo.notasACancelar" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoInserir == false"></bloqueado>
