import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';

@Component({
  templateUrl: './r2009.component.html',
})
export class R2009Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public lancamentoContabeis: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.lancamentoContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'LancamentoContabil').lista;
      this.ordenarAgrupar(this.lancamentoContabeis);
      this.ehImprimirRelatorio();
    });
  }
}
