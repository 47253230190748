<div class="row align-items-center">
  <titulo class="col-sm pl-sm-2" [titulo]="bibDialogo.atualizacaoCadastro" [imagem]="bibImagem.atualizacaocadastro" [icone]="bibIcone.mais" (iconeEmt)="adicionarAtualizacao()" [desabilitado]="bloquearPermissao(parceiro.id)"></titulo>
</div>
<div class="row pl-3" *ngIf="parceiro.parceiroAtualizacoes.length == 0">
  <p>
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.atualizacaoCadastro.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarAtualizacao()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="parceiro.parceiroAtualizacoes ? (parceiro.parceiroAtualizacoes.length > 0 ? true : false) : false">
  <thead>
    <tr>
      <th class="l-20">{{ bibDialogo.dataEHora }}</th>
      <th class="l-20">{{ bibDialogo.usuario }}</th>
      <th class="l-50">{{ bibDialogo.observacao }}</th>
      <th class="l-10"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parceiroAtualizacao of parceiro.parceiroAtualizacoes; let i = index">
      <td>{{ parceiroAtualizacao.data | data: 'dd/MM/yyyy HH:mm' }}</td>
      <td class="limitar">{{ parceiroAtualizacao.nomeUsuario ? parceiroAtualizacao.nomeUsuario : nomeUsuario }}</td>
      <td class="limitar"><texto *ngIf="!parceiroAtualizacao.id" [campo]="parceiroAtualizacao.observacao" (alteracao)="parceiroAtualizacao.observacao = $event" [maximoCaracteres]="100" [compacto]="true" [desabilitado]="parceiroAtualizacao.id" [foco]="true"></texto> {{ parceiroAtualizacao.id ? parceiroAtualizacao.observacao : '' }}</td>
      <td class="row justify-content-end"><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this.parceiro.parceiroAtualizacoes, this.excluirParceiroAtualizacoes, parceiroAtualizacao.id)"></btnAdicional></td>
    </tr>
  </tbody>
</table>
