import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros2032: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataVencimentoDe, nome: 'VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.familia, nome: 'ID_FAMILIA', coluna: 12, servicoWeb: bibServico.familia, tipo: bibPropriedade.filtro.busca },

]);

export default filtros2032;
