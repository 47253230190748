import { Type } from 'class-transformer';
import { EquipeParceiro } from './equipeParceiro';

export class Equipe {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public ativo: string = 'S';
  public id: number;
  public idEmpresa: number;
  public idGestor: number;
  public idLoja: number;  
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public equipeParceiros: EquipeParceiro[] = [];

  public gestor: string;
  public loja: string;  
}
