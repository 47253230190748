import { Type } from 'class-transformer';

export class Cidade {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public ativo: string = 'S';
  public empresa: string;
  public estado: string;
  public ibge: string;
  public id: number;
  public idEmpresa: number;
  public idEstado: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;

  public cidadeEstado: string;
}
