import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GrupoUsuarioMenu } from 'src/app/modelo/grupoUsuarioMenu';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'grupoPermissaoMenusFrm',
  templateUrl: './grupoPermissaoMenusFrm.component.html',
})
export class GrupoPermissaoMenusFrmComponent extends PaginaComponent {
  @Input() grupoUsuarioMenus: GrupoUsuarioMenu[] = [];
  @Output() alteracao = new EventEmitter<any>();
  public campoPesquisa: string = this.bibDialogo.menu;
  public filtro: string = '';

  public listaCampoPesquisa: any[] = [
    { id: this.bibDialogo.menu, nome: this.bibDialogo.menu },
    { id: this.bibDialogo.semAcesso, nome: this.bibDialogo.semAcesso },
    { id: this.bibDialogo.comAcesso, nome: this.bibDialogo.comAcesso },
  ];

  setCampoPesquisa(valor: string): void {
    this.campoPesquisa = valor;
    if (this.campoPesquisa == this.bibDialogo.semAcesso || this.campoPesquisa == this.bibDialogo.comAcesso) {
      this.filtro = '';
    }
  }

  alterarTodos(grupoUsuarioMenu: GrupoUsuarioMenu, valor: string): void {
    grupoUsuarioMenu.acessar = valor;
    grupoUsuarioMenu.editar = valor;
    grupoUsuarioMenu.inserir = valor;
    grupoUsuarioMenu.excluir = valor;
    grupoUsuarioMenu.inativar = valor;
    if (valor == 'N') grupoUsuarioMenu.favorito = valor;
  }
}
