const bibParametro = {
  alimentaCustoReposicao: 7,
  aprovarMovimentacao: 21,
  comissao: 31,
  custoReposicao: 22,
  destino: 19,
  devolucao: 33,
  entradaSaida: 1,
  estoqueComprado: 8,
  estoqueNFe: 34,
  estoqueFisico: 2,
  estoqueReservado: 3,
  estoqueTransferido: 14,
  exigirSenha: 24,
  faturamento: 10,
  financeiro: 4,
  identificacao: 32,
  movimentaFinanceiro: 4,
  negociacao: 9,
  permiteValorMenor: 25,
  vincular: 30,
  estoqueCarteira: 36,
  vendaDireta: 37,
  entradaServico: 38,
};

export default bibParametro;
