<layout [titulo]="produtoFormulado.nome" [id]="produtoFormulado.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="justify-content-end" *ngIf="utilSessao.getUsuario()?.agrow == 'S' || produtoFormulado.bula == 'S' || produtoFormulado.fichaEmergencia == 'S'">
    <p *ngIf="utilSessao.getUsuario()?.agrow == 'S' || produtoFormulado.bula == 'S'" [ngClass]="utilSessao.getUsuario()?.agrow == 'S' ? 'mr-1' : 'mr-4'">{{ bibDialogo.bula + ': ' }}<a *ngIf="produtoFormulado.bula == 'S'" [href]="'https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/arquivos/bula/' + produtoFormulado.id + '.pdf'" target="_blank">{{ bibDialogo.cliqueAqui }}</a></p>
    <interruptor *ngIf="utilSessao.getUsuario()?.agrow == 'S'" class="mr-4" [campo]="produtoFormulado.bula" (alteracao)="produtoFormulado.bula = $event"></interruptor>
    <p *ngIf="utilSessao.getUsuario()?.agrow == 'S' || produtoFormulado.fichaEmergencia == 'S'" class="mr-1">{{ bibDialogo.fichaEmergencia + ': ' }}<a *ngIf="produtoFormulado.fichaEmergencia == 'S'" [href]="'https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/arquivos/fichaemergencia/' + produtoFormulado.id + '.pdf'" target="_blank">{{ bibDialogo.cliqueAqui }}</a></p>
    <interruptor *ngIf="utilSessao.getUsuario()?.agrow == 'S'" [campo]="produtoFormulado.fichaEmergencia" (alteracao)="produtoFormulado.fichaEmergencia = $event"></interruptor>
  </div>
  <section class="card card-fluid">
    <header class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <a class="nav-link active" [id]="bibDialogo.dados" data-toggle="tab" href="#dados">{{ bibDialogo.dados }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [id]="bibDialogo.indicacoes" data-toggle="tab" href="#indicacoes">{{ bibDialogo.indicacoes }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [id]="bibDialogo.restricoes" data-toggle="tab" href="#restricao">{{ bibDialogo.restricoes }} <atencao *ngIf="this.produtoFormulado.produtoFormuladoRestricoes.length > 0" [atencao]="this.produtoFormulado.produtoFormuladoRestricoes.length"></atencao></a>
        </li>
      </ul>
    </header>
    <div class="card-body">
      <div id="myTabContent" class="tab-content">
        <compendioEletronicoDadosFrm class="tab-pane fade" id="dados" [ngClass]="{ 'active show': true }" [produtoFormulado]="produtoFormulado"></compendioEletronicoDadosFrm>
        <compendioEletronicoIndicacoes class="tab-pane fade" id="indicacoes" [produtoFormulado]="produtoFormulado"></compendioEletronicoIndicacoes>
        <compendioEletronicoRestricao class="tab-pane fade" id="restricao" [produtoFormulado]="produtoFormulado"></compendioEletronicoRestricao>
      </div>
    </div>
  </section>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirProdutoDose()" [desabilitado]="bloquearPermissao(produtoFormulado.id)"></botao>
