<div class="row">
  <titulo class="col-sm" [id]="'adicionarContato'" [titulo]="bibDialogo.contato" [imagem]="bibImagem.pessoa" [icone]="bibIcone.mais" (click)="abrirModal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.contato.toLowerCase()" [desabilitado]="temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
</div>
<div class="row" *ngIf="_parceiroContatos.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.contato.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="_parceiroContatos.length > 0">
  <thead>
    <tr>
      <th class="l-30">{{ bibDialogo.nome }}</th>
      <th class="l-15">{{ bibDialogo.tipoDeContato }}</th>
      <th class="l-10">{{ bibDialogo.telefone }}</th>
      <th class="l-20">{{ bibDialogo.email }}</th>
      <th class="l-15">{{ bibDialogo.observacao }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{ tachado: parceiroContato.ativo != 'S' }" *ngFor="let parceiroContato of _parceiroContatos; let i = index">
      <td *ngIf="parceiroContato.nome != null" class="limitar">{{ parceiroContato.nome }}</td>
      <td *ngIf="parceiroContato.nome == null" class="limitar"><atencao [atencao]="bibDialogo.nomeContatoObrigatorio" [tipo]="bibPropriedade.atencao.cuidado"></atencao></td>
      <td>{{ parceiroContato.vinculo }}</td>
      <td>{{ parceiroContato.telefone }}</td>
      <td class="limitar">{{ parceiroContato.email }}</td>
      <td class="limitar">{{ parceiroContato.observacao }}</td>
      <td>
        <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(parceiroContato)"></btnAdicional>
      </td>
      <td>
        <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this._parceiroContatos, this.excluirContatos, parceiroContato.id)"></btnAdicional>
      </td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
