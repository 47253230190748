<td class="p-0" colspan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r">{{ bibDialogo.produto }}</th>
          <th class="r-1">{{ bibDialogo.unidade }}</th>
          <th class="text-right r-1">{{ bibDialogo.quantidade }}</th>
          <th class="text-right r-1">{{ bibDialogo.valor }}</th>
          <th class="text-right r-1">{{ bibDialogo.pesoBruto }}</th>      
          <th class="r-1"></th>                  
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let romaneioEntregaProdutoItem of romaneioEntregaProdutoMov.romaneioEntregaProdutoItens">
          <tr>
            <td class="limitar r">{{ romaneioEntregaProdutoItem.produtoNumero + ' - ' + romaneioEntregaProdutoItem.produto }}</td>
            <td>{{ romaneioEntregaProdutoItem.unidadeAbreviacao }}</td>
            <td class="text-right">{{ romaneioEntregaProdutoItem.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ romaneioEntregaProdutoItem.valorFinal | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ romaneioEntregaProdutoItem.pesoBruto | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="r-1"></td>                
          </tr>
        </ng-container>
      </tbody>
    </table>
    <br/>
</td>
