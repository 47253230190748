<layout [titulo]="dataSaldo ? bibDialogo.saldoEm + (dataSaldo | data) : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-20 clique" (click)="listar(add('conta'), this.utilSessao.posicao, this.paginacao)">{{ menuConta.apelido }}</th>
        <th class="l-15 naoImprimir"></th>
        <th class="l-15 text-right clique" (click)="listar(add('valorPrevisto'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.previsto }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorBaixadas'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.baixas }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorConciliadas'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.conciliadas }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('limite'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.limite }}</th>
        <th class="l-20 text-right">{{ bibDialogo.disponivel }}<ajuda [ajuda]="bibDialogo.disponivelAjuda"></ajuda></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let saldoBancario of saldoBancarios; let i = index">
        <tr>
          <td class="limitar">{{ saldoBancario.conta }}</td>
          <td><img *ngIf="saldoBancario.numeroBanco" src="img/Boletos/Colorido/{{ saldoBancario.numeroBanco }}.bmp" class="logomarca" /></td>
          <td class="text-right">{{ saldoBancario.valorPrevisto | monetario }}</td>
          <td class="text-right">{{ saldoBancario.valorBaixadas | monetario }}</td>
          <td class="text-right">{{ saldoBancario.valorConciliadas | monetario }}</td>
          <td class="text-right">{{ saldoBancario.limite | monetario }}</td>
          <td class="text-right">{{ saldoBancario.valorConciliadas + saldoBancario.limite | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
