import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1053.component.html',
})
export class R1053Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public movimentacoes: any[] = [];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public relatorioGrupoFinal: RelatorioGrupo = { totais: totais, linhas: [] };
  public usaNegociacao: boolean;
  public rotuloNegociacao: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 71).apelido;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.ordenarAgrupar(this.movimentacoes);
      this.ehImprimirRelatorio();
      Object.assign(this.relatorioGrupoFinal, this.relatorioGrupo);
      this.setRetrato();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaNegociacao = this.definirRegra(relatorioAdicional, 174, this.usaNegociacao);

  }

  expandir(movimentacao: any) {
    movimentacao.expandido = !movimentacao.expandido;
    if (movimentacao.expandido && (movimentacao.movimentacaoProdutos == null || movimentacao.movimentacaoProdutos.length == 0)) {
      this.listarEspecifico(new Criterio('ID_MOVIMENTACAO', movimentacao.id), 46).subscribe((res) => {
        movimentacao.movimentacaoProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }
}
