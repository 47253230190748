import { Component } from '@angular/core';
import { Conta } from 'src/app/modelo/conta';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from '../../utilitario/pagina.component';

@Component({
  templateUrl: './contaFrm.component.html',
})
export class ContaFrmComponent extends PaginaComponent {
  public conta: Conta = new Conta();
  public criterioContaCorrentista: Criterio[] = [new Criterio('CORRENTISTA', 3)];
  public ehValidoContaContabilPai: boolean = true;
  public empresaUsaContabilidade: boolean;
  public empresaUsaCorrentista: boolean;
  public ehSpedRegimeCaixa: boolean = this.utilSessao.getLojas().filter((loja) => loja.spedContCritEscrituracao == 1).length > 0 ? true : false;

  public opcoes: any[] = [
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
  ];

  public opcoesRemessaLayout: any[] = [
    { id: '240', nome: '240' },
    { id: '400', nome: '400' },
  ];

  public tipoContaBancaria: any[] = [
    { id: 'Corrente', nome: 'Corrente' },
    { id: 'Poupança', nome: 'Poupança' },
  ];

  public opcoesTipoJuros: any[] = [
    { id: '1', nome: this.bibDialogo.isento.toUpperCase() },
    { id: '2', nome: this.bibDialogo.valorPorDia.toUpperCase() },
    { id: '3', nome: this.bibDialogo.taxaMensal.toUpperCase() },
  ];

  public tipoConta: any[] = [
    { id: 'B', nome: this.bibDialogo.bancaria.toUpperCase() },
    { id: 'C', nome: this.bibDialogo.caixa.toUpperCase() },
  ];

  public tipoImpressao: any[] = [
    { id: '0', nome: this.bibDialogo.pdfNormal.toUpperCase() },
    { id: '1', nome: this.bibDialogo.pdfCarneDuplo.toUpperCase() },
    { id: '2', nome: this.bibDialogo.pdfCarneTriplo.toUpperCase() },
    { id: '3', nome: this.bibDialogo.pdfDuplo.toUpperCase() },
  ];

  public tipoProtestos: any[] = [
    { id: 1, nome: this.bibDialogo.protestarDiasCorridos.toUpperCase() },
    { id: 2, nome: this.bibDialogo.protestarDiasUteis.toUpperCase() },
    { id: 3, nome: this.bibDialogo.protestarNao.toUpperCase() },
    { id: 4, nome: this.bibDialogo.protestarFimFamilentarUteis.toUpperCase() },
    { id: 5, nome: this.bibDialogo.protestarFimFamilentarCorridos.toUpperCase() },
    { id: 8, nome: this.bibDialogo.protestarNegativacao.toUpperCase() },
  ];

  public tipoMultas: any[] = [
    { id: 0, nome: this.bibDialogo.naoRegistrarMulta.toUpperCase() },
    { id: 1, nome: this.bibDialogo.valorEmReaisFixo.toUpperCase() },
    { id: 2, nome: this.bibDialogo.valorEmPercentual.toUpperCase() },
  ];

  public tipoDescontos: any[] = [
    { id: 0, nome: this.bibDialogo.semInstrucaoDesconto.toUpperCase() },
    { id: 1, nome: this.bibDialogo.valorFixoAteDataInformada.toUpperCase() },
    { id: 2, nome: this.bibDialogo.percentualAteDataInformada.toUpperCase() },
    { id: 3, nome: this.bibDialogo.valorPorAntecipacaoDiaCorrido.toUpperCase() },
    { id: 4, nome: this.bibDialogo.valorPorAntecipacaoDiaUtil.toUpperCase() },
    { id: 5, nome: this.bibDialogo.percentualSobreDiaCorrido.toUpperCase() },
    { id: 6, nome: this.bibDialogo.percentualSobreDiaUtil.toUpperCase() },
    { id: 7, nome: this.bibDialogo.cancelamentoDesconto.toUpperCase() },
  ];

  public numeroBancos: any[] = [
    { id: '001', nome: '001 - BB' },
    { id: '003', nome: '003 - BASA' },
    { id: '004', nome: '004 - BNB' },
    { id: '021', nome: '021 - BANESTE' },
    { id: '033', nome: '033 - SANTANDER' },
    { id: '037', nome: '037 - BANPARA' },
    { id: '041', nome: '041 - BANRISUL' },
    { id: '047', nome: '047 - BANESE' },
    { id: '070', nome: '070 - BRB' },
    { id: '077', nome: '077 - BANCO INTER' },
    { id: '084', nome: '084 - UNIPRIME NORTE DO PARANÁ' },
    { id: '085', nome: '085 - AILOS' },
    { id: '089', nome: '089 - CREDISAN' },
    { id: '092', nome: '092 - BRK' },
    { id: '099', nome: '099 - UNIPRIME IGUAÇU' },
    { id: '104', nome: '104 - CAIXA' },
    { id: '133', nome: '133 - CRESOL' },
    { id: '136', nome: '136 - UNICRED' },
    { id: '218', nome: '218 - BS2' },
    { id: '224', nome: '224 - FIBRA' },
    { id: '237', nome: '237 - BRADESCO' },
    { id: '246', nome: '246 - BANCO ABC' },
    { id: '291', nome: '291 - BCN' },
    { id: '336', nome: '336 - C6 BANCK' },
    { id: '341', nome: '341 - ITAU' },
    { id: '389', nome: '389 - BMB' },
    { id: '422', nome: '422 - SAFRA' },
    { id: '637', nome: '637 - SOFISA' },
    { id: '653', nome: '653 - VOITER' },
    { id: '655', nome: '655 - VOTORANTIM' },
    { id: '707', nome: '707 - DAYCOVAL' },
    { id: '745', nome: '745 - CITIBANK' },
    { id: '748', nome: '748 - SICREDI' },
    { id: '755', nome: '755 - BANK OF AMERICA' },
    { id: '756', nome: '756 - SICCOB' },
    { id: '000', nome: '000 - ATHENA' },
  ];

  public homologacaoProducao: any[] = [
    { id: 'S', nome: this.bibDialogo.homologacao.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.producao.toUpperCase() },
  ];

  ngOnInit(): void {
    this.empresaUsaContabilidade = this.utilSessao.getEmpresa().contabilidade == 'S' ? true : false;
    this.empresaUsaCorrentista = this.utilSessao.getEmpresa().correntista == 'S' ? true : false;
    this.ehAlteracao();
    this.sugerirEspecie(this.conta.numeroBanco);
  }

  ehAlteracao(): void {
    this.conta = this.plainToClass(Conta, this.ehAtualizacao(this.conta));
    if (this.conta.id) {
      this.listarConta(this.conta.id);
    }
  }

  mudarBanco(numeroBanco: string) {
    if (numeroBanco != null) {
      this.sugerirEspecie(numeroBanco);
    }
  }

  sugerirEspecie(numeroBanco: string) {
    if (this.conta.id == null) {
      if (numeroBanco == '001') {
        this.conta.especie = '';
      } else {
        this.conta.especie = 'R$';
      }
    }
  }

  setTipoConta(tipoConta: any): void {
    this.conta.tipo = tipoConta.id;
    if ((this.conta.tipo == 'C' || this.conta.tipo == 'D') && this.conta.numero == '') this.conta.numero = '0';
    if (this.conta.tipo == 'B' && this.conta.numero == '0') this.conta.numero = '';
  }

  setContaContabil(contaContabil: ContaContabil): void {
    this.conta.idContaContabil = contaContabil.id;
    this.conta.contaContabil = contaContabil && contaContabil.nome ? contaContabil.nome.trim() : null;
    this.ehValidoContaContabilPai = contaContabil && contaContabil.pai == 'S' ? false : true;
  }

  listarConta(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.conta).subscribe((res) => {
      this.conta = this.plainToClass(Conta, res[0]) as any;
    });
  }

  persistirConta(novo: boolean = false): void {
    this.definirNumero();
    if (this.ehValidoObrigatorio() && this.ehValidoObrigatorioBoleto()) {
      super.persistir(new Transporte(this.conta), this.bibServico.conta, novo ? new Conta() : null);
    }
  }

  definirNumero() {
    if (this.conta.tipo != 'B') {
      this.conta.numero = '0';
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.conta.nome && this.conta.idLoja && this.conta.numero && this.conta.limite != null && (this.conta.numeroBanco ? this.conta.agenciaBanco && this.conta.digitoBanco && this.conta.numero && this.conta.digito && this.conta.tipoContaBancaria && this.conta.cedente && this.conta.tipoImpressao && this.conta.numeroBoleto && this.conta.layout : true)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoObrigatorioBoleto(): boolean {
    if (this.conta.numeroBanco && this.util.coalesce(this.conta.tipoJuros, "-1") != "-1" && (this.conta.tipoJuros == "1" || (this.conta.tipoJuros != "1" && this.conta.taxaJuros != undefined && this.util.coalesce(this.conta.diasJuros, -1) >= 0))) {
      if (this.conta.numeroBanco && this.util.coalesce(this.conta.tipoMulta, -1) >= 0 && (this.conta.tipoMulta == 0 || (this.conta.tipoMulta != 0 && this.util.coalesce(this.conta.percentualMulta, -1) >= 0 && this.util.coalesce(this.conta.diasMulta, -1) >= 0)) ? true : false) {
        if (this.conta.numeroBanco && this.util.coalesce(this.conta.tipoDesconto, -1) >= 0 && (this.conta.tipoDesconto == 0 || (this.conta.tipoDesconto != 0 && this.util.coalesce(this.conta.valorDesconto, -1) >= 0 && this.util.coalesce(this.conta.diasDesconto, -1) >= 0)) ? true : false) {
          if (this.conta.numeroBanco && this.util.coalesce(this.conta.tipoProtesto, -1) >= 0 && (this.conta.tipoProtesto == 3 || (this.conta.tipoProtesto != 3 && this.util.coalesce(this.conta.diasProtesto, -1) >= 0)) ? true : false) {
            return true;
          }
        }
      }
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
