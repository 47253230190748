import { Component, Input } from '@angular/core';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'pesquisaClienteMovimentacaoProduto',
  templateUrl: './pesquisaClienteMovimentacaoProduto.component.html',
})
export class PesquisaClienteMovimentacaoProdutoComponent extends PaginaComponent {
  @Input() movimentacoes: Movimentacao[] = [];
  public movimentacaoProdutoAgrupados: MovimentacaoProduto[] = [];
  @Input() apresentaTodos: boolean;
  public agrupado: boolean = false;
  public idProdutos: number[];

  setAgrupado() {
    if (this.movimentacaoProdutoAgrupados.length <= 0) {
      this.agrupar();
    }
    this.agrupado = !this.agrupado;
  }

  private agrupar(): void {
    let idsProdutoIncluidos: number[] = [];
    const movimentacoes: Movimentacao[] = this.plainToClass(Movimentacao, this.movimentacoes);
    movimentacoes.forEach((movimentacao) => {
      movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (idsProdutoIncluidos.includes(movimentacaoProduto.idProduto)) {
          let posicao = this.movimentacaoProdutoAgrupados.findIndex((movimentacaoProdutoBusca) => movimentacaoProdutoBusca.idProduto == movimentacaoProduto.idProduto);
          this.movimentacaoProdutoAgrupados[posicao].descontoValor += movimentacaoProduto.descontoValor;
          this.movimentacaoProdutoAgrupados[posicao].valorTotal += movimentacaoProduto.valorTotal;
          this.movimentacaoProdutoAgrupados[posicao].setQuantidade(this.movimentacaoProdutoAgrupados[posicao].getQuantidade() + movimentacaoProduto.getQuantidade(), null, null, null, 0, true);
        } else {
          this.movimentacaoProdutoAgrupados.push(movimentacaoProduto);
          idsProdutoIncluidos.push(movimentacaoProduto.idProduto);
        }
      });
    });
  }
}
