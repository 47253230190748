import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Menu } from 'src/app/modelo/menu';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import filtros from 'src/app/pagina/menuEmpresa/filtro';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './menuEmpresa.component.html',
})
export class MenuEmpresaComponent extends PaginaComponent {
  public criterios: Criterio[] = [];
  public filtros: Filtro[] = filtros;
  public menuEmpresas: MenuEmpresa[] = [];
  public menus: Menu[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.criterios = criterios.filter((criterio) => criterio.nome == 'MENU' || criterio.nome == 'APELIDO');
    criterios.push(new Criterio('SISTEMA', this.utilSessao.sistema));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.menu).subscribe((res) => {
      this.menus = this.plainToClass(Menu, res) as any;
      this.listarMenuEmpresa(criterios);
    });
  }

  listarMenuEmpresa(criterios: Criterio[]): void {
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.menuEmpresa).subscribe((res) => {
      this.vincular(this.plainToClass(MenuEmpresa, res) as any);
    });
  }

  vincular(menuEmpresas: MenuEmpresa[]): void {
    this.menuEmpresas = [];
    const usuario: Usuario = this.utilSessao.getUsuario();
    this.menus.forEach((menu) => {
      let menuEmpresa: MenuEmpresa = menuEmpresas.find((menuEmpresa) => menuEmpresa.idMenu == menu.id);
      if (menuEmpresa) {
        this.menuEmpresas.push(menuEmpresa);
      } else {
        let menuEmpresaNovo: MenuEmpresa = new MenuEmpresa();
        menuEmpresaNovo.apelido = menu.nome;
        menuEmpresaNovo.ativo = menu.ativo;
        menuEmpresaNovo.descricao = menu.descricao;
        menuEmpresaNovo.idEmpresa = usuario.idEmpresa;
        menuEmpresaNovo.idMenu = menu.id;
        menuEmpresaNovo.menu = menu.nome;
        menuEmpresaNovo.menuEmpresa = false;
        menuEmpresaNovo.tag = menu.tag;
        this.menuEmpresas.push(menuEmpresaNovo);
      }
    });
    this.menuEmpresas.sort((a, b) => (a['menu'] == b['menu'] ? 0 : a['menu'] > b['menu'] ? 1 : -1));
  }

  alterar(menuEmpresa: MenuEmpresa, tipo: string, valor: string): void {
    if (menuEmpresa[tipo] != valor) {
      menuEmpresa.alterado = true;
    }
    menuEmpresa[tipo] = valor;
  }

  persistirMenuEmpresa(): void {
    let menuEmpresas: MenuEmpresa[] = this.menuEmpresas.filter((menuEmpresa) => menuEmpresa.alterado);
    if (this.ehValido(menuEmpresas)) {
      let idsExcluir: number[] = [];
      this.criarListaExclusao(menuEmpresas, idsExcluir);
      let transporte: Transporte = new Transporte(this.criarListaPersistir(menuEmpresas));
      transporte.adicionar(idsExcluir);
      super.persistir(transporte, this.bibServico.menuEmpresa, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.listar(this.criterios);
        }
      });
    }
  }

  criarListaPersistir(menuEmpresas): MenuEmpresa[] {
    let menuEmpresaPersistir: MenuEmpresa[] = [];
    menuEmpresas.forEach((menuEmpresa) => {
      const menu: Menu = this.menus.find((menuBusca) => menuBusca.id == menuEmpresa.idMenu);
      if (menuEmpresa.apelido != menu.nomeOriginal || menuEmpresa.tag != menu.tagOriginal || menuEmpresa.ativo != menu.ativo || menuEmpresa.descricao != menu.descricaoOriginal) {
        if (menuEmpresa.descricao == menu.descricaoOriginal) {
          menuEmpresa.descricao = null;
        }
        menuEmpresaPersistir.push(menuEmpresa);
      }
    });
    return menuEmpresaPersistir;
  }

  criarListaExclusao(menuEmpresas: MenuEmpresa[], idsExcluir: number[]): void {
    menuEmpresas.forEach((menuEmpresa) => {
      const menu: Menu = this.menus.find((menuBusca) => menuBusca.id == menuEmpresa.idMenu);
      if (menuEmpresa.apelido == menu.nomeOriginal && menuEmpresa.tag == menu.tagOriginal && menuEmpresa.ativo == menu.ativo && menuEmpresa.descricao == menu.descricaoOriginal && menuEmpresa.id) {
        idsExcluir.push(menuEmpresa.id);
      }
    });
  }

  ehValido(menuEmpresas: MenuEmpresa[]): boolean {
    const ehValidoAlterado = this.ehValidoAlterado(menuEmpresas);
    const ehValidoVazio = this.ehValidoVazio(menuEmpresas);
    return ehValidoAlterado && ehValidoVazio;
  }

  ehValidoVazio(menuEmpresas: MenuEmpresa[]): boolean {
    for (const menuEmpresa of menuEmpresas) {
      if (!menuEmpresa.apelido || !menuEmpresa.tag) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.camposSemDados + ': ' + menuEmpresa.menu));
        return false;
      }
    }
    return true;
  }

  ehValidoAlterado(menuEmpresas: MenuEmpresa[]): boolean {
    let idsExcluir: number[] = [];
    this.criarListaExclusao(menuEmpresas, idsExcluir);
    if ((menuEmpresas.length > 0 && this.criarListaPersistir(menuEmpresas).length > 0) || idsExcluir.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumMenuAlterado));
    return false;
  }

  restaurarPadrao(): void {
    this.menuEmpresas.forEach((menuEmpresa) => {
      const menu: Menu = this.menus.find((menuBusca) => menuBusca.id == menuEmpresa.idMenu);
      if (menuEmpresa.apelido != menu.nomeOriginal || menuEmpresa.tag != menu.tagOriginal || menuEmpresa.ativo != menu.ativo || menuEmpresa.descricao != menu.descricaoOriginal) {
        menuEmpresa.alterado = true;
        menuEmpresa.descricao = menu.descricaoOriginal;
        menuEmpresa.apelido = menu.nomeOriginal;
        menuEmpresa.tag = menu.tagOriginal;
        menuEmpresa.menuEmpresa = false;
        menuEmpresa.ativo = menu.ativo;
      }
    });
  }
}
