export class ConfiguracaoTransferenciaTotalContabil {
  public alterado: boolean;
  public contaContabil: string;
  public contaCredito: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public debitoCredito: number;
  public historico: string;
  public historicoContrapartida: string;
  public id: number;
  public idConfTranferenciaContabil: number;
  public idContaContabil: number;
  public idContaContabilContrapartida: number;
  public idEmpresa: number;
  public idTipoLancamentoContabil: number;
  public idTotalTransferenciaContabil: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public tipoLancamentoContabil: string;
  public totalTransferenciaContabil: string;
}
