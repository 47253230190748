import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Formulario } from '../formulario';

@Component({
  selector: 'texto',
  templateUrl: './texto.component.html',
  styleUrls: ['./texto.component.css'],
})
export class TextoComponent extends Formulario {
  @Input() minusculo: boolean = false;
  @Input() normal: boolean = false;
  @Input() texto: string;
  @Input() tipo: string;
  @Input() valorMaximo: number = 21471483647;
  public _minimoCaracter: number = 0;
  public mensagemMinimoCaracter: string = '';

  definirTipo(): string {
    switch (this.tipo) {
      case this.bibPropriedade.texto.email:
        return 'email';
      case this.bibPropriedade.texto.senha:
        return 'password';
      case this.bibPropriedade.texto.inteiro:
        return 'text';
      case this.bibPropriedade.texto.numero:
        return 'text';
      case this.bibPropriedade.texto.contabil:
        return 'text';
      default:
        return 'text';
    }
  }

  @Input()
  set minimoCaracter(minimoCaracter: any) {
    this._minimoCaracter = minimoCaracter;
    this.mensagemMinimoCaracter = `Mín. ${this._minimoCaracter} caracteres`;
  }

  alterarTexto(valor: string): void {
    if (valor != null) {
      this.campoAtual = valor.trim();
    }

    if (this.campoAtual == '') {
      this.campoAtual = null;
    }

    if (this.campoAtual != null && this.campoAtual != '') {
      if (this.valorMaximo && this.tipo == this.bibPropriedade.texto.inteiro) {
        this.campoAtual = this.campoAtual.replace('.', '');
        this.campoAtual = this.campoAtual.replace(',', '');
        this.campoAtual = this.campoAtual > this.valorMaximo ? this.valorMaximo : this.campoAtual;
      }
    }

    if (this.campoAtual != null && this.campoAtual != '') {
      if (this.valorMaximo && this.tipo == this.bibPropriedade.texto.numero) {
        this.campoAtual = this.campoAtual.replace('-', '');
        this.campoAtual = this.campoAtual.replace(',', '');
      }
    }

    let campoAtualMaisuculo: string = plainToClass(String, this.campoAtual) as string;
    if (!this.minusculo && campoAtualMaisuculo) {
      campoAtualMaisuculo = this.normal ? campoAtualMaisuculo : campoAtualMaisuculo.toUpperCase();
    }
    this.alterar(campoAtualMaisuculo ? campoAtualMaisuculo.trim() : campoAtualMaisuculo);
  }

  retirarLetras(evento: any): any {
    return (evento.charCode >= 48 && evento.charCode <= 57) || evento.charCode == 46 || evento.charCode == 44 || evento.charCode == 45;
  }

  mascaraContabil(evento: any): any {
    return evento.charCode == 48 || evento.charCode == 46;
  }
}
