import { Component, Input } from '@angular/core';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Resultado } from 'src/app/modelo/resultado';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'financeiroFrmNeg',
  styleUrls: ['./financeiroFrmNeg.component.css'],
  templateUrl: './financeiroFrmNeg.component.html',
})
export class FinanceiroFrmNegComponent extends PaginaComponent {
  @Input() financeiro: Financeiro;
  @Input() financeiros: Financeiro[];
  public listaFrequencia: any[] = [];
  public listaTipoJuros: any[] = [];
  public iniciaProcessamento: boolean = false;
  public bibDialogo = this.bibDialogo;
  public bibIcone = this.bibIcone;
  public bibPropriedade = this.bibPropriedade;
  public bibServico = this.bibServico;
  public ehDesconsideraSequenciaParcela: string = 'N';

  ngOnInit(): void {
    this.listaFrequencia = [
      { id: '1', nome: this.bibDialogo.semanal },
      { id: '2', nome: this.bibDialogo.quinzenal },
      { id: '3', nome: this.bibDialogo.mensal },
      { id: '4', nome: this.bibDialogo.bimestral },
      { id: '5', nome: this.bibDialogo.trimestral },
      { id: '6', nome: this.bibDialogo.semestral },
      { id: '7', nome: this.bibDialogo.anual },
    ];
    this.listaTipoJuros = [
      { id: 'N', nome: this.bibDialogo.nenhum },
      { id: 'S', nome: this.bibDialogo.simples },
      { id: 'C', nome: this.bibDialogo.composto },
    ];
    this.sugerirFrequencia();
  }

  sugerirFrequencia() {
    const indiceFrequenciaMensal: number = this.listaFrequencia.findIndex((frequencia) => frequencia.id == 3);
    this.financeiro.repetidaTipo = indiceFrequenciaMensal + 1;
  }

  gerarRepetirRenegociar(): void {
    if (this.ehValido()) {
      if (this.financeiros.length > 0) {
        if (confirm(this.bibDialogo.alterarNegociacao + ' ' + this.bibDialogo.desejaContinuar)) {
          this.iniciarRenegociacao();
        }
      } else {
        this.iniciarRenegociacao();
      }
    }
  }

  iniciarRenegociacao() {
    this.utilSessao.iniciarProcesso();
    setTimeout((_) => this.financeiro.recalcularNegociacao(this.financeiros), 3000);
    setTimeout((_) => this.utilSessao.finalizarProcesso(), 3000)
  }

  ehValido(): boolean {
    if (this.financeiro.valor > 0 && this.financeiro.repetidaTipo > 0 && this.financeiro.repetidaQuantidade > 0 && this.financeiro.tipoJuroRenegociacao != null) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setFinanceiroRepetir(): void {
    if (this.ehPermiteAlterarFormaNegociacao('RE')) {
      this.financeiro.negociar = 'RE';
      this.reiniciar();
    }
  }

  setValorReal(financeiroGerado: Financeiro, valorReal: number) {
    financeiroGerado.valor = valorReal;
    financeiroGerado.valorReal = valorReal;
    financeiroGerado.totalizar();
    this.financeiro.totalizaValorReal = 0;
    this.financeiro.totalizaValorFinal = 0;
    this.financeiro.totalizaValorRestante = 0;
    this.financeiros.forEach((financeiroGerado) => {
      this.financeiro.totalizaValorReal += financeiroGerado.valorReal;
      this.financeiro.totalizaValorFinal += financeiroGerado.valorFinal;
      this.financeiro.totalizaValorRestante += financeiroGerado.valorRestante;
    });
  }

  ehPermiteAlterarFormaNegociacao(formaDesejada: string): boolean {
    if (this.financeiro.negociar != formaDesejada && this.financeiros.length > 0) {
      if (confirm(this.bibDialogo.alterarNegociacao + ' ' + this.bibDialogo.desejaContinuar)) {
        this.financeiros = [];
      } else {
        return false;
      }
    }
    return true;
  }

  setFinanceiroRenegociar(): void {
    if (this.ehPermiteAlterarFormaNegociacao('RN')) {
      this.financeiro.negociar = 'RN';
    }
  }

  setFrequencia(frequencia: number): void {
    this.financeiro.repetidaTipo = frequencia;
    if (this.financeiro.repetidaTipo != 3) {
      this.financeiro.repetidaDiaFixo = 0;
    }
  }

  setTipoJuroRenegociacao(tipoJuroRenegociacao: string): void {
    this.financeiro.tipoJuroRenegociacao = tipoJuroRenegociacao;
    if (this.financeiro.tipoJuroRenegociacao == 'N') {
      this.financeiro.percentualJuroRenegociacao = 0;
    }
  }

  limparRenegociacaoRepeticao(): void {
    if (confirm(this.bibDialogo.alterarNegociacao + ' ' + this.bibDialogo.desejaContinuar)) {
      this.financeiro.negociar = 'N';
      this.financeiro.repetidaTipo = null;
      this.financeiro.repetidaQuantidade = null;
      this.financeiro.repetidaDiaFixo = 0;
      this.reiniciar();
      this.financeiros = [];
    }
  }

  reiniciar(): void {
    this.financeiro.percentualJuroRenegociacao = 0;
    this.financeiro.tipoJuroRenegociacao = null;
    this.financeiro.tipoJuroRenegociacao = 'N';
  }
}
