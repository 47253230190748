<layout [subTitulo]="categoria" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-15 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviado + ' ' + bibNomenclatura.escrever(bibNomenclatura?.movimentacao).toLowerCase() }}</th>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataNegociacao }}</th>
        <th class="l-30 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-30 clique" (click)="listar(add('colaborador'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.vendedor }}</th>
        <th class="l-5 clique" (click)="listar(add('permiteEntrega'), this.utilSessao.posicao, this.paginacao)"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr [ngClass]="{ tachado: movimentacao.ativo != 'S' }">
          <td>{{ movimentacao.abreviacao }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td>{{ movimentacao.data | data: 'dd/MM/yy' }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(movimentacao.id, i, movimentacoes)">{{ movimentacao.parceiro }}</a>
          </td>
          <td class="limitar">{{ movimentacao.colaborador }}</td>
          <td><icone [icone]="bibIcone.circulo" [cor]="movimentacao.financeiro == 'N' || movimentacao.permitirEntregaDireta == 'S' || (movimentacao.permiteEntrega && movimentacao.permitirEntregaDireta != 'S') ? bibPropriedade.icone.verde : movimentacao.negociacao?.permitirEntregaDireta == 'A' ? bibPropriedade.icone.laranja : bibPropriedade.icone.vermelho" [mensagem]="movimentacao.financeiro == 'N' || movimentacao.permitirEntregaDireta == 'S' || (movimentacao.permiteEntrega && movimentacao.permitirEntregaDireta != 'S') ? bibDialogo.aguardandoEntrega : movimentacao.negociacao?.permitirEntregaDireta == 'A' ? bibDialogo.aguardandoAprovacao : bibDialogo.aguardandoPagamento"></icone></td>
          <td class="row justify-content-end">
            <btnAdicional [id]="'entregar' + i" [icone]="bibIcone.entrega" [ajuda]="bibDialogo.realizarEntrega" (btnAdicional)="ir(movimentacao.id, i, movimentacoes)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
