<layout [titulo]="tipoTitulo.nome" [id]="tipoTitulo.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="tipoTitulo.nome" (alteracao)="tipoTitulo.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="tipoTitulo.ativo" (alteracao)="tipoTitulo.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'especie'" [rotulo]="bibDialogo.especie" [campo]="tipoTitulo.idEspecie" (alteracao)="setEspecie($event)" [lista]="especies" [obrigatorio]="true" [desabilitado]="especieDesabilitado"></lista>
    <lista *ngIf="!this.ocultarConciliacaoAutomatica" class="col-sm-3" [id]="'conciliacaoAutomatica'" [rotulo]="bibDialogo.conciliacaoAutomaticaAbreviada" [campo]="tipoTitulo?.conciliacaoAutomatica" [lista]="listaSimNao" (alteracao)="tipoTitulo.conciliacaoAutomatica = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div *ngIf="especiesCartaoCreditoCartaoDebitoPix.includes(tipoTitulo.idEspecie)" class="row">
    <lista class="col-sm-6" [id]="'conta'" [rotulo]="bibDialogo.contaEspecifica" [campo]="tipoTitulo.idConta" (alteracao)="setTipoTituloIdConta($event)" [lista]="contas" [textoVazio]="bibDialogo.naoUsarContaEspecifica.toUpperCase()" [ajuda]="bibDialogo.dicaContaEspecifica" [ajudaDireita]="true" [obrigatorio]="tipoTitulo.idEspecie == 7 || tipoTitulo.idEspecie == 9" [idMenu]="permissaoConta && permissaoConta.inserir == 'S' ? menuConta.idMenu : null"></lista>
  </div>
  <div *ngIf="tipo == 'C' || tipoTitulo.idEspecie == 2 || tipoTitulo.idEspecie == 10">
    <div class="row">
      <lista class="col-sm-6" [rotulo]="bibDialogo.regraRecebimento" [campo]="tipoTitulo.regraRecebimento" (alteracao)="setRegraRecebimento($event.id)" [lista]="regrasRecebimento" [obrigatorio]="true"></lista>
      <texto *ngIf="tipoTitulo.regraRecebimento != null && (tipoTitulo.regraRecebimento == 2 || tipoTitulo.regraRecebimento == 3)" class="col-sm-4" [rotulo]="tipoTitulo.regraRecebimento == 2 ? bibDialogo.anteciparPara : bibDialogo.diaFixo" [campo]="tipoTitulo.dia" (alteracao)="tipoTitulo.dia = $event" [tipo]="bibPropriedade.texto.inteiro" [ajuda]="tipoTitulo.regraRecebimento == 2 ? bibDialogo.vendaMaisDias : bibDialogo.ajudaDiaFixo" [valorMaximo]="31" [obrigatorio]="true" [sufixo]="tipoTitulo.regraRecebimento == 2 ? bibDialogo.diaMais : null"></texto>
    </div>
    <tipoTituloCaixaFrm [tipoTitulo]="tipoTitulo" [idExclusaoTipoTituloCaixas]="idExclusaoTipoTituloCaixas" [especieCartaoDebito]="tipoTitulo.idEspecie == 2"></tipoTituloCaixaFrm>
    <atencao *ngIf="this.tipoTitulo.tipoTituloCaixas.length == 0" [tipo]="bibPropriedade.atencao.alerta" [atencao]="bibDialogo.cartaoExigeTaxa"></atencao>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ehPersistirTipoTitulo()" [desabilitado]="bloquearPermissao(tipoTitulo.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="ehPersistirTipoTitulo(true)" [desabilitado]="bloquearPermissao(tipoTitulo.id)"></botao>
<auditoria *ngIf="tipoTitulo.id" [idUsuarioInclusao]="tipoTitulo.idUsuarioInclusao" [dataHoraInclusao]="tipoTitulo.dataHoraInclusao" [idUsuarioAlteracao]="tipoTitulo.idUsuarioAlteracao" [dataHoraAlteracao]="tipoTitulo.dataHoraAlteracao"></auditoria>
