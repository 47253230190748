<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.valorFinalFinanceiro }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.tipo }}</th>
    <th class="r-1">{{ bibDialogo.valorEsperado }}</th>
    <th class="r-1">{{ bibDialogo.valorEncontrado }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let valorFinalFinanceiro of valorFinalFinanceiros">
      <tr class="conteudo">
        <td>{{ valorFinalFinanceiro.id }}</td>
        <td>{{ valorFinalFinanceiro.dataNegociacao | data }}</td>
        <td>{{ valorFinalFinanceiro.numero }}</td>
        <td>{{ valorFinalFinanceiro.idLoja + ' - ' + valorFinalFinanceiro.loja }}</td>
        <td>{{ valorFinalFinanceiro.idEmpresa + ' - ' + valorFinalFinanceiro.empresa }}</td>
        <td>{{ valorFinalFinanceiro.tipo }}</td>
        <td>{{ valorFinalFinanceiro.valorEsperado | monetario }}</td>
        <td>{{ valorFinalFinanceiro.valorEncontrado | monetario }}</td>
        <td>{{ valorFinalFinanceiro.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
