import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { Filtros4 } from 'src/app/relatorio/r4/acessorio/filtro';
import { Filtros2023 } from '../r2023/acessorio/filtro';
import { RelatorioComponent } from '../relatorio.component';
import { Filtros } from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  selector: 'r2008',
  templateUrl: './r2008.component.html',
  styleUrls: ['./r2008.component.css'],
})
export class R2008Component extends RelatorioComponent {
  public contaContabeis: any[] = [];
  public filtros: Filtro[] = new Filtros().filtros;
  public filtros4: Filtro[] = new Filtros4().filtros;
  public filtros2023: Filtro[] = new Filtros2023().filtros;
  public grupos = grupos;
  public lancamentoContabeis: any[] = [];
  public mostrarLoja: boolean = true;
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public saldoAtual: number;
  public saldoTotalAnterior: number = 0;
  public ehRelatorio4: boolean = false;
  public ehRelatorio2008: boolean = false;
  public ehRelatorio2022: boolean = false;
  public ehRelatorio2023: boolean = false;
  public menuRoteiroAtivo: boolean = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.ativo == 'S' && menuEmpresa.idMenu == 167) != undefined;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.idRelatorio == 4) {
        this.ehRelatorio4 = true;
      }
      if (params.idRelatorio == 2008) {
        this.ehRelatorio2008 = true;
      }
      if (params.idRelatorio == 2022) {
        this.ehRelatorio2022 = true;
      }
      if (params.idRelatorio == 2023) {
        this.ehRelatorio2023 = true;
      }
    });
  }

  listarRelatorio(criterios: Criterio[]): void {
    const idsLojas: number[] = criterios.find((criterio) => criterio.nome == 'IDS_LOJA') ? criterios.find((criterio) => criterio.nome == 'IDS_LOJA').valor : [];
    this.ehAcrecentarNomeRelatorio(criterios.find((criterio) => criterio.nome == 'ID_GRUPO_CONTABIL'));
    this.mostrarLoja = idsLojas.length && idsLojas.length <= 2 ? false : true;
    this.modificarCriterioPadraoContaContabil(criterios);
    super.listar(criterios).subscribe(() => {
      this.contaContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ContaContabil').lista;
      this.lancamentoContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'LancamentoContabil').lista;
      this.ordenarAgrupar(this.lancamentoContabeis);
      this.ehImprimirRelatorio();
      this.formatarHistorico();
      if (this.ehRelatorio2023 == true) {
        this.agrupar(this.lancamentoContabeis, { rotulo: this.bibDialogo.conta, campo: 'contaContabilContraPartida', id: '0' });
        this.juntarContasContabeisLancamentos();
      }
      this.totalizarDebitoCredito();
    });
  }

  modificarCriterioPadraoContaContabil(criterios: Criterio[]): void {
    if (this.ehRelatorio4 == true) {
      let criterio: Criterio = criterios.find((criterio) => criterio.nome == 'IDS_CONTA_CONTABIL');
      if (criterio != null && criterio.valor != null) {
        this.listarContaContabil(criterio.valor).subscribe((res) => {
          const contaContabil: ContaContabil = this.plainToClass(ContaContabil, res[0]) as any;
          criterio.apresentacao = contaContabil.nome;
        });
      }
    }
  }

  listarContaContabil(idConta: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte([new Criterio('ID', idConta)]), this.bibServico.contaContabil);
  }

  ehAcrecentarNomeRelatorio(criterio: Criterio): void {
    const nomeGrupoContabil = criterio && criterio.apresentacao ? ' - ' + criterio.apresentacao : '';
    this.utilNotificacao.acrescentaNomeEmt.emit(nomeGrupoContabil);
  }

  juntarContasContabeisLancamentos(): void {
    this.lancamentoContabeis.forEach((lancamentoContabil) => {
      lancamentoContabil.saldoInicial = 0;
      if (lancamentoContabil.contaContabilContraPartida != undefined) {
        const contaContabilDesejada: any = this.contaContabeis.find((contaContabil) => contaContabil.contaContabil == lancamentoContabil.contaContabilContraPartida);
        lancamentoContabil.saldoInicial = contaContabilDesejada ? contaContabilDesejada.saldoAnterior : 0;
        lancamentoContabil.contaContabilContraPartida += ' - SALDO INICIAL: ' + this.util.moeda(lancamentoContabil.saldoInicial).replace('R$', '');;
      } else {
        lancamentoContabil.contaContabilContraPartida = 'SEM CONTRAPARTIDA - SALDO INICIAL: ' + this.util.formatarMilhar(lancamentoContabil.saldoInicial).replace('R$', '');
      }
    });
  }

  totalizarDebitoCredito(): void {
    let debito: number = 0;
    let credito: number = 0;
    let nomeContaContabil: string = '';
    let contaAtivoDespesa: boolean = false;
    if (this.lancamentoContabeis.length > 0) {
      this.calcularSaldoTotalAnterior();
      let saldo: number = this.saldoTotalAnterior;
      this.lancamentoContabeis.forEach((lancamentoContabil) => {
        contaAtivoDespesa = lancamentoContabil.numeracao.substring(0, 1) == '1' || lancamentoContabil.numeracao.substring(0, 2) == '3.2' ? true : false;
        if (this.ehRelatorio2023 && nomeContaContabil != lancamentoContabil.contaContabilContraPartida) {
          nomeContaContabil = lancamentoContabil.contaContabilContraPartida;
          saldo = lancamentoContabil.saldoInicial;
        }

        saldo += lancamentoContabil.debito ? lancamentoContabil.debito * (contaAtivoDespesa ? 1 : -1) : 0;
        saldo -= lancamentoContabil.credito ? lancamentoContabil.credito * (contaAtivoDespesa ? 1 : -1) : 0;
        lancamentoContabil.saldoAtual = saldo;
        this.saldoAtual = saldo;
        debito += lancamentoContabil.debito ? lancamentoContabil.debito * (contaAtivoDespesa ? 1 : -1) : 0;
        credito += lancamentoContabil.credito ? lancamentoContabil.credito * (contaAtivoDespesa ? 1 : -1) : 0;
      });
      this.lancamentoContabeis[0].debitoTotal = debito;
      this.lancamentoContabeis[0].creditoTotal = credito;
    }
  }

  calcularSaldoTotalAnterior(): void {
    this.saldoTotalAnterior = 0;
    if (!this.ehRelatorio2023) {
      if (this.contaContabeis.length > 0) {
        this.contaContabeis.forEach((contaContabil) => {
          this.saldoTotalAnterior += contaContabil.saldoAnterior;
        });
      }
    }
  }

  formatarHistorico(): void {
    this.lancamentoContabeis.forEach((lancamentoContabil) => {
      lancamentoContabil.historico = lancamentoContabil.historico.replace('01/01', '');
    });
  }
}
