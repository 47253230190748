import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboStatus: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.aberta, id: 'A' },
  { nome: '02 - ' + bibDialogo.aguardando, id: 'S' },
  { nome: '03 - ' + bibDialogo.cancelada, id: 'C' },
  { nome: '04 - ' + bibDialogo.concluida, id: 'E' },
  { nome: '05 - ' + bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'remetente', rotulo: bibDialogo.remetente, nome: 'ID_PARCEIRO_REMETENTE', coluna: 6, servicoWeb: bibServico.fornecedor, tipo: bibPropriedade.filtro.busca },
  { id: 'destinatario', rotulo: bibDialogo.destinatario, nome: 'ID_PARCEIRO_DESTINATARIO', coluna: 6, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
  { id: 'transportadora', rotulo: bibDialogo.transportadora, nome: 'ID_PARCEIRO_TRANSPORTADORA', coluna: 6, servicoWeb: bibServico.transportadora, tipo: bibPropriedade.filtro.busca },
  { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'SITUACAO_CTE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
]);
export default filtros;
