import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.somenteReceitaMaiorDespesa, id: 'VALOR_RESTANTE' },
  { nome: bibDialogo.todos, id: '-' },
]);

export class Filtros {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.relacionamento, nome: 'ID_RELACIONAMENTO', coluna: 6, servicoWeb: bibServico.relacionamento, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.situacao, nome: 'RECEITA_MAIOR_DESPESA', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: 'VALOR_RESTANTE', lista: filtroComboSituacao, obrigatorio: 'S' },
  ]);
}
