import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
})

export class VideoComponent implements OnInit {

  public url: any;
  public menuLink: string = 'https://www.youtube.com/embed/videoseries?si=ajjH-sqZOFPa-57I&amp;list=PLuJwpUiv6hc0ojXzPE0gIP2zTLjkco6fy';

  constructor(public domSanitizer: DomSanitizer, public utilSessao: UtilSessao) { }

  ngOnInit(): void {
    this.retornarUrlConvertida();
  }

  retornarUrlConvertida(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.utilSessao.getPermissao().menuLink); //permissao.
  }
}
