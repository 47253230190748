import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { R2030Component } from '../r2030.component';

@Component({
  selector: ' <tr>[r2030detalhes]</tr>',
  templateUrl: './r2030detalhes.component.html',
})
export class R2030DetalhesComponent extends R2030Component {
  @Input() detalhes: any;
  public utilSessao: UtilSessao = new UtilSessao();
  public bibDialogo = bibDialogo;
}
