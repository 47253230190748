import { Pipe, PipeTransform } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Pipe({
  name: 'filtrarRelatorioPersonalizado',
})
export class RelatorioPersonalizadoPipe implements PipeTransform {
  transform(relatorios: any[], argumentos?: any): any {
    if (argumentos.filtro && argumentos.filtro != '') {
      argumentos.filtro = argumentos.filtro.toLowerCase();
      switch (argumentos.especifico) {
        case bibDialogo.modulo:
          return relatorios.filter((relatorio) => relatorio.relatorioModulo.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.nome:
          return relatorios.filter((relatorio) => relatorio.nome.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.numero:
          return relatorios.filter((relatorio) => relatorio.id.toString().includes(argumentos.filtro));
      }
    } else {
      return relatorios;
    }
  }
}
