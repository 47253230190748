import { Component, Input } from '@angular/core';
import { Formulario } from '../formulario';

@Component({
  selector: 'check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css'],
})
export class CheckComponent extends Formulario {
  @Input() indeterminado: boolean = false;

  alterarMarcado(marcado: boolean): void {
    if (marcado) {
      this.campo = 'S';
    } else {
      this.campo = 'N';
    }
    super.alterar();
  }
}
