import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
  { nome: 'TODOS', id: '-' },
]);

const filtroComboEntradaSaida: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'ENTRADA', id: 'E' },
  { nome: 'SAÍDA', id: 'S' },
  { nome: 'TODOS', id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'cfop', rotulo: bibDialogo.numeroCfopAbreviacao, nome: 'ID_CFOP', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  { id: 'entradaSaida', rotulo: bibDialogo.entradaSaida, nome: 'ENTRADA_SAIDA', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboEntradaSaida },
]);
export default filtros;
