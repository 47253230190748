export class GrupoAcessoHorario {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idGrupoAcesso: number;
  public diaSemana: number = 2;
  public horaInicial: string = '00:00';
  public horaFinal: string = '00:00';
  public ativo: string = 'S';
}
