<div *ngIf="movimentacaoControlador.inicializado">
  <div *ngIf="!movimentacaoControlador.nota && !this.movimentacaoControlador.notaTerceiro" class="row">
    <titulo class="col-sm-12" *ngIf="movimentacaoControlador.movimentacaoEstilo == 1" [titulo]="movimentacaoControlador.bibDialogo.observacao" [imagem]="movimentacaoControlador.bibImagem.aspas"></titulo>
    <descricao class="col-sm-12" *ngIf="movimentacaoControlador.movimentacaoEstilo == 1" [campo]="movimentacaoControlador.movimentacao.observacao" (alteracao)="movimentacaoControlador.movimentacao.observacao = $event" [focoId]="movimentacaoControlador.focoIdObservacao" [id]="'observação'" [desabilitado]="movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao" [maximoCaracteres]="1000"></descricao>
  </div>
  <div *ngIf="movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro" class="row">
    <titulo class="col-sm-12" [titulo]="movimentacaoControlador.bibDialogo.informacaoComplementar" [imagem]="movimentacaoControlador.bibImagem.informacaoComplementar"></titulo>
    <descricao class="col-sm-12" [campo]="movimentacaoControlador.movimentacao.informacaoComplementar" (alteracao)="movimentacaoControlador.movimentacao.informacaoComplementar = $event" [focoId]="movimentacaoControlador.focoIdObservacao" [id]="'informacaoComplementar'" [desabilitado]="movimentacaoControlador.bloqueado" [maximoCaracteres]="2500"></descricao>
  </div>
  <titulo *ngIf="movimentacaoControlador.identificacao == 5" [titulo]="movimentacaoControlador.bibDialogo.estoque" [imagem]="movimentacaoControlador.bibImagem.produtoestoque"></titulo>
  <lista [id]="'estoque'" *ngIf="movimentacaoControlador.identificacao == 5" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.estoque" [campo]="movimentacaoControlador.movimentacao.estoqueEscolha" [lista]="listaEstoqueEscolha" (alteracao)="movimentacaoControlador.movimentacao.estoqueEscolha = $event.id" [obrigatorio]="true" [desabilitado]="movimentacaoControlador.bloqueado"></lista>
  <div *ngIf="this.movimentacaoControlador.notaTerceiro || (movimentacaoControlador.nota && movimentacaoControlador.estoqueNFe == 1)">
    <titulo [titulo]="movimentacaoControlador.bibDialogo.documentoFiscal" [imagem]="movimentacaoControlador.bibImagem.nfe"></titulo>
    <div *ngIf="this.movimentacaoControlador.notaTerceiro || (movimentacaoControlador.nota && movimentacaoControlador.estoqueNFe == 1)" class="row">
      <lista *ngIf="movimentacaoControlador.entradaServico == false" class="col-sm-4" [rotulo]="movimentacaoControlador.bibDialogo.emitente" [campo]="movimentacaoControlador.movimentacao.emitente" [lista]="(movimentacaoControlador.nota && movimentacaoControlador.estoqueNFe == 1) ? tipoEmissoesProprioTerceiro : tipoEmissoesTerceiro" (alteracao)="setEmitente($event.id)" [desabilitado]="movimentacaoControlador.bloqueado"></lista>
      <lista class="col-sm-5" [id]="'modelo'" *ngIf="movimentacaoControlador.movimentacao.emitente == 1 || movimentacaoControlador.entradaServico" [rotulo]="movimentacaoControlador.bibDialogo.modelo" [campo]="movimentacaoControlador.movimentacao.modelo" (alteracao)="movimentacaoControlador.movimentacao.modelo = $event.id" [lista]="movimentacaoControlador.nfeModelos" [obrigatorio]="movimentacaoControlador.movimentacao.serie || movimentacaoControlador.movimentacao.chaveFiscal || movimentacaoControlador.movimentacao.importacaoXml == 'S'" [desabilitado]="movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao"></lista>
      <texto class="col-sm-3" [id]="'serie'" *ngIf="movimentacaoControlador.movimentacao.emitente == 1 && movimentacaoControlador.entradaServico == false" [rotulo]="movimentacaoControlador.bibDialogo.serie" [campo]="movimentacaoControlador.movimentacao.serie" (alteracao)="movimentacaoControlador.movimentacao.serie = $event" [maximoCaracteres]="4" [obrigatorio]="movimentacaoControlador.movimentacao.modelo || movimentacaoControlador.movimentacao.chaveFiscal || movimentacaoControlador.movimentacao.importacaoXml == 'S'" [desabilitado]="movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao"></texto>
    </div>
    <div *ngIf="movimentacaoControlador.entradaServico == false && (this.movimentacaoControlador.notaTerceiro || (movimentacaoControlador.nota && movimentacaoControlador.estoqueNFe == 1))" class="row">
      <texto *ngIf="movimentacaoControlador.movimentacao.emitente == 1" class="col-sm-12" [id]="'chave'" [rotulo]="movimentacaoControlador.bibDialogo.chave" [campo]="movimentacaoControlador.movimentacao.chaveFiscal" (alteracao)="movimentacaoControlador.movimentacao.chaveFiscal = $event" [maximoCaracteres]="44" [exigidoCaracteres]="44" [icone]="movimentacaoControlador.bibIcone.olho" [iconeDesabilitado]="false" (btnAdicional)="consultarNfe()" [ajuda]="movimentacaoControlador.bibDialogo.portalNfeCopiarChaveFiscal" [ajudaDireita]="true" [obrigatorio]="movimentacaoControlador.movimentacao.modelo == '55' || movimentacaoControlador.movimentacao.importacaoXml == 'S'" [desabilitado]="movimentacaoControlador.bloqueado && movimentacaoControlador.bloqueiaAlteracao"></texto>
    </div>
  </div>
  <totalizador *ngIf="!movimentacaoControlador.interna && movimentacaoControlador.empresa.movimentacaoEstilo == 1" [valorTotalizador]="movimentacaoControlador.movimentacao.valorTotal | monetario" [atencaoTotalizador]="movimentacaoControlador.movimentacao.valorBloqueado > 0 ? (movimentacaoControlador.movimentacao.valorFinanceiro | monetario) : ''" [tipoAtencaoTotalizador]="movimentacaoControlador.bibPropriedade.atencao.alerta"></totalizador>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
