import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './tipoTitulo.component.html',
})
export class TipoTituloComponent extends PaginaComponent {
  public filtros: Filtro[];
  public tipoTitulos: TipoTitulo[] = [];
  public tipo: string;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.tipo = params['parametro'];
      this.selecionarFiltro();
    });
  }

  selecionarFiltro(): void {
    switch (this.tipo) {
      case 'T':
        this.filtros = filtros.tipoTitulo;
        break;
      case 'C':
        this.filtros = filtros.cartoes;
        break;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    if (this.tipo == 'C') {
      criterios.push(new Criterio('CARTOES', 'S'));
    }
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.tipoTitulo).subscribe((res) => {
      this.tipoTitulos = this.plainToClass(TipoTitulo, res) as any;
    });
  }

  excluirTipoTitulo(id: number, nome: string): void {
    super.excluir<TipoTitulo>(id, this.tipoTitulos, nome);
  }
}
