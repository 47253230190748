import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1004.component.html',
})
export class R1004Component extends RelatorioComponent {
  public estoqueHistoricos: any[] = [];
  public filtros: Filtro[] = filtros;
  public ordens = ordens;
  public usaLote: boolean = false;
  public usaLocal: boolean = false;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.estoqueHistoricos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'EstoqueHistorico').lista;
      this.ordenarAgrupar(this.estoqueHistoricos);
      this.calcularSaldo();
    });
  }

  expandir(estoqueHistorico: any) {
    estoqueHistorico.expandido = !estoqueHistorico.expandido;
    if (estoqueHistorico.expandido && (estoqueHistorico.estoqueHistoricoItens == null || estoqueHistorico.estoqueHistoricoItens.length == 0)) {
      this.listarEspecifico(new Criterio('ID_REFERENCIA', estoqueHistorico.id), 156).subscribe((res) => {
        estoqueHistorico.estoqueHistoricoItens = this.plainToClass(RelatorioResultado, res)[0].lista;
        this.calcularSaldoItem(estoqueHistorico);
      });
    }
  }

  calcularSaldo() {
    this.estoqueHistoricos.forEach((estoqueHistorico) => {
      estoqueHistorico.saldoAtual = estoqueHistorico.quantidadeAnterior + estoqueHistorico.quantidadeEntrada - estoqueHistorico.quantidadeSaida * -1;
    });
  }

  calcularSaldoItem(estoqueHistorico: any) {
    let saldoAnterior: number = estoqueHistorico.quantidadeAnterior;
    estoqueHistorico.estoqueHistoricoItens.forEach((estoqueHistoricoItem) => {
      estoqueHistoricoItem.saldoAnterior = saldoAnterior;
      estoqueHistoricoItem.saldo = estoqueHistoricoItem.saldoAnterior + estoqueHistoricoItem.quantidade;
      saldoAnterior = estoqueHistoricoItem.saldo;
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaLote = this.definirRegra(relatorioAdicional, 230, this.usaLote);
    this.usaLocal = this.definirRegra(relatorioAdicional, 231, this.usaLocal);
  }
}
