import { IntegracaoUsuario } from './integracaoUsuario';
import { RelatorioUsuario } from './relatorioUsuario';
import { UsuarioCategoriaRoteiro } from './usuarioCategoriaRoteiro';
import { UsuarioConta } from './usuarioConta';
import { UsuarioLoja } from './usuarioLoja';
import { UsuarioMenu } from './usuarioMenu';

export class Usuario {
  public agrow: string = 'N';
  public ativo: string = 'S';
  public colaboradorIdLoja: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dataHoraUltimoAcesso: Date;
  public email: string;
  public foto: string = 'N';
  public fotoUsuario: string;
  public historicoFoto: string = 'N';
  public id: number;
  public idColaborador: number;
  public idEmpresa: number;
  public idGrupoAcesso: number;
  public idGrupoUsuario: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public key: string;
  public mostrarAlerta: string = 'N';
  public nome: string;
  public observacao: string;
  public permiteMovimentacaoData: string = 'N';
  public permiteBaixaEstornoRetroativo: string = 'N';
  public permitir: string = 'S';
  public relatorioUsuarios: RelatorioUsuario[] = [];
  public senha: string;
  public token: string;
  public usaModoClaro: string = 'S';
  public usuarioCategoriaRoteiros: UsuarioCategoriaRoteiro[];
  public usuarioContas: UsuarioConta[];
  public usuarioIntegracoes: IntegracaoUsuario[] = [];
  public usuarioLojas: UsuarioLoja[];
  public usuarioMenus: UsuarioMenu[] = [];
  public vendedor: string = 'N';
  public comprador: string = 'N';
  public interno: string = 'N';
  public colaborador: string;

  public contemUmaLetraMaiuscula: boolean = false;
  public contemDuasLetrasMinuscula: boolean = false;
  public contemDoisNumeros: boolean = false;
  public contemUmCaracterEspecial: boolean = false;
  public entre8e30Caracteres: boolean = false;
}
