import { Component, Input } from '@angular/core';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';

@Component({
  selector: 'movimentacaoTotalizadorFrm',
  templateUrl: './movimentacaoTotalizadorFrm.component.html',
})
export class MovimentacaoTotalizadorFrmComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;
}
