export class NcmEmpresa {
  public cest: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idNcm: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public ncm: string;
}
