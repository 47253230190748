const bibAtalho = {
  b: 'b',
  bMaiusculo: 'B',
  cancelar: 27, // "ESC"
  ctrl: 66,
  end: 35, // "end"
  enter: 13, // "ENTER"
  espaco: 32, // "SPACE"
  fechar: 120, // "F9"
  home: 36, // "home"
  i: 'i',
  iMaiusculo: 'I',
  listar: 113, // "F2"
  mais: 107, // "+"
  menos: 109, // "-"
  menu: 77, // "M"
  novo: 45, // "INS"
  pesquisaProduto: 119, // "F8"
  q: 'q',
  qMaiusculo: 'Q',
  salvar: 121, // "F10"
  setaBaixo: 40, // "setaBaixo"
  setaCima: 38, // "setaCima"
  setaDireita: 39, // "setaDireita"
  setaEsquerda: 37, // "setaEsquerda"
  shift: 16, // "shift"
  tab: 9, // "tab"
};
export default bibAtalho;
