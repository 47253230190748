<titulo [titulo]="bibDialogo.aliquotaIcms" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.aliquota.toLocaleLowerCase()" [desabilitado]="!temPermissaoEdicao"></titulo>
<div *ngIf="lojaEstados.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.aliquota.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="lojaEstados.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l-50 clique" (click)="util.ordenarLista(this.lojaEstados, 'estado')">{{ bibDialogo.estado }}</th>
      <th class="l-15 clique" (click)="util.ordenarLista(this.lojaEstados, 'aliquotaJuridica')">{{ bibDialogo.aliquotaJuridica }}</th>
      <th class="l-15 clique" (click)="util.ordenarLista(this.lojaEstados, 'aliquotaFisica')">{{ bibDialogo.aliquotaFisica }}</th>
      <th class="l-15 clique" (click)="util.ordenarLista(this.lojaEstados, 'aliquotaInterna')">{{ bibDialogo.aliquota + ' ' + bibDialogo.interna }}<ajuda [ajuda]="bibDialogo.ajudaAliquotaInterna"></ajuda></th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let lojaEstado of lojaEstados; let i = index">
      <tr>
        <td>
          <lista *ngIf="!lojaEstado.id || edicaoLojaEstados.includes(lojaEstado.id)" [campo]="lojaEstado.idEstado" (alteracao)="setEstado(lojaEstado, $event)" [lista]="estados" [obrigatorio]="true" [focoId]="focoEstado"></lista>
          {{ lojaEstado.id && edicaoLojaEstados.includes(lojaEstado.id) == false ? lojaEstado.estado : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="!lojaEstado.id || edicaoLojaEstados.includes(lojaEstado.id)" [campo]="lojaEstado.aliquotaJuridica" (alteracao)="lojaEstado.aliquotaJuridica = $event" [sufixo]="bibDialogo.percentualSimbolo" [obrigatorio]="true"></decimal>
          {{ lojaEstado.id && edicaoLojaEstados.includes(lojaEstado.id) == false ? lojaEstado.aliquotaJuridica + ' ' + bibDialogo.percentualSimbolo : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="!lojaEstado.id || edicaoLojaEstados.includes(lojaEstado.id)" [campo]="lojaEstado.aliquotaFisica" (alteracao)="lojaEstado.aliquotaFisica = $event" [sufixo]="bibDialogo.percentualSimbolo" [obrigatorio]="true"></decimal>
          {{ lojaEstado.id && edicaoLojaEstados.includes(lojaEstado.id) == false ? lojaEstado.aliquotaFisica + ' ' + bibDialogo.percentualSimbolo : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="!lojaEstado.id || edicaoLojaEstados.includes(lojaEstado.id)" [campo]="lojaEstado.aliquotaInterna" (alteracao)="lojaEstado.aliquotaInterna = $event" [sufixo]="bibDialogo.percentualSimbolo"></decimal>
          {{ lojaEstado.id && edicaoLojaEstados.includes(lojaEstado.id) == false && lojaEstado.aliquotaInterna ? lojaEstado.aliquotaInterna + ' ' + bibDialogo.percentualSimbolo : '' }}
        </td>   
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editar(lojaEstado)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirLojaEstado(i, lojaEstado.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
