import { Component } from '@angular/core';
import { ClienteEspecial } from 'src/app/modelo/clienteEspecial';
import { Criterio } from 'src/app/modelo/criterio';
import { Fabricante } from 'src/app/modelo/fabricante';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './especialFrm.component.html',
})
export class EspecialFrmComponent extends PaginaComponent {
  public clienteEspecial: ClienteEspecial = new ClienteEspecial();
  public listaPrecificacao: any[] = [
    { id: 1, nome: this.bibNomenclatura.escrever(this.bibNomenclatura.custoReposicao) },
    { id: 2, nome: this.bibNomenclatura.escrever(this.bibNomenclatura.custoGerencial) },
    { id: 3, nome: this.bibDialogo.custoMedioNfe.toUpperCase() },
    { id: 4, nome: this.bibDialogo.precoVenda.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.clienteEspecial = this.plainToClass(Fabricante, this.ehAtualizacao(this.clienteEspecial));
    if (this.clienteEspecial.id) {
      this.listarClienteEspecial(this.clienteEspecial.id);
    }
  }

  listarClienteEspecial(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.clienteEspecial).subscribe((res) => {
      this.clienteEspecial = this.plainToClass(ClienteEspecial, res[0]) as any;
    });
  }

  persistirClienteEspecial(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.clienteEspecial), this.bibServico.clienteEspecial, novo ? new ClienteEspecial() : null);
    }
  }

  setPrecificacao(idPrecificacao: number): void {
    this.clienteEspecial.precificacao = idPrecificacao;
    this.clienteEspecial.percentual = 0;
  }

  ehValido(): boolean {
    if (this.clienteEspecial.nome && this.clienteEspecial.percentual) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
