<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-80 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-10 clique" (click)="listar(add('ordem'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.ordem }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let polegada of polegadas; let i = index">
        <tr [ngClass]="{ tachado: polegada.ativo != 'S' }">
          <td>
            <a href="#" (click)="ir(polegada.id, i, polegadas)">{{ polegada.nome }}</a>
          </td>
          <td>
            <a>{{ polegada.ordem }}</a>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(polegada.id, i, polegadas)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirPolegada(polegada.id, polegada.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
