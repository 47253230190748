import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from '../../utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Resultado } from 'src/app/modelo/resultado';
import { NfeXmlPdf } from 'src/app/modelo/nfeXmlPdf';
import { Parceiro } from 'src/app/modelo/parceiro';
import { differenceInDays } from 'date-fns';
import { UtilNfeXmlPdf } from './utilNfeXmlPdf';

@Component({
  templateUrl: './nfeXmlPdfFrm.component.html',
})
export class NfeXmlPdfFrmComponent extends PaginaComponent {
  public nfeXmlPdf: NfeXmlPdf = new NfeXmlPdf();
  public utilNfeXmlPdf: UtilNfeXmlPdf = new UtilNfeXmlPdf(this.utilSessao, this.http);
  private intervaloAtualizar: any;
  public statusNfeOpcoes: any[] = [
    { id: '1', nome: this.bibDialogo.sucesso },
    { id: '2', nome: this.bibDialogo.cancelada },
    { id: '3', nome: this.bibDialogo.todos },
  ];
  public limitaParceiroOpcoes: any[] = [
    { id: '1', nome: this.bibDialogo.nao },
    { id: '2', nome: this.bibDialogo.sim },
  ];
  public tipoOpcoes: any[] = [
    { id: '1', nome: this.bibDialogo.xml },
    { id: '2', nome: this.bibDialogo.pdf },
    { id: '3', nome: this.bibDialogo.xml + ' e ' + this.bibDialogo.pdf },
  ];
  public limitaParceiro: boolean = false;

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervaloAtualizar);
  }

  ehAlteracao(): void {
    this.nfeXmlPdf = this.plainToClass(NfeXmlPdf, this.ehAtualizacao(this.nfeXmlPdf));
    if (this.nfeXmlPdf.id) {
      this.listarNfeXmlPdf(this.nfeXmlPdf.id);
    }
  }

  listarNfeXmlPdf(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.nfeXmlPdf).subscribe((res) => {
      this.nfeXmlPdf = res[0] as NfeXmlPdf;
      if (this.nfeXmlPdf && this.nfeXmlPdf.id > 0 && this.nfeXmlPdf.situacao == 2 && this.intervaloAtualizar) {
        clearInterval(this.intervaloAtualizar);
      }
    });
  }

  setParceiro(parceiro: Parceiro): void {
    this.nfeXmlPdf.idParceiro = parceiro.id;
    this.nfeXmlPdf.parceiro = parceiro.numeroNomeParceiro;
  }

  persistirNfeXmlPdf(novo: boolean = false): void {
    if (this.limitaParceiro == false) {
      this.nfeXmlPdf.idParceiro = null;
    }
    if (this.ehValidoObrigatorio()) {
      super.persistir(new Transporte(this.nfeXmlPdf), this.bibServico.nfeXmlPdf, novo ? new NfeXmlPdf() : null).subscribe((res) => {
        if (this.nfeXmlPdf.id) {
          this.iniciarAtualizacaoAutomatica();
        }
      });
    }
  }

  ehValidoObrigatorio(): boolean {
    if (!this.nfeXmlPdf.dataInicio || !this.nfeXmlPdf.dataFim || (!this.nfeXmlPdf.idParceiro && this.limitaParceiro)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    const diferencaDias: Number = differenceInDays(new Date(this.nfeXmlPdf.dataFim), new Date(this.nfeXmlPdf.dataInicio));
    if (diferencaDias >= 31 && !this.nfeXmlPdf.idParceiro) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.periodoIntervaloMes));
      return false;
    }
    return true;
  }

  iniciarAtualizacaoAutomatica() {
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', function () {
      abaAtiva = abaAtiva == true ? false : true;
    });
    clearInterval(this.intervaloAtualizar);
    this.intervaloAtualizar = setInterval(() => {
      if (abaAtiva) {
        this.listarNfeXmlPdf(this.nfeXmlPdf.id);
      }
    }, 100000);
  }
}
