import { Type } from 'class-transformer';
import { VisitaFoto } from './visitaFoto';

export class Visita {
  public id: number;
  public idEmpresa: number;
  @Type(() => Date)
  public data: Date = new Date();
  public idParceiro: number;
  public idParceiroDestino: number;
  public idResponsavel: number;
  public descricao: string;
  public dataHoraInclusao: Date;
  public dataHoraAlteracao: Date;
  public idUsuarioInclusao: number;
  public idUsuarioAlteracao: number;

  public parceiro: string;
  public destino: string;
  public responsavel: string;
  public visitaFotos: VisitaFoto[] = [];

  @Type(() => Date)
  public dataProxima: Date;
}
