import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { addDays } from 'date-fns';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Formulario } from '../formulario';

@Component({
  selector: 'data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css'],
})
export class DataComponent extends Formulario {
  @Input() calendario: boolean = true;
  private dynamicScripts: string[] = ['assets/vendor/flatpickr/flatpickr.js', 'assets/javascript/theme.min.js'];
  public usaModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public mostraCalendario: boolean = false;
  public bibDialogo = bibDialogo;

  constructor(public activatedRoute: ActivatedRoute) {
    super();
    this.tipoCampo = 2;
  }

  ngOnInit(): void {
    setTimeout((_) => this.carregarScripts(), 1000);
  }

  ngOnDestroy(): void {
    if (this.calendario) {
      this.limparScripts();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (this.focado && this.campoAtual == null) {
      const dataAtual = new Date();
      switch (event.key) {
        case '-':
          event.preventDefault();
          const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
          this.campoAtual = primeiroDiaDoMes;
          this.emitirAlteracao(this.campoAtual);
          break;
        case ' ':
          event.preventDefault();
          this.campoAtual = new Date();
          this.emitirAlteracao(this.campoAtual);
          break;
        case '+':
          event.preventDefault();
          const ultimoDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
          this.campoAtual = ultimoDiaDoMes;
          this.emitirAlteracao(this.campoAtual);
          break;
      }
    }
  }

  carregarScripts(): void {
    this.limparScripts();
    for (var i = 0; i < this.dynamicScripts.length; i++) {
      let elemento: any = document.createElement('script');
      elemento.src = this.dynamicScripts[i];
      elemento.classList.add('scriptsData');
      document.getElementsByTagName('body')[0].appendChild(elemento);
    }
    this.mostraCalendario = true;
  }

  limparScripts(): void {
    document.querySelectorAll('.scriptsData').forEach((evento) => {
      evento.remove();
    });
    document.querySelectorAll('.flatpickr-calendar').forEach((evento) => {
      evento.remove();
    });
  }

  alterarData(data: string): void {
    if (data.length == 10) {
      if (data.indexOf('/') > 0) {
        let dia = data.substring(0, 2);
        let mes = data.substring(3, 5);
        let ano = data.substring(6, 10);
        data = ano + '-' + mes + '-' + dia;
      }
      const dataSelecionada = addDays(new Date(Date.parse(data)), 1);
      if (dataSelecionada.toString() == 'Invalid Date') {
        this.campoAtual = '';
      } else {
        this.campoAtual = dataSelecionada;
      }
      this.emitirAlteracao(this.campoAtual);
    } else {
      if (data.length == 0) {
        this.limparData();
      }
    }
  }

  limparData(): void {
    this.campoAtual = null;
    this.emitirAlteracao(this.campoAtual);
  }
}
