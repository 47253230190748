<layout [titulo]="unidade.nome" [id]="unidade.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="unidade.nome" (alteracao)="unidade.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="unidade.ativo" (alteracao)="unidade.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm-4" [id]="'abreviacao'" [rotulo]="bibDialogo.abreviacao" [campo]="unidade.abreviacao" (alteracao)="unidade.abreviacao = $event" [obrigatorio]="true" [ajuda]="bibDialogo.minimoDoisMaximoCincoCaracteres" [maximoCaracteres]="5"></texto>
    <!-- <decimal class="col-sm-4" [rotulo]="bibDialogo.fatorMaximoSugerido" [campo]="unidade.fatorMaximoSugerido" (alteracao)="unidade.fatorMaximoSugerido = $event"></decimal> -->
  </div>
  <div *ngIf="integracaoEidaf || integracaoAgroDefesa" class="row">
    <texto class="col-sm-4" [id]="'integracao'" [rotulo]="bibDialogo.integracao" [campo]="unidade.integracao" (alteracao)="unidade.integracao = $event" [normal]="true" [ajuda]="bibDialogo.ajudaIntegracao" [maximoCaracteres]="20" [ajudaDireita]="true"></texto>
  </div>  
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirUnidade()" [desabilitado]="bloquearPermissao(unidade.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirUnidade(true)" [desabilitado]="bloquearPermissao(unidade.id)"></botao>
<auditoria *ngIf="unidade.id" [idUsuarioInclusao]="unidade.idUsuarioInclusao" [dataHoraInclusao]="unidade.dataHoraInclusao" [idUsuarioAlteracao]="unidade.idUsuarioAlteracao" [dataHoraAlteracao]="unidade.dataHoraAlteracao"></auditoria>
