<titulo [titulo]="menuNegociacao.apelido + ' ' + bibDialogo.restrita.toLowerCase()" [imagem]="bibImagem.credito" [icone]="bibIcone.mais" (iconeEmt)="abrirPesquisa()" [ajuda]="bibDialogo.ajudaNegociacaoRestrita" [desabilitado]="bloquearPermissao(parceiro.id)"> </titulo>
<div class="row" *ngIf="parceiro.parceiroNegociacoes.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + menuNegociacao.apelido.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirPesquisa()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="parceiro.parceiroNegociacoes.length > 0">
  <thead>
    <tr>
      <th class="l-95">{{ bibDialogo.nome }}</th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parceiroNegociacao of parceiro.parceiroNegociacoes; let i = index">
      <td>{{ parceiroNegociacao.nome }}</td>
      <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirNegociacaoRestrita(i, parceiroNegociacao.id, parceiroNegociacao.idNegociacao)"></btnAdicional></td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
