<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(movimentacaoProdutoAgrupados, $event)" (campoGrupoEmt)="agrupar(movimentacaoProdutoAgrupados, $event)" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r">{{ bibDialogo.cfop }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th class="r-1 text-right">{{ bibDialogo.baseIcms }}</th>
      <th class="r-1 text-right">{{ bibDialogo.icms }}</th>
      <th class="r-1 text-right">{{ bibDialogo.isentas }}</th>
      <th class="r-1 text-right">{{ bibDialogo.outras }}</th>
      <th class="r-1 text-right">{{ bibDialogo.icmsSt }}</th>
      <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>
      <th class="r-1 text-right">{{ bibDialogo.despesasAcessorias }}</th>
      <th class="r-1 text-right">{{ bibDialogo.basePisCofins }}</th>
      <th class="r-1 text-right">{{ bibDialogo.pis }}</th>
      <th class="r-1 text-right">{{ bibDialogo.cofins }}</th>
      <th class="r-1 text-right">{{ bibDialogo.ipi }}</th>
      <th *ngIf="movimentacaoProdutoAgrupados.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProdutoAgrupado of movimentacaoProdutoAgrupados; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ movimentacaoProdutoAgrupado.id + ' ' }}<ajuda [ajudaDireita]="true" [ajuda]="movimentacaoProdutoAgrupado.cfop" [posicao]="bibDialogo.esquerda"></ajuda></td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.valor * -1 | monetario) : (movimentacaoProdutoAgrupado.valor | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.baseIcms * -1 | monetario) : (movimentacaoProdutoAgrupado.baseIcms | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.valorIcms * -1 | monetario) : (movimentacaoProdutoAgrupado.valorIcms | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.isentas * -1 | monetario) : (movimentacaoProdutoAgrupado.isentas | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.outras * -1 | monetario) : (movimentacaoProdutoAgrupado.outras | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.icmsStValor * -1 | monetario) : (movimentacaoProdutoAgrupado.icmsStValor | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.descontoValor * -1 | monetario) : (movimentacaoProdutoAgrupado.descontoValor | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.despesaAcessoria * -1 | monetario) : (movimentacaoProdutoAgrupado.despesaAcessoria | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.basePis * -1 | monetario) : (movimentacaoProdutoAgrupado.basePis | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.valorPis * -1 | monetario) : (movimentacaoProdutoAgrupado.valorPis | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.valorCofins * -1 | monetario) : (movimentacaoProdutoAgrupado.valorCofins | monetario) }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupado.id > 5000 ? (movimentacaoProdutoAgrupado.valorIpi * -1 | monetario) : (movimentacaoProdutoAgrupado.valorIpi | monetario) }}</td>
          <td class="naoImprimir" *ngIf="movimentacaoProdutoAgrupados.length > 0"><btnAdicional [icone]="movimentacaoProdutoAgrupado.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacaoProdutoAgrupado)"></btnAdicional></td>
        </tr>
        <tr *ngIf="movimentacaoProdutoAgrupado.expandido" r1012itens [movimentacaoProdutos]="movimentacaoProdutoAgrupado.movimentacaoProdutos" class="sem-borda"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacaoProdutoAgrupados.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
  <atencao class="naoImprimir" [atencao]="bibDialogo.alertaArredondamento" [tipo]="bibPropriedade.atencao.alerta"></atencao>

  <table class="tabela-totalizador" *ngIf="mostrarTotalizador">
    <ng-container *ngFor="let totalizadorImposto of totalizadorImpostos; let i = index">
      <div class="row pt-2">
        <th class="r-1-5 text-right"></th>
        <th class="r-1-5 text-right">{{ bibDialogo.icms }} </th>
        <th class="r-1-5 text-right">{{ bibDialogo.pis }} </th>
        <th class="r-1-5 text-right">{{ bibDialogo.cofins }} </th>
      </div>
      <div class="row">
        <th class="r-1-5 text-right margin-left: 10px">{{ bibDialogo.saldoAnterior }} </th>
        <th class="r-1-5 text-right">{{ totalizadorImposto.valorIcmsSaldoAnterior | monetario }}</th>
        <th class="r-1-5 text-right">{{ totalizadorImposto.valorPisSaldoAnterior | monetario }}</th>
        <th class="r-1-5 text-right">{{ totalizadorImposto.valorCofinsSaldoAnterior | monetario }}</th>
      </div>
      <div class="row">
        <th class="r-1-5 text-right margin-left: 10px">{{ bibDialogo.apuradoMes }} </th>
        <th class="r-1-5 text-right"> {{ totalizadorImposto.valorIcmsPeriodo | monetario }}</th>
        <th class="r-1-5 text-right">{{ totalizadorImposto.valorPisPeriodo | monetario }}</th>
        <th class="r-1-5 text-right">{{ totalizadorImposto.valorCofinsPeriodo | monetario }}</th>
      </div>
      <div class="row">
        <th class="r-1-5 text-right margin-left: 10px">{{ bibDialogo.totalPagar }} </th>
        <th class="r-1-5 text-right"> {{ totalAPagarICMS | monetario }} </th>
        <th class="r-1-5 text-right">{{ totalAPagarPIS | monetario }}</th>
        <th class="r-1-5 text-right">{{ totalAPagarCofins | monetario }}</th>
      </div>
      <div class="row">
        <th class="r-1-5 text-right margin-left: 10px">{{ bibDialogo.saldoAtual }} </th>
        <th class="r-1-5 text-right"> {{ saldoICMS | monetario }} </th>
        <th class="r-1-5 text-right">{{ saldoPIS | monetario }}</th>
        <th class="r-1-5 text-right">{{ saldoCofins | monetario }}</th>
      </div>
    </ng-container>
  </table>

  <table class="mt-4" *ngIf="mostrarNaturezaOperacao">
    <thead>
      <th class="r">{{ bibDialogo.natureza }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th class="r-1 text-right">{{ bibDialogo.baseIcms }}</th>
      <th class="r-1 text-right">{{ bibDialogo.icms }}</th>
      <th class="r-1 text-right">{{ bibDialogo.isentas }}</th>
      <th class="r-1 text-right">{{ bibDialogo.outras }}</th>
      <th class="r-1 text-right">{{ bibDialogo.icmsSt }}</th>
      <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>
      <th class="r-1 text-right">{{ bibDialogo.despesasAcessorias }}</th>
      <th class="r-1 text-right">{{ bibDialogo.basePisCofins }}</th>
      <th class="r-1 text-right">{{ bibDialogo.pis }}</th>
      <th class="r-1 text-right">{{ bibDialogo.cofins }}</th>
      <th class="r-1 text-right">{{ bibDialogo.ipi }}</th>
      <th class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProdutoAgrupadoNaturezaOperacao of movimentacaoProdutoAgrupadoNaturezaOperacoes; let i = index">
        <tr class="conteudo">
          <td>{{ movimentacaoProdutoAgrupadoNaturezaOperacao.naturezaOperacao.substr(0,5) }}<ajuda class="ml-2"  [ajudaDireita]="true"  [ajuda]="movimentacaoProdutoAgrupadoNaturezaOperacao.naturezaOperacao"></ajuda></td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.valor | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.baseIcms | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.valorIcms | monetario }}</td>                    
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.isentas | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.outras | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.icmsStValor | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.descontoValor | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.despesaAcessoria | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.basePis | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.valorPis | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.valorCofins | monetario }}</td>
          <td class="text-right">{{ movimentacaoProdutoAgrupadoNaturezaOperacao.valorIpi | monetario }}</td>
          <td></td>
          <!-- <td><ajuda [ajuda]="movimentacaoProdutoAgrupadoNaturezaOperacao.naturezaOperacao" [posicao]="bibDialogo.esquerda"></ajuda></td> -->
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacaoProdutoAgrupadoNaturezaOperacoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>    

</relatorioLayout>
