import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001FinanceiroReceitaDespesa',
  templateUrl: './r2001FinanceiroReceitaDespesa.component.html',
})
export class R2001FinanceiroReceitaDespesaComponent {
  @Input() financeiroReceitaDespesas: any[];
  public bibDialogo = bibDialogo;
}
