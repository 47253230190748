import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Cultura } from 'src/app/agrow/modelo/cultura';

@Component({
  templateUrl: './culturaFrm.component.html',
})
export class CulturaFrmComponent extends PaginaComponent {
  public cultura: Cultura = new Cultura();
  public integracaoEidaf: boolean = false;
  public integracaoAgroDefesa: boolean = false;
  public culturas: [] = [];

  ngOnInit(): void {
    this.integracaoEidaf = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
    this.integracaoAgroDefesa = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO').length > 0 ? true : false;
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.cultura = this.plainToClass(Cultura, this.ehAtualizacao(this.cultura));
    if (this.cultura.id) {
      this.listarCultura(this.cultura.id);
    }
  }

  listarCultura(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.cultura).subscribe((res) => {
      this.cultura.nome = this.plainToClass(Cultura, res[0]).nome;
      this.cultura.eidaf = this.plainToClass(Cultura, res[0]).eidaf;
      this.cultura.agroDefesa = this.plainToClass(Cultura, res[0]).agroDefesa;
    });
  }

  persistirCultura(novo: boolean = false): void {
    if (this.ehValidoObrigatorio()) {
      super.persistir(new Transporte(this.cultura), this.bibServico.cultura, novo ? new Cultura() : null);
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.cultura.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setNome(cultura: any) {
    this.cultura.nome = cultura.nome;
  }
}
