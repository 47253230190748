<div class="row align-items-center">
  <titulo class="col-sm" [titulo]="parceiro.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art" [imagem]="bibImagem.agronomo" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [desabilitado]="temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
</div>
<div class="row pl-3" *ngIf="parceiroArts.length == 0">
  <p>
    {{ bibDialogo.nenhumaAbr + ' ' + (parceiro.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art) + ', ' }} <strong class="clique" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="parceiroArts.length > 0">
  <thead>
    <tr>
      <th class="l-30">{{ parceiro.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art }}</th>
      <th class="l-15">{{ bibDialogo.estado }}</th>
      <th class="l-15">{{ bibDialogo.receitaDe }}</th>
      <th class="l-15">{{ bibDialogo.receitaAte }}</th>
      <th class="l-15">{{ bibDialogo.receita + ' ' + bibDialogo.atual.toLowerCase() }}</th>
      <th class="l-15">{{ bibDialogo.disponivel }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parceiroArt of parceiroArts; let i = index">
      <td class="limitar">{{ parceiroArt.art }}</td>
      <td>{{ parceiroArt.estado }}</td>
      <td>{{ parceiroArt.de }}</td>
      <td>{{ parceiroArt.ate }}</td>
      <td>{{ parceiroArt.atual }}</td>
      <td>{{ parceiroArt.atual ? parceiroArt.ate - parceiroArt.atual : parceiroArt.ate - parceiroArt.de }}</td>
      <td>
        <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(parceiroArt)"></btnAdicional>
      </td>
      <td>
        <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this.parceiroArts, this.excluirArts, parceiroArt.id)"></btnAdicional>
      </td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
