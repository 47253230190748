import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2000.component.html',
})
export class R2000Component extends RelatorioComponent {
  public filtros = filtros;
  public movimentacoes: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.ehImprimirRelatorio();
    });
  }

  expandir(movimentacao: any) {
    movimentacao.expandido = !movimentacao.expandido;
    if (movimentacao.expandido && (movimentacao.movimentacaoProdutos == null || movimentacao.movimentacaoProdutos.length == 0) ) {
      this.listarEspecifico(new Criterio("ID_MOVIMENTACAO", movimentacao.id), 18).subscribe((res) => {
        movimentacao.movimentacaoProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });      
      this.listarEspecifico(new Criterio("ID_MOVIMENTACAO", movimentacao.id), 17).subscribe((res) => {
        movimentacao.entregaProdutos = this.plainToClass(RelatorioResultado, res)[0].lista;
      });      
    }
  }

}
