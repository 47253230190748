<div class="row">
  <titulo class="col-sm" [titulo]="bibDialogo.pesquisaRapida" [imagem]="bibImagem.pesquisa"></titulo>
</div>
<div class="row form-group naoImprimir">
  <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="listaCampoPesquisa" (alteracao)="setCampoPesquisa($event.id)" [obrigatorio]="true"></lista>
  <texto class="col-sm" [desabilitado]="campoPesquisa == bibDialogo.semAcesso || campoPesquisa == bibDialogo.comAcesso ? true : false" [campo]="filtro" (alteracao)="filtro = $event" [descricao]="bibDialogo.digiteNomeTela"></texto>
</div>
<div class="responsiva">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-25 clique" (click)="util.ordenarLista(usuarioMenus, 'menu')">{{ bibDialogo.menu }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioMenus, 'acessar')">{{ bibDialogo.acessar }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioMenus, 'inserir')">{{ bibDialogo.inserir }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioMenus, 'editar')">{{ bibDialogo.editar }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioMenus, 'excluir')">{{ bibDialogo.excluir }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioMenus, 'inativar')">{{ bibDialogo.inativar }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioMenus, 'favorito')">{{ bibDialogo.favorito }}</th>
        <th class="l-10"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
  </table>
  <ng-container class="responsiva" *ngFor="let usuarioMenu of usuarioMenus | filtrarUsuarioMenu: { filtro: filtro, especifico: campoPesquisa }; let i = index">
    <div class="col-sm-12" *ngIf="i == 0 ? true : usuarioMenus[i].modulo != usuarioMenus[i - 1].modulo">
      <div class="mt-3 row align-items-center justify-content-between">
        <p>
          <strong>{{ usuarioMenu.modulo.toUpperCase() }} </strong>
        </p>
        <p class="clique" (click)="this.permitirAcessoModulo(usuarioMenus[i].modulo)">{{ bibDialogo.concederPermissaoCompleta + ' ' + usuarioMenu.modulo.toLowerCase() }}</p>
      </div>
    </div>
    <table class="table table-responsive-sm mb-0">
      <tbody>
        <tr>
          <td class="l-25 limitar"><span *ngIf="usuarioMenu.idMenu == 80" class="menu-icon oi oi-dashboard"></span> {{ usuarioMenu.menu }} <span *ngIf="usuarioMenu.novo == 'S'" class="badge badge-warning">Novo</span></td>
          <td class="l-10" class="text-center">
            <icone *ngIf="usuarioMenu.menuAcessar == 'S'" [icone]="usuarioMenu.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="setUsuarioMenuAcessar(usuarioMenu)" [cor]="usuarioMenu.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="usuarioMenu.acessar == 'S' ? bibDialogo.removerAcesso : bibDialogo.acessar"></icone>
          </td>
          <td class="l-10" class="text-center">
            <icone *ngIf="usuarioMenu.menuInserir == 'S'" [icone]="usuarioMenu.inserir == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="setUsuarioMenuInserir(usuarioMenu)" [cor]="usuarioMenu.inserir == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="usuarioMenu.inserir == 'S' ? bibDialogo.removerInsercao : bibDialogo.inserir"></icone>
          </td>
          <td class="l-10" class="text-center">
            <icone *ngIf="usuarioMenu.menuEditar == 'S'" [icone]="usuarioMenu.editar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="setUsuarioMenuEditar(usuarioMenu)" [cor]="usuarioMenu.editar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="usuarioMenu.editar == 'S' ? bibDialogo.removerEdicao : bibDialogo.editar"></icone>
          </td>
          <td class="l-10" class="text-center">
            <icone *ngIf="usuarioMenu.menuExcluir == 'S'" [icone]="usuarioMenu.excluir == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="setUsuarioMenuExcluir(usuarioMenu)" [cor]="usuarioMenu.excluir == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="usuarioMenu.excluir == 'S' ? bibDialogo.removerExclusao : bibDialogo.excluir"></icone>
          </td>
          <td class="l-10" class="text-center">
            <icone *ngIf="usuarioMenu.menuInativar == 'S'" [icone]="usuarioMenu.inativar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="setUsuarioMenuInativar(usuarioMenu)" [cor]="usuarioMenu.inativar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="usuarioMenu.inativar == 'S' ? bibDialogo.removerInativar : bibDialogo.inativar"></icone>
          </td>
          <td class="l-10" class="text-center">
            <icone *ngIf="usuarioMenu.acessar == 'S'" [icone]="bibIcone.estrela" [clique]="true" (iconeEmt)="setUsuarioMenuFavorito(usuarioMenu)" [cor]="usuarioMenu.favorito == 'S' ? bibPropriedade.icone.amarelo : bibPropriedade.icone.cinza" [mensagem]="usuarioMenu.favorito == 'S' ? bibDialogo.removerFavorito : bibDialogo.favoritar"></icone>
          </td>
          <td class="l-5" *ngIf="usuarioMenu.idMenu != 101">
            <btnAdicional [icone]="bibIcone.cadeadoAberto" [iconeCor]="bibPropriedade.icone.preto" (btnAdicional)="alterarTodos(usuarioMenu, 'S')" [ajuda]="bibDialogo.permitir"></btnAdicional>
          </td>
          <td class="l-5" *ngIf="usuarioMenu.idMenu != 101">
            <btnAdicional [icone]="bibIcone.cadeadoFechado" [iconeCor]="bibPropriedade.icone.cinza" (btnAdicional)="alterarTodos(usuarioMenu, 'N')" [ajuda]="bibDialogo.bloquear"></btnAdicional>
          </td>
          <td class="l-5">
            <btnAdicional *ngIf="usuarioMenu.descricao && usuarioMenu.descricao.length > 0" [icone]="usuarioMenu.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="usuarioMenu.expandido = !usuarioMenu.expandido"></btnAdicional>
          </td>
        </tr>
        <tr *ngIf="usuarioMenu.expandido">
          <td colspan="9"><apresentacao class="col-sm-12" [rotulo]="bibDialogo.descricao" [campo]="usuarioMenu.descricao"></apresentacao></td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
