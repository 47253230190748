export class EmpresaOperacaoContabil {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idConfigMovContabil: number;
  public idEmpresa: number;
  public idOperacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public operacao: string;
  public configuracaoContabil: string;
}
