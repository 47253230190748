<layout [titulo]="bibDialogo.cte" [id]="cte.id" (voltarAvancarEmt)="ehAlteracao()" [utilitarios]="utilitarios" (utilitarioEmt)="irCte()">
  <div class="row">
    <texto class="col-sm-3" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="cte.numero" (alteracao)="cte.numero = $event" [desabilitado]="true"></texto>
    <data class="col-sm-4" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="cte.data" (alteracao)="cte.data = $event" [obrigatorio]="true" [desabilitado]="bloqueado"></data>
  </div>
  <div *ngIf="visualizaLoja" class="row">
    <lista class="col-sm-12" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="cte.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="setLoja($event)" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()" [ajudaDireita]="true" [ajuda]="bibDialogo.lojaCadastro" [desabilitado]="bloqueado" [foco]="true"></lista>
  </div>
  
  <div class="row">
    <busca class="col-sm-6" [id]="'remetente'" [rotulo]="bibDialogo.remetente" [campo]="cte.parceiroRemetente" (alteracao)="setParceiroRemetente($event)" [desabilitado]="bloqueado" [servico]="bibServico.fornecedor" [obrigatorio]="true" [atencao]="'3'"></busca>
    <busca class="col-sm-6" [id]="'destinatario'" [rotulo]="bibDialogo.destinatario" [campo]="cte.parceiroDestinatario" (alteracao)="setParceiroDestinatario($event)" [desabilitado]="bloqueado" [servico]="bibServico.cliente" [obrigatorio]="true" [atencao]="'3'"></busca>
  </div>
  <div class="row">
    <busca class="col-sm-6" [id]="'cidadeInicial'" [rotulo]="bibDialogo.cidade + ' ' + bibDialogo.inicial.toLowerCase()" [campo]="cte.cidadeInicial" (alteracao)="setCidadeInicial($event)" [desabilitado]="bloqueado" [servico]="bibServico.cidade" [obrigatorio]="true" [atencao]="'3'"></busca>
    <busca class="col-sm-6" [id]="'cidadeFinal'" [rotulo]="bibDialogo.cidade + ' ' + bibDialogo.final.toLowerCase()" [campo]="cte.cidadeFinal" (alteracao)="setCidadeFinal($event)" [desabilitado]="bloqueado" [servico]="bibServico.cidade" [obrigatorio]="true" [atencao]="'3'"></busca>    
  </div>
  <div class="row">
    <!-- <busca class="col-sm-12" [id]="'cfop'" [rotulo]="bibDialogo.cfop" [campo]="cte.cfop" (alteracao)="setCfop($event)" [desabilitado]="bloqueado" [servico]="bibServico.cfopEmpresa" [obrigatorio]="true" [atencao]="'3'"></busca> -->
    <lista class="col-sm-12" [id]="'cfop'" [rotulo]="bibDialogo.cfop" [campo]="cte.idCfopEmpresa" (alteracao)="cte.idCfopEmpresa = $event.id" [lista]="cfopsCte" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCfopEmpresaCte()" [obrigatorio]="true" [desabilitado]="bloqueado"></lista>
  </div>  
  <cteDocumentoFiscal [cte]="cte" [excluirCteDocumentoFiscais]="excluirCteDocumentoFiscais" [edicaoCteDocumentoFiscais]="edicaoCteDocumentoFiscais" [bloqueado]="bloqueado"></cteDocumentoFiscal>
  <cteInformacaoCarga [cte]="cte" [excluirCteInformacaoCargas]="excluirCteInformacaoCargas" [edicaoCteInformacaoCargas]="edicaoCteInformacaoCargas" [bloqueado]="bloqueado"></cteInformacaoCarga>
  <div class="row">
    <titulo class="col-sm-12" [titulo]="bibDialogo.transporte" [imagem]="bibImagem.transporte"></titulo>
    <lista class="col-sm-6" [id]="'tipoFrete'" [rotulo]="bibDialogo.freteTipo" [campo]="cte.tipoFrete" [lista]="listaTipoFrete" (alteracao)="cte.tipoFrete = $event.id" [desabilitado]="bloqueado" [obrigatorio]="true"></lista>
    <busca class="col-sm-12" [id]="'transportadora'" [idMenu]="menuTransportadora.idMenu" [rotulo]="menuTransportadora.apelido" [campo]="cte.parceiroTransportadora" (alteracao)="setTransportadora($event)" [desabilitado]="bloqueado" [servico]="bibServico.transportadora" [obrigatorio]="true" [atencao]="'3'"></busca>
    <lista class="col-sm-3" [rotulo]="bibDialogo.veiculo" [campo]="cte.idVeiculo" (alteracao)="setVeiculo($event)" [lista]="parceiroVeiculos" [desabilitado]="bloqueado" [obrigatorio]="true"> </lista>
    <apresentacao class="col-sm-3" [rotulo]="bibDialogo.uf + ' ' + bibDialogo.placa.toLowerCase()" [campo]="cte.placaUf"></apresentacao>
    <apresentacao class="col-sm-3" [rotulo]="bibDialogo.placa" [campo]="cte.placa"></apresentacao>
    <texto class="col-sm-3" [rotulo]="bibDialogo.rntrc" [campo]="cte.rntrc" (alteracao)="cte.rntrc = $event" [obrigatorio]="true" [desabilitado]="bloqueado"></texto>
  </div>
  <titulo class="col-sm-12" [titulo]="bibDialogo.total" [imagem]="bibImagem.acoes"></titulo>
  <decimal class="col-sm-3" [id]="'valor'" [rotulo]="bibDialogo.valor" [campo]="cte.valor" (alteracao)="cte.valor = $event" [obrigatorio]="true" [desabilitado]="bloqueado"></decimal>
  <div class="row">
    <titulo class="col-sm-12" [titulo]="bibDialogo.impostos" [imagem]="bibImagem.dinheiro"></titulo>
    <lista class="col-sm-12" [id]="'cstIcms'" [rotulo]="bibDialogo.cst + ' ' + bibDialogo.icms" [campo]="cte.idCstIcms" (alteracao)="setCstIcms($event)" [lista]="icmsCsts" [obrigatorio]="true" [desabilitado]="bloqueado"></lista>   
    <decimal class="col-sm-4" [id]="'icmsBase'" [rotulo]="bibDialogo.base + ' ' + bibDialogo.icms" [campo]="cte.icmsBase" (alteracao)="setIcmsBase(cte, $event)" [obrigatorio]="true" [desabilitado]="bloqueado"></decimal>
    <decimal class="col-sm-4" [id]="'icmsAliquota'" [rotulo]="bibDialogo.aliquota + ' ' + bibDialogo.icms" [campo]="cte.icmsAliquota" (alteracao)="setIcmsAliquota(cte, $event)" [obrigatorio]="true" [desabilitado]="bloqueado"></decimal>
    <decimal class="col-sm-4" [id]="'icmsValor'" [rotulo]="bibDialogo.valor + ' ' + bibDialogo.icms" [campo]="cte.icmsValor" (alteracao)="cte.icmsValor = $event" [obrigatorio]="true" [desabilitado]="bloqueado"></decimal>
  </div> 
  <titulo class="col-sm-12" [titulo]="bibDialogo.observacao" [imagem]="bibImagem.aspas"></titulo>
  <descricao class="col-sm-12" [id]="'observação'" [campo]="cte.informacaoComplementar" (alteracao)="cte.informacaoComplementar = $event" [desabilitado]="bloqueado" [maximoCaracteres]="5000"></descricao> 
</layout>
<div>
  <botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCte()" [desabilitado]="bloqueado"></botao>
  <botao [compacto]="compacto" [legenda]="bibDialogo.finalizar" [fundo]="true" (botaoEmt)="finalizar()" [desabilitado]="bloqueado"></botao>
  <atencao *ngIf="cte.status == 'S'" class="col-sm d-flex align-items-center justify-content-end" [atencao]="bibDialogo.finalizado" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
</div>
<auditoria *ngIf="cte.id" [idUsuarioInclusao]="cte.idUsuarioInclusao" [dataHoraInclusao]="cte.dataHoraInclusao" [idUsuarioAlteracao]="cte.idUsuarioAlteracao" [dataHoraAlteracao]="cte.dataHoraAlteracao"></auditoria>
<bloqueado [bloqueado]="cte.status == 'S' || cte.ativo != 'S'"></bloqueado>