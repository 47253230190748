import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r1009itens]</tr>',
  templateUrl: './r1009itens.component.html',
})
export class R1009ItensComponent {
  @Input() conciliacaoAgrupado: any;
  @Input() maisInformacoes: boolean;
  @Input() usaTaxaCartao: boolean;
  public bibDialogo = bibDialogo;
}
