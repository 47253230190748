import { Component } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { DesfazimentoProduto } from 'src/app/modelo/desfazimentoProduto';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtro from './filtro';

@Component({
  templateUrl: './desfazimentoEstorno.component.html',
})
export class DesfazimentoEstornoComponent extends PaginaComponent {
  public desfazimentoProdutos: DesfazimentoProduto[] = [];
  public filtros: Filtro[] = filtro;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.desfazimentoProduto).subscribe((res) => {
      this.desfazimentoProdutos = this.plainToClass(DesfazimentoProduto, res) as any;
    });
  }

  estornar(): void {
    if (confirm(bibDialogo.certezaEstornarProduto)) {
      let idsExcluir: number[] = [];
      this.desfazimentoProdutos.forEach((desfazimentoProduto) => {
        desfazimentoProduto.selecionado == 'S' ? idsExcluir.push(desfazimentoProduto.id) : null;
      });
      this.excluirLista(idsExcluir, this.bibServico.desfazimentoProduto).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.desfazimentoProdutos = this.desfazimentoProdutos.filter((desfazimentoProduto) => desfazimentoProduto.selecionado != 'S');
        }
      });
    }
  }
}
