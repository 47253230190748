<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="3">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-0-5">{{ bibDialogo.id }}</th>
          <th class="r">{{ bibDialogo.contaDebito }}</th>
          <th class="r-0-5">{{ bibDialogo.id }}</th>
          <th class="r-5">{{ bibDialogo.contaCredito }}</th>
          <th class="r-1 text-right">{{ bibDialogo.percentual }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let roteiroContabil of roteiroContabeis">
          <tr>
            <td>{{ roteiroContabil.contaContabilId }}</td>
            <td>{{ roteiroContabil.numeracao + ' - ' + roteiroContabil.contaContabil }}</td>
            <td>{{ roteiroContabil.contaContabilContrapartidaId }}</td>
            <td>{{ roteiroContabil.numeracaoContrapartida + ' - ' + roteiroContabil.contaContabilContrapartida }}</td>
            <td class="text-right">{{ roteiroContabil.percentual + '%' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
