export class VisitaFoto {
  public arquivoFile: any;
  public arquivoRetorno: any;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public excluido: string;
  public id: number;
  public idEmpresa: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public pendente: string;
}
