import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2015.component.html',
})
export class R2015Component extends RelatorioComponent {
  public conciliacaoFinanceiroBaixas: any[] = [];
  public conciliacaoLancamentoContabeis: any[] = [];
  public entregaProdutoMovimentacaoProdutos: any[] = [];
  public estoqueHistoricos: any[] = [];
  public filtros = filtros;
  public operacaoMovimentacoes: any[] = [];
  public quantidadeMovimentacaoProMovimentacaoQuas: any[] = [];
  public situacaoMovimentacaoParMovimentacoes: any[] = [];
  public situacaoMovimentacaoProMovimentacoes: any[] = [];
  public situacaoMovimentacaoQuaMovimentacoes: any[] = [];
  public transferenciaPisCofins: any[] = [];
  public valorBaixaFinanceiroFinanceiroBaixas: any[] = [];
  public valorDescontoFinanceiroFinanceiroBaixas: any[] = [];
  public valorFinalFinanceiros: any[] = [];
  public valorFinanceiroMovimentacaoFinanceiros: any[] = [];
  public valorFinanceiroMovimentacaoMovimentacaoParceiros: any[] = [];
  public valorJuroFinanceiroFinanceiroBaixas: any[] = [];
  public valorLiquidoMovimentacaoParFinanceiros: any[] = [];
  public valorMovimentacaoMovimentacaoParceiros: any[] = [];
  public valorMovimentacaoSemFinanceiroFinanceiros: any[] = [];
  public valorRestanteFinanceiros: any[] = [];
  public movimentacaoMovimentacaoPendentes: any[] = [];
  public movimentacaoProdutoEstoqueHistoricos: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.conciliacaoFinanceiroBaixas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ConciliacaoFinanceiroBaixa').lista;
      this.conciliacaoLancamentoContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ConciliacaoLancamentoContabil').lista;
      this.entregaProdutoMovimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'EntregaProdutoMovimentacaoProduto').lista;
      this.estoqueHistoricos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'EstoqueHistorico').lista;
      this.operacaoMovimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'OperacaoMovimentacao').lista;
      this.quantidadeMovimentacaoProMovimentacaoQuas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'QuantidadeMovimentacaoProMovimentacaoQua').lista;
      this.situacaoMovimentacaoParMovimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'SituacaoMovimentacaoParMovimentacao').lista;
      this.situacaoMovimentacaoProMovimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'SituacaoMovimentacaoProMovimentacao').lista;
      this.situacaoMovimentacaoQuaMovimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'SituacaoMovimentacaoQuaMovimentacao').lista;
      this.transferenciaPisCofins = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TransferenciaPisCofins').lista;
      this.valorBaixaFinanceiroFinanceiroBaixas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorBaixaFinanceiroFinanceiroBaixa').lista;
      this.valorDescontoFinanceiroFinanceiroBaixas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorDescontoFinanceiroFinanceiroBaixa').lista;
      this.valorFinalFinanceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorFinalFinanceiro').lista;
      this.valorFinanceiroMovimentacaoFinanceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorFinanceiroMovimentacaoFinanceiro').lista;
      this.valorFinanceiroMovimentacaoMovimentacaoParceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorFinanceiroMovimentacaoMovimentacaoPar').lista;
      this.valorJuroFinanceiroFinanceiroBaixas = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorJuroFinanceiroFinanceiroBaixa').lista;
      this.valorLiquidoMovimentacaoParFinanceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorLiquidoMovimentacaoParFinanceiro').lista;
      this.valorMovimentacaoMovimentacaoParceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorMovimentacaoMovimentacaoPar').lista;
      this.valorMovimentacaoSemFinanceiroFinanceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorMovimentacaoSemFinanceiroFinanceiro').lista;
      this.valorRestanteFinanceiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ValorRestanteFinanceiro').lista;
      this.movimentacaoMovimentacaoPendentes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoMovimentacaoPendente').lista;
      this.movimentacaoProdutoEstoqueHistoricos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProdutoEstoqueHistorico').lista;
    });
  }
}
