import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015TransferenciaPisCofins',
  templateUrl: './r2015TransferenciaPisCofins.component.html',
})
export class R2015TransferenciaPisCofins {
  @Input() transferenciaPisCofins: any[] = [];
  public bibDialogo = bibDialogo;
}
