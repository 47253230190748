<layout>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-40 clique" (click)="util.ordenarLista(nomenclaturaEmpresas, 'nomenclatura')">{{ bibDialogo.nomenclatura }}</th>
        <th class="l-40 clique" (click)="util.ordenarLista(nomenclaturaEmpresas, 'apelido')">{{ bibDialogo.apelido }}</th>
        <th class="l-20 text-right"><botao [id]="'restauraPadrao'" [compacto]="compacto" [legenda]="bibDialogo.restauraPadrao" (botaoEmt)="restaurarPadrao()"></botao></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let nomenclaturaEmpresa of nomenclaturaEmpresas; let i = index">
        <tr *ngIf="i >= posicao && i < posicao + paginacao">
          <td>{{ nomenclaturaEmpresa.nomenclatura }}</td>
          <td colspan="2"><texto [id]="'nomenclatura' + i" [campo]="nomenclaturaEmpresa.apelido" (alteracao)="alterar(nomenclaturaEmpresa, $event)" [normal]="true"></texto></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirNomenclaturaEmpresa()" [desabilitado]="!temPermissaoEdicao"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
