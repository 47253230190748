export class RelatorioUsuario {
  public acessar: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public favorito: string = 'N';
  public id: number;
  public idEmpresa: number;
  public idModuloEmpresa: number;
  public idRelatorio: number;
  public idUsuario: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public novo: string;
  public ordem: number;
  public principal: string;
  public relatorio: string;
  public relatorioModulo: string;
  public servico: string;
  public tipoRelatorioModulo: string;
  public dashboard: string;
  public dashboardInicial: string;
  public excel: string;
}
