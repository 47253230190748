export class Permissao {
  public agrow: string;
  public descricao: string;
  public editar: string;
  public excluir: string;
  public favorito: string;
  public filtro: string;
  public icone: string;
  public idMenu: number;
  public idMenuFiltro: number;
  public idMenuTemporario: string;
  public idModulo: number;
  public idModuloEmpresa: number;
  public idOperacao: number;
  public imprimir: string;
  public inativar: string;
  public insercao: string;
  public inserir: string;
  public menu: string;
  public modularizado: string;
  public novo: string;
  public parametro: string;
  public posicao: number;
  public servico: string;
  public tag: string;
  public tela: string;
  public verbo: string;
  public menuLink: string;
}
