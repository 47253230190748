import { Component, HostListener, Input } from '@angular/core';
import { addDays, differenceInDays, format } from 'date-fns';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';
import { Relatorio } from 'src/app/modelo/relatorio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from '../../utilitario/pagina.component';

@Component({
  selector: 'filtro',
  templateUrl: './filtro.component.html',
})
export class FiltroComponent extends PaginaComponent {
  private idIntegracao: number;
  private idMenu: number;
  public compactoFiltro: boolean = false;
  public ehValidoFiltroAlemObrigatorio: boolean = false;
  public filtroNome: string;
  public filtroObrigatorio: boolean = false;
  public filtros: Filtro[] = [];
  public idRelatorio: number;
  public quantidadeFiltroObrigatorio: number;
  public relatorioNome: string;
  public visualizacao = [];
  public ids: string[] = [];
  public filtroAutomatico: boolean = false;
  public posicao: number = 1;
  @Input() criterios: Criterio[] = [];

  public tipos: any[] = [
    { id: '-', nome: this.bibDialogo.todos.toUpperCase() },
    { id: 'F', nome: this.bibDialogo.fisica.toUpperCase() },
    { id: 'J', nome: this.bibDialogo.juridica.toUpperCase() },
  ];

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    switch (event.keyCode) {
      case this.bibAtalho.listar:
        this.filtrar();
        break;
    }
  }

  ngOnInit(): void {
    this.utilSessao.limparResultado();
    this.filtroAutomatico = this.utilSessao.idRelatorioInterno != null;
    this.utilSessao.idRelatorioInterno = null;
    this.idRelatorio = this.utilSessao.getIdentificacao('idRelatorio')?.conteudo;
    this.idIntegracao = this.utilSessao.getIdentificacao('idIntegracao')?.conteudo;
    this.relatorioNome = this.utilSessao.getRelatorioNome();
    if (this.idRelatorio) {
      this.filtros = this.utilSessao.getFiltroRelatorio(this.idRelatorio);
      const relatorio: Relatorio = this.utilSessao.getRelatorios().find((relatorio) => relatorio.id == this.idRelatorio);
      this.filtroObrigatorio = relatorio ? relatorio.filtro == 'O' : false;
      if (relatorio.filtro == "A") {
        if (relatorio.servico != "" && relatorio.quantidadeRegistroExigeFiltro != null) {
          this.comunicacaoService.listar(new Transporte(new Criterio('CONTAR', 'S')), relatorio.servico).subscribe((res) => {
            const quantidadeRegistros: number = res[0];
            if (quantidadeRegistros > relatorio.quantidadeRegistroExigeFiltro) {
              this.filtroObrigatorio = true;
            }
          });
        }
      }
      this.filtroNome = 'Relatório ' + this.idRelatorio;
    } else {
      if (this.idIntegracao) {
        this.filtros = this.utilSessao.getFiltroIntegracao(this.idIntegracao);
        const integracao: IntegracaoUsuario = this.utilSessao.getUsuarioIntegracoes().find((integracao) => integracao.id == this.idIntegracao);
        this.filtroObrigatorio = integracao ? integracao.filtro == 'O' : false;
        this.filtroNome = 'Integração ' + this.idIntegracao;
      } else {
        this.idMenu = this.permissao.idMenu;
        this.filtros = this.utilSessao.getFiltro(this.idMenu);
        this.filtroObrigatorio = this.permissao ? this.permissao.filtro == 'O' : false;
        this.filtroNome = 'Menu ' + this.idMenu + '_' + this.permissao.parametro + '_' + this.permissao.idOperacao;

      }
    }
    if (this.filtros.length > 0) {
      setTimeout(() => (
        this.filtros.forEach((filtro) => {
          this.vincularFiltroVinculado(filtro);
        })
      ));
      this.popularLista();
      this.lerCriterios();
    }
    this.atualizarFiltrosSemId();
    this.quantidadeFiltroObrigatorio = this.filtros.filter((filtro) => filtro.obrigatorio == 'S').length;
    this.inicializarFiltroVinculado();
    this.inicializarFiltroBloqueado();
    this.ehValidoAlemObrigatorio();
    this.limparFiltroSeNumeroPreenchido();
    if (this.filtroAutomatico) {
      this.setFiltro();
    }
  }

  inicializarFiltroVinculado(): void {
    let filtroVinculos: Filtro[] = this.filtros.filter((filtroBusca) => filtroBusca.vinculoVisualizacao != null);
    filtroVinculos.forEach((filtroVinculo) => {
      let filtro: Filtro = this.filtros.find((filtro) => filtro.nome == filtroVinculo.vinculoVisualizacao);
      let apresentarFiltro: boolean = filtroVinculo.visualizacao.includes(filtro.valor);
      if (apresentarFiltro) {
        filtroVinculo.visualizacaoInicial = true;
      } else {
        filtroVinculo.visualizacaoInicial = false;
        if (filtro.tipo != 12) {
          filtroVinculo.valor = null;
        }
      }
    });
  }

  inicializarFiltroBloqueado(): void {
    let filtroVinculos: Filtro[] = this.filtros.filter((filtroBusca) => filtroBusca.vinculaBloqueado != null);
    filtroVinculos.forEach((filtroVinculo) => {
      let filtro: Filtro = this.filtros.find((filtro) => filtro.nome == filtroVinculo.vinculaBloqueado);
      let apresentarFiltro: boolean = filtroVinculo.bloqueados.includes(filtro.valor);
      if (apresentarFiltro) {
        filtroVinculo.bloqueioInicial = false;
      } else {
        if (Number(filtro.valor)) {
          filtroVinculo.bloqueioInicial = true;
          filtroVinculo.valor = null;
        }
      }
      this.bloquearDesbloquearFiltroCorrentista(filtro, filtroVinculo);
    });
  }

  vincularFiltroVinculado(filtro: Filtro): void {
    if (filtro.vincularValor == 'S') {
      let filtroVinculos: Filtro[] = this.filtros.filter((filtroBusca) => filtroBusca.valorVinculado != null && filtro.valorVinculado != null && filtroBusca.valorVinculado == filtro.valorVinculado && filtro.nome != filtroBusca.nome);
      filtroVinculos.forEach((filtroVinculo) => {
        filtroVinculo.criterios.forEach((criterio) => {
          if (criterio.nome == filtroVinculo.valorVinculado) {
            criterio.valor = filtro.valor;
          }
        })
      });
    }
  }

  lerCriterios(): void {
    let criterios: Criterio[] = this.getCriterios();
    if (criterios != null && criterios.length > 0) {
      for (const criterio of criterios) {
        for (const filtro of this.filtros) {
          if (filtro.nome == criterio.nome) {
            if (filtro.tipo == 1 && criterio.valor && criterio.valor != '' && filtro.idRelatorio == null && filtro.idIntegracao == null) {
              filtro.valor = addDays(new Date(Date.parse(criterio.valor)), 1).toString();
            } else {
              filtro.valor = criterio.valor;
            }
            filtro.apresentacao = criterio.apresentacao;
            filtro.manual = 'S';
          }
        }
      }
    }
  }

  getCriterios(): Criterio[] {
    let criterios: Criterio[];
    if (this.idRelatorio) {
      criterios = this.utilSessao.getCriterioRelatorio(this.idRelatorio);
    } else {
      if (this.idIntegracao) {
        criterios = this.utilSessao.getCriterioIntegracao(this.idIntegracao);
      } else if (this.utilSessao.getCriterio()) {
        criterios = this.utilSessao.getCriterio().filter((criterio) => criterio.idMenu == this.permissao.idMenu && criterio.manual == 'S');
      }
    }
    return criterios;
  }

  reiniciar(): void {
    let historicoFiltros: Filtro[] = this.filtros;
    if (this.idRelatorio) {
      this.filtros = this.utilSessao.getFiltroRelatorio(this.idRelatorio);
    } else {
      if (this.idIntegracao) {
        this.filtros = this.utilSessao.getFiltroIntegracao(this.idIntegracao);
      } else {
        this.filtros = this.utilSessao.getFiltro(this.idMenu);
      }
    }
    this.filtros.forEach((filtro) => {
      filtro.lista = historicoFiltros.find((filtroBusca) => filtroBusca.nome == filtro.nome).lista;
    });
    this.ehValidoAlemObrigatorio();
  }

  popularLista(): void {
    for (let filtro of this.filtros.filter((filtro) => filtro.tipo == bibPropriedade.filtro.lista && filtro.servicoWeb != null)) {
      for (const criterio of this.criterios) {
        filtro.criterios.push(criterio);
      }
      this.comunicacaoService.listar(new Transporte(filtro.criterios), filtro.servicoWeb).subscribe((res) => {
        filtro.lista = res;
      });
    }
  }

  filtrar(): void {
    if (this.ehValido()) {
      this.setFiltro();
    }
  }

  setFiltro(): void {
    let criterios: Criterio[] = [];
    this.filtros.forEach((filtro) => {
      if (filtro.valor == null || filtro.valor == 'Invalid Date') {
        filtro.valor = '';
      }
    });

    let filtroVinculos: Filtro[] = this.filtros.filter((filtroBusca) => filtroBusca.vinculoVisualizacao != null);
    filtroVinculos.forEach((filtroVinculo) => {
      let filtro: Filtro = this.filtros.find((filtro) => filtro.nome == filtroVinculo.vinculoVisualizacao);
      let apresentarFiltro: boolean = filtroVinculo.visualizacao.includes(filtro.valor);
      if (!apresentarFiltro) {
        filtroVinculo.valor = null;
        filtroVinculo.apresentacao = '';
      }
    });

    for (let filtro of this.filtros.filter((filtro) => (filtro.valor != '' && filtro.tipo != 5) || (filtro.tipo == 5 && filtro.valor) || (filtro.tipo == 2 && filtro.valor == 0) || filtro.tipo == 1)) {
      this.formatarData(filtro);
      if ((filtro.idIntegracao || filtro.idRelatorio) && filtro.tipo == 1 && filtro.valor) {
        filtro.valor = "'" + filtro.valor + "'";
      }
      this.atualizarApresentacaoListaCriterioPadrao(filtro);
      criterios.push(new Criterio(filtro.nome, filtro.valor, this.permissao ? this.permissao.idMenu : null, this.idRelatorio, filtro.rotulo, filtro.apresentacao, filtro.tipo, filtro.idIntegracao, filtro.manual, filtro.visualizacaoInicial));
    }
    this.utilSessao.modalFiltro = true;
    this.utilSessao.setFiltroNome(this.filtroNome);
    this.utilSessao.telaFiltrada = this.router.url;

    this.utilSessao.setCriterio(criterios.filter((criterio) => criterio.valor != '' || (criterio.tipo == 2 && criterio.valor == '0') || criterio.valor == ''));
    criterios = criterios.filter((criterio) => (criterio.valor != '' && criterio.valor != '-') || (criterio.tipo == 2 && criterio.valor == '0'));

    criterios.forEach((criterio) => {
      if (criterio.tipo == 12) {
        criterio.apresentacao = criterio.valor.length == 1 ? (criterio.valor[0].nomeFantasia && criterio.valor[0].abreviacao ? criterio.valor[0].abreviacao : criterio.valor[0].nome) : criterio.valor.length + ' ' + bibDialogo.selecionados;
        criterio.valor = criterio.valor.map((registro) => registro.id).toString();
      }
    });
    this.utilSessao.setPaginaAtual(1);
    this.utilSessao.iniciarListagem = true;
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.criterio, criterios));
  }

  formatarData(filtro: Filtro): void {
    if (filtro.tipo == 1 && filtro.valor != null && filtro.valor != '') {
      filtro.valor = format(new Date(Date.parse(filtro.valor)), 'yyyy-MM-dd');
    }
  }

  atualizarApresentacaoListaCriterioPadrao(filtro: Filtro): void {
    if (filtro.tipo == 5 && filtro.apresentacao == null && filtro.valor != null) {
      const objeto: any = filtro.lista.find((objeto) => objeto.id == filtro.valor);
      if (objeto) {
        filtro.apresentacao = objeto.nome ? objeto.nome : objeto.numeroNomeParceiro;
      }
    }
  }

  setBusca(filtro: Filtro, objeto: any): void {
    filtro.valor = objeto.id;
    filtro.apresentacao = objeto.nome || objeto.numeroNomeParceiro;
    filtro.manual = 'S';
    this.vincularFiltro(filtro);
    this.ehValidoAlemObrigatorio();
    this.vincularFiltroVinculado(filtro);
  }

  ehValido(): boolean {
    const validoObrigatorio = this.ehValidoObrigatorio();
    const validoPeloMenosUmFiltro = this.ehValidoPeloMenosUmFiltro();
    const validoDataMaximoMes = this.ehValidoDataMaximoMes();
    const validoDataPeridoMesmoAno = this.ehValidoDataPeriodoMesmoAno();
    const validoProdutoDescriacao = this.ehValidoProdutoDescriacao();
    return validoObrigatorio && validoPeloMenosUmFiltro && validoDataMaximoMes && validoProdutoDescriacao && validoDataPeridoMesmoAno;
  }

  ehValidoProdutoDescriacao(): boolean {
    let quantidadeNumeroLetras = 0;
    const filtroProdutoDescricao: Filtro = this.filtros.find((filtro) => filtro.tipo == 0 && filtro.nome == 'NOME_PRODUTO');
    if (filtroProdutoDescricao != null && filtroProdutoDescricao.valor != null) {
      for (let index = 0; index < filtroProdutoDescricao.valor.length; index++) {
        if (filtroProdutoDescricao.valor[index].match('[A-Za-z0-9]')) {
          quantidadeNumeroLetras++;
        }
      }
      if (filtroProdutoDescricao.valor.length > 0 && quantidadeNumeroLetras < 4) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueFiltroProdutoDescricao));
        return false;
      }
    }
    return true;
  }

  ehValidoObrigatorio(): boolean {
    const validoObrigatorio: boolean = this.filtros.filter((filtro) => filtro.obrigatorio == 'S' && filtro.visualizacaoInicial && (filtro.valor == null || filtro.valor == '')).length == 0;
    if (!validoObrigatorio) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    }
    return validoObrigatorio;
  }

  ehValidoPeloMenosUmFiltro(): boolean {
    let validoPeloMenosUmFiltro: boolean = true;
    if (this.filtroObrigatorio) {
      validoPeloMenosUmFiltro = this.filtros.filter((filtro) => filtro.obrigatorio == 'N' && filtro.valor && filtro.valor != '' && filtro.valor != '-' && filtro.rotulo != bibDialogo.exceto && (filtro.rotulo != bibDialogo.maiorQue && filtro.rotulo != bibDialogo.menorQue)).length > 0;
      if (validoPeloMenosUmFiltro == false) {
        this.utilSessao.setResultado(new Resultado(false, this.quantidadeFiltroObrigatorio > 0 ? this.bibDialogo.preenchaUmFiltroAlemObrigatorio : this.bibDialogo.preenchaUmFiltro));
      }
    }
    return validoPeloMenosUmFiltro;
  }

  atualizarFiltrosSemId(): void {
    const filtrosExcetoId: Filtro[] = this.filtros.filter((filtro) => filtro.nome != 'ID');
    if (this.idRelatorio) {
      this.filtros = filtrosExcetoId;
    }
  }

  ehValidoDataMaximoMes(): boolean {
    let validoDataMaximoMes: boolean = true;
    let peloMenosUmIntervaloCorreto: boolean = false;
    if (this.ehCriterioNumero() == false && this.ehCriterioIdProduto() == false && this.ehCampoBuscaPreenchido() == false && this.ehCampoCheckListaPreenchido() == false && this.idRelatorio > 0) {
      this.filtros
        .filter((filtro) => filtro.tipo == 1)
        .filter((filtro) => filtro.nome.includes('DATA'))
        .filter((filtro) => filtro.nome.includes('_DE'))
        .filter((filtro) => filtro.visualizacaoInicial == true)
        .forEach((filtro) => {
          let dataDe: Date = this.criarDataDe(filtro);
          let dataAte: Date = this.criarDataAte(filtro);
          const diferencaDias: number = differenceInDays(dataAte, dataDe);
          if (diferencaDias >= 366) {
            validoDataMaximoMes = false;
          } else {
            peloMenosUmIntervaloCorreto = true;
          }
        });
    }
    if (!validoDataMaximoMes && peloMenosUmIntervaloCorreto == false) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataIntervaloUmAno));
    }

    return validoDataMaximoMes || peloMenosUmIntervaloCorreto;
  }

  ehValidoDataPeriodoMesmoAno(): boolean {
    let validoDataPeriodoMesmoAno: boolean = true;
    if (this.ehCriterioNumero() == false && this.ehCriterioIdProduto() == false && this.ehCampoBuscaPreenchido() == false && this.ehCampoCheckListaPreenchido() == false && this.idRelatorio > 0) {
      this.filtros
        .filter((filtro) => filtro.tipo == 1)
        .filter((filtro) => filtro.nome.includes('PERIODO'))
        .filter((filtro) => filtro.nome.includes('_DE'))
        .filter((filtro) => filtro.visualizacaoInicial == true)
        .forEach((filtro) => {
          let anoDe: string = this.criarDataDe(filtro).getFullYear().toString();
          let anoAte: string = this.criarDataAte(filtro).getFullYear().toString();

          if (anoDe != anoAte) {
            validoDataPeriodoMesmoAno = false;
          }
        });
    }
    if (!validoDataPeriodoMesmoAno) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.periodoIntervaloAno));
    }

    return validoDataPeriodoMesmoAno;
  }

  ehCriterioNumero(): boolean {
    return (
      this.filtros
        .filter((filtro) => filtro.nome.includes('NUMERO'))
        .filter((filtro) => filtro.valor != '')
        .filter((filtro) => filtro.valor != null).length > 0
    );
  }

  ehCriterioIdProduto(): boolean {
    return (
      this.filtros
        .filter((filtro) => filtro.nome.includes('ID_PRODUTO'))
        .filter((filtro) => filtro.valor != '')
        .filter((filtro) => filtro.valor != null).length > 0
    );
  }

  ehCampoBuscaPreenchido(): boolean {
    return (
      this.filtros
        .filter((filtro) => filtro.tipo == this.bibPropriedade.filtro.busca)
        .filter((filtro) => filtro.valor != '')
        .filter((filtro) => filtro.valor != null).length > 0
    );
  }

  ehCampoCheckListaPreenchido(): boolean {
    return (
      this.filtros
        .filter((filtro) => filtro.tipo == this.bibPropriedade.filtro.checklista)
        .filter((filtro) => filtro.valor.length > 0)
        .filter((filtro) => filtro.valor != null).length > 0
    );
  }

  criarDataDe(filtro: Filtro): Date {
    let dataDe: Date = new Date('1500-01-01');
    if (filtro.valor != null && filtro.valor != '') {
      dataDe = new Date(Date.parse(filtro.valor));
    }
    return dataDe;
  }

  criarDataAte(filtro: Filtro): Date {
    let dataAte: Date = new Date();
    const nomeAte: String = filtro.nome.replace('_DE', '_ATE');
    const filtroAte: Filtro = this.filtros.find((filtro) => filtro.nome == nomeAte);
    if (filtroAte && filtroAte.valor != null && filtroAte.valor != '') {
      dataAte = new Date(Date.parse(filtroAte.valor));
    }
    return dataAte;
  }

  setTipoPessoa(filtro: Filtro, tipo: String): void {
    if (filtro.tipoPessoa != tipo) {
      filtro.nome = null;
      filtro.valor = '';
    }
    if (tipo == 'F') {
      filtro.nome = 'TIPO_PESSOA';
      filtro.valor = 'F';
      filtro.rotulo = this.bibDialogo.cpf;
      filtro.servicoWeb = this.bibDialogo.cpf.toLowerCase();
    }
    if (tipo == 'J') {
      filtro.nome = 'TIPO_PESSOA';
      filtro.valor = 'J';
      filtro.rotulo = this.bibDialogo.cnpj;
      filtro.servicoWeb = this.bibDialogo.cnpj.toLowerCase();
    }
    this.ehValidoAlemObrigatorio();
  }

  setFiltroValor(filtro: Filtro, valor: any): void {
    filtro.valor = valor;
    filtro.apresentacao = valor;
    filtro.manual = 'S';
    if (filtro.tipo == 13) {
      let numeracoes: string[] = [];
      filtro.valor.split('.').forEach((numeracao) => {
        if (Number(numeracao) > 0) {
          numeracoes.push(numeracao);
        }
      });
      filtro.valor = numeracoes.join('.');
    }
    const rotuloAtual: string = filtro.rotulo;
    if (filtro.rotulo && filtro.naoLimpa == false && (filtro.rotulo.startsWith('Nº') || filtro.rotulo == 'Número' || filtro.rotulo.startsWith('Núm') || filtro.rotulo == 'ID')) {
      this.filtros.forEach((filtro) => {
        const ehLimparFiltro: boolean = filtro.obrigatorio == 'N' && filtro.rotulo != rotuloAtual;
        const ehChecklist: boolean = filtro.tipo == 12;
        const ehLista: boolean = filtro.tipo == 9;
        if (ehLimparFiltro && !ehChecklist && !ehLista) {
          filtro.valor = null;
          filtro.apresentacao = '';
        }
      });
    }
    this.ehVincularFiltro(filtro);
  }

  setFiltroValorLista(filtro: Filtro, objeto: any): void {
    filtro.valor = objeto.id;
    filtro.apresentacao = objeto.nome ? objeto.nome : objeto.razaoSocial;
    filtro.manual = 'S';
    this.ehVincularFiltro(filtro);
  }

  ehVincularFiltro(filtro: Filtro): void {
    this.vincularFiltro(filtro);
    this.vincularFiltroBloqueado(filtro);
    this.ehValidoAlemObrigatorio();
  }

  vincularFiltro(filtro: Filtro): void {
    let filtroVinculos: Filtro[] = this.filtros.filter((filtroBusca) => filtroBusca.vinculoVisualizacao == filtro.nome);
    filtroVinculos.forEach((filtroVinculo) => {
      if (filtroVinculo.rotulos) {
        filtroVinculo.rotulos.forEach((rotulo) => (rotulo.valor == filtro.valor ? (filtroVinculo.rotulo = rotulo.rotulo) : (filtroVinculo.rotulo = filtroVinculo.rotulo)));
      }
      let apresentarFiltro: boolean = filtroVinculo.visualizacao.indexOf(filtro.valor) > -1 || this.vincularFiltroLista(filtro, filtroVinculo);
      if (apresentarFiltro && filtroVinculo.visualizacaoInicial == false) {
        filtroVinculo.visualizacaoInicial = true;
      } else {
        filtroVinculo.visualizacaoInicial = false;
      }
    });
  }

  vincularFiltroLista(filtro: Filtro, filtroVinculo: Filtro): boolean {
    let apresentarFiltro: boolean = false;
    if (filtro && filtro.valor) {
      let ehLista: boolean = Array.isArray(filtro.valor);
      if (ehLista) {
        filtro.valor.forEach((valor) => {
          if (valor.correntista != undefined) {
            this.visualizacao.push(valor.correntista);
            if (!this.ids.includes(valor.id.toString())) {
              this.ids.push(valor.id.toString());
            }
            filtro.valor.length == 0 ? (this.visualizacao = []) : '';
          }
        });
        this.visualizacao.forEach((visualizacao) => (filtroVinculo.visualizacao.includes(visualizacao) ? (apresentarFiltro = true) : (apresentarFiltro = false)));
      }
    }
    return apresentarFiltro;
  }

  vincularFiltroBloqueado(filtro: Filtro): void {
    let filtroVinculos: Filtro[] = this.filtros.filter((filtroBusca) => filtroBusca.vinculaBloqueado == filtro.nome);
    filtroVinculos.forEach((filtroVinculo) => {
      if (filtroVinculo.rotulos) {
        filtroVinculo.rotulos.forEach((rotulo) => (rotulo.valor == filtro.valor ? (filtroVinculo.rotulo = rotulo.rotulo) : (filtroVinculo.rotulo = filtroVinculo.rotulo)));
      }
      let apresentarFiltro: boolean = filtroVinculo.bloqueados.indexOf(filtro.valor) > -1;
      if (apresentarFiltro) {
        filtroVinculo.bloqueioInicial = false;
      } else {
        filtroVinculo.bloqueioInicial = true;
        filtroVinculo.valor = null;
      }
      this.bloquearDesbloquearFiltroCorrentista(filtro, filtroVinculo);
    });
  }

  ehValidoAlemObrigatorio(): void {
    if (this.filtroObrigatorio) {
      this.ehValidoFiltroAlemObrigatorio = this.filtros.filter((filtro) => filtro.obrigatorio == 'N' && filtro.valor && filtro.valor != '' && filtro.valor != '-' && filtro.rotulo != bibDialogo.exceto && (filtro.rotulo != bibDialogo.maiorQue && filtro.rotulo != bibDialogo.menorQue)).length > 0;
    }
  }

  limparFiltroSeNumeroPreenchido(): void {
    this.filtros.forEach((filtro) => {
      if (filtro.valor && filtro.valor.length > 1 && (filtro.rotulo.startsWith('Nº') || filtro.rotulo == 'Número' || filtro.rotulo.startsWith('Núm') || filtro.rotulo == 'ID')) {
        this.filtros.forEach((filtro) => {
          const ehLimparFiltro: boolean = filtro.obrigatorio == 'N' && filtro.rotulo && !filtro.rotulo.startsWith('Nº') && !filtro.rotulo.startsWith('Número') && !filtro.rotulo.startsWith('Núm');
          const ehChecklist: boolean = filtro.tipo == 12;
          const ehLista: boolean = filtro.tipo == 9;
          if (ehLimparFiltro && !ehChecklist && !ehLista) {
            filtro.valor = '';
            filtro.apresentacao = '';
          }
        });
      }
    });
  }

  bloquearDesbloquearFiltroCorrentista(filtro: Filtro, filtroVinculo: Filtro) {
    if (filtro && filtro.valor && filtro.tipo == 12) {
      filtro.valor.forEach((valor) => {
        if (valor && valor.correntista != null) {
          if (filtroVinculo.bloqueados.includes(valor.correntista.toString())) {
            filtroVinculo.bloqueioInicial = false;
          }
        }
      });
    }
  }

  focar(focado: boolean, indice: number) {
    if (focado) {
      this.posicao = indice;
    }
  }
}
