import { Pipe, PipeTransform } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Pipe({
  name: 'filtrarRelatorioUsuario',
})
export class RelatorioUsuarioPipe implements PipeTransform {
  transform(relatorioUsuarios: any[], argumentos?): any {
    if (argumentos.filtro || argumentos.especifico == bibDialogo.semAcesso || argumentos.especifico == bibDialogo.comAcesso) {
      if (argumentos && argumentos.filtro) {
        argumentos.filtro = argumentos.filtro.toLowerCase();
      }
      switch (argumentos.especifico) {
        case bibDialogo.numero:
          return relatorioUsuarios.filter((relatorioUsuario) => relatorioUsuario.idRelatorio.toString().toUpperCase() == argumentos.filtro);
        case bibDialogo.nome:
          return relatorioUsuarios.filter((relatorioUsuario) => relatorioUsuario.relatorio.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.modulo:
          return relatorioUsuarios.filter((relatorioUsuario) => relatorioUsuario.relatorioModulo.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.semAcesso:
          return relatorioUsuarios.filter((relatorioUsuario) => relatorioUsuario.acessar == 'N');
        case bibDialogo.comAcesso:
          return relatorioUsuarios.filter((relatorioUsuario) => relatorioUsuario.acessar == 'S');
      }
    } else {
      return relatorioUsuarios;
    }
  }
}
