import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { R1012Component } from '../r1012.component';

@Component({
  selector: '<tr>[r1012itens]</tr>',
  templateUrl: './r1012itens.component.html',
})
export class R1012ItensComponent extends R1012Component {
  @Input() movimentacaoProdutos: any[];
  public bibDialogo = bibDialogo;

  abrirModalMovimentacao(id: number): void {
    this.emitirModal(id);
  }
}
