import { Component } from '@angular/core';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Praga } from 'src/app/agrow/modelo/praga';

@Component({
  templateUrl: './pragaFrm.component.html',
})
export class PragaFrmComponent extends PaginaComponent {
  public praga: Praga = new Praga();
  public integracaoEidaf: boolean = false;
  public integracaoAgroDefesa: boolean = false;

  ngOnInit(): void {
    this.integracaoEidaf = this.utilSessao.getLojas().filter((loja) => loja.estado == 'ES').length > 0 ? true : false;
    this.integracaoAgroDefesa = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO').length > 0 ? true : false;
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.praga = this.plainToClass(Praga, this.ehAtualizacao(this.praga));
    if (this.praga.id) {
      this.listarPraga(this.praga.id);
    }
  }

  listarPraga(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.praga).subscribe((res) => {
      this.praga = this.plainToClass(Praga, res[0]) as any;
    });
  }

  persistirPraga(novo: boolean = false): void {
    if (this.ehValidoObrigatorio()) {
      super.persistir(new Transporte(this.praga), this.bibServico.praga, novo ? new Praga() : null);
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.praga.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setNomeCientifico(praga: any) {
    this.praga.nome = praga.nomePopular;
    this.praga.nomeCientifico = praga.nome;
  }
}
