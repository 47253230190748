import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ParceiroContato } from 'src/app/modelo/parceiroContato';
import { Transporte } from 'src/app/modelo/transporte';
import { Vinculo } from 'src/app/modelo/vinculo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroContatoFrmComponent } from './frm/parceiroContatoFrm.component';

@Component({
  selector: 'parceiroContato',
  templateUrl: './parceiroContato.component.html',
})
export class ParceiroContatoComponent extends PaginaComponent {
  @Input() excluirContatos: number[] = [];
  @Input() tipoVinculo: string;
  public componente: any;
  private modalEmt: any;

  public _parceiroContatos: ParceiroContato[];
  public _idParceiro: number;

  @Input() set idParceiro(idParceiro: number) {
    this._idParceiro = idParceiro;
    this.sugerirVinculos();
  }

  @Input() set parceiroContatos(parceiroContatos: ParceiroContato[]) {
    this._parceiroContatos = parceiroContatos;
    this.sugerirVinculos();
  }

  ngOnInit(): void {
    this.sugerirVinculos();
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.parceiroContato) {
        this.adicionarTemporario(this.plainToClass(ParceiroContato, identificacao.conteudo), this._parceiroContatos);
      }
    });
  }

  sugerirVinculos(): void {
    if ((this._idParceiro == null || this._idParceiro == undefined) && this._parceiroContatos && this._parceiroContatos.length == 0) {
      this.comunicacaoService.listar(new Transporte([new Criterio('CONTATO', 'S'), new Criterio('TIPO', this.tipoVinculo)]), this.bibServico.vinculo).subscribe((res) => {
        let vinculos: Vinculo[] = this.plainToClass(Vinculo, res);
        const vinculosAutomaticos: Vinculo[] = vinculos.filter((vinculo) => vinculo.sugestaoAutomatica == 'S');
        this.gerarVinculosAutomaticos(vinculosAutomaticos);
      });
    }
  }

  gerarVinculosAutomaticos(vinculos: Vinculo[]): void {
    if ((this._idParceiro == null || this._idParceiro == undefined) && this._parceiroContatos && this._parceiroContatos.length == 0) {
      vinculos.forEach((vinculo) => {
        let parceiroContato: ParceiroContato = new ParceiroContato();
        parceiroContato.idVinculo = vinculo.id;
        parceiroContato.vinculo = vinculo.nome;
        parceiroContato.nome = null;
        this.adicionarTemporario(this.plainToClass(ParceiroContato, parceiroContato), this._parceiroContatos);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  abrirModal(parceiroContato: ParceiroContato = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', parceiroContato));
    this.utilSessao.setIdentificacao(new Identificacao('tipoVinculo', this.tipoVinculo));
    this.componente = ParceiroContatoFrmComponent;
  }
}
