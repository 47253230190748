import { Util } from '../utilitario/util';
import { UtilSessao } from '../utilitario/util.sessao';

export class Conexao {
  constructor(public utilSessao: UtilSessao) {
    this.montarUrl();
  }
  private util: Util = new Util();
  public url: string;
  public caminhoAwsS3: string;

  montarUrl() {
    switch (this.utilSessao.backend) {
      case 'backend':
        this.url = 'https://backend.agrownegocios.com.br/Backend/';
        this.caminhoAwsS3 = 'https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/agrow/';
        break;

      case 'backhom':
        this.url = 'https://backhom.agrownegocios.com.br/Backend/';
        this.caminhoAwsS3 = 'https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/homologacao/';
        break;

      case 'backadm':
        this.url = 'https://backadm.agrownegocios.com.br/Backend/';
        this.caminhoAwsS3 = 'https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/adm/';
        break;

      default:
        this.url = 'http://127.0.0.1:9180/Backend/';
        this.caminhoAwsS3 = 'https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/local/';
    }
  }

  unirUrl(servico: string): string {
    return this.url + servico;
  }

  contarUrl(servico: string): string {
    return this.url + 'contar' + this.util.definirPrimeiraMaiscula(servico);
  }

  listarUrl(servico: string): string {
    return this.url + 'listar' + this.util.definirPrimeiraMaiscula(servico);
  }

  buscarUrl(servico: string): string {
    return this.url + 'buscar' + this.util.definirPrimeiraMaiscula(servico);
  }

  duplicarUrl(servico: string): string {
    return this.url + 'duplicar' + this.util.definirPrimeiraMaiscula(servico);
  }

  transformarUrl(servico: string): string {
    return this.url + 'transformar' + this.util.definirPrimeiraMaiscula(servico);
  }

  executarUrl(metodoServico: string): string {
    return this.url + metodoServico;
  }

  persistirUrl(servico: string): string {
    return this.url + 'persistir' + this.util.definirPrimeiraMaiscula(servico);
  }

  excluirUrl(servico: string): string {
    return this.url + 'excluir' + this.util.definirPrimeiraMaiscula(servico);
  }
}
