import { Component, Input } from '@angular/core';

@Component({
  selector: '<tr>[r1151financeiro]</tr>',
  templateUrl: './r1151financeiro.component.html',
})
export class R1151FinanceiroComponent {
  @Input() financeiro: any;
  @Input() tachado: boolean = false;
}
