import { Pipe, PipeTransform } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Pipe({
  name: 'filtrarUsuarioMenu',
})
export class UsuarioMenuPipe implements PipeTransform {
  transform(menus: any[], argumentos?: any): any {
    if (argumentos.filtro || argumentos.especifico == bibDialogo.semAcesso || argumentos.especifico == bibDialogo.comAcesso) {
      if (argumentos && argumentos.filtro) {
        argumentos.filtro = argumentos.filtro.toLowerCase();
      }
      switch (argumentos.especifico) {
        case bibDialogo.menu:
          return menus.filter((menu) => menu.menu.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.semAcesso:
          return menus.filter((menu) => menu.acessar == 'N');
        case bibDialogo.comAcesso:
          return menus.filter((menu) => menu.acessar == 'S');
        default:
          return menus.filter((menu) => menu.menu.toLowerCase().includes(argumentos.filtro) || menu.abreviacao.toLowerCase().includes(argumentos.filtro));
      }
    } else {
      return menus;
    }
  }
}
