import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'favorito',
})
export class FavoritoPipe implements PipeTransform {
  transform(registros: any): any {
    if (registros != null) {
      let favoritos = registros.filter((registro) => registro && registro.favorito == 'S');
      favoritos.sort((a, b) => {
        return a['menu'] === b['menu'] ? 0 : a['menu'] > b['menu'] ? 1 : -1;
      });
      return favoritos;
    } else {
      return [];
    }
  }
}
