import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transferencia } from 'src/app/modelo/transferencia';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  selector: 'transferenciaBancaria',
  templateUrl: './transferenciaBancaria.component.html',
})
export class TransferenciaBancariaComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public transferencias: Transferencia[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.transferencia).subscribe((res) => {
      this.transferencias = this.plainToClass(Transferencia, res) as any;
    });
  }

  excluirTransferencia(id: number, nome: string): void {
    super.excluir<Transferencia>(id, this.transferencias, nome);
  }
}
