import { Toma3 } from "./toma3";

export class Ide {
  public cUF: string;
  public cCT: string;
  public CFOP: string;
  public natOp: string;
  public mod: string;
  public serie: string;
  public nCT: string;
  public dhEmi: string;
  public tpImp: string;
  public tpEmis: string;
  public cDV: string;
  public tpAmb: string;
  public tpCTe: string;
  public procEmi: string;
  public verProc: string;
  public cMunEnv: string;
  public xMunEnv: string;
  public UFEnv: string;
  public modal: string;
  public tpServ: string;
  public cMunIni: string;
  public xMunIni: string;
  public UFIni: string;
  public cMunFim: string;
  public xMunFim: string;
  public UFFim: string;
  public retira: string;
  public indIEToma: string;
  public dhCont: string;
  public xJust: string;
  public toma3: Toma3;
}