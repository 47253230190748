import { Component } from '@angular/core';
import { Cor } from 'src/app/modelo/cor';
import { Criterio } from 'src/app/modelo/criterio';
import { Relacionamento } from 'src/app/modelo/relacionamento';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './relacionamentoFrm.component.html',
})
export class RelacionamentoFrmComponent extends PaginaComponent {
  public cor: string;
  public cores: Cor[];
  public relacionamento: Relacionamento = new Relacionamento();

  ngOnInit(): void {
    this.listarCor();
    this.ehAlteracao();
  }

  listarCor(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cor).subscribe((res) => {
      this.cores = this.plainToClass(Cor, res) as any;
    });
  }

  ehAlteracao(): void {
    this.relacionamento = this.plainToClass(Relacionamento, this.ehAtualizacao(this.relacionamento));
    if (this.relacionamento.id) {
      this.listarRelacionamento(this.relacionamento.id);
    }
  }

  listarRelacionamento(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.relacionamento).subscribe((res) => {
      this.relacionamento = this.plainToClass(Relacionamento, res[0]) as any;
    });
  }

  persistirRelacionamento(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.relacionamento), this.bibServico.relacionamento, novo ? new Relacionamento() : null);
    }
  }

  ehValido(): boolean {
    if (this.relacionamento.nome && this.relacionamento.idCor) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setCor(cor: Cor): void {
    this.relacionamento.idCor = cor.id;
    this.relacionamento.cor = cor.nome.toLowerCase();
  }
}
