import { Type } from 'class-transformer';
import { Conciliacao } from './conciliacao';
import { TransferenciaCheque } from './transferenciaCheque';

export class Transferencia {
  @Type(() => Date)
  public data: Date = new Date();
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public contaDestino: string;
  public contaOrigem: string;
  public id: number;
  public idContaDestino: number;
  public idContaOrigem: number;
  public idEmpresa: number;
  public especie: number;
  public idTipoTitulo: number;
  public idLoja: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public observacao: string;
  public tipoTituloTipo: number;
  public transferenciaCheques: TransferenciaCheque[] = [];
  public conciliacoes: Conciliacao[] = [];
  public valor: number;
}
