export class ParceiroContato {
  public alterado: boolean = false;
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public excluido: boolean = false;
  public id: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public idVinculo: number;
  public nome: string;
  public telefone: string;
  public vinculo: string;
  public observacao: string;
  public email: string;
}
