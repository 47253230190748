import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { MenuModuloEmpresa } from 'src/app/modelo/menuModuloEmpresa';
import { ModuloEmpresa } from 'src/app/modelo/moduloEmpresa';
import { RelatorioModuloEmpresa } from 'src/app/modelo/relatorioModuloEmpresa';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './moduloEmpresaFrm.component.html',
})
export class ModuloEmpresaFrmComponent extends PaginaComponent {
  public idsMenuModuloEmpresaExcluir: number[] = [];
  public idsRelatorioModuloEmpresaExcluir: number[] = [];
  public moduloEmpresa: ModuloEmpresa = new ModuloEmpresa();
  public moduloEmpresas: ModuloEmpresa[] = [];

  ngOnInit(): void {
    this.listarModuloEmpresaPai();
    this.ehAlteracao();
  }

  listarModuloEmpresaPai(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.moduloEmpresa).subscribe((res) => {
      this.moduloEmpresas = this.plainToClass(ModuloEmpresa, res) as any;
    });
  }

  ehAlteracao(): void {
    this.moduloEmpresa = this.plainToClass(ModuloEmpresa, this.ehAtualizacao(this.moduloEmpresa));
    if (this.moduloEmpresa.id) {
      this.listarModuloEmpresa(this.moduloEmpresa.id);
    }
  }

  listarModuloEmpresa(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.moduloEmpresa).subscribe((res) => {
      this.moduloEmpresa = this.plainToClass(ModuloEmpresa, res[0]) as any;
      this.listarMenuModuloEmpresa();
      this.listarRelatorioModuloEmpresa();
    });
  }

  listarMenuModuloEmpresa(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('ID_MODULO_EMPRESA', this.moduloEmpresa.id)]), this.bibServico.menuModuloEmpresa).subscribe((res) => {
      this.moduloEmpresa.menuModuloEmpresas = this.ordenarPorOrdem(this.plainToClass(MenuModuloEmpresa, res) as any);
    });
  }

  listarRelatorioModuloEmpresa(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('ID_MODULO_EMPRESA', this.moduloEmpresa.id)]), this.bibServico.relatorioModuloEmpresa).subscribe((res) => {
      this.moduloEmpresa.relatorioModuloEmpresas = this.ordenarPorOrdem(this.plainToClass(RelatorioModuloEmpresa, res) as any);
    });
  }

  ordenarPorOrdem(menus: any[]): any[] {
    return menus.sort((a, b) => (a['ordem'] == b['ordem'] ? 0 : a['ordem'] > b['ordem'] ? 1 : -1));
  }

  persistirModuloEmpresa(novo: boolean = false): void {
    if (this.ehValido()) {
      if (this.moduloEmpresa.idModuloPai == 0) {
        this.moduloEmpresa.idModuloPai = null;
      }
      this.moduloEmpresa.nome = this.moduloEmpresa.nome.trim();
      let transporte: Transporte = new Transporte(this.moduloEmpresa);
      transporte.adicionar(this.idsMenuModuloEmpresaExcluir);
      transporte.adicionar(this.idsRelatorioModuloEmpresaExcluir);
      super.persistir(transporte, this.bibServico.moduloEmpresa, novo ? new ModuloEmpresa() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.moduloEmpresa], this.bibClasse.moduloEmpresa, true);
          this.definirId(this.moduloEmpresa.menuModuloEmpresas, this.bibClasse.menuModuloEmpresa);
          this.definirId(this.moduloEmpresa.relatorioModuloEmpresas, this.bibClasse.relatorioModuloEmpresa);
          this.idsMenuModuloEmpresaExcluir = [];
          this.idsRelatorioModuloEmpresaExcluir = [];
          if (novo) {
            this.moduloEmpresa = null;
          }
        }
      });
    }
  }

  ehValido(): boolean {
    if (this.moduloEmpresa.nome && this.moduloEmpresa.ordem.toString()) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
