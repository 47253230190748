import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Grupo } from 'src/app/modelo/grupo';
import { GrupoIcms } from 'src/app/modelo/grupoIcms';
import { GrupoIcmsSt } from 'src/app/modelo/grupoIcmsSt';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { IcmsFrmComponent } from 'src/app/pagina/imposto/icms/icmsFrm.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './grupoImpostoFrm.component.html',
})
export class GrupoImpostoFrmComponent extends PaginaComponent {
  private excluirGrupoCstsIcms: number[] = [];
  public componente: any;
  public excluirGrupoIcmsSts: number[] = [];
  public grupo: Grupo = new Grupo();
  public grupoCstsIcms: GrupoIcms[] = [];
  public grupoIcmsEntradas: GrupoIcms[] = [];
  public grupoIcmsSaidas: GrupoIcms[] = [];

  ngOnInit(): void {
    this.ehAlteracao();
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.grupoIcms) {
        this.adicionarTemporario(this.plainToClass(GrupoIcms, identificacao.conteudo), this.grupoCstsIcms);
        this.grupoCstsIcms.sort((a, b) => (a['estado'] == b['estado'] ? 0 : a['estado'] > b['estado'] ? 1 : -1));
      }
    });
  }

  ehAlteracao(): void {
    this.grupo = this.plainToClass(Grupo, this.ehAtualizacao(this.grupo));
    if (this.grupo.id) {
      this.listarGrupo();
    }
  }

  listarGrupo(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.grupo.id)), this.bibServico.grupo).subscribe((res) => {
      this.grupo = this.plainToClass(Grupo, res[0]) as any;
      this.listarCstIcms();
    });
  }

  listarCstIcms(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO', this.grupo.id)), this.bibServico.grupoIcms).subscribe((res) => {
      this.grupoCstsIcms = this.plainToClass(GrupoIcms, res) as any;
      this.listarGrupoIcmsSt();
    });
  }

  listarGrupoIcmsSt(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO', this.grupo.id)), this.bibServico.grupoIcmsSt).subscribe((res) => {
      this.grupo.grupoIcmsSts = this.plainToClass(GrupoIcmsSt, res) as any;
    });
  }

  abrirModalIcms(tipo: number, icms: any = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('icms', icms));
    this.utilSessao.setIdentificacao(new Identificacao('tipo', tipo));
    this.utilSessao.setIdentificacao(new Identificacao('classe', this.bibClasse.grupoIcms));
    this.componente = IcmsFrmComponent;
  }

  persistirGrupoCstIcms(): void {
    if (this.ehValido()) {
      this.vincularGrupoImpostos();
      let ehLimpaIds: boolean = this.grupoCstsIcms.filter((grupoCstIcms) => !grupoCstIcms.id).length == this.grupoCstsIcms.length;
      let transporte: Transporte = new Transporte(this.grupoCstsIcms);
      transporte.adicionar(this.excluirGrupoCstsIcms);
      transporte.adicionar(this.grupo.grupoIcmsSts);
      transporte.adicionar(this.excluirGrupoIcmsSts);
      super.persistir(transporte, this.bibServico.grupoIcms, null).subscribe(() => {
        if (!this.utilSessao.falha && (this.grupo.grupoIcmsSts.length > 0 || this.grupoCstsIcms.length > 0)) {
          if (ehLimpaIds) {
            this.grupoCstsIcms.forEach((grupoCstIcms) => (grupoCstIcms.id = null));
          }
          this.definirId(this.grupoCstsIcms, this.bibClasse.grupoIcms, true);
          this.definirId(this.grupo.grupoIcmsSts, this.bibClasse.grupoIcmsSt);
        }
        if (!this.utilSessao.falha) {
          this.excluirGrupoCstsIcms = [];
          this.excluirGrupoIcmsSts = [];
        }
      });
    }
  }

  ehValido(): boolean {
    if (this.grupoCstsIcms.length == 0 && this.excluirGrupoCstsIcms.length == 0 && this.grupo.grupoIcmsSts.length == 0 && this.excluirGrupoIcmsSts.length == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumRegistroAlterado));
      return false;
    }
    return true;
  }

  vincularGrupoImpostos(): void {
    this.grupoCstsIcms.forEach((grupoIcms) => {
      grupoIcms.idGrupo = this.grupo.id;
    });
    this.grupo.grupoIcmsSts.forEach((grupoIcmsSt) => {
      grupoIcmsSt.idGrupo = this.grupo.id;
    });
  }

  excluirGrupoIcms(posicao: number, tipo: number, id: number): void {
    const grupoIcms: GrupoIcms[] = this.grupoCstsIcms.filter((grupoCstIcms) => grupoCstIcms.tipo == tipo);
    super.ehExcluir(posicao, grupoIcms, this.excluirGrupoCstsIcms, id);
    this.grupoCstsIcms = this.grupoCstsIcms.filter((grupoCstIcms) => grupoCstIcms.tipo != tipo).concat(grupoIcms);
  }
}
