import { Component, EventEmitter, Output } from '@angular/core';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'bloqueia',
  templateUrl: './bloqueia.component.html',
})
export class BloqueiaComponent extends PaginaComponent {
  @Output() bloqueiaTela = new EventEmitter<boolean>();
  public foco: boolean;
  public imagemUsuario: string = 'img/semfoto.jpg';
  public senha: string;
  public usuario: Usuario = this.utilSessao.getUsuario();
  public usuarioIncorreto: boolean = false;

  ngOnInit(): void {
    this.foco = true;
  }

  acessar(): void {
    if (this.senha == this.usuario.senha) {
      this.bloqueiaTela.emit(false);
      this.utilSessao.setBloqueado(false);
      this.usuarioIncorreto = false;
      this.senha = '';
    } else {
      this.usuarioIncorreto = true;
    }
  }
}
