import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { ParceiroHistoricoLimite } from 'src/app/modelo/parceiroHistoricoLimite';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'limiteCreditoHistorico',
  templateUrl: './limiteCreditoHistorico.component.html',
})
export class LimiteCreditoHistoricoComponent extends PaginaComponent {
  public parceiro: string;
  public parceiroHistoricoLimites: ParceiroHistoricoLimite[] = [];
  public empresaLimiteCreditoAutomatico: boolean;

  ngOnInit(): void {
    this.empresaLimiteCreditoAutomatico = this.utilSessao.getEmpresa().limiteCredito == 1 ? true : false;
    this.parceiro = this.utilSessao.getIdentificacao('parceiro')?.conteudo;
    let idParceiro = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
    if (idParceiro) {
      this.listarParceiroHistoricoLimites(idParceiro);
    }
  }

  listarParceiroHistoricoLimites(idParceiro: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_PARCEIRO', idParceiro)]), this.bibServico.parceiroHistoricoLimite).subscribe((res) => {
      this.parceiroHistoricoLimites = this.plainToClass(ParceiroHistoricoLimite, res) as any;
      this.parceiroHistoricoLimites.sort((a, b) => (a['id'] == b['id'] ? (a['data'] == b['data'] ? 0 : a['data'] > b['data'] ? -1 : 1) : a['id'] > b['id'] ? -1 : 1));
    });
  }
}
