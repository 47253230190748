import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { GrupoUsuario } from 'src/app/modelo/grupoUsuario';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './grupoPermissao.component.html',
})
export class GrupoPermissaoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public grupoUsuarios: GrupoUsuario[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.grupoUsuario).subscribe((res) => {
      this.grupoUsuarios = this.plainToClass(GrupoUsuario, res) as any;
    });
  }

  excluirUsuario(id: number, nome: string): void {
    super.excluir<GrupoUsuario>(id, this.grupoUsuarios, nome);
  }
}
