export class GrupoIcmsSt {
  public aliquota: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public estado: string;
  public excluido: boolean = false;
  public grupo: string;
  public id: number;
  public idEmpresa: number;
  public idEstado: number;
  public idGrupo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public mva: number;
}
