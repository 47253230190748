import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Relacionamento } from 'src/app/modelo/relacionamento';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './relacionamento.component.html',
})
export class RelacionamentoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public relacionamentos: Relacionamento[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.relacionamento).subscribe((res) => {
      this.relacionamentos = this.plainToClass(Relacionamento, res);
    });
  }

  excluirRelacionamento(id: number, nome: string): void {
    super.excluir<Relacionamento>(id, this.relacionamentos, nome);
  }
}
