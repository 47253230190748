<layout [titulo]="bibDialogo.transmitir + ' ' + bibDialogo.cte" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div *ngIf="emiteEmContingencia" class="col-sm-12">
     <div class="alert alert-warning has-icon" role="alert">
      <div class="alert-icon">
        <span class="fa fa-bullhorn"></span>
      </div>
      {{ bibDialogo.configuradoContingencia }}
    </div> 
  </div>
  <div *ngIf="emiteEmHomologacao" class="col-sm-12">
    <div class="alert alert-warning has-icon" role="alert">
     <div class="alert-icon">
       <span class="fa fa-bullhorn"></span>
     </div>
     {{ bibDialogo.configuradoHomologacao }}
   </div> 
 </div>  
  <div class="row justify-content-between">
    <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
    <span *ngIf="dataAtualizacao" class="text-uppercase">{{ bibDialogo.dataAtualizacao + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm:ss') }} </span>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroAbreviado + ' ' + bibDialogo.fiscal }}</th>
        <th class="l-5 clique" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-20 clique" (click)="listar(add('parceiroRemetente'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.remetente }}</th>
        <th class="l-20 clique" (click)="listar(add('parceiroDestinatario'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.destinatario }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotal }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <!-- <th class="l-5"></th> -->
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let cte of ctes; let i = index">
        <tr>
          <td>
            <check *ngIf="cte.situacao != 100 && cte.situacao != 101" (alteracao)="setCteSelecionado(cte)"></check>
          </td>
          <td>
              <icone [icone]="bibIcone.circulo" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="cte?.situacao == 1 ? bibPropriedade.icone.cinza : cte?.situacao == 2 ? bibPropriedade.icone.cinza : cte?.situacao == 100 ? bibPropriedade.icone.verde : cte?.situacao < 0 ? bibPropriedade.icone.vermelho : bibPropriedade.icone.preto" [mensagem]="cte.situacao == 1 ? bibDialogo.aguardandoEnvio : cte.situacao == 2 ? bibDialogo.aguardandoEnvio : cte.situacao == 100 ? bibDialogo.transmitidaComSucesso : cte.situacao < 0 ? bibDialogo.rejeicao : bibDialogo.cancelada" [ajudaDireita]="true"></icone>
          </td>
          <td>{{ cte.data | data }}</td>
          <td class="text-right">{{ cte.numero }}</td>
          <td>{{ cte.loja }}</td>
          <td class="limitar fonte-menor">{{ cte.parceiroRemetente }}</td>
          <td class="limitar fonte-menor"><a href="#" (click)="abrirModalCte(cte.id)">{{ cte.parceiroDestinatario }}</a></td>
          <td class="text-right">{{ cte.valor | monetario }}</td>   
          <ng-container>
            <td><btnAdicional *ngIf="cte.situacaoDescricao" [icone]="bibIcone.lupa" [ajuda]="bibDialogo.consultarCte" (btnAdicional)="consultarCte(cte)"></btnAdicional></td>
          </ng-container>
          <td><btnAdicional *ngIf="cte.situacao != 1" [icone]="cte.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="cte.expandido = !cte.expandido" [desabilitado]="cte?.status != 1 ? false : true"></btnAdicional></td>
          <td><btnAdicional *ngIf="cte.situacao == 100 || cte.situacao == 101" [icone]="bibIcone.xml" [ajuda]="bibDialogo.xml" (btnAdicional)="baixarXml(cte)"></btnAdicional></td>
          <td><btnAdicional *ngIf="cte.situacao == 100 || cte.situacao == 101" [icone]="bibIcone.pdf" [ajuda]="bibDialogo.pdf" (btnAdicional)="baixarPdf(cte)"></btnAdicional></td>
          <td><btnAdicional *ngIf="cte.situacao == 100" [icone]="bibIcone.atencao" [ajuda]="bibDialogo.enviarSolicitacaoCancelamento" (btnAdicional)="enviarCancelamento(cte)"></btnAdicional></td>
          <!-- <td><btnAdicional *ngIf="cte.situacao == 100" [icone]="bibIcone.carta" [ajuda]="bibDialogo.enviarPdfXmlParaCliente" (btnAdicional)="ehEnviarEmail(cte.idParceiroDestinatario, cte)"></btnAdicional></td> -->
          <td><btnAdicional *ngIf="cte.situacao != 100 && cte.situacao != 101" [icone]="bibIcone.globo" [ajuda]="bibDialogo.resumo + ' ' + bibDialogo.cte" (btnAdicional)="abrirResumoCte(cte)"></btnAdicional></td>
        </tr>
        <ng-container *ngIf="cte.expandido">
          <tr *ngIf="cte?.situacao != 0 && cte?.situacao != 100 && cte?.situacao != 101">
            <td colspan="15"><descricao [rotulo]="bibDialogo.descricao" [campo]="cte?.situacaoDescricao" [normal]="true" [maximoCaracteres]="1500" [desabilitado]="true"></descricao></td>
          </tr>
          <ng-container *ngIf="cte.situacao == 100 || cte.situacao == 101">
            <tr>
              <td colspan="8"><apresentacao [rotulo]="bibDialogo.chave" [campo]="cte.chave"></apresentacao></td>
              <td colspan="7"><apresentacao [rotulo]="bibDialogo.protocolo" [campo]="cte?.protocolo"></apresentacao></td>
            </tr>
            <tr>
              <td colspan="8"><apresentacao [rotulo]="bibDialogo.situacao" [campo]="cte.situacaoDescricao"> </apresentacao></td>
              <td colspan="7"><apresentacao [rotulo]="bibDialogo.dataHora" [campo]="cte.dataHoraCancelamento ? cte.dataHoraCancelamento : cte.dataHora"> </apresentacao></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="row p-0 m-0">
    <botao [compacto]="compacto" [legenda]="bibDialogo.emitir + ' ' + bibDialogo.cte" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="iniciarProcessamentoCte()"></botao>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>

