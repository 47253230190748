import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r1134.component.html',
  styleUrls: ['./r1134.component.css'],
})
export class R1134Component extends RelatorioComponent {
  public ehMatricial: boolean = false;
  public filtros = filtros;
  public lojaEnderecoEntrega: string;
  public meses: string[] = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');
  public movimentacoes: any[] = [];
  public receituarios: any[] = [];
  public ehDiquatOuParaquat: boolean = false

  listarRelatorio(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().permitir != 'S' ? this.utilSessao.getUsuario().idColaborador : null));
    super.listar(criterios).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.receituarios = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Receituario').lista;
      this.encontrarDiquatOuParaquat(this.receituarios);
      this.vincular();
      this.ehImprimirRelatorio();
    });
  }

  encontrarDiquatOuParaquat(receituarios: any[]) {
    const diquatOuParaquatEncontrado: boolean = receituarios.find((receituario) => receituario.ehDiquatOuParaquat == 'S');
    if (diquatOuParaquatEncontrado) {
      this.ehDiquatOuParaquat = true;
    }
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.ehMatricial = this.definirRegra(relatorioAdicional, 4, this.ehMatricial);
  }

  vincular(): void {
    this.movimentacoes.forEach((movimentacao) => {
      movimentacao.dataRodape = this.formatarData(movimentacao.cidade, new Date(movimentacao.data));
      movimentacao.receituarios = this.receituarios.filter((receituario) => receituario.idMovimentacao == movimentacao.id);
    });
  }

  formatarData(cidade: string, movimentacaoData: Date): string {
    let dia: number = movimentacaoData.getDate();
    let mes: number = movimentacaoData.getMonth();
    let ano: number = movimentacaoData.getFullYear();
    return cidade.toUpperCase() + ', ' + (dia > 9 ? dia : 0 + dia) + ' de ' + this.meses[mes] + ' de ' + ano;
  }

  setQuantidade(quantidade: string): string {
    if (quantidade) {
      if (Number(quantidade.substring(0, quantidade.indexOf(' ') + 1)) - parseInt(quantidade) > 0) {
        return quantidade;
      } else {
        return parseInt(quantidade) + ' ' + quantidade.substring(quantidade.indexOf(' ') + 1, 50);
      }
    }
  }

  setDose(dose: string): string {
    if (dose) {
      if (Number(dose.substring(0, dose.indexOf(' ') + 1)) - parseInt(dose) > 0) {
        return dose;
      } else {
        return parseInt(dose) + ' ' + dose.substring(dose.indexOf(' ') + 1, 50);
      }
    }
  }
}
