import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';

@Component({
  templateUrl: './r2031.component.html',
})
export class R2031Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public comissoes: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public componente: any;
  public modal: boolean;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.comissoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Comissao').lista;
      this.ordenarAgrupar(this.comissoes);
      this.ehImprimirRelatorio();
    });
  }

  expandir(comissao: any) {
    comissao.expandido = !comissao.expandido;
    if (comissao.expandido && (comissao.comissaoDetalhes == null || comissao.comissaoDetalhes.length == 0)) {
      this.listarEspecifico(new Criterio('ID_FINANCEIRO', comissao.idFinanceiro), 202).subscribe((res) => {
        comissao.comissaoDetalhes = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

  emitirModal(id: number): void {
    this.componente = this.abrirModalRelatorio(MovimentacaoFrmComponent, [new Identificacao('objeto', id)], null);
  }
}
