<div class="responsiva">
  <atencao *ngIf="menuRoteiroContabil" class="p-2" [atencao]="bibDialogo.somenteParaUsuarioQueUtilizam + ' ' + menuRoteiroContabil.apelido.toLowerCase()"></atencao>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th *ngIf="menuCategoriaRoteiroContabil" class="l-90 clique" (click)="util.ordenarLista(usuarioCategoriaRoteiros, 'categoriaRoteiroContabil')">{{ menuCategoriaRoteiroContabil.apelido }}</th>
        <th class="l-10 clique text-center" (click)="util.ordenarLista(usuarioCategoriaRoteiros, 'acessar')">{{ bibDialogo.permitir }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usuarioCategoriaRoteiro of usuarioCategoriaRoteiros; let i = index">
        <td class="limitar">{{ usuarioCategoriaRoteiro.categoriaRoteiroContabil }}</td>
        <td class="text-center">
          <icone [icone]="usuarioCategoriaRoteiro.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="usuarioCategoriaRoteiro.acessar = usuarioCategoriaRoteiro.acessar == 'S' ? 'N' : 'S'" [cor]="usuarioCategoriaRoteiro.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza"></icone>
        </td>
      </tr>
    </tbody>
  </table>
</div>
