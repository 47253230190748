import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';

const filtros = {
  filtros: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'numeroMovimentacao', rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO_MOVIMENTACAO', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'dataCompraDe', rotulo: bibDialogo.dataUltCompraDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'dataCompraAte', rotulo: bibDialogo.dataUltCompraAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
    { id: 'produto', rotulo: bibDialogo.produto, nome: 'PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
    { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
    { id: 'categoria', rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: 'categoria', tipo: bibPropriedade.filtro.busca },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { nome: 'ATIVO', valor: 'S', tipo: bibPropriedade.filtro.valorPadrao },
  ]),
};
export default filtros;
