<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-30">{{ bibDialogo.loja }}</th>
        <th class="l">{{ bibDialogo.gestor }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let equipe of equipes; let i = index">
        <tr [ngClass]="{ tachado: equipe.ativo != 'S' }">
          <td class="limitar"> {{ equipe.loja }} </td>              
          <td class="limitar">
            <a href="#" (click)="ir(equipe.id, i, equipes)">{{ equipe.gestor }}</a>
          </td>    
          <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(equipe.id, i, equipes)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirEquipe(equipe.id, equipe.gestor)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>