import { Type } from 'class-transformer';

export class Relatorio {
  @Type(() => Date)
  public data: Date;

  public ativo: string = 'S';
  public controlaPermissao: string = 'S';
  public dashboard: string = 'N';
  public opcao: number;
  public favorito: string;
  public filtro: string;
  public id: number;
  public idModulo: number;
  public idRelatorioReferencia: number;
  public layoutEspecifico: string;
  public nome: string;
  public ordem: string;
  public principal: string;
  public relatorioModulo: string;
  public sistema: string;
  public tipoRelatorioModulo: string;
  public usaFiltro: boolean = true;
  public usaFotoEmpresa: string;
  public versao: number;
  public servico: string;
  public quantidadeRegistroExigeFiltro: number;
}
