import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: '<tr>[r1126endereco]</tr>',
  templateUrl: './r1126endereco.component.html',
})
export class R1126EnderecoComponent {
  @Input() parceiro: any;
  public bibDialogo = bibDialogo;
  public rotuloBairro: string = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 2).apelido;
  public rotuloCidade: string = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 1).apelido;
}
