<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.movimentacoesDistorcidas }}</h1>
<div **ngIf="movimentacaoDistorcidas.length > 0" class="criterios row">{{ empresaAlertaDesconto ? bibDialogo.descontoAcimaDe + ' ' + movimentacaoDistorcidas[0].descontoAcimaDe + '% | ' : null }} {{ empresaAlertaPrazo ? bibDialogo.prazoAcimaDaMedia + ' ' + movimentacaoDistorcidas[0].mediaDiaPrazo + ' dias | ' : null }} {{ bibDialogo.lucroMenorIgual + ' ' + movimentacaoDistorcidas[0].lucro }}</div>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
    <th class="r-0-5">{{ bibDialogo.vend }}</th>
    <th class="r">{{ bibDialogo.cliente }}</th>
    <th class="r-1 text-right">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorTabela }}</th>
    <th class="r-1 text-right">{{ bibDialogo.negociacaoPrazoDias }}</th>
    <th class="r-1 text-right">{{ bibDialogo.valorAVista }}</th>
    <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>
    <th class="r-1 text-right">{{ bibDialogo.lucro }}</th>
    <th class="r-1 text-right">{{ bibDialogo.percentualSimbolo }}</th> 
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoDistorcida of movimentacaoDistorcidas">
      <tr>
        <td>{{ movimentacaoDistorcida.loja }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.numero }}</td>
        <td>{{ movimentacaoDistorcida.vendedor }}</td>
        <td class="limitar">{{ movimentacaoDistorcida.cliente }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.custoGerencial | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.valorTotal | monetario }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.valorUnitarioTabela | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.negociacaoPrazo }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.valorMinimo | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ (movimentacaoDistorcida.descontoPercentual | monetario) + '%' }}</td>
        <td class="text-right">{{ movimentacaoDistorcida.lucroValor | monetario }}</td>
        <td class="text-right">{{ (movimentacaoDistorcida.lucroPercentual | monetario) + '%' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="movimentacaoDistorcidas.length > 0">
    <tr>
      <td colspan="4"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoDistorcidas[0].valorTotalCustoGerencial | monetario }}</strong>
      </td>      
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoDistorcidas[0].valorTotalMovimentacao | monetario }}</strong>
      </td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoDistorcidas[0].valorTotalTabela | monetario }}</strong>
      </td>   
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoDistorcidas[0].valorTotalMinimo | monetario }}</strong>
      </td>
      <td colspan="1"></td>
      <td class="text-right">
        <strong class="border-top border-dark">{{ movimentacaoDistorcidas[0].valorTotalLucro | monetario }}</strong>
      </td>
    </tr>
  </tfoot>
</table>
