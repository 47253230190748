<div class="row">
  <titulo class="col-sm" [titulo]="bibDialogo.pesquisaRapida" [imagem]="bibImagem.pesquisa"></titulo>
</div>
<div class="row form-group naoImprimir">
  <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="listaCampoPesquisa" (alteracao)="setCampoPesquisa($event.id)" [obrigatorio]="true"></lista>
  <texto [desabilitado]="campoPesquisa == bibDialogo.semAcesso || campoPesquisa == bibDialogo.comAcesso ? true : false" class="col-sm" [campo]="filtro" (alteracao)="alterarValorFiltro($event)" [descricao]="bibDialogo.digiteNumeroNomeRelatorio"></texto>
</div>
<table class="table table-responsive-sm">
  <thead>
    <tr>
      <th class="l-80">{{ bibDialogo.relatorio }}</th>
      <th class="l-10">{{ bibDialogo.permitir }}</th>
      <th class="l-10">{{ bibDialogo.favorito }}</th>
    </tr>
  </thead>
</table>
<ng-container class="responsiva" *ngFor="let relatorioGrupoUsuario of relatorios | filtrarRelatorioUsuario: { filtro: filtro, especifico: campoPesquisa }; let i = index">
  <div class="col-sm-12" *ngIf="i == 0 ? true : relatorios[i].relatorioModulo != relatorios[i - 1].relatorioModulo">
    <titulo [titulo]="relatorioGrupoUsuario.relatorioModulo" [compacto]="false"></titulo>
  </div>
  <table class="table table-responsive-sm mb-0">
    <tbody>
      <tr>
        <td class="l-80 limitar">
          <div class="list-group-item-figure">
            <div class="tile tile-circle" [ngClass]="'bg-' + relatorioGrupoUsuario.tipoRelatorioModulo">{{ relatorioGrupoUsuario.idRelatorio }}</div>
            <span [ngClass]="ehModoClaro ? 'cor' : ''" class="m-1">{{ ' - ' + relatorioGrupoUsuario.relatorio }}</span>
          </div>
        </td>
        <td class="l-10">
          <icone [icone]="relatorioGrupoUsuario.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="relatorioGrupoUsuario.acessar = relatorioGrupoUsuario.acessar == 'S' ? 'N' : 'S'" [cor]="relatorioGrupoUsuario.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="relatorioGrupoUsuario.acessar == 'S' ? bibDialogo.removerPermissao : bibDialogo.permitir"></icone>
        </td>
        <td class="l-10">
          <icone [icone]="bibIcone.estrela" [clique]="true" (iconeEmt)="relatorioGrupoUsuario.favorito = relatorioGrupoUsuario.favorito == 'S' ? 'N' : 'S'" [cor]="relatorioGrupoUsuario.favorito == 'S' ? bibPropriedade.icone.amarelo : bibPropriedade.icone.cinza" [mensagem]="relatorioGrupoUsuario.favorito == 'S' ? bibDialogo.removerFavorito : bibDialogo.favoritar"></icone>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
