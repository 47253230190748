export class ProdutoPisCofins {
  public aliquotaPisEntrada: number = 0;
  public aliquotaPisSaida: number = 0;
  public aliquotaCofinsEntrada: number = 0;
  public aliquotaCofinsSaida: number = 0;
  public idCstPisEntrada: number;
  public idCstPisSaida: number;
  public idCstCofinsEntrada: number;
  public idCstCofinsSaida: number;
  public cstPisEntrada: string;
  public cstPisSaida: string;
  public cstCofinsEntrada: string;
  public cstCofinsSaida: string;
  public enquadramento: number;
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idProduto: number;
  public produto: string;
  public tipo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public excluido: boolean = false;
  public exigeAliquotaPisEntrada: string;
  public exigeAliquotaPisSaida: string;
  public exigeAliquotaCofinsEntrada: string;
  public exigeAliquotaCofinsSaida: string;
}
