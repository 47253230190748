import { Component } from '@angular/core';
import { Grupo } from 'src/app/modelo/grupo';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import filtros from './filtro';
import { Filtro } from 'src/app/modelo/filtro';

@Component({
  templateUrl: './grupoImposto.component.html',
})
export class GrupoImpostoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public grupos: Grupo[] = [];

  listar(criterios: Criterio[] = [], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.grupo, true).subscribe((res) => {
      this.grupos = this.plainToClass(Grupo, res) as any;
    });   
  }
}
