<td class="p-0" colspan="5">
  <table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r-4-5">{{ bibDialogo.logradouro }}</th>
        <th class="r">{{ bibDialogo.nome }}</th>
        <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
        <th class="r-2">{{ rotuloBairro }}</th>
        <th class="r-1">{{ rotuloCidade }}</th>
        <th class="r-2">{{ bibDialogo.cep }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiroDestino of parceiro.parceiroDestinos">
        <tr>
          <td>{{ parceiroDestino.tipo + ' ' + parceiroDestino.endereco }}</td>
          <td>{{ parceiroDestino.nome }}</td>
          <td class="text-right">{{ parceiroDestino.numero }}</td>
          <td>{{ parceiroDestino.bairro }}</td>
          <td>{{ parceiroDestino.cidade }}</td>
          <td>{{ parceiroDestino.cep }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</td>
