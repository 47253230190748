import { Type } from 'class-transformer';

export class MdfePercurso {
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public id: number;
  public idEstado: number;
  public idMdfe: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;

  public estado: string;
}
