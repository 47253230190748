<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique clique"></th>
        <th class="l-30 clique" (click)="listar(add('usuario'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.usuario }}</th>
        <th class="l-15 clique" (click)="listar(add('dataHoraAbertura'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataHoraAbertura }}</th>
        <th class="l-15 clique" (click)="listar(add('dataHoraFechamento'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataHoraFechamento }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let caixa of caixas; let i = index">
        <tr [ngClass]="{ tachado: caixa.ativo != 'S' }">
          <td>         
            <btnAdicional *ngIf="caixa.dataHoraFechamento != null" [icone]="bibIcone.cadeadoFechado" (btnAdicional)="abrirCaixa(caixa)" [ajuda]="bibDialogo.abrir"></btnAdicional>
          </td>
          <td class="limitar">
            <a href="#" (click)="ir(caixa.id, i, caixas)">{{ caixa.usuario }}</a>
          </td>
          <td>{{ caixa.dataHoraAbertura | data: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ caixa.dataHoraFechamento | data: 'dd/MM/yyyy HH:mm' }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(caixa.id, i, caixas)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirCaixa(caixa.id, caixa.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
