<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="parametro == 2016 ? filtros2016 : filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(estoques, $event)" (campoGrupoEmt)="agrupar(estoques, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th *ngIf="usaAgrupaProduto == false" [ngClass]="parametro == 2016 ? 'p-29' : 'r-0-5'" class="clique" (click)="ordenar(estoques, 'loja')">{{ bibDialogo.loja }}</th>
      <th class="r clique" (click)="ordenar(estoques, 'produto')">{{ bibDialogo.produto }}</th>
      <th [ngClass]="parametro == 2016 ? 'p-38' : 'r-1'" class="clique" (click)="ordenar(estoques, 'unidade')">{{ parametro == 2016 ? bibDialogo.unidadeAbreviacao : bibDialogo.unidade }}</th>
      <th *ngIf="usaGrupo && parametro != 2016 && !inventario" class="r-1-5 clique" (click)="ordenar(estoques, 'grupo')">{{ bibDialogo.grupo }}</th>
      <th *ngIf="usaFabricante && parametro != 2016 && !inventario" class="r-1-5 clique" (click)="ordenar(estoques, 'fabricante')">{{ bibDialogo.fabricante }}</th>
      <th *ngIf="usaCst && parametro == 2016 && !inventario" class="p-38 clique" (click)="ordenar(estoques, 'cstIcms')">{{ bibDialogo.cst }}</th>
      <th *ngIf="usaAgrupaProduto == false && usaLote && parametro != 2016 && !inventario" class="r-1-5 clique" (click)="ordenar(estoques, 'lote')">{{ bibDialogo.lote }}</th>
      <th *ngIf="usaLocal && parametro != 2016 && !inventario" class="r-1-5 clique" (click)="ordenar(estoques, 'local')">{{ bibDialogo.local }}</th>  
      <th *ngIf="usaQuantidadeFisica && parametro != 2016 && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeFisica')">{{ bibDialogo.quantidadeFisica }}</th>    
      <th *ngIf="usaQuantidadeDisponivel && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeDisponivel')">{{ bibDialogo.quantidadeDisponivel }}</th>
      <th *ngIf="usaQuantidadeTotal && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeTotal')">{{ bibDialogo.quantidadeTotal }}<ajuda [ajuda]="bibDialogo.ajudaQuantidadeTotal"></ajuda></th>
      <th *ngIf="usaCustoReposicao && parametro == 2016 && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'custoReposicao')">{{ bibNomenclatura.escrever(bibNomenclatura.custoReposicao) }}<ajuda [ajuda]="bibDialogo.unitario"></ajuda></th>
      <th *ngIf="usaCustoReposicao && parametro == 2016 && !inventario" class="r-1 text-right clique">{{ bibDialogo.abreviacaoTotal + ' ' + bibNomenclatura.escrever(bibNomenclatura.custoReposicao) }}</th>
      <th *ngIf="usaCustoGerencial && parametro == 2016 && !inventario" class="c-1-9 text-right clique" (click)="ordenar(estoques, 'custoGerencial')">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}<ajuda [ajuda]="bibDialogo.unitario"></ajuda></th>
      <th *ngIf="usaCustoGerencial && parametro == 2016 && !inventario" class="c-1-9 text-right clique">{{ bibDialogo.abreviacaoTotal + ' ' + bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>      
      <td *ngIf="usaMargemReal && parametro == 2016 && !inventario" [ngClass]="parametro == 2016 ? 'p-46-5' : 'r-1'" class="text-right clique negrito" (click)="ordenar(estoques, 'margemReal')">
        {{ bibDialogo.margem }} <br />
        {{ bibDialogo.real }}
        <ajuda class="naoImprimir" [ajuda]="bibDialogo.margemLucroAtual + ' ' + bibNomenclatura.escrever(bibNomenclatura.custoGerencial).toLowerCase() + '\n' + this.util.substituir(bibDialogo.margemLucroCalculo, [bibNomenclatura.escrever(bibNomenclatura.custoGerencial).toLowerCase(), bibNomenclatura.escrever(bibNomenclatura.custoGerencial).toLowerCase()])"></ajuda>
      </td>
      <td *ngIf="usaPrecoVenda && parametro == 2016 && !inventario" class="r-1 text-right clique negrito" (click)="ordenar(estoques, 'precoVenda')">
        {{ bibDialogo.preco }} <br />
        {{ bibDialogo.venda }}
      </td>
      <th *ngIf="usaQuantidadePendente && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadePendente')">{{ bibDialogo.quantidadePendente }}</th>
      <th *ngIf="usaDiferencaPendente && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeDisponivel - quantidadePendente')">{{ bibDialogo.quantidadeDisponivelxPendente + ' ' }}<ajuda [ajuda]="bibDialogo.quantidadeDisponivelxPendenteDescricao"></ajuda></th>
      <th *ngIf="usaQuantidadeComprada && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeComprada')">{{ bibDialogo.quantidadeComprada }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.todasLojas"></ajuda></th>
      <th *ngIf="usaQuantidadeNfe || inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeNfe')">{{ bibDialogo.quantidadeNfe }}</th>
      <th *ngIf="usaCustoNfe || inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'custoNfe')">
        {{ bibDialogo.custo }} <br />
        {{ bibDialogo.nfe }}<ajuda [ajuda]="bibDialogo.unitario"></ajuda>
      </th>
      <th *ngIf="inventario" class="r-1 text-right clique">
        {{ bibDialogo.total }} <br />
        {{ bibDialogo.nfe }}        
      </th>      
      <th *ngIf="usaQuantidadeDisponivelxNfe && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeNfe - quantidadeDisponivel')">{{ bibDialogo.quantidadeDisponivelxNfe + ' ' }}<ajuda [ajuda]="bibDialogo.quantidadeDisponivelxNfeDescricao"></ajuda></th>
      <th *ngIf="usaVendaUltimosMeses && parametro == 2016 && !inventario" class="r-1 text-right">
        {{ bibDialogo.venda }} <br />
        {{ '3' + ' ' + bibDialogo.mes }}
        <ajuda class="naoImprimir" [ajuda]="bibDialogo.vendaUltimosMeses"></ajuda>
      </th>
      <th *ngIf="usaQuantidadeMinima && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeMinimaTotal')">{{ bibDialogo.estoqueMinimoAbreviacao + ' ' + bibDialogo.total }}<ajuda [ajuda]="bibDialogo.estoqueMinimoEmTodasLojas"></ajuda></th>
      <th *ngIf="usaQuantidadeAComprar && !inventario" class="r-1 text-right clique" (click)="ordenar(estoques, 'quantidadeAComprar')">{{ bibDialogo.quantidadeAComprar + ' ' }}<ajuda [ajuda]="bibDialogo.estoqueMaximoxQuantidadeDisponivel"></ajuda></th>
      <th *ngIf="usaSugestaoCompra" class="r-1 text-right clique" (click)="ordenar(estoques, 'sugestaoCompra')">{{ bibDialogo.sugestao + ' de ' + bibDialogo.compra }}<ajuda [ajuda]="bibDialogo.ajudaSugestaoCompra"></ajuda></th>
      <th *ngIf="usaQuantidadeEmBranco && !inventario" class="r-1 text-right">{{ bibDialogo.quantidadeEmBranco }}</th>
      <th class="r-1" *ngIf="parametro != 2016 && usaEntrega && !inventario"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let estoque of usaAgrupaProduto ? estoquesAgrupadoProduto : estoques; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td *ngIf="usaAgrupaProduto == false">{{ estoque.loja }}</td>
          <td [ngClass]="parametro == 2016 ? 'fonte-7pt' : ''">{{ estoque.numero + ' - ' + estoque.produto }}</td>
          <td [ngClass]="parametro == 2016 ? 'fonte-7pt' : ''">{{ estoque.unidade }}</td>
          <td *ngIf="usaGrupo && !inventario">{{ estoque.grupo }}</td>                    
          <td *ngIf="usaFabricante && !inventario">{{ estoque.fabricante }}</td>
          <td *ngIf="usaCst && parametro == 2016 && !inventario">{{ estoque.cstIcms }}</td>
          <td *ngIf="usaAgrupaProduto == false && usaLote && parametro != 2016 && !inventario"><ajuda [ajuda]="estoque.lote" class="naoImprimir"></ajuda>{{ estoque.lote }}</td>
          <td *ngIf="usaLocal && parametro != 2016 && !inventario">{{ estoque.local }}</td>   
          <td *ngIf="usaQuantidadeFisica && parametro != 2016 && !inventario" class="text-right">{{ estoque.quantidadeFisica | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaQuantidadeDisponivel && !inventario" class="text-right">{{ estoque.quantidadeDisponivel | monetario: this.empresaRegraCasasDecimaisEntrada }}<ajuda *ngIf="estoque.quantidadeDisponivel > -0.009 && estoque.quantidadeDisponivel < 0.001" [ajuda]="estoque.quantidadeDisponivel"></ajuda></td>
          <td *ngIf="usaQuantidadeTotal && !inventario" class="text-right">{{ estoque.quantidadeTotal | monetario: this.empresaRegraCasasDecimaisEntrada }}<ajuda *ngIf="estoque.quantidadeTotal > -0.009 && estoque.quantidadeTotal < 0.001" [ajuda]="estoque.quantidadeTotal"></ajuda></td>
          <td *ngIf="usaCustoReposicao && parametro == 2016 && !inventario" class="text-right">{{ estoque.custoReposicao | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaCustoReposicao && parametro == 2016 && !inventario" class="text-right">{{ estoque.custoReposicao * estoque.quantidadeDisponivel | monetario }}</td>          
          <td *ngIf="usaCustoGerencial && parametro == 2016 && !inventario" class="text-right">{{ estoque.custoGerencial | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaCustoGerencial && parametro == 2016 && !inventario" class="text-right">{{ estoque.custoGerencial * estoque.quantidadeDisponivel | monetario }}</td>          
          <td *ngIf="usaMargemReal && parametro == 2016 && !inventario" class="text-right">{{ estoque.margemReal | monetario }}</td>
          <td *ngIf="usaPrecoVenda && parametro == 2016 && !inventario" class="text-right">{{ estoque.precoVenda | monetario: this.empresaRegraCasasDecimais }}</td>
          <td *ngIf="usaQuantidadePendente && !inventario" class="text-right">{{ estoque.quantidadePendente | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaDiferencaPendente && !inventario" [ngClass]="estoque.quantidadeDisponivel - estoque.quantidadePendente < 0 ? 'atraso' : ''" class="text-right">{{ estoque.quantidadeDisponivel - estoque.quantidadePendente | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaQuantidadeComprada && !inventario" class="text-right">{{ estoque.quantidadeComprada | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaQuantidadeNfe || inventario" class="text-right">{{ estoque.quantidadeNfe | monetario: this.empresaRegraCasasDecimaisEntrada }}<ajuda *ngIf="estoque.quantidadeNfe > -0.009 && estoque.quantidadeNfe < 0.001" [ajuda]="estoque.quantidadeNfe"></ajuda></td>
          <td *ngIf="usaCustoNfe || inventario" class="text-right">{{ estoque.custoNfe | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="inventario" class="text-right">{{ estoque.quantidadeNfe * estoque.custoNfe | monetario }}</td>          
          <td *ngIf="usaQuantidadeDisponivelxNfe && !inventario" class="text-right">{{ estoque.quantidadeNfe - estoque.quantidadeDisponivel | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaVendaUltimosMeses && parametro == 2016 && !inventario" class="text-right">{{ estoque.ultimosMeses * -1 | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaQuantidadeMinima && !inventario" class="text-right">{{ estoque.quantidadeMinimaTotal | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaQuantidadeAComprar && !inventario" class="text-right">{{ estoque.quantidadeAComprar | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td *ngIf="usaSugestaoCompra" class="text-right">{{ (estoque.sugestaoCompra >= 0 ? estoque.sugestaoCompra : 0) | monetario: this.empresaRegraCasasDecimaisEntrada }}<ajuda *ngIf="estoque.sugestaoCompra < 0" [ajuda]="bibDialogo.excedente + ': ' + (estoque.sugestaoCompra  * -1 | monetario)" [icone]="bibIcone.mais"></ajuda></td>
          <td *ngIf="usaQuantidadeEmBranco && !inventario"></td>
          <td class="naoImprimir" *ngIf="parametro != 2016 && usaEntrega && estoque.quantidadePendente > 0 && !inventario"><btnAdicional [icone]="estoque.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(estoque)"></btnAdicional></td>
        </tr>
        <tr r1002itens *ngIf="parametro != 2016 && usaEntrega && estoque.quantidadePendente > 0 && estoque.expandido" [empresaRegraCasasDecimaisEntrada]="empresaRegraCasasDecimaisEntrada" [estoquePendencias]="estoque.estoquePendencias"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="estoques.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
  <div *ngIf="estoques.length > 0 && parametro == 2016">
    <tr class="sem-borda">
      <td [colSpan]="10" class="r">
        <strong>{{ bibDialogo.totalizadores }}</strong>
      </td>
    </tr>
    <tr *ngIf="(usaCustoReposicao || usaCustoGerencial) && parametro == 2016 && !inventario">
      <td [colSpan]="10" class="r">
        <strong>{{ bibDialogo.quantidadeDisponivel + ' x ' + bibNomenclatura.escrever(bibNomenclatura.custoReposicao) + ': ' }}</strong> {{ totalCustoReposicao | monetario }}
      </td>
    </tr>
    <tr *ngIf="(usaCustoReposicao || usaCustoGerencial) && parametro == 2016 && !inventario">
      <td [colSpan]="10" class="r">
        <strong>{{ bibDialogo.quantidadeDisponivel + ' x ' + bibNomenclatura.escrever(bibNomenclatura.custoGerencial) + ': ' }}</strong> {{ totalCustoGerencial | monetario }}
      </td>
    </tr>
    <tr *ngIf="(usaCustoReposicao || usaCustoGerencial || usaCustoNfe && !inventario) || inventario">
      <td [colSpan]="10" class="r">
        <strong>{{ bibDialogo.quantidadeNfe + ' x ' + bibDialogo.custoNfe + ': ' }}</strong> {{ totalCustoMedio | monetario }}
      </td>
    </tr>
  </div>  
</relatorioLayout>
