<ng-container>
  <td class="p-0" colSpan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-3">{{ bibDialogo.pis + ' - ' + bibDialogo.cstEntrada }}</th>
          <th class="r-3">{{ bibDialogo.pis + ' - ' + bibDialogo.aliquotaEntrada }}</th>
          <th class="r-3">{{ bibDialogo.pis + ' - ' + bibDialogo.cstSaida }}</th>
          <th class="r-3">{{ bibDialogo.pis + ' - ' + bibDialogo.aliquotaSaida }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ produto.pisEntrada }}</td>
          <td>{{ produto.pisAliquotaEntrada }}</td>
          <td>{{ produto.pisSaida }}</td>
          <td>{{ produto.pisAliquotaSaida }}</td>
        </tr>
      </tbody>
    </table>
  </td>
</ng-container>
