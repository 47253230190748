export class UsuarioLoja {
  public abreviacao: string;
  public acessar: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idLojaValor: number;
  public idUsuario: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
}
