<layout>
  <div class="row">
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.numero" [campo]="movimentacao.numero"> </apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.data" [campo]="movimentacao.data | data"> </apresentacao>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.loja" [campo]="movimentacao.abreviacao"> </apresentacao>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.valorTotal" [campo]="movimentacao.valorTotal | monetario"> </apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.parceiro" [campo]="movimentacao.parceiro"> </apresentacao>
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.responsavel" [campo]="movimentacao.colaborador"> </apresentacao>
  </div>
  <div class="row">
    <lista *ngIf="!apresentaDescricaoTexto" class="col-sm-12" [rotulo]="bibDialogo.descricao" [campo]="movimentacao.movimentacaoCancelamento?.motivo" (alteracao)="setMotivo($event.id)" [lista]="motivos" [obrigatorio]="true" [desabilitado]="bloqueado" [icone]="bibIcone.editar" (btnAdicional)="apresentaDescricaoTexto = true"></lista>
    <texto *ngIf="apresentaDescricaoTexto" class="col-sm-12" [rotulo]="bibDialogo.descricao" [campo]="movimentacao.movimentacaoCancelamento?.motivo" (alteracao)="setMotivo($event)" [obrigatorio]="true" [desabilitado]="bloqueado" [icone]="bibIcone.editar" (btnAdicional)="apresentaDescricaoTexto = false"></texto>
  </div>
</layout>
<div class="row align-items-center">
  <div class="col-sm">
    <botao (botaoEmt)="persistirCancelamento(movimentacao.id)" [fundo]="true" [compacto]="compacto" [legenda]="bibDialogo.cancelar" [tipo]="bibPropriedade.botao.sucesso" [desabilitado]="bloqueado || !temPermissaoInserir"></botao>
  </div>
</div>
<bloqueado [bloqueado]="bloqueado || temPermissaoInserir == false"></bloqueado>
