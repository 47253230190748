import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { ReceituarioEletronico } from 'src/app/modelo/receituarioEletronico';

export class Receituario {
  public alterado: boolean = false;
  public aplicacao: string;
  public areaHa: number;
  public art: string;
  public caldaAte: number;
  public caldaDe: number;
  public caldaUnidade: string;
  public cultura: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public dose: number;
  public doseAte: number;
  public doseDe: number;
  public doseUnidade: string;
  public epocaAplicacao: string;
  public estagioCultura: string;
  public grauToxicologica: string;
  public id: number;
  public idAplicacao: number;
  public idColaborador: number;
  public idCultura: number;
  public idEmpresa: number;
  public idEquipamento: number;
  public idMovimentacao: number;
  public idMovimentacaoPro: number;
  public idPraga: number;
  public idProduto: number;
  public idRecomendacao: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public intervaloSeguranca: string;
  public localAplicacao: string;
  public modalidadeAplicacao: string;
  public movimentacaoProduto: MovimentacaoProduto = new MovimentacaoProduto();
  public numero: number = 0;
  public numeroAplicacao: number = 1;
  public observacao: string;
  public praga: string;
  public status: string;
  public mascaraInicial: string;
  public mascaraFinal: string;

  public doseFator: number;
}
