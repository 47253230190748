import { Component } from '@angular/core';
import { RelatorioComponent } from '../relatorio.component';
import { Criterio } from 'src/app/modelo/criterio';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r1.component.html',
  styleUrls: ['./r1.component.css'],
})
export class R1Component extends RelatorioComponent {
  public filtros = filtros;
  public produtoEtiquetas: any[] = [];
  public produtos: any[];
  public quantidadeEtiqueta: number = 1;

  listarRelatorio(criterios: Criterio[]): void {
    this.quantidadeEtiqueta = criterios.find((criterio) => criterio.nome == 'QUANTIDADE') ? criterios.find((criterio) => criterio.nome == 'QUANTIDADE').valor : 1;
    super.listar(criterios).subscribe(() => {
      this.produtos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Produto').lista;
      this.clonarEtiquetas();
      this.ehImprimirRelatorio();
    });
  }

  clonarEtiquetas(): void {
    let contador: number = 1;
    this.produtoEtiquetas = [];
    while (this.quantidadeEtiqueta >= contador) {
      this.produtos.forEach((produto) => {
        this.produtoEtiquetas.push(produto);
      });
      contador++;
    }
    this.produtoEtiquetas.sort((a, b) => (a['id'] == b['id'] ? 0 : a['id'] < b['id'] ? 1 : -1));
  }
}
