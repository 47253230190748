<layout [titulo]="classificacaoFiscal.nome" [id]="classificacaoFiscal.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'descricao'" [rotulo]="bibDialogo.descricao" [campo]="classificacaoFiscal.nome" (alteracao)="classificacaoFiscal.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <lista class="col-sm" [id]="'enquadramento'" [rotulo]="bibDialogo.enquadramento" [campo]="classificacaoFiscal.enquadramento" [lista]="listaEnquadramento" (alteracao)="setEnquadramento($event.id)" [obrigatorio]="true"></lista>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="classificacaoFiscal.ativo" (alteracao)="classificacaoFiscal.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm-9" [id]="'cst'" [rotulo]="bibDialogo.cst + ' ' + bibDialogo.icms" [campo]="classificacaoFiscal.idCstIcms" [lista]="classificacaoFiscal.enquadramento == 1 ? cstIcmsSimplesNacionais : cstIcmsNaoSimplesNacionais" (alteracao)="classificacaoFiscal.idCstIcms = $event.id" [obrigatorio]="true" [desabilitado]="!classificacaoFiscal.enquadramento"></lista>
  </div>
  <div *ngIf="utilSessao.sistema == 'aGrow-w'" class="row">
    <lista class="col-sm-9" [id]="'cst'" [rotulo]="bibDialogo.cst + ' ' + bibDialogo.icms + ' ' + bibDialogo.contribuinte.toLowerCase() + ' ' + bibDialogo.isento.toLowerCase()" [campo]="classificacaoFiscal.idCstIcmsSemInscricao" [lista]="classificacaoFiscal.enquadramento == 1 ? cstIcmsSimplesNacionaisTributadoIntegralmente : cstIcmsNaoSimplesNacionaisTributadoIntegralmente" (alteracao)="classificacaoFiscal.idCstIcmsSemInscricao = $event.id" [desabilitado]="!classificacaoFiscal.enquadramento" [ajuda]="bibDialogo.cstContribuindoIsento" [ajudaDireita]="true"></lista>
  </div>  
  <div class="row">
    <decimal class="col-sm-3" [id]="'baseCalculo'" [rotulo]="bibDialogo.baseCalculo" [campo]="classificacaoFiscal.base" (alteracao)="classificacaoFiscal.base = $event" [sufixo]="'%'" [obrigatorio]="true" [numeroCasasDecimais]="2" [valorMaximo]="100"></decimal>
    <div *ngIf="mostraCodigoBeneficioFiscal" [ngClass]="compacto ? 'row' : ''">
      <rotulo class="ml-3" [ngClass]="compacto ? 'col' : ''" [rotulo]="bibDialogo.codigoBeneficioFiscal" [ajuda]="bibDialogo.ajudaCodigoBeneficioFiscal" [ajudaDireita]="true"></rotulo>
      <texto [id]="'codigoBeneficioFiscal'" [ngClass]="compacto ? 'col' : ''" [campo]="classificacaoFiscal.codigoBeneficioFiscal" (alteracao)="classificacaoFiscal.codigoBeneficioFiscal = $event" [maximoCaracteres]="8"></texto>
    </div>
  </div>
  <titulo [titulo]="bibDialogo.calculosAutomaticos" [imagem]="bibImagem.checkbox"></titulo>
  <div class="row">
    <check [id]="'icmsSt'" [rotulo]="bibDialogo.icmsSt" [campo]="classificacaoFiscal.icmsSt" (alteracao)="classificacaoFiscal.icmsSt = $event" [compacto]="'true'"></check>
    <!-- <check [id]="'icmsDesonerado'" [rotulo]="bibDialogo.icmsDesonerado" [campo]="classificacaoFiscal.icmsDesonerado" (alteracao)="classificacaoFiscal.icmsDesonerado = $event" [compacto]="'true'"></check> -->
  </div>
  <titulo [titulo]="bibDialogo.descricaoNfe" [imagem]="bibImagem.complementoProduto"></titulo>
  <descricao [id]="'descricaoNfe'" [campo]="classificacaoFiscal.descricaoNfe" (alteracao)="classificacaoFiscal.descricaoNfe = $event" [maximoCaracteres]="200"></descricao>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirClassificacaoFiscal()" [desabilitado]="bloquearPermissao(classificacaoFiscal.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirClassificacaoFiscal(true)" [desabilitado]="bloquearPermissao(classificacaoFiscal.id)"></botao>
<auditoria *ngIf="classificacaoFiscal.id" [idUsuarioInclusao]="classificacaoFiscal.idUsuarioInclusao" [dataHoraInclusao]="classificacaoFiscal.dataHoraInclusao" [idUsuarioAlteracao]="classificacaoFiscal.idUsuarioAlteracao" [dataHoraAlteracao]="classificacaoFiscal.dataHoraAlteracao"></auditoria>
