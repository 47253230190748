<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(comissoes, $event)" (campoGrupoEmt)="agruparInterno(comissoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r-0-5">{{ bibDialogo.loja }}</th>
        <th class="r-0-5 text-right">{{ 'Nº' }}<ajuda [ajuda]="bibDialogo.numero + ' ' + bibDialogo.movimentacao.toLowerCase()"></ajuda></th>
        <th class="r-1">{{ bibDialogo.vencimento }}<ajuda [ajuda]="bibDialogo.dataVencimento"></ajuda></th>
        <th *ngIf="rotuloAgrupaAtual != bibDialogo.vendedor" class="r-1">{{ bibDialogo.vendedor }}</th>
        <th *ngIf="rotuloAgrupaAtual != bibDialogo.cliente" class="r">{{ bibDialogo.cliente }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorParcela}}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorBaixa }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ajudaValorBaixa"></ajuda></th>
        <th class="r-1-5 text-right">{{ bibDialogo.valorComissao }}</th>   
        <th class="r-1 text-right">{{ bibDialogo.valorPago }}<ajuda [ajuda]="bibDialogo.ajudaComissaoPaga"></ajuda></th>   
        <th class="r-1 text-right">{{ bibDialogo.valorAguardando }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ajudaComissaoAguardando"></ajuda></th> 
        <th class="r-1 text-right">{{ bibDialogo.valorTotalAPagar }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ajudaComissaoValorTotalAPagar"></ajuda></th> 
        <th class="r-1">{{ bibDialogo.dataBaixa }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ultimoRecebimento + ' (' + bibDialogo.liberacao + ')' "></ajuda></th>
        <th class="r-1">{{ bibDialogo.dataBaixaComissao }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.ultimoPagamento"></ajuda></th>
        <th class="r-1"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let comissao of comissoes; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ comissao.abreviacao }}</td>
          <td class="text-right">
            <a href="#" (click)="abrirModalMovimentacao(comissao.idMovimentacao)">{{ comissao.numero }}</a>
          </td>     
          <td>{{ comissao.dataVencimento | data }}</td>
          <td *ngIf="rotuloAgrupaAtual != bibDialogo.vendedor"><ajuda [ajuda]="comissao.vendedor"></ajuda>{{ comissao.abreviacaoVendedor }}</td>
          <td *ngIf="rotuloAgrupaAtual != bibDialogo.cliente" ><ajuda class="naoImprimir" [ajuda]="comissao.numeroCliente + ' - ' + comissao.cliente"></ajuda>{{ comissao.numeroCliente + ' - ' + comissao.cliente }}</td>
          <td class="text-right">{{ comissao.valorParcela | monetario }}</td>
          <td class="text-right">{{ comissao.valorBaixa | monetario }}</td>
          <td class="text-right">{{ comissao.valor | monetario }}<atencao [tipo]="bibPropriedade.atencao.informativo" [atencao]="(((comissao.valor / comissao.valorParcelaMovimentacao) * 100) | monetario) + '%'" [ajuda]="util.substituir(bibDialogo.mensagemRelatorio1127, [(((comissao.valor / comissao.valorParcelaMovimentacao) * 100) | monetario), comissao.valor | monetario, comissao.valorParcelaMovimentacao | monetario])"></atencao></td>
          <td class="text-right">{{ comissao.valorPago | monetario }}</td>
          <td class="text-right">{{ comissao.valorDisponivel | monetario }}</td>
          <td class="text-right" [ngClass]="comissao.valorRestante == 0 ? 'tachado' : ''">{{ comissao.valorRestante | monetario }}</td>
          <td>{{ comissao.dataBaixa | data }}</td>
          <td>{{ comissao.dataBaixaComissao | data }}</td>
          <td class="text-right"><div class="naoImprimir"><btnAdicional [icone]="comissao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.listarProdutos(comissao)"></btnAdicional></div></td>
        </tr>
        <tr *ngIf="comissao.expandido">
          <td colspan="12"><r1127produtos [comissao]="comissao"></r1127produtos></td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="comissoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>