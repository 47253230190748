import { Component, Input, ViewChildren } from '@angular/core';
import { Formulario } from '../formulario';

@Component({
  selector: 'radio',
  templateUrl: './radio.component.html',
})
export class RadioComponent extends Formulario {
  @ViewChildren('input') input: any;
  @Input() nome: string;
  @Input() valor: string;
  @Input() marcado: boolean = false;

  ngOnInit(): void {
    this.marcado = this.campoAtual == this.valor;
  }

  alterar(): void {
    this.emitirAlteracao(this.valor);
  }

  converterCampo(): string {
    return String(this.campo);
  }
}
