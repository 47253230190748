<div class="row align-items-center">
  <titulo class="col-sm" [titulo]="bibDialogo.restricoes" [imagem]="bibImagem.cadeado" [icone]="bibIcone.mais" (iconeEmt)="adicionarRestricao()" ></titulo>
</div>
<div class="row" *ngIf="produtoFormulado.produtoFormuladoRestricoes.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.restricao.toLowerCase() }} <strong class="clique" (click)="adicionarRestricao()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<div class="responsiva" *ngIf="produtoFormulado.produtoFormuladoRestricoes.length > 0">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-45">{{ bibDialogo.cultura }}<ajuda [ajuda]="bibDialogo.ajudaCultura" [ajudaDireita]="true"></ajuda></th>        
        <th class="l-20">{{ bibDialogo.estado }}</th>        
        <th class="l-30">{{ bibDialogo.equipamento }}</th>         
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let produtoFormuladoRestricao of produtoFormulado.produtoFormuladoRestricoes; let i = index">
        <td class="limitar"><lista *ngIf="!produtoFormuladoRestricao.id" [campo]="produtoFormuladoRestricao.cultura" [lista]="culturas" (alteracao)="produtoFormuladoRestricao.cultura = $event.id" [icone]="bibIcone.atualizar" (btnAdicional)="listarProdutoFormuladoDose()" [foco]="true"></lista> {{ produtoFormuladoRestricao.id ? produtoFormuladoRestricao.cultura : '' }}</td>
        <td><lista *ngIf="!produtoFormuladoRestricao.id" [campo]="produtoFormuladoRestricao.estado" [lista]="estados" (alteracao)="produtoFormuladoRestricao.idEstado = $event.id"></lista> {{ produtoFormuladoRestricao.id ? produtoFormuladoRestricao.estado ? produtoFormuladoRestricao.estado : (produtoFormuladoRestricao.idEstado ? nomearEstado(produtoFormuladoRestricao.idEstado) : null) : null }}</td>
        <td><lista *ngIf="!produtoFormuladoRestricao.id" [campo]="produtoFormuladoRestricao.equipamento" (alteracao)="produtoFormuladoRestricao.idEquipamento =  $event.id" [lista]="equipamentos" [icone]="bibIcone.atualizar" (btnAdicional)="listarEquipamento()"></lista> {{ produtoFormuladoRestricao.id ? produtoFormuladoRestricao.equipamento ? produtoFormuladoRestricao.equipamento : (produtoFormuladoRestricao.idEquipamento ? nomearEquipamento(produtoFormuladoRestricao.idEquipamento) : null) : null }}</td>
        <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, produtoFormulado.produtoFormuladoRestricoes, produtoFormulado.idsProdutoFormuladoRestricaoExcluir, produtoFormuladoRestricao.id)"></btnAdicional></td>
      </tr>
    </tbody>
  </table>
</div>