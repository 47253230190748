import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r2029.component.html',
})
export class R2029Component extends RelatorioComponent {
  public filtros = filtros;
  public patrimoniais: any[];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.patrimoniais = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Patrimonial').lista;
      this.ehImprimirRelatorio();
    });
  }
}
