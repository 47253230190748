import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { id: 'valorApartirDe', rotulo: bibDialogo.valorDe, nome: 'VALOR_DE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
  { id: 'valorApartirAte', rotulo: bibDialogo.valorAte, nome: 'VALOR_ATE', coluna: 6, tipo: bibPropriedade.filtro.decimal },
  { id: 'contaOrigem', rotulo: bibDialogo.contaOrigem, nome: 'ID_CONTA_ORIGEM', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('CONTA_BANCARIA_E_CAIXA', 'S')] },
  { id: 'contaDestino', rotulo: bibDialogo.contaDestino, nome: 'ID_CONTA_DESTINO', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('CONTA_BANCARIA_E_CAIXA', 'S')] },
]);
export default filtros;
