import { Component } from '@angular/core';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { Criterio } from 'src/app/modelo/criterio';
import { GrupoContabil } from 'src/app/modelo/grupoContabil';
import { GrupoContaContabil } from 'src/app/modelo/grupoContaContabil';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './grupoContabilFrm.component.html',
})
export class GrupoContabilFrmComponent extends PaginaComponent {
  public componente: any;
  public grupoContabil: GrupoContabil = new GrupoContabil();
  public idGrupoContaContabeisExcluir: number[] = [];
  public ehMinhaModal: boolean = false;

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const contaContabeis: any[] = identificacao.conteudo;
        contaContabeis.forEach((contaContabil) => {
          if (this.grupoContabil.grupoContaContabeis.filter((grupoContaContabil) => grupoContaContabil.idConta == contaContabil.id).length == 0) {
            let grupoContaContabil: GrupoContaContabil = new GrupoContaContabil();
            grupoContaContabil.idConta = contaContabil.id;
            grupoContaContabil.nomeConta = contaContabil.nome;
            this.grupoContabil.grupoContaContabeis.push(grupoContaContabil);
          }
        });
      }
    });
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.grupoContabil = this.plainToClass(GrupoContabil, this.ehAtualizacao(this.grupoContabil));
    if (this.grupoContabil.id) {
      this.listarGrupoContabil(this.grupoContabil.id);
    }
  }

  listarGrupoContabil(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.grupoContabil).subscribe((res) => {
      this.grupoContabil = this.plainToClass(GrupoContabil, res[0]) as any;
      this.listarGrupoContaContabil(this.grupoContabil.id);
    });
  }

  listarGrupoContaContabil(idGrupoContabil: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_CONTABIL', idGrupoContabil)), this.bibServico.grupoContaContabil).subscribe((res) => {
      this.grupoContabil.grupoContaContabeis = this.plainToClass(GrupoContaContabil, res) as any;
    });
  }

  abrirModal(): void {
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.contaContabil));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.grupoContabil.grupoContaContabeis));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.contaContabil));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }

  persistirGrupoContabil(): void {
    if (this.ehValido()) {
      let transporte = new Transporte(this.grupoContabil);
      transporte.adicionar(this.idGrupoContaContabeisExcluir);
      this.persistir(transporte, this.bibServico.grupoContabil, null).subscribe(() => {
        this.definirId([this.grupoContabil], this.bibClasse.grupoContabil, true);
        this.definirId(this.grupoContabil.grupoContaContabeis, this.bibClasse.grupoContaContabeil);
        this.idGrupoContaContabeisExcluir = [];
      });
    }
  }

  ehValido(): boolean {
    if (this.grupoContabil.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  excluirGrupoContabilTotal(indice: number): void {
    if (confirm(this.bibDialogo.certezaExcluirConfiguracao)) {
      if (this.grupoContabil.grupoContaContabeis[indice].id) {
        this.idGrupoContaContabeisExcluir.push(this.grupoContabil.grupoContaContabeis[indice].id);
      }
      this.grupoContabil.grupoContaContabeis.splice(indice, 1);
    }
  }
}
