<layout [titulo]="praga.nome" [id]="praga.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <busca class="col-sm" [rotulo]="bibDialogo.nomeCientifico" [campo]="praga.nomeCientifico" (alteracao)="setNomeCientifico($event)" [servico]="bibServico.pragaCompendio" [obrigatorio]="true" [foco]="true"></busca>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.praga" [campo]="praga.nome" (alteracao)="praga.nome = $event" [desabilitado]="true"></texto>
  </div>  
  <div *ngIf="integracaoEidaf" class="row">
    <texto class="col-sm-4" [id]="'integracao'" [rotulo]="bibDialogo.integracao" [campo]="praga.eidaf" (alteracao)="praga.eidaf = $event" [ajuda]="bibDialogo.ajudaIntegracaoEidaf" [maximoCaracteres]="20" [ajudaDireita]="true"></texto>
  </div>
  <div *ngIf="integracaoAgroDefesa" class="row">
    <texto class="col-sm-4" [id]="'integracao'" [rotulo]="bibDialogo.integracao" [campo]="praga.agroDefesa" (alteracao)="praga.agroDefesa = $event" [normal]="true" [ajuda]="bibDialogo.ajudaIntegracaoAgroDefesa" [maximoCaracteres]="20" [ajudaDireita]="true"></texto>
  </div>    
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirPraga()" [desabilitado]="bloquearPermissao(praga.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirPraga(true)" [desabilitado]="bloquearPermissao(praga.id)"></botao>
<auditoria *ngIf="praga.id" [idUsuarioInclusao]="praga.idUsuarioInclusao" [dataHoraInclusao]="praga.dataHoraInclusao" [idUsuarioAlteracao]="praga.idUsuarioAlteracao" [dataHoraAlteracao]="praga.dataHoraAlteracao"></auditoria>
