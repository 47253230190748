<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(movimentacaoProdutos, $event)" (campoGrupoEmt)="agrupar(movimentacaoProdutos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-3">{{ bibDialogo.fornecedor }}</th>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
      <th class="r-1 text-right">{{ bibNomenclatura.escrever(bibNomenclatura.custoReposicao) }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valorUnitario }}</th>
      <th class="r-1 text-right">{{ bibDialogo.variacao }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ movimentacaoProduto.loja }}</td>
          <td>{{ movimentacaoProduto.movimentacaoData | data }}</td>
          <td class="text-right">{{ movimentacaoProduto.movimentacaoNumero }}</td>
          <td>{{ movimentacaoProduto.parceiro }}</td>
          <td>{{ movimentacaoProduto.produtoNumero + ' - ' + movimentacaoProduto.produto }}</td>
          <td>{{ movimentacaoProduto.unidade }}</td>
          <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ movimentacaoProduto.custoReposicao | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorUnitarioFinal | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right" [ngClass]="movimentacaoProduto.variacao != 0 ? (movimentacaoProduto.variacao > 0 ? 'bg-vermelho text-white' : 'bg-verde text-white') : ''">{{ ((movimentacaoProduto.variacao < 0 ? movimentacaoProduto.variacao * -1 : movimentacaoProduto.variacao) | monetario) + '% ' }}<i [ngClass]="movimentacaoProduto.variacao != 0 ? (movimentacaoProduto.variacao > 0 ? bibIcone.setaCompletaCima : bibIcone.setaCompletaBaixo) : ''"></i></td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacaoProdutos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
