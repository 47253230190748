<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let objeto of objetos; let i = index">
        <tr [ngClass]="{ tachado: objeto.ativo != 'S' }">
          <td>
            <a *ngIf="objeto.id != 0 && (objeto.pai == 'N' && objeto.nivel != 1)" href="#" (click)="irPara(objeto.id)"> {{ objeto.nome.split(' ').join('&nbsp;') }}</a>
            <span *ngIf="objeto.id == 0">{{ objeto.nome.split(' ').join('&nbsp;') }}</span>
            <a *ngIf="objeto.id != 0 && (objeto.pai == 'S' || objeto.nivel == 1)" href="#" (click)="irPara(objeto.id)"><strong>{{ objeto.nome.split(' ').join('&nbsp;') }}</strong></a>
          </td>
          <td>
            <btnAdicional [id]="'editar'" *ngIf="objeto.id != 0 && objeto.nome != bibDialogo.grupo.toUpperCase() && objeto.nome != bibDialogo.categoria.toUpperCase()" [icone]="bibIcone.editar" (btnAdicional)="irPara(objeto.id)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [id]="'excluir'" *ngIf="objeto.id != 0 && objeto.nome != bibDialogo.grupo.toUpperCase() && objeto.nome != bibDialogo.categoria.toUpperCase()" [icone]="bibIcone.lixo" (btnAdicional)="excluirObjeto(objeto.id, objeto.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
