export class Icms51 {
    public orig: string;
    public CST: string;
    public modBC: string;
    public pRedBC: string;
    public vBC: string;
    public pICMS: string;
    public vICMS: string;
    public vICMSOp: string;
    public pDif: string;
    public vICMSDif: string;
    public vBCFCP: string;
    public pFCP: string;
    public vFCP: string;
    public pFCPDif: string;
    public vFCPDif: string;
    public vFCPEfet: string;
}		