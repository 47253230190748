import { plainToClass } from 'class-transformer';
import bibServico from 'src/app/biblioteca/bibServico';
import { ComunicacaoService } from '../servico/comunicacao.service';
import { Categoria } from './categoria';
import { Criterio } from './criterio';
import { Polegada } from './polegada';
import { Produto } from './produto';
import { ProdutoEstoqueMinimoMaximo } from './produtoEstoqueMinimoMaximo';
import { ProdutoHistoricoAnual } from './produtoHistoricoAnual';
import { ProdutoMedia } from './produtoMedia';
import { Transporte } from './transporte';
import { Unidade } from './unidade';

export class ProdutoControlador {
  private bibServico = bibServico;
  public excluirEstoqueMinimoMaximo: Number[] = [];
  public obrigatorio: Boolean;
  public produto: Produto = new Produto();
  public unidades: Unidade[] = [];
  public polegadas: Polegada[] = [];
  public categorias: Categoria[] = [];
  public ehClone: boolean = false;
  public ehNcmAnvisa: boolean = false;
  public ehAnvisaIsencao: boolean = false;
  public empresaRealizaIntegracaoFornecedores: string = 'N';
  public totalHistorico: number = 0;
  public produtoMedias: ProdutoMedia[] = [];
  public visualizaHistorico: boolean = false;
  public produtoHistoricoMesAnual: any[] = [];
  public produtoHistoricoAnual: ProdutoHistoricoAnual[] = [];
  public dataAtual: Date;
  public meses: string[] = new Array('Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez');

  constructor(public comunicacaoService: ComunicacaoService) { }

  excluir(posicao: number, lista: any[], excluir: Number[]): void {
    if (lista[posicao].id) {
      excluir.push(lista[posicao].id);
    }
    lista.splice(posicao, 1);
  }

  setEhValidoCodigoAnvisa(): boolean {
    if (this.produto.anvisaCodigo == null || /^[0-9]+$/.test(this.produto.anvisaCodigo) || this.produto.anvisaCodigo.toUpperCase() == 'ISENTO') {
      return true;
    }
    return false;
  }

  ehPreenchido(propriedade: any): boolean {
    if (this.produto.produtoEstoqueMinimoMaximos.length > 0) {
      let produtoEstoqueMinimoMaximosEmBranco: ProdutoEstoqueMinimoMaximo[] = this.produto.produtoEstoqueMinimoMaximos.filter((produtoEstoqueMinimoMaximos) => produtoEstoqueMinimoMaximos[propriedade] == null);
      if (produtoEstoqueMinimoMaximosEmBranco.length > 0) {
        return false;
      } else {
        let produtoEstoqueMinimoMaximosEmBranco: ProdutoEstoqueMinimoMaximo[] = this.produto.produtoEstoqueMinimoMaximos.filter((produtoEstoqueMinimoMaximos) => produtoEstoqueMinimoMaximos[propriedade] == '');
        if (produtoEstoqueMinimoMaximosEmBranco.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  ehMedicamento(ncmNumero: string): void {
    this.ehNcmAnvisa = false;
    if (ncmNumero.substring(0, 4).trim() == '3001' || ncmNumero.substring(0, 4).trim() == '3002' || ncmNumero.substring(0, 4).trim() == '3003' || ncmNumero.substring(0, 4).trim() == '3004' || ncmNumero.substring(0, 4).trim() == '3005' || ncmNumero.substring(0, 4).trim() == '3006') {
      this.ehNcmAnvisa = true;
    }
  }

  ehMedicamentoIsento(anvisaCodigo: string): void {
    this.ehAnvisaIsencao = false;
    if (anvisaCodigo == 'ISENTO') {
      this.ehAnvisaIsencao = true;
    }
  }

  ehTipoObrigatorio(): void {
    this.obrigatorio = this.produto.entrada != 'S' && this.produto.saida != 'S' && this.produto.interna != 'S';
  }

  totalizarQuantidadeMinimoMaximoPercentual(): void {
    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima = 0;
    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima = 0;
    this.produto.produtoEstoqueMinimoMaximoTotalPercentual = 0;

    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeDisponivel = 0;
    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeReservadaCarteira = 0;
    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeNfe = 0;
    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeTransferida = 0;
    this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeComprada = 0;

    this.produto.produtoEstoqueMinimoMaximos.forEach((produtoEstoqueMinimoMaximo) => {
      if (produtoEstoqueMinimoMaximo.quantidadeMaxima) {
        this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima = this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima + produtoEstoqueMinimoMaximo.quantidadeMaxima;
      }
      if (produtoEstoqueMinimoMaximo.quantidadeMinima) {
        this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima = this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima + produtoEstoqueMinimoMaximo.quantidadeMinima;
      }
      if (produtoEstoqueMinimoMaximo.percentual) {
        this.produto.produtoEstoqueMinimoMaximoTotalPercentual = this.produto.produtoEstoqueMinimoMaximoTotalPercentual + produtoEstoqueMinimoMaximo.percentual;
      }
      this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeDisponivel += produtoEstoqueMinimoMaximo.quantidadeDisponivel;
      this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeReservadaCarteira += produtoEstoqueMinimoMaximo.quantidadeReservada + produtoEstoqueMinimoMaximo.quantidadeCarteira;
      this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeNfe += produtoEstoqueMinimoMaximo.quantidadeNfe;
      this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeTransferida += produtoEstoqueMinimoMaximo.quantidadeTransferida;
      this.produto.produtoEstoqueMinimoMaximoTotalQuantidadeComprada += produtoEstoqueMinimoMaximo.quantidadeComprada;
    });
  }

  listarUnidade(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.unidade).subscribe((res) => {
      this.unidades = plainToClass(Unidade, res) as any;
    });
  }

  listarPolegadas(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.polegada).subscribe((res) => {
      this.polegadas = plainToClass(Polegada, res) as any;
    });
  }

  listarCategorias(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.categoria).subscribe((res) => {
      this.categorias = plainToClass(Categoria, res) as any;
    });
  }

  apresentarHistorico(visualizaHistorico: boolean): void {
    this.totalHistorico = 0;
    this.visualizaHistorico = visualizaHistorico;
    if (this.visualizaHistorico) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoMedia).subscribe((res) => {
        this.produtoMedias = plainToClass(ProdutoMedia, res) as any;
        for (const produtoMedia of this.produtoMedias) {
          this.totalHistorico += produtoMedia.mediaMensalVendaAnoAnterior + produtoMedia.mediaMensalVendaTrimestreAnterior + produtoMedia.mediaMensalVendaTrimestrePosterior + produtoMedia.vendaMesAnterior + produtoMedia.vendaMesAtual;
        }
        this.listarProdutoHistoricoAnual();
      });
    }
  }

  listarProdutoHistoricoAnual(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoHistoricoAnual).subscribe((res) => {
      this.produtoHistoricoAnual = plainToClass(ProdutoHistoricoAnual, res) as any;
      this.gerarListaHistoricoMesAnual();
    });
  }

  gerarListaHistoricoMesAnual(): void {
    this.dataAtual = new Date();
    let dataHistorico: Date[] = [];
    this.produtoHistoricoMesAnual = [];
    for (var i = 0; i < 12; i++) {
      let mes: number = this.dataAtual.getMonth();
      this.dataAtual.setMonth(mes - 1);
      if (this.dataAtual.getMonth() != mes - 1 && (this.dataAtual.getMonth() != 11 || (mes == 11 && this.dataAtual.getDate() == 1))) this.dataAtual.setDate(0);
      dataHistorico[i] = new Date(this.dataAtual);
    }
    dataHistorico.forEach((data, i) => {
      let produtoHistorico: ProdutoHistoricoAnual = this.produtoHistoricoAnual.find((produtoHistorico) => produtoHistorico.id == data.getMonth() + 1);
      this.produtoHistoricoMesAnual.push({ id: i, nome: produtoHistorico ? this.meses[produtoHistorico.id - 1] + '/' + produtoHistorico.ano.toString().substring(produtoHistorico.ano.toString().length - 2) + ': ' + produtoHistorico.quantidade : this.meses[data.getMonth()] + '/' + data.getFullYear().toString().substring(data.getFullYear().toString().length - 2) + ': ' + 0, mesAno: produtoHistorico ? this.meses[produtoHistorico.id - 1] + '/' + produtoHistorico.ano : this.meses[data.getMonth()] + '/' + data.getFullYear(), quantidade: produtoHistorico ? produtoHistorico.quantidade : 0 });
    });
    this.produtoHistoricoMesAnual.sort((a, b) => (a['id'] == b['id'] ? 0 : a['id'] < b['id'] ? 1 : -1));
  }

}
