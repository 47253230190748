import { Component } from '@angular/core';
import { CategoriaRoteiroContabil } from 'src/app/modelo/categoriaRoteiroContabil';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Integracao } from 'src/app/modelo/integracao';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';
import { Loja } from 'src/app/modelo/loja';
import { Menu } from 'src/app/modelo/menu';
import { Permissao } from 'src/app/modelo/permissao';
import { Relatorio } from 'src/app/modelo/relatorio';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { UsuarioCategoriaRoteiro } from 'src/app/modelo/usuarioCategoriaRoteiro';
import { UsuarioConta } from 'src/app/modelo/usuarioConta';
import { UsuarioLoja } from 'src/app/modelo/usuarioLoja';
import { UsuarioMenu } from 'src/app/modelo/usuarioMenu';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UtilCriptografia } from 'src/app/utilitario/utilCriptografia';

@Component({
  templateUrl: './usuarioFrm.component.html',
})
export class UsuarioFrmComponent extends PaginaComponent {
  private permissoes: Permissao[] = this.utilSessao.getPermissoes();
  private utilCriptografia: UtilCriptografia = new UtilCriptografia();
  public dashboardUsuarios: RelatorioUsuario[] = [];
  public dominio: string;
  public focoIdSenha: number;
  public integracaoUsuarios: IntegracaoUsuario[] = [];
  public ocultaConta: boolean = true;
  public ocultaIntegracao: boolean = true;
  public ocultaLoja: boolean = true;
  public ocultaRoteiroContabil: boolean = true;
  public parametro: string;
  public permiteEdicao: boolean = false;
  public relatorioDashboardUsuarios: RelatorioUsuario[] = [];
  public relatorioUsuarios: RelatorioUsuario[] = [];
  public usuario: Usuario = new Usuario();
  public usuarioCategoriaRoteiros: UsuarioCategoriaRoteiro[] = [];
  public usuarioContas: UsuarioConta[] = [];
  public usuarioLojas: UsuarioLoja[] = [];
  public usuarioMenus: UsuarioMenu[] = [];

  ngOnInit(): void {
    this.dominio = this.utilSessao.getDominio('@');
    this.receberVerificacaoOcultarAbas();
    this.ehAlteracao();
  }

  receberVerificacaoOcultarAbas(): void {
    this.utilNotificacao.ocultaRoteiroContabil.subscribe((res) => {
      this.ocultaRoteiroContabil = res;
    });
    this.utilNotificacao.ocultaConta.subscribe((res) => {
      this.ocultaConta = res;
    });
  }

  ehAlteracao(): void {
    this.usuario = this.plainToClass(Usuario, this.ehAtualizacao(this.usuario));
    this.listarLoja();
    this.listarMenu();
    this.listarConta();
    this.listarCategoriaRoteiroContabil();
    this.listarRelatorio();
    this.listarIntegracao();
    this.possuiPermissao();
    if (this.usuario.id) {
      this.listarUsuario();
    }
    this.activatedRoute.params.subscribe((params) => {
      this.parametro = params.parametro;
      this.iniciarMinhaConta();
    });
  }

  listarLoja(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.loja).subscribe((res) => {
      const lojas: Loja[] = this.plainToClass(Loja, res) as any;
      if (this.usuario.id) {
        this.listarUsuarioLoja(lojas);
      } else {
        this.completarUsuarioLoja(lojas);
      }
    });
  }

  listarUsuarioLoja(lojas: Loja[]): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_USUARIO', this.usuario.id), new Criterio('ATIVO', 'S')]), this.bibServico.usuarioLoja).subscribe((res) => {
      this.usuarioLojas = this.plainToClass(UsuarioLoja, res) as any;
      this.usuarioLojas.forEach((usuarioLoja) => (usuarioLoja.acessar = 'S'));
      this.completarUsuarioLoja(lojas);
    });
  }

  completarUsuarioLoja(lojas: Loja[]): void {
    const numeroLojas: number = lojas.length;
    this.ocultaLoja = numeroLojas > 1 ? false : true;
    for (const loja of lojas) {
      if (this.usuarioLojas.find((usuarioLoja) => usuarioLoja.idLoja == loja.id) == undefined) {
        let usuarioLoja: UsuarioLoja = new UsuarioLoja();
        usuarioLoja.idLoja = loja.id;
        usuarioLoja.nome = loja.nomeFantasia;
        usuarioLoja.abreviacao = loja.abreviacao;
        if (numeroLojas > 1) {
          usuarioLoja.acessar = 'N';
        } else {
          usuarioLoja.acessar = 'S';
        }
        this.usuarioLojas.push(usuarioLoja);
      }
    }
    this.usuarioLojas.sort((a, b) => (a['nome'] == b['nome'] ? 0 : a['nome'] > b['nome'] ? 1 : -1));
  }

  listarMenu(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('SISTEMA', this.utilSessao.sistema), new Criterio('MENU_EMPRESA_ATIVO', 'S')]), this.bibServico.menu).subscribe((res) => {
      const menus: Menu[] = this.plainToClass(Menu, res) as any;
      if (this.usuario.id) {
        this.listarUsuarioMenu(menus);
      } else {
        this.completarUsuarioMenu(menus);
      }
    });
  }

  listarUsuarioMenu(menus: Menu[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_USUARIO', this.usuario.id)), this.bibServico.usuarioMenu).subscribe((res) => {
      this.usuarioMenus = this.plainToClass(UsuarioMenu, res) as any;
      this.usuarioMenus.forEach((usuario) => {
        if (usuario.moduloEmpresaPai || usuario.moduloEmpresa) {
          usuario.modulo = usuario.moduloEmpresaPai ? usuario.moduloEmpresaPai : usuario.moduloEmpresa;
        } else {
          usuario.modulo = usuario.moduloPai ? usuario.moduloPai : usuario.modulo;
        }
      });
      this.completarUsuarioMenu(menus);
    });
  }

  completarUsuarioMenu(menus: Menu[]): void {
    for (const menu of menus) {
      if (this.usuarioMenus.filter((usuarioMenu) => usuarioMenu.idMenu == menu.id).length == 0) {
        let usuarioMenu: UsuarioMenu = new UsuarioMenu();
        usuarioMenu.acessar = 'N';
        usuarioMenu.idMenu = menu.id;
        usuarioMenu.menu = menu.nome;
        usuarioMenu.menuAcessar = menu.acessar;
        usuarioMenu.menuInserir = menu.inserir;
        usuarioMenu.menuEditar = menu.editar;
        usuarioMenu.menuExcluir = menu.excluir;
        usuarioMenu.menuInativar = menu.inativar;
        usuarioMenu.descricao = menu.descricao;
        if (menu.moduloEmpresaPai || menu.moduloEmpresa) {
          usuarioMenu.modulo = menu.moduloEmpresaPai ? menu.moduloEmpresaPai : menu.moduloEmpresa;
        } else {
          usuarioMenu.modulo = menu.moduloPai ? menu.moduloPai : menu.modulo;
        }
        usuarioMenu.moduloPai = menu.moduloPai;
        this.usuarioMenus.push(usuarioMenu);
      }
    }
    this.conferirFavoritos();
    this.verificarPermissaoConta();
    this.verificarPermissaoRoteiroContabil();
    this.usuarioMenus.sort((a, b) => (a['modulo'] == b['modulo'] ? (a['menu'] == b['menu'] ? 0 : a['menu'] > b['menu'] ? 1 : -1) : a['modulo'] > b['modulo'] ? 1 : -1));
  }

  verificarPermissaoConta(): void {
    const idsMenusUtilizamConta = [93, 104, 166, 110, 49, 33, 29, 28, 41, 42, 115, 159, 158, 39, 30, 114, 130];
    const usuarioMenuPermissao = this.usuarioMenus.filter((usuarioMenu) => usuarioMenu.acessar == 'S');
    const permissaoConta = usuarioMenuPermissao.filter((permissao) => idsMenusUtilizamConta.includes(permissao.idMenu));
    if (permissaoConta.length > 0 && this.menuConta.ativo == 'S') {
      this.ocultaConta = false;
    } else {
      this.ocultaConta = true;
    }
  }

  listarConta(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.conta).subscribe((res) => {
      const contas: Conta[] = this.plainToClass(Conta, res) as any;
      if (this.usuario.id) {
        this.listarUsuarioConta(contas);
      } else {
        this.completarUsuarioConta(contas);
      }
    });
  }

  listarUsuarioConta(contas: Conta[]): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_USUARIO', this.usuario.id), new Criterio('ATIVO', 'S')]), this.bibServico.usuarioConta).subscribe((res) => {
      this.usuarioContas = this.plainToClass(UsuarioConta, res) as any;
      this.completarUsuarioConta(contas);
    });
  }

  completarUsuarioConta(contas: Conta[]): void {
    for (const conta of contas) {
      if (this.usuarioContas.filter((usuarioConta) => usuarioConta.idConta == conta.id).length == 0) {
        let usuarioConta: UsuarioConta = new UsuarioConta();
        usuarioConta.idConta = conta.id;
        usuarioConta.conta = conta.nome;
        usuarioConta.acessar = 'N';
        this.usuarioContas.push(usuarioConta);
      }
    }
    this.usuarioContas.sort((a, b) => (a['conta'] == b['conta'] ? 0 : a['conta'] > b['conta'] ? 1 : -1));
  }

  verificarPermissaoRoteiroContabil(): void {
    const idsMenusUtilizamRoteiroContabil = [167, 157, 133];
    const usuarioPermissaoRoteiroContabil = this.usuarioMenus.filter((usuarioMenu) => usuarioMenu.acessar == 'S');
    const permissaoRoteiroContabil = usuarioPermissaoRoteiroContabil.filter((usuarioMenu) => idsMenusUtilizamRoteiroContabil.includes(usuarioMenu.idMenu));
    if (permissaoRoteiroContabil.length > 0 && this.menuRoteiroContabil.ativo == 'S') {
      this.ocultaRoteiroContabil = false;
    } else {
      this.ocultaRoteiroContabil = true;
    }
  }

  listarCategoriaRoteiroContabil(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.categoriaRoteiroContabil).subscribe((res) => {
      const categoriaRoteiroContabeis: CategoriaRoteiroContabil[] = this.plainToClass(CategoriaRoteiroContabil, res) as any;
      if (this.usuario.id) {
        this.listarUsuarioCategoriaRoteiro(categoriaRoteiroContabeis);
      } else {
        this.completarUsuarioCategoriaRoteiro(categoriaRoteiroContabeis);
      }
    });
  }

  listarUsuarioCategoriaRoteiro(categoriaRoteiroContabeis: CategoriaRoteiroContabil[]): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_USUARIO', this.usuario.id)]), this.bibServico.usuarioCategoriaRoteiro).subscribe((res) => {
      this.usuarioCategoriaRoteiros = this.plainToClass(UsuarioCategoriaRoteiro, res) as any;
      this.completarUsuarioCategoriaRoteiro(categoriaRoteiroContabeis);
    });
  }

  completarUsuarioCategoriaRoteiro(categoriaRoteiroContabeis: CategoriaRoteiroContabil[]): void {
    for (const categoriaRoteiroContabil of categoriaRoteiroContabeis) {
      if (this.usuarioCategoriaRoteiros.filter((usuarioCategoriaRateio) => usuarioCategoriaRateio.idCategoriaRoteiroContabil == categoriaRoteiroContabil.id).length == 0) {
        let usuarioCategoriaRoteiro: UsuarioCategoriaRoteiro = new UsuarioCategoriaRoteiro();
        usuarioCategoriaRoteiro.idCategoriaRoteiroContabil = categoriaRoteiroContabil.id;
        usuarioCategoriaRoteiro.categoriaRoteiroContabil = categoriaRoteiroContabil.nome;
        usuarioCategoriaRoteiro.acessar = 'N';
        this.usuarioCategoriaRoteiros.push(usuarioCategoriaRoteiro);
      }
    }
    this.usuarioCategoriaRoteiros.sort((a, b) => (a['categoriaRoteiroContabil'] == b['categoriaRoteiroContabil'] ? 0 : a['categoriaRoteiroContabil'] > b['categoriaRoteiroContabil'] ? 1 : -1));
  }

  iniciarMinhaConta(): void {
    if (this.parametro == 'MinhaConta') {
      this.loginEmitter.permitir(this.permissoes);
      this.usuario = this.utilSessao.getUsuario();
      this.loginEmitter.logar(this.usuario, true);
      this.possuiPermissao();

      this.titleService.setTitle(this.bibDialogo.minhaConta);
    }
  }

  possuiPermissao(): void {
    this.permissao = this.permissoes.find((permissao) => permissao.idMenu == 69);
    if (this.permissao) {
      this.permiteEdicao = this.permissao.editar == 'S';
    }
  }

  listarRelatorio(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('SISTEMA', this.utilSessao.sistema)), this.bibServico.relatorio).subscribe((res) => {
      const relatorios: Relatorio[] = this.plainToClass(Relatorio, res) as any;
      if (this.usuario.id) {
        this.listarRelatorioUsuario(relatorios);
      } else {
        this.completarRelatorioUsuario(relatorios);
      }
    });
  }

  listarRelatorioUsuario(relatorios: Relatorio[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_USUARIO', this.usuario.id)), this.bibServico.relatorioUsuario).subscribe((res) => {
      this.relatorioDashboardUsuarios = this.plainToClass(RelatorioUsuario, res) as any;
      this.completarRelatorioUsuario(relatorios);
    });
  }

  completarRelatorioUsuario(relatorios: Relatorio[]): void {
    for (const relatorio of relatorios) {
      if (this.relatorioDashboardUsuarios.filter((relatorioUsuario) => relatorioUsuario.idRelatorio == relatorio.id).length == 0) {
        let relatorioUsuario: RelatorioUsuario = new RelatorioUsuario();
        relatorioUsuario.acessar = 'N';
        relatorioUsuario.idRelatorio = relatorio.id;
        relatorioUsuario.relatorio = relatorio.nome;
        relatorioUsuario.dashboard = relatorio.dashboard;
        relatorioUsuario.relatorioModulo = relatorio.relatorioModulo;
        relatorioUsuario.tipoRelatorioModulo = relatorio.tipoRelatorioModulo;
        relatorioUsuario.dashboardInicial = 'N';
        this.relatorioDashboardUsuarios.push(relatorioUsuario);
      }
    }
    this.relatorioDashboardUsuarios = this.relatorioDashboardUsuarios.sort((a, b) => (a['relatorioModulo'] == b['relatorioModulo'] ? (a['idRelatorio'] == b['idRelatorio'] ? 0 : a['idRelatorio'] > b['idRelatorio'] ? 1 : -1) : a['relatorioModulo'] > b['relatorioModulo'] ? 1 : -1));
    this.relatorioUsuarios = this.relatorioDashboardUsuarios.filter((relatorioUsuario) => relatorioUsuario.dashboard == 'N');
    this.dashboardUsuarios = this.relatorioDashboardUsuarios.filter((relatorioUsuario) => relatorioUsuario.dashboard == 'S');
  }

  listarUsuario(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.usuario.id)), this.bibServico.usuario).subscribe((res) => {
      this.usuario = this.plainToClass(Usuario, res[0]) as any;
      this.usuario.senha = this.utilCriptografia.descriptografar(this.usuario.senha);
      this.usuario.historicoFoto = this.usuario.foto;
    });
  }

  conferirFavoritos(): void {
    const permissoes: Permissao[] = this.utilSessao.getPermissoes();
    if (this.usuario.id) {
      this.usuarioMenus.forEach((usuarioMenu) => {
        const listarPermissoes: Permissao[] = permissoes.filter((permissao) => permissao.idMenu == usuarioMenu.idMenu);
        if (listarPermissoes.length > 0) {
          usuarioMenu.favorito = listarPermissoes[0].favorito;
        }
      });
    }
  }

  atualizarColaborador(id: number): void {
    if (id != null && id != 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.usuario).subscribe((res) => {
        this.usuario.idColaborador = (this.plainToClass(Usuario, res[0]) as any).idColaborador;
        this.usuario.colaborador = (this.plainToClass(Usuario, res[0]) as any).colaborador;
      });
    }
  }

  persistirUsuario(): void {
    if (this.ehValido()) {
      this.utilNotificacao.setAlterandoSenha();
      this.configurarUsuarioPersistir();
      const ehInsercao: boolean = this.usuario.id > 0 ? false : true;
      super.persistir(new Transporte(this.usuario), this.bibServico.usuario, null).subscribe((res) => {
        let senhaDescriptografada: boolean = false;
        if (!this.utilSessao.falha) {
          this.definirIds();
          if (this.usuario.id == this.utilSessao.getUsuario().id) {
            this.definirFavoritoMenu();
            this.definirFavoritoRelatorio();
            this.usuario.senha = this.utilCriptografia.descriptografar(this.usuario.senha);
            senhaDescriptografada = true;
          }
          if (ehInsercao) {
            this.atualizarColaborador(this.usuario.id);
          }
        }
        if (!senhaDescriptografada) {
          this.usuario.senha = this.utilCriptografia.descriptografar(this.usuario.senha);
        }
        this.utilSessao.alertaAlteracao = true;
      });
    }
  }

  listarIntegracao(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('SISTEMA', this.utilSessao.sistema)]), this.bibServico.integracao).subscribe((res) => {
      const integracoes: Integracao[] = this.plainToClass(Integracao, res) as any;
      if (this.usuario.id) {
        this.listarIntegracaoUsuario(integracoes);
      } else {
        this.completarIntegracaoUsuario(integracoes);
      }
    });
  }

  listarIntegracaoUsuario(integracoes: Integracao[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_USUARIO', this.usuario.id)), this.bibServico.integracaoUsuario).subscribe((res) => {
      this.integracaoUsuarios = this.plainToClass(IntegracaoUsuario, res) as any;
      this.completarIntegracaoUsuario(integracoes);
    });
  }

  completarIntegracaoUsuario(integracoes: Integracao[]): void {
    if (integracoes.length > 0) {
      this.ocultaIntegracao = false;
    } else {
      this.ocultaIntegracao = true;
    }

    for (const integracao of integracoes) {
      if (this.integracaoUsuarios.filter((integracaoUsuario) => integracaoUsuario.idIntegracao == integracao.id).length == 0) {
        let integracaoUsuario: IntegracaoUsuario = new IntegracaoUsuario();
        integracaoUsuario.idIntegracao = integracao.id;
        integracaoUsuario.integracao = integracao.nome;
        integracaoUsuario.acessar = 'N';
        integracaoUsuario.integracaoCategoria = integracao.integracaoCategoria;
        this.integracaoUsuarios.push(integracaoUsuario);
      }
    }
    this.integracaoUsuarios.sort((a, b) => (a['integracao'] == b['integracao'] ? 0 : a['integracao'] > b['integracao'] ? 1 : -1));
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoNomeUsuario: boolean = this.setEhValidoNomeUsuario();
    const ehValidoEmail: boolean = this.setEhValidoEmail();
    const ehValidoRequisitosSenha: boolean = this.ehValidoRequisitosSenha();
    const ehValidoSenha: boolean = this.ehValidarCaracteresEspeciaisNaoPermitidos();
    const ehValidoOperacional: boolean = this.setEhValidoOperacional();
    return ehValidoObrigatorio && ehValidoNomeUsuario && ehValidoEmail && ehValidoRequisitosSenha && ehValidoSenha && ehValidoOperacional;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.usuario.nome && this.usuario.email && this.usuario.senha && this.usuario.key && ((this.utilSessao.getEmpresa().mobilidade == 'S' && this.usuario.idColaborador) || this.utilSessao.getEmpresa().mobilidade != 'S')) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoNomeUsuario(): boolean {
    const contagemEspaco: number = this.usuario.nome.split('').filter((letra) => letra == ' ').length;
    if (contagemEspaco > 1) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nomeUsuarioUmSobrenome));
      return false;
    }
    return true;
  }

  setEhValidoEmail(): boolean {
    var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.usuario.email.match(validRegex)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.emailUsuario));
      return false;
    }
    return true;
  }

  ehValidoRequisitosSenha(): boolean {
    if (this.usuario.senha.length >= 8 && this.usuario.contemDoisNumeros && this.usuario.contemDuasLetrasMinuscula && this.usuario.contemUmCaracterEspecial && this.usuario.contemUmaLetraMaiuscula) {
      return true;
    }
    if (this.usuario.senha.length < 8) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.senhaAcesso + ': ' + this.bibDialogo.senhaFraca.toLowerCase()));
    }
    if (!this.usuario.contemDoisNumeros || !this.usuario.contemDuasLetrasMinuscula || !this.usuario.contemUmCaracterEspecial || !this.usuario.contemUmaLetraMaiuscula) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.senhaAcessoDeveAtenderOsPreRequisitos));
    }
    return false;
  }

  ehValidarCaracteresEspeciaisNaoPermitidos(): boolean {
    let contemCaracterEspecial: number = 0;
    for (let letra of this.usuario.senha) {
      if (['%', '&', '-', '+', '#', '!', '*', '$', ',', "'", '"'].includes(letra)) {
        contemCaracterEspecial++;
      }
    }
    if (contemCaracterEspecial > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.caracteresInvalidosParaSenha));
      return false;
    }
    return true;
  }

  setEhValidoOperacional(): boolean {
    if (this.usuario.key.length < 6) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.senhaOperacional + ': ' + this.bibDialogo.minimoCaracteresSenhaOperacional.toLowerCase()));
      return false;
    } else {
      return true;
    }
  }

  configurarUsuarioPersistir(): void {
    this.usuario.usuarioLojas = this.usuarioLojas.filter((usuarioLoja) => usuarioLoja.acessar == 'S');
    this.usuario.usuarioMenus = this.usuarioMenus.filter((usuarioMenu) => usuarioMenu.acessar == 'S');
    this.usuario.relatorioUsuarios = this.relatorioDashboardUsuarios.filter((relatorioUsuario) => relatorioUsuario.acessar == 'S');
    this.usuario.usuarioContas = this.usuarioContas.filter((usuarioConta) => usuarioConta.acessar == 'S');
    this.usuario.usuarioIntegracoes = this.integracaoUsuarios.filter((integracaoUsuario) => integracaoUsuario.acessar == 'S');
    this.usuario.usuarioCategoriaRoteiros = this.usuarioCategoriaRoteiros.filter((usuarioCategoriaRoteiro) => usuarioCategoriaRoteiro.acessar == 'S');
    this.usuario.senha = this.utilCriptografia.criptografar(this.usuario.senha);
    if (this.usuario.id == null && this.dominio) {
      if (this.usuario.email.indexOf('@') <= 0) {
        this.usuario.email = this.usuario.email + this.dominio;
      }
    }
  }

  definirIds(): void {
    this.definirId([this.usuario], this.bibClasse.usuario, true);
    this.definirId(this.usuario.usuarioLojas, this.bibClasse.usuarioLoja);
    this.definirId(this.usuario.usuarioMenus, this.bibClasse.usuarioMenu);
    this.definirId(this.usuario.relatorioUsuarios, this.bibClasse.relatorioUsuario);
    this.definirId(this.usuario.usuarioContas, this.bibClasse.usuarioConta);
    this.definirId(this.usuario.usuarioIntegracoes, this.bibClasse.integracaoUsuario);
  }

  definirFavoritoMenu(): void {
    const permissoes = this.utilSessao.getPermissoes();
    this.usuarioMenus.forEach((usuarioMenu) => {
      let permissao = permissoes.filter((permissao) => permissao.idMenu == usuarioMenu.idMenu)[0];
      if (permissao != undefined) permissao.favorito = usuarioMenu.favorito;
    });
    this.utilSessao.setPermissoes(permissoes);
  }

  definirFavoritoRelatorio(): void {
    let relatorios: Relatorio[] = this.utilSessao.getRelatorios();
    relatorios.forEach((relatorio) => {
      this.relatorioUsuarios.forEach((relatorioUsuario) => {
        if (relatorioUsuario.idRelatorio == relatorio.id) {
          relatorio.favorito = relatorioUsuario.favorito;
        }
      });
    });
    this.utilSessao.setRelatorios(relatorios);
  }

  exigirSenha(): void {
    this.focoIdSenha = Math.random();
  }

  conferirSenha(chave: string): void {
    if (chave && chave.length == 6) {
      this.focoIdSenha = null;
      this.ehPersistirUsuario(chave);
    }
  }

  ehPersistirUsuario(chave: string): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.utilSessao.getUsuario().id)), this.bibServico.usuario).subscribe((res) => {
      const usuario: Usuario = this.plainToClass(Usuario, res[0]) as any;
      if (usuario.key == chave) {
        this.persistirUsuario();
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.senhaOperacionalNaoVinculadaAoUsuario, [this.utilSessao.getUsuario().nome])));
      }
    });
  }
}
