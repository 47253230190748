export class ParceiroVeiculo {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idEstado: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public motoristaCpf: string;
  public motoristaNome: string;
  public nome: string;
  public placa: string;
  public renavan: string;
  public rntrc: string;

  public alterado: boolean = false;
  public estado: string;
  public excluido: boolean = false;
  public ehValidoCpf: boolean = true;
}
