<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(produtos, $event)" (campoGrupoEmt)="agrupar(produtos, $event)" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1-5">{{ bibDialogo.referencia }}</th>
      <th class="r-1">{{ bibDialogo.dataUltimaCompraAbreviado }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.valorUltimaCompraAbreviado }}</th>
      <th class="r-1-5 text-right">{{ bibNomenclatura.escrever(bibNomenclatura.custoReposicao) }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.precoVenda }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="produto.lojas.length > 0 && usaSemSugestaoCompra ? 'sem-borda' : ''">
          <td>{{ produto.numero + ' - ' + produto.nome }}</td>
          <td>{{ produto.unidade }}</td>
          <td>{{ produto.referencia }}</td>
          <td>{{ produto.dataUltimaCompra | data }}</td>
          <td class="text-right">{{ produto.valorUltimaCompra | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right">{{ produto.custoReposicao | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
          <td class="text-right">{{ produto.precoVenda | monetario: this.empresaRegraCasasDecimaisEntrada }}</td>
        </tr>
        <tr *ngIf="produto.lojas.length > 0 && usaSemSugestaoCompra" r2003itens [produto]="produto" [empresaRegraCasasDecimais]="empresaRegraCasasDecimais > 4 ? 4 : empresaRegraCasasDecimais" [empresaRegraCasasDecimaisEntrada]="empresaRegraCasasDecimaisEntrada" [tipoSugestao]="tipoSugestao" [quantidadeDia]="quantidadeDia"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="produtos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
