import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Polegada } from 'src/app/modelo/polegada';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './polegadaFrm.component.html',
})
export class PolegadaFrmComponent extends PaginaComponent {
  public polegada: Polegada = new Polegada();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.polegada = this.plainToClass(Polegada, this.ehAtualizacao(this.polegada));
    if (this.polegada.id) {
      this.listarPolegada(this.polegada.id);
    }
  }

  listarPolegada(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.polegada).subscribe((res) => {
      this.polegada = this.plainToClass(Polegada, res[0]) as any;
    });
  }

  persistirPolegada(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.polegada), this.bibServico.polegada, novo ? new Polegada() : null);
    }
  }

  ehValido(): boolean {
    if (this.polegada.nome && this.polegada.ordem >= 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
