<titulo [titulo]="bibDialogo.localDeAcesso" [imagem]="bibImagem.configuracaoTabelaPreco" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.local.toLocaleLowerCase()" [desabilitado]="!temPermissaoEdicao"></titulo>
<div *ngIf="grupoAcessoLocais.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.local.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="grupoAcessoLocais.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l-20 clique" (click)="util.ordenarLista(this.grupoAcessoLocais, 'nome')">{{ bibDialogo.nome }}</th>
      <th class="l-35 clique" (click)="util.ordenarLista(this.grupoAcessoLocais, 'ipDaRede')">{{ bibDialogo.ipDaRede }}</th>
      <th class="l"></th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let grupoAcessoLocal of grupoAcessoLocais; let i = index">
      <tr>
        <td class="text-left">
          <texto *ngIf="!grupoAcessoLocal.id || edicaoGrupoAcessoLocais.includes(grupoAcessoLocal.id)" [campo]="grupoAcessoLocal.nome" (alteracao)="grupoAcessoLocal.nome = $event" [obrigatorio]="true"></texto>
          {{ grupoAcessoLocal.id && edicaoGrupoAcessoLocais.includes(grupoAcessoLocal.id) == false ? grupoAcessoLocal.nome : '' }}
        </td>
        <td class="text-left">
          <texto *ngIf="!grupoAcessoLocal.id || edicaoGrupoAcessoLocais.includes(grupoAcessoLocal.id)" [campo]="grupoAcessoLocal.ip" (alteracao)="grupoAcessoLocal.ip = $event" [ajuda]="bibDialogo.ajudaIp" [obrigatorio]="true"></texto>
          {{ grupoAcessoLocal.id && edicaoGrupoAcessoLocais.includes(grupoAcessoLocal.id) == false ? grupoAcessoLocal.ip : '' }}
        </td>
        <td></td>
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editar(grupoAcessoLocal)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoAcessoLocal(i, grupoAcessoLocal.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
