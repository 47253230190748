import { Directive, HostListener, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[mascara]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MascaraDirective,
      multi: true,
    },
  ],
})
export class MascaraDirective implements ControlValueAccessor {
  onTouched: any;
  onChange: any;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input('mascara') mascara: string;

  constructor(private el: ElementRef) {}

  writeValue(value: any): void {
    if (value) {
      this.el.nativeElement.value = value;
    } else {
      this.el.nativeElement.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {
    let valorInicial = $event.target.value;
    let valor = valorInicial.replace(/\D/g, '');

    var alterouMascara = false;
    var mascaraFixo = '(99)9999-9999';
    var mascaraCelular = '(99)99999-9999';
    if (this.mascara == mascaraFixo) {
      if (valor.length == 11) {
        this.mascara = mascaraCelular;
      }
    } else {
      if (this.mascara == mascaraCelular) {
        if (valor.length < 11) {
          this.mascara = mascaraFixo;
          alterouMascara = true;
        }
      }
    }

    if ($event.keyCode === 8 && alterouMascara == false) {
      this.onChange(valorInicial);
      return;
    }

    let pad = this.mascara.replace(/\D/g, '').replace(/9/g, '_');
    if (valor.length <= pad.length) {
      this.onChange(valorInicial);
    }

    $event.target.value = this.aplicarMascara(valor);
    this.ngModelChange.emit(this.aplicarMascara(valor));
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if ($event.target.value.length === this.mascara.length) {
      return;
    }
    this.onChange('');
    $event.target.value = '';
  }

  aplicarMascara(valor: string): string {
    valor = valor.replace(/\D/g, '');
    let pad = this.mascara.replace(/\D/g, '').replace(/9/g, '_');
    let valorMask = valor + pad.substring(0, pad.length - valor.length);
    let valorMaskPos = 0;

    valor = '';
    for (let i = 0; i < this.mascara.length; i++) {
      if (isNaN(parseInt(this.mascara.charAt(i)))) {
        valor += this.mascara.charAt(i);
      } else {
        valor += valorMask[valorMaskPos++];
      }
    }

    if (valor.indexOf('_') > -1) {
      valor = valor.substr(0, valor.indexOf('_'));
    }
    return valor;
  }
}
