<layout [subTitulo]="creditos.length > 0 ? bibDialogo.parceiro.toUpperCase() + ': ' + creditos[0].parceiro : ''" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-10 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-30 clique" (click)="listar(add('origem'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.origem }}</th>
        <th class="l-35 clique" (click)="listar(add('complemento'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.complemento }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valor }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let credito of creditos; let i = index">
        <tr>
          <td><icone [icone]="bibIcone.circulo" [cor]="credito.valor < 0 ? bibPropriedade.icone.vermelho : bibPropriedade.icone.azul"></icone></td>
          <td>{{ credito.data | data }}</td>
          <td [id]="'origem'">{{ credito.origem == '1' ? (credito.valor > 0 ? bibDialogo.adiantamento : bibDialogo.adiantamentoEstorno) : credito.origem == '2' ? (credito.valor < 0 ? bibDialogo.baixa : bibDialogo.baixaEstorno) : credito.origem == '3' ? (credito.valor > 0 ? bibDialogo.devolucao : bibDialogo.devolucaoAberta) : credito.origem == '4' ? bibDialogo.geradoResgateFinanceiro : '' }}</td>
          <td>{{ credito.complemento }}</td>
          <td class="text-right">{{ credito.valor | monetario }}</td>
          <td>
            <btnAdicional *ngIf="credito.idFinanceiro || credito.idMovimentacao" [icone]="bibIcone.olho" (btnAdicional)="abrirModal(credito)"></btnAdicional>
          </td>
          <td>
            <btnAdicional *ngIf="credito.situacao == 'A'" [icone]="bibIcone.codigoBarra" [ajuda]="bibDialogo.resgatarCredito" [desabilitado]="credito.resgateCredito == 'S' || !temPermissaoEdicao" (btnAdicional)="persistirCredito(credito)"></btnAdicional>
            <btnAdicional *ngIf="!credito.chave" [icone]="bibIcone.marcar" [ajuda]="bibDialogo.exigeAprovacao" (btnAdicional)="gerarAprovacao(credito)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <div class="row">
    <atencao *ngIf="this.creditos.length == 0" [atencao]="atencao" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>    
</layout>
<totalizador *ngIf="creditos.length > 0" [valorTotalizador]="creditos[0].parceiroCredito | monetario" [atencaoTotalizador]="bibDialogo.disponivel + ': ' + (creditos[0].parceiroCredito - (creditoFuturo > creditos[0].parceiroCredito ? 0 : creditoFuturo) | monetario)" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta"></totalizador>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
