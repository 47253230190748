<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-25 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-35 clique" (click)="listar(add('tipo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.tipo }}</th>
        <th class="l-10 clique" (click)="listar(add('endereco'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.endereco }}</th>
        <th class="l-10 clique" (click)="listar(add('contato'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.contato }}</th>
        <th class="l-10 clique" (click)="listar(add('telefone'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.telefone }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr [ngClass]="{ tachado: vinculo.ativo != 'S' }" *ngFor="let vinculo of vinculos; let i = index">
        <td>
          <a *ngIf="vinculo.idEmpresa" href="#" (click)="ir(vinculo.id, i, vinculos)">{{ vinculo.nome }}</a> {{ !vinculo.idEmpresa ? vinculo.nome : '' }}
        </td>
        <td>{{ vinculo.tipo == 'C' ? bibDialogo.clienteColaborador : vinculo.tipo == 'F' ? bibDialogo.fornecedorTransportadora : bibDialogo.todos }}</td>
        <td>{{ vinculo.endereco == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
        <td>{{ vinculo.contato == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
        <td>{{ vinculo.telefone == 'S' ? bibDialogo.sim : bibDialogo.nao }}</td>
        <td *ngIf="!vinculo.idEmpresa">
          <atencao [atencao]="utilSessao.sistema"></atencao>
        </td>
        <td>
          <btnAdicional *ngIf="vinculo.idEmpresa" [icone]="bibIcone.editar" (btnAdicional)="ir(vinculo.id, i, vinculos)"></btnAdicional>
        </td>
        <td>
          <btnAdicional *ngIf="vinculo.idEmpresa" [icone]="bibIcone.lixo" (btnAdicional)="excluirVinculo(vinculo.id, vinculo.nome)"></btnAdicional>
        </td>
      </tr>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
