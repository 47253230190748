import { Component, Input } from '@angular/core';
import { format } from 'date-fns';
import { Criterio } from 'src/app/modelo/criterio';
import { Financeiro } from 'src/app/modelo/financeiro';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'financeiroFrmBai',
  templateUrl: './financeiroFrmBai.component.html',
  styleUrls: ['./financeiroFrmBai.component.css'],
})
export class FinanceiroFrmBaiComponent extends PaginaComponent {
  @Input() excluirFinanceiroBaixas: Number[] = [];
  @Input() financeiro: Financeiro = new Financeiro();
  @Input() tipoTitulos: TipoTitulo[] = [];
  public relatorio1144: boolean = this.utilSessao.getRelatorioUsuarios().find((relatario) => relatario.idRelatorio == 1144) != undefined;
  public valorBaixa: number = 0;
  public valorRestante: number = 0;

  ngOnInit(): void {
    this.utilSessao.setRealizandoBaixa(null);
  }

  irRecibo(financeiroBaixa: FinanceiroBaixa): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID', this.financeiro.id));
    criterios.push(new Criterio('ID_FINANCEIRO_BAIXA', financeiroBaixa.id));
    this.utilSessao.setRelatorioCriterios(criterios);
    this.util.irRelatorio(1144);
  }

  formatarData(data: Date): string {
    return `'${format(new Date(data), 'yyyy-MM-dd')}'`;
  }

  irBaixa(): void {
    const realizandoBaixa: string = this.utilSessao.getRealizandoBaixa();
    if (realizandoBaixa == null) {
      this.utilSessao.setRealizandoBaixa('S');
      this.utilSessao.setIdentificacao(new Identificacao('idFinanceiro', this.financeiro.id));
      this.utilSessao.setIdentificacao(new Identificacao('idParceiro', this.financeiro.idParceiro));
      const url: string = '#/baixa/' + (this.financeiro.tipo == 'R' ? 'RECEITA' : 'DESPESA');
      window.open(url, '_blank');
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.baixaEmAndamento));
    }
  }
}
