import { Component } from '@angular/core';
import { Grupo } from 'src/app/modelo/grupo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Categoria } from 'src/app/modelo/categoria';
import { Natureza } from 'src/app/modelo/natureza';
import { CentroResultado } from 'src/app/modelo/centroResultado';

@Component({
  templateUrl: './genericoHierarquiaFrm.component.html',
})
export class GenericoHierarquiaFrmComponent extends PaginaComponent {
  private classe: any;
  public criterioSemIdZero: Criterio[] = [new Criterio('SEM_ID_ZERO', 'S')];
  public lista: any[] = [];
  public objeto: any;
  public rotuloPai: string;
  public tipoLista: string;

  ngOnInit() {
    switch (this.utilSessao.getIdentificacao('parametro')?.conteudo.toLowerCase()) {
      case this.bibServico.grupo:
        this.classe = Grupo;
        this.objeto = new Grupo();
        this.rotuloPai = this.menuGrupo.apelido;
        break;
      case this.bibServico.categoria:
        this.classe = Categoria;
        this.objeto = new Categoria();
        this.rotuloPai = this.menuCategoria.apelido;
        break;
      case this.bibServico.natureza:
        this.classe = Natureza;
        this.objeto = new Natureza();
        this.rotuloPai = this.menuNatureza.apelido;
        break;
      case 'centro_resultado':
        this.classe = CentroResultado;
        this.objeto = new CentroResultado();
        this.rotuloPai = this.menuCentroDeResultado.apelido;
        this.rotuloPai.toLowerCase();
        break;
    }
    this.lista = [
      { id: 'N', nome: this.rotuloPai.toUpperCase() },
      { id: 'S', nome: this.bibDialogo.sub.toUpperCase() + '-' + this.rotuloPai.toUpperCase() },
    ];
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.objeto = this.plainToClass(this.classe, this.ehAtualizacao(this.objeto));
    if (this.objeto.id) {
      this.listarObjeto(this.objeto.id);
    }
  }

  listarObjeto(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.objeto.servico).subscribe((res) => {
      this.objeto = this.plainToClass(this.classe, res[0]) as any;
      this.tipoLista = this.objeto.getIdPai() != null ? (this.objeto.getIdPai() == 0 ? 'N' : 'S') : null;
    });
  }

  setLista(tipo: string): void {
    this.tipoLista = tipo;
    this.objeto.setIdPai(null);
    if (this.tipoLista == 'N') {
      this.objeto.setIdPai(0);
    }
  }

  persistirObjeto(novo: boolean = false): void {
    if (this.ehValidoObrigatorio() && this.ehvalidoEdicao()) {
      this.objeto.nome = this.objeto.nome.trim();
      super.persistir(new Transporte(this.objeto), this.objeto.servico, novo ? this.selecionarObjetoNovo() : null);
    }
    if (novo == true) {
      this.tipoLista = null;
    }
  }

  selecionarObjetoNovo(): any {
    switch (this.objeto.servico) {
      case this.bibServico.grupo:
        return new Grupo();
      case this.bibServico.natureza:
        return new Natureza();
      case this.bibServico.categoria:
        return new Categoria();
      case this.bibServico.centroResultado:
        return new CentroResultado();
    }
  }

  ehValidoObrigatorio(): boolean {
    if ((this.objeto.nome && this.objeto.getIdPai()) || this.objeto.getIdPai() == 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehvalidoEdicao(): boolean {
    if (this.objeto.id != this.objeto.getIdPai()) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.validarRegistroPai));
    return false;
  }
}
