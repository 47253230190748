import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r2028itens]</tr>',
  templateUrl: './r2028itens.component.html',
})
export class R2028ItensComponent {
  @Input() notas: any[] = [];
  public bibDialogo = bibDialogo;
}
