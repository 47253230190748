<ng-container class="border-bottom border-dark">
  <td class="p-0" colspan="5">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-1">{{ bibDialogo.data }}</th>
          <th class="r">{{ bibDialogo.historico }}</th>
          <th class="r-1 text-right">{{ bibDialogo.debito }}</th>
          <th class="r-1 text-right">{{ bibDialogo.credito }}</th>
          <th class="r-1 text-right">{{ bibDialogo.saldoAtual }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let lancamentoContabil of contaContabil.lancamentoContabeis">
          <tr>
            <td>{{ lancamentoContabil.data | data}} </td>
            <td class="limitar">{{ lancamentoContabil.historico }}</td>
            <td class="text-right">{{ lancamentoContabil.debito | monetario}}</td>
            <td class="text-right">{{ lancamentoContabil.credito | monetario}}</td>
            <td class="text-right">{{ lancamentoContabil.saldoAtual | monetario}}</td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3"></td>
          <td class="text-right font-weight-bold borda-cima">{{contaContabil.debito | monetario}}</td>
          <td class="text-right font-weight-bold borda-cima">{{contaContabil.credito | monetario}}</td>
        </tr>
      </tfoot>
    </table>
  </td>
</ng-container>
