import { Type } from 'class-transformer';

export class ProdutoValorHistorico {
  @Type(() => Date)
  public data: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public ativo: string = 'S';
  public empresa: string;
  public id: number;
  public idCustoGerencial: number;
  public idCustoReposicao: number;
  public idEmpresa: number;
  public idLoja: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public lojaAbreviacao: string;
  public movimentacaoNumero: number;
  public produto: string;
  public usuario: string;
  public valor: number;
}
