<div class="row align-items-center">
  <titulo class="col-sm" [titulo]="bibDialogo.relatorio" [imagem]="bibImagem.dados"></titulo>
  <botao class="text-right" [id]="'adicionarRelatorio'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.relatorio.toLowerCase()" (botaoEmt)="adicionarRelatorio()"></botao>
</div>
<div class="row" *ngIf="moduloEmpresa.relatorioModuloEmpresas.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.relatorio.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarRelatorio()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<div class="responsiva" *ngIf="moduloEmpresa.relatorioModuloEmpresas.length > 0">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10">{{ bibDialogo.ordem }}</th>
        <th class="l-85">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let relatorioModuloEmpresa of moduloEmpresa.relatorioModuloEmpresas; let i = index">
        <td><texto [campo]="relatorioModuloEmpresa.ordem" [id]="'relatorioModuloEmpresaOrdem' + i" [obrigatorio]="true" [tipo]="this.bibPropriedade.texto.inteiro" [compacto]="true" (alteracao)="setRelatorioModuloEmpresa(relatorioModuloEmpresa, $event)"></texto></td>
        <td>{{ relatorioModuloEmpresa.idRelatorio + ' - ' + relatorioModuloEmpresa.nome }}</td>
        <td><btnAdicional [icone]="bibIcone.lixo" [id]="'item' + i" (btnAdicional)="ehExcluir(i, moduloEmpresa.relatorioModuloEmpresas, idsRelatorioModuloEmpresaExcluir, relatorioModuloEmpresa.id)"></btnAdicional></td>
      </tr>
    </tbody>
  </table>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
