import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r2000Itens]</tr>',
  templateUrl: './r2000Itens.component.html',
})
export class R2000Itens {
  @Input() empresaRegraCasasDecimais: number;
  @Input() movimentacaoProdutos: any[];
  public bibDialogo = bibDialogo;
}
