import { Type } from 'class-transformer';

export class Caixa {
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;
  @Type(() => Date)
  public dataHoraAbertura: Date;
  @Type(() => Date)
  public dataHoraFechamento: Date;

  public id: number;
  public ativo: string = 'S';
  public usuario: string;
  public idEmpresa: number;
  public idEstado: number;  
  public idConta: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;  
}
