import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.aguardando, id: '2,5' },
  { nome: bibDialogo.cancelada, id: '6' },
  { nome: bibDialogo.encerrado, id: '4' },
  { nome: bibDialogo.sucesso, id: '3' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.transportadora, nome: 'ID_PARCEIRO_TRANSPORTADORA', coluna: 12, servicoWeb: bibServico.transportadora, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO_MDFE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroSituacao },
]);

export default filtros;
