import { NFref } from "./nfRef";

export class Ide {
  public cUF: string;
  public natOp: string;
  public mod: string;
  public serie: string;
  public nNF: string;
  public dhEmi: string;
  public dhSaiEnt: string;
  public tpNF: string;
  public idDest: string;
  public cMunFG: string;
  public tpImp: string;
  public tpEmis: string;
  public cDV: string;
  public tpAmb: string;
  public finNFe: string;
  public indFinal: string;
  public indPres: string;
  public procEmi: string;
  public verProc: string;
  public dhCont: string;
  public xJust: string;
  public NFref: NFref;
}