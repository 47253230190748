<td class="p-0" colspan="6">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r text-right">{{ bibDialogo.data }}</th>
          <th class="text-right r-1">{{ bibDialogo.numeroAbreviado }}</th>
          <th class="text-right r-1">{{ bibDialogo.placa }}</th>
          <th class="text-right r-1">{{ bibDialogo.frete }}</th>
          <th class="text-right r-1">{{ bibDialogo.ipi }}</th>
          <th class="text-right r-1">{{ bibDialogo.icms }}</th>      
          <th class="text-right r-1">{{ bibDialogo.pis }}</th>      
          <th class="text-right r-1">{{ bibDialogo.cofins }}</th>      
          <th class="text-right r-2">{{ bibDialogo.quantidadeTotal }}</th>
          <th class="text-right r-1">{{ bibDialogo.valorTotal }}</th>
          <th class="r-1"></th>                  
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let nota of notas">
          <tr>
            <td class="text-right">{{ nota.data | data}}</td>
            <td class="text-right">{{ nota.numero }}</td>
            <td class="text-right">{{ nota.placa }}</td>
            <td class="text-right">{{ nota.freteValor | monetario }}</td>
            <td class="text-right">{{ nota.ipiValor | monetario}}</td>
            <td class="text-right">{{ nota.icmsValor | monetario}}</td>
            <td class="text-right">{{ nota.pisValor | monetario}}</td>
            <td class="text-right">{{ nota.cofinsValor | monetario}}</td>
            <td class="text-right">{{ nota.quantidadeTotal }}</td>
            <td class="text-right">{{ nota.valorTotal | monetario}}</td>        
          </tr>
        </ng-container>
      </tbody>
    </table>
    <br/>
</td>
