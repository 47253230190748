<div class="row align-items-center">
  <titulo class="col-sm pl-sm-2" [id]="'adicionarOcorrencia'" [titulo]="bibDialogo.ocorrencia" [imagem]="bibImagem.ocorrencia" [icone]="bibIcone.mais" (iconeEmt)="adicionarOcorrencia()" [desabilitado]="bloquearPermissao(parceiro.id)"></titulo>
</div>
<div class="row pl-3" *ngIf="parceiro.parceiroOcorrencias.length == 0">
  <p>
    {{ bibDialogo.nenhumaAbr + ', ' }} <strong class="clique" (click)="adicionarOcorrencia()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="parceiro.parceiroOcorrencias ? (parceiro.parceiroOcorrencias.length > 0 ? true : false) : false">
  <thead>
    <tr>
      <th class="l-15">{{ bibDialogo.data }}</th>
      <th class="l-70">{{ bibDialogo.motivoOcorrencia }}</th>
      <th class="l-5">{{ bibDialogo.ativo }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parceiroOcorrencia of parceiro.parceiroOcorrencias; let i = index">
      <td>
        <data [id]="'dataOcorrencia' + i" *ngIf="!parceiroOcorrencia.id || edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id)" [campo]="parceiroOcorrencia.data" (alteracao)="parceiroOcorrencia.data = $event" [obrigatorio]="true" [focoId]="focoId"></data>
        {{ parceiroOcorrencia.id && edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id) == false ? (parceiroOcorrencia.data | data: 'dd/MM/yyyy') : '' }}
      </td>
      <td class="limitar">
        <lista [id]="'motivoOcorrencia' + i" *ngIf="!parceiroOcorrencia.id || edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id)" [campo]="parceiroOcorrencia.idMotivoOcorrencia" (alteracao)="setMotivoOcorrencia(parceiroOcorrencia, $event)" [lista]="motivoOcorrencias" [obrigatorio]="true" [maximoCaracteres]="100" [compacto]="compacto"></lista>
        {{ parceiroOcorrencia.id && edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id) == false ? parceiroOcorrencia.motivoOcorrencia : '' }}
      </td>
      <td>
        <check *ngIf="!parceiroOcorrencia.id || edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id)" [campo]="parceiroOcorrencia.ativo" (alteracao)="parceiroOcorrencia.ativo = $event"></check>
        {{ parceiroOcorrencia.id && edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id) == false ? parceiroOcorrencia.ativo : '' }}
      </td>
      <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editarOcorrencia(parceiroOcorrencia)"></btnAdicional></td>
      <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirParceiroOcorrencia(i, parceiroOcorrencia.id)"></btnAdicional></td>
    </tr>
    <atencao class="mt-2" [atencao]="bibDialogo.ocorrenciaAjuda"></atencao>
  </tbody>
</table>
