import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'r2001DebitoMaiorLimite',
  templateUrl: './r2001DebitoMaiorLimite.component.html',
})
export class R2001DebitoMaiorLimiteComponent {
  @Input() debitoMaiorLimites: any[] = [];
  public rotuloRamoAtividade: string = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 21).apelido;
  public bibDialogo = bibDialogo;
}
