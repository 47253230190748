<relatorioLayout [empresa]="empresa" [relatorio]="relatorio" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" [filtros]="filtros">
  <r2015QuantidadeMovimentacaoProMovimentacaoQua [quantidadeMovimentacaoProMovimentacaoQuas]="quantidadeMovimentacaoProMovimentacaoQuas"></r2015QuantidadeMovimentacaoProMovimentacaoQua>
  <r2015ValorMovimentacaoMovimentacaoPar [valorMovimentacaoMovimentacaoParceiros]="valorMovimentacaoMovimentacaoParceiros"></r2015ValorMovimentacaoMovimentacaoPar>
  <r2015ValorFinanceiroMovimentacaoMovimentacaoPar [valorFinanceiroMovimentacaoMovimentacaoParceiros]="valorFinanceiroMovimentacaoMovimentacaoParceiros"></r2015ValorFinanceiroMovimentacaoMovimentacaoPar>
  <r2015SituacaoMovimentacaoProMovimentacao [situacaoMovimentacaoProMovimentacoes]="situacaoMovimentacaoProMovimentacoes"></r2015SituacaoMovimentacaoProMovimentacao>
  <r2015SituacaoMovimentacaoQuaMovimentacao [situacaoMovimentacaoQuaMovimentacoes]="situacaoMovimentacaoQuaMovimentacoes"></r2015SituacaoMovimentacaoQuaMovimentacao>
  <r2015SituacaoMovimentacaoParMovimentacao [situacaoMovimentacaoParMovimentacoes]="situacaoMovimentacaoParMovimentacoes"></r2015SituacaoMovimentacaoParMovimentacao>
  <r2015ValorFinanceiroMovimentacaoFinanceiro [valorFinanceiroMovimentacaoFinanceiros]="valorFinanceiroMovimentacaoFinanceiros"></r2015ValorFinanceiroMovimentacaoFinanceiro>
  <r2015ValorLiquidoMovimentacaoParFinanceiro [valorLiquidoMovimentacaoParFinanceiros]="valorLiquidoMovimentacaoParFinanceiros"></r2015ValorLiquidoMovimentacaoParFinanceiro>
  <r2015ValorMovimentacaoSemFinanceiroFinanceiro [valorMovimentacaoSemFinanceiroFinanceiros]="valorMovimentacaoSemFinanceiroFinanceiros"></r2015ValorMovimentacaoSemFinanceiroFinanceiro>
  <r2015ValorBaixaFinanceiroFinanceiroBaixa [valorBaixaFinanceiroFinanceiroBaixas]="valorBaixaFinanceiroFinanceiroBaixas"></r2015ValorBaixaFinanceiroFinanceiroBaixa>
  <r2015ValorDescontoFinanceiroFinanceiroBaixa [valorDescontoFinanceiroFinanceiroBaixas]="valorDescontoFinanceiroFinanceiroBaixas"></r2015ValorDescontoFinanceiroFinanceiroBaixa>
  <r2015ValorJuroFinanceiroFinanceiroBaixa [valorJuroFinanceiroFinanceiroBaixas]="valorJuroFinanceiroFinanceiroBaixas"></r2015ValorJuroFinanceiroFinanceiroBaixa>
  <r2015ValorRestanteFinanceiro [valorRestanteFinanceiros]="valorRestanteFinanceiros"></r2015ValorRestanteFinanceiro>
  <r2015ValorFinalFinanceiro [valorFinalFinanceiros]="valorFinalFinanceiros"></r2015ValorFinalFinanceiro>
  <r2015TransferenciaPisCofins [transferenciaPisCofins]="transferenciaPisCofins"></r2015TransferenciaPisCofins>
  <r2015ConciliacaoLancamentoContabil [conciliacaoLancamentoContabeis]="conciliacaoLancamentoContabeis"></r2015ConciliacaoLancamentoContabil>
  <r2015ConciliacaoFinanceiroBaixa [conciliacaoFinanceiroBaixas]="conciliacaoFinanceiroBaixas"></r2015ConciliacaoFinanceiroBaixa>
  <r2015EstoqueHistorico [estoqueHistoricos]="estoqueHistoricos"></r2015EstoqueHistorico>
  <r2015EntregaProdutoMovimentacaoProduto [entregaProdutoMovimentacaoProdutos]="entregaProdutoMovimentacaoProdutos"></r2015EntregaProdutoMovimentacaoProduto>
  <r2015OperacaoMovimentacao [operacaoMovimentacoes]="operacaoMovimentacoes"></r2015OperacaoMovimentacao>
  <r2015MovimentacaoMovimentacaoPendente [movimentacaoMovimentacaoPendentes]="movimentacaoMovimentacaoPendentes"></r2015MovimentacaoMovimentacaoPendente>
  <r2015MovimentacaoProdutoEstoqueHistorico [movimentacaoProdutoEstoqueHistoricos]="movimentacaoProdutoEstoqueHistoricos"></r2015MovimentacaoProdutoEstoqueHistorico>
</relatorioLayout>
