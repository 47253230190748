import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboStatus: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'AGUARDANDO', id: 'AGUARDANDO' },
  { nome: 'EM PROCESSO DE REGISTRO', id: 'PROCESSO' },
  { nome: 'CANCELADO', id: 'CANCELADO' },
  { nome: 'SUCESSO', id: 'SUCESSO' },
]);

const filtroComboSimNao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'SIM', id: 1 },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroFinanceiroAbreviacao, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.numeroBoletoAbreviacao, nome: 'NUMERO_BOLETA', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataGeracaoDe, nome: 'DATA_GERACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataGeracaoAte, nome: 'DATA_GERACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  { rotulo: bibDialogo.conta, nome: 'ID_CONTA_PREVISTA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('CONTA_BANCARIA_E_CAIXA', 'S'), new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S'), new Criterio('CONTA_BANCARIA_BOLETO', 'S')] },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO_BOLETO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  { rotulo: bibDialogo.boletoAAlterar, nome: 'FINANCEIRO_DIFERE_BOLETO', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '', lista: filtroComboSimNao },
]);

export default filtros;
