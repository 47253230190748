import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1168.component.html',
  styleUrls: ['./r1168.component.css'],
})
export class R1168Component extends RelatorioComponent {
  public filtros = filtros;
  public financeiros: any[] = [];
  public movimentacaoFinanceiras: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public legendaReceita: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    criterios.forEach((criterio) => (criterio.nome == 'RECEITA_DESPESA' && criterio.valor == 'R' ? (this.legendaReceita = true) : (this.legendaReceita = false)));
    super.listar(criterios).subscribe(() => {
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.ordenarAgrupar(this.financeiros);
      this.ehImprimirRelatorio();
    });
  }

  expandir(financeiro: any) {
    financeiro.expandido = !financeiro.expandido;
    if (financeiro.expandido && (financeiro.movimentacaoFinanceiras == null || financeiro.movimentacaoFinanceiras.length == 0)) {
      this.listarEspecifico(new Criterio('ID_CONTA_CONTABIL', financeiro.id), 73).subscribe((res) => {
        financeiro.movimentacaoFinanceiras = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }
}
