export class NaturezaOperacao {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public finalidade: number = 1;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public usaCustoReposicao: string = '1';
  public entradaSaida: number = -1;
  public mobilidadePadrao: string = 'N';
}
