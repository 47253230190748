<layout [titulo]="bibDialogo.receituarioEletronico + (receituario.numero ? ' Nº ' + receituario.numero : '')">
  <div class="row">
    <lista class="col-sm" [rotulo]="bibDialogo.cultura" [campo]="receituario.idCultura" (alteracao)="setCultura($event)" [lista]="culturas" [obrigatorio]="true" [desabilitado]="receituario.status == 'S'" [foco]="true"></lista>
    <lista class="col-sm" [rotulo]="bibDialogo.praga" [campo]="receituario.idPraga" (alteracao)="setPraga($event)" [lista]="pragas" [obrigatorio]="receituario.cultura" [desabilitado]="!receituario.cultura || receituario.status == 'S'"> </lista>
  </div>
  <div class="row">
    <lista class="col-sm" [rotulo]="bibDialogo.aplicacao" [campo]="receituario.idAplicacao" (alteracao)="setAplicacao($event)" [lista]="aplicacao" [obrigatorio]="receituario.cultura && receituario.praga" [desabilitado]="!receituario.cultura || !receituario.praga || receituario.praga == '' || receituario.status == 'S'"> </lista>
    <decimal class="col-sm" [rotulo]="bibDialogo.numeroAplicacao" [campo]="receituario.numeroAplicacao" (alteracao)="setNumeroAplicacao($event)" [obrigatorio]="true" [desabilitado]="receituario.status == 'S'"></decimal>
  </div>
  <div class="row">
    <lista class="col-sm" [rotulo]="bibDialogo.equipamento" [campo]="receituario.idEquipamento" (alteracao)="receituario.idEquipamento = $event.id" [lista]="equipamentos" [desabilitado]="receituario.status == 'S'" [icone]="bibIcone.atualizar" (btnAdicional)="listarEquipamentos()" [idMenu]="permissaoEquipamento && permissaoEquipamento.inserir == 'S' ? menuEquipamento.idMenu : null" [obrigatorio]="obrigarEquipamento"></lista>
    <lista class="col-sm" [rotulo]="bibDialogo.recomendacao" [campo]="receituario.idRecomendacao" (alteracao)="receituario.idRecomendacao = $event.id" [lista]="recomendacoes" [desabilitado]="receituario.status == 'S'" [icone]="bibIcone.atualizar" (btnAdicional)="listarRecomendacoes()" [idMenu]="permissaoRecomendacao && permissaoRecomendacao.inserir == 'S' ? menuRecomendacao.idMenu : null"></lista>
  </div>
  <div class="row">
    <texto class="col-sm-12" [rotulo]="bibDialogo.epocaAplicacao" [campo]="receituario.epocaAplicacao" (alteracao)="receituario.epocaAplicacao = $event" [desabilitado]="receituario.status == 'S'" [maximoCaracteres]="100"></texto>
    <texto class="col-sm-12" [rotulo]="bibDialogo.localAplicacao" [campo]="receituario.localAplicacao" (alteracao)="receituario.localAplicacao = $event" [desabilitado]="receituario.status == 'S'" [maximoCaracteres]="100"></texto>
  </div>
  <div class="row">
    <texto class="col-sm" [rotulo]="bibDialogo.intervaloSeguranca" [campo]="receituario.intervaloSeguranca" (alteracao)="receituario.intervaloSeguranca = $event" [desabilitado]="true" [maximoCaracteres]="100"></texto>
    <texto class="col-sm" [rotulo]="bibDialogo.estagioCultura" [campo]="receituario.estagioCultura" (alteracao)="receituario.estagioCultura = $event" [desabilitado]="receituario.status == 'S'" [maximoCaracteres]="100"></texto>
  </div>
  <div class="row align-items-center">
    <decimal class="col-sm-4" [rotulo]="bibDialogo.dose" [campo]="receituario.dose" (alteracao)="setDose($event)" [obrigatorio]="true" [desabilitado]="receituario.status == 'S'" [sufixo]="receituario.doseUnidade"></decimal>
    <atencao [ngClass]="compacto ? '' : 'mt-4'" *ngIf="receituario.doseUnidade" [atencao]="receituario.doseDe == receituario.doseAte ? (receituario.doseDe | monetario) : (receituario.doseDe | monetario) + ' à ' + (receituario.doseAte | monetario)"></atencao>
  </div>
  <div class="row">
    <decimal class="col-sm" [rotulo]="bibDialogo.volumeCaldaDe" [campo]="receituario.caldaDe" (alteracao)="receituario.caldaDe = $event" [desabilitado]="true"></decimal>
    <decimal class="col-sm" [rotulo]="bibDialogo.volumeCaldaAte" [campo]="receituario.caldaAte" (alteracao)="receituario.caldaAte = $event" [desabilitado]="true"></decimal>
  </div>
  <div class="row">
    <texto class="col-sm" [rotulo]="bibDialogo.caldaUnidade" [campo]="receituario.caldaUnidade" (alteracao)="receituario.caldaUnidade = $event" [desabilitado]="true"></texto>
  </div>
  <div class="row">
    <decimal class="col-sm" [rotulo]="bibDialogo.areaHa" [campo]="receituario.areaHa" (alteracao)="receituario.areaHa = $event" [obrigatorio]="true" [desabilitado]="receituario.status == 'S'"></decimal>  
    <atencao *ngIf="falhaCalculoArea" [atencao]="bibDialogo.ajudaAreaReceituario" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>
  <div class="row">
    <texto class="col-sm-12" [rotulo]="bibDialogo.modalidadeAplicacao" [campo]="receituario.modalidadeAplicacao" (alteracao)="receituario.modalidadeAplicacao = $event" [desabilitado]="receituario.status == 'S'" [maximoCaracteres]="100"></texto>
  </div>
  <div class="row">
    <descricao class="col-sm-12" [rotulo]="bibDialogo.observacao" [campo]="receituario.observacao" (alteracao)="receituario.observacao = $event" [desabilitado]="receituario.status == 'S'" [maximoCaracteres]="200"></descricao>
  </div>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="row">
    <botao class="ml-2" [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirReceituario()" [desabilitado]="receituario.status == 'S'"></botao>
    <bloqueado [bloqueado]="receituario.status == 'S'"></bloqueado>
  </div>
</div>
