import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1125pis]</tr>',
  templateUrl: './r1125pis.component.html',
})
export class R1125pisComponent {
  @Input() produto: any;
  public bibDialogo = bibDialogo;
}
