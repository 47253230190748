import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'r2001MovimentacaoVendedor',
  templateUrl: './r2001MovimentacaoVendedor.component.html',
})
export class R2001MovimentacaoVendedorComponent {
  @Input() movimentacaoVendedores: any[] = [];
  public menuColaboradorApelido = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 56).apelido;
  public bibDialogo = bibDialogo;
}
