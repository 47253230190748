import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.nome, nome: 'NOME', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.exceto, nome: 'EXCETO', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 3, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  { rotulo: bibDialogo.foto, nome: 'FOTO', obrigatorio: 'S', coluna: 3, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboAtivo },
  { rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  { rotulo: bibDialogo.estDisponivelDe, nome: 'DISPONIVEL_DE', coluna: 3, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.estDisponivelAte, nome: 'DISPONIVEL_ATE', coluna: 3, tipo: bibPropriedade.filtro.numero },
]);
export default filtros;
