import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Regiao } from 'src/app/modelo/regiao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './regiaoFrm.component.html',
})
export class RegiaoFrmComponent extends PaginaComponent {
  public regiao: Regiao = new Regiao();

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.regiao = this.plainToClass(Regiao, this.ehAtualizacao(this.regiao));
    if (this.regiao.id) {
      this.listarRegiao(this.regiao.id);
    }
  }

  listarRegiao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.regiao).subscribe((res) => {
      this.regiao = this.plainToClass(Regiao, res[0]) as any;
    });
  }

  persistirRegiao(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.regiao), this.bibServico.regiao, novo ? new Regiao() : null);
    }
  }

  ehValido(): boolean {
    if (this.regiao.nome) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
