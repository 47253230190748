import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboStatus: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'ABERTA', id: 'N' },
  { nome: 'CANCELADA', id: 'C' },
  { nome: 'CONFIRMADA', id: 'S' },
  { nome: 'TODOS', id: '-' },
]);

const filtroComboTotalizador: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'PARCEIRO', id: 'MOV.ID_PARCEIRO' },
  { nome: 'PRODUTO', id: 'MOVPRO.ID_PRODUTO' },
  { nome: 'GRUPO', id: 'PRO.ID_GRUPO' },
  { nome: 'CATEGORIA', id: 'PRO.ID_CATEGORIA' },
  { nome: 'RESPONSÁVEL', id: 'MOV.ID_COLABORADOR' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.produtoDescricao, nome: 'NOME_PRODUTO', coluna: 6, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_RESPONSAVEL', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.negociacao, nome: 'ID_NEGOCIACAO', coluna: 6, servicoWeb: bibServico.negociacao, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboStatus },
  { rotulo: bibDialogo.totalizarPor, nome: 'TOTALIZADOR', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboTotalizador },
]);
export default filtros;
