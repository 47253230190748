import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { DecimalPipe } from './decimal.pipe';

@Directive({ selector: '[decimalDirective]' })
export class DecimalDirective {
  private el: HTMLInputElement;
  @Input() numeroCasasDecimais: number;

  constructor(private elementRef: ElementRef, private decimalPipe: DecimalPipe) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.decimalPipe.parse(value, this.numeroCasasDecimais);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.decimalPipe.transform(value, this.numeroCasasDecimais);
  }
}
