<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="13">
    <table class="tabela-complementar">
      <thead>
        <th class="r-0-5">{{ bibDialogo.loja }}</th>
        <th class="r-1">{{ bibDialogo.data }}</th>
        <th class="r-1">{{ bibDialogo.vencimento }}</th>
        <th *ngIf="ehRelatorio1109 == false" class="r-1">{{ bibDialogo.parcela }}</th>
        <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
        <th *ngIf="ehRelatorio1109" class="r-1 text-right">{{ bibDialogo.numeroFiscal }}</th>
        <th class="r">{{ bibDialogo.vendedor }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
        <th *ngIf="ehRelatorio1109" class="r-1 text-right">{{ bibDialogo.valorVencido }}</th>
        <th class="r-1 text-right">{{ bibDialogo.acrescimo }}</th>        
        <th class="r-1 text-right">{{ bibDialogo.desconto }}</th>    
        <th *ngIf="ehRelatorio1109 == false" class="r-1 text-right">{{ bibDialogo.valorPago }}</th>    
        <th class="r-1 text-right">{{ bibDialogo.valorFinal }}</th>    
        <th *ngIf="ehRelatorio1109" class="r-1 text-right">{{ bibDialogo.credito }}</th>
        <th *ngIf="ehRelatorio1109" class="r-1 text-right">{{ bibDialogo.valorLiquido }}</th>
        <th class="r-0-5"></th>
        <th *ngIf="ehRelatorio1109" class="r-0-5 text-right fonte-pequena">{{ bibDialogo.diasAtraso }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let financeiro of financeiros">
          <tr class="conteudo">
            <td>{{ financeiro.abreviacao }}</td>
            <td>{{ financeiro.data | data }}</td>
            <td>{{ financeiro.dataVencimento | data }}</td>
            <td *ngIf="ehRelatorio1109 == false">{{ financeiro.parcela }}</td>
            <td class="text-right">
              <a href="#" (click)="abrirModalFinanceiro(financeiro.id, financeiro.tipo)">{{ financeiro.numero }}</a>
            </td>
            <td *ngIf="ehRelatorio1109" class="text-right">{{ financeiro.numeroFiscal }}</td>
            <td>{{ financeiro.numeroColaborador && financeiro.colaborador ? financeiro.numeroColaborador + ' - ' + financeiro.colaborador : '' }}</td>
            <td class="text-right">{{ financeiro.valorOriginal > 0 ? (financeiro.valorOriginal | monetario) : '' }}</td>
            <td *ngIf="ehRelatorio1109" class="text-right">{{ financeiro.valorVencido > 0 ? (financeiro.valorVencido | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.acrescimo > 0 ? (financeiro.acrescimo | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.desconto > 0 ? (financeiro.desconto | monetario) : '' }}</td>
            <td *ngIf="ehRelatorio1109 == false" class="text-right">{{ financeiro.valorPago > 0 ? (financeiro.valorPago | monetario) : '' }}</td>
            <td class="text-right">{{ financeiro.valorTotal > 0 ? (financeiro.valorTotal | monetario) : '' }}</td>
            <td *ngIf="ehRelatorio1109" class="text-right">{{ financeiro.valorCredito > 0 ? (financeiro.valorCredito | monetario) : '' }}</td>
            <td *ngIf="ehRelatorio1109" class="text-right">{{ financeiro.valorTotal - financeiro.valorCredito > 0 ? (financeiro.valorTotal - financeiro.valorCredito | monetario) : '' }}</td>            
            <td></td>
            <td *ngIf="ehRelatorio1109" class="text-right">{{ financeiro.diaAtraso }}</td>
          </tr>
          <tr *ngIf="ehRelatorio1109 && usaObservacao && financeiro.observacao">
            <p>{{ financeiro.observacao }}</p>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
