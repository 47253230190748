import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'PENDENTE', id: 'P' },
  { nome: 'RECUSADA', id: 'R' },
  { nome: 'APROVADO', id: 'A' },
  { nome: 'TODOS', id: '-' },
]);

const filtros = {
  filtrosVenda: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroAbreviado + ' ' + bibDialogo.movimentacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 12, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('CATEGORIA', 'V')] },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
  filtrosCompra: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 12, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('CATEGORIA', 'C')] },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
  filtrosComissao: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.vendedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.parceiro, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 12, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('CATEGORIA', 'S')] },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
  filtrosFinanceiro: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 12, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('CATEGORIA', 'F')] },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
  filtrosGestor: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 12, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('CATEGORIA', 'G')] },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
  filtrosDeposito: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('CATEGORIA', 'D')] },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
  filtrosTodas: plainToClass(Filtro, [
    { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { rotulo: bibDialogo.dataDe, nome: 'DATA_COMISSAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_COMISSAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
    { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 12, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.motivo, nome: 'ID_MOTIVO', coluna: 12, servicoWeb: bibServico.aprovacaoMotivo, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: 5, valor: 'P', lista: filtroComboSituacao },
  ]),
};

export default filtros;
