<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros" [subTitulo]="somenteUmParceiro && financeiros.length > 0 ? (this.tipo == 'R' ? bibDialogo.cliente.toUpperCase() + ': ' : bibDialogo.fornecedor.toUpperCase() + ': ') + financeiros[0].parceiro : ''">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-10 clique" (click)="listar(add('parcela'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parcela }}</th>
        <th class="l-10 clique" (click)="listar(add('dataVencimento'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.vencimento }}</th>
        <th class="l-25 clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ this.tipo == 'R' ? bibDialogo.cliente : bibDialogo.fornecedor }}</th>
        <th></th>
        <th class="l-5 text-right clique" (click)="listar(add('valorFinal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.total }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorRestante'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorRestante }}</th>
        <th class="l-5 text-right"></th>
        <th class="l-5 text-right"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <tr [ngClass]="{ tachado: financeiro.ativo != 'S' }">
          <td><i *ngIf="financeiro.valorRestante == 0" [ngClass]="bibIcone.cadeadoFechado"></i></td>
          <td>{{ financeiro.abreviacao }}</td>
          <td class="text-right">{{ financeiro.numero }}</td>
          <td class="limitar">{{ financeiro.parcela }}</td>
          <td>{{ financeiro.dataVencimento | data }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(financeiro.id, i, financeiros)">{{ financeiro.parceiro }}</a>
          </td>
          <td><ajuda [ajuda]="financeiro.clienteNomeFantasia"></ajuda></td>
          <td class="text-right">{{ financeiro.valorFinal | monetario }}</td>
          <td class="text-right">{{ financeiro.valorRestante | monetario }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(financeiro.id, i, financeiros)"></btnAdicional>
          </td>
          <td>
            <btnAdicional *ngIf="(!financeiro.idMovimentacao && !financeiro.idDesfazimento && (financeiroTipo == 'R' || financeiroTipo == 'D')) || (financeiro.idMovimentacao && financeiro.idCredito)" [icone]="bibIcone.lixo" (btnAdicional)="excluirFin(financeiro)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="visualizaTotalizadores && financeiros.length > 0">
      <tr *ngFor="let totalFinanceiro of totalFinanceiros">
        <td colspan="7"></td>
        <td class="text-right font-weight-bold">{{ totalFinanceiro.totalValorFinal | monetario }}</td>
        <td class="text-right font-weight-bold">{{ totalFinanceiro.totalValorRestante | monetario }}</td>
        <td></td>
      </tr>
    </tfoot>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
