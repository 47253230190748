import { CfopEmpresaConfiguracaoMovimentacaoContabil } from './cfopEmpresaConfiguracaoMovimentacaoContabil';

export class CfopEmpresa {
  public ativo: string = 'S';
  public bloqueadoEstoque: string = 'N';
  public bloqueadoEstoqueNfe: string = 'N';
  public bloqueadoFinanceiro: string = 'N';
  public bloqueadoCustoReposicao: string = 'N';
  public cfopBloqueiaQuantidadeFaturada: string;
  public cfopDevolucao: string;
  public cfopEntradaSaida: string;
  public cfopTransferencia: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricaoNfe: string;
  public id: number;
  public idCfop: number;
  public idCfopContrapartida: number;
  public idCfopDevolucao: number;
  public idConfMovimentacaoContabil: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public nomeContrapartida: string;
  public idClassificacaoFiscal: number;
  public idCstPis: number;
  public pisAliquota: number;
  public idCstCofins: number;
  public cofinsAliquota: number;
  public idCstIpi: string;
  public ipiAliquota: number;
  public ipiEnquadramento: string;
  public idContaContabilSped: number;
  public contaContabilSped: string;
  public cfopEmpresaConfiguracaoMovimentacaoContabeis: CfopEmpresaConfiguracaoMovimentacaoContabil[] = [];
  public enviarSpedConRegCompetencia: string;
  public naturezaCreditoPisCofins: string;
}
