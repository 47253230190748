<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.quantidadeProdutoQuantidadeMovimentacao }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r-1-5">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r">{{ bibDialogo.produto }}</th>
    <th class="r-1">{{ bibDialogo.quantidade }}</th>
    <th class="r-1">{{ bibDialogo.quantidadeTotalAbreviado }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let quantidadeMovimentacaoProMovimentacaoQua of quantidadeMovimentacaoProMovimentacaoQuas">
      <tr class="conteudo">
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.id }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.data | data }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.numero }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.idLoja + ' - ' + quantidadeMovimentacaoProMovimentacaoQua.loja }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.idEmpresa + ' - ' + quantidadeMovimentacaoProMovimentacaoQua.empresa }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.idOperacao + ' - ' + quantidadeMovimentacaoProMovimentacaoQua.operacao }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.nome }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.quantidadeEsperada | monetario }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.quantidadeEncontrada | monetario }}</td>
        <td>{{ quantidadeMovimentacaoProMovimentacaoQua.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
