import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Criterio } from 'src/app/modelo/criterio';
import { Equipe } from 'src/app/modelo/equipe';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './equipe.component.html',
})
export class EquipeComponent extends PaginaComponent {
  public equipes: Equipe[] = [];
  public filtros: Filtro[] = filtros;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.equipe).subscribe((res) => {
      this.equipes = this.plainToClass(Equipe, res) as any;
    });
  }

  excluirEquipe(id: number, equipe: string): void {
    super.excluir<Equipe>(id, this.equipes, equipe);
  }

  listarEquipe(idEquipe: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idEquipe)), this.bibServico.equipe);
  }
}
