import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';

const bibNomenclatura: BibNomenclatura = new BibNomenclatura();
export class Filtros {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { id: 'grupo', rotulo: bibDialogo.grupo, nome: 'GRUPO', coluna: 12, tipo: bibPropriedade.filtro.texto },
    { id: 'margemGerencialDe', rotulo: bibNomenclatura.escrever(bibNomenclatura.margemGerencial) + ' (De)', nome: 'MARGEM_GERENCIAL_DE', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'margemGerencialAte', rotulo: bibNomenclatura.escrever(bibNomenclatura.margemGerencial) + ' (Até)', nome: 'MARGEM_GERENCIAL_ATE', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'margemLucroDe', rotulo: bibDialogo.margemLucroDe, nome: 'MARGEM_LUCRO_DE', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'margemLucroAte', rotulo: bibDialogo.margemLucroAte, nome: 'MARGEM_LUCRO_ATE', coluna: 6, tipo: bibPropriedade.filtro.numero },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 6, obrigatorio: 'S', servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  ]);
}
