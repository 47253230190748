import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  selector: 'app-r1118',
  templateUrl: './r1118.component.html',
})
export class R1118Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public produtoValores: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public usaData: boolean = true;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.produtoValores = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ProdutoValor').lista;
      this.ordenarAgrupar(this.produtoValores);
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaData = this.definirRegra(relatorioAdicional, 261, this.usaData);
  }
}
