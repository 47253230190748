import { Type } from 'class-transformer';
import { Util } from '../utilitario/util';
import { FinanceiroBaixaPro } from './financeiroBaixaPro';

export class FinanceiroBaixa {
  @Type(() => Date)
  dataBaixa: Date = new Date();
  @Type(() => Date)
  dataPrevista: Date;
  @Type(() => Date)
  dataConciliacao: Date;
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public abreviacao: string;
  public alterou: boolean = false;
  public bloqueiaConta: boolean = false;
  public chaveGeral: string;
  public chequeAbreviacao: string;
  public chequeIdLoja: number;
  public chequeParceiro: string;
  public conciliado: string;
  public conta: string;
  public contaContaContabil: string;
  public descontoValor: number = 0;
  public expandido: boolean = false;
  public financeiroBaixaPros: FinanceiroBaixaPro[] = [];
  public id: number;
  public idCheque: number;
  public idConciliacao: number;
  public idConta: number;
  public idContaContaContabil: number;
  public idEmpresa: number;
  public idFinanceiro: number;
  public idLoja: number;
  public idMovimentacao: number;
  public idTipoTitulo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public juroValor: number = 0;
  public juroManual: number = 0;
  public loja: string;
  public numeroDocumento: string;
  public numeroFinanceiro: number;
  public obrigaNumeroDocumento: boolean = false;
  public observacao: string;
  public ordemSalvar: number;
  public parceiro: string;
  public parcela: string;
  public posicao: number = 0;
  public selecionado: string = 'N';
  public tipo: string;
  public tipoTitulo: string;
  public tipoTituloDia: number;
  public tipoTituloIdEspecie: number;
  public tipoTituloNivel: number;
  public tipoTituloNumeroTaxas: number;
  public tipoTituloRegraRecebimento: number;
  public tipoTituloIdConta: number;
  public valor: number = 0;
  public valorCheio: number = 0;
  public valorTroco: number = 0;
  public troco: boolean = false;

  constructor(valor: number = null, idFinanceiro: number = null, idConta: number = null, idTipoTitulo: number = null, idEmpresa: number = null, idUsuarioAlteracao: number = null, idUsuarioInclusao: number = null, status: string = null, numeroDocumento: string = null, idLoja: number = null, descontoValor: number = 0, juroValor: number = 0, valorTroco = 0) {
    this.setValor(valor);
    this.idFinanceiro = idFinanceiro;
    this.idConta = idConta;
    this.idTipoTitulo = idTipoTitulo;
    this.idEmpresa = idEmpresa;
    this.idUsuarioAlteracao = idUsuarioAlteracao;
    this.idUsuarioInclusao = idUsuarioInclusao;
    this.numeroDocumento = numeroDocumento;
    this.idLoja = idLoja;
    this.descontoValor = descontoValor;
    this.juroValor = juroValor;
    this.valorTroco = valorTroco;
    this.valorCheio = valor + valorTroco;
  }

  setValor(valor: number) {
    this.valor = new Util().arredondar(valor);
  }

  getValor(): number {
    return this.valor;
  }
}
