import { Component, Input } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroDestinoFrmComponent } from './frm/parceiroDestinoFrm.component';
import { Negociacao } from 'src/app/modelo/negociacao';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';

@Component({
  selector: 'parceiroDestino',
  templateUrl: './parceiroDestino.component.html',
})
export class ParceiroDestinoComponent extends PaginaComponent {
  @Input() excluirDestinos: Number[] = [];
  @Input() negociacoes: Negociacao[];
  @Input() parceiroCnpjCpf: string;
  @Input() parceiroDestinos: ParceiroDestino[] = [];
  @Input() parceiroNome: string;
  @Input() tipoVinculo: string;
  @Input() tipoPessoa: string;
  @Input() idParceiro: number;
  private modalEmt: any;
  public componente: any;
  public rotuloBairro: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 2).apelido;
  public rotuloCidade: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 1).apelido;

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.parceiroDestino) {
        this.adicionarTemporario(this.plainToClass(ParceiroDestino, identificacao.conteudo), this.parceiroDestinos);
      }
    });
  }

  ngOnDestroy() {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  abrirModal(parceiroDestino: ParceiroDestino = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', parceiroDestino));
    this.utilSessao.setIdentificacao(new Identificacao('tipoVinculo', this.tipoVinculo));
    this.utilSessao.setIdentificacao(new Identificacao('negociacoes', this.negociacoes));
    this.utilSessao.setIdentificacao(new Identificacao('parceiroNome', this.parceiroNome));
    this.utilSessao.setIdentificacao(new Identificacao('parceiroCnpjCpf', this.parceiroCnpjCpf));
    this.utilSessao.setIdentificacao(new Identificacao('tipoPessoa', this.tipoPessoa));
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', this.idParceiro));
    this.componente = ParceiroDestinoFrmComponent;
  }
}
