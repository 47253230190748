import { ɵallowPreviousPlayerStylesMerge } from '@angular/animations/browser';
import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ContaContabil } from '../../modelo/contaContabil';
import filtros from './filtro';

@Component({
  templateUrl: './contaContabil.component.html',
})
export class ContaContabilComponent extends PaginaComponent {
  public componente: any;
  public contaContabeis: ContaContabil[] = [];
  public contemContaContabil: boolean = false;
  public ehMostraContaContabilPadrao: boolean = false;
  public filtros: Filtro[] = filtros;
  public utilizaPlanoContaPadrao: boolean = this.utilSessao.getEmpresa().regraPlanoContaPadrao == 'S';

  ngOnInit(): void {
    if (this.utilizaPlanoContaPadrao) {
      if (this.util.coalesce(this.utilSessao.getIdentificacao('contaContabilPadrao')?.conteudo, false) == true) {
        this.ehMostraContaContabilPadrao = true;
        this.utilSessao.setIdentificacao(new Identificacao('contaContabilPadrao', false));
        this.listarContaContabilPadrao();
      } else {
        this.verificarContemContaContabil();
      }
    }
    if (this.utilSessao.telaInterna) {
      this.contaContabeis = this.utilSessao.contaContabeis;
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    criterios.push(new Criterio('SEM_CORRENTISTA', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.contaContabil).subscribe((res) => {
      this.contaContabeis = this.plainToClass(ContaContabil, res) as any;
    });
  }

  listarContaContabilPadrao(): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('CONTA_CONTABIL_PADRAO', 'S'), new Criterio('ATIVO', 'S')]), this.bibServico.contaContabil).subscribe((res) => {
      this.contaContabeis = this.plainToClass(ContaContabil, res) as any;
      this.contaContabeis = this.contaContabeis.filter((contaContabil) => contaContabil.id != 0);
    });
  }

  verificarContemContaContabil(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('SEM_ID_ZERO', 'S')), this.bibServico.contaContabil).subscribe((res) => {
      const contaContabeis: ContaContabil[] = this.plainToClass(ContaContabil, res) as any;
      this.contemContaContabil = contaContabeis.length > 0;
    });
  }

  excluirContaContabil(id: number, nome: string): void {
    super.excluir<ContaContabil>(id, this.contaContabeis, nome);
  }

  mostrarContaContabilPadrao(): void {
    this.utilSessao.setIdentificacao(new Identificacao('contaContabilPadrao', true));
    this.componente = ContaContabilComponent;
  }

  importarContaContabilPadrao(): void {
    this.contaContabeis.forEach((contaContabil) => {
      contaContabil.id = null;
      contaContabil.nome = contaContabil.nome.trim();
      contaContabil.idPlanoContaPai = contaContabil.idPlanoContaPai == 0 ? 0 : null;
      contaContabil.numeracao = this.adicionarMascaraEmpresa(contaContabil.numeracao);
      contaContabil.numeracaoPai = this.adicionarMascaraEmpresa(contaContabil.numeracaoPai);
    });
    this.persistir(new Transporte(this.contaContabeis), this.bibServico.contaContabil, null).subscribe((res) => {
      this.modalNotificacao.modalEmt.emit(null);
    });
  }

  adicionarMascaraEmpresa(numeracao: string): string {
    let numeracaoFormatada: string[] = [];
    const numeracaoContabeis: string[] = numeracao.split('.');
    const numeracaoEmpresa: string[] = this.utilSessao.getEmpresa().regraMascaraContaContabil.split('.');
    numeracaoContabeis.forEach((numeracaoContabil, index) => {
      if (numeracaoEmpresa[index]) {
        let numero: number = Number(numeracaoContabil);
        numeracaoFormatada.push(numero.toString().padStart(numeracaoEmpresa[index].length, '0'));
      } else {
        let numero: number = Number(numeracaoContabil);
        numeracaoFormatada.push(numero.toString());
      }
    });
    return numeracaoFormatada.join('.');
  }

  fecharModalPlanoConta(): void {
    this.componente = null;
    const criterios: Criterio[] = [];
    this.listar(criterios);
    this.verificarContemContaContabil();
  }

  adicionarContaContabil(contaContabil: ContaContabil): void {
    this.utilSessao.setIdentificacao(new Identificacao('contaContabilPai', contaContabil));
    this.router.navigate(['/contaContabilFrm']);
  }

  expandirContaContabil(contaContabil: ContaContabil): void {
    if (this.contaContabeis.filter((contaContabilCorrentista) => contaContabilCorrentista.idPaiCorrentista == contaContabil.id).length == 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PAI_CORRENTISTA', contaContabil.id)), this.bibServico.contaContabil).subscribe((res) => {
        this.contaContabeis = this.popularComCorrentista(contaContabil, this.plainToClass(ContaContabil, res) as any);
      });
    } else {
      for (const contaContabilAtual of this.contaContabeis.filter((contaContabilCorrentista) => contaContabilCorrentista.idPaiCorrentista == contaContabil.id)) {
        contaContabilAtual.correntista = contaContabilAtual.correntista == 0 ? -1 : 0;
      }
    }
  }

  popularComCorrentista(contaContabil: ContaContabil, contaContabeisCorrentistas: ContaContabil[]): ContaContabil[] {
    let contaContabeis: ContaContabil[] = [];
    for (const contaContabilAtual of this.contaContabeis) {
      if (contaContabilAtual.id == contaContabil.id) {
        contaContabeis.push(contaContabil);
        for (const contaContabeisCorrentista of contaContabeisCorrentistas) {
          contaContabeis.push(contaContabeisCorrentista);
        }
      } else {
        contaContabeis.push(contaContabilAtual);
      }
    }
    return contaContabeis;
  }

  mostrarHierarquiaDaConta(contaContabil: ContaContabil): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_CONTA_OU_PAI', contaContabil.id.toString() + ' or concon.id_plano_conta_pai = ' + contaContabil.id.toString() + 'or concon.id = ' + contaContabil.idPlanoContaPai.toString()));
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, 0, 100, true, this.utilSessao.numeroRegistro), this.bibServico.contaContabil).subscribe((res) => {
      this.utilSessao.contaContabeis = this.plainToClass(ContaContabil, res) as any;
      this.utilSessao.telaInterna = true;
      this.componente = ContaContabilComponent;
    });
  }
}
