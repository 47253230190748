import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { R2005Component } from '../r2005.component';

@Component({
  selector: '<tr>[r2005correntistas]</tr>',
  templateUrl: './r2005correntistas.component.html',
})
export class R2005CorrentistasComponent extends R2005Component {
  @Input() correntistas: any;
  @Input() usaCorrentista: boolean;
  public bibDialogo = bibDialogo;
  public bibPropriedade = bibPropriedade;
}
