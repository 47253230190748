import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboEstoque: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.disponivel, id: 'disponivel' },
  { nome: bibDialogo.todos, id: 'todos' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.produtoDescricao, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.tabelaPreco, nome: 'ID_TABELA_PRECO', coluna: 6, servicoWeb: bibServico.tabelaPreco, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.cliente, nome: 'ID_CLIENTE', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.lojaValor, nome: 'ID_LOJA_VALOR', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista, obrigatorio: 'S' },
  { rotulo: bibDialogo.estoque, nome: 'SOMENTE_ESTOQUE', coluna: 6, tipo: bibPropriedade.filtro.combo, obrigatorio: 'S', valor: 'disponivel', lista: filtroComboEstoque },
]);
export default filtros;
