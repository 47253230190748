<titulo [titulo]="bibDialogo.documentoFiscal" [imagem]="bibImagem.documentos" [icone]="bibIcone.mais" (iconeEmt)="adicionarDocumentoFiscal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.documentoFiscal.toLocaleLowerCase()" [desabilitado]="bloqueado"></titulo>
<div *ngIf="cte.cteDocumentoFiscais.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.documentoFiscal.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarDocumentoFiscal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="cte.cteDocumentoFiscais.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l clique" (click)="util.ordenarLista(this.cte.cteDocumentoFiscais, 'chave')">{{ bibDialogo.chave }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let cteDocumentoFiscal of cte.cteDocumentoFiscais; let i = index">
      <tr>
        <td >
          <texto *ngIf="!cteDocumentoFiscal.id || edicaoCteDocumentoFiscais.includes(cteDocumentoFiscal.id)" [campo]="cteDocumentoFiscal.chave" (alteracao)="cteDocumentoFiscal.chave = $event" [maximoCaracteres]="44" [obrigatorio]="true" [focoId]="focoChave"></texto>
          {{ cteDocumentoFiscal.id && edicaoCteDocumentoFiscais.includes(cteDocumentoFiscal.id) == false ? cteDocumentoFiscal.chave : '' }}
        </td>
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editarDocumentoFiscal(cteDocumentoFiscal)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirDocumentoFiscal(i, cteDocumentoFiscal.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
