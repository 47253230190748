export class GrupoUsuarioMenu {
  public acessar: string = 'N';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricao: string;
  public editar: string = 'N';
  public excluir: string = 'N';
  public expandido: boolean = false;
  public favorito: string = 'N';
  public id: number;
  public idEmpresa: number;
  public idGrupoUsuario: number;
  public idMenu: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public inativar: string = 'N';
  public inserir: string = 'N';
  public menu: string;
  public menuAcessar: string;
  public menuEditar: string;
  public menuExcluir: string;
  public menuInativar: string;
  public menuInserir: string;
  public modulo: string;
  public moduloEmpresa: string;
  public moduloEmpresaPai: string;
  public moduloPai: string;
}
