import { Component } from '@angular/core';
import { Categoria } from 'src/app/modelo/categoria';
import { CentroResultado } from 'src/app/modelo/centroResultado';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Grupo } from 'src/app/modelo/grupo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Natureza } from 'src/app/modelo/natureza';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './genericoHierarquia.component.html',
})
export class GenericoHierarquiaComponent extends PaginaComponent {
  private classe: any;
  private servico: string;
  public filtros: Filtro[] = filtros;
  public objetos: any[] = [];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.objetos = [];
      this.permissao = this.utilSessao.getPermissao();
      switch (params.parametro.toLowerCase()) {
        case this.bibServico.grupo:
          this.classe = Grupo;
          this.servico = this.bibServico.grupo;
          break;
        case this.bibServico.categoria:
          this.classe = Categoria;
          this.servico = this.bibServico.categoria;
          break;
        case this.bibServico.natureza:
          this.classe = Natureza;
          this.servico = this.bibServico.natureza;
          break;
        case 'centro_resultado':
          this.classe = CentroResultado;
          this.servico = this.bibServico.centroResultado;
          break;
      }
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.servico).subscribe((res) => {
      this.objetos = this.plainToClass(this.classe, res) as any;
    });
  }

  excluirObjeto(id: number, nome: string): void {
    super.excluir(id, this.objetos, nome);
  }

  irPara(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('id', id));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', this.permissao.parametro));
    let url: string = this.permissao.tela + 'Frm';
    this.router.navigate([url]);
  }
}
