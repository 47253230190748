import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboTipo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'DESPESA', id: 'D' },
  { nome: 'RECEITA', id: 'R' },
  { nome: 'TODOS', id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numeroChequeAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataPreDatadaDe', rotulo: bibDialogo.dataPreDatadaDe, nome: 'DATA_PRE_DATADA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataPreDatadaAte', rotulo: bibDialogo.dataPreDatadaAte, nome: 'DATA_PRE_DATADA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataFinalizadoDe', rotulo: bibDialogo.dataFinalizadoDe, nome: 'DATA_FINALIZADO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'dataFinalizadoAte', rotulo: bibDialogo.dataFinalizadoAte, nome: 'DATA_FINALIZADO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'contaInicial', rotulo: bibDialogo.contaInicial, nome: 'ID_CONTA_INICIAL', coluna: 6, servicoWeb: bibServico.contaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { id: 'contaAtual', rotulo: bibDialogo.contaAtual, nome: 'ID_CONTA_ATUAL', coluna: 6, servicoWeb: bibServico.contaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { id: 'parceiro', rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { id: 'tipo', rotulo: bibDialogo.tipo, nome: 'TIPO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboTipo },
]);
export default filtros;
