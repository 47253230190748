import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Generico } from 'src/app/modelo/generico';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Resultado } from 'src/app/modelo/resultado';

@Component({
  templateUrl: './genericoFrm.component.html',
})
export class GenericoFrmComponent extends PaginaComponent {
  public generico: Generico = new Generico();
  public usaLoja: boolean = false;

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.generico = this.plainToClass(Generico, this.ehAtualizacao(this.generico));
    this.usaLoja = this.utilSessao.getIdentificacao('parametro') != null;
    if (this.generico.id) {
      this.comunicacaoService.listarLog(new Transporte(new Criterio('ID', this.generico.id)), this.permissao.servico).subscribe((res) => {
        this.generico = this.plainToClass(Generico, res[0]) as any;
      });
    }
  }

  persistirGenerico(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.generico), this.permissao.servico, novo ? new Generico() : null);
    }
  }

  ehValido(): boolean {
    if (this.generico.nome && (this.generico.idLoja || !this.usaLoja)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
