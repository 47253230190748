<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="comissao ? 8 : usaNegociacao && usaSituacao ? 11 : pedidoCarteira || usaNegociacao || usaSituacao ? 9 : 8">
    <table class="tabela-complementar">
      <thead>
        <tr>          
          <th *ngIf="pedidoCarteira == true" class="r-1 quebra-linha">{{ bibDialogo.documentoFiscal }}</th>
          <th class="r">{{ menuProduto.apelido }}</th>
          <th *ngIf="comissao == false" class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
          <th class="text-right r-1">{{ bibDialogo.quantidade }}</th>
          <th *ngIf="pedidoCarteira == true" class="text-right r-1">{{ bibDialogo.quantidadeEntregue }}</th>          
          <th *ngIf="pedidoCarteira == true" class="text-right r-1">{{ bibDialogo.quantidadeFaturada }}</th>
          <th *ngIf="pedidoCarteira == true" class="text-right r-1">{{ bibDialogo.quantidadePendente }}</th>
          <th *ngIf="pedidoCarteira == false" class="text-right r-1 quebra-linha">{{ bibDialogo.valorUnitario }}</th>
          <th *ngIf="pedidoCarteira == false" class="text-right r-1">{{ bibDialogo.desconto }}</th>
          <th *ngIf="pedidoCarteira == false && comissao == false" class="text-right r-1">{{ bibDialogo.valorTotal }}</th>
          <th *ngIf="pedidoCarteira == false && comissao == false" class="text-right r-1 quebra-linha">{{ bibDialogo.descontoRateado }}</th>
          <th *ngIf="comissao == true" class="text-right r-1 quebra-linha">{{ bibDialogo.origemComissao }}</th>
          <th *ngIf="comissao == true" class="text-right r-1 quebra-linha">{{ bibDialogo.percentualComissao }}</th>
          <th *ngIf="comissao == true" class="text-right r-1 quebra-linha">{{ bibDialogo.valorComissao }}</th>
          <th class="text-right r-1">{{ bibDialogo.valorFinal }}</th>
          <th *ngIf="pedidoCarteira == true" class="text-right r-1">{{ bibDialogo.valorFaturado }}</th>
          <th *ngIf="pedidoCarteira == true" class="text-right r-1">{{ bibDialogo.valorDesfeito }}</th>
          <th *ngIf="pedidoCarteira == true" class="text-right r-1">{{ bibDialogo.valorPendente }}</th>
          <th *ngIf="usaNegociacao" class="r-1"></th>
          <th *ngIf="usaSituacao" class="r-2"></th>
          <th class="r-1"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos; let i = index">
          <tr>
            <td *ngIf="pedidoCarteira == true">{{ movimentacaoProduto.documentoFiscal }}</td>
            <td class="limitar r">{{ movimentacaoProduto.numero + ' - ' + movimentacaoProduto.produto }}</td>
            <td *ngIf="comissao == false">{{ movimentacaoProduto.unidadeAbreviacao }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
            <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacaoProduto.quantidadeEntregue | monetario: this.empresaRegraCasasDecimais }}</td>
            <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacaoProduto.quantidadeFaturada | monetario: this.empresaRegraCasasDecimais }}</td>
            <td *ngIf="pedidoCarteira == true" class="text-right" [ngClass]="movimentacaoProduto.quantidadePendenteFaturamento == 0 ? 'tachado' : ''">{{ movimentacaoProduto.quantidadePendenteFaturamento | monetario: this.empresaRegraCasasDecimais }}<ajuda *ngIf="movimentacaoProduto.quantidadeDesfeita + movimentacaoProduto.quantidadeDevolvidaEst > 0" class="naoImprimir" [ajuda]="bibDialogo.quantidadeDesfeita + ' (' + (movimentacaoProduto.quantidadeDesfeita | monetario: this.empresaRegraCasasDecimais) + ') + ' + bibDialogo.quantidadeEstorno + ' (' + (movimentacaoProduto.quantidadeDevolvidaEst | monetario: this.empresaRegraCasasDecimais)+ ') = ' + (movimentacaoProduto.quantidadeDesfeita + movimentacaoProduto.quantidadeDevolvidaEst | monetario: this.empresaRegraCasasDecimais) + ' ' + movimentacaoProduto.unidadeAbreviacao"></ajuda></td>
            <td *ngIf="pedidoCarteira == false" class="text-right">{{ movimentacaoProduto.valorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
            <td *ngIf="pedidoCarteira == false" class="text-right">{{ movimentacaoProduto.descontoValor | monetario }}</td>            
            <td *ngIf="comissao == true" class="text-right"><atencao [atencao]="movimentacaoProduto.comissaoOrigem" [ajuda]="movimentacaoProduto.comissaoOrigem == 'N' ? bibDialogo.semComissao : movimentacaoProduto.comissaoOrigem == 'PC' ? bibDialogo.produtoColaborador : movimentacaoProduto.comissaoOrigem == 'P' ? bibDialogo.produto :  movimentacaoProduto.comissaoOrigem == 'GC' ? bibDialogo.grupoColaborador : movimentacaoProduto.comissaoOrigem == 'G' ? bibDialogo.grupo : movimentacaoProduto.comissaoOrigem == 'C' ? bibDialogo.colaborador : ''"></atencao></td>
            <td *ngIf="comissao == true" class="text-right">{{ movimentacaoProduto.comissaoPercentual | monetario }}</td>
            <td *ngIf="comissao == true" class="text-right">{{ movimentacaoProduto.comissaoValor | monetario }}</td>
            <td *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacaoProduto.valorTotal | monetario }}</td>            
            <td *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacaoProduto.descontoRateado | monetario }}</td>
            <td class="text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
            <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacaoProduto.valorFaturado | monetario }}</td>
            <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacaoProduto.valorDesfeitoEstorno | monetario }}</td>
            <td *ngIf="pedidoCarteira == true" class="text-right"[ngClass]="movimentacaoProduto.valorPendente == 0 ? 'tachado' : ''">{{ movimentacaoProduto.valorPendente | monetario }}</td>
            <td *ngIf="usaNegociacao"></td>
            <td *ngIf="usaSituacao"></td>
            <td class="naoImprimir" *ngIf="movimentacao.movimentacaoProdutos && movimentacao.movimentacaoProdutos.length > 0 && pedidoCarteira == false"><btnAdicional [icone]="movimentacaoProduto.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacaoProduto)"></btnAdicional></td>
          </tr>
          <tr *ngIf="movimentacaoProduto.expandido" r1001notas [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [movimentacaoProduto]="movimentacaoProduto" [pedidoCarteira]="pedidoCarteira"></tr>
        </ng-container>
      </tbody>
      <tfoot>
        <div class="r-1">
          <th>{{ movimentacao.movimentacaoProdutos ? movimentacao.movimentacaoProdutos.length : 0 }}</th>
        </div>
        <td [colSpan]="pedidoCarteira == true ? 6 : comissao == true ? 5 : 3" class="nao-utilizar-borda"></td>
        <th *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacao.totalDesconto | monetario }}</th>
        <th *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacao.totalValorTotal | monetario }}</th>
        <th *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacao.totalDescontoRateado | monetario }}</th>
        <th *ngIf="comissao == true"  class="text-right">{{ movimentacao.valorComissao | monetario }}</th>
        <th class="text-right">{{ movimentacao.totalValorFinal | monetario }}</th>
        <th *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacao.totalValorFaturado | monetario }}</th>
        <th *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacao.totalValorDesfeitoEstorno | monetario }}</th>
        <th *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacao.totalValorPendente | monetario }}</th>
      </tfoot>
    </table>
    <br/>
  </td>
</ng-container>
