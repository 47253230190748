<ng-container class="border-bottom border-dark">
  <td class="p-0" colspan="14">
    <table class="tabela-complementar">
      <thead>
        <tr>
          <th class="r-1-5"></th>
          <th class="r-0-8 text-right">{{ bibDialogo.janeiroAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.fevereiroAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.marcoAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.abrilAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.maioAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.junhoAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.julhoAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.agostoAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.setembroAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.outubroAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.novembroAbreviacao }}</th>
          <th class="r-0-8 text-right">{{ bibDialogo.dezembroAbreviacao }}</th>
          <th class="r-1 text-right">{{ bibDialogo.total }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoFinanceira of movimentacaoFinanceiras">
          <tr>
            <td class="limitar"><ajuda [ajuda]="movimentacaoFinanceira.parceiro" [ajudaDireita]="true"></ajuda>{{movimentacaoFinanceira.parceiro}}</td>
            <td class="text-right">{{ movimentacaoFinanceira.janeiro == 0 ? '' : (movimentacaoFinanceira.janeiro | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.janeiro == movimentacaoFinanceira.fevereiro || movimentacaoFinanceira.janeiro == 0 || movimentacaoFinanceira.fevereiro == 0 ? '' : movimentacaoFinanceira.janeiro * -1 > movimentacaoFinanceira.fevereiro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.fevereiro == 0 ? '' : (movimentacaoFinanceira.fevereiro | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.fevereiro == movimentacaoFinanceira.marco || movimentacaoFinanceira.fevereiro == 0 || movimentacaoFinanceira.marco == 0 ? '' : movimentacaoFinanceira.fevereiro * -1 > movimentacaoFinanceira.marco * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.marco == 0 ? '' : (movimentacaoFinanceira.marco | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.marco == movimentacaoFinanceira.abril || movimentacaoFinanceira.marco == 0 || movimentacaoFinanceira.abril == 0 ? '' : movimentacaoFinanceira.marco * -1 > movimentacaoFinanceira.abril * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.abril == 0 ? '' : (movimentacaoFinanceira.abril | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.abril == movimentacaoFinanceira.maio || movimentacaoFinanceira.abril == 0 || movimentacaoFinanceira.maio == 0 ? '' : movimentacaoFinanceira.abril * -1 > movimentacaoFinanceira.maio * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.maio == 0 ? '' : (movimentacaoFinanceira.maio | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.maio == movimentacaoFinanceira.junho || movimentacaoFinanceira.maio == 0 || movimentacaoFinanceira.junho == 0 ? '' : movimentacaoFinanceira.maio * -1 > movimentacaoFinanceira.junho * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.junho == 0 ? '' : (movimentacaoFinanceira.junho | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.junho == movimentacaoFinanceira.julho || movimentacaoFinanceira.junho == 0 || movimentacaoFinanceira.junho == 0 ? '' : movimentacaoFinanceira.junho * -1 > movimentacaoFinanceira.julho * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.julho == 0 ? '' : (movimentacaoFinanceira.julho | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.julho == movimentacaoFinanceira.agosto || movimentacaoFinanceira.julho == 0 || movimentacaoFinanceira.agosto == 0 ? '' : movimentacaoFinanceira.julho * -1 > movimentacaoFinanceira.agosto * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.agosto == 0 ? '' : (movimentacaoFinanceira.agosto | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.agosto == movimentacaoFinanceira.setembro || movimentacaoFinanceira.agosto == 0 || movimentacaoFinanceira.setembro == 0 ? '' : movimentacaoFinanceira.agosto * -1 > movimentacaoFinanceira.setembro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.setembro == 0 ? '' : (movimentacaoFinanceira.setembro | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.setembro == movimentacaoFinanceira.outubro || movimentacaoFinanceira.setembro == 0 || movimentacaoFinanceira.outubro == 0 ? '' : movimentacaoFinanceira.setembro * -1 > movimentacaoFinanceira.outubro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.outubro == 0 ? '' : (movimentacaoFinanceira.outubro | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.outubro == movimentacaoFinanceira.novembro || movimentacaoFinanceira.outubro == 0 || movimentacaoFinanceira.novembro == 0 ? '' : movimentacaoFinanceira.outubro * -1 > movimentacaoFinanceira.novembro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.novembro == 0 ? '' : (movimentacaoFinanceira.novembro | monetario) }}</td>
            <td class="text-right" [ngClass]="movimentacaoFinanceira.novembro == movimentacaoFinanceira.dezembro || movimentacaoFinanceira.novembro == 0 || movimentacaoFinanceira.dezembro == 0 ? '' : movimentacaoFinanceira.novembro * -1 > movimentacaoFinanceira.dezembro * -1 ? 'bg-verde-claro' : 'bg-vermelho-claro'">{{ movimentacaoFinanceira.dezembro == 0 ? '' : (movimentacaoFinanceira.dezembro | monetario) }}</td>
            <td class="text-right">{{ movimentacaoFinanceira.total | monetario }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
