import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[r1126telefone]</tr>',
  templateUrl: './r1126telefone.component.html',
})
export class R1126TelefoneComponent {
  @Input() parceiro: any;
  public bibDialogo = bibDialogo;
}
