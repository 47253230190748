import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'vendas', posicao: 2 },
  { campo: 'valorComissao', posicao: 3 },
  { campo: 'valorLiberado', posicao: 4 },
  { campo: 'valorNaoLiberado', posicao: 5 },
  { campo: 'valorPago', posicao: 6 },
  { campo: 'valorAguardando', posicao: 7 },
  { campo: 'valorTotalPagar', posicao: 8 },
];
export default totais;
