import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { format, subMonths } from 'date-fns';
import { Observable, of } from 'rxjs';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';

@Component({
  templateUrl: './pesquisaMovimentacao.component.html',
  styleUrls: ['./pesquisaMovimentacao.component.css'],
})
export class PesquisaMovimentacaoComponent extends PaginaComponent {
  @ViewChild('corpoTabelaMovimentacao') private corpoTabelaMovimentacao: ElementRef;
  public abaSelecionado: boolean = false;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public compacto: boolean = false;
  public consumidorFinal: boolean;
  public criterio: string = '';
  public criterios: Criterio[] = [new Criterio('Nº MOVIMENTAÇÃO', this.criterio), new Criterio('PRODUTO', this.criterio)];
  public ehDevolucao: string = '';
  public ehTransferenciaEntrada: string = '';
  public filtro = this.criterios[0];
  public focoBusca: number;
  public idLoja: number = 0;
  public idLojaDestino: number = 0;
  public idLojaMovimentacao: number;
  public idParceiro: number = 0;
  public modalVinculandoXml: boolean = false;
  public movimentacaoProdutos: MovimentacaoProduto[] = [];
  public movimentacoes: Movimentacao[] = [];
  public nomeParceiro: string;
  public nota: string = '';
  public notaTerceiro: string = '';
  public parceiro: Parceiro;
  public pedido: boolean = false;
  public posicaoMovimentacao: number = -1;
  public saida: boolean;
  public selecionados: MovimentacaoProduto[] = [];
  public util: Util = new Util();
  public vinculo: string = '';
  public naturezaOperacaoFinalidade: number;
  public pedidoCarteira: boolean = false;

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    switch (event.keyCode) {
      case bibAtalho.setaCima:
        event.preventDefault();
        this.marcarLista(-1);
        break;

      case bibAtalho.setaBaixo:
        event.preventDefault();
        this.marcarLista(1);
        break;

      case bibAtalho.enter:
        if (this.selecionados.length > 0 && this.posicaoMovimentacao >= 0) {
          event.preventDefault();
          this.vincularMovimentacaoProduto();
        }
        break;

      case bibAtalho.espaco:
        if (this.movimentacoes.length > 0 && this.posicaoMovimentacao >= 0) {
          event.preventDefault();
          this.setSelecionadoMovimentacao(this.posicaoMovimentacao);
          event.stopImmediatePropagation();
        }
        break;

      case bibAtalho.cancelar:
        event.preventDefault();
        this.focoBusca = Math.random();
        event.stopImmediatePropagation();
        this.posicaoMovimentacao = -1;
        break;
    }
  }

  ngOnInit(): void {
    this.compacto = this.utilSessao.getIdentificacao('compacto')?.conteudo;
    this.ehDevolucao = this.utilSessao.getIdentificacao('ehDevolucao')?.conteudo;
    this.ehTransferenciaEntrada = this.utilSessao.getIdentificacao('ehTransferenciaEntrada')?.conteudo;
    this.idLoja = this.utilSessao.getIdentificacao('idLoja')?.conteudo;
    this.idLojaDestino = this.utilSessao.getIdentificacao('idLojaDestino')?.conteudo;
    this.nota = this.utilSessao.getIdentificacao('nota')?.conteudo;
    this.notaTerceiro = this.utilSessao.getIdentificacao('notaTerceiro')?.conteudo;
    this.idParceiro = this.utilSessao.getIdentificacao('parceiro')?.conteudo;
    this.vinculo = this.utilSessao.getIdentificacao('vinculo')?.conteudo;
    this.modalVinculandoXml = this.utilSessao.getIdentificacao('modalVinculandoXml')?.conteudo;
    this.idLojaMovimentacao = this.utilSessao.getIdentificacao('idLojaMovimentacao')?.conteudo;
    this.saida = this.utilSessao.getIdentificacao('saida')?.conteudo;
    this.pedido = this.utilSessao.getIdentificacao('pedido')?.conteudo;
    this.consumidorFinal = this.utilSessao.getIdentificacao('consumidorFinal')?.conteudo;
    this.nomeParceiro = this.utilSessao.getIdentificacao('nomeParceiro')?.conteudo;
    this.naturezaOperacaoFinalidade = this.utilSessao.getIdentificacao('naturezaOperacaoFinalidade')?.conteudo;
    this.listarParceiro(this.idParceiro);
  }

  listarParceiro(idParceiro: number): void {
    if (idParceiro) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiro)), this.bibServico.parceiro).subscribe((res) => {
        this.parceiro = this.plainToClass(Parceiro, res[0]) as any;
        this.listarMovimentacao();
      });
    } else {
      this.listarMovimentacao();
    }
  }

  listarMovimentacao(): void {
    this.movimentacaoProdutos = [];
    let criterios: Criterio[] = this.getCriterios();
    this.ehBuscaClienteAVista(criterios);
    this.ehAdicionarCriterioConsumidorFinal(criterios);
    this.adicionarCriterio(criterios);
    this.comunicacaoService.listar(new Transporte(criterios, 0, 30), this.bibServico.movimentacao).subscribe((res) => {
      this.limparLista();
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      if (this.movimentacoes.length > 0) {
        this.montarMovimentacaoProduto(this.movimentacoes[0], 0);
      }
    });
  }

  adicionarCriterio(criterios: Criterio[]): void {
    if (this.filtro.valor != '') {
      switch (this.filtro.nome) {
        case 'Nº PRODUTO':
          criterios.push(new Criterio('NUMERO_PRODUTO', this.filtro.valor));
          break;
        case 'Nº MOVIMENTAÇÃO':
          criterios.push(new Criterio('NUMERO', this.filtro.valor));
          break;
        case 'DESCRIÇÃO PRODUTO':
          criterios.push(new Criterio('PRODUTO', this.filtro.valor));
          break;
        case 'Nº CLIENTE':
          criterios.push(new Criterio('NUMERO_CLIENTE', this.filtro.valor));
          break;
      }
    }
  }

  getCriterios(): Criterio[] {
    let criterios: Criterio[] = this.criteriosPadrao();
    this.adicionarCriterioParceiro(criterios);
    this.adicionarCriterioTransferencia(criterios);
    this.adicionarCriterioFiltro(criterios);
    this.adicionarCriterioMovimentacaoOutroResponsavel(criterios);
    return criterios;
  }

  criteriosPadrao(): Criterio[] {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('IDS_VINCULO', this.vinculo));
    criterios.push(new Criterio('STATUS', 'S'));
    criterios.push(new Criterio('DEVOLUCAO', this.ehDevolucao));
    criterios.push(new Criterio('NOTA', this.nota == 'S' || this.notaTerceiro == 'S' ? 'S' : 'N'));
    criterios.push(new Criterio('PEDIDO', this.pedido ? 'S' : 'N'));
    criterios.push(new Criterio('LOJA_USUARIO', this.ehTransferenciaEntrada == 'S' ? null : 'S'));
    criterios.push(new Criterio('TRANSFERENCIA_ENTRADA', this.ehTransferenciaEntrada));
    criterios.push(new Criterio('LOJA_USUARIO_TRANSFERENCIA', this.ehTransferenciaEntrada));
    return criterios;
  }

  adicionarCriterioParceiro(criterios: Criterio[]): void {
    if (this.parceiro) {
      if (this.parceiro.tipoFornecedor === 'S' && this.parceiro.idFamilia && this.naturezaOperacaoFinalidade != 2) {
        criterios.push(new Criterio('FORNECEDOR_FAMILIA', this.parceiro.idFamilia));
      } else {
        if (this.parceiro.permiteFaturamentoCompartilhado == null || this.parceiro.permiteFaturamentoCompartilhado == 'N') {
          criterios.push(new Criterio('ID_PARCEIRO', this.idParceiro));
        }
      }
    }
  }

  adicionarCriterioTransferencia(criterios: Criterio[]): void {
    if (this.ehTransferenciaEntrada == 'S') {
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
      criterios.push(new Criterio('DATA_DE', format(new Date(Date.parse(loja.dataInicioSistema.toString())), 'yyyy-MM-dd')));
      criterios.push(new Criterio('ID_LOJA_DESTINO', this.idLoja));
      criterios.push(new Criterio('ID_LOJA', this.idLojaDestino));
    } else {
      criterios.push(new Criterio('ID_LOJA', this.idLoja));
    }
  }

  adicionarCriterioFiltro(criterios: Criterio[]): void {
    if (this.filtro.valor != '') {
      this.alterarValorFiltro(this.filtro.valor);
      this.adicionarCriterio(criterios);
    }
  }

  adicionarCriterioMovimentacaoOutroResponsavel(criterios: Criterio[]): void {
    if (this.utilSessao.getUsuario().permitir != 'S') {
      criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().idColaborador));
    }
  }

  ehBuscaClienteAVista(criterios: Criterio[]): void {
    if (this.saida && this.pedido && this.consumidorFinal) {
      let dataAtual: Date = subMonths(new Date(), 1);
      criterios.push(new Criterio('DATA_DE', format(new Date(Date.parse(dataAtual.toString())), 'yyyy-MM-dd')));
    }
  }

  ehAdicionarCriterioConsumidorFinal(criterios: Criterio[]): void {
    if (this.consumidorFinal) {
      criterios.push(new Criterio('NOME_PARCEIRO', this.nomeParceiro));
    }
  }

  alterarValorFiltro(valor: string): void {
    this.filtro.valor = typeof valor === 'object' ? '' : valor;
    this.filtro.nome = Number(valor) || Number(valor) == 0 ? (this.filtro.nome == 'Nº PRODUTO' ? 'Nº PRODUTO' : this.filtro.nome == 'Nº MOVIMENTAÇÃO' ? 'Nº MOVIMENTAÇÃO' : 'Nº CLIENTE') : 'DESCRIÇÃO PRODUTO';
  }

  limparLista(): void {
    this.movimentacoes = [];
    this.posicaoMovimentacao = -1;
  }

  listarMovimentacaoProduto(movimentacao: Movimentacao): Observable<any> {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_MOVIMENTACAO', movimentacao.id));
    criterios.push(new Criterio('DEVOLUCAO', this.ehDevolucao));
    criterios.push(new Criterio('NOTA', this.nota == 'S' || this.notaTerceiro == 'S' ? 'S' : 'N'));
    criterios.push(new Criterio('TRANSFERENCIA_ENTRADA', this.ehTransferenciaEntrada));
    criterios.push(new Criterio('IGNORAR_PERSONALIZADO', 'S'));
    this.adicionarCriterio(criterios);
    const resposta: Observable<any> = this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoProduto);
    resposta.subscribe((res) => {
      movimentacao.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      this.listarMovimentacaoQuantidade(movimentacao);
    });
    return resposta;
  }

  listarMovimentacaoQuantidade(movimentacao: Movimentacao): void {
    let idsMovimentacaoPro: string = '';
    movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => (idsMovimentacaoPro = this.util.concatenar(idsMovimentacaoPro, movimentacaoProduto.id)));
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('IDS_MOVIMENTACAO_PRO', idsMovimentacaoPro));
    criterios.push(new Criterio('STATUS', "'S', 'U'"));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoQuantidade).subscribe((res) => {
      this.vincularQuantidadeProduto(this.plainToClass(MovimentacaoQuantidade, res) as any, movimentacao);
    });
  }

  vincularQuantidadeProduto(movimentacaoQuantidades: MovimentacaoQuantidade[], movimentacao: Movimentacao): void {
    movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
        if (movimentacaoProduto.id == movimentacaoQuantidade.idMovimentacaoProduto) {
          movimentacaoProduto.movimentacaoQuantidades.push(this.plainToClass(MovimentacaoQuantidade, movimentacaoQuantidade));
        }
      });
    });
    this.movimentacaoProdutos = movimentacao.movimentacaoProdutos;
  }

  montarMovimentacaoProduto(movimentacao: Movimentacao, posicao: number): Observable<any> {
    const IDENTIFICACAO_PEDIDO: number = 2;
    if (movimentacao.identificacao == IDENTIFICACAO_PEDIDO && movimentacao.financeiro == 'N') {
      this.pedidoCarteira = true;
    } else {
      this.pedidoCarteira = false;
    }
    this.movimentacaoProdutos = [];
    this.posicaoMovimentacao = posicao;
    if (movimentacao.movimentacaoProdutos.length == 0) {
      return this.listarMovimentacaoProduto(movimentacao);
    } else {
      this.movimentacaoProdutos = movimentacao.movimentacaoProdutos;
    }
    return of({});
  }

  vincularMovimentacaoProduto(): void {
    const movimentacoesVinculadas: Movimentacao[] = this.movimentacoes.filter((movimentacao) => movimentacao.selecionado == 'S');
    if (movimentacoesVinculadas.filter((movimentacao) => movimentacao.idLoja == this.idLojaMovimentacao).length != movimentacoesVinculadas.length && !this.ehTransferenciaEntrada) {
      if (confirm(this.bibDialogo.existemItensDeOutraLoja)) {
        this.emitirMovimentacaoProduto(movimentacoesVinculadas);
      }
    } else {
      this.emitirMovimentacaoProduto(movimentacoesVinculadas);
    }
  }

  emitirMovimentacaoProduto(movimentacoes: Movimentacao[]): void {
    if ((this.nota || this.notaTerceiro) && movimentacoes.length == 1) {
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibDialogo.observacao, movimentacoes[0].observacao));
    }
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.movimentacaoProduto, this.selecionados));
    this.selecionados = [];
  }

  setSelecionadoMovimentacao(posicao: number, selecionado: string = null): void {
    const movimentacao = this.movimentacoes[posicao];
    if (selecionado == null) {
      selecionado = movimentacao.selecionado == 'S' ? 'N' : 'S';
    }
    movimentacao.selecionado = selecionado;
    this.setMovimentacaoSelecionado(movimentacao, posicao);
  }

  setSelecionadoMovimentacaoProduto(movimentacaoProduto: MovimentacaoProduto, selecionado: string = null): void {
    movimentacaoProduto.selecionado = selecionado;
    this.setSelecionados(movimentacaoProduto);
    this.setMovimentacaoIndeterminado(movimentacaoProduto.idMovimentacao);
  }

  setMovimentacaoIndeterminado(idMovimentacao: number): void {
    this.movimentacoes
      .filter((movimentacao) => movimentacao.id == idMovimentacao)
      .forEach((movimentacao) => {
        movimentacao.indeterminado = false;
        movimentacao.selecionado = 'N';
        let quantidadeSelecionado: number = 0;
        movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
          if (movimentacaoProduto.selecionado == 'S') {
            quantidadeSelecionado++;
          }
        });
        if (quantidadeSelecionado > 0 && movimentacao.movimentacaoProdutos.length > quantidadeSelecionado) {
          movimentacao.indeterminado = true;
        }
        if (movimentacao.movimentacaoProdutos.length == quantidadeSelecionado) {
          movimentacao.selecionado = 'S';
        }
      });
  }

  setMovimentacaoSelecionado(movimentacao: Movimentacao, posicao: number): void {
    this.montarMovimentacaoProduto(movimentacao, posicao).subscribe(() => {
      movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.selecionado = movimentacao.selecionado;
        this.setSelecionados(movimentacaoProduto);
      });
    });
  }

  setSelecionados(movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.selecionado == 'S') {
      let movimentacaoProdutoSelecionado: boolean = false;
      this.selecionados.forEach((selecionado) => {
        if (selecionado.id == movimentacaoProduto.id) {
          movimentacaoProdutoSelecionado = true;
        }
      });
      if (!movimentacaoProdutoSelecionado) {
        this.selecionados.push(this.plainToClass(MovimentacaoProduto, movimentacaoProduto));
      }
    } else {
      let posicao: number = 0;
      for (let selecionado of this.selecionados) {
        if (selecionado.id == movimentacaoProduto.id) {
          this.selecionados.splice(posicao, 1);
        }
        posicao++;
      }
    }
  }

  excluirSelecionado(movimentacaoProduto: MovimentacaoProduto): void {
    movimentacaoProduto.selecionado = 'N';
    this.setSelecionados(movimentacaoProduto);
    this.movimentacoes.forEach((movimentacao) => {
      movimentacao.movimentacaoProdutos.forEach((movimentacaoProdutoAntigo) => {
        if (movimentacaoProdutoAntigo.id == movimentacaoProduto.id) {
          this.setSelecionadoMovimentacaoProduto(movimentacaoProdutoAntigo, 'N');
        }
      });
    });
  }

  marcarLista(incremento: number): void {
    if (this.movimentacoes.length > 0) {
      this.posicaoMovimentacao = this.util.marcarLista(this.posicaoMovimentacao, incremento, this.movimentacoes);
      this.montarMovimentacaoProduto(this.movimentacoes[this.posicaoMovimentacao], this.posicaoMovimentacao);
      const scroll: number = 21.39 * incremento;
      this.corpoTabelaMovimentacao.nativeElement.scrollTop += scroll;
      if (this.posicaoMovimentacao >= 0) {
        Promise.resolve(null).then(() => this.corpoTabelaMovimentacao.nativeElement.focus());
      }
    }
  }
}
