import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015EntregaProdutoMovimentacaoProduto',
  templateUrl: './r2015EntregaProdutoMovimentacaoProduto.component.html',
})
export class R2015EntregaProdutoMovimentacaoProduto {
  @Input() entregaProdutoMovimentacaoProdutos: any[] = [];
  public bibDialogo = bibDialogo;
}
