import { Component } from '@angular/core';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'contaCorrenteFrm',
  templateUrl: './contaCorrenteFrm.component.html',
})
export class ContaCorrenteFrmComponent extends PaginaComponent {
  public bloqueado: boolean = false;
  public categorias: any[];
  public financeiro: Financeiro = new Financeiro();
  public tipos: any[];

  ngOnInit(): void {
    this.financeiro = this.util.coalesce(this.utilSessao.getIdentificacao('financeiro')?.conteudo, new Financeiro());
    this.tipos = this.utilSessao.getIdentificacao('tipos')?.conteudo;
    this.categorias = this.utilSessao.getIdentificacao('categorias')?.conteudo;
    this.bloqueado = (!this.temPermissaoEdicao && this.financeiro.id != null) || (this.financeiro.id && this.financeiro.valorRestante == 0);
  }

  setNome(financeiro: Financeiro, parceiro: Parceiro): void {
    financeiro.idParceiro = parceiro.id;
    financeiro.parceiro = parceiro.razaoSocial;
    financeiro.nomeParceiro = parceiro.razaoSocial;
  }

  salvarFinanceiro(): void {
    if (this.ehValido()) {
      this.financeiro.alterado = true;
      if (this.financeiro.id) {
        this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.financeiro, this.financeiro));
      } else {
        this.financeiro.valorRestante = this.financeiro.valorFinal;
        this.financeiro.dataVencimento = this.financeiro.dataNegociacao;
        this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.financeiro, this.financeiro));
      }
    }
  }

  ehValido(): boolean {
    return this.ehValidoObrigatorio() && this.ehValidoValor() && this.ehValidoDataVencimento();
  }

  ehValidoObrigatorio(): boolean {
    if (this.financeiro.dataNegociacao && this.financeiro.dataVencimento && this.financeiro.parcela && this.financeiro.idLoja && this.financeiro.idParceiro && this.financeiro.idConta) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoValor(): boolean {
    if (this.financeiro.valor && this.financeiro.valor > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorDeveSerMaiorQueZero));
    return false;
  }

  ehValidoDataVencimento(): boolean {
    if (this.financeiro.dataVencimento < this.financeiro.dataNegociacao) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.vencimentoMenorNegociacao));
      return false;
    }
    return true;
  }

  setValor(valor: number): void {
    this.financeiro.valor = valor;
    this.financeiro.valorFinal = valor;
  }

  setAcrescimo(valor: number): void {
    this.financeiro.multa = valor ? valor : 0;
    this.financeiro.valorFinal = this.financeiro.valor - this.financeiro.desconto + (valor ? valor : 0);
  }

  setDesconto(valor: number): void {
    this.financeiro.desconto = valor ? valor : 0;
    this.financeiro.valorFinal = this.financeiro.valor + this.financeiro.multa - (valor ? valor : 0);
  }

  setDataNegociacao(financeiro: Financeiro, data: Date): void {
    let historicoDataNegociacao: Date = financeiro.dataNegociacao;
    financeiro.dataNegociacao = data;
    if (financeiro.dataVencimento == null || financeiro.dataVencimento == historicoDataNegociacao) {
      financeiro.dataVencimento = financeiro.dataNegociacao;
    }
  }
}
