import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import filtros2005 from './acessorio/filtro';
import { Filtro } from 'src/app/modelo/filtro';

@Component({
  selector: 'r2005',
  templateUrl: './r2005.component.html',
  styleUrls: ['./r2005.component.css'],
})
export class R2005Component extends RelatorioComponent {
  @Input() filtros: Filtro[] = filtros2005;
  public ano: number;
  public bibPropriedade = bibPropriedade;
  public contaContabeis: any[] = [];
  public todasContasContabeis: any[] = [];
  public contaContabilTotais: any[] = [];
  public correntistas: any[] = [];
  public correntistasParceiros: any[] = [];
  public contaContabilPatrimonialDRe: any[] = [];
  public responsavelContador: any[] = [];
  public mes: number;
  public nivelCriterio: number = 10;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public usaContaContabilZerada: boolean = false;
  public ocultarSaldoAnterior: boolean = false;
  public ehRelatorio2005: boolean = false;
  public ehRelatorio2007: boolean = false;
  public ehRelatorio2010: boolean = false;
  public parametro: number;
  public totalGeral: number = 0;
  public contaContabilNomes: string;
  public dataRodape: string = '';
  public meses: string[] = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.idRelatorio == 2005) {
        this.ehRelatorio2005 = true;
      }
      if (params.idRelatorio == 2007) {
        this.ehRelatorio2007 = true;
      }
      if (params.idRelatorio == 2010) {
        this.ehRelatorio2010 = true;
      }
    });
  }

  listarRelatorio(criterios: Criterio[]): void {
    if (criterios.length > 0) {
      this.mes = criterios.find((criterio) => criterio.nome == 'MES').valor;
      this.ano = criterios.find((criterio) => criterio.nome == 'ANO').valor;
      this.nivelCriterio = criterios.find((criterio) => criterio.nome == 'NIVEL').valor;
    }
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios), false).subscribe(() => {
      this.contaContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ContaContabil').lista;
      this.contaContabilTotais = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'LancamentoContabil').lista;
      this.correntistas = []; // this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Correntista').lista;
      this.contaContabilPatrimonialDRe = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ContaContabilPatrimonialDRe').lista;
      this.responsavelContador = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ResponsavelContador').lista;
      this.totalizar();
      this.totalizarPai();
      this.todasContasContabeis = this.contaContabeis;
      this.contaContabeis = this.contaContabeis.filter((contaContabil) => contaContabil.clienteFornecedor != 'S');
      this.contaContabeis = this.contaContabeis.filter((contaContabil) => contaContabil.saldoAtual != 0);
    });
  }

  nomeclaturaBalancoPatrimonialDRe() {
    let quantidadeRegistro: number = 1;
    for (let patrimonialDRe of this.contaContabilPatrimonialDRe) {
      let totalpatrimonialDRe: any[] = this.contaContabeis.filter((contaContabil) => contaContabil.nome == patrimonialDRe.nome);
      patrimonialDRe.valor = totalpatrimonialDRe[0].debito - totalpatrimonialDRe[0].credito;
      if (this.contaContabilNomes == '') {
        this.contaContabilNomes = patrimonialDRe.nome;
      } else if (quantidadeRegistro == this.contaContabilPatrimonialDRe.length) {
        this.contaContabilNomes += ' E ' + patrimonialDRe.nome;
      } else {
        this.contaContabilNomes += ', ' + patrimonialDRe.nome;
      }
      quantidadeRegistro += 1;
    }
  }

  totalizar(): void {
    this.contaContabeis.forEach((contaContabil) => {
      let contaContabilTotal: any = this.contaContabilTotais.filter((contaContabilTotal) => contaContabilTotal.idContaContabil == contaContabil.id)[0];
      if (contaContabilTotal != null) {
        contaContabil.saldoAnterior += contaContabilTotal.saldoAnterior != undefined ? contaContabilTotal.saldoAnterior : 0;
        contaContabil.saldoAtual += contaContabilTotal.saldoAtual != undefined ? contaContabilTotal.saldoAtual : 0;
        contaContabil.debito += contaContabilTotal.debito != undefined ? contaContabilTotal.debito : 0;
        contaContabil.credito += contaContabilTotal.credito != undefined ? contaContabilTotal.credito : 0;
      }
    });
  }

  totalizarPai(): void {
    this.totalGeral = 0;
    this.contaContabilNomes = '';
    this.contaContabeis.sort((a, b) => (Number(a['nivel']) === Number(b['nivel']) ? 0 : Number(a['nivel']) > Number(b['nivel']) ? -1 : 1));
    this.contaContabeis.forEach((contaContabil) => {
      if (contaContabil.pai == 'S') {
        let contasFilhas = this.contaContabeis.filter((contaContabilBusca) => contaContabilBusca.idContaContabil == contaContabil.id);
        if (contasFilhas) {
          contasFilhas.forEach((contaFilha) => {
            contaContabil.saldoAnterior += contaFilha.saldoAnterior;
            contaContabil.saldoAtual += contaFilha.saldoAtual;
            contaContabil.debito += contaFilha.debito;
            contaContabil.credito += contaFilha.credito;
          });
        }
      } else {
        this.totalGeral += this.util.coalesce(contaContabil.credito - contaContabil.debito, 0);
      }
    });

    this.contaContabeis.sort((a, b) => (a['numeracao'] === b['numeracao'] ? 0 : a['numeracao'] > b['numeracao'] ? 1 : -1));
    this.contaContabeis = this.contaContabeis.filter((contaContabilTotal) => Number(this.nivelCriterio) >= Number(contaContabilTotal.nivel));
    const PARCEIRO: number = 2;
    //  this.correntistasParceiros = this.correntistas.filter((correntista) => (correntista.correntista == PARCEIRO && correntista.saldoAtual != 0) || correntista.saldoAnterior != 0 || correntista.debito != 0 || correntista.credito != 0);
    // this.correntistas = this.correntistas.filter((correntista) => Number(this.nivelCriterio) + Number(5) > Number(correntista.nivelCorrentista) && correntista.correntista != PARCEIRO);
    this.inicializarListaContaContabeis(this.usaContaContabilZerada);
    //this.vincularCorrentista();
    this.ehImprimirRelatorio();
    this.nomeclaturaBalancoPatrimonialDRe();
    if (this.responsavelContador.length > 0) {
      this.dataRodape = this.formatarData(this.responsavelContador[0].cidade, new Date());
    }
  }

  vincularCorrentista(): void {
    this.contaContabeis.forEach((contaContabil) => {
      contaContabil.correntistasParceiros = this.correntistasParceiros.filter((correntista) => correntista.idContaContabil == contaContabil.id);
      //  contaContabil.correntistas = this.correntistas.filter((correntista) => correntista.idContaContabil == contaContabil.id);
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaContaContabilZerada = this.definirRegra(relatorioAdicional, 217, this.usaContaContabilZerada);
    this.ocultarSaldoAnterior = this.definirRegra(relatorioAdicional, 222, this.ehRelatorio2005 ? true : this.ocultarSaldoAnterior);
    this.inicializarListaContaContabeis(this.usaContaContabilZerada);
  }

  inicializarListaContaContabeis(utilizarContaContabilZerada: boolean) {
    if (utilizarContaContabilZerada || !this.ehRelatorio2005) {
      //   this.contaContabeis = this.todasContasContabeis;
    } else {
      this.contaContabeis = this.contaContabeis.filter((contaContabil) => contaContabil.clienteFornecedor != 'S').filter((contaContabil) => contaContabil.saldoAtual != 0); // this.contaContabilTotais.filter((contaContabil) => contaContabil.saldoAtual != 0 || contaContabil.saldoAnterior != 0 || contaContabil.debito != 0 || contaContabil.credito != 0);
    }
  }

  expandir(contaContabil: any) {
    contaContabil.expandido = !contaContabil.expandido;
    if (contaContabil.expandido && (contaContabil.correntistasParceiros == null || contaContabil.correntistasParceiros.length == 0)) {
      this.listarEspecifico(new Criterio('ID_CONTA_CONTABIL', 1), 154).subscribe((res) => {
        contaContabil.correntistasParceiros = this.plainToClass(RelatorioResultado, res)[0].lista;
      });
    }
  }

  formatarData(cidade: string, data: Date): string {
    let dia: number = data.getDate();
    let mes: number = data.getMonth();
    let ano: number = data.getFullYear();
    return cidade.toUpperCase() + ', ' + (dia > 9 ? dia : 0 + dia) + ' de ' + this.meses[mes] + ' de ' + ano;
  }
}
