<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(produtos, $event)" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-2 text-right">{{ bibDialogo.precoVenda }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let produto of produtos; let i = index">
        <tr class="conteudo sem-borda">
          <td>{{ produto.nome }}</td>
          <td class="text-right">{{ produto.precoVenda | monetario }}</td>
        </tr>
        <tr r1105tabelas [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [produto]="produto"></tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>
