import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import bibIcone from 'src/app/biblioteca/bibIcone';

@Component({
  selector: ' <tr>[r2003itens]</tr>',
  templateUrl: './r2003itens.component.html',
})
export class R2003ItensComponent {
  @Input() produto: any;
  @Input() tipoSugestao: string;
  @Input() empresaRegraCasasDecimais: number;
  @Input() empresaRegraCasasDecimaisEntrada: number;
  @Input() quantidadeDia: number = 0;
  
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();

  definirRegraDia(): string {
    return this.bibDialogo.calculoHistorico.replace(' = _', '').replace('_', this.quantidadeDia.toString());  
  }  

  definirRegraDiaMaiorMedia(loja: any): string {
    return this.bibDialogo.calculoHistorico.replace('_', loja.maiorMediaRegra).replace('_', this.quantidadeDia.toString());  
  }   
}
