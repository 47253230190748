import { Type } from 'class-transformer';
import { ParceiroArt } from './parceiroArt';
import { ParceiroAtualizacao } from './parceiroAtualizacao';
import { ParceiroCobranca } from './parceiroCobranca';
import { ParceiroContato } from './parceiroContato';
import { ParceiroDestino } from './parceiroDestino';
import { ParceiroEndereco } from './parceiroEndereco';
import { ParceiroHistoricoLimite } from './parceiroHistoricoLimite';
import { ParceiroNegociacao } from './parceiroNegociacao';
import { ParceiroOcorrencia } from './parceiroOcorrencia';
import { ParceiroTelefone } from './parceiroTelefone';
import { ParceiroVeiculo } from './parceiroVeiculo';

export class Parceiro {
  @Type(() => Date)
  public dataNascimento: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;

  public abreviacaoLoja: string;
  public alterado: boolean;
  public arquivo: string;
  public ativo: string = 'S';
  public check: string = 'N';
  public classificacao: string;
  public cnpjCpf: string;
  public codigoInterno: string;
  public comissionado: string = 'N';
  public contaContabil: string;
  public contaContabilFornecedor: string;
  public contribuinte: number = 9;
  public crea: string;
  public credito: number;
  public creditoEmpresa: number;
  public descontoMaximo: number = 0;
  public diaFechamento: number = null;
  public dinamicaPagamento: number;
  public ehValidoCnpjCpf: boolean = true;
  public email: string;
  public enderecoCompleto: string;
  public estadoCivil: number;
  public familia: string;
  public id: number;
  public idClienteEspecial: number;
  public idContaContabil: number;
  public idContaContabilFornecedor: number;
  public idFamilia: number;
  public idLoja: number;
  public idNegociacao: number;
  public idParceiroDestino: number;
  public idParceiroFaturamento: number;
  public idParceiroVendedor: number;
  public idRamoAtividade: number;
  public idRelacionamento: number;
  public idTipoTitulo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public identidade: string;
  public indeterminate: boolean = false;
  public inscricaoEstadual: string;
  public juroPercentual: number;
  public limiteAtual: number;
  public limiteAtualFamilia: number;
  public limiteCredito: number = 0;
  public limiteDisponivel: number = 0;
  public limiteFamilia: number;
  public limiteMaximo: number = 0;
  public linhaSelecionada: boolean = false;
  public mostrarAlerta: string = 'N';
  public nomeFantasia: string;
  public nomeParceiroVendedor: string;
  public numero: number;
  public numeroNomeParceiro: string;
  public observacao: string;
  public observacaoInterna: string;
  public parceiroArts: ParceiroArt[] = [];
  public parceiroAtualizacoes: ParceiroAtualizacao[] = [];
  public parceiroContatos: ParceiroContato[] = [];
  public parceiroDestinos: ParceiroDestino[] = [];
  public parceiroEnderecos: ParceiroEndereco[] = [];
  public parceiroFaturamento: string;
  public parceiroHistoricoLimites: ParceiroHistoricoLimite[] = [];
  public parceiroNegociacoes: ParceiroNegociacao[] = [];
  public parceiroOcorrencias: ParceiroOcorrencia[] = [];
  public parceiroTelefones: ParceiroTelefone[] = [];
  public parceiroVeiculos: ParceiroVeiculo[] = [];
  public parceiroCobrancas: ParceiroCobranca[] = [];
  public parceiroVendedor: string;
  public percentualComissao: number = 0;
  public percentualComissaoVd: number = 0;
  public prazoFixo: number;
  public profissao: string;
  public ramoAtividade: string;
  public razaoSocial: string;
  public receitaMensal: number = 0;
  public registroDuplicado: string = 'N';
  public sexo: string;
  public tipoCliente: string = 'N';
  public tipoColaborador: string = 'N';
  public tipoFornecedor: string = 'N';
  public tipoNegociacao: string = 'A';
  public tipoPessoa: string = this.tipoFornecedor == 'N' ? 'F' : 'J';
  public tipoTransportadora: string = 'N';
  public valorPerda: number = 0;
  public usaLimiteCredito: string = 'N';
  public agronomoTecnico: string = 'N';
  public cidade: string;
  public uf: string;
  public negociacao: string;
  public mostrarCampoFaturarMatriz: string = 'N';
  public contaContabilEspecifica: string;
  public idContaContabilEspecifica: number;
  public vendedor: string = 'N';
  public comprador: string = 'N';
  public interno: string = 'N';
  public avalista: string;
  public permiteFaturamentoCompartilhado: string = 'N';
  public relacionamento: string;
  public placa: string;
  public placaIdEstado: number;
  public cnpjIntegracao: string;
}
