import { Component, Input } from '@angular/core';
import { RelatorioGrupoUsuario } from 'src/app/modelo/relatorioGrupoUsuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'grupoPermissaoRelatoriosFrm',
  templateUrl: './grupoPermissaoRelatoriosFrm.component.html',
  styleUrls: ['./grupoPermissaoRelatoriosFrm.component.css'],
})
export class GrupoPermissaoRelatoriosFrmComponent extends PaginaComponent {
  public campoPesquisa: string = this.bibDialogo.numero;
  public filtro: string = '';
  public relatorios: RelatorioGrupoUsuario[] = [];

  public listaCampoPesquisa: any[] = [
    { id: this.bibDialogo.numero, nome: this.bibDialogo.numero },
    { id: this.bibDialogo.nome, nome: this.bibDialogo.nome },
    { id: this.bibDialogo.semAcesso, nome: this.bibDialogo.semAcesso },
    { id: this.bibDialogo.comAcesso, nome: this.bibDialogo.comAcesso },
  ];

  public _relatorioGrupoUsuarios: RelatorioGrupoUsuario[] = [];
  @Input()
  set relatorioGrupoUsuarios(relatorioGrupoUsuarios: RelatorioGrupoUsuario[]) {
    this._relatorioGrupoUsuarios = relatorioGrupoUsuarios;
    this.relatorios = this._relatorioGrupoUsuarios;
  }

  setCampoPesquisa(valor: string): void {
    this.campoPesquisa = valor;
    if (this.campoPesquisa == this.bibDialogo.semAcesso || this.campoPesquisa == this.bibDialogo.comAcesso) {
      this.filtro = '';
    }
  }

  alterarValorFiltro(valor: string): void {
    if (this.campoPesquisa != this.bibDialogo.numero && Number(valor)) {
      this.campoPesquisa = this.bibDialogo.numero;
    } else {
      if (this.campoPesquisa == this.bibDialogo.numero && !Number(valor)) {
        this.campoPesquisa = this.bibDialogo.nome;
      }
    }
    this.setFiltro(valor);
  }

  setFiltro(filtro: string): void {
    this.filtro = filtro;
    if (this.filtro != null) {
      if (this.campoPesquisa == this.bibDialogo.nome) {
        this._relatorioGrupoUsuarios = this.relatorios.filter((relatorio) => relatorio.relatorio.toLowerCase().includes(this.filtro.toLowerCase()));
      } else if (this.campoPesquisa == this.bibDialogo.numero) {
        this._relatorioGrupoUsuarios = this.relatorios.filter((relatorio) => relatorio.idRelatorio.toString() == this.filtro);
      }
    } else {
      this._relatorioGrupoUsuarios = this.relatorios;
    }
  }
}
