import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.responsavel, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, obrigatorio: 'S', tipo: bibPropriedade.filtro.busca },
]);
export default filtros;
