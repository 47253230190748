import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r1233.component.html',
})
export class R1233Component extends RelatorioComponent {
  public movimentacoes: any[] = [];
  public movimentacaoProdutos: any[] = [];
  public filtros = filtros;
  public duasVias: boolean = false;
  public avalista: boolean = false;
  public produtos: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.movimentacoes = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Movimentacao').lista;
      this.movimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.vincular();
      this.atualizarRelatorioAdicionais();
      this.ehImprimirRelatorio();
    });
  }

  atualizarRelatorioAdicionais(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      this.definirRegraRelatorio(relatorioAdicional);
    });
  }

  vincular(): void {
    if (this.movimentacoes.length > 0 && this.movimentacaoProdutos.length > 0) {
      this.movimentacoes.forEach((movimentacao) => {
        if (this.movimentacaoProdutos.length > 0) {
          movimentacao.movimentacaoProdutos = this.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == movimentacao.idMovimentacao);
          movimentacao.valorTotalBrutoProduto = movimentacao.movimentacaoProdutos.reduce((soma, movimentacaoProduto) => soma + movimentacaoProduto.valorTotalBruto, 0);
          if (movimentacao.valorTotalBrutoProduto - movimentacao.descontoTotal < movimentacao.valorTotal) {
            movimentacao.encargos = movimentacao.valorTotal - (movimentacao.valorTotalBrutoProduto - movimentacao.descontoTotal);
          }
        }
      });
    }
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.duasVias = this.definirRegra(relatorioAdicional, 221, this.duasVias);
    this.avalista = this.definirRegra(relatorioAdicional, 255, this.avalista);
    this.produtos = this.definirRegra(relatorioAdicional, 256, this.produtos);
  }
}
