<layout [titulo]="bibDialogo.mdfe" [id]="mdfe.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm-3" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="mdfe.numero" (alteracao)="mdfe.numero = $event" [desabilitado]="true"></texto>
    <data class="col-sm-4" [id]="'data'" [rotulo]="bibDialogo.data" [campo]="mdfe.data" (alteracao)="mdfe.data = $event" [obrigatorio]="true" [desabilitado]="bloqueado"></data>
  </div>
  <div *ngIf="visualizaLoja" class="row">
    <lista class="col-sm-12" [id]="'loja'" [rotulo]="bibDialogo.loja" [campo]="mdfe.idLoja" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true" (alteracao)="setLoja($event)" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()" [ajudaDireita]="true" [ajuda]="bibDialogo.lojaCadastro" [desabilitado]="bloqueado" [foco]="true"></lista>
  </div>
  <div class="row">
    <busca class="col-sm-12" [id]="'cidade'" [rotulo]="bibDialogo.cidade + ' ' + bibDialogo.final " [campo]="mdfe.cidadeFinal" (alteracao)="setCidadeFinal($event)" [servico]="bibServico.cidade" [obrigatorio]="true" [desabilitado]="bloqueado"></busca>
  </div>
  <div class="row">
    <titulo class="col-sm-12" [titulo]="bibDialogo.transporte" [imagem]="bibImagem.transporte"></titulo>
    <busca class="col-sm-12" [id]="'transportadora'" [idMenu]="menuTransportadora.idMenu" [rotulo]="menuTransportadora.apelido" [campo]="mdfe.parceiroTransportadora" (alteracao)="setTransportadora($event)" [desabilitado]="bloqueado" [servico]="bibServico.transportadora" [obrigatorio]="true" [atencao]="'3'"></busca>
    <lista class="col-sm-4" [rotulo]="bibDialogo.veiculo" [campo]="mdfe.idVeiculo" (alteracao)="setVeiculo($event)" [lista]="parceiroVeiculos" [desabilitado]="bloqueado" [obrigatorio]="true"> </lista>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.uf + ' ' + bibDialogo.placa.toLowerCase()" [campo]="mdfe.placaUf"></apresentacao>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.placa" [campo]="mdfe.placa"></apresentacao>
    <texto class="col-sm-8" [id]="'motoristaNome'" [rotulo]="bibDialogo.motorista" [campo]="mdfe.motoristaNome" (alteracao)="mdfe.motoristaNome = $event" [desabilitado]="bloqueado" [obrigatorio]="true"></texto>
    <mascara class="col-sm-4" [id]="'motoristaCpf'" [rotulo]="bibDialogo.cpf + ' ' + bibDialogo.motorista.toLowerCase()" [campo]="mdfe.motoristaCpf" (alteracao)="setCpf(mdfe, $event)" [obrigatorio]="true" [tipo]="bibPropriedade.mascara.cpf" [atencao]="!mdfe.ehValidoCpf && mdfe.motoristaCpf ? bibDialogo.incorreto : null" [obrigatorio]="true" [desabilitado]="bloqueado"></mascara>
  </div>
  <div class="row">
    <mdfeDocumentoFiscal [mdfe]="mdfe" [excluirDocumentosFiscais]="excluirDocumentosFiscais" [bloqueado]="bloqueado"></mdfeDocumentoFiscal>
  </div>
  <div class="row">
    <mdfePercurso [mdfe]="mdfe" [excluirPercursos]="excluirPercursos" [bloqueado]="bloqueado"></mdfePercurso>
  </div>
  <titulo class="col-sm-12" [titulo]="bibDialogo.observacao" [imagem]="bibImagem.aspas"></titulo>
  <descricao class="col-sm-12" [campo]="mdfe.informacaoComplementar" (alteracao)="mdfe.informacaoComplementar = $event" [id]="'observação'" [desabilitado]="bloqueado" [maximoCaracteres]="5000"></descricao>
</layout>
<bloqueado [bloqueado]="mdfe.status == 'S' || mdfe.ativo != 'S'"></bloqueado>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirMdfe()" [desabilitado]="bloqueado"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.finalizar" [fundo]="true" (botaoEmt)="finalizar()" [desabilitado]="bloqueado"></botao>
<auditoria *ngIf="mdfe.id" [idUsuarioInclusao]="mdfe.idUsuarioInclusao" [dataHoraInclusao]="mdfe.dataHoraInclusao" [idUsuarioAlteracao]="mdfe.idUsuarioAlteracao" [dataHoraAlteracao]="mdfe.dataHoraAlteracao"></auditoria>
