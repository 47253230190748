export class LogDetalhe {
  public campo: string;
  public antes: string;
  public depois: string;

  constructor(campo: string, antes: string, depois: string) {
    this.campo = campo;
    this.antes = antes;
    this.depois = depois;
  }
}
