<layout [titulo]="objeto.nome" [id]="objeto.id" [filtros]="filtros">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l">{{ bibDialogo.colaborador }}</th>
        <th class="l-30">{{ bibDialogo.comissao }}</th>
        <th class="l-30" *ngIf="utilSessao.sistema == 'aGrow-w'">{{ bibDialogo.comissao + ' VD' }}<ajuda class="pl-1" [ajuda]="bibDialogo.ajudaVendaDireta"></ajuda></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let parceiroComissao of parceiroComissoes; let i = index">
        <td class="l-70">{{ parceiroComissao.colaborador }}</td>
        <td class="l-30">
          <decimal [id]="'parceiro' + i" [desabilitado]="!temPermissaoEdicao" *ngIf="parceiroComissao.comissionado == 'S'" [campo]="parceiroComissao.percentual" [sufixo]="'%'" (alteracao)="parceiroComissao.percentual = $event" [foco]="i == 0" [valorMaximo]="100"></decimal>
          <apresentacao [id]="bibDialogo.adicionar + i" *ngIf="parceiroComissao.comissionado != 'S'" [campo]="bibDialogo.naoComissionado"></apresentacao>
        </td>
        <td *ngIf="utilSessao.sistema == 'aGrow-w'" class="l-30">
          <decimal [id]="'parceiroVd' + i" [desabilitado]="!temPermissaoEdicao" *ngIf="parceiroComissao.comissionado == 'S'" [campo]="parceiroComissao.percentualVd" [sufixo]="'%'" (alteracao)="parceiroComissao.percentualVd = $event" [foco]="i == 0" [valorMaximo]="100"></decimal>
          <apresentacao [id]="bibDialogo.adicionar + i" *ngIf="parceiroComissao.comissionado != 'S'" [campo]="bibDialogo.naoComissionado"></apresentacao>
        </td>        
      </tr>
    </tbody>
  </table>
</layout>
<botao [compacto]="compacto" [desabilitado]="!temPermissaoEdicao" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionar()"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
