<layout [id]="ncmEmpresa.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <busca class="col-sm-12" [id]="'ncm'" [rotulo]="bibDialogo.ncm" [campo]="ncmEmpresa.ncm" [minimoCaracterBusca]="5" (alteracao)="ncmEmpresa.idNcm = $event.id" [servico]="bibServico.ncm" [foco]="true"></busca>
  </div>
  <div class="row align-items-center">
    <texto class="col-4" [id]="'cest'" [rotulo]="bibDialogo.cest" [campo]="ncmEmpresa.cest" (alteracao)="ncmEmpresa.cest = $event" [obrigatorio]="true" [maximoCaracteres]="7" [icone]="bibIcone.globo" (btnAdicional)="abrirSiteConfaz()" [ajuda]="bibDialogo.acesseSiteConfaz"></texto>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirNcmEmpresa()" [desabilitado]="bloquearPermissao(ncmEmpresa.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirNcmEmpresa(true)" [desabilitado]="bloquearPermissao(ncmEmpresa.id)"></botao>
<auditoria *ngIf="ncmEmpresa.id" [idUsuarioInclusao]="ncmEmpresa.idUsuarioInclusao" [dataHoraInclusao]="ncmEmpresa.dataHoraInclusao" [idUsuarioAlteracao]="ncmEmpresa.idUsuarioAlteracao" [dataHoraAlteracao]="ncmEmpresa.dataHoraAlteracao"></auditoria>
