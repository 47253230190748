import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2001MovimentacaoOcorrencia',
  templateUrl: './r2001MovimentacaoOcorrencia.component.html',
})
export class R2001MovimentacaoOcorrenciaComponent {
  @Input() movimentacaoOcorrencias: any[];
  public bibDialogo = bibDialogo;
}
