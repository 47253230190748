<layout [titulo]="produto">
  <lista class="col-sm" [rotulo]="bibDialogo.tabelaPreco" [campo]="tabelaPreco.id" (alteracao)="setTabelaPreco($event)" [lista]="tabelaPrecos" [desabilitado]="bloqueado || ehTabelaPrecoConfiguracaoSelecionado"></lista>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5" *ngIf="retornaConsulta"></th>
        <th class="l-20 text-right">
          <span *ngIf="tabelaPreco.tipo == 1">{{ bibDialogo.prazo }}</span>
        </th>
        <th class="l-20 text-right">{{ bibDialogo.valor }}</th>
        <th class="l-20 text-right">{{ bibDialogo.dataDe }}</th>
        <th class="l-20 text-right">{{ bibDialogo.dataAte }}</th>
        <th class="l-15 text-right">{{ bibDialogo.taxa }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let tabelaPrecoConfiguracao of tabelaPreco.tabelaPrecoConfiguracoes; let i = index" >
        <tr [ngClass]="{ tachado: tabelaPrecoConfiguracao.ehVencido }">
          <td *ngIf="retornaConsulta"><check *ngIf="tabelaPrecoConfiguracao.selecionado == 'S' || !ehTabelaPrecoConfiguracaoSelecionado" [campo]="tabelaPrecoConfiguracao.selecionado" (alteracao)="setSelecionado(tabelaPrecoConfiguracao)"></check></td>
          <td class="text-right">{{ tabelaPrecoConfiguracao.quantidadeDia }}</td>
          <td class="text-right">{{ tabelaPrecoConfiguracao.valor | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ tabelaPrecoConfiguracao.dataDe | data }}</td>
          <td class="text-right">{{ tabelaPrecoConfiguracao.dataAte | data }}</td>
          <td class="text-right">{{ (tabelaPrecoConfiguracao.juroPercentual | monetario) + '%' }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<bloqueado [bloqueado]="bloqueado"></bloqueado>
<div *ngIf="retornaConsulta" class="row align-items-center justify-content-between p-0 m-0">
  <botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionarTabelaPrecoConfiguracao()" [desabilitado]="bloqueado"></botao>
</div>
