export class Vinculo {
  public ativo: string = 'S';
  public contato: string = 'N';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public endereco: string = 'N';
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public sugestaoAutomatica: string = 'N';
  public telefone: string = 'N';
  public tipo: string;
}
