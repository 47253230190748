import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - TODOS', id: '-' },
  { nome: '02 - CONCILIADOS', id: '1' },
  { nome: '03 - NÃO CONCILIADOS', id: '0' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataLancamentoDe, nome: 'DATA_LANCAMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataLancamentoAte, nome: 'DATA_LANCAMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_PREVISTA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_PREVISTA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.tipoTitulo, nome: 'IDS_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ATIVO', 'S'), new Criterio('IDS_ESPECIE', '4')] },
  { rotulo: bibDialogo.numeroChequeAbreviacao, nome: 'NUMERO_CHEQUE', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboSituacao },
]);
export default filtros;
