import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Fabricante } from 'src/app/modelo/fabricante';
import { Grupo } from 'src/app/modelo/grupo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Ncm } from 'src/app/modelo/ncm';
import { ProdutoControlador } from 'src/app/modelo/produtoControlador';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'produtoCabecalhoFrm',
  templateUrl: './produtoCabecalhoFrm.component.html',
})
export class ProdutoCabecalhoFrmComponent extends PaginaComponent {
  @Input() produtoControlador: ProdutoControlador;
  public criterios: Criterio[] = [new Criterio('SEM_ID_ZERO', 'S')];
  public regraProdutoNomeAutomatico: string = this.utilSessao.getEmpresa().regraProdutoNomeAutomatico;
  public informarNumero: boolean = false;
  public focoNumero: number;
  public validoCodigoAnvisa: boolean = true;

  public tipo: any[] = [
    { id: 'P', nome: this.bibDialogo.produto },
    { id: 'S', nome: this.bibDialogo.servico },
  ];

  public listaSimNao: any[] = [
    { id: 'N', nome: this.bibDialogo.nao },
    { id: 'S', nome: this.bibDialogo.sim },
  ];

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == 'produto') {
        this.produtoControlador.produto = identificacao.conteudo;
      }
    });
  }

  setNome(nome: string): void {
    if (this.produtoControlador.produto.nomeFiscal == '' || this.produtoControlador.produto.nomeFiscal == null || this.produtoControlador.produto.nomeFiscal == this.produtoControlador.produto.nome) {
      this.produtoControlador.produto.nomeFiscal = nome;
    }
    this.produtoControlador.produto.nome = nome;
  }

  setTipo(tipo: string): void {
    this.produtoControlador.produto.tipo = tipo;
    if (this.produtoControlador.produto.tipo == 'P') {
      this.produtoControlador.produto.controlarEstoque = 'S';
    } else {
      this.produtoControlador.produto.controlarEstoque = 'N';
    }
  }

  setReferencia(referencia: string): void {
    this.produtoControlador.produto.referencia = referencia;
    this.montarNome(this.regraProdutoNomeAutomatico);
  }

  setProdutoDescricao(descricao: string): void {
    this.produtoControlador.produto.descricao = descricao;
    this.montarNome(this.regraProdutoNomeAutomatico);
  }

  setGrupo(grupo: Grupo): void {
    this.produtoControlador.produto.idGrupo = grupo.id;
    this.produtoControlador.produto.grupo = grupo && grupo.nome ? grupo.nome.trim() : null;
    this.produtoControlador.produto.usaIcmsDesonerado = grupo.usaIcmsDesonerado;
    this.produtoControlador.produto.cofinsAliquotaEntrada = grupo.cofinsAliquotaEntrada;
    this.produtoControlador.produto.cofinsAliquotaSaida = grupo.cofinsAliquotaSaida;
    this.produtoControlador.produto.idCfopTransferencia = grupo.idCfopTransferencia;
    this.produtoControlador.produto.idCfopEntrada = grupo.idCfopEntrada;
    this.produtoControlador.produto.idCfopSaida = grupo.idCfopSaida;
    this.produtoControlador.produto.idCfopTransferenciaForaUf = grupo.idCfopTransferenciaForaUf;
    this.produtoControlador.produto.idCfopEntradaForaUf = grupo.idCfopEntradaForaUf;
    this.produtoControlador.produto.idCfopSaidaForaUf = grupo.idCfopSaidaForaUf;
    this.produtoControlador.produto.idCstCofinsEntrada = grupo.idCstCofinsEntrada;
    this.produtoControlador.produto.idCstCofinsSaida = grupo.idCstCofinsSaida;
    this.produtoControlador.produto.idCstPisEntrada = grupo.idCstPisEntrada;
    this.produtoControlador.produto.idCstPisSaida = grupo.idCstPisSaida;
    this.produtoControlador.produto.idIpiEntrada = grupo.idIpiEntrada;
    this.produtoControlador.produto.idIpiSaida = grupo.idIpiSaida;
    this.produtoControlador.produto.pisAliquotaEntrada = grupo.pisAliquotaEntrada;
    this.produtoControlador.produto.pisAliquotaSaida = grupo.pisAliquotaSaida;
    this.produtoControlador.produto.ipiAliquotaEntrada = grupo.ipiAliquotaEntrada;
    this.produtoControlador.produto.ipiAliquotaSaida = grupo.ipiAliquotaSaida;
    this.produtoControlador.produto.ipiEnquadramentoEntrada = grupo.ipiEnquadramentoEntrada;
    this.produtoControlador.produto.ipiEnquadramentoSaida = grupo.ipiEnquadramentoSaida;
    this.montarNome(this.regraProdutoNomeAutomatico);
  }

  setFabricante(fabricante: Fabricante): void {
    this.produtoControlador.produto.idFabricante = fabricante.id;
    this.produtoControlador.produto.fabricante = fabricante.nome;
    this.montarNome(this.regraProdutoNomeAutomatico);
  }

  setNcm(ncm: Ncm): void {
    this.produtoControlador.produto.idNcm = ncm.id;
    this.produtoControlador.ehMedicamento(ncm.numero.toString());
  }

  setAnvisaCodigo(anvisaCodigo: string): void {
    this.produtoControlador.produto.anvisaCodigo = anvisaCodigo;
    this.produtoControlador.ehMedicamentoIsento(anvisaCodigo);
    this.validoCodigoAnvisa = this.produtoControlador.setEhValidoCodigoAnvisa();
  }

  montarNome(regraProdutoNomeAutomatico: string): void {
    if (regraProdutoNomeAutomatico == 'S') {
      const nomeGrupo = this.produtoControlador.produto.grupo != null ? this.produtoControlador.produto.grupo.trim() + ' ' : '';
      const descricaoProduto = this.produtoControlador.produto.descricao != null ? this.produtoControlador.produto.descricao.trim() + ' ' : '';
      const referenciaProduto = this.produtoControlador.produto.referencia != null ? this.produtoControlador.produto.referencia.trim() + ' ' : '';
      const nomeFabricante = this.produtoControlador.produto.fabricante != null ? this.produtoControlador.produto.fabricante.trim() + ' ' : '';
      this.produtoControlador.produto.nome = nomeGrupo + descricaoProduto + referenciaProduto + nomeFabricante;
      this.produtoControlador.produto.nome = this.produtoControlador.produto.nome != '' ? this.produtoControlador.produto.nome : null;
      this.produtoControlador.produto.nomeFiscal = this.produtoControlador.produto.nome;
    }
  }

  setControlaEstoque(controlaEstoque: string) {
    this.produtoControlador.produto.controlarEstoque = controlaEstoque;
    if (this.produtoControlador.produto.controlarEstoque == 'S') {
      this.utilSessao.telaInterna = true;
      this.utilSessao.setIdentificacao(new Identificacao('idProduto', this.produtoControlador.produto.id));
    }
  }

  abrirSiteConfaz(): void {
    window.open('https://www.confaz.fazenda.gov.br/legislacao/convenios/2018/CV142_18');
  }

  editarCampo(): void {
    if (confirm(this.bibDialogo.alertaNumeroManual)) {
      this.informarNumero = true;
      this.focoNumero = Math.random();
    }
  }
}
