import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Permissao } from 'src/app/modelo/permissao';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioResultado } from 'src/app/modelo/relatorioResultado';
import { FinanceiroFrmComponent } from 'src/app/pagina/financeiro/frm/financeiroFrm.component';
import { ParceiroFrmComponent } from 'src/app/pagina/parceiro/frm/parceiroFrm.component';
import filtros from '../r1109/acessorio/filtro';
import filtros2032 from '../r2032/acessorio/filtro';
import { RelatorioComponent } from '../relatorio.component';
import totais from './acessorio/total';

@Component({
  selector: 'r1109',
  templateUrl: './r1109.component.html',
  styleUrls: ['./r1109.component.css'],
})
export class R1109Component extends RelatorioComponent {
  public componente: any;
  public filtros = filtros;
  public filtros2032 = filtros2032;
  public totais = totais;
  public financeiros: any[] = [];
  public usaExpandido: boolean = false;
  public usaObservacao: boolean = false;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public telaCliente: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 5);
  public financeiroDetalhes: any[] = [];
  public idExpandir: number;
  public ehRelatorio1109: boolean = false;
  public ehRelatorio2032: boolean = false;

  public tipoNegociacoes: any[] = [
    { id: 'L', nome: this.bibDialogo.livre.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.semanal.toUpperCase() },
    { id: 'Q', nome: this.bibDialogo.quinzenal.toUpperCase() },
    { id: 'M', nome: this.bibDialogo.mensal.toUpperCase() },
    { id: 'D', nome: this.bibDialogo.diaFixo.toUpperCase() },
    { id: 'P', nome: this.bibDialogo.prazoFixo.toUpperCase() },
    { id: 'A', nome: this.bibDialogo.aVista.toUpperCase() },
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.idRelatorio == 1109) {
        this.ehRelatorio1109 = true;
        this.idExpandir = 233;
      }
      if (params.idRelatorio == 2032) {
        this.ehRelatorio2032 = true;
        this.idExpandir = 258;
      }
    });
  }

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'FinanceiroAgrupado').lista;
      if (this.relatorioResultados[1] == undefined) {
        let relatorioResultado: RelatorioResultado = new RelatorioResultado();
        relatorioResultado.dado = 'Financeiro';
        this.relatorioResultados.push(relatorioResultado);
      }
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.completarFinanceiro();
      this.ordenarAgrupar(this.financeiros);
      this.ehImprimirRelatorio();
      if (this.ehRelatorio2032) {
        this.usaExpandido = true;
      }
      this.expandirRelatorio();
      this.atualizarTotalizador();
    });
  }

  completarFinanceiro(): void {
    this.financeiros.forEach((financeiro) => {
      financeiro.negociacao = this.tipoNegociacoes.find((tipoNegociacao) => tipoNegociacao.id == financeiro.negociacao).nome;
      financeiro.valorLiquido = financeiro.valorTotal - financeiro.valorCredito;
    });
  }

  expandir(financeiro: any, indice: number) {
    if (financeiro.expandido && (financeiro.financeiros == null || financeiro.financeiros.length == 0)) {
      this.listarEspecifico(new Criterio('ID_PARCEIRO', financeiro.id), 127).subscribe((res) => {
        let financeiroDetalhes: any[] = this.plainToClass(RelatorioResultado, res)[0].lista;
        financeiroDetalhes.forEach((financeiroDetalhe) => {
          financeiroDetalhe.valorLiquido = financeiroDetalhe.valorTotal - financeiroDetalhe.valorCredito;
        });
        financeiro.financeiros = financeiroDetalhes;
        financeiro.financeiros.forEach((financeiroDetalhe) => {
          this.financeiroDetalhes.push(financeiroDetalhe);
        });
        if (indice == this.financeiros.length - 1) {
          this.relatorioResultados[1].lista = this.financeiroDetalhes;
        }
      });
    }
  }

  emitirModal(id: number, tipo: string): void {
    this.componente = this.abrirModalRelatorio(FinanceiroFrmComponent, [new Identificacao('modalBloqueada', 'S'), new Identificacao('parametro', tipo), new Identificacao('objeto', id)], null);
  }

  irParceiro(idParceiro: number, cobranca = false): void {
    const MENU_CLIENTE = 5;
    this.componente = this.abrirModalRelatorio(ParceiroFrmComponent, [new Identificacao('idParceiro', idParceiro), new Identificacao('pesquisaCliente', false), new Identificacao('parametro', 'CLIENTE'), new Identificacao('cobranca', cobranca)], MENU_CLIENTE);
  }

  expandirRelatorio() {
    this.financeiros.forEach((financeiro, indice) => {
      financeiro.expandido = this.usaExpandido;
      if (this.usaExpandido) {
        this.expandir(financeiro, indice);
      } else {
        financeiro.financeiros = null;
      }
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaExpandido = this.definirRegra(relatorioAdicional, this.idExpandir, this.usaExpandido);
    this.usaObservacao = this.definirRegra(relatorioAdicional, 269, this.usaObservacao);
    this.expandirRelatorio();
  }

  atualizarTotalizador(): void {
    if (this.ehRelatorio2032) {
      this.posicionarTotalizador('valorOriginal', true, -4);
      this.posicionarTotalizador('valorTotal', true, -4);
    }
  }
}
