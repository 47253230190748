import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from '../../utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Resultado } from 'src/app/modelo/resultado';
import { Vinculo } from 'src/app/modelo/vinculo';

@Component({
  templateUrl: './vinculoFrm.component.html',
})
export class VinculoFrmComponent extends PaginaComponent {
  public vinculo: Vinculo = new Vinculo();
  public opcoes: any[] = [
    { id: 'C', nome: this.bibDialogo.clienteColaborador },
    { id: 'F', nome: this.bibDialogo.fornecedorTransportadora },
    { id: 'T', nome: this.bibDialogo.todos },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.vinculo = this.plainToClass(Vinculo, this.ehAtualizacao(this.vinculo));
    if (this.vinculo.id) {
      this.listarVinculo(this.vinculo.id);
    }
  }

  listarVinculo(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.vinculo).subscribe((res) => {
      this.vinculo = res[0] as Vinculo;
    });
  }

  persistirVinculo(novo: boolean = false): void {
    if (this.ehValidoObrigatorio()) {
      super.persistir(new Transporte(this.vinculo), this.bibServico.vinculo, novo ? new Vinculo() : null);
    }
  }

  ehValidoObrigatorio(): boolean {
    if (!this.vinculo.nome || !this.vinculo.tipo || (this.vinculo.endereco == 'N' && this.vinculo.telefone == 'N' && this.vinculo.contato == 'N')) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }
}
