import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Local } from '../../modelo/local';
import filtros from './filtro';

@Component({
  templateUrl: './local.component.html',
})
export class LocalComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public locais: Local[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.local).subscribe((res) => {
      this.locais = this.plainToClass(Local, res) as any;
    });
  }

  excluirLocal(id: number, nome: string): void {
    super.excluir<Local>(id, this.locais, nome);
  }
}
