import { GrupoAcessoHorario } from './grupoAcessoHorario';
import { GrupoAcessoLocal } from './grupoAcessoLocal';

export class GrupoAcesso {
  public ativo: string = 'S';
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public grupoAcessoHorarios: GrupoAcessoHorario[] = [];
  public grupoAcessoLocais: GrupoAcessoLocal[] = [];
}
