import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboFinalidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'REMESSA DO ARQUIVO ORIGINAL', id: '0' },
  { nome: 'REMESSA DO ARQUIVO SUBSTITUTO', id: '1' },
]);

const filtroComboEscrituracao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: ' ', id: ' ' },
  { nome: 'ORIGINAL', id: '0' },
  { nome: 'RETIFICADRA', id: '1' },
]);

const filtroComboIndicadorSituação: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: ' ', id: ' ' },
  { nome: 'ABERTURA', id: '0' },
  { nome: 'CISÃO', id: '1' },
  { nome: 'FUSÃO', id: '2' },
  { nome: 'INCORPORAÇÃO', id: '3' },
  { nome: 'ENCERRAMENTO', id: '4' },
]);
const filtroSituaçãoEstoque: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '0' },
  { nome: 'DISPONÍVEL', id: '1' },
  { nome: 'INDISPONÍVEL', id: '2' },
]);
const filtroSimNao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);
const filtroReceitaDespesa: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: 'T' },
  { nome: 'RECEITA', id: 'R' },
  { nome: 'DESPESA', id: 'D' },
]);
const filtroParceiroNatureza: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'AMBOS', id: 'A' },
  { nome: 'PARCEIRO', id: 'P' },
  { nome: 'NATUREZA', id: 'N' },
]);

const filtros = {
  spedFiscal: plainToClass(Filtro, [
    { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
    { rotulo: bibDialogo.finalidade, nome: 'COD_FIN', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboFinalidade },
  ]),
  spedContribuicao: plainToClass(Filtro, [
    { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { rotulo: bibDialogo.escrituracao, nome: 'ESCRITURACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboEscrituracao },
    { rotulo: bibDialogo.indicadorSituacao, nome: 'INDICADOR_SITUACAO', obrigatorio: 'S', coluna: 6, valor: ' ', tipo: bibPropriedade.filtro.combo, lista: filtroComboIndicadorSituação },
    { rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 6, obrigatorio: 'S', servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
    { rotulo: bibDialogo.escrituracaoAnterior, nome: 'ESCRITURA_ANTERIOR', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.texto, vinculoVisualizacao: 'ESCRITURACAO', visualizacaoInicial: false, visualizacao: ['1'] },
  ]),
  etiqueta: plainToClass(Filtro, [
    { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
    { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
    { id: 'grupo', rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  ]),
  resultadoConsultor: plainToClass(Filtro, [
    { id: 'data_de', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'data_ate', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 6, obrigatorio: 'S', servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
    { id: 'vendedor', rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaboradorVendedor, tipo: bibPropriedade.filtro.busca },
    { id: 'safra', rotulo: bibDialogo.safra, nome: 'ID_SAFRA', coluna: 6, servicoWeb: bibServico.safra, tipo: bibPropriedade.filtro.busca },
  ]),
  estoque: plainToClass(Filtro, [
    { id: 'data_ate', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'produto', rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
    { id: 'grupo', rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca },
    { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
    { id: 'categoria', rotulo: bibDialogo.categoria, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
    { id: 'situacaoEstoque', rotulo: bibDialogo.estoque, nome: 'ESTOQUE', obrigatorio: 'S', coluna: 6, valor: '0', tipo: bibPropriedade.filtro.combo, lista: filtroSituaçãoEstoque },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 12, obrigatorio: 'S', servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  ]),
  neogrid: plainToClass(Filtro, [
    { id: 'data_de', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'data_ate', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
    { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  ]),
  sicca: plainToClass(Filtro, [
    { id: 'data_de', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'data_ate', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  ]),
  dominio: plainToClass(Filtro, [
    { id: 'data_de', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'data_ate', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data },
    { id: 'usuario', rotulo: bibDialogo.usuario, nome: 'USUARIO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'codigo_filial', rotulo: bibDialogo.codigoFilial, nome: 'CODIGO_FILIAL', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { id: 'receita_despesa', rotulo: bibDialogo.receitaEDespesa, nome: 'RECEITA_DESPESA', obrigatorio: 'S', coluna: 4, valor: 'T', tipo: bibPropriedade.filtro.combo, lista: filtroReceitaDespesa },
    { id: 'retirar_devolucao', rotulo: bibDialogo.retirarDevolucao, nome: 'RETIRAR_DEVOLUCAO', obrigatorio: 'S', coluna: 4, valor: 'N', tipo: bibPropriedade.filtro.combo, lista: filtroSimNao },
    { id: 'tipo', rotulo: bibDialogo.tipo, nome: 'TIPO', obrigatorio: 'S', coluna: 4, valor: 'A', tipo: bibPropriedade.filtro.combo, lista: filtroParceiroNatureza },
    { id: 'loja', rotulo: bibDialogo.loja, nome: 'ID_LOJA', coluna: 12, obrigatorio: 'S', servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.lista },
  ]),
};

export default filtros;
