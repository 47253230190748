export class Icms70 {
    public orig: string;
    public CST: string;
    public modBC: string;
    public pRedBC: string;
    public vBC: string;
    public pICMS: string;
    public vICMS: string;
    public modBCST: string;
    public pMVAST: string;
    public pRedBCST: string;
    public vBCST: string;
    public pICMSST: string;
    public vICMSST: string;
    public vICMSDeson: string;
    public motDesICMS: string;
    public vBCFCP: string;
    public pFCP: string;
    public vFCP: string;
    public vBCFCPST: string;
    public pFCPST: string;
    public vFCPST: string;
    public vICMSSTDeson: string;
    public motDesICMSST: string;
}