import { Type } from 'class-transformer';

export class ConferenciaTitulo {
  @Type(() => Date)
  public dataBaixa: Date = new Date();
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public contaContabil: string;
  public descontoValor: number;
  public id: number;
  public idConfrencia: number;
  public idConta: number;
  public idContaContabil: number;
  public idEmpresa: number;
  public idEspecie: number;
  public idTipoTitulo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public numeroDocumento: string;
  public observacao: string;
  public tipoTitulo: string;
  public valor: number;
  public valorCheio: number;
  public valorTroco: number;
}
