import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.data, campo: 'data' },
  { rotulo: bibDialogo.roteiro, campo: 'roteiro' },
  { rotulo: bibDialogo.usuario, campo: 'usuario' },
];

export default grupos;
