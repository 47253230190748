<layout [id]="movimentacao.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <apresentacao class="col-sm" [rotulo]="bibDialogo.nMovimentacao" [campo]="movimentacao.numero"></apresentacao>
    <apresentacao class="col-sm" [rotulo]="bibDialogo.data" [campo]="movimentacao.data | data"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm" [rotulo]="bibDialogo.loja" [campo]="movimentacao.loja"></apresentacao>
    <apresentacao class="col-sm" [rotulo]="bibDialogo.colaborador" [campo]="movimentacao.colaborador"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.cliente" [campo]="movimentacao.parceiro"></apresentacao>
    <busca class="col-sm-12" [rotulo]="bibDialogo.agronomo" [campo]="colaborador" (alteracao)="setAgronomo($event.id)" [servico]="bibServico.colaborador" [criterios]="criteriosResponsavel" [obrigatorio]="true" [foco]="true" [desabilitado]="bloqueia"></busca>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-10 text-right">{{ bibDialogo.numeroAbreviado + ' ' + bibDialogo.receituario }}</th>
        <th class="l-65">{{ bibDialogo.produto }}</th>
        <th class="l-10 text-right">{{ bibDialogo.quantidade }}</th>
        <th class="l-10">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let receituario of receituarios">
          <td class="text-center">
            <icone *ngIf="receituario.grauToxicologica == 'I' || receituario.grauToxicologica == 'II' || receituario.grauToxicologica == 'III' || receituario.grauToxicologica == 'IV'" [icone]="bibIcone.quadrado" [ajudaDireita]="true" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="receituario.grauToxicologica == 'I' ? bibPropriedade.icone.vermelho : receituario.grauToxicologica == 'II' ? bibPropriedade.icone.amarelo : receituario.grauToxicologica == 'III' ? bibPropriedade.icone.azul : bibPropriedade.icone.verde" [mensagem]="receituario.movimentacaoProduto.classeToxicologica"></icone>
          </td>
          <td class="text-right">{{ receituario.numero }}</td>
          <td class="limitar">{{ receituario.movimentacaoProduto.produto }} <br /></td>
          <td class="text-right">{{ receituario.movimentacaoProduto.quantidade | monetario}}</td>
          <td>{{ receituario.movimentacaoProduto.abreviacao }}</td>
          <td>
            <icone [icone]="bibIcone.circulo" [ngClass]="ehModoClaro ? '' : 'fa-preto'" [cor]="receituario.cultura && receituario.praga && receituario.aplicacao && (receituario.dose != null && receituario.dose != 0) ? bibPropriedade.icone.verde : bibPropriedade.icone.vermelho" [mensagem]="receituario.cultura && receituario.praga && receituario.aplicacao && (receituario.dose != null && receituario.dose != 0) ? bibDialogo.receituarioPreenchido : bibDialogo.aguardandoPreenchimentoReceituario"></icone>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(receituario)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="row">
    <botao class="ml-2" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="salvar()" [desabilitado]="bloqueia"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.finalizar" [fundo]="true" (botaoEmt)="finalizar()" [desabilitado]="bloqueia"></botao>
    <bloqueado [bloqueado]="bloqueia"></bloqueado>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
