import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import bibServico from 'src/app/biblioteca/bibServico';

const filtroComboAmbiente: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'PRODUÇÃO', id: 'P' },
  { nome: 'HOMOLOGAÇÃO', id: 'H' },
  { nome: 'TODOS', id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'empresa', rotulo: bibDialogo.empresa, nome: 'EMPRESA', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'estado', rotulo: bibDialogo.estado, nome: 'ID_ESTADO', coluna: 6, servicoWeb: bibServico.estado, tipo: bibPropriedade.filtro.busca },
  { id: 'status', rotulo: bibDialogo.ambiente, nome: 'STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'P', lista: filtroComboAmbiente },
]);
export default filtros;
