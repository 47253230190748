import { forEach } from 'cypress/types/lodash';
import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { ParceiroPropriedade } from 'src/app/modelo/parceiroPropriedade';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './parceiroDestinoFrm.component.html',
})
export class ParceiroDestinoFrmComponent extends PaginaComponent {
  public ajuda: string;
  public componente: any;
  public negociacoes: Negociacao[];
  public obrigaCpfCnpj: number = this.utilSessao.getEmpresa().regraCnpjCpf;
  public parceiroCnpjCpf: string;
  public parceiroDestino: ParceiroDestino = new ParceiroDestino();
  public parceiroPropriedades: ParceiroPropriedade[] = [];
  public parceiroEnderecoPrincipal: ParceiroEndereco = new ParceiroEndereco();
  public parceiroNome: string;
  public tipoVinculo: string;
  public tipoPessoa: string;
  public _parceiro: Parceiro;

  @Input()
  set parceiro(parceiro: Parceiro) {
    if (parceiro) {
      this._parceiro = parceiro;
    }
  }

  public listaPessoas: any[] = [
    { id: 'F', nome: this.bibDialogo.fisica },
    { id: 'J', nome: this.bibDialogo.juridica },
  ];

  ngOnInit(): void {
    this.parceiroDestino = this.plainToClass(ParceiroDestino, this.ehAtualizacao(this.parceiroDestino));
    if (this.parceiroDestino.id && !this.parceiroDestino.alterado) {
      this.listarParceiroDestino(this.parceiroDestino.id);
    }
    this.parceiroEnderecoPrincipal = this.utilSessao.getIdentificacao('parceiroEnderecoPrincipal')?.conteudo;
    this.tipoVinculo = this.utilSessao.getIdentificacao('tipoVinculo')?.conteudo;
    this.negociacoes = this.utilSessao.getIdentificacao('negociacoes')?.conteudo;
    this.parceiroNome = this.utilSessao.getIdentificacao('parceiroNome')?.conteudo;
    this.parceiroCnpjCpf = this.utilSessao.getIdentificacao('parceiroCnpjCpf')?.conteudo;
    this.tipoPessoa = this.utilSessao.getIdentificacao('tipoPessoa')?.conteudo;
    this.ajuda = this.bibDialogo.copiar + ' ' + (this.tipoPessoa == 'F' ? this.bibDialogo.cpf : this.bibDialogo.cnpj) + ' ' + this.bibDialogo.do.toLowerCase() + ' ' + this.parceiroNome;
  }

  listarParceiroDestino(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.parceiroDestino).subscribe((res) => {
      this.parceiroDestino = this.plainToClass(ParceiroDestino, res[0]) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO_DESTINO', id)), this.bibServico.parceiroPropriedade).subscribe((res1) => {
        this.parceiroDestino.parceiroPropriedades = this.plainToClass(ParceiroPropriedade, res1) as any;
      })
    });
  }

  persistirParceiroDestino(): void {
    if (this.ehValido()) {
      this.parceiroDestino.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.parceiroDestino, this.parceiroDestino));
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoArea: boolean = this.ehValidoArea();
    return ehValidoObrigatorio && ehValidoArea;
  }


  ehValidoObrigatorio(): boolean {
    if (this.parceiroDestino.nome && this.parceiroDestino.endereco && this.parceiroDestino.numero && this.parceiroDestino.bairro && this.parceiroDestino.cidade && this.parceiroDestino.idEstado && (this.parceiroDestino.cnpjCpf || this.obrigaCpfCnpj != 1)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoArea(): boolean {
    let areaInformada: number = 0;
    this.parceiroDestino.parceiroPropriedades.forEach((parceiroPropriedade) => areaInformada += parceiroPropriedade.hectares);
    if (areaInformada > this.parceiroDestino.area) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.somaDosHectares));
      return false;
    }
    return true;
  }

  setCnpjCpf(parceiroDestino: ParceiroDestino, valor: string): void {
    parceiroDestino.cnpjCpf = valor;
    parceiroDestino.ehValidoCnpjCpf = this.util.validarCnpjCpf(parceiroDestino.cnpjCpf);
  }

  setArea(parceiroDestino: ParceiroDestino, area: number) {
    parceiroDestino.area = area;
    this.focoId = null;
  }

  setCnpjCpfParceiroDestino(): void {
    this.parceiroDestino.tipoPessoa = this.tipoPessoa;
    this.parceiroDestino.cnpjCpf = this.parceiroCnpjCpf;
    this.parceiroDestino.ehValidoCnpjCpf = this.util.validarCnpjCpf(this.parceiroDestino.cnpjCpf);
  }

  setEnderecoParceiroDestino(): void {
    this.parceiroDestino.cep = this.parceiroEnderecoPrincipal.cep;
    this.parceiroDestino.numero = this.parceiroEnderecoPrincipal.numero;
    this.parceiroDestino.endereco = this.parceiroEnderecoPrincipal.endereco;
    this.parceiroDestino.idBairro = this.parceiroEnderecoPrincipal.idBairro;
    this.parceiroDestino.bairro = this.parceiroEnderecoPrincipal.bairro;
    this.parceiroDestino.complemento = this.parceiroEnderecoPrincipal.complemento;
    this.parceiroDestino.idCidade = this.parceiroEnderecoPrincipal.idCidade;
    this.parceiroDestino.cidade = this.parceiroEnderecoPrincipal.cidade;
    this.parceiroDestino.idEstado = this.parceiroEnderecoPrincipal.idEstado;
    this.parceiroDestino.estado = this.parceiroEnderecoPrincipal.estado;
    this.parceiroDestino.idRegiao = this.parceiroEnderecoPrincipal.idRegiao;
    this.parceiroDestino.regiao = this.parceiroEnderecoPrincipal.regiao;
    this.parceiroDestino.latitude = this.parceiroEnderecoPrincipal.latitude;
    this.parceiroDestino.longitude = this.parceiroEnderecoPrincipal.longitude;
    this.parceiroDestino.observacao = this.parceiroEnderecoPrincipal.observacao;
    this.parceiroDestino.tipo = this.parceiroEnderecoPrincipal.tipo;
    this.parceiroDestino.nome = this.parceiroNome;
    this.parceiroDestino.cnpjCpf = this.parceiroCnpjCpf;
  }

  setTipoPessoa(cnpjCpf: string): void {
    this.parceiroDestino.tipoPessoa = cnpjCpf;
  }

  abrirMapa(): void {
    window.open('https://www.google.com/maps/search/?api=1&query=' + this.parceiroDestino.latitude + ',' + this.parceiroDestino.longitude, '_blank');
  }
}
