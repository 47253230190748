<layout [subTitulo]="loja ? bibDialogo.loja.toUpperCase() + ': ' + loja.nomeFantasia : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div [ngClass]="ehModoClaro ? 'bg-cinza-fraco' : 'badge-light'" class="row" *ngIf="produtoValores.length > 1">
    <div [ngClass]="compacto ? 'pt-3' : 'pt-3'" class="col-sm-3 d-flex">
      <p>{{ bibDialogo.atualizarMargemLote }}</p>
      <check [campo]="atualizarMargem" (alteracao)="atualizarMargem = $event"></check>
    </div>
    <div [ngClass]="compacto ? 'pt-3' : ''" class="col-sm-3" *ngIf="atualizarMargem == 'S'">
      <lista [campo]="margemEscolhida" [obrigatorio]="true" (alteracao)="margemEscolhida = $event.id" [lista]="margemEscolhidas"></lista>
    </div>
    <div [ngClass]="compacto ? 'pt-3' : ''" class="col-sm-3" *ngIf="atualizarMargem == 'S'">
      <lista [campo]="formaEscolhida" [obrigatorio]="true" (alteracao)="formaEscolhida = $event.id" [lista]="formaEscolhidas"></lista>
    </div>
    <div [ngClass]="compacto ? 'pt-3' : ''" class="col-sm-2" *ngIf="atualizarMargem == 'S'">
      <decimal [campo]="percentual" [sufixo]="(margemEscolhida == 'P' || margemEscolhida == 'R') && formaEscolhida == 'D' ? '' : '%'" (alteracao)="percentual = $event"></decimal>
    </div>
    <div [ngClass]="compacto ? 'pt-1' : 'pt-1'" class="col-sm-1" *ngIf="atualizarMargem == 'S'">
      <botao [compacto]="compacto" [legenda]="bibDialogo.aplicar" (botaoEmt)="atualizarMargemProdutoValor()"></botao>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-9">
      <registro [posicao]="posicao" [paginacao]="paginacao" [numeroRegistro]="produtoValores.length"></registro>
    </div>
    <div *ngIf="produtoValores.length > 1" class="col-sm-3">
      <p>
        {{ bibDialogo.ordenarPorProduto }} <strong class="clique" (click)="ordenarPorProduto()"> {{ bibDialogo.cliqueAqui.toLowerCase() }}</strong>
      </p>
    </div>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10 clique" (click)="listar(add('dataUltimaCompra'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.dataUltCompra }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorUltimaCompra'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorUltCompra }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('custoReposicao'), this.utilSessao.posicao, this.paginacao)">{{ bibNomenclatura.escrever(bibNomenclatura.custoReposicao) }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('margemGerencial'), this.utilSessao.posicao, this.paginacao)">{{ bibNomenclatura.escrever(bibNomenclatura.margemGerencial) }}<ajuda class="naoImprimir" [ajudaDireita]="true" [ajuda]="bibDialogo.margemGerencialManterProduto"></ajuda></th>
        <th class="l-10 text-right clique" (click)="listar(add('custoGerencial'), this.utilSessao.posicao, this.paginacao)">{{ bibNomenclatura.escrever(bibNomenclatura.custoGerencial) }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('margemLucro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.margemLucro }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('precoVenda'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.precoVenda }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valorMinimo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorMinimo }}</th>
        <th class="l-5 text-right clique" (click)="listar(add('promocao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.promocaoAbreviado }} <ajuda class="naoImprimir" [ajuda]="bibDialogo.promocao"></ajuda></th>
        <th class="l-10 text-right clique" (click)="listar(add('custoNfe'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.custoNfe }} <ajuda [ajuda]="bibDialogo.dialogoCustoNfe"></ajuda></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let produtoValor of produtoValores; let i = index">
        <tr>
          <td colspan="6">
            <div class="row">
              <btnAdicional *ngIf="!this.utilSessao.modal && this.permissaoProduto" [icone]="bibIcone.olho" [ajuda]="bibDialogo.visualizarProduto" (btnAdicional)="visualizar(produtoValor.idProduto)"></btnAdicional>
              <span class="ml-1">{{ produtoValor.produto }}</span>
              <atencao [atencao]="produtoValor.unidade" [tipo]="bibPropriedade.atencao.informativo"></atencao>
            </div>
          </td>
          <td class="text-right" colspan="4">
            <span *ngIf="produtoValor.dataHoraAlteracao != null">{{ bibDialogo.dataAtualizacao + ': ' + (produtoValor.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm') }}</span>
          </td>
        </tr>
        <tr class="border-0">
          <td class="border-0">{{ produtoValor.dataUltimaCompra | data: 'dd/MM/yyyy' }}</td>
          <td class="text-right border-0">{{ produtoValor.valorUltimaCompra | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right border-0">
            <decimal [id]="'custoReposicao' + i" [campo]="produtoValor.custoReposicao" (alteracao)="setCustoReposicao(produtoValor, $event)" [numeroCasasDecimais]="4" [foco]="i == 0"> </decimal>
          </td>
          <td class="text-right border-0">
            <apresentacao *ngIf="produtoValor.margemGerencial == null && indice != 'mg' + i" [campo]="produtoValor.grupoMargemGerencial.toFixed(2)" [icone]="bibIcone.editar" (btnAdicional)="editarCampo('mg' + i)"></apresentacao>
            <decimal [id]="'margemGerencial' + i" *ngIf="produtoValor.margemGerencial != null || indice == 'mg' + i" [campo]="produtoValor.margemGerencial" (alteracao)="setMargemGerencial(produtoValor, $event)" [sufixo]="mostraSufixoPercentual ? '%' : ''" (navegacao)="$event == false ? (indice = '') : ''" [focoId]="focoEdicao ? true : false"></decimal>
          </td>
          <td class="text-right border-0">
            <decimal [id]="'custoGerencial' + i" [campo]="produtoValor.custoGerencial" (alteracao)="setCustoGerencial(produtoValor, $event, true)" [numeroCasasDecimais]="4"> </decimal>
          </td>
          <td class="text-right border-0">
            <apresentacao *ngIf="produtoValor.margemLucro == null && indice != 'ml' + i" [campo]="produtoValor.grupoMargemLucro.toFixed(2)" [icone]="bibIcone.editar" (btnAdicional)="editarCampo('ml' + i)"></apresentacao>
            <decimal [id]="'margemLucro' + i" [ajuda]="produtoValor.margemLucro != produtoValor.margemReal ? bibDialogo.margemReal + ' = ' + (produtoValor.margemReal | monetario) + '%' : ''" *ngIf="produtoValor.margemLucro != null || indice == 'ml' + i" [campo]="produtoValor.margemLucro" (alteracao)="setMargemLucro(produtoValor, $event)" [sufixo]="mostraSufixoPercentual ? '%' : ''" [focoId]="focoEdicao ? true : false"></decimal>
          </td>
          <td class="text-right border-0">
            <decimal [id]="'precoVenda' + i" [campo]="produtoValor.precoVenda" (alteracao)="setPrecoVenda(produtoValor, $event, true)" [numeroCasasDecimais]="4"></decimal>
          </td>
          <td class="text-right border-0">
            <decimal [campo]="produtoValor.valorMinimo" (alteracao)="setValorMinimo(produtoValor, $event)" [numeroCasasDecimais]="4"></decimal>
          </td>
          <td class="row justify-content-center">
            <check [id]="'lixo' + i" *ngIf="produtoValor.alterado != 'N' || produtoValor.id != null" [campo]="produtoValor.promocao" (alteracao)="produtoValor.promocao = $event"></check>
          </td>
          <td class="text-right border-0">
            <decimal [id]="'custoNfe' + i" [campo]="produtoValor.custoNfe" (alteracao)="setCustoNfe(produtoValor, $event)" [desabilitado]="produtoValor.custoNfe > 0" [numeroCasasDecimais]="2"></decimal>
          </td>
          <td class="border-0">
            <btnAdicional [id]="'lixo' + i" *ngIf="produtoValor.id != null" [icone]="bibIcone.lixo" (btnAdicional)="excluirProdutoValor(produtoValor.id, produtoValor.produto)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <div class="row">
    <atencao *ngIf="this.produtoValores.length == 0" [atencao]="atencao" [tipo]="bibPropriedade.atencao.alerta"></atencao>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ehPersistirProdutoValor()" [desabilitado]="bloquearPermissao(1)"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
