<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-10 text-right">{{ bibDialogo.numero }}</th>
        <th class="l-10 text-right">{{ bibDialogo.data }}</th>
        <th class="l-20">{{ bibDialogo.loja }}</th>
        <th class="l-20">{{ bibDialogo.motorista }}</th>
        <th class="l-20">{{ bibDialogo.cidade + ' ' + bibDialogo.final.toLowerCase() }}</th>        
        <th class="l-15">{{ bibDialogo.situacao }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let mdfe of mdfes; let i = index">
        <tr [ngClass]="{ tachado: mdfe.ativo != 'S' }">
          <td>
            <btnAdicional *ngIf="mdfe.status == 'S'" [icone]="bibIcone.cadeadoFechado" (btnAdicional)="abrirMdfe(mdfe)" [ajuda]="bibDialogo.abrirMdfe"></btnAdicional>
          </td>          
          <td class="l-10 text-right">{{ mdfe.numero }}</td>
          <td class="text-right">{{ mdfe.data | data }}</td>
          <td class="limitar">
            <a href="#" (click)="ir(mdfe.id, i, mdfes)">{{ mdfe.loja }}</a>
          </td>    
          <td class="limitar"> {{ mdfe.motoristaNome }} </td>     
          <td class="limitar"> {{ mdfe.cidadeFinal }} </td>                     
          <td><span class="badge badge-subtle" [ngClass]="mdfe.situacao == 1 ? 'badge-light' : mdfe.situacao == 2 ? 'badge-light' : mdfe.situacao == 3 ? 'badge-success' : mdfe.situacao == 4 ? 'badge-primary' : mdfe.situacao == 5 ? 'badge-warning' : 'badge-dark'">{{ mdfe.situacao == 1 ? bibDialogo.aberta.toUpperCase() : mdfe.situacao == 2 ? bibDialogo.aguardandoEnvio : mdfe.situacao == 3 ? bibDialogo.transmitido : mdfe.situacao == 4 ? bibDialogo.encerrado : mdfe.situacao == 5 ? bibDialogo.rejeicao : bibDialogo.cancelada }}</span></td>
          <td><btnAdicional [icone]="mdfe.status == 'S' || mdfe.ativo != 'S' ? bibIcone.olho : bibIcone.editar" (btnAdicional)="ir(mdfe.id, i, mdfes)"></btnAdicional></td>
          <td><btnAdicional *ngIf="mdfe.ativo != 'N'" [icone]="bibIcone.lixo" (btnAdicional)="excluirProduto(mdfe.id, mdfe.numero)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>