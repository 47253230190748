import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();

const filtroComboDevolucao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.desconsiderarDevolucaoDeCompra, id: '12' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataVencimentoDe, nome: 'DATA_VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'DATA_VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.fornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.fornecedor, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
  { rotulo: utilFiltro.apelidoCentroResultado, nome: 'IDS_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
  { rotulo: bibDialogo.natureza, nome: 'IDS_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.contaPrevista, nome: 'IDS_CONTA_PREVISTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
  { rotulo: bibDialogo.devolucoes, nome: 'DESCONSIDERAR_DEVOLUCAO', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboDevolucao, obrigatorio: 'S' },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
