import { RoteiroContabilRateio } from './roteiroContabilRateio';

export class RoteiroContabil {
  public ativo: string = 'S';
  public categoriaRoteiroContabil: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idCategoriaRoteiroContabil: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public numero: number;
  public roteiroContabilRateios: RoteiroContabilRateio[] = [];
}
