import { Type } from 'class-transformer';

export class ParceiroHistoricoLimite {
  @Type(() => Date)
  public data: Date = new Date();

  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public limiteCredito: number;
  public limiteDisponivel: number;
  public limiteMaximo: number;
  public variacaoLimiteCredito: number = 0;
  public variacaoLimiteDisponivel: number = 0;
  public variacaoLimiteMaximo: number = 0;
}
