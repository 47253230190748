import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { R2031Component } from '../r2031.component';

@Component({
  selector: 'r2031detalhes',
  templateUrl: './r2031detalhes.component.html',
})
export class R2031DetalhesComponent extends R2031Component {
  @Input() comissao: any;
  public bibDialogo = bibDialogo;

  abrirModalMovimentacao(id: number): void {
    this.emitirModal(id);
  }
}
