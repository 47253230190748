<div class="row align-items-center">
  <titulo class="col-sm pl-sm-2" [titulo]="bibDialogo.cobranca" [imagem]="bibImagem.dinheiro" [icone]="bibIcone.mais" (iconeEmt)="adicionarCobranca()" [desabilitado]="bloquearPermissao(parceiro.id)"></titulo>
</div>
<div class="row pl-3" *ngIf="parceiro.parceiroCobrancas.length == 0">
  <p>
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.cobranca.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarCobranca()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="parceiro.parceiroCobrancas ? (parceiro.parceiroCobrancas.length > 0 ? true : false) : false">
  <thead>
    <tr>
      <th class="l-15">{{ bibDialogo.dataEHora }}</th>
      <th class="l-10">{{ bibDialogo.usuario }}</th>
      <th class="l-20">{{ bibDialogo.formaDeCobranca }}</th>
      <th class="l-35">{{ bibDialogo.observacao }}</th>
      <th class="l-15">{{ bibDialogo.proxima + ' ' + bibDialogo.cobranca  }}</th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parceiroCobranca of parceiro.parceiroCobrancas; let i = index">
      <td>{{ parceiroCobranca.data | data: 'dd/MM/yyyy HH:mm' }}</td>
      <td class="limitar">{{ parceiroCobranca.nomeUsuario }}</td>
      <td><lista *ngIf="!parceiroCobranca.id" [campo]="parceiroCobranca.formaCobranca" [lista]="listaFormaCobranca" (alteracao)="parceiroCobranca.formaCobranca = $event.id" [obrigatorio]="true" [foco]="true"></lista> {{ parceiroCobranca.id ? parceiroCobranca.formaCobranca : '' }}</td>
      <td class="limitar"><ajuda [ajuda]="parceiroCobranca.observacao" [ajudaDireita]="true"></ajuda><texto *ngIf="!parceiroCobranca.id" [campo]="parceiroCobranca.observacao" (alteracao)="parceiroCobranca.observacao = $event" [maximoCaracteres]="1000" [obrigatorio]="true" [desabilitado]="parceiroCobranca.id"></texto> {{ parceiroCobranca.id ? parceiroCobranca.observacao : '' }}</td>
      <td><data *ngIf="!parceiroCobranca.id" [campo]="parceiroCobranca.dataProxima" (alteracao)="parceiroCobranca.dataProxima = $event"></data>{{ parceiroCobranca.id ? (parceiroCobranca.dataProxima | data) : '' }}</td>
      <td class="row justify-content-end"><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this.parceiro.parceiroCobrancas, this.excluirParceiroCobrancas, parceiroCobranca.id)"></btnAdicional></td>
    </tr>
  </tbody>
</table>
