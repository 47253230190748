import { any } from 'cypress/types/bluebird';
import format from 'date-fns/format';
import { Observable } from 'rxjs';
import { bibServicoNsCTe } from 'src/app/biblioteca/bibServicoNsCTe';
import { CteNs } from 'src/app/integracao/ns/cte/CTe';
import { Dest } from 'src/app/integracao/ns/cte/dest';
import { Emit } from 'src/app/integracao/ns/cte/emit';
import { EnderDest } from 'src/app/integracao/ns/cte/enderDest';
import { EnderEmit } from 'src/app/integracao/ns/cte/enderEmit';
import { EnderReme } from 'src/app/integracao/ns/cte/enderReme';
import { Icms } from 'src/app/integracao/ns/cte/icms';
import { Icmssn102 } from 'src/app/integracao/ns/cte/icmssn102';
import { Ide } from 'src/app/integracao/ns/cte/ide';
import { Imp } from 'src/app/integracao/ns/cte/imp';
import { Rem } from 'src/app/integracao/ns/cte/rem';
import { Toma3 } from 'src/app/integracao/ns/cte/toma3';
import { VPrest } from 'src/app/integracao/ns/cte/vPrest';
import { Icmssn101 } from 'src/app/integracao/ns/cte/icmssn101';
import { Cst } from 'src/app/modelo/cst';
import { Cte } from 'src/app/modelo/cte';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { ComunicacaoNsCTeService } from 'src/app/servico/comunicacaoNsCTe.service';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { Icmssn103 } from 'src/app/integracao/ns/cte/icmssn103';
import { Icmssn201 } from 'src/app/integracao/ns/cte/icmssn201';
import { Icmssn202 } from 'src/app/integracao/ns/cte/icmssn202';
import { Icmssn203 } from 'src/app/integracao/ns/cte/icmssn203';
import { Icmssn300 } from 'src/app/integracao/ns/cte/icmssn300';
import { Icmssn400 } from 'src/app/integracao/ns/cte/icmssn400';
import { Icmssn500 } from 'src/app/integracao/ns/cte/icmssn500';
import { Icmssn900 } from 'src/app/integracao/ns/cte/icmssn900';
import { Icms00 } from 'src/app/integracao/ns/cte/icms00';
import { Icms10 } from 'src/app/integracao/ns/cte/icms10';
import { Icms20 } from 'src/app/integracao/ns/cte/icms20';
import { Icms30 } from 'src/app/integracao/ns/cte/icms30';
import { Icms40 } from 'src/app/integracao/ns/cte/icms40';
import { Icms51 } from 'src/app/integracao/ns/cte/icms51';
import { Icms60 } from 'src/app/integracao/ns/cte/icms60';
import { Icms70 } from 'src/app/integracao/ns/cte/icms70';
import { Icms90 } from 'src/app/integracao/ns/nfe/icms90';
import { Resultado } from 'src/app/modelo/resultado';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Criterio } from 'src/app/modelo/criterio';
import { plainToClass } from 'class-transformer';
import { InfCte } from 'src/app/integracao/ns/cte/infCte';
import { InfCTeNorm } from 'src/app/integracao/ns/cte/infCTeNorm';
import { InfNFe } from 'src/app/integracao/ns/cte/infNfe';
import { InfCarga } from 'src/app/integracao/ns/cte/infCarga';
import { InfDoc } from 'src/app/integracao/ns/cte/infDoc';
import { InfQ } from 'src/app/integracao/ns/cte/infQ';
import { CteInformacaoCarga } from 'src/app/modelo/cteInformacaoCarga';
import { Compl } from 'src/app/integracao/ns/cte/compl';
import { CteDocumentoFiscal } from 'src/app/modelo/cteDocumentoFiscal';

export class UtilDocumentoEletronicoCte {

  constructor(public comunicacaoService: ComunicacaoService, public utilSessao: UtilSessao, public util: Util, public comunicacaoNsCTeService: ComunicacaoNsCTeService, public bibServicoNsCte: bibServicoNsCTe, public bibDialogo, public bibServico, public bibClasse) { }

  public parceiros: Parceiro[] = [];
  public parceiroEnderecos: ParceiroEndereco[] = [];
  public estados: Estado[] = [];
  public cstIcmss: Cst[] = [];
  public plainToClass: Function = plainToClass;
  private razaoSocialHomologacao: string = 'CTE EMITIDO EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL';

  iniciar(cte: Cte): Observable<any> {
    return new Observable<any>((observable) => {
      this.utilSessao.iniciarProcesso();
      this.listarParceiro(cte).subscribe(() => {
        this.listarParceiroEndereco(cte).subscribe(() => {
          this.listarCstICMS().subscribe(() => {
            this.listarEstados().subscribe(() => {
              this.listarInformacoesCargas(cte).subscribe(() => {
                this.listarDocumentoFiscais(cte).subscribe(() => {
                  observable.next();
                  observable.complete();
                  this.utilSessao.finalizarProcesso();
                })
              })
            });
          });
        });
      });
    });
  }

  private listarParceiro(cte: Cte): Observable<any> {
    this.utilSessao.mensagemProcessandoNFe('Listando parceiros');
    let idsParceiros: number[] = [];
    idsParceiros.push(cte.idParceiroDestinatario);
    idsParceiros.push(cte.idParceiroRemetente);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idsParceiros.toString())), this.bibServico.parceiro).subscribe((res) => {
        this.parceiros = this.plainToClass(Parceiro, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarParceiroEndereco(cte: Cte): Observable<any> {
    this.utilSessao.mensagemProcessandoNFe('Listando endereços dos parceiros');
    let idsParceiros: number[] = [];
    idsParceiros.push(cte.idParceiroDestinatario);
    idsParceiros.push(cte.idParceiroRemetente);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIROS', idsParceiros.toString())), this.bibServico.parceiroEndereco).subscribe((res) => {
        this.parceiroEnderecos = this.plainToClass(Parceiro, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarInformacoesCargas(cte: Cte): Observable<any> {
    this.utilSessao.mensagemProcessandoNFe('Listando informações de carga');
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_CTE', cte.id.toString())), this.bibServico.cteInformacaoCarga).subscribe((res) => {
        cte.cteInformacaoCargas = this.plainToClass(CteInformacaoCarga, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarDocumentoFiscais(cte: Cte): Observable<any> {
    this.utilSessao.mensagemProcessandoNFe('Listando documentos fiscais');
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_CTE', cte.id.toString())), this.bibServico.cteDocumentoFiscal).subscribe((res) => {
        cte.cteDocumentoFiscais = this.plainToClass(CteDocumentoFiscal, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarCstICMS(): Observable<any> {
    this.utilSessao.mensagemProcessandoNFe('Listando ICMS');
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstIcms).subscribe((res) => {
        this.cstIcmss = this.plainToClass(Cst, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarEstados(): Observable<any> {
    this.utilSessao.mensagemProcessandoNFe('Listando estados');
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
        this.estados = this.plainToClass(Estado, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  transformarCteEmCteNs(CTe: CteNs, cteLancamento: Cte, loja: Loja): CteNs {
    CTe.infCte = new InfCte();
    CTe.infCte.ide = this.gerarIdentificacao(cteLancamento, loja);
    CTe.infCte.emit = this.gerarEmitente(loja);
    CTe.infCte.dest = this.gerarDestinatario(cteLancamento.idParceiroDestinatario, cteLancamento.idLoja);
    CTe.infCte.rem = this.gerarRemetente(cteLancamento.idParceiroRemetente, cteLancamento.idLoja);
    CTe.infCte.compl = this.gerarComplemento(cteLancamento);
    //expedidor rodrigo
    CTe.infCte.vPrest = this.gerarValoresCte(cteLancamento);
    CTe.infCte.imp = this.gerarImpostos(cteLancamento);
    CTe.infCte.infCTeNorm = this.gerarInformacoesCargaDocumentoFiscal(cteLancamento);
    return CTe;
  }

  gerarIdentificacao(cte: Cte, loja: Loja, contingencia: boolean = false): Ide {
    const ide: Ide = new Ide();
    ide.cUF = loja.estadoIbge.toString();
    ide.CFOP = cte.idCfop.toString();
    ide.natOp = cte.cfop.substring(0, 59);
    ide.mod = '57';
    ide.modal = '01';
    ide.tpServ = "0"
    ide.serie = loja.cteSerie.toString();
    ide.nCT = cte.numero.toString();
    ide.dhEmi = format(cte.data, "yyyy-MM-dd'T'" + new Date().toLocaleTimeString() + "'-03:00'");
    ide.tpCTe = "0";
    ide.tpImp = "1";
    ide.tpEmis = contingencia ? "5" : "1";
    ide.cDV = "";
    ide.tpAmb = loja.cteAmbiente.toString();
    ide.procEmi = "0"
    ide.verProc = "1.02|NS_API";
    ide.cMunEnv = cte.ibgeCidadeInicial;
    ide.xMunEnv = cte.cidadeInicial;
    ide.UFEnv = cte.estadoCidadeInicial;
    ide.cMunIni = cte.ibgeCidadeInicial;
    ide.xMunIni = cte.cidadeInicial;
    ide.UFIni = cte.estadoCidadeInicial;
    ide.cMunFim = cte.ibgeCidadeFinal
    ide.xMunFim = cte.cidadeFinal;
    ide.UFFim = cte.estadoCidadeFinal;
    ide.retira = "1";
    const remetente = 0;
    const expedidor = 1;
    const recebedor = 3;
    ide.indIEToma = cte.tipoFrete == remetente ? cte.contribuinteParceiroRemetente : cte.tipoFrete == expedidor || cte.tipoFrete == recebedor ? cte.contribuinteParceiroDestinatario : "1";
    ide.dhCont = contingencia ? format(new Date(), loja.dataHoraContingencia.toString()) : null;
    ide.xJust = contingencia ? "SERVIDOR FORA DO AR" : null;
    ide.toma3 = this.gerarTomadorServico(cte);
    return ide;
  }

  gerarTomadorServico(cte: Cte): Toma3 {
    let tomador: any;
    let cnpjCpf: string = "";
    const idParceiroTomador: number = cte.tipoFrete == 0 ? cte.idParceiroRemetente : cte.tipoFrete == 2 || cte.tipoFrete == 3 ? cte.idParceiroDestinatario : -1;
    if (idParceiroTomador != -1) {
      tomador = this.parceiros.find((parceiro) => parceiro.id == idParceiroTomador);
      cnpjCpf = this.util.retirarCaracteresInvalidos(tomador.cnpjCpf);
    } else {
      tomador = this.utilSessao.getLojas().find((loja) => loja.id == cte.idLoja);
      cnpjCpf = this.util.retirarCaracteresInvalidos(tomador.cnpj);
    }
    const toma3: Toma3 = new Toma3();
    toma3.toma = cte.tipoFrete.toString();
    cnpjCpf.length > 11 ? toma3.CNPJ = cnpjCpf : toma3.CPF = cnpjCpf;
    toma3.xNome = this.getAmbiente(cte.idLoja) == '1' ? tomador.razaoSocial.substring(0, 59) : this.razaoSocialHomologacao;
    toma3.IE = this.util.retirarCaracteresInvalidos(tomador.inscricaoEstadual);
    return toma3;
  }

  gerarEmitente(loja: Loja): Emit {
    const emit: Emit = new Emit();
    emit.CNPJ = this.util.retirarCaracteresInvalidos(loja.cnpj);
    emit.xNome = this.getAmbiente(loja.id) == '1' ? loja.razaoSocial : this.razaoSocialHomologacao;
    emit.IE = this.util.retirarCaracteresInvalidos(loja.inscricaoEstadual);
    const SIMPLES_NACIONAL: number = 1;
    const SIMPLES_NACIONAL_EXCESSO: number = 4;
    emit.CRT = loja.enquadramento == SIMPLES_NACIONAL ? "1" : loja.enquadramento == SIMPLES_NACIONAL_EXCESSO ? '2' : '3';
    emit.enderEmit = this.gerarEnderecoEmitente(loja);
    return emit;
  }

  gerarEnderecoEmitente(loja: Loja): EnderEmit {
    const enderEmit: EnderEmit = new EnderEmit();
    enderEmit.xLgr = loja.enderecoTipo + ' ' + loja.endereco;
    enderEmit.nro = loja.enderecoNumero.toString();
    enderEmit.xCpl = loja.enderecoComplemento;
    enderEmit.xBairro = loja.bairro;
    enderEmit.cMun = loja.cidadeIbge;
    enderEmit.xMun = loja.cidade;
    enderEmit.UF = loja.estado;
    enderEmit.CEP = loja.enderecoCep ? loja.enderecoCep.replace('-', '') : null;
    enderEmit.fone = loja.telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    return enderEmit;
  }

  gerarDestinatario(idParceiro: number, idLoja: number): Dest {
    this.utilSessao.mensagemProcessandoNFe('Destinatário');
    const dest: Dest = new Dest();
    const parceiro = this.parceiros.find((parceiro) => parceiro.id == idParceiro);
    const cnpjCpf = this.util.retirarCaracteresInvalidos(parceiro.cnpjCpf);
    cnpjCpf.length > 11 ? dest.CNPJ = cnpjCpf : dest.CPF = cnpjCpf
    dest.xNome = this.getAmbiente(idLoja) == '1' ? parceiro.razaoSocial.substring(0, 59) : this.razaoSocialHomologacao;
    dest.indIEDest = parceiro.contribuinte.toString();
    const NAO_CONTRIBUINTE: string = '9';
    dest.IE = parceiro.contribuinte.toString() == NAO_CONTRIBUINTE ? '' : parceiro.inscricaoEstadual.split('.').join('');
    dest.email = parceiro.email;
    dest.enderDest = this.gerarEnderecoDestinatario(parceiro);
    return dest;
  }

  gerarEnderecoDestinatario(parceiro: Parceiro): EnderDest {
    const enderDest: EnderDest = new EnderDest();
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiroEndereco: any = this.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idParceiro == parceiro.id && parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    enderDest.xLgr = parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco;
    enderDest.nro = parceiroEndereco.numero;
    enderDest.xBairro = parceiroEndereco.bairro;
    enderDest.cMun = parceiroEndereco.cidadeIbge;
    enderDest.xMun = parceiroEndereco.cidade;
    enderDest.UF = estado.abreviacao;
    enderDest.CEP = parceiroEndereco.cep.replace('-', '');
    return enderDest;
  }

  gerarRemetente(idParceiro: number, idLoja: number): Rem {
    this.utilSessao.mensagemProcessandoNFe('Remetente');
    const rem: Rem = new Rem();
    const remetente = this.parceiros.find((parceiro) => parceiro.id == idParceiro);
    const cnpjCpf = this.util.retirarCaracteresInvalidos(remetente.cnpjCpf);
    cnpjCpf.length > 11 ? rem.CNPJ = cnpjCpf : rem.CPF = cnpjCpf
    rem.xNome = this.getAmbiente(idLoja) == '1' ? remetente.razaoSocial.substring(0, 59) : this.razaoSocialHomologacao;
    const NAO_CONTRIBUINTE: string = '9';
    rem.IE = remetente.contribuinte.toString() == NAO_CONTRIBUINTE ? '' : remetente.inscricaoEstadual.split('.').join('');
    rem.enderReme = this.gerarEnderecoRemetente(remetente);
    return rem;
  }

  gerarComplemento(cte: Cte): Compl {
    this.utilSessao.mensagemProcessandoNFe('Complemento');
    const compl: Compl = new Compl();
    compl.xObs = cte.informacaoComplementar;
    return compl;
  }

  gerarEnderecoRemetente(remetente: Parceiro): EnderReme {
    const enderReme: EnderReme = new EnderReme();
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiroEndereco: any = this.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idParceiro == remetente.id && parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    enderReme.xLgr = parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco;
    enderReme.nro = parceiroEndereco.numero;
    enderReme.xBairro = parceiroEndereco.bairro;
    enderReme.cMun = parceiroEndereco.cidadeIbge;
    enderReme.xMun = parceiroEndereco.cidade;
    enderReme.UF = estado.abreviacao;
    enderReme.CEP = parceiroEndereco.cep.replace('-', '');
    return enderReme;
  }

  gerarValoresCte(cte: Cte): VPrest {
    this.utilSessao.mensagemProcessandoNFe('Valores CT-e');
    const vPrest: VPrest = new VPrest();
    vPrest.vTPrest = cte.valor.toFixed(2).toString()
    vPrest.vRec = cte.valor.toFixed(2).toString()
    return vPrest;
  }

  gerarIcms(cte: Cte): Icms {
    this.utilSessao.mensagemProcessandoNFe('ICMS CT-e');
    const icms: Icms = new Icms();
    const cstIcms: Cst = this.cstIcmss.find((cstIcmsBusca) => cstIcmsBusca.id == cte.idCstIcms);
    switch (cstIcms.cst.substring(1)) {
      case '101':
        icms.ICMSSN101 = this.gerarIcms101(cstIcms, cte);
        break;
      case '102':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, cte);
        break;
      case '103':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, cte);
        break;
      case '201':
        icms.ICMSSN201 = this.gerarIcms201(cstIcms, cte);
        break;
      case '202':
        icms.ICMSSN202 = this.gerarIcms202(cstIcms, cte);
        break;
      case '203':
        icms.ICMSSN202 = this.gerarIcms202(cstIcms, cte);
        break;
      case '300':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, cte);
        break;
      case '400':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, cte);
        break;
      case '500':
        icms.ICMSSN500 = this.gerarIcms500(cstIcms, cte);
        break;
      case '900':
        icms.ICMSSN900 = this.gerarIcms900(cstIcms, cte);
        break;
      case '00':
        icms.ICMS00 = this.gerarIcms00(cstIcms, cte);
        break;
      case '10':
        icms.ICMS10 = this.gerarIcms10(cstIcms, cte);
        break;
      case '20':
        icms.ICMS20 = this.gerarIcms20(cstIcms, cte);
        break;
      case '30':
        icms.ICMS30 = this.gerarIcms30(cstIcms);
        break;
      case '40':
        icms.ICMS40 = this.gerarIcms40(cstIcms);
        break;
      case '41':
        icms.ICMS40 = this.gerarIcms40(cstIcms);
        break;
      case '50':
        icms.ICMS40 = this.gerarIcms40(cstIcms);
        break;
      case '51':
        icms.ICMS51 = this.gerarIcms51(cstIcms, cte);
        break;
      case '60':
        icms.ICMS60 = this.gerarIcms60(cstIcms, cte);
        break;
      case '70':
        icms.ICMS70 = this.gerarIcms70(cstIcms, cte);
        break;
      case '90':
        icms.ICMS90 = this.gerarIcms90(cstIcms, cte);
        break;
      default:
        break;
    }
    return icms;
  }


  gerarImpostos(cte: Cte): Imp {
    this.utilSessao.mensagemProcessandoNFe('Impostos CT-e');
    const imp: Imp = new Imp();
    imp.vTotTrib = cte.icmsValor.toFixed(2).toString();
    imp.ICMS = this.gerarIcms(cte);
    return imp;
  }

  gerarInformacoesCargaDocumentoFiscal(cte: Cte): InfCTeNorm {
    const infCTeNorm: InfCTeNorm = new InfCTeNorm();
    infCTeNorm.infCarga = this.gerarInformacoesCarga(cte);
    infCTeNorm.infDoc = this.gerarDocumentosFiscais(cte);
    return infCTeNorm;
  }

  gerarInformacoesCarga(cte: Cte): InfCarga {
    const infCarga: InfCarga = new InfCarga();
    cte.valorCarga ? infCarga.vCarga = cte.valorCarga.toFixed(2).toString() : null;
    infCarga.proPred = cte.produtoPredominante;
    infCarga.infQ = this.gerarQuantidadeCarga(cte);
    return infCarga;
  }

  gerarQuantidadeCarga(cte: Cte): InfQ[] {
    const infQs: InfQ[] = [];
    cte.cteInformacaoCargas.forEach(cteInformacaoCarga => {
      const infQ: InfQ = new InfQ();
      infQ.cUnid = cteInformacaoCarga.unidadeMedida;
      infQ.tpMed = cteInformacaoCarga.tipoMedida;
      infQ.qCarga = cteInformacaoCarga.quantidadePeso.toFixed(4).toString();
      infQs.push(infQ);
    });

    return infQs;
  }

  gerarDocumentosFiscais(cte: Cte): InfDoc {
    const infDoc: InfDoc = new InfDoc();
    const infNFes: InfNFe[] = [];
    cte.cteDocumentoFiscais.forEach(cteDocumentoFiscal => {
      const infNFe: InfNFe = new InfNFe();
      infNFe.chave = cteDocumentoFiscal.chave;
      infNFes.push(infNFe);
    });
    infDoc.infNFe = infNFes;
    return infDoc;
  }

  gerarIcms101(cstIcms: Cst, cte: Cte): Icmssn101 {
    const icmssn101: Icmssn101 = new Icmssn101();
    icmssn101.orig = cstIcms.cst[0];
    icmssn101.CSOSN = cstIcms.cst.substring(1);
    return icmssn101;
  }

  gerarIcms102(cstIcms: Cst, cte: Cte): Icmssn102 {
    const icmssn102: Icmssn102 = new Icmssn102();
    icmssn102.orig = cstIcms.cst[0];
    icmssn102.CSOSN = cstIcms.cst.substring(1);
    return icmssn102;
  }

  gerarIcms103(cstIcms: Cst, cte: Cte): Icmssn103 {
    const icmssn103: Icmssn103 = new Icmssn103();
    icmssn103.orig = cstIcms.cst[0];
    icmssn103.CSOSN = cstIcms.cst.substring(1);
    return icmssn103;
  }

  gerarIcms201(cstIcms: Cst, cte: Cte): Icmssn201 {
    const icmssn201: Icmssn201 = new Icmssn201();
    icmssn201.orig = cstIcms.cst[0];
    icmssn201.CSOSN = cstIcms.cst.substring(1);
    return icmssn201;
  }

  gerarIcms202(cstIcms: Cst, cte: Cte): Icmssn202 {
    const icmssn202: Icmssn202 = new Icmssn202();
    icmssn202.orig = cstIcms.cst[0];
    icmssn202.CSOSN = cstIcms.cst.substring(1);
    return icmssn202;
  }

  gerarIcms203(cstIcms: Cst, cte: Cte): Icmssn203 {
    const icmssn203: Icmssn203 = new Icmssn203();
    icmssn203.orig = cstIcms.cst[0];
    icmssn203.CSOSN = cstIcms.cst.substring(1);
    return icmssn203;
  }

  gerarIcms300(cstIcms: Cst, cte: Cte): Icmssn300 {
    const icmssn300: Icmssn300 = new Icmssn300();
    icmssn300.orig = cstIcms.cst[0];
    icmssn300.CSOSN = cstIcms.cst.substring(1);
    return icmssn300;
  }

  gerarIcms400(cstIcms: Cst, cte: Cte): Icmssn400 {
    const icmssn400: Icmssn400 = new Icmssn400();
    icmssn400.orig = cstIcms.cst[0];
    icmssn400.CSOSN = cstIcms.cst.substring(1);
    return icmssn400;
  }

  gerarIcms500(cstIcms: Cst, cte: Cte): Icmssn500 {
    const icmssn500: Icmssn500 = new Icmssn500();
    icmssn500.orig = cstIcms.cst[0];
    icmssn500.CSOSN = cstIcms.cst.substring(1);
    return icmssn500;
  }

  gerarIcms900(cstIcms: Cst, cte: Cte): Icmssn900 {
    const icmssn900: Icmssn900 = new Icmssn900();
    icmssn900.orig = cstIcms.cst[0];
    icmssn900.modBC = "3";
    icmssn900.vBC = cte.icmsBase.toFixed(2).toString()
    icmssn900.pRedBC = "0.00";
    icmssn900.pICMS = cte.icmsAliquota.toFixed(2).toString()
    icmssn900.vICMS = cte.icmsValor.toFixed(2).toString();
    icmssn900.modBCST = "0";
    icmssn900.vBCST = "0.00";
    icmssn900.pICMSST = "0.00";
    icmssn900.vICMSST = "0.00";
    icmssn900.pCredSN = "0.00";
    icmssn900.vCredICMSSN = "0.00";
    icmssn900.vBCFCPST = "0.00";
    icmssn900.pFCPST = "0.00";
    icmssn900.vFCPST = "0.00";
    return icmssn900;
  }

  gerarIcms00(cstIcms: Cst, cte: Cte): Icms00 {
    const icms00: Icms00 = new Icms00();
    icms00.orig = cstIcms.cst[0];
    icms00.CST = cstIcms.cst.substring(1);
    icms00.modBC = "3";
    icms00.vBC = cte.icmsBase.toFixed(2).toString()
    icms00.pICMS = cte.icmsAliquota.toFixed(2).toString()
    icms00.vICMS = cte.icmsValor.toFixed(2).toString();
    return icms00;
  }

  gerarIcms10(cstIcms: Cst, cte: Cte): Icms10 {
    const icms10: Icms10 = new Icms10();
    icms10.orig = cstIcms.cst[0];
    icms10.CST = cstIcms.cst.substring(1);
    icms10.modBC = "3";
    icms10.vBC = cte.icmsBase.toFixed(2).toString()
    icms10.pICMS = cte.icmsAliquota.toFixed(2).toString()
    icms10.vICMS = cte.icmsValor.toFixed(2).toString();
    icms10.modBCST = "6";
    icms10.vBCST = '0.00';
    icms10.pICMSST = '0.00';
    icms10.vICMSST = '0.00';
    return icms10;
  }

  gerarIcms20(cstIcms: Cst, cte: Cte): Icms20 {
    const icms20: Icms20 = new Icms20();
    icms20.orig = cstIcms.cst[0];
    icms20.CST = cstIcms.cst.substring(1);
    icms20.modBC = "3";
    icms20.vBC = cte.icmsBase.toFixed(2).toString();
    icms20.pICMS = cte.icmsAliquota.toFixed(2).toString();
    icms20.vICMS = cte.icmsValor.toFixed(2).toString();
    icms20.vICMSDeson = '';
    icms20.motDesICMS = '';
    icms20.pRedBC = '';
    return icms20;
  }

  gerarIcms30(cstIcms: Cst): Icms30 {
    const icms30: Icms30 = new Icms30();
    icms30.orig = cstIcms.cst[0];
    icms30.CST = cstIcms.cst.substring(1);
    return icms30;
  }

  gerarIcms40(cstIcms: Cst): Icms40 {
    const icms40: Icms40 = new Icms40();
    icms40.orig = cstIcms.cst[0];
    icms40.CST = cstIcms.cst.substring(1);
    return icms40;
  }

  gerarIcms51(cstIcms: Cst, cte: Cte): Icms51 {
    const icms51: Icms51 = new Icms51();
    icms51.orig = cstIcms.cst[0];
    icms51.CST = cstIcms.cst.substring(1);
    icms51.modBC = "3";
    const percentualReducao: number = this.util.arredondar(((cte.valor - cte.icmsBase) / cte.valor) * 100);
    percentualReducao && percentualReducao > 0 ? icms51.pRedBC = percentualReducao.toFixed(2).toString() : '';
    icms51.vBC = cte.icmsBase.toFixed(2).toString();
    icms51.pICMS = cte.icmsAliquota.toFixed(2).toString();
    icms51.vICMS = cte.icmsValor.toFixed(2).toString();
    return icms51;
  }

  gerarIcms60(cstIcms: Cst, cte: Cte): Icms60 {
    const icms60: Icms60 = new Icms60();
    icms60.orig = cstIcms.cst[0];
    icms60.CST = cstIcms.cst.substring(1);
    icms60.vBCSTRet = '0.00';
    icms60.vICMSSTRet = '0.00';
    icms60.pST = '0.00';
    return icms60;
  }

  gerarIcms70(cstIcms: Cst, cte: Cte): Icms70 {
    const icms70: Icms70 = new Icms70();
    icms70.orig = cstIcms.cst[0];
    icms70.CST = cstIcms.cst.substring(1);
    icms70.modBC = "3";
    icms70.vBC = cte.icmsBase.toFixed(2).toString();
    icms70.pICMS = cte.icmsAliquota.toFixed(2).toString();
    icms70.vICMS = cte.icmsValor.toFixed(2).toString();
    icms70.vICMSDeson = '';
    icms70.motDesICMS = '';
    const percentualReducao = this.util.arredondar(((cte.valor - cte.icmsBase) / cte.valor) * 100);
    percentualReducao ? icms70.pRedBC = percentualReducao.toFixed(2).toString() : '';
    return icms70;
  }

  gerarIcms90(cstIcms: Cst, cte: Cte): Icms90 {
    const icms90: Icms90 = new Icms90();
    icms90.orig = cstIcms.cst[0];
    icms90.CST = cstIcms.cst.substring(1);
    icms90.modBC = "3";
    icms90.vBC = cte.icmsBase.toFixed(2).toString();
    icms90.pICMS = cte.icmsAliquota.toFixed(2).toString();
    icms90.vICMS = cte.icmsValor.toFixed(2).toString();
    icms90.vICMSDeson = '';
    icms90.motDesICMS = '';
    const percentualReducao = this.util.arredondar(((cte.valor - cte.icmsBase) / cte.valor) * 100);
    percentualReducao ? icms90.pRedBC = percentualReducao.toFixed(2).toString() : '';
    return icms90;
  }

  formatarDataPadrao(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }

  completaEsquerda(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }


  baixarXml(chaveCte: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNsCte.getToken(),
      "chNFe": chaveCte,
      "tpAmb": this.getAmbiente(idLoja),
      "tpDown": "X"
    });
  }

  baixarPdf(chaveCte: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNsCte.getToken(),
      "chNFe": chaveCte,
      "tpAmb": this.getAmbiente(idLoja),
      "tpDown": "P"
    });
  }

  enviarEmail(chaveCte: string, email: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNsCte.getToken(),
      "chNFe": chaveCte,
      "tpAmb": this.getAmbiente(idLoja),
      "enviaEmailDoc": true,
      "anexarPDF": true,
      "email": email
    });
    this.comunicacaoNsCTeService.persistir(this.bibServicoNsCte.getUrlEmail(), transporte).subscribe(
      (res) => {
        this.utilSessao.setResultado(new Resultado(true, res.mensagem));
      },
      (error) => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelReenviarEmail));
      }
    );
  }

  persistirCte(cte: Cte): Observable<any> {
    const transporte = new Transporte([cte], this.bibServico.Cte);
    transporte.idMenu = 126;
    transporte.posicao = 0;
    transporte.quantidade = 0;
    return this.comunicacaoService.persistir(transporte, this.bibServico.Cte);
  }

  getAmbiente(idLoja: number): string {
    const PRODUCAO: string = '1';
    const HOMOLOGACAO: string = '2';
    return this.utilSessao.getLojas().find((loja) => loja.id == idLoja).cteAmbiente == 1 ? PRODUCAO : HOMOLOGACAO;
  }
}
