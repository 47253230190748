import { Component, Input } from '@angular/core';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'compendioEletronicoDadosFrm',
  templateUrl: './compendioEletronicoDadosFrm.component.html',
})
export class CompendioEletronicoDadosFrmComponent extends PaginaComponent {
  public _produtoFormulado: ProdutoFormulado = new ProdutoFormulado();
  @Input()
  set produtoFormulado(produtoFormulado: ProdutoFormulado) {
    this._produtoFormulado = produtoFormulado;
    this.setEhIconeClasseToxicologica();
  }
  private numeroRomanoIcones: string[] = ['I', 'II', 'III', 'IV'];

  setClasseToxicologica(classeToxicologica: string): void {
    this._produtoFormulado.classeToxicologica = classeToxicologica;
    this.setEhIconeClasseToxicologica();
  }

  setEhIconeClasseToxicologica(classificacao: string = null): void {
    let numeroRomano: string = '';
    if (this._produtoFormulado.classeToxicologica) {
      numeroRomano = this._produtoFormulado.classeToxicologica.split('-')[0].trim();
    }

    if (classificacao) {
      this._produtoFormulado.classeToxicologica = classificacao;
      numeroRomano = this._produtoFormulado.classeToxicologica.split('-')[0].trim();
    }
    if (this.numeroRomanoIcones.includes(numeroRomano)) {
      this._produtoFormulado.ehIconeClasseToxicologica = true;
      switch (numeroRomano) {
        case 'I':
          this._produtoFormulado.corIcone = this.bibPropriedade.icone.vermelho;
          break;

        case 'II':
          this._produtoFormulado.corIcone = this.bibPropriedade.icone.amarelo;
          break;

        case 'III':
          this._produtoFormulado.corIcone = this.bibPropriedade.icone.azul;
          break;

        case 'IV':
          this._produtoFormulado.corIcone = this.bibPropriedade.icone.verde;
          break;
      }
    } else {
      this._produtoFormulado.ehIconeClasseToxicologica = false;
    }
  }
}
