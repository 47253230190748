import { plainToClass, Type } from 'class-transformer';
import { addDays, addMonths, setDate } from 'date-fns';
import { FinanceiroBoleto } from '../agrow/modelo/financeiroBoleto';
import { Util } from '../utilitario/util';
import { UtilSessao } from '../utilitario/util.sessao';
import { BaixaMovimentacaoProduto } from './baixaMovimentacaoProduto';
import { FinanceiroBaixa } from './financeiroBaixa';

export class Financeiro {
  @Type(() => Date)
  public dataNegociacao: Date = new Date();
  @Type(() => Date)
  public dataVencimento: Date = new Date();
  @Type(() => Date)
  public dataConferencia: Date;

  private valorAPagarProporcional: number = 0;
  public aVista: string;
  public abreviacao: string;
  public alterado: boolean = false;
  public anoNegociacao: string;
  public ativo: string = 'S';
  public atraso: number;
  public baixaMovimentacaoProdutos: BaixaMovimentacaoProduto[] = [];
  public baixaValorBaixa: number = 0;
  public baixaValorRestante: number = 0;
  public baixado: boolean = false;
  public categoria: string = 'M';
  public centroResultado: string;
  public chave: string;
  public clienteNomeFantasia: string = '';
  public comissao: string;
  public conta: string;
  public contaContabil: string;
  public contar: number;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public desconto: number = 0;
  public diasAtrasado: number = 0;
  public especieNivel: number;
  public expandido: boolean = false;
  public financeiroBaixas: FinanceiroBaixa[] = [];
  public financeiroBoleto: FinanceiroBoleto;
  public id: number;
  public idCentroResultado: number;
  public idColaboradorMovimentacao: number;
  public idConta: number;
  public idContaContabil: number;
  public idCredito: number;
  public idDesfazimento: number;
  public idEmpresa: number;
  public idFinanceiroBoleto: number;
  public idFinanceiroOriginal: number;
  public idFinanceiroReferencia: number;
  public idLoja: number;
  public idMovimentacao: number;
  public idNatureza: number;
  public idOperacao: number;
  public idParceiro: number;
  public idParceiroDestino: number;
  public idTipoTitulo: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public juro: number = 0;
  public juroAPagar: number = 0;
  public juroAutomatico: number = 0;
  public loja: string;
  public mesNegociacao: string;
  public multa: number = 0;
  public natureza: string;
  public negociar: string = 'N';
  public nomeParceiro: string;
  public numero: number = 0;
  public numeroBanco: string;
  public numeroFiscal: number;
  public observacao: string;
  public ordemPagamento: number = 1;
  public origem: string = '';
  public parceiro: string;
  public parcela: string = '01/01';
  public parcelaAntiga: string;
  public percentualJuroRenegociacao: number = 0;
  public renegociacao: number = 0;
  public renegociacaoAntiga: number = 0;
  public repetidaDiaFixo: number = 0;
  public repetidaQuantidade: number;
  public repetidaTipo: number;
  public selecionado: string = 'N';
  public tipo: string = 'R';
  public tipoJuroRenegociacao: string = 'N';
  public tipoTitulo: string;
  public tipoTituloIdEspecie: number;
  public totalizaValorFinal: number = 0;
  public totalizaValorReal: number = 0;
  public totalizaValorRestante: number = 0;
  public usaGeraFinanceiroBaixaPro: boolean;
  public validaLimiteCredito: string = 'S';
  public valor: number;
  public valorAnterior: number = 0;
  public valorAnulado: number = 0;
  public valorBaixa: number = 0;
  public valorDescontoOriginal: number;
  public valorFinal: number = 0;
  public valorFinalOriginal: number;
  public valorFiscal: number = 0;
  public valorMultaOriginal: number;
  public valorReal: number = 0;
  public valorRestante: number = 0;
  public valorTotalMovimentacao: number;
  public destacaObservacao: string = 'N';
  public devolucao: string = 'N';
  public ehCliente: string = 'N';
  public safra: string;
  public difereBoleto: boolean = false;
  public gerouBoleto: string;

  setValorAPagarProporcional(valorAPagarProporcional: number) {
    this.valorAPagarProporcional = new Util().arredondar(valorAPagarProporcional);
  }
  getValorAPagarProporcional(): number {
    return this.valorAPagarProporcional;
  }

  private valorAPagarTotal: number = 0;
  setValorAPagarTotal(valorAPagarTotal: number) {
    this.valorAPagarTotal = new Util().arredondar(valorAPagarTotal);
  }
  getValorAPagarTotal(): number {
    return this.valorAPagarTotal;
  }

  private valorAPagarTotalProduto: number = 0;
  setValorAPagarTotalProduto(valorAPagarTotalProduto: number) {
    this.valorAPagarTotalProduto = new Util().arredondar(valorAPagarTotalProduto);
  }
  getValorAPagarTotalProduto(): number {
    return this.valorAPagarTotalProduto;
  }

  ehBaixado(): void {
    const modalBloqueada: boolean = new Util().coalesce(new UtilSessao().getIdentificacao('modalBloqueada')?.conteudo, false);
    if (this.valorRestante == 0 || modalBloqueada) {
      this.baixado = true;
    }
  }

  popular(): void {
    this.ehBaixado();
  }

  gerarFinanceiros(financeiros: Financeiro[]): void {
    this.limparLista(financeiros);
    if ((this.negociar == 'RN' || this.negociar == 'RE') && this.percentualJuroRenegociacao != null) {
      for (let index = 1; index <= this.repetidaQuantidade; index++) {
        let financeiroNovo: Financeiro = plainToClass(Financeiro, this) as any;
        if (this.negociar == 'RN') {
          financeiroNovo.renegociacaoAntiga = financeiroNovo.renegociacao;
          financeiroNovo.renegociacao++;
        }
        this.zerarId(financeiroNovo, index);
        this.fixarDia(financeiroNovo);
        this.calcularFinanceiro(financeiroNovo, index);
        this.montarParcela(financeiroNovo, this.repetidaQuantidade, index);
        this.calcularDataVencimento(financeiroNovo, index);
        this.reiniciarFinanceiro(financeiroNovo);
        financeiros.push(financeiroNovo);
      }
    }
  }

  limparLista(financeiros: Financeiro[]) {
    while (financeiros.length > 0) {
      financeiros.splice(0, 1);
    }
  }

  zerarId(financeiro: Financeiro, parcela: number): void {
    if (parcela > 1) {
      financeiro.id = null;
      financeiro.idFinanceiroReferencia = null;
    }
  }

  calcularFinanceiro(financeiro: Financeiro, index: number): void {
    let valor: number = this.valorRestante;
    if (this.negociar == 'RN') {
      valor = new Util().arredondar(this.valorRestante / financeiro.repetidaQuantidade);
      if (index == 1) {
        financeiro.valor = valor + financeiro.valorBaixa + financeiro.desconto + financeiro.valorAnulado - financeiro.juro - financeiro.juroAutomatico - financeiro.multa;
        if (financeiro.valor < 0) {
          financeiro.valor = 0;
        }
        financeiro.valorReal = new Util().arredondar(financeiro.valor);
        financeiro.valorBaixa = financeiro.valorBaixa;
        financeiro.valorAnterior = financeiro.juro + financeiro.juroAutomatico + financeiro.multa - financeiro.desconto - financeiro.valorAnulado;
        financeiro.juro = financeiro.juro + financeiro.juroAutomatico;
        financeiro.juroAutomatico = 0;
      } else {
        financeiro.valor = valor;
        financeiro.valorReal = valor;
        this.zerarValores(financeiro);
      }
    } else {
      if (index != 1) {
        this.zerarValores(financeiro);
      }
    }
    financeiro.totalizar();
  }

  zerarValores(financeiro: Financeiro) {
    financeiro.valorBaixa = 0.0;
    financeiro.desconto = 0.0;
    financeiro.juro = 0.0;
    financeiro.juroAutomatico = 0.0;
    financeiro.multa = 0.0;
    financeiro.valorAnulado = 0.0;
    financeiro.valorAnterior = 0.0;
  }

  calcularJuros(financeiros: Financeiro[]): void {
    this.totalizaValorFinal = 0;
    this.totalizaValorRestante = 0;
    this.totalizaValorReal = 0;
    let juroCompostoAcumulado: number = 0;
    financeiros.forEach((financeiro) => {
      if (this.tipoJuroRenegociacao != 'N') {
        financeiro.valor += new Util().arredondar(((financeiro.valorRestante + juroCompostoAcumulado) * this.percentualJuroRenegociacao) / 100);
        if (this.tipoJuroRenegociacao == 'C') {
          juroCompostoAcumulado += ((financeiro.valorRestante + juroCompostoAcumulado) * this.percentualJuroRenegociacao) / 100;
        }
        financeiro.valorReal = financeiro.valor;
        financeiro.totalizar();
      }
      this.totalizaValorReal += financeiro.valorReal;
      this.totalizaValorFinal += financeiro.valorFinal;
      this.totalizaValorRestante += financeiro.valorRestante;
    });
  }

  calcularDataVencimento(financeiro: Financeiro, parcela: number) {
    switch (financeiro.repetidaTipo.toString()) {
      case '1':
        financeiro.dataVencimento = addDays(financeiro.dataVencimento, 7 * parcela);
        break;
      case '2':
        financeiro.dataVencimento = addDays(financeiro.dataVencimento, 15 * parcela);
        break;
      case '3':
        financeiro.dataVencimento = addMonths(financeiro.dataVencimento, 1 * parcela);
        break;
      case '4':
        financeiro.dataVencimento = addMonths(financeiro.dataVencimento, 2 * parcela);
        break;
      case '5':
        financeiro.dataVencimento = addMonths(financeiro.dataVencimento, 3 * parcela);
        break;
      case '6':
        financeiro.dataVencimento = addMonths(financeiro.dataVencimento, 6 * parcela);
        break;
      case '7':
        financeiro.dataVencimento = addMonths(financeiro.dataVencimento, 12 * parcela);
        break;
    }
  }

  fixarDia(financeiro: Financeiro) {
    if (this.repetidaDiaFixo > 0 && this.repetidaTipo > 2) {
      financeiro.dataVencimento = setDate(financeiro.dataVencimento, this.repetidaDiaFixo);
    }
  }

  montarParcela(financeiro: Financeiro, parcelaTotal: number, parcelaAtual: number) {
    financeiro.parcelaAntiga = financeiro.parcela;
    if (financeiro.negociar == 'RN') {
      financeiro.parcela = financeiro.parcela + ' - ';
    } else {
      financeiro.parcela = '';
    }
    financeiro.parcela = financeiro.parcela + this.completaEsquerda(parcelaAtual.toString(), '0', 2) + '/' + this.completaEsquerda(parcelaTotal.toString(), '0', 2);
  }

  completaEsquerda(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  reiniciarFinanceiro(financeiro: Financeiro): void {
    financeiro.repetidaDiaFixo = 0;
    financeiro.negociar = 'N';
    financeiro.repetidaQuantidade = 0;
    financeiro.repetidaTipo = 0;
  }

  totalizarValorParcelaFinanceiro(financeiros: Financeiro[], automatico: boolean = true): void {
    let valorTotal: number = 0;
    financeiros.forEach((financeiro) => {
      valorTotal += new Util().arredondar(Number(financeiro.valorFinal));
    });
    if (this.negociar == 'RN' && automatico) {
      valorTotal = this.calcularRestanteValoresRepetirRenegociar(financeiros, valorTotal);
    }
    this.totalizaValorFinal = valorTotal;
  }

  totalizarValorTotalFinanceiro(financeiros: Financeiro[]): void {
    if (this.tipoJuroRenegociacao == 'S') {
      let quantidade: number = financeiros.length;
      if (this.negociar == 'RN') {
        quantidade = 1;
      }
      const valorTotalRepetir = new Util().arredondar(this.valorRestante + (this.valorRestante * this.percentualJuroRenegociacao) / 100) * quantidade;
      if (this.totalizaValorFinal != valorTotalRepetir) {
        let diferenca: number = new Util().arredondar(this.totalizaValorFinal - valorTotalRepetir);
        let posicao: number = 0;
        if (diferenca < 0) {
          diferenca = diferenca * -1;
        }
        this.totalizaValorFinal += diferenca;
        this.valorFinal += diferenca;
        this.calcularDiferencaFinanceiros(financeiros, posicao, diferenca);
      }
    }
  }

  calcularRestanteValoresRepetirRenegociar(financeiros: Financeiro[], valorTotal: number): number {
    if (this.valorFinal != valorTotal) {
      let diferenca: number = new Util().arredondar(this.valorFinal - valorTotal);
      if (financeiros[0].valorReal + diferenca > 0) {
        valorTotal += diferenca;
        financeiros[0].valor += diferenca;
        financeiros[0].valorReal += diferenca;
        financeiros[0].totalizar();
      }
    }
    return valorTotal;
  }

  calcularDiferencaFinanceiros(financeiros: Financeiro[], posicao: number, diferenca: number): void {
    financeiros[posicao].valor += diferenca;
    financeiros[posicao].valorFinal += diferenca;
  }

  recalcularNegociacao(financeiros: Financeiro[]): void {
    this.gerarFinanceiros(financeiros);
    this.totalizarValorParcelaFinanceiro(financeiros);
    this.calcularJuros(financeiros);
    this.totalizarValorTotalFinanceiro(financeiros);
  }

  totalizar(): void {
    this.valorFinal = 0;
    this.valorRestante = 0;

    if (this.valor) {
      this.valorFinal = new Util().arredondar(this.valorFinal, 2) + new Util().arredondar(Number(this.valor), 2);
    }
    if (this.multa) {
      this.valorFinal = new Util().arredondar(this.valorFinal, 2) + new Util().arredondar(Number(this.multa), 2);
    }
    if (this.juro) {
      this.valorFinal = new Util().arredondar(this.valorFinal, 2) + new Util().arredondar(Number(this.juro), 2);
    }
    if (this.juroAutomatico) {
      this.valorFinal = new Util().arredondar(this.valorFinal, 2) + new Util().arredondar(Number(this.juroAutomatico), 2);
    }
    if (this.desconto) {
      this.valorFinal = new Util().arredondar(this.valorFinal, 2) - new Util().arredondar(Number(this.desconto), 2);
    }
    if (this.valorFinal) {
      this.valorRestante = new Util().arredondar(this.valorRestante, 2) + new Util().arredondar(Number(this.valorFinal), 2);
    }
    if (this.valorBaixa) {
      this.valorRestante = new Util().arredondar(this.valorRestante, 2) - new Util().arredondar(Number(this.valorBaixa), 2);
    }
    if (this.valorAnulado) {
      this.valorRestante = new Util().arredondar(this.valorRestante, 2) - new Util().arredondar(Number(this.valorAnulado), 2);
    }
  }

  ehBloqueaFinanceiroCabecalho(): boolean {
    if (this.idMovimentacao || this.baixado || this.idCredito || this.idDesfazimento || this.comissao == 'S' || this.idFinanceiroBoleto) {
      return true;
    }
    return false;
  }

  ehBloqueiaFinanceiroBaixadoBoleto(): boolean {
    if (this.baixado || this.idFinanceiroBoleto) {
      return true;
    }
    return false;
  }
}
