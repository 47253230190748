<layout [titulo]="bibDialogo.cartaCorrecaoNfe" [subTitulo]="(data | data) + ' - ' + parceiro">
  <div class="row justify-content-end">
    <botao *ngIf="contagemCartaCorrecaoAutorizado > 0" [compacto]="compacto" [legenda]="bibDialogo.imprimirComprovante" (botaoEmt)="baixarPdf()"></botao>
    <botao *ngIf="movimentacaoNfe?.situacaoAutorizado == 'S'" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + bibDialogo.cartaCorrecao.toLowerCase()" (botaoEmt)="abrirModal()"></botao>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-10">{{ bibDialogo.data }}</th>
        <th class="l-50">{{ bibDialogo.justificativa }}</th>
        <th class="l-15 text-right">{{ bibDialogo.sequencia }}</th>
        <th class="l-20"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoCartaCorrecao of movimentacaoNfe.movimentacaoCartaCorrecoes">
        <tr>
          <td><icone [icone]="bibIcone.circulo" [cor]="movimentacaoCartaCorrecao.status == -1 ? bibPropriedade.icone.amarelo : movimentacaoCartaCorrecao.status == 135 ? bibPropriedade.icone.verde : bibPropriedade.icone.vermelho" [mensagem]="movimentacaoCartaCorrecao.status == -1 ? bibDialogo.emAndamento : movimentacaoCartaCorrecao.status == 135 ? bibDialogo.transmitidaComSucesso : bibDialogo.naoAutorizada"></icone></td>
          <td>{{ data | data }}</td>
          <td class="limitar">{{ movimentacaoCartaCorrecao.justificativa }}</td>
          <td class="text-right">{{ movimentacaoCartaCorrecao.sequencia }}</td>
          <td class="row justify-content-end">
            <btnAdicional *ngIf="movimentacaoCartaCorrecao.status != 135 && movimentacaoCartaCorrecao.status != -1" [icone]="movimentacaoCartaCorrecao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="movimentacaoCartaCorrecao.expandido = !movimentacaoCartaCorrecao.expandido"></btnAdicional>
            <btnAdicional *ngIf="movimentacaoCartaCorrecao.status == -1" [icone]="bibIcone.lupa" (btnAdicional)="consultarCartaCorrecao(movimentacaoCartaCorrecao)"></btnAdicional>
            <btnAdicional *ngIf="movimentacaoCartaCorrecao.status != 135 && movimentacaoCartaCorrecao.status != -1" [icone]="bibIcone.lixo" (btnAdicional)="excluirMovimentacaoCartaCorrecao(movimentacaoCartaCorrecao)"></btnAdicional>
          </td>
        </tr>
        <tr *ngIf="movimentacaoCartaCorrecao.expandido">
          <td colspan="6">
            <descricao [rotulo]="bibDialogo.descricao" [campo]="movimentacaoCartaCorrecao.statusDescricao" [desabilitado]="true"></descricao>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
