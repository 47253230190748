import { Component } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroVeiculo } from 'src/app/modelo/parceiroVeiculo';
import { Resultado } from 'src/app/modelo/resultado';
import { RomaneioEntrega } from 'src/app/modelo/romaneioEntrega';
import { RomaneioEntregaDocumentoFiscal } from 'src/app/modelo/romaneioEntregaDocumentoFiscal';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './romaneioEntregaFrm.component.html',
})
export class RomaneioEntregaFrmComponent extends PaginaComponent {
  public romaneioEntrega: RomaneioEntrega = new RomaneioEntrega();
  public conexao: Conexao = new Conexao(this.utilSessao);
  public data: Date = new Date();
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public excluirDocumentosFiscais: number[] = [];
  public parceiroVeiculos: ParceiroVeiculo[];
  public estados: Estado[];

  public visualizaLoja: boolean = false;
  public bloqueado: boolean = false;
  public menuTransportadora: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 55);

  ngOnInit(): void {
    this.listarEstados();
    this.ehAlteracao();
    this.mostrarOcultarLoja();
    this.ehBloqueado();
  }

  listarEstados(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }  

  mostrarOcultarLoja() {
    if (this.utilSessao.getLojas().length > 1) {
      this.visualizaLoja = true;
    }
  }

  listarParceirosEstados(): void {
    this.parceiroVeiculos = [];
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('ID_PARCEIRO', this.romaneioEntrega.idParceiroTransportadora));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroVeiculo).subscribe((res) => {
      this.parceiroVeiculos = this.plainToClass(ParceiroVeiculo, res) as any;
    });
  }

  ehAlteracao(): void {
    this.romaneioEntrega = this.plainToClass(RomaneioEntrega, this.ehAtualizacao(this.romaneioEntrega));
    if (this.romaneioEntrega.id) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.romaneioEntrega.id)), this.bibServico.romaneioEntrega).subscribe((res) => {
        this.romaneioEntrega = this.plainToClass(RomaneioEntrega, res[0]) as any;
        this.listarDocumentoFiscais(this.romaneioEntrega.id);
        this.listarParceirosEstados();
        this.ehBloqueado();
      });
    } else {
      this.romaneioEntrega.data = this.data;
      this.romaneioEntrega.status = 'N';
      this.definirLoja();
    }
  }

  setLoja(loja: Loja): void {
    this.romaneioEntrega.idLoja = loja.id;
  }

  listarDocumentoFiscais(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_ROMANEIO_ENTREGA', id)), this.bibServico.romaneioEntregaDocumentoFiscal).subscribe((res) => {
      this.romaneioEntrega.romaneioEntregaDocumentoFiscais = this.plainToClass(RomaneioEntregaDocumentoFiscal, res) as any;
    });
  }

  atualizarNumero(id: number): void {
    if (id != null && id != 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.romaneioEntrega).subscribe((res) => {
        this.romaneioEntrega.numero = (this.plainToClass(RomaneioEntrega, res[0]) as any).numero;
      });
    }
  }  

  finalizar(): void {
    this.romaneioEntrega.status = 'S';
    this.persistirRomaneioEntrega();
  }

  persistirRomaneioEntrega(): void {
    if (this.ehValido()) {
      if (this.romaneioEntrega.numero == null) {
        this.romaneioEntrega.numero = 0;
      }
      const transporte: Transporte = new Transporte(this.romaneioEntrega);
      transporte.adicionar(this.excluirDocumentosFiscais);
      const ehFinalizar: boolean = this.romaneioEntrega.status == 'S' ? true : false;
      super.persistir(transporte, this.bibServico.romaneioEntrega, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.romaneioEntrega], this.bibClasse.romaneioEntrega, true);
          this.definirId(this.romaneioEntrega.romaneioEntregaDocumentoFiscais, this.bibClasse.romaneioEntregaDocumentoFiscal);
          this.excluirDocumentosFiscais = [];
          if (ehFinalizar) {
            this.atualizarNumero(this.romaneioEntrega.id);
          }
        }
      });
      this.ehBloqueado();
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoCpf: boolean = this.setEhValidoCpf();
    const ehValidoData: boolean = this.setEhValidoData();
    const ehDocumentoFiscal: boolean = this.setEhDocumentoFiscal();
    return ehValidoObrigatorio && ehValidoCpf && ehValidoData && ehDocumentoFiscal;
  }

  setEhDocumentoFiscal(): boolean {
    if (this.romaneioEntrega.romaneioEntregaDocumentoFiscais.length > 0 || this.romaneioEntrega.status == 'N ') {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.documentoFiscalVazio));
    return false;
  }

  ehValidoObrigatorio(): boolean {
    if (this.romaneioEntrega.data && this.romaneioEntrega.placa && this.romaneioEntrega.placaUf && this.romaneioEntrega.motoristaNome && this.romaneioEntrega.motoristaCpf) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoCpf(): boolean {
    if (this.util.validarCnpjCpf(this.romaneioEntrega.motoristaCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cpfIncorreto));
    return false;
  }

  setEhValidoData(): boolean {
    return true;
  }

  setCpf(romaneioEntrega: RomaneioEntrega, valor: string): void {
    romaneioEntrega.motoristaCpf = valor == '' ? null : valor;
    romaneioEntrega.ehValidoCpf = this.util.validarCnpjCpf(romaneioEntrega.motoristaCpf);
  }

  ehBloqueado(): void {
    this.bloqueado = this.romaneioEntrega == null || this.romaneioEntrega.status == 'S';
  }

  setTransportadora(parceiro: Parceiro): void {
    this.romaneioEntrega.idParceiroTransportadora = parceiro.id;
    this.romaneioEntrega.parceiroTransportadora = parceiro.razaoSocial;
    this.romaneioEntrega.motoristaCpf = null;
    this.romaneioEntrega.motoristaNome = null;
    this.romaneioEntrega.placa = null;
    this.romaneioEntrega.placaUf = null;
    this.romaneioEntrega.idVeiculo = null;
    this.romaneioEntrega.veiculo = null;
    this.listarParceirosEstados();
  }

  setEstado(idEstado: number) {
    this.estados.forEach((estado) => {
      if (estado.id == idEstado) {
        this.romaneioEntrega.placaUf = estado.nome;
      }
    });
  }

  setVeiculo(parceiroVeiculo: ParceiroVeiculo): void {
    this.romaneioEntrega.idVeiculo = parceiroVeiculo.id;
    this.romaneioEntrega.veiculo = parceiroVeiculo.nome;
    this.romaneioEntrega.placa = parceiroVeiculo.placa;
    this.setEstado(parceiroVeiculo.idEstado);
    this.romaneioEntrega.motoristaCpf = parceiroVeiculo.motoristaCpf;
    this.romaneioEntrega.motoristaNome = parceiroVeiculo.motoristaNome;
  }

  definirLoja(): void {
    if (this.utilSessao.getLojas().length == 1) {
      this.setLoja(this.utilSessao.getLojas()[0]);
    }
  }
}
