import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const grupos: Grupo[] = [
  { rotulo: utilFiltro.apelidoTelaFabricante, campo: 'fabricante' },
  { rotulo: utilFiltro.apelidoTelaGrupo, campo: 'grupo' },
];
export default grupos;
