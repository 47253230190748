<layout [titulo]="grupoUsuario.nome" [id]="grupoUsuario.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="grupoUsuario.nome" (alteracao)="grupoUsuario.nome = $event" [obrigatorio]="true" [foco]="true" [maximoCaracteres]="100"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="grupoUsuario.ativo" (alteracao)="grupoUsuario.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <section class="card card-fluid">
    <header class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#menu">{{ bibDialogo.menu }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#relatorio">{{ bibDialogo.relatorio }}</a>
        </li>
      </ul>
    </header>
    <div class="card-body">
      <div id="myTabContent" class="tab-content">
        <grupoPermissaoMenusFrm class="tab-pane fade" id="menu" [ngClass]="{ 'active show': true }" [grupoUsuarioMenus]="grupoUsuarioMenus"></grupoPermissaoMenusFrm>
        <grupoPermissaoRelatoriosFrm class="tab-pane fade" id="relatorio" [relatorioGrupoUsuarios]="relatorioGrupoUsuarios"></grupoPermissaoRelatoriosFrm>
      </div>
    </div>
  </section>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGrupoUsuario()" [desabilitado]="bloquearPermissao(grupoUsuario.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirGrupoUsuario(true)" [desabilitado]="bloquearPermissao(grupoUsuario.id)"></botao>
<auditoria *ngIf="grupoUsuario.id" [idUsuarioInclusao]="grupoUsuario.idUsuarioInclusao" [dataHoraInclusao]="grupoUsuario.dataHoraInclusao" [idUsuarioAlteracao]="grupoUsuario.idUsuarioAlteracao" [dataHoraAlteracao]="grupoUsuario.dataHoraAlteracao"></auditoria>
