import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroAbreviado + ' ' + bibDialogo.movimentacao, nome: 'NUMERO', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.loja, nome: 'ID_LOJA', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.lojaUsuario, tipo: bibPropriedade.filtro.listaLojaUsuario },
  { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('OPERACAO_SAIDA', 'S')] },
]);
export default filtros;
