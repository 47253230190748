import { Type } from 'class-transformer';
import { MovimentacaoCancelamento } from 'src/app/modelo/movimentacaoCancelamento';
import { Util } from '../utilitario/util';
import { MovimentacaoCartaCorrecao } from './movimentacaoCartaCorrecao';
import { MovimentacaoChave } from './movimentacaoChave';
import { MovimentacaoNfe } from './movimentacaoNfe';
import { MovimentacaoParcela } from './movimentacaoParcela';
import { MovimentacaoProduto } from './movimentacaoProduto';
import { Negociacao } from './negociacao';
import { ReceituarioEletronico } from './receituarioEletronico';
import { Relacionamento } from './relacionamento';

export class Movimentacao {
  @Type(() => Date)
  public data: Date = new Date();
  @Type(() => Date)
  public dataEntradaSaida: Date = new Date();
  @Type(() => Date)
  public dataHoraAlteracao: Date;
  @Type(() => Date)
  public dataHoraInclusao: Date;

  public abreviacao: string;
  public abreviacaoLojaDestino: string;
  public abrindo: boolean = false;
  public acrescimoPercentual: number = 0;
  public agronomo: string;
  public aprovarMovimentacao: string = 'N';
  public ativo: string = 'S';
  public chave: string;
  public chaveFiscal: string;
  public classificacaoBloqueadoEstoque: string;
  public classificacaoBloqueadoEstoqueNfe: string;
  public classificacaoBloqueadoFinanceiro: string;
  public classificacaoVinculaOutroParceiro: boolean;
  public clienteNomeFantasia: string = '';
  public cofinsBase: number = 0;
  public cofinsValor: number = 0;
  public colaborador: string;
  public colaboradorAbreviacao: string;
  public consumidorFinal: string = 'N';
  public contagemProduto: number = 0;
  public descontoPercentual: number = 0;
  public descontoPercentualItem: number = 0;
  public descontoTotal: number = 0;
  public descontoValor: number = 0;
  public despesaValor: number = 0;
  public documentoFiscal: number;
  public emitente: number;
  public enderecoOutro: string;
  public entradaSaida: string = '0';
  public entradaSaidaInterna: number;
  public especie: string = 'V';
  public estoqueEscolha: number = 0;
  public expandido: boolean = false;
  public financeiro: string = 'N';
  public freteTipo: number;
  public freteValor: number = 0;
  public freteValorNaoSoma: number = 0;
  public horaSaida: string = '00:00:00';
  public ibptEstadualPercentual: number = 0;
  public ibptEstadualValor: number = 0;
  public ibptNacionalPercentual: number = 0;
  public ibptNacionalValor: number = 0;
  public icmsBase: number = 0;
  public icmsDesoneradoValor: number = 0;
  public icmsDispensadoValor: number = 0;
  public icmsStBase: number = 0;
  public icmsStValor: number = 0;
  public icmsValor: number = 0;
  public id: number;
  public idAgronomo: number;
  public idClassificacao: number;
  public idColaborador: number;
  public idEmpresa: number;
  public idEstadoTransportadora: number;
  public idLoja: number;
  public idLojaDestino: number;
  public idNaturezaOperacao: number;
  public idNegociacao: number;
  public idOperacao: number;
  public idParceiro: number;
  public idParceiroDestino: number;
  public idParceiroEndereco: number;
  public idTipoTituloParceiro: number;
  public idTransportadora: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public identificacao: number;
  public importacaoXml: string = 'N';
  public indeterminado: boolean = false;
  public informacaoComplementar: string;
  public ipiBase: number = 0;
  public ipiValor: number = 0;
  public limiteCustoReposicao: number = 0;
  public loja: string;
  public lucroPercentual: number = 0;
  public lucroValor: number = 0;
  public mediaDiaPrazo: number = 0;
  public modelo: string;
  public movimentacaoCancelamento: MovimentacaoCancelamento;
  public movimentacaoCartaCorrecao: MovimentacaoCartaCorrecao;
  public movimentacaoChaves: MovimentacaoChave[] = [];
  public movimentacaoNfe: MovimentacaoNfe;
  public movimentacaoParcelas: MovimentacaoParcela[] = [];
  public movimentacaoProdutoFaltantes: MovimentacaoProduto[] = [];
  public movimentacaoProdutos: MovimentacaoProduto[] = [];
  public naturezaOperacao: string;
  public naturezaOperacaoFinalidade: number;
  public naturezaOperacaoUsaCustoReposicao: string = 'N';
  public negociacao: Negociacao;
  public nomeParceiro: string;
  public numero: number = 0;
  public observacao: string;
  public operacao: string;
  public operacaoAbreviacao: string;
  public outroValor: number = 0;
  public parceiro: string;
  public parceiroCpfCnpj: string;
  public parceiroContribuinte: number;
  public parceiroDiaFechamento: number;
  public parceiroEndereco: string;
  public parceiroIdClienteEspecial: number;
  public parceiroIdRelacionamento;
  public parceiroIdTipoTitulo: number;
  public parceiroLimiteDisponivel: number;
  public parceiroPrazoFixo: number;
  public parceiroTipoNegociacao: string;
  public parceiroTipoPessoa: string;
  public parceiroInscricao: string;
  public parcelamento: number = 0;
  public permiteEntrega: boolean;
  public permitirEntregaDireta: string;
  public pesoBruto: number = 0;
  public pesoLiquido: number = 0;
  public pisBase: number = 0;
  public pisValor: number = 0;
  public relacionamento: Relacionamento;
  public seguroValor: number = 0;
  public selecionado: string = 'N';
  public serie: string;
  public situacao: number = 1;
  public situacaoDenegada: string = 'N';
  public status: string = 'N';
  public tipoTituloParceiro: string;
  public transportadora: string;
  public transportadoraEspecie: string;
  public transportadoraMarca: string;
  public transportadoraNumeracao: string;
  public transportadoraPlaca: string;
  public transportadoraQuantidade: number;
  public transportadoraUfPlaca: string;
  public usaLucro: string = 'N';
  public valorBloqueado: number = 0;
  public valorComissao: number = 0;
  public valorDiferencaBalanco: number = 0;
  public valorEntradaBalanco: number = 0;
  public valorFinanceiro: number = 0;
  public valorSaidaBalanco: number = 0;
  public valorTotal: number = 0;
  public valorTotalProduto: number = 0;
  public valorTotalProdutoBruto: number = 0;
  public valorTotalProdutoEntrada: number = 0;
  public valorTotalProdutoSaida: number = 0;
  public consultandoNfeNaoTransmitida: string = 'N';
  public atualizandoConsultaNfe: string = 'N';
  public receituarioPendente: number = 0;
  public idSafra: number;
  public idTecnospeed: string;
  public inssRetidoValor: number = 0;
  public irrfRetidoValor: number = 0;
  public csllRetidoValor: number = 0;
  public pisRetidoValor: number = 0;
  public cofinsRetidoValor: number = 0;
  public deducoesValor: number = 0;
  public outrasRetencoesValor: number = 0;
  public issBase: number = 0;
  public issValor: number = 0;
  public idCentroResultado: number;
  public centroResultado: string;

  setDescontoPercentual(descontoPercentual: number): void {
    this.descontoPercentual = new Util().arredondar(descontoPercentual);
  }

  calcularBalanco(): void {
    this.valorSaidaBalanco = 0;
    this.valorEntradaBalanco = 0;
    this.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (movimentacaoProduto.estoqueFisico == -1) {
        this.valorSaidaBalanco += (movimentacaoProduto.getQuantidade() ? movimentacaoProduto.getQuantidade() : 0) * (movimentacaoProduto.custoGerencial ? movimentacaoProduto.custoGerencial : 0);
      } else if (movimentacaoProduto.estoqueFisico == 1) {
        this.valorEntradaBalanco += (movimentacaoProduto.getQuantidade() ? movimentacaoProduto.getQuantidade() : 0) * (movimentacaoProduto.custoGerencial ? movimentacaoProduto.custoGerencial : 0);
      }
    });
    this.valorDiferencaBalanco = this.valorEntradaBalanco - this.valorSaidaBalanco;
  }
}
