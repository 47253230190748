import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.data, campo: 'data' },
  { rotulo: bibDialogo.dataPreDatada, campo: 'dataPreDatada' },
  { rotulo: bibDialogo.dataFinalizado, campo: 'dataFinalizado' },
  { rotulo: bibDialogo.parceiro, campo: 'parceiro' },
  { rotulo: bibDialogo.contaAtual, campo: 'conta' },
  { rotulo: bibDialogo.numeroBanco, campo: 'numeroBanco' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
];

export default grupos;
