import { Type } from 'class-transformer';

export class Comissao {
  @Type(() => Date)
  public dataHoraInclusao: Date;
  @Type(() => Date)
  public dataVencimento: Date;
  @Type(() => Date)
  public dataVencimentoPagamentoComissao: Date;
  @Type(() => Date)
  public dataMovimentacao: Date;
  @Type(() => Date)
  public dataSolicitacaoPagamento: Date;

  public colaborador: string;
  public empresa: string;
  public id: number;
  public idColaborador: number;
  public idFinanceiro: number;
  public idLoja: number;
  public idMovimentacao: number;
  public loja: string;
  public lojaAbreviacao: string;
  public lote: string;
  public mesAnoReferencia: string;
  public numeroMovimentacao: number;
  public parceiro: string;
  public selecionado: string;
  public valor: number;
  public valorLiberado: number;
  public valorDisponivel: number;
  public valorLote: number = 0;
  public valorPago: number;
  public valorRestante: number;
  public gestor: string;
}
