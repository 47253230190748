import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  templateUrl: './r1105.component.html',
})
export class R1105Component extends RelatorioComponent {
  public filtros = filtros;
  public produtos: any[] = [];
  public tabelasPrecos: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.produtos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Produto').lista;
      this.tabelasPrecos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'TabelaPreco').lista;
      this.juntarTabelaPrecoProduto();
      this.ehImprimirRelatorio();
    });
  }

  juntarTabelaPrecoProduto(): void {
    this.produtos.forEach((produto) => {
      this.popularTabelaPreco(produto);
    });
  }

  popularTabelaPreco(produto: any) {
    produto.tabelasPrecos = [];
    this.tabelasPrecos.forEach((tabelaPreco) => {
      let i: number = 0;
      if (tabelaPreco.taxa == 1) {
        let juroValor: number = (tabelaPreco.juroPercentual / 100) * produto.precoVenda;
        tabelaPreco.valorVenda = produto.precoVenda + juroValor;
      } else {
        let juroValor: number;
        if (i == 0) {
          juroValor = (tabelaPreco.juroPercentual / 100) * produto.precoVenda;
        } else {
          juroValor = (tabelaPreco.juroPercentual / 100) * produto.precoVenda;
          juroValor = (tabelaPreco.juroPercentual / 100) * produto.tabelasPrecos[i - 1].valorVenda;
        }
        if (tabelaPreco.taxa == 2) {
          tabelaPreco.valorVenda = produto.precoVenda + juroValor;
        }
        if (tabelaPreco.taxa == 3) {
          tabelaPreco.valorVenda = produto.precoVenda - juroValor;
        }
      }
      produto.tabelasPrecos.push(this.clonarSemReferencia(tabelaPreco));
      i++;
    })
  }

  clonarSemReferencia(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      const cloneArray = [];
      for (const item of obj) {
        cloneArray.push(this.clonarSemReferencia(item));
      }
      return cloneArray;
    }
    const cloneObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        cloneObj[key] = this.clonarSemReferencia(obj[key]);
      }
    }
    return cloneObj;
  }
}
