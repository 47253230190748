import { Aprovacao } from 'src/app/modelo/aprovacao';
import { AprovacaoFrmComponent } from './aprovacaoFrm.component';
import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import { Filtro } from 'src/app/modelo/filtro';
import filtros from './filtro';
import { FinanceiroFrmComponent } from '../financeiro/frm/financeiroFrm.component';

@Component({
  templateUrl: './aprovacao.component.html',
})
export class AprovacaoComponent extends PaginaComponent {
  private modalEmt: any;
  private params: any;
  public aprovacoes: Aprovacao[] = [];
  public componente: any;
  public filtros: Filtro[];
  public parametro: string;

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.aprovacao) {
        if (identificacao.conteudo) {
          this.atualizar(identificacao.conteudo);
        }
      }
    });
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params['parametro'];
      this.aprovacoes = [];
      this.selecionarFiltro();
    });
  }

  selecionarFiltro(): void {
    switch (this.parametro) {
      case 'VENDA':
        this.filtros = filtros.filtrosVenda;
        break;
      case 'COMPRA':
        this.filtros = filtros.filtrosCompra;
        break;
      case 'COMISSAO':
        this.filtros = filtros.filtrosComissao;
        break;
      case 'FINANCEIRO':
        this.filtros = filtros.filtrosFinanceiro;
        break;
      case 'GESTOR':
        this.filtros = filtros.filtrosGestor;
        break;
      case 'DEPOSITO':
        this.filtros = filtros.filtrosDeposito;
        break;
      case 'TODAS':
        this.filtros = filtros.filtrosTodas;
        break;
    }
  }

  ngOnDestroy(): void {
    this.modalEmt.unsubscribe();
    this.params.unsubscribe();
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    if (this.parametro != this.bibClasse.comissao.toUpperCase() && this.parametro != this.bibDialogo.gestor.toUpperCase() && this.parametro != this.bibDialogo.deposito.toUpperCase()) {
      criterios.push(new Criterio('OPERACAO_USUARIO', 'S'));
    }
    criterios.push(new Criterio(this.parametro, 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.aprovacao).subscribe((res) => {
      this.aprovacoes = this.plainToClass(Aprovacao, res) as any;
      this.definirFinalizadoOrigem();
    });
  }

  definirFinalizadoOrigem(): void {
    this.aprovacoes.forEach((aprovacao) => {
      aprovacao.finalizado = aprovacao.finalizadoOrigem.split('_')[0];
      aprovacao.origem = Number(aprovacao.finalizadoOrigem.split('_')[1]);
    });
  }

  abrirModal(id: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('id', id));
    this.componente = AprovacaoFrmComponent;
  }

  atualizar(aprovacao: Aprovacao): void {
    let aprovacaoAtual: Aprovacao = this.aprovacoes.find((_aprovacao) => _aprovacao.id == aprovacao.id);
    aprovacaoAtual.situacao = aprovacao.situacao;
  }

  abrirModalOrigem(aprovacao: Aprovacao): void {
    if (aprovacao.categoria == 'G') {
      this.utilSessao.setIdentificacao(new Identificacao('parametro', 'D'));
      this.utilSessao.setIdentificacao(new Identificacao('objeto', aprovacao.origem));
      this.utilSessao.setIdentificacao(new Identificacao('modalBloqueada', 'S'));
      this.componente = FinanceiroFrmComponent;
    } else {
      this.utilSessao.setIdentificacao(new Identificacao('objeto', aprovacao.origem));
      this.utilSessao.setIdentificacao(new Identificacao('bloqueado', true));
      this.componente = MovimentacaoFrmComponent;
    }
  }
}
