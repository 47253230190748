<ng-container>
  <td class="p-0" [colSpan]="7">
    <table>
      <tbody>
        <ng-container *ngFor="let correntista of correntistas">
          <tr class="border-bottom border-dark">
            <td class="r-1-5">{{ correntista.numeracao }}</td>
            <td class="r">{{ correntista.nome.split(' ').join('&nbsp;') }}</td>
            <td class="r-1-5 text-right">{{ correntista.saldoAnterior | monetario }}</td>
            <td class="r-1-5 text-right">{{ correntista.debito | monetario }}</td>
            <td class="r-1-5 text-right">{{ correntista.credito | monetario }}</td>
            <td class="r-1-5 text-right">{{ correntista.saldoAtual | monetario }}</td>
            <td class="r-0-5"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
