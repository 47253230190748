const bibImagem = {
  acoes: 'acoes',
  agronomo: 'agronomo',
  alertas: 'alertas',
  anvisa: 'anvisa',
  aspas: 'aspas',
  atualizacaocadastro: 'atualizacaocadastro',
  baixa: 'baixa',
  cadeado: 'cadeado',
  cadeadoclaro: 'cadeadofechadoclaro',
  cadeadoescuro: 'cadeadofechadoescuro',
  cartao: 'cartao',
  checkbox: 'checkbox',
  cliente: 'cliente',
  colaborador: 'colaborador',
  comissao: 'comissao',
  compensacao: 'compensacao',
  complementoProduto: 'complementoproduto',
  configuracaoTabelaPreco: 'configuracaotabelapreco',
  contabilidade: 'contabilidade',
  credito: 'credito',
  cstcfop: 'cstcfop',
  cstcofins: 'cstcofins',
  csticmsentrada: 'csticmsentrada',
  csticmssaida: 'csticmssaida',
  cstipi: 'cstipi',
  cstmva: 'cstmva',
  cstpis: 'cstpis',
  custoReposicao: 'custoreposicao',
  dados: 'dados',
  dadoscomplementares: 'dadoscomplementares',
  descricao: 'descricao',
  destino: 'destino',
  dinheiro: 'dinheiro',
  documentos: 'documentos',
  empresafilial: 'empresafilial',
  endereco: 'endereco',
  estoque: 'estoque',
  familia: 'familia',
  fechamento: 'fechamento',
  financeiroSelecionados: 'financeiroSelecionados',
  fotos: 'fotos',
  fotosPretoBranco: 'fotosPretoBranco',
  grupo: 'grupo',
  historico: 'historico',
  icmsdesonerado: 'icmsdesonerado',
  icmsdiferimento: 'icmsdiferimento',
  icmsstsaida: 'icmsstsaida',
  informacaoComplementar: 'informacaoComplementar',
  juroautomatico: 'juroautomatico',
  lancamentocontabil: 'lancamentocontabil',
  layout: 'layout',
  limitar: 'limitar',
  limiteCredito: 'limiteCredito',
  loja: 'loja',
  ncm: 'ncm',
  nfe: 'nfe',
  observacao: 'observacao',
  observacaoPretoBranco: 'observacaoPretoBranco',
  ocorrencia: 'ocorrencia',
  pagamento: 'pagamento',
  penalidade: 'penalidade',
  percentual: 'percentual',
  permissoes: 'permissoes',
  pesquisa: 'pesquisa',
  pessoa: 'pessoa',
  produto: 'produto',
  produtoclassificacao: 'produtoclassificacao',
  produtoestoque: 'produtoestoque',
  produtoestoqueminimomaximo: 'produtoestoqueminimomaximo',
  produtoimpostos: 'produtoimpostos',
  produtopeso: 'produtopeso',
  produtotipo: 'produtotipo',
  receituario: 'receituario',
  regras: 'regras',
  resumo: 'resumo',
  semFoto: 'semfoto',
  telefone: 'telefone',
  transporte: 'transporte',
  unidadealternativa: 'unidadealternativa',
  vendedor: 'vendedor',
};

export default bibImagem;
