import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { MotivoOcorrencia } from 'src/app/modelo/motivoOcorrencia';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroOcorrencia } from 'src/app/modelo/parceiroOcorrencia';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'parceiroOcorrencia',
  templateUrl: './parceiroOcorrencia.component.html',
})
export class ParceiroOcorrenciaComponent extends PaginaComponent {
  public motivoOcorrencias: MotivoOcorrencia[];
  public parceiroOcorrencia: ParceiroOcorrencia = new ParceiroOcorrencia();
  @Input() parceiro: Parceiro;
  @Input() parceiroOcorrencias: ParceiroOcorrencia[] = [];
  @Input() excluirOcorrencias: number[] = [];
  @Input() edicaoParceiroOcorrencias: number[] = [];

  ngOnInit(): void {
    this.listarMotivoOcorrencia();
  }

  listarMotivoOcorrencia(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.motivoOcorrencia).subscribe((res) => {
      this.motivoOcorrencias = this.plainToClass(MotivoOcorrencia, res) as any;
    });
  }

  setMotivoOcorrencia(parceiroOcorrencia: ParceiroOcorrencia, motivoOcorrencia: MotivoOcorrencia) {
    parceiroOcorrencia.idMotivoOcorrencia = motivoOcorrencia.id;
    parceiroOcorrencia.motivoOcorrencia = motivoOcorrencia.nome;
  }

  adicionarOcorrencia(): void {
    let parceiroOcorrencia = new ParceiroOcorrencia();
    parceiroOcorrencia.idParceiro = this.parceiro.id;
    this.parceiro.parceiroOcorrencias.push(parceiroOcorrencia);
    this.listarMotivoOcorrencia();
  }

  editarOcorrencia(parceiroOcorrencia: ParceiroOcorrencia): void {
    if (this.edicaoParceiroOcorrencias.includes(parceiroOcorrencia.id)) {
      const posicao: number = this.edicaoParceiroOcorrencias.indexOf(parceiroOcorrencia.id, 0);
      this.edicaoParceiroOcorrencias.splice(posicao, 1);
    } else {
      this.edicaoParceiroOcorrencias.push(parceiroOcorrencia.id);
    }
  }

  excluirParceiroOcorrencia(posicao: number, idParceiroOcorrencia: number): void {
    super.ehExcluir(posicao, this.parceiro.parceiroOcorrencias, this.excluirOcorrencias, idParceiroOcorrencia);
  }
}
