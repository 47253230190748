import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: '<tr>[r1126dados]</tr>',
  templateUrl: './r1126dados.component.html',
})
export class R1126DadosComponent {
  @Input() parceiro: any;
  public bibDialogo = bibDialogo;
  public rotuloRamoAtividade: string = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 21).apelido;
}
