<div class="row">
  <titulo class="col-sm-12" [titulo]="bibDialogo.transporte" [imagem]="bibImagem.transporte"></titulo>
  <busca class="col-sm-6" [id]="'transportadora'" [idMenu]="movimentacaoControlador.telaTransportadora && movimentacaoControlador.telaTransportadora.inserir == 'S' ? movimentacaoControlador.menuTransportadora.idMenu : null" [rotulo]="movimentacaoControlador.menuTransportadora.apelido" [campo]="movimentacaoControlador.movimentacao.transportadora" (alteracao)="definirTransportadora($event)" [desabilitado]="movimentacaoControlador.bloqueado" [servico]="movimentacaoControlador.bibServico.veiculoParceiro" [atencao]="'3'"></busca>
  <texto class="col-sm-3" [id]="'placa'" [rotulo]="bibDialogo.placa" [campo]="movimentacaoControlador.movimentacao.transportadoraPlaca" (alteracao)="movimentacaoControlador.movimentacao.transportadoraPlaca = $event" [desabilitado]="movimentacaoControlador.bloqueado" [maximoCaracteres]="8"></texto>
  <lista class="col-sm-3" [id]="'ufPlaca'" [rotulo]="bibDialogo.uf + ' ' + bibDialogo.placa.toLowerCase()" [campo]="movimentacaoControlador.movimentacao.idEstadoTransportadora" (alteracao)="movimentacaoControlador.movimentacao.idEstadoTransportadora = $event.id" [lista]="estados" [desabilitado]="movimentacaoControlador.bloqueado"></lista>
  <lista class="col-sm-6" [id]="'tipoFrete'" [rotulo]="bibDialogo.freteTipo" [campo]="movimentacaoControlador.movimentacao.freteTipo" [lista]="listaTipoFrete" (alteracao)="movimentacaoControlador.movimentacao.freteTipo = $event.id" [desabilitado]="movimentacaoControlador.bloqueado" [obrigatorio]="(this.movimentacaoControlador.notaTerceiro && this.movimentacaoControlador.movimentacao.modelo != null) || this.movimentacaoControlador.nota"></lista>
  <texto class="col-sm-3" [id]="'quantidade'" [rotulo]="bibDialogo.transporteQuantidade" [campo]="movimentacaoControlador.movimentacao.transportadoraQuantidade" (alteracao)="movimentacaoControlador.movimentacao.transportadoraQuantidade = $event" [desabilitado]="movimentacaoControlador.bloqueado" [tipo]="movimentacaoControlador.bibPropriedade.texto.inteiro"></texto>
  <texto class="col-sm-3" [id]="'numeracao'" [rotulo]="bibDialogo.transporteNumeracao" [campo]="movimentacaoControlador.movimentacao.transportadoraNumeracao" (alteracao)="movimentacaoControlador.movimentacao.transportadoraNumeracao = $event" [desabilitado]="movimentacaoControlador.bloqueado" [tipo]="this.bibPropriedade.texto.inteiro"></texto>
  <texto class="col-sm-6" [id]="'especie'" [rotulo]="bibDialogo.transporteEspecie" [campo]="movimentacaoControlador.movimentacao.transportadoraEspecie" (alteracao)="movimentacaoControlador.movimentacao.transportadoraEspecie = $event" [desabilitado]="movimentacaoControlador.bloqueado" [maximoCaracteres]="100"></texto>
  <texto class="col-sm-6" [id]="'marca'" [rotulo]="bibDialogo.transporteMarca" [campo]="movimentacaoControlador.movimentacao.transportadoraMarca" (alteracao)="movimentacaoControlador.movimentacao.transportadoraMarca = $event" [desabilitado]="movimentacaoControlador.bloqueado" [maximoCaracteres]="100"></texto>
  <decimal class="col-sm-6" [id]="'pesoLiquido'" [rotulo]="bibDialogo.pesoLiquido" [campo]="movimentacaoControlador.movimentacao.pesoLiquido" (alteracao)="movimentacaoControlador.movimentacao.pesoLiquido = $event" [desabilitado]="movimentacaoControlador.bloqueado" [sufixo]="'KG'" [numeroCasasDecimais]="3"></decimal>
  <decimal class="col-sm-6" [id]="'pesoBruto'" [rotulo]="bibDialogo.pesoBruto" [campo]="movimentacaoControlador.movimentacao.pesoBruto" (alteracao)="movimentacaoControlador.movimentacao.pesoBruto = $event" [desabilitado]="movimentacaoControlador.bloqueado" [sufixo]="'KG'" [numeroCasasDecimais]="3"></decimal>
</div>
