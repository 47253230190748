export class Modulo {
  public icone: string;
  public id: number;
  public idCor: number;
  public idModuloPai: number;
  public nivel: number;
  public nome: string;
  public ordem: number;
  public titulo: string;
}
