import { Component, HostListener, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import bibAtalho from './biblioteca/bibAtalho';
import bibDialogo from './biblioteca/bibDialogo';
import bibIcone from './biblioteca/bibIcone';
import { Conexao } from './biblioteca/conexao';
import { PesquisaProdutoComponent } from './formulario/pesquisa/produto/pesquisaProduto.component';
import { Identificacao } from './modelo/identificacao';
import { IntegracaoUsuario } from './modelo/integracaoUsuario';
import { MenuEmpresa } from './modelo/menuEmpresa';
import { Permissao } from './modelo/permissao';
import { Relatorio } from './modelo/relatorio';
import { Usuario } from './modelo/usuario';
import { AtalhoComponent } from './pagina/atalhos/atalhos.component';
import { LoginEmitter } from './pagina/login/login.emitter';
import { ParceiroFrmComponent } from './pagina/parceiro/frm/parceiroFrm.component';
import { Util } from './utilitario/util';
import { UtilNotificacao } from './utilitario/util.notificacao';
import { UtilSessao } from './utilitario/util.sessao';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
})
export class AppComponent {
  private menuPrincipalEmitter;
  public permissoes: any[] = [];
  public acessoSemLogin: boolean = false;
  public atalho = bibAtalho;
  public autenticado: boolean = false;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bloqueiaTela: boolean;
  public componente: any = null;
  public desejo: string = '';
  public ehModoClaro: boolean = this.utilSessao.getUsuario() && this.utilSessao.getUsuario().usaModoClaro == 'S';
  public favoritos: any[] = [];
  public focusDesejo: boolean;
  public idUsuarioAtual: number;
  public intervaloVerificaSessao: any;
  public loadAPI: Promise<any>;
  public menu: boolean = false;
  public menuCarregado: boolean = false;
  public menuRelatoriosCategoria: any[] = [];
  public menus: any[] = [];
  public nome: string = '';
  public permissaoAbrirPesquisaCliente: Permissao;
  public permissaoAbrirPesquisaProduto: Permissao;
  public relatorios: Relatorio[] = [];
  public relatoriosCategoria: any[] = [];
  public relatoriosUsuario: any[] = [];
  public dashboardUsuario: any[] = [];
  public saindo: boolean = false;
  public abreviacaoLoja: string;
  public usaRelatorio: boolean = false;
  public usuario: Usuario = this.utilSessao.getUsuario();
  public loginCompleto: boolean = this.utilSessao.getLoginCompleto();
  public usuarioIntegracoes: IntegracaoUsuario[] = [];
  public util: Util;
  public conexao: Conexao;

  @ViewChildren('input') input: any;

  constructor(private utilNotificacao: UtilNotificacao, private router: Router, private loginEmitter: LoginEmitter, public utilSessao: UtilSessao) {
    this.util = new Util(router);
    this.relatorios = this.utilSessao.getRelatorios();
    this.relatoriosUsuario = this.utilSessao.getRelatorioUsuarios();
    this.usuarioIntegracoes = this.utilSessao.getUsuarioIntegracoes();
    this.dashboardUsuario = this.utilSessao.getDashboardUsuarios();
    this.permissoes = this.utilSessao.getPermissoes();
    this.definirSistema();
    this.conexao = new Conexao(this.utilSessao);
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (event.keyCode == this.atalho.cancelar) {
      let menuPersonalizado = this.utilSessao.getMenuPersonalizado();
      if (menuPersonalizado == false || menuPersonalizado == null) {
        this.focarDesejo();
      }
    }
    if (event.ctrlKey && (event.key === bibAtalho.q || event.key === bibAtalho.qMaiusculo)) {
      this.bloqueiaTela = true;
      this.utilSessao.setBloqueado(true);
    }
    if (event.ctrlKey && (event.key === bibAtalho.i || event.key === bibAtalho.iMaiusculo)) {
      if (!this.bloqueiaTela) {
        this.abrirPesquisaCliente();
      }
    }

    if (event.keyCode == this.atalho.pesquisaProduto) {
      if (!this.bloqueiaTela) {
        this.abrirPesquisaProduto();
      }
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    this.bloqueiaTela = this.utilSessao.getBloqueado();
  }

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(): void {
    this.utilNotificacao.ehModoClaroEmmiter.emit(true);
    this.removerModoEscuro();
  }

  @HostListener('window:afterprint', ['$event'])
  onAfterPrint(): void {
    this.carregarCss();
    this.utilNotificacao.ehModoClaroEmmiter.emit(this.utilSessao.getUsuario().usaModoClaro == 'S');
  }

  ngOnInit(): void {
    if (this.utilSessao.getDataAtualLogin()) {
      const dataAtual = new Date();
      if (dataAtual.getDate() != new Date(this.utilSessao.getDataAtualLogin()).getDate()) {
        this.utilSessao.setDataAtualLogin(new Date());
        window.location.reload();
      }
    } else {
      this.utilSessao.setDataAtualLogin(new Date());
    }
    this.verificarDiferencaVersao();
    this.bloqueiaTela = this.utilSessao.getBloqueado();
    this.utilNotificacao.usaRelatorioEmt.subscribe((res) => {
      this.usaRelatorio = res;
    });

    this.loginEmitter.permitirEmitter.subscribe((res) => {
      if (res != null) {
        this.permissoes = res;
        this.permissaoAbrirPesquisaProduto = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 156);
        this.permissaoAbrirPesquisaCliente = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 161);
      }
      this.utilNotificacao.setMenuPrincipal();
    });

    this.utilNotificacao.relatoriosEmt.subscribe((res) => {
      this.relatoriosUsuario = res;
    });

    this.utilNotificacao.dashboardEmt.subscribe((res) => {
      this.dashboardUsuario = res;
    });

    this.utilNotificacao.usuarioIntegracaoEmt.subscribe((res) => {
      this.usuarioIntegracoes = res;
    });

    this.loginEmitter.saindo.subscribe((res) => {
      if (res != null) {
        this.saindo = res;
        this.nome = null;
      }
    });

    this.loginEmitter.idEmitter.subscribe((res) => {
      if (res != null) {
        this.idUsuarioAtual = res;
      }
    });

    this.loginEmitter.acessoSemLoginEmitter.subscribe((res) => {
      this.acessoSemLogin = res;
      this.menu = this.autenticado || this.acessoSemLogin;
    });

    this.loginEmitter.usuarioEmitter.subscribe((res) => {
      this.usuario = res;
      if (this.usuario) {
        this.menu = true;
        this.ehModoClaro = this.usuario.usaModoClaro == 'S';
      } else {
        this.menu = false;
      }
      this.carregarCss();
      setTimeout((_) => this.dadosUsuario());
    });

    this.loginEmitter.relatoriosCategoriaEmitter.subscribe((res) => {
      if (res != null) {
        this.relatoriosCategoria = res;
      } else {
        this.relatoriosCategoria = [];
      }
    });

    this.menuPrincipalEmitter = this.utilNotificacao.menuPrincipalEmitter.subscribe((res) => {
      if (res) {
        this.removerJS();
        this.menuCarregado = false;
      }
      this.carregarJS();
    });

    this.utilNotificacao.modalEmt.subscribe((parametro) => {
      switch (parametro) {
        case 'pesquisaProduto':
          this.abrirPesquisaProduto();
          break;

        case 'pesquisaCliente':
          this.abrirPesquisaCliente();
          break;

        default:
          break;
      }
    });

    this.utilNotificacao.ehModoClaroEmmiter.subscribe((ehModoClaro) => {
      this.ehModoClaro = ehModoClaro;
    });
    this.utilSessao.setMenuPersonalizado(false);
    setTimeout((_) => this.carregarPermissoes());
    this.intervaloVerificaSessao = setInterval(() => {
      if (!this.utilSessao.getUsuario() && this.loginCompleto) {
        this.sair();
      }
    }, 30000);

    this.loginEmitter.loginCompletoEmitter.subscribe((res) => {
      this.loginCompleto = res;
    });
  }

  definirSistema() {
    this.utilSessao.sistema = 'aGrow-w';
    if (window.location.href.indexOf('sigescom.') >= 0) {
      this.utilSessao.sistema = 'Sigescom-w';
      this.utilSessao.backend = 'backend';
    } else {
      if (window.location.href.indexOf('erp.') >= 0) {
        this.utilSessao.backend = 'backend';
      } else {
        if (window.location.href.indexOf('homologacao.') >= 0) {
          this.utilSessao.backend = 'backhom';
        } else {
          if (window.location.href.indexOf('adm.') >= 0) {
            this.utilSessao.backend = 'backadm';
          } else {
            this.utilSessao.backend = 'localhost';
          }
        }
      }
    }
  }

  verificarDiferencaVersao(): void {
    if (this.usuario && this.loginCompleto == null) {
      this.sair();
    }
  }

  carregarCss(): void {
    var cssDinamicos: any[] = [];
    if (this.utilSessao.getUsuario() && this.utilSessao.getUsuario().usaModoClaro != 'S') {
      cssDinamicos = ['assets/stylesheets/theme-dark.css'];
    }
    if (cssDinamicos != null) {
      for (var i = 0; i < cssDinamicos.length; i++) {
        let node = document.createElement('link');
        node.rel = 'stylesheet';
        node.href = cssDinamicos[i];
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
    this.ehRetirarModoEscuro();
  }

  ehRetirarModoEscuro(): void {
    if (this.utilSessao.getUsuario() && this.utilSessao.getUsuario().usaModoClaro == 'S') {
      this.removerModoEscuro();
    }
  }

  removerModoEscuro(): void {
    document.querySelectorAll('[href="assets/stylesheets/theme-dark.css"').forEach((evento) => {
      evento.remove();
    });
  }

  dadosUsuario(): void {
    this.montarNome();
  }

  montarNome(): void {
    if (this.usuario) {
      let indice: number = this.usuario.nome.indexOf(' ');
      let primeiraLetra: string = this.usuario.nome.substr(0, 1).toUpperCase();
      let restante: string = this.usuario.nome.substr(1, indice != -1 ? indice - 1 : 15).toLowerCase();
      let sobrenome: string = this.usuario.nome.split(' ')[this.usuario.nome.split(' ').length - 1];
      this.nome = primeiraLetra + restante;
      this.abreviacaoLoja = this.util.getEhAbreviacaoLoja(false, this.nome.toUpperCase() == sobrenome.toUpperCase() ? '' : sobrenome);
    }
  }

  removerJS(): void {
    document.querySelectorAll('.scriptsMenu').forEach((evento) => {
      evento.remove();
    });
  }

  carregarJS(): void {
    var dynamicScripts: any[] = [];
    if (!this.menuCarregado) {
      this.menuCarregado = true;
      dynamicScripts = ['assets/vendor/stacked-menu/stacked-menu.js', 'assets/javascript/main.js', 'assets/vendor/perfect-scrollbar/perfect-scrollbar.min.js'];
    }

    if (dynamicScripts != null) {
      for (var i = 0; i < dynamicScripts.length; i++) {
        let elemento: any = document.createElement('script');
        elemento.src = dynamicScripts[i];
        elemento.classList.add('scriptsMenu');
        document.getElementsByTagName('body')[0].appendChild(elemento);
      }
    }
  }

  abrirPesquisaCliente(): void {
    if (this.permissaoAbrirPesquisaCliente) {
      this.utilSessao.excluirIdentificacao('id');
      this.utilSessao.excluirIdentificacao('idParceiro');
      this.utilSessao.setIdentificacao(new Identificacao('pesquisaCliente', true));
      this.utilSessao.setIdentificacao(new Identificacao('parametro', 'CLIENTE'));
      this.utilSessao.setPermissaoEspecifica(this.permissaoAbrirPesquisaCliente);
      this.componente = ParceiroFrmComponent;
    }
  }

  abrirPesquisaProduto(): void {
    if (this.permissaoAbrirPesquisaProduto) {
      this.utilSessao.setIdentificacao(new Identificacao('retornaConsulta', false));
      const idLoja: number = this.utilSessao.getIdLoja() ? this.utilSessao.getIdLoja() : this.utilSessao.getLojas().length == 1 ? this.utilSessao.getLojas()[0].id : null;
      this.utilSessao.setIdentificacao(new Identificacao('idLoja', idLoja));
      this.componente = PesquisaProdutoComponent;
    }
  }

  abrirModalAtalhos(): void {
    this.componente = AtalhoComponent;
  }

  bloqueiar(): void {
    this.bloqueiaTela = true;
    this.utilSessao.setBloqueado(true);
  }

  abrirMinhaConta(): void {
    event.preventDefault();
    this.utilSessao.setPermissao(null);
    this.utilSessao.setIdentificacao(new Identificacao('id', this.utilSessao.getUsuario().id));
    this.router.navigate(['/usuarioFrm/MinhaConta']);
  }

  ehAbrirMenuPersonalizado(): void {
    if (this.utilSessao.ambienteProducao == true && this.utilSessao.getAlteracao() && !this.utilSessao.modal && !this.utilSessao.falha && this.router.url != '/') {
      if (confirm(bibDialogo.desejaSubstituirTela)) {
        this.abrirMenuPersonalizado();
      }
    } else {
      this.abrirMenuPersonalizado();
    }
  }

  abrirMenuPersonalizado(): void {
    event.preventDefault();
    this.router.navigate(['/menuPersonalizado']);
    this.utilNotificacao.menuPersonalizadoEmt.emit(1);
  }

  carregarPermissoes(): void {
    this.permissoes = this.utilSessao.getPermissoes();
  }

  focarDesejo(): void {
    this.input.first.nativeElement.focus();
    this.input.first.nativeElement.select();
  }

  destino(permissao, frm: any): string {
    let parametro: string = '';
    if (permissao.parametro) {
      if (frm == '') {
        parametro = permissao.parametro;
      } else {
        this.utilSessao.limparIdentificacoes();
        this.utilSessao.setIdentificacao(new Identificacao('parametro', permissao.parametro));
      }
    }
    return permissao.tela + (frm ? frm : '') + '/' + parametro;
  }

  destinoRelatorio(relatorio): string {
    let destino: string = '/relatorioFrm/' + relatorio.idRelatorio;
    return destino;
  }

  irPara(permissao: any, limparParametro: boolean = true) {
    event.preventDefault();
    this.ir(this.destino(permissao, false), limparParametro);
  }

  ir(destino: string, limparParametro: boolean = true, novaAbaNavegador: boolean = false) {
    if (limparParametro) {
      this.utilSessao.setParametro(null);
    }
    if (novaAbaNavegador) {
      let identificacao: Identificacao = this.utilSessao.getIdentificacao('idRelatorio');
      if (identificacao?.conteudo != null) {
        identificacao.conteudo = null;
        this.utilSessao.setIdentificacao(identificacao);
      }
      window.open('./#/' + destino, '_blank');
    } else {
      this.router.navigate(['/' + destino]);
    }
  }

  irDesejo(): void {
    if (this.desejo != '') {
      const ehDesejoValido: boolean = this.utilSessao.getMenuEmpresa().find((menu) => menu.tag.toUpperCase() == this.desejo.toUpperCase()) != undefined;
      const ehDesejoValidoVerbo: boolean = this.permissoes.find((permissao) => permissao.verbo && permissao.verbo.toUpperCase() == this.desejo.toUpperCase()) != undefined;
      if (this.utilSessao.ambienteProducao == true && this.utilSessao.getAlteracao() && !this.utilSessao.modal && !this.utilSessao.falha && this.desejo.indexOf('*') == -1 && this.desejo.toUpperCase() != 'ID' && !Number(this.desejo) && this.router.url != '/') {
        if (ehDesejoValido || ehDesejoValidoVerbo) {
          if (confirm(bibDialogo.desejaSubstituirTela)) {
            this.irDesejoEfetivo();
          }
        }
      } else {
        this.irDesejoEfetivo();
      }
    }
  }

  irDesejoEfetivo(): void {
    if (this.desejo.toLowerCase() == 'sair') {
      this.ehSair();
    } else if (this.desejo.toLowerCase() == bibDialogo.id.toLowerCase()) {
      this.utilSessao.mostraId = !this.utilSessao.mostraId;
      this.utilNotificacao.setMostrarId(this.utilSessao.mostraId);
    } else if (this.ehRelatorio()) {
      this.ir('/relatorioLista/relatorio', false);
    } else if (this.ehDashboard()) {
      this.ir('/relatorioLista/dashboard', false);
    } else if (this.ehIntegracao()) {
      this.ir('/integracaoLista', false);
    } else if (this.ehMenu()) {
      this.ir('/menuPersonalizado', false);
    } else if (Number(this.desejo)) {
      this.util.irRelatorio(Number(this.desejo));
    } else {
      let frm = '';
      let novaAbaNavegador: boolean = this.desejo.indexOf('*') != -1;
      let desejoAtual = this.desejo.replace('*', '').toLowerCase();
      if (desejoAtual[desejoAtual.length - 1] == '+') {
        desejoAtual = desejoAtual.substring(0, desejoAtual.length - 1);
        let menuEmpresa: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menu) => menu.tag == desejoAtual);
        if (menuEmpresa && menuEmpresa.modularizado == 'S') {
          frm = '/Frm';
        } else {
          frm = 'Frm';
        }
      }

      if (desejoAtual.length >= 3) {
        let permissaoDesejo = this.permissoes.filter((permissao) => permissao.tag.toLowerCase() == desejoAtual);
        if (permissaoDesejo.length == 0) {
          permissaoDesejo = this.permissoes.filter((permissao) => permissao.menu.toLowerCase() == desejoAtual);
        }
        if (permissaoDesejo.length == 0) {
          permissaoDesejo = this.permissoes.filter((permissao) => (typeof permissao.verbo == 'undefined' ? '' : permissao.verbo).toLowerCase() == desejoAtual);
        }
        if (permissaoDesejo.length > 0) {
          if (permissaoDesejo[0].parametro) {
            this.utilSessao.setParametro(permissaoDesejo[0].parametro);
          } else {
            this.utilSessao.setParametro(null);
          }
          if (permissaoDesejo[0].idOperacao) {
            this.utilSessao.setParametroTemporario(permissaoDesejo[0].idOperacao);
          } else {
            this.utilSessao.setParametroTemporario(null);
          }
          this.desejo = null;
          if (novaAbaNavegador) {
            this.ir(this.destino(permissaoDesejo[0], frm), false, true);
          } else {
            this.ir(this.destino(permissaoDesejo[0], frm), false);
          }
        }
      }
    }
  }

  ehMenu(): boolean {
    if (this.desejo.toLowerCase() == 'men' || this.desejo.toLowerCase() == 'menu') {
      return true;
    }
    return false;
  }

  ehRelatorio(): boolean {
    if (this.desejo.toLowerCase() == 'rel' || this.desejo.toLowerCase() == 'relatorio' || this.desejo.toLowerCase() == 'relatório') {
      return true;
    }
    return false;
  }

  ehDashboard(): boolean {
    if (this.desejo.toLowerCase() == 'das' || this.desejo.toLowerCase() == 'dashboard') {
      return true;
    }
    return false;
  }

  ehIntegracao(): boolean {
    if (this.desejo.toLowerCase() == 'int' || this.desejo.toLowerCase() == 'integracao' || this.desejo.toLowerCase() == 'integração') {
      return true;
    }
    return false;
  }

  buscarImagemUsuario(): string {
    return this.utilSessao.getUsuario().fotoUsuario != undefined ? this.utilSessao.getUsuario().fotoUsuario : '/img/semfoto.jpg';
  }

  sair(): void {
    this.usuario = null;
    this.permissoes = null;
    this.loginCompleto = null;
    this.router.navigate(['/login' + this.utilSessao.getDominio('/')]);
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('dataAtualLogin', JSON.stringify(new Date()));
    this.desejo = '';
  }

  ehSair(): void {
    let frm = window.location.href.indexOf('Frm') > 0 ? true : false;
    if (this.utilSessao.ambienteProducao == true && frm) {
      if (confirm(bibDialogo.desejaSair)) {
        this.sair();
      }
    } else {
      this.sair();
    }
  }

  ngOnDestroy(): void {
    this.menuPrincipalEmitter.unsubscribe();
  }
}
