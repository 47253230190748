import { Component, HostListener, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Mdfe } from 'src/app/modelo/mdfe';
import { MdfePercurso } from 'src/app/modelo/mdfePercurso';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'mdfePercurso',
  templateUrl: './mdfePercursoFrm.component.html',
})
export class MdfePercursoFrmComponent extends PaginaComponent {
  @Input() mdfe: Mdfe;
  @Input() excluirPercursos: number[] = [];
  @Input() bloqueado: boolean = false;

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public componente: any = null;
  public ehMinhaModal: boolean = false;

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent): void {
    if (event.keyCode == this.bibAtalho.novo) {
      this.ehAbrirModalPercurso();
    }
  }

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const estados: any[] = identificacao.conteudo;
        estados.forEach((estado) => {
          if (this.mdfe.mdfePercursos.filter((mdfePercurso) => mdfePercurso.idEstado == estado.id).length == 0) {
            let mdfePercurso: MdfePercurso = new MdfePercurso();
            mdfePercurso.idEstado = estado.id;
            mdfePercurso.estado = estado.nome;
            this.mdfe.mdfePercursos.push(mdfePercurso);
          }
        });
      }
    });
  }

  excluirPercurso(posicao: number, id: number): void {
    super.ehExcluir(posicao, this.mdfe.mdfePercursos, this.excluirPercursos, id);
  }

  ehAbrirModalPercurso() {
    let criterios: Criterio[] = [];
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.estado));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.mdfe.mdfePercursos));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.estado));
    this.utilSessao.setIdentificacao(new Identificacao('criterios', criterios));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }
}
