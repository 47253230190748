import { Component, Input } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { GrupoAcesso } from 'src/app/modelo/grupoAcesso';
import { GrupoUsuario } from 'src/app/modelo/grupoUsuario';
import { GrupoUsuarioMenu } from 'src/app/modelo/grupoUsuarioMenu';
import { Parceiro } from 'src/app/modelo/parceiro';
import { RelatorioGrupoUsuario } from 'src/app/modelo/relatorioGrupoUsuario';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { UsuarioMenu } from 'src/app/modelo/usuarioMenu';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'usuarioDadosFrm',
  templateUrl: './usuarioDadosFrm.component.html',
  styleUrls: ['./usuarioDadosFrm.component.css'],
})
export class UsuarioDadosFrmComponent extends PaginaComponent {
  @Input() permiteEdicao: boolean = false;
  @Input() relatorioUsuarios: RelatorioUsuario[] = [];
  @Input() usuarioMenus: UsuarioMenu[] = [];
  @Input() mobilidade: string;
  public desabilitado: boolean;
  public dominio: string;
  public grupoAcessos: GrupoAcesso[] = [];
  public grupoUsuarioMenus: GrupoUsuarioMenu[] = [];
  public grupoUsuarios: GrupoUsuario[] = [];
  public idGrupoUsuarios: number[] = [];
  public relatorioGrupoUsuarios: RelatorioGrupoUsuario[] = [];
  public senhaFraca: boolean = false;
  public _usuario: Usuario = new Usuario();
  public mostraSenha: boolean = false;
  public mostraSenhaOperacional: boolean = false;
  public alterandoSenha: boolean = false;

  @Input()
  set usuario(usuario: Usuario) {
    if (usuario) {
      this._usuario = usuario;
      this.setSenha(this._usuario.senha);
    }
  }

  ngOnInit(): void {
    this.dominio = this.utilSessao.getDominio('@');
    this.desabilitado = this.router.url == '/minhaContaFrm';
    this.listarGrupoUsuarios();
    this.listarGrupoAcessos();
    this.utilNotificacao.alterandoSenhaEmitter.subscribe((alterando) => {
      this.alterandoSenha = alterando;
    });
  }

  listarGrupoUsuarios(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.grupoUsuario).subscribe((res) => {
      this.grupoUsuarios = this.plainToClass(GrupoUsuario, res) as any;
      this.util.ordenarLista(this.grupoUsuarios, 'nome');
      this.idGrupoUsuarios = this.grupoUsuarios.map((grupoUsuario) => grupoUsuario.id);
      if (this.idGrupoUsuarios.length > 0) {
        this.buscarGrupoUsuarioMenuRelatorio(this.idGrupoUsuarios);
      }
    });
  }

  listarGrupoAcessos(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.grupoAcesso).subscribe((res) => {
      this.grupoAcessos = this.plainToClass(GrupoAcesso, res) as any;
      this.util.ordenarLista(this.grupoAcessos, 'nome');
    });
  }

  buscarGrupoUsuarioMenuRelatorio(ids: number[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_USUARIOS', ids.toString())), this.bibServico.grupoUsuarioMenu).subscribe((res) => {
      this.grupoUsuarioMenus = this.plainToClass(GrupoUsuarioMenu, res) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_USUARIOS', ids.toString())), this.bibServico.relatorioGrupoUsuario).subscribe((res) => {
        this.relatorioGrupoUsuarios = this.plainToClass(RelatorioGrupoUsuario, res) as any;
      });
    });
  }

  setGrupoUsuario(id: number): void {
    this._usuario.idGrupoUsuario = id;
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_USUARIO', id)), this.bibServico.grupoUsuarioMenu).subscribe((res) => {
      let menus: GrupoUsuarioMenu[] = this.plainToClass(GrupoUsuarioMenu, res) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_GRUPO_USUARIO', id)), this.bibServico.relatorioGrupoUsuario).subscribe((res) => {
        let relatorios: RelatorioGrupoUsuario[] = this.plainToClass(RelatorioGrupoUsuario, res) as any;
        this.atualizarUsuarioMenus(menus);
        this.atualizarRelatorioUsuarios(relatorios);
      });
    });
  }

  atualizarUsuarioMenus(menus: GrupoUsuarioMenu[]): void {
    menus.forEach((menu) => {
      this.usuarioMenus.find((usuarioMenu) => {
        if (usuarioMenu.idMenu == menu.idMenu) {
          usuarioMenu.acessar = menu.acessar == 'S' ? 'S' : 'N';
          usuarioMenu.inserir = menu.inserir == 'S' ? 'S' : 'N';
          usuarioMenu.editar = menu.editar == 'S' ? 'S' : 'N';
          usuarioMenu.excluir = menu.excluir == 'S' ? 'S' : 'N';
          usuarioMenu.favorito = menu.favorito == 'S' ? 'S' : 'N';
          usuarioMenu.inativar = menu.inativar == 'S' ? 'S' : 'N';
        }
      });
    });
  }

  atualizarRelatorioUsuarios(relatorios: RelatorioGrupoUsuario[]): void {
    relatorios.forEach((relatorio) => {
      this.relatorioUsuarios.find((relatorioUsuario) => {
        if (relatorioUsuario.idRelatorio == relatorio.idRelatorio) {
          relatorioUsuario.acessar = relatorio.acessar == 'S' ? 'S' : 'N';
          relatorioUsuario.favorito = relatorio.favorito == 'S' ? 'S' : 'N';
        }
      });
    });
  }

  setSenha(senha: string): void {
    const contemNumeros: any = /(?=(?:.*?[0-9]){2})/;
    const contemDuasMinusculas: any = /(?=(?:.*?[a-z]){2})/;
    const contemLetraMaiuscula: any = /(?=(?:.*?[A-Z]))/;

    if (senha != null && senha.length >= 8 && senha.length <= 30) {
      this._usuario.entre8e30Caracteres = true;
    } else {
      this._usuario.entre8e30Caracteres = false;
    }

    if (senha != null && contemNumeros.exec(senha)) {
      this._usuario.contemDoisNumeros = true;
    } else {
      this._usuario.contemDoisNumeros = false;
    }

    if (senha != null && contemDuasMinusculas.exec(senha)) {
      this._usuario.contemDuasLetrasMinuscula = true;
    } else {
      this._usuario.contemDuasLetrasMinuscula = false;
    }

    if (senha != null && contemLetraMaiuscula.exec(senha)) {
      this._usuario.contemUmaLetraMaiuscula = true;
    } else {
      this._usuario.contemUmaLetraMaiuscula = false;
    }

    let contemUmCaracterEspecial: number = 0;
    if (senha != null) {
      for (let letra of senha) {
        if (['@', '?', '{', '}', '|', ']', '[', '=', '^', '~'].includes(letra)) {
          contemUmCaracterEspecial++;
        }
      }
    }
    this._usuario.contemUmCaracterEspecial = contemUmCaracterEspecial > 0 ? true : false;
    this._usuario.senha = senha;
  }

  alterarSenha(): void {
    this.alterandoSenha = true;
    this._usuario.senha = '';
    this._usuario.key = '';
  }

  setColaborador(parceiro: Parceiro): void {
    this._usuario.idColaborador = parceiro.id;
    this._usuario.colaborador = parceiro.razaoSocial;
  }
}
