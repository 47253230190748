<layout [titulo]="empresa?.nome" [tag]="empresa?.id">
  <header class="page-cover pt-3 mb-5">
    <div class="text-center upper">
      <div class="fileinput-button">
        <div class="fileinput-button-label">{{ bibDialogo.alterarLogomarca }}</div>
        <img id="output" [src]="empresa?.fotoEmpresa ? empresa?.fotoEmpresa : empresa?.foto == 'S' ? conexao.caminhoAwsS3 + '' + empresa?.id + '/empresa/' + empresa?.id + '.jpg' : imagemEmpresa" [alt]="empresa?.nome" class="img-thumbnail" width="200" height="200" />
        <input class="tamanho-foto-empresa" accept="image/*" type="file" id="fileupload-avatar" (change)="alterarFoto($event)" #arquivoUpload />
      </div>
      <div *ngIf="empresa?.foto == 'S'" class="pt-2"><i class="fa fa-trash fa-cinza-escuro clique" (click)="deletarFoto()"></i></div>
    </div>
  </header>
  <div class="row">
    <texto class="col-sm" [id]="'abreviacao'" [rotulo]="bibDialogo.abreviacao" [campo]="empresa?.abreviacao" (alteracao)="empresa.abreviacao = $event" [obrigatorio]="true" [maximoCaracteres]="100" [foco]="true"></texto>
  </div>
  <div class="row">
    <busca class="col-sm-6" [id]="'clienteFornecedorFinal'" [rotulo]="bibDialogo.clienteFornecedorFinal" [campo]="empresa?.consumidor" (alteracao)="empresa.idConsumidorFinal = $event.id" [minimoCaracterBusca]="5" [servico]="bibServico.cliente"></busca>
    <busca class="col-sm-6" [id]="'produtoPersonalizado'" [rotulo]="bibDialogo.produtoPersonalizado" [campo]="empresa?.produtoPersonalizado" (alteracao)="empresa.idProdutoPersonalizado = $event.id" [servico]="bibServico.produto"></busca>
  </div>
  <titulo *ngIf="!ehSistemaaGrow" [titulo]="bibDialogo.layout" [imagem]="bibImagem.layout"></titulo>
  <div *ngIf="!ehSistemaaGrow" class="row">
    <lista class="col-md-6" [rotulo]="bibDialogo.compacto" [campo]="empresa?.compacto" [lista]="simNao" (alteracao)="empresa.compacto = $event.id" [obrigatorio]="true"></lista>
  </div>
  <titulo [titulo]="bibDialogo.limitar" [imagem]="bibImagem.limitar"></titulo>
  <div class="row">
    <lista class="col-sm" [id]="'estoque'" [rotulo]="bibDialogo.estoque" [campo]="empresa?.limitarEstoque" [lista]="limitar" (alteracao)="empresa.limitarEstoque = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-sm" [id]="'negociacao'" [rotulo]="bibDialogo.negociacao" [campo]="empresa?.limitarNegociacao" [lista]="limitar" (alteracao)="empresa.limitarNegociacao = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista class="col-sm" [id]="'parceiro'" [rotulo]="bibDialogo.parceiro" [campo]="empresa?.limitarParceiro" [lista]="limitar" (alteracao)="empresa.limitarParceiro = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-sm" [id]="'produto'" [rotulo]="bibDialogo.produto" [campo]="empresa?.limitarProduto" [lista]="limitar" (alteracao)="empresa.limitarProduto = $event.id" [obrigatorio]="true"></lista>
  </div>
  <titulo [titulo]="bibDialogo.contrato" [imagem]="bibImagem.descricao"></titulo>
  <div class="row">
    <descricao class="col-sm" [id]="'clausulas'" [rotulo]="bibDialogo.clausulas" [campo]="empresa?.contrato" (alteracao)="empresa.contrato = $event" [maximoCaracteres]="500"></descricao>
  </div>
  <titulo [titulo]="bibDialogo.comissao" [imagem]="bibImagem.comissao"></titulo>
  <div class="row">
    <lista class="col-sm-6" [id]="'regraComissao'" [rotulo]="bibDialogo.regraComissao" [campo]="empresa?.regraComissao" [lista]="regraComissao" (alteracao)="empresa.regraComissao = $event.id" [obrigatorio]="true"></lista>
  </div>
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.fiscais" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <lista class="col-md-6" [rotulo]="bibDialogo.subtrairIcmsPisCofins" [ajuda]="bibDialogo.subtrairIcmsPisCofinsAjuda" [campo]="empresa?.subtrairIcmsPisCofins" [lista]="simNao" (alteracao)="empresa.subtrairIcmsPisCofins = $event.id" [obrigatorio]="true" [ajudaDireita]="true"></lista>
  </div>  
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.produtos" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <lista class="col-md-6" [rotulo]="bibDialogo.produtoNomeAutomatico" [ajuda]="bibDialogo.produtoNomeAutomaticoAjuda" [campo]="empresa?.regraProdutoNomeAutomatico" [lista]="simNao" (alteracao)="empresa.regraProdutoNomeAutomatico = $event.id" [obrigatorio]="true" [ajudaDireita]="true"></lista>
  </div>    
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.cliente + '/' + bibDialogo.fornecedor" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <lista class="col-sm-6" [rotulo]="bibDialogo.cnpjCpf" [campo]="empresa?.regraCnpjCpf" [lista]="regraCnpjCpf" (alteracao)="empresa.regraCnpjCpf = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-sm-6" [rotulo]="bibDialogo.duplicaCnpjCpf" [campo]="empresa?.regraCnpjCpfDuplicado" [lista]="regraCnpjCpfDuplicados" (alteracao)="empresa.regraCnpjCpfDuplicado = $event.id" [obrigatorio]="true"></lista>
  </div>    
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.custoReposicao" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <lista class="col-sm-6" [id]="'custoReposicao'" [rotulo]="bibDialogo.custoReposicao" [campo]="empresa?.regraCustoReposicao" [lista]="regraCustoReposicao" (alteracao)="empresa.regraCustoReposicao = $event.id" [obrigatorio]="true"></lista>
  </div>  
  <div class="row ml-2 mt-2">
    <label *ngIf="empresa?.regraCustoReposicao != 4">{{bibDialogo.considerarValorUnitarioComAdicionaisAbaixo}}</label>
  </div>      
  <div *ngIf="empresa?.regraCustoReposicao != 4" class="row">
    <check class="col-sm" [rotulo]="'+ ' + bibDialogo.ipi" [campo]="empresa.custoMaisIpi" (alteracao)="empresa.custoMaisIpi = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'+ ' + bibDialogo.icmsSt" [campo]="empresa.custoMaisIcmsSt" (alteracao)="empresa.custoMaisIcmsSt = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'+ ' + bibDialogo.frete" [campo]="empresa.custoMaisFrete" (alteracao)="empresa.custoMaisFrete = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'+ ' + bibDialogo.seguro" [campo]="empresa.custoMaisSeguro" (alteracao)="empresa.custoMaisSeguro = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'+ ' + bibDialogo.outros" [campo]="empresa.custoMaisOutro" (alteracao)="empresa.custoMaisOutro = $event" [compacto]="true"></check>
  </div>  
  <div *ngIf="empresa?.regraCustoReposicao != 4" class="row">
    <check class="col-sm" [rotulo]="'- ' + bibDialogo.desconto" [campo]="empresa.custoMenosDesconto" (alteracao)="empresa.custoMenosDesconto = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'- ' + bibDialogo.icms" [campo]="empresa.custoMenosIcms" (alteracao)="empresa.custoMenosIcms = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'- ' + bibDialogo.icmsDesonerado" [campo]="empresa.custoMenosIcmsDesonerado" (alteracao)="empresa.custoMenosIcmsDesonerado = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'- ' + bibDialogo.cofins" [campo]="empresa.custoMenosCofins" (alteracao)="empresa.custoMenosCofins = $event" [compacto]="true"></check>
    <check class="col-sm" [rotulo]="'- ' + bibDialogo.pis" [campo]="empresa.custoMenosPis" (alteracao)="empresa.custoMenosPis = $event" [compacto]="true"></check>
  </div>   
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.movimentacao" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <lista class="col-md-6" [rotulo]="bibDialogo.abrirMovimentacao" [ajuda]="bibDialogo.permiteAbrirMovimentacao" [campo]="empresa?.regraAbrirMovimentacao" [lista]="simNao" (alteracao)="empresa.regraAbrirMovimentacao = $event.id" [obrigatorio]="true" [ajudaDireita]="true"></lista>
    <lista *ngIf="utilSessao.sistema != 'aGrow-w'" class="col-md-6" [rotulo]="bibDialogo.utilizarOrdemDePagamento" [campo]="empresa?.ordemPagamento" [lista]="ordemPagamento" (alteracao)="empresa.ordemPagamento = $event.id" [obrigatorio]="true"></lista>    
    <busca class="col-sm-6" [rotulo]="bibDialogo.aoFinalizarMovimentacao" [campo]="empresa?.nomeRelatorioMovimentacao" (alteracao)="empresa.idRelatorioVenda = $event.id" [servico]="bibServico.relatorio" [criterios]="criterioRelatorio" [ajuda]="bibDialogo.gerarRelatorioDesejadoMovimentacao" [ajudaDireita]="true"></busca>    
    <lista class="col-sm-6" [rotulo]="bibDialogo.validarInadimplencia" [campo]="empresa?.regraValidarInadimplencia" [lista]="validarInadimplencias" (alteracao)="empresa.regraValidarInadimplencia = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-md-6" [rotulo]="bibDialogo.exigeAprovacaoInterna" [ajuda]="bibDialogo.exigeAprovacaoInternaAjuda" [campo]="empresa?.exigeAprovacaoInterna" [lista]="simNao" (alteracao)="empresa.exigeAprovacaoInterna = $event.id" [obrigatorio]="true" [ajudaDireita]="true"></lista>
    <lista class="col-md-6" [rotulo]="bibDialogo.mostraMargemLucro" [campo]="empresa?.mostraMargemLucro" [lista]="listaMostraMargemLucro" (alteracao)="empresa.mostraMargemLucro = $event.id" [obrigatorio]="true"></lista>
  </div>   
  <titulo [titulo]="bibDialogo.alertas + ' ' + bibDialogo.movimentacao" [imagem]="bibImagem.alertas"></titulo>
  <div class="row">
    <decimal class="col-sm-4" [id]="'descontoAcimaDe'" [campo]="empresa?.alertaDesconto" [rotulo]="bibDialogo.descontoAcimaDe" (alteracao)="empresa.alertaDesconto = $event" [numeroCasasDecimais]="4" [sufixo]="bibDialogo.percentualSimbolo"> </decimal>
    <texto class="col-sm-4" [id]="'alertaPrazo'" [campo]="empresa?.alertaPrazo" [tipo]="bibPropriedade.texto.inteiro" [rotulo]="bibDialogo.prazoMaiorQue" (alteracao)="empresa.alertaPrazo = $event" [sufixo]="bibDialogo.dias"></texto>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.alertarValor" [campo]="empresa?.alertarValorAcima" (alteracao)="empresa.alertarValorAcima = $event" [prefixo]="'R$'" [numeroCasasDecimais]="2"></decimal>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.alertarPeso" [campo]="empresa?.alertarPesoAcima" (alteracao)="empresa.alertarPesoAcima = $event" [sufixo]="'KG'" [numeroCasasDecimais]="3"></decimal>
    <decimal class="col-sm-4" [rotulo]="bibDialogo.alertarQuantidade" [campo]="empresa?.alertarQuantidadeAcima" (alteracao)="empresa.alertarQuantidadeAcima = $event" [numeroCasasDecimais]="4"></decimal>    
    <decimal class="col-sm-4" [rotulo]="bibDialogo.variacaoValorTotal" [ajuda]="bibDialogo.variacaoDescontoAcrescimoValorTotal" [campo]="empresa?.regraVariacaoValorTotal" [sufixo]="'%'" [numeroCasasDecimais]="2" [valorMaximo]="100" (alteracao)="empresa.regraVariacaoValorTotal = $event" [ajudaDireita]="true"></decimal>
  </div>  
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.formatacao" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <texto class="col-sm-4" [rotulo]="bibDialogo.numeroCasasDecimais" [campo]="empresa?.regraCasasDecimais" [tipo]="bibPropriedade.texto.inteiro" (alteracao)="empresa.regraCasasDecimais = $event" [obrigatorio]="true" [ajuda]="bibDialogo.ajudaNumeroCasasDecimaisSaida" [ajudaDireita]="true"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.numeroCasasDecimaisEntrada" [campo]="empresa?.regraCasasDecimaisEntrada" [tipo]="bibPropriedade.texto.inteiro" [ajuda]="bibDialogo.ajudaNumeroCasasDecimaisEntrada" (alteracao)="empresa.regraCasasDecimaisEntrada = $event" [obrigatorio]="true"></texto>
    <texto class="col-sm-4" [rotulo]="bibDialogo.mascaraContaContabil" [campo]="empresa?.regraMascaraContaContabil" [maximoCaracteres]="20" [tipo]="bibPropriedade.texto.contabil" [ajuda]="bibDialogo.ajudaMascaraContaContabil" (alteracao)="empresa.regraMascaraContaContabil = $event" [obrigatorio]="true"></texto>
  </div>    
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.entrega" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <lista class="col-md-6" [rotulo]="bibDialogo.controlarEntrega" [ajuda]="bibDialogo.controlarSepararEntregarMercadoria" [campo]="empresa?.separarEntregar" [lista]="simNao" (alteracao)="empresa.separarEntregar = $event.id" [obrigatorio]="true" [ajudaDireita]="true"></lista>
    <lista *ngIf="empresa.separarEntregar == 'S'" class="col-md-6" [rotulo]="bibDialogo.entregaOpcional" [ajuda]="bibDialogo.ajudaEntregaOpcional" [campo]="empresa?.separarEntregarOpcional" [lista]="simNao" (alteracao)="empresa.separarEntregarOpcional = $event.id" [obrigatorio]="true" [ajudaDireita]="true"></lista>
    <busca *ngIf="empresa.separarEntregar == 'S'" class="col-sm-6" [rotulo]="bibDialogo.aoFinalizarEntrega" [campo]="empresa?.nomeRelatorioEntrega" (alteracao)="empresa.idRelatorioEntrega = $event.id" [servico]="bibServico.relatorio" [criterios]="criterioRelatorio" [ajuda]="bibDialogo.gerarRelatorioDesejadoEntrega" [ajudaDireita]="true"></busca>  
  </div>   
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.adicionais" [imagem]="bibImagem.regras"></titulo>
  <div class="row">
    <div class="col-md-6">
      <lista *ngIf="maisDeUmaLoja" [rotulo]="bibDialogo.sugerirLojaEmLogin" [campo]="empresa?.regraLojaLogin" [lista]="simNao" (alteracao)="empresa.regraLojaLogin = $event.id" [obrigatorio]="true"></lista>
    </div>
    <lista class="col-sm-6" [id]="'limiteDisponivel'" [rotulo]="bibDialogo.limiteDisponivel" [campo]="empresa?.limiteCredito" [lista]="retornoLimiteCredito" (alteracao)="empresa.limiteCredito = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-md-6" *ngIf="utilSessao.sistema != 'aGrow-w'" [rotulo]="bibDialogo.movimentacao" [campo]="empresa?.movimentacaoSalvarFinalizar" [lista]="movimentacao" (alteracao)="empresa.movimentacaoSalvarFinalizar = $event.id" [ajuda]="bibDialogo.regraUtilizadaApenasEmDevolucaoPedidoOrcamento" [obrigatorio]="true"></lista>
    <lista class="col-sm-6" *ngIf="utilSessao.sistema != 'aGrow-w'" [rotulo]="bibDialogo.estoqueNegativo" [ajuda]="bibDialogo.ajudaPermiteEstoquFisicoENFfeNegativo" [ajudaDireita]="true" [campo]="empresa?.regraEstoqueNegativo" [lista]="simNao" (alteracao)="empresa.regraEstoqueNegativo = $event.id" [obrigatorio]="true"></lista>    
  </div>  
  <titulo [titulo]="bibDialogo.regras + ' ' + bibDialogo.financeiro" [imagem]="bibImagem.dinheiro"></titulo>
  <div class="row">
    <div class="col-sm-6">
      <lista class="col-sm-6" [rotulo]="bibDialogo.limitarEspecie" [ajuda]="bibDialogo.ajudaLimitarEspecie" [ajudaDireita]="true" [campo]="empresa?.regraEspecieTipoTitulo" [lista]="simNao" (alteracao)="empresa.regraEspecieTipoTitulo = $event.id" [obrigatorio]="true"></lista>    
    </div>
  </div>
  <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.bloquearFinanceiro" [imagem]="bibImagem.cadeado" [icone]="bibIcone.mais" (iconeEmt)="abrirPesquisa()"></titulo>
  </div>
  <div class="row" *ngIf="empresa?.empresaOperacaoBloqueios.length == 0">
    <p class="col-sm-12 pl-3">
      {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.operacao.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirPesquisa()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
    </p>
  </div>
  <div class="responsiva" *ngIf="empresa?.empresaOperacaoBloqueios.length > 0">
    <table class="table table-responsive-sm">
      <thead>
        <tr>
          <th class="l-95">{{ bibDialogo.nome }}</th>
          <th class="l-5"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let operacaoBloqueio of empresa?.empresaOperacaoBloqueios; let i = index">
          <td>{{ operacaoBloqueio.nome }}</td>
          <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, empresa?.empresaOperacaoBloqueios, idsOperacaoBloqueioExcluir, operacaoBloqueio.id)"></btnAdicional></td>
        </tr>
      </tbody>
    </table>
  </div>  
  <div  *ngIf="empresa.contabilidade == 'S'" class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.contabilidade" [imagem]="bibImagem.contabilidade"></titulo>
  </div>
  <div *ngIf="empresa.contabilidade == 'S'" class="row">
    <lista class="col-md-6" [rotulo]="bibDialogo.correntista" [campo]="empresa?.correntista" [lista]="simNao" (alteracao)="empresa.correntista = $event.id" [ajuda]="bibDialogo.trabalhaCorrentista" [obrigatorio]="true"></lista>           
    <pesquisaTextoBusca class="col-sm-12" [id]="'historico'" [idMenu]="permissaoHistorico && permissaoHistorico.inserir == 'S' ? menuHistorico.idMenu : null" [rotulo]="menuHistorico.apelido" [campo]="empresa.historico" (alteracao)="empresa.historico = $event" [obrigatorio]="false" [icone]="bibIcone.lupa" [servico]="bibServico.historico" [minimoCaracterBusca]="2"></pesquisaTextoBusca>
  </div>    
  <div  *ngIf="empresa.contabilidade == 'S'" class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.contasContabeis" [imagem]="bibImagem.contabilidade"></titulo>
  </div>
  <div class="row" *ngIf="empresa.contabilidade == 'S'">
    <busca *ngIf="empresa.correntista == 'N'" class="col-sm-6" [rotulo]="bibDialogo.cliente" [campo]="empresa?.nomeContaContabilCliente" (alteracao)="empresa.idContaContabilCliente = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioContaContabilAtivo" [ajuda]="bibDialogo.contaContabilVincularCliente" [ajudaDireita]="true"></busca>    
    <busca *ngIf="empresa.correntista == 'N'" class="col-sm-6" [rotulo]="bibDialogo.fornecedor" [campo]="empresa?.nomeContaContabilFornecedor" (alteracao)="empresa.idContaContabilFornecedor = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioContaContabilAtivo" [ajuda]="bibDialogo.contaContabilVincularFornecedor" [ajudaDireita]="true"></busca>        
    <busca class="col-sm-6" [rotulo]="bibDialogo.desconto + ' ' + bibDialogo.despesa.toLowerCase()" [campo]="empresa?.nomeContaContabilDescCredito" (alteracao)="empresa.idContaContabilDescCredito = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularDesconto + ' ' + bibDialogo.despesa.toLowerCase()" [ajudaDireita]="true"></busca>    
    <busca class="col-sm-6" [rotulo]="bibDialogo.desconto + ' ' + bibDialogo.receita.toLowerCase()" [campo]="empresa?.nomeContaContabilDescDebito" (alteracao)="empresa.idContaContabilDescDebito = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularDesconto + ' ' + bibDialogo.receita.toLowerCase()" [ajudaDireita]="true"></busca>        
    <busca class="col-sm-6" [rotulo]="bibDialogo.juro + ' ' + bibDialogo.despesa.toLowerCase()" [campo]="empresa?.nomeContaContabilJuroCredito" (alteracao)="empresa.idContaContabilJuroCredito = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularJuro + ' ' + bibDialogo.despesa.toLowerCase()" [ajudaDireita]="true"></busca>    
    <busca class="col-sm-6" [rotulo]="bibDialogo.juro + ' ' + bibDialogo.receita.toLowerCase()" [campo]="empresa?.nomeContaContabilJuroDebito" (alteracao)="empresa.idContaContabilJuroDebito = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularJuro + ' ' + bibDialogo.receita.toLowerCase()" [ajudaDireita]="true"></busca>        
    <busca class="col-sm-6" [rotulo]="bibDialogo.multa + ' ' + bibDialogo.despesa.toLowerCase()" [campo]="empresa?.nomeContaContabilMultaCredito" (alteracao)="empresa.idContaContabilMultaCredito = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularMulta + ' ' + bibDialogo.receita.toLowerCase()" [ajudaDireita]="true"></busca>    
    <busca class="col-sm-6" [rotulo]="bibDialogo.multa + ' ' + bibDialogo.receita.toLowerCase()" [campo]="empresa?.nomeContaContabilMultaDebito" (alteracao)="empresa.idContaContabilMultaDebito = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularMulta + ' ' + bibDialogo.despesa.toLowerCase()" [ajudaDireita]="true"></busca>          
    <busca *ngIf="empresa.correntista == 'N'" class="col-sm-6" [rotulo]="bibDialogo.caixa" [campo]="empresa?.nomeContaContabilCaixa" (alteracao)="empresa.idContaContabilCaixa = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioContaContabilAtivo" [ajuda]="bibDialogo.contaContabilVincularCaixa" [ajudaDireita]="true"></busca>        
    <busca *ngIf="empresa.correntista == 'N'" class="col-sm-6" [rotulo]="bibDialogo.especifica" [campo]="empresa?.nomeContaContabilEspecifica" (alteracao)="empresa.idContaContabilEspecifica = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioContaContabilAtivo" [ajuda]="bibDialogo.contaContabilVincularEspecifica" [ajudaDireita]="true"></busca>        
    <busca *ngIf="empresa.correntista == 'N'" class="col-sm-6" [rotulo]="bibDialogo.despesa" [campo]="empresa?.nomeContaContabilDespesa" (alteracao)="empresa.idContaContabilDespesa = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioContaContabilAtivo" [ajuda]="bibDialogo.contaContabilVincularDespesa" [ajudaDireita]="true"></busca>        
    <busca *ngIf="empresa.correntista == 'N'" class="col-sm-6" [rotulo]="bibDialogo.receita" [campo]="empresa?.nomeContaContabilReceita" (alteracao)="empresa.idContaContabilReceita = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioContaContabilAtivo" [ajuda]="bibDialogo.contaContabilVincularReceita" [ajudaDireita]="true"></busca>            
    <busca class="col-sm-6" [rotulo]="bibDialogo.taxa + ' ' + bibDialogo.cartao.toLowerCase()" [campo]="empresa?.nomeContaContabilTaxaCartao" (alteracao)="empresa.idContaContabilTaxaCartao = $event.id" [servico]="bibServico.contaContabil" [ajuda]="bibDialogo.contaContabilVincularTaxasCartao" [ajudaDireita]="true"></busca>          
  </div>
<div *ngIf="empresa.contabilidade == 'S'">
    <div class="row ml-1 justify-content-between align-items-center">
      <titulo [titulo]="bibDialogo.configuracaoContabil" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionar()"></titulo>
    </div>
    <div class="row" *ngIf="empresa?.empresaOperacaoContabeis.length == 0">
      <p class="col-sm-12 pl-3">
        {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.configuracao.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
      </p>
    </div>
    <div class="responsiva" *ngIf="empresa?.empresaOperacaoContabeis.length > 0">
      <table class="table table-responsive-sm tabela-formulario">
        <thead>
          <tr>
            <th class="l-50">{{ bibDialogo.operacao }}</th>
            <th class="l-40">{{ bibDialogo.configuracaoMovimentacaoContabil }}</th>
            <th class="l-5"></th>
            <th class="l-5"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let operacaoContabil of empresa?.empresaOperacaoContabeis; let i = index">
            <td>
              <busca class="busca-configuracao-contabil" *ngIf="!operacaoContabil.id || edicaoOperacoesContabeis.includes(operacaoContabil.id)" [campo]="operacaoContabil.operacao" (alteracao)="setOperacaoContabil(operacaoContabil, $event)" [servico]="bibServico.operacao" [focoId]="focoOperacao" [obrigatorio]="true" [compacto]="true"></busca>
              {{ operacaoContabil.id && edicaoOperacoesContabeis.includes(operacaoContabil.id) == false ? operacaoContabil.operacao : '' }}
            </td>
            <td>
              <busca class="busca-configuracao-contabil margem-configuracao-contabil" *ngIf="!operacaoContabil.id || edicaoOperacoesContabeis.includes(operacaoContabil.id)" [campo]="operacaoContabil.configuracaoContabil" (alteracao)="setConfiguracaoContabil(operacaoContabil, $event)" [servico]="bibServico.configuracaoMovimentacaoContabil" [obrigatorio]="true" [compacto]="true"></busca>
              {{ operacaoContabil.id && edicaoOperacoesContabeis.includes(operacaoContabil.id) == false ? operacaoContabil.configuracaoContabil : '' }}
            </td>
            <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editar(operacaoContabil)"></btnAdicional></td>
            <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, empresa?.empresaOperacaoContabeis, idsOperacaoContabilExcluir, operacaoContabil.id)"></btnAdicional></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="empresa.correntista == 'S'" class="row ml-1 justify-content-between align-items-center">
      <titulo [titulo]="bibDialogo.configuracaoContabilFinanceiro" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionarConfiguracaoContabilFinanceiro()"></titulo>
    </div>
    <div class="row" *ngIf="empresa?.empresaOperacaoContabeisFinanceiro?.length == 0 && empresa.correntista == 'S'">
      <p class="col-sm-12 pl-3">
        {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.configuracao.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionarConfiguracaoContabilFinanceiro()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
      </p>
    </div>
    <div class="responsiva" *ngIf="empresa?.empresaOperacaoContabeisFinanceiro?.length > 0 && empresa.correntista == 'S'">
      <table class="table table-responsive-sm tabela-formulario">
        <thead>
          <tr>
            <th class="l-50">{{ bibDialogo.operacao }}</th>
            <th class="l-40">{{ bibDialogo.contaContabil }}</th>
            <th class="l-5"></th>
            <th class="l-5"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let operacaoContabilFinanceiro of empresa?.empresaOperacaoContabeisFinanceiro; let i = index">
            <td>
              <busca class="busca-configuracao-contabil" *ngIf="!operacaoContabilFinanceiro.id || edicaoOperacoesContabeisFinanceiro.includes(operacaoContabilFinanceiro.id)" [campo]="operacaoContabilFinanceiro.operacao" (alteracao)="setOperacaoContabilFinanceiro(operacaoContabilFinanceiro, $event)" [servico]="bibServico.operacao" [focoId]="focoOperacaoFinanceiro" [obrigatorio]="true" [compacto]="true" [criterios]="criterioOperacaoFinanceiro"></busca>
              {{ operacaoContabilFinanceiro.id && edicaoOperacoesContabeisFinanceiro.includes(operacaoContabilFinanceiro.id) == false ? operacaoContabilFinanceiro.operacao : '' }}
            </td>
            <td>
              <busca class="busca-configuracao-contabil margem-configuracao-contabil" *ngIf="!operacaoContabilFinanceiro.id || edicaoOperacoesContabeisFinanceiro.includes(operacaoContabilFinanceiro.id)" [campo]="operacaoContabilFinanceiro.contaContabil" (alteracao)="setContaContabilFinanceiro(operacaoContabilFinanceiro, $event)" [servico]="bibServico.contaContabil" [obrigatorio]="true" [compacto]="true"></busca>
              {{ operacaoContabilFinanceiro.id && edicaoOperacoesContabeisFinanceiro.includes(operacaoContabilFinanceiro.id) == false ? operacaoContabilFinanceiro.contaContabil : '' }}
            </td>
            <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editarOperacaoContabeisFinanceiro(operacaoContabilFinanceiro)"></btnAdicional></td>
            <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluirOperacaoContabeisFinanceiro(i, empresa?.empresaOperacaoContabeisFinanceiro)"></btnAdicional></td>
          </tr>
        </tbody>
      </table>
    </div>    
  </div>  
  <div class="row ml-1 justify-content-between align-items-center">
    <titulo [titulo]="bibDialogo.empresaFilial" [imagem]="bibImagem.empresafilial" [icone]="bibIcone.mais" (iconeEmt)="abrirPesquisaFilial()"></titulo>
  </div>
  <div class="row" *ngIf="empresa?.empresaFiliais.length == 0">
    <p class="col-sm-12 pl-3">
      {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.empresaFilial.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirPesquisaFilial()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
    </p>
  </div>
  <div class="responsiva" *ngIf="empresa?.empresaFiliais.length > 0">
    <table class="table table-responsive-sm tabela-formulario">
      <thead>
        <tr>
          <th class="l-50">{{ bibDialogo.filial }}</th>
          <th class="l-40">{{ bibDialogo.cnpj }}</th>
          <th class="l-5"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let empresaFilial of empresa?.empresaFiliais; let i = index">
          <td>
            {{ empresaFilial.nomeFilial }}
          </td>
          <td>
            {{ empresaFilial.cnpjFilial }}
          </td>
          <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, empresa?.empresaFiliais, idsEmpresaFilalExcluir, empresaFilial.id)"></btnAdicional></td>
        </tr>
      </tbody>
    </table>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" (botaoEmt)="persistirEmpresa()" [fundo]="true" [desabilitado]="!temPermissaoEdicao"></botao>
<auditoria *ngIf="empresa?.id" [idUsuarioInclusao]="empresa?.idUsuarioInclusao" [dataHoraInclusao]="empresa?.dataHoraInclusao" [idUsuarioAlteracao]="empresa?.idUsuarioAlteracao" [dataHoraAlteracao]="empresa?.dataHoraAlteracao"></auditoria>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
