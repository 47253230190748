import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './usuario.component.html',
})
export class UsuarioComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public usuarios: Usuario[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.usuario).subscribe((res) => {
      this.usuarios = this.plainToClass(Usuario, res) as any;
      this.usuarios = this.usuarios.filter((usuario) => usuario.agrow == 'N');
      this.usuarios.sort((a, b) => (a['nome'] == b['nome'] ? 0 : a['nome'] > b['nome'] ? 1 : -1));
    });
  }

  excluirUsuario(id: number, nome: string): void {
    super.excluir<Usuario>(id, this.usuarios, nome);
  }
}
