import { Component, Input } from '@angular/core';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroPropriedade } from 'src/app/modelo/parceiroPropriedade';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'parceiroDestinoPropriedade',
  templateUrl: './parceiroDestinoPropriedadeFrm.component.html',
})
export class ParceiroDestinoPropriedadeFrmComponent extends PaginaComponent {
  @Input() parceiro: Parceiro;
  @Input() parceiroPropriedades: ParceiroPropriedade[] = [];
  @Input() parceiroDestino: ParceiroDestino = new ParceiroDestino();
  @Input() edicaoParceiroPropriedades: number[] = [];
  public idParceiro: number;
  public focoArea: number;

  ngOnInit(){
    this.idParceiro = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
  }

  adicionarParceiroPropriedade(): void {
    this.focoId = null;
    const parceiroPropriedade: ParceiroPropriedade = new ParceiroPropriedade();
    parceiroPropriedade.idParceiro = this.idParceiro;
    this.parceiroDestino.parceiroPropriedades.push(parceiroPropriedade);
    this.focoArea = Math.random();
  }

  editarParceiroPropriedades(parceiroPropriedade: ParceiroPropriedade): void{
    if (this.edicaoParceiroPropriedades.includes(parceiroPropriedade.id)) {
      const posicao: number = this.edicaoParceiroPropriedades.indexOf(parceiroPropriedade.id, 0);
      this.edicaoParceiroPropriedades.splice(posicao, 1);
    } else {
      this.edicaoParceiroPropriedades.push(parceiroPropriedade.id);
    }
  }
  
  excluirParceiroPropriedades(id: number, indice: number): void{
    this.parceiroDestino.parceiroPropriedades.splice(indice, 1);
    this.utilSessao.idsPropriedadesExcluir.push(id);
  }
 
}
