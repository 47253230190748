import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.numero, campo: 'produtoNumero' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.grupo, campo: 'grupo' },
  { rotulo: bibDialogo.fabricante, campo: 'fabricante' },
  { rotulo: bibDialogo.unidade, campo: 'unidade' },
];
export default ordens;
