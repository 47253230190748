<relatorioLayout
  class="remove-padding-top"
  [relatorio]="relatorio"
  [subTitulo]="subTitulo"
  [idRelatorioInterno]="idRelatorioInterno"
  [empresa]="empresa"
  [filtros]="filtros"
  [relatorioAdicionais]="relatorioAdicionais"
  (filtroEmt)="listarRelatorio($event)"
>
  <div class="page-section">
    <div
      *ngIf="dataAtualizacao"
      class="justify-content-end"
    >
      <span class="oi oi-media-record pulse margem-alerta mr-1 text-success"></span>
      <span class="text-uppercase">{{ bibDialogo.atualizadoEm + ': ' + (dataAtualizacao | data : 'dd/MM/yyyy HH:mm') }} </span>
    </div>
    <div
      *ngIf="resumoCabecalho"
      class="section-block"
    >
      <div class="metric-row">
        <div class="col-lg-12">
          <div class="metric-row metric-flush">
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">{{ bibDialogo.vendas }}</h1>
                <p class="metric-value h3 pb-1">{{ resumoCabecalho.valorVendaTotal | monetario }}</p>
                <atencao [atencao]="bibDialogo.quantidade + ' : ' + resumoCabecalho.quantidadeVenda"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">{{ bibDialogo.margem }}</h1>
                <p class="metric-value h3 pb-1">{{ ((resumoCabecalho.lucroTotal * 100) / resumoCabecalho.valorVendaTotal | monetario) + '%' }}</p>
                <atencao
                  [atencao]="bibDialogo.valor + ' : ' + (resumoCabecalho.lucroTotal | monetario)"
                  [tipo]="bibPropriedade.atencao.discreto"
                ></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">{{ bibDialogo.devolucao }}</h1>
                <p class="metric-value h3 pb-1">{{ resumoCabecalho.valorDevolucaoTotal | monetario }}</p>
                <atencao
                  [atencao]="bibDialogo.quantidade + ' : ' + resumoCabecalho.quantidadeDevolucao"
                  [tipo]="bibPropriedade.atencao.cuidado"
                ></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">{{ bibDialogo.faturamento }}</h1>
                <p class="metric-value h3 pb-1">{{ resumoCabecalho.valorVendaTotal - resumoCabecalho.valorDevolucaoTotal | monetario }}</p>
                <atencao
                  [atencao]="'Vendas - devoluções'"
                  [tipo]="bibPropriedade.atencao.sucesso"
                ></atencao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        [hidden]="vendaDia == null"
        class="col-12 col-lg-6 col-xl-4"
      >
        <div class="card card-fluid">
          <div class="card-body espaco-grafico">
            <h3 class="card-title mb-4">{{ bibDialogo.vendas }}</h3>
            <div class="chartjs">
              <canvas
                #vendaDiaRef
                id="vendaDia"
                >{{ vendaDiaGrafico }}</canvas
              >
            </div>
          </div>
        </div>
      </div>
      <div
        [hidden]="faturamentos == null || faturamentos.length == 0"
        class="col-12 col-lg-8 col-xl-8"
      >
        <div class="card card-fluid">
          <div class="card-body espaco-grafico">
            <h3 class="card-title mb-4">{{ bibDialogo.faturamento }}</h3>
            <div class="chartjs">
              <canvas
                #faturamentoRef
                id="faturamento"
                >{{ faturamento }}</canvas
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        [hidden]="topVendedorValores == null || topVendedorValores.length == 0"
        class="col-12 col-lg-6 col-xl-6"
      >
        <div class="card card-fluid">
          <div class="card-body pb-0">
            <h3 class="card-title">{{ bibDialogo.topCincoVendedorValores }}</h3>
          </div>
          <div
            *ngFor="let topVendedorValor of topVendedorValores; let i = index"
            class="list-group list-group-flush"
          >
            <div class="list-group-item">
              <div class="list-group-item-figure">
                <a
                  class="user-avatar"
                  data-toggle="tooltip"
                  ngbTooltip="{{ topVendedorValor.razaoSocial + ' ( ' + (this.topVendedorValor.valorTotalAVistaDia + this.topVendedorValor.valorTotalPrazoDia | monetario) + ' )' }}"
                  ><img [src]="topVendedorValor.usuarioFoto == 'S' ? conexao.caminhoAwsS3 + idEmpresa + '/usuario/' + topVendedorValor.idUsuario + '.jpg' : imagemUsuario"
                /></a>
              </div>
              <div class="list-group-item-body">
                <div class="progress progress-animated bg-transparent rounded-0">
                  <div
                    class="progress-bar cor-a-vista"
                    role="progressbar"
                    ngbTooltip="{{ bibDialogo.aVista + ': ' + (this.topVendedorValor.valorTotalAVistaDia | monetario) }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{ width: (this.topVendedorValor.valorTotalAVistaDia / valorTotalPrimeiroVendedor) * 100 + '%' }"
                  ></div>
                  <div
                    class="progress-bar cor-a-prazo"
                    role="progressbar"
                    ngbTooltip="{{ bibDialogo.aPrazo + ': ' + (this.topVendedorValor.valorTotalPrazoDia | monetario) }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{ width: (this.topVendedorValor.valorTotalPrazoDia / valorTotalPrimeiroVendedor) * 100 + '%' }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [hidden]="topVendedorMargens == null || topVendedorMargens.length == 0"
        class="col-8 col-lg-8 col-xl-6"
      >
        <div class="card card-fluid">
          <div class="card-body espaco-grafico-radar">
            <h3 class="card-title mb-4">{{ bibDialogo.topCincoVendedorMargem }}</h3>
            <div class="chartjs">
              <canvas
                #topVendedorMargensRef
                id="topVendedorMargens"
                >{{ topVendedorPorMagem }}</canvas
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        [hidden]="topProdutoMargens == null || topProdutoMargens.length == 0"
        class="col-6 col-lg-6 col-xl-12"
      >
        <div class="card card-fluid">
          <div class="card-body espaco-grafico-barra">
            <h3 class="card-title mb-4">{{ bibDialogo.topProdutoMargem }}</h3>
            <div class="chartjs">
              <canvas
                #topProdutoMargensRef
                id="topProdutoMargens"
                >{{ topProdutoPorMagemGrafico }}</canvas
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</relatorioLayout>
