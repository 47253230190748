import { BaseCalculo } from './baseCalculo';
import { FundoCombateProbreza } from './fundoCombatePobreza';
import { UfDestino } from './ufDestino';

export class SubstituicaoTributaria {
  public baseCalculo: BaseCalculo = new BaseCalculo();
  public fundoCombateProbreza: FundoCombateProbreza;
  public aliquota: number;
  public valor: number;
  public ufDestino: UfDestino;
}
