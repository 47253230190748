import { Pipe, PipeTransform } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';

@Pipe({
  name: 'filtrarUsuarioIntegracao',
})
export class UsuarioIntegracaoPipe implements PipeTransform {
  transform(integracaoUsuarios: IntegracaoUsuario[], argumentos?: any): any {
    if (argumentos.filtro || argumentos.especifico == bibDialogo.semAcesso || argumentos.especifico == bibDialogo.comAcesso) {
      if (argumentos && argumentos.filtro) {
        argumentos.filtro = argumentos.filtro.toLowerCase();
      }
      switch (argumentos.especifico) {
        case bibDialogo.nome:
          return integracaoUsuarios.filter((integracaoUsuario) => integracaoUsuario.integracao.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.categoria:
          return integracaoUsuarios.filter((integracaoUsuario) => integracaoUsuario.integracaoCategoria.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.semAcesso:
          return integracaoUsuarios.filter((integracaoUsuario) => integracaoUsuario.acessar == 'N');
        case bibDialogo.comAcesso:
          return integracaoUsuarios.filter((integracaoUsuario) => integracaoUsuario.acessar == 'S');
      }
    } else {
      return integracaoUsuarios;
    }
  }
}
