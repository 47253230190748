import { Component, Input } from '@angular/core';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
@Component({
  selector: 'atencao',
  templateUrl: './atencao.component.html',
  styleUrls: ['./atencao.component.css'],
})
export class AtencaoComponent {
  @Input() atencao: string;
  @Input() ajuda: string;
  @Input() tipo: String;
  @Input() compacto: boolean = false;

  getClasse(): string {
    switch (this.tipo) {
      case bibPropriedade.atencao.tachado:
        return 'badge-danger tachado';
      case bibPropriedade.atencao.cuidado:
        return 'badge-danger';
      case bibPropriedade.atencao.sucesso:
        return 'badge-success';
      case bibPropriedade.atencao.alerta:
        return 'badge-warning';
      case bibPropriedade.atencao.informativo:
        return 'badge-dark';
      case bibPropriedade.atencao.discreto:
        return 'badge-light';
      case bibPropriedade.atencao.roxo:
        return 'badge-primary roxo';
      default:
        return 'badge-primary';
    }
  }
}
