<layout (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <apresentacao class="col-sm" [rotulo]="bibDialogo.numero" [campo]="movimentacao.numero"></apresentacao>
    <apresentacao class="col-sm" [rotulo]="bibDialogo.data" [campo]="movimentacao.data | data"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-6" [rotulo]="bibDialogo.loja" [campo]="movimentacao.loja"></apresentacao>
    <apresentacao class="col-sm-6" [rotulo]="bibDialogo.operacao" [campo]="movimentacao.operacao"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.vendedor" [campo]="movimentacao.colaborador" (btnAdicional)="limparNomeColaborador()" [foco]="true"></apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.cliente" [campo]="movimentacao.parceiro"></apresentacao>
    <busca class="col-sm-12" [rotulo]="bibDialogo.responsalvelEntrega" [campo]="colaborador" (alteracao)="idColaborador = $event.id" [servico]="bibServico.colaborador" [criterios]="criteriosResponsavel" [obrigatorio]="true" [desabilitado]="confirmada"></busca>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-35">{{ bibDialogo.produto }}</th>
        <th class="l-10 text-right">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="l-10 text-right">{{ bibDialogo.loja }}</th>
        <th class="l-15 text-right">{{ bibDialogo.comprado }}</th>
        <th class="l-15 text-right">{{ bibDialogo.pendente }}</th>
        <th class="l-15 text-right">{{ bibDialogo.quantidade }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacao.movimentacaoProdutos; let j = index">
        <tr [ngClass]="{ tachado: movimentacao.ativo != 'S' }" *ngFor="let movimentacaoQuantidade of movimentacaoProduto.movimentacaoQuantidades; let i = index">
          <td class="limitar">
            {{ movimentacaoProduto.produto }} <br />
            <span class="fonte-menor">{{ movimentacaoProduto.grupo && movimentacaoProduto.categoria ? movimentacaoProduto.grupo + ' - ' + movimentacaoProduto.categoria : movimentacaoProduto.grupo || movimentacaoProduto.categoria || '  ' }} </span>
          </td>
          <td class="text-right">{{ movimentacaoProduto.abreviacao }}</td>
          <td class="text-right">{{ movimentacaoQuantidade.lojaAbreviacao }}</td>
          <td class="text-right">{{ movimentacaoQuantidade.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ (movimentacaoQuantidade.quantidade * movimentacaoProduto.quantidadePendenteFaturamento) / movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">
            <decimal [desabilitado]="confirmada || ((movimentacaoQuantidade.quantidade * movimentacaoProduto.quantidadePendenteFaturamento) / movimentacaoProduto.quantidade) == 0" [id]="'p' + j" [campo]="movimentacaoQuantidade.quantidadeDesfazimento" (click)="posicaoProduto = j; posicaoQuantidade = i" (alteracao)="movimentacaoQuantidade.quantidadeDesfazimento = $event" [focoId]="indiceFoco == 'p' + j || j == 0" (keypress)="setQuantidadeEntregue($event, j, i, (movimentacaoQuantidade.quantidade * movimentacaoProduto.quantidadePendenteFaturamento) / movimentacaoProduto.quantidade)" (navegacao)="setPosicao($event, j, i)" [numeroCasasDecimais]="4" [focoId]="focoQuantidade" [id]="'quantidade' + 1"></decimal>
          </td>
        </tr>

      </ng-container>
    </tbody>
  </table>
  <lista [id]="'motivo'" [rotulo]="bibDialogo.motivo" [lista]="motivoDesfazimentos" [obrigatorio]="true" (alteracao)="desfazimento.idMotivoDesfazimento = $event.id" [desabilitado]="confirmada"></lista>
  <descricao [rotulo]="bibDialogo.observacao" [campo]="observacao" (alteracao)="observacao = $event" [desabilitado]="confirmada" [focoId]="focoObservacao" [maximoCaracteres]="200"></descricao>
</layout>
<div class="row align-items-center">
  <botao class="col" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" [desabilitado]="confirmada || !temPermissaoEdicao" (botaoEmt)="persistirDesfazimento()"></botao>
  <atencao [atencao]="bibDialogo.teclaEspacoQuantidadeRestante"></atencao>
</div>
<bloqueado [bloqueado]="confirmada || temPermissaoEdicao == false"></bloqueado>
