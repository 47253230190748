import { Component } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Criterio } from 'src/app/modelo/criterio';
import { Equipe } from 'src/app/modelo/equipe';
import { EquipeParceiro } from 'src/app/modelo/equipeParceiro';
import { Loja } from 'src/app/modelo/loja';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './equipeFrm.component.html',
})
export class EquipeFrmComponent extends PaginaComponent {
  public equipe: Equipe = new Equipe();
  public conexao: Conexao = new Conexao(this.utilSessao);
  public excluirEquipeParceiros: number[] = [];
  public edicaoEquipeParceiros: number[] = [];
  public visualizaLoja: boolean = false;

  ngOnInit(): void {
    this.ehAlteracao();
    this.mostrarOcultarLoja();
  }

  mostrarOcultarLoja() {
    if (this.utilSessao.getLojas().length > 1) {
      this.visualizaLoja = true;
    }
  }

  ehAlteracao(): void {
    this.equipe = this.plainToClass(Equipe, this.ehAtualizacao(this.equipe));
    if (this.equipe.id) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.equipe.id)), this.bibServico.equipe).subscribe((res) => {
        this.equipe = this.plainToClass(Equipe, res[0]) as any;
        this.listarEquipeParceiros(this.equipe.id);
      });
    } else {
      this.equipe.ativo = 'S';
      this.definirLoja();
    }
  }

  setLoja(loja: Loja): void {
    this.equipe.idLoja = loja.id;
    this.equipe.loja = loja.nomeFantasia;
  }

  setGestor(parceiro: Parceiro): void {
    this.equipe.idGestor = parceiro.id;
    this.equipe.gestor = parceiro.razaoSocial;
  }

  listarEquipeParceiros(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_EQUIPE', id)), this.bibServico.equipeParceiro).subscribe((res) => {
      this.equipe.equipeParceiros = this.plainToClass(EquipeParceiro, res) as any;
    });
  }

  persistirEquipe(): void {
    if (this.ehValido()) {
      const transporte: Transporte = new Transporte(this.equipe);
      transporte.adicionar(this.excluirEquipeParceiros);
      super.persistir(transporte, this.bibServico.equipe, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.equipe], this.bibClasse.equipe, true);
          this.listarEquipeParceiros(this.equipe.id);
          this.excluirEquipeParceiros = [];
        }
      });
    }
  }

  ehValido(): boolean {
    const ehValidoEquipe: boolean = this.ehValidoEquipe();
    const ehValidoEquipeParceiro: boolean = this.ehValidoEquipeParceiro();
    return ehValidoEquipe && ehValidoEquipeParceiro;
  }

  ehValidoEquipe(): boolean {
    if (this.equipe.gestor && this.equipe.loja) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoEquipeParceiro(): boolean {
    if (this.equipe.equipeParceiros.length == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.equipeDeveConterAoMenoUmColaborador));
      return false;
    }   

    if (this.equipe.equipeParceiros.find((equipeParceiro) => equipeParceiro.idParceiro == null) != undefined) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }   

    if (this.equipe.equipeParceiros.find((equipeParceiro) => equipeParceiro.comissao <= 0) != undefined) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.percentualComissaoMaiorZero));
      return false;
    }     
    return true;
  }

  definirLoja(): void {
    if (this.utilSessao.getLojas().length == 1) {
      this.setLoja(this.utilSessao.getLojas()[0]);
    }
  }
}
