export class Endereco {
  public bairro: string;
  public cep: string;
  public codigoCidade: string;
  public codigoPais: string;
  public complemento: string;
  public descricaoCidade: string;
  public descricaoPais: string;
  public estado: string;
  public logradouro: string;
  public numero: string;
  public tipoLogradouro?: string;
}
