import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  /*  { rotulo: bibDialogo.loja, campo: 'abreviacao' },
  { rotulo: bibDialogo.data, campo: 'movimentacaoData' },
  { rotulo: bibDialogo.clienteFornecedor, campo: 'parceiro' },
  { rotulo: bibDialogo.responsavel, campo: 'colaboradorNome' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.grupo, campo: 'grupo' },*/
];

export default grupos;
