export class CteInformacaoCarga {
  public id: number;
  public idEmpresa: number;
  public idCte: number;
  public unidadeMedida: string;
  public tipoMedida: string;
  public quantidadePeso: number;
  public idUsuarioInclusao: number;
  public idUsuarioAlteracao: number;
  public dataHoraInclusao: Date;
  public dataHoraAlteracao: Date;
}
