import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginaComponent } from '../../utilitario/pagina.component';

@Component({
  selector: 'progresso',
  templateUrl: './progresso.component.html',
  styleUrls: ['./progresso.component.css'],
})
export class Progresso extends PaginaComponent {
  @Input() clique: boolean = false;
  @Input() etapa: number;
  @Input() progresso: string[] = [];
  @Output() progressoEmt = new EventEmitter<number>();

  alterarEtapa(posicao: number): void {
    if (this.clique) {
      this.progressoEmt.emit(posicao + 1);
    }
  }
}
