export class Total {
  public baseCalculoIcms: number;
  public baseCalculoIcmsSt: number;
  public baseCalculoIrrf: number;
  public valorAproximadoTributos: number;
  public valorCofins: number;
  public valorDesconto: number;
  public valorFcp: number;
  public valorFcpSt: number;
  public valorFcpStRetido: number;
  public valorFrete: number;
  public valorIcms: number;
  public valorIcmsDesonerado: number;
  public valorIcmsSt: number;
  public valorIi: number;
  public valorIpi: number;
  public valorIpiDevolvido: number;
  public valorIrrfRetido: number;
  public valorNfe: number;
  public valorOutros: number;
  public valorPis: number;
  public valorProdutosServicos: number;
  public valorSeguro: number;
}
