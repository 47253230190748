import { Component, EventEmitter, Input, Output } from '@angular/core';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';

@Component({
  selector: 'icone',
  templateUrl: './icone.component.html',
  styleUrls: ['./icone.component.css'],
})
export class IconeComponent {
  @Input() ajudaDireita: boolean = false;
  @Input() centralizaVertical: boolean = false;
  @Input() ajudaSuperiorDireita: boolean = false;
  @Input() clique: boolean = false;
  @Input() cor: string;
  @Input() desabilitado: boolean = false;
  @Input() icone: string;
  @Input() mensagem: string;
  @Output() iconeEmt = new EventEmitter<boolean>();
  public bibPropriedade = bibPropriedade;

  definirCor(): string {
    switch (this.cor) {
      case this.bibPropriedade.icone.vermelho:
        return 'fa-vermelho';

      case this.bibPropriedade.icone.roxo:
        return 'fa-roxo';

      case this.bibPropriedade.icone.verde:
        return 'fa-verde';

      case this.bibPropriedade.icone.amarelo:
        return 'fa-amarelo';

      case this.bibPropriedade.icone.azul:
        return 'fa-azul';

      case this.bibPropriedade.icone.cinza:
        return 'fa-cinza';

      case this.bibPropriedade.icone.cinzaEscuro:
        return 'fa-cinza-escuro';

      case this.bibPropriedade.icone.laranja:
        return 'fa-laranja';

      case this.bibPropriedade.icone.preto:
        return 'fa-preto';

      case this.bibPropriedade.icone.ciano:
        return 'fa-ciano';

      case this.bibPropriedade.icone.branco:
        return 'fa-branco';

      default:
        return 'black';
    }
  }

  clicar(): void {
    if (this.clique) {
      this.iconeEmt.emit(true);
    }
  }
}
