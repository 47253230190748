import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1248.component.html',
})
export class R1248Component extends RelatorioComponent {
  public filtros = filtros;
  public grupos = grupos;
  public movimentacaoProdutos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public relatorioGrupoFinal: RelatorioGrupo = { totais: totais, linhas: [] };
  public componente: any;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.ordenarAgrupar(this.movimentacaoProdutos);
      Object.assign(this.relatorioGrupoFinal, this.relatorioGrupo);
    });
  }

  visualizarMovimentacao(idMovimentacaoProduto: number, idMovimentacao: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('idProdutoOrigem', idMovimentacaoProduto));
    this.componente = this.abrirModalRelatorio(MovimentacaoFrmComponent, [new Identificacao('objeto', idMovimentacao)], null);
  }

  agrupar(lista: any[], agrupa: any): void {
    this.removerTotalizador('quantidade');
    if (agrupa.rotulo == 'Produto') {
      const totalQuantidade: any = { campo: 'quantidade', posicao: 6, mostrar: true };
      this.relatorioGrupo.totais.push(totalQuantidade);
    }
    super.agrupar(lista, agrupa);
  }

  removerTotalizador(campo: string): void {
    const indiceTotalizador: number = this.relatorioGrupo.totais.findIndex((total) => total.campo == campo);
    this.relatorioGrupo.totais.splice(indiceTotalizador, 1);
  }
}
