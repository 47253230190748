<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [filtros]="filtros" [grupos]="grupos" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(parceiros, $event)" (filtroEmt)="listarRelatorio($event)" (campoGrupoEmt)="agrupar(parceiros, $event)">
  <table>
    <thead>
      <tr>
        <th class="r clique" (click)="ordenar(parceiros, 'razaoSocial')">{{ bibDialogo.parceiro }}</th>
        <th *ngIf="usaNomeFantasia" class="r clique" (click)="ordenar(parceiros, 'nomeFantasia')">{{ bibDialogo.nomeFantasia }}</th>
        <th *ngIf="usaDocumento" class="r-2 clique" (click)="ordenar(parceiros, 'cnpjCpf')">{{ bibDialogo.cnpjCpf }}</th>
        <th *ngIf="usaDocumento" class="r-2 clique" (click)="ordenar(parceiros, 'identidade')">{{ bibDialogo.documento }}</th>
        <th *ngIf="usaDocumento" class="r-1 clique" (click)="ordenar(parceiros, 'dataNascimento')">{{ bibDialogo.dataNascimento }}</th>
        <th *ngIf="rotuloAgrupaAtual != utilFiltro.apelidoTelaRelacionamento && usaRelacionamento" class="r-2 clique" (click)="ordenar(parceiros, 'relacionamento')">{{ bibDialogo.relacionamento }}</th>
        <th *ngIf="rotuloAgrupaAtual != bibDialogo.uf" class="r-1 clique" (click)="ordenar(parceiros, 'estado')">{{ bibDialogo.uf }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiro of parceiros; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr r1126item [parceiro]="parceiro" [usaDocumento]="usaDocumento" [usaRelacionamento]="usaRelacionamento" [usaNomeFantasia]="usaNomeFantasia" [rotuloAgrupaAtual]="rotuloAgrupaAtual" [ngClass]="usaDadosAdicionais || usaTelefone || usaContato ||  usaEndereco || usaDestino || usaAssinatura ? 'sem-borda' : ''"></tr>
        <tr *ngIf="usaDadosAdicionais" r1126dados [parceiro]="parceiro" [ngClass]="usaTelefone || usaContato ||  usaEndereco || usaDestino || usaAssinatura ? 'sem-borda' : ''"></tr>
        <tr *ngIf="usaTelefone" r1126telefone [parceiro]="parceiro" [ngClass]="usaContato ||  usaEndereco || usaDestino || usaAssinatura ? 'sem-borda' : ''"></tr>
        <tr *ngIf="usaContato" r1126contato [parceiro]="parceiro" [ngClass]=" usaEndereco || usaDestino || usaAssinatura ? 'sem-borda' : ''"></tr>
        <tr *ngIf="usaEndereco" r1126endereco [parceiro]="parceiro" [ngClass]="usaDestino || usaAssinatura ? 'sem-borda' : ''"></tr>
        <tr *ngIf="usaDestino" r1126destino [parceiro]="parceiro" [ngClass]="usaAssinatura ? 'sem-borda' : ''"></tr>
        <tr *ngIf="usaAssinatura" r1126assinatura [parceiro]="parceiro"></tr>
        <div *ngIf="usaAssinatura && i + 1 != parceiros.length" class="separador naoImprimir"></div>
        <footer *ngIf="usaAssinatura" class="quebra-de-pagina"></footer>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="parceiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>    
  </table>
</relatorioLayout>
