import { Icms00 } from "./icms00";
import { Icms02 } from "./icms02";
import { Icms10 } from "./icms10";
import { Icms15 } from "./icms15";
import { Icms20 } from "./icms20";
import { Icms30 } from "./icms30";
import { Icms40 } from "./icms40";
import { Icms51 } from "./icms51";
import { Icms60 } from "./icms60";
import { Icms70 } from "./icms70";
import { Icms90 } from "./icms90";
import { Icmssn101 } from "./icmssn101";
import { Icmssn102 } from "./icmssn102";
import { Icmssn201 } from "./icmssn201";
import { Icmssn202 } from "./icmssn202";
import { Icmssn203 } from "./icmssn203";
import { Icmssn300 } from "./icmssn300";
import { Icmssn400 } from "./icmssn400";
import { Icmssn500 } from "./icmssn500";
import { Icmssn900 } from "./icmssn900";

export class Icms {
    public ICMSSN101: Icmssn101;
    public ICMSSN102: Icmssn102;
    public ICMSSN103: Icmssn203;
    public ICMSSN201: Icmssn201;
    public ICMSSN202: Icmssn202;
    public ICMSSN203: Icmssn203;
    public ICMSSN300: Icmssn300;
    public ICMSSN400: Icmssn400;
    public ICMSSN500: Icmssn500;
    public ICMSSN900: Icmssn900;
    public ICMS00: Icms00;
    public ICMS02: Icms02;
    public ICMS10: Icms10;
    public ICMS15: Icms15;
    public ICMS20: Icms20;
    public ICMS30: Icms30;
    public ICMS40: Icms40;
    public ICMS51: Icms51;
    public ICMS60: Icms60;
    public ICMS70: Icms70;
    public ICMS90: Icms90;
    public ICMS88: Icms51;
}