import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import totais from './acessorio/total';
import grupos from './acessorio/grupo';

@Component({
  templateUrl: './r1085.component.html',
})
export class R1085Component extends RelatorioComponent {
  public resultadosCMV: any[];
  public filtros: Filtro[] = filtros;
  public grupos = grupos;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public valorCustoVenda = 0;
  public valorVenda = 0;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.resultadosCMV = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'CMV').lista;
      this.totalizarCMV();
      this.ordenarAgrupar(this.resultadosCMV);
    });
  }

  totalizarCMV() {
    this.resultadosCMV.forEach((resultadoCMV) => {
      resultadoCMV.valorCMV = resultadoCMV.valorInicial + resultadoCMV.valorEntrada - resultadoCMV.valorFinal;
      this.valorCustoVenda += resultadoCMV.valorCustoSaida;
      this.valorVenda += resultadoCMV.valorSaida;
    });
  }
}
