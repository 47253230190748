<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(movimentacoes, $event)" (campoGrupoEmt)="agrupar(movimentacoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th class="r">{{ pedidoCarteira == false ? bibDialogo.clienteFornecedor : bibDialogo.cliente }}</th>
      <th class="r-2">{{ bibDialogo.vendedor }}</th>
      <th *ngIf="pedidoCarteira == false && comissao == false" class="r-1 text-right">{{ bibDialogo.acrescimo }}</th>
      <th *ngIf="pedidoCarteira == false && comissao == false" class="r-1 text-right">{{ bibDialogo.descontoTotal }}</th>
      <th *ngIf="comissao == true" class="r-1 text-right">{{ bibDialogo.valorComissao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
      <th *ngIf="pedidoCarteira == true" class="r-1 text-right">{{ bibDialogo.valorFaturado }}</th>
      <th *ngIf="pedidoCarteira == true" class="r-1 text-right">{{ bibDialogo.valorDesfeito }}<ajuda class="naoImprimir" [ajuda]="bibDialogo.valorDesfeito + ' + ' + bibDialogo.valorEstorno"></ajuda></th>
      <th *ngIf="pedidoCarteira == true" class="r-1 text-right">{{ bibDialogo.valorPendente }}</th>
      <th *ngIf="usaNegociacao" class="r-1">{{ menuNegociacao.apelido }}</th>
      <th *ngIf="usaSituacao" class="r-2">{{ bibDialogo.situacao }}</th>
      <th *ngIf="movimentacoes.length > 0" class="r-1"></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="movimentacao.expandido || (pedidoCarteira && movimentacao.liberadoFaturamento == 'N') ? 'sem-borda' : ''">
          <td>{{ movimentacao.abreviacao }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td>{{ movimentacao.data | data }}</td>
          <td>{{ movimentacao.parceiroNumero + ' - ' + movimentacao.parceiro }}</td>
          <td>{{ movimentacao.colaboradorNumero + ' - ' + movimentacao.colaborador }}</td>
          <td *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacao.acrecimo | monetario }}</td>
          <td *ngIf="pedidoCarteira == false && comissao == false" class="text-right">{{ movimentacao.descontoValor | monetario }}</td>
          <td *ngIf="comissao == true" class="text-right">{{ movimentacao.valorComissao | monetario }}</td>
          <td class="text-right">{{ movimentacao.valorTotal | monetario }}</td>
          <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacao.valorTotalFaturado | monetario }}</td>
          <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacao.valorTotalDesfeitoEstorno | monetario }}</td>
          <td *ngIf="pedidoCarteira == true" class="text-right">{{ movimentacao.valorTotalPendente | monetario }}</td>
          <td class="limitar" [ngClass]="movimentacao.contagemTipoTitulo > 0 && movimentacao.aguardandoNfe == 'S' && movimentacao.negociacao ? 'bg-danger text-white': ''" *ngIf="usaNegociacao">{{ movimentacao.negociacao }}</td>
          <td class="limitar naoImprimir" *ngIf="usaSituacao">
            <span class="badge badge-subtle" [ngClass]="movimentacao.situacao == 1 ? 'badge-danger' : movimentacao.situacao == 2 ? 'badge-warning' : movimentacao.situacao == 3 ? 'badge-light' : movimentacao.situacao == 4 ? 'badge-primary' : movimentacao.situacao == 5 ? 'badge-success' : 'badge-dark'">{{ movimentacao.situacao == 1 ? bibDialogo.aberta.toUpperCase() : movimentacao.situacao == 2 ? bibDialogo.aguardandoFinanceiro : movimentacao.situacao == 3 ? bibDialogo.aguardandoEntrega : movimentacao.situacao == 4 ? bibDialogo.entregaParcial : movimentacao.situacao == 5 ? bibDialogo.concluida : bibDialogo.cancelada }}</span>
          </td>
          <td class="limitar somente-imprimir" *ngIf="usaSituacao">{{ movimentacao.situacaoDescricao }}</td>
          <td class="naoImprimir" *ngIf="movimentacoes.length > 0"><btnAdicional [icone]="movimentacao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(movimentacao)"></btnAdicional></td>
        </tr>
        <tr [ngClass]="movimentacao.expandido ? 'sem-borda' : 'border-botton'" *ngIf="pedidoCarteira && movimentacao.liberadoFaturamento == 'N'">
          <div class="mb-1">
            <atencao [atencao]="bibDialogo.aguardandoEntrega" [tipo]="bibPropriedade.atencao.alerta"></atencao>
          </div>
        </tr>        
        <tr *ngIf="movimentacao.expandido" r1001itens [empresaRegraCasasDecimais]="empresaRegraCasasDecimais" [usaNegociacao]="usaNegociacao" [usaSituacao]="usaSituacao" [movimentacao]="movimentacao" [pedidoCarteira]="pedidoCarteira" [comissao]="comissao" [criteriosFiltro]="criteriosFiltro"></tr>
        <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" class="sem-borda"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
