import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.estoqueDisponivelDiferenteNFe, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL) <> SUM(ESTHIS.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelIgualNfe, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL) = SUM(ESTHIS.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelMenorNfe, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL) < SUM(ESTHIS.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelMaiorNfe, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL) > SUM(ESTHIS.QUANTIDADE_NFE)' },
  { nome: bibDialogo.estoqueDisponivelMenorPendente, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL) < SUM(ESTHIS.QUANTIDADE_RESERVADA)' },
  { nome: bibDialogo.estoqueDisponivelMenorMinimo, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL) <= PROEST.QUANTIDADE_MINIMA' },
]);

const filtroComboQuantidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.disponivel, id: 'SUM(ESTHIS.QUANTIDADE_DISPONIVEL)' },
  { nome: bibDialogo.nfe, id: 'SUM(ESTHIS.QUANTIDADE_NFE)' },
  { nome: bibDialogo.pendente, id: 'SUM(ESTHIS.QUANTIDADE_RESERVADA)' },
]);

const filtroComboUsoConsumo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.nao, id: 'N' },
  { nome: bibDialogo.sim, id: 'S' },
  { nome: bibDialogo.todos, id: '-' },
]);

const valores: string[] = ['SUM(ESTHIS.QUANTIDADE_DISPONIVEL)', 'SUM(ESTHIS.QUANTIDADE_NFE)', 'SUM(ESTHIS.QUANTIDADE_RESERVADA)'];

export class Filtros2016 {
  public filtros: Filtro[] = plainToClass(Filtro, [
    { rotulo: bibDialogo.data, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date(), obrigatorio: 'S' },
    { rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.produtoDescricao, nome: 'NOME_PRODUTO', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { rotulo: bibDialogo.referencia, nome: 'REFERENCIA', coluna: 6, tipo: bibPropriedade.filtro.texto },
    { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
    { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
    { rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
    { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista, obrigatorio: 'S' },
    { rotulo: bibDialogo.situacao, nome: 'DIFERENCA', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboSituacao },
    { rotulo: bibDialogo.quantidade, nome: 'OPERADOR', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboQuantidade },
    { rotulo: bibDialogo.maiorQue, nome: 'QUANTIDADE_MAIOR', coluna: 6, tipo: bibPropriedade.filtro.numero, vinculaBloqueado: 'OPERADOR', bloqueioInicial: true, bloqueados: valores },
    { rotulo: bibDialogo.menorQue, nome: 'QUANTIDADE_MENOR', coluna: 6, tipo: bibPropriedade.filtro.numero, vinculaBloqueado: 'OPERADOR', bloqueioInicial: true, bloqueados: valores },
    { rotulo: bibDialogo.usoConsumo, nome: 'USO_CONSUMO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'N', lista: filtroComboUsoConsumo },
  ]);
}
