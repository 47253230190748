<layout [titulo]="grupoContabil.nome" [id]="grupoContabil.id">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="grupoContabil.nome" (alteracao)="grupoContabil.nome = $event" [obrigatorio]="true" [foco]="true" [maximoCaracteres]="100"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="grupoContabil.ativo" (alteracao)="grupoContabil.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row align-items-center">
    <titulo [titulo]="menuGrupoContabil.apelido" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.telefone.toLowerCase()" [desabilitado]="!temPermissaoEdicao"></titulo>
  </div>
  <div class="row" *ngIf="grupoContabil.grupoContaContabeis.length == 0">
    <p class="col-sm-12 pl-3">
      {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.configuracao.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ menuGrupoContabil.apelido.toLowerCase() }}
    </p>
  </div>
  <table class="table table-responsive-sm" *ngIf="grupoContabil.grupoContaContabeis.length > 0">
    <thead>
      <tr>
        <th class="l-95">{{ bibDialogo.conta }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let grupoContaContabil of grupoContabil.grupoContaContabeis; let i = index">
        <td class="limitar">{{ grupoContaContabil.nomeConta }}</td>
        <td>
          <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoContabilTotal(i)"></btnAdicional>
        </td>
      </tr>
    </tbody>
  </table>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirGrupoContabil()" [desabilitado]="!temPermissaoEdicao"></botao>
<auditoria *ngIf="grupoContabil.id" [idUsuarioInclusao]="grupoContabil.idUsuarioInclusao" [dataHoraInclusao]="grupoContabil.dataHoraInclusao" [idUsuarioAlteracao]="grupoContabil.idUsuarioAlteracao" [dataHoraAlteracao]="grupoContabil.dataHoraAlteracao"></auditoria>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
