import { Component, Input } from '@angular/core';
import { differenceInDays } from 'date-fns';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Patrimonial } from 'src/app/modelo/patrimonial';
import { PatrimonialLancamentoContabil } from 'src/app/modelo/patrimonialLancamentoContabil';
import { Resultado } from 'src/app/modelo/resultado';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'patrimonialLancamentoContabil',
  templateUrl: './patrimonialLancamentoContabilFrm.component.html',
})  
export class PatrimonialLancamentoContabilFrmComponent extends PaginaComponent {
  @Input() excluirPatrimonialLancamentosContabeis: number[] = [];
  @Input() patrimonial: Patrimonial;

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public focoData: number;
  public percentualDiario: number;
  public valorLancamentosContabeis: number;
  
  dataMaisRecente(): Date {
    let maiorData: Date = this.patrimonial.dataAquisicao; 
    const dataMaisRecente = this.patrimonial.patrimonialLancamentosContabeis.reduce((maiorDataEncontrada, patrimonialLancamentoContabil) => {
      if (!maiorDataEncontrada || patrimonialLancamentoContabil.data > maiorDataEncontrada) {
        return patrimonialLancamentoContabil.data;
      }
      return maiorDataEncontrada;
    }, maiorData);
  
    return dataMaisRecente; 
  }

  setData(patrimonialLancamentoContabil: PatrimonialLancamentoContabil, data: Date): void {
    patrimonialLancamentoContabil.data = data;
    patrimonialLancamentoContabil.percentual = 0;
    patrimonialLancamentoContabil.valor = 0;
    if (this.patrimonial.valorAtual > 0 && this.patrimonial.percentualDepreciacao > 0) {
      const dataMaisRecente: Date = this.patrimonial.patrimonialLancamentosContabeis.length > 1 ? this.patrimonial.patrimonialLancamentosContabeis[0].data : this.patrimonial.dataAquisicao;
      const diasDepreciacao: number = differenceInDays(data.setHours(0,0,0,0), new Date(dataMaisRecente));
      const depreciacaoDiaria: number = this.patrimonial.percentualDepreciacao / 365;
      patrimonialLancamentoContabil.percentual = diasDepreciacao * depreciacaoDiaria > 100 ? 100 : diasDepreciacao * depreciacaoDiaria;
      patrimonialLancamentoContabil.valor = (this.patrimonial.valorAtual * patrimonialLancamentoContabil.percentual) / 100;
      patrimonialLancamentoContabil.valor = patrimonialLancamentoContabil.valor >= this.patrimonial.valorAtual ? this.patrimonial.valorAtual : patrimonialLancamentoContabil.valor;
    }
  }  

  adicionar(): void {
    if (this.ehValido()){
      let patrimonialLancamentoContabil = new PatrimonialLancamentoContabil();
      this.setData(patrimonialLancamentoContabil, new Date());
      patrimonialLancamentoContabil.idLoja = this.patrimonial.idLoja;
      patrimonialLancamentoContabil.alterado = true;
      this.patrimonial.patrimonialLancamentosContabeis.push(patrimonialLancamentoContabil);
      this.focoData = Math.random();
    }
  }

  ehValido(){
    const ehValidoDataAquisicao = this.ehValidoDataAquisicao();
    const ehValidoValorAtual = this.ehValidoValorAtual();

    return ehValidoDataAquisicao && ehValidoValorAtual;
  }

  ehValidoDataAquisicao(): boolean {
    if (this.patrimonial.dataAquisicao) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.depreciacaoInformarDataAquisicao));
    return false;
  }

  ehValidoValorAtual(): boolean {
    if (this.patrimonial.valorAtual > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.depreciacaoValorAtual));
    return false;
  }  

  excluirPatrimonialLancamentoContabil(posicao: number, idPatrimonialLancamentoContabil: number): void {
    super.ehExcluir(posicao, this.patrimonial.patrimonialLancamentosContabeis, this.excluirPatrimonialLancamentosContabeis, idPatrimonialLancamentoContabil);
  }
}
