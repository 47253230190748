<div class="naoImprimir" *ngIf="_numeroRegistro > paginacao && numeroPagina && apresentaPaginacao">
  <nav class="d-flex justify-content-center align-items-center">
    <atencao class="mr-2" [atencao]="_utilizaPaginacaoFrm ? bibDialogo.ctrlShiftAtras : bibDialogo.ctrlAtras"></atencao>
    <ul class="pagination d-flex justify-content-center align-items-center mb-0">
      <li *ngIf="_paginaAtual > 1">
        <a href="#" (click)="voltar()"> <icone [icone]="bibIcone.setaEsquerda"></icone> </a>
      </li>
      <li *ngIf="_paginaAtual > limitePagina">
        <a class="page-link" (click)="voltarAba(limitePagina * (numeroAba - 1) + 1 + (limitePagina - 1))">...</a>
      </li>
      <ng-container *ngFor="let numero of [].constructor(limitePagina); let i = index">
        <li *ngIf="i + numeroAba * limitePagina < _numeroRegistro / paginacao" [ngClass]="posicao == i ? 'page-item active pagina-destaque' : 'page-item'">
          <a class="page-link" (click)="navegar(i + numeroAba * limitePagina + 1, i)">{{ i + numeroAba * limitePagina + 1 }}</a>
        </li>
      </ng-container>
      <li *ngIf="limitePagina * (numeroAba + 1) + 1 < numeroPagina">
        <a class="page-link" (click)="avancarAba(limitePagina * (numeroAba + 1) + 1)">...</a>
      </li>
      <li *ngIf="_paginaAtual < _numeroRegistro / paginacao">
        <a href="#" (click)="avancar()"> <icone [icone]="bibIcone.setaDireita"></icone> </a>
      </li>
    </ul>
    <atencao class="ml-2" [atencao]="_utilizaPaginacaoFrm ? bibDialogo.ctrlShiftAvancar : bibDialogo.ctrlAvancar"></atencao>
  </nav>
  <div class="d-flex justify-content-center align-items-center flex-direction-column mt-1">
    <a  *ngIf="utilizaPaginacaoFrm == false && (this._numeroRegistro <= 500 || this._demanda == false)" href="#" (click)="apresentarTodos()">{{ bibDialogo.mostrarTodos }}</a>
  </div>
</div>
