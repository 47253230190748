import { Component, Input } from '@angular/core';

@Component({
  selector: 'obrigatorio',
  templateUrl: './obrigatorio.component.html',
})
export class ObrigatorioComponent {
  @Input() abreviatura: boolean = false;
  @Input() compacto: boolean = false;
  @Input() obrigatorio: boolean = true;
  @Input() preenchido: boolean = false;

  retornarDescricao(): string {
    if (this.compacto || this.abreviatura) {
      return '!';
    } else {
      return 'Obrigatório !';
    }
  }
}
