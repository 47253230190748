import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Monitoramento } from 'src/app/modelo/monitoramento';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './monitoramento.component.html',
  styleUrls: ['./monitoramento.component.css'],
})
export class MonitoramentoComponent extends PaginaComponent {
  public monitoramentos: Monitoramento[] = [];
  public filtros: Filtro[] = filtros;
  public usuario: Usuario = this.utilSessao.getUsuario();
  public hoje: Date = new Date();

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.monitoramento).subscribe((res) => {
      this.monitoramentos = this.plainToClass(Monitoramento, res) as any;
    });
  }

  definirUsuarioEmpresa(idEmpresa: number) {
    const idEmpresaAnterior: number = this.usuario.idEmpresa;
    if (this.usuario.idEmpresa != idEmpresa) {
      this.usuario.idEmpresa = idEmpresa;
      super.persistir(new Transporte(this.usuario), this.bibServico.monitoramento, null).subscribe((res) => {
        if (this.utilSessao.falha == true) {
          this.usuario.idEmpresa = idEmpresaAnterior;
        } else {
          this.utilSessao.alertaAlteracao = true;
          this.sair();
        }
      });
    }
  }

  sair(): void {
    this.router.navigate(['/login' + this.utilSessao.getDominio('/')]);
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('dataAtualLogin', JSON.stringify(new Date()));
  }
}
