import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'r2001MovimentacaoFabricante',
  templateUrl: './r2001MovimentacaoFabricante.component.html',
})
export class R2001MovimentacaoFabricanteComponent {
  @Input() movimentacaoFabricantes: any[] = [];
  public menuFabricanteApelido = new UtilSessao().getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 9).apelido;
  public bibDialogo = bibDialogo;
}
