<div [hidden]="bloqueiaTela" class="app has-fullwidth">
  <header *ngIf="usaRelatorio == false && usuario && saindo == false && loginCompleto" class="app-header naoImprimir" [ngClass]="utilSessao.sistema.toLowerCase()">
    <div class="top-bar">
      <div class="top-bar-brand">
        <button class="hamburger hamburger-squeeze mr-2" type="button" data-toggle="aside" aria-label="Menu" aria-controls="navigation">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <a [routerLink]="['']" tabindex="-1">
          <img [src]="'img/logomarca/logo_' + utilSessao.sistema.toLowerCase() + '_clara.png'" height="32" alt="" />
        </a>
        <p class="versao-logomarca p-1">{{ bibDialogo.versaoSistema }}</p>
      </div>
      <div class="top-bar-list">
        <div class="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
          <button class="hamburger hamburger-squeeze" type="button" data-toggle="aside" aria-label="Menu" aria-controls="navigation">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div class="top-bar-item top-bar-item-full">
          <div *ngIf="acessoSemLogin == false" class="top-bar-search">
            <div class="input-group input-group-search pesquisa-sem-borda">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <span class="oi oi-magnifying-glass" (click)="irDesejo()"></span>
                </span>
              </div>
              <input #input [(ngModel)]="desejo" type="text" class="form-control" aria-label="Search" placeholder="o que você deseja?" (keyup.enter)="irDesejo()" [focus]="true" />
            </div>
          </div>
        </div>
        <div class="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
          <ul class="header-nav nav">
            <li class="nav-item" data-toggle="tooltip" [title]="bibDialogo.menuRapido">
              <a (click)="ehAbrirMenuPersonalizado()" class="nav-link clique">
                <span class="oi oi-tablet"></span>
              </a>
            </li>
            <li class="nav-item dropdown header-nav-dropdown" data-toggle="tooltip" [title]="bibDialogo.favoritos">
              <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="oi oi-star"></span>
              </a>
              <div class="dropdown-arrow"></div>
              <div class="dropdown-menu dropdown-menu-rich dropdown-menu-right">
                <div class="dropdown-sheets" style="overflow-x: auto; max-height: 315px;">
                  <div *ngFor="let permissao of permissoes | favorito" class="dropdown-sheet-item">
                    <a href="#" (click)="irPara(permissao)" class="tile-wrapper">
                      <span class="tile tile-lg bg-teal">
                        <span class="oi icone-oi" [ngClass]="permissao.icone ? permissao.icone : 'oi oi-target'"></span>
                      </span>
                      <span class="tile-peek">{{ permissao.menu }}</span>
                    </a>
                  </div>
                  <div *ngFor="let relatorio of relatorios | favorito" class="dropdown-sheet-item">
                    <a href="#" (click)="util.irRelatorio(relatorio.id)" class="tile-wrapper">
                      <span class="tile tile-lg bg-teal">
                        <span class="oi icone-oi" [ngClass]="'oi oi-spreadsheet'"></span>
                      </span>
                      <span class="tile-peek">{{ relatorio.id }}</span>
                    </a>
                  </div>
                  <div *ngIf="(utilSessao.getPermissoes() | favorito).length == 0 && (relatorios | favorito).length == 0" class="dropdown-sheet-item">Nenhum</div>
                </div>
              </div>
            </li>
            <li *ngIf="usuarioIntegracoes && usuarioIntegracoes.length > 0" class="nav-item" data-toggle="tooltip" [title]="bibDialogo.integracoes">
              <a tabindex="-1" [routerLink]="'/integracaoLista'" class="nav-link">
                <span class="fas fa-retweet"></span>
              </a>
            </li>
            <li *ngIf="permissaoAbrirPesquisaCliente" class="nav-item" data-toggle="tooltip" [title]="bibDialogo.pesquisaClienteAtalho">
              <a (click)="abrirPesquisaCliente()" class="nav-link clique">
                <span class="fas fa-user-check"></span>
              </a>
            </li>
            <li *ngIf="permissaoAbrirPesquisaProduto" class="nav-item" data-toggle="tooltip" [title]="bibDialogo.pesquisaProdutoAtalho">
              <a (click)="abrirPesquisaProduto()" class="nav-link clique"> <span class="fab fa-product-hunt"></span> </a>
            </li>
            <li *ngIf="relatoriosUsuario && relatoriosUsuario.length > 0" class="nav-item" data-toggle="tooltip" [title]="bibDialogo.relatorios">
              <a tabindex="-1" [routerLink]="'/relatorioLista/relatorio'" class="nav-link">
                <span [class]="bibIcone.relatorio"></span>
              </a>
            </li>
            <li *ngIf="dashboardUsuario && dashboardUsuario.length > 0" class="nav-item" data-toggle="tooltip" [title]="bibDialogo.dashboard">
              <a tabindex="-1" [routerLink]="'/relatorioLista/dashboard'" class="nav-link">
                <span class="menu-icon oi oi-bar-chart"></span>
              </a>
            </li>
            <li class="nav-item" data-toggle="tooltip" [title]="bibDialogo.abrirNovaAba">
              <a href="/Frontend" target="_blank" class="nav-link clique">
                <span class="menu-icon oi oi-browser"></span>
              </a>
            </li>
          </ul>
          <div class="dropdown">
            <button tabindex="-1" class="btn-account d-none d-md-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span *ngIf="conexao" class="user-avatar">
                <img [src]="usuario.foto == 'S' ? conexao.caminhoAwsS3 + '' + usuario.idEmpresa + '/usuario/' + usuario.id + '.jpg' : 'img/semfoto.jpg'" />
              </span>
              <span class="account-summary pr-lg-4 d-none d-lg-block">
                <span class="account-name">{{ nome }}</span>
                <span class="account-description">{{ abreviacaoLoja }}</span>
              </span>
            </button>
            <div class="dropdown-arrow dropdown-arrow-left"></div>
            <div class="dropdown-menu">
              <h6 class="dropdown-header d-none d-md-block d-lg-none">{{ nome }}</h6>
              <div class="dropdown-item clique" (click)="abrirMinhaConta()"><span class="dropdown-icon oi oi-person"></span>Minha conta</div>
              <div class="dropdown-item clique" (click)="abrirModalAtalhos()"><span class="dropdown-icon oi oi-terminal"></span>Teclas de atalho</div>
              <div class="dropdown-item clique" (click)="bloqueiar()"><span class="dropdown-icon fa fa-lock"></span>Bloqueia tela</div>
              <div class="dropdown-divider clique"></div>
              <div class="dropdown-item clique" (click)="ehSair()"><span class="dropdown-icon oi oi-account-logout"></span>Sair</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <aside *ngIf="usaRelatorio == false" class="app-aside naoImprimir" #asideMenuPrincipal>
    <div class="aside-content naoImprimir" [ngClass]="ehModoClaro ? '' : 'modo-escuro'">
      <header class="aside-header">
        <button class="hamburger hamburger-squeeze" type="button" data-toggle="aside" aria-label="Menu" aria-controls="navigation">
          <span class="hamburger-box" (click)="util.fecharMenuPrincipal()">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <button *ngIf="acessoSemLogin == true" class="btn-account d-flex d-md-none" type="button" data-toggle="collapse" data-target="#dropdown-aside">
          <span class="user-avatar user-avatar-lg">
            <img [src]="buscarImagemUsuario()" [alt]="nome" />
          </span>
          <span class="account-icon">
            <span class="fa fa-caret-down fa-lg"></span>
          </span>
          <span class="account-summary">
            <span class="account-name">{{ nome }}</span>
            <span class="account-description">{{ abreviacaoLoja }}</span>
          </span>
        </button>
        <div id="dropdown-aside" class="dropdown-aside collapse naoImprimir">
          <div class="pb-3">
            <div class="dropdown-item clique" (click)="abrirMinhaConta()"><span class="dropdown-icon oi oi-person"></span>Minha conta</div>
            <div class="dropdown-item clique" (click)="abrirModalAtalhos()"><span class="dropdown-icon oi oi-terminal"></span>Teclas de atalho</div>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" (click)="ehSair()"><span class="dropdown-icon oi oi-account-logout"></span>Sair</a>
          </div>
        </div>
        <div class="top-bar-brand justify-content-center" [ngClass]="ehModoClaro || ehModoClaro == null ? '' : 'modo-escuro'">
          <img [src]="'img/logomarca/logo_' + utilSessao.sistema.toLowerCase() + '.png'" height="32" alt="" />
        </div>
      </header>
      <section class="aside-menu has-scrollable">
        <nav class="stacked-menu">
          <menu tipo="menuPrincipal" [usuarioLogado]="usuario && saindo == false && loginCompleto ? true : false"></menu>
        </nav>
      </section>
    </div>
  </aside>
  <main [ngClass]="usuario && loginCompleto && usaRelatorio == false ? 'app-main' : ''">
    <div [ngClass]="usuario && loginCompleto ? 'wrapper' : ''">
      <div [ngClass]="usuario && loginCompleto ? 'page' : ''">
        <div [ngClass]="usuario && loginCompleto ? 'page-inner' : ''">
          <div [ngClass]="usuario && loginCompleto && usaRelatorio == false ? 'page-section relativo' : ''">
            <router-outlet *ngIf="saindo == false"></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<div *ngIf="utilSessao.processando > 0">
  <div class="divAguarde align-items-center">
    <div class="loader loader-lg"></div>
    <div *ngIf="utilSessao.mensagemAguardando">
      <div>
        <span class="badge compacto badge-light" [ngClass]="utilSessao.mensagemDesistirEspera ? 'margem-mensagem' : 'margem-mensagem-loader'">{{ utilSessao.mensagemDesistirEspera ? bibDialogo.mensagemAguardarEspera : bibDialogo.aguardeUmMomento }}</span>
      </div>
      <div>
        <span *ngIf="utilSessao.mensagemDesistirEspera" class="badge compacto badge-light" [ngClass]="utilSessao.mensagemDesistirEspera ? 'margem-mensagem-espera-loader' : 'margem-mensagem-loader'">{{ bibDialogo.mensagemAguardarEsperaDesistir }}</span>
        <span *ngIf="utilSessao.mensagemDesistirEspera" class="clique badge compacto badge-success ml-1" (click)="utilSessao.finalizarProcesso()">{{ bibDialogo.sim }}</span>
        <span *ngIf="utilSessao.mensagemDesistirEspera" class="clique badge compacto badge-danger ml-1" (click)="utilSessao.mensagemDesistirEspera = false">{{ bibDialogo.nao }}</span>
      </div>
    </div>
  </div>
  <div class="divAguardeFundo"></div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueia *ngIf="bloqueiaTela" (bloqueiaTela)="bloqueiaTela = $event"></bloqueia>
