import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import bibAtalho from '../../biblioteca/bibAtalho';
import bibDialogo from '../../biblioteca/bibDialogo';
import bibIcone from '../../biblioteca/bibIcone';

@Component({
  selector: 'paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.css'],
})
export class PaginacaoComponent {
  adicionando: boolean = false;
  @Input() paginacao: number;  
  @Output() paginacaoEmt = new EventEmitter<number>();
  @Output() paginacaoFrmEmt = new EventEmitter<number>();
  @Output() alteracao = new EventEmitter<number>();
  @Input()
  set numeroRegistro(numeroRegistro: number) {
    if (numeroRegistro > this._numeroRegistro) {
      this.adicionando = true;
    } else {
      this.adicionando = false;
    }
    this._numeroRegistro = numeroRegistro;
    this.iniciar();
  }
  @Input()
  set utilizaPaginacaoFrm(utilizaPaginacaoFrm: boolean) {
    this._utilizaPaginacaoFrm = utilizaPaginacaoFrm;
    this.iniciar();
  }

  @Input()
  set demanda(demanda: boolean) {
    this._demanda = demanda;
  }

  public _demanda: boolean = false;
  public _numeroRegistro: number;
  public _utilizaPaginacaoFrm: boolean = false;
  public _paginaAtual: number = 0;
  public apresentaPaginacao: boolean = true;
  public bibAtalho = bibAtalho;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public limitePagina: number = 10;
  public numeroAba: number = 0;
  public numeroPagina: number;
  public posicao: number = 0;

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (event.keyCode == this.bibAtalho.setaEsquerda && event.ctrlKey) {
      this.voltar();
    }
    if (event.keyCode == this.bibAtalho.setaDireita && event.ctrlKey) {
      this.avancar();
    }
  }

  constructor(public utilSessao: UtilSessao) {}

  irAutomatico(iniciando: boolean = false): void {
    if (this._numeroRegistro) {
      this.navegarAutomatico(this._paginaAtual + this.numeroAba * this.limitePagina + 1, this._paginaAtual, iniciando);
    }
  }

  iniciar(): void {
    if (this.utilSessao.getPaginaAtual() && this._utilizaPaginacaoFrm == false) {
      this._paginaAtual = this.utilSessao.getPaginaAtual() - 1;
    } else {
      if (this.adicionando) {
        this._paginaAtual = Math.trunc(this._numeroRegistro / this.paginacao);
        this.adicionando = false; 
      } else {
        this._paginaAtual = this._paginaAtual > 0 ? this._paginaAtual - 1 : this._paginaAtual < 0 ? 0 : this._paginaAtual;
      }
      if (this._numeroRegistro <= this.paginacao || !this.numeroPagina || !this.apresentaPaginacao) {
        this._paginaAtual = 0;
      }
    }

    this.contarPaginas();
    this.numeroAba = 0;
    this.posicao = 0;
    this.irAutomatico(true);
  }

  contarPaginas(): void {
    if (this._numeroRegistro > this.paginacao) {
      this.numeroPagina = Math.trunc(this._numeroRegistro / this.paginacao);
      if (this._numeroRegistro % this.paginacao > 0) {
        this.numeroPagina++;
      }
    }
  }

  voltar(): void {
    event.preventDefault();
    if (this._paginaAtual > 1) {
      this._paginaAtual--;
      this.posicao--;
      if (this.posicao < 0) {
        this.numeroAba--;
        this.posicao = this.limitePagina - 1;
      }
      this.alteracao.emit((this._paginaAtual - 1) * this.paginacao);
      this.utilSessao.setPaginaAtual(this._paginaAtual);
    }
  }

  avancar(): void {
    event.preventDefault();
    if (this._paginaAtual < this.numeroPagina) {
      this._paginaAtual++;
      this.posicao++;

      if (this.posicao + 1 > this.limitePagina) {
        this.numeroAba++;
        this.posicao = 0;
      }
      this.alteracao.emit((this._paginaAtual - 1) * this.paginacao);
      this.utilSessao.setPaginaAtual(this._paginaAtual);
    }
  }

  navegar(pagina: number, posicao: number = 0): void {
    event.preventDefault();
    this.navegarAutomatico(pagina, posicao);
  }

  navegarAutomatico(pagina: number, posicao: number = 0, iniciando: boolean = false): void {
    this._paginaAtual = pagina;
    this.posicao = posicao;
    if (iniciando != true || this._demanda == false) {
      this.alteracao.emit((this._paginaAtual - 1) * this.paginacao);
    }
    if (this.utilizaPaginacaoFrm != true && iniciando == false) {
      this.utilSessao.setPaginaAtual(this._paginaAtual);
    }
    window.scrollTo(0, 0);
  }

  voltarAba(pagina: number) {
    this.mudarAba(pagina, -1, this.limitePagina - 1);
  }

  avancarAba(pagina: number) {
    this.mudarAba(pagina, 1, 0);
  }

  mudarAba(pagina: number, incremento: number, posicao: number): void {
    event.preventDefault();
    this._paginaAtual = pagina;
    this.posicao = posicao;
    this.numeroAba = this.numeroAba + incremento;
    this.alteracao.emit((this._paginaAtual - 1) * this.paginacao);
    this.utilSessao.setPaginaAtual(this._paginaAtual);
  }

  apresentarTodos(): void {
    event.preventDefault();
    if (confirm(bibDialogo.removerPaginacao)) {
      this.apresentaPaginacao = false;
      this.paginacaoEmt.emit(this._numeroRegistro);
      this.paginacaoFrmEmt.emit(null);
      this.utilSessao.setPaginaAtual(null);
    }
  }
}
