<titulo [id]="'adicionarVeiculo'" [titulo]="bibDialogo.veiculo" [imagem]="bibImagem.transporte" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.veiculo.toLowerCase()" [desabilitado]="temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
<div class="row" *ngIf="_parceiroVeiculos.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.veiculo.toLowerCase() + ', ' }} <strong class="clique" [id]="'adicionarVeiculo'" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="_parceiroVeiculos.length > 0">
  <thead>
    <tr>
      <th class="l-40">{{ bibDialogo.nome }}</th>
      <th class="l-10">{{ bibDialogo.placa }}</th>
      <th class="l-25">{{ bibDialogo.uf + ' ' + bibDialogo.placa.toLowerCase() }}</th>
      <th class="l-15">{{ bibDialogo.renavan }}</th>      
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{ tachado: parceiroVeiculo.ativo != 'S' }" *ngFor="let parceiroVeiculo of _parceiroVeiculos; let i = index">
      <td *ngIf="parceiroVeiculo.nome != null">{{ parceiroVeiculo.nome }}</td>
      <td *ngIf="parceiroVeiculo.nome == null" class="limitar"><atencao [atencao]="bibDialogo.nomeVeiculoObrigatorio" [tipo]="bibPropriedade.atencao.cuidado"></atencao></td>      
      <td>{{ parceiroVeiculo.placa }}</td>
      <td>{{ parceiroVeiculo.estado }}</td>
      <td>{{ parceiroVeiculo.renavan }}</td>
      <td>
        <btnAdicional [id]="'editarVeiculo' + i" [icone]="bibIcone.editar" (btnAdicional)="abrirModal(parceiroVeiculo)"></btnAdicional>
      </td>
      <td>
        <btnAdicional [id]="'excluirVeiculo' + i" [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this._parceiroVeiculos, this.excluirVeiculos, parceiroVeiculo.id)"></btnAdicional>
      </td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>