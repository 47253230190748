export class IcmsTot {    
    public vBC: string;
    public vICMS: string;
    public vICMSDeson: string;
    public vFCP: string;
    public vBCST: string;
    public vST: string;
    public vFCPST: string;
    public vFCPSTRet: string;
    public vProd: string;
    public vFrete: string;
    public vSeg: string;
    public vDesc: string;
    public vII: string;
    public vIPI: string;
    public vIPIDevol: string;
    public vPIS: string;
    public vCOFINS: string;
    public vOutro: string;
    public vNF: string;
    public vTotTrib: string;
}