import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import bibPropriedade from '../../../biblioteca/bibPropriedade';

@Component({
  selector: 'containerFormulario',
  templateUrl: './containerFormulario.component.html',
  styleUrls: ['./containerFormulario.component.css'],
})
export class ContainerFormularioComponent {
  @Input() ajuda: string;
  @Input() ajudaDireita: boolean = false;
  @Input() atencaoMensagem: string;
  @Input() borda: boolean = true;
  @Input() campoAtual: any;
  @Input() campoFor: string;
  @Input() compacto: boolean = false;
  @Input() compactoBotao: boolean = false;
  @Input() desabilitado: boolean = false;
  @Input() icone: string;
  @Input() iconeDesabilitado: boolean = true;
  @Input() idMenu: number = 0;
  @Input() obrigatorio: boolean = false;
  @Input() preenchido: boolean = false;
  @Input() prefixo: string;
  @Input() rotulo: string;
  @Input() sufixo: string;
  @Input() vertical: boolean = true;
  @Output() btnAdicional = new EventEmitter<boolean>();
  public bibPropriedade = bibPropriedade;
  public ehModoClaro: boolean;
  public filtro: boolean = false;

  constructor(private utilNotificacao: UtilNotificacao, private utilSessao: UtilSessao) { }

  ngOnInit(): void {
    this.filtro = this.utilSessao.getIdentificacao('filtro')?.conteudo;
    this.ehModoClaro = new UtilSessao().getUsuario().usaModoClaro == 'S';
    this.utilNotificacao.ehModoClaroEmmiter.subscribe((ehModoClaro) => {
      this.ehModoClaro = ehModoClaro;
    });
  }

  clicarBtnAdicional(): void {
    this.btnAdicional.emit(true);
  }
}
