<div *ngIf="_idUsuarioInclusao" class="section-block">
  <div class="alert alert-secondary">
    <div class="row">
      {{ bibDialogo.registroInseridoEm }} {{ dataHoraInclusao | data: 'dd/MM/yyyy' }} às {{ dataHoraInclusao | data: 'HH:mm' }} e {{ bibDialogo.alterado.toLocaleLowerCase() }} em {{ dataHoraAlteracao | data: 'dd/MM/yyyy' }} às {{ dataHoraAlteracao | data: 'HH:mm' }}.
      <btnAdicional class="ml-2" [icone]="mostraDadosUsuario ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="mostrarDadosUsuario()" [ajuda]="mostraDadosUsuario ? bibDialogo.cliqueParaOcultarDetalhes : bibDialogo.cliqueParaMostrarDetalhes"></btnAdicional>
    </div>
    <div *ngIf="mostraDadosUsuario" class="avatar-group mb-1 mt-2">
      <a class="user-avatar user-avatar-md mr-2m">
        <img [src]="fotoUsuarioInclusao" (error)="fotoUsuarioInclusao = fotoUsuarioPadrao" />
      </a>
      <a *ngIf="_idUsuarioAlteracao != _idUsuarioInclusao" class="user-avatar user-avatar-md mr-2m">
        <img [src]="fotoUsuarioAlteracao" (error)="fotoUsuarioAlteracao = fotoUsuarioPadrao" />
      </a>
      {{ bibDialogo.inseridoPor }} <strong>{{ nomeUsuarioInclusao }}</strong>
      <a *ngIf="_idUsuarioAlteracao != _idUsuarioInclusao">
        e {{ bibDialogo.alterado.toLocaleLowerCase() }} {{ _idUsuarioAlteracao != _idUsuarioInclusao ? 'por ' : '' }}<strong>{{ _idUsuarioAlteracao != _idUsuarioInclusao ? nomeUsuarioAlteracao : '' }}</strong
        >.</a
      >
    </div>
  </div>
</div>
