<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <tr>
        <th class="r-1">{{ bibDialogo.menu }}</th>
        <th class="r-1">{{ bibDialogo.numeroUnico }}</th>
        <th class="r-1-5">{{ bibDialogo.dataHora }}</th>
        <th class="r-1">{{ bibDialogo.usuario }}</th>
        <th class="r-1">{{ bibDialogo.status }}</th>
        <th class="r-0-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let auditoria of auditorias; let i = index">
        <tr class="conteudo" [ngClass]="auditoria.expandido ? 'sem-borda' : 'conteudo'">
          <td>{{ auditoria.menu }}</td>                
          <td>{{ auditoria.idRegistro }}</td>          
          <td>{{ auditoria.dataHora | data: 'dd/MM/yy HH:mm' }}</td>
          <td>{{ auditoria.usuario }}</td>
          <td>{{ auditoria.operacaoTipo }}</td>
          <td *ngIf="auditoria.operacao == 1" class="naoImprimir"><btnAdicional [icone]="auditoria.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(auditoria)"></btnAdicional></td>
        </tr>
        <tr *ngIf="auditoria.expandido && auditoria.operacao == 1" r2030detalhes [detalhes]="auditoria.auditoriaDetalhes"></tr>
      </ng-container>
    </tbody>
  </table>
</relatorioLayout>