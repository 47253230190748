import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

@Component({
  selector: '<tr>[r1126item]</tr>',
  templateUrl: './r1126item.component.html',
})
export class R1126ItemComponent {
  @Input() parceiro: any;
  @Input() usaDocumento: boolean = true;
  @Input() usaRelacionamento: boolean = false;
  @Input() usaNomeFantasia: boolean = false;
  @Input() rotuloAgrupaAtual: string;
  public bibDialogo = bibDialogo;
  public utilFiltro: UtilFiltro = new UtilFiltro();
}
