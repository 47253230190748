import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtroComboHistorico: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'COM MOVIMENTAÇÃO', id: 'S' },
  { nome: 'SEM MOVIMENTAÇÃO', id: 'N' },
]);

const filtroComboTipo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'PRODUTO', id: 'P' },
  { nome: 'SERVIÇO', id: 'S' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'referencia', rotulo: bibDialogo.referencia, nome: 'REFERENCIA', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'exceto', rotulo: bibDialogo.exceto, nome: 'EXCETO', coluna: 12, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
  { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { id: 'categoria', rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
  { id: 'codigoBarra', rotulo: bibDialogo.codigoDeBarra, nome: 'CODIGO_BARRA', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'foto', rotulo: bibDialogo.foto, nome: 'FOTO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboAtivo },
  { id: 'tipo', rotulo: bibDialogo.tipo, nome: 'TIPO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboTipo },
  { id: 'faturamento', rotulo: bibDialogo.emiteNfe, nome: 'FATURAMENTO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboAtivo },
  { id: 'classificacaoFiscal', rotulo: bibDialogo.classificacaoFiscal, nome: 'ID_CLASSIFICACAO_FISCAL', coluna: 6, servicoWeb: bibServico.classificacaoFiscal, tipo: bibPropriedade.filtro.busca },
  { id: 'ingredienteAtivo', rotulo: bibDialogo.ingredienteAtivo, nome: 'INGREDIENTE_ATIVO', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'historico', rotulo: bibDialogo.historico, nome: 'HISTORICO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboHistorico },
  { id: 'dataDe', rotulo: bibDialogo.dataDe, nome: 'DATA_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
  { id: 'dataAte', rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, vinculoVisualizacao: 'HISTORICO', visualizacaoInicial: false, visualizacao: ['S', 'N'] },
  { id: 'ncm', rotulo: bibDialogo.ncm, nome: 'ID_NCM', coluna: 12, servicoWeb: bibServico.ncm, tipo: bibPropriedade.filtro.busca },
]);

export default filtros;
