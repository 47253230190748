import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Loja } from 'src/app/modelo/loja';
import { ProdutoControlador } from 'src/app/modelo/produtoControlador';
import { ProdutoEstoqueMinimoMaximo } from 'src/app/modelo/produtoEstoqueMinimoMaximo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'produtoEstoqueMinimoMaximoFrm',
  templateUrl: './produtoEstoqueMinimoMaximoFrm.component.html',
  styleUrls: ['./produtoEstoqueMinimoMaximoFrm.component.css'],
})
export class ProdutoEstoqueMinimoMaximoFrmComponent extends PaginaComponent {
  @Input() produtoControlador: ProdutoControlador;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public editandoQuantidadeMinima: boolean = false;
  public editandoQuantidadeMaxima: boolean = false;

  excluirProdutoEstoqueMinimoMaximo(posicao: number): void {
    this.produtoControlador.produto.produtoEstoqueMinimoMaximos[posicao].quantidadeMinima = null;
    this.produtoControlador.produto.produtoEstoqueMinimoMaximos[posicao].quantidadeMaxima = null;
    this.produtoControlador.produto.produtoEstoqueMinimoMaximos[posicao].percentual = null;
    this.produtoControlador.totalizarQuantidadeMinimoMaximoPercentual();
  }

  setQuantidadeMinima(produtoEstoqueMinimoMaximo: ProdutoEstoqueMinimoMaximo, valor: number): void {
    produtoEstoqueMinimoMaximo.quantidadeMinima = valor;
    this.produtoControlador.totalizarQuantidadeMinimoMaximoPercentual();
  }

  setQuantidadeMaxima(produtoEstoqueMinimoMaximo: ProdutoEstoqueMinimoMaximo, valor: number): void {
    produtoEstoqueMinimoMaximo.quantidadeMaxima = valor;
    this.produtoControlador.totalizarQuantidadeMinimoMaximoPercentual();
  }

  setPercentual(produtoEstoqueMinimoMaximo: ProdutoEstoqueMinimoMaximo, percentual: number): void {
    produtoEstoqueMinimoMaximo.percentual = this.util.arredondar(percentual);
    this.produtoControlador.totalizarQuantidadeMinimoMaximoPercentual();
    this.setTotalMinima(this.produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima);
    this.setTotalMaxima(this.produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima);
  }

  editar(produtoEstoqueMinimoMaximo: ProdutoEstoqueMinimoMaximo): void {
    produtoEstoqueMinimoMaximo.editando = !produtoEstoqueMinimoMaximo.editando;
  }

  setLoja(produtoEstoqueMinimoMaximo: ProdutoEstoqueMinimoMaximo, loja: Loja): void {
    produtoEstoqueMinimoMaximo.idLoja = loja.id;
    produtoEstoqueMinimoMaximo.lojaAbreviacao = loja.abreviacao;
  }

  setTotalMinima(totalMinima: number) {
    this.produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima = totalMinima;
    for (const produtoEstoqueMinimoMaximo of this.produtoControlador.produto.produtoEstoqueMinimoMaximos) {
      produtoEstoqueMinimoMaximo.quantidadeMinima = totalMinima * (this.util.coalesce(produtoEstoqueMinimoMaximo.percentual, 0) * 0.01);
    }
  }

  setTotalMaxima(totalMaxima: number) {
    this.produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima = totalMaxima;
    for (const produtoEstoqueMinimoMaximo of this.produtoControlador.produto.produtoEstoqueMinimoMaximos) {
      produtoEstoqueMinimoMaximo.quantidadeMaxima = totalMaxima * (this.util.coalesce(produtoEstoqueMinimoMaximo.percentual, 0) * 0.01);
    }
  }
}
