<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" (campoOrdemEmt)="ordenar(resultadosABC, $event)" (filtroEmt)="listarRelatorio($event)">
  <table> 
    <thead>
      <th class="r">{{ apresentacao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
      <th class="r-1 text-right">{{ bibDialogo.percentualSimbolo + ' ' + bibDialogo.lucro }}</th>   
      <th class="r-1 text-right">{{ bibDialogo.valor + ' ' + bibDialogo.lucro}}</th>    
      <th class="r-1 text-right">{{ bibDialogo.curvaABC }}</th>       
      <th *ngIf="usaDevolucoes" class="r-1 text-right">{{ bibDialogo.devolucoes }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let resultadoABC of resultadosABC; let i = index">        
        <tr class="conteudo">  
          <td class="limitar">{{ resultadoABC.id + ' - ' + resultadoABC.nome }}</td>
          <td class="text-right">{{ resultadoABC.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ resultadoABC.valor | monetario }}</td>  
          <td class="text-right">{{ resultadoABC.lucroPercentual | monetario}}</td>  
          <td class="text-right">{{ resultadoABC.lucroValor | monetario}}</td>  
          <td class="text-center">{{ resultadoABC.curvaABC }}</td>  
          <td *ngIf="usaDevolucoes" class="text-right">{{ resultadoABC.valorDevolucao | monetario }}</td>  
        </tr>
      </ng-container>
    </tbody> 
    <tfoot>
     <tr *ngIf="resultadosABC?.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
