import { Component, Input } from '@angular/core';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroArt } from 'src/app/modelo/parceiroArt';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { ParceiroArtFrmComponent } from './frm/parceiroArtFrm.component';

@Component({
  selector: 'parceiroArt',
  templateUrl: './parceiroArt.component.html',
})
export class ParceiroArtComponent extends PaginaComponent {
  @Input() excluirArts: number[] = [];
  @Input() parceiroArts: ParceiroArt[] = [];
  @Input() parceiro: Parceiro;
  public componente: any;
  private modalEmt: any;

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.parceiroArt) {
        this.adicionarTemporario(this.plainToClass(ParceiroArt, identificacao.conteudo), this.parceiroArts);
      }
    });
  }

  abrirModal(parceiroArt: ParceiroArt = null): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', parceiroArt));
    this.utilSessao.setIdentificacao(new Identificacao('parceiro', this.parceiro));
    this.componente = ParceiroArtFrmComponent;
  }

  ngOnDestroy() {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }
}
