import { Component } from '@angular/core';
import { Conexao } from 'src/app/biblioteca/conexao';
import { Cidade } from 'src/app/modelo/cidade';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { Mdfe } from 'src/app/modelo/mdfe';
import { MdfeDocumentoFiscal } from 'src/app/modelo/mdfeDocumentoFiscal';
import { MdfePercurso } from 'src/app/modelo/mdfePercurso';
import { MenuEmpresa } from 'src/app/modelo/menuEmpresa';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroVeiculo } from 'src/app/modelo/parceiroVeiculo';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './mdfeFrm.component.html',
})
export class MdfeFrmComponent extends PaginaComponent {
  public mdfe: Mdfe = new Mdfe();
  public conexao: Conexao = new Conexao(this.utilSessao);
  public data: Date = new Date();
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public excluirDocumentosFiscais: number[] = [];
  public excluirPercursos: number[] = [];
  public parceiroVeiculos: ParceiroVeiculo[];
  public estados: Estado[];

  public visualizaLoja: boolean = false;
  public bloqueado: boolean = false;
  public menuTransportadora: MenuEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 55);

  ngOnInit(): void {
    this.listarEstados();
    this.ehAlteracao();
    this.mostrarOcultarLoja();
    this.ehBloqueado();
  }

  mostrarOcultarLoja() {
    if (this.utilSessao.getLojas().length > 1) {
      this.visualizaLoja = true;
    }
  }

  listarParceirosEstados(): void {
    this.parceiroVeiculos = [];
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('ID_PARCEIRO', this.mdfe.idParceiroTransportadora));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroVeiculo).subscribe((res) => {
      this.parceiroVeiculos = this.plainToClass(ParceiroVeiculo, res) as any;
    });
  }

  ehAlteracao(): void {
    this.mdfe = this.plainToClass(Mdfe, this.ehAtualizacao(this.mdfe));
    if (this.mdfe.id) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.mdfe.id)), this.bibServico.mdfe).subscribe((res) => {
        this.mdfe = this.plainToClass(Mdfe, res[0]) as any;
        this.listarDocumentoFiscais(this.mdfe.id);
        this.listarPercursos(this.mdfe.id);
        this.ehBloqueado();
      });
    } else {
      this.mdfe.ativo = 'S';
      this.mdfe.data = this.data;
      this.mdfe.situacao = 1;
      this.mdfe.status = 'N';
      this.definirLoja();
    }
  }

  setLoja(loja: Loja): void {
    this.mdfe.idLoja = loja.id;
    this.mdfe.serie = loja.mdfeSerie;
  }

  listarDocumentoFiscais(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MDFE', id)), this.bibServico.mdfeDocumentoFiscal).subscribe((res) => {
      this.mdfe.mdfeDocumentoFiscais = this.plainToClass(MdfeDocumentoFiscal, res) as any;
    });
  }

  listarPercursos(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_MDFE', id)), this.bibServico.mdfePercurso).subscribe((res) => {
      this.mdfe.mdfePercursos = this.plainToClass(MdfePercurso, res) as any;
    });
  }

  listarEstados(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  finalizar(): void {
    this.mdfe.status = 'S';
    this.mdfe.situacao = 2;
    this.persistirMdfe();
  }

  persistirMdfe(): void {
    if (this.ehValido()) {
      const transporte: Transporte = new Transporte(this.mdfe);
      transporte.adicionar(this.excluirDocumentosFiscais);
      transporte.adicionar(this.excluirPercursos);
      super.persistir(transporte, this.bibServico.mdfe, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.definirId([this.mdfe], this.bibClasse.mdfe, true);
          this.definirId(this.mdfe.mdfeDocumentoFiscais, this.bibClasse.mdfeDocumentoFiscal);
          this.definirId(this.mdfe.mdfePercursos, this.bibClasse.mdfePercurso);
          this.excluirDocumentosFiscais = [];
          this.excluirPercursos = [];
        }
      });
      this.ehBloqueado();
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoCpf: boolean = this.setEhValidoCpf();
    const ehValidoData: boolean = this.setEhValidoData();
    const ehDocumentoFiscal: boolean = this.setEhDocumentoFiscal();
    return ehValidoObrigatorio && ehValidoCpf && ehValidoData && ehDocumentoFiscal;
  }

  setEhDocumentoFiscal(): boolean {
    if (this.mdfe.mdfeDocumentoFiscais.length > 0 || this.mdfe.status == 'N ') {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.mdfeDocumentoFiscalVazio));
    return false;
  }

  ehValidoObrigatorio(): boolean {
    if (this.mdfe.cidadeFinal && this.mdfe.data && this.mdfe.parceiroTransportadora && this.mdfe.veiculo && this.mdfe.placa && this.mdfe.placaUf && this.mdfe.motoristaNome && this.mdfe.motoristaCpf) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoCpf(): boolean {
    if (this.util.validarCnpjCpf(this.mdfe.motoristaCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.cpfIncorreto));
    return false;
  }

  setEhValidoData(): boolean {
    // if (this.mdfe.data) {
    //   if (this.mdfe.data > this.data) {
    //     this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataFutura));
    //     return false;
    //   }
    // }
    return true;
  }

  setCpf(mdfe: Mdfe, valor: string): void {
    mdfe.motoristaCpf = valor == '' ? null : valor;
    mdfe.ehValidoCpf = this.util.validarCnpjCpf(mdfe.motoristaCpf);
  }

  ehBloqueado(): void {
    this.bloqueado = this.mdfe == null || this.mdfe.status == 'S' || this.mdfe.ativo != 'S';
  }

  setCidadeFinal(cidade: Cidade): void {
    this.mdfe.idCidadeFinal = cidade.id;
    this.mdfe.cidadeFinal = cidade.nome;
  }

  setTransportadora(parceiro: Parceiro): void {
    this.mdfe.idParceiroTransportadora = parceiro.id;
    this.mdfe.parceiroTransportadora = parceiro.razaoSocial;
    this.mdfe.motoristaCpf = null;
    this.mdfe.motoristaNome = null;
    this.mdfe.placa = null;
    this.mdfe.placaUf = null;
    this.mdfe.idVeiculo = null;
    this.mdfe.veiculo = null;
    this.listarParceirosEstados();
  }

  setEstado(idEstado: number) {
    this.estados.forEach((estado) => {
      if (estado.id == idEstado) {
        this.mdfe.placaUf = estado.nome;
      }
    });
  }

  setVeiculo(parceiroVeiculo: ParceiroVeiculo): void {
    this.mdfe.idVeiculo = parceiroVeiculo.id;
    this.mdfe.veiculo = parceiroVeiculo.nome;
    this.mdfe.placa = parceiroVeiculo.placa;
    this.setEstado(parceiroVeiculo.idEstado);
    this.mdfe.motoristaCpf = parceiroVeiculo.motoristaCpf;
    this.mdfe.motoristaNome = parceiroVeiculo.motoristaNome;
  }

  definirLoja(): void {
    if (this.utilSessao.getLojas().length == 1) {
      this.setLoja(this.utilSessao.getLojas()[0]);
    }
  }
}
