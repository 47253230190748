import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1138.component.html',
})
export class R1138Component extends RelatorioComponent {
  public cheques: any[] = [];
  public filtros = filtros;
  public grupos = grupos;
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    this.atualizarTotalizador();
    criterios.push(new Criterio('ID_COLABORADOR', this.utilSessao.getUsuario().permitir != 'S' ? this.utilSessao.getUsuario().idColaborador : null));
    super.listar(criterios).subscribe(() => {
      this.cheques = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Cheque').lista;
      this.ordenarAgrupar(this.cheques);
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.ehImprimirRelatorio();
    });
  }

  atualizarTotalizador(): void {
    this.posicionarTotalizador('valorFinal', true);
  }
}
