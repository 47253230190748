<main class="auth">
  <header id="auth-header" class="auth-header" [ngClass]="utilSessao.sistema.toLowerCase()">
    <h1>
      <div class="container">
        <img [src]="'img/logomarca/logo_' + utilSessao.sistema.toLowerCase() + '_clara.png'" height="72" class="mb-4" />
      </div>
    </h1>
  </header>
  <form #formulario="ngForm" class="auth-form" autocomplete="off">
    <div class="mb-4">
      <div *ngIf="usuarioIncorreto" class="form-group">
        <div class="alert alert-danger">{{ bibDialogo.senhaIncorreta }}</div>
      </div>
      <div class="text-center">
        <div class="user-avatar user-avatar-xl mb-4">
          <img id="output" [src]="usuario?.fotoUsuario ? usuario?.fotoUsuario : imagemUsuario" />
        </div>
        <h2 class="card-title text-capitalize">{{ usuario?.colaborador }}</h2>
        <h6 class="card-subtitle text-muted">{{ usuario?.email.toLowerCase() }}</h6>
      </div>
    </div>
    <div class="input-group input-group-lg circle">
      <input [focus]="true" type="password" [(ngModel)]="senha" name="senha" class="form-control pt-1" autocomplete="off" />
      <div class="input-group-prepend">
        <button class="btn btn-reset text-primary px-3" type="submit" [disabled]="formulario.form.invalid" (click)="acessar()"><i [ngClass]="ehModoClaro ? '' : 'pb-3'" class="fa fa-arrow-circle-right"></i></button>
      </div>
    </div>
  </form>
</main>
