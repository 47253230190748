import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Cte } from 'src/app/modelo/cte';
import { CteInformacaoCarga } from 'src/app/modelo/cteInformacaoCarga';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'cteInformacaoCarga',
  templateUrl: './cteInformacaoCargaFrm.component.html',
})
export class CteInformacaoCargaFrmComponent extends PaginaComponent {
  @Input() cte: Cte;
  @Input() excluirCteInformacaoCargas: number[] = [];
  @Input() edicaoCteInformacaoCargas: number[] = [];
  @Input() bloqueado: boolean = false;

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public focoUnidadeMedida: number;

  public unidadesMedida: any[] = [
    { id: '00', nome: this.bibDialogo.cteUnidadeMedidaMetroCubico },
    { id: '01', nome: this.bibDialogo.cteUnidadeMedidaKilo },
    { id: '02', nome: this.bibDialogo.cteUnidadeMedidaTonelada },
    { id: '03', nome: this.bibDialogo.cteUnidadeMedidaUnidade },
    { id: '04', nome: this.bibDialogo.cteUnidadeMedidaLitro },
    { id: '05', nome: this.bibDialogo.cteUnidadeMedidaMilhaoBTU },
  ];

  adicionar(): void {
    if (this.bloqueado != true) {
      let cteInformacaoCarga = new CteInformacaoCarga();
      this.cte.cteInformacaoCargas.push(cteInformacaoCarga);
      this.focoUnidadeMedida = Math.random();
    }
  }

  excluirCteInformacaoCarga(posicao: number, idCteInformacaoCarga: number): void {
    if (this.bloqueado != true) {
      super.ehExcluir(posicao, this.cte.cteInformacaoCargas, this.excluirCteInformacaoCargas, idCteInformacaoCarga);
    }
  }

  editar(cteInformacaoCarga: CteInformacaoCarga): void {
    if (this.bloqueado != true) {
      if (this.edicaoCteInformacaoCargas.includes(cteInformacaoCarga.id)) {
        const posicao: number = this.edicaoCteInformacaoCargas.indexOf(cteInformacaoCarga.id, 0);
        this.edicaoCteInformacaoCargas.splice(posicao, 1);
      } else {
        this.edicaoCteInformacaoCargas.push(cteInformacaoCarga.id);
      }
    }
  }
}
