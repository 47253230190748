import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { LojaEstado } from 'src/app/modelo/lojaEstado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'lojaEstado',
  templateUrl: './lojaEstadoFrm.component.html',
})
export class LojaEstadoFrmComponent extends PaginaComponent {
  @Input() lojaEstados: LojaEstado[] = [];
  @Input() excluirLojaEstados: number[] = [];
  @Input() edicaoLojaEstados: number[] = [];

  public estados: Estado[];
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public focoEstado: number;
  
  ngOnInit(): void {
    this.listarEstado();
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  adicionar(): void {
    let lojaEstado = new LojaEstado();
    this.lojaEstados.push(lojaEstado);
    this.focoEstado = Math.random();
  }

  excluirLojaEstado(posicao: number, idLojaEstado: number): void {
    super.ehExcluir(posicao, this.lojaEstados, this.excluirLojaEstados, idLojaEstado);
  }

  setEstado(lojaEstado: LojaEstado, estado: Estado): void {
    lojaEstado.idEstado = estado.id;
    lojaEstado.estado = estado.nome;
  }

  editar(lojaEstado: LojaEstado): void {
    if (this.edicaoLojaEstados.includes(lojaEstado.id)) {
      const posicao: number = this.edicaoLojaEstados.indexOf(lojaEstado.id, 0);
      this.edicaoLojaEstados.splice(posicao, 1);
    } else {
      this.edicaoLojaEstados.push(lojaEstado.id);
    }
  }
}
