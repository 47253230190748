import { Component } from '@angular/core';
import bibIcone from 'src/app/biblioteca/bibIcone';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import ordens from './acessorio/ordem';

@Component({
  templateUrl: './r1010.component.html',
})
export class R1010Component extends RelatorioComponent {
  public bibIcone = bibIcone;
  public filtros = filtros;
  public grupos = grupos;
  public movimentacaoProdutoVariacao: any[] = [];
  public movimentacaoProdutos: any[] = [];
  public ordens = ordens;
  public relatorioGrupo: RelatorioGrupo = { totais: [], linhas: [] };
  public usaSemVariacao: boolean = true;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(this.util.verificarPermissaoVisualizarMovimentacaoOutroResponsavel(criterios)).subscribe(() => {
      this.movimentacaoProdutoVariacao = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.movimentacaoProdutoVariacao.forEach((movimentacaoProduto) => (movimentacaoProduto.variacao = this.util.arredondar(movimentacaoProduto.variacao)));
      this.ordenarAgrupar(this.movimentacaoProdutoVariacao);
      this.atualizarRelatorioAdicionais();
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
      this.ehImprimirRelatorio();
    });
  }

  atualizarRelatorioAdicionais(): void {
    this.relatorioAdicionais.forEach((relatorioAdicional) => {
      if (relatorioAdicional.id == 147) {
        relatorioAdicional.marcado = this.usaSemVariacao ? 'S' : 'N';
        this.verificarProdutoVariacao();
      }
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaSemVariacao = this.definirRegra(relatorioAdicional, 147, this.usaSemVariacao);
    this.verificarProdutoVariacao();
  }

  verificarProdutoVariacao(): void {
    if (this.usaSemVariacao) {
      this.movimentacaoProdutos = this.movimentacaoProdutoVariacao;
    } else {
      this.movimentacaoProdutos = this.movimentacaoProdutoVariacao.filter((movimentacaoProduto) => movimentacaoProduto.variacao != 0);
    }
  }
}
