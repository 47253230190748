<td class="p-0" colspan="5">
  <table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r">{{ parceiro.tipoPessoa === 'J' ? bibDialogo.nomeFantasia : bibDialogo.apelido }}</th>
        <th class="r-5">{{ bibDialogo.email }}</th>
        <th class="r-4">{{ rotuloRamoAtividade }}</th>
        <th *ngIf="parceiro.tipoPessoa === 'F'" class="r-1">{{ bibDialogo.sexo }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ parceiro.nomeFantasia }}</td>
        <td>{{ parceiro.email }}</td>
        <td>{{ parceiro.ramoAtividade }}</td>
        <td *ngIf="parceiro.tipoPessoa === 'F'">{{ parceiro.sexo }}</td>
      </tr>
    </tbody>
  </table>
</td>
