<layout [titulo]="idRelatorio + ' - ' + relatorio">
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90">{{ bibDialogo.adicional }}</th>
        <th class="l-10">{{ bibDialogo.marcado }}</th>
        <th class="l-10">{{ bibDialogo.ocultar }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let relatorioAdicional of relatorioAdicionais; let i = index">
        <td>{{ relatorioAdicional.nome }}</td>
        <td><interruptor [id]="'adicional' + i" [campo]="relatorioAdicional.marcado" (alteracao)="setRelatorioAdicionalEmpresa(relatorioAdicional, $event)"></interruptor></td>
        <td><interruptor [id]="'adicional' + i" [campo]="relatorioAdicional.oculto" (alteracao)="setRelatorioAdicionalEmpresaOcultar(relatorioAdicional, $event)"></interruptor></td>
      </tr>
    </tbody>
  </table>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirRelatorioAdicionalEmpresas()"></botao>
