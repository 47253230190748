import { Component, Input } from '@angular/core';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroAtualizacao } from 'src/app/modelo/parceiroAtualizacao';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'parceiroAtualizacao',
  templateUrl: './parceiroAtualizacao.component.html',
})
export class ParceiroAtualizacaoComponent extends PaginaComponent {
  public idUsuario: number;
  public nomeUsuario: string;
  @Input() parceiro: Parceiro;
  @Input() parceiroAtualizacoes: ParceiroAtualizacao[] = [];
  @Input() excluirParceiroAtualizacoes: number[];

  adicionarAtualizacao(): void {
    this.idUsuario = this.utilSessao.getUsuario().id;
    this.nomeUsuario = this.utilSessao.getUsuario().nome;
    const parceiroAtualizacao: ParceiroAtualizacao = new ParceiroAtualizacao();
    parceiroAtualizacao.idParceiro = this.parceiro.id;
    this.parceiro.parceiroAtualizacoes.push(parceiroAtualizacao);
  }
}
