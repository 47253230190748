import { Component, HostListener, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Criterio } from 'src/app/modelo/criterio';

import { Equipe } from 'src/app/modelo/equipe';
import { EquipeParceiro } from 'src/app/modelo/equipeParceiro';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'equipeParceiro',
  templateUrl: './equipeParceiro.component.html',
})
export class EquipeParceiroComponent extends PaginaComponent {
  @Input() equipe: Equipe;
  @Input() excluirEquipeParceiros: number[] = [];
  @Input() edicaoEquipeParceiros: number[] = [];

  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public focoEquipeParceiro: number;
  public colaboradores: any[] = [];

  ngOnInit(): void {
    this.listarColaboradores();
  }  

  excluirEquipeParceiro(posicao: number, id: number): void {
    super.ehExcluir(posicao, this.equipe.equipeParceiros, this.excluirEquipeParceiros, id);
  }

  adicionar(): void {
    let equipeParceiro = new EquipeParceiro();
    this.equipe.equipeParceiros.push(equipeParceiro);
    this.focoEquipeParceiro = Math.random();
  }  

  editar(equipeParceiro: EquipeParceiro): void {
    if (this.edicaoEquipeParceiros.includes(equipeParceiro.id)) {
      const posicao: number = this.edicaoEquipeParceiros.indexOf(equipeParceiro.id, 0);
      this.edicaoEquipeParceiros.splice(posicao, 1);
    } else {
      this.edicaoEquipeParceiros.push(equipeParceiro.id);
    }
  }  

  setParceiro(equipeParceiro: EquipeParceiro, parceiro: Parceiro): void {
    equipeParceiro.idParceiro = parceiro.id;
    equipeParceiro.parceiro = parceiro.razaoSocial;
  }

  listarColaboradores(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('COLABORADOR', 'S'));
    criterios.push(new Criterio('VENDEDOR', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiro).subscribe((res) => {
      this.plainToClass(Parceiro, res).filter((colaborador) => colaborador.id != this.equipe.idGestor).map((colaborador) => {
        this.colaboradores.push({ id: colaborador.id, razaoSocial: colaborador.razaoSocial });
      });      
    });
  }  
}
