import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ComunicacaoNsService {
  private token: string = 'QUdST1cgU09GVFdBUkUgdnNWWmI='; //produção
  private url: string = 'https://nfe.ns.eti.br/';

  constructor(private http: HttpClient) { }

  private consumir(rota: string, parametros: any, metodo: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    if (metodo == 'POST') {
      return this.http.post(this.url + rota, parametros, { headers });
    }
  }

  persistir(url: string, parametros: any): Observable<any> {
    return this.consumir(url, parametros, 'POST');
  }

  consumirPdfXml(rota: string, parametros: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + rota, parametros, { headers });
  }

  listar(url: string, cnpj: string, idIntegracao: string): Observable<any> {
    const parametros = JSON.stringify({
      "X-AUTH-TOKEN": this.token,
      "CNPJ": cnpj,
      "nsNRec": idIntegracao,
    })
    return this.consumir(url, parametros, 'POST');
  }

  persistirCancelamento(url: string, parametros: any): Observable<any> {
    return this.consumir(url, parametros, 'POST');
  }
}
