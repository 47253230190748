import { Type } from 'class-transformer';

export class ParceiroAtualizacao {
  @Type(() => Date)
  public data: Date = new Date();

  public id: number;
  public idParceiro: number;
  public observacao: string;
  public nomeUsuario: string;
}
