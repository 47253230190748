<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(comissoes, $event)" (campoGrupoEmt)="agrupar(comissoes, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <tr>
        <th class="r-2">{{ bibDialogo.data }}</th>
        <th class="r">{{ bibDialogo.colaborador }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valor }}</th>
        <th class="r-1 text-right">{{ bibDialogo.status }}</th>
        <th class="r-1"></th>
      </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let comissao of comissoes; let i = index">
          <tr class="conteudo">
            <td>{{ comissao.dataMovimentacao | data }}</td>     
            <td>{{ comissao.nome }}</td>
            <td class="text-right">{{ comissao.valor | monetario }}</td>
            <td class="text-right">{{ comissao.status }}</td>
            <td class="text-right"><div class="naoImprimir"><btnAdicional [icone]="comissao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="this.expandir(comissao)"></btnAdicional></div></td>
          </tr>
          <tr *ngIf="comissao.expandido">
            <td colspan="5"><r2031detalhes [comissao]="comissao"></r2031detalhes></td>
          </tr>
        </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="comissoes.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>