<layoutFiltro *ngIf="filtroAutomatico == false">
  <div [ngClass]="idRelatorio ? '' : 'pt-2'">
    <h4 *ngIf="idRelatorio">{{ relatorioNome }}</h4>
  </div>
  <div class="card card-fluid p-2" *ngIf="filtros.length > 0">
    <div class="row">
      <ng-container *ngFor="let filtro of filtros; let i = index">
        <div class="col-sm-12 p-0 m-0" *ngIf="filtro.subordem == 1"></div>
        <texto [id]="filtro.id ? filtro.id : ''" *ngIf="filtro.tipo == 0 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor" (alteracao)="setFiltroValor(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [sufixo]="filtro.sufixo" [ajuda]="filtro.ajuda" [ajudaDireita]="true" [minimoCaracter]="filtro.nome == 'NOME_PRODUTO' ? 4 : 0" (navegacao)="focar($event, i)"></texto>
        <data *ngIf="filtro.tipo == 1 && filtro.visualizacaoInicial" [id]="filtro.id ? filtro.id : ''" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor" (alteracao)="setFiltroValor(filtro, $event != null ? $event : '')" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [sufixo]="filtro.sufixo" [sufixo]="filtro.sufixo" [desabilitado]="filtro.bloqueioInicial" [ajuda]="filtro.ajuda" [ajudaDireita]="true"></data>
        <busca [id]="filtro.id ? filtro.id : ''" *ngIf="filtro.tipo == 2 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.apresentacao" (alteracao)="setBusca(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [servico]="filtro.servicoWeb" [somenteAtivo]="false" [criterios]="filtro.criterios" [sufixo]="filtro.sufixo" [buscarPorNumero]="true" [ajudaDireita]="true" [ehFiltroVinculado]="filtro.valorVinculado != null ? true : false"></busca>
        <texto [id]="filtro.id ? filtro.id : ''" *ngIf="filtro.tipo == 3 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor" (alteracao)="setFiltroValor(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [tipo]="bibPropriedade.texto.inteiro" [sufixo]="filtro.sufixo" [desabilitado]="filtro.bloqueioInicial" [ajuda]="filtro.ajuda" [ajudaDireita]="true" (navegacao)="focar($event, i)"></texto>
        <lista [id]="filtro.id ? filtro.id : ''" *ngIf="(filtro.tipo == 4 || filtro.tipo == 5) && filtro.visualizacaoInicial" [class]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [ngClass]="{ 'bg-cinza-claro pb-2': filtro.nome == 'ORDENACAO' }" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro && filtro.valor ? filtro.valor : ''" (alteracao)="setFiltroValorLista(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [lista]="filtro.lista" [icone]="filtro.nome == 'ORDENACAO' ? (filtro.valor.indexOf(' DESC') >= 0 ? bibIcone.ordemDecrescente : bibIcone.ordemCrescente) : null" [sufixo]="filtro.sufixo" [ajuda]="filtro.ajuda" [ajudaDireita]="true" [apresentaDescricaoTodos]="true"></lista>
        <interruptor *ngIf="filtro.tipo == 8 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor" (alteracao)="setFiltroValor(filtro, $event == 'N' ? '' : 'S')" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [compacto]="false"></interruptor>
        <lista [id]="filtro.id ? filtro.id : ''" *ngIf="filtro.tipo == 9 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [compacto]="true" [filtroEmiteAlteracao]="false" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro && filtro.valor ? filtro.valor : ''" (alteracao)="setFiltroValorLista(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [tipo]="filtro.servicoWeb" [sufixo]="filtro.sufixo" [ajuda]="filtro.ajuda" [ajudaDireita]="true" [apresentaDescricaoTodos]="true"></lista>
        <checkLista *ngIf="filtro.tipo == 12 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" (alteracao)="setFiltroValor(filtro, $event)" [lista]="filtro.valor" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [tipo]="filtro.servicoWeb" [sufixo]="filtro.sufixo" [ajuda]="filtro.ajuda" [ajudaDireita]="true" [criterios]="filtro.criterios" [desabilitado]="filtro.bloqueioInicial"></checkLista>
        <div *ngIf="filtro.tipo == 10 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compacto && filtro.coluna != 12 ? '12' : filtro.coluna)">
          <div class="row">
            <lista [id]="filtro.id ? filtro.id : ''" *ngIf="filtro.servicoWeb == bibDialogo.cpf.toLowerCase() || filtro.servicoWeb == bibDialogo.cnpj.toLowerCase()" [ngClass]="compactoFiltro ? 'col-sm' : 'col-sm-4'" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="bibDialogo.tipoPessoa" [campo]="'-'" (alteracao)="setTipoPessoa(filtro, $event.id)" [lista]="tipos" [obrigatorio]="true" [apresentaDescricaoTodos]="true"></lista>
            <mascara [id]="filtro.id ? filtro.id : ''" class="col-sm" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor" (alteracao)="setFiltroValor(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [tipo]="filtro.servicoWeb"></mascara>
          </div>
        </div>
        <mascara *ngIf="filtro.tipo == 13" [id]="filtro.id ? filtro.id : ''" class="col-sm" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor" (alteracao)="setFiltroValor(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [tipo]="bibPropriedade.mascara.contabil"></mascara>
        <decimal *ngIf="filtro.tipo == 11 && filtro.visualizacaoInicial" [ngClass]="'col-sm-' + (compactoFiltro && filtro.coluna != 12 ? '6' : filtro.coluna)" [filtroEmiteAlteracao]="false" [compacto]="true" [rotulo]="filtro.idNomenclatura ? bibNomenclatura.escrever(filtro.idNomenclatura) + ' ' + (filtro.rotulo.indexOf('(De)') != -1 ? '(De)' : filtro.rotulo.indexOf('(Até)') != -1 ? '(Até)' : '') : filtro.rotulo" [campo]="filtro.valor ? filtro.valor : ''" (alteracao)="setFiltroValor(filtro, $event)" [obrigatorio]="filtro.obrigatorio == 'S'" [foco]="i == posicao" [focoId]="i == posicao ? focoId : null" [sufixo]="filtro.sufixo"></decimal>
      </ng-container>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="div">
        <botao [id]="'pesquisar'" (botaoEmt)="filtrar()" [compacto]="compactoFiltro" [fundo]="true" [legenda]="bibDialogo.pesquisar + ' ' + bibDialogo.f2" [tipo]="bibPropriedade.botao.sucesso"></botao>
        <botao (botaoEmt)="reiniciar()" [compacto]="compactoFiltro" [fundo]="true" [legenda]="bibDialogo.reiniciarFiltro" (saindo)="focoId = $event"></botao>
        <atencao *ngIf="filtroObrigatorio && !ehValidoFiltroAlemObrigatorio" [tipo]="bibPropriedade.atencao.alerta" [atencao]="this.quantidadeFiltroObrigatorio > 0 ? this.bibDialogo.preenchaUmFiltroAlemObrigatorio : this.bibDialogo.preenchaUmFiltro"></atencao>
      </div>
      <atencao [atencao]="bibDialogo.buscaParcial"></atencao>
    </div>
  </div>
</layoutFiltro>
