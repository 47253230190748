<td class="p-0" [colSpan]="8">
  <table class="pb-5">
    <tbody>
      <ng-container *ngFor="let conciliacao of conciliacoes; let i = index">
        <tr>
          <td class="r">{{ conciliacao.conta }}</td>
          <td class="r-1-5 text-right">{{ conciliacao.saldoReal | monetario }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="conciliacoes.length > 0">
      <tr>
        <td>{{ bibDialogo.saldoAtualReal }} {{ conciliacoes[0] ? ' em ' + (conciliacoes[0].dataAtual | data) : '' }}</td>
        <td class="borda-cima text-right relatorio-negrito">{{ saldoTotal | monetario }}</td>
      </tr>
    </tfoot>    
  </table>
</td>
