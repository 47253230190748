<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [subTitulo]="subTitulo" [idRelatorioInterno]="idRelatorioInterno" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <div class="page-section">
    <div *ngIf="dataAtualizacao" class="justify-content-end">
      <span class="oi oi-media-record pulse margem-alerta mr-1 text-success"></span>
      <span class="text-uppercase">{{ bibDialogo.atualizadoEm + ': ' + (dataAtualizacao | data: 'dd/MM/yyyy HH:mm') }} </span>
    </div>
    <div *ngIf="receitaDespesaBaixada && receitaDespesaAberta" class="section-block">
      <div class="metric-row">
        <div class="col-lg-12">
          <div class="metric-row metric-flush">
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.receita + ' ' }}<b>{{ bibDialogo.baixada.toLowerCase() }}</b>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaBaixada.receitaBaixadaHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaBaixada.receitaBaixadaMes | monetario)"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaBaixada.receitaBaixadaAno | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaBaixada.receitaBaixadaTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.receita + ' ' }}<b>{{ bibDialogo.aberta }}</b>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaAberta.receitaAbertaHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaAberta.receitaAbertaMes | monetario)"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaAberta.receitaAbertaAno | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaAberta.receitaAbertaTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.receita + ' ' }}<b>{{ bibDialogo.inadimplente + ' ' }}</b>
                  <ajuda [ajuda]="bibDialogo.cincoDiasAtraso"></ajuda>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaAberta.receitaAbertaInadimplenteHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaAberta.receitaAbertaInadimplenteMes | monetario)"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaAberta.receitaAbertaInadimplenteAno | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaAberta.receitaAbertaInadimplenteTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.receita + ' ' }}<b>{{ bibDialogo.aConciliar }}</b>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaBaixada.receitaBaixadaAguardandoHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaBaixada.receitaBaixadaAguardandoMes | monetario)"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaBaixada.receitaBaixadaAguardandoAno | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaBaixada.receitaBaixadaAguardandoTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div [hidden]="receitaBaixadaTipoTitulos == null" class="col-6">
        <div class="card card-fluid">
          <div class="card-body espaco-natureza">
            <h3 class="card-title mb-4">{{ bibDialogo.receitasBaixadasMesTipoTitulo }}</h3>
            <div class="chartjs">
              <canvas #receitaBaixadaTipoTituloRef id="receitaBaixadaTipoTituloGrafico">{{ receitaBaixadaTipoTituloGrafico }}</canvas>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="receitaInadimplenteParceiros == null" class="col-6">
        <div class="card card-fluid">
          <div class="card-body espaco-natureza">
            <h3 class="card-title mb-4">
              {{ bibDialogo.inadimplencia }}
              <ajuda [ajuda]="bibDialogo.cliqueBolaDetalhes"></ajuda>
            </h3>
            <div class="chartjs">
              <canvas #receitaInadimplenteParceiroRef id="receitaInadimplenteParceiroGrafico">{{ receitaInadimplenteParceiroGrafico }}</canvas>
            </div>
            <atencao *ngIf="this.posicaoReceitaInadimplente" [atencao]="receitaInadimplenteParceiros[this.posicaoReceitaInadimplente].parceiro"></atencao>
            <atencao *ngIf="this.posicaoReceitaInadimplente" [atencao]="bibDialogo.total + ' ' + bibDialogo.inadimplente + ' de ' + (receitaInadimplenteParceiros[this.posicaoReceitaInadimplente].valorRestante | monetario) + ' com ' + bibDialogo.atraso + ' ' + bibDialogo.maximo + ' de ' + receitaInadimplenteParceiros[this.posicaoReceitaInadimplente].maximoDiaAtraso + ' ' + bibDialogo.dias" [tipo]="bibPropriedade.atencao.informativo"></atencao>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="receitaDespesaBaixada && receitaDespesaAberta" class="section-block">
      <div class="metric-row">
        <div class="col-lg-12">
          <div class="metric-row metric-flush">
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.despesa + ' ' }}<b>{{ bibDialogo.baixada.toLowerCase() }}</b>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaBaixada.despesaBaixadaHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaBaixada.despesaBaixadaMes | monetario)" [tipo]="bibPropriedade.atencao.cuidado"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaBaixada.despesaBaixadaAno | monetario)" [tipo]="bibPropriedade.atencao.informativo"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaBaixada.despesaBaixadaTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.despesa + ' ' }}<b>{{ bibDialogo.aberta }}</b>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaAberta.despesaAbertaHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaAberta.despesaAbertaMes | monetario)" [tipo]="bibPropriedade.atencao.cuidado"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaAberta.despesaAbertaAno | monetario)" [tipo]="bibPropriedade.atencao.informativo"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaAberta.despesaAbertaTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.despesa + ' ' }}<b>{{ bibDialogo.inadimplente + ' ' }}</b>
                  <ajuda [ajuda]="bibDialogo.cincoDiasAtraso"></ajuda>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaAberta.despesaAbertaInadimplenteHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaAberta.despesaAbertaInadimplenteMes | monetario)" [tipo]="bibPropriedade.atencao.cuidado"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaAberta.despesaAbertaInadimplenteAno | monetario)" [tipo]="bibPropriedade.atencao.informativo"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaAberta.despesaAbertaInadimplenteTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
            <div class="col">
              <div class="metric metric-bordered align-items-center">
                <h1 class="metric-label">
                  {{ bibDialogo.despesa + ' ' }}<b>{{ bibDialogo.aConciliar }}</b>
                </h1>
                <p class="metric-value h3 pb-1">{{ receitaDespesaBaixada.despesaBaixadaAguardandoHoje | monetario }}</p>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaDespesaBaixada.despesaBaixadaAguardandoMes | monetario)" [tipo]="bibPropriedade.atencao.cuidado"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaDespesaBaixada.despesaBaixadaAguardandoAno | monetario)" [tipo]="bibPropriedade.atencao.informativo"></atencao>
                <atencao [atencao]="bibDialogo.total + ': ' + (receitaDespesaBaixada.despesaBaixadaAguardandoTotal | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div [hidden]="despesaContaContabeis == null" class="col-12">
        <div class="card card-fluid">
          <div class="card-body espaco-natureza">
            <h3 class="card-title mb-4">
              {{ bibDialogo.despesasMesContaContabil }}
              <ajuda [ajuda]="bibDialogo.abertas + ' e ' + bibDialogo.baixada.toLowerCase() + 's'"></ajuda>
            </h3>
            <div class="chartjs">
              <canvas #despesaNaturezaRef id="despesaNaturezaGrafico">{{ despesaNaturezaGrafico }}</canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div [hidden]="receitaDespesaAnual == null" class="col-12">
        <div class="card card-fluid">
          <div class="card-body espaco-receita-despesa-anual">
            <div class="mt-3 row align-items-center justify-content-between">
              <h3 class="card-title mb-4">{{ bibDialogo.receita + ' x ' + bibDialogo.despesa + ' ' + bibDialogo.anual }}</h3>
            </div>
            <div class="chartjs">
              <canvas #receitaDespesaAnualRef id="receitaDespesaAnualGrafico">{{ receitaDespesaAnualGrafico }}</canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="receitaBaixadaDesconto && saldo" class="section-block">
      <div class="metric-row">
        <div class="col-lg-12">
          <div class="metric-row metric-flush">
            <div class="col">
              <div>
                <div [ngClass]="receitaBaixadaDesconto.descontoValorHoje > 0 ? 'borda-esquerda-vermelha' : 'borda-esquerda-cinza'">
                  <h1 class="metric-label">
                    {{ bibDialogo.desconto + ' ' }}<b>{{ bibDialogo.concedido }}</b>
                  </h1>
                  <p class="metric-value h3 pb-1">{{ receitaBaixadaDesconto.descontoValorHoje | monetario }}</p>
                </div>
                <atencao [atencao]="bibDialogo.mes + ': ' + (receitaBaixadaDesconto.descontoValorMes | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
                <atencao [atencao]="bibDialogo.ano + ': ' + (receitaBaixadaDesconto.descontoValorAno | monetario)" [tipo]="bibPropriedade.atencao.informativo"></atencao>
              </div>
            </div>
            <div class="col">
              <div>
                <div [ngClass]="saldo.saldo > 0 ? 'borda-esquerda-azul' : 'borda-esquerda-vermelha'">
                  <h1 class="metric-label">
                    {{ bibDialogo.saldo + ' ' }}<b>{{ bibDialogo.atual }}</b>
                  </h1>
                  <p class="metric-value h3 pb-1">{{ saldo.saldo | monetario }}</p>
                </div>
                <atencao [atencao]="bibDialogo.caixa + ': ' + (saldo.saldoCaixa | monetario)"></atencao>
                <atencao [atencao]="bibDialogo.banco + ': ' + (saldo.saldoBanco | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
              </div>
            </div>
            <div class="col">
              <div>
                <div [ngClass]="saldo.saldo + receitaDespesaAberta.abertoHoje + receitaDespesaBaixada.aguardandoHoje > 0 ? 'borda-esquerda-azul' : 'borda-esquerda-vermelha'">
                  <h1 class="metric-label">
                    {{ bibDialogo.saldo + ' ' }}<b>{{ bibDialogo.previsto + ' ' }}</b>
                    <ajuda [ajuda]="bibDialogo.saldoPrevistoConta"></ajuda>
                  </h1>
                  <p class="metric-value h3 pb-1">{{ saldo.saldo + receitaDespesaAberta.abertoHoje + receitaDespesaBaixada.aguardandoHoje | monetario }}</p>
                </div>
                <atencao [atencao]="bibDialogo.final + ' ' + bibDialogo.mes + ': ' + (saldo.saldo + receitaDespesaAberta.abertoMes + receitaDespesaBaixada.aguardandoMes | monetario)" [tipo]="bibPropriedade.atencao.discreto"></atencao>
                <atencao [atencao]="bibDialogo.final + ' ' + bibDialogo.ano + ': ' + (saldo.saldo + receitaDespesaAberta.abertoAno + receitaDespesaBaixada.aguardandoAno | monetario)" [tipo]="bibPropriedade.atencao.informativo"></atencao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</relatorioLayout>
