import { Component, Input } from '@angular/core';
import { Bairro } from 'src/app/modelo/bairro';
import { Cep } from 'src/app/modelo/cep';
import { Cidade } from 'src/app/modelo/cidade';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { Regiao } from 'src/app/modelo/regiao';
import { Transporte } from 'src/app/modelo/transporte';
import { Vinculo } from 'src/app/modelo/vinculo';
import { PaginaComponent } from '../../utilitario/pagina.component';

@Component({
  selector: 'enderecoCep',
  templateUrl: './enderecoCep.component.html',
})
export class EnderecoCepComponent extends PaginaComponent {
  @Input() mostrarVinculo: boolean = true;
  @Input() objeto: any;
  @Input() tipo: string = '';
  @Input() tipoVinculo: string;
  @Input() focoCep: number;
  public buscaCep: boolean = false;
  public estados: Estado[];
  public focoNumero: number;
  public focoTipoEndereco: number;
  public vinculos: any[] = [];
  public lojaEmiteReceituarioEletronico: boolean = false;

  public rotuloBairro: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 2).apelido;
  public rotuloCidade: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 1).apelido;

  public tipoEnderecos: any[] = [
    { id: 'ACESSO', nome: 'ACESSO' },
    { id: 'ALAMEDA', nome: 'ALAMEDA' },
    { id: 'ÁREA', nome: 'ÁREA' },
    { id: 'AVENIDA', nome: 'AVENIDA' },
    { id: 'CHÁCARA', nome: 'CHÁCARA' },
    { id: 'COLÔNIA', nome: 'COLÔNIA' },
    { id: 'CONDOMÍNIO', nome: 'CONDOMÍNIO' },
    { id: 'EQNP', nome: 'EQNP' },
    { id: 'ESTRADA', nome: 'ESTRADA' },
    { id: 'ESTÂNCIA', nome: 'ESTÂNCIA' },
    { id: 'FAZENDA', nome: 'FAZENDA' },
    { id: 'GLEBA', nome: 'GLEBA' },
    { id: 'LADEIRA', nome: 'LADEIRA' },
    { id: 'POVOADO', nome: 'POVOADO' },
    { id: 'PRAÇA', nome: 'PRAÇA' },
    { id: 'PROLONGAMENTO', nome: 'PROLONGAMENTO' },
    { id: 'QUINHÃO', nome: 'QUINHÃO' },
    { id: 'RODOVIA', nome: 'RODOVIA' },
    { id: 'RUA', nome: 'RUA' },
    { id: 'SÍTIO', nome: 'SÍTIO' },
    { id: 'TRAVESSA', nome: 'TRAVESSA' },
    { id: 'VICINAL', nome: 'VICINAL' },
    { id: 'LOTE', nome: 'LOTE' },
    { id: 'CÓRREGO', nome: 'CÓRREGO' },
  ];

  ngOnInit(): void {
    this.lojaEmiteReceituarioEletronico = this.utilSessao.getLojas().filter((loja) => (loja.estado == 'ES')).length > 0 ? true : false;
    this.listarVinculo();
    this.listarEstado();
    this.ehSetFoco();
    this.objeto.idVinculo = this.objeto.idVinculo != null ? this.objeto.idVinculo : 3;
  }

  ehSetFoco(): void {
    if (this.objeto.id > 0) {
      this.focoTipoEndereco = Math.random();
    } else {
      this.focoCep = Math.random();
    }
  }

  listarVinculo(): void {
    let criterios: Criterio[] = [];
    this.setCriterios(criterios);
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.vinculo).subscribe((res) => {
      this.vinculos = this.plainToClass(Vinculo, res) as any;
    });
  }

  setCriterios(criterios: Criterio[]): void {
    if (this.tipoVinculo) {
      criterios.push(new Criterio('TIPO', this.tipoVinculo));
    }
    criterios.push(new Criterio('ENDERECO', 'S'));
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  setBairro(bairro: Bairro): void {
    this.objeto.idBairro = bairro.id;
    this.objeto.bairro = bairro.nome;
  }

  setCidade(cidade: Cidade): void {
    this.objeto.idCidade = cidade.id;
    this.objeto.cidade = cidade.nome;
    this.objeto.idEstado = cidade.idEstado;
  }

  setRegiao(regiao: Regiao): void {
    this.objeto.idRegiao = regiao.id;
    this.objeto.regiao = regiao.nome;
  }

  setCep(cep: string): void {
    this.objeto.cep = cep;
    if (this.objeto.cep.length == 9) {
      this.listarCep();
      this.buscaCep = true;
    }
  }

  listarCep(): void {
    if (this.objeto) {
      if (this.buscaCep) {
        if (this.objeto.cep) {
          if (this.objeto.cep.length == 9) {
            this.comunicacaoService.listar(new Transporte(new Criterio('CEP', this.objeto.cep)), this.bibServico.buscarCEP, false, true, true, true, 120000, false, this.bibDialogo.webServiceCepIndisponivel).subscribe((res) => {
              if (res[0]) {
                const cep: Cep = this.plainToClass(Cep, res[0]) as any;
                this.objeto.idBairro = cep.idBairro;
                this.objeto.idCidade = cep.idCidade;
                this.objeto.idEstado = cep.idEstado;
                this.objeto.bairro = cep.bairro;
                this.objeto.cidade = cep.cidade;
                this.objeto.estado = cep.estado;
                this.objeto.endereco = cep.endereco.trim();
                this.objeto.enderecoTipo = cep.tipoEndereco;
                this.objeto.tipo = cep.tipoEndereco;
                this.buscaCep = false;
                this.focoNumero = Math.random();
              } else {
                this.focoTipoEndereco = Math.random();
              }
            });
          }
        }
      }
    }
  }

  setTipoEndereco(tipoEndereco: any): void {
    this.objeto.enderecoTipo = tipoEndereco.id;
    this.objeto.tipo = tipoEndereco.id;
  }

}
