import { Transportador } from './transportador';
import { Veiculo } from './veiculo';
import { Volume } from './volume';

export class TransporteTecnoSpeed {
  public modalidadeFrete: string;
  public transportador: Transportador = new Transportador();
  public veiculo: Veiculo = new Veiculo();
  public volumes: Volume[] = [];
}
