<titulo [id]="'adicionarTelefone'" [titulo]="bibDialogo.telefone" [imagem]="bibImagem.telefone" [icone]="bibIcone.mais" (iconeEmt)="abrirModal()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.telefone.toLowerCase()" [desabilitado]="temPermissaoEdicao || temPermissaoInserir ? false : true"></titulo>
<div class="row" *ngIf="_parceiroTelefones.length == 0">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.telefone.toLowerCase() + ', ' }} <strong class="clique" [id]="'adicionarTelefone'" (click)="abrirModal()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table class="table table-responsive-sm" *ngIf="_parceiroTelefones.length > 0">
  <thead>
    <tr>
      <th class="l-20">{{ bibDialogo.telefone }}</th>
      <th class="l-20">{{ bibDialogo.tipoDeTelefone }}</th>
      <th class="l-50">{{ bibDialogo.observacao }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{ tachado: parceiroTelefone.ativo != 'S' }" *ngFor="let parceiroTelefone of _parceiroTelefones; let i = index">
      <td *ngIf="parceiroTelefone.telefone != null">{{ parceiroTelefone.telefone }}</td>
      <td *ngIf="parceiroTelefone.telefone == null" class="limitar"><atencao [atencao]="bibDialogo.numeroTelefoneObrigatorio" [tipo]="bibPropriedade.atencao.cuidado"></atencao></td>
      <td>{{ parceiroTelefone.vinculo }}</td>
      <td class="limitar">{{ parceiroTelefone.observacao }}</td>
      <td>
        <btnAdicional [id]="'editarTelefone' + i" [icone]="bibIcone.editar" (btnAdicional)="abrirModal(parceiroTelefone)"></btnAdicional>
      </td>
      <td>
        <btnAdicional [id]="'excluirTelefone' + i" [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this._parceiroTelefones, this.excluirTelefones, parceiroTelefone.id)"></btnAdicional>
      </td>
    </tr>
  </tbody>
</table>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>