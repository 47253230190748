import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'DATA_INICIO', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_FIM', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.texto },    
  { rotulo: bibDialogo.nome, nome: 'NOME', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
]);
export default filtros;
