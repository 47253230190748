import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroValorRestante: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'A PAGAR', id: ' > 0' },
  { nome: 'PAGOS', id: ' <= 0' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataNegociacaoDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataNegociacaoAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.conta, nome: 'ID_CONTA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
  { rotulo: bibDialogo.tipoTitulo, nome: 'ID_TIPO_TITULO', coluna: 6, servicoWeb: bibServico.tipoTitulo, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.situacao, nome: 'VALOR_RESTANTE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: ' > 0', lista: filtroValorRestante },
]);
export default filtros;
