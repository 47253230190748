<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-55 clique" (click)="listar(add('razaoSocial'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.cliente }}</th>
        <th class="l-15 clique" (click)="listar(add('limiteMaximo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.limiteMaximo }}</th>
        <th *ngIf="empresaLimiteCreditoAutomatico" class="l-15 clique" (click)="listar(add('limiteCredito'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.limiteCredito }}</th>
        <th class="l-15 clique" (click)="listar(add('limiteDisponivel'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.limiteDisponivel }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parceiro of parceiros; let i = index">
        <tr [ngClass]="{ tachado: parceiro.ativo != 'S' }">
          <td class="limitar cliqueLink">
            <a (click)="abrirModalCliente(parceiro.id)">{{ parceiro.numero + ' - ' + parceiro.razaoSocial }}</a>
          </td>
          <td><decimal [campo]="parceiro.receitaMensal" [id]="bibDialogo.rendaMensal" (alteracao)="utilLimiteCredito.setReceitaMensal(parceiro, $event)" [desabilitado]="true"> </decimal></td>
          <td *ngIf="empresaLimiteCreditoAutomatico"><decimal [id]="'limiteCredito'" [campo]="parceiro.limiteCredito" (alteracao)="utilLimiteCredito.setLimiteCredito(parceiro, $event, parceiroLimites)" [desabilitado]="empresa.limiteCredito == 2"></decimal></td>
          <td><decimal [campo]="parceiro.limiteDisponivel" [id]="'limiteDisponivel'" (alteracao)="utilLimiteCredito.setLimiteCreditoDisponivel(parceiro, $event, parceiroLimites)" [desabilitado]="empresa.limiteCredito == 1"></decimal></td>
          <td><btnAdicional [icone]="bibIcone.historicoCredito" [ajuda]="bibDialogo.visualizarHistorico" (btnAdicional)="abrirModalHistorico(parceiro)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.olho" [ajuda]="bibDialogo.historicoCompra" (btnAdicional)="vincularParceiroHistorico(parceiro.id)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<div *ngIf="visualizaHistorico" class="footer">
  <div class="row">
    <div class="col-sm-2 text-right">{{ bibDialogo.debitosAtrasado + ': ' }} {{ (parceiroHistorico && parceiroHistorico.receitaAtrasada ? parceiroHistorico.receitaAtrasada : 0) | monetario }}</div>
    <div class="col-sm-3 text-right">{{ bibDialogo.debitosVencer + ': ' }} {{ (parceiroHistorico && parceiroHistorico.receitaVencer ? parceiroHistorico.receitaVencer : 0) | monetario }}</div>
    <div class="col-sm-3 text-right">{{ bibDialogo.totalAberto + ': ' }} {{ (parceiroHistorico && parceiroHistorico.despesasAbertas ? parceiroHistorico.despesasAbertas : 0) | monetario }}</div>
    <div class="col-sm-3 text-right">{{ bibDialogo.credito + ': ' }} {{ (parceiroHistorico && parceiroHistorico.lucroAno ? parceiroHistorico.lucroAno : 0) | monetario }}</div>
  </div>
  <div class="row">
    <div class="col-sm-2 text-right">{{ bibDialogo.mediaAtraso + ': ' }} {{ (parceiroHistorico && parceiroHistorico.mediaAtraso ? parceiroHistorico.mediaAtraso : 0) + ' ' + bibDialogo.dias }}</div>
    <div class="col-sm-3 text-right">{{ bibDialogo.atrasoMaximo + ': ' }} {{ (parceiroHistorico && parceiroHistorico.atrasoMaximo ? parceiroHistorico.atrasoMaximo : 0) + ' ' + bibDialogo.dias }}</div>
    <div class="col-sm-3 text-right">{{ bibDialogo.dataUltCompra + ': ' }} {{ (parceiroHistorico && parceiroHistorico.dataUltimaNegociacao ? parceiroHistorico.dataUltimaNegociacao : null) | data }}</div>
    <div class="col-sm-3 text-right">{{ bibDialogo.totalComprasUltimoAno + ': ' }} {{ (parceiroHistorico && parceiroHistorico.receitaAno ? parceiroHistorico.receitaAno : 0) | monetario }}</div>
  </div>
</div>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirParceiros()" [desabilitado]="!temPermissaoEdicao"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
