import { Cidade } from 'src/app/modelo/cidade';
import { Component } from '@angular/core';
import { Estado } from 'src/app/modelo/estados';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Resultado } from 'src/app/modelo/resultado';

@Component({
  templateUrl: './cidadeFrm.component.html',
})
export class CidadeFrmComponent extends PaginaComponent {
  public cidade: Cidade = new Cidade();
  public estados: Estado[];

  ngOnInit(): void {
    this.listarEstado();
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.cidade = this.plainToClass(Cidade, this.ehAtualizacao(this.cidade));
    if (this.cidade.id) {
      this.listarCidade(this.cidade.id);
    }
  }

  listarEstado(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.estado).subscribe((res) => {
      this.estados = this.plainToClass(Estado, res) as any;
    });
  }

  listarCidade(id: number): void {
    this.comunicacaoService.listarLog(new Transporte(new Criterio('ID', id)), this.bibServico.cidade).subscribe((res) => {
      this.cidade = this.plainToClass(Cidade, res[0]) as any;
    });
  }

  persistirCidade(novo: boolean = false): void {
    if (this.ehValido()) {
      super.persistir(new Transporte(this.cidade, 0, 0, false, null, this.bibDialogo.backendW), this.bibServico.cidade, novo ? new Cidade() : null);
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.ehValidoObrigatorio();
    const ehValidoIBGE: boolean = ehValidoObrigatorio && this.ehValidoIBGE();
    return ehValidoObrigatorio && ehValidoIBGE;
  }

  ehValidoObrigatorio(): boolean {
    if (this.cidade.nome && this.cidade.idEstado && this.cidade.ibge) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoIBGE(): boolean {
    if (this.cidade.ibge.toString().length == 7) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.ibgeSeteCarateres));
    return false;
  }

  abrirSiteIBGE(): void {
    if (this.cidade.nome && this.cidade.idEstado) {
      let nome: string = this.removerAcento(this.cidade.nome);
      while (nome.indexOf(' ') !== -1) {
        nome = nome.replace(' ', '-');
      }
      const abreviacao: string = this.estados.find((estado) => estado.id == this.cidade.idEstado).abreviacao.toLocaleLowerCase();
      window.open('https://cidades.ibge.gov.br/brasil/' + abreviacao + '/' + nome + '/panorama');
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeCidadeEstado));
    }
  }

  removerAcento(nome) {
    nome = nome.toLowerCase();
    nome = nome.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    nome = nome.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    nome = nome.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    nome = nome.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    nome = nome.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    nome = nome.replace(new RegExp('[Ç]', 'gi'), 'c');
    return nome;
  }
}
