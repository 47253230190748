import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import bibServico from 'src/app/biblioteca/bibServico';

const filtroComboAtivoCidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'SIM', id: 'S' },
  { nome: 'NÃO', id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'estado', rotulo: bibDialogo.estado, nome: 'ID_ESTADO', coluna: 6, servicoWeb: bibServico.estado, tipo: bibPropriedade.filtro.busca },
  { id: 'ativo', rotulo: bibDialogo.ativo, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivoCidade },
  { id: 'ibge', rotulo: bibDialogo.ibge, nome: 'IBGE', coluna: 6, tipo: bibPropriedade.filtro.numero },
]);
export default filtros;
