import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroFinanceiro: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.nao, id: "'N'" },
  { nome: bibDialogo.sim, id: "'R', 'D'" },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numeroMovimentacaoAbreviacao, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.produtoDescricao, nome: 'PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.texto },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.negociacao, nome: 'ID_NEGOCIACAO', coluna: 6, servicoWeb: bibServico.negociacao, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.operacao, nome: 'IDS_OPERACAO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('OPERACAO_GERA_LUCRO', 'S')] },
  { rotulo: bibDialogo.centroResultado, nome: 'IDS_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.gerouFinanceiro, nome: 'FINANCEIRO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.lista, valor: '-', lista: filtroFinanceiro },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
]);
export default filtros;
