<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="posicao" [paginacao]="paginacao" [numeroRegistro]="objetos.length"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-90 clique" (click)="listar(add('nome'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.nome }}</th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let objeto of objetos; let i = index">
        <tr>
          <td class="limitar">{{ objeto.nome.split(' ').join('&nbsp;') }}</td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirObjeto(objeto.id, objeto.nome)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
