import format from 'date-fns/format';
import { Observable } from 'rxjs';
import { bibServicoNs } from 'src/app/biblioteca/bibServicoNs';
import { Cobr } from 'src/app/integracao/ns/nfe/cobr';
import { Cofins } from 'src/app/integracao/ns/nfe/cofins';
import { CofinsAliq } from 'src/app/integracao/ns/nfe/cofinsAliq';
import { CofinsNt } from 'src/app/integracao/ns/nfe/cofinsNt';
import { CofinsOutr } from 'src/app/integracao/ns/nfe/cofinsOutr';
import { CofinsQtde } from 'src/app/integracao/ns/nfe/cofinsQtde';
import { Dest } from 'src/app/integracao/ns/nfe/dest';
import { Det } from 'src/app/integracao/ns/nfe/det';
import { DetPag } from 'src/app/integracao/ns/nfe/detPag';
import { Dup } from 'src/app/integracao/ns/nfe/dup';
import { Emit } from 'src/app/integracao/ns/nfe/emit';
import { EnderDest } from 'src/app/integracao/ns/nfe/enderDest';
import { EnderEmit } from 'src/app/integracao/ns/nfe/enderEmit';
import { Fat } from 'src/app/integracao/ns/nfe/fat';
import { Icms } from 'src/app/integracao/ns/nfe/icms';
import { Icms00 } from 'src/app/integracao/ns/nfe/icms00';
import { Icms10 } from 'src/app/integracao/ns/nfe/icms10';
import { Icms20 } from 'src/app/integracao/ns/nfe/icms20';
import { Icms30 } from 'src/app/integracao/ns/nfe/icms30';
import { Icms40 } from 'src/app/integracao/ns/nfe/icms40';
import { Icms51 } from 'src/app/integracao/ns/nfe/icms51';
import { Icms60 } from 'src/app/integracao/ns/nfe/icms60';
import { Icms70 } from 'src/app/integracao/ns/nfe/icms70';
import { Icms90 } from 'src/app/integracao/ns/nfe/icms90';
import { IcmsTot } from 'src/app/integracao/ns/nfe/icmsTot';
import { Icmssn101 } from 'src/app/integracao/ns/nfe/icmssn101';
import { Icmssn102 } from 'src/app/integracao/ns/nfe/icmssn102';
import { Icmssn103 } from 'src/app/integracao/ns/nfe/icmssn103';
import { Icmssn201 } from 'src/app/integracao/ns/nfe/icmssn201';
import { Icmssn202 } from 'src/app/integracao/ns/nfe/icmssn202';
import { Icmssn203 } from 'src/app/integracao/ns/nfe/icmssn203';
import { Icmssn300 } from 'src/app/integracao/ns/nfe/icmssn300';
import { Icmssn400 } from 'src/app/integracao/ns/nfe/icmssn400';
import { Icmssn500 } from 'src/app/integracao/ns/nfe/icmssn500';
import { Icmssn900 } from 'src/app/integracao/ns/nfe/icmssn900';
import { Ide } from 'src/app/integracao/ns/nfe/ide';
import { Imposto } from 'src/app/integracao/ns/nfe/imposto';
import { InfAdic } from 'src/app/integracao/ns/nfe/infAdic';
import { Ipi } from 'src/app/integracao/ns/nfe/ipi';
import { IpiNt } from 'src/app/integracao/ns/nfe/ipiNt';
import { IpiTrib } from 'src/app/integracao/ns/nfe/ipiTrib';
import { NFref } from 'src/app/integracao/ns/nfe/nfRef';
import { NfeNs } from 'src/app/integracao/ns/nfe/nfe';
import { Obs } from 'src/app/integracao/ns/nfe/obs';
import { ObsCont } from 'src/app/integracao/ns/nfe/obsCont';
import { Pag } from 'src/app/integracao/ns/nfe/pag';
import { Pis } from 'src/app/integracao/ns/nfe/pis';
import { PisAliq } from 'src/app/integracao/ns/nfe/pisAliq';
import { PisNt } from 'src/app/integracao/ns/nfe/pisNt';
import { PisOutr } from 'src/app/integracao/ns/nfe/pisOutr';
import { PisQtde } from 'src/app/integracao/ns/nfe/pisQtde';
import { Prod } from 'src/app/integracao/ns/nfe/prod';
import { RetTrib } from 'src/app/integracao/ns/nfe/retTrib';
import { Total } from 'src/app/integracao/ns/nfe/total';
import { Transp } from 'src/app/integracao/ns/nfe/transp';
import { Transporta } from 'src/app/integracao/ns/nfe/transporta';
import { Vol } from 'src/app/integracao/ns/nfe/vol';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Cst } from 'src/app/modelo/cst';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoChave } from 'src/app/modelo/movimentacaoChave';
import { MovimentacaoNfe } from 'src/app/modelo/movimentacaoNfe';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { ComunicacaoNsService } from 'src/app/servico/comunicacaoNs.service';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { UtilDocumentoEletronico } from './utilDocumentoEletronico';
import { endOfDay } from 'date-fns';

export class UtilDocumentoEletronicoNs {

  constructor(public comunicacaoService: ComunicacaoService, public utilSessao: UtilSessao, public util: Util, public comunicacaoNsService: ComunicacaoNsService, public bibServicoNs: bibServicoNs, public bibDialogo, public bibServico, public bibClasse, public utilDocumentoEletronico: UtilDocumentoEletronico) { }

  private razaoSocialHomologacao: string = 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL';
  public ehConsumidorFinalNfce: boolean = false;
  public enviarIbpt: boolean = true;
  public tipoContingencia: string;

  verificarTipoContingencia(loja: Loja): void {
    let svc_an = ['AC', 'AL', 'AP', 'CE', 'DF', 'ES', 'MG', 'PA', 'PB', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];
    if (svc_an.includes(loja.estado)) {
      this.tipoContingencia = "6";
    } else {
      this.tipoContingencia = "7";
    }
  }

  transformarMovimentacaoEmNfe(movimentacao: Movimentacao): NfeNs {
    const nfe: NfeNs = new NfeNs();
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    this.verificarTipoContingencia(loja);
    nfe.ide = this.gerarIdentificacao(movimentacao, loja);
    nfe.emit = this.gerarEmitente(movimentacao, loja);
    nfe.dest = this.gerarDestinatario(movimentacao.idParceiro, movimentacao.idParceiroDestino, movimentacao.idLoja);
    nfe.det = this.gerarDetalhes(movimentacao.id, movimentacao.idParceiro, movimentacao.idParceiroDestino);
    nfe.total = this.gerarTotal(movimentacao, nfe.det);
    nfe.transp = this.gerarTransporte(movimentacao);
    nfe.cobr = this.gerarCobranca(movimentacao);
    nfe.pag = this.gerarPagamento(movimentacao);
    nfe.infAdic = this.gerarInformacaoAdicional(movimentacao);
    return nfe;
  }

  private montarDataHoraEntradaSaida(data: Date, hora: string): Date {
    this.utilSessao.mensagemProcessandoNFe('Data hora');
    const horas: string[] = hora.split(':');
    const ano: number = data.getFullYear();
    const mes: number = data.getMonth();
    const dia: number = data.getDate();
    return new Date(ano, mes, dia, Number(horas[0]), Number(horas[1]), Number(horas[2]), 0);
  }

  gerarIdentificacao(movimentacao: Movimentacao, loja: Loja, contingencia: boolean = false): Ide {
    const ide: Ide = new Ide();
    ide.cUF = loja.estadoIbge.toString();
    ide.natOp = movimentacao.naturezaOperacao;
    ide.mod = movimentacao.consumidorFinal == 'S' ? '65' : '55';
    ide.serie = loja.serie;
    ide.nNF = movimentacao.numero.toString();
    ide.dhEmi = format(movimentacao.data, "yyyy-MM-dd'T'hh:mm:ss'-03:00'");
    ide.dhSaiEnt = format(movimentacao.dataEntradaSaida, "yyyy-MM-dd") + 'T' + movimentacao.horaSaida + '-03:00';
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiro = this.utilDocumentoEletronico.parceiros.find((parceiro) => parceiro.id == movimentacao.idParceiro);
    const parceiroEndereco: any = movimentacao.idParceiroDestino ? this.utilDocumentoEletronico.parceiroDestinos.find((parceiroDestino) => (parceiroDestino.idParceiro == parceiro.id)) : this.utilDocumentoEletronico.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.utilDocumentoEletronico.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    const INTERNA: string = "1";
    const INTERESTADUAL: string = "2";
    ide.idDest = estado.abreviacao != loja.estado ? INTERESTADUAL : INTERNA;
    const NOTA_FISCAL: boolean = movimentacao.idOperacao == 3;
    const PEDIDO_COM_NOTA_FISCAL: boolean = movimentacao.idOperacao == 20;
    ide.tpNF = (NOTA_FISCAL || PEDIDO_COM_NOTA_FISCAL) ? "1" : "0";
    const NFCE: boolean = movimentacao.modelo == "65";
    ide.tpImp = NFCE ? "4" : "1";
    ide.tpEmis = contingencia ? this.tipoContingencia : "1";
    ide.cMunFG = loja.cidadeIbge;
    ide.cDV = "";
    ide.tpAmb =
      ide.finNFe = this.gerarFinalidadeNfe(movimentacao.naturezaOperacaoFinalidade);
    ide.procEmi = "0"
    ide.verProc = "3.0|NS_API";
    contingencia ? ide.dhCont = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'-03:00'") : "";
    contingencia ? ide.xJust = "SERVIDOR FORA DO AR" : "";
    ide.indFinal = movimentacao.consumidorFinal ? "1" : "0";
    ide.indPres = "1";
    movimentacao.movimentacaoChaves?.length > 0 ? ide.NFref = this.gerarNfeReferenciada(movimentacao.movimentacaoChaves) : "";
    return ide;
  }

  gerarFinalidadeNfe(naturezaOperacao: number): string {
    const NORMAL = "1";
    const COMPLEMENTAR = "2";
    const AJUSTE = "3";
    const DEVOLUCAO = "4";
    switch (naturezaOperacao) {
      case 1:
        return NORMAL;
      case 2:
        return NORMAL;
      case 3:
        return DEVOLUCAO;
      case 4:
        return COMPLEMENTAR;
      case 5:
        return AJUSTE;
    }
  }

  gerarNfeReferenciada(movimentacaoChaves: MovimentacaoChave[]): NFref {
    const nfRef: NFref = new NFref();
    movimentacaoChaves.forEach((movimentacaoChave) => {
      nfRef.refNFe = movimentacaoChave.chave;
    })
    return nfRef;
  }

  gerarEmitente(movimentacao: Movimentacao, loja: Loja): Emit {
    const emit: Emit = new Emit();
    emit.CNPJ = this.util.retirarCaracteresInvalidos(loja.cnpj);
    emit.xNome = this.getAmbiente(loja.id) == '1' ? loja.razaoSocial : this.razaoSocialHomologacao;
    emit.IE = loja.inscricaoEstadual;
    const SIMPLES_NACIONAL: number = 1;
    const SIMPLES_NACIONAL_EXCESSO: number = 4;
    emit.CRT = loja.enquadramento == SIMPLES_NACIONAL ? "1" : loja.enquadramento == SIMPLES_NACIONAL_EXCESSO ? '2' : '3';
    emit.enderEmit = this.gerarEnderecoEmitente(loja);
    return emit;
  }

  gerarEnderecoEmitente(loja: Loja): EnderEmit {
    const enderEmit: EnderEmit = new EnderEmit();
    enderEmit.xLgr = loja.enderecoTipo + ' ' + loja.endereco;
    enderEmit.nro = loja.enderecoNumero.toString();
    enderEmit.xCpl = loja.enderecoComplemento;
    enderEmit.xBairro = loja.bairro;
    enderEmit.cMun = loja.cidadeIbge;
    enderEmit.xMun = loja.cidade;
    enderEmit.UF = loja.estado;
    enderEmit.CEP = loja.enderecoCep ? loja.enderecoCep.replace('-', '') : null;
    enderEmit.fone = loja.telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    return enderEmit;
  }

  gerarDestinatario(idParceiro: number, idParceiroDestino: number, idLoja: number): Dest {
    this.utilSessao.mensagemProcessandoNFe('Destinatário');
    const dest: Dest = new Dest();
    const parceiro = this.utilDocumentoEletronico.parceiros.find((parceiro) => parceiro.id == idParceiro);
    parceiro.parceiroDestinos = this.utilDocumentoEletronico.parceiroDestinos.filter((parceiroDestino) => parceiroDestino.id == idParceiroDestino);
    const cnpjCpf = this.util.retirarCaracteresInvalidos(idParceiroDestino ? parceiro.parceiroDestinos[0].cnpjCpf ? parceiro.parceiroDestinos[0].cnpjCpf : parceiro.cnpjCpf : parceiro.cnpjCpf);
    cnpjCpf.length > 11 ? dest.CNPJ = cnpjCpf : dest.CPF = cnpjCpf
    dest.xNome = this.ehConsumidorFinalNfce ? '' : this.getAmbiente(idLoja) == '1' ? parceiro.razaoSocial.substring(0, 59) : this.razaoSocialHomologacao;
    dest.indIEDest = this.ehConsumidorFinalNfce ? null : parceiro.contribuinte.toString();
    const NAO_CONTRIBUINTE: string = '9';
    dest.indIEDest == NAO_CONTRIBUINTE ? '' : dest.IE = this.ehConsumidorFinalNfce ? '' : idParceiroDestino ? parceiro.parceiroDestinos[0].inscricaoEstadual.split('.').join('') : parceiro.inscricaoEstadual.split('.').join('');
    dest.email = this.ehConsumidorFinalNfce ? '' : parceiro.email.substring(0, 60);
    dest.enderDest = this.gerarEnderecoDestinatario(parceiro, idParceiroDestino);
    return dest;
  }

  gerarEnderecoDestinatario(parceiro: Parceiro, idParceiroDestino: number): EnderDest {
    const enderDest: EnderDest = new EnderDest();
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiroEndereco: any = idParceiroDestino ? this.utilDocumentoEletronico.parceiroDestinos.find((parceiroDestino) => (parceiroDestino.id == idParceiroDestino)) : this.utilDocumentoEletronico.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.utilDocumentoEletronico.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    enderDest.xLgr = (idParceiroDestino ? parceiro.parceiroDestinos[0].nome + ' - ' : '') + parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco;
    enderDest.nro = parceiroEndereco.numero;
    enderDest.xBairro = parceiroEndereco.bairro;
    enderDest.cMun = parceiroEndereco.cidadeIbge;
    enderDest.xMun = parceiroEndereco.cidade;
    enderDest.UF = estado.abreviacao;
    enderDest.CEP = parceiroEndereco.cep.replace('-', '');
    return enderDest;
  }

  gerarDetalhes(idMovimentacao: number, idParceiro: number, idParceiroDestino: number): Det[] {
    const dets: Det[] = [];
    const movimentacaoProdutos = this.utilDocumentoEletronico.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == idMovimentacao);
    movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
      const det: Det = new Det();
      det.prod = this.gerarProduto(movimentacaoProduto);
      det.imposto = this.gerarImposto(movimentacaoProduto, idParceiro, idParceiroDestino);
      det.nItem = (i + 1).toString();
      const infomacaoComplementarProduto = this.utilDocumentoEletronico.gerarInformacaoComplementarProduto(movimentacaoProduto).replace('\n', '');
      infomacaoComplementarProduto ? det.infAdProd = infomacaoComplementarProduto : '';
      dets.push(det);
    })
    return dets;
  }

  gerarProduto(movimentacaoProduto: MovimentacaoProduto): Prod {
    const prod: Prod = new Prod();
    const produto: Produto = this.utilDocumentoEletronico.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
    const cfopEmpresa: CfopEmpresa = this.utilDocumentoEletronico.cfopEmpresas.find((cfopEmpresaBusca) => cfopEmpresaBusca.id == movimentacaoProduto.idCfopEmpresa);
    prod.cProd = produto.numero.toString();
    prod.xProd = produto.nomeFiscal;
    prod.NCM = produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim();
    produto.cest ? prod.CEST = produto.cest.toString() : '';
    prod.CFOP = cfopEmpresa.idCfop.toString();
    prod.uCom = movimentacaoProduto.abreviacao;
    prod.qCom = movimentacaoProduto.quantidade.toString();
    prod.vUnCom = movimentacaoProduto.getValorUnitario().toString();
    prod.vProd = ((movimentacaoProduto.valorTotal + movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado) + (movimentacaoProduto.getQuantidade() * movimentacaoProduto.descontoValorUnitario)).toFixed(2).toString();
    prod.vDesc = (movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado) > 0 ? (movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado).toFixed(2).toString() : null;
    if (movimentacaoProduto.descontoValorUnitario && movimentacaoProduto.descontoValorUnitario > 0) {
      prod.vDesc = (movimentacaoProduto.descontoValorUnitario * movimentacaoProduto.quantidade).toFixed(2).toString();
    }
    prod.vFrete = movimentacaoProduto.freteRateado > 0 ? movimentacaoProduto.freteRateado.toFixed(2).toString() : null;
    prod.vUnTrib = prod.vUnCom;
    prod.uTrib = prod.uCom;
    prod.qTrib = prod.qCom;
    prod.indTot = "1";
    prod.cEAN = produto.codigoBarra ? produto.codigoBarra : 'SEM GTIN';
    prod.cEANTrib = produto.codigoBarra ? produto.codigoBarra : 'SEM GTIN';
    (movimentacaoProduto.outroRateado + movimentacaoProduto.despesaRateado) > 0 ? prod.vOutro = (movimentacaoProduto.outroRateado + movimentacaoProduto.despesaRateado).toFixed(2).toString() : '';
    return prod;
  }

  gerarImposto(movimentacaoProduto: MovimentacaoProduto, idParceiro: number, idParceiroDestino: number): Imposto {
    const imposto = new Imposto();
    imposto.ICMS = this.gerarIcms(movimentacaoProduto);
    imposto.IPI = this.gerarIpi(movimentacaoProduto);
    imposto.COFINS = this.gerarCofins(movimentacaoProduto);
    imposto.PIS = this.gerarPis(movimentacaoProduto);
    imposto.vTotTrib = this.enviarIbpt ? this.util.arredondar(movimentacaoProduto.ibptEstadualValor + movimentacaoProduto.ibptNacionalValor).toString() : "0";
    return imposto;
  }

  gerarIcms(movimentacaoProduto: MovimentacaoProduto): Icms {
    const icms: Icms = new Icms();
    const cstIcms: Cst = this.utilDocumentoEletronico.cstIcmss.find((cstIcmsBusca) => cstIcmsBusca.id == movimentacaoProduto.idCstIcms);
    switch (cstIcms.cst.substring(1)) {
      case '101':
        icms.ICMSSN101 = this.gerarIcms101(cstIcms, movimentacaoProduto);
        break;
      case '102':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, movimentacaoProduto);
        break;
      case '103':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, movimentacaoProduto);
        break;
      case '201':
        icms.ICMSSN201 = this.gerarIcms201(cstIcms, movimentacaoProduto);
        break;
      case '202':
        icms.ICMSSN202 = this.gerarIcms202(cstIcms, movimentacaoProduto);
        break;
      case '203':
        icms.ICMSSN202 = this.gerarIcms202(cstIcms, movimentacaoProduto);
        break;
      case '300':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, movimentacaoProduto);
        break;
      case '400':
        icms.ICMSSN102 = this.gerarIcms102(cstIcms, movimentacaoProduto);
        break;
      case '500':
        icms.ICMSSN500 = this.gerarIcms500(cstIcms, movimentacaoProduto);
        break;
      case '900':
        icms.ICMSSN900 = this.gerarIcms900(cstIcms, movimentacaoProduto);
        break;
      case '00':
        icms.ICMS00 = this.gerarIcms00(cstIcms, movimentacaoProduto);
        break;
      case '10':
        icms.ICMS10 = this.gerarIcms10(cstIcms, movimentacaoProduto);
        break;
      case '20':
        icms.ICMS20 = this.gerarIcms20(cstIcms, movimentacaoProduto);
        break;
      case '30':
        icms.ICMS30 = this.gerarIcms30(cstIcms, movimentacaoProduto);
        break;
      case '40':
        icms.ICMS40 = this.gerarIcms40(cstIcms, movimentacaoProduto);
        break;
      case '41':
        icms.ICMS40 = this.gerarIcms40(cstIcms, movimentacaoProduto);
        break;
      case '50':
        icms.ICMS40 = this.gerarIcms40(cstIcms, movimentacaoProduto);
        break;
      case '51':
        icms.ICMS51 = this.gerarIcms51(cstIcms, movimentacaoProduto);
        break;
      case '60':
        icms.ICMS60 = this.gerarIcms60(cstIcms, movimentacaoProduto);
        break;
      case '70':
        icms.ICMS70 = this.gerarIcms70(cstIcms, movimentacaoProduto);
        break;
      case '90':
        icms.ICMS90 = this.gerarIcms90(cstIcms, movimentacaoProduto);
        break;
      default:
        break;
    }
    return icms;
  }

  gerarIcms101(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn101 {
    const icmssn101: Icmssn101 = new Icmssn101();
    icmssn101.orig = cstIcms.cst[0];
    icmssn101.CSOSN = cstIcms.cst.substring(1);
    return icmssn101;
  }


  gerarIcms102(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn102 {
    const icmssn102: Icmssn102 = new Icmssn102();
    icmssn102.orig = cstIcms.cst[0];
    icmssn102.CSOSN = cstIcms.cst.substring(1);
    return icmssn102;
  }

  gerarIcms103(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn103 {
    const icmssn103: Icmssn103 = new Icmssn103();
    icmssn103.orig = cstIcms.cst[0];
    icmssn103.CSOSN = cstIcms.cst.substring(1);
    return icmssn103;
  }

  gerarIcms201(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn201 {
    const icmssn201: Icmssn201 = new Icmssn201();
    icmssn201.orig = cstIcms.cst[0];
    icmssn201.CSOSN = cstIcms.cst.substring(1);
    return icmssn201;
  }

  gerarIcms202(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn202 {
    const icmssn202: Icmssn202 = new Icmssn202();
    icmssn202.orig = cstIcms.cst[0];
    icmssn202.CSOSN = cstIcms.cst.substring(1);
    return icmssn202;
  }

  gerarIcms203(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn203 {
    const icmssn203: Icmssn203 = new Icmssn203();
    icmssn203.orig = cstIcms.cst[0];
    icmssn203.CSOSN = cstIcms.cst.substring(1);
    return icmssn203;
  }

  gerarIcms300(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn300 {
    const icmssn300: Icmssn300 = new Icmssn300();
    icmssn300.orig = cstIcms.cst[0];
    icmssn300.CSOSN = cstIcms.cst.substring(1);
    return icmssn300;
  }

  gerarIcms400(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn400 {
    const icmssn400: Icmssn400 = new Icmssn400();
    icmssn400.orig = cstIcms.cst[0];
    icmssn400.CSOSN = cstIcms.cst.substring(1);
    return icmssn400;
  }

  gerarIcms500(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn500 {
    const icmssn500: Icmssn500 = new Icmssn500();
    icmssn500.orig = cstIcms.cst[0];
    icmssn500.CSOSN = cstIcms.cst.substring(1);
    return icmssn500;
  }

  gerarIcms900(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icmssn900 {
    const icmssn900: Icmssn900 = new Icmssn900();
    icmssn900.orig = cstIcms.cst[0];
    icmssn900.modBC = "3";
    icmssn900.vBC = movimentacaoProduto.getIcmsBase().toString();
    icmssn900.pRedBC = "0.00";
    icmssn900.pICMS = movimentacaoProduto.getIcmsAliquota().toString();
    icmssn900.vICMS = movimentacaoProduto.getIcmsValor().toString();
    icmssn900.modBCST = "0";
    icmssn900.vBCST = "0.00";
    icmssn900.pICMSST = "0.00";
    icmssn900.vICMSST = "0.00";
    icmssn900.pCredSN = "0.00";
    icmssn900.vCredICMSSN = "0.00";
    icmssn900.vBCFCPST = "0.00";
    icmssn900.pFCPST = "0.00";
    icmssn900.vFCPST = "0.00";
    return icmssn900;
  }

  gerarIcms00(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms00 {
    const icms00: Icms00 = new Icms00();
    icms00.orig = cstIcms.cst[0];
    icms00.CST = cstIcms.cst.substring(1);
    icms00.modBC = "3";
    icms00.vBC = movimentacaoProduto.getIcmsBase().toFixed(2).toString();
    icms00.pICMS = movimentacaoProduto.getIcmsAliquota().toFixed(2).toString();
    icms00.vICMS = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    return icms00;
  }

  gerarIcms10(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms10 {
    const icms10: Icms10 = new Icms10();
    icms10.orig = cstIcms.cst[0];
    icms10.CST = cstIcms.cst.substring(1);
    icms10.modBC = "3";
    icms10.vBC = movimentacaoProduto.getIcmsBase().toFixed(2).toString();
    icms10.pICMS = movimentacaoProduto.getIcmsAliquota().toFixed(2).toString();
    icms10.vICMS = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    icms10.modBCST = "6";
    icms10.vBCST = movimentacaoProduto.getIcmsStBase().toFixed(2).toString();
    icms10.pICMSST = movimentacaoProduto.icmsStAliquota.toFixed(2).toString();
    icms10.vICMSST = movimentacaoProduto.getIcmsStValor().toFixed(2).toString();
    return icms10;
  }

  gerarIcms20(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms20 {
    const icms20: Icms20 = new Icms20();
    icms20.orig = cstIcms.cst[0];
    icms20.CST = cstIcms.cst.substring(1);
    icms20.modBC = "3";
    icms20.vBC = movimentacaoProduto.getIcmsBase().toFixed(2).toString();
    icms20.pICMS = movimentacaoProduto.getIcmsAliquota().toFixed(2).toString();
    icms20.vICMS = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms20.vICMSDeson = movimentacaoProduto.getIcmsDesonerado().toString() : '';
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms20.motDesICMS = '9' : '';
    const percentualReducao = this.util.arredondar(((movimentacaoProduto.valorTotal - movimentacaoProduto.getIcmsBase()) / movimentacaoProduto.valorTotal) * 100);
    percentualReducao ? icms20.pRedBC = percentualReducao.toFixed(2).toString() : '';
    return icms20;
  }

  gerarIcms30(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms30 {
    const icms30: Icms30 = new Icms30();
    icms30.orig = cstIcms.cst[0];
    icms30.CST = cstIcms.cst.substring(1);
    icms30.vBCST = movimentacaoProduto.getIcmsStBase().toFixed(2).toString();
    icms30.pICMSST = movimentacaoProduto.icmsStAliquota.toFixed(2).toString();
    icms30.vICMSST = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms30.vICMSDeson = movimentacaoProduto.getIcmsDesonerado().toFixed(2).toString() : '';
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms30.motDesICMS = "9" : '';
    return icms30;
  }

  gerarIcms40(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms40 {
    const icms40: Icms40 = new Icms40();
    icms40.orig = cstIcms.cst[0];
    icms40.CST = cstIcms.cst.substring(1);
    return icms40;
  }

  gerarIcms51(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms51 {
    const icms51: Icms51 = new Icms51();
    icms51.orig = cstIcms.cst[0];
    icms51.CST = cstIcms.cst.substring(1);
    icms51.modBC = "3";
    const percentualReducao: number = this.util.arredondar(((movimentacaoProduto.valorTotal - movimentacaoProduto.getIcmsBase()) / movimentacaoProduto.valorTotal) * 100);
    percentualReducao && percentualReducao > 0 ? icms51.pRedBC = percentualReducao.toFixed(2).toString() : '';
    icms51.vBC = movimentacaoProduto.getIcmsBase().toFixed(2).toString();
    icms51.pICMS = movimentacaoProduto.getIcmsAliquota().toFixed(2).toString();
    icms51.vICMS = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    const produto: Produto = this.utilDocumentoEletronico.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
    const aliquotaZero: boolean = produto.diferimentoAliquota == null || produto.diferimentoAliquota == 0;
    aliquotaZero == false ? icms51.pDif = produto.diferimentoAliquota.toFixed(2).toString() : '';
    aliquotaZero == false ? icms51.vICMSDif = (movimentacaoProduto.getIcmsValor() * (produto.diferimentoAliquota / 100)).toFixed(2).toString() : '';
    return icms51;
  }

  gerarIcms60(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms60 {
    const icms60: Icms60 = new Icms60();
    icms60.orig = cstIcms.cst[0];
    icms60.CST = cstIcms.cst.substring(1);
    icms60.vBCSTRet = movimentacaoProduto.getIcmsStBase().toFixed(2).toString();
    icms60.vICMSSTRet = movimentacaoProduto.getIcmsStValor().toFixed(2).toString();
    icms60.pST = (movimentacaoProduto.getIcmsStBase() > 0 && movimentacaoProduto.getIcmsStValor() > 0) ? ((movimentacaoProduto.getIcmsStBase() * movimentacaoProduto.getIcmsStValor()) / 100).toFixed(2).toString() : '0.00';
    return icms60;
  }

  gerarIcms70(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms70 {
    const icms70: Icms70 = new Icms70();
    icms70.orig = cstIcms.cst[0];
    icms70.CST = cstIcms.cst.substring(1);
    icms70.modBC = "3";
    icms70.vBC = movimentacaoProduto.getIcmsBase().toFixed(2).toString();
    icms70.pICMS = movimentacaoProduto.getIcmsAliquota().toFixed(2).toString();
    icms70.vICMS = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms70.vICMSDeson = movimentacaoProduto.getIcmsDesonerado().toString() : '';
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms70.motDesICMS = '9' : '';
    const percentualReducao = this.util.arredondar(((movimentacaoProduto.valorTotal - movimentacaoProduto.getIcmsBase()) / movimentacaoProduto.valorTotal) * 100);
    percentualReducao ? icms70.pRedBC = percentualReducao.toString() : '';
    return icms70;
  }

  gerarIcms90(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): Icms90 {
    const icms90: Icms90 = new Icms90();
    icms90.orig = cstIcms.cst[0];
    icms90.CST = cstIcms.cst.substring(1);
    icms90.modBC = "3";
    icms90.vBC = movimentacaoProduto.getIcmsBase().toFixed(2).toString();
    icms90.pICMS = movimentacaoProduto.getIcmsAliquota().toFixed(2).toString();
    icms90.vICMS = movimentacaoProduto.getIcmsValor().toFixed(2).toString();
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms90.vICMSDeson = movimentacaoProduto.getIcmsDesonerado().toString() : '';
    movimentacaoProduto.getIcmsDesonerado() > 0 ? icms90.motDesICMS = '9' : '';
    const percentualReducao = this.util.arredondar(((movimentacaoProduto.valorTotal - movimentacaoProduto.getIcmsBase()) / movimentacaoProduto.valorTotal) * 100);
    percentualReducao ? icms90.pRedBC = percentualReducao.toString() : '';
    return icms90;
  }

  gerarIpi(movimentacaoProduto: MovimentacaoProduto): Ipi {
    let ipi: Ipi
    if (movimentacaoProduto.getIpiValor() > 0) {
      ipi = new Ipi();
      const produto: Produto = this.utilDocumentoEletronico.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
      ipi.cEnq = produto.ipiEnquadramentoSaida ? produto.ipiEnquadramentoSaida.toString() : '999';
      ipi.IPITrib = this.gerarIpiTrib(movimentacaoProduto);
    }
    return ipi;
  }

  gerarIpiTrib(movimentacaoProduto: MovimentacaoProduto): IpiTrib {
    const ipiTrib: IpiTrib = new IpiTrib();
    const cstIpi: Cst = this.utilDocumentoEletronico.cstsIpis.find((cstIpi) => cstIpi.id == Number(movimentacaoProduto.idCstIpi));
    ipiTrib.CST = cstIpi.cst;
    ipiTrib.vIPI = movimentacaoProduto.getIpiValor().toFixed(2).toString();
    ipiTrib.vBC = movimentacaoProduto.getIpiBase().toFixed(2).toString();
    ipiTrib.pIPI = movimentacaoProduto.getIpiAliquota().toFixed(2).toString();
    return ipiTrib;
  }

  gerarIpiNaoTributado(movimentacaoProduto: MovimentacaoProduto): IpiNt {
    const ipiNt: IpiNt = new IpiNt();
    const cstIpi: Cst = this.utilDocumentoEletronico.cstsIpis.find((cstIpi) => cstIpi.id == Number(movimentacaoProduto.idCstIpi));
    ipiNt.CST = cstIpi.cst;
    return ipiNt;
  }

  gerarPis(movimentacaoProduto: MovimentacaoProduto): Pis {
    const pis: Pis = new Pis();
    const cstPis: Cst = this.utilDocumentoEletronico.cstPisCofinss.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstPis);
    const CSTS_TRIBUTADOS = ["01", "02"];
    const CSTS_NAO_TRIBUTADOS = ["04", "05", "06", "07", "08", "09"];
    const CSTS_TRIBUTADOS_POR_QUANTIDADE = ["03"];
    switch (true) {
      case CSTS_TRIBUTADOS.includes(cstPis.cst):
        pis.PISAliq = this.gerarPisAliquota(cstPis.cst, movimentacaoProduto);
        break;

      case CSTS_TRIBUTADOS_POR_QUANTIDADE.includes(cstPis.cst):
        pis.PISQtde = this.gerarPisQuantidade(cstPis.cst, movimentacaoProduto);
        break;

      case CSTS_NAO_TRIBUTADOS.includes(cstPis.cst):
        pis.PISNT = this.gerarPisNaoTributado(cstPis.cst, movimentacaoProduto);
        break;

      default:
        pis.PISOutr = this.gerarPisOutro(cstPis.cst, movimentacaoProduto);
        break;
    }
    return pis;
  }

  gerarPisAliquota(cst: string, movimentacaoProduto: MovimentacaoProduto): PisAliq {
    const pisAliq: PisAliq = new PisAliq();
    pisAliq.CST = cst;
    pisAliq.vBC = movimentacaoProduto.getPisBase().toFixed(2).toString();
    pisAliq.pPIS = movimentacaoProduto.getPisAliquota().toFixed(2).toString();
    pisAliq.vPIS = movimentacaoProduto.getPisValor().toFixed(2).toString();
    return pisAliq;
  }

  gerarPisQuantidade(cst: string, movimentacaoProduto: MovimentacaoProduto): PisQtde {
    const pisQtde: PisQtde = new PisQtde();
    pisQtde.CST = cst;
    pisQtde.CST = cst;
    pisQtde.qBCProd = movimentacaoProduto.quantidade.toFixed(2).toString();
    pisQtde.vAliqProd = movimentacaoProduto.getPisAliquota().toFixed(2).toString();
    pisQtde.vPIS = movimentacaoProduto.getPisValor().toFixed(2).toString();
    return pisQtde;
  }

  gerarPisNaoTributado(cst: string, movimentacaoProduto: MovimentacaoProduto): PisNt {
    const pisNt: PisNt = new PisNt();
    pisNt.CST = cst;
    return pisNt;
  }

  gerarPisOutro(cst: string, movimentacaoProduto: MovimentacaoProduto): PisOutr {
    const pisOutr: PisOutr = new PisOutr();
    pisOutr.CST = cst;
    pisOutr.vBC = movimentacaoProduto.getPisBase().toFixed(2).toString();
    pisOutr.pPIS = movimentacaoProduto.getPisAliquota().toString();
    pisOutr.vPIS = movimentacaoProduto.getPisValor().toFixed(2).toString();
    return pisOutr;
  }

  gerarCofins(movimentacaoProduto: MovimentacaoProduto): Cofins {
    const cofins: Cofins = new Cofins();
    const cstCofins: Cst = this.utilDocumentoEletronico.cstPisCofinss.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstCofins);
    const CSTS_TRIBUTADOS = ["01", "02"];
    const CSTS_NAO_TRIBUTADOS = ["04", "05", "06", "07", "08", "09"];
    const CSTS_TRIBUTADOS_POR_QUANTIDADE = ["03"];
    switch (true) {
      case CSTS_TRIBUTADOS.includes(cstCofins.cst):
        cofins.COFINSAliq = this.gerarCofinsAliquota(cstCofins.cst, movimentacaoProduto);
        break;

      case CSTS_TRIBUTADOS_POR_QUANTIDADE.includes(cstCofins.cst):
        cofins.COFINSQtde = this.gerarCofinsQuantidade(cstCofins.cst, movimentacaoProduto);
        break;

      case CSTS_NAO_TRIBUTADOS.includes(cstCofins.cst):
        cofins.COFINSNT = this.gerarCofinsNaoTributado(cstCofins.cst, movimentacaoProduto);
        break;

      default:
        cofins.COFINSOutr = this.gerarCofinsOutro(cstCofins.cst, movimentacaoProduto);
        break;
    }
    return cofins;
  }

  gerarCofinsAliquota(cst: string, movimentacaoProduto: MovimentacaoProduto): CofinsAliq {
    const cofinsAliq: CofinsAliq = new CofinsAliq();
    cofinsAliq.CST = cst;
    cofinsAliq.vBC = movimentacaoProduto.getCofinsBase().toFixed(2).toString();
    cofinsAliq.pCOFINS = movimentacaoProduto.getCofinsAliquota().toFixed(2).toString();
    cofinsAliq.vCOFINS = movimentacaoProduto.getCofinsValor().toFixed(2).toString();
    return cofinsAliq;
  }

  gerarCofinsQuantidade(cst: string, movimentacaoProduto: MovimentacaoProduto): CofinsQtde {
    const cofinsQtde: CofinsQtde = new CofinsQtde;
    cofinsQtde.CST = cst;
    cofinsQtde.CST = cst;
    cofinsQtde.qBCProd = movimentacaoProduto.quantidade.toFixed(2).toString();
    cofinsQtde.vAliqProd = movimentacaoProduto.getCofinsAliquota().toFixed(2).toString();
    cofinsQtde.vCOFINS = movimentacaoProduto.getCofinsValor().toFixed(2).toString();
    return cofinsQtde;
  }

  gerarCofinsNaoTributado(cst: string, movimentacaoProduto: MovimentacaoProduto): CofinsNt {
    const cofinsNt: CofinsNt = new CofinsNt();
    cofinsNt.CST = cst;
    return cofinsNt;
  }

  gerarCofinsOutro(cst: string, movimentacaoProduto: MovimentacaoProduto): CofinsOutr {
    const cofinsOutr: CofinsOutr = new CofinsOutr();
    cofinsOutr.CST = cst;
    cofinsOutr.vCOFINS = movimentacaoProduto.getCofinsValor().toFixed(2).toString();
    cofinsOutr.vBC = movimentacaoProduto.getCofinsBase().toFixed(2).toString();
    cofinsOutr.pCOFINS = movimentacaoProduto.getCofinsAliquota().toFixed(2).toString();
    return cofinsOutr;
  }

  gerarTotal(movimentacao: Movimentacao, det: Det[]): Total {
    const total: Total = new Total();
    total.ICMSTot = this.gerarIcmsTotal(movimentacao, det);
    //total.retTrib = this.gerarTributosRetidos();
    return total;
  }

  gerarIcmsTotal(movimentacao: Movimentacao, det: Det[]): IcmsTot {
    const icmsTot: IcmsTot = new IcmsTot();
    icmsTot.vBC = movimentacao.icmsBase.toFixed(2).toString();
    icmsTot.vICMS = this.gerarValorIcms(movimentacao.id).toFixed(2).toString();
    icmsTot.vICMSDeson = movimentacao.icmsDesoneradoValor.toFixed(2).toString();
    icmsTot.vBCST = movimentacao.icmsStBase.toFixed(2).toString();
    icmsTot.vST = movimentacao.icmsStValor.toFixed(2).toString();
    icmsTot.vFrete = movimentacao.freteValor.toFixed(2).toString();
    icmsTot.vSeg = movimentacao.seguroValor.toFixed(2).toString();
    icmsTot.vIPI = movimentacao.ipiValor.toFixed(2).toString();
    icmsTot.vPIS = movimentacao.pisValor.toFixed(2).toString();
    icmsTot.vCOFINS = movimentacao.cofinsValor.toFixed(2).toString();
    icmsTot.vOutro = (movimentacao.outroValor + movimentacao.despesaValor).toFixed(2).toString();
    icmsTot.vNF = movimentacao.valorTotal.toFixed(2).toString();
    icmsTot.vTotTrib = (this.enviarIbpt ? this.util.arredondar(movimentacao.ibptEstadualValor + movimentacao.ibptNacionalValor) : 0.0).toString();
    icmsTot.vFCP = "0";
    icmsTot.vFCPST = "0";
    icmsTot.vFCPSTRet = "0";
    icmsTot.vII = "0";
    icmsTot.vIPIDevol = "0";
    icmsTot.vProd = "0";
    icmsTot.vDesc = "0";
    det.forEach((item) => {
      icmsTot.vProd = (Number(icmsTot.vProd) + Number(item.prod.vProd)).toFixed(2);
      icmsTot.vDesc = (Number(icmsTot.vDesc) + Number(item.prod.vDesc)).toFixed(2);
    });
    return icmsTot;
  }

  gerarValorIcms(idMovimentacao: number): number {
    let valorIcms: number = 0;
    let valorDiferimento: number = 0;
    const movimentacaoProdutos = this.utilDocumentoEletronico.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == idMovimentacao);
    movimentacaoProdutos.forEach((movimentacaoProduto) => {
      valorIcms += movimentacaoProduto.getIcmsValor();
      const produto: Produto = this.utilDocumentoEletronico.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
      valorDiferimento += (produto.diferimentoAliquota ? movimentacaoProduto.getIcmsValor() * (produto.diferimentoAliquota / 100) : 0);
    });
    return (valorIcms - valorDiferimento);
  }

  gerarTributosRetidos(): RetTrib {
    const retTrib: RetTrib = new RetTrib();
    retTrib.vRetCOFINS = 0.00;
    retTrib.vRetPIS = 0.00;
    return retTrib;
  }

  gerarTransporte(movimentacao: Movimentacao): Transp {
    const transp: Transp = new Transp();
    const transportadora: Parceiro = movimentacao.idTransportadora ? this.utilDocumentoEletronico.getParceiro(movimentacao.idTransportadora, null) : null;
    transp.modFrete = movimentacao.freteTipo == 100 ? '0' : movimentacao.freteTipo.toString();
    if (transportadora != null) {
      transp.transporta = this.gerarTransportadora(transportadora);
      transp.vol = this.gerarVolumes(movimentacao);
      return transp;
    }
    return transp;
  }

  gerarTransportadora(transportadora: Parceiro): Transporta {
    const transporta: Transporta = new Transporta();
    transporta.CNPJ = transportadora.cnpjCpf ? this.util.retirarCaracteresInvalidos(transportadora.cnpjCpf) : null;
    transporta.IE = transportadora.inscricaoEstadual ? transportadora.inscricaoEstadual : null;
    transporta.UF = transportadora.uf;
    transporta.xEnder = transportadora.enderecoCompleto.substring(0, 60);
    transporta.xMun = transportadora.cidade;
    transporta.xNome = transportadora.razaoSocial;
    return transporta;
  }

  gerarVolumes(movimentacao: Movimentacao): Vol {
    const vol: Vol = new Vol();
    vol.marca = movimentacao.transportadoraMarca;
    vol.nVol = movimentacao.transportadoraNumeracao ? movimentacao.transportadoraNumeracao : null;
    vol.qVol = movimentacao.transportadoraQuantidade.toString();
    return vol;
  }

  gerarCobranca(movimentacao: Movimentacao): Cobr {
    const cobr: Cobr = new Cobr();
    cobr.dup = this.gerarDuplicatas(movimentacao.movimentacaoParcelas);
    cobr.fat = this.gerarFaturamento(movimentacao);
    return cobr;
  }

  gerarDuplicatas(movimentacaoParcelas: MovimentacaoParcela[]): Dup[] {
    const dup: Dup[] = [];
    movimentacaoParcelas.forEach((movimentacaoParcela, i) => {
      const duplicata: Dup = new Dup();
      duplicata.dVenc = this.formatarData(movimentacaoParcela.dataVencimento);
      duplicata.nDup = this.completaEsquerda((i + 1).toString(), '0', 3);
      duplicata.vDup = movimentacaoParcela.getValorParcela().toFixed(2).toString();
      dup.push(duplicata);
    })
    return dup;
  }

  gerarFaturamento(movimentacao: Movimentacao): Fat {
    const fat: Fat = new Fat();
    fat.nFat = this.completaEsquerda(movimentacao.movimentacaoParcelas.length.toString(), '0', 3);
    fat.vDesc = movimentacao.descontoValor.toFixed(2).toString();
    fat.vLiq = movimentacao.valorTotal.toFixed(2).toString();
    fat.vOrig = movimentacao.valorTotal.toFixed(2).toString();
    return fat;
  }

  gerarPagamento(movimentacao: Movimentacao): Pag {
    const pag: Pag = new Pag();
    pag.detPag = this.gerarDetalhamentoPagamento(movimentacao);
    //pag.vTroco = "0";
    return pag;
  }

  gerarDetalhamentoPagamento(movimentacao: Movimentacao): DetPag {
    const detPag: DetPag = new DetPag();
    detPag.indPag = "1";
    detPag.tPag = movimentacao.naturezaOperacaoFinalidade == 3 ? "90" : "99";
    detPag.vPag = movimentacao.naturezaOperacaoFinalidade == 3 ? "0.00" : movimentacao.valorTotal.toFixed(2).toString();
    movimentacao.naturezaOperacaoFinalidade == 3 ? "" : detPag.xPag = "Outros";
    return detPag;
  }

  gerarInformacaoAdicional(movimentacao: Movimentacao): InfAdic {
    const infAdic: InfAdic = new InfAdic();
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiro = this.utilDocumentoEletronico.parceiros.find((parceiro) => parceiro.id == movimentacao.idParceiro);
    const parceiroEndereco: any = movimentacao.idParceiroDestino ? this.utilDocumentoEletronico.parceiroDestinos.find((parceiroDestino) => (parceiroDestino.idParceiro = parceiro.id)) : this.utilDocumentoEletronico.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.utilDocumentoEletronico.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    infAdic.infCpl = this.utilDocumentoEletronico.gerarInformacaoComplementar(movimentacao, estado.abreviacao).replace(/[\n\r]/g, ' ');
    return infAdic;
  }

  gerarObservacaoContribuite(): ObsCont {
    const obsCont: ObsCont = new ObsCont;
    obsCont.obs.push(this.gerarObservacaoContribuiteDetalhe());
    return obsCont;
  }

  gerarObservacaoContribuiteDetalhe(): Obs {
    const obs: Obs = new Obs();
    obs.xCampo = "";
    obs.xTexto = "";
    return obs;
  }

  formatarDataPadrao(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }

  completaEsquerda(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }


  baixarXml(chaveNota: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
      "chNFe": chaveNota,
      "tpAmb": this.getAmbiente(idLoja),
      "tpDown": "X"
    });
    this.comunicacaoNsService.consumirPdfXml(this.bibServicoNs.getUrlPdf(), transporte).subscribe(
      (res) => {
        const blob = new Blob([res.xml], { type: 'application/xml' });
        const url = window.URL.createObjectURL(blob);
        var link: any = document.createElement('a');
        link.href = url;
        link.download = url.substring(url.lastIndexOf('/') + 1);
        link.click();
      },
      () => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
      }
    );
  }

  baixarPdf(chaveNota: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
      "chNFe": chaveNota,
      "tpAmb": this.getAmbiente(idLoja),
      "tpDown": "P"
    });
    this.comunicacaoNsService.consumirPdfXml(this.bibServicoNs.getUrlPdf(), transporte).subscribe(
      (res) => {
        var data = Uint8Array.from(atob(res.pdf), c => c.charCodeAt(0));
        var blob = new Blob([data], { type: 'application/pdf' });
        window.open(window.URL.createObjectURL(blob));
      },
      () => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelEfetuarDownload));
      }
    );
  }

  enviarEmail(chaveNota: string, email: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
      "chNFe": chaveNota,
      "tpAmb": this.getAmbiente(idLoja),
      "enviaEmailDoc": true,
      "anexarPDF": true,
      "email": email
    });
    this.comunicacaoNsService.persistir(this.bibServicoNs.getUrlEmail(), transporte).subscribe(
      (res) => {
        this.utilSessao.setResultado(new Resultado(true, res.mensagem));
      },
      (error) => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelReenviarEmail));
      }
    );
  }

  cancelarNfe(movimentacaoNfe: MovimentacaoNfe, chaveNota: string, protocolo: string, justificativa: string, idLoja: number): void {
    const transporte = JSON.stringify({
      "X-AUTH-TOKEN": this.bibServicoNs.getToken(),
      "chNFe": chaveNota,
      "tpAmb": this.getAmbiente(idLoja),
      "dhEvento": format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'-03:00'"),
      "nProt": protocolo,
      "xJust": justificativa.replace(/\n/g, '').replace(/\t/g, '')
    });
    this.comunicacaoNsService.persistirCancelamento(this.bibServicoNs.getUrlCancelamento(), transporte).subscribe(
      (res) => {
        // console.log(res)
        if (res.retEvento?.cStat == 135) {
          movimentacaoNfe.status = res.retEvento.cStat;
          movimentacaoNfe.statusDescricao = res.retEvento.xMotivo;
          movimentacaoNfe.protocoloCancelamento = res.retEvento.nProt;
          movimentacaoNfe.situacaoCancelado = 'S';
          movimentacaoNfe.situacaoAutorizado = 'N';
          this.persistirMovimentacaoNfe(movimentacaoNfe);
        }
      },
      (error) => {
        // console.log(error)
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelCancelar));
      }
    );
  }

  persistirMovimentacaoNfe(movimentacaoNfe: MovimentacaoNfe): Observable<any> {
    if (this.utilSessao.getEmpresa().integracaoNs == 'S') {
      movimentacaoNfe.idTecnospeed = null;
    }
    const transporte = new Transporte([movimentacaoNfe], this.bibServico.movimentacaoNfe);
    transporte.idMenu = 126;
    transporte.posicao = 0;
    transporte.quantidade = 0;
    return this.comunicacaoService.persistir(transporte, this.bibServico.movimentacaoNfe);
  }

  getAmbiente(idLoja: number): string {
    const PRODUCAO: string = '1';
    const HOMOLOGACAO: string = '2';
    return this.utilSessao.getLojas().find((loja) => loja.id == idLoja).status == 'P' ? PRODUCAO : HOMOLOGACAO;
  }
}
