import { Component } from '@angular/core';
import { Equipamento } from 'src/app/agrow/modelo/equipamento';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import { ProdutoFormuladoRestricao } from 'src/app/agrow/modelo/ProdutoFormuladoRestricao';
import { Receituario } from 'src/app/agrow/modelo/receituario';
import { Recomendacao } from 'src/app/agrow/modelo/recomendacao';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './receituarioEletronico.component.html',
})
export class ReceituarioEletronicoComponent extends PaginaComponent {
  public culturas: any[] = [];
  public equipamentos: Equipamento[] = [];
  public pragas: any[] = [];
  public produtoFormuladoDoses: ProdutoFormuladoDose[] = [];
  public produtoFormuladoPragaCulturas: ProdutoFormuladoDose[] = [];
  public receituario: Receituario = new Receituario();
  public recomendacoes: Recomendacao[] = [];
  public produto: Produto;
  public produtoFormuladoRestricoes: ProdutoFormuladoRestricao[] = [];
  public idEstado: number;
  public falhaCalculoArea: boolean = false;
  public obrigarEquipamento: boolean = false

  public aplicacao: any[] = [
    { id: 1, nome: this.bibDialogo.terrestre.toUpperCase() },
    { id: 2, nome: this.bibDialogo.aerea.toUpperCase() },
    { id: 11, nome: this.bibDialogo.soloSulcoPlantio.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarEquipamentos();
    this.listarRecomendacoes();
    this.obrigarEquipamento = this.utilSessao.getLojas().filter((loja) => loja.estado == 'GO' || loja.estado == 'ES').length > 0 ? true : false;
  }

  ehAlteracao(): void {
    this.receituario = this.plainToClass(Receituario, this.utilSessao.getIdentificacao('objeto')?.conteudo);
    const idParceiro: number = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
    const idParceiroDestino: number = this.utilSessao.getIdentificacao('idParceiroDestino')?.conteudo;
    if (!this.receituario) {
      this.receituario = new Receituario();
    }
    if (this.receituario.id && !this.receituario.alterado) {
      this.listarReceituario();
    } else {
      this.listarProduto();
    }
    if (idParceiroDestino) {
      this.listarParceiroDestino(idParceiroDestino);
    } else {
      this.listarParceiroEndereco(idParceiro);
    }
  }

  listarReceituario(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.receituario.id)), this.bibServico.receituario).subscribe((res) => {
      this.receituario = this.plainToClass(Receituario, res[0]) as any;
      this.listarProduto();
    });
  }

  listarProduto(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.receituario.idProduto)), this.bibServico.produto).subscribe((res) => {
      this.produto = this.plainToClass(Produto, res[0]) as any;
      this.listarProdutoFormuladoDose();
      this.listarProdutoFormuladoRestricao();
    });
  }

  listarProdutoFormuladoDose(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO_FORMULADO', this.produto.idProdutoFormulado)), this.bibServico.produtoFormuladoDose).subscribe((res) => {
      this.produtoFormuladoDoses = this.plainToClass(ProdutoFormuladoDose, res) as any;
      if (this.produtoFormuladoDoses.length > 0) {
        let produtoFormuladoCulturas: ProdutoFormuladoDose[] = this.criarProdutoFormuladoCulturas();
        this.culturas = this.criarListaCultura(produtoFormuladoCulturas);
        if (this.receituario.cultura) {
          this.receituario.idCultura = this.culturas.find((cultura) => cultura.nome.toUpperCase() == this.receituario.cultura.toUpperCase()).id;
          let produtoFormuladoPragas: ProdutoFormuladoDose[] = this.criarProdutoFormuladoPragas(this.receituario.cultura);
          this.pragas = this.criarListaPraga(produtoFormuladoPragas);
          if (this.receituario.praga) {
            this.receituario.idPraga = this.pragas.find((praga) => praga.nome.toUpperCase() == this.receituario.praga.toUpperCase()).id;
          }
          let idAplicacao: number;
          if (this.receituario.aplicacao) {
            this.receituario.idAplicacao = this.receituario.aplicacao.toUpperCase() == bibDialogo.terrestre.toUpperCase() ? 1 : (this.receituario.aplicacao.toUpperCase() == bibDialogo.soloSulcoPlantio.toUpperCase() ? 11 : 2);
            idAplicacao = this.receituario.idAplicacao;
          }
          if (this.receituario.dose == null || this.receituario.dose == 0) {
            this.setCultura(this.culturas.find((cultura) => cultura.nome?.toUpperCase() == this.receituario.cultura?.toUpperCase()), false);
            this.setPraga(this.pragas.find((praga) => praga.nome?.toUpperCase() == this.receituario.praga?.toUpperCase()));
            if (idAplicacao) {
              this.receituario.idAplicacao = idAplicacao;
              this.setAplicacao(this.aplicacao.find((aplicacaoDesejada) => aplicacaoDesejada.id == this.receituario.idAplicacao));
            }
          }
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.preenchaProdutoFormuladoDose, [this.produto.produtoFormulado, this.menuCompendioEletronico.apelido])));
      }
    });
  }

  listarProdutoFormuladoRestricao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', this.produto.id)), this.bibServico.produtoFormuladoRestricao).subscribe((res) => {
      this.produtoFormuladoRestricoes = this.plainToClass(ProdutoFormuladoRestricao, res) as any;
    });
  }

  private listarParceiroDestino(idParceiroDestino: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiroDestino)), this.bibServico.parceiroDestino).subscribe((res) => {
      const parceiroDestino: ParceiroDestino = this.plainToClass(ParceiroDestino, res)[0] as any;
      this.idEstado = parceiroDestino.idEstado;
    });
  }

  private listarParceiroEndereco(idParceiro: number): void {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIRO', idParceiro), new Criterio('ID_VINCULO', 3)];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroEndereco).subscribe((res) => {
      const parceiroEndereco: ParceiroEndereco = this.plainToClass(ParceiroEndereco, res)[0] as any;
      this.idEstado = parceiroEndereco.idEstado;
    });
  }

  criarProdutoFormuladoCulturas(): ProdutoFormuladoDose[] {
    let produtoFormuladoCulturas: ProdutoFormuladoDose[] = [];
    this.produtoFormuladoDoses.forEach((produtoFormulado, i) => {
      if (i == this.produtoFormuladoDoses.length - 1 || produtoFormulado.cultura != this.produtoFormuladoDoses[i == this.produtoFormuladoDoses.length - 1 ? i : i + 1].cultura) {
        produtoFormuladoCulturas.push(produtoFormulado);
      }
    });
    return produtoFormuladoCulturas;
  }

  criarProdutoFormuladoPragas(cultura: string): ProdutoFormuladoDose[] {
    let produtoFormuladoPragas: ProdutoFormuladoDose[] = [];
    let pragaAtual: String = '';
    this.produtoFormuladoDoses.filter((produtoFormulado) => produtoFormulado.cultura.toUpperCase() == cultura.toUpperCase())
      .sort((a, b) => (a['praga'] === b['praga'] ? 0 : a['praga'] > b['praga'] ? 1 : -1))
      .forEach((produtoFormulado, i) => {
        if (pragaAtual.localeCompare(produtoFormulado.praga)) {
          produtoFormuladoPragas.push(produtoFormulado);
          pragaAtual = produtoFormulado.praga;
        }
      });
    return produtoFormuladoPragas;
  }

  criarListaCultura(lista: any[]): any[] {
    let listaCulturas: any[] = [];
    for (const objeto of lista) {
      listaCulturas.push({ id: objeto.id, nome: objeto.cultura });
    }
    return listaCulturas;
  }

  criarListaPraga(lista: any[]): any[] {
    let listaPragas: any[] = [];
    for (const objeto of lista) {
      listaPragas.push({ id: objeto.id, nome: objeto.praga });
    }
    return listaPragas;
  }

  listarEquipamentos(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.equipamento).subscribe((res) => {
      this.equipamentos = this.plainToClass(Equipamento, res) as any;
    });
  }

  listarRecomendacoes(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.recomendacao).subscribe((res) => {
      this.recomendacoes = this.plainToClass(Recomendacao, res) as any;
    });
  }

  setCultura(cultura: any, alterouCultura: boolean = true): void {
    this.receituario.cultura = cultura.nome;
    this.receituario.idCultura = cultura.id;
    this.produtoFormuladoPragaCulturas = this.criarProdutoFormuladoPragas(cultura.nome);
    if (this.produtoFormuladoPragaCulturas.length == 1) {
      this.setPraga({ id: this.produtoFormuladoPragaCulturas[0].id, nome: this.produtoFormuladoPragaCulturas[0].praga });
    } else {
      if (alterouCultura) {
        this.receituario.praga = null;
        this.receituario.idPraga = null;
      }
    }
    this.pragas = this.criarListaPraga(this.produtoFormuladoPragaCulturas);
    this.receituario.idAplicacao = null;
  }

  setPraga(praga: any): void {
    this.receituario.praga = praga.nome;
    this.receituario.idPraga = praga.id;
  }

  setAplicacao(aplicacao: any): void {
    this.receituario.aplicacao = aplicacao.nome;
    this.receituario.idAplicacao = aplicacao.id;
    let produtoFormulado: ProdutoFormuladoDose = this.produtoFormuladoPragaCulturas.find((produtoFormuladoPragaCultura) => produtoFormuladoPragaCultura.id == this.receituario.idPraga);
    this.receituario.intervaloSeguranca = produtoFormulado.intervaloSeguranca ? produtoFormulado.intervaloSeguranca : null;
    this.receituario.doseUnidade = produtoFormulado.doseUnidade;
    this.receituario.doseDe = produtoFormulado.doseDe;
    this.receituario.doseAte = produtoFormulado.doseAte;
    this.receituario.doseFator = produtoFormulado.doseFator;
    this.receituario.modalidadeAplicacao = produtoFormulado.modalidadeAplicacao;
    this.receituario.epocaAplicacao = produtoFormulado.epocaAplicacao;
    this.setDose(produtoFormulado.doseAte);
    this.receituario.caldaDe = aplicacao.id == 1 || aplicacao.id == 11 ? produtoFormulado.caldaDeTerrestre : produtoFormulado.caldaDeAereo;
    this.receituario.caldaAte = aplicacao.id == 1 || aplicacao.id == 11 ? produtoFormulado.caldaAteTerrestre : produtoFormulado.caldaAteAereo;
    this.receituario.caldaUnidade = aplicacao.id == 1 || aplicacao.id == 11 ? produtoFormulado.caldaUnidadeTerrestre : produtoFormulado.caldaUnidadeAereo;
  }

  persistirReceituario(): void {
    if (this.ehValido()) {
      this.receituario.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibServico.receituario, this.receituario));
    }
  }

  ehValido(): boolean {
    const validoObrigatorio: boolean = this.ehValidoObrigatorio();
    const validoMaiorZero: boolean = this.ehValidoMaiorZero();
    const validoDose: boolean = this.ehValidoDose(validoObrigatorio);
    const validoRestricao: boolean = this.ehValidoRestricao(validoObrigatorio);
    return validoObrigatorio && validoMaiorZero && validoDose && validoRestricao;
  }

  ehValidoObrigatorio(): boolean {
    if (this.receituario.cultura && this.receituario.praga && this.receituario.aplicacao) {
      if (this.obrigarEquipamento) {
        if (this.receituario.idEquipamento) {
          return true;
        }
      } else {
        return true;
      }
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehValidoMaiorZero(): boolean {
    if (this.receituario.numeroAplicacao > 0 && this.receituario.dose > 0 && this.receituario.areaHa > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueCamposReceituarioMaiorZero));
    return false;
  }

  ehValidoDose(ehValidoObrigatorio: boolean): boolean {
    if (ehValidoObrigatorio) {
      if (this.receituario.dose >= this.receituario.doseDe && this.receituario.dose <= this.receituario.doseAte) {
        return true;
      }
      if (confirm(bibDialogo.doseForaFaixa)) {
        return true;
      }
    } else {
      return ehValidoObrigatorio;
    }
    return false;
  }

  ehValidoRestricao(ehValidoObrigatorio: boolean): boolean {
    if (ehValidoObrigatorio && this.produtoFormuladoRestricoes.length > 0) {
      for (const produtoFormuladoRestricao of this.produtoFormuladoRestricoes) {
        let informacaoApontada: string = '';
        informacaoApontada = this.montarApontadaCultura(produtoFormuladoRestricao, informacaoApontada);
        informacaoApontada = this.montarApontadaEstado(produtoFormuladoRestricao, informacaoApontada);
        informacaoApontada = this.montarApontadaEquipamento(produtoFormuladoRestricao, informacaoApontada);
        const informacaoRestrita: string = 'CULTURA: ' + (produtoFormuladoRestricao.cultura && produtoFormuladoRestricao.cultura != '' ? produtoFormuladoRestricao.cultura : '') + '_' + 'ESTADO: ' + (produtoFormuladoRestricao.idEstado ? produtoFormuladoRestricao.idEstado : '') + '_' + 'EQUIPAMENTO: ' + (produtoFormuladoRestricao.idEquipamento ? produtoFormuladoRestricao.idEquipamento : '') + '_';
        if (informacaoApontada == informacaoRestrita) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.restricaoReceituario));
          return false;
        }
      }
    }
    return true;
  }

  montarApontadaCultura(produtoFormuladoRestricao: ProdutoFormuladoRestricao, informacaoApontada: string) {
    if (produtoFormuladoRestricao.cultura && produtoFormuladoRestricao.cultura != '') {
      return (informacaoApontada = informacaoApontada + 'CULTURA: ' + this.receituario.cultura + '_');
    }
    return (informacaoApontada = informacaoApontada + 'CULTURA: _');
  }

  montarApontadaEstado(produtoFormuladoRestricao: ProdutoFormuladoRestricao, informacaoApontada: string) {
    if (produtoFormuladoRestricao.idEstado) {
      return (informacaoApontada = informacaoApontada + 'ESTADO: ' + this.idEstado + '_');
    }
    return (informacaoApontada = informacaoApontada + 'ESTADO: _');
  }

  montarApontadaEquipamento(produtoFormuladoRestricao: ProdutoFormuladoRestricao, informacaoApontada: string) {
    if (produtoFormuladoRestricao.idEquipamento) {
      return (informacaoApontada = informacaoApontada + 'EQUIPAMENTO: ' + this.receituario.idEquipamento + '_');
    }
    return (informacaoApontada = informacaoApontada + 'EQUIPAMENTO: _');
  }

  setDose(dose: number) {
    this.receituario.dose = dose;
    this.calcularArea();
  }

  setNumeroAplicacao(numeroAplicacao: number) {
    this.receituario.numeroAplicacao = numeroAplicacao;
    this.calcularArea();
  }

  calcularArea(): void {
    if (this.produto && this.receituario.dose && this.receituario.numeroAplicacao && this.receituario.doseFator && this.receituario.doseFator > 0) {
      const quantidadeUnidadePadrao: number = this.receituario.movimentacaoProduto['quantidade'] * this.produto.fator;
      const areaQuantidade: number = quantidadeUnidadePadrao / (this.receituario.dose * this.receituario.doseFator);
      this.receituario.areaHa = this.util.arredondar(areaQuantidade / this.receituario.numeroAplicacao);
      this.falhaCalculoArea = false;
    } else {
      this.falhaCalculoArea = true;
    }
  }
}
