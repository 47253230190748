import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Historico } from 'src/app/modelo/historico';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './historicoFrm.component.html',
})
export class HistoricoFrmComponent extends PaginaComponent {
  public bloqueiaMarcacaoNDocumento: boolean = false;
  public bloqueiaMarcacaoOperacao: boolean = false;
  public bloqueiaMarcacaoParceiro: boolean = false;
  public bloqueiaMarcacaoParcela: boolean = false;
  public bloqueiaMarcacaoTipoDeTitulo: boolean = false;
  public compacto: boolean = this.utilSessao.getEmpresa().compacto == 'S' ? true : false;
  public historico: Historico = new Historico();

  public debitoCredito: any = [
    { id: '0', nome: this.bibDialogo.todos.toUpperCase() },
    { id: '1', nome: this.bibDialogo.debito.toUpperCase() },
    { id: '2', nome: this.bibDialogo.credito.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.historico = this.plainToClass(Historico, this.ehAtualizacao(this.historico));
    if (this.historico.id) {
      this.listarhistorico(this.historico.id);
    }
  }

  listarhistorico(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.historico).subscribe((res) => {
      this.historico = this.plainToClass(Historico, res[0]) as any;
    });
  }

  setHistorico(nome: string): void {
    this.historico.nome = nome;
    this.verificarMarcacao();
  }

  flagTipoDeTitulo(): void {
    this.historico.nome == null ? (this.historico.nome = ' ' + this.bibDialogo.tipoDeTituloMarcacao) : (this.historico.nome += ' ' + this.bibDialogo.tipoDeTituloMarcacao);
    this.verificarMarcacao();
  }

  flagParcela(): void {
    this.historico.nome == null ? (this.historico.nome = ' ' + this.bibDialogo.parcelaMarcacao) : (this.historico.nome += ' ' + this.bibDialogo.parcelaMarcacao);
    this.verificarMarcacao();
  }

  flagOperacao(): void {
    this.historico.nome == null ? (this.historico.nome = ' ' + this.bibDialogo.operacaoMarcacao) : (this.historico.nome += ' ' + this.bibDialogo.operacaoMarcacao);
    this.verificarMarcacao();
  }

  flagParceiro(): void {
    this.historico.nome == null ? (this.historico.nome = ' ' + this.bibDialogo.parceiroMarcacao) : (this.historico.nome += ' ' + this.bibDialogo.parceiroMarcacao);
    this.verificarMarcacao();
  }

  flagNumeroDocumento(): void {
    this.historico.nome == null ? (this.historico.nome = ' ' + this.bibDialogo.numeroDocumentoAbreviacaoMarcacao) : (this.historico.nome += ' ' + this.bibDialogo.numeroDocumentoAbreviacaoMarcacao);
    this.verificarMarcacao();
  }

  verificarMarcacao(): void {
    if (this.historico.nome != null) {
      this.bloqueiaMarcacaoNDocumento = this.historico.nome.indexOf('<<Nº DOCUMENTO>>') > -1 ? true : false;
      this.bloqueiaMarcacaoOperacao = this.historico.nome.indexOf('<<OPERAÇÃO>>') > -1 ? true : false;
      this.bloqueiaMarcacaoParceiro = this.historico.nome.indexOf('<<PARCEIRO>>') > -1 ? true : false;
      this.bloqueiaMarcacaoParcela = this.historico.nome.indexOf('<<PARCELA>>') > -1 ? true : false;
      this.bloqueiaMarcacaoTipoDeTitulo = this.historico.nome.indexOf('<<TIPO DE TÍTULO>>') > -1 ? true : false;
    }
  }

  persistirhistorico(novo: boolean = false): void {
    if (this.ehValidoObrigatorio()) {
      super.persistir(new Transporte(this.historico), this.bibServico.historico, novo ? new Historico() : null);
    }
  }

  ehValidoObrigatorio(): boolean {
    if (this.historico.nome && this.historico.tipo) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
