import { Component, Input } from '@angular/core';
import { PesquisaComponent } from 'src/app/formulario/pesquisa/pesquisa/pesquisa.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { ModuloEmpresa } from 'src/app/modelo/moduloEmpresa';
import { RelatorioModuloEmpresa } from 'src/app/modelo/relatorioModuloEmpresa';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'relatorioModuloEmpresa',
  templateUrl: './relatorioModuloEmpresa.component.html',
})
export class RelatorioModuloEmpresaComponent extends PaginaComponent {
  @Input() moduloEmpresa: ModuloEmpresa;
  @Input() idsRelatorioModuloEmpresaExcluir: number[] = [];
  public componente: any = null;
  public ehMinhaModal: boolean = false;

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (this.ehMinhaModal) {
        this.ehMinhaModal = false;
        const relatorios: any[] = identificacao.conteudo;
        relatorios.forEach((relatorio) => {
          if (this.moduloEmpresa.relatorioModuloEmpresas.filter((relatorioModuloEmpresa) => relatorioModuloEmpresa.idRelatorio == relatorio.id).length == 0) {
            let relatorioModuloEmpresa: RelatorioModuloEmpresa = new RelatorioModuloEmpresa();
            relatorioModuloEmpresa.ordem = 0;
            relatorioModuloEmpresa.nome = relatorio.nome;
            relatorioModuloEmpresa.idRelatorio = relatorio.id;
            this.moduloEmpresa.relatorioModuloEmpresas.push(relatorioModuloEmpresa);
          }
        });
      }
      this.ordenarRelatorioModuloEmpresas();
    });
  }

  ordenarRelatorioModuloEmpresas(): void {
    this.moduloEmpresa.relatorioModuloEmpresas.sort((a, b) => (a['ordem'] == b['ordem'] ? 0 : a['ordem'] > b['ordem'] ? 1 : -1));
  }

  adicionarRelatorio(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('SISTEMA', this.utilSessao.sistema));
    this.utilSessao.setIdentificacao(new Identificacao('servico', this.bibServico.relatorio));
    this.utilSessao.setIdentificacao(new Identificacao('lista', this.moduloEmpresa.relatorioModuloEmpresas));
    this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this.bibDialogo.relatorio));
    this.utilSessao.setIdentificacao(new Identificacao('criterios', criterios));
    this.ehMinhaModal = true;
    this.componente = PesquisaComponent;
  }

  setRelatorioModuloEmpresa(relatorioModuloEmpresa: RelatorioModuloEmpresa, ordem: number): void {
    relatorioModuloEmpresa.ordem = ordem;
    this.ordenarRelatorioModuloEmpresas();
  }
}
