<layout [titulo]="bibDialogo.veiculo" [id]="parceiroVeiculo.id">
  <div class="row">
    <texto class="col-sm" [id]="'nomeVeiculo'" [rotulo]="bibDialogo.nome" [campo]="parceiroVeiculo.nome" (alteracao)="parceiroVeiculo.nome = $event" [maximoCaracteres]="50" [obrigatorio]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="parceiroVeiculo.ativo" (alteracao)="parceiroVeiculo.ativo = $event"> </interruptor>
  </div>
  <div class="row">
    <texto class="col-sm-3" [id]="'placa'" [rotulo]="bibDialogo.placa" [campo]="parceiroVeiculo.placa" (alteracao)="parceiroVeiculo.placa = $event" [maximoCaracteres]="7" [obrigatorio]="true"></texto>
    <lista class="col-sm-3" [id]="'estado'" [rotulo]="bibDialogo.estado" [campo]="parceiroVeiculo.idEstado" (alteracao)="setEstado($event)" [lista]="estados" [obrigatorio]="true"> </lista>
    <texto class="col-sm-3" [id]="'renavan'" [rotulo]="bibDialogo.renavan" [campo]="parceiroVeiculo.renavan" (alteracao)="parceiroVeiculo.renavan = $event" [maximoCaracteres]="30" [obrigatorio]="true"></texto>    
    <texto class="col-sm-3" [id]="'rntrc'" [rotulo]="bibDialogo.rntrc" [campo]="parceiroVeiculo.rntrc" (alteracao)="parceiroVeiculo.rntrc = $event" [maximoCaracteres]="20" [obrigatorio]="true"></texto>    
  </div>  
  <div class="row">
    <texto class="col-sm-8" [id]="'motoristaNome'" [rotulo]="bibDialogo.motorista" [campo]="parceiroVeiculo.motoristaNome" (alteracao)="parceiroVeiculo.motoristaNome = $event" [maximoCaracteres]="100"></texto>
    <mascara class="col-sm-4" [id]="'motoristaCpf'" [rotulo]="bibDialogo.cpf" [campo]="parceiroVeiculo.motoristaCpf" (alteracao)="setCpf(parceiroVeiculo, $event)" [tipo]="bibPropriedade.mascara.cpf" [atencao]="!parceiroVeiculo.ehValidoCpf ? bibDialogo.incorreto : null"></mascara>    
  </div>
</layout>
<botao [id]="'adicionar'" [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="persistirParceiroVeiculo()" (saindo)="focoId = $event"></botao>
<auditoria *ngIf="parceiroVeiculo.id" [idUsuarioInclusao]="parceiroVeiculo.idUsuarioInclusao" [dataHoraInclusao]="parceiroVeiculo.dataHoraInclusao" [idUsuarioAlteracao]="parceiroVeiculo.idUsuarioAlteracao" [dataHoraAlteracao]="parceiroVeiculo.dataHoraAlteracao"></auditoria>
