export class BloqueioPeriodo {
  public ativo: string = 'S';
  public dataAte: Date;
  public dataDe: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idLoja: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public motivo: string;
  public tipo: number;
}
