<form>
  <div class="row">
    <div class="col-sm-8">
      <!-- <div class="row">
        <check class="col-sm" [rotulo]="menuFornecedor.apelido" [campo]="_usuario.vendedor" (alteracao)="_usuario.vendedor = $event" [compacto]="true"></check>
        <check class="col-sm" [rotulo]="menuTransportadora.apelido" [campo]="_usuario.comprador" (alteracao)="_usuario.comprador = $event" [compacto]="true"></check>
        <check class="col-sm" [rotulo]="menuColaborador.apelido" [campo]="_usuario.interno" (alteracao)="_usuario.interno = $event" [compacto]="true"></check>        
        <interruptor class="justify-content-end" *ngIf="permiteEdicao" [rotulo]="bibDialogo.ativo" [campo]="_usuario.ativo" (alteracao)="_usuario.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>        
      </div> -->
      <div class="row justify-content-end">
        <interruptor *ngIf="!_usuario.id" class="col-sm" [rotulo]="bibDialogo.comprador" [campo]="_usuario.comprador" (alteracao)="_usuario.comprador = $event"></interruptor>
        <interruptor *ngIf="!_usuario.id" class="col-sm" [rotulo]="bibDialogo.interno" [campo]="_usuario.interno" (alteracao)="_usuario.interno = $event"></interruptor>        
        <interruptor *ngIf="!_usuario.id" class="col-sm" [rotulo]="bibDialogo.vendedor" [campo]="_usuario.vendedor" (alteracao)="_usuario.vendedor = $event"></interruptor>
        <interruptor class="justify-content-end" *ngIf="permiteEdicao" [rotulo]="bibDialogo.ativo" [campo]="_usuario.ativo" (alteracao)="_usuario.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
      </div>
      <div class="row">
        <texto class="col-sm-12" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="_usuario.nome" (alteracao)="_usuario.nome = $event" [ajuda]="bibDialogo.nomeUmSobrenome" [ajudaDireita]="true" [obrigatorio]="true" [foco]="true" [maximoCaracteres]="30"></texto>
      </div>
      <div class="row">
        <texto class="col-sm-12" [id]="'_usuario'" [rotulo]="utilSessao.sistema == 'aGrow-w' ? bibDialogo.email : bibDialogo.usuario" [campo]="_usuario.email" (alteracao)="_usuario.email = $event" [obrigatorio]="true" [tipo]="bibPropriedade.texto.email" [sufixo]="_usuario.id == null && dominio ? dominio : null"></texto>
      </div>
      <div *ngIf="_usuario.id || mobilidade == 'S'">
        <busca class="col-sm-8" [id]="'colaborador'" [rotulo]="bibDialogo.colaborador" [campo]="_usuario.colaborador" (alteracao)="setColaborador($event)" [servico]="bibServico.colaborador" [obrigatorio]="utilSessao.getEmpresa().mobilidade == 'S'"></busca>
      </div>
      <div *ngIf="!_usuario.id || alterandoSenha">
        <div class="row">
          <texto class="col-sm-12" [id]="'senhaAcesso'" [icone]="mostraSenha ? 'fa fa-eye-slash' : 'fa fa-eye fa-fw'" (btnAdicional)="mostraSenha == true ? (mostraSenha = false) : (mostraSenha = true)" [rotulo]="bibDialogo.senhaAcesso" [normal]="true" [campo]="_usuario.senha" (alteracao)="setSenha($event)" [tipo]="mostraSenha ? '' : bibPropriedade.texto.senha" [maximoCaracteres]="30" [obrigatorio]="true" autocomplete="off"></texto>
        </div>
        <div class="row mt-3">
          <div class="row">
            <h6 class="pl-5">{{ bibDialogo.preRequisitos }}</h6>
          </div>
          <ul>
            <div class="row altura-linha">
              <li class="largura-maxima">{{ bibDialogo.minimoDeCaracteres }}</li>
              <titulo *ngIf="_usuario.entre8e30Caracteres" class="col margem-icone-check" [imagem]="bibImagem.checkbox" [compacto]="true"></titulo>
            </div>
            <div class="row altura-linha">
              <li>{{ bibDialogo.umaLetraMaiuscula }}</li>
              <titulo *ngIf="_usuario.contemUmaLetraMaiuscula" class="col margem-icone-check" [imagem]="bibImagem.checkbox" [compacto]="true"></titulo>
            </div>
            <div class="row altura-linha">
              <li>{{ bibDialogo.duasLetrasMinusculas }}</li>
              <titulo *ngIf="_usuario.contemDuasLetrasMinuscula" class="col margem-icone-check" [imagem]="bibImagem.checkbox" [compacto]="true"></titulo>
            </div>
            <div class="row altura-linha">
              <li>{{ bibDialogo.doisNumeros }}</li>
              <titulo *ngIf="_usuario.contemDoisNumeros" class="col margem-icone-check" [imagem]="bibImagem.checkbox" [compacto]="true"></titulo>
            </div>
            <div class="row altura-linha">
              <li>{{ bibDialogo.umCaracterEspecial }}</li>
              <titulo *ngIf="_usuario.contemUmCaracterEspecial" class="col margem-icone-check" [imagem]="bibImagem.checkbox" [compacto]="true"></titulo>
            </div>
          </ul>
        </div>
        <div class="row">
          <texto class="col-sm-12" [id]="'senhaOperacional'" [icone]="mostraSenhaOperacional ? 'fa fa-eye-slash' : 'fa fa-eye fa-fw'" (btnAdicional)="mostraSenhaOperacional == true ? (mostraSenhaOperacional = false) : (mostraSenhaOperacional = true)" [rotulo]="bibDialogo.senhaOperacional" [ajuda]="bibDialogo.assinaturaConfirmacao" [ajudaDireita]="true" [campo]="_usuario.key" (alteracao)="_usuario.key = $event" [tipo]="mostraSenhaOperacional ? '' : bibPropriedade.texto.senha" [maximoCaracteres]="6" [exigidoCaracteres]="6" [obrigatorio]="true" autocomplete="off"></texto>
        </div>
      </div>
      <div class="row">
        <lista *ngIf="permiteEdicao" class="col-sm-12" [id]="'grupoAcesso'" [rotulo]="bibDialogo.acessoHorarioLocal" [ajuda]="bibDialogo.ajudaHorarioLocal" [ajudaDireita]="true" [campo]="_usuario.idGrupoAcesso" [lista]="grupoAcessos" (alteracao)="_usuario.idGrupoAcesso = $event.id" [icone]="bibIcone.atualizar" (btnAdicional)="listarGrupoAcessos()"></lista>
      </div>
      <div class="row">
        <lista *ngIf="permiteEdicao" class="col-sm-12" [id]="'grupoUsuario'" [rotulo]="menuGrupoPermisao.apelido" [campo]="_usuario.idGrupoUsuario" [lista]="grupoUsuarios" (alteracao)="setGrupoUsuario($event.id)" [icone]="bibIcone.atualizar" (btnAdicional)="listarGrupoUsuarios()"></lista>
      </div>
    </div>
    <div class="col-sm-4">
      <usuarioFotoFrm [usuario]="_usuario"></usuarioFotoFrm>
    </div>
  </div>
  <botao *ngIf="_usuario.id && !alterandoSenha" [compacto]="compacto" [legenda]="bibDialogo.alterarSenha" (botaoEmt)="alterarSenha()"></botao>
</form>
