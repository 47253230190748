import { LogDetalhe } from './logDetalhe';

export class Log {
  public idEmpresa: number;
  public idUsuario: number;
  public titulo: string;
  public idMenu: number;
  public idRegistro: number;
  public idRegistroFilho: number;
  public operacao: number;
  public logDetalhes: LogDetalhe[] = [];
}
