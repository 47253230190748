import { Component } from '@angular/core';
import { Conta } from 'src/app/modelo/conta';
import { ContaContabil } from 'src/app/modelo/contaContabil';
import { Criterio } from 'src/app/modelo/criterio';
import { Historico } from 'src/app/modelo/historico';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { RoteiroContabilRateio } from 'src/app/modelo/roteiroContabilRateio';
import { TipoLancamentoContabil } from 'src/app/modelo/tipoLancamentoContabil';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  templateUrl: './roteiroContabilRateioFrm.component.html',
})
export class RoteiroContabilRateioFrmComponent extends PaginaComponent {
  public criterioRotulo: Criterio[] = [new Criterio('SEM_ROTULO_PARCEIRO_DOCUMENTO', 'S'), new Criterio('TIPO', 0)];
  public criterios: Criterio[] = [new Criterio('NOME_NUMERACAO'), new Criterio('SEM_ID_ZERO', 'S'), new Criterio('TODOS_OU_MANUAL', 'S')];
  public historicos: Historico[] = [];
  public nomeRoteiroContabil: string;
  public roteiroContabilRateio: RoteiroContabilRateio = new RoteiroContabilRateio();
  public tipoLancamentoContabeis: TipoLancamentoContabil[] = [];

  ngOnInit(): void {
    this.nomeRoteiroContabil = this.utilSessao.getIdentificacao('nomeRoteiroContabil')?.conteudo;
    this.ehAlteracao();
    this.listarTipoLancamentoContabil();
  }

  listarTipoLancamentoContabil(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoLancamentoContabil).subscribe((res) => {
      this.tipoLancamentoContabeis = this.plainToClass(TipoLancamentoContabil, res) as any;
    });
  }

  ehAlteracao(): void {
    this.roteiroContabilRateio = this.plainToClass(RoteiroContabilRateio, this.ehAtualizacao(this.roteiroContabilRateio));
    if (this.roteiroContabilRateio.id && !this.roteiroContabilRateio.alterado) {
      this.listarRoteiroContabilRateio();
    }
  }

  setConta(contaContabil: ContaContabil): void {
    this.roteiroContabilRateio.idContaContabil = contaContabil.id;
    this.roteiroContabilRateio.contaContabil = contaContabil.nome;
    this.roteiroContabilRateio.contaContabilCorrentista = contaContabil.correntista;
  }

  setContaContrapartida(contaContabil: ContaContabil): void {
    this.roteiroContabilRateio.idContaContabilContrapartida = contaContabil.id;
    this.roteiroContabilRateio.contaContabilContrapartida = contaContabil.nome;
    this.roteiroContabilRateio.contaContabilCorrentistaC = contaContabil.correntista;
  }

  setContaBancaria(contaBancaria: Conta): void {
    this.roteiroContabilRateio.idContaBancaria = contaBancaria.id;
    this.roteiroContabilRateio.contaBancaria = contaBancaria.nome;
  }

  setContaBancariaContrapartida(contaBancaria: Conta): void {
    this.roteiroContabilRateio.idContaBancariaContrapartida = contaBancaria.id;
    this.roteiroContabilRateio.contaBancariaContrapartida = contaBancaria.nome;
  }

  setParceiro(parceiro: Parceiro): void {
    this.roteiroContabilRateio.idParceiro = parceiro.id;
    this.roteiroContabilRateio.parceiro = parceiro.razaoSocial;
  }

  setParceiroContrapartida(parceiro: Parceiro): void {
    this.roteiroContabilRateio.idParceiroContrapartida = parceiro.id;
    this.roteiroContabilRateio.parceiroContrapartida = parceiro.razaoSocial;
  }

  listarRoteiroContabilRateio(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.roteiroContabilRateio.id)), this.bibServico.roteiroContabilRateio).subscribe((res) => {
      this.roteiroContabilRateio = this.plainToClass(RoteiroContabilRateio, res[0]) as any;
    });
  }

  persistiRoteiroContabilRateio(): void {
    if (this.ehValido()) {
      if (this.roteiroContabilRateio.contaContabilCorrentista != 2) {
        this.roteiroContabilRateio.idParceiro = null;
        this.roteiroContabilRateio.parceiro = null;
      }
      if (this.roteiroContabilRateio.contaContabilCorrentistaC != 2) {
        this.roteiroContabilRateio.idParceiroContrapartida = null;
        this.roteiroContabilRateio.parceiroContrapartida = null;
      }
      if (this.roteiroContabilRateio.contaContabilCorrentista != 4) {
        this.roteiroContabilRateio.idContaBancaria = null;
        this.roteiroContabilRateio.contaBancaria = null;
      }
      if (this.roteiroContabilRateio.contaContabilCorrentistaC != 4) {
        this.roteiroContabilRateio.idContaBancariaContrapartida = null;
        this.roteiroContabilRateio.contaBancariaContrapartida = null;
      }
      this.roteiroContabilRateio.alterado = true;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.roteiroContabilRateio, this.roteiroContabilRateio));
    }
  }

  setTipoLancamentoContabil(tipoLancamentoContabil: TipoLancamentoContabil): void {
    this.roteiroContabilRateio.idTipoLancamentoContabil = tipoLancamentoContabil.id;
    this.roteiroContabilRateio.tipoLancamentoContabil = tipoLancamentoContabil.nome;
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoContasDiferente: boolean = this.setEhValidoContasDiferente();
    const ehValidoRateioPercentual = this.setEhValidoRateioPercentual();
    return ehValidoObrigatorio && ehValidoContasDiferente && ehValidoRateioPercentual;
  }

  setEhValidoObrigatorio(): boolean {
    const ehValidoContaBancaria: boolean = this.setEhValidoContaBancaria();
    const ehValidoContaBancariaContraPartida: boolean = this.setEhValidoContaBancariaContraPartida();
    const ehValidoParceiro: boolean = this.setEhValidoParceiro();
    const ehValidoParceiroContraPartida: boolean = this.setEhValidoParceiroContraPartida();
    if (this.roteiroContabilRateio.idContaContabil && this.roteiroContabilRateio.idTipoLancamentoContabil && this.roteiroContabilRateio.idContaContabilContrapartida && this.roteiroContabilRateio.historico && (this.roteiroContabilRateio.percentual || this.roteiroContabilRateio.percentual == 0) && ehValidoContaBancaria && ehValidoContaBancariaContraPartida && ehValidoParceiro && ehValidoParceiroContraPartida) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoContasDiferente(): boolean {
    if (this.roteiroContabilRateio.idContaContabil == this.roteiroContabilRateio.idContaContabilContrapartida) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.contasContabeisDevemSerDiferentes));
      return false;
    }
    return true;
  }

  setEhValidoRateioPercentual(): boolean {
    if (this.roteiroContabilRateio.percentual > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.percentualDoRoteiroMaiorQueZero));
    return false;
  }

  setEhValidoParceiro(): boolean {
    if (this.roteiroContabilRateio.contaContabilCorrentista == 2) {
      if (this.roteiroContabilRateio.idParceiro) {
        return true;
      }
      return false;
    }
    return true;
  }

  setEhValidoParceiroContraPartida(): boolean {
    if (this.roteiroContabilRateio.contaContabilCorrentistaC == 2) {
      if (this.roteiroContabilRateio.idParceiroContrapartida) {
        return true;
      }
      return false;
    }
    return true;
  }

  setEhValidoContaBancaria(): boolean {
    if (this.roteiroContabilRateio.contaContabilCorrentista == 4) {
      if (this.roteiroContabilRateio.idContaBancaria) {
        return true;
      }
      return false;
    }
    return true;
  }

  setEhValidoContaBancariaContraPartida(): boolean {
    if (this.roteiroContabilRateio.contaContabilCorrentistaC == 4) {
      if (this.roteiroContabilRateio.idContaBancariaContrapartida) {
        return true;
      }
      return false;
    }
    return true;
  }
}
