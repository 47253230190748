<layout [titulo]="roteiroContabil.nome" [id]="roteiroContabil.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <lista class="col-sm" [id]="'categoriaRoteiroContabil'" [rotulo]="menuCategoriaRoteiroContabil.apelido == bibDialogo.categoriaRoteiroContabil ? bibDialogo.categoriaRoteiroContabilAbreviado : menuCategoriaRoteiroContabil.apelido" [campo]="roteiroContabil.idCategoriaRoteiroContabil" (alteracao)="setCategoriaRoteiroContabil($event.id)" [lista]="categoriaRoteiroContabeis" [obrigatorio]="true" [icone]="this.bibIcone.atualizar" (btnAdicional)="listarCategoriaRoteiroContabeis()" [idMenu]="permissaoCategoriaRoteiroContabil && permissaoCategoriaRoteiroContabil.inserir == 'S' ? menuCategoriaRoteiroContabil.idMenu : null" [foco]="true"></lista>
    <apresentacao class="col-sm-3" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="roteiroContabil.numero"></apresentacao>
  </div>
  <div class="row align-items-center">
    <titulo class="col-sm" [titulo]="bibDialogo.roteiro"></titulo>
    <botao class="text-right" [compacto]="compacto" [legenda]="bibDialogo.adicionar + ' ' + menuRoteiroContabil.apelido.toLowerCase()" (botaoEmt)="abrirModal(null)"></botao>
  </div>
  <div class="row" *ngIf="roteiroContabil.roteiroContabilRateios.length == 0">
    <p class="col-sm-12 pl-3">
      {{ bibDialogo.nenhumaAbr + ' ' + menuRoteiroContabil.apelido.toLowerCase() + ', ' }} <strong class="clique" (click)="abrirModal(null)">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
    </p>
  </div>
  <table class="table table-responsive-sm" *ngIf="roteiroContabil.roteiroContabilRateios.length > 0">
    <thead>
      <tr>
        <th class="l-35">{{ bibDialogo.contaDebito }}</th>
        <th class="l-30">{{ bibDialogo.contaCredito }}</th>
        <th class="l-10">{{ menuTipoLancamentoContabil.apelido }}</th>
        <th class="l-10 text-right">{{ bibDialogo.percentual }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let roteiroContabilRateio of roteiroContabil.roteiroContabilRateios; let i = index">
        <tr>
          <td class="limitar">{{ roteiroContabilRateio.contaContabil }}</td>
          <td class="limitar">{{ roteiroContabilRateio.contaContabilContrapartida }}</td>
          <td class="limitar">{{ roteiroContabilRateio.tipoLancamentoContabil }}</td>
          <td class="text-right">{{ (roteiroContabilRateio.percentual | monetario) + '%' }}</td>
          <td><btnAdicional [icone]="roteiroContabilRateio.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="roteiroContabilRateio.expandido = !roteiroContabilRateio.expandido" [id]="'Expandido'"></btnAdicional></td>
          <td>
            <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="abrirModal(roteiroContabilRateio)"></btnAdicional>
          </td>
          <td>
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="ehExcluir(i, this.roteiroContabil.roteiroContabilRateios, this.idsRoteiroContabilRateioExclusao, roteiroContabilRateio.id)"></btnAdicional>
          </td>
        </tr>
        <tr *ngIf="roteiroContabilRateio.expandido">
          <td class="limitar" colspan="7"><apresentacao [rotulo]="menuHistorico.apelido" [compacto]="true" [campo]="roteiroContabilRateio.historico"></apresentacao></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="roteiroContabil.roteiroContabilRateios.length > 0 && modificaNome ? roteiroContabil.roteiroContabilRateios[0].historico : roteiroContabil.nome" (alteracao)="roteiroContabil.nome = $event" [obrigatorio]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="roteiroContabil.ativo" (alteracao)="roteiroContabil.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirRoteiroContabil()" [desabilitado]="bloquearPermissao(roteiroContabil.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirRoteiroContabil(true)" [desabilitado]="bloquearPermissao(roteiroContabil.id)"></botao>
<auditoria *ngIf="roteiroContabil.id" [idUsuarioInclusao]="roteiroContabil.idUsuarioInclusao" [dataHoraInclusao]="roteiroContabil.dataHoraInclusao" [idUsuarioAlteracao]="roteiroContabil.idUsuarioAlteracao" [dataHoraAlteracao]="roteiroContabil.dataHoraAlteracao"></auditoria>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
