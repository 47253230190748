export class LojaEstado {
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEstado: number;
  public estado: string;
  public idLoja: number;
  public aliquotaFisica: number;
  public aliquotaJuridica: number;
  public aliquotaInterna: number;
}
