<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.valorRestanteFinanceiro }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r-1">{{ bibDialogo.data }}</th>
    <th class="r-1">{{ bibDialogo.numero }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.tipo }}</th>
    <th class="r-1">{{ bibDialogo.valorEsperado }}</th>
    <th class="r-1">{{ bibDialogo.valorEncontrado }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let valorRestanteFinanceiro of valorRestanteFinanceiros">
      <tr class="conteudo">
        <td>{{ valorRestanteFinanceiro.id }}</td>
        <td>{{ valorRestanteFinanceiro.dataNegociacao | data }}</td>
        <td>{{ valorRestanteFinanceiro.numero }}</td>
        <td>{{ valorRestanteFinanceiro.idLoja + ' - ' + valorRestanteFinanceiro.loja }}</td>
        <td>{{ valorRestanteFinanceiro.tipo }}</td>
        <td>{{ valorRestanteFinanceiro.valorEsperado | monetario }}</td>
        <td>{{ valorRestanteFinanceiro.valorEncontrado | monetario }}</td>
        <td>{{ valorRestanteFinanceiro.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
