<layout>
  <div class="row">
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.numero" [campo]="aprovacao.numero"> </apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.data" [campo]="aprovacao.data | data"> </apresentacao>
    <apresentacao class="col-sm-4" [rotulo]="bibDialogo.loja" [campo]="aprovacao.lojaAbreviacao"> </apresentacao>
  </div>
  <div class="row">
    <apresentacao [ngClass]="compacto ? 'mt-1' : ''" class="col-sm" [rotulo]="aprovacao.idAprovacaoMotivo == 15 ? bibDialogo.cliente : bibDialogo.clienteFornecedor" [campo]="aprovacao.parceiro"> </apresentacao>
    <botao *ngIf="permissaoAbrirPesquisaCliente" [ngClass]="compacto ? '' : 'mt-4'" [compacto]="compacto" [legenda]="bibDialogo.visualizarParceiro" (botaoEmt)="irCliente(aprovacao.idParceiro)" [compacto]="compacto"></botao>
  </div>
  <div class="row">
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.responsavel" [campo]="aprovacao.responsavel"> </apresentacao>
  </div>
  <div class="row">
    <apresentacao class="col-sm" [rotulo]="bibDialogo.motivo" [campo]="aprovacao.aprovacaoMotivo"> </apresentacao>
    <botao *ngIf="aprovacao.idAprovacaoMotivo != 6 && aprovacao.idAprovacaoMotivo != 7" [ngClass]="compacto ? '' : 'mt-4'" [compacto]="compacto" [legenda]="aprovacao.categoria == 'G' ? bibDialogo.visualizarFinanceiro : bibDialogo.visualizarMovimentacao" (botaoEmt)="abrirModalOrigem(aprovacao)" [compacto]="compacto"></botao>
  </div>
  <div [ngClass]="compacto ? 'row alinha-compacto' : 'row'">
    <descricao class="col-sm" [rotulo]="bibDialogo.descricao" [campo]="aprovacao?.descricao" [desabilitado]="true" [compacto]="false" [maximoCaracteres]="1000"></descricao>
  </div>
  <div class="row">
    <descricao class="col-sm-12" [rotulo]="bibDialogo.justificativa" [normal]="true" [campo]="aprovacao?.justificativa" (alteracao)="aprovacao.justificativa = $event" [desabilitado]="(aprovacao.finalizado == 'S' && aprovacao.idAprovacaoMotivo != 13 && aprovacao.idAprovacaoMotivo != 15) || aprovacao.ativo != 'S'" [foco]="true" [maximoCaracteres]="200"></descricao>
    <apresentacao class="col-sm-12" [rotulo]="bibDialogo.situacao" [campo]="aprovacao?.situacao == 'P' ? bibDialogo.pendente : aprovacao.situacao == 'A' ? bibDialogo.aprovado : aprovacao.situacao == 'R' ? bibDialogo.recusado : ''"> </apresentacao>
  </div>
</layout>
<div class="row align-items-center">
  <div class="col-sm">
    <botao (botaoEmt)="persistirAprovacao('A')" [fundo]="true" [compacto]="compacto" [legenda]="bibDialogo.aprovar" [tipo]="bibPropriedade.botao.sucesso" [desabilitado]="(aprovacao.finalizado == 'S' && aprovacao.idAprovacaoMotivo != 13 && aprovacao.idAprovacaoMotivo != 15) || aprovacao.ativo != 'S' || !temPermissaoEdicao || aprovacao.situacao == 'A'"></botao>
    <botao (botaoEmt)="persistirAprovacao('R')" [fundo]="true" [compacto]="compacto" [legenda]="bibDialogo.recusar" [tipo]="bibPropriedade.botao.cuidado" [desabilitado]="(aprovacao.finalizado == 'S' && aprovacao.idAprovacaoMotivo != 13 && aprovacao.idAprovacaoMotivo != 15) || aprovacao.ativo != 'S' || !temPermissaoEdicao || aprovacao.situacao == 'R'"></botao>
  </div>
  <atencao *ngIf="aprovacao.ativo != 'S'" [tipo]="bibPropriedade.atencao.alerta" [atencao]="bibDialogo.inativo"></atencao>
  <atencao *ngIf="aprovacao.finalizado == 'S' && aprovacao.idAprovacaoMotivo != 13 && aprovacao.idAprovacaoMotivo != 15" [tipo]="bibPropriedade.atencao.alerta" [atencao]="bibDialogo.movimentacaoFinalizada"></atencao>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="(aprovacao.finalizado == 'S' && aprovacao.idAprovacaoMotivo != 13 && aprovacao.idAprovacaoMotivo != 15) || temPermissaoEdicao == false"></bloqueado>
<auditoria *ngIf="aprovacao.id" [idUsuarioInclusao]="aprovacao.idUsuarioInclusao" [dataHoraInclusao]="aprovacao.dataHoraInclusao" [idUsuarioAlteracao]="aprovacao.idUsuarioAlteracao" [dataHoraAlteracao]="aprovacao.dataHoraAlteracao"></auditoria>
