import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Util } from 'src/app/utilitario/util';

@Component({
  selector: 'r1233Movimentacao',
  templateUrl: './r1233Movimentacao.component.html',
  styleUrls: ['./r1233Movimentacao.component.css'],
})
export class R1233MovimentacaoComponent {
  @Input() movimentacao: any;
  @Input() registro: number;
  @Input() totalRegistro: number;
  @Input() segundaVia: boolean = false;
  @Input() avalista: boolean = false;
  @Input() produtos: boolean = false;
  public bibDialogo = bibDialogo;
  public util: Util = new Util();
}
