export class bibServicoNsCTe {

  getToken(): string {
    return "QUdST1cgU09GVFdBUkUgdnNWWmI=";
  }

  getUrlEmissao(): string {
    return "cte/issue";
  }

  getUrlConsultarEmissao(): string {
    return "cte/issueStatus/300";
  }

  getUrlConsultar(): string {
    return "cte/stats/300";
  }

  getUrlPdf(): string {
    return "cte/get/300";
  }

  getUrlCancelamento(): string {
    return "cte/cancel/300";
  }

  getUrlCartaCorrecao(): string {
    return "cce";
  }

  getUrlEmail(): string {
    return "util/resendemail";
  }

  getUrlEventos(): string {
    return "cte/get/event";
  }

  getUrlPreviaCte(): string {
    return "util/previa/cte";
  }
}
