export class TabelaPrecoConfiguracao {
  public dataAte: Date;
  public dataDe: Date;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public id: number;
  public idEmpresa: number;
  public idTabelaPreco: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public juroPercentual: number;
  public quantidadeDia: number;
  public selecionado: string = 'N';
  public valor: number;
  public ehVencido: boolean = false;
}
