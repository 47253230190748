import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: 'r2015QuantidadeMovimentacaoProMovimentacaoQua',
  templateUrl: './r2015QuantidadeMovimentacaoProMovimentacaoQua.component.html',
})
export class R2015QuantidadeMovimentacaoProMovimentacaoQua {
  @Input() quantidadeMovimentacaoProMovimentacaoQuas: any[] = [];
  public bibDialogo = bibDialogo;
}
