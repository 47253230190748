<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(estoques, $event)" (campoGrupoEmt)="agrupar(estoques, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.estoque + ' ' + estoque + ' ' + lojaUmAbreviacao }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.estoque + ' ' + estoque + ' ' + lojaDoisAbreviacao }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.diferenca }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let estoque of estoques; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ estoque.numero + ' - ' + estoque.produto }}</td>
          <td>{{ estoque.unidade }}</td>
          <td class="text-right">{{ estoque.estoqueLojaUm | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ estoque.estoqueLojaDois | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ estoque.diferenca | monetario: this.empresaRegraCasasDecimais }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="estoques.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
