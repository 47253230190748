import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: ' <tr>[r2006itens]</tr>',
  templateUrl: './r2006itens.component.html',
  styleUrls: ['./r2006itens.component.css'],
})
export class R2006ItensComponent {
  @Input() contaContabil: any;
  public bibDialogo = bibDialogo;
}
