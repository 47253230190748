<layout [titulo]="local.nome" [id]="local.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <lista [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [id]="'tipo'" [rotulo]="bibDialogo.tipo" [campo]="tipoLista" [lista]="lista" (alteracao)="setLista($event.id)" [obrigatorio]="true" [foco]="true"></lista>
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome + ' ' + (tipoLista != null ? (tipoLista == 'N' ? this.menuLocal.apelido.toLowerCase() : this.bibDialogo.sub.toLowerCase() + '-' + this.menuLocal.apelido.toLowerCase()) : '')" [campo]="local.nome ? local.nome.trim() : null" (alteracao)="local.nome = $event" [obrigatorio]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="local.ativo" (alteracao)="local.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <busca *ngIf="this.tipoLista == 'S'" class="col-sm-12" [idMenu]="permissaoLocal && permissaoLocal.inserir == 'S' ? menuLocal.idMenu : null" [id]="'localPai'" [rotulo]="menuLocal.apelido + ' ' + bibDialogo.pai" [campo]="local.localPai" (alteracao)="local.idLocalPai = $event.id" [criterios]="criterioSemIdZero" [servico]="bibServico.local" [obrigatorio]="true"></busca>
  </div>
  <div class="row">
    <lista class="col-sm-12" [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [campo]="local.idLoja" (alteracao)="local.idLoja = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [obrigatorioLoja]="true"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirLocal()" [desabilitado]="bloquearPermissao(local.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirLocal(true)" [desabilitado]="bloquearPermissao(local.id)"></botao>
<auditoria *ngIf="local.id" [idUsuarioInclusao]="local.idUsuarioInclusao" [dataHoraInclusao]="local.dataHoraInclusao" [idUsuarioAlteracao]="local.idUsuarioAlteracao" [dataHoraAlteracao]="local.dataHoraAlteracao"></auditoria>
