import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({ name: 'decimal' })
export class DecimalPipe implements PipeTransform {
  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    this.DECIMAL_SEPARATOR = '.';
    this.THOUSANDS_SEPARATOR = '*';
  }

  transform(value: number | string, fractionSize: number = 2): string {
    value = this.verificarRecorrenciaPonto(value);
    value = Number(value.toString().replace(',', '.')).toFixed(fractionSize);
    let [integer, fraction = ''] = (value || '').toString().split(this.DECIMAL_SEPARATOR);

    fraction = fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

    let numeroStr = integer + fraction;
    while (numeroStr.indexOf('.') !== -1) {
      numeroStr = numeroStr.replace('.', ',');
    }
    while (numeroStr.indexOf('*') !== -1) {
      numeroStr = numeroStr.replace('*', '.');
    }
    return numeroStr;
  }

  parse(value: string, fractionSize: number = 2): string {
    while (value.indexOf('.') !== -1) {
      if (value.length - value.indexOf('.') == 3 || value.length - value.indexOf('.') == 2) break;
      value = value.replace('.', '');
    }
    while (value.indexOf(',') !== -1) {
      value = value.replace(',', '.');
    }

    let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

    integer = integer.replace(this.THOUSANDS_SEPARATOR, '');

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0 ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';
    let numeroStr: string = integer + fraction;
    return numeroStr;
  }

  verificarRecorrenciaPonto(valor: any): string {
    let arrayValor: string[] = valor.toString().split('');
    let pontoRecorrentes: number = arrayValor.filter((caracter) => caracter == '.').length;
    while (pontoRecorrentes > 1) {
      arrayValor.splice(valor.toString().indexOf('.'), 1);
      pontoRecorrentes = arrayValor.filter((caracter) => caracter == '.').length;
    }
    return arrayValor.join('');
  }
}
