<table class="tabela-complementar">
    <thead>
      <tr>
        <th class="r">{{ bibDialogo.produto }}</th>
        <th class="r-1 text-right">{{ bibDialogo.unidade }}</th>
        <th class="r-1 text-right">{{ bibDialogo.quantidade }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorUnitario }}</th>
        <th class="r-1 text-right">{{ bibDialogo.valorFinal }}</th>
        <th class="r-1 text-right">{{ bibDialogo.comissao }}</th>
        <th class="r-2 text-right"></th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let produto of comissao.comissaoProduto">
          <td>{{ produto.nome }}<atencao [atencao]="produto.comissaoOrigem" [ajuda]="produto.comissaoOrigem == 'N' ? bibDialogo.semComissao : produto.comissaoOrigem == 'PC' ? bibDialogo.comissaoOrigemProdutoColaborador : produto.comissaoOrigem == 'P' ? bibDialogo.comissaoOrigemProduto :  produto.comissaoOrigem == 'GC' ? bibDialogo.comissaoOrigemGrupoColaborador : produto.comissaoOrigem == 'G' ? bibDialogo.comissaoOrigemGrupo : produto.comissaoOrigem == 'C' ? bibDialogo.comissaoOrigemColaborador : ''"></atencao></td>
          <td class="text-right">{{ produto.unidade }}</td>
          <td class="text-right">{{ produto.quantidade }}</td>
          <td class="text-right">{{ produto.valorUnitario | monetario }}</td>
          <td class="text-right">{{ produto.valorFinal | monetario }}</td>
          <td class="text-right">{{ produto.comissaoValor | monetario }}<atencao [tipo]="bibPropriedade.atencao.informativo" [atencao]="(produto.comissaoPercentual | monetario) + '%'"></atencao></td>
          <td class="text-right"></td>
        </tr>
    </tbody>
    <tfoot>
    </tfoot>
  </table>
