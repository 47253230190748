<h1 class="relatorio-titulo text-uppercase mt-4">{{ movimentacaoManualApelido }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.loja }}</th>
    <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
    <th class="r">{{ bibDialogo.responsavel }}</th>
    <th class="r-4">{{ bibDialogo.produto }}</th>
    <th class="r-1-5 text-right">{{ bibDialogo.entrada }}</th>
    <th class="r-1-5 text-right">{{ bibDialogo.saida }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let movimentacaoManual of movimentacaoManuais">
      <tr>
        <td>{{ movimentacaoManual.loja }}</td>
        <td class="text-right">{{ movimentacaoManual.numeroMovimentacao }}</td>
        <td>{{ movimentacaoManual.numeroResponsavel + ' - ' + movimentacaoManual.responsavel }}</td>
        <td>{{ movimentacaoManual.numeroProduto + ' - ' + movimentacaoManual.produto }}</td>
        <td class="text-right">{{ movimentacaoManual.quantidadeEntrada | monetario: this.empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ movimentacaoManual.quantidadeSaida | monetario: this.empresaRegraCasasDecimais }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
