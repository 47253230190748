import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Generico } from 'src/app/modelo/generico';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './generico.component.html',
})
export class GenericoComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public genericos: Generico[] = [];
  public usaLoja: boolean = false;
  public usuario: Usuario = new Usuario();

  ngOnInit(): void {
    this.usuario = this.utilSessao.getUsuario();
    this.activatedRoute.params.subscribe((params) => {
      this.usaLoja = params.parametro != null;
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.permissao.servico).subscribe((res) => {
      this.genericos = this.plainToClass(Generico, res) as any;
    });
  }

  excluirGenerico(id: number, nome: string): void {
    super.excluir<any>(id, this.genericos, nome);
  }
}
