export class ClassificacaoFiscal {
  public ativo: string = 'S';
  public base: number;
  public cstIcms: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricaoNfe: string;
  public enquadramento: number;
  public icmsDesonerado: string = 'N';
  public icmsSt: string = 'N';
  public id: number;
  public idCstIcms: number;
  public idCstIcmsSemInscricao: number;
  public idEmpresa: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public codigoBeneficioFiscal: string;
}
