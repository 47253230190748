import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import { Filtro } from 'src/app/modelo/filtro';

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'menu', rotulo: bibDialogo.menu, nome: 'MENU', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'apelido', rotulo: bibDialogo.apelido, nome: 'APELIDO', coluna: 12, tipo: bibPropriedade.filtro.texto },
]);

export default filtros;
