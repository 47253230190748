import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboReceitaDespesa: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'TODOS', id: '-' },
  { nome: 'RECEITA', id: 'R' },
  { nome: 'DESPESA', id: 'D' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.ano, nome: 'ANO', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.numero, valor: new Date().toString().substring(11, 15) },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.receitaEDespesa, nome: 'RECEITA_DESPESA', obrigatorio: 'S', coluna: 6, tipo: 5, valor: '-', lista: filtroComboReceitaDespesa },
]);
export default filtros;
