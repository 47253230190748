<layout [titulo]="relacionamento.nome" [id]="relacionamento.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="relacionamento.nome" (alteracao)="relacionamento.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="relacionamento.ativo" (alteracao)="relacionamento.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'descricao'" [rotulo]="bibDialogo.descricao" [campo]="relacionamento.descricao" (alteracao)="relacionamento.descricao = $event"></texto>
  </div>
  <div class="row">
    <lista class="col-sm-6" [id]="'sinalizacao'" [rotulo]="bibDialogo.sinalizacao" [ajuda]="bibDialogo.corUtilizadaNaVenda" [ajudaDireita]="true" [campo]="relacionamento.idCor" (alteracao)="setCor($event)" [lista]="cores" [obrigatorio]="true"></lista>
    <icone class="mt-5" *ngIf="relacionamento.idCor" [icone]="bibIcone.circulo" [cor]="bibPropriedade.icone[relacionamento.cor?.toLowerCase()]"></icone>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (click)="persistirRelacionamento()" [desabilitado]="bloquearPermissao(relacionamento.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirRelacionamento(true)" [desabilitado]="bloquearPermissao(relacionamento.id)"></botao>
<auditoria *ngIf="relacionamento.id" [idUsuarioInclusao]="relacionamento.idUsuarioInclusao" [dataHoraInclusao]="relacionamento.dataHoraInclusao" [idUsuarioAlteracao]="relacionamento.idUsuarioAlteracao" [dataHoraAlteracao]="relacionamento.dataHoraAlteracao"></auditoria>
