import { Component } from '@angular/core';
import { Conciliacao } from 'src/app/modelo/conciliacao';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './conciliacao.component.html',
  styleUrls: ['./conciliacao.component.css'],
})
export class ConciliacaoComponent extends PaginaComponent {
  private params: any;
  public componente = null;
  public conciliacoes: Conciliacao[] = [];
  public dataSugerida = new Date();
  public filtros: Filtro[];
  public mostraMarcarTodos: boolean = true;
  public parametro: string;
  public tipoTitulos: TipoTitulo[] = [];
  public valorTotalDespesa: number = 0;
  public valorTotalReceita: number = 0;
  public cartaoEspecies: number[] = [2, 10];

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.parametro = params.parametro;
      this.conciliacoes = [];
      this.selecionarFiltro();
    });
  }

  selecionarFiltro(): void {
    switch (this.parametro) {
      case 'S':
        this.filtros = filtros.conciliacao;
        break;
      case 'M':
        this.filtros = filtros.conciliacao;
        break;
      case 'N':
        this.filtros = filtros.conciliacaoEstorno;
        break;
      case 'C':
        this.filtros = filtros.controleCartao;
        break;
    }
  }

  ngOnDestroy(): void {
    this.params.unsubscribe();
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.valorTotalReceita = 0;
    this.valorTotalDespesa = 0;
    criterios.push(new Criterio('CONCILIADO', this.parametro == 'N' ? 'S' : 'N'));
    if (this.parametro == 'C') {
      criterios.push(new Criterio('CARTOES', 'S'));
      criterios.push(new Criterio('TIPO', 'R'));
    }
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.conciliacao).subscribe((res) => {
      this.conciliacoes = this.plainToClass(Conciliacao, res) as any;
      this.ehListarTipoTitulo();
    });
  }

  ehListarTipoTitulo(): void {
    if (this.parametro == 'M') {
      this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoTitulo).subscribe((res) => {
        this.tipoTitulos = this.plainToClass(TipoTitulo, res) as any;
        this.definirTiposTituloPermitidos();
      });
    }
  }

  definirTiposTituloPermitidos(): void {
    this.conciliacoes.forEach((conciliacao) => {
      let especieMenorNivel: number = Math.min(...this.tipoTitulos.filter((tipoTitulo) => conciliacao.idTipoTitulo == tipoTitulo.id).map((tipoTitulo) => tipoTitulo.especieNivel));
      conciliacao.tiposTituloPermitidos = this.tipoTitulos.filter((tipoTitulo) => tipoTitulo.especieNivel <= especieMenorNivel);
    });
  }

  setSelecionado(conciliacao: Conciliacao, marcado: string): void {
    if (this.parametro == 'S' || this.parametro == 'C') {
      conciliacao.conciliado = marcado;
      conciliacao.dataConciliacao = conciliacao.conciliado == 'S' ? (this.parametro == 'C' ? conciliacao.dataPrevista : this.dataSugerida) : null;
    } else {
      conciliacao.conciliado = marcado == 'S' ? 'N' : 'S';
      conciliacao.dataConciliacao = null;
    }
    this.calcularTotal();
  }

  calcularTotal(): void {
    this.valorTotalReceita = 0;
    this.valorTotalDespesa = 0;
    this.conciliacoes.forEach((conciliacao) => {
      if (conciliacao.conciliado == 'S' && (this.parametro == 'S' || this.parametro == 'C')) {
        conciliacao.tipo == 'R' ? (this.valorTotalReceita += conciliacao.valor) : (this.valorTotalDespesa += conciliacao.valor);
      }
      if (conciliacao.conciliado == 'N' && this.parametro == 'N') {
        conciliacao.tipo == 'R' ? (this.valorTotalReceita += conciliacao.valor) : (this.valorTotalDespesa += conciliacao.valor);
      }
    });
  }

  setDataConciliacao(conciliacao: Conciliacao, date: Date): void {
    conciliacao.dataConciliacao = date;
    if (date) {
      this.dataSugerida = date;
    }
  }

  persistirConciliacao(): void {
    if (this.ehValido()) {
      this.limparData();
      let conciliacaoPersistir: Conciliacao[] = this.parametro != 'M' ? this.conciliacoes.filter((conciliacao) => conciliacao.conciliado == (this.parametro != 'C' ? this.parametro : 'S')) : this.conciliacoes;
      super.persistir(new Transporte(conciliacaoPersistir), this.bibServico.conciliacao, null).subscribe(() => {
        let ids: number[] = conciliacaoPersistir.filter((conciliacao) => conciliacao.contaTipo == 'D').map((conciliacaoConta) => conciliacaoConta.id);
        if (ids.length > 0) {
          this.excluirLista(ids, this.bibServico.conciliacao);
        }
        if (!this.utilSessao.falha) {
          if (this.parametro == 'C') {
            this.conciliacoes = this.conciliacoes.filter((conciliacao) => conciliacao.conciliado == 'N');
          } else {
            this.conciliacoes = this.conciliacoes.filter((conciliacao) => conciliacao.conciliado == (this.parametro == 'S' || this.parametro == 'M' ? 'N' : 'S'));
            this.valorTotalReceita = 0;
            this.valorTotalDespesa = 0;
          }
        }
      });
    }
  }

  limparData(): void {
    if (this.parametro == 'N') {
      for (const conciliacao of this.conciliacoes.filter((conciliacao) => conciliacao.conciliado == 'N')) {
        conciliacao.dataConciliacao = null;
      }
    }
  }

  ehValido(): boolean {
    if (this.parametro != 'M') {
      const validoSelecionadoUm: boolean = this.ehValidoSelecionadoUm();
      let validoConciliacao: boolean = true;
      if (this.parametro == 'S') {
        validoConciliacao = this.ehValidoConciliacao();
      }
      return validoSelecionadoUm && validoConciliacao;
    }
    return true;
  }

  ehValidoSelecionadoUm(): boolean {
    if (this.conciliacoes.find((conciliacao) => conciliacao.conciliado == (this.parametro != 'C' ? this.parametro : 'S'))) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.semConciliacaoSelecionada));
    return false;
  }

  ehValidoConciliacao(): boolean {
    const validoConciliacaoComData: boolean = this.ehValidoConciliacaoComData();
    const validoConciliacaoDataValida: boolean = this.ehValidoConciliacaoDataValida();
    return validoConciliacaoComData && validoConciliacaoDataValida;
  }

  ehValidoConciliacaoComData(): boolean {
    if (this.conciliacoes.find((conciliacao) => conciliacao.conciliado == 'S' && !conciliacao.dataConciliacao)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.conciliacaoSemData));
      return false;
    }
    return true;
  }

  ehValidoConciliacaoDataValida(): boolean {
    if (this.conciliacoes.find((conciliacao) => conciliacao.conciliado == 'S' && new Date(conciliacao.dataConciliacao.toDateString()) < (conciliacao.data ? new Date(conciliacao.data.toDateString()) : new Date().toDateString()) && conciliacao.dataConciliacao != null)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataConciliacaoMenorQueBaixa));
      return false;
    }
    return true;
  }

  marcarDesmarcarTodos(): void {
    if (this.mostraMarcarTodos == true) {
      this.conciliacoes.forEach((conciliacao) => ((conciliacao.conciliado = 'S'), (conciliacao.dataConciliacao = conciliacao.dataPrevista)));
      this.mostraMarcarTodos = false;
    } else {
      this.conciliacoes.forEach((conciliacao) => ((conciliacao.conciliado = 'N'), (conciliacao.dataConciliacao = null)));
      this.mostraMarcarTodos = true;
    }
    this.calcularTotal();
  }
}
