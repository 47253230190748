<layout [titulo]="objeto.nome" [id]="objeto.id">
  <div class="row">
    <lista [id]="'tipo'" [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.tipo" [campo]="tipoLista" [lista]="lista" (alteracao)="setLista($event.id)" [obrigatorio]="true" [foco]="true"></lista>
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome + ' ' + (tipoLista != null ? (tipoLista == 'N' ? this.rotuloPai.toLowerCase() : this.bibDialogo.sub.toLowerCase() + '-' + this.rotuloPai.toLowerCase()) : '')" [campo]="objeto.nome ? objeto.nome.trim() : null" (alteracao)="objeto.nome = $event" [obrigatorio]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="objeto.ativo" (alteracao)="objeto.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <busca [id]="'nomePai'" *ngIf="this.tipoLista == 'S'" [rotulo]="rotuloPai" [campo]="objeto.getPai()" (alteracao)="objeto.setIdPai($event.id)" [servico]="objeto.servico" [criterios]="criterioSemIdZero" [obrigatorio]="true"></busca>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirObjeto()" [desabilitado]="bloquearPermissao(objeto.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirObjeto(true)" [desabilitado]="bloquearPermissao(objeto.id)"></botao>
<auditoria *ngIf="objeto.id" [idUsuarioInclusao]="objeto.idUsuarioInclusao" [dataHoraInclusao]="objeto.dataHoraInclusao" [idUsuarioAlteracao]="objeto.idUsuarioAlteracao" [dataHoraAlteracao]="objeto.dataHoraAlteracao"></auditoria>
