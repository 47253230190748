import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Grupo } from 'src/app/modelo/grupo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';

@Component({
  templateUrl: './grupo.component.html',
})
export class GrupoComponent extends PaginaComponent {
  private classe: any = Grupo;
  private servico: string = this.bibServico.grupo;
  public filtros: Filtro[] = filtros;
  public objetos: any[] = [];

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('SEM_ID_ZERO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.servico).subscribe((res) => {
      this.objetos = this.plainToClass(this.classe, res) as any;
    });
  }

  excluirObjeto(id: number, nome: string): void {
    super.excluir(id, this.objetos, nome);
  }

  irPara(id: number): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('id', id));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', this.permissao.parametro));
    let url: string = this.permissao.tela + 'Frm';
    this.router.navigate([url]);
  }
}
