import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Ordem } from 'src/app/interface/ordem';

const ordens: Ordem[] = [
  { rotulo: bibDialogo.numero, campo: 'numeroMovimentacao' },
  { rotulo: bibDialogo.dataDesfazimento, campo: 'dataDesfazimento' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.cliente, campo: 'parceiro' },
  { rotulo: bibDialogo.motivo, campo: 'mnotivo' },
  { rotulo: bibDialogo.operacao, campo: 'operacao' },
  { rotulo: bibDialogo.loja, campo: 'abreviacao' },
];
export default ordens;
