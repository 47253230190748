<div class="row">
  <titulo class="col-sm" [titulo]="bibDialogo.pesquisaRapida" [imagem]="bibImagem.pesquisa"></titulo>
</div>
<div class="row form-group naoImprimir">
  <lista [campo]="campoPesquisa" [filtroEmiteAlteracao]="false" [lista]="listaCampoPesquisa" (alteracao)="setCampoPesquisa($event.id)" [obrigatorio]="true"></lista>
  <texto [desabilitado]="campoPesquisa == bibDialogo.semAcesso || campoPesquisa == bibDialogo.comAcesso ? true : false" class="col-sm" [campo]="filtro" (alteracao)="alterarValorFiltro($event)" [descricao]="bibDialogo.digiteNumeroNomeRelatorio"></texto>
</div>
<table class="table table-responsive-sm">
  <thead>
    <tr>
      <th>{{ bibDialogo.relatorio }}</th>
      <th class="l-10 text-center">{{ bibDialogo.permitir }}</th>
      <th class="l-10 text-center">{{ bibDialogo.favorito }}</th>
      <th *ngIf="dashboard == 'N'" class="l-10 text-center">{{ bibDialogo.excel }}</th>
      <th *ngIf="dashboard == 'S'" class="l-10 text-center">{{ bibDialogo.telaInicial }}</th>
    </tr>
  </thead>
</table>
<ng-container class="responsiva" *ngFor="let relatorioUsuario of _relatorioUsuarios | filtrarRelatorioUsuario: { filtro: filtro, especifico: campoPesquisa }; let i = index">
  <div class="col-sm-12" *ngIf="i == 0 ? true : _relatorioUsuarios[i].relatorioModulo != _relatorioUsuarios[i - 1].relatorioModulo">
    <div *ngIf="relatorioUsuario.relatorioModulo" class="mt-3 row align-items-center justify-content-between">
      <p><strong>{{ relatorioUsuario.relatorioModulo.toUpperCase() }} </strong></p>
      <p class="clique" (click)="this.permitirAcessoModulo(relatorioUsuario.relatorioModulo)">{{ bibDialogo.concederPermissaoCompleta + ' ' + relatorioUsuario.relatorioModulo.toLowerCase() }}</p>
    </div>     
  </div>
  <table class="table table-responsive-sm mb-0">
    <tbody>
      <tr>
        <td class="limitar">
          <div class="list-group-item-figure">
            <div class="tile tile-circle" [ngClass]="'bg-' + relatorioUsuario.tipoRelatorioModulo">{{ relatorioUsuario.idRelatorio }}</div>
            <span [ngClass]="ehModoClaro ? 'cor' : ''" class="m-1">{{ ' - ' + relatorioUsuario.relatorio }}</span>
          </div>
        </td>
        <td class="l-10 text-center">
          <icone [icone]="relatorioUsuario.acessar == 'S' ? bibIcone.cadeadoAberto : bibIcone.cadeadoFechado" [clique]="true" (iconeEmt)="setRelatorioUsuarioAcessar(relatorioUsuario)" [cor]="relatorioUsuario.acessar == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="relatorioUsuario.acessar == 'S' ? bibDialogo.removerPermissao : bibDialogo.permitir"></icone>
        </td>
        <td class="l-10 text-center">
          <icone [icone]="bibIcone.estrela" [clique]="true" (iconeEmt)="setRelatorioUsuarioFavorito(relatorioUsuario)" [cor]="relatorioUsuario.favorito == 'S' ? bibPropriedade.icone.amarelo : bibPropriedade.icone.cinza" [mensagem]="relatorioUsuario.favorito == 'S' ? bibDialogo.removerFavorito : bibDialogo.favoritar"></icone>
        </td>
        <td *ngIf="dashboard == 'N'" class="l-10 text-center">
          <icone [icone]="bibIcone.planilha" [clique]="true" (iconeEmt)="setRelatorioUsuarioExportarExcel(relatorioUsuario)" [cor]="relatorioUsuario.excel == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="relatorioUsuario.excel == 'N' ? bibDialogo.certezaArquivoExcelPermissao : null"></icone>
        </td>           
        <td *ngIf="dashboard == 'S'" class="l-10 text-center">
          <icone [icone]="bibIcone.dashboard" [clique]="true" (iconeEmt)="setDashboardInicial(relatorioUsuario)" [cor]="relatorioUsuario.dashboardInicial == 'S' ? bibPropriedade.icone.verde : bibPropriedade.icone.cinza" [mensagem]="relatorioUsuario.dashboardInicial == 'S' ? bibDialogo.removerDashboardInicial : bibDialogo.tornarDashboardInicial"></icone>
        </td>        
      </tr>
    </tbody>
  </table>
</ng-container>
