export class Estado {
  public abreviacao: string;
  public ativo: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public ibge: number;
  public id: number;
  public idMenu: number;
  public idPais: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public nome: string;
  public pais: string;
  public pobrezaAliquota: number;
  public servico: string;
}
