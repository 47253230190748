<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-10 clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-50 clique" (click)="listar(add('razaoSocial'), this.utilSessao.posicao, this.paginacao)">{{ menuCliente.apelido }}</th>
        <th class="l-5"></th>
        <th class="l-35 clique" (click)="listar(add('idClienteEspecial'), this.utilSessao.posicao, this.paginacao)">{{ menuConfiguracaoClienteEspecial.apelido }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let cliente of clientes; let i = index">
        <tr [ngClass]="{ tachado: cliente.ativo != 'S' }">
          <td>{{ cliente.numero }}</td>
          <td class="limitar">{{ cliente.razaoSocial }}</td>
          <td><ajuda [ajuda]="cliente.nomeFantasia"></ajuda></td>
          <td><lista [id]="'especial'" [campo]="cliente.idClienteEspecial" (alteracao)="setClienteEspecial(cliente, $event)" [lista]="clienteEspeciais"></lista></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao
></layout>
<botao *ngIf="clientes.length > 0" [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirCliente()" [desabilitado]="temPermissaoEdicao ? false : true"></botao>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
