import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RelatorioUsuario } from '../modelo/relatorioUsuario';
import { UtilSessao } from '../utilitario/util.sessao';

@Injectable({
  providedIn: 'root',
})
export class AutenticacaoRelatorioGuard implements CanActivate {
  constructor(private router: Router, private utilSessao: UtilSessao) {}

  canActivate(rota: ActivatedRouteSnapshot): boolean {
    if (this.utilSessao.getIdentificacao('controlaPermissao')?.conteudo == false) {
      return true;
    }
    if (this.relatorioAutenticar(rota)) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  relatorioAutenticar(rota: ActivatedRouteSnapshot): boolean {
    const listaRelatorioDashborad: RelatorioUsuario[] = this.utilSessao.getRelatorioUsuarios().concat(this.utilSessao.getDashboardUsuarios());
    const relatorioUsuario: RelatorioUsuario = listaRelatorioDashborad.find((relatorio) => relatorio.idRelatorio == Number(rota.url[rota.url.length - 2].path));
    return relatorioUsuario != null;
  }
}
