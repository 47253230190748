import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboMes: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - JANEIRO', id: 1 },
  { nome: '02 - FEVEREIRO', id: 2 },
  { nome: '03 - MARÇO', id: 3 },
  { nome: '04 - ABRIL', id: 4 },
  { nome: '05 - MAIO', id: 5 },
  { nome: '06 - JUNHO', id: 6 },
  { nome: '07 - JULHO', id: 7 },
  { nome: '08 - AGOSTO', id: 8 },
  { nome: '09 - SETEMBRO', id: 9 },
  { nome: '10 - OUTUBRO', id: 10 },
  { nome: '11 - NOVEMBRO', id: 11 },
  { nome: '12 - DEZEMBRO', id: 12 },
]);

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.mes, nome: 'MES', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboMes },
  { rotulo: bibDialogo.ano, nome: 'ANO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.numero, valor: new Date().getFullYear() },
  { rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
  { rotulo: bibDialogo.nivel, nome: 'NIVEL', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.numero, valor: '4' },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, obrigatorio: 'S', servicoWeb: bibServico.loja, todos: 'S', tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.tipoLancamento, nome: 'BALANCO_PATRIMONIAL', obrigatorio: 'S', coluna: 6, valor: '1', tipo: bibPropriedade.filtro.lista, visualizacaoInicial: false },
]);

export default filtros;
