<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="listar(add('status'), this.utilSessao.posicao, this.paginacao)"></th>
        <th class="l-5 clique" (click)="listar(add('loja'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-5 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numeroFiscalAbreviacao }}</th>
        <th class="l-25 clique" (click)="listar(add('parceiroRemetente'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.remetente }}</th>
        <th class="l-25 clique" (click)="listar(add('parceiroDestinatario'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.destinatario }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotal }}</th>
        <th class="l-5"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let cte of ctes; let i = index">
        <tr [ngClass]="{ tachado: cte.ativo == 'N' }">
          <td>
            <btnAdicional *ngIf="cte.status == 'S'" [icone]="bibIcone.cadeadoFechado" (btnAdicional)="abrirCte(cte)" [ajuda]="bibDialogo.abrirCte"></btnAdicional>
          </td>
          <td>{{ cte.loja }}</td>
          <td class="text-right">{{ cte.data | data }}</td>
          <td class="text-right">{{ cte.numero }}</td>
          <td class="limitar">{{ cte.parceiroRemetente }}</td>          
          <td class="limitar">
            <a href="#" (click)="ir(cte.id, i, ctes)">{{ cte.parceiroDestinatario }}</a>
          </td>
          <td class="text-right">{{ cte.valor | monetario }}</td>          
          <td><btnAdicional [icone]="bibIcone.editar" (btnAdicional)="ir(cte.id, i, ctes)"></btnAdicional></td>
          <td><btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluirCte(cte.id, cte.parceiroDestinatario)"></btnAdicional></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
