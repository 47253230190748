import bibDialogo from 'src/app/biblioteca/bibDialogo';

export class ProdutoUnidade {
  public nome: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public fator: number;
  public id: number;
  public idEmpresa: number;
  public idProduto: number;
  public idUnidade: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public operacao: string;
  public unidadeAbreviacao: string;
  public sugerirUnidade: string = 'N';
  public conversao: any[] = [
    { id: 'M', nome: bibDialogo.aoEntrarComEstoqueSeraAlimentadoCom },
    { id: 'D', nome: bibDialogo.aoEntrarComEstoqueSeraAlimentadoCom },
  ];
}
