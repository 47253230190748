<div class="row align-items-center justify-content-between" *ngIf="!financeiro.baixado">
  <div class="col-6">
    <titulo *ngIf="financeiro.valorRestante > 0" [titulo]="(financeiro.negociar == 'RE' ? bibDialogo.repetir.toLowerCase() + ' ' + (financeiro.valor | monetario) : (financeiro.negociar == 'RN' ? bibDialogo.renegociar.toLowerCase() + ' ' + (financeiro.valorRestante | monetario) : bibDialogo.negociacao))" [imagem]="bibImagem.checkbox" [atencao]="financeiro.negociar == 'RE' ? bibDialogo.valor : financeiro.negociar == 'RN' ? bibDialogo.valorRestante : null"></titulo>
  </div>
  <div class="col-6" class="justify-content-end">
    <botao *ngIf="!financeiro.idMovimentacao && financeiro.comissao != 'S'" [compacto]="compacto" [legenda]="bibDialogo.repetir" (botaoEmt)="setFinanceiroRepetir()" [desabilitado]="financeiro.baixado"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.renegociar" (botaoEmt)="setFinanceiroRenegociar()"></botao>
    <botao [compacto]="compacto" [legenda]="bibDialogo.fechar" (botaoEmt)="limparRenegociacaoRepeticao()"></botao>
  </div>
</div>
<div class="row" *ngIf="financeiro.negociar != 'RE' && financeiro.negociar != 'RN'">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.negociacao.toLowerCase() + ', ' }} <strong class="clique" (click)="setFinanceiroRenegociar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<ng-container *ngIf="financeiro.negociar == 'RE' || financeiro.negociar == 'RN'">
  <div class="row">
    <div class="col-sm-10">
      <div class="row">
        <lista [ngClass]="compacto ? 'col-sm-4' : 'col-sm'" [rotulo]="bibDialogo.frequencia" [campo]="financeiro.repetidaTipo" [lista]="listaFrequencia" (alteracao)="setFrequencia($event.id)" [obrigatorio]="true" [desabilitado]="financeiro.baixado"></lista>
        <texto [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.quantidade" [campo]="financeiro.repetidaQuantidade" (alteracao)="financeiro.repetidaQuantidade = $event" [obrigatorio]="true" [desabilitado]="financeiro.baixado" [tipo]="bibPropriedade.texto.inteiro"></texto>
        <texto [ngClass]="compacto ? 'col-sm-3' : 'col-sm-2'" [rotulo]="bibDialogo.diaFixo" [campo]="financeiro.repetidaDiaFixo" (alteracao)="financeiro.repetidaDiaFixo = $event" [obrigatorio]="false" [desabilitado]="financeiro.baixado || financeiro.repetidaTipo != 3" [tipo]="bibPropriedade.texto.inteiro" [valorMaximo]="31"></texto>
      </div>
      <div *ngIf="financeiro.negociar == 'RN'" class="row">
        <lista [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.tipoJuros" [campo]="financeiro.tipoJuroRenegociacao" [lista]="listaTipoJuros" (alteracao)="setTipoJuroRenegociacao($event.id)" [obrigatorio]="true" [desabilitado]="financeiro.baixado"></lista>
        <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.juro" [campo]="financeiro.percentualJuroRenegociacao" (alteracao)="financeiro.percentualJuroRenegociacao= $event" [obrigatorio]="true" [desabilitado]="financeiro.baixado || financeiro.tipoJuroRenegociacao == 'N'" [sufixo]="'%'"></decimal>
        <check class="col-sm-4" [ngClass]="compacto ? '' : 'alinha-botao'" [rotulo]="bibDialogo.desconsiderarSequenciaParcela" [campo]="this.ehDesconsideraSequenciaParcela" (alteracao)="this.ehDesconsideraSequenciaParcela = $event" [compacto]="true" [ajuda]="bibDialogo.ignoraSequenciaParcela"></check>
      </div>
    </div>
    <div class="col-sm alinhar-centro">
      <botao [ngClass]="compacto ? '' : 'alinha-botao'" [compacto]="compacto" [legenda]="bibDialogo.gerar" (botaoEmt)="gerarRepetirRenegociar()" [desabilitado]="financeiro.baixado"></botao>
    </div>
  </div>
  <table class="table table-responsive-sm borderless">
    <thead>
      <tr>
        <th [ngClass]="financeiro.negociar == 'RN' ? 'l-25' : 'l-10'">{{ bibDialogo.parcela }}</th>
        <th class="l-15">{{ bibDialogo.data }}</th>
        <th *ngIf="financeiro.negociar == 'RN'" class="text-right l-15">{{ bibDialogo.valor }}</th>
        <th *ngIf="financeiro.negociar == 'RN'" class="text-right l-15">{{ bibDialogo.adicional }}<ajuda [ajuda]="bibDialogo.juro + ' + ' + bibDialogo.juroAutomatico.toLowerCase() + ' + ' + bibDialogo.acrescimo.toLowerCase()  + ' - ' + bibDialogo.desconto.toLowerCase() + ' - ' + bibDialogo.valorAnulado.toLowerCase()"></ajuda></th>
        <th *ngIf="financeiro.negociar == 'RN'" class="text-right l-15">{{ bibDialogo.valorPago }}</th>
        <th class="text-right l-15">{{ financeiro.negociar == 'RN' ? bibDialogo.valorFinal : bibDialogo.valor }}<ajuda *ngIf="financeiro.negociar == 'RN'" [ajuda]="bibDialogo.valorFinal + ' = ' + bibDialogo.valor.toLowerCase() + ' + ' + bibDialogo.adicional.toLowerCase() + ' - ' + bibDialogo.valorPago.toLowerCase()"></ajuda></th>
        <td class="l-10"></td>
        <td *ngIf="financeiro.negociar == 'RE'" class="l-50"></td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="utilSessao.processando == 0">
        <tr *ngFor="let financeiroGerado of financeiros; let i = index">
          <td><texto [campo]="this.ehDesconsideraSequenciaParcela == 'S' ? financeiroGerado.parcela.substring(financeiroGerado.parcela.indexOf(' ') + 2, 40) : financeiroGerado.parcela.substring(0, 40)" (alteracao)="financeiroGerado.parcela = $event" [obrigatorio]="true"></texto></td>
          <td><data [campo]="financeiroGerado.dataVencimento" (alteracao)="financeiroGerado.dataVencimento = $event" [obrigatorio]="true" [desabilitado]="financeiroGerado.baixado"></data></td>
          <td *ngIf="financeiro.negociar == 'RN'"><decimal [campo]="financeiroGerado.valorReal" (alteracao)="setValorReal(financeiroGerado, $event)" [obrigatorio]="true"></decimal></td>
          <td class="text-right" *ngIf="financeiro.negociar == 'RE'">{{ financeiro.valorReal | monetario }}</td>          
          <td *ngIf="financeiro.negociar == 'RN'" class="text-right">{{ financeiroGerado.valorAnterior | monetario }}</td>
          <td *ngIf="financeiro.negociar == 'RN'" class="text-right">{{ financeiroGerado.valorBaixa | monetario }}</td>
          <td class="text-right">{{ financeiroGerado.valorRestante | monetario }}</td>
          <td><atencao [atencao]="i == 0 ? (financeiro.negociar == 'RE' ? 'manter atual' : 'alterar atual') : 'criar novo'" [ajuda]="i == 0 ? (financeiro.negociar == 'RE' ? bibDialogo.dicaRenegociarManterPrimeiro : bibDialogo.dicaRenegociarPrimeiro) : bibDialogo.dicaRenegociarDemais" [tipo]="i == 0 ? (financeiro.negociar == 'RE' ? bibPropriedade.atencao.informativo : null) : bibPropriedade.atencao.sucesso"></atencao></td>
          <td *ngIf="financeiro.negociar == 'RE'"></td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="financeiros.length > 0">
      <tr *ngIf=" utilSessao.processando == 0">
        <td *ngIf="financeiro.negociar == 'RE'" colspan="2"></td>
        <td *ngIf="financeiro.negociar == 'RN'" colspan="2"></td>
        <td *ngIf="financeiro.negociar == 'RN'" colspan="3"></td>
        <td class="text-right totalizador">{{ financeiro.totalizaValorRestante | monetario }}</td>
        <td></td>
        <td *ngIf="financeiro.negociar == 'RE'"></td>
      </tr>
      <tr *ngIf="financeiro.negociar == 'RN' && this.util.arredondar(financeiro.totalizaValorRestante - financeiro.valorRestante) != 0">
        <td colspan="6">
          <p class="col-sm-12 pl-3 text-right">
            {{ bibDialogo.diferenca + ' de ' + bibDialogo.valor.toLowerCase() + ':' }} <atencao [atencao]="financeiro.totalizaValorRestante - financeiro.valorRestante | monetario" [tipo]="bibPropriedade.atencao.alerta" [ajuda]="bibDialogo.valorRestante + ': ' + (financeiro.valorRestante | monetario)"></atencao>
          </p>
        </td>
      </tr>
    </tfoot>
  </table>
</ng-container>
