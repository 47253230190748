import { Type } from 'class-transformer';

export class Monitoramento {
  id: number;
  idEmpresa: number;
  empresa: string;
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
  ambiente: string;
}
