<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.operacaoMovimentacao }}</h1>
<table>
  <thead>
    <th class="r-0-5">{{ bibDialogo.id }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.operacao }}</th>
    <th class="r-0-5">{{ bibDialogo.fisico }}</th>
    <th class="r-0-5">{{ bibDialogo.fisico }}</th>
    <th class="r-1">{{ bibDialogo.reservado }}</th>
    <th class="r-1">{{ bibDialogo.reservado }}</th>
    <th class="r-1">{{ bibDialogo.comprado }}</th>
    <th class="r-1">{{ bibDialogo.comprado }}</th>
    <th class="r-1">{{ bibDialogo.emTransferencia }}</th>
    <th class="r-1">{{ bibDialogo.emTransferencia }}</th>
    <th class="r-0-5">{{ bibDialogo.nfe }}</th>
    <th class="r-0-5">{{ bibDialogo.nfe }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let operacaoMovimentacao of operacaoMovimentacoes">
      <tr class="conteudo">
        <td>{{ operacaoMovimentacao.id }}</td>
        <td>{{ operacaoMovimentacao.idLoja + ' - ' + operacaoMovimentacao.loja }}</td>
        <td>{{ operacaoMovimentacao.idEmpresa + ' - ' + operacaoMovimentacao.empresa }}</td>
        <td>{{ operacaoMovimentacao.idOperacao + ' - ' + operacaoMovimentacao.operacao }}</td>
        <td>{{ operacaoMovimentacao.estoqueFisico }}</td>
        <td>{{ operacaoMovimentacao.estoqueFisicoCorreto }}</td>
        <td>{{ operacaoMovimentacao.estoqueReservado }}</td>
        <td>{{ operacaoMovimentacao.estoqueReservadoCorreto }}</td>
        <td>{{ operacaoMovimentacao.estoqueComprado }}</td>
        <td>{{ operacaoMovimentacao.estoqueCompradoCorreto }}</td>
        <td>{{ operacaoMovimentacao.estoqueTransferido }}</td>
        <td>{{ operacaoMovimentacao.estoqueTransferidoCorreto }}</td>
        <td>{{ operacaoMovimentacao.estoqueNfeRegra }}</td>
        <td>{{ operacaoMovimentacao.estoqueNfeRegraCorreto }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
