<titulo [titulo]="bibDialogo.horarioDeAcesso" [imagem]="bibImagem.atualizacaocadastro" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.horario.toLocaleLowerCase()" [desabilitado]="!temPermissaoEdicao"></titulo>
<div *ngIf="grupoAcessoHorarios.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.horario.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="grupoAcessoHorarios.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l-15 clique" (click)="util.ordenarLista(this.grupoAcessoHorarios, 'estado')">{{ bibDialogo.diaSemana }}</th>
      <th class="l-15 clique" (click)="util.ordenarLista(this.grupoAcessoHorarios, 'horaInicial')">{{ bibDialogo.horarioInicial }}</th>
      <th class="l-15 clique" (click)="util.ordenarLista(this.grupoAcessoHorarios, 'horaFinal')">{{ bibDialogo.horarioFinal }}</th>
      <th class="l"></th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let grupoAcessoHorario of grupoAcessoHorarios; let i = index">
      <tr>
        <td>
          <lista [id]="'diaDaSemana' + i" *ngIf="!grupoAcessoHorario.id || edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id)" [campo]="grupoAcessoHorario.diaSemana" (alteracao)="setDiaSemana(grupoAcessoHorario, $event.id)" [lista]="diasSemana" [obrigatorio]="true"></lista>
          {{ grupoAcessoHorario.id && edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id) == false ? diasSemana[grupoAcessoHorario.diaSemana - 1].nome : '' }}
        </td>
        <td class="text-left">
          <mascara *ngIf="!grupoAcessoHorario.id || edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id)" [campo]="grupoAcessoHorario.horaInicial" (alteracao)="grupoAcessoHorario.horaInicial = $event" [tipo]="bibPropriedade.mascara.horaMinuto" [obrigatorio]="true"></mascara>
          {{ grupoAcessoHorario.id && edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id) == false ? grupoAcessoHorario.horaInicial : '' }}
        </td>
        <td class="text-left">
          <mascara *ngIf="!grupoAcessoHorario.id || edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id)" [campo]="grupoAcessoHorario.horaFinal" (alteracao)="grupoAcessoHorario.horaFinal = $event" [tipo]="bibPropriedade.mascara.horaMinuto" [obrigatorio]="true"></mascara>
          {{ grupoAcessoHorario.id && edicaoGrupoAcessoHorarios.includes(grupoAcessoHorario.id) == false ? grupoAcessoHorario.horaFinal : '' }}
        </td>
        <td></td>
        <td>
          <btnAdicional [id]="'editar' + i" [icone]="bibIcone.editar" (btnAdicional)="editar(grupoAcessoHorario)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirGrupoAcessoHorario(i, grupoAcessoHorario.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
