import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Local } from '../../modelo/local';

@Component({
  templateUrl: './localFrm.component.html',
})
export class LocalFrmComponent extends PaginaComponent {
  public criterioSemIdZero: Criterio[] = [new Criterio('SEM_ID_ZERO', 'S')];
  public local: Local = new Local();
  public tipoLista: string;

  public lista: any[] = [
    { id: 'N', nome: this.menuLocal.apelido.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.sub + '-' + this.menuLocal.apelido.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.local = this.plainToClass(Local, this.ehAtualizacao(this.local));
    if (this.local.id) {
      this.listarLocal(this.local.id);
    }
  }

  listarLocal(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.local).subscribe((res) => {
      this.local = this.plainToClass(Local, res[0]) as any;
      this.tipoLista = this.local.idLocalPai != null ? (this.local.idLocalPai == 0 ? 'N' : 'S') : null;
    });
  }

  setLista(tipo: string): void {
    this.tipoLista = tipo;
    this.local.idLocalPai = null;
    this.local.localPai = null;
    if (this.tipoLista == 'N') {
      this.local.idLocalPai = 0;
    }
  }

  persistirLocal(novo: boolean = false): void {
    if (this.ehValido()) {
      this.local.nome = this.local.nome.trim();
      super.persistir(new Transporte(this.local), this.bibServico.local, novo ? new Local() : null);
    }
    if (novo == true) {
      this.tipoLista = null;
    }
  }

  ehValido(): boolean {
    if (this.local.nome && this.local.idLoja && (this.local.idLocalPai || this.local.idLocalPai == 0)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }
}
