import { Component } from '@angular/core';
import { Grupo } from 'src/app/modelo/grupo';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';
import { Criterio } from 'src/app/modelo/criterio';
import { Resultado } from 'src/app/modelo/resultado';
import { Categoria } from 'src/app/modelo/categoria';
import { Natureza } from 'src/app/modelo/natureza';
import { CentroResultado } from 'src/app/modelo/centroResultado';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Cfop } from 'src/app/modelo/cfop';
import { Cst } from 'src/app/modelo/cst';

@Component({
  templateUrl: './grupoFrm.component.html',
})
export class GrupoFrmComponent extends PaginaComponent {
  private classe: any;
  public criterioSemIdZero: Criterio[] = [new Criterio('SEM_ID_ZERO', 'S')];
  public lista: any[] = [];
  public listaSimNao: any[] = [];
  public grupo: any;
  public rotuloPai: string;
  public tipoLista: string;
  public cfopsEmpresaEntrada: CfopEmpresa[] = [];
  public cfopsEmpresaSaida: CfopEmpresa[] = [];
  public cfopsEmpresaTransferencia: CfopEmpresa[] = [];
  private cstIpis: Cst[] = [];
  public cstIpiEntradas: Cst[] = [];
  public cstIpiSaidas: Cst[] = [];
  public cstsPisCofinsEntrada: Cst[] = [];
  public cstsPisCofinsSaida: Cst[] = [];

  ngOnInit() {
    switch (this.utilSessao.getIdentificacao('parametro')?.conteudo.toLowerCase()) {
      case this.bibServico.grupo:
        this.classe = Grupo;
        this.grupo = new Grupo();
        this.rotuloPai = this.menuGrupo.apelido;
        break;
      case this.bibServico.categoria:
        this.classe = Categoria;
        this.grupo = new Categoria();
        this.rotuloPai = this.menuCategoria.apelido;
        break;
      case this.bibServico.natureza:
        this.classe = Natureza;
        this.grupo = new Natureza();
        this.rotuloPai = this.menuNatureza.apelido;
        break;
      case 'centro_resultado':
        this.classe = CentroResultado;
        this.grupo = new CentroResultado();
        this.rotuloPai = this.menuCentroDeResultado.apelido;
        this.rotuloPai.toLowerCase();
        break;
    }
    this.lista = [
      { id: 'N', nome: this.rotuloPai.toUpperCase() },
      { id: 'S', nome: this.bibDialogo.sub.toUpperCase() + '-' + this.rotuloPai.toUpperCase() },
    ];
    this.listaSimNao = [
      { id: 'S', nome: this.bibDialogo.sim },
      { id: 'N', nome: this.bibDialogo.nao },
    ];
  
    this.ehAlteracao();
    this.listarCfopEmpresa();
    this.listarCstPisCofins();
    this.listarCstIpi();
  }

  ehAlteracao(): void {
    this.grupo = this.plainToClass(this.classe, this.ehAtualizacao(this.grupo));
    if (this.grupo.id) {
      this.listarObjeto(this.grupo.id);
    }
  }

  listarObjeto(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.grupo.servico).subscribe((res) => {
      this.grupo = this.plainToClass(this.classe, res[0]) as any;
      this.tipoLista = this.grupo.getIdPai() != null ? (this.grupo.getIdPai() == 0 ? 'N' : 'S') : null;
    });
  }

  listarCfopEmpresa(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cfopEmpresa).subscribe((res) => {
      const cfopsEmpresa: CfopEmpresa[] = this.plainToClass(Cfop, res) as any;
      this.cfopsEmpresaEntrada = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopEntradaSaida == 'E');
      this.cfopsEmpresaSaida = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopEntradaSaida == 'S');
      this.cfopsEmpresaTransferencia = cfopsEmpresa.filter((cfopEmpresa) => cfopEmpresa.cfopTransferencia == 'S');
    });
  }

  listarCstPisCofins(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstPisCofins).subscribe((res) => {
      const cstsPisCofins: Cst[] = this.plainToClass(Cst, res) as any;
      this.cstsPisCofinsEntrada = cstsPisCofins.filter((cstPisCofins) => cstPisCofins.entradaSaida == 'E');
      this.cstsPisCofinsSaida = cstsPisCofins.filter((cstPisCofins) => cstPisCofins.entradaSaida == 'S');
    });
  }

  listarCstIpi(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.cstIpi).subscribe((res) => {
      this.cstIpis = this.plainToClass(Cst, res) as any;
      this.cstIpiEntradas = this.cstIpis.filter((cstIpi) => cstIpi.entradaSaida == 'E');
      this.cstIpiSaidas = this.cstIpis.filter((cstIpi) => cstIpi.entradaSaida == 'S');
    });
  }

  setLista(tipo: string): void {
    this.tipoLista = tipo;
    this.grupo.setIdPai(null);
    if (this.tipoLista == 'N') {
      this.grupo.setIdPai(0);
    }
  }

  persistirObjeto(novo: boolean = false): void {
    if (this.ehValidoObrigatorio() && this.ehvalidoEdicao()) {
      this.grupo.nome = this.grupo.nome.trim();
      super.persistir(new Transporte(this.grupo), this.grupo.servico, novo ? this.selecionarObjetoNovo() : null);
    }
    if (novo == true) {
      this.tipoLista = null;
    }
  }

  selecionarObjetoNovo(): any {
    switch (this.grupo.servico) {
      case this.bibServico.grupo:
        return new Grupo();
      case this.bibServico.natureza:
        return new Natureza();
      case this.bibServico.categoria:
        return new Categoria();
      case this.bibServico.centroResultado:
        return new CentroResultado();
    }
  }

  ehValidoObrigatorio(): boolean {
    if (((this.grupo.nome && this.grupo.getIdPai()) || this.grupo.getIdPai() == 0) && (this.grupo.idCstPisEntrada ? this.grupo.pisAliquotaEntrada || this.grupo.pisAliquotaEntrada == 0 : true) && (this.grupo.idCstPisSaida ? this.grupo.pisAliquotaSaida || this.grupo.pisAliquotaSaida == 0 : true) && (this.grupo.idCstCofinsEntrada ? this.grupo.cofinsAliquotaEntrada || this.grupo.cofinsAliquotaEntrada == 0 : true) && (this.grupo.idCstCofinsSaida ? this.grupo.cofinsAliquotaSaida || this.grupo.cofinsAliquotaSaida == 0 : true) && (this.grupo.idIpiEntrada ? this.grupo.ipiAliquotaEntrada || this.grupo.ipiAliquotaEntrada == 0 : true) && (this.grupo.idIpiSaida ? this.grupo.ipiAliquotaSaida || this.grupo.ipiAliquotaSaida == 0 : true)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  ehvalidoEdicao(): boolean {
    if (this.grupo.id != this.grupo.getIdPai()) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.validarRegistroPai));
    return false;
  }
}
