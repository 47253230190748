<h1 class="relatorio-titulo text-uppercase mt-4">{{ bibDialogo.conciliacaoBaixa }}</h1>
<table>
  <thead>
    <th class="r-1">{{ bibDialogo.id }}</th>
    <th class="r">{{ bibDialogo.loja }}</th>
    <th class="r-1">{{ bibDialogo.empresa }}</th>
    <th class="r-1">{{ bibDialogo.dataLancamento }}</th>
    <th class="r-1">{{ bibDialogo.dataConciliacao }}</th>
    <th class="r-2">{{ bibDialogo.conta }}</th>
    <th class="r-1">{{ bibDialogo.tipo }}</th>
    <th class="r-1">{{ bibDialogo.situacao }}</th>
    <th class="r-1">{{ bibDialogo.valor }}</th>
    <th class="r-1">{{ bibDialogo.valorPago }}</th>
    <th class="r-1-5">{{ bibDialogo.dataHotaAlteracao }}</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let conciliacaoFinanceiroBaixa of conciliacaoFinanceiroBaixas">
      <tr class="conteudo">
        <td>{{ conciliacaoFinanceiroBaixa.id }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.idLoja + ' - ' + conciliacaoFinanceiroBaixa.loja }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.idEmpresa + ' - ' + conciliacaoFinanceiroBaixa.empresa }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.dataLancamento | data }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.dataConciliacao | data }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.idConta + ' - ' + conciliacaoFinanceiroBaixa.conta }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.tipo }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.situacao }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.valorEsperado | monetario }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.valorEncontrado | monetario }}</td>
        <td>{{ conciliacaoFinanceiroBaixa.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
