<layout [subTitulo]="conciliacoes.length > 0 ? bibDialogo.conta.toUpperCase() + ': ' + conciliacoes[0].conta : null" (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <div class="row"><registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro> <botao *ngIf="parametro == 'C' && conciliacoes.length > 0" class="col justify-content-end" [compacto]="compacto" [legenda]="mostraMarcarTodos ? bibDialogo.selecionarTodos : bibDialogo.desmarcarTodos" (botaoEmt)="marcarDesmarcarTodos()"></botao></div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th *ngIf="parametro != 'M'" class="l-5 naoImprimir"></th>
        <th *ngIf="parametro != 'C'" class="l-5 clique" (click)="listar(add('tipo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.tipo }}</th>
        <th [ngClass]="parametro == 'C' ? 'fonte-menor' : ''" class="l-5 clique" (click)="listar(add('abreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th [ngClass]="parametro == 'C' ? 'l-10 fonte-menor' : 'l-15 clique'" (click)="listar(add(parametro != 'C' ? 'dataConciliacao' : 'dataLancamento'), this.utilSessao.posicao, this.paginacao)">{{ parametro != 'C' ? bibDialogo.dataConciliacao : bibDialogo.dataLancamento }}</th>
        <th [ngClass]="parametro == 'C' ? 'fonte-menor' : ''" class="l-10 naoImprimir clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ parametro != 'C' ? bibDialogo.data : bibDialogo.dataPrevista }}</th>
        <th *ngIf="parametro != 'C'" [ngClass]="parametro == 'C' ? 'fonte-menor' : ''" class="l-15 clique" (click)="listar(add('nomeParceiro'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.origem + '/' + bibDialogo.destino }}</th>
        <th [ngClass]="parametro == 'C' ? 'fonte-menor' : ''" class="l-15 clique" (click)="listar(add('tipoTitulo'), this.utilSessao.posicao, this.paginacao)">{{ menuTipoDeTitulo.apelido }}</th>
        <th *ngIf="parametro == 'C'" class="l-5 text-center clique fonte-menor" (click)="listar(add('debitoCredito'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.debitoCreditoAbreviacao }}</th>
        <th *ngIf="parametro == 'C'" class="l-5 text-right clique fonte-menor" (click)="listar(add('parcela'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.parcela }}</th>
        <th *ngIf="parametro == 'C'" class="l-10 text-right clique fonte-menor" (click)="listar(add('valorTotal'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorTotal }}</th>
        <th [ngClass]="parametro == 'C' ? 'fonte-menor text-center' : ''" class="l-10 text-right clique" (click)="listar(add('valor'), this.utilSessao.posicao, this.paginacao)">{{ parametro == 'C' ? bibDialogo.valorParcela : bibDialogo.valor }}</th>
        <th *ngIf="parametro == 'C'" class="l-10 text-right clique fonte-menor" (click)="listar(add('valorLiquido'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.valorLiquido }}</th>
        <th [ngClass]="[parametro == 'M' ? 'l-20' : 'l-10', parametro == 'C' ? 'fonte-menor' : '']" class="clique text-right" (click)="listar(add('numeroDocumento'), this.utilSessao.posicao, this.paginacao)">{{ parametro != 'C' ? '  Nº ' + bibDialogo.documento : bibDialogo.numeroAutorizacao }}</th>
        <th class="l-5 naoImprimir"></th>
        <th class="l-5 naoImprimir" *ngIf="parametro == 'S' || parametro == 'M'"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let conciliacao of conciliacoes; let i = index">
        <tr>
          <td *ngIf="parametro != 'M'" class="naoImprimir">
            <check [campo]="parametro == 'S' || parametro == 'M' || parametro == 'C' ? conciliacao.conciliado : conciliacao.conciliado == 'S' ? 'N' : 'S'" (alteracao)="setSelecionado(conciliacao, $event)" [foco]="i == 0 ? true : false"></check>
          </td>
          <td *ngIf="parametro != 'C'">
            <div class="text-center fa-branco" [ngClass]="{ 'bg-azul': conciliacao.tipo == 'R', 'bg-vermelho': conciliacao.tipo == 'D' }">{{ conciliacao.tipo }}</div>
          </td>
          <td>{{ conciliacao.abreviacao }}</td>
          <td>
            <data *ngIf="parametro == 'S'" [calendario]="false" [campo]="conciliacao.dataConciliacao" (alteracao)="setDataConciliacao(conciliacao, $event)" [desabilitado]="conciliacao.conciliado == 'N'"></data>
            <ng-container *ngIf="parametro == 'N' || parametro == 'M'">{{ conciliacao.dataConciliacao | data }}</ng-container>
            <ng-container *ngIf="parametro == 'C'">{{ conciliacao.dataLancamento | data }}</ng-container>
          </td>
          <td class="naoImprimir">{{ conciliacao.dataPrevista | data }}</td>
          <td *ngIf="parametro != 'C'" class="limitar">{{ conciliacao.transferenciaId ? conciliacao.contaTransferencia : conciliacao.nomeParceiro }}</td>
          <td class="limitar">
            <ng-container *ngIf="parametro != 'M' || cartaoEspecies.includes(this.conciliacao.idEspecie)">{{ conciliacao.tipoTitulo }}</ng-container>
            <lista [id]="'tipoTitulo'" *ngIf="parametro == 'M' && cartaoEspecies.includes(this.conciliacao.idEspecie) == false" [campo]="conciliacao.idTipoTitulo" (alteracao)="conciliacao.idTipoTitulo = $event.id" [lista]="conciliacao.tiposTituloPermitidos" [obrigatorio]="true"></lista>
          </td>
          <td *ngIf="parametro == 'C'" class="text-center">{{ conciliacao.idEspecie == 2 ? 'D' : 'C' }}</td>
          <td *ngIf="parametro == 'C'" class="text-right">{{ conciliacao.parcela ? conciliacao.parcela : '1'  }}</td>
          <td *ngIf="parametro == 'C'" class="text-right">{{ conciliacao.valorTotal | monetario }}</td>
          <td *ngIf="parametro == 'C'" class="text-center">{{ conciliacao.valor | monetario }}</td>
          <td class="text-right">{{ conciliacao.valorLiquido | monetario }}</td>
          <td class="limitar text-right">
            <ng-container *ngIf="parametro != 'M'">{{ conciliacao.numeroDocumento }}</ng-container>
            <texto [id]="'numeroDocumento'" *ngIf="parametro == 'M'" [campo]="conciliacao.numeroDocumento" (alteracao)="conciliacao.numeroDocumento = $event"></texto>
          </td>
          <td class="naoImprimir">
            <btnAdicional *ngIf="conciliacao.observacao != null" [icone]="conciliacao.expandido ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="conciliacao.expandido = !conciliacao.expandido"></btnAdicional>
          </td>
          <td class="naoImprimir" *ngIf="parametro == 'S'">
            <btnAdicional [icone]="bibIcone.lixo" (btnAdicional)="excluir(conciliacao.id, conciliacoes, bibDialogo.estaConciliacaoEBaixasVinculadas.toLowerCase())" [ajuda]="bibDialogo.estornarBaixa"></btnAdicional>
          </td>
        </tr>
        <tr *ngIf="conciliacao.expandido">
          <td class="texto-normal" colspan="12">
            <descricao [rotulo]="bibDialogo.descricao" [campo]="conciliacao.observacao" (alteracao)="conciliacao.observacao = $event" [desabilitado]="true" [normal]="true" [maximoCaracteres]="250"></descricao>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
  <totalizador *ngIf="valorTotalReceita != 0" [alinhamento]="valorTotalDespesa != 0 ? bibPropriedade.totalizador.esquerda : bibPropriedade.totalizador.centro" [atencaoTotalizador]="bibDialogo.receita" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta" [valorTotalizador]="valorTotalReceita | monetario" [tipoTotalizador]="bibPropriedade.cor.azul"></totalizador>
  <totalizador *ngIf="valorTotalDespesa != 0" [alinhamento]="valorTotalReceita != 0 ? bibPropriedade.totalizador.direita : bibPropriedade.totalizador.centro" [atencaoTotalizador]="bibDialogo.despesa" [tipoAtencaoTotalizador]="bibPropriedade.atencao.alerta" [valorTotalizador]="valorTotalDespesa | monetario" [tipoTotalizador]="bibPropriedade.cor.vermelho"></totalizador>
</layout>
<botao [compacto]="compacto" [legenda]="parametro == 'S' || parametro == 'C' ? bibDialogo.conciliar : parametro == 'M' ? bibDialogo.salvar : bibDialogo.desfazer" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirConciliacao()" [desabilitado]="!temPermissaoEdicao"></botao>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<bloqueado [bloqueado]="temPermissaoEdicao == false"></bloqueado>
