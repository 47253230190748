export class ProdutoParceiroComissao {
  public colaborador: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public excluido: boolean = false;
  public id: number;
  public idColaborador: number;
  public idEmpresa: number;
  public idProduto: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public percentual: number;
  public percentualVd: number;
  public produto: string;
}
