import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboAtivo: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.ativo, id: 'S' },
  { nome: bibDialogo.inativo, id: 'N' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'id', rotulo: bibDialogo.nReduzido, nome: 'ID', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'numero', rotulo: bibDialogo.numeracaoConta, nome: 'NUMERACAO', servicoWeb: bibServico.contabil, coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 12, tipo: bibPropriedade.filtro.texto },
  { id: 'nivel', rotulo: bibDialogo.nivel, nome: 'NIVEL', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'ativo', rotulo: bibDialogo.situacao, nome: 'ATIVO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'S', lista: filtroComboAtivo },
]);

export default filtros;
