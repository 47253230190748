<titulo [titulo]="bibDialogo.estoqueMinimoMaximo" [imagem]="bibImagem.produtoestoqueminimomaximo" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.estoqueMinimoMaximo.toLowerCase()" [desabilitado]="bloquearPermissao(produtoControlador.produto.id)"> </titulo>
<table *ngIf="produtoControlador.produto.produtoEstoqueMinimoMaximos.length > 0" class="table table-responsive-sm tabela-formulario">
  <thead>
    <tr>
      <th class="l-15">{{ bibDialogo.loja }}</th>
      <th class="l-10 text-right">{{ bibDialogo.disponivel }}</th>
      <th class="l-10 text-right">{{ bibDialogo.pendente }}</th>
      <th class="l-10 text-right">{{ bibDialogo.nfe }}</th>
      <th class="l-10 text-right fonte-menor">{{ bibDialogo.emTransferencia }}<ajuda [ajuda]="bibDialogo.aguardandoChegar"></ajuda></th>
      <th class="l-10 text-right">{{ bibDialogo.comprado }}</th>  
      <th class="l-10 text-right">{{ bibDialogo.minimo }}</th>
      <th class="l-10 text-right">{{ bibDialogo.maximo }}</th>
      <th class="l-10 text-right">{{ bibDialogo.percentual + ' ' + bibDialogo.padrao }}</th>          
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let produtoEstoqueMinimoMaximo of produtoControlador.produto.produtoEstoqueMinimoMaximos; let i = index">
      <tr>
        <td>{{ produtoEstoqueMinimoMaximo.lojaAbreviacao }}</td>
        <td class="text-right">{{ produtoEstoqueMinimoMaximo.quantidadeDisponivel | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ produtoEstoqueMinimoMaximo.quantidadeReservada + produtoEstoqueMinimoMaximo.quantidadeCarteira | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ produtoEstoqueMinimoMaximo.quantidadeNfe | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ produtoEstoqueMinimoMaximo.quantidadeTransferida | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right">{{ produtoEstoqueMinimoMaximo.quantidadeComprada | monetario: empresaRegraCasasDecimais }}</td>
        <td class="text-right">
          <decimal *ngIf="produtoEstoqueMinimoMaximo.editando" [campo]="produtoEstoqueMinimoMaximo.quantidadeMinima" [id]="'min' + i" (alteracao)="setQuantidadeMinima(produtoEstoqueMinimoMaximo, $event)"></decimal>
          {{ produtoEstoqueMinimoMaximo.editando == false ? (produtoEstoqueMinimoMaximo.quantidadeMinima | monetario) : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="produtoEstoqueMinimoMaximo.editando" [campo]="produtoEstoqueMinimoMaximo.quantidadeMaxima" [id]="'max' + i" (alteracao)="setQuantidadeMaxima(produtoEstoqueMinimoMaximo, $event)"></decimal>
          {{ produtoEstoqueMinimoMaximo.editando == false ? (produtoEstoqueMinimoMaximo.quantidadeMaxima | monetario) : '' }}
        </td>
        <td class="text-right">
          <decimal *ngIf="produtoEstoqueMinimoMaximo.editando" [campo]="produtoEstoqueMinimoMaximo.percentual" [id]="'percentual' + i" (alteracao)="setPercentual(produtoEstoqueMinimoMaximo, $event)"></decimal>
          {{ produtoEstoqueMinimoMaximo.editando == false ? (produtoEstoqueMinimoMaximo.percentual | monetario) : '' }}
        </td>        
        <td></td>
        <td></td>        
        <td>
          <btnAdicional [id]="'editar' + i" [icone]="bibIcone.editar" (btnAdicional)="editar(produtoEstoqueMinimoMaximo)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirProdutoEstoqueMinimoMaximo(i)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="1"></td>
      <td class="text-right font-weight-bold">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeDisponivel | monetario: empresaRegraCasasDecimais }}</td>
      <td class="text-right font-weight-bold">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeReservadaCarteira | monetario: empresaRegraCasasDecimais }}</td>
      <td class="text-right font-weight-bold">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeNfe | monetario: empresaRegraCasasDecimais }}</td>
      <td class="text-right font-weight-bold">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeTransferida | monetario: empresaRegraCasasDecimais }}</td>
      <td class="text-right font-weight-bold">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeComprada | monetario: empresaRegraCasasDecimais }}</td>
      <td colspan="1" class="text-right font-weight-bold limitar" *ngIf="this.editandoQuantidadeMinima == false && produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual != 100">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima| decimal }}</td>
      <td colspan="1" class="text-right" *ngIf="this.editandoQuantidadeMinima == false && produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual == 100">
        <apresentacao [campo]="produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima | monetario" [icone]="bibIcone.editar" (btnAdicional)="this.editandoQuantidadeMinima = !this.editandoQuantidadeMinima"></apresentacao>
      </td>      
      <td colspan="1" class="text-right" *ngIf="this.editandoQuantidadeMinima" >
        <decimal [campo]="produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMinima" (alteracao)="setTotalMinima($event)"></decimal>
      </td>
      <td colspan="1" class="text-right font-weight-bold limitar" *ngIf="this.editandoQuantidadeMaxima == false && produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual != 100">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima| decimal }}</td>
      <td colspan="1" class="text-right" *ngIf="this.editandoQuantidadeMaxima == false && produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual == 100">
        <apresentacao [campo]="produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima | monetario" [icone]="bibIcone.editar" (btnAdicional)="this.editandoQuantidadeMaxima = !this.editandoQuantidadeMaxima"></apresentacao>
      </td>      
      <td colspan="1" class="text-right" *ngIf="this.editandoQuantidadeMaxima">
        <decimal [campo]="produtoControlador.produto.produtoEstoqueMinimoMaximoTotalQuantidadeMaxima" (alteracao)="setTotalMaxima($event)"></decimal>
      </td>
      <td colspan="1" class="text-right font-weight-bold limitar">{{ produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual | decimal }}<atencao *ngIf="produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual > 0 && produtoControlador.produto.produtoEstoqueMinimoMaximoTotalPercentual != 100" [atencao]="'!'" [ajuda]="bibDialogo.verifiqueEstoqueMinimoMaximoPercentual" [tipo]="bibPropriedade.atencao.alerta"></atencao></td>      
      <td></td>
      <td></td>
    </tr>
  </tfoot>
</table>