import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.aguardando, id: 'A' },
  { nome: bibDialogo.cancelada, id: 'C' },
  { nome: bibDialogo.sucesso, id: 'S' },
  { nome: 'a ' + bibDialogo.cancelar + ' ou a' + bibDialogo.estornar, id: 'X' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 12, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.parceiro, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.responsavel, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.operacao, nome: 'ID_OPERACAO', coluna: 6, servicoWeb: bibServico.operacao, tipo: bibPropriedade.filtro.lista, criterios: [new Criterio('PERMITE_TRANSMITIR_NFE', 'S')] },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO_NFE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'A', lista: filtroSituacao },
  { rotulo: bibDialogo.situacao, nome: 'DESCONSIDERAR_NFE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.texto, valor: 'S', visualizacaoInicial: false },
]);
export default filtros;
