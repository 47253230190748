import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import bibPropriedade from '../../biblioteca/bibPropriedade';

@Component({
  selector: 'botao',
  templateUrl: './botao.component.html',
  styleUrls: ['./botao.css'],
})
export class BotaoComponent {
  public _desabilitado: boolean = false;
  public _legenda: string;
  public id: string;
  public modal: boolean = false;
  public bibDialogo = bibDialogo;
  public bibPropriedade = bibPropriedade;
  @Input() compacto: boolean;
  @Input() fundo: boolean;
  @Input() icone: String;
  @Input() tipo: String;
  @Output() botaoEmt = new EventEmitter<boolean>();
  @Output() entrando = new EventEmitter<number>();
  @Output() saindo = new EventEmitter<number>();
  @ViewChildren('botao') botao: any;

  @Input()
  set desabilitado(desabilitado: boolean) {
    this._desabilitado = desabilitado;
    this.verificarBotaoSalvarDesabilitado();
  }

  @Input()
  set legenda(legenda: string) {
    this._legenda = legenda;
    let legendaEmArray: string[] = [];
    legenda.split('').forEach((letra, i) => {
      letra = letra.toLowerCase();
      if (legenda[i - 1] && legenda[i - 1] == ' ') {
        letra = letra.toUpperCase();
      }
      legendaEmArray.push(letra);
    });
    legendaEmArray = legendaEmArray.filter((letra) => letra != ' ' && letra != '-');
    this.id = legendaEmArray.join('');
    this.verificarBotaoSalvarDesabilitado();
  }

  constructor(public utilSessao: UtilSessao, public utilNotificacao: UtilNotificacao) {}

  @Input()
  set focoId(focoId: number) {
    if (focoId) {
      Promise.resolve(null).then(() => this.botao.first.nativeElement.focus());
    }
  }

  ngOnInit(): void {
    this.utilNotificacao.salvarEmt.subscribe(() => {
      event.preventDefault();
      if (this._legenda == bibDialogo.salvar) {
        this.clicar();
      }
    });
    this.utilNotificacao.finalizarEmt.subscribe(() => {
      event.preventDefault();
      if (this._legenda == bibDialogo.finalizar) {
        this.clicar();
      }
    });
    this.modal = this.utilSessao.modal;
  }

  verificarBotaoSalvarDesabilitado(): void {
    if (this._desabilitado == true && this._legenda == bibDialogo.salvar) {
      this.utilSessao.setMostrarAtencaoSalvar(false);
    } else if (this._desabilitado == false && this._legenda == bibDialogo.salvar) {
      this.utilSessao.setMostrarAtencaoSalvar(true);
    }
  }

  getClasse(): string {
    switch (this.tipo) {
      case bibPropriedade.botao.cuidado:
        return `btn btn${this.fundo ? '-danger' : '-outline-danger'} ${this._desabilitado ? 'disabled transparente' : ''} ${this.compacto ? ' btn-xs' : ''}`;
      case bibPropriedade.botao.sucesso:
        return `btn btn${this.fundo ? '-success' : '-outline-success'} ${this._desabilitado ? 'disabled transparente' : ''} ${this.compacto ? ' btn-xs' : ''}`;
      case bibPropriedade.botao.atencao:
        return `btn btn${this.fundo ? '-warning ' : '-outline-warning '} ${this._desabilitado ? 'disabled transparente' : ''} ${this.compacto ? ' btn-xs' : ''}`;
      case bibPropriedade.botao.escuro:
          return `btn btn${this.fundo ? '-dark ' : '-outline-dark '} ${this._desabilitado ? 'disabled transparente' : ''} ${this.compacto ? ' btn-xs' : ''}`;
  
      default:
        return `btn btn${this.fundo ? '-primary' : '-outline-primary'} ${this._desabilitado ? 'disabled transparente' : ''} ${this.compacto ? ' btn-xs' : ''}`;
    }
  }

  clicar(): void {
    if (!this._desabilitado) {
      if (this.utilSessao.getResultados().length > 0 && (this.utilSessao.falha || this.utilSessao.informacaoAlert)) {
        this.utilSessao.limparResultado();
      } else {
        this.botaoEmt.emit(true);
      }
    }
  }

  navegar(focado: boolean): void {
    if (focado) {
      this.entrando.emit(Math.random());
    } else {
      this.saindo.emit(Math.random());
    }
  }
}
