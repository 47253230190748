<layout (filtroEmt)="listar($event)" [filtros]="filtros">
  <nav class="d-flex justify-content-center align-items-center">
    <ul class="pagination d-flex justify-content-center align-items-center">
      <li *ngIf="datas.length > 1 && (posicaoPaginacao || posicaoPaginacao == 0)" class="mr-1">
        <a href="#" (click)="voltar()"> <icone [icone]="bibIcone.setaEsquerda"></icone> </a>
      </li>
      <ng-container *ngFor="let data of datas; let i = index">
        <li *ngIf="i >= limitePaginacao[0] && i <= limitePaginacao[1]" [ngClass]="posicaoPaginacao == i ? 'page-item active paginaDestaque' : 'page-item'">
          <a class="page-link" (click)="popularFinanceiroMostrar(data, i)">{{ data }}</a>
        </li>
      </ng-container>
      <li *ngIf="datas.length > 1 && (posicaoPaginacao || posicaoPaginacao == 0)" class="ml-1">
        <a href="#" (click)="avancar()"> <icone [icone]="bibIcone.setaDireita"></icone> </a>
      </li>
    </ul>
  </nav>

  <div *ngIf="financeiros.length > 0" class="d-flex justify-content-center align-items-center flex-direction-column">
    <a href="#" (click)="mostrarTodosFinanceiros()">{{ bibDialogo.mostrarTodos }}</a>
  </div>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5">{{ bibDialogo.tipo }}</th>
        <th class="l-10">{{ bibDialogo.categoria }}</th>
        <th class="l-10">{{ bibDialogo.conta }}</th>
        <th class="l-15">{{ bibDialogo.parceiro }}</th>
        <th class="l-5">{{ bibDialogo.data }}</th>
        <th class="text-right l-10">{{ bibDialogo.previsto }}</th>
        <th class="text-right l-5">{{ bibDialogo.mais }}</th>
        <th class="text-right l-5">{{ bibDialogo.menos }}</th>
        <th class="text-right l-10">{{ bibDialogo.valorFinal }}</th>
        <th class="text-right l-10">{{ bibDialogo.valorRestante }}</th>
        <th class="l-5">{{ bibDialogo.loja }}</th>
        <th class="l-5">{{ bibDialogo.pago }}</th>
        <th class="l-5 naoImprimir"></th>
      </tr>
    </thead>
    <ng-container *ngFor="let financeiro of financeiroMostrar; let i = index">
      <tr *ngIf="financeiroMostrar[i - 1] ? financeiro.dataNegociacao.getMonth() != financeiroMostrar[i - 1].dataNegociacao.getMonth() : i == 0">
        <td colspan="12">
          <titulo [titulo]="selecionaNomeMes(financeiro.dataNegociacao.getMonth()) + ' de ' + financeiro.dataNegociacao.getFullYear()"></titulo>
        </td>
      </tr>
      <tr [ngClass]="{ selecionado: financeiro.financeiroBaixas.length > 0 || financeiro.alterado == true }">
        <td>
          <lista *ngIf="'l' + i + 'c' + '1' == indice && formularioAberto" [campo]="financeiro.tipo" [lista]="tipos" [obrigatorio]="true" (alteracao)="financeiro.tipo = $event.id" (navegacao)="fecharFormulario($event)" [foco]="true" [compacto]="true"></lista>
          <div *ngIf="'l' + i + 'c' + '1' != indice || !formularioAberto" (click)="calcularIndice(i, 1)" (dblclick)="abrirFormulario(i, 1)" tabindex="0" [id]="'l' + i + 'c' + '1'" class="text-center fa-branco" [ngClass]="{ 'bg-azul': financeiro.tipo == 'R', 'bg-vermelho': financeiro.tipo == 'D' }">{{ getNomeTipo(financeiro.tipo) }}</div>
        </td>
        <td>
          <lista *ngIf="'l' + i + 'c' + '2' == indice && formularioAberto" [campo]="financeiro.categoria" [lista]="categorias" [obrigatorio]="true" (alteracao)="financeiro.categoria = $event.id" [compacto]="true" (navegacao)="fecharFormulario($event)" [foco]="true"></lista>
          <div *ngIf="'l' + i + 'c' + '2' != indice || !formularioAberto" (click)="calcularIndice(i, 2)" (dblclick)="abrirFormulario(i, 2)" tabindex="0" [id]="'l' + i + 'c' + '2'" [focus]="i == 0">{{ getNomeCategoria(financeiro.categoria) }}</div>
        </td>
        <td>
          <lista *ngIf="'l' + i + 'c' + '3' == indice && formularioAberto" [campo]="financeiro.idConta" [tipo]="bibPropriedade.lista.contaUsuario" (alteracao)="financeiro.idConta = $event.id" [obrigatorio]="true" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></lista>
          <div *ngIf="'l' + i + 'c' + '3' != indice || !formularioAberto" class="row justify-content-end" (click)="calcularIndice(i, 3)" (dblclick)="abrirFormulario(i, 3)" tabindex="0" [id]="'l' + i + 'c' + '3'">
            {{ getNomeConta(financeiro.idConta) }}
            <obrigatorio *ngIf="financeiro.idConta == null" [compacto]="true"></obrigatorio>
          </div>
        </td>
        <td>
          <busca *ngIf="'l' + i + 'c' + '4' == indice && formularioAberto" [campo]="financeiro.parceiro" (alteracao)="financeiro.idParceiro = $event.id" [servico]="financeiro.bibServico.parceiro" [minimoCaracterBusca]="5" [obrigatorio]="true" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></busca>
          <div class="limitar" *ngIf="'l' + i + 'c' + '4' != indice || !formularioAberto" (click)="calcularIndice(i, 4)" (dblclick)="abrirModal(financeiro)" tabindex="0" [id]="'l' + i + 'c' + '4'">{{ financeiro.parceiro }}</div>
        </td>
        <td>
          <data *ngIf="'l' + i + 'c' + '5' == indice && formularioAberto" [campo]="financeiro.dataNegociacao" [calendario]="false" (alteracao)="financeiro.dataNegociacao = $event" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></data>
          <div *ngIf="'l' + i + 'c' + '5' != indice || !formularioAberto" (click)="calcularIndice(i, 5)" (dblclick)="abrirModal(financeiro)" tabindex="0" [id]="'l' + i + 'c' + '5'">{{ financeiro.dataNegociacao | data }}</div>
        </td>
        <td [ngClass]="ehModoClaro ? 'bg-cinza-fraco' : 'badge-light'">
          <div class="text-right" tabindex="0" [id]="'l' + i + 'c' + '6'" (click)="calcularIndice(i, 6)">{{ financeiro.valor | monetario }}</div>
        </td>
        <td>
          <decimal *ngIf="'l' + i + 'c' + '7' == indice && formularioAberto" [campo]="financeiro.multa + financeiro.juroAutomatico" (alteracao)="setValor(financeiro, $event, 'multa')" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></decimal>
          <div class="text-right" *ngIf="'l' + i + 'c' + '7' != indice || !formularioAberto" (click)="calcularIndice(i, 7)" (dblclick)="abrirFormulario(i, 7)" tabindex="0" [id]="'l' + i + 'c' + '7'">{{ financeiro.multa + financeiro.juroAutomatico | monetario }}</div>
        </td>
        <td>
          <decimal *ngIf="'l' + i + 'c' + '8' == indice && formularioAberto" [campo]="financeiro.desconto" (alteracao)="setValor(financeiro, $event, 'desconto')" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></decimal>
          <div class="text-right" *ngIf="'l' + i + 'c' + '8' != indice || !formularioAberto" (click)="calcularIndice(i, 8)" (dblclick)="abrirFormulario(i, 8)" tabindex="0" [id]="'l' + i + 'c' + '8'">{{ financeiro.desconto | monetario }}</div>
        </td>
        <td>
          <decimal *ngIf="'l' + i + 'c' + '9' == indice && formularioAberto" [campo]="financeiro.valorFinal" (alteracao)="setValorFinal(financeiro, $event)" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></decimal>
          <div class="text-right" *ngIf="'l' + i + 'c' + '9' != indice || !formularioAberto" tabindex="0" [id]="'l' + i + 'c' + '9'" (click)="calcularIndice(i, 9)" (dblclick)="abrirFormulario(i, 9)" [ngClass]="{ 'bg-vermelho': (financeiro.tipo == 'R' && financeiro.desconto > 0) || (financeiro.tipo == 'D' && financeiro.multa > 0), 'bg-verde': (financeiro.tipo == 'R' && financeiro.multa > 0) || (financeiro.tipo == 'D' && financeiro.desconto > 0), 'fa-branco': (financeiro.tipo == 'R' && financeiro.multa > 0) || (financeiro.tipo == 'D' && financeiro.desconto > 0) || (financeiro.tipo == 'R' && financeiro.desconto > 0) || (financeiro.tipo == 'D' && financeiro.multa > 0) }">{{ financeiro.valorFinal | monetario }}</div>
        </td>
        <td [ngClass]="ehModoClaro ? 'bg-cinza-fraco' : 'badge-light'">
          <div class="text-right" tabindex="0" [id]="'l' + i + 'c' + '10'" (click)="calcularIndice(i, 10)">{{ financeiro.valorRestante | monetario }}</div>
        </td>
        <td>
          <lista *ngIf="'l' + i + 'c' + '11' == indice && formularioAberto" [tipo]="financeiro.bibPropriedade.lista.lojaUsuario" [campo]="financeiro.idLoja" [obrigatorio]="true" (alteracao)="financeiro.idLoja = $event.id" [compacto]="true" [foco]="true" (navegacao)="fecharFormulario($event)"></lista>
          <div *ngIf="'l' + i + 'c' + '11' != indice || !formularioAberto" (click)="calcularIndice(i, 11)" (dblclick)="abrirFormulario(i, 11)" [id]="'l' + i + 'c' + '11'" tabindex="0">{{ getNomeLoja(financeiro.idLoja) }}</div>
        </td>
        <td class="naoImprimir">
          <div *ngIf="financeiro.valorRestante == 0" (click)="calcularIndice(i, 12)" [id]="'l' + i + 'c' + '12'" tabindex="0">{{ bibDialogo.pago }}</div>
          <check *ngIf="financeiro.valorRestante > 0" (click)="calcularIndice(i, 12)" [id]="'l' + i + 'c' + '12'" tabindex="0" [campo]="financeiro.financeiroBaixas.length > 0 ? 'S' : 'N'" (alteracao)="setPago(financeiro, $event)" [desabilitado]="financeiro.baixado"></check>
        </td>
        <td>
          <btnAdicional *ngIf="financeiro.valorRestante > 0" [icone]="bibIcone.editar" (btnAdicional)="abrirModal(financeiro)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </table>
  <div class="valorRodape text-center bg-cinza-claro" *ngIf="financeiros.length > 0" [ngClass]="{ 'modo-escuro': utilSessao.getUsuario()?.usaModoClaro != 'S' }">
    <div class="d-flex justify-content-center align-items-center flex-direction-column">
      <botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirFinanceiros()"></botao>
    </div>
  </div>
</layout>
<div class="d-flex flex-row align-items-center justify-content-between">
  <div *ngIf="temPermissaoInserir && financeiros.length > 0" class="naoImprimir">
    <button [id]="bibDialogo.adicionar" type="button" (click)="abrirModal()" class="posicao btn btn-success btn-floated">
      <span [class]="bibIcone.mais"></span>
    </button>
  </div>
  <div class="pb-3">
    <atencao [atencao]="bibDialogo.enterDescricao"></atencao>
    <atencao [atencao]="bibDialogo.escDescricao"></atencao>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="fecharModal()"></modal>
