import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoCancelamento } from 'src/app/modelo/movimentacaoCancelamento';
import { MovimentacaoNfe } from 'src/app/modelo/movimentacaoNfe';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { MovimentacaoFrmComponent } from '../movimentacao/frm/estrutura/movimentacaoFrm.component';
import { CancelamentoFrmComponent } from './cancelamentoFrm.component';
import filtros from './filtro';

@Component({
  templateUrl: './cancelamento.component.html',
})
export class CancelamentoComponent extends PaginaComponent {
  private modalEmt: any;
  public componente: any;
  public filtros: Filtro[] = filtros;
  public movimentacoes: Movimentacao[] = [];
  public atencao = this.util.substituir(this.bibDialogo.utilizeBotaoPesquisaParaListar, ['as ' + this.bibDialogo.operacoes.toLowerCase()]);

  ngOnInit(): void {
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.movimentacaoCancelamento) {
        const movimentacaoCancelamento: MovimentacaoCancelamento = this.plainToClass(MovimentacaoCancelamento, identificacao.conteudo);
        this.movimentacoes = this.movimentacoes.filter((movimentacao) => movimentacao.id != movimentacaoCancelamento.idMovimentacao);
      }
    });
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    criterios.push(new Criterio('STATUS', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      if (this.movimentacoes.length > 0) {
        this.listarMovimentacaoNfe(this.movimentacoes.map((movimentacao) => movimentacao.id));
        this.listarMovimentacaoCan();
      }
    });
  }

  private listarMovimentacaoNfe(idMovimentacoes: number[]): void {
    let criterios: Criterio = new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString());
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoNfe).subscribe((res) => {
      this.vincularMovimentacaoMovimentacaoNfe(this.plainToClass(MovimentacaoNfe, res) as any);
    });
  }

  private listarMovimentacaoCan(): void {
    this.comunicacaoService.listar(new Transporte(), this.bibServico.movimentacaoCancelamento).subscribe((res) => {
      this.vincularMovimentacaoMovimentacaoCan(this.plainToClass(MovimentacaoCancelamento, res) as any);
    });
  }

  private vincularMovimentacaoMovimentacaoCan(cancelamentos: MovimentacaoCancelamento[]): void {
    this.movimentacoes.forEach((movimentacao) => {
      const cancelamento: MovimentacaoCancelamento = cancelamentos.find((cancelamento) => cancelamento.idMovimentacao == movimentacao.id);
      if (cancelamento) {
        movimentacao.movimentacaoCancelamento = cancelamento;
      }
    });
  }

  private vincularMovimentacaoMovimentacaoNfe(movimentacoesNfes: MovimentacaoNfe[]): void {
    this.movimentacoes.forEach((movimentacao) => {
      const movimentacaoNfe: MovimentacaoNfe = movimentacoesNfes.find((movimentacoesNfe) => movimentacoesNfe.idMovimentacao == movimentacao.id);
      if (movimentacaoNfe) {
        movimentacao.movimentacaoNfe = movimentacaoNfe;
      }
    });
  }

  abrirModal(movimentacao: Movimentacao): void {
    if (movimentacao.movimentacaoNfe && movimentacao.movimentacaoNfe.situacaoDenegada == 'N') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.notaTransmitidaBloquearCancelar));
    } else {
      this.utilSessao.setIdentificacao(new Identificacao('movimentacao', movimentacao));
      this.componente = CancelamentoFrmComponent;
    }
  }

  abrirModalMovimentacao(id: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = MovimentacaoFrmComponent;
  }
}
