import { Pipe, PipeTransform } from '@angular/core';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Pipe({
  name: 'filtrarProdutoFormuladoDose',
})
export class ProdutoFormuladoDosePipe implements PipeTransform {
  transform(produtoFormuladoDoses: ProdutoFormuladoDose[], argumentos?: any): any {
    if (argumentos.filtro || argumentos.especifico == bibDialogo.semAcesso) {
      argumentos.filtro = argumentos.filtro.toLowerCase();
      switch (argumentos.especifico) {
        case bibDialogo.cultura:
          return produtoFormuladoDoses.filter((produtoFormuladoDose) => produtoFormuladoDose.cultura.toLowerCase().includes(argumentos.filtro));
        case bibDialogo.praga:
          return produtoFormuladoDoses.filter((produtoFormuladoDose) => produtoFormuladoDose.praga.toLowerCase().includes(argumentos.filtro));
      }
    } else {
      return produtoFormuladoDoses;
    }
  }
}
