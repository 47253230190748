<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (adicionalEmt)="definirRegraRelatorio($event)" (campoOrdemEmt)="ordenar(financeiros, $event)" (campoGrupoEmt)="agruparCalcularSaldo(financeiros, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-1">{{ bibDialogo.vencimento }}</th>
      <th class="r">{{ bibDialogo.parceiro }}</th>
      <th class="r-2">{{ bibDialogo.natureza }}</th>
      <th class="r-2">{{ bibDialogo.tipoTitulo }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.receita }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.despesa }}</th>
      <th class="r-1-5 text-right">{{ bibDialogo.saldo }}</th>
    </thead>
    <tbody>
      <tr *ngIf="inadimplenteFinanceiros.length > 0">
        <td class="p-0" [colSpan]="3">INADIMPLÊNCIA DESCONSIDERADA - APÓS 5 DIAS DE ATRASO</td>
        <td [colSpan]="2" class="naoImprimir"><btnAdicional [icone]="expandidoInadimplente ? bibIcone.setaCima : bibIcone.setaBaixo" (btnAdicional)="expandidoInadimplente = !expandidoInadimplente"></btnAdicional></td>
        <td class="text-right">{{ indadimplenteReceita | monetario }}</td>
        <td class="text-right">{{ indadimplenteDespesa | monetario }}</td>
        <td class="tachado text-right">{{ indadimplenteReceita - indadimplenteDespesa | monetario }}</td>
      </tr>
      <ng-container *ngFor="let inadimplenteFinanceiro of inadimplenteFinanceiros; let i = index">
        <tr *ngIf="expandidoInadimplente" class="conteudo" r1151financeiro [financeiro]="inadimplenteFinanceiro" [tachado]="true"></tr>
      </ng-container>
      <tr class="sem-borda" r1151contas [conciliacoes]="conciliacoes" [saldoTotal]="saldoTotalConciliacao"></tr>
      <ng-container *ngFor="let financeiro of financeiros; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" r1151financeiro [financeiro]="financeiro"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
